/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-cycle */
import React from 'react';

import { emailRegex, dashboardButtonUrlPattern } from '../../Shared/Forms/helpers/contants';
import { isRedirectToUrlBtn } from '../../Shared/Forms/helpers/dashboardBtnHelper';
import { stateList, countryList } from '../../Shared/Forms/helpers/stateCountryHelper';
import { timezones } from '../../Shared/Forms/helpers/timezoneHelper';
import AdvancedVendorSettings from '../AdvancedVendorSettings';
import DashboardSettingWrapper from '../DashboardSetting/DashboardSettingWrapper';
import MetaDataListWrapper from '../MetaDataSettings/MetaDataListWrapper';
import UserSegmentsListWrapper from '../UserSegmentsSettings/UserSegmentsListWrapper';

import {
  buttonStyleMapping,
  homeScreenStyleMapping,
  dashboardButtonStyleMapping,
  dashboardButtonActionMapping,
  welcomeScreenButtonStyleMapping,
  welcomeScreenButtonActionMapping,
  welcomeScreenSkipButtonContentMapping,
  orderingTestKitMapping,
  footerNavigationMapping,
} from './mappings';

export const NetworkAccountFormMap = [
  {
    type: 'group',
    label: 'TENANT INFORMATION',
    name: 'INFORMATION_SECTION',
    fields: [
      {
        type: 'hidden',
        name: '_id',
        label: 'ID',
        required: false,
        width: 4,
      },
      {
        type: 'text',
        name: 'name',
        label: 'Tenant Name',
        required: false,
        width: 4,
        maxLength: 50,
        readOnly: true,
      },
      {
        type: 'text',
        name: 'companyName',
        label: 'Company Name',
        required: false,
        width: 4,
        maxLength: 50,
        readOnly: true,
      },
      {
        type: 'text',
        name: 'appName',
        label: 'App Name',
        required: false,
        width: 4,
        maxLength: 50,
      },
      {
        type: 'text',
        name: 'addressOne',
        label: 'Address 1',
        required: true,
        width: 4,
      },
      {
        type: 'text',
        name: 'addressTwo',
        label: 'Address 2',
        required: false,
        width: 4,
      },
      {
        type: 'text',
        name: 'city',
        label: 'City',
        required: true,
        width: 4,
        maxLength: 50,
      },
      {
        type: 'autocomplete',
        name: 'state',
        label: 'State',
        enum: stateList,
        required: true,
        width: 4,
        labelField: 'name',
        valueField: 'abbreviation',
      },
      {
        type: 'text',
        name: 'zip',
        label: 'Zip Code',
        required: true,
        width: 4,
        maxLength: 5,
        pattern: '^[0-9]{5}(?:-[0-9]{4})?$',
      },
      {
        type: 'autocomplete',
        name: 'country',
        label: 'Country',
        required: true,
        enum: countryList,
        width: 4,
      },
    ],
  },
  {
    type: 'hidden',
    label: 'ROOT ADMIN',
    name: 'ROOT_ADMIN_SECTION',
    fields: [
      {
        type: 'text',
        name: 'adminContactFirstName',
        label: 'First Name',
        required: false,
        width: 4,
      },
      {
        type: 'text',
        name: 'adminContactLastName',
        label: 'Last Name',
        required: false,
        width: 4,
      },
      {
        type: 'text',
        name: 'adminContactPosition',
        label: 'Position',
        required: false,
        width: 4,
      },
      {
        type: 'text',
        name: 'adminContactEmail',
        label: 'Email',
        required: false,
        width: 4,
        pattern: emailRegex,
      },
      {
        type: 'text',
        name: 'adminContactOfficePhone',
        label: 'Mobile Phone',
        required: false,
        width: 4,
        mask: '(999) 999-9999',
        pattern: '^(?!(\\d)\\1{9})(?!0123456789|1234567890|0987654321|9876543210)\\d{10}$',
      },
      {
        type: 'text',
        name: 'adminContactFax',
        label: 'Fax',
        required: false,
        width: 4,
        mask: '(999) 999-9999',
        pattern: '^(?!(\\d)\\1{9})(?!0123456789|1234567890|0987654321|9876543210)\\d{10}$',
      },
    ],
  },
  {
    type: 'group',
    label: 'MEMBER SUPPORT',
    name: 'MEMBER_SUPPORT_SECTION',
    fields: [
      {
        type: 'text',
        name: 'supportEmail',
        label: 'Support Email',
        required: false,
        width: 4,
        maxLength: 250,
        pattern: emailRegex,
      },
      {
        type: 'text',
        name: 'supportPhone',
        label: 'Support Phone',
        required: false,
        width: 4,
        mask: '(999) 999-9999',
        pattern: '^(?!(\\d)\\1{9})(?!0123456789|1234567890|0987654321|9876543210)\\d{10}$',
      },
      {
        type: 'text',
        name: 'privacyEmail',
        label: 'Privacy Email',
        required: false,
        width: 4,
        maxLength: 250,
        pattern: emailRegex,
      },
      {
        type: 'text',
        name: 'supportIncomingFax',
        label: 'Incoming Fax',
        required: false,
        width: 4,
        mask: '(999) 999-9999',
        pattern: '^(?!(\\d)\\1{9})(?!0123456789|1234567890|0987654321|9876543210)\\d{10}$',
      },
      {
        type: 'text',
        name: 'supportDirectAddres',
        label: 'Direct Address',
        required: false,
        width: 4,
        maxLength: 500,
      },
      {
        type: 'text',
        name: 'emailSenderAddress',
        label: 'Sender Email',
        required: false,
        width: 4,
        maxLength: 250,
        pattern: emailRegex,
      },
    ],
  },
  {
    type: 'group',
    label: 'BILLING INFO',
    name: 'BILLING_INFO_SECTION',
    fields: [
      {
        type: 'text',
        name: 'bankName',
        label: 'Bank Name',
        required: false,
        width: 4,
        maxLength: 250,
      },
      {
        type: 'text',
        name: 'accountName',
        label: 'Account Name',
        required: false,
        width: 4,
        maxLength: 250,
      },
      {
        type: 'text',
        name: 'accountNumber',
        label: 'Account Number',
        required: false,
        width: 4,
        maxLength: 250,
        sensitive: true,
      },
      {
        type: 'text',
        name: 'routingNumber',
        label: 'Routing Number',
        required: false,
        width: 4,
        maxLength: 250,
        sensitive: true,
      },
      {
        type: 'text',
        name: 'swiftCode',
        label: 'SWIFT Code',
        required: false,
        width: 4,
        sensitive: true,
      },
      {
        type: 'text',
        name: 'taxIDEIN',
        label: 'Tax ID / EIN',
        required: false,
        width: 4,
        sensitive: true,
      },
    ],
  },
];

export const AdvancedVendorSettingsMap = [
  {
    type: 'text',
    name: 'accountId',
    label: 'Account ID',
    required: false,
    width: 4,
    variant: 'outlined',
  },
  {
    type: 'text',
    name: 'receivingApplication',
    label: 'Receiving Application',
    required: false,
    width: 4,
    variant: 'outlined',
  },
  {
    type: 'text',
    name: 'sendingApplication',
    label: 'Sending Application',
    required: false,
    width: 4,
    variant: 'outlined',
  },
  {
    type: 'text',
    name: 'receivingFacility',
    label: 'Receiving Facility',
    required: false,
    width: 4,
    variant: 'outlined',
  },
  {
    type: 'text',
    name: 'sendingFacility',
    label: 'Sending Facility',
    required: false,
    width: 4,
    variant: 'outlined',
  },
];

export function NetworkSettingsFormMap(isSingleTenant, currentTenant, currentNetwork, formData) {
  return [
    {
      type: 'group',
      label: 'TEST KIT ORDER SETTINGS',
      name: 'TEST_KIT_ORDER_SETTINGS',
      fields: [
        {
          type: 'autocomplete',
          name: 'orderingTestKitOption',
          label: 'Test Kit Order Method',
          required: false,
          width: 4,
          enum: orderingTestKitMapping,
          labelField: 'label',
          valueField: 'value',
          initialValue: 0,
          disableClear: true,
          variant: 'outlined',
        },
      ],
    },
    {
      type: 'group',
      label: 'MEDICAL SETTINGS',
      name: 'LAB_SETTINGS',
      fields: [
        {
          type: 'header',
          label: 'Medical Director',
        },
        {
          type: 'providersTypeAhead',
          name: 'medicalDirector',
          label: 'Select a provider by NPI',
          required: false,
          width: 4,
          variant: 'outlined',
          setFields: [
            {
              field: 'firstName',
              targetField: 'medicContactFirstName',
            },
            {
              field: 'middleName',
              targetField: 'medicContactMiddleName',
            },
            {
              field: 'lastName',
              targetField: 'medicContactLastName',
            },
            {
              field: 'suffix',
              targetField: 'medicContactSuffix',
            },
            {
              field: 'title',
              targetField: 'medicContactTitle',
            },
            {
              field: 'professionalIdentificationsPrimarySpecialty',
              targetField: 'medicContactSpeciality',
            },
            {
              field: 'sex',
              targetField: 'medicContactGender',
            },
            {
              field: 'npi',
              targetField: 'medicContactNPI',
            },
            {
              field: 'professionalIdentificationsCAQHProviderID',
              targetField: 'medicContactCAQH',
            },
            {
              field: 'contactInformationPrimaryEmail',
              targetField: 'medicContactEmail',
            },
            {
              field: 'contactInformationOfficePhone',
              targetField: 'medicContactOfficePhone',
            },
            {
              field: 'contactInformationFax',
              targetField: 'medicContactFax',
            },
          ],
        },
        {
          type: 'spacer',
        },
        {
          type: 'text',
          name: 'medicContactFirstName',
          label: 'First Name',
          required: false,
          width: 4,
          maxLength: 50,
          readOnly: true,
          variant: 'outlined',
        },
        {
          type: 'text',
          name: 'medicContactMiddleName',
          label: 'Middle Name',
          required: false,
          width: 4,
          maxLength: 50,
          readOnly: true,
          variant: 'outlined',
        },
        {
          type: 'text',
          name: 'medicContactLastName',
          label: 'Last Name',
          required: false,
          width: 4,
          maxLength: 50,
          readOnly: true,
          variant: 'outlined',
        },
        {
          type: 'text',
          name: 'medicContactSuffix',
          label: 'Suffix',
          required: false,
          width: 4,
          readOnly: true,
          variant: 'outlined',
        },
        {
          type: 'text',
          name: 'medicContactTitle',
          label: 'Title',
          required: false,
          width: 4,
          maxLength: 250,
          readOnly: true,
          variant: 'outlined',
        },
        {
          type: 'text',
          name: 'medicContactSpeciality',
          label: 'Specialty',
          required: false,
          width: 4,
          readOnly: true,
          variant: 'outlined',
        },
        {
          type: 'text',
          name: 'medicContactGender',
          label: 'Sex',
          required: false,
          width: 4,
          readOnly: true,
          variant: 'outlined',
        },
        {
          type: 'text',
          name: 'medicContactNPI',
          label: 'NPI',
          required: false,
          width: 4,
          maxLength: 10,
          readOnly: true,
          variant: 'outlined',
        },
        {
          type: 'text',
          name: 'medicContactCAQH',
          label: 'CAQH Provider ID',
          required: false,
          width: 4,
          maxLength: 10,
          readOnly: true,
          variant: 'outlined',
        },
        {
          type: 'text',
          name: 'medicContactEmail',
          label: 'Email',
          required: false,
          width: 4,
          maxLength: 250,
          readOnly: true,
          variant: 'outlined',
        },
        {
          type: 'text',
          name: 'medicContactOfficePhone',
          label: 'Mobile Phone',
          required: false,
          width: 4,
          readOnly: true,
          variant: 'outlined',
        },
        {
          type: 'text',
          name: 'medicContactFax',
          label: 'Fax',
          required: false,
          width: 4,
          readOnly: true,
          variant: 'outlined',
        },
        {
          type: 'divider',
        },
        {
          type: 'header',
          label: 'Advanced Vendor Settings',
        },
        {
          type: 'customComponent',
          name: 'advancedVendorSettings',
          label: 'Advanced Vendor Settings',
          value: <AdvancedVendorSettings key="ADVANCED_VENDOR_SETTINGS" />,
        },
        {
          type: 'divider',
        },
        {
          type: 'header',
          label: 'Orders',
        },
        {
          type: 'toggle',
          name: 'labOrderCollectInsuranceInformation',
          label: 'Collect Insurance Information',
          required: false,
          width: 4,
          labelPlacement: 'end',
        },
        {
          type: 'toggle',
          name: 'labOrderCoverPatientResponsibility',
          label: 'Cover Patient Responsibility',
          required: false,
          width: 4,
          labelPlacement: 'end',
        },
        {
          type: 'toggle',
          name: 'labOrderShowDedicatedAgreementScreen',
          label: 'Show Dedicated Agreement Screen',
          required: false,
          width: 4,
          labelPlacement: 'end',
        },
        {
          type: 'toggle',
          name: 'labOrderRequireSignature',
          label: 'Require Patient Signature',
          required: false,
          width: 4,
          labelPlacement: 'end',
        },
      ],
    },
    {
      type: 'group',
      label: 'USER ONBOARDING SETTINGS',
      name: 'USER_ONBOARDING_SETTINGS',
      fields: [
        {
          type: 'toggle',
          name: 'onboardingShowDedicatedAgreementScreen',
          label: 'Display Agreement Screen',
          required: false,
          width: 4,
          labelPlacement: 'end',
        },
        {
          type: 'toggle',
          name: 'onboardingRequireSignature',
          label: 'Required Signature',
          required: false,
          width: 4,
          labelPlacement: 'end',
        },
        {
          type: 'toggle',
          name: 'onboardingCollectInsuranceInformation',
          label: 'Collect Insurance Information',
          required: false,
          width: 4,
          labelPlacement: 'end',
        },
        {
          type: 'toggle',
          name: 'collectDateOfBirth',
          label: 'Collect Date of Birth',
          required: false,
          width: 4,
          labelPlacement: 'end',
          defaultValue: true,
          requiredField: {
            type: 'toggle',
            name: 'collectDateOfBirthRequired',
            label: 'Required',
            required: false,
            width: 12,
            labelPlacement: 'end',
            disableIfFalse: 'collectDateOfBirth',
          },
        },
        {
          type: 'toggle',
          name: 'collectSex',
          label: 'Collect Sex',
          required: false,
          width: 4,
          labelPlacement: 'end',
          defaultValue: true,
          requiredField: {
            type: 'toggle',
            name: 'collectSexRequired',
            label: 'Required',
            required: false,
            width: 12,
            labelPlacement: 'end',
            disableIfFalse: 'collectSex',
          },
        },
        {
          type: 'toggle',
          name: 'collectAddress',
          label: 'Collect Address',
          required: false,
          width: 4,
          labelPlacement: 'end',
          defaultValue: true,
          childFields: [
            'collectStreetAddress',
            'collectState',
            'collectCity',
            'collectZip',
            'collectStreetAddressRequired',
            'collectStateRequired',
            'collectZipRequired',
          ],
          requiredField: {
            type: 'toggle',
            name: 'collectAddressRequired',
            label: 'Required',
            required: false,
            width: 12,
            labelPlacement: 'end',
            disableIfFalse: 'collectAddress',
            childFields: [
              'collectStreetAddressRequired',
              'collectCityRequired',
              'collectStateRequired',
              'collectZipRequired',
            ],
          },
        },
        {
          type: 'toggle',
          name: 'collectStreetAddress',
          label: 'Collect Street Address',
          required: false,
          width: 4,
          labelPlacement: 'end',
          defaultValue: true,
          disableIfFalse: 'collectAddress',
          requiredField: {
            type: 'toggle',
            name: 'collectStreetAddressRequired',
            label: 'Required',
            required: false,
            width: 12,
            labelPlacement: 'end',
            disableIfFalse: 'collectStreetAddress',
          },
        },
        {
          type: 'toggle',
          name: 'collectCity',
          label: 'Collect City',
          required: false,
          width: 4,
          labelPlacement: 'end',
          defaultValue: true,
          disableIfFalse: 'collectAddress',
          requiredField: {
            type: 'toggle',
            name: 'collectCityRequired',
            label: 'Required',
            required: false,
            width: 12,
            labelPlacement: 'end',
            disableIfFalse: 'collectCity',
          },
        },
        {
          type: 'toggle',
          name: 'collectState',
          label: 'Collect State',
          required: false,
          width: 4,
          labelPlacement: 'end',
          defaultValue: true,
          disableIfFalse: 'collectAddress',
          requiredField: {
            type: 'toggle',
            name: 'collectStateRequired',
            label: 'Required',
            required: false,
            width: 12,
            labelPlacement: 'end',
            disableIfFalse: 'collectState',
          },
        },
        {
          type: 'toggle',
          name: 'collectZip',
          label: 'Collect Zip Code',
          required: false,
          width: 4,
          labelPlacement: 'end',
          defaultValue: true,
          disableIfFalse: 'collectAddress',
          requiredField: {
            type: 'toggle',
            name: 'collectZipRequired',
            label: 'Required',
            required: false,
            width: 12,
            labelPlacement: 'end',
            disableIfFalse: 'collectZip',
          },
        },
        {
          type: 'toggle',
          name: 'collectRace',
          label: 'Collect Race',
          required: false,
          width: 4,
          labelPlacement: 'end',
          defaultValue: true,
          requiredField: {
            type: 'toggle',
            name: 'raceRequired',
            label: 'Required',
            required: false,
            width: 12,
            labelPlacement: 'end',
            disableIfFalse: 'collectRace',
          },
        },
        {
          type: 'toggle',
          name: 'collectEthicity',
          label: 'Collect Ethnicity',
          required: false,
          width: 4,
          labelPlacement: 'end',
          defaultValue: true,
          requiredField: {
            type: 'toggle',
            name: 'ehtnicityRequired',
            label: 'Required',
            required: false,
            width: 12,
            labelPlacement: 'end',
            disableIfFalse: 'collectEthicity',
          },
        },
        {
          type: 'toggle',
          name: 'verifyEmail',
          label: 'Verify Email',
          required: false,
          width: 4,
          labelPlacement: 'end',
        },
        {
          type: 'toggle',
          name: 'collectPhoneNumber',
          label: 'Collect Phone Number',
          required: false,
          width: 4,
          labelPlacement: 'end',
          requiredField: {
            type: 'toggle',
            name: 'collectPhoneNumberRequired',
            label: 'Required',
            required: false,
            width: 12,
            labelPlacement: 'end',
            disableIfFalse: 'collectPhoneNumber',
          },
        },
        {
          type: 'toggle',
          name: 'verifyPhone',
          label: 'Verify Phone',
          required: false,
          width: 4,
          labelPlacement: 'end',
          requiredField: {
            type: 'toggle',
            name: 'verifyPhoneRequired',
            label: 'Required',
            required: false,
            width: 12,
            labelPlacement: 'end',
            disableIfFalse: 'verifyPhone',
          },
        },
        {
          type: 'toggle',
          name: 'verifyIdCard',
          label: 'Verify ID Card',
          required: false,
          width: 4,
          labelPlacement: 'end',
          requiredField: {
            type: 'toggle',
            name: 'verifyIdCardRequired',
            label: 'Required',
            required: false,
            width: 12,
            labelPlacement: 'end',
            disableIfFalse: 'verifyIdCard',
          },
        },
        {
          type: 'toggle',
          name: 'uploadProfileImage',
          label: 'Upload Profile Image',
          required: false,
          width: 4,
          labelPlacement: 'end',
        },
        {
          type: 'toggle',
          name: 'showWelcomeScreen',
          label: 'Show Welcome Screen',
          required: false,
          width: 4,
          labelPlacement: 'end',
        },
        {
          type: 'toggle',
          name: 'showPreRegistrationWidget',
          label: 'Show Pre-registration Widget',
          required: false,
          width: 4,
          labelPlacement: 'end',
        },
        {
          type: 'toggle',
          name: 'enableWelcomeKit',
          label: 'Enable Welcome Kit',
          required: false,
          width: 4,
          labelPlacement: 'end',
        },
        {
          type: 'toggle',
          name: 'enableRegistrationFAQ',
          label: 'Enable Registration FAQ',
          required: false,
          width: 4,
          labelPlacement: 'end',
        },
      ],
    },
    // PROCTOR SETTINGS
    // Proctor hidden for 3.3 release
    {
      type: 'group',
      label: 'PROCTOR SETTINGS',
      name: 'PROCTOR_SETTINGS',
      fields: [
        {
          type: 'toggle',
          name: 'enableProctorFeature',
          label: 'Enable Proctoring',
          required: false,
          width: 4,
          labelPlacement: 'end',
          readOnly: !formData?.enableProctorFeatureUI,
        },
      ],
    },
    {
      type: 'group',
      label: 'ADHERENCE SETTINGS',
      name: 'ADHERENCE_SETTINGS',
      fields: [
        {
          type: 'toggle',
          name: 'enableAdherenceAlerts',
          label: 'Enable Adherence Alerts',
          required: false,
          width: 4,
          labelPlacement: 'end',
          readOnly: !formData?.enableAdherenceAlertsControl,
        },
      ],
    },
    {
      type: 'group',
      label: 'DASHBOARD SETTINGS',
      name: 'DASHBOARD_SETTINGS',
      fields: [
        {
          type: 'customComponent',
          value: (
            <DashboardSettingWrapper
              currentTenant={currentTenant}
              currentNetwork={currentNetwork}
              key={'DASHBOARD_SETTINGS'}
            />
          ),
        },
        {
          type: 'toggle',
          name: 'displayAIHistogramCharts',
          label: 'Display AI Histogram Charts',
          required: false,
          width: 12,
          labelPlacement: 'end',
          readOnly: !formData?.enableDashboardSettings,
        },
        {
          type: 'header',
          label: 'Primary Dashboard Card',
        },
        {
          type: 'autocomplete',
          name: 'dashboardButtonAction1',
          label: 'Button Action',
          // required: false,
          width: 4,
          enum: dashboardButtonActionMapping,
          labelField: 'label',
          valueField: 'value',
          disableClear: true,
          required: !!formData.dashboardButtonTitle1,
          readOnly: !formData?.enableDashboardSettings,
          variant: 'outlined',
        },
        {
          type: 'autocomplete',
          name: 'dashboardButtonStyle1',
          label: 'Button Style',
          required: false,
          width: 4,
          enum: dashboardButtonStyleMapping,
          labelField: 'label',
          valueField: 'value',
          initialValue: 0,
          disableClear: true,
          description: 'Button can include an image background or colored background with optional gradient.',
          readOnly: !formData?.enableDashboardSettings,
          variant: 'outlined',
        },
        {
          type: 'conditional',
          checkField: 'dashboardButtonStyle1',
          checkValue: 3,
          targetType: 'imageCrop',
          name: 'dashboardButtonImageURL1',
          label: 'Image',
          imageWidth: 684,
          imageHeight: 528,
          acceptedFiles: ['image/jpeg', 'image/png'],
          fileTypeCaption: 'Allowed file types: PNG and JPG',
          required: false,
          width: 4,
          readOnly: !formData?.enableDashboardSettings,
        },
        {
          type: 'conditional',
          targetType: 'grouped-colorpicker',
          checkField: 'dashboardButtonStyle1',
          checkValue: 2,
          name: 'dashboardButtonGradientGroup1',
          width: 4,
          pickers: [
            {
              name: 'dashboardButtonGradientColor1',
              label: 'Gradient Color 1',
              defaultValue: '#00C93C',
              required: false,
              disabled: !formData?.enableDashboardSettings,
            },
            {
              name: 'dashboardButtonGradientColor2',
              label: 'Gradient Color 2',
              defaultValue: '#00C6DD',
              required: false,
              disabled: !formData?.enableDashboardSettings,
            },
          ],
        },
        {
          type: 'conditional',
          checkField: 'dashboardButtonStyle1',
          checkNotValues: [3, 2],
          checkPopulated: true,
          targetType: 'spacer',
          name: 'dashboardSpacer1',
        },
        {
          type: isRedirectToUrlBtn(formData?.dashboardButtonAction1) ? 'text' : 'hidden',
          name: 'dashboardButtonUrl1',
          label: 'Redirect URL',
          required: isRedirectToUrlBtn(formData?.dashboardButtonAction1),
          width: 4,
          maxLength: 100,
          readOnly: !formData?.enableDashboardSettings,
          pattern: dashboardButtonUrlPattern,
        },
        {
          type: isRedirectToUrlBtn(formData?.dashboardButtonAction1) ? 'spacer' : 'hidden',
          marginBottom: 20,
        },
        {
          type: 'toggle',
          name: 'dashboardButtonEnabled1',
          label: 'Button Enable',
          required: false,
          width: 12,
          labelPlacement: 'end',
          readOnly: !formData?.enableDashboardSettings,
        },
        {
          type: 'toggle',
          name: 'dashboardButtonVisible1',
          label: 'Button Visible',
          required: false,
          width: 12,
          labelPlacement: 'end',
          readOnly: !formData?.enableDashboardSettings,
        },
        {
          type: 'text',
          name: 'dashboardCardTitle1',
          label: 'Card Title',
          required: false,
          width: 4,
          maxLength: 15,
          readOnly: !formData?.enableDashboardSettings,
          variant: 'outlined',
        },
        {
          type: 'html',
          name: 'dashboardCardBody1',
          label: 'Card Body',
          required: false,
          width: 8,
          maxLength: 140,
          disabled: !formData?.enableDashboardSettings,
        },
        {
          type: 'divider',
          width: 12,
        },
        {
          type: 'header',
          label: 'Secondary Dashboard Card',
        },
        {
          type: 'autocomplete',
          name: 'dashboardButtonAction2',
          label: 'Button Action',
          // required: false,
          width: 4,
          enum: dashboardButtonActionMapping,
          labelField: 'label',
          valueField: 'value',
          disableClear: true,
          required: !!formData.dashboardButtonTitle2,
          readOnly: !formData?.enableDashboardSettings,
          variant: 'outlined',
        },
        {
          type: 'autocomplete',
          name: 'dashboardButtonStyle2',
          label: 'Button Style',
          required: false,
          width: 4,
          enum: dashboardButtonStyleMapping,
          labelField: 'label',
          valueField: 'value',
          initialValue: 0,
          disableClear: true,
          description: 'Button can include an image background or colored background with optional gradient.',
          readOnly: !formData?.enableDashboardSettings,
          variant: 'outlined',
        },
        {
          type: 'conditional',
          checkField: 'dashboardButtonStyle2',
          checkValue: 3,
          targetType: 'imageCrop',
          name: 'dashboardButtonImageURL2',
          label: 'Image',
          required: false,
          width: 4,
          imageWidth: 684,
          imageHeight: 528,
          acceptedFiles: ['image/jpeg', 'image/png'],
          fileTypeCaption: 'Allowed file types: PNG and JPG',
          readOnly: !formData?.enableDashboardSettings,
        },
        {
          type: 'conditional',
          targetType: 'grouped-colorpicker',
          checkField: 'dashboardButtonStyle2',
          checkValue: 2,
          name: 'dashboardButtonGradientGroup2',
          width: 4,
          pickers: [
            {
              name: 'dashboardButtonGradientColor3',
              label: 'Gradient Color 1',
              defaultValue: '#00C93C',
              required: false,
              disabled: !formData?.enableDashboardSettings,
            },
            {
              name: 'dashboardButtonGradientColor4',
              label: 'Gradient Color 2',
              defaultValue: '#00C6DD',
              required: false,
              disabled: !formData?.enableDashboardSettings,
            },
          ],
        },
        {
          type: 'conditional',
          checkField: 'dashboardButtonStyle2',
          checkNotValues: [3, 2],
          checkPopulated: true,
          targetType: 'spacer',
          name: 'dashboardSpacer2',
        },
        {
          type: isRedirectToUrlBtn(formData?.dashboardButtonAction2) ? 'text' : 'hidden',
          name: 'dashboardButtonUrl2',
          label: 'Redirect URL',
          required: isRedirectToUrlBtn(formData?.dashboardButtonAction2),
          width: 4,
          maxLength: 100,
          readOnly: !formData?.enableDashboardSettings,
          pattern: dashboardButtonUrlPattern,
        },
        {
          type: isRedirectToUrlBtn(formData?.dashboardButtonAction2) ? 'spacer' : 'hidden',
          marginBottom: 20,
        },
        {
          type: 'toggle',
          name: 'dashboardButtonEnabled2',
          label: 'Button Enable',
          required: false,
          width: 12,
          labelPlacement: 'end',
          readOnly: !formData?.enableDashboardSettings,
        },
        {
          type: 'toggle',
          name: 'dashboardButtonVisible2',
          label: 'Button Visible',
          width: 12,
          labelPlacement: 'end',
          readOnly: !formData?.enableDashboardSettings,
        },
        {
          type: 'text',
          name: 'dashboardCardTitle2',
          label: 'Card Title',
          required: false,
          width: 4,
          maxLength: 15,
          readOnly: !formData?.enableDashboardSettings,
          variant: 'outlined',
        },
        {
          type: 'html',
          name: 'dashboardCardBody2',
          label: 'Card Body',
          required: false,
          width: 8,
          maxLength: 140,
          disabled: !formData?.enableDashboardSettings,
        },

        {
          type: 'divider',
          width: 12,
        },
        {
          type: 'header',
          label: 'Tertiary Dashboard Card',
        },
        {
          type: 'autocomplete',
          name: 'dashboardButtonAction3',
          label: 'Button Action',
          // required: false,
          width: 4,
          enum: dashboardButtonActionMapping,
          labelField: 'label',
          valueField: 'value',
          disableClear: true,
          required: !!formData.dashboardButtonTitle3,
          readOnly: !formData?.enableDashboardSettings,
          variant: 'outlined',
        },
        {
          type: 'autocomplete',
          name: 'dashboardButtonStyle3',
          label: 'Button Style',
          required: false,
          width: 4,
          enum: dashboardButtonStyleMapping,
          labelField: 'label',
          valueField: 'value',
          initialValue: 0,
          disableClear: true,
          description: 'Button can include an image background or colored background with optional gradient.',
          readOnly: !formData?.enableDashboardSettings,
          variant: 'outlined',
        },
        {
          type: 'conditional',
          checkField: 'dashboardButtonStyle3',
          checkValue: 3,
          targetType: 'imageCrop',
          name: 'dashboardButtonImageURL3',
          label: 'Image',
          required: false,
          width: 4,
          imageWidth: 684,
          imageHeight: 528,
          acceptedFiles: ['image/jpeg', 'image/png'],
          fileTypeCaption: 'Allowed file types: PNG and JPG',
          readOnly: !formData?.enableDashboardSettings,
        },
        {
          type: 'conditional',
          targetType: 'grouped-colorpicker',
          checkField: 'dashboardButtonStyle3',
          checkValue: 2,
          name: 'dashboardButtonGradientGroup3',
          width: 4,
          pickers: [
            {
              name: 'dashboardButtonGradientColor5',
              label: 'Gradient Color 1',
              defaultValue: '#00C93C',
              required: false,
              disabled: !formData?.enableDashboardSettings,
            },
            {
              name: 'dashboardButtonGradientColor6',
              label: 'Gradient Color 2',
              defaultValue: '#00C6DD',
              required: false,
              disabled: !formData?.enableDashboardSettings,
            },
          ],
        },
        {
          type: 'conditional',
          checkField: 'dashboardButtonStyle3',
          checkNotValues: [3, 2],
          checkPopulated: true,
          targetType: 'spacer',
          name: 'dashboardSpacer3',
        },
        {
          type: isRedirectToUrlBtn(formData?.dashboardButtonAction3) ? 'text' : 'hidden',
          name: 'dashboardButtonUrl3',
          label: 'Redirect URL',
          required: isRedirectToUrlBtn(formData?.dashboardButtonAction3),
          width: 4,
          maxLength: 100,
          readOnly: !formData?.enableDashboardSettings,
          pattern: dashboardButtonUrlPattern,
        },
        {
          type: isRedirectToUrlBtn(formData?.dashboardButtonAction3) ? 'spacer' : 'hidden',
          marginBottom: 20,
        },
        {
          type: 'toggle',
          name: 'dashboardButtonEnabled3',
          label: 'Button Enable',
          required: false,
          width: 12,
          labelPlacement: 'end',
          readOnly: !formData?.enableDashboardSettings,
        },
        {
          type: 'toggle',
          name: 'dashboardButtonVisible3',
          label: 'Button Visible',
          width: 12,
          labelPlacement: 'end',
          readOnly: !formData?.enableDashboardSettings,
        },
        {
          type: 'text',
          name: 'dashboardCardTitle3',
          label: 'Card Title',
          required: false,
          width: 4,
          maxLength: 15,
          readOnly: !formData?.enableDashboardSettings,
          variant: 'outlined',
        },
        {
          type: 'html',
          name: 'dashboardCardBody3',
          label: 'Card Body',
          required: false,
          width: 8,
          maxLength: 140,
          disabled: !formData?.enableDashboardSettings,
        },

        {
          type: 'divider',
          width: 12,
        },
        {
          type: 'header',
          label: 'Recent Care Services Dashboard Card',
        },
        {
          type: 'toggle',
          name: 'dashboardEnableTestOrders',
          label: 'Enable Test Orders',
          required: false,
          width: 4,
          labelPlacement: 'end',
          readOnly: !formData?.enableDashboardSettings,
        },
        {
          type: 'divider',
          width: 12,
        },
        {
          type: 'header',
          label: 'RecentTest History Dashboard Card',
        },
        {
          type: 'toggle',
          name: 'dashboardEnableTestHistory',
          label: 'Enable Test History',
          required: false,
          width: 4,
          labelPlacement: 'end',
          readOnly: !formData?.enableDashboardSettings,
        },
      ],
    },

    {
      // type: !!isDefault ? "hidden" : "group",
      type: "group",
      label: "FOOTER NAVIGATION",
      name: "FOOTER_NAVIGATION",
      //defaultExpanded: false,
      fields: [
        {
          type: "text",
          name: "menuItem001",
          label: "Menu Link 1*",
          required: false,
          width: 3,
          maxLength: 15,
          initialValue: 'Home',
          readOnly: true,
          variant: "outlined",
        },
        {
          type: "autocomplete",
          name: "menuItem02",
          label: "Menu Link 2*",
          required: true,
          width: 3,
          enum: Object.values(footerNavigationMapping),
          labelField: "label",
          valueField: "value",
          disableClear: true,
          // readOnly: !formData?.enabled,
          allowZeroVal: true,
          variant: "outlined",
          disableOptions: [formData?.menuItem02, formData?.menuItem03, formData?.menuItem04].filter(i => !!i),
        },
        {
          type: "autocomplete",
          name: "menuItem03",
          label: "Menu Link 3*",
          required: true,
          width: 3,
          enum: Object.values(footerNavigationMapping),
          labelField: "label",
          valueField: "value",
          disableClear: true,
          // readOnly: !formData?.enabled,
          allowZeroVal: true,
          variant: "outlined",
          disableOptions: [formData?.menuItem02, formData?.menuItem03, formData?.menuItem04].filter(i => !!i),
        },
        {
          type: "autocomplete",
          name: "menuItem04",
          label: "Menu Link 4*",
          required: true,
          width: 3,
          enum: Object.values(footerNavigationMapping),
          labelField: "label",
          valueField: "value",
          disableClear: true,
          // readOnly: !formData?.enabled,
          allowZeroVal: true,
          variant: "outlined",
          disableOptions: [formData?.menuItem02, formData?.menuItem03, formData?.menuItem04].filter(i => !!i),
        },
      ],
    },

    {
      type: 'group',
      label: 'VACCINATION RECORD SETTINGS',
      name: 'VACCINATION_RECORD_SETTINGS',
      fields: [
        {
          type: 'toggle',
          name: 'enableVaccinationRecord',
          label: 'Enable Vaccination Record',
          required: false,
          width: 12,
          labelPlacement: 'end',
          readOnly: !currentNetwork?.verifyVaccinationSettings.enableVaccinationRecordControl,
        },
        {
          type: 'toggle',
          name: 'enableVaccinationAttestation',
          label: 'Enable Vaccination Attestation Flow',
          required: false,
          width: 12,
          labelPlacement: 'end',
          customPaddingLeft: 20,
          readOnly:
            !formData?.enableVaccinationRecord ||
            !currentNetwork?.verifyVaccinationSettings.enableVaccinationRecordControl,
        },
        {
          type: 'toggle',
          name: 'enableVaccinationScanCredentials',
          label: 'Enable Vaccination Scan Credentials Flow',
          required: false,
          width: 12,
          labelPlacement: 'end',
          customPaddingLeft: 20,
          readOnly:
            !formData?.enableVaccinationRecord ||
            !currentNetwork?.verifyVaccinationSettings.enableVaccinationRecordControl,
        },
        {
          type: 'toggle',
          name: 'enableVaccinationImportCredentials',
          label: 'Enable Import Credentials Flow',
          required: false,
          width: 12,
          labelPlacement: 'end',
          customPaddingLeft: 20,
          readOnly:
            !formData?.enableVaccinationRecord ||
            !currentNetwork?.verifyVaccinationSettings.enableVaccinationRecordControl,
        },
        {
          type: 'toggle',
          name: 'enableVaccinationViaPatientPortal',
          label: 'Enable Vaccination Via Patient Portal Flow',
          required: false,
          width: 12,
          labelPlacement: 'end',
          customPaddingLeft: 20,
          readOnly:
            !formData?.enableVaccinationRecord ||
            !currentNetwork?.verifyVaccinationSettings.enableVaccinationRecordControl,
        },
        {
          type: 'toggle',
          name: 'enableVaccinationConfirmWithProvider',
          label: 'Enable Vaccination Confirm With Provider Flow',
          required: false,
          width: 12,
          labelPlacement: 'end',
          customPaddingLeft: 20,
          readOnly:
            !formData?.enableVaccinationRecord ||
            !currentNetwork?.verifyVaccinationSettings.enableVaccinationRecordControl,
        },
        {
          type: 'toggle',
          name: 'enableVaccinationAppleHealthKit',
          label: 'Enable Vaccination Apple Health Kit Flow',
          required: false,
          width: 12,
          labelPlacement: 'end',
          customPaddingLeft: 20,
          readOnly:
            !formData?.enableVaccinationRecord ||
            !currentNetwork?.verifyVaccinationSettings.enableVaccinationRecordControl,
        },
        // {
        //   type: 'toggle',
        //   name: 'enableVaccinationShare',
        //   label: 'Enable Vaccination Share Flow',
        //   required: false,
        //   width: 12,
        //   labelPlacement: 'end',
        //   customPaddingLeft: 20,
        //   readOnly:
        //     !currentNetwork?.verifyVaccinationSettings.enableVaccinationRecord ||
        //     !currentNetwork?.verifyVaccinationSettings.enableVaccinationRecordControl,
        // },

        {
          type: 'divider',
          width: 12,
        },

        // Verify Vaccination Settings
        {
          type: 'header',
          label: 'Tenant Specific Values for Verify Vaccination Settings',
          description: `The inputs below will override any default values for the verify vaccination settings`,
        },
        // STRING INPUT SETTINGS
        {
          type: 'text',
          name: 'verifyVaccinationAttestationTitle',
          label: 'Vaccination Attestation Title',
          required: false,
          customPaddingLeft: 20,
          width: 4,
          readOnly:
            !formData?.enableVaccinationRecord ||
            !currentNetwork?.verifyVaccinationSettings.enableVaccinationSettingsControl,
          variant: 'outlined',
        },
        {
          type: 'text',
          name: 'verifyVaccinationAttestationSubTitle',
          label: 'Vaccination Attestation Subtitle',
          required: false,
          customPaddingLeft: 20,
          width: 4,
          readOnly:
            !formData?.enableVaccinationRecord ||
            !currentNetwork?.verifyVaccinationSettings.enableVaccinationSettingsControl,
          variant: 'outlined',
        },
        {
          type: 'blank',
          required: false,
          width: 4,
        },

        {
          type: 'text',
          name: 'verifyVaccinationScanCredentialsTitle',
          label: 'Vaccination Scan Credentials Title',
          required: false,
          customPaddingLeft: 20,
          width: 4,
          readOnly:
            !formData?.enableVaccinationRecord ||
            !currentNetwork?.verifyVaccinationSettings.enableVaccinationSettingsControl,
          variant: 'outlined',
        },
        {
          type: 'text',
          name: 'verifyVaccinationScanCredentialsSubTitle',
          label: 'Vaccination Scan Credentials Subtitle',
          required: false,
          customPaddingLeft: 20,
          width: 4,
          readOnly:
            !formData?.enableVaccinationRecord ||
            !currentNetwork?.verifyVaccinationSettings.enableVaccinationSettingsControl,
          variant: 'outlined',
        },
        {
          type: 'blank',
          required: false,
          width: 4,
        },
        {
          type: 'text',
          name: 'verifyVaccinationImportCredentialsTitle',
          label: 'Import Scan Credentials Title',
          required: false,
          width: 4,
          readOnly:
            !formData?.enableVaccinationRecord ||
            !currentNetwork?.verifyVaccinationSettings.enableVaccinationSettingsControl,
          variant: 'outlined',
        },
        {
          type: 'text',
          name: 'verifyVaccinationImportCredentialsSubTitle',
          label: 'Import Scan Credentials Subtitle',
          required: false,
          width: 4,
          readOnly:
            !formData?.enableVaccinationRecord ||
            !currentNetwork?.verifyVaccinationSettings.enableVaccinationSettingsControl,
          variant: 'outlined',
        },
        {
          type: 'blank',
          required: false,
          width: 4,
        },

        // Via Patient Portal Input Strings
        {
          type: 'text',
          name: 'verifyVaccinationViaPatienPortalTitle',
          label: 'Via Patient Portal Title',
          required: false,
          width: 4,
          readOnly:
            !formData?.enableVaccinationRecord ||
            !currentNetwork?.verifyVaccinationSettings.enableVaccinationSettingsControl,
          variant: 'outlined',
        },

        {
          type: 'text',
          name: 'verifyVaccinationViaPatienPortalSubtitle',
          label: 'Via Patient Portal Subtitle',
          required: false,
          width: 4,
          readOnly:
            !formData?.enableVaccinationRecord ||
            !currentNetwork?.verifyVaccinationSettings.enableVaccinationSettingsControl,
          variant: 'outlined',
        },
        {
          type: 'blank',
          required: false,
          width: 4,
        },
        // Confirm with Provider Input Strings
        {
          type: 'text',
          name: 'verifyVaccinationConfirmWithProviderTitle',
          label: 'Confirm with Provider Title',
          required: false,
          width: 4,
          readOnly:
            !formData?.enableVaccinationRecord ||
            !currentNetwork?.verifyVaccinationSettings.enableVaccinationSettingsControl,
          variant: 'outlined',
        },
        {
          type: 'text',
          name: 'verifyVaccinationConfirmWithProviderSubtitle',
          label: 'Confirm with Provider Subtitle',
          required: false,
          width: 4,
          readOnly:
            !formData?.enableVaccinationRecord ||
            !currentNetwork?.verifyVaccinationSettings.enableVaccinationSettingsControl,
          variant: 'outlined',
        },
        {
          type: 'blank',
          required: false,
          width: 4,
        },
        // Apple Health Kit Input Strings
        {
          type: 'text',
          name: 'verifyVaccinationAppleHealthKitTitleTenant',
          label: 'Apple Health Kit Title',
          required: false,
          width: 4,
          readOnly:
            !formData?.enableVaccinationRecord ||
            !currentNetwork?.verifyVaccinationSettings.enableVaccinationSettingsControl,
          variant: 'outlined',
        },
        {
          type: 'text',
          name: 'verifyVaccinationAppleHealthKitSubtitleTenant',
          label: 'Apple Health Kit Subtitle',
          required: false,
          width: 4,
          readOnly:
            !formData?.enableVaccinationRecord ||
            !currentNetwork?.verifyVaccinationSettings.enableVaccinationSettingsControl,
          variant: 'outlined',
        },
        {
          type: 'blank',
          required: false,
          width: 4,
        },
      ],
    },
    {
      type: 'group',
      label: 'WELCOME SCREEN SETTINGS',
      name: 'WELCOME_SCREEN_SETTINGS',
      fields: [
        // {
        //   type: "customComponent",
        //   value: (
        //     <DashboardSettingWrapper
        //       currentTenant={currentTenant}
        //       currentNetwork={currentNetwork}
        //       key={"WELCOME_SCREEN_SETTINGS"}
        //     />
        //   ),
        // },
        {
          type: 'header',
          label: 'Welcome Screen Button',
        },
        {
          type: 'autocomplete',
          name: 'welcomeScreenButtonAction',
          label: 'Button Action',
          // required: false,
          width: 4,
          enum: welcomeScreenButtonActionMapping,
          labelField: 'label',
          valueField: 'value',
          disableClear: true,
          required: !!formData.welcomeScreenButtonTitle,
          variant: 'outlined',
        },
        {
          type: 'autocomplete',
          name: 'welcomeScreenButtonStyle',
          label: 'Button Style',
          required: false,
          width: 4,
          enum: welcomeScreenButtonStyleMapping,
          labelField: 'label',
          valueField: 'value',
          initialValue: 0,
          disableClear: true,
          description: 'Button can be solid blue or white with blue outline',
          variant: 'outlined',
        },
        {
          type: 'toggle',
          name: 'welcomeScreenButtonEnabled',
          label: 'Button Enable',
          required: false,
          width: 12,
          labelPlacement: 'end',
        },
        {
          type: 'toggle',
          name: 'welcomeScreenButtonVisible',
          label: 'Button Visible',
          required: false,
          width: 12,
          labelPlacement: 'end',
        },
        {
          type: 'header',
          label: 'Welcome Screen Skip Button',
          description: 'Skip to next screen in the flow',
        },
        {
          type: 'autocomplete',
          name: 'welcomeScreenSkipButtonAction',
          label: 'Button Content',
          // required: false,
          width: 4,
          enum: welcomeScreenSkipButtonContentMapping,
          labelField: 'label',
          valueField: 'value',
          disableClear: true,
          required: !!formData.welcomeScreenSkipButtonTitle,
          variant: 'outlined',
        },
        {
          type: 'toggle',
          name: 'welcomeScreenSkipButtonEnabled',
          label: 'Button Enable',
          required: false,
          width: 12,
          labelPlacement: 'end',
        },
        {
          type: 'toggle',
          name: 'welcomeScreenSkipButtonVisible',
          label: 'Button Visible',
          width: 12,
          labelPlacement: 'end',
        },
      ],
    },
    {
      type: 'group',
      label: 'APP SETTINGS',
      name: 'APP_SETTINGS',
      fields: [
        {
          type: 'toggle',
          name: 'isEmergencyContactInfoEnabled',
          label: 'Enable Emergency Contact',
          required: false,
          width: 4,
          labelPlacement: 'end',
        },
        {
          type: 'toggle',
          name: 'showQRCode',
          label: 'Show QR Code on Home Screen',
          required: false,
          width: 4,
          labelPlacement: 'end',
        },
        {
          type: 'toggle',
          name: 'showClearanceStatusHomeScreen',
          label: 'Show Clearance Status on Home Screen',
          required: false,
          width: 4,
          labelPlacement: 'end',
        },
        {
          type: 'toggle',
          name: 'enableImportHealthRecord',
          label: 'Enable Health Record Module',
          required: false,
          width: 4,
          labelPlacement: 'end',
        },
        {
          type: 'toggle',
          name: 'requireIdVerification',
          label: 'Require ID Verification',
          required: false,
          width: 4,
          labelPlacement: 'end',
        },
        // Functionality moved to Platform / TestOrderable
        // {
        //   type: "toggle",
        //   name: "bypassMLModel",
        //   label: "Bypass ML Model",
        //   required: false,
        //   width: 4,
        //   labelPlacement: "end",
        // },
        {
          type: 'toggle',
          name: 'enableSnackbar',
          label: 'Enable Snackbar',
          required: false,
          width: 4,
          labelPlacement: 'end',
        },
        {
          type: 'toggle',
          name: 'showTestResultOutcome',
          label: 'Show Test Result Outcome',
          required: false,
          width: 4,
          labelPlacement: 'end',
          defaultValue: true,
        },
        {
          type: 'toggle',
          name: 'showVCAssessmentOutcome',
          label: 'Show VCB Assessment Outcome',
          required: false,
          width: 4,
          labelPlacement: 'end',
          defaultValue: true,
        },
        {
          type: 'toggle',
          name: 'showHomeScreenBackgroundColor',
          label: 'Show Home Screen Background Color',
          required: false,
          width: 4,
          labelPlacement: 'end',
          defaultValue: true,
        },
        {
          type: 'toggle',
          name: 'showLastAssessmentDate',
          label: 'Show Last Assessment Date',
          required: false,
          width: 4,
          labelPlacement: 'end',
          defaultValue: true,
        },
        {
          type: 'toggle',
          name: 'isSAFEPassportEnabled',
          label: 'Enable SAFE Passport',
          required: false,
          width: 4,
          labelPlacement: 'end',
          defaultValue: false,
        },
        {
          type: 'toggle',
          name: 'enableFaceIdAndTouchId',
          label: 'Enable Passcode & FaceId/Touch ID',
          required: false,
          width: 4,
          labelPlacement: 'end',
          defaultValue: false,
        },
        {
          type: 'toggle',
          name: 'useCustomPasswordExpirationValue',
          label: 'Set password expiration',
          required: false,
          width: 4,
          labelPlacement: 'end',
          defaultValue: false,
          fields: [
            {
              type: 'number',
              name: 'passwordExpiration',
              label: 'Require password change after',
              required: false,
              width: 7,
              labelPlacement: 'start',
              min: 1,
              max: 365,
              defaultValue: 365,
              readOnly: !formData.useCustomPasswordExpirationValue,
              placeholder:
                !formData.useCustomPasswordExpirationValue && !formData.passwordExpiration
                  ? 364
                  : formData.passwordExpiration,
              unit: 'days',
              variant: 'outlined',
            },
          ],
        },
        {
          type: 'toggle',
          name: 'enableZendesk',
          label: 'Enable Zendesk',
          required: false,
          width: 4,
          labelPlacement: 'end',
          defaultValue: false,
        },
        {
          type: 'blank',
          width: 8,
        },
      ],
    },
    {
      type: 'group',
      label: 'SCHEDULING SETTINGS',
      name: 'SCHEDULING_SETTINGS',
      fields: [
        {
          type: 'toggle',
          name: 'enableScheduleAppointment',
          label: 'Enable Schedule Appointment',
          required: false,
          width: 4,
          labelPlacement: 'end',
          readOnly: !formData?.enableScheduleAppointmentControl,
        },
      ],
    },
    {
      type: 'group',
      label: 'MULTI FACTOR AUTHENTICATION',
      name: 'MULTI_FACTOR_AUTHENTICATION',
      fields: [
        {
          type: 'sub-group',
          width: 6,
          fields: [
            {
              type: 'header',
              label: 'MFA Settings',
            },
            {
              type: 'toggle',
              name: 'enableDeviceRemembering',
              label: 'Enable Device Remembering',
              required: false,
              width: 12,
              labelPlacement: 'end',
              readOnly: !formData?.allowTenantMfaConfiguration,
            },
            {
              type: 'toggle',
              name: 'enableSMSVerification',
              label: 'Enable SMS Verification',
              required: false,
              width: 12,
              labelPlacement: 'end',
              readOnly: !formData?.allowTenantMfaConfiguration,
            },
            {
              type: 'toggle',
              name: 'enableGoogleAuth',
              label: 'Enable Third Party Authenticator Application Verification',
              required: false,
              width: 12,
              labelPlacement: 'end',
              readOnly: !formData?.allowTenantMfaConfiguration,
            },

            // {
            //   type: 'toggle',
            //   name: 'enableOneTimePassword',
            //   label: 'Enable Time Based One-Time Password',
            //   required: false,
            //   width: 12,
            //   labelPlacement: 'end',
            //   readOnly: !formData?.allowTenantMfaConfiguration,
            // },
          ],
        },

        {
          type: 'sub-group',
          width: 6,
          fields: [
            {
              type: 'header',
              label: 'MFA Expiration Settings',
              description:
                'Enter the number of days before MFA is required for each login. The value should be ' +
                'between 0 and 365, with 0 meaning that MFA is required every time you log in',
            },
            // {
            //   type: 'number',
            //   name: 'healthAdministratorDays',
            //   title: 'Health Administrator',
            //   titlePlacement: 'start',
            //   label: 'Expire MFA after',
            //   min: 0,
            //   max: 365,
            //   defaultValue: 365,
            //   placeholder: 365,
            //   unit: 'days',
            //   required: false,
            //   disabled: !formData.enableDeviceRemembering,
            //   variant: 'outlined',
            //   readOnly: !formData?.allowTenantMfaConfiguration || !formData.enableDeviceRemembering,
            // },
            // {
            //   type: 'number',
            //   name: 'tenantAdministratorDays',
            //   title: 'Tenant Administrator',
            //   titlePlacement: 'start',
            //   label: 'Expire MFA after',
            //   min: 0,
            //   max: 365,
            //   defaultValue: 365,
            //   placeholder: 365,
            //   unit: 'days',
            //   required: false,
            //   disabled: !formData.enableDeviceRemembering,
            //   variant: 'outlined',
            //   readOnly: !formData?.allowTenantMfaConfiguration || !formData.enableDeviceRemembering,
            // },
            {
              type: 'number',
              name: 'healthCheckerDays',
              title: 'Health Checker',
              titlePlacement: 'start',
              label: 'Expire MFA after',
              min: 0,
              max: 365,
              defaultValue: 120,
              placeholder: 120,
              unit: 'days',
              required: false,
              // disabled: !formData.enableDeviceRemembering,
              variant: 'outlined',
              readOnly: !formData?.allowTenantMfaConfiguration || !formData.enableDeviceRemembering,
            },
            {
              type: 'number',
              name: 'memberDays',
              title: 'Member',
              titlePlacement: 'start',
              label: 'Expire MFA after',
              min: 0,
              max: 365,
              defaultValue: 120,
              placeholder: 120,
              unit: 'days',
              required: false,
              // disabled: !formData.enableDeviceRemembering,
              variant: 'outlined',
              readOnly: !formData?.allowTenantMfaConfiguration || !formData.enableDeviceRemembering,
            },
          ],
        },
      ],
    },
    {
      type: 'group',
      label: 'IMPORT HEALTH SETTINGS',
      name: 'IMPORT_EALTH_SETTINGS',
      fields: [
        {
          type: 'toggle',
          name: 'importHealthRequireSignature',
          label: 'Import Health Require Signature',
          required: false,
          width: 4,
          labelPlacement: 'end',
          defaultValue: true,
        },
        {
          type: 'toggle',
          name: 'importHealthRequireIDVerification',
          label: 'Import Health Require ID Verification',
          required: false,
          width: 4,
          labelPlacement: 'end',
          defaultValue: true,
        },
        {
          type: 'toggle',
          name: 'importHealthRequireAgreementScreen',
          label: 'Import Health Require AgreementScreen',
          required: false,
          width: 4,
          labelPlacement: 'end',
          defaultValue: true,
        },
      ],
    },

    // SAFEPASS SETTINGS
    // TODO: Integration with Backend
    {
      type: 'group',
      label: 'SAFEPASS SETTINGS',
      name: 'SAFEPASS_SETTINGS',
      fields: [
        {
          type: 'toggle',
          name: 'enableSafePass',
          label: 'Enable SafePass Feature',
          required: false,
          width: 12,
          labelPlacement: 'end',
          customPaddingLeft: 20,
          readOnly: !currentNetwork?.enableSafePassControl,
        },
      ],
    },

    {
      type: 'group',
      label: 'SOCIAL NETWORK',
      name: 'SOCIAL_NETWORK',
      fields: [
        {
          type: 'text',
          name: 'facebook',
          label: 'Facebook',
          required: false,
          width: 4,
          variant: 'outlined',
        },
        {
          type: 'text',
          name: 'instagram',
          label: 'Instagram',
          required: false,
          width: 4,
          variant: 'outlined',
        },
        {
          type: 'text',
          name: 'linkedin',
          label: 'LinkedIn',
          required: false,
          width: 4,
          variant: 'outlined',
        },
        {
          type: 'text',
          name: 'youtube',
          label: 'YouTube',
          required: false,
          width: 4,
          variant: 'outlined',
        },
        {
          type: 'text',
          name: 'twitter',
          label: 'Twitter',
          required: false,
          width: 4,
          variant: 'outlined',
        },
        {
          type: 'text',
          name: 'website',
          label: 'Website',
          required: false,
          width: 4,
          variant: 'outlined',
        },
        {
          type: 'text',
          name: 'blog',
          label: 'Blog',
          required: false,
          width: 4,
          variant: 'outlined',
        },
      ],
    },
    {
      type: 'group',
      label: 'COMMUNICATION SETTINGS',
      name: 'COMMUNICATION_ADVANCED',
      fields: [
        {
          type: 'toggle',
          name: 'sendSMS',
          label: 'Enable SMS Notifications',
          required: false,
          width: 4,
          labelPlacement: 'end',
        },
        {
          type: 'toggle',
          name: 'sendEmail',
          label: 'Enable Email Notifications',
          required: false,
          width: 4,
          labelPlacement: 'end',
        },
        {
          type: 'toggle',
          name: 'safeNotificationsEnabled',
          label: 'Enable App Messenger Notifications',
          required: false,
          width: 4,
          labelPlacement: 'end',
        },
        {
          type: 'toggle',
          name: 'pushNotificationsEnabled',
          label: 'Enable Push Notifications',
          required: false,
          width: 4,
          labelPlacement: 'end',
        },
      ],
    },
    {
      type: 'group',
      label: 'UI SETTINGS',
      name: 'UI_SETTINGS',
      fields: [
        {
          type: 'colorpicker',
          name: 'appThemeColor',
          label: 'Primary Theme Color',
          description: 'This will be the most common color used throughout your mobile and web apps.',
          defaultValue: '#2E5266',
          required: false,
          width: 4,
        },
        {
          type: 'colorpicker',
          name: 'secondaryColor',
          label: 'Secondary Theme Color',
          description: 'This will be the secondary color used throughout your mobile and web apps.',
          defaultValue: '#2E5266',
          required: false,
          width: 4,
        },
        {
          type: 'colorpicker',
          name: 'errorColor',
          label: 'Error Message Color',
          description: 'This color will be used for messages that contain "error" or other "negative" messages.',
          required: false,
          defaultValue: '#FF6464',
          width: 4,
        },
        {
          type: 'colorpicker',
          name: 'successColor',
          label: 'Success Message Color',
          description: 'This color will be used for messages that contain "successful" or other "positive" messages.',
          defaultValue: '#76D873',
          required: false,
          width: 4,
        },
        {
          type: 'colorpicker',
          name: 'warningColor',
          label: 'Warning Message Color',
          description: 'This color will be used for messages that contain "warning" or other "neutral" messages.',
          defaultValue: '#FA9C13',
          required: false,
          width: 4,
        },
        {
          type: 'autocomplete',
          name: 'buttonStyle',
          label: 'Button Style',
          required: false,
          width: 4,
          enum: buttonStyleMapping.map((k) => ({
            value: k.value,
            label: k.label,
          })),
          labelField: 'label',
          valueField: 'value',
          initialValue: 0,
          disableClear: true,
          description: 'Button corners can either be square or rounded.',
        },
        {
          type: 'autocomplete',
          name: 'homeScreenStyle',
          label: 'Home Screen Style',
          required: false,
          width: 4,
          enum: homeScreenStyleMapping.map((k) => ({
            value: k.value,
            label: k.label,
          })),
          labelField: 'label',
          valueField: 'value',
          disableClear: true,
          initialValue: 'dashboardStyle',
          variant: 'outlined',
        },
      ],
    },
    {
      type: 'group',
      label: 'USER SEGMENTS SETTINGS',
      name: 'USER_SEGMENTS_SETTINGS',
      fields: [
        {
          type: 'customComponent',
          value: <UserSegmentsListWrapper currentTenant={currentTenant} key={'USER_SEGMENTS_SETTINGS'} />,
        },
      ],
    },
    {
      type: 'group',
      label: 'CUSTOM METADATA SETTINGS',
      name: 'CUSTOM_METADATA_SETTINGS',
      fields: [
        {
          type: 'customComponent',
          value: <MetaDataListWrapper currentTenant={currentTenant} key={'CUSTOM_METADATA_SETTINGS'} />,
        },
      ],
    },
    {
      type: 'group',
      label: 'ADVANCED SETTINGS',
      name: 'ADVANCED_ADVANCED',
      fields: [
        {
          type: isSingleTenant ? 'hidden' : 'toggle',
          name: 'root',
          label: 'Root Tenant',
          required: false,
          readOnly: currentTenant.root,
          width: 4,
          labelPlacement: 'end',
        },
        {
          type: 'toggle',
          name: 'disableSignUp',
          label: 'Invite Only (disable sign up)',
          required: false,
          width: 4,
          labelPlacement: 'end',
        },
        {
          type: 'toggle',
          name: 'showPatientRecordInAdminConsole',
          label: 'Show Patient Record',
          required: false,
          width: 4,
          labelPlacement: 'end',
        },
        {
          type: 'toggle',
          name: 'requireGuardianMode',
          label: 'Guardian mode',
          required: false,
          width: 4,
          labelPlacement: 'end',
        },
        {
          type: 'divider',
          width: 12,
        },
        {
          type: 'number',
          name: 'timeoutDuration',
          label: 'Inactivity Timeout Duration (Minutes)',
          required: false,
          width: 4,
          min: 0,
          variant: 'outlined',
        },
        {
          type: 'autocomplete',
          name: 'reportingTimezone',
          label: 'Timezone For Reporting',
          required: false,
          enum: Object.keys(timezones).map((i) => ({
            value: timezones[i].value,
            label: timezones[i].label,
          })),
          width: 4,
          disableClear: true,
          labelField: 'label',
          valueField: 'value',
          initialValue: 'UTC-04:00',
          variant: 'outlined',
        },
      ],
    },
    {
      type: 'group',
      label: 'APP DOWNLOAD SETTINGS',
      name: 'APP_DOWNLOAD_SETTINGS',
      fields: [
        {
          type: 'text',
          name: 'webSignUpUrl',
          label: 'Web SignUp Url',
          required: false,
          width: 4,
          variant: 'outlined',
        },
        {
          type: 'text',
          name: 'appleAppDownloadUrl',
          label: 'iOS App URL',
          required: false,
          width: 4,
          variant: 'outlined',
        },
        {
          type: 'image',
          name: 'webSignUpQRCodeUrl',
          label: 'QR Code',
          required: false,
          width: 2,
          variant: 'outlined',
        },
        {
          type: 'divider',
          width: 12,
        },
        {
          type: 'text',
          name: 'universalAppDownloadUrl',
          label: 'Universal App Download Url',
          required: false,
          width: 4,
          variant: 'outlined',
        },
        {
          type: 'text',
          name: 'androidAppDownloadUrl',
          label: 'Android App URL',
          required: false,
          width: 4,
          variant: 'outlined',
        },
        {
          type: 'blank',
          width: 4,
        },
        {
          type: 'image',
          name: 'universalAppDownloadQRCodeUrl',
          label: 'QR Code',
          required: false,
          width: 2,
          variant: 'outlined',
        },
      ],
    },
  ];
}

export const NetworkGraphicAssetsFormMap = [
  {
    type: 'group',
    label: 'GRAPHIC ASSETS',
    name: 'GRAPHIC_ASSETS',
    fields: [
      {
        type: 'assetsWrapper',
        name: 'networkLogoInThemeColor',
        label: 'App Icon',
        description: 'This is the icon that will be used as the phone app icon.',
        required: false,
        editable: false,
        width: 9,
        imageWidth: 1024,
        imageHeight: 1024,
        acceptedFiles: ['image/png'],
      },
      {
        type: 'divider',
      },
      {
        type: 'assetsWrapper',
        name: 'emailLogo',
        label: 'Email Image',
        description:
          'This should be a company logo, for placement in the email (eg. registration email, forgot password)',
        required: false,
        editable: false,
        width: 9,
        imageWidth: 48,
        imageHeight: 64,
        acceptedFiles: ['image/png'],
      },
      {
        type: 'divider',
      },
      {
        type: 'assetsWrapper',
        name: 'logoSquareDark',
        label: 'Square Logo (For a White Background)',
        description:
          'This should be a square version of the company logo, for placement on a white/light' +
          'background in the mobile app (eg. splash screen, login screens).',
        required: false,
        editable: false,
        width: 9,
        imageWidth: 600,
        imageHeight: 600,
        acceptedFiles: ['image/png'],
      },
      {
        type: 'divider',
      },
      {
        type: 'assetsWrapper',
        name: 'logoSquareLight',
        label: 'Square Logo (For a Dark Background)',
        description:
          'This should be a square version of the company logo, for placement on a dark background' +
          ' in the mobile app (eg. splash screen, login screens).',
        required: false,
        editable: false,
        width: 9,
        imageWidth: 600,
        imageHeight: 600,
        acceptedFiles: ['image/png'],
        backgroundColor: 'rgb(0 0 0 / 50%)',
      },
      {
        type: 'divider',
      },
      {
        type: 'assetsWrapper',
        name: 'logoRectangleDark',
        label: 'Rectangle Logo (For a White Background)',
        description:
          'This should be a rectangular-shaped version of the company logo, for placement on a ' +
          'white/light background in the mobile app (eg. section header).',
        required: false,
        editable: false,
        width: 9,
        imageWidth: 3000,
        imageHeight: 600,
        acceptedFiles: ['image/png'],
      },
      {
        type: 'divider',
      },
      {
        type: 'assetsWrapper',
        name: 'logoRectangleLight',
        label: 'Rectangle Logo (For a Dark Background)',
        description:
          'This should be a rectangular-shaped version of the company logo, for placement ' +
          'on a dark background in the mobile app (eg. section header).',
        required: false,
        editable: false,
        width: 9,
        imageWidth: 3000,
        imageHeight: 600,
        acceptedFiles: ['image/png'],
        backgroundColor: 'rgb(0 0 0 / 50%)',
      },
      {
        type: 'divider',
      },
      {
        type: 'assetsWrapper',
        name: 'loginPopupImage',
        label: 'Sign In Image',
        description: 'This should be a rectangular-shaped image for placement on the login page.',
        required: false,
        editable: false,
        width: 9,
        imageWidth: 48,
        imageHeight: 64,
        acceptedFiles: ['image/png'],
      },
      {
        type: 'divider',
      },
      {
        type: 'assetsWrapper',
        name: 'welcomeScreenImage',
        label: 'Welcome Screen',
        description: 'This should be a square-shaped image for placement on the welcome page.',
        required: false,
        editable: false,
        width: 9,
        imageWidth: 600,
        imageHeight: 600,
        acceptedFiles: ['image/png'],
      },
      {
        type: 'divider',
      },
      {
        type: 'assetsWrapper',
        name: 'favicon',
        label: 'Favicon',
        description: 'This should be a square-shaped image, used as the icon shown on the tab of a web browser.',
        required: false,
        editable: false,
        width: 9,
        imageWidth: 512,
        imageHeight: 512,
        acceptedFiles: ['image/png'],
      },
      {
        type: 'divider',
      },
      {
        type: 'assetsWrapper',
        name: 'homeScreenBanner',
        label: 'Home Screen Banner',
        description:
          'This url and image is used to direct members to a url of your choice. For example: ' +
          'you can use this to direct members to your website or if there is a member portal that ' +
          'they can log into.',
        required: false,
        editable: false,
        width: 9,
        imageWidth: 3000,
        imageHeight: 600,
        acceptedFiles: ['image/png'],
        url: {
          type: 'text',
          label: 'URL',
          name: 'homeScreenBannerUrl',
        },
      },
    ],
  },
];

export const NetworkHealthProtocolFormMap = (questionnaires) => [
  {
    type: 'group',
    label: 'VIRTUAL CONSULT (SYMPTOM CHECKER) SETTINGS',
    name: 'VIRTUAL_CONSULT_SETTINGS',
    fields: [
      {
        type: 'header',
        label: 'Questionnaire Settings',
      },
      {
        type: 'number',
        name: 'symptomsCheckInterval',
        label: 'Symptom Checker Frequency (# of Days)',
        required: false,
        width: 4,
        min: 0,
      },
      {
        type: 'autocomplete',
        name: 'defaultQuestionnaireId',
        label: 'Default Questionnaire',
        required: false,
        width: 4,
        enum: questionnaires?.content.map((k) => ({
          value: k.id,
          // set title if questionnaire does not have translation obj
          label: k?.title?.translations?.eng || (typeof k?.title === 'string' && k?.title) || 'Unnamed',
        })),
        labelField: 'label',
        valueField: 'value',
      },
      {
        type: 'divider',
      },
      {
        type: 'header',
        label: 'Reminder Settings',
        description: `This is a local notification configured on the user's mobile device.
          If any updates are made to these settings, they will get updates on the user's
          device the next time they've opened the app.`,
      },
      {
        type: 'time',
        name: 'notificationTime',
        label: 'Notification Time',
        required: false,
        width: 4,
        format: 'hh:mm a',
      },
      {
        type: 'text',
        name: 'notificationMessage',
        label: 'Notification Message',
        required: false,
        width: 8,
        rows: 1,
      },
    ],
  },
  {
    type: 'group',
    label: 'COVID-19 POSITIVE RESULT - SIDELINE SETTINGS',
    name: 'POSTIVE_RESULT_SIDELINE_SETTINGS',
    fields: [
      {
        type: 'toggle',
        name: 'sidelineUserWithPositiveTestResultEnabled',
        label: 'Sideline User with Positive Test Result (Active COVID-19)',
        required: false,
        width: 12,
        labelPlacement: 'end',
      },
      {
        type: 'number',
        name: 'sidelineUserWithPositiveTestResultForDays',
        label: 'Sideline for (days)',
        required: false,
        width: 4,
        min: 0,
      },
    ],
  },
  {
    type: 'group',
    label: 'VIRTUAL CONSULT OUTCOMES - SIDELINE SETTINGS',
    name: 'VIRTUAL_CONSULT_SIDELINE_SETTINGS',
    fields: [
      {
        type: 'header',
        label: 'Symptomatic Outcome',
      },
      {
        type: 'number',
        name: 'symptomaticOutcomeSidelineForDays',
        label: 'Sideline for (days)',
        required: false,
        width: 4,
        min: 0,
      },
      {
        type: 'header',
        label: 'Exposure Outcome',
      },
      {
        type: 'number',
        name: 'exposureOutcomeSidelineForDays',
        label: 'Sideline for (days)',
        required: false,
        width: 4,
        min: 0,
      },
    ],
  },
];
