import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

import { InfoCardSkeletonLoading } from '../Content/ContentSkeletonLoading';

/**
 * Show text info card with custom bg color
 * @param props
 */
export function OldInfoCard(props) {
  const { value, title, text, bgColor } = props;
  const classes = useStyles({ bgColor });

  if (value === 'N/A') {
    return (
      <Paper className={classes.loadingWrapper}>
        <InfoCardSkeletonLoading />
      </Paper>
    );
  }

  return (
    <Paper className={classes.root}>
      <div className={classes.value}>{value}</div>
      <div className={classes.title}>{title}</div>
      <div className={classes.text}>{text}</div>
    </Paper>
  );
}

OldInfoCard.propTypes = {
  value: PropTypes.string.isRequired,
  title: PropTypes.string,
  text: PropTypes.string,
  bgColor: PropTypes.string,
};

// Info card styles
const useStyles = makeStyles({
  root: (theme) => ({
    padding: '10px 20px 13px',
    backgroundColor: theme.bgColor || '#FFC66F',
    height: '100%',
    borderRadius: 20,
  }),
  loadingWrapper: {
    height: 168,
    backgroundColor: '#ffffff',
    borderRadius: 20,
  },
  value: {
    fontSize: 40,
    color: '#FFFFFF',
    fontFamily: 'Averta Regular',
  },
  title: {
    color: '#000000',
    fontFamily: 'Averta bold',
    fontSize: 16,
    fontWeight: 'bold',
    marginTop: 15,
  },
  text: {
    color: '#666666',
    fontFamily: 'Averta bold',
    fontSize: 14,
    marginTop: 7,
  },
});

export default OldInfoCard;
