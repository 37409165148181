/* eslint-disable react/no-array-index-key */
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateEncounterNotes } from '../Encounters/encountersSlice';
import NoteForm from './NoteForm';
import ProviderQuestionnaireWrapper from './ProviderQuestionnaireWrapper';

function NotesWrapper(props) {
  const { data } = props;
  const { encounterId, testList, notes } = data;
  const classes = useStyles();

  const [noteText, setNoteText] = useState('Subjective:\nObjective:\nAssessment:\nPlan:\nTime spent:\n');
  const [notesList, setNotesList] = useState(notes);
  const [sendingNote, setSendingNote] = useState(false);

  const dispatch = useDispatch();

  const handleSend = async () => {
    const newNoteObj = {
      text: noteText,
      providerID: data.physicianId,
      firstName: data.physicianFirstName,
      lastName: data.physicianLastName,
      email: data.physicianEmail,
    };

    setSendingNote(true);
    const success = await dispatch(updateEncounterNotes(encounterId, newNoteObj));
    setSendingNote(false);

    if (success) {
      const updatedNotesList = [...notesList];
      updatedNotesList.push(newNoteObj);
      setNotesList(updatedNotesList);
      setNoteText('');
    }
  };

  return (
    <Grid className={classes.notesWrapper}>
      <Grid container>
        <Grid style={{ padding: '0 10px', flex: 1 }} md={6}>
          <Typography className={classes.infoHeader} style={{ padding: '5px 0' }}>
            CALL: 3
          </Typography>
          <Typography className={classes.infoHeader} style={{ padding: '5px 0' }}>
            NOTES
          </Typography>

          <NoteForm noteText={noteText} setNoteText={setNoteText} sendingNote={sendingNote} onHandleSend={handleSend} />
        </Grid>

        <Divider variant="middle" orientation="vertical" flexItem />

        <Grid container direction="column" justifyContent="center" md={6} style={{ padding: '0 10px', flex: 1 }}>
          {testList &&
            testList.length &&
            testList.map((testItem, key) => (
              <Grid container style={{ paddingTop: 5 }} key={key}>
                <Typography className={classes.infoHeader}>{testItem.testName}:</Typography>
                <Typography className={classes.testStatus}>{testItem.interpretation}</Typography>
              </Grid>
            ))}
          <ProviderQuestionnaireWrapper testList={testList} />
        </Grid>
      </Grid>

      {notesList && notesList.length ? (
        <Grid style={{ padding: '30px 10px' }}>
          <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>STATUS</StyledTableCell>
                  <StyledTableCell>NOTES</StyledTableCell>
                  <StyledTableCell>DATE</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {notesList.map((row, key) => (
                  <TableRow key={key}>
                    <StyledTableCell component="th" scope="row">
                      {`${key + 1}. ${row?.status || '-'}`}
                    </StyledTableCell>
                    <StyledTableCell>{row.text}</StyledTableCell>
                    <StyledTableCell>{moment(row.created).format('DD/MM/YYYY HH:mm:ss')}</StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      ) : null}
    </Grid>
  );
}

const useStyles = makeStyles({
  notesWrapper: {
    borderRadius: 6,
    backgroundColor: '#f9f9f9',
    padding: 20,
  },
  infoHeader: {
    fontWeight: 'bold',
    fontSize: 12,
  },
  testStatus: {
    color: '#43a047',
    fontSize: 12,
  },
});

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: '#eeeff2',
  },
}))(TableCell);

export default NotesWrapper;
