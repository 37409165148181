import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/styles';
import { Checkbox as KCheckbox } from '@progress/kendo-react-inputs';
import { Tooltip as KTooltip } from '@progress/kendo-react-tooltip';
import React from 'react';

const SelectionHeaderCell = (selectIds, selectAll, data) =>
  function child(props) {
    const classes = useStyles();
    // const selectAllStatus = { selectIds, selectAll };

    const isIndeterminate =
      selectIds.length > 0 &&
      !selectAll &&
      data?.items
        ?.map((item) => ({
          selected: selectIds.indexOf(item._id) > -1,
        }))
        .findIndex((dataItem) => dataItem.selected === false) > -1;

    // If there's no data on the page(If filter is applied and there is no data displayed on the table)
    //  then uncheck the checkbox
    // const isChecked = data?.items.length > 0  ? props.selectionValue : false;
    let isChecked = false;

    if (selectAll) {
      isChecked = true;
    } else if (data?.items.length > 0) {
      isChecked = props.selectionValue;
    } else {
      isChecked = false;
    }

    return (
      <Box className={classes.checkBoxWrapper}>
        <Box className={classes.subWrapper}>
          <KTooltip title="Select All" placement="bottom" anchorElement="target" parentTitle>
            <p>
              <strong title="Select All">
                <KCheckbox
                  size="medium"
                  rounded="medium"
                  checked={isChecked}
                  onChange={props.selectionChange}
                  value={isIndeterminate ? null : undefined}
                />{' '}
              </strong>
            </p>
          </KTooltip>
        </Box>
      </Box>
    );
  };

const useStyles = makeStyles({
  checkBoxWrapper: {
    position: 'relative',
  },
  subWrapper: {
    position: 'relative',
    top: '10px',
  },
  checkBox: {},
});

export default SelectionHeaderCell;
