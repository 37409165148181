/* eslint-disable no-template-curly-in-string */

export const variables = [
  {
    key: '${patient_full_name}',
    description: 'Patient Full Name',
  },
  {
    key: '${parent_legal_guardian}',
    description: 'Parent Legal Guardian',
  },
  {
    key: '${account_name}',
    description: 'Account name',
  },
  {
    key: '${account_support_phone}',
    description: 'Account support phone number',
  },
  {
    key: '${app_icon_rectangle}',
    description: 'Tenant rectangle application icon',
  },
  {
    key: '${app_icon_square}',
    description: 'Tenant square application icon',
  },
  {
    key: '${app_name}',
    description: 'Application Name',
  },
  {
    key: '${app_url}',
    description: 'Application URL',
  },
  {
    key: '${background_image}',
    description: 'Tenant background image',
  },
  {
    key: '${company_name}',
    description: 'Company name',
  },
  {
    key: '${email_logo}',
    description: 'Email logo',
  },
  {
    key: '${favicon}',
    description: 'Tenant favicon',
  },
  {
    key: '${icon}',
    description: 'Tenant icon',
  },
  {
    key: '${logo}',
    description: 'Tenant logo',
  },
  {
    key: '${physician_fax}',
    description: 'Fax number of physician',
  },
  {
    key: '${physician_first_name}',
    description: 'First name of physician',
  },
  {
    key: '${physician_full_name}',
    description: 'Full name of physician',
  },
  {
    key: '${physician_last_name}',
    description: 'Last name of physician',
  },
  {
    key: '${physician_phone}',
    description: 'Phone number of physician',
  },
  {
    key: '${privacy_email}',
    description: 'Privacy email address',
  },
  {
    key: '${support_domain}',
    description: 'Support domain link',
  },
  {
    key: '${tenant_address_line1}',
    description: 'Tenant address line 1',
  },
  {
    key: '${tenant_address_line2}',
    description: 'Tenant address line 2',
  },
  {
    key: '${tenant_address_city}',
    description: 'Tenant city',
  },
  {
    key: '${tenant_address_state}',
    description: 'Tenant state',
  },
  {
    key: '${tenant_address_zip}',
    description: 'Tenant zip code',
  },
  {
    key: '${tenant_name}',
    description: 'Tenant name',
  },
  {
    key: '${tenant_social_blog}',
    description: 'Tenant blog link',
  },
  {
    key: '${tenant_social_facebook}',
    description: 'Tenant Facebook link',
  },
  // {
  //   key: '${tenant_social_googleBusiness}',
  //   description: 'Tenant Google Business link'
  // },
  // {
  //   key: '${tenant_social_healthGrades}',
  //   description: 'Tenant Healthgrades link'
  // },
  {
    key: '${tenant_social_instagram}',
    description: 'Tenant Instagram link',
  },
  {
    key: '${tenant_social_linkedIn}',
    description: 'Tenant LinkedIn link',
  },
  {
    key: '${tenant_social_twitter}',
    description: 'Tenant Twitter link',
  },
  {
    key: '${tenant_social_website}',
    description: 'Tenant website link',
  },
  {
    key: '${scheduled_appointment_date}',
    description: 'Scheduled appointment date',
  },
  {
    key: '${scheduled_appointment_time}',
    description: 'Scheduled appointment time',
  },
  {
    key: '${Scheduled_appointment_date_of_week}',
    description: 'Scheduled appointment date of week',
  },
  {
    key: '${scheduled_pickup_date}',
    description: 'Scheduled pickup date',
  },
  {
    key: '${scheduled_pickup_time}',
    description: 'Scheduled pickup time',
  },
  {
    key: '${scheduled_pickup_day_of_week}',
    description: 'Scheduled pickup day of week',
  },
  {
    key: '${facility_name}',
    description: 'Facility name',
  },
  {
    key: '${facility_address_line_1}',
    description: 'Facility address line 1',
  },
  {
    key: '${facility_address_line_2}',
    description: 'Facility address line 2',
  },
  {
    key: '${facility_city}',
    description: 'Facility city',
  },
  {
    key: '${facility_state}',
    description: 'Facility state',
  },
  {
    key: '${facility_zip}',
    description: 'Facility zip',
  },
  // {
  //   key: '${tenant_social_yelp}',
  //   description: 'Tenant Yelp link'
  // },
  {
    key: '${test_name}',
    description: 'Test name',
  },
  {
    key: '${test_quantity}',
    description: 'Test quantity',
  },
  {
    key: '${test_tracking_number}',
    description: 'Order tracking number',
  },
  {
    key: '${shipping_name}',
    description: 'Shipping name',
  },
  {
    key: '${shipping_address}',
    description: 'Shipping address',
  },
  {
    key: '${shipping_city}',
    description: 'Shipping city',
  },
  {
    key: '${shipping_state}',
    description: 'Shipping state',
  },
  {
    key: '${shipping_country}',
    description: 'Shipping country',
  },
  {
    key: '${shipping_zip}',
    description: 'Shipping zip code',
  },
  {
    key: '${support_phone}',
    description: 'Support phone',
  },
  {
    key: '${support_email}',
    description: 'Support email',
  },
];
