import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { DialogActionsBar } from '@progress/kendo-react-dialogs';
import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

// React Crop
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import styles from '../../assets/Questionnaire.module.css';
import { uploadImage } from '../../questionnaireSlice';
import utils from '../utils/utils';

import logo from './resources/upload-empty.png';

// const API_ROOT = process.env.REACT_APP_API_ROOT;

function Previews({ field, value, handleChangeWithParams, disabled, selectedLanguage }) {
  const [currentValue, setCurrentValue] = useState(value);
  const [file, setFile] = useState(field.value);
  const [loading, setLoading] = useState(false);
  const [showCropper, setShowCropper] = useState(false);
  const [crop, setCrop] = useState({
    aspect: field.imageWidth / field.imageHeight,
    width: field.imageWidth,
  });
  const [draftBlob, setDraftBlob] = useState(undefined);
  const [draftCrop, setDraftCrop] = useState(undefined);
  const [imgRef, setImgRef] = useState(undefined);
  const [error, setError] = useState('');
  const [imageType, setImageType] = useState('image/png');
  const [fileName, setFileName] = useState('newfile.png');
  // const imgRef = useRef();

  useEffect(() => {
    if (value !== currentValue) {
      setCurrentValue(value);
    }
    // eslint-disable-next-line
  }, [value]);

  const { getRootProps, getInputProps } = useDropzone({
    disabled: disabled || field.disabled || field.readOnly,
    accept: `${field.acceptedFiles ? field.acceptedFiles.join(', ') : 'image/png, image/jpg, image/jpeg'}`,
    onDrop: (acceptedFiles, failedFiles) => {
      if (acceptedFiles.length + failedFiles.length > 1) {
        setError('Only upload one file at a time');
      } else if (acceptedFiles.length > 0) {
        if (acceptedFiles[0].type === 'image/svg+xml') {
          uploadCrop(acceptedFiles[0]);
        } else {
          setImageType(acceptedFiles[0].type);
          setFileName(acceptedFiles[0].name);

          // Reset fields
          setDraftBlob(undefined);
          setImgRef(undefined);
          setDraftCrop(undefined);
          setCrop({
            aspect: field.imageWidth / field.imageHeight,
            width: field.imageWidth,
          });

          setFile(
            Object.assign(acceptedFiles[0], {
              preview: URL.createObjectURL(acceptedFiles[0]),
            })
          );
          setShowCropper(true);
          setError('');
        }
      } else if (failedFiles.length > 0) {
        setError(failedFiles[0].errors[0].message);
      }
    },
  });

  const onCancel = () => {
    setFile(field.value);
    setShowCropper(false);
  };

  const uploadCrop = async (droppedFile) => {
    if (droppedFile == null) {
      return;
    }
    setLoading(true);
    const uploadResult = await uploadImage(droppedFile, droppedFile.name);
    if (!uploadResult) {
      setLoading(false);
      setError('Image upload error. Please try again');
    } else {
      handleChangeWithParams(field.name, uploadResult.fileId);
      setCurrentValue(uploadResult.previewUrl);
      setLoading(false);
    }
  };

  const onSaveCrop = () => {
    uploadCrop(draftBlob || file);

    setFile(
      Object.assign(draftBlob || file, {
        preview: draftCrop,
      })
    );
    setShowCropper(false);
  };

  const getCroppedImg = useCallback(
    (image, cropInfo, fileName) => {
      const canvas = document.createElement('canvas');
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      canvas.width = cropInfo.width;
      canvas.height = cropInfo.height;
      const ctx = canvas.getContext('2d');

      ctx.drawImage(
        image,
        cropInfo.x * scaleX,
        cropInfo.y * scaleY,
        cropInfo.width * scaleX,
        cropInfo.height * scaleX,
        0,
        0,
        cropInfo.width,
        cropInfo.height
      );

      return new Promise((resolve) => {
        canvas.toBlob((blob) => {
          if (!blob) {
            // reject(new Error('Canvas is empty'));
            /* eslint-disable-next-line no-console */
            console.error('Canvas is empty');
            return;
          }
          blob.name = fileName;
          blob.lastModifiedDate = new Date();
          setDraftBlob(blob);

          window.URL.revokeObjectURL(draftCrop);
          const imageUrl = window.URL.createObjectURL(blob);
          resolve(imageUrl);
        }, imageType);
      });
    },
    [draftCrop, imageType]
  );

  const makeClientCrop = useCallback(
    async (img, cropInfo) => {
      if (img && cropInfo.width && cropInfo.height) {
        const croppedImageUrl = await getCroppedImg(img, cropInfo, fileName);
        setDraftCrop(croppedImageUrl);
      }
    },
    [fileName, getCroppedImg]
  );

  const onCropComplete = useCallback(
    (cropInfo) => {
      makeClientCrop(imgRef, cropInfo);
    },
    [imgRef, makeClientCrop]
  );

  // If you setState the crop in here you should return false.
  const onImageLoaded = useCallback(async (image) => {
    setImgRef(image);
    URL.revokeObjectURL(file.preview);
  }, []);

  const handleRemoveImage = () => {
    handleChangeWithParams(field.name, null);
    setCurrentValue('');
  };

  const fieldTitle =
    selectedLanguage && field.name && field.name.translations
      ? field.name.translations[selectedLanguage]
      : field.label || field.name;
  const questionDescription =
    selectedLanguage && field.description && field.description.translations
      ? field.description.translations[selectedLanguage]
      : field.description;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} style={{ width: '100%' }}>
        {fieldTitle && (
          <Typography className={field.nameStyle ? styles[`title--${field.nameStyle}`] : styles['title--style1']}>
            {field.questionPreview ? utils.renderHTMLTags(fieldTitle) : fieldTitle}
            {field.required && '*'}
          </Typography>
        )}
        {field.image && (
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <img
              src={field.image}
              alt={fieldTitle}
              style={{
                display: 'block',
                marginLeft: 'auto',
                marginRight: 'auto',
                width: '90%',
              }}
            />
          </Grid>
        )}
        {questionDescription && (
          <Typography
            className={
              field.descriptionStyle ? styles[`description--${field.descriptionStyle}`] : styles['description--style1']
            }
          >
            {utils.renderHTMLTags(questionDescription)}
          </Typography>
        )}
      </Grid>

      <Grid item xs={12}>
        <div className={currentValue || field.type === 'assetsWrapper' ? styles.boxPreview : styles.box}>
          <Grid container justifyContent="flex-end">
            {currentValue && !(disabled || field.disabled || field.readOnly) && (
              <Grid item>
                <Tooltip title="Remove Image">
                  <IconButton style={{ marginTop: -20 }} onClick={handleRemoveImage}>
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            )}
            <Grid item xs={12} {...getRootProps({ className: 'dropzone' })} style={{ outline: 'none' }}>
              <input {...getInputProps()} disabled={disabled} />
              <div
                className={!currentValue ? styles.thumbnail : null}
                style={{
                  backgroundColor: field.backgroundColor && currentValue ? field.backgroundColor : '',
                  marginBottom: 10,
                }}
              >
                {loading ? (
                  <CircularProgress />
                ) : (
                  <img src={currentValue || logo} style={{ maxWidth: '100%' }} alt="thumbnail" />
                )}
              </div>
              {!(disabled || field.disabled || field.readOnly) && (
                <Button
                  className={styles.button}
                  variant="contained"
                  disableElevation
                  color="primary"
                  disabled={loading || disabled || field.disabled || field.readOnly}
                >
                  Browse
                </Button>
              )}
            </Grid>
            {!(disabled || field.disabled || field.readOnly) && (
              <Grid item xs={12}>
                <Typography variant="caption" style={{ opacity: 0.5 }}>
                  Or drag image here.
                </Typography>
              </Grid>
            )}
          </Grid>

          {showCropper && (
            <Dialog
              onClose={(event, reason) => {
                if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                  onCancel();
                }
              }}
              width="100%"
              height="100%"
              open={showCropper}
              scroll="paper"
            >
              <DialogTitle>Select the desired area</DialogTitle>
              <DialogContent>
                {file && file.preview && (
                  <ReactCrop
                    src={file.preview}
                    crop={crop}
                    ruleOfThirds
                    keepSelection
                    onImageLoaded={onImageLoaded}
                    onComplete={onCropComplete}
                    onChange={(c) => setCrop(c)}
                    style={{
                      backgroundColor: field.backgroundColor ? field.backgroundColor : '',
                    }}
                  />
                )}
              </DialogContent>
              <DialogActionsBar>
                <button className="k-button" onClick={onCancel}>
                  Cancel
                </button>
                <button className="k-button" onClick={onSaveCrop}>
                  Save
                </button>
              </DialogActionsBar>
            </Dialog>
          )}
        </div>
      </Grid>
      {(field.imageWidth || field.imageHeight || field.acceptedFiles) && (
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          {field.acceptedFiles && (
            <Typography variant="caption" component="p" style={{ opacity: 0.5 }}>
              {`Allowed file types: ${field.acceptedFiles
                .map((f) => f.substring(f.indexOf('/') + 1).toUpperCase())
                .join(', ')}`}
            </Typography>
          )}
          {(field.imageWidth || field.imageHeight) && (
            <Typography variant="caption" component="p" style={{ opacity: 0.5 }}>
              {`Image size must be at least ${field.imageWidth} x ${field.imageHeight}`}
            </Typography>
          )}
        </Grid>
      )}
      {error && (
        <Typography color="error" variant="caption">
          {error}
        </Typography>
      )}
    </Grid>
  );
}

export default Previews;
