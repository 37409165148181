/* eslint-disable */
import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment/moment';
import md5 from 'md5';
import { selectEPrescribe } from './slices/selectors';
import { postPatient } from './slices/ePrescribeSlice';
import { showNotification } from '../Notifications/notificationSlice';

// mui
import Grid from '@material-ui/core/Grid';
import { selectUserDetails } from '../Users/selectors';
import { selectCurrentTenant } from '../Shared/selectors';

const EPrescribe = () => {
  const userDetails = useSelector(selectUserDetails);
  const currentTenant = useSelector(selectCurrentTenant);
  const ePrescribe = useSelector(selectEPrescribe);

  let ssoUri = 'https://web.staging.drfirst.com/sso/portalServices?';
  let rcopia_portal_system_name = 'savendor8118';
  let rcopia_practice_user_name = 'sa98008';
  let rcopia_user_id = 'sdoctor9934';
  let rcopia_user_external_id = 'provider_sa98008_1584539778';
  let service = 'rcopia';
  let action = 'login';
  let rcopia_patient_system_name = 'savendor8118';
  let time = new Date();
  let secret_key = 'pjvd0gn4';
  const dispatch = useDispatch();
  const [url, setSSOUrl] = useState('');

  const postDrFirst = useCallback(() => {
    let startup_screen = 'patient';
    let id = userDetails.nationalHealthId;
    let month =
      time.getUTCMonth() + 1 < 10 ? '0' + (time.getUTCMonth() + 1).toString() : (time.getUTCMonth() + 1).toString();
    let day = time.getUTCDate() < 10 ? '0' + time.getUTCDate().toString() : time.getUTCDate().toString();
    let year = time.getUTCFullYear().toString().substring(2, 4);
    let hours = time.getUTCHours() < 10 ? '0' + time.getUTCHours().toString() : time.getUTCHours().toString();
    let minutes = time.getUTCMinutes() < 10 ? '0' + time.getUTCMinutes().toString() : time.getUTCMinutes().toString();
    let seconds = time.getUTCSeconds() < 10 ? '0' + time.getUTCSeconds().toString() : time.getUTCSeconds().toString();
    let timeString = month + day + year + hours + minutes + seconds;
    if (id?.length > 0) {
      let paramString = `rcopia_portal_system_name=${rcopia_portal_system_name}&rcopia_practice_user_name=${rcopia_practice_user_name}&rcopia_user_external_id=${rcopia_user_external_id}&service=${service}&action=${action}&startup_screen=${startup_screen}&rcopia_patient_system_name=${rcopia_patient_system_name}&rcopia_patient_external_id=${id}&skip_auth=n&time=${timeString}${secret_key}`;
      let md5Hash = md5(paramString).toString().toUpperCase();
      setSSOUrl(
        ssoUri +
          `rcopia_portal_system_name=${rcopia_portal_system_name}&rcopia_practice_user_name=${rcopia_practice_user_name}&rcopia_user_external_id=${rcopia_user_external_id}&service=${service}&action=${action}&startup_screen=${startup_screen}&rcopia_patient_system_name=${rcopia_patient_system_name}&rcopia_patient_external_id=${id}&skip_auth=n&time=${timeString}&MAC=${md5Hash}`
      );
    } else if (id === 0) {
      startup_screen = 'message';
      let paramString = `rcopia_portal_system_name=${rcopia_portal_system_name}&rcopia_practice_user_name=${rcopia_practice_user_name}&rcopia_user_id=${rcopia_user_id}&rcopia_user_external_id=${rcopia_user_external_id}&service=${service}&action=${action}&startup_screen=${startup_screen}&rcopia_patient_system_name=${rcopia_patient_system_name}&limp_mode=y&skip_auth=n&time=${timeString}${secret_key}`;
      let md5Hash = md5(paramString).toString().toUpperCase();
      setSSOUrl(
        ssoUri +
          `rcopia_portal_system_name=${rcopia_portal_system_name}&rcopia_practice_user_name=${rcopia_practice_user_name}&rcopia_user_id=${rcopia_user_id}&rcopia_user_external_id=${rcopia_user_external_id}&service=${service}&action=${action}&startup_screen=${startup_screen}&rcopia_patient_system_name=${rcopia_patient_system_name}&limp_mode=y&skip_auth=n&time=${timeString}&MAC=${md5Hash}`
      );
    } else if (id === -1) {
      startup_screen = 'report';
      let paramString = `rcopia_portal_system_name=${rcopia_portal_system_name}&rcopia_practice_user_name=${rcopia_practice_user_name}&rcopia_user_id=${rcopia_user_id}&rcopia_user_external_id=${rcopia_user_external_id}&service=${service}&action=${action}&startup_screen=${startup_screen}&rcopia_patient_system_name=${rcopia_patient_system_name}&limp_mode=y&skip_auth=n&time=${timeString}${secret_key}`;
      let md5Hash = md5(paramString).toString().toUpperCase();
      setSSOUrl(
        ssoUri +
          `rcopia_portal_system_name=${rcopia_portal_system_name}&rcopia_practice_user_name=${rcopia_practice_user_name}&rcopia_user_id=${rcopia_user_id}&rcopia_user_external_id=${rcopia_user_external_id}&service=${service}&action=${action}&startup_screen=${startup_screen}&rcopia_patient_system_name=${rcopia_patient_system_name}&limp_mode=y&skip_auth=n&time=${timeString}&MAC=${md5Hash}`
      );
    }
  }, [
    action,
    rcopia_patient_system_name,
    rcopia_portal_system_name,
    rcopia_practice_user_name,
    rcopia_user_external_id,
    rcopia_user_id,
    secret_key,
    service,
    ssoUri,
    time,
    userDetails.nationalHealthId,
  ]);

  const formatDate = (date) => {
    return moment.utc(date).format('MM/DD/YYYY');
  };

  const checkMandatoryFields = () => {
    const missed = [];
    const fields = {
      firstName: userDetails?.firstName || false,
      lastName: userDetails?.lastName || false,
      birthDate: userDetails?.birthDate || false,
      gender: userDetails?.gender || false,
      mobileNumber: userDetails?.contact?.mobileNumber || false,
      address1: userDetails?.primaryAddress?.line1 || false,
      city: userDetails?.primaryAddress?.city || false,
      state: userDetails?.primaryAddress?.state || false,
      zip: userDetails?.primaryAddress?.zip || false,
    };

    for (let i in fields) {
      if (!fields[i]) missed.push(i);
    }

    return missed;
  };

  const postPatientCallback = useCallback(
    (userDetails) => {
      const mandatoryFields = checkMandatoryFields();

      if (!mandatoryFields.length) {
        let sendPatient = {
          externalID: userDetails.nationalHealthId,
          firstName:
            userDetails.firstName && userDetails.firstName.length >= 35
              ? userDetails.firstName.substr(0, 35)
              : userDetails.firstName,
          middleName:
            userDetails.middleName && userDetails.middleName.length >= 35
              ? userDetails.middleName.substr(0, 35)
              : userDetails.middleName,
          lastName:
            userDetails.lastName && userDetails.lastName.length >= 35
              ? userDetails.lastName.substr(0, 35)
              : userDetails.lastName,
          dob: formatDate(userDetails.birthDate),
          sex: userDetails.gender,
          mobilePhone: userDetails.contact ? userDetails.contact.mobileNumber : '',
          homePhone: userDetails.contact ? userDetails.contact.homeNumber : '',
          address1: userDetails.primaryAddress ? userDetails.primaryAddress.line1 : '',
          city: userDetails.primaryAddress ? userDetails.primaryAddress.city : '',
          state: userDetails.primaryAddress ? userDetails.primaryAddress.state : '',
          zip: userDetails.primaryAddress ? userDetails.primaryAddress.zip : '',
        };
        let sendPatientObj = { patient: [] };
        sendPatientObj.patient.push(sendPatient);
        dispatch(postPatient(sendPatientObj));
      } else {
        dispatch(showNotification(`Mandatory fields are missing: <br> - ${mandatoryFields.join('<br>- ')}`, 'error'));
      }
    },
    [dispatch]
  );

  useEffect(() => {
    postPatientCallback(userDetails);
  }, [currentTenant, postPatientCallback, userDetails]);

  useEffect(() => {
    if (ePrescribe) {
      if (ePrescribe.success) {
        postDrFirst();
      } else {
        dispatch(showNotification('Oops! Something went wrong. Please try again.', 'error'));
      }
    }
  }, [dispatch, ePrescribe, postDrFirst]);

  return (
    <Grid>
      <iframe src={url} height="800" width="100%" title={'E Prescribe'} />
    </Grid>
  );
};

export default EPrescribe;
