import React from 'react';
import ContentLoader from 'react-content-loader';

export function UserSegmentSkeletonLoading(props) {
  return (
    <ContentLoader speed={2} viewBox="0 0 1500 1950" backgroundColor="#f3f3f3" foregroundColor="#ecebeb" {...props}>
      <rect x="70" y="100" rx="3" ry="3" width="400" height="40" />

      <rect x="300" y="200" rx="3" ry="3" width="90" height="20" />
      <rect x="300" y="240" rx="3" ry="3" width="1100" height="40" />

      <rect x="70" y="400" rx="3" ry="3" width="400" height="40" />
      <rect x="700" y="530" rx="3" ry="3" width="200" height="40" />
      <rect x="1400" y="530" rx="3" ry="3" width="40" height="40" />

      <rect x="300" y="620" rx="3" ry="3" width="90" height="20" />
      <rect x="300" y="660" rx="3" ry="3" width="900" height="40" />

      <rect x="620" y="770" rx="3" ry="3" width="400" height="40" />
      <rect x="520" y="860" rx="3" ry="3" width="600" height="350" />

      <rect x="300" y="200" rx="3" ry="3" width="90" height="20" />
      <rect x="300" y="1240" rx="3" ry="3" width="200" height="20" />
      <rect x="300" y="1270" rx="3" ry="3" width="900" height="520" />

      <rect x="300" y="1280" rx="3" ry="3" width="900" height="520" />
      <rect x="300" y="1830" rx="3" ry="3" width="900" height="50" />
      <rect x="300" y="1910" rx="3" ry="3" width="900" height="50" />
    </ContentLoader>
  );
}
