import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { filterBy } from '@progress/kendo-data-query';
import { TimePicker } from '@progress/kendo-react-dateinputs';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import { Input, Checkbox } from '@progress/kendo-react-inputs';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import CreateResultDialogStyles from '../../assets/jss/components/CreateResultDialogStyles';
import DateInput from '../Shared/Forms/KendoFields/DateInput';
import ProvidersTypeahead from '../Shared/Forms/KendoFields/Typeahead/ProvidersTypeahead';
import { checkMandatoryFields } from './helpers/ordersUtils';
import TestResultField from './ResultField';

function CreateResultDialog(props) {
  const {
    classes,
    closeDialog,
    userDetails,
    handleSelectUser,
    handleTextChange,
    users,
    tests,
    handleTestChange,
    handleCreateResult,
    handleResultChange,
    handleProviderChange,
    handleCollectionDateChange,
    handleCollectionTimeChange,
    handleSpecimedIdChange,
    userSpecific,
    selectedUser,
    selectedProvider,
    specimenTypes,
    selectedTest,
    selectedResult,
    resultDialogOpen,
    isSubmitting,
  } = props;
  const [testValue, setTestValue] = useState(undefined);
  const [specimenIdValue, setSpecimenIdValue] = useState(undefined);
  const [collectionDate, setCollectionDate] = useState(new Date());
  const [collectionTime, setCollectionTime] = useState(new Date());
  const [confirmed, setConfirmed] = useState(false);
  const [setAllSpecimenTypes] = useState(specimenTypes);

  const [user] = useState(
    (userSpecific && {
      ...userDetails,
      fullName: `${userDetails.firstName || ''} ${userDetails.lastName || ''} <${userDetails.email}>`,
    }) ||
      undefined
  );

  const [allTests, setAllTests] = useState(
    tests &&
      tests.map((ot) => ({
        ...ot,
        fullName: `${ot.vendorName} / ${ot.longName ? ot.longName : ot.shortName}`,
      }))
  );
  const [loadingTests, setLoadingTests] = useState(false);
  const [timeout, setStateTimeout] = useState(undefined);
  const [missingData, setMissingData] = useState([]);
  const [mandatoryFields] = React.useState(['nationalHealthId']);

  const currentDate = +moment().format('YYYYMMDD');
  const currentTime = +moment().format('HHmm00');
  const selectedCollectionDate = +moment(collectionDate).format('YYYYMMDD');
  const selectedCollectionTime = +moment(collectionTime).format('HHmm00');

  const disabledSave =
    (!userSpecific && !selectedUser) ||
    !selectedTest ||
    !selectedResult ||
    !collectionDate ||
    selectedCollectionDate > currentDate ||
    (selectedCollectionDate === currentDate && selectedCollectionTime > currentTime) ||
    !selectedProvider ||
    !collectionTime ||
    !confirmed ||
    missingData.length;

  useEffect(() => {
    if (userSpecific && userDetails) {
      checkMandatoryFields(mandatoryFields, userDetails.email, [userDetails], userSpecific, setMissingData);
    }
  }, [users, userDetails, userSpecific, mandatoryFields]);

  const handleCancel = () => {
    closeDialog();
  };

  const onTestChange = (e) => {
    const { value } = e.target;
    setTestValue(value);
    handleTestChange(value);
  };

  const onSpecimenIdChange = (e) => {
    const { value } = e.target;
    setSpecimenIdValue(value);
    handleSpecimedIdChange(value);
  };

  const onCollectionDateChange = (v) => {
    if (v instanceof Date && !Number.isNaN(v)) {
      setCollectionDate(v);
      handleCollectionDateChange(v);
    } else {
      setCollectionDate(undefined);
      handleCollectionDateChange(undefined);
    }
  };

  const onCollectionTimeChange = (e) => {
    setCollectionTime(e.target.value);
    handleCollectionTimeChange(e.target.value);
  };

  const onConfirmationChange = (e) => {
    setConfirmed(e.value);
  };

  const filterData = (filter, allData) => {
    if (filter.field) {
      return filterBy(allData, filter);
    }
    const regex = new RegExp(filter.value, 'i');
    return allData.filter((s) => s.search(regex) > -1);
  };

  const filterTestData = (filter) => {
    if (!tests) return [];

    const allData =
      tests &&
      tests.map((ot) => ({
        ...ot,
        fullName: `${ot.fullName ? ot.fullName : `${ot.vendorName || ''}/${ot.longName}` || ''}`,
      }));
    return filterData(filter, allData);
  };

  const onTestFilterChange = (event) => {
    if (timeout) {
      clearTimeout(timeout);
    }

    setStateTimeout(
      setTimeout(() => {
        const newData = filterTestData(event.filter);
        setAllTests(newData);
        setLoadingTests(false);
      }, 500)
    );

    setLoadingTests(true);
  };

  // eslint-disable-next-line
  const onSpecimenFilterChange = (event) => {
    if (timeout) {
      clearTimeout(timeout);
    }

    setStateTimeout(
      setTimeout(() => {
        const newData = filterData(event.filter, specimenTypes);
        setAllSpecimenTypes(newData);
        setLoadingTests(false);
      }, 500)
    );

    setLoadingTests(true);
  };

  const handleUserSelection = (e) => {
    checkMandatoryFields(mandatoryFields, e.target.value ? e.target.value.email : '', users, true, setMissingData);
    handleSelectUser(e);
  };

  return (
    <Dialog
      onClose={handleCancel}
      open={resultDialogOpen}
      maxWidth="sm"
      fullWidth
      disableEnforceFocus
      className={classes.dialogContainer}
    >
      <DialogTitle style={{ padding: 0 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography
            style={{
              fontSize: 24,
              fontWeight: 500,
              color: 'rgba(80, 83, 88, 1)',
            }}
          >
            New Test Result
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent
        style={{
          padding: '0 16px',
          marginTop: 40,
          boxSizing: 'border-box',
          overflowY: 'initial',
        }}
      >
        {userSpecific ? (
          <Grid container direction="column">
            <Grid item>
              <Typography style={{ fontWeight: 600, fontSize: 18, color: '#505358' }}>
                {`${userDetails.firstName} ${userDetails.lastName}`}
              </Typography>
              <Typography style={{ fontWeight: 400, fontSize: 16, color: '#505358' }}>{userDetails.email}</Typography>
            </Grid>
          </Grid>
        ) : (
          <ComboBox
            data={users.map((u) => ({
              ...u,
              fullName: `${u.firstName || ''} ${u.lastName || ''} (${u.email})`,
            }))}
            allowCustom={false}
            suggest
            label="User"
            name="userToTest"
            required
            filterable
            onFilterChange={handleTextChange}
            onChange={handleUserSelection}
            style={{ width: '100%' }}
            disabled={userSpecific}
            value={user}
            textField="fullName"
          />
        )}
        {missingData.length > 0 ? (
          <Grid container style={{ paddingTop: 10 }} justifyContent="center" spacing={4}>
            <Grid item>
              <Typography variant="caption" color="error">
                Lab result cannot be processed for this user due to the following missing information:
              </Typography>
              <br />
              <br />
              <Typography variant="caption" color="error">
                {missingData.join(', ')}
              </Typography>
            </Grid>
          </Grid>
        ) : (
          <Grid container style={{ gap: 20, marginTop: 20 }}>
            <ComboBox
              data={allTests}
              allowCustom={false}
              suggest
              filterable
              onFilterChange={onTestFilterChange}
              label="Test Type"
              name="testType"
              required
              onChange={onTestChange}
              style={{ width: '100%' }}
              textField="fullName"
              value={testValue}
              loading={loadingTests}
            />
            <Input
              label="Specimen ID / Device Identifier"
              name="specimenId"
              required={false}
              onChange={onSpecimenIdChange}
              style={{ width: '100%' }}
              value={specimenIdValue}
            />
            <Grid container spacing={2}>
              <Grid item md={6}>
                <Typography style={{ fontSize: 12 }}>Collection Date</Typography>
                <DateInput
                  field={{
                    name: 'collectionDate',
                    max: new Date(),
                  }}
                  value={new Date()}
                  handleChangeWithParams={(name, value) => onCollectionDateChange(value)}
                  withoutMargin
                  required
                />
              </Grid>
              <Grid item md={6} xs={12} style={{ marginTop: 4 }}>
                <Typography style={{ fontSize: 12 }}>Collection Time</Typography>
                <div style={{ width: '100%', marginTop: -6 }}>
                  <TimePicker value={collectionTime} onChange={onCollectionTimeChange} required width="100%" />
                </div>
              </Grid>
            </Grid>
            <ProvidersTypeahead handleChange={handleProviderChange} />
            <Typography style={{ marginTop: 10, width: '100%' }}>Test Result</Typography>
            <div style={{ marginTop: 10, width: '100%' }}>
              <TestResultField onChange={handleResultChange} />
            </div>
            <div style={{ marginTop: 10 }}>
              <Checkbox
                label="By clicking here I confirm that the provided data is accurate."
                value="confirmation"
                checked={confirmed}
                onChange={onConfirmationChange}
              />
            </div>
          </Grid>
        )}
      </DialogContent>
      <DialogActions style={{ padding: '0 8px' }}>
        <Grid container justifyContent="flex-end" style={{ marginTop: 26, gridGap: 16 }}>
          <Button variant="outlined" color="primary" disableElevation elevation={0} onClick={closeDialog}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            elevation={0}
            onClick={handleCreateResult}
            disabled={disabledSave || isSubmitting}
          >
            Confirm
            {isSubmitting && <CircularProgress style={{ marginLeft: 8 }} size={18} color="secondary" />}
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export default withStyles(CreateResultDialogStyles)(CreateResultDialog);
