/* eslint-disable no-restricted-syntax */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/anchor-is-valid */
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import LanguageIcon from '@material-ui/core/SvgIcon/SvgIcon';
import Typography from '@material-ui/core/Typography';
import React, { useEffect, useState } from 'react';

// component and helper imports
import { useDispatch } from 'react-redux';

// mui

import { getContentById } from '../virtualConsultSlice';
import styles from './assets/Questionnaire.module.css';
import { createInputHelper } from './components/createInputHelper';
import utils from './components/utils/utils';

// Renders the individual question to be displayed to the DeviceFrame preview screen
function QuestionPreview(props) {
  const {
    actions,
    formData,
    links,
    handleDeviceButton,
    handleChange,
    handleChangeWithParams,
    handleChangeComment,
    handleLinkClick,
    disabled,
    disabledBack,
    selectedLanguage,
    handleLanguageChange,
    questionnaireCommentEnabled = true, // if comment setting enable for questionnaire
    dialog,
    handleBack,
    values,
    loading,
    isFirstQuestion,
    previewForBuilder,
    questionList,
    currentTenant,
    fromQuestionStore,
  } = props;

  const dispatch = useDispatch();

  // Abort if no data present
  if (!formData) return null;

  const languageSupport = false;

  const languageMenu = [
    {
      label: 'English',
      value: 'eng',
    },
    {
      label: 'Español',
      value: 'spa',
    },
    {
      label: '中文 (普通話)',
      value: 'zho',
    },
  ];

  const answerCommentField = {
    type: 'text',
    name: 'comment',
    label: 'Add a Comment',
    required: false,
    questionComment: true,
    width: 12,
    rows: 1,
  };

  const languageErrors = {
    spa: 'Esta página no está disponible actualmente en español.',
    zho: '該頁面當前不支持西班牙語',
  };

  const buttonLanguages = {
    continue: {
      eng: 'Continue',
      spa: 'Seguir',
      zho: '继续',
    },
    back: {
      eng: 'Back',
      spa: 'Regresar',
      zho: '返回',
    },
  };

  // State and function to populate full combo subnode question details inside question builder
  const [subnodes, setSubnodes] = useState([]);
  useEffect(() => {
    const getFullQuestions = async () => {
      const fullSubnodes = [];
      for (const subnode of formData.nodeList) {
        if (subnode?.questionId) {
          // Not sure where getContentById is coming from since it is not defined anywhere
          // eslint-disable-next-line no-undef
          const fullQuestion = await dispatch(getContentById(currentTenant, 'questions', subnode.questionId, true));
          fullSubnodes.push(fullQuestion);
        }
      }
      setSubnodes(fullSubnodes);
    };

    if (previewForBuilder && formData.type === 'combo' && formData.nodeList?.length) {
      if (fromQuestionStore) {
        getFullQuestions();
      } else {
        // Populate subnodes from questionList from questionnaire editor
        const fullSubnodes = formData.nodeList.map((subnode) =>
          questionList.find((question) => subnode.questionId === question.id)
        );
        setSubnodes(fullSubnodes);
      }
    }
  }, [actions, currentTenant, formData.nodeList, formData.type, previewForBuilder, questionList]);

  useEffect(() => {
    if (!previewForBuilder && formData && (formData.name || formData.description)) {
      // try to find and execute script from description
      utils.findScript(
        selectedLanguage && formData.description && formData.description.translations
          ? formData.description.translations[selectedLanguage]
          : formData.description
      );

      // try to find and execute script from question name
      utils.findScript(
        selectedLanguage && formData.name && formData.name.translations
          ? formData.name.translations[selectedLanguage]
          : formData.label || formData.name
      );
    }
  }, [formData, previewForBuilder, selectedLanguage]);

  const screen = () => {
    const updatedData = { ...formData };
    updatedData.questionPreview = true;
    updatedData.questionnaireCommentEnabled = questionnaireCommentEnabled;
    if (updatedData.commentEnabled) {
      updatedData.answerCommentField = answerCommentField;
    }
    if (previewForBuilder && updatedData?.type === 'combo' && updatedData.nodeList?.length) {
      // Subnodes will be populated by the useEffect above for editor previews
      updatedData.subnodes = subnodes;
    }

    return updatedData.name ? (
      <Grid item xs={12} style={{ width: '100%' }}>
        {dialog ? (
          <h1>{languageErrors[selectedLanguage]}</h1>
        ) : (
          <>
            <Grid item xs={12} style={{ width: '100%' }}>
              {createInputHelper(
                [updatedData],
                values,
                handleChange,
                handleChangeWithParams,
                null,
                false,
                () => {},
                selectedLanguage
              )}
            </Grid>
            <Typography variant={updatedData.helpStyle ? '' : 'caption'}>
              <span
                dangerouslySetInnerHTML={{
                  __html:
                    selectedLanguage && updatedData.help && updatedData.help.translations
                      ? updatedData.help.translations[selectedLanguage]
                      : updatedData.help,
                }}
              />
            </Typography>
            {updatedData.commentEnabled && questionnaireCommentEnabled && updatedData.type !== 'multiple' ? (
              <Grid item xs={12} style={{ width: '100%' }}>
                {createInputHelper([answerCommentField], values, () => {}, handleChangeComment)}
              </Grid>
            ) : null}
            <Grid item xs={12} />
            <Grid item xs={12}>
              {links &&
                links.map((link, idx) => (
                  <Grid key={`link-${idx}`} item xs={12}>
                    <Link href="#" onClick={() => handleLinkClick(link.nodeId)}>
                      {selectedLanguage && link.name && link.name.translations
                        ? link.name.translations[selectedLanguage]
                        : link.name}
                    </Link>
                  </Grid>
                ))}
            </Grid>
          </>
        )}
      </Grid>
    ) : (
      <Grid container justifyContent="center" style={{ marginTop: 100 }}>
        <Grid item xs={10}>
          <Typography variant="body2" style={{ opacity: 0.6 }}>
            Preview of question will be shown here during setup.
          </Typography>
        </Grid>
      </Grid>
    );
  };

  if (!formData) return null;

  return (
    <Grid container className={styles.containerWrapper}>
      {languageSupport ? (
        <Grid item xs={12}>
          <div
            style={{
              marginTop: 30,
              marginRight: 5,
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <LanguageIcon />
            <Select
              style={{ width: 130, marginLeft: 5 }}
              labelId="languageSelection"
              id="languageSelection"
              value={selectedLanguage}
              onChange={handleLanguageChange}
            >
              {languageMenu.map((lang, idx) => (
                <MenuItem key={idx} value={lang.value}>
                  {lang.label}
                </MenuItem>
              ))}
            </Select>
          </div>
        </Grid>
      ) : null}
      <Grid item xs={12}>
        {screen()}
      </Grid>

      <Grid item xs={12} className={styles.buttonsWrapper}>
        <Grid item xs={12} className={styles.buttonSpace}>
          <Button
            className={styles.buttonBack}
            disableRipple
            disabled={isFirstQuestion || disabledBack || previewForBuilder}
            onClick={() => handleBack()}
          >
            {disabledBack && <CircularProgress className={styles.buttonLoading} />}
            {buttonLanguages.back[selectedLanguage] || 'Back'}
          </Button>
        </Grid>

        <Button
          className={styles.buttonContinue}
          variant="contained"
          color="primary"
          disableElevation
          size="large"
          onClick={handleDeviceButton}
          disabled={disabled || previewForBuilder}
        >
          {loading && <CircularProgress className={styles.buttonLoading} />}
          {buttonLanguages.continue[selectedLanguage] || 'Continue'}
        </Button>
      </Grid>
    </Grid>
  );
}

export default QuestionPreview;
