import {
  Chart,
  ChartArea,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartSeriesItemTooltip,
  ChartSeriesLabels,
  ChartTitle,
} from '@progress/kendo-react-charts';

import PropTypes from 'prop-types';
import React from 'react';

// components and helpers
import ChartCard from '../ChartCard';
import InfoBox from './InfoBox';

/**
 * Donut chart component
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function DonutChart(props) {
  const { title, height, data, infoText, isTooltipVisible = false } = props;

  const labelContent = (e) => `${e.value}%`;

  return (
    <ChartCard height={height}>
      {infoText && <InfoBox infoText={infoText} position={'topRight'} />}
      <Chart>
        <ChartTitle color="#505358" font="16px Avenir" text={title} />
        <ChartArea height={height - 20} />
        <ChartSeries>
          <ChartSeriesItem
            type="donut"
            data={data}
            categoryField="label"
            field="value"
            // colorField="color"
          >
            <ChartSeriesItemTooltip
              format={'{0}%'}
              // render={({ point }) => <span>{point.value}%</span>}
              visible={isTooltipVisible}
            />
            <ChartSeriesLabels color="#fff" background="none" content={labelContent} />
          </ChartSeriesItem>
        </ChartSeries>
        <ChartLegend visible position="right" />
      </Chart>
    </ChartCard>
  );
}

DonutChart.propTypes = {
  title: PropTypes.string,
  height: PropTypes.number.isRequired,
  data: PropTypes.array.isRequired,
};

export default DonutChart;
