export const selectOrderDetails = (state) => state.orders.orderDetails;
export const selectOrderDetailsLoading = (state) => state.orders.getOrderDetailsLoading;
export const selectOrderableTestsLoading = (state) => state.orders.orderableTests?.loading;
export const selectOrderableTests = (state) => state.orders.orderableTests;
export const selectOrderableTestsList = (state) => state.orders.orderableTests?.list;
export const selectVendorsLoading = (state) => state.orders.vendors?.loading;
export const selectVendorsList = (state) => state.orders.vendors?.list;
export const selectLabOrdersLoading = (state) => state.orders.labOrdersLoading;
export const selectLabOrders = (state) => state.orders.labOrders;
export const selectTenantLabOrdersLoading = (state) => state.orders.tenantLabOrdersLoading;
export const selectTenantLabOrders = (state) => state.orders.tenantLabOrders;
export const selectTestFormat = (state) => state.orders.testFormat;
export const selectProviders = (state) => state.orders.providers;
export const selectScanHistoryLoading = (state) => state.orders.scanHistoryLoading;
export const selectScanHistory = (state) => state.orders.scanHistory;
export const selectKendoPagination = (state) => state.orders.ordersPagination;
