export const rapidTestTabsMapping = [
  {
    label: 'Patient',
    value: 0,
  },
  {
    label: 'Test Details',
    value: 1,
  },
  // SHP-4298: UI updates
  // {
  //   label: 'Scan',
  //   value: 3,
  // },
  {
    label: 'AI/Processing',
    value: 2,
  },
  {
    label: 'Public Health Record',
    value: 3,
  },
];
