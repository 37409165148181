import React, { useEffect } from 'react';

// redux
import { useDispatch, useSelector } from 'react-redux';

import { selectCurrentTenant } from '../Shared/selectors';
import AppKendoTableWrapper from '../Shared/Table/KendoTableWrapper';
import utils from '../Shared/Utils/utils';

import { allUploadsColumns as columns } from './helpers/usersListMapper';
import { selectAllUploadsList, selectAllUploadsListLoading } from './selectors';
import { getAllUploads } from './usersSlice';

function Users() {
  const allUploadsListLoading = useSelector(selectAllUploadsListLoading);
  const allUploadsList = useSelector(selectAllUploadsList);

  const currentTenant = useSelector(selectCurrentTenant);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllUploads(0, 10, currentTenant.id));
  }, [currentTenant]);

  const handleTableUpdate = (page, pageSize) => {
    dispatch(getAllUploads(page, pageSize, currentTenant.id));
  };

  const data = {
    total: allUploadsList && allUploadsList.total,
    pageSize: allUploadsList && allUploadsList.pageSize,
    items:
      allUploadsList &&
      allUploadsList.items &&
      allUploadsList.items.map((item) => ({
        ...item,
        fileName: item?.fileName || '',
        uploadedBy: item?.uploadedBy || '',
        dateUploaded: item?.dateUploaded ? utils.formatDate(item.dateUploaded) : '-',
      })),
  };

  return (
    <AppKendoTableWrapper
      tableTitle="View All Uploads"
      data={data}
      columns={columns()}
      onTableUpdate={handleTableUpdate}
      currentTenant={currentTenant}
      loading={allUploadsListLoading}
      disableAutoFocus
      noRecordsMessage="There are no uploads at this time."
    />
  );
}

export default Users;
