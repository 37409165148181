const getRequiredByType = (nestedRules) => {
  switch (nestedRules.type) {
    case 'multiple':
    case 'text':
      return !nestedRules.operation || !nestedRules.expectedAnswer;
    case 'date':
      return !nestedRules.operation || !nestedRules.expected;
    case 'yes-no':
      return !nestedRules.expectedAnswer;
    case 'slider':
    case 'scale':
    case 'numeric':
      return !nestedRules.operation || typeof nestedRules.answer !== 'number';
    case 'multiple-size':
      return !nestedRules.operation || !nestedRules.size;
    case 'score':
      return !nestedRules.operation;
    default:
  }
};

export const checkRequired = (questionNodes, setDisableSave) => {
  for (let i = 0; i < questionNodes.length; i++) {
    // check if default jump is set in order to save
    if (
      !questionNodes[i].defaultRoute ||
      (questionNodes[i].defaultRoute && !questionNodes[i].defaultRoute.defaultNextQuestionId)
    ) {
      setDisableSave(true);
      // if default jump is set, check if nested rules are present and check required fields are populated in order to save
    } else if (questionNodes[i].rules.length > 0) {
      const { rules } = questionNodes[i];
      for (let i = 0; i < rules.length; i++) {
        const nestedRules = rules[i].rules;
        if (!rules[i].nextQuestionId && rules[i].rules.length > 0) {
          setDisableSave(true);
          break;
        } else if (rules[i].nextQuestionId && rules[i].rules.length > 0) {
          for (let j = 0; j < nestedRules.length; j++) {
            const typeRules = getRequiredByType(nestedRules[j]);
            if ((nestedRules[j].expectedAnswers && nestedRules[j].expectedAnswers.length === 0) || typeRules) {
              setDisableSave(true);
              break;
            } else {
              setDisableSave(false);
            }
          }
        } else {
          setDisableSave(false);
        }
      }
    } else {
      setDisableSave(false);
    }
  }
};
