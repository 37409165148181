import { paths } from '../../../constants';
import { emailRegex } from '../../Shared/Forms/helpers/contants';

import { roleMappings } from './mappings';

const memberImportCSVData = [
  {
    EmailId: '<REQUIRED: EMAIL ADDRESS>',
    FirstName: '<REQUIRED: FIRST NAME>',
    LastName: '<REQUIRED: LAST NAME>',
    Permission: '<REQUIRED: ADMINISTRATOR or ADMINISTRATOR_RESTRICTED or CHECKER or DEFAULT>',
    EmployeeId: '<OPTIONAL: EMPLOYEE ID>',
    DOB: '<OPTIONAL: MM/DD/YYYY>',
    Gender: '<OPTIONAL: MALE, FEMALE, OTHER or INTERSEX>',
    PhoneNumber: '<OPTIONAL: 10 DIGITS>',
    'LineAddress 1': '<OPTIONAL>',
    City: '<OPTIONAL>',
    State: '<OPTIONAL: 2 LETTER STATE CODE>',
    Zip: '<OPTIONAL>',
    BenefitsId: '<OPTIONAL>',
    GroupNumber: '<OPTIONAL>',
    RxBIN: '<OPTIONAL>',
    PCN: '<OPTIONAL>',
    TokenId: '<OPTIONAL>',
  },
];

export const role = [
  {
    type: 'dropdown',
    name: 'role',
    label: 'Select Role',
    enum: roleMappings,
    required: true,
    width: 12,
    labelField: 'label',
    valueField: 'id',
  },
  {
    type: 'email',
    name: 'email',
    label: 'Email',
    required: true,
    width: 12,
    placeholder: 'e.g. myname@example.net',
    pattern: emailRegex,
  },
  {
    type: 'removeAutofill',
  },
];

export const roleDependent = [
  {
    type: 'dropdown',
    name: 'role',
    label: 'Select Role',
    enum: roleMappings,
    required: true,
    width: 12,
    labelField: 'label',
    valueField: 'id',
  },
  {
    type: 'userInputSearch',
    name: 'parentEmail',
    label: 'Parent account',
    required: true,
    width: 12,
  },
  {
    type: 'header',
    label: 'Date of Birth',
  },
  {
    type: 'date',
    name: 'birthDate',
    label: 'Date of Birth',
    required: false,
    width: 12,
    pattern: '^(0[1-9]|1[012])[-/.](0[1-9]|[12][0-9]|3[01])[-/.](19|20)\\d\\d$',
    max: new Date(),
    minAgeRestriction: '18',
  },
  {
    type: 'email',
    name: 'email',
    label: 'Email',
    required: true,
    width: 12,
    placeholder: 'e.g. myname@example.net',
    pattern: emailRegex,
  },
  {
    type: 'removeAutofill',
  },
];

export const basic = [
  {
    type: 'dropdown',
    name: 'role',
    label: 'Select Role',
    enum: roleMappings,
    required: true,
    width: 12,
    labelField: 'label',
    valueField: 'id',
    disabled: true,
  },
  {
    type: 'email',
    name: 'email',
    label: 'Email',
    required: true,
    width: 12,
    placeholder: 'e.g. myname@example.net',
    disabled: true,
  },
  {
    type: 'text',
    name: 'firstName',
    label: 'First Name',
    required: true,
    width: 12,
  },
  {
    type: 'text',
    name: 'lastName',
    label: 'Last Name',
    required: true,
    width: 12,
  },
  // {
  //   type: 'toggle',
  //   // Does not create user in Cognito. Triggers sign up email with web verification.
  //   name: 'inviteOnly',
  //   label: 'Email Invitation Only',
  //   width: 12,
  //   show: false,
  // },
];

export const basicInvite = [
  {
    type: 'dropdown',
    name: 'role',
    label: 'Select Role',
    enum: roleMappings,
    required: true,
    width: 12,
    labelField: 'label',
    valueField: 'id',
  },
  {
    type: 'email',
    name: 'email',
    label: 'Email',
    required: true,
    width: 12,
    placeholder: 'e.g. myname@example.net',
    // eslint-disable-next-line
    pattern: "(?=^.{6,100}$)^[a-zA-Z0-9_\\.\\-\\+]+\\@[a-zA-Z0-9\\-]+\\.[a-zA-Z0-9]{2,}$",
  },
  {
    type: 'text',
    name: 'firstName',
    label: 'First Name',
    required: true,
    width: 12,
    minLength: 2,
    maxLength: 40,
    displayErrorMessage: false,
    pattern: "(?=^.{2,40}$)^[a-zA-Z\\'\\`\\-\\s]+$",
  },
  {
    type: 'text',
    name: 'lastName',
    label: 'Last Name',
    required: true,
    width: 12,
    minLength: 2,
    maxLength: 40,
    displayErrorMessage: false,
    pattern: "(?=^.{2,40}$)^[a-zA-Z\\'\\`\\-\\s]+$",
  },
  // {
  //   type: 'toggle',
  //   // Does not create user in Cognito. Triggers sign up email with web verification.
  //   name: 'inviteOnly',
  //   label: 'Email Invitation Only',
  //   width: 12,
  //   show: false,
  // },
  {
    type: 'removeAutofill',
  },
];

export const basicDependent = [
  {
    type: 'dropdown',
    name: 'role',
    label: 'Select Role',
    enum: roleMappings,
    required: true,
    width: 12,
    labelField: 'label',
    valueField: 'id',
    disabled: true,
  },
  {
    type: 'userInputSearch',
    name: 'parentEmail',
    label: 'Parent account',
    required: true,
    width: 12,
  },
  {
    type: 'header',
    label: 'Date of Birth',
  },
  {
    type: 'date',
    name: 'birthDate',
    label: 'Date of Birth',
    required: true,
    width: 12,
    pattern: '^(0[1-9]|1[012])[-/.](0[1-9]|[12][0-9]|3[01])[-/.](19|20)\\d\\d$',
    max: new Date(),
    minAgeRestriction: '18',
  },
  {
    type: 'email',
    name: 'email',
    label: 'Email',
    required: true,
    width: 12,
    placeholder: 'e.g. myname@example.net',
  },
  {
    type: 'text',
    name: 'firstName',
    label: 'First Name',
    required: true,
    width: 12,
  },
  {
    type: 'text',
    name: 'lastName',
    label: 'Last Name',
    required: true,
    width: 12,
  },
];

export const memberImport = (currentTenant) => [
  {
    type: 'groupNoDropdown',
    label: 'Import Users into System',
    name: 'Import_Users_into_System',
    button: 'Download Template',
    buttonVariant: 'contained',
    buttonType: 'csvImport',
    btnTooltip: 'Download User Import Template',
    csvData: memberImportCSVData,
    fields: [
      {
        type: 'file',
        name: 'files',
        label: '',
        required: true,
        width: 12,
        uploadLimit: 1,
        acceptedFiles: ['text/csv'],
        extendedLimitsDescription: `
				Please limit your CSV upload to 10,000 members in a single CSV File.
				If you need to upload more than 10,000 members, please upload in sets with a maximum of
        10,000 members per CSV. Alternatively, please contact us at support@safehealth.me
        for help uploading large amounts of records (over 100,000).`,
      },
    ],
    buttons: [
      {
        button: 'View all uploads',
        // button types: link (will redirect to new page), dialog (will open dialog)
        buttonType: 'link',
        btnTooltip: 'View all uploads',
        // if buttonType === 'link', provide link
        link: paths.viewAllUploads(currentTenant && currentTenant.id),
        // link: `/${currentTenant && currentTenant.id}/users/import/view-all-uploads`,
        // button variants: contained, outlined
        variant: 'outlined',
      },
    ],
  },
];
