export const createUserJsonBuilder = (state) => ({
  tenantId: state.tenantId,
  email: state.email,
  firstName: state.firstName,
  lastName: state.lastName,
  dateOfBirth: state.birthDate && new Date(state.birthDate),
  phone: state.mobileNumber,
  sex: state.gender,
  biometricsEnabled: true,
  isGuardianMode: state.isGuardianMode,
  userPermissionOption: state.role,
});
