import React, { useEffect, useState } from 'react';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getMasterLabTestsTenant } from '../../Orders/ordersSlice';
import { selectTenantSettings } from '../../Settings/selectors';
import AppExpansionPanelWrapper from '../../Shared/ExpansionPanel/ExpansionPanelWrapper';
import { selectCurrentTenantId } from '../../Shared/selectors';
import { selectLoadingQuestionnaires, selectOutcomes, selectLoadingSegments, selectUserSegments } from '../selectors';
import { getContent, getUserSegments } from '../virtualConsultSlice';

// imports

import { setBaseSegment } from './helpers/setBaseSegment';

export function UserSegmentPageWrapper() {
  const currentTenant = useSelector(selectCurrentTenantId);
  const outcomes = useSelector(selectOutcomes);
  const outcomesLoading = useSelector(selectLoadingQuestionnaires);
  const loadingSegments = useSelector(selectLoadingSegments);
  const userSegments = useSelector(selectUserSegments);
  const tenantSettings = useSelector(selectTenantSettings);

  const [segments, setSegments] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getMasterLabTestsTenant('orderableTests', currentTenant));

    dispatch(getUserSegments(currentTenant));
    dispatch(getContent(currentTenant, 'outcomes', '', 'id', 'asc', 0, 500));
  }, [currentTenant]);

  // create base user segment based on values in Questionnaire Outcomes
  useEffect(() => {
    if (
      userSegments &&
      userSegments.items &&
      tenantSettings &&
      userSegments.items !== segments &&
      outcomes &&
      outcomes.content &&
      !outcomesLoading
    ) {
      const data = setBaseSegment(userSegments.items, tenantSettings, outcomes && outcomes.content);
      setSegments(data);
    }
  }, [userSegments, segments, tenantSettings, outcomes, outcomesLoading]);

  const handleEdit = (data) => {
    navigate(`/${currentTenant}/virtual-consult/user-segments/details/${data.id}`);
  };

  const panelEdit = {
    // types: link (will redirect to new page), inPanel (open edit mode in panel)
    type: 'link',
    // if type === 'link', provide action
    action: (e) => handleEdit(e),
  };

  const panelChips = [
    {
      title: 'Base User Segment',
      label: 'Default',
    },
  ];

  if (!currentTenant) return <div />;

  return (
    <AppExpansionPanelWrapper
      data={segments.length && segments}
      type="segments"
      dataLoading={loadingSegments}
      currentTenant={currentTenant}
      titleValue="name"
      draggable={false}
      showSystemVariablePanel={false}
      panelPreview={false}
      panelEdit={panelEdit}
      panelChips={panelChips}
      allOutcomes={outcomes && outcomes.content}
    />
  );
}

export default UserSegmentPageWrapper;
