import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Dialog } from '@progress/kendo-react-dialogs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import {
  ContentSkeletonLoading,
  ContentSkeletonLoadingMobile,
} from '../Account/TenantManagement/TenantSkeletonLoading';
import TextFieldInput from '../Shared/Forms/KendoFields/TextFieldInput';
import { selectCurrentTenant } from '../Shared/selectors';
import { selectUserDetailsLoading } from '../Users/selectors';
import { getUser } from '../Users/usersSlice';

import { sendContactSupport, resetContactSupport } from './helpers/ContactSupportSlice';
import { subjects } from './helpers/mapping';
import { selectContactSupport } from './selectors';

function ContactSupport() {
  const userDetailsLoading = useSelector(selectUserDetailsLoading);
  const contactSupportDetails = useSelector(selectContactSupport);
  const currentTenant = useSelector(selectCurrentTenant);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [userDetails, setUserDetails] = useState(null);
  const APP_VERSION = '1.0';
  const dispatch = useDispatch();
  const params = useParams();
  const { userId } = params;
  const subjectField = {
    type: 'dropdown',
    name: 'subject',
    label: 'Subject*',
    width: 4,
    required: true,
    enum: subjects,
  };
  const messageField = {
    type: 'text',
    name: 'message',
    label: 'Message',
    required: true,
    width: 8,
    rows: 7,
  };
  const navigate = useNavigate();
  const handleCancel = () => {
    dispatch(resetContactSupport());
    navigate('/');
  };
  useEffect(() => {}, [contactSupportDetails]);

  const getUserData = async () => {
    const res = await dispatch(getUser(userId));
    if (res) {
      setUserDetails(res);
    }
  };

  useEffect(() => {
    getUserData();
  }, [currentTenant]);

  const handleSubmit = () => {
    const meta = `\nApp version: ${APP_VERSION}\nSystem version: Web app\nTenant: ${
      currentTenant.id
    }\nName: ${`${userDetails?.firstName} ${userDetails?.lastName}`}\nEmail: ${userDetails?.email}`;
    dispatch(
      sendContactSupport(
        subject,
        message,
        `${userDetails?.firstName} ${userDetails?.lastName}`,
        userDetails?.email,
        meta
      )
    );
  };

  const handleChange = (e) => {
    if (e && e.target && e.target.name == 'message') {
      setMessage(e.target.value);
    }
    if (e && e.target && e.target.name == 'subject') {
      setSubject(e.target.value);
    }
  };

  if (!currentTenant) return <div />;

  if (userDetailsLoading || !userDetails || contactSupportDetails.loading) {
    return (
      <Grid container justifyContent="center">
        {matches ? (
          <Grid item xs={12} md={7}>
            <Paper elevation={0} style={{ borderRadius: 30 }}>
              <ContentSkeletonLoading />
            </Paper>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Paper elevation={0} style={{ borderRadius: 30 }}>
              <ContentSkeletonLoadingMobile />
            </Paper>
          </Grid>
        )}
      </Grid>
    );
  }
  // profile page
  return (
    <CardContent>
      <Grid container direction="row" justifyContent="flex-start" alignItems="center">
        <Card style={{ width: '100%', marginBottom: '30px', borderRadius: '20px' }}>
          <CardContent>
            <Grid container>
              <Grid item xs={12}>
                <Typography style={{ marginBottom: '20px', fontSize: '24px' }}>Contact Support</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  style={{
                    marginBottom: '20px',
                    fontSize: '18px',
                    color: '#505358',
                  }}
                >
                  Submit an email request and a SAFE Health representative will respond to you at the earliest
                  convenience.
                </Typography>
              </Grid>
              <Grid item xs={12} md={5} style={{ marginBottom: '20px' }}>
                <DropDownList
                  label={subjectField.label || subjectField.name}
                  name={subjectField.name}
                  data={subjectField.enum || subjectField.options || []}
                  required={subjectField.required}
                  style={{ width: '100%' }}
                  textField={subjectField.labelField}
                  defaultItem={subjectField.defaultItem}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={12} style={{ marginBottom: '20px' }}>
                <TextFieldInput field={messageField} handleChange={handleChange} />
              </Grid>
              <Grid container style={{ display: 'block' }}>
                <Button
                  variant="outlined"
                  elevation={0}
                  disabled={false}
                  style={{ marginRight: '10px' }}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  elevation={0}
                  disabled={!(subject && message)}
                  style={{ float: 'right' }}
                  onClick={handleSubmit}
                >
                  Send Message
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      {contactSupportDetails.data && (
        <Dialog width={600}>
          <Grid container spacing={2} style={{ justifyContent: 'center', padding: '15px' }}>
            <Typography style={{ marginBottom: '20px', fontSize: '25px' }}>Thank you for contacting support</Typography>
            <Typography style={{ marginBottom: '20px', fontSize: '15px' }}>
              {' '}
              Please check your email for confirmation that your ticket has been submitted. You will be contacted
              shortly by a SAFE Health representative.
            </Typography>
            <Button variant="contained" color="primary" elevation={0} style={{ float: 'right' }} onClick={handleCancel}>
              RETURN TO DASHBOARD
            </Button>
          </Grid>
        </Dialog>
      )}
    </CardContent>
  );
}

export default ContactSupport;
