import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import PageviewIcon from '@material-ui/icons/Pageview';
import PersonIcon from '@material-ui/icons/Person';
import TelegramIcon from '@material-ui/icons/Telegram';
import React, { useState } from 'react';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import utils from '../../Shared/Utils/utils';
import { downloadLabel, downloadRequisition, downloadResults, renewLabTest } from '../ordersSlice';

function OrdersCustomCell(props) {
  const { dataItem, vendorSettings } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleViewTestRecord = () => {
    if (dataItem?.source === 'RAPID_TEST') {
      navigate(
        // eslint-disable-next-line
        `/${dataItem.tenantId}/diagnostics/rapid-test/${dataItem.nationalHealthId}/${dataItem.orderId}/${dataItem.userId}`,
        { state: { from: 'userDetails' } }
      );
    } else {
      navigate(
        `/${dataItem && dataItem.tenantId}/diagnostics/diagnostic-report/${dataItem.nationalHealthId}/${
          dataItem.orderId
        }/${dataItem.userId}`
      );
    }
    handleCloseMenu();
  };

  const handleViewUser = () => {
    navigate(`/${dataItem.tenantId}/users/details/${dataItem.userId}/testing`);
    handleCloseMenu();
  };

  const handleResendEmail = () => {
    if (dataItem) {
      dispatch(renewLabTest(dataItem._id, dataItem.userId, dataItem.tenantId));
      handleCloseMenu();
    }
  };

  const handleDownloadResults = () => {
    if (dataItem) {
      const name = `results_${dataItem.nationalHealthId || dataItem.userId}_${utils.formatDate(new Date())}`;
      dispatch(downloadResults(dataItem._id, name));
      handleCloseMenu();
    }
  };

  const handleDownloadRequisition = () => {
    if (dataItem) {
      const name = `requisition_${dataItem.nationalHealthId || dataItem.userId}_${dataItem._id}_${utils.formatDate(
        new Date()
      )}`;
      dispatch(downloadRequisition(dataItem._id, name));
      handleCloseMenu();
    }
  };

  const handleDownloadLabel = () => {
    if (dataItem) {
      const name = `label_${dataItem.nationalHealthId || dataItem.userId}_${dataItem._id}_${utils.formatDate(
        new Date()
      )}`;
      dispatch(downloadLabel(dataItem._id, name));
      handleCloseMenu();
    }
  };

  const getActions = () => {
    const menuItems = [];

    if (dataItem && window.location.pathname.split('/')[2] === 'diagnostics') {
      menuItems.push([
        <MenuItem key="userDetails" onClick={handleViewUser}>
          <ListItemIcon>
            <PersonIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Member Testing History</Typography>
        </MenuItem>,
        <MenuItem key="viewResult" onClick={handleViewTestRecord}>
          <ListItemIcon>
            <PageviewIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Test Details</Typography>
        </MenuItem>,
      ]);
    }
    if (
      (dataItem && window.location.pathname.split('/')[2] === 'users') ||
      window.location.pathname.split('/')[2] === 'profile'
    ) {
      menuItems.push(
        <MenuItem key="viewResult" onClick={handleViewTestRecord}>
          <ListItemIcon>
            <PageviewIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Test Details</Typography>
        </MenuItem>
      );
    }

    if (
      dataItem &&
      dataItem.status &&
      dataItem.status.toUpperCase() === 'PRE ORDERED' &&
      dataItem.source &&
      dataItem.source.toUpperCase() === 'PIXEL' &&
      !dataItem.received
    ) {
      menuItems.push(
        <MenuItem key="resend" onClick={handleResendEmail}>
          <ListItemIcon>
            <TelegramIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Resend</Typography>
        </MenuItem>
      );
    }

    // checks by vendor settings for displaying Download Result and Requisition actions
    if (
      // dataItem &&
      // (dataItem.manuallyAdded ||
      //   (!dataItem.manuallyAdded &&
      //     vendorSettings[dataItem.source]?.isResultDocumentSupported &&
      //     dataItem.status)) &&
      // dataItem.status.toUpperCase() === "COMPLETED"
      dataItem?.resultsPath
    ) {
      menuItems.push(
        <MenuItem key="results" onClick={handleDownloadResults}>
          <ListItemIcon>
            <CloudDownloadIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Results</Typography>
        </MenuItem>
      );
    }

    if (
      dataItem &&
      !dataItem.manuallyAdded &&
      vendorSettings &&
      vendorSettings[dataItem.source]?.isRequisitionSupported &&
      dataItem.status &&
      (dataItem.status.toUpperCase() === 'COMPLETED' || dataItem.status.toUpperCase() === 'PRE ORDERED')
    ) {
      menuItems.push(
        <MenuItem key="requisition" onClick={handleDownloadRequisition}>
          <ListItemIcon>
            <CloudDownloadIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Requisition</Typography>
        </MenuItem>
      );
    }

    if (
      dataItem &&
      !dataItem.manuallyAdded &&
      dataItem.source &&
      vendorSettings &&
      vendorSettings[dataItem.source]?.isSpecimenLabelSupported &&
      dataItem.status &&
      (dataItem.status.toUpperCase() === 'COMPLETED' || dataItem.status.toUpperCase() === 'PRE ORDERED')
    ) {
      menuItems.push(
        <MenuItem key="requisition" onClick={handleDownloadLabel}>
          <ListItemIcon>
            <CloudDownloadIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Specimen Label</Typography>
        </MenuItem>
      );
    }

    return menuItems;
  };

  const menu = getActions();

  if (menu && menu.length) {
    return (
      <td>
        <Grid container alignItems="center">
          <Grid item>
            <IconButton aria-controls="actions-menu" aria-haspopup="true" onClick={handleOpenMenu}>
              <MoreHorizIcon />
            </IconButton>
          </Grid>
          <Menu id="actions-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleCloseMenu}>
            {menu}
          </Menu>
        </Grid>
      </td>
    );
  }
  return (
    <td>
      <p />
    </td>
  );
}

export default OrdersCustomCell;
