import Grid from '@material-ui/core/Grid';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { NumericTextBox } from '@progress/kendo-react-inputs';
import React, { useState } from 'react';

// ui reflection of file view
function MeasurementField(props) {
  const { field, initialValue, handleChangeWithParams, disabled } = props;

  const [currentValue, setCurrentValue] = useState({
    value: initialValue && initialValue.value,
    unit:
      initialValue && field.valueField
        ? field.enum.find((s) => s[field.valueField] === initialValue.unit)
        : initialValue && initialValue.unit,
  });

  const handleValueChange = (e) => {
    const newValue = {
      ...currentValue,
      [e.target.name]: e.target.value,
    };

    setCurrentValue(newValue);

    handleChangeWithParams(
      field.name,
      field.valueField
        ? {
            ...newValue,
            unit: newValue[field.valueField],
          }
        : newValue
    );
  };

  const itemRender = (li) => {
    const itemChildren = <span>{li.props.children === 'DEFAULT' ? 'MEMBER' : li.props.children}</span>;
    return React.cloneElement(li, li.props, itemChildren);
  };

  return (
    <Grid item container spacing={2}>
      <Grid item>
        <NumericTextBox
          width="100%"
          value={currentValue && currentValue.value}
          onChange={handleValueChange}
          required={field.required}
          label={field.label}
          name="value"
          autoComplete="off"
          disabled={disabled || field.disabled || field.readOnly}
        />
      </Grid>
      <Grid item>
        <DropDownList
          label={field.unitLabel || 'Units'}
          name="unit"
          data={field.enum}
          onChange={handleValueChange}
          required={field.required}
          value={currentValue && currentValue.unit}
          itemRender={itemRender}
          disabled={disabled || field.disabled || field.readOnly}
          textField={field.labelField}
        />
      </Grid>
    </Grid>
  );
}

export default MeasurementField;
