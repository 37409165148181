export const subjects = [
  'Registration',
  'Testing',
  'Payment',
  'Feedback',
  'Account Access',
  'App Installation',
  'Technical Issue',
  'Other',
];
