// If this email regex is updated, please update in the main location - components/Shared/Forms/Helpers/Contants.js as well
export const validationRegex = {
  // eslint-disable-next-line
  email: /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/,
  phone: /^(?!(\d)\1{9})(?!0123456789|1234567890|0987654321|9876543210)\d{10}$/,
  ssn: /^(?!(000|666|9))\d{3}-(?!00)\d{2}-(?!0000)\d{4}$|^(?!(000|666|9))\d{3}(?!00)\d{2}(?!0000)\d{4}$/,
  url: /(https?|ftp):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:\\/~+#-]*[\w@?^=%&amp;\\/~+#-])?/,
  zip: /^\d{5}(\d{4})?$/,
};

export const fieldMask = {
  phone: '(999) 999-9999',
  ssn: '999-99-9999',
  zip: '99999-9999',
};
