/* eslint-disable no-template-curly-in-string */

export const tagVariables = {
  account_information: {
    name: 'Account Information',
    mergeTags: {
      account_name: {
        value: '${account_name}',
        name: 'Account name',
      },
      account_support_phone: {
        value: '${account_support_phone}',
        name: 'Account support phone number',
      },
      company_name: {
        value: '${company_name}',
        name: 'Company name',
      },
    },
  },
  app_name: {
    value: '${app_name}',
    name: 'Application Name',
  },
  app_url: {
    value: '${app_url}',
    name: 'Application URL',
  },
  encounter_id: {
    value: '${encounter_id}',
    name: 'Encounter ID',
  },
  error: {
    name: 'Error',
    mergeTags: {
      error: {
        value: '${error}',
        name: 'Error message',
      },
      message: {
        value: '${message}',
        name: 'Error message in more detail',
      },
    },
  },
  member_role: {
    value: '${member_role}',
    name: 'Role of member',
  },
  member_invite: {
    name: 'Member invite',
    mergeTags: {
      member_invite: {
        value: '${verify_email_url}',
        name: 'Invite link',
      },
      message: {
        value: '${days_elapsed}',
        name: 'Reminder elapsed time',
      },
    },
  },
  test_information: {
    name: 'Test Information',
    mergeTags: {
      test_name: {
        value: '${test_name}',
        name: 'Test name',
      },
      test_quantity: {
        value: '${test_quantity}',
        name: 'Test quantity',
      },
    },
  },
  shipping_information: {
    name: 'Shipping Information',
    mergeTags: {
      shipping_name: {
        value: '${shipping_name}',
        name: 'Shipping name',
      },
      shipping_address: {
        value: '${shipping_address}',
        name: 'Shipping address',
      },
      shipping_city: {
        value: '${shipping_city}',
        name: 'Shipping city',
      },
      shiipping_state: {
        value: '${shipping_state}',
        name: 'Shipping state',
      },
      shipping_country: {
        value: '${shipping_country}',
        name: 'Shipping country',
      },
      shipping_zip: {
        value: '${shipping_zip}',
        name: 'Shipping zip code',
      },
    },
  },
  order_information: {
    name: 'Order Information',
    mergeTags: {
      order_tracking_number: {
        value: '${test_tracking_number}',
        name: 'Order tracking number',
      },
      order_id: {
        value: '${order_id}',
        name: 'Order ID',
      },
    },
  },
  patient_information: {
    name: 'Patient Information',
    mergeTags: {
      patient_address: {
        value: '${patient_address}',
        name: 'Address of patient',
      },
      patient_dob: {
        value: '${patient_dob}',
        name: 'Patient date of birth',
      },
      patient_email: {
        value: '${patient_email}',
        name: 'Email of patient',
      },
      patient_first_name: {
        value: '${patient_first_name}',
        name: 'First name of patient',
      },
      patient_full_name: {
        value: '${patient_full_name}',
        name: 'Full name of patient',
      },
      patient_identifier: {
        value: '${patient_identifier}',
        name: 'National Health ID of patient',
      },
      patient_last_name: {
        value: '${patient_last_name}',
        name: 'Last name of patient',
      },
      patient_phone: {
        value: '${patient_phone}',
        name: 'Phone number of patient',
      },
      patient_sex: {
        value: '${patient_sex}',
        name: 'Gender of patient',
      },
    },
  },
  physician_information: {
    name: 'Physician Information',
    mergeTags: {
      physician_fax: {
        value: '${physician_fax}',
        name: 'Fax number of physician',
      },
      physician_first_name: {
        value: '${physician_first_name}',
        name: 'First name of physician',
      },
      physician_full_name: {
        value: '${physician_full_name}',
        name: 'Full name of physician',
      },
      physician_last_name: {
        value: '${physician_last_name}',
        name: 'Last name of physician',
      },
      physician_phone: {
        value: '${physician_phone}',
        name: 'Phone number of physician',
      },
    },
  },
  support_request: {
    name: 'Support Request Information',
    mergeTags: {
      privacy_email: {
        value: '${privacy_email}',
        name: 'Privacy email address',
      },
      support_body: {
        value: '${support_body}',
        name: 'Support email content',
      },
      support_subject: {
        value: '${support_subject}',
        name: 'Support email subject',
      },
      support_user: {
        value: '${support_user}',
        name: 'User email sending the support request',
      },
      support_domain: {
        value: '${support_domain}',
        name: 'Support domain link',
      },
      support_phone: {
        value: '${support_phone}',
        name: 'Support phone',
      },
      support_email: {
        value: '${support_email}',
        name: 'Support email',
      },
    },
  },
  tenant_assets: {
    name: 'Tenant Assets',
    mergeTags: {
      app_icon_rectangle: {
        value: '${app_icon_rectangle}',
        name: 'Tenant rectangle application icon',
      },
      app_icon_square: {
        value: '${app_icon_square}',
        name: 'Tenant square application icon',
      },
      background_image: {
        value: '${background_image}',
        name: 'Tenant background image',
      },
      email_logo: {
        value: '${email_logo}',
        name: 'Tenant email logo',
      },
      favicon: {
        value: '${favicon}',
        name: 'Tenant favicon',
      },
      icon: {
        value: '${icon}',
        name: 'Tenant icon',
      },
      logo: {
        value: '${logo}',
        name: 'Tenant logo',
      },
    },
  },
  tenant_information: {
    name: 'Tenant Information',
    mergeTags: {
      tenant_address_line1: {
        value: '${tenant_address_line1}',
        name: 'Tenant address line 1',
      },
      tenant_address_line2: {
        value: '${tenant_address_line2}',
        name: 'Tenant address line 2',
      },
      tenant_address_city: {
        value: '${tenant_address_city}',
        name: 'Tenant city',
      },
      tenant_address_state: {
        value: '${tenant_address_state}',
        name: 'Tenant state',
      },
      tenant_address_zip: {
        value: '${tenant_address_zip}',
        name: 'Tenant zip code',
      },
      tenant_name: {
        value: '${tenant_name}',
        name: 'Tenant name',
      },
    },
  },
  tenant_social: {
    name: 'Tenant Social Media',
    mergeTags: {
      tenant_social_blog: {
        value: '${tenant_social_blog}',
        name: 'Tenant blog link',
      },
      tenant_social_facebook: {
        value: '${tenant_social_facebook}',
        name: 'Tenant Facebook link',
      },
      tenant_social_instagram: {
        value: '${tenant_social_instagram}',
        name: 'Tenant Instagram link',
      },
      tenant_social_linkedIn: {
        value: '${tenant_social_linkedIn}',
        name: 'Tenant LinkedIn link',
      },
      tenant_social_twitter: {
        value: '${tenant_social_twitter}',
        name: 'Tenant Twitter link',
      },
      tenant_social_website: {
        value: '${tenant_social_website}',
        name: 'Tenant website link',
      },
    },
  },
  scheduled_appointment: {
    name: 'Scheduled Appointment',
    mergeTags: {
      scheduled_appointment_date: {
        value: '${scheduled_appointment_date}',
        name: 'Scheduled appointment date',
      },
      scheduled_appointment_time: {
        value: '${scheduled_appointment_time}',
        name: 'Scheduled appointment time',
      },
      scheduled_appointment_date_of_week: {
        value: '${Scheduled_appointment_date_of_week}',
        name: 'Scheduled appointment date of week',
      },
    },
  },
  scheduled_pickup: {
    name: 'Scheduled pickup',
    mergeTags: {
      scheduled_pickup_date: {
        value: '${scheduled_pickup_date}',
        name: 'Scheduled pickup date',
      },
      scheduled_pickup_time: {
        value: '${scheduled_pickup_time}',
        name: 'Scheduled pickup time',
      },
      scheduled_pickup_day_of_week: {
        value: '${scheduled_pickup_day_of_week}',
        name: 'Scheduled pickup day of week',
      },
    },
  },
  facility: {
    name: 'Facility',
    mergeTags: {
      facility_name: {
        value: '${facility_name}',
        name: 'Facility name',
      },
      facility_address_line_1: {
        value: '${facility_address_line_1}',
        name: 'Facility address line 1',
      },
      facility_address_line_2: {
        value: '${facility_address_line_2}',
        name: 'Facility address line 2',
      },
      facility_city: {
        value: '${facility_city}',
        name: 'Facility city',
      },
      facility_state: {
        value: '${facility_state}',
        name: 'Facility state',
      },
      facility_zip: {
        value: '${facility_zip}',
        name: 'Facility zip',
      },
    },
  },
};

export const variables = [
  {
    key: '${account_name}',
    description: 'Account name',
  },
  {
    key: '${account_support_phone}',
    description: 'Account support phone number',
  },
  {
    key: '${app_icon_rectangle}',
    description: 'Tenant rectangle application icon',
  },
  {
    key: '${app_icon_square}',
    description: 'Tenant square application icon',
  },
  {
    key: '${app_name}',
    description: 'Application Name',
  },
  {
    key: '${app_url}',
    description: 'Application URL',
  },
  {
    key: '${background_image}',
    description: 'Tenant background image',
  },
  {
    key: '${company_name}',
    description: 'Company name',
  },
  {
    key: '${encounter_id}',
    description: 'Encounter ID',
  },
  {
    key: '${email_logo}',
    description: 'Email logo',
  },
  {
    key: '${error}',
    description: 'Error message',
  },
  {
    key: '${favicon}',
    description: 'Tenant favicon',
  },
  {
    key: '${icon}',
    description: 'Tenant icon',
  },
  {
    key: '${logo}',
    description: 'Tenant logo',
  },
  {
    key: '${member_id}',
    description: 'Member Id',
  },
  {
    key: '${member_role}',
    description: 'Role of member',
  },
  {
    key: '${message}',
    description: 'Error message in more detail',
  },
  {
    key: '${order_id}',
    description: 'Order ID',
  },
  {
    key: '${test_tracking_number}',
    description: 'Order tracking number',
  },
  {
    key: '${patient_address}',
    description: 'Address of patient',
  },
  {
    key: '${patient_dob}',
    description: 'Patient date of birth',
  },
  {
    key: '${patient_email}',
    description: 'Email of patient',
  },
  {
    key: '${patient_first_name}',
    description: 'First name of patient',
  },
  {
    key: '${patient_full_name}',
    description: 'Full name of patient',
  },
  {
    key: '${patient_identifier}',
    description: 'National Health ID of patient',
  },
  {
    key: '${patient_last_name}',
    description: 'Last name of patient',
  },
  {
    key: '${patient_phone}',
    description: 'Phone number of patient',
  },
  {
    key: '${patient_sex}',
    description: 'Gender of patient',
  },
  {
    key: '${physician_fax}',
    description: 'Fax number of physician',
  },
  {
    key: '${physician_first_name}',
    description: 'First name of physician',
  },
  {
    key: '${physician_full_name}',
    description: 'Full name of physician',
  },
  {
    key: '${physician_last_name}',
    description: 'Last name of physician',
  },
  {
    key: '${physician_phone}',
    description: 'Phone number of physician',
  },
  {
    key: '${privacy_email}',
    description: 'Privacy email address',
  },
  {
    key: '${support_body}',
    description: 'Support email content',
  },
  {
    key: '${support_subject}',
    description: 'Support email subject',
  },
  {
    key: '${support_user}',
    description: 'User email sending the support request',
  },
  {
    key: '${support_domain}',
    description: 'Support domain link',
  },
  {
    key: '${support_phone}',
    description: 'Support phone',
  },
  {
    key: '${support_email}',
    description: 'Support email',
  },
  {
    key: '${tenant_admin_full_name}',
    description: 'Tenant Admin Full Name',
  },
  {
    key: '${tenant_address_line1}',
    description: 'Tenant address line 1',
  },
  {
    key: '${tenant_address_line2}',
    description: 'Tenant address line 2',
  },
  {
    key: '${tenant_address_city}',
    description: 'Tenant city',
  },
  {
    key: '${tenant_address_state}',
    description: 'Tenant state',
  },
  {
    key: '${tenant_address_zip}',
    description: 'Tenant zip code',
  },
  {
    key: '${tenant_name}',
    description: 'Tenant name',
  },
  {
    key: '${tenant_social_blog}',
    description: 'Tenant blog link',
  },
  {
    key: '${tenant_social_facebook}',
    description: 'Tenant Facebook link',
  },
  {
    key: '${tenant_social_instagram}',
    description: 'Tenant Instagram link',
  },
  {
    key: '${tenant_social_linkedIn}',
    description: 'Tenant LinkedIn link',
  },
  {
    key: '${tenant_social_twitter}',
    description: 'Tenant Twitter link',
  },
  {
    key: '${tenant_social_website}',
    description: 'Tenant website link',
  },
  {
    key: '${tenant_ios_app_url}',
    description: 'Tenant iOS URL',
  },
  {
    key: '${tenant_android_app_url}',
    description: 'Tenant Android URL',
  },
  {
    key: '${scheduled_appointment_date}',
    description: 'Scheduled appointment date',
  },
  {
    key: '${scheduled_appointment_time}',
    description: 'Scheduled appointment time',
  },
  {
    key: '${Scheduled_appointment_date_of_week}',
    description: 'Scheduled appointment date of week',
  },
  {
    key: '${scheduled_pickup_date}',
    description: 'Scheduled pickup date',
  },
  {
    key: '${scheduled_pickup_time}',
    description: 'Scheduled pickup time',
  },
  {
    key: '${scheduled_pickup_day_of_week}',
    description: 'Scheduled pickup day of week',
  },
  {
    key: '${facility_name}',
    description: 'Facility name',
  },
  {
    key: '${facility_address_line_1}',
    description: 'Facility address line 1',
  },
  {
    key: '${facility_address_line_2}',
    description: 'Facility address line 2',
  },
  {
    key: '${facility_city}',
    description: 'Facility city',
  },
  {
    key: '${facility_state}',
    description: 'Facility state',
  },
  {
    key: '${facility_zip}',
    description: 'Facility zip',
  },
  {
    key: '${test_name}',
    description: 'Test name',
  },
  {
    key: '${test_quantity}',
    description: 'Test quantity',
  },
  {
    key: '${shipping_name}',
    description: 'Shipping name',
  },
  {
    key: '${shipping_address}',
    description: 'Shipping address',
  },
  {
    key: '${shipping_city}',
    description: 'Shipping city',
  },
  {
    key: '${shipping_state}',
    description: 'Shipping state',
  },
  {
    key: '${shipping_country}',
    description: 'Shipping country',
  },
  {
    key: '${shipping_zip}',
    description: 'Shipping zip code',
  },
  {
    key: '${verify_email_url}',
    description: 'New user sign up link',
  },
  {
    key: '${days_elapsed}',
    description: 'New user sign up reminder elapsed time',
  },
];
