import { createSlice } from '@reduxjs/toolkit';

import api from '../../../api';
import { handleError } from '../Content/APIUtils';

const initialState = {
  error: null,
  list: null,
  loading: false,
};

export const consentsSlice = createSlice({
  name: 'consents',
  initialState,
  reducers: {
    setConsentsLoading: (state) => ({
      ...state,
      loading: true,
      error: null,
    }),
    setConsents: (state, { payload }) => ({
      ...state,
      list: payload,
      loading: false,
    }),
    setConsentsError: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
    }),
  },
});

export const { setConsentsLoading, setConsents, setConsentsError } = consentsSlice.actions;

export const getConsents = (page, pageSize, sort, direction, username, tenant) => async (dispatch) => {
  dispatch(setConsentsLoading());

  const params = {
    skip: page * pageSize,
    limit: pageSize,
    sort,
    direction,
    username,
  };
  const [result, error] = await api.getConsentsRequest(tenant, params);

  if (result) {
    dispatch(setConsents(result));
  }

  if (error && error.message) {
    /* eslint-disable-next-line no-console */
    console.log('ERROR', error);
    dispatch(setConsentsError(error));
    dispatch(handleError(error));
  }
};

export default consentsSlice.reducer;
