import { createSlice } from '@reduxjs/toolkit';

import api from '../../api';
import { showNotification } from '../Notifications/notificationSlice';
import getUserPhoto from '../Shared/Utils/getUserPhoto';

const initialState = {};

export const imagesSlice = createSlice({
  name: 'images',
  initialState,
  reducers: {
    setImagesList: (state, { payload }) => payload,
    setImage: (state, { payload }) => ({
      ...state,
      [payload.username]: payload.url,
    }),
  },
});

export const { setImagesList, setImage } = imagesSlice.actions;

export const getMemberPhotos = (usernames) => async (dispatch, getState) => {
  const uniqueUsernames = [...new Set(usernames)];
  const { images } = getState();

  const existsImagesKeys = Object.keys(images);
  if (existsImagesKeys.length > 10) {
    existsImagesKeys.forEach((username) => {
      URL.revokeObjectURL(images[username]);
      delete images[username];
    });
  }

  await Promise.all(
    uniqueUsernames.map(async (username) => {
      if (images[username]) return;

      images[username] = await getUserPhoto(username);

      return dispatch(setImagesList({ ...images }));
    })
  );
};

export const setMemberPhoto = (username, file) => async (dispatch) => {
  const [result, error] = await api.setMemberPhotoRequest(username, file);

  if (result) {
    dispatch(setImage({ username, url: URL.createObjectURL(file) }));
  }

  if (error && error.message) {
    dispatch(showNotification('Image uploading error', 'error'));
  }
};

export default imagesSlice.reducer;
