import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Edit from '@material-ui/icons/Edit';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import React, { useState } from 'react';
import CovidVaxStatusDialog from './CovidVaxStatusDialog';

function CovidVaxStatusCustomCell(props) {
  const { dataItem } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [covidVaxStatusDialogOpen, setCovidVaxStatusDialogOpen] = useState(false);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleEditVaxStatus = () => {
    setCovidVaxStatusDialogOpen(true);
    handleCloseMenu();
  };

  const onCloseDialog = () => {
    setCovidVaxStatusDialogOpen(false);
  };

  const getActions = () => {
    const menuItems = [];
    menuItems.push([
      <MenuItem key="editVaxStatus" onClick={handleEditVaxStatus}>
        <ListItemIcon>
          <Edit fontSize="small" />
        </ListItemIcon>
        <Typography variant="inherit">Edit</Typography>
      </MenuItem>,
    ]);

    return menuItems;
  };

  const menu = getActions();

  if (menu && menu.length) {
    return (
      <td>
        <Grid container alignItems="center">
          <Grid item>
            <IconButton aria-controls="actions-menu" aria-haspopup="true" onClick={handleOpenMenu}>
              <MoreHorizIcon />
            </IconButton>
          </Grid>
          <Menu id="actions-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleCloseMenu}>
            {menu}
          </Menu>
        </Grid>
        {covidVaxStatusDialogOpen && (
          <CovidVaxStatusDialog closeDialog={onCloseDialog} selectedVaxStatus={dataItem} dialogMode="Edit" />
        )}
      </td>
    );
  }
  return (
    <td>
      <p />
    </td>
  );
}

export default CovidVaxStatusCustomCell;
