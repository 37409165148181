import Grid from '@material-ui/core/Grid';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { initForm } from '../../../../Shared/Forms/formSlice';
import { selectFormData } from '../../../../Shared/Forms/selectors';
import { selectCurrentTenant } from '../../../../Shared/selectors';
import OutcomeBuilderWrapper from '../../../OutcomesBuilder/OutcomeBuilderWrapper';
import { handlePasswordComplexity } from '../../../QuestionBuilder/helpers/questionJsonBuilder';
import QuestionBuilderWrapper from '../../../QuestionBuilder/QuestionBuilderWrapper';
import { setEditingReviewerQuestion } from '../../../Reviewer/reviewerSlice';

function EditingQuestionWrapper(props) {
  const {
    editingQuestion,
    setEditingQuestion,
    questionnaireData,
    handleQuestionUpdateCancel,
    nodes,
    setNodes,
    selectedQuestions,
    setSelectedQuestions,
    handleSubmit,
    setQuestionnaireUpdated,
  } = props;

  const formData = useSelector(selectFormData);
  const currentTenant = useSelector(selectCurrentTenant);

  const dispatch = useDispatch();

  const handleQuestionUpdate = async () => {
    let updatedQuestionnaireData;
    let saveNodes;
    let saveQuestions = [...selectedQuestions];
    if (
      editingQuestion.type === 'test' ||
      editingQuestion.type === 'instructions' ||
      editingQuestion.type === 'prescription' ||
      editingQuestion.type === 'v_consultation' ||
      editingQuestion.type === 'alarm'
    ) {
      const updatedOutcome = [...nodes];
      const data = formData;
      nodes.forEach((item, i) => {
        if (item && item.outcome && item.outcome.id === formData.id) {
          updatedOutcome[i].outcome = data;
          return updatedOutcome;
        }
      });
      updatedQuestionnaireData = {
        ...questionnaireData,
        nodes: updatedOutcome,
      };
      saveNodes = [...updatedOutcome];
    } else {
      let updatedQuestions = [...selectedQuestions];
      let updatedNodes = [...nodes];
      const data = formData;

      // Create scale options from min and max values
      if (data.type === 'scale') {
        data.options = [];
        for (let i = data.scaleMin; i <= data.scaleMax; i++) {
          data.options.push(i);
        }
      }

      // Assemble password complexity string for 'password' questions
      if (formData.type === 'password') {
        data.complexity = handlePasswordComplexity(data);
      }

      // Find the question in the question list and update
      let oldNodeList;
      selectedQuestions.forEach((item, i) => {
        if (item && item.id === formData.id) {
          // Grab node list for combo question to compare and adjust subnode questions in questionnaire
          oldNodeList = item.type === 'combo' && item.nodeList.map((subnode) => subnode.questionId);
          updatedQuestions[i] = data;
          return updatedQuestions;
        }
      });

      // Find and update node in the node list
      nodes.forEach((item, i) => {
        if (item && item.question && item.question.id === formData.id) {
          updatedNodes[i].question = data;
          updatedNodes[i].name = data.name;
          return updatedNodes;
        }
      });

      // Update combo node subnode questions
      if (data.type === 'combo') {
        const comboIndex = selectedQuestions.findIndex((question) => question.id === data.id);
        data.nodeList.forEach((subnode, idx) => {
          const expectedIndex = comboIndex + idx + 1;
          const subnodeIndex = selectedQuestions.findIndex((question) => question.id === subnode.questionId);
          if (subnodeIndex !== expectedIndex) {
            // The subnode is out of order in the question list and needs to be moved
            const subnodeToMove = updatedQuestions.splice(subnodeIndex, 1);
            const newIndex = subnodeIndex < expectedIndex ? expectedIndex - 1 : expectedIndex;
            updatedQuestions.splice(newIndex, 0, ...subnodeToMove);
          }
          // Check that subnode question does not exist in node list and remove it if needed
          updatedNodes = updatedNodes.filter((node) => node.question?.id !== subnode.questionId);
        });

        // Check if any subnodes have been removed and remove them from the question list
        oldNodeList.forEach((oldSubnode) => {
          if (!data.nodeList.some((subnode) => subnode.questionId === oldSubnode)) {
            updatedQuestions = updatedQuestions.filter((question) => question.id !== oldSubnode);
          }
        });
      }

      updatedQuestionnaireData = {
        ...questionnaireData,
        questionList: updatedQuestions,
        nodes: updatedNodes,
      };
      saveQuestions = [...updatedQuestions];
      saveNodes = [...updatedNodes];
    }
    setQuestionnaireUpdated(true);
    setEditingQuestion({});
    setSelectedQuestions(saveQuestions);
    setNodes(saveNodes);
    dispatch(setEditingReviewerQuestion(null));
    dispatch(initForm(updatedQuestionnaireData));
    handleSubmit(saveQuestions, saveNodes, updatedQuestionnaireData);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        {editingQuestion.type === 'test' ||
        editingQuestion.type === 'instructions' ||
        editingQuestion.type === 'prescription' ||
        editingQuestion.type === 'v_consultation' ||
        editingQuestion.type === 'alarm' ? (
          <OutcomeBuilderWrapper
            editOutcome={editingQuestion}
            currentTenant={currentTenant.id}
            handleQuestionUpdate={handleQuestionUpdate}
            handleCancel={handleQuestionUpdateCancel}
          />
        ) : (
          <QuestionBuilderWrapper
            editQuestion={editingQuestion}
            currentTenant={currentTenant.id}
            handleQuestionUpdate={handleQuestionUpdate}
            handleCancel={handleQuestionUpdateCancel}
            selectedQuestions={selectedQuestions}
          />
        )}
      </Grid>
    </Grid>
  );
}

export default EditingQuestionWrapper;
