import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { useTheme } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import NoAccessView from '../../views/NoAccessView';
import Clinical from '../ClinicalInformation/Clinical';
import { selectTenantNetwork } from '../Networks/selectors';
import Profile from '../Profile/Profile';
import { selectTenantSettings } from '../Settings/selectors';
import AccessControl from '../Shared/AccessControl';
import { selectCurrentTenant } from '../Shared/selectors';
import Simulator from '../Simulator/Simulator';
import { selectCurrentUserPermissions } from '../Users/selectors';

function Member(props) {
  const { type } = props;
  const currentTenant = useSelector(selectCurrentTenant);
  const tenantSettings = useSelector(selectTenantSettings);

  const currentUserPermissions = useSelector(selectCurrentUserPermissions);
  const tenantNetworkSettings = useSelector(selectTenantNetwork);
  const theme = useTheme();
  const [value, setValue] = useState(type === 'simulator' ? 'simulator' : 0);
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  let pageComponent;
  useEffect(() => {
    const splitUrl = window.location.href.split('/');
    if (splitUrl[splitUrl.length - 1] !== '') {
      if (
        splitUrl[splitUrl.length - 1] === 'clinical' ||
        splitUrl[splitUrl.length - 2] === 'clinical' ||
        splitUrl[splitUrl.length - 3] === 'clinical'
      ) {
        setValue(1);
      }
    }
  }, [type]);

  const handleChange = (e, value) => {
    setValue(value);
    let path;
    if (value === 1) {
      path = 'clinical';
    }
    if (value === 'simulator') {
      path = 'simulator';
    }

    const splitUrl = window.location.href.split('/');
    if (splitUrl[splitUrl.length - 2] === 'profile' || splitUrl[splitUrl.length - 2] === 'details') {
      if (value !== 0) {
        window.history.pushState(path, path, `${splitUrl.join('/')}/${path}`);
      }
    } else {
      splitUrl.pop();
      if (value === 0) {
        window.history.pushState(path, path, `${splitUrl.join('/')}`);
      } else {
        window.history.pushState(path, path, `${splitUrl.join('/')}/${path}`);
      }
    }
  };

  switch (value) {
    case 1:
      pageComponent = tenantSettings?.menuItems['members/clinical'] ? (
        <AccessControl
          deniedPermissions={['ADMINISTRATOR_RESTRICTED']}
          renderNoAccess={() => <NoAccessView permissionsNeeded="Administrator" />}
        >
          <Clinical type={type} />
        </AccessControl>
      ) : (
        <NoAccessView />
      );
      break;
    case 'simulator':
      pageComponent =
        tenantNetworkSettings?.enableSimulator &&
        (currentUserPermissions?.permission === 'ADMINISTRATOR' ||
          currentUserPermissions?.permission === 'ADMINISTRATOR_RESTRICTED') ? (
          <AccessControl
            allowedPermissions={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}
            renderNoAccess={() => <NoAccessView permissionsNeeded="Administrator" />}
          >
            <Simulator />
          </AccessControl>
        ) : (
          <NoAccessView />
        );
      break;
    default:
      pageComponent = <Profile type={type} />;
      break;
  }

  if (!currentTenant) return <div />;

  return (
    <Grid item xs={12}>
      <Grid item xs={4}>
        {matches ? (
          <Tabs
            value={value}
            indicatorColor="primary"
            variant="fullWidth"
            textColor="primary"
            onChange={handleChange}
            aria-label="disabled tabs example"
          >
            <Tab label="Demographics" style={{ fontSize: '12px', marginBottom: '-10px' }} />
            {tenantSettings?.menuItems['members/clinical'] &&
              currentUserPermissions?.permission === 'ADMINISTRATOR' && (
                <Tab label="Clinical" id="clinical" style={{ fontSize: '12px', marginBottom: '-10px' }} />
              )}
            {tenantNetworkSettings?.enableSimulator &&
              (currentUserPermissions?.permission === 'ADMINISTRATOR' ||
                currentUserPermissions?.permission === 'ADMINISTRATOR_RESTRICTED') && (
                <Tab
                  label="Simulator"
                  id="simulator"
                  style={{ fontSize: '12px', marginBottom: '-10px' }}
                  value={'simulator'}
                />
              )}
          </Tabs>
        ) : (
          // MOBILE SELECTION VIEW
          <FormControl variant="outlined" fullWidth>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={value}
              onChange={(e) => setValue(e.target.value)}
            >
              <MenuItem value={0} key={0}>
                Demographics
              </MenuItem>
              {currentUserPermissions?.permission === 'ADMINISTRATOR' && (
                <MenuItem value={1} key={1}>
                  Clinical
                </MenuItem>
              )}
              {tenantNetworkSettings?.enableSimulator &&
                (currentUserPermissions?.permission === 'ADMINISTRATOR' ||
                  currentUserPermissions?.permission === 'ADMINISTRATOR_RESTRICTED') && (
                  <MenuItem key={2} value={'simulator'}>
                    Simulator
                  </MenuItem>
                )}
            </Select>
          </FormControl>
        )}
      </Grid>
      <div style={{ marginTop: 10 }}>{pageComponent}</div>
    </Grid>
  );
}

export default Member;
