export const initialSorting = {
  LOGIN_REPORT: {
    field: 'LoginUnix',
    dir: 'desc',
  },
  DEFAULT: {
    field: 'Email',
    dir: 'desc',
  }
};

export const transform = (list, page, pageSize) => ({
  ...list,
  items:
    list &&
    list.items &&
    // eslint-disable-next-line array-callback-return
    list.items.filter((report, i) => {
      if (i >= page * pageSize && i < page * pageSize + pageSize) {
        return {
          ...report,
        };
      }
    }),
});

const ASSESSMENT_REPORT = [
  {
    id: 'User ID',
    label: 'User ID',
    show: true,
    sortable: true,
    minWidth: 170,
  },
  {
    id: 'Email',
    label: 'Email',
    show: true,
    locked: true,
    minWidth: 170,
    sortable: true,
  },
  {
    id: 'Date Of Birth',
    label: 'Date Of Birth',
    show: true,
    minWidth: 170,
    sortable: true,
  },
  {
    id: 'Questionnaire Completed On',
    label: 'Questionnaire Completed On',
    show: true,
    minWidth: 170,
    sortable: true,
  },
  {
    id: 'Outcome',
    label: 'Outcome',
    show: true,
    filter: true,
    minWidth: 225,
    sortable: true,
    // cell: htmlUtils.withMapping(htmlUtils.tag, statusMapping),
  },
  {
    id: 'Clearance Status',
    label: 'Clearance Status',
    show: true,
    filter: true,
    minWidth: 170,
    sortable: true,
  },
  {
    id: 'User Segment',
    label: 'User Segment',
    show: true,
    filter: true,
    minWidth: 170,
    sortable: true,
  },
  // {
  //   id: "action",
  //   label: "Action",
  //   show: true,
  //   minWidth: 100,
  //   cell: ReportsCustomCell,
  //   sortable: false,
  // },
];

const SCAN_REPORT = [
  {
    id: 'ID',
    label: 'ID',
    show: false,
    sortable: false,
    minWidth: 170,
  },
  {
    id: 'User ID',
    label: 'User ID',
    show: true,
    sortable: true,
    minWidth: 170,
  },
  {
    id: 'Email',
    label: 'Email',
    show: true,
    locked: true,
    minWidth: 170,
    sortable: true,
  },
  {
    id: 'Full Name',
    label: 'Full Name',
    show: true,
    minWidth: 170,
    sortable: true,
  },
  {
    id: 'Phone Number',
    label: 'Phone Number',
    show: true,
    minWidth: 170,
    sortable: true,
  },
  {
    id: 'Checked On',
    label: 'Checked On',
    show: true,
    minWidth: 170,
    sortable: true,
  },
  {
    id: 'Checked By',
    label: 'Checked By',
    show: true,
    minWidth: 170,
    sortable: true,
  },
  {
    id: 'Outcome',
    label: 'Outcome',
    show: true,
    filter: true,
    minWidth: 170,
    sortable: true,
  },
  {
    id: 'Overridden Outcome',
    label: 'Overridden Outcome',
    show: true,
    minWidth: 170,
    sortable: true,
  },
  {
    id: 'Overridden Reason',
    label: 'Overridden Reason',
    show: true,
    minWidth: 170,
    sortable: true,
  },
  {
    id: 'Overridden On',
    label: 'Overridden On',
    show: true,
    minWidth: 170,
    sortable: true,
  },
  {
    id: 'Overridden By',
    label: 'Overridden By',
    show: true,
    minWidth: 170,
    sortable: true,
  },
  {
    id: 'Questionnaire Expired',
    label: 'Questionnaire Expired',
    show: true,
    filter: true,
    minWidth: 170,
    sortable: true,
  },
  {
    id: 'Symptomatic',
    label: 'Symptomatic',
    show: true,
    filter: true,
    minWidth: 170,
    sortable: true,
    // cell: htmlUtils.withMapping(htmlUtils.tag, statusMapping),
  },
  // {
  //   id: "action",
  //   label: "Action",
  //   show: true,
  //   minWidth: 100,
  //   cell: ReportsCustomCell,
  //   sortable: false,
  // },
];

const USER_REPORT = [
  {
    id: 'ID',
    label: 'ID',
    show: false,
    sortable: false,
    minWidth: 170,
  },
  {
    id: 'User ID',
    label: 'User ID',
    show: true,
    sortable: true,
    minWidth: 170,
  },
  {
    id: 'Date of Birth',
    label: 'Date of Birth',
    show: true,
    locked: true,
    minWidth: 170,
    sortable: true,
  },
  {
    id: 'Email',
    label: 'Email',
    show: true,
    minWidth: 170,
    sortable: true,
  },
  {
    id: 'Full Name',
    label: 'Full Name',
    show: true,
    minWidth: 170,
    sortable: true,
  },
  {
    id: 'Gender',
    label: 'Gender',
    show: true,
    filter: true,
    minWidth: 170,
    sortable: true,
  },
  {
    id: 'State',
    label: 'State',
    show: true,
    minWidth: 170,
    sortable: true,
  },
  {
    id: 'TenantId',
    label: 'TenantId',
    show: true,
    minWidth: 170,
    sortable: true,
  },
  {
    id: 'Account Creation Date',
    label: 'Account Creation Date',
    show: true,
    minWidth: 170,
    sortable: true,
  },
  {
    id: 'Zip',
    label: 'Zip',
    show: true,
    minWidth: 170,
    sortable: true,
  },
  // {
  //   id: "action",
  //   label: "Action",
  //   show: true,
  //   minWidth: 100,
  //   cell: ReportsCustomCell,
  //   sortable: false,
  // },
];

const LABTEST_REPORT = [
  {
    id: 'ID',
    label: 'ID',
    show: false,
    sortable: false,
    minWidth: 170,
  },
  {
    id: 'User ID',
    label: 'User ID',
    show: true,
    sortable: true,
    minWidth: 170,
  },
  {
    id: 'Resulted Date',
    label: 'Resulted Date',
    show: true,
    minWidth: 170,
    sortable: true,
  },
  {
    id: 'Result',
    label: 'Result',
    show: true,
    minWidth: 170,
    sortable: true,
  },
  {
    id: 'Test Date',
    label: 'Test Date',
    show: true,
    filter: true,
    minWidth: 170,
    sortable: true,
  },
  {
    id: 'Test Name',
    label: 'Test Name',
    show: true,
    filter: true,
    minWidth: 170,
    sortable: true,
  },
  {
    id: 'Age',
    label: 'Age',
    show: true,
    locked: true,
    minWidth: 70,
    sortable: true,
  },
  {
    id: 'Gender',
    label: 'Gender',
    show: true,
    filter: true,
    minWidth: 170,
    sortable: true,
  },
  {
    id: 'User Segment',
    label: 'User Segment',
    show: true,
    minWidth: 170,
    sortable: true,
  },
  // {
  //   id: "action",
  //   label: "Action",
  //   show: true,
  //   minWidth: 100,
  //   cell: ReportsCustomCell,
  //   sortable: false,
  // },
];

const CRO_REPORT = [
  {
    id: 'ID',
    label: 'ID',
    show: false,
    sortable: false,
    minWidth: 170,
  },
  {
    id: 'First Name',
    label: 'First Name',
    show: true,
    sortable: true,
    minWidth: 170,
  },
  {
    id: 'Last Name',
    label: 'Last Name',
    show: true,
    sortable: true,
    minWidth: 170,
  },
  {
    id: 'Type',
    label: 'Type',
    show: true,
    locked: true,
    minWidth: 170,
    sortable: true,
  },
  {
    id: 'Date',
    label: 'Date',
    show: true,
    minWidth: 170,
    sortable: true,
  },
  {
    id: 'Result',
    label: 'Result',
    show: true,
    minWidth: 170,
    sortable: true,
  },
];

// todo: Report Type needs JAVA backend implementation
const PENDING_INVITES = [
  {
    id: 'id',
    label: 'ID',
    show: false,
    sortable: false,
    minWidth: 170,
  },
  {
    id: 'Created',
    label: 'Created',
    show: true,
    minWidth: 170,
    sortable: true,
  },
];

const ACTIVE_USER_REPORT = [
  {
    id: 'Tenant ID',
    label: 'Tenant ID',
    show: true,
    sortable: true,
    minWidth: 170,
  },
  {
    id: 'User Role',
    label: 'User Role',
    show: true,
    minWidth: 170,
    sortable: true,
  },
  {
    id: 'User ID',
    label: 'User ID',
    show: true,
    sortable: true,
    minWidth: 170,
  },
  {
    id: 'First Name',
    label: 'First Name',
    show: true,
    minWidth: 170,
    sortable: true,
  },
  {
    id: 'Last Name',
    label: 'Last Name',
    show: true,
    minWidth: 170,
    sortable: true,
  },
  {
    id: 'User Creation Date',
    label: 'User Creation Date',
    show: true,
    minWidth: 170,
    sortable: true,
  },
  {
    id: 'Last Login Date',
    label: 'Last Login Date',
    show: true,
    minWidth: 170,
    sortable: true,
  },
];

const LOGIN_REPORT = [
  {
    id: 'User ID',
    label: 'User ID',
    show: true,
    sortable: true,
    minWidth: 170,
  },
  {
    id: 'TenantId',
    label: 'Tenant ID',
    show: true,
    minWidth: 170,
    sortable: true,
  },
  {
    id: 'Log-in Time Stamp',
    label: 'Log-in Time Stamp',
    show: true,
    minWidth: 170,
    sortable: true,
  },
];

export const reportColumns = (type) => {
  switch (type) {
    case 'ASSESSMENT_REPORT':
      return ASSESSMENT_REPORT;
    case 'SCAN_REPORT':
      return SCAN_REPORT;
    case 'USER_REPORT':
      return USER_REPORT;
    case 'LABTEST_REPORT':
      return LABTEST_REPORT;
    case 'CRO_REPORT':
      return CRO_REPORT;
    case 'PENDING_INVITES':
      return PENDING_INVITES;
    case 'ACTIVE_USER_REPORT':
      return ACTIVE_USER_REPORT;
    case 'LOGIN_REPORT':
      return LOGIN_REPORT;
    default:
      return [];
  }
};

export const tenantDropdownProps = (tenants, disabled = false) => ({
  type: 'multiselectDropdownField',
  name: 'tenants',
  label: 'Tenants',
  selectField: 'tenants',
  enum:
    tenants?.map((item) => ({
      label: item.title,
      value: item.id,
    })) || [],
  overrideStrings: {
    selectSomeItems: 'Tenants',
    allItemsAreSelected: 'All Tenants Enabled',
    selectAll: 'Select All',
    search: 'Search',
  },
  isCustomValueRenderer: true,
  valueTextTemplate: `$value Tenants Enabled`,
  allSelectedText: 'All Tenants Enabled',
  disabled,
  required: false,
  hideSelected: true,
  width: 4,
  description: 'Select tenant',
});
