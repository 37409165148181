// columns to pass to table
import * as htmlUtils from '../../../Shared/Grid/helpers';
import SettingsActionsCellWrapper from '../SettingsActionsCellWrapper';

import { statusMapping } from './mappings';

export const initialSorting = {
  field: 'name',
  dir: 'asc',
};

export const columns = [
  {
    id: 'name',
    label: 'Message Name',
    show: true,
    locked: true,
    width: 170,
    sort: false,
  },
  {
    id: 'type',
    label: 'Type',
    show: true,
    width: 170,
    sort: false,
  },
  {
    id: 'status',
    label: 'Status',
    show: true,
    width: 170,
    sortable: false,
    cell: htmlUtils.withMapping(htmlUtils.tag, statusMapping),
  },
  {
    id: 'action',
    label: 'Action',
    show: true,
    width: 170,
    sort: false,
    cell: SettingsActionsCellWrapper,
  },
];

export const tableButtons = (setOpen, importZIPLoading, exportZipLoading, handleExport, setCreate) => [
  {
    title: 'Create Message',
    buttonType: 'action',
    action: () => setCreate(true),
    variant: 'outlined',
    color: 'primary',
  },
  {
    title: 'Import Messages',
    buttonType: 'action',
    action: () => setOpen(true),
    variant: 'outlined',
    color: 'primary',
    disabled: importZIPLoading,
    loading: importZIPLoading,
  },
  {
    title: 'Export All Messages',
    buttonType: 'action',
    action: () => handleExport(),
    variant: 'outlined',
    color: 'primary',
    disabled: exportZipLoading,
    loading: exportZipLoading,
  },
];
