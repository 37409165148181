import React from 'react';
import ContentLoader from 'react-content-loader';

export function VaccinationRecordImageSkeletonLoading(props) {
  return (
    <ContentLoader
      // height={54}
      // width={320}
      width={400}
      speed={0.8}
      viewBox="0 0 400 400"
      backgroundColor="#ecebeb"
      foregroundColor="#f3f3f3"
      {...props}
    >
      <rect x="0" y="0" rx="4" ry="4" width="400" height="400" />
    </ContentLoader>
  );
}
