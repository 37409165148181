/* eslint-disable react/no-array-index-key */
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { FileIcon } from 'react-file-icon';
import { useDispatch } from 'react-redux';

import ImporterStyles from '../../../../assets/jss/components/ImporterStyles';
import { showNotification } from '../../../Notifications/notificationSlice';
import utils from '../../Utils/utils';

import logo from './resources/upload-empty.png';

function FileUploader({ field, value, handleChangeWithParams, classes, disabled }) {
  const [files, setFiles] = useState(field.value || []);
  const [loading] = useState(false);
  const [currentValue, setCurrentValue] = useState(value);
  const dispatch = useDispatch();

  useEffect(() => {
    if (value !== currentValue) {
      setCurrentValue(value);
    }
    // eslint-disable-next-line
  }, [value]);

  const { getRootProps, getInputProps } = useDropzone({
    disabled,
    accept: field.acceptedFiles && field.acceptedFiles.join(', '),
    onDrop: (acceptedFiles, fileRejections) => {
      if (field.uploadLimit && acceptedFiles.length + files.length > field.uploadLimit) {
        dispatch(
          showNotification(
            // "Only upload one file",
            'Please upload additional CSV files separately.',
            'error'
          )
        );
      } else if (fileRejections.length && fileRejections[0].errors.length) {
        fileRejections[0].errors.forEach((error) => {
          dispatch(showNotification(error.message, 'error'));
        });
      } else {
        const existingFiles = files.slice();
        acceptedFiles.forEach((f) => {
          existingFiles.push(f);
        });
        setFiles(existingFiles);
      }
    },
  });

  useEffect(() => {
    handleChangeWithParams(field.name, files);
    setCurrentValue(files);
  }, [field.name, files, handleChangeWithParams]);

  const removeFileFromList = (idx) => {
    const existingFiles = files.slice();
    existingFiles.splice(idx, 1);
    setFiles(existingFiles);
  };

  const dropZoneStyle = {
    outline: 'none',
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <div className={!currentValue ? classes.box : classes.boxPreview}>
          <Grid container>
            <Grid item xs={12} {...getRootProps({ style: dropZoneStyle })}>
              <input {...getInputProps()} />
              <div className={classes.thumbnail}>
                {loading ? <CircularProgress /> : <img src={logo} style={{ maxWidth: '100%' }} alt="thumbnail" />}
              </div>
              <Button
                className={classes.button}
                variant="contained"
                disableElevation
                color="primary"
                disabled={loading || disabled}
              >
                Browse
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="caption" style={{ opacity: 0.5 }}>
                Or drag files here.
              </Typography>
            </Grid>
          </Grid>
        </div>
      </Grid>
      {field.acceptedFiles && (
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          {field.acceptedFiles && (
            <Typography variant="caption" component="p" style={{ opacity: 0.5 }}>
              {`Allowed file types: ${field.acceptedFiles
                .map((f) => f.substring(f.indexOf('/') + 1).toUpperCase())
                .join(', ')}`}
            </Typography>
          )}
        </Grid>
      )}
      {field.extendedLimitsDescription && (
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          {field.extendedLimitsDescription && (
            <Typography variant="caption" component="p" style={{ opacity: 0.5 }}>
              {field.extendedLimitsDescription}
            </Typography>
          )}
        </Grid>
      )}
      {files && files.length > 0 && (
        <Grid item container spacing={2}>
          <Grid item xs={12}>
            <Typography style={{ fontWeight: 'bold' }}>Selected Files</Typography>
          </Grid>
          {files.map((f, idx) => (
            <Grid key={`file_${idx}`} item className={classes.file}>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Grid container spacing={2}>
                    <Grid item>
                      <FileIcon extension={f.name.substring(f.name.lastIndexOf('.') + 1)} size={45} />
                    </Grid>
                    <Grid item>
                      <Grid container direction="column">
                        <Grid item>
                          <Typography>{f.name}</Typography>
                        </Grid>
                        <Grid item>
                          <Typography style={{ opacity: 0.5 }}>{utils.toUserFriendlySize(f.size)}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <IconButton onClick={() => removeFileFromList(idx)} disabled={disabled}>
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      )}
    </Grid>
  );
}

export default withStyles(ImporterStyles)(FileUploader);
