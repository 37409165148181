/* eslint-disable react/no-array-index-key */
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import React, { useEffect, useState } from 'react';

// redux
import { useDispatch, useSelector } from 'react-redux';

import UserSegmentsSettingsStyles from '../../../assets/jss/components/UserSegmentsSettingsStyles';
import { showConfirmation } from '../../Confirmation/confirmationSlice';
import { selectUserSegments } from '../../VirtualConsult/selectors';
import { createUserSegment, getUserSegments, updateUserSegment } from '../../VirtualConsult/virtualConsultSlice';

// imports

// mui

import UserSegmentsEditorDialog from './UserSegmentsEditorDialog';

export function UserSegmentsListWrapper(props) {
  const { currentTenant, classes } = props;

  const userSegments = useSelector(selectUserSegments);

  const [segments, setSegments] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [editingUserSegment, setEditingUserSegment] = useState(null);
  const [updateUserSegmentNameLoading, setUpdateUserSegmentNameLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserSegments(currentTenant.id));
  }, [currentTenant.id]);

  useEffect(() => {
    if (userSegments && userSegments.items) {
      setSegments(userSegments.items);
    }
  }, [userSegments]);

  const handleDelete = (userSegmentId) => {
    dispatch(
      showConfirmation(
        'Confirm',
        'Are you sure you want to delete this User Segment?',
        'segmentDelete',
        currentTenant,
        userSegmentId
      )
    );
  };

  const handleUpdateUserSegmentName = async (userSegmentEdited) => {
    setUpdateUserSegmentNameLoading(true);

    if (userSegmentEdited.id) {
      await dispatch(updateUserSegment(currentTenant.id, userSegmentEdited, 'settings'));
      // await actions.updateUserSegment(currentTenant.id, userSegmentEdited);
    } else {
      await dispatch(createUserSegment(currentTenant.id, userSegmentEdited));
      // await actions.addUserSegment(currentTenant.id, userSegmentEdited.name);
    }

    closeDialog();
    setUpdateUserSegmentNameLoading(false);
    setEditingUserSegment(null);
  };

  const handleOpenEditing = (editingItem) => {
    setEditingUserSegment(editingItem);
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
    setEditingUserSegment(null);
  };

  const handleAddUserSegment = () => {
    setEditingUserSegment({
      default: false,
      id: '',
      name: '',
      tenantId: '',
      testSettings: {
        byOutcome: {},
      },
    });
    setDialogOpen(true);
  };

  return (
    <Grid container className={classes.container}>
      <>
        {segments && segments.length
          ? segments.map((item, key) => (
              <Grid container key={key}>
                <Grid item xs={10} md={4} style={{ paddingLeft: 6 }}>
                  <TextField
                    label="User Segment Name"
                    name="userSegmentName"
                    margin="normal"
                    value={item.name}
                    autoComplete="off"
                    type="text"
                    fullWidth
                    disabled
                    variant="outlined"
                  />
                </Grid>

                <Grid container item alignItems="flex-end" justifyContent="flex-start" xs={2} md={8}>
                  {item.default ? (
                    <Grid style={{ paddingLeft: 14, paddingBottom: 8 }}>
                      <Chip label="Default" />
                    </Grid>
                  ) : (
                    <>
                      <Tooltip title="Edit User Segment Name">
                        <IconButton onClick={() => handleOpenEditing(item)}>
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete User Segment Name">
                        <IconButton onClick={() => handleDelete(item.id)} data-testid="expansionPanelDeleteBtn">
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                </Grid>
              </Grid>
            ))
          : null}

        <Grid item xs={12} style={{ marginTop: '27px' }}>
          <Button color="primary" variant="contained" onClick={handleAddUserSegment}>
            Add User Segment
          </Button>
        </Grid>
      </>

      {dialogOpen && (
        <UserSegmentsEditorDialog
          segments={segments}
          onCloseDialog={closeDialog}
          updateUserSegmentNameLoading={updateUserSegmentNameLoading}
          editingUserSegment={editingUserSegment}
          onHandleUpdateUserSegmentName={handleUpdateUserSegmentName}
        />
      )}
    </Grid>
  );
}

export default withStyles(UserSegmentsSettingsStyles)(UserSegmentsListWrapper);
