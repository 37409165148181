import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import { withStyles } from '@material-ui/core/styles';
import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import React from 'react';
import { useSelector } from 'react-redux';

// component and styling imports

// material-ui

import NavBarStyles from '../../assets/jss/components/NavBarStyles';
import { selectProfilePicture, selectUserAccountPermission } from '../Users/selectors';

import DropdownBox from './DropdownBox';

function DesktopMenu(props) {
  const { classes, user, logout, currentTenant } = props;
  const profilePicture = useSelector(selectProfilePicture);
  const userAccountPermission = useSelector(selectUserAccountPermission);

  const fullName = user ? `${user.firstName} ${user.lastName}` : '';

  return (
    <PopupState variant="popover" popupId="dropdown-menu">
      {(popupState) => (
        <div style={{ margin: 'auto' }}>
          <Button
            disableElevation
            variant="contained"
            color="primary"
            style={{ textTransform: 'none' }}
            {...bindTrigger(popupState)}
          >
            <Avatar alt="Profile Picture" src={profilePicture && profilePicture.src} className={classes.avatar} />
            {fullName}
            <ArrowDropDownRoundedIcon className={classes.arrowIcon} fontSize="small" />
          </Button>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            disableScrollLock
          >
            <DropdownBox
              onMenuClick={popupState.close}
              logout={logout}
              user={user}
              popupState={popupState}
              currentTenant={currentTenant}
              userAccountPermission={userAccountPermission}
            />
          </Popover>
        </div>
      )}
    </PopupState>
  );
}

export default withStyles(NavBarStyles)(DesktopMenu);
