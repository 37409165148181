import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles({
  root: (theme) => ({
    position: 'relative',
    height: theme.height || 270,
    padding: 10,
    borderRadius: 20,
  }),
});

function ChartCard(props) {
  const { children, height } = props;
  const classes = useStyles({ height });
  return <Paper className={classes.root}>{children}</Paper>;
}
ChartCard.propTypes = {
  height: PropTypes.number,
};

export default ChartCard;
