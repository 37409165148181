import React, { useCallback, useEffect, useState, useMemo } from 'react';

// redux
import { useDispatch, useSelector } from 'react-redux';

// components and helpers
import { paths } from '../../../constants';
import history from '../../Shared/Redux/history';
import { selectCurrentTenant } from '../../Shared/selectors';
import AppKendoTableWrapper from '../../Shared/Table/KendoTableWrapper';
import utils from '../../Shared/Utils/utils';
import { initialKendoState, userTypes } from '../../Users/helpers/mappings';
import { columns } from '../../Users/helpers/usersListMapper';

// kendo
import { selectAllUsers, selectAllUsersLoading, selectCurrentUser, selectUserDetails } from '../../Users/selectors';
import { getMetadataValues, getUser, getUserChildren } from '../../Users/usersSlice';

function DependentUsers() {
  const dispatch = useDispatch();

  const allUsersLoading = useSelector(selectAllUsersLoading);
  const allUsers = useSelector(selectAllUsers);
  const currentTenant = useSelector(selectCurrentTenant);
  const currentUser = useSelector(selectCurrentUser);
  const userDetails = useSelector(selectUserDetails);

  const [userSegmentValues, setUserSegmentValues] = useState([]);

  useEffect(() => {
    // Initial load when change from one content page to another
    dispatch(getUserChildren(currentTenant.id, userDetails.email));
    (async () => {
      setUserSegmentValues(await dispatch(getMetadataValues(currentTenant.id, 'User Segment')));
    })();
  }, [currentTenant]);

  // onRowClick function in table
  const handleRowClick = useCallback(
    (e) => {
      dispatch(getUser(e.email));
      history.replace(paths.profileDetails(currentTenant.id, e.email));
    },
    [currentTenant]
  );

  // Filters to pass to table wrapper
  const columnList = useMemo(
    () => columns(userSegmentValues, currentUser && currentUser.permission)[userTypes.ACTIVE],
    [currentUser, userSegmentValues]
  );

  const data = {
    total: allUsers && allUsers.totalElements,
    pageSize: allUsers && allUsers.size,
    items:
      allUsers &&
      allUsers.content &&
      allUsers.content.map((user) => ({
        ...user,
        birthDate: user.birthDate ? utils.formatDate(user.birthDate) : null,
        permission: user.metadata && user.metadata._PERMISSION,
        userSegment: user.metadata && user.metadata['User Segment'],
        status: user.metadata && user.metadata['_covid19-status'],
        gender: user.gender && utils.removeUnderscoresTitleCase(user.gender),
      })),
  };

  return (
    <AppKendoTableWrapper
      data={data}
      initialSort={initialKendoState.sort[0].field}
      initialDir={initialKendoState.sort[0].dir}
      showSearchbar={false}
      columns={columnList}
      currentTenant={currentTenant}
      loading={allUsersLoading}
      onRowClick={handleRowClick}
      disableAutoFocus
    />
  );
}

export default DependentUsers;
