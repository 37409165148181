import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import React, { useEffect, useState } from 'react';

import NavBarStyles from '../../assets/jss/components/NavBarStyles';

function TenantDropdown(props) {
  const { classes, currentTenant, handleChangeTenant, allTenantsLoading, allTenants, account } = props;
  const [sortedTenants, setSortedTenants] = useState('');

  useEffect(() => {
    if (!allTenantsLoading && allTenants && allTenants.items) {
      const array = [...allTenants.items];
      const sorted = array
        .filter((t) => !t.template)
        .sort((a, b) => {
          const tenantA = a.title.toUpperCase();
          const tenantB = b.title.toUpperCase();
          if (tenantA < tenantB) return -1;
          if (tenantA > tenantB) return 1;
          return 0;
        });
      sorted.forEach((t, i) => {
        if (t.root) {
          sorted.splice(i, 1);
          sorted.unshift(t);
        } else if (t.template) {
          sorted.splice(i, 1);
          sorted.push(t);
        }
      });
      setSortedTenants(sorted);
    }
  }, [allTenants, allTenantsLoading]);

  if (
    currentTenant &&
    (!account || account.accountType === 'SINGLE_TENANT' || (allTenants && allTenants.total === 1))
  ) {
    return (
      <Typography>{currentTenant && currentTenant.title ? currentTenant.title : allTenants.items[0].title}</Typography>
    );
  }

  const getTenant = (tenant) => {
    if (tenant.root) return classes.rootTenant;
    if (tenant.template) return classes.templateTenant;
    return null;
  };
  return (
    <PopupState variant="popover" popupId="dropdown-menu">
      {(popupState) => (
        <div style={{ margin: 'auto' }}>
          <Button
            disableElevation
            variant="contained"
            color="primary"
            style={{ textTransform: 'none', width: 'max-content' }}
            {...bindTrigger(popupState)}
          >
            {currentTenant && currentTenant.title ? currentTenant.title : 'Select Tenant'}
            <ArrowDropDownRoundedIcon className={classes.arrowIcon} fontSize="small" />
          </Button>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            disableScrollLock
          >
            <Box p={2} onClick={() => popupState.close()}>
              {!allTenantsLoading &&
                sortedTenants &&
                sortedTenants.length > 0 &&
                sortedTenants.map((tenant) => (
                  <MenuItem
                    key={tenant.id}
                    value={tenant.id}
                    className={getTenant(tenant)}
                    onClick={() => handleChangeTenant(tenant.id)}
                  >
                    {tenant.title}
                  </MenuItem>
                ))}
            </Box>
          </Popover>
        </div>
      )}
    </PopupState>
  );
}

export default withStyles(NavBarStyles)(TenantDropdown);
