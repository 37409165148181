/* eslint-disable react/no-array-index-key */
import Box from '@material-ui/core/Box';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { useTheme, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React, { useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import ProfileViewStyles from '../../assets/jss/components/ProfileViewStyles';
import CreateFormWrapper from '../Shared/Forms/CreateFormWrapper';
import utils from '../Shared/Utils/utils';

import { updateDiagnosticReport } from './diagnosticReportSlice';
// eslint-disable-next-line import/namespace
import { DiagnosticReportFormMap, transformToForm, transform } from './helpers/formHelper';

// material-ui

function DiagnosticReportInfo(props) {
  const { classes, diagnosticReportInfo, userDetails, orderDetails, currentTenant } = props;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const dispatch = useDispatch();
  let steps;

  const form = useMemo(() => DiagnosticReportFormMap(), [diagnosticReportInfo]);

  const initialValues = useMemo(() => {
    const defaultFieldsReducer = (accum, field) => {
      if (field.type === 'group' || field.type === 'groupNoDropdown' || field.type === 'conditionalGroup') {
        return field.fields.reduce(defaultFieldsReducer, accum);
      }
      if (field.default !== undefined) {
        return {
          ...accum,
          [field.name]: field.default,
        };
      }
      return accum;
    };
    const defaultValues = form.reduce(defaultFieldsReducer, {});
    return diagnosticReportInfo ? transformToForm.report(diagnosticReportInfo.data) : defaultValues;
  }, [diagnosticReportInfo, form]);
  // sets step to it's correctly formatted JSON
  steps = useMemo(
    () => [
      {
        label: 'Result',
        data: form,
      },
    ],
    [form]
  );

  const handleSubmit = useCallback((formData) => {
    dispatch(updateDiagnosticReport(currentTenant.id, transform(formData).report));
  }, []);
  const createPatientDetails = (width) => {
    const patientItems = [];
    patientItems.push(
      <InfoItemComponent width={width} label="Gender">
        {userDetails?.gender || '---'}
      </InfoItemComponent>
    );
    patientItems.push(
      <InfoItemComponent width={width} label="Date Of Birth">
        {utils.formatDate(userDetails?.birthDate) || '---'}
      </InfoItemComponent>
    );
    patientItems.push(
      <InfoItemComponent width={width} label="Race">
        {userDetails?.race || '---'}
      </InfoItemComponent>
    );
    patientItems.push(
      <InfoItemComponent width={width} label="Ethnicity">
        {userDetails?.ethnicity || '---'}
      </InfoItemComponent>
    );
    patientItems.push(
      <InfoItemComponent width={width} label="Address 1">
        {userDetails?.primaryAddress?.line1 || '---'}
      </InfoItemComponent>
    );
    patientItems.push(
      <InfoItemComponent width={width} label="Address 2">
        {userDetails?.primaryAddress?.line2 || '---'}
      </InfoItemComponent>
    );
    patientItems.push(
      <InfoItemComponent width={width} label="City">
        {userDetails?.primaryAddress?.city || '---'}
      </InfoItemComponent>
    );
    patientItems.push(
      <InfoItemComponent width={width} label="State">
        {userDetails?.primaryAddress?.state || '---'}
      </InfoItemComponent>
    );
    patientItems.push(
      <InfoItemComponent width={width} label="Zip">
        {userDetails?.primaryAddress?.zip || '---'}
      </InfoItemComponent>
    );
    return patientItems.map((item, index) => <React.Fragment key={index}>{item}</React.Fragment>);
  };
  const createObservationList = (width, isTest) => {
    const listItems = [];
    if (orderDetails && !isTest) {
      listItems.push(
        <InfoItemComponent width={width} label="Encounter Id">
          {orderDetails.encounterId || '---'}
        </InfoItemComponent>
      );
      listItems.push(
        <InfoItemComponent width={width} label="Lab Order Status">
          {orderDetails?.statusDisplayText || '---'}
        </InfoItemComponent>
      );
      listItems.push(
        <InfoItemComponent width={width} label="Lab Vendor">
          {orderDetails?.details?.labVendor?.name || '---'}
        </InfoItemComponent>
      );
      listItems.push(
        <InfoItemComponent width={12} label="Order Items">
          {GetOrderItems()}
        </InfoItemComponent>
      );
      listItems.push(
        <InfoItemComponent width={width} label="Provider">
          {GetOrderingProvider()}
        </InfoItemComponent>
      );
    }
    return listItems.map((item, index) => <React.Fragment key={index}>{item}</React.Fragment>);
  };
  const GetOrderItems = () => {
    let analyteItems = '---';
    if (orderDetails?.details?.orderablePanel?.length) {
      const tests = orderDetails?.details?.panelTests;
      analyteItems = '';
      tests.forEach((test) => {
        analyteItems = `${analyteItems + test.CustomName} , `;
      });
    } else if (orderDetails?.details?.orderableTest) {
      analyteItems = orderDetails?.details?.orderableTest?.customName;
    }
    return analyteItems;
  };
  const GetOrderingProvider = () => {
    let providerName = '---';
    if (orderDetails?.details?.orderingProvider) {
      providerName = `${orderDetails.details.orderingProvider?.firstName || ''} ${
        orderDetails.details.orderingProvider?.middleName || ''
      } ${orderDetails.details.orderingProvider?.lastName || ''}`;
    }
    return providerName;
  };

  return (
    <CardContent>
      <Grid className={classes.infoBoxesUpdated}>
        <InfoItemsWrapper title="Patient Details">{createPatientDetails(4)}</InfoItemsWrapper>
        <Grid item xs={12} style={{ paddingBottom: 16 }}>
          <Divider />
        </Grid>
        <InfoItemsWrapper title="Order Details">
          {matches ? createObservationList(4, false) : createObservationList(12, false)}
        </InfoItemsWrapper>
        <CreateFormWrapper
          steps={steps}
          handleSubmit={handleSubmit}
          initialValues={initialValues}
          formType="editContent"
          removeDeleteOption
          isDirtyRequired
        />
      </Grid>
    </CardContent>
  );
}

function InfoItemsWrapper(props) {
  const { title, children: content } = props;
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h4">{title}</Typography>
      </Grid>
      {content}
    </Grid>
  );
}

function InfoItemComponent(props) {
  const { label, field, children: content, width, customStyle } = props;

  return (
    <Grid item xs={width}>
      <Box>
        <Typography
          variant="h6"
          style={{
            fontSize: '12px',
            fontWeight: 'bold',
            paddingBottom: '5px',
          }}
        >
          {label}
        </Typography>
        <div>
          <Typography variant="body1" style={field === 'testResult' ? customStyle : {}}>
            {' '}
            {content}
          </Typography>
        </div>
      </Box>
    </Grid>
  );
}

export default withStyles(ProfileViewStyles)(DiagnosticReportInfo);
