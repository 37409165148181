/* eslint-disable import/namespace */
import Backdrop from '@material-ui/core/Backdrop';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CancelIcon from '@material-ui/icons/Cancel';

import WifiOffIcon from '@material-ui/icons/WifiOff';
import classNames from 'classnames';
import createDOMPurify from 'dompurify';
import PropTypes from 'prop-types';
import React from 'react';

// redux additions
import { useDispatch, useSelector } from 'react-redux';
import NotificationStyles from '../../assets/jss/components/NotificationStyles';
import { selectTenantNetwork } from '../Networks/selectors';
import { hideNotificationAction } from './notificationSlice';

import { selectIsOffline, selectNotification } from './selectors';

const DOMPurify = createDOMPurify(window);

// Defines if we need to hide "Connection error" message
const HIDE_CONNECTION_ERROR = process.env.REACT_APP_HIDE_CONNECTION_ERROR === 'true';
const AUTO_HIDE_DURATION = 5000;

export function Notification(props) {
  const { snackbar } = props;
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  const tenantNetwork = useSelector(selectTenantNetwork);
  const isOffline = useSelector(selectIsOffline);
  const notification = useSelector(selectNotification);

  const dispatch = useDispatch();

  //   // if notification AutoHide disabled then hide notification after click on page
  // useEffect(() => {
  //   if (notification?.isAutoHide === false) {
  //     window.addEventListener('mousedown', () => dispatch(hideNotificationAction()));
  //     return () => window.removeEventListener('mousedown', () => dispatch(hideNotificationAction()));
  //   }
  // }, [notification]);

  const handleClose = (event, reason) => {
    // closing notification
    // if (event) {
    //   event.preventDefault();
    // }
    if (reason === 'clickaway') {
      return;
    }
    dispatch(hideNotificationAction());
  };

  const handleUpdate = async (event) => {
    // update service worker
    if (event) {
      event.preventDefault();
    }
    if (navigator && navigator.serviceWorker) {
      const waitingServiceWorker = await navigator.serviceWorker.ready;

      if (waitingServiceWorker.waiting) {
        waitingServiceWorker.waiting.postMessage({ type: 'SKIP_WAITING' });
      }
    }
    dispatch(hideNotificationAction());
  };

  const getBackgroundColor = () => {
    switch (notification?.notificationType) {
      case 'error':
        return tenantNetwork?.errorColor || '#FF6464';
      case 'success':
        return tenantNetwork?.successColor || '#76D873';
      case 'warning':
        return tenantNetwork?.warningColor || '#ffc12a';
      case 'info':
        return tenantNetwork?.primaryColor || '#304075';
      default:
        return tenantNetwork?.warningColor || '#304075';
    }
  };

  const getNotificationContent = () => {
    if (notification.isNewUIVersion) {
      return (
        <Grid container spacing={2}>
          <Grid item>
            <Typography variant={'body2'} className={classes.updateMessage} id="client-snackbar">
              {notification.message}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant={'body2'} onClick={handleUpdate} className={classes.updateNow}>
              Update Now
            </Typography>
          </Grid>
        </Grid>
      );
    }
    return (
      <span id="client-snackbar" className={classes[(`message-${notification.notificationType}`, 'message-text')]}>
        <span
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(notification.message),
          }}
        />
      </span>
    );
  };

  return (
    <div>
      <Snackbar
        open={notification.visible}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: !notification.isNewUIVersion ? 'center' : 'left',
        }}
        autoHideDuration={notification?.isAutoHide && !notification.isNewUIVersion ? AUTO_HIDE_DURATION : null}
        {...snackbar}
      >
        <SnackbarContent
          aria-describedby="client-snackbar"
          className={classNames(classes.snackBar, classes[notification?.notificationType])}
          classes={{
            action: classes.contentAction,
            message: classes.contentMessage,
            root: classes.root,
          }}
          style={{ backgroundColor: getBackgroundColor() }}
          message={getNotificationContent()}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes[`closeIcon`]}
              onClick={handleClose}
            >
              <CancelIcon className={classes.icon} />
            </IconButton>,
          ]}
        />
      </Snackbar>

      {isOffline && !HIDE_CONNECTION_ERROR && (
        <Backdrop open style={{ zIndex: 5000 }}>
          <Snackbar
            open
            onClose={handleClose}
            anchorOrigin={{
              vertical: matches ? 'center' : 'bottom',
              horizontal: 'center',
            }}
            {...snackbar}
          >
            <SnackbarContent
              aria-describedby="client-snackbar"
              message={
                <span id="client-snackbar" className={classes.updateMessage}>
                  <WifiOffIcon className={classes.icon} />
                  Internet connection lost. Check network and try again.
                </span>
              }
            />
          </Snackbar>
        </Backdrop>
      )}
    </div>
  );
}

const useStyles = makeStyles(NotificationStyles);

Notification.propTypes = {
  notification: PropTypes.object,
};

Notification.defaultProps = {
  notification: {
    message: null,
    visible: false,
    notificationType: 'info',
    update: false,
  },
};

export default Notification;
