/* eslint-disable react/no-array-index-key */
import React from 'react';
import { useSelector } from 'react-redux';

import NoAccessView from '../../views/NoAccessView';
import { selectAuthToken } from '../Auth/selectors';

import AccessControl from './AccessControl';

function PrivateRoute({ children, allowed, ...props }) {
  const isAuthenticated = useSelector(selectAuthToken);

  // get current pathname to check with access control if page shown/hidden before rendering
  let url = window.location.pathname;
  let currentPageArray = url.split('/').slice(2);
  let currentPage;
  // check if url is extension of route for create/details/import. if so, block if root is hidden
  if (
    currentPageArray.includes('create') ||
    currentPageArray.includes('details') ||
    currentPageArray.includes('import')
  ) {
    currentPage = currentPageArray[0];
  } else {
    currentPage = currentPageArray.join('/');
  }
  function Component() {
    return isAuthenticated === true
      ? React.Children.map(children, (child, i) =>
          React.cloneElement(child, {
            key: i,
            ...props,
          })
        )
      : null;
  }
  return (
    <AccessControl
      allowedPermissions={allowed || ['ADMINISTRATOR']}
      currentPage={currentPage}
      renderNoAccess={() => <NoAccessView permissionsNeeded="Administrator" />}
    >
      <Component />
    </AccessControl>
  );
}

export default PrivateRoute;
