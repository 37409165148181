// material-ui components
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import createDOMPurify from 'dompurify';
import PropTypes from 'prop-types';
import React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { deleteContent, updateVideos, deleteMessageContent } from '../Content/contentSlice';
import { deleteMetaDataKey } from '../Networks/networksSlice';
import { deleteFont, removeFaq, deleteVerbiage } from '../Settings/settingsSlice';
import CircularProgress from '../Shared/CircularProgress';
import utils from '../Shared/Utils/utils';
import { statusMappings, userTypes } from '../Users/helpers/mappings';
import { selectUserDeleteAccountLoading } from '../Users/selectors';
import { deleteUserAccount, deleteUserTenant, downloadUploadingFile, userStatusChange } from '../Users/usersSlice';
import { removeAnnotation, setDeletingShapeId } from '../VirtualConsult/Reviewer/reviewerSlice';
import {
  deleteOutcome,
  deleteQuestion,
  deleteQuestionnaire,
  deleteUserSegment,
} from '../VirtualConsult/virtualConsultSlice';

import { hideConfirmation } from './confirmationSlice';
import { selectConfirmation } from './selectors';

const DOMPurify = createDOMPurify(window);

// actions taken upon confirmation/cancellation of an action
export function ConfirmationDialog() {
  const confirmation = useSelector(selectConfirmation);
  const userDeleteAccountLoading = useSelector(selectUserDeleteAccountLoading);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  // cancelling an action event response
  const handleCancel = (event) => {
    if (event) {
      event.preventDefault();
    }
    if (confirmation.onNo) {
      confirmation.onNo();
    }
    dispatch(hideConfirmation());
  };

  // confirming an action event response
  const handleOk = async (event) => {
    if (event) {
      event.preventDefault();
    }

    if (confirmation.confirmationType === 'userDeleteAccount') {
      const res = await dispatch(
        deleteUserAccount(confirmation.id, confirmation.tenant, confirmation.confirmationType)
      );
      if (res) navigate(`/${confirmation.tenant}/users/${userTypes.ACTIVE}`);
    } else if (confirmation.confirmationType === 'userStatusChange') {
      const status =
        confirmation.id.status === statusMappings.DEACTIVATED.value
          ? statusMappings.ACTIVE.id
          : statusMappings.DEACTIVATED.id;
      await dispatch(userStatusChange(confirmation.tenant, confirmation.id, status, confirmation.onSuccess));
    } else if (confirmation.confirmationType === 'userRemoveTenant') {
      const res = dispatch(deleteUserTenant(confirmation.id, confirmation.tenant));
      if (res) navigate(`/${confirmation.tenant}/users`);
    } else if (confirmation.confirmationType === 'faqDelete') {
      await dispatch(removeFaq(confirmation.id));
      await confirmation.onSuccess();
    } else if (confirmation.confirmationType === 'verbiageDelete') {
      dispatch(deleteVerbiage(confirmation.account, confirmation.tenant, confirmation.id));
    } else if (confirmation.confirmationType === 'videoDelete') {
      dispatch(updateVideos(confirmation.tenant, confirmation.id, confirmation.confirmationType));
    } else if (confirmation.confirmationType === 'contentDelete') {
      dispatch(deleteContent(confirmation.contentType, confirmation.tenant, confirmation.id)).then(() => {
        if (confirmation.contentType === 'introWidgets') {
          navigate(
            `/${confirmation.tenant}/settings/configuration/assets/${utils.camelToSnake(confirmation.contentType)}`
          );
        } else {
          navigate(`/${confirmation.tenant}/content/${utils.camelToSnake(confirmation.contentType)}`);
        }
      });
    } else if (confirmation.confirmationType === 'messageDelete') {
      dispatch(deleteMessageContent(confirmation.contentType, confirmation.tenant, confirmation.id));
    } else if (confirmation.confirmationType === 'questionDelete') {
      dispatch(deleteQuestion(confirmation.tenant, confirmation.id));
    } else if (confirmation.confirmationType === 'questionnaireDelete') {
      dispatch(deleteQuestionnaire(confirmation.tenant.id, confirmation.id));
    } else if (confirmation.confirmationType === 'outcomeDelete') {
      dispatch(deleteOutcome(confirmation.tenant, confirmation.id));
    } else if (confirmation.confirmationType === 'fontDelete') {
      dispatch(deleteFont(confirmation.tenant, confirmation.id));
    } else if (confirmation.confirmationType === 'segmentDelete') {
      dispatch(deleteUserSegment(confirmation.tenant.id, confirmation.id));
    } else if (confirmation.confirmationType === 'questionnaireQuestionDelete') {
      if (confirmation.onSuccess) {
        confirmation.onSuccess();
      }
    } else if (confirmation.confirmationType === 'metaDataKeyDelete') {
      dispatch(deleteMetaDataKey(confirmation.tenant.id, confirmation.id));
    } else if (confirmation.confirmationType === 'importVirtualConsult') {
      if (confirmation.onSuccess) {
        confirmation.onSuccess();
      }
    } else if (confirmation.confirmationType === 'annotationDelete') {
      const { tenant: questionnaireId, id: annotationId } = confirmation;
      dispatch(removeAnnotation(questionnaireId, annotationId));
    } else if (confirmation.confirmationType === 'annotationShapeDelete') {
      const { tenant: shapeId } = confirmation;
      dispatch(setDeletingShapeId(shapeId));
    } else if (confirmation.confirmationType === 'downloadUploadingFile') {
      const { tenant, id: fileData } = confirmation;
      const getFileName = () => {
        if (fileData?.fileName) return fileData.fileName;
        if (fileData?.dateUploaded) return `${utils.formatDate(fileData.dateUploaded)}.csv`;
        return `${fileData?.uploadID}.csv`;
      };
      dispatch(downloadUploadingFile(tenant, fileData?.uploadID, getFileName()));
    } else if (confirmation.confirmationType === 'reassignVerbiageHeader') {
      if (confirmation.onSuccess) {
        confirmation.onSuccess();
      }
    }
    dispatch(hideConfirmation());
  };

  return (
    <Dialog
      open={confirmation.visible}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
          handleCancel(event);
        }
      }}
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
    >
      <DialogTitle id="confirmation-dialog-title">{confirmation.title}</DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          <span
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(confirmation.message),
            }}
          />
        </Typography>
      </DialogContent>
      <DialogActions>
        <div>
          {confirmation.confirmationType !== 'info' && (
            <Button onClick={handleCancel} color="primary" style={{ marginRight: 8 }}>
              {confirmation.onNo ? 'No' : 'Cancel'}
            </Button>
          )}

          <Button
            style={{ position: 'relative' }}
            variant="contained"
            onClick={handleOk}
            color="primary"
            disabled={userDeleteAccountLoading}
          >
            {userDeleteAccountLoading ? <CircularProgress /> : null}
            OK
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

ConfirmationDialog.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  confirmation: PropTypes.object,
};

ConfirmationDialog.defaultProps = {
  confirmation: {
    title: null,
    message: null,
    confirmationType: null,
    visible: false,
  },
};

const styles = (theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    width: '80%',
    maxHeight: 435,
  },
});

export function mapStateToProps(state) {
  return {
    ...state,
  };
}

// middleware
export default connect(mapStateToProps, null)(withStyles(styles)(ConfirmationDialog));
