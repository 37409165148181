import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { Editor, EditorTools, ProseMirror } from '@progress/kendo-react-editor';

import React, { useState } from 'react';

// import { debounce } from 'lodash'
import CreateFormStyles from '../../../assets/jss/components/CreateFormStyles';
// import { setAllowChange } from '../../LanguageMenu/languageSlice'
// import { setFormSubmitting } from './formSlice'
// import FormDialog from './FormDialog'

import { placeholder, placeholderStyles } from './helpers/kendoEditorPlaceholder';

const {
  Bold,
  Italic,
  Underline,
  Strikethrough,
  AlignLeft,
  AlignCenter,
  AlignRight,
  AlignJustify,
  OrderedList,
  UnorderedList,
  Undo,
  Redo,
  Link,
  Unlink,
  InsertImage,
  ViewHtml,
  SelectAll,
} = EditorTools;

// const matchesPattern = (value, pattern) => {
//   if (value instanceof Date) {
//   } else {
//     const re = new RegExp(pattern, 'g')
//     let matches
//     // strip masked phone number to check validity of numbers (not allow all numbers in a row)
//     if (pattern === '^(?!(\\d)\\1{9})(?!0123456789|1234567890|0987654321|9876543210)\\d{10}$') {
//       value = value.replace(/[^A-Z0-9]+/gi, '')
//     }
//     matches = value.match(re)
//     return (matches && (matches.length > 1 || matches[0] !== value)) || !matches
//   }
// }

// const requiredFieldsReducer = (accum, field) => {
//   if (field.type === 'group' || field.type === 'groupNoDropdown') {
//     return field.fields.reduce(requiredFieldsReducer, accum);
//   }
//   if (field.required) {
//     accum.push(field);
//   } else if (field.pattern) {
//     accum.push(field);
//   }
//   return accum;
// };

function CreateFormWrapper(props) {
  const { classes, handleSubmit, handleCancel, disableForm } = props;
  const [messageTitle, setMessageTitle] = useState('');
  const defaultEditorValue = '<p></p>';

  const [message, setMessage] = useState(defaultEditorValue);
  // const dispatch = useDispatch()
  const inputClasses = useStyles();
  const [subjectInpErr, setSubjectInpErr] = useState('');
  const [bodyInpErr, setBodyInpErr] = useState('');

  // const clearMessage = debounce(() => setMessage(defaultEditorValue), 300)

  const handleMessageChange = (e) => {
    const value = e.html;
    if (value === '<p></p>') {
      setBodyInpErr('*Message Body is required');
      setMessage(value);
    } else {
      setMessage(value);
      setBodyInpErr('');
    }
  };

  const handleMessageClick = (e) => e.target.value;

  // const submitData = async () => {
  //   await checkRequired(requiredFields)
  //   if (!disabled) {
  //     setOpenConfirmation(false)
  //     dispatch(setFormSubmitting(true))
  //     dispatch(setAllowChange(true))
  //     formType === 'inviteUser' || formType === 'inviteUser' ? handleInvite(formData) : handleSubmit(formData)
  //   }
  // }

  const styles = `
    p {
        color: #757575;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif
    }
`;

  const onMount = (event) => {
    // Style the actual Body Content
    const iframeDocument = event.dom.ownerDocument;
    const style = iframeDocument.createElement('style');
    style.appendChild(iframeDocument.createTextNode(styles));
    iframeDocument.head.appendChild(style);

    // For Placeholder implementation
    const state = event.viewProps.state;
    const plugins = [...state.plugins, placeholder('Body of your message goes here.')];
    const editorDocument = event.dom.ownerDocument;
    const styleElement = editorDocument && editorDocument.querySelector('style');

    if (styleElement) {
      styleElement.appendChild(editorDocument.createTextNode(placeholderStyles));
    }

    return new ProseMirror.EditorView(
      {
        mount: event.dom,
      },
      {
        ...event.viewProps,
        state: ProseMirror.EditorState.create({
          doc: state.doc,
          plugins,
        }),
      }
    );
    // End of Placeholder implementation for Editor
  };

  // const onCancelSubmit = () => {
  //   setOpenConfirmation(false)
  // };

  const validateInputs = () => {
    setBodyInpErr('');
    setSubjectInpErr('');

    if (messageTitle === '') {
      setSubjectInpErr('*Title Message is required');
    }
    if (message === '' || message === '<p></p>' || message === defaultEditorValue) {
      setBodyInpErr('*Message Body is required');
    }
    if (message === defaultEditorValue) {
      setBodyInpErr('*Message Body is required');
    }
  };

  const handleComponentSubmit = () => {
    validateInputs();

    if (message !== '' && messageTitle !== '' && message !== defaultEditorValue && message !== '<p></p>') {
      handleSubmit({ subject: messageTitle, message });
    }
  };

  return (
    <>
      <Grid container justifyContent="center">
        <Grid item xs={11}>
          <div>
            <div className={classes.instructions}>
              <p style={{ fontWeight: 800, marginBottom: 0 }}>
                Please select one of the two message types that you would like to send to your previously selected
                members.
              </p>

              <Grid item xs={12} style={{ paddingTop: 10 }}>
                {/* Option 1 */}
                <span style={{ fontSize: 14 }}>
                  <span style={{ fontWeight: 600 }}>Option 1.</span> Templated Message
                </span>
                <FormControl fullWidth>
                  <InputLabel>Select Templated Message</InputLabel>
                  <Select label="Select Templated Message" disabled>
                    <MenuItem value="id">Test</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} style={{ paddingTop: 10 }}>
                {/* Option 2 */}
                <span style={{ fontSize: 14 }}>
                  <span style={{ fontWeight: 600 }}>Option 2.</span> Custom Message
                </span>

                <FormControl fullWidth>
                  <TextField
                    type="text"
                    value={messageTitle}
                    onChange={(e) => setMessageTitle(e.target.value)}
                    label="Title of your message goes here."
                    margin="normal"
                    className={inputClasses.subjectOpt2}
                    variant="outlined"
                    error={!!subjectInpErr}
                    helperText={subjectInpErr}
                  />
                </FormControl>
                <FormControl fullWidth className={inputClasses.editor}>
                  <Editor
                    style={{
                      height: 180,
                    }}
                    className={inputClasses.editor}
                    disabled={disableForm}
                    tools={[
                      [
                        Bold,
                        Italic,
                        Underline,
                        Strikethrough,
                        AlignLeft,
                        AlignCenter,
                        AlignRight,
                        AlignJustify,
                        OrderedList,
                        UnorderedList,
                        SelectAll,
                        Undo,
                        Redo,
                        Link,
                        Unlink,
                        InsertImage,
                        ViewHtml,
                      ],
                    ]}
                    contentStyle={{
                      height: 150,
                    }}
                    label="Body of your message goes here."
                    value={message}
                    onChange={handleMessageChange}
                    onMount={onMount}
                    onClick={handleMessageClick}
                  />
                  <Typography className={inputClasses.bodyErrMessage}>{!!bodyInpErr && bodyInpErr}</Typography>
                </FormControl>
              </Grid>
            </div>
          </div>
        </Grid>
      </Grid>
      {(handleCancel || handleSubmit) && (
        <Grid container justifyContent="flex-end">
          {handleCancel && (
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                disableElevation
                elevation={0}
                onClick={handleCancel}
                className={classes.button}
                disabled={disableForm}
              >
                Cancel
              </Button>
            </Grid>
          )}
          {handleSubmit && (
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                disableElevation
                elevation={0}
                onClick={handleComponentSubmit}
                className={classes.button}
                disabled={disableForm}
              >
                Send message
              </Button>
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
}

const useStyles = makeStyles({
  subjectOpt2: {
    marginBottom: '15px',
  },
  bodyErrMessage: {
    color: '#f44336',
    'margin-left': '14px',
    'margin-top': '8px',
    'font-size': '0.75rem',
  },
});

export default withStyles(CreateFormStyles)(CreateFormWrapper);
