// redux
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { useTheme, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ErrorIcon from '@material-ui/icons/Error';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CreateFormStyles from '../../../assets/jss/components/CreateFormStyles';
import CreateFormWrapper from '../../Shared/Forms/CreateFormWrapper';
import { selectCurrentTenant } from '../../Shared/selectors';
import { createTenantJsonBuilder } from './helpers/createTenantJsonBuilder';
import { tenantSteps } from './helpers/formHelper';
import { selectAddTenantLoading, selectTenant, selectTenantError, selectTenantLoading } from './selectors';
import { ContentSkeletonLoading, ContentSkeletonLoadingMobile } from './TenantSkeletonLoading';
import { getTenant, addTenant, getChannels } from './tenantsSlice';

function TenantFormWrapper(props) {
  const { classes, edit, id } = props;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const navigate = useNavigate();

  const tenant = useSelector(selectTenant);
  const currentTenant = useSelector(selectCurrentTenant);
  const loading = useSelector(selectTenantLoading);
  const addTenantLoading = useSelector(selectAddTenantLoading);
  const error = useSelector(selectTenantError);

  const [channels, setChannels] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (id && edit) {
      // Get Tenant info
      dispatch(getTenant(id));
    }
  }, [edit, id]);

  useEffect(() => {
    (async () => {
      const res = await dispatch(getChannels(id));
      if (res) setChannels(res.data);
    })();
  }, []);

  const handleSubmit = async (formData) => {
    const data = createTenantJsonBuilder(currentTenant, formData);
    if (!edit) {
      const res = await dispatch(addTenant(currentTenant.id, data));
      if (res) navigate(`/${currentTenant.id}/account/tenants`);
    }
  };

  if (!currentTenant) return <div />;

  if (edit && loading) {
    return (
      <Grid container justifyContent="center">
        {matches ? (
          <Grid item xs={12} md={7}>
            <Paper elevation={0} style={{ borderRadius: 30 }}>
              <ContentSkeletonLoading />
            </Paper>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Paper elevation={0} style={{ borderRadius: 30 }}>
              <ContentSkeletonLoadingMobile />
            </Paper>
          </Grid>
        )}
      </Grid>
    );
  }

  if (tenant && tenant.error) {
    return (
      <Grid container justifyContent="center">
        <Grid item>
          <ErrorIcon color="primary" />
        </Grid>
        <Grid item>
          <Typography align="center" variant="caption" style={{ width: '100%' }} color="primary">
            {error}
          </Typography>
        </Grid>
      </Grid>
    );
  }

  const steps = tenantSteps(channels);

  const loadingComponent = (
    <div className={classes.loadingOverlay}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignContent="center"
        alignItems="center"
        className={classes.container}
      >
        <Grid item>
          <CircularProgress size={42} color="primary" />
        </Grid>
        <Grid item>
          <Typography style={{ paddingTop: 20, color: '#65656C' }}>Tenant Creation Processing...</Typography>
        </Grid>
      </Grid>
    </div>
  );

  return (
    <div style={{ position: 'relative' }}>
      <Grid container justifyContent="center">
        {addTenantLoading && loadingComponent}
        <CreateFormWrapper steps={steps} handleSubmit={handleSubmit} initialValues={edit ? tenant : {}} />
      </Grid>
    </div>
  );
}

export default withStyles(CreateFormStyles)(TenantFormWrapper);
