import { createSlice } from '@reduxjs/toolkit';

import api from '../../api';
import { showNotification } from '../Notifications/notificationSlice';
import { handleError } from '../Shared/Content/APIUtils';
import { setFormSubmitting } from '../Shared/Forms/formSlice';

const initialState = {
  diagnosticReportInfo: null,
  diagnosticReportInfoLoading: null,
};

export const diagnosticReportSlice = createSlice({
  name: 'diagnosticReport',
  initialState,
  reducers: {
    setDiagnosticReportLoading: (state) => ({
      ...state,
      diagnosticReportInfoLoading: true,
    }),
    setDiagnosticReport: (state, { payload }) => ({
      ...state,
      diagnosticReportInfo: payload,
      diagnosticReportInfoLoading: false,
    }),
  },
});

export const { setDiagnosticReport, setDiagnosticReportLoading } = diagnosticReportSlice.actions;

export const getDiagnosticReport = (safeEMRId, encounterId, tenant) => async (dispatch) => {
  dispatch(setDiagnosticReportLoading());

  const [result, error] = await api.getDiagnosticReportRequest(safeEMRId, encounterId, tenant);

  if (error && error.message) {
    dispatch(handleError(error));
    // history.push only changed url string and not element so instead passing this generic error string to component
    // and then using useNavigate hook to redirect properly
    dispatch(setDiagnosticReport('error'));
  }

  if (result) {
    dispatch(setDiagnosticReport(result));
  }
};

export const updateDiagnosticReport = (tenant, data) => async (dispatch) => {
  const [result, error] = await api.updateDiagnosticReportRequest(tenant, data);

  if (error && error.message) {
    dispatch(handleError(error));
    dispatch(setFormSubmitting(false));
  }

  if (result) {
    dispatch(showNotification('Successfully Updated', 'success'));
    dispatch(setFormSubmitting(false));
  }
};

export default diagnosticReportSlice.reducer;
