import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ProfileViewStyles from '../../assets/jss/components/ProfileViewStyles';
import ProfileCard from '../Profile/ProfileCard';
import {
  ProfileCardSkeletonLoading,
  ProfileCardSkeletonLoadingMobile,
  ProfileTabsSkeletonLoading,
  ProfileDropdownSkeletonLoadingMobile,
} from '../Profile/ProfileSkeletonLoading';
import { selectCurrentTenant } from '../Shared/selectors';
import {
  selectProfilePicture,
  selectUserDetails,
  selectUserDetailsLoading,
  selectUserPermissionsLoading,
} from '../Users/selectors';
import { getUser, getUserPermissions } from '../Users/usersSlice';

import ClinicalInformation from './ClinicalInformation';

function Clinical(props) {
  const { classes, type } = props;
  const dispatch = useDispatch();

  const userDetails = useSelector(selectUserDetails);
  const currentTenant = useSelector(selectCurrentTenant);
  const userDetailsLoading = useSelector(selectUserDetailsLoading);
  const profilePicture = useSelector(selectProfilePicture);
  const userPermissionsLoading = useSelector(selectUserPermissionsLoading);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  useEffect(() => {
    const splitUrl = window.location.href.split('/');
    const userId = splitUrl[4] === 'users' || splitUrl[4] === 'record-requests' ? splitUrl[6] : splitUrl[5];
    dispatch(getUser(userId));
    dispatch(getUserPermissions(currentTenant.id, userId));
  }, [currentTenant.id, type]);

  if (userDetailsLoading || !userDetails || !profilePicture || userPermissionsLoading) {
    return (
      <div>
        {matches ? (
          <div>
            <ProfileCardSkeletonLoading />
            <Paper style={{ borderRadius: '20px', marginBottom: 30 }} elevation={0}>
              <ProfileTabsSkeletonLoading />
            </Paper>
          </div>
        ) : (
          <div>
            <Card style={{ borderRadius: '20px', marginBottom: 20 }} elevation={0}>
              <ProfileCardSkeletonLoadingMobile />
            </Card>
            <Paper style={{ borderRadius: '20px', marginBottom: 30 }} elevation={0}>
              <ProfileDropdownSkeletonLoadingMobile />
            </Paper>
          </div>
        )}
      </div>
    );
  }

  // profile page
  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <ProfileCard />
      </Grid>

      <Grid item xs={12} className={classes.bottomBox}>
        <ClinicalInformation />
      </Grid>
    </Grid>
  );
}

export default withStyles(ProfileViewStyles)(Clinical);
