import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grid from '@material-ui/core/Grid';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import React from 'react';

import SettingsTemplateVariableHelper from '../../../Settings/SystemMessages/SettingsTemplateVariableHelper';

export default function CustomClickAwayListner(props) {
  const { variables } = props;
  const [variableOpen, setVariableOpen] = React.useState(false);

  const handleVariablePanel = () => {
    setVariableOpen(!variableOpen);
  };
  return (
    <div>
      <Button variant="outlined" color="primary" endIcon={<ArrowDropDownIcon />} onClick={handleVariablePanel}>
        ADD SYSTEM MESSAGE VARIABLE
      </Button>

      {variableOpen && (
        <ClickAwayListener onClickAway={handleVariablePanel}>
          <Grid
            item
            xs={12}
            // className={classes.messageVariablesDropdown}
          >
            <SettingsTemplateVariableHelper variables={variables} />
          </Grid>
        </ClickAwayListener>
      )}
    </div>
  );
}
