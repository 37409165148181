import Box from '@material-ui/core/Box';
import React from 'react';
import ContentLoader from 'react-content-loader';
import { FormSkeletonWrapper } from '../../Shared/FormSkeletonWrapper/FormSkeletonWrapper';

export function SkeletonLoading() {
  const settingsSkeleton = () => (
    <FormSkeletonWrapper>
      <ContentLoader
        speed={2}
        width="100%"
        height={210}
        viewBox="0 0 100% 250"
        backgroundColor="#ecebeb"
        foregroundColor="#f3f3f3"
      >
        <rect x="20" y="30" rx="10" ry="10" width="130" height="30" />
        <rect x="20" y="90" rx="5" ry="5" width="90" height="15" />

        <rect x="75%" y="75" rx="10" ry="10" width="150" height="17" />
        <rect x="75%" y="115" rx="10" ry="10" width="150" height="17" />
        <rect x="75%" y="155" rx="10" ry="10" width="150" height="17" />
      </ContentLoader>
    </FormSkeletonWrapper>
  );
  return <Box width="100%">{settingsSkeleton()}</Box>;
}

export function SelectedNameSkeletonLoader() {
  return (
    <ContentLoader
      speed={2}
      width="100%"
      height={40}
      viewBox="0 0 100% 40"
      backgroundColor="#ecebeb"
      foregroundColor="#f3f3f3"
    >
      <rect x="0" y="0" rx="10" ry="10" width="180" height="40" />
    </ContentLoader>
  );
}

export function TemplateEditorSkeletonLoading() {
  return (
    <ContentLoader speed={2} viewBox="0 0 400 250" backgroundColor="#ecebeb" foregroundColor="#f3f3f3">
      <rect x="85" y="20" rx="3" ry="3" width="100" height="10" />
      <rect x="30" y="40" rx="3" ry="3" width="220" height="5" />
      <rect x="30" y="50" rx="3" ry="3" width="220" height="5" />
      <rect x="30" y="60" rx="3" ry="3" width="220" height="5" />
      <rect x="30" y="70" rx="3" ry="3" width="220" height="5" />
      <rect x="30" y="80" rx="3" ry="3" width="220" height="5" />
      <rect x="30" y="90" rx="3" ry="3" width="220" height="5" />
      <rect x="30" y="100" rx="3" ry="3" width="100" height="5" />
      <rect x="30" y="120" rx="3" ry="3" width="100" height="5" />
      <rect x="30" y="130" rx="3" ry="3" width="100" height="5" />

      <rect x="280" y="20" rx="3" ry="3" width="25" height="25" />
      <rect x="280" y="55" rx="3" ry="3" width="25" height="25" />
      <rect x="280" y="90" rx="3" ry="3" width="25" height="25" />

      <rect x="310" y="20" rx="3" ry="3" width="25" height="25" />
      <rect x="310" y="55" rx="3" ry="3" width="25" height="25" />

      <rect x="340" y="20" rx="3" ry="3" width="25" height="25" />
      <rect x="340" y="55" rx="3" ry="3" width="25" height="25" />

      <rect x="370" y="20" rx="3" ry="3" width="15" height="15" />
      <rect x="370" y="45" rx="3" ry="3" width="15" height="15" />
      <rect x="370" y="70" rx="3" ry="3" width="15" height="15" />
      <rect x="370" y="95" rx="3" ry="3" width="15" height="15" />

      {/* Control Buttons */}
      <rect x="0" y="235" rx="3" ry="3" width="40" height="10" />
      <rect x="42" y="235" rx="3" ry="3" width="40" height="10" />
      <rect x="84" y="235" rx="3" ry="3" width="40" height="10" />

      <rect x="calc(100% - 40px)" y="235" rx="3" ry="3" width="40" height="10" />
      <rect x="calc(100% - (40px + 40px + 2px))" y="235" rx="3" ry="3" width="40" height="10" />
    </ContentLoader>
  );
}
