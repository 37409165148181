import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { useTheme, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useParams } from 'react-router-dom';
import SettingsTemplatesStyles from '../../../assets/jss/components/SettingsTemplatesStyles';
import { setAllowChange } from '../../LanguageMenu/languageSlice';
import { showNotification } from '../../Notifications/notificationSlice';
import { selectCurrentTenant } from '../../Shared/selectors';
import utils from '../../Shared/Utils/utils';
import {
  selectCustomFontsList,
  selectSelectedTemplate,
  selectSelectedTemplateSettings,
  selectSubmittingSettings,
  selectSubmittingTemplate,
  selectSubmittingTest,
  selectTemplateLoading,
  selectTenantTemplates,
} from '../selectors';
import {
  getAllTemplates,
  getCustomFontsList,
  getTemplate,
  getTemplateSettings,
  saveTemplate,
  saveTemplateSettings,
  testTemplate,
} from '../settingsSlice';

import { systemMessagesTabTypes, systemMessagesTypes } from './helpers/mappings';
import SettingsTemplateEditor from './SettingsTemplateEditor';
import SettingsToggles from './SettingsToggles';
import {
  TemplateEditorSkeletonLoading,
  SkeletonLoading,
  SelectedNameSkeletonLoader,
} from './TemplateEditorSkeletonLoading';

function SettingsTemplateEditorWrapper(props) {
  const { classes } = props;
  const submittingSettings = useSelector(selectSubmittingSettings);
  const currentTenant = useSelector(selectCurrentTenant);
  const submittingTemplate = useSelector(selectSubmittingTemplate);
  const tenantTemplates = useSelector(selectTenantTemplates);
  const selectedTemplate = useSelector(selectSelectedTemplate);
  const templateLoading = useSelector(selectTemplateLoading);
  const selectedTemplateSettings = useSelector(selectSelectedTemplateSettings);
  const submittingTest = useSelector(selectSubmittingTest);
  const customFontsList = useSelector(selectCustomFontsList);

  const [selectedName, setSelectedName] = useState('');
  const [selectedContent, setSelectedContent] = useState('');
  const [subjectContent, setSubjectContent] = useState(null);
  const [editing, setEditing] = useState(false);
  const [tenantCustomFontsList, setTenantCustomFontsList] = useState([]);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const [enabledTemplate, setEnabledTemplate] = useState(false);
  const [enabledSMS, setEnabledSMS] = useState(false);
  const [enabledPushNotifications, setEnabledPushNotifications] = useState(false);
  const [enabledEmail, setEnabledEmail] = useState(false);
  const [enabledSAFE, setEnabledSAFE] = useState(false);
  const { tabType: selectedType, id: templateId } = useParams();

  const dispatch = useDispatch();

  // on mount get current template from url id with settings
  useEffect(() => {
    dispatch(setAllowChange(false));
    dispatch(getTemplate(currentTenant.accountId, currentTenant.id, templateId, systemMessagesTypes[selectedType]));
    dispatch(getTemplateSettings(currentTenant.accountId, currentTenant.id, templateId));
    dispatch(getCustomFontsList(currentTenant.id));
    if (!tenantTemplates) {
      // get all TenantTemplates
      dispatch(getAllTemplates(currentTenant, 0, 100, '', 'name', 'ASC', systemMessagesTypes[selectedType]));
    }

    // unmount clear state
    return () => {
      setSelectedName('');
      setSelectedContent('');
      setSubjectContent(null);
      setEditing(false);
      dispatch(setAllowChange(true));
    };
  }, [currentTenant]);

  useEffect(() => {
    if (customFontsList && customFontsList.length) {
      setTenantCustomFontsList(customFontsList);
    }
  }, [customFontsList]);

  useEffect(() => {
    if (selectedTemplate?.name) {
      setSelectedName(selectedTemplate?.name);
    }
  }, [selectedTemplate]);

  // once tenant templates, selected template and settings are available, set template type/email/content
  useEffect(() => {
    if (tenantTemplates && tenantTemplates.items && !templateLoading && selectedTemplate) {
      setSelectedContent(selectedTemplate.content);
    }
    if (tenantTemplates && selectedTemplateSettings) {
      setSubjectContent(selectedTemplateSettings.subject);
      setEnabledTemplate(selectedTemplateSettings.enabled);
      setEnabledSMS(selectedTemplateSettings.sms);
      setEnabledPushNotifications(selectedTemplateSettings.push);
      setEnabledEmail(selectedTemplateSettings.email);
      setEnabledSAFE(selectedTemplateSettings.safe);
    }
  }, [templateLoading, tenantTemplates, selectedTemplate, selectedTemplateSettings, templateId]);

  // handles save of template with json and html for email, and text for sms
  const handleSaveTemplate = (templateId, html, json) => {
    let tpl;
    let id;
    if (json) {
      tpl = {
        id: templateId,
        content: html,
        specs: JSON.stringify(json),
      };
      id = templateId;
    } else if (selectedTemplate) {
      tpl = {
        id: templateId,
        content: selectedContent,
        specs: null,
      };
      id = templateId;
    }
    setEditing(false);
    handleSaveSettings();
    dispatch(saveTemplate(currentTenant.accountId, currentTenant.id, id, tpl));
  };

  const handleTestTemplate = (email) => {
    if (selectedTemplate) {
      dispatch(testTemplate(currentTenant.accountId, currentTenant.id, templateId, email));
    }
  };

  // handling the save of template settings
  const handleSaveSettings = () => {
    const settings = { subject: subjectContent };
    setEditing(false);
    if (selectedTemplate) {
      dispatch(saveTemplateSettings(currentTenant.accountId, currentTenant.id, templateId, settings));
    }
  };

  // handle update of sms content
  const updateSmsContent = (e) => {
    const { value } = e.target;
    setSelectedContent(value);
  };

  // handle update of subject content for emails
  const updateSubjectContent = (e) => {
    const { value } = e.target;
    setSubjectContent(value);
  };

  // cancel editing of email / sms settings
  const handleCancel = () => {
    setEditing(false);
    if (selectedTemplate) {
      setSelectedContent(selectedTemplate.content);
    }
    if (selectedTemplateSettings) {
      setSubjectContent(selectedTemplateSettings.subject);
    }
  };

  // show notification after successful copy of template json to clipboard
  const handleConfirmCopy = () => {
    dispatch(showNotification('Template successfully copied to clipboard', 'success'));
  };

  const handleEnabling = (toggle, boolean, type) => {
    let settings;
    let subToggles;

    switch (toggle) {
      case 'enabled': {
        setEnabledTemplate(boolean);
        if (type === 'email') {
          setEnabledEmail(boolean);
          setEnabledSAFE(boolean);
          subToggles = ['email', 'safe'];
        } else {
          setEnabledPushNotifications(boolean);
          setEnabledSMS(boolean);
          subToggles = ['push', 'sms'];
        }
        break;
      }
      case 'sms': {
        setEnabledSMS(boolean);
        break;
      }
      case 'push': {
        setEnabledPushNotifications(boolean);
        break;
      }
      case 'safe': {
        setEnabledSAFE(boolean);
        break;
      }
      case 'email': {
        setEnabledEmail(boolean);
        break;
      }
      default:
        break;
    }

    if (toggle !== 'enabled') {
      // if enabled is false & toggles are true
      if (!enabledTemplate && (enabledEmail || enabledPushNotifications || enabledSAFE || enabledSMS)) {
        settings = {
          ...selectedTemplateSettings,
          [toggle]: boolean,
          enabled: boolean,
        };
      } else {
        // enabled true so toggles only the selected switch
        settings = {
          ...selectedTemplateSettings,
          [toggle]: boolean,
        };
      }
    } else {
      // toggles all on or off
      settings = {
        ...selectedTemplateSettings,
        [toggle]: boolean,
        [subToggles[0]]: boolean,
        [subToggles[1]]: boolean,
      };
    }

    // disables enabled toggle if other toggles are disabled
    if (settings.enabled && ((!settings.email && !settings.safe) || (!settings.sms && !settings.push))) {
      settings.enabled = false;
    }

    if (selectedTemplate) {
      dispatch(saveTemplateSettings(currentTenant.accountId, currentTenant.id, templateId, settings));
    }
  };

  // if error while trying to import template, show notification
  const errorNotification = (err) => {
    if (err.includes('Unexpected token')) {
      err = 'This is not a valid template. Please try to copy the desired template again.';
    }
    dispatch(showNotification(err, 'error'));
  };

  const getEmailSettingsLayout = () => {
    if (editing)
      return (
        <div>
          <Button
            variant="outlined"
            size="small"
            onClick={handleCancel}
            disabled={submittingSettings}
            style={{ marginRight: '15px' }}
          >
            Cancel
          </Button>
          <Tooltip title="Save Updates">
            <IconButton onClick={handleSaveSettings}>
              {submittingSettings ? <CircularProgress color="primary" size={20} /> : <SaveIcon color="primary" />}
            </IconButton>
          </Tooltip>
        </div>
      );
    if (submittingSettings) return <CircularProgress color="primary" />;
    return (
      <Tooltip title="Edit Email Settings Text">
        <IconButton onClick={() => setEditing(true)} style={{ height: '45px' }}>
          <EditIcon />
        </IconButton>
      </Tooltip>
    );
  };

  const getNonTenantEmailSettings = () => {
    if (
      selectedName &&
      selectedTemplate &&
      selectedType === systemMessagesTabTypes.EMAIL &&
      matches &&
      !templateLoading
    )
      return (
        <Grid item style={{ marginTop: 50 }} xs={12}>
          <SettingsTemplateEditor
            selectedTemplate={selectedTemplate}
            tenantCustomFontsList={tenantCustomFontsList}
            handleSaveTemplate={handleSaveTemplate}
            handleTestTemplate={handleTestTemplate}
            confirmCopy={handleConfirmCopy}
            errorNotification={errorNotification}
            submitting={submittingTemplate}
            submittingTest={submittingTest}
            templateId={templateId}
          />
        </Grid>
      );
    if (!selectedType || selectedType === systemMessagesTabTypes.EMAIL) {
      return (
        <Grid
          container
          style={{ marginTop: 50 }}
          justifyContent="space-between"
          alignContent="center"
          alignItems="center"
        >
          <Paper style={{ padding: 20, borderRadius: 20, width: '100%' }} elevation={0}>
            <TemplateEditorSkeletonLoading />
          </Paper>
        </Grid>
      );
    }
    return null;
  };

  const renderEmailSettings = () => {
    if (
      !templateLoading &&
      // subjectContent !== null &&
      selectedTemplateSettings &&
      selectedType === systemMessagesTabTypes.EMAIL
    ) {
      return (
        <Grid item xs={12}>
          <Paper style={{ padding: 20, borderRadius: 20 }} elevation={0}>
            <Grid container justifyContent="space-between" alignContent="center" alignItems="center">
              <Grid item xs={8}>
                <Typography variant="h6">Email Settings</Typography>
              </Grid>
              <Grid item xs={4} style={{ textAlign: 'right' }}>
                {getEmailSettingsLayout()}
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={9} style={{ margin: '20px 0 20px', paddingRight: '25px' }}>
                    {!editing ? (
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography color="primary" variant="caption">
                            Email Subject
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="subtitle1">{subjectContent}</Typography>
                        </Grid>
                      </Grid>
                    ) : (
                      <TextField
                        label="Email Subject"
                        name="subject"
                        placeholder="Email Subject"
                        margin="normal"
                        variant="outlined"
                        value={subjectContent}
                        onChange={updateSubjectContent}
                        autoComplete="off"
                        type="number"
                        multiline
                        rows={1}
                        rowsMax={2}
                        required
                        fullWidth
                        InputLabelProps={{
                          style: {
                            fontSize: '18px',
                            position: 'absolute',
                            top: '-10px',
                            left: '-4px',
                            backgroundColor: '#fff',
                            padding: '8px',
                          },
                        }}
                      />
                    )}
                  </Grid>
                  <Grid item xs={3}>
                    <SettingsToggles
                      type="email"
                      enabledTemplate={enabledTemplate}
                      handleSettingsToggleChange={handleEnabling}
                      enabledEmail={enabledEmail}
                      enabledSAFE={enabledSAFE}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      );
    }
    if (
      // subjectContent !== null &&
      selectedTemplate &&
      !templateLoading &&
      selectedType === systemMessagesTabTypes.SMS
    ) {
      return (
        <Grid item xs={12}>
          <Paper className={classes.smsPaper} elevation={0}>
            <Grid
              container
              justifyContent="space-between"
              alignContent="center"
              alignItems="center"
              style={{ paddingBottom: 10 }}
            >
              <Grid item xs={8}>
                <Typography variant="h5">SMS Notification Settings</Typography>
              </Grid>
              <Grid item xs={4} style={{ textAlign: 'right' }}>
                {editing ? (
                  <div>
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={handleCancel}
                      disabled={submittingSettings}
                      style={{ marginRight: '15px' }}
                    >
                      Cancel
                    </Button>
                    <Tooltip title="Save Updates">
                      <IconButton onClick={() => handleSaveTemplate(templateId)}>
                        <SaveIcon color="primary" />
                      </IconButton>
                    </Tooltip>
                  </div>
                ) : (
                  <Tooltip title="Edit SMS Text">
                    <IconButton onClick={() => setEditing(true)}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </Grid>
              <Grid item xs={9} style={{ margin: '20px 0 20px', paddingRight: '25px' }}>
                {!editing ? (
                  <Grid container style={{ marginBottom: '25px' }}>
                    <Grid item xs={12}>
                      <Typography color="primary" variant="caption">
                        SMS Notification Title
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="subtitle1">{subjectContent}</Typography>
                    </Grid>
                  </Grid>
                ) : (
                  <TextField
                    label={`SMS Notification Title`}
                    name="content"
                    placeholder={selectedName}
                    margin="normal"
                    variant="outlined"
                    value={subjectContent}
                    onChange={updateSubjectContent}
                    autoComplete="off"
                    type="number"
                    multiline
                    required
                    fullWidth
                    InputLabelProps={{
                      style: {
                        fontSize: '18px',
                        position: 'absolute',
                        top: '-10px',
                        left: '-4px',
                        backgroundColor: '#fff',
                        padding: '8px',
                      },
                    }}
                  />
                )}
                {!editing ? (
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography color="primary" variant="caption">
                        SMS Notification Text
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="subtitle1">{selectedContent}</Typography>
                    </Grid>
                  </Grid>
                ) : (
                  <TextField
                    label={`SMS Text for ${selectedName}`}
                    name="content"
                    placeholder={selectedName}
                    margin="normal"
                    variant="outlined"
                    value={selectedContent}
                    onChange={updateSmsContent}
                    autoComplete="off"
                    type="number"
                    multiline
                    rows={5}
                    rowsMax={10}
                    required
                    fullWidth
                    InputLabelProps={{
                      style: {
                        fontSize: '18px',
                        position: 'absolute',
                        top: '-10px',
                        left: '-4px',
                        backgroundColor: '#fff',
                        padding: '8px',
                      },
                    }}
                  />
                )}
              </Grid>
              <Grid item xs={3}>
                <SettingsToggles
                  enabledTemplate={enabledTemplate}
                  handleSettingsToggleChange={handleEnabling}
                  enabledSMS={enabledSMS}
                  enabledPushNotifications={enabledPushNotifications}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      );
    }
    return <SkeletonLoading />;
  };

  const renderSelectedName = () => {
    if (!selectedName || templateLoading) return <SelectedNameSkeletonLoader />;

    return <Typography variant="h4">{selectedName}</Typography>;
  };

  if (!currentTenant) return <div />;
  return (
    <Grid container>
      <Grid item xs={12} style={{ margin: '30px 0 30px' }}>
        {renderSelectedName()}
      </Grid>
      {renderEmailSettings()}

      {getNonTenantEmailSettings()}
      {selectedTemplate?.content && selectedType === systemMessagesTabTypes.EMAIL && !matches && (
        <Grid item xs={12}>
          <iframe
            src={utils.createIFrameContentData(selectedTemplate?.content)}
            id="content_iFrame"
            style={{ border: 0, height: 600, width: '100%' }}
            title="System Message"
          />
          <Typography variant="caption">
            * Templates on mobile are preview only. To edit, please login on a desktop.
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}

export default withStyles(SettingsTemplatesStyles)(SettingsTemplateEditorWrapper);
