import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../api';

/* FirstView Subscriber Simulated Data slice */
const initialState = {
  /* state:  init | idle | pending | fulfilled | rejected */
  fvSubsLoading: 'idle',
  fvSubsData: null,
  error: null,
};

// SHOPIFY SIMULATOR
const fvSubsSimulatorSlice = createSlice({
  name: 'fvSubsSimulatorSlice',
  initialState,
  reducers: {
    resetFvSubsLoading: (state) => {
      state.fvSubsLoading = 'idle';
    },
    resetFvSubsData: (state) => {
      state.fvSubsData = null;
    },
    resetFvSubsErr: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    /*  Load Fv Simulated Subscriber Data */
    builder.addCase(loadSubsData.pending, (state, action) => {
      if (action.meta.requestStatus === 'pending') {
        state.fvSubsLoading = 'pending';
      }
    });
    builder.addCase(loadSubsData.fulfilled, (state, action) => {
      if (action.meta.requestStatus === 'fulfilled') {
        state.fvSubsLoading = 'fulfilled';
      }

      if (action.payload[1]?.status >= 400) {
        state.error = {
          ...state.error,
          fvDataErr: {
            message: action.payload[1]?.message
          }
        }
      }

      /* There will be NO data returned for  first time */
      state.fvSubsData = action.payload[0]?.data ?? null;
    });
    builder.addCase(loadSubsData.rejected, (state) => {
      state.fvSubsLoading = 'rejected';
    });

    /* UPDATE FV Subscriber */
    builder.addCase(updateFvSubscriber.pending, (state, action) => {
      if (action.meta.requestStatus === 'pending') {
        state.fvSubsLoading = 'pending';
      }
    });
    builder.addCase(updateFvSubscriber.fulfilled, (state, action) => {
      if (action.meta.requestStatus === 'fulfilled') {
        state.fvSubsLoading = 'fulfilled';
      }

      if (action.payload[1]?.status >= 400) {
        state.error = {
          ...state.error,
          fvDataErr: {
            message: action.payload[1]?.message
          }
        }
      }

      /* There will be NO data returned for  first time */
      state.fvSubsData = action.payload[0]?.data ?? null;
    });
    builder.addCase(updateFvDependent.rejected, (state) => {
      state.fvSubsLoading = 'rejected';
    });

    /* UPDATE FV Dependents */
    builder.addCase(updateFvDependent.pending, (state, action) => {
      if (action.meta.requestStatus === 'pending') {
        state.fvSubsLoading = 'pending';
      }
    });
    builder.addCase(updateFvDependent.fulfilled, (state, action) => {
      if (action.meta.requestStatus === 'fulfilled') {
        state.fvSubsLoading = 'fulfilled';
      }

      if (action.payload[1]?.status >= 400) {
        state.error = {
          ...state.error,
          fvDataErr: {
            message: action.payload[1]?.message
          }
        }
      }

      /* There will be NO data returned for  first time */
      state.fvSubsData = action.payload[0]?.data ?? null;
    });
    builder.addCase(updateFvSubscriber.rejected, (state) => {
      state.fvSubsLoading = 'rejected';
    });

    /* ADD FV Dependents */
    builder.addCase(addFvDevependent.pending, (state, action) => {
      if (action.meta.requestStatus === 'pending') {
        state.fvSubsLoading = 'pending';
      }
    });
    builder.addCase(addFvDevependent.fulfilled, (state, action) => {
      if (action.meta.requestStatus === 'fulfilled') {
        state.fvSubsLoading = 'fulfilled';
      }

      console.log(action)

      if (action.payload[1]?.status >= 400) {
        state.error = {
          ...state.error,
          fvDataErr: {
            message: action.payload[1]?.message
          }
        }
      }

      /* There will be NO data returned for  first time */
      state.fvSubsData = action.payload[0]?.data ?? null;
    });
    builder.addCase(addFvDevependent.rejected, (state) => {
      state.fvSubsLoading = 'rejected';
    });
  },
});

export const { resetFvSubsData, resetFvSubsLoading, resetFvSubsErr } = fvSubsSimulatorSlice.actions;

export const loadSubsData = createAsyncThunk(
  'fvSubsSimulatorSlice/loadSubsData',
  async (params, { rejectWithValue, getState, dispatch }) => {
    try {
      const state = getState();

      // If error obj is NOT empty | if Error exists
      if (!(JSON.stringify(state?.error) === '{}')) {
        dispatch(resetFvSubsErr());
      }

      const { tenantId, externalId } = params;
      const result = await api.getSimulateFvSubscriber(tenantId, externalId);

      return result;
    } catch (error) {
      if (!error.response) {
        throw new Error(error);
      }

      return rejectWithValue(error.response.data);
    }
  }
);

// UPDATE Subscriber Info
export const updateFvSubscriber = createAsyncThunk(
  'fvSubsSimulatorSlice/updateFvSubscriber',
  async (params, { rejectWithValue, getState, dispatch }) => {
    try {
      const state = getState();

      // If error obj is NOT empty | if Error exists
      if (!(JSON.stringify(state?.error) === '{}')) {
        dispatch(resetFvSubsErr());
      }

      const { tenantId, externalId, formData } = params;
      const result = await api.postUpdateFvSubscriber(tenantId, externalId, formData);

      return result;
    } catch (error) {
      if (!error.response) {
        throw new Error(error);
      }

      return rejectWithValue(error.response.data);
    }
  }
);

// UPDATE Dependent Info
export const updateFvDependent = createAsyncThunk(
  'fvSubsSimulatorSlice/updateFvDependent',
  async (params, { rejectWithValue, getState, dispatch }) => {
    try {
      const state = getState();

      // If error obj is NOT empty | if Error exists
      if (!(JSON.stringify(state?.error) === '{}')) {
        dispatch(resetFvSubsErr());
      }


      const { tenantId, externalId, depExternalId, formData } = params;
      const result = await api.postUpdateFvDependent(tenantId, externalId, depExternalId, formData);

      return result;
    } catch (error) {
      if (!error.response) {
        throw new Error(error);
      }

      return rejectWithValue(error.response.data);
    }
  }
);

// UPDATE Dependent Info
export const addFvDevependent = createAsyncThunk(
  'fvSubsSimulatorSlice/addFvDevependent',
  async (params, { rejectWithValue, getState, dispatch }) => {
    try {
      const state = getState();

      // If error obj is NOT empty | if Error exists
      if (!(JSON.stringify(state?.error) === '{}')) {
        dispatch(resetFvSubsErr());
      }


      const { tenantId, externalId, formData } = params;
      const result = await api.postAddFvDependent(tenantId, externalId, formData);

      return result;
    } catch (error) {
      if (!error.response) {
        throw new Error(error);
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export default fvSubsSimulatorSlice.reducer;
