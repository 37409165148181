export const allergyColumns = () => [
  {
    id: 'allergy',
    label: 'Allergy',
    show: true,
    minWidth: 200,
    locked: true,
  },
  {
    id: 'onsetDate',
    label: 'Onset Date',
    show: true,
    minWidth: 150,
    sortable: false,
  },
  {
    id: 'verificationStatus',
    label: 'Verification Status',
    show: true,
    minWidth: 150,
    sortable: false,
  },
  {
    id: 'clinicalStatus',
    label: 'Clinical Status',
    show: true,
    minWidth: 100,
    sortable: false,
  },
  {
    id: 'reaction',
    label: 'Reaction',
    show: true,
    sortable: false,
    minWidth: 150,
  },
  {
    id: 'severity',
    label: 'Severity',
    show: true,
    sortable: false,
    minWidth: 100,
  },
  {
    id: 'type',
    label: 'Type',
    show: true,
    minWidth: 100,
    sortable: false,
  },
  {
    id: 'code',
    label: 'Code',
    show: true,
    minWidth: 150,
    sortable: false,
  },
];

export const conditionColumns = () => [
  {
    id: 'conditionName',
    label: 'Condition Name',
    show: true,
    minWidth: 200,
    locked: true,
  },
  {
    id: 'dateRecorded',
    label: 'Date Recorded',
    show: true,
    minWidth: 150,
    sortable: false,
  },
  {
    id: 'severity',
    label: 'Severity',
    show: true,
    minWidth: 100,
    sortable: false,
  },
  {
    id: 'verificationStatus',
    label: 'Verification Status',
    show: true,
    minWidth: 150,
    sortable: false,
  },
  {
    id: 'clinicalStatus',
    label: 'Clinical Status',
    show: true,
    minWidth: 100,
    sortable: false,
  },
];

export const immunizationColumns = () => [
  {
    id: 'immunizationName',
    label: 'Immunization Name',
    show: true,
    minWidth: 200,
    locked: true,
  },
  {
    id: 'manufacturer',
    label: 'Manufacturer',
    show: true,
    minWidth: 180,
    sortable: false,
  },
  {
    id: 'startDate',
    label: 'Start Date',
    show: true,
    minWidth: 130,
    sortable: false,
  },
  {
    id: 'status',
    label: 'Status',
    show: true,
    minWidth: 120,
    sortable: false,
  },
  {
    id: 'route',
    label: 'Route',
    show: true,
    minWidth: 150,
    sortable: false,
  },
  {
    id: 'site',
    label: 'Site',
    show: true,
    minWidth: 150,
    sortable: false,
  },
  {
    id: 'provider',
    label: 'Provider',
    show: true,
    minWidth: 100,
    sortable: false,
  },
];

export const immunizationTableButtons = (setDialogOpen, pushImmunizationAction, pullImmunizationAction) => [
  {
    title: 'Add New',
    buttonType: 'action',
    variant: 'outlined',
    action: () => {
      setDialogOpen(true);
    },
  },
  {
    title: 'Push Immunization',
    buttonType: 'action',
    variant: 'outlined',
    action: () => pushImmunizationAction(),
  },
  {
    title: 'Pull Immunization',
    buttonType: 'action',
    variant: 'outlined',
    action: () => pullImmunizationAction(),
  },
];

export const medicationColumns = () => [
  {
    id: 'drugName',
    label: 'Drug Name',
    show: true,
    minWidth: 200,
    locked: true,
  },
  {
    id: 'action',
    label: 'Action',
    show: true,
    minWidth: 100,
    sortable: false,
  },
  {
    id: 'dose',
    label: 'Dose',
    show: true,
    minWidth: 80,
    sortable: false,
  },
  {
    id: 'doseUnit',
    label: 'Dose Unit',
    show: true,
    minWidth: 110,
    sortable: false,
  },
  {
    id: 'route',
    label: 'Route',
    show: true,
    sortable: false,
    minWidth: 120,
  },
  {
    id: 'doseTiming',
    label: 'Dose Timing',
    show: true,
    sortable: false,
    minWidth: 150,
  },
  {
    id: 'doseOther',
    label: 'Dose Other',
    show: true,
    minWidth: 150,
    sortable: false,
  },
  {
    id: 'quantity',
    label: 'Quantity',
    show: true,
    minWidth: 120,
    sortable: false,
  },
  {
    id: 'effectiveStart',
    label: 'Effective Start',
    show: true,
    minWidth: 150,
    sortable: false,
  },
  {
    id: 'effectiveEnd',
    label: 'Effective End',
    show: true,
    minWidth: 150,
    sortable: false,
  },
  {
    id: 'prescriptionId',
    label: 'Prescription Id',
    show: true,
    minWidth: 150,
    sortable: false,
  },
];

export const medicationTableButtons = (setDialogOpen, sentMedicationToDrfirst, isSyncDrfirstDisabled) => [
  {
    title: 'Add New',
    buttonType: 'action',
    variant: 'outlined',
    action: () => {
      setDialogOpen(true);
    },
  },
  {
    title: 'Sync Drfirst',
    buttonType: 'action',
    variant: 'outlined',
    disabled: isSyncDrfirstDisabled,
    action: () => {
      sentMedicationToDrfirst();
    },
  },
];

export const prescriptionColumns = () => [
  {
    id: 'id',
    label: 'Id',
    show: true,
    minWidth: 200,
    locked: true,
  },
  {
    id: 'status',
    label: 'Status',
    show: true,
    minWidth: 150,
    sortable: false,
  },
];

export const procedureColumns = () => [
  {
    id: 'procedure',
    label: 'Procedure',
    show: true,
    minWidth: 200,
    locked: true,
  },
  {
    id: 'bodySite',
    label: 'Body Site',
    show: true,
    minWidth: 150,
    sortable: false,
  },
  {
    id: 'practiceName',
    label: 'Practice Name',
    show: true,
    minWidth: 150,
    sortable: false,
  },
  {
    id: 'note',
    label: 'Note',
    show: true,
    minWidth: 100,
    sortable: false,
  },
  {
    id: 'reason',
    label: 'Reason',
    show: true,
    sortable: false,
    minWidth: 150,
  },
];

export const procedureTableButtons = () => [
  // {
  //   title: "Add New",
  //   buttonType: "action",
  //   variant: "outlined",
  //   action: () => {
  //     setDialogOpen(true);
  //   },
  // },
];

export const procedureDialogFields = () => [
  {
    id: 'procedureCode',
    label: 'Procedure Name',
  },
  {
    id: 'procedureCategory',
    label: 'Procedure Category',
  },
  {
    id: 'procedureCodeValue',
    label: 'Procedure Code',
  },
  {
    id: 'bodySite',
    label: 'Body Site',
  },
  {
    id: 'performedStartDate',
    label: 'Performed Start Date',
  },
  {
    id: 'performedEndDate',
    label: 'Performed End Date',
  },
  {
    id: 'practitioner',
    label: 'Practitioner',
  },
  {
    id: 'reason',
    label: 'Reason',
  },
  {
    id: 'reasonCodeValue',
    label: 'Reason Code',
  },
  {
    id: 'status',
    label: 'Status',
  },
  {
    id: 'notes',
    label: 'Notes',
  },
];

export const vitalColumns = () => [
  {
    id: 'vitalName',
    label: 'Vital Name',
    show: true,
    minWidth: 200,
    locked: true,
  },
  {
    id: 'codeValue',
    label: 'Code Value',
    show: true,
    minWidth: 150,
    sortable: false,
  },
  {
    id: 'vitalValue',
    label: 'Vital Value',
    show: true,
    minWidth: 150,
    sortable: false,
  },
  {
    id: 'codeUnit',
    label: 'Code Unit',
    show: true,
    minWidth: 150,
    sortable: false,
  },
];

export const vitalTableButtons = (setDialogOpen) => [
  {
    title: 'Add New',
    buttonType: 'action',
    variant: 'outlined',
    action: () => {
      setDialogOpen(true);
    },
  },
];

export const vitalDialogFields = () => [
  {
    id: 'vitalName',
    label: 'Vital Name',
  },
  {
    id: 'vitalCode',
    label: 'Vital Code',
  },
  {
    id: 'vitalCodeValue',
    label: 'Vital Code Value',
  },
  {
    id: 'vitalQuantity',
    label: 'Vital Value',
  },
  {
    id: 'vitalQuantityCodeValue',
    label: 'Vital Value Unit',
  },
  {
    id: 'effectiveDateTime',
    label: 'Date',
  },
  {
    id: 'status',
    label: 'Status',
  },
];
