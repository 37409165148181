/* eslint-disable import/namespace */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-named-as-default */
import Box from '@material-ui/core//Box';
import Button from '@material-ui/core//Button';
import Menu from '@material-ui/core//Menu';
import Tooltip from '@material-ui/core//Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import LayersClearOutlinedIcon from '@material-ui/icons/LayersClearOutlined';
import RemoveIcon from '@material-ui/icons/Remove';
import SyncIcon from '@material-ui/icons/Sync';
import TuneIcon from '@material-ui/icons/Tune';

import { TextFilter } from '@progress/kendo-react-data-tools';
import { DateInput, DateRangePicker as KendoDateRangePicker } from '@progress/kendo-react-dateinputs';
import { ComboBox } from '@progress/kendo-react-dropdowns';
// import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import ContentStyles from '../../../assets/jss/components/ContentStyles';
// import DateRangeStyles from '../../../assets/jss/components/DateRangeStyles';
import TableWrapperStyles from '../../../assets/jss/components/TableWrapperStyles';
import LanguageMenu from '../../LanguageMenu/LanguageMenu';
import { selectPrevFilterState } from '../../Users/selectors';
import SearchBar from '../Searchbar';
import TagSearchBar from '../TagSearch';
import utils from '../Utils/utils';

import KendoTable from './KendoTable';
import MultiSelectFilter from './MultiSelectFilter';
import { TableSkeletonLoading, TabTableSkeletonLoading } from './TableSkeletonLoading';

const KendoTableWrapper = React.memo((props) => {
  const {
    filters,
    columns,
    data,
    disableAutoFocus,
    onRowClick,
    onTableUpdate,
    tableButtons,
    initialSort,
    initialDir,
    showSearchbar,
    searchAdornmentPos,
    showFilterIcon,
    showFilterClearIcon,
    showOpenFilters,
    handleShowOpenFilters,
    showButtons,
    loading,
    onSelectionChange,
    onHeaderSelectionChange,
    detailsComponent,
    parent,
    showTagSearch,
    paginationPages,
    disablePagination,
    sorted,
    suppliedPageSize,
    searchInState,
    oneLineSearchBar,
    noRecordsMessage,
    showLanguageMenu,
    tableTitle,
    sortingMode,
    showBulkAction,
    selectIds,
    onSelectAll,
    selectAll,
    onBulkAction,
    isEnableDateFilter,
    setSelectIds,
    isEnableSelectAllMessage,
    prevFilterState,
  } = props;

  const classes = useTableStyles();

  const contentClasses = useContentStyles();
  const tableClasses = useStyles();

  const [advancedSearchBoolean, setAdvancedSearchBoolean] = useState(showOpenFilters || false);
  const [action] = useState('');
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(suppliedPageSize || 10);
  const [sort, setSort] = useState(initialSort);
  const [direction, setDirection] = useState(initialDir);
  const [filter, setFilter] = useState('');
  const [searchValue, setSearchValue] = useState(prevFilterState?.searchValue || '');
  const [tags, setTags] = useState([]);
  const [loadSkeleton, setLoadSkeleton] = useState(true);
  const [type] = useState('table');
  const [anchorElMenu, setAnchorElMenu] = useState(null);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const [timeout, setStateTimeout] = useState(undefined);
  const [filterDetails, setFilterDetails] = filters ? useState(JSON.parse(JSON.stringify(filters))) : useState(filters);
  const [isClearSearchVal, setisClearSearchVal] = useState(false);

  const prevFilter = useSelector(selectPrevFilterState);

  useEffect(() => {
    setAdvancedSearchBoolean(showOpenFilters);
  }, [showOpenFilters]);

  useEffect(() => {
    if (prevFilterState && Object.keys(prevFilterState).length !== 0) {
      setSearchValue(prevFilterState?.searchValue || filter?.searchValue || '');
      setFilter({ ...prevFilterState });
    }
    setisClearSearchVal(false);
  }, [prevFilterState, prevFilter]);

  useEffect(() => {
    setSearchValue(searchInState);
    setFilterDetails(filters);
  }, [filters, searchValue]);

  useEffect(() => {
    if (
      data &&
      (parent === 'content' ||
        parent === 'custom-report' ||
        parent === 'system-messages' ||
        parent === 'virtual-consult')
    ) {
      const dataPage = parent === 'virtual-consult' ? data.number : data.page;
      setPage(dataPage || 0);
    }
  }, [data, parent]);

  // PAGINATION
  const onPaginationChange = useCallback(
    (page, pageSize) => {
      if (onTableUpdate) {
        setLoadSkeleton(false);
        setPage(page);
        setPageSize(pageSize);
        onTableUpdate(page, pageSize, sort, direction, searchValue, filter, tags);
      }
    },
    [sort, direction, searchValue, filter, onTableUpdate, tags]
  );

  // SORTING
  const handleCustomSort = useCallback(
    (sortName, order) => {
      if (onTableUpdate) {
        setLoadSkeleton(false);
        setSort(sortName);
        setDirection(order);
        onTableUpdate(page, pageSize, sortName, order, searchValue, filter, tags);
      }
    },
    [page, pageSize, searchValue, filter, onTableUpdate, tags]
  );

  // FILTERING
  const handleAdvancedSearchClick = useCallback(() => {
    const newAdvancedSearchBoolean = !advancedSearchBoolean;

    setAdvancedSearchBoolean(newAdvancedSearchBoolean);
    if (handleShowOpenFilters) {
      handleShowOpenFilters(newAdvancedSearchBoolean);
    }
  }, [advancedSearchBoolean, prevFilter, prevFilterState]);

  const isPrevFilterEmpty = (prevFilter) => {
    const isPrevFilterEmpty = Object.keys(prevFilter).length === 0 && prevFilter.constructor === Object;
    return isPrevFilterEmpty;
  };

  const handleFilter = useCallback(
    (e) => {
      if (onTableUpdate) {
        setLoadSkeleton(false);
        const filterName = e.target.name;
        let filterValue = e.target.value;

        if (filterValue) {
          const filterData = filterDetails.find((f) => f.id === filterName);
          if (filterData && filterData.valueField) {
            filterValue = e.target.value[filterData.valueField];
          }
        }

        // reset selected Ids when filter is triggered
        if (setSelectIds) setSelectIds([]);

        setPage(0);
        const newStateFilter = {
          ...filter,
          [filterName]: e.target.value,
        };

        setFilter(newStateFilter);
        onTableUpdate(
          0,
          pageSize,
          sort,
          direction,
          searchValue || prevFilter?.searchValue,
          newStateFilter,
          tags,
          false
        );
      }
    },
    [onTableUpdate, filter, pageSize, sort, direction, searchValue, tags, filterDetails, prevFilterState, prevFilter]
  );

  // Search by tags

  const handleChangeTags = useCallback(
    (newTags) => {
      if (onTableUpdate) {
        const currentPage = 0;
        setPage(currentPage);
        setTags(newTags);
        onTableUpdate(currentPage, pageSize, sort, direction, searchValue, filter, newTags);
      }
    },
    [onTableUpdate, pageSize, sort, direction, searchValue, filter]
  );

  // on clear of searchbar
  const handleClear = useCallback(() => {
    if (onTableUpdate) {
      setLoadSkeleton(false);
      setSearchValue('');
      const currentPage = 0;
      setPage(currentPage);
      onTableUpdate(0, pageSize, sort, direction, '', filter, tags, true);
    }
  }, [pageSize, sort, direction, filter, onTableUpdate, tags, searchValue]);

  // SEARCHBAR
  // on text change in searchbar
  const handleTextChange = useCallback(
    (e) => {
      if (!e.target.value.length) {
        if (timeout) {
          clearTimeout(timeout);
        }
        handleClear();
      } else if (e.target.value.length > 2) {
        if (onTableUpdate) {
          setLoadSkeleton(false);
          const currentPage = 0;
          const { value } = e.target;
          setPage(currentPage);
          setSearchValue(value);
          if (timeout) {
            clearTimeout(timeout);
          }
          setStateTimeout(
            setTimeout(() => {
              onTableUpdate(currentPage, pageSize, sort, direction, value, filter, tags);
            }, 800)
          );
        }
      }
    },
    [handleClear, onTableUpdate, timeout, pageSize, sort, direction, filter, tags]
  );

  const handleClickMenu = (event) => {
    setAnchorElMenu(event.currentTarget);
  };

  const handleClearFilterClick = useCallback(() => {
    if (onTableUpdate) {
      setLoadSkeleton(false);

      // reset selected Ids when filter is triggered
      if (setSelectIds) setSelectIds([]);

      setPage(0);

      const defaultSearchValue = '';

      setSearchValue(defaultSearchValue);

      const newStateFilter = {
        lastTestResult: [],
        vaccineRecordStatus: [],
        permission: null,
        gender: null,
        userSegment: null,
        dateRangeFilter: {
          testResultFrom: null,
          testResultTo: null,
        },
        status: null,
      };
      setDateRangeValue(dateInputDefaultValue);
      setFilter(newStateFilter);

      const isClearFilter = true;
      setisClearSearchVal(true);

      onTableUpdate(0, pageSize, sort, direction, defaultSearchValue, newStateFilter, tags, isClearFilter);
    }
  }, [onTableUpdate, filter, pageSize, sort, direction, searchValue, tags, filterDetails]);

  const handleCloseMenu = () => {
    setAnchorElMenu(null);
  };

  const filterData = (filter, filterId) => {
    const updatedFilterDetails = JSON.parse(JSON.stringify(filters));
    if (filter.value) {
      updatedFilterDetails.map((x) => {
        if (x.id == filterId) {
          x.options = x.options.filter((s) => s.toUpperCase().indexOf(filter.value.toUpperCase()) > -1);
        }
        return x.options;
      });
    }
    return updatedFilterDetails;
  };

  const handleFilterChange = (event, filterId) => {
    if (timeout) {
      clearTimeout(timeout);
    }

    setStateTimeout(
      setTimeout(() => {
        const newData = filterData(event.filter, filterId);
        setFilterDetails(newData);
      }, 500)
    );
  };
  const handleAction = (event) => {
    const bulkActionParams = {
      action: event.target.value,
      ids: selectIds,
      filter,
      searchParam: searchValue,
    };

    onBulkAction(bulkActionParams);
    // setAction(value)
  };

  /* DATE PICKER RELATED FUNCTIONS */
  const dateInputDefaultValue = { start: null, end: null };
  const [dateRangeValue, setDateRangeValue] = useState(dateInputDefaultValue);
  const [isShowDateRange, setIsShowDateRange] = useState(false);

  // Set DatePicker default value
  const setDateRangeDefault = (prevFilter) => {
    if (!isPrevFilterEmpty(prevFilter) && prevFilter.dateRangeFilter) {
      let dateRangeVal = { start: null, end: null };

      if (prevFilter.dateRangeFilter.testResultFrom) {
        dateRangeVal = {
          ...dateRangeVal,
          start: new Date(prevFilter.dateRangeFilter.testResultFrom),
        };
      }

      if (prevFilter.dateRangeFilter.testResultTo) {
        dateRangeVal = {
          ...dateRangeVal,
          end: new Date(prevFilter.dateRangeFilter.testResultTo),
        };
      }

      return dateRangeVal;
    }

    return dateInputDefaultValue;
  };

  //
  useEffect(() => {
    if (prevFilterState) {
      setDateRangeValue(setDateRangeDefault(prevFilterState));
    }
  }, []);

  /**
   * Return Custom Start Date Field For DateRangeComponent
   */
  const handleDateInputChange = (e) => {
    if (!e.target.value) {
      setDateRangeValue(dateInputDefaultValue);
      handleResetDatePicker();
    }
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  function CustomStartDateField(props) {
    return (
      <>
        <DateInput {...props} width={200} onChange={handleDateInputChange} size="small" className={classes.dateInput} />
        <RemoveIcon style={{ paddingTop: 15 }} />
      </>
    );
  }

  /**
   * Return Custom End Date Field For DateRangeComponent
   */
  // eslint-disable-next-line react/no-unstable-nested-components
  function CustomEndDateField(props) {
    return (
      <DateInput
        {...props}
        width={200}
        onChange={handleDateInputChange}
        style={{ paddingLeft: 15 }}
        className={classes.dateInput}
      />
    );
  }

  const handleDateRangeChange = useCallback(
    (e) => {
      if (onTableUpdate) {
        setLoadSkeleton(false);
        // Close the date selector if the end date is selcted
        if (e.value && e.value.end) {
          setIsShowDateRange(false);
        }
        // reset Selected Ids when filter changes
        setSelectIds([]);

        // Do not update table if only one date is selected
        // The date range picker will always wait for the second input
        setDateRangeValue(e.value);

        const dateRangeFilter = {
          testResultFrom: '',
          testResultTo: '',
        };

        if (e.value.start) {
          dateRangeFilter.testResultFrom = utils.formatDateNoLocale(e.value.start);
        }
        if (e.value.end) {
          dateRangeFilter.testResultTo = utils.formatDateNoLocale(e.value.end);
        }

        setPage(0);
        const newStateFilter = {
          ...filter,
          dateRangeFilter,
        };
        setFilter(newStateFilter);

        onTableUpdate(0, pageSize, sort, direction, searchValue, newStateFilter, tags);
      }
    },
    [onTableUpdate, filter, pageSize, sort, direction, searchValue, tags, filterDetails]
  );

  const handleResetDatePicker = () => {
    setIsShowDateRange(false);

    if (onTableUpdate) {
      setDateRangeValue({ start: null, end: null });
    }

    const dateRangeFilter = {
      testResultFrom: '',
      testResultTo: '',
    };

    setPage(0);
    const newStateFilter = {
      ...filter,
      dateRangeFilter,
    };
    setFilter(newStateFilter);

    onTableUpdate(0, pageSize, sort, direction, searchValue, newStateFilter, tags);
  };

  const getMultiSelectFilterVal = (prevFilter, fItem) => {
    const filterVal = !isPrevFilterEmpty(prevFilter) ? prevFilter[fItem.id] : fItem.selectedByDefault;
    return filterVal;
  };

  return (
    <Grid container>
      {showLanguageMenu ? (
        <div className={contentClasses.languageWrapper} style={{ padding: '15px 0' }}>
          <LanguageMenu />
        </div>
      ) : null}

      <Grid item xs={12}>
        <Paper className={classes.paper} style={{ borderRadius: '20px' }} elevation={0}>
          {loading && loadSkeleton ? (
            <div className="tableWrapper">
              {type === 'table' ? (
                <TableSkeletonLoading desktop={matches ? 1 : 0} />
              ) : (
                <TabTableSkeletonLoading desktop={matches ? 1 : 0} />
              )}
            </div>
          ) : (
            <Grid container justifyContent="center" alignItems="center" alignContent="center">
              {oneLineSearchBar ? (
                <Grid container>
                  <Grid
                    item
                    md={filterDetails ? Math.floor(12 / (filterDetails.length + 1)) : 12}
                    xs={12}
                    className={(showSearchbar && classes.headerItem) || undefined}
                  >
                    {showSearchbar && (
                      <SearchBar
                        handleTextChange={handleTextChange}
                        handleClear={handleClear}
                        searchValue={searchValue}
                        disableAutoFocus={disableAutoFocus}
                        searchAdornmentPos={searchAdornmentPos}
                      />
                    )}
                  </Grid>
                  {advancedSearchBoolean
                    ? filterDetails &&
                      filterDetails.length &&
                      filterDetails.map((f) => (
                        <Grid
                          item
                          xs={12}
                          md={Math.floor(12 / (filterDetails.length + 1))}
                          style={{ margin: '0 0 15px 0', paddingLeft: '20px' }}
                        >
                          {f.multiSelectFilter ? (
                            <MultiSelectFilter
                              filterName={f.id}
                              filterLabel={f.label}
                              options={f.options}
                              textField={f.labelField}
                              handleFilter={handleFilter}
                              selectedByDefault={f.selectedByDefault}
                            />
                          ) : (
                            <ComboBox
                              placeholder={f.label}
                              name={f.id}
                              value={filter[f.id]}
                              data={f.options}
                              type={TextFilter}
                              onChange={handleFilter}
                              textField={f.labelField}
                              style={{ width: '100%' }}
                            />
                          )}
                        </Grid>
                      ))
                    : null}
                </Grid>
              ) : (
                <>
                  {tableTitle && (
                    <Grid container direction="row" justifyContent="flex-start">
                      <Typography variant="h6" className={classes.tableTitle}>
                        View All Uploads
                      </Typography>
                    </Grid>
                  )}
                  {showBulkAction && (
                    <Grid
                      item
                      md={2}
                      xs={12}
                      style={{
                        marginRight: 20,
                        marginLeft: 0,
                      }}
                    >
                      <FormControl fullWidth disabled={!(selectIds.length > 0 || selectAll)}>
                        <InputLabel>Bulk Action</InputLabel>
                        <Select label="Bulk Action" value={action} onChange={handleAction}>
                          <MenuItem
                            onClick={() => {
                              /* eslint-disable-next-line no-console */
                              console.log('clicked');
                            }}
                            value="sendmessage"
                          >
                            Send message to members
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  )}
                  <Grid
                    item
                    md={showBulkAction ? 4 : 5}
                    xs={9}
                    className={(showSearchbar && classes.headerItem) || undefined}
                    style={{ paddingTop: 16 }}
                  >
                    {showSearchbar && (
                      <SearchBar
                        handleTextChange={handleTextChange}
                        handleClear={handleClear}
                        searchValue={searchValue}
                        disableAutoFocus={disableAutoFocus}
                        searchAdornmentPos={searchAdornmentPos}
                        isClearSearch={isClearSearchVal}
                      />
                    )}
                  </Grid>
                  <Grid
                    item
                    md={1}
                    xs={3}
                    style={{
                      paddingTop: 16,
                    }}
                  >
                    {showFilterIcon && (
                      <Box>
                        <Tooltip title="Advanced Search" placement="top">
                          <IconButton color="primary" onClick={handleAdvancedSearchClick}>
                            <TuneIcon />
                          </IconButton>
                        </Tooltip>

                        {showFilterClearIcon && advancedSearchBoolean && (
                          <Tooltip title="Clear Filter" placement="right">
                            <IconButton color="primary" onClick={handleClearFilterClick}>
                              <LayersClearOutlinedIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                      </Box>
                    )}
                  </Grid>
                  <Grid
                    item
                    md={showBulkAction ? 5 : 6}
                    xs={12}
                    className={showButtons ? classes.headerItem : null}
                    style={{ marginLeft: showBulkAction ? -20 : 0 }}
                  >
                    {showButtons && (
                      <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        className="table-button-wrapper"
                      >
                        {tableButtons &&
                          tableButtons.map((button, i) => (
                            <Grid key={`action${i}`} item className={classes.userButtonDesktop}>
                              {button.buttonType === 'link' ? (
                                <Link to={!button.disabled && button.link} className={classes.link}>
                                  <Button
                                    className={
                                      button.variant === 'outlined'
                                        ? tableClasses.buttonOutlined
                                        : tableClasses.buttonContained
                                    }
                                    variant={button.variant}
                                    color="primary"
                                    disabled={button.disabled}
                                    disableElevation
                                    elevation={0}
                                  >
                                    {button.title}
                                  </Button>
                                </Link>
                              ) : button.buttonType === 'icon' ? (
                                <Tooltip title={`${button.icon} Table`}>
                                  <IconButton
                                    aria-label={button.icon}
                                    onClick={button.action}
                                    disabled={button.disabled}
                                  >
                                    {button.icon === 'Sync' && <SyncIcon />}
                                  </IconButton>
                                </Tooltip>
                              ) : button.buttonType === 'menu' ? (
                                <>
                                  <Button
                                    variant={button.variant}
                                    color="primary"
                                    onClick={handleClickMenu}
                                    disableElevation
                                    elevation={0}
                                  >
                                    {button.title}
                                  </Button>
                                  <Menu
                                    id="simple-menu"
                                    anchorEl={anchorElMenu}
                                    keepMounted
                                    open={Boolean(anchorElMenu)}
                                    onClose={handleCloseMenu}
                                  >
                                    {button.menuItems && button.menuItems.length
                                      ? button.menuItems.map((item, key) => (
                                          <MenuItem
                                            key={key}
                                            onClick={() => {
                                              const menuItemAction = item.action;
                                              menuItemAction();
                                              handleCloseMenu();
                                            }}
                                          >
                                            {item.title}
                                          </MenuItem>
                                        ))
                                      : null}
                                  </Menu>
                                </>
                              ) : (
                                <Button
                                  className={
                                    button.variant === 'outlined'
                                      ? tableClasses.buttonOutlined
                                      : tableClasses.buttonContained
                                  }
                                  variant={button.variant}
                                  color="primary"
                                  onClick={button.action}
                                  disabled={button.disabled}
                                  disableElevation
                                  elevation={0}
                                >
                                  {button.loading && (
                                    <Grid className={classes.buttonLoading}>
                                      <CircularProgress size={28} thickness={6} />
                                    </Grid>
                                  )}

                                  {button.title}
                                </Button>
                              )}
                            </Grid>
                          ))}
                      </Grid>
                    )}
                  </Grid>
                  <Grid item md={12} xs={10} className={(showTagSearch && classes.headerItem) || undefined}>
                    {showTagSearch && <TagSearchBar tags={tags} handleChangeTags={handleChangeTags} />}
                  </Grid>
                  {advancedSearchBoolean ? (
                    <Grid
                      item
                      container
                      alignItems="stretch"
                      xs={12}
                      direction="row"
                      justifyContent="space-between"
                      spacing={0}
                      style={{
                        marginBottom: '15px',
                      }}
                    >
                      {showBulkAction && (
                        <Grid item container md={2} xs={12} direction="column" alignItems="stretch">
                          {/* Dummy Filler to Match Figma Design */}
                          <Paper elevation={0} />
                        </Grid>
                      )}
                      <Grid
                        item
                        container
                        xs={12}
                        md={10}
                        spacing={2}
                        style={{
                          paddingLeft: '4px',
                          paddingRight: '5px',
                        }}
                      >
                        {filterDetails?.length
                          ? filterDetails.map((f) => (
                              <React.Fragment key={f.id}>
                                {f.multiSelectFilter ? (
                                  <Grid key={f.id} item xs={12} md={3} lg={3}>
                                    {/* MultiSelectFilter has display === inline-flex */}
                                    <MultiSelectFilter
                                      filterName={f.id}
                                      filterLabel={f.label}
                                      options={f.options}
                                      textField={f.labelField}
                                      handleFilter={handleFilter}
                                      selectedByDefault={getMultiSelectFilterVal(prevFilterState, f)}
                                      filterValue={filter[f.id]}
                                    />
                                  </Grid>
                                ) : (
                                  <Grid
                                    key={f.id}
                                    item
                                    xs={12}
                                    md={showBulkAction ? 2 : 3}
                                    lg={showBulkAction ? 2 : 3}
                                    container
                                    alignItems="flex-end"
                                  >
                                    {/* ComboBox has display === block */}
                                    <ComboBox
                                      placeholder={f.label}
                                      name={f.id}
                                      value={filter[f.id]}
                                      data={f.options}
                                      type={TextFilter}
                                      filterable
                                      onChange={handleFilter}
                                      onFilterChange={(e) => handleFilterChange(e, f.id)}
                                      textField={f.labelField}
                                      style={{ width: '100%' }}
                                    />
                                  </Grid>
                                )}
                              </React.Fragment>
                            ))
                          : null}
                        {isEnableDateFilter && (
                          <Grid item xs={12} md={6} lg={6}>
                            <Box>
                              {/* DATE RANGE PICKER */}
                              <Box display="block">
                                <KendoDateRangePicker
                                  // onChange={handleDateRangeChange}
                                  value={dateRangeValue}
                                  startDateInput={(p) =>
                                    CustomStartDateField({
                                      ...p,
                                      label: 'Recent Test Start Date',
                                      oldStyle: true,
                                    })
                                  }
                                  endDateInput={(p) =>
                                    CustomEndDateField({
                                      ...p,
                                      label: 'Recent Test End Date',
                                      oldStyle: true,
                                    })
                                  }
                                  onChange={handleDateRangeChange}
                                  onBlur={() => setIsShowDateRange(false)}
                                  onFocus={() => setIsShowDateRange(true)}
                                  show={isShowDateRange}
                                />
                              </Box>
                              {/* END - DATE RANGE PICKER */}
                            </Box>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  ) : null}
                </>
              )}
              {
                // Show if nothing is selected on the other pages
                isEnableSelectAllMessage &&
                  selectIds.length > 0 &&
                  !selectAll &&
                  data?.items
                    ?.map((item) => ({
                      selected: selectIds.indexOf(item._id) > -1,
                    }))
                    .findIndex((dataItem) => dataItem.selected === false) > -1 && (
                    <Grid item xs={12} style={{ backgroundColor: '#E8FBF0', paddingLeft: 24 }}>
                      You have selected <span style={{ fontWeight: 800 }}>{selectIds.length} </span> members.
                    </Grid>
                  )
              }
              {
                // Show if selected all of the items within the page
                isEnableSelectAllMessage &&
                  selectIds &&
                  selectIds.length !== 0 &&
                  !selectAll &&
                  data?.items
                    ?.map((item) => ({
                      selected: selectIds.indexOf(item._id) > -1,
                    }))
                    .findIndex((dataItem) => dataItem.selected === false) === -1 && (
                    <Grid item xs={12} style={{ backgroundColor: '#E8FBF0', paddingLeft: 24 }}>
                      {/* All&nbsp;<span style={{ fontWeight: 800 }}>{data.items.length}</span>&nbsp;members on this page are selected. */}
                      You have selected <span style={{ fontWeight: 800 }}>{selectIds.length} </span> members.{' '}
                      <a href="#" onClick={() => onSelectAll(true)} style={{ fontWeight: 600, color: '#0063ba' }}>
                        Select all {data.total} members in this tenant
                      </a>
                    </Grid>
                  )
              }

              {
                // Show if all of the items are selected
                isEnableSelectAllMessage && selectAll && selectIds.length === 0 && (
                  <Grid item xs={12} style={{ backgroundColor: '#E8FBF0', paddingLeft: 24 }}>
                    All <span style={{ fontWeight: 800 }}>{data.total} </span> members are selected.{' '}
                    <a href="#" onClick={() => onSelectAll(false)} style={{ fontWeight: 600, color: '#0063ba' }}>
                      Unselect all {data.total} selected members.
                    </a>
                  </Grid>
                )
              }
              {
                // Show if all of the items are selected and then one or more elements are unselected.
                isEnableSelectAllMessage && selectAll && selectIds.length > 0 && (
                  <Grid item xs={12} style={{ backgroundColor: '#E8FBF0', paddingLeft: 24 }}>
                    You have selected
                    <span style={{ fontWeight: 800 }}>{data.total - selectIds.length} </span>
                    members.{' '}
                    <a href="#" onClick={() => onSelectAll(false)} style={{ fontWeight: 600, color: '#0063ba' }}>
                      Unselect all {data.total - selectIds.length} selected members.
                    </a>
                  </Grid>
                )
              }
              <Grid item xs={12}>
                <KendoTable
                  data={data}
                  columns={columns}
                  sortingMode={sortingMode}
                  filters={columns.filter((c) => c.filter)}
                  page={!disablePagination && onTableUpdate && page}
                  pageSize={!disablePagination && onTableUpdate && pageSize}
                  sorted={sorted || { field: sort, dir: direction }}
                  onPaginationChange={onTableUpdate ? onPaginationChange : undefined}
                  onSort={onTableUpdate ? handleCustomSort : undefined}
                  onRowClick={onRowClick}
                  onSelectionChange={onSelectionChange}
                  onHeaderSelectionChange={onHeaderSelectionChange}
                  loading={loading}
                  detailsComponent={detailsComponent}
                  paginationPages={paginationPages}
                  noRecordsMessage={noRecordsMessage}
                  selectIds={selectIds}
                  selectAll={selectAll}
                />
              </Grid>
            </Grid>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
});

KendoTableWrapper.propTypes = {
  tableTitle: PropTypes.string,
  data: PropTypes.object.isRequired,
  initialSort: PropTypes.string,
  initialDir: PropTypes.string,
  showSearchbar: PropTypes.bool.isRequired,
  searchAdornmentPos: PropTypes.string,
  showFilterIcon: PropTypes.bool.isRequired,
  showFilterClearIcon: PropTypes.bool,
  showOpenFilters: PropTypes.bool.isRequired,
  showButtons: PropTypes.bool.isRequired,
  filters: PropTypes.array,
  tableButtons: PropTypes.array,
  columns: PropTypes.array.isRequired,
  // currentTenant: PropTypes.object,
  onRowClick: PropTypes.func,
  onTableUpdate: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  onSelectionChange: PropTypes.func,
  // jsonInput: PropTypes.bool,
  paginationPages: PropTypes.array,
  disablePagination: PropTypes.bool,
  showLanguageMenu: PropTypes.bool,
  sortingMode: PropTypes.oneOf(['single', 'multiple']).isRequired,
  showBulkAction: PropTypes.bool,
  selectIds: PropTypes.array,
  onSelectAll: PropTypes.func,
  selectAll: PropTypes.bool,
  isEnableDateFilter: PropTypes.bool,
  isEnableSelectAllMessage: PropTypes.bool,
};

KendoTableWrapper.defaultProps = {
  selectAll: false,
  selectIds: [],
  tableTitle: null,
  showSearchbar: false,
  searchAdornmentPos: 'start',
  showFilterIcon: false,
  showFilterClearIcon: false,
  showButtons: false,
  loading: false,
  paginationPages: [5, 10, 20, 50],
  onSelectionChange: () => {},
  showLanguageMenu: false,
  showBulkAction: false,
  sortingMode: 'single',
  isEnableDateFilter: false,
  isEnableSelectAllMessage: false,
  prevFilterState: {},
};

const useContentStyles = makeStyles(ContentStyles);
const useStyles = makeStyles({
  buttonOutlined: {
    border: '2px solid #2e5266!important',
    borderRadius: '8px!important',
  },
  buttonContained: {
    border: '1px solid #2e5266!important',
    borderRadius: '8px!important',
  },
  buttonStyles: {
    padding: '9px 16px',
    width: 'auto!important',
  },
  dateRange: {
    '& .k-input': {
      textAlign: 'center!important',
    },
  },
  dateInput: {
    '& .k-dateinput-wrapper': {
      width: '170px!important',
    },
  },
});

const useTableStyles = makeStyles(TableWrapperStyles);

export default KendoTableWrapper;
