/* eslint-disable react/no-array-index-key */
/* eslint-disable no-prototype-builtins */
/* eslint-disable import/no-cycle */
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import MedicalCodes from '@material-ui/icons/LocalHospital';
import AddMoreThanJumpIcon from '@material-ui/icons/OfflineBolt';
import React, { useContext, useState, useEffect } from 'react';

import { createInputHelper } from '../../../../../Shared/Forms/helpers/createInputHelper';
import { getCodeName, getFieldName } from '../../../../QuestionBuilder/helpers/formHelper';
import { defaultQuestionId, nodeLinks } from '../../../helpers/formHelper';
import { transform } from '../../../helpers/questionnaireBuilderMapper';
import { QuestionnaireBuilderContext } from '../../../QuestionnaireBuilderWrapper';
import MedicalCode from '../MedicalCode/MedicalCode';
import MoreThanOption from '../MoreThanOption';

import { checkRequired } from './helpers/jumpHelper';
import Jump from './Jump';

function JumpList(props) {
  const {
    setShowJumps,
    setDisableButtons,
    currentNode,
    nodeIndex,
    updateQuestionNodes,
    questionNodes,
    setShowAutoGenerate,
    cancelQuestionNodeUpdate,
  } = props;

  const [editDefaultJumpCoding, setEditDefaultJumpCoding] = useState(false);
  const [disableSave, setDisableSave] = useState(false);
  const { nodes, setNodes, handleSubmit, selectedQuestions } = useContext(QuestionnaireBuilderContext);

  const isNonConditional =
    currentNode.question.type !== 'numeric' &&
    currentNode.question.type !== 'multiple' &&
    currentNode.question.type !== 'multiple-drop' &&
    currentNode.question.type !== 'date' &&
    currentNode.question.type !== 'scale' &&
    currentNode.question.type !== 'slider' &&
    currentNode.question.type !== 'yes-no' &&
    currentNode.question.type !== 'large-text' &&
    currentNode.question.type !== 'small-text' &&
    currentNode.question.type !== 'multiple-size';

  useEffect(() => {
    checkRequired(questionNodes, setDisableSave);
  }, [questionNodes]);

  const addJump = () => {
    const { rules } = currentNode;

    const updatedRules = [
      ...rules,
      {
        nextQuestionId: currentNode.id,
        rules: [
          {
            type: currentNode && currentNode.question && currentNode.question.type,
            precision: currentNode && currentNode.question && currentNode.question.precision,
            questionId: currentNode && currentNode.question && currentNode.question.id,
            expectedAnswers: [],
          },
        ],
      },
    ];
    updateQuestionNodes(`[${nodeIndex}].rules`, () => updatedRules);
  };

  const addMoreThanJump = () => {
    updateQuestionNodes(`[${nodeIndex}].rules`, (rules) => [
      ...rules,
      {
        coding: null,
        nextQuestionId: currentNode.id,
        rules: [
          {
            type: 'multiple-size',
            questionId: currentNode.question.id,
            size: 1,
            operation: 'GREATER_EQUAL',
          },
        ],
      },
    ]);
  };

  const removeJump = (jumpIndex) => {
    const { rules } = currentNode;

    const updatedRules = rules.filter((rule, index) => index !== jumpIndex);
    updateQuestionNodes(`[${nodeIndex}].rules`, () => updatedRules);
  };

  const setNextQuestionId = (value, jumpIndex) => {
    const { rules } = currentNode;
    const updatedRules = rules.map((rule, index) => {
      if (jumpIndex === index) {
        return {
          ...rule,
          nextQuestionId: value,
        };
      }
      return rule;
    });
    updateQuestionNodes(`[${nodeIndex}].rules`, () => updatedRules);
  };

  const setDefaultQuestionId = (name, value) => {
    updateQuestionNodes(`[${nodeIndex}].defaultRoute`, (values) => ({
      ...values,
      defaultNextQuestionId: value,
    }));
  };

  const setNodeLinks = (name, value) => {
    updateQuestionNodes(`[${nodeIndex}].links`, () => value);
  };

  const handleSaveJumps = () => {
    const updatedNodes = nodes.map((n) => {
      const updatedNode = questionNodes.find((questionNode) => questionNode.id === n.id);
      if (!updatedNode) return n;
      return updatedNode;
    });
    const newNodes = questionNodes.filter((questionNode) => !nodes.some((n) => n.id === questionNode.id));

    const saveOutcomeNodes = [...updatedNodes, ...newNodes];
    setNodes(saveOutcomeNodes);
    handleSubmit(selectedQuestions, saveOutcomeNodes);
    setShowJumps(false);
    setDisableButtons(false);
  };

  const handleCancelJumps = () => {
    cancelQuestionNodeUpdate();
    setShowJumps(false);
    setDisableButtons(false);
  };

  const addCoding = (jumpIndex) => {
    const path = jumpIndex !== -1 ? `[${nodeIndex}].rules[${jumpIndex}]` : `[${nodeIndex}].defaultRoute`;

    updateQuestionNodes(path, (data) => {
      if (data && data.coding) return data;
      return {
        ...data,
        coding: {
          code: '',
          description: '',
          system: '',
        },
      };
    });
  };

  const editCoding = (jumpIndex) => (name, value) => {
    const path = jumpIndex !== -1 ? `[${nodeIndex}].rules[${jumpIndex}].coding` : `[${nodeIndex}].defaultRoute.coding`;

    if (name === 'codeDescription') {
      updateQuestionNodes(path, (data) => {
        if (!value) {
          return {
            ...data,
            description: null,
          };
        }
        return {
          ...data,
          code: value[getCodeName(data.system)],
          description: value[getFieldName(data.system)],
        };
      });
      return;
    }

    updateQuestionNodes(path, (data) => ({
      ...data,
      [name]: value,
    }));
  };

  const removeMedicalCode = (jumpIndex) => {
    const path = jumpIndex !== -1 ? `[${nodeIndex}].rules[${jumpIndex}].coding` : `[${nodeIndex}].defaultRoute.coding`;
    updateQuestionNodes(path, () => null);
  };

  const renderJumps = () => {
    if (!currentNode.rules.length) {
      return (
        <Jump
          jumpIndex={0}
          jump={{
            rules: [],
          }}
          setNextQuestionId={setNextQuestionId}
          addJump={addJump}
          removeJump={removeJump}
          currentNode={currentNode}
          isNonConditional={isNonConditional}
          nodes={nodes}
          updateQuestionNodes={updateQuestionNodes}
          nodeIndex={nodeIndex}
          addCoding={addCoding}
          removeMedicalCode={removeMedicalCode}
        />
      );
    }
    return currentNode.rules.map((item, index) => {
      if (item.rules.length && item.rules[0].hasOwnProperty('size')) {
        return (
          <MoreThanOption
            key={`moreThanOption_${index}`}
            jump={item}
            jumpIndex={index}
            nodeIndex={nodeIndex}
            currentNode={currentNode}
            updateQuestionNodes={updateQuestionNodes}
            setShowAutoGenerate={setShowAutoGenerate}
            addCoding={addCoding}
            removeMedicalCode={removeMedicalCode}
            editCoding={editCoding}
            removeJump={removeJump}
          />
        );
      }
      return (
        <Jump
          key={`jump_${index}`}
          jumpIndex={index}
          jump={item}
          setNextQuestionId={setNextQuestionId}
          addJump={addJump}
          removeJump={removeJump}
          currentNode={currentNode}
          isNonConditional={isNonConditional}
          updateQuestionNodes={updateQuestionNodes}
          nodeIndex={nodeIndex}
          addCoding={addCoding}
          removeMedicalCode={removeMedicalCode}
          editCoding={editCoding}
        />
      );
    });
  };

  return (
    <>
      {renderJumps()}
      <Box style={{ display: 'flex', justifyContent: 'center' }}>
        {!isNonConditional ? (
          <Tooltip title="Add jump">
            <IconButton onClick={addJump}>
              <AddCircleOutlineIcon />
            </IconButton>
          </Tooltip>
        ) : null}
        {currentNode.question.type === 'multiple' && currentNode.question.multiSelect && (
          <Tooltip title="Add More Than jump">
            <IconButton onClick={addMoreThanJump}>
              <AddMoreThanJumpIcon />
            </IconButton>
          </Tooltip>
        )}
      </Box>
      {editDefaultJumpCoding && (
        <MedicalCode
          coding={currentNode.defaultRoute ? currentNode.defaultRoute.coding : {}}
          removeMedicalCode={() => {
            removeMedicalCode(-1);
            setEditDefaultJumpCoding(false);
          }}
          editCoding={() => editCoding(-1)}
        />
      )}
      {createInputHelper(
        defaultQuestionId(transform.nodes(nodes), currentNode.rules.length),
        {
          defaultNextQuestionId: currentNode.defaultRoute ? currentNode.defaultRoute.defaultNextQuestionId : null,
        },
        () => {},
        (name, value) => setDefaultQuestionId(name, value)
      )}
      <Grid item xs={12}>
        <Tooltip title="Add medical code to default jump">
          <IconButton
            onClick={() => {
              setEditDefaultJumpCoding(!editDefaultJumpCoding);
              addCoding(-1);
            }}
          >
            <MedicalCodes />
          </IconButton>
        </Tooltip>
      </Grid>
      {/* Links that can be rendered on the front end for alternate navigation */}
      {createInputHelper(
        nodeLinks(transform.otherNodes(nodes, currentNode)),
        { links: currentNode.links },
        () => {},
        (name, value) => setNodeLinks(name, value)
      )}

      <Grid style={{ marginTop: 30 }} container>
        <Grid item xs={6}>
          <Button variant="contained" color="primary" onClick={handleSaveJumps} disabled={disableSave}>
            Save
          </Button>
          <Button variant="outlined" color="primary" style={{ marginLeft: 15 }} onClick={handleCancelJumps}>
            Cancel
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

export default JumpList;
