// material-ui
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { selectOtherUser, selectOtherUserQR } from '../Users/selectors';
import { getUserQRCode } from '../Users/usersSlice';

function QRCode() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const otherUser = useSelector(selectOtherUser);
  const qrCode = useSelector(selectOtherUserQR);

  const { userId: userProfile } = useParams();

  useEffect(() => {
    if (!otherUser || otherUser !== userProfile) {
      dispatch(getUserQRCode(userProfile));
    }
  }, [otherUser, userProfile]);

  return (
    <Card className={classes.addPhotoIcon} id="qrCodeModal" variant="outlined">
      <CardMedia
        component="img"
        alt="user qr code"
        src={qrCode ? qrCode.src : ''}
        title="QR Code"
        height="150px"
        width="150px"
        style={{ objectFit: 'contain', marginTop: -8 }}
      />
    </Card>
  );
}

export default QRCode;

const useStyles = makeStyles({
  addPhotoIcon: {
    width: 150,
    '& svg': {
      visibility: 'hidden',
      position: 'absolute',
      bottom: '10px',
      right: '10px',
    },
    '&:hover': {
      '& svg': {
        visibility: 'visible',
      },
    },
  },
});
