import { createSlice } from '@reduxjs/toolkit';

import api from '../../api';
import { paths } from '../../constants';
import { showNotification } from '../Notifications/notificationSlice';

const initialState = {
  allTenants: null,
  allTenantsLoading: false,
  currentTenant: null,
  isLoggedIn: false,
};

export const sharedSlice = createSlice({
  name: 'shared',
  initialState,
  reducers: {
    setAuthToken: (state, { payload }) => ({
      ...state,
      isLoggedIn: !!payload,
    }),
    setLoggedOut: (state) => ({
      ...state,
      isLoggedIn: false,
    }),
    setTenant: (state, { payload }) => ({
      ...state,
      currentTenant: payload,
    }),
    updateTenant: (state, { payload }) => ({
      ...state,
      currentTenant: {
        ...state.currentTenant,
        color: payload.color,
        logoURL: payload.logo,
        title: payload.name,
      },
    }),
    setTenants: (state, { payload }) => ({
      ...state,
      allTenants: payload,
      allTenantsLoading: false,
    }),
    setTenantsLoading: (state, { payload }) => ({
      ...state,
      allTenantsLoading: payload,
    }),
  },
});

export const { setAuthToken, setLoggedOut, setTenant, updateTenant, setTenants, setTenantsLoading } =
  sharedSlice.actions;

export const logout = (navigate) => async (dispatch) => {
  dispatch(setLoggedOut());
  localStorage.clear();
  if (navigate) navigate(paths.signIn());
};

export const setCurrentTenant = (tenant) => async (dispatch) => {
  localStorage.setItem('tenantId', tenant?.id || '');

  dispatch(setTenant(tenant));
};

export const updateCurrentTenant = (color, logo, name) => async (dispatch) => {
  dispatch(updateTenant({ color, logo, name }));
};

export const getMyTenants = () => async (dispatch) => {
  dispatch(setTenantsLoading(true));

  const [result] = await api.getMyTenantsRequest();
  if (!result?.total) {
    dispatch(setTenantsLoading(false));
    dispatch(showNotification('Sorry! You do not have permissions to view this page.', 'error'));
    return false;
  }

  dispatch(setTenants(result));
  dispatch(setTenantsLoading(false));
  return true;
};

export default sharedSlice.reducer;
