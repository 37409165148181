import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import React from 'react';

import styles from '../../../assets/Questionnaire.module.css';

function TestPanel(props) {
  const { testPanel, panelIdx, interactive, selectedTests, handleChange } = props;
  if (!testPanel || !selectedTests[panelIdx]) return null;
  return (
    <Grid container spacing={3}>
      {testPanel.orderableTestList.map((test) => (
        <Grid key={test.labTestOrderableId} item xs={12} lg={6}>
          <Button
            variant="outlined"
            onClick={() => handleChange(panelIdx, test.labTestOrderableId)}
            disabled={!interactive}
            endIcon={
              selectedTests[panelIdx].tests && selectedTests[panelIdx].tests[test.labTestOrderableId] ? (
                <CheckCircleOutlineIcon style={{ fontSize: '32px', color: 'rgb(16, 6, 159)' }} />
              ) : (
                <RadioButtonUncheckedIcon style={{ fontSize: '32px', color: 'lightgray' }} />
              )
            }
            className={
              selectedTests[panelIdx].tests && selectedTests[panelIdx].tests[test.labTestOrderableId]
                ? styles.selectedTest
                : styles.unselectedTest
            }
          >
            {test.longName}
          </Button>
        </Grid>
      ))}
    </Grid>
  );
}

export default TestPanel;
