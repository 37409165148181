import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { MaskedTextBox, Input } from '@progress/kendo-react-inputs';
import { Error } from '@progress/kendo-react-labels';
import React, { useState, useEffect, useRef } from 'react';

import utils from '../../../../Shared/Utils/utils';
import styles from '../../assets/Questionnaire.module.css';
import { validationRegex, fieldMask } from '../helpers/validationHelper';

function TextFieldInput(props) {
  const { field, value: initialValue, handleChangeWithParams, disabled, selectedLanguage } = props;
  const [currentValue, setCurrentValue] = useState(initialValue || '');
  const [valid, setValid] = useState(true);
  const [scriptExecutionError, setScriptExecutionError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [sensitiveVisible, setSensitiveVisible] = useState(false);
  const masktextfield = useRef(null);

  const fieldDisabled = disabled || field.readOnly || field.disableIfFalse;

  // disable autocomplete through reference
  useEffect(() => {
    if (masktextfield && masktextfield.current) {
      const input = masktextfield.current.element;
      input.autocomplete = 'new-password';
    }
  }, [masktextfield]);

  // Initial load value checks
  useEffect(() => {
    if (initialValue !== currentValue) {
      setCurrentValue(initialValue || '');
    }
    if (!initialValue && field.required) {
      setValid(false);
    }
    if (initialValue && field.validationType) {
      checkInputValidity(initialValue);
    }
    if (field.complexity) {
      const validPassword = checkComplexityValidity(initialValue);
      setValid(validPassword);
    }
    // eslint-disable-next-line
  }, [initialValue]);

  // Construct password error message consisting of specific complexity requirements
  useEffect(() => {
    if (field.complexity) {
      let constructedMessage = 'Password must contain ';
      const conditions = [];
      const minLength = field.complexity.replace(/\D/g, '');
      if (minLength) {
        conditions.push(`at least ${minLength} characters`);
      }
      if (field.complexity.includes('A-Z')) {
        conditions.push('at least one capital letter');
      }
      if (field.complexity.includes('d')) {
        conditions.push('at least one number');
      }
      if (field.complexity.includes('W')) {
        conditions.push('at least one special character');
      }

      conditions.forEach((condition, idx) => {
        if (conditions.length === 1) {
          constructedMessage += condition;
        } else if (idx === conditions.length - 1) {
          constructedMessage += `and ${condition}`;
        } else {
          constructedMessage += `${condition}${conditions.length > 2 ? ',' : ''} `;
        }
      });
      setErrorMessage(constructedMessage);
    }
  }, [field.complexity]);

  // Set error message and mask based on validation type.
  // Clear value on validation type change for editor preview.
  useEffect(() => {
    if (field.validationType && field.validationType === 'None') {
      setErrorMessage('');
    } else if (field.validationType) {
      setCurrentValue('');
      const customizedError =
        field.validationType === 'Other' ? 'Invalid input' : `Please enter a valid ${field.validationType}`;
      setErrorMessage(customizedError);
    }
  }, [field.validationType]);

  const onChange = (e) => {
    setScriptExecutionError(false);
    let { value } = e.target;
    let validInput = true;
    if (field.type === 'zip' || (field.validationType && field.validationType !== 'None')) {
      validInput = checkInputValidity(value);
      if (value === '(___) ___-____') {
        value = '';
        setValid(true);
      }
    } else if (field.complexity) {
      // Check if entry matches password complexity requirements
      validInput = checkComplexityValidity(value);
    }
    setValid(validInput);
    setCurrentValue(value);
    if (validInput) {
      handleChangeWithParams(field.name, value);
    } else {
      handleChangeWithParams(field.name, '');
    }
  };

  // check regex validity to show error text and enable/disable submit
  const checkInputValidity = (value) => {
    let checkRegEx;
    let validation;
    if (field.type === 'zip') {
      validation = field.type;
      checkRegEx = validationRegex.zip;
    } else {
      validation = field.validationType.toLowerCase();
      checkRegEx = validationRegex[validation] || new RegExp(field.customValidation);
    }
    const formattedValue = validation === 'phone' || validation === 'zip' ? value.replace(/[^A-Z0-9]+/gi, '') : value;
    return checkRegEx.test(formattedValue);
  };

  // If password with minimum complexity requirements, check validity
  const checkComplexityValidity = (value) => {
    const complexityRe = new RegExp(field.complexity);
    return complexityRe.test(value);
  };

  const handleScriptExecute = () => {
    setScriptExecutionError(false);
    const scriptExecutionResult = utils.findScript(currentValue);
    if (!scriptExecutionResult) {
      setScriptExecutionError(true);
    }
  };

  const fieldTitle =
    selectedLanguage && field.name && field.name.translations
      ? field.name.translations[selectedLanguage]
      : field.label || field.name;

  const questionDescription =
    selectedLanguage && field.description && field.description.translations
      ? field.description.translations[selectedLanguage]
      : field.description;

  if (!field.name) return <div />;
  const getFieldStyle = (field) => {
    if (field.nameStyle) return styles[`title--${field.nameStyle}`];
    if (field.questionComment) return styles.commentStyles;
    return styles['title--style1'];
  };
  if (field.rows || field.type === 'large-text') {
    return (
      <>
        <Grid item xs={12} style={{ width: '100%' }}>
          {fieldTitle && (
            <Typography className={() => getFieldStyle(field)}>
              {field.questionPreview ? utils.renderHTMLTags(fieldTitle) : fieldTitle}
              {field.required && '*'}
            </Typography>
          )}
          {field.image && (
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <img
                src={field.image}
                alt={fieldTitle}
                style={{
                  display: 'block',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  width: '90%',
                }}
              />
            </Grid>
          )}
          {questionDescription && (
            <Typography
              className={
                field.descriptionStyle
                  ? styles[`description--${field.descriptionStyle}`]
                  : styles['description--style1']
              }
            >
              {utils.renderHTMLTags(questionDescription)}
            </Typography>
          )}
        </Grid>
        <textarea
          rows={field.rows}
          className="k-textarea"
          onChange={onChange}
          required={field.required}
          label={field.label}
          name={field.name}
          autoComplete="off"
          style={{ maxWidth: '100%', minWidth: '100%' }}
          value={currentValue}
          disabled={fieldDisabled}
        />
        {field.checkScriptExecution && (
          <>
            <Button
              style={{ marginTop: 20 }}
              variant="contained"
              color="primary"
              onClick={handleScriptExecute}
              disabled={false}
            >
              Check script execution
            </Button>
            {scriptExecutionError && (
              <Typography style={{ padding: '10px 0', color: 'red' }}>
                Script execution error. Fix our script, please!
              </Typography>
            )}
          </>
        )}
      </>
    );
  }

  return (
    <Grid container style={{ position: 'relative' }}>
      {!field.isFieldForm && (
        <Grid item xs={12} style={{ width: '100%' }}>
          {fieldTitle && (
            <Typography className={() => getFieldStyle(field)}>
              {field.questionPreview ? utils.renderHTMLTags(fieldTitle) : fieldTitle}
              {field.required && '*'}
            </Typography>
          )}
          {field.image && (
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <img
                src={field.image}
                alt={fieldTitle}
                style={{
                  display: 'block',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  width: '90%',
                }}
              />
            </Grid>
          )}

          {questionDescription && (
            <Typography className={styles.descriptionStyles}>{utils.renderHTMLTags(questionDescription)}</Typography>
          )}
        </Grid>
      )}
      <Grid item xs={12}>
        {fieldMask[field.validationType?.toLowerCase()] || field.type === 'zip' ? (
          <MaskedTextBox
            mask={field.type === 'zip' ? fieldMask.zip : fieldMask[field.validationType.toLowerCase()]}
            value={currentValue}
            onChange={onChange}
            required={field.required && !currentValue}
            label={
              selectedLanguage && field.name && field.name.translations
                ? field.name.translations[selectedLanguage]
                : field.label || field.name
            }
            name={
              selectedLanguage && field.name && field.name.translations
                ? field.name.translations[selectedLanguage]
                : field.label || field.name
            }
            width="100%"
            pattern={field.pattern}
            disabled={fieldDisabled}
            valid={valid}
            ref={masktextfield}
          />
        ) : (
          <Input
            width="100%"
            value={currentValue}
            onChange={onChange}
            required={(!initialValue || !currentValue) && field.required}
            label={
              selectedLanguage && field.name && field.name.translations
                ? field.name.translations[selectedLanguage]
                : field.label || field.name
            }
            name={
              selectedLanguage && field.name && field.name.translations
                ? field.name.translations[selectedLanguage]
                : field.name
            }
            pattern={field.pattern}
            style={{ width: '100%' }}
            disabled={fieldDisabled}
            maxLength={field.maxLength}
            autoComplete="new-password"
            // eslint-disable-next-line no-nested-ternary
            type={field.sensitive ? (sensitiveVisible ? 'text' : 'password') : 'text'}
          />
        )}
      </Grid>
      {field.sensitive && (
        <Grid item className={styles.buttonVisibleWrapper}>
          <Tooltip title={sensitiveVisible ? 'Hide value' : 'Show value'}>
            <IconButton className={styles.buttonVisible} onClick={() => setSensitiveVisible(!sensitiveVisible)}>
              {sensitiveVisible ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
            </IconButton>
          </Tooltip>
        </Grid>
      )}
      {!valid && field.validationType && <Error id={`invalid${field.validationType}`}>{errorMessage}</Error>}
      {!valid && field.complexity && <Error id="invalidPassword">{errorMessage}</Error>}
    </Grid>
  );
}

export default TextFieldInput;
