/* eslint-disable react/no-array-index-key */
/* eslint-disable import/no-cycle */
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import DragNDropWrapper from '../../DragNDropWrapper';
import { selectFormData } from '../selectors';
import RemovableGroupedItems from './RemovableGroupedItems';

// ui reflection of file view
function DynamicListField(props) {
  const { field, initialValue, handleChangeWithParams, disabled, getChildren } = props;
  const formData = useSelector(selectFormData);

  const defaults = field.fields.reduce(
    (accum, f) => {
      if (f.default !== null && f.default !== undefined) {
        accum[f.name] = f.default;
      }
      return accum;
    },
    { name: '' }
  );

  const [currentValue, setCurrentValue] = useState(initialValue || []);
  const [previousValue, setPreviousValue] = useState(currentValue);
  const [openedItem, setOpenedItem] = useState(-1);

  const fieldDisabled = disabled || field.readOnly || (field.disableIfFalse && !formData[field.disableIfFalse]);

  useEffect(() => {
    if (initialValue !== currentValue) {
      setCurrentValue(initialValue || []);
    }
    // eslint-disable-next-line
  }, [initialValue]);

  const onChange = (name, value, index, writeToParent) => {
    const previousId = previousValue[index]?.id;
    const newList = JSON.parse(JSON.stringify(currentValue)); // currentValue.slice();
    newList[index][name] = value;
    if (name === 'id' && previousId !== value && newList[index].value) {
      newList[index].value = undefined;
    }
    setCurrentValue(newList);
    if (handleChangeWithParams && writeToParent) {
      handleChangeWithParams(name, value);
    } else if (handleChangeWithParams) {
      handleChangeWithParams(field.name, newList);
    }
    setPreviousValue(newList);
  };

  const onReordered = useCallback(
    (newList) => {
      setCurrentValue(newList);
      if (field.expandable) {
        setOpenedItem(-1);
      }
      if (handleChangeWithParams) handleChangeWithParams(field.name, newList);
    },
    [field, handleChangeWithParams]
  );

  // remove option from array
  const handleRemoveOption = (index) => {
    const newArray = Object.assign([], currentValue);
    if (index > -1) {
      newArray.splice(index, 1);
    }

    setCurrentValue(newArray);
    if (field.expandable && openedItem === index) setOpenedItem(-1);
    if (handleChangeWithParams) handleChangeWithParams(field.name, newArray);
  };

  const addNewItem = () => {
    const newItems = currentValue.slice();
    newItems.push({
      ...defaults,
      timestamp: Date.now,
    });
    setCurrentValue(newItems);
    if (field.expandable) {
      setOpenedItem(newItems.length - 1);
    }
    if (handleChangeWithParams) handleChangeWithParams(field.name, newItems);
  };

  const handleDynamicAutoset = (e, index) => {
    const name = Object.keys(e)[0];
    const value = e[name];
    onChange(name, value, index);
  };

  const handleExpansionChange = useCallback(
    (idx) => {
      if (openedItem === idx) {
        setOpenedItem(-1);
      } else {
        setOpenedItem(idx);
      }
    },
    [openedItem]
  );

  const renderGroup = (item, index) => {
    if (field.expandable) {
      return (
        <Accordion
          elevation={0}
          expanded={openedItem === index}
          onChange={() => handleExpansionChange(index)}
          style={{ background: '#ecebeb' }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${field.name}-${index}bh-content`}
            id={`panel${field.name}-${index}bh-header`}
            data-testid="AccordionSummary"
          >
            <Grid container spacing={1} alignItems="center">
              {!field.hideButton && !fieldDisabled && (
                <Grid item>
                  <IconButton
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleRemoveOption(index);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              )}
              <Grid item>
                <Typography
                  variant="h6"
                  color="primary"
                  style={{
                    overflowWrap: 'break-word',
                    wordWrap: 'break-word',
                    hyphens: 'auto',
                  }}
                >
                  {`${index + 1}. ${item[field.titleField] || ''}`}
                </Typography>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails data-testid="AccordionDetails">
            <Grid container spacing={2}>
              <Grid item style={{ width: '100%' }}>
                <RemovableGroupedItems
                  index={index}
                  label={field.label}
                  fields={field.fieldset ? getChildren(field, item, initialValue) : field.fields}
                  values={item}
                  handleChange={(e) => onChange(e.target.name, e.target.value, index)}
                  handleChangeWithParams={(name, value) => onChange(name, value, index)}
                  handleRemove={handleRemoveOption}
                  autoSetValues={(values) => handleDynamicAutoset(values, index)}
                  disabled={disabled || fieldDisabled || field.disabled}
                  parentFieldName={field.name}
                  disabledBtn={field.buttonDisabled}
                  getChildren={getChildren}
                  hideAction
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      );
    }
    return (
      <RemovableGroupedItems
        key={`removableGroup_${index}_${item.name}`}
        index={index}
        label={field.label}
        fields={field.fieldset ? getChildren(field, item, initialValue) : field.fields}
        values={item}
        handleChange={(e) => onChange(e.target.name, e.target.value, index)}
        handleChangeWithParams={(name, value, writeToParent) => onChange(name, value, index, writeToParent)}
        handleRemove={handleRemoveOption}
        autoSetValues={(e) => handleDynamicAutoset(e, index)}
        disabled={fieldDisabled || field.disabled}
        parentFieldName={field.name}
        disabledBtn={field.buttonDisabled}
        getChildren={getChildren}
      />
    );
  };

  return (
    <>
      <Grid container spacing={2}>
        {currentValue.length > 1 && field.sortable && !fieldDisabled ? (
          <DragNDropWrapper data={currentValue} renderItem={renderGroup} onOrderChanged={onReordered} />
        ) : (
          currentValue.map((item, index) => (
            <Grid item xs={12} key={`removable_group_${index}`}>
              {renderGroup(item, index)}
            </Grid>
          ))
        )}
      </Grid>

      {!field.hideButton
        ? !fieldDisabled &&
          (field.buttonType === 'text' || field.label ? (
            <Grid item container justifyContent="flex-end">
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={addNewItem}
                  disabled={field.disabled || field.buttonDisabled}
                  style={{ marginTop: 10 }}
                >
                  {`Add ${field.label}`}
                </Button>
              </Grid>
            </Grid>
          ) : (
            <Tooltip title={field.tooltip}>
              <IconButton onClick={addNewItem} disabled={field.disabled || field.buttonDisabled}>
                <AddIcon />
              </IconButton>
            </Tooltip>
          ))
        : ''}
    </>
  );
}

export default DynamicListField;
