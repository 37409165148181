export const rxOrderStatusEnum = [
  'pick-up-created',
  'pick-up-no-rx',
  'delivery-created',
  'delivery-confirmed',
  'delivery-enroute-to-pick-up',
  'delivery-arrived-at-pick-up',
  'delivery-picked-up',
  'delivery-enroute-to-dropoff',
  'delivery-arrived-at-dropoff',
  'delivery-delivered',
  'delivery-enroute-to-return',
  'delivery-arrived-at-return',
  'delivery-returned',
  'delivery-cancelled',
  'delivery-no-rx',
];

export const rxOrderStatusDropdownField = {
  type: 'dropdown',
  name: 'rxOrder',
  label: 'Status',
  enum: rxOrderStatusEnum,
};

export const rxOrderIdField = {
  type: 'text',
  name: 'rxOrderId',
  label: 'Rx Order ID (Optional)',
  readOnly: false,
};
export const pharmacyNameField = {
  type: 'text',
  name: 'pharmaName',
  label: 'Pharmacy Name',
  readOnly: false,
};
export const phoneField = {
  type: 'text',
  name: 'phone',
  label: 'Phone Number',
  readOnly: false,
};
export const line1Field = {
  type: 'text',
  name: 'line1',
  label: 'Address line 1',
  readOnly: false,
};
export const cityField = {
  type: 'text',
  name: 'city',
  label: 'City',
  readOnly: false,
};
export const stateField = {
  type: 'text',
  name: 'stateAddress',
  label: 'State',
  readOnly: false,
};
export const zipField = {
  type: 'text',
  name: 'zip',
  label: 'ZIP Code',
  readOnly: false,
};
export const countryField = {
  type: 'text',
  name: 'country',
  label: 'Country',
  readOnly: false,
};

export const preferredField = {
  type: 'toggle',
  name: 'preferred',
  label: 'Preferred',
  required: false,
}
