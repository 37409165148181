import Grid from '@material-ui/core/Grid';
import React from 'react';

import Description from './Description';
import Image from './Image';
import Name from './Name';

function StandardInfo(props) {
  const { field, selectedLanguage, centerName } = props;

  return (
    <Grid item xs={12} style={{ width: '100%' }}>
      <Name
        fieldName={field.name}
        fieldLabel={field.label}
        nameStyle={field.nameStyle}
        required={field.required}
        questionPreview={field.questionPreview}
        selectedLanguage={selectedLanguage}
        centerName={centerName}
      />
      <Image image={field.image} />
      <Description
        description={field.description}
        descriptionStyle={field.descriptionStyle}
        selectedLanguage={selectedLanguage}
      />
    </Grid>
  );
}

export default StandardInfo;
