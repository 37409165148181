import { Button as MuiButton, Grid as MuiGrid, styled, Typography as MuiTypography } from '@material-ui/core';
import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserFromToken } from '../Auth/helpers';
import { selectAccountId } from '../Auth/selectors';
import { setAuthToken } from '../Shared/sharedSlice';
import { sendMfaCode } from './adminSlice';
import { VerificationCode } from './VerificationCode';

const Typography = styled(MuiTypography)(({ theme }) => ({
  '&.dash': {
    fontSize: '30px',
    margin: '10px',
    marginTop: '20px',
  },
  '&.login-again > b': {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  '&.error-message': {
    textAlign: 'center',
    paddingTop: '5px',
    color: '#ea0f00',
  },
}));

const Grid = styled(MuiGrid)({
  '&.root-container': {
    marginTop: '40px',
  },
  '&.button-container': {
    marginTop: '30px',
  },
});

const Button = styled(MuiButton)({
  textTransform: 'none',
  padding: 8,
  borderRadius: 25,
});

export function EnterCodeForm(props) {
  const { sessionId, userName, onReset, mfaMethod } = props;

  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState('');
  const [codeError, setCodeError] = useState('');

  const accountId = useSelector(selectAccountId);
  const continueRef = useRef();
  const dispatch = useDispatch();

  const handleContinue = async () => {
    setLoading(true);

    const result = await dispatch(sendMfaCode(mfaMethod, code, sessionId, userName, accountId));

    setLoading(false);
    if (!result.isOK) {
      const errorMessage =
        result?.message && result.message.toLowerCase().includes('invalid code')
          ? 'The verification code you entered was not valid. Please try again.'
          : result?.message;
      setCodeError(errorMessage || 'Incorrect code');
      return;
    }

    // authentication
    if (result.isOK && result.data) {
      const { mfaResponse } = result.data;
      const { authenticationResult } = mfaResponse;
      const { refreshToken, idToken } = authenticationResult;

      localStorage.setItem('id_token', idToken);
      localStorage.setItem('refresh_token', refreshToken);

      const user = getUserFromToken();
      localStorage.setItem('user_id', user.email);
      dispatch(setAuthToken(idToken));
    }

    setLoading(false);
  };

  const handleLoginAgain = () => {
    if (onReset) {
      onReset();
    }
  };

  const handleResetError = () => {
    setCodeError('');
  };

  const handleSubmitCode = (code) => {
    setCode(code);
    continueRef.current.focus();
  };

  return (
    <Grid container className="root-container">
      <VerificationCode
        onSubmit={handleSubmitCode}
        resetError={handleResetError}
        isSubmitting={loading}
        checkErrors
        codeLength={6}
        divideEvery={3}
      />

      {codeError && (
        <Grid item container justifyContent="center" direction="row">
          <Typography className="error-message" variant="body1">
            {codeError}
          </Typography>
        </Grid>
      )}

      <Grid item container justifyContent="center" direction="row" className="button-container">
        <Typography className="login-again">
          Didn&apos;t receive code?
          {/* eslint-disable-next-line */}
          <b onClick={handleLoginAgain}>&nbsp;Try logging in again.</b>
        </Typography>
      </Grid>

      <Grid item xs={12} container justifyContent="center" className="button-container">
        <Button
          ref={continueRef}
          onClick={handleContinue}
          disabled={loading || code.length !== 6}
          variant="contained"
          color="primary"
          fullWidth
        >
          Continue
        </Button>
      </Grid>
    </Grid>
  );
}
