import { Grid, Paper } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React from 'react';

export function FormSkeletonWrapper({ children }) {
  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  if (matches) {
    return (
      <Grid container justify={'center'}>
        <Grid item md={12}>
          <Paper elevation={0} style={{ borderRadius: 30 }}>
            {children}
          </Paper>
        </Grid>
      </Grid>
    );
  }
  return (
    <Grid container justify={'center'}>
      <Grid item xs={12}>
        <Paper elevation={0} style={{ borderRadius: 30 }}>
          {children}
        </Paper>
      </Grid>
    </Grid>
  );
}
