export const statusMapping = {
  DISABLED: {
    label: 'Disabled',
    background: 'gray',
    color: 'white',
  },
  ACTIVE: {
    label: 'Active',
    background: 'green',
    color: 'white',
  },
};

export const addedToQuestionnaire = {
  true: {
    label: 'Added',
    background: 'gray',
    color: 'white',
  },
  false: {
    label: 'Not Added',
    background: 'green',
    color: 'white',
  },
};
