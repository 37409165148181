import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';

import SettingsTemplatesStyles from '../../../assets/jss/components/SettingsTemplatesStyles';

function SettingsTemplateVariableHelper(props) {
  const { classes, variables } = props;

  const handleCopyKey = (key) => {
    navigator.clipboard
      .writeText(key)
      .then(() => {
        /* eslint-disable-next-line no-console */
        console.log('Copied');
      })
      .catch((err) => {
        /* eslint-disable-next-line no-console */
        console.log('Something went wrong', err);
      });
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <List>
          {variables &&
            variables.map((v) => (
              <ListItem>
                <ListItemText
                  primary={
                    <Typography variant="body1" className={classes.variableKey} onClick={() => handleCopyKey(v.key)}>
                      {v.key}
                    </Typography>
                  }
                  secondary={v.description}
                />
              </ListItem>
            ))}
        </List>
      </Grid>
    </Grid>
  );
}

export default withStyles(SettingsTemplatesStyles)(SettingsTemplateVariableHelper);
