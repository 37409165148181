import { Box, Button, CircularProgress, Divider, Typography } from "@material-ui/core";
import { styled } from '@material-ui/core/styles';
import React from "react";
import TextFieldInput from "../../../Shared/Forms/KendoFields/TextFieldInput";
import { memberIDField, groupIDField, rxBINField, pCNField, firstNameField, lastNameField, phoneNumber1Field, dateOfBirthField, addressLine1Field, cityField, stateField, postalcodeField, countryField, genderField, last4SSNField, statusField, tokenIdField, defaultDemographicInfo } from "./helpers";
import NumberFieldInput from "../../../Shared/Forms/KendoFields/NumberFieldInput";
import DateInput from "../../../Shared/Forms/KendoFields/DateInput";
import ToggleInput from "../../../Shared/Forms/KendoFields/ToggleField";

/* UNCONTROLLED FORM */
let DependentForm = ({
  hasData,
  dependentData,
  onDependentChange,
  fvSubsLoading,
  onUpdateDependent,
  dependentIdx
}) => {


  const {
    token: { cardTokenID },
    status = null,
    demographicInfo: {
      name: { firstName = "", lastName = "" },
      phone: { phoneNumber1 = "" },
      dateOfBirth = "",
      gender = "",
      last4SSN = 0,

      address: {
        addressLine1 = "",
        city = "",
        state = "",
        postalcode = "",
        country = ""
      }
    } = {  //default values for demographic info
      ...defaultDemographicInfo
    },
  } = dependentData


  /* CONVERT Birth Date to Date Object */
  const dobArrSubStr = dateOfBirth.split('');
  const MM = dobArrSubStr.splice(0,2).join('');
  const DD = dobArrSubStr.splice(0,2).join('');
  const YYYY = dobArrSubStr.splice(0,4).join('');
  const dobInputVal = new Date(`${MM}-${DD}-${YYYY}`);

  const handleTextInputChange = (e) => {
    onDependentChange(e.target.name, e.target.value, dependentIdx)
  }

  const handleDateChange = (fieldName, fieldVal) => {
    onDependentChange(fieldName, fieldVal, dependentIdx)
  }

  const handleToggleChange = (fieldName, fieldVal) => {
    onDependentChange(fieldName, fieldVal, dependentIdx)
  }

  // fix: SAF-8760 set the status to Active if string is "Active".
  //  If "Inactive" then set it to false
  const isActive = status === 'Active' ? true : false;

  return (
    <Box>
      <Box display={'flex'} alignItems={'center'} marginTop={'10px'} marginBottom={'20px'}>
        <Box marginRight={'20px'}>
          <ToggleInput
            field={statusField}
            value={isActive}
            handleChangeWithParams={handleToggleChange}
          />
        </Box>
        <Box display={'inline-block'} >
          <Typography variant="subtitle2">
            Use the toggle to set the Dependent Account to Active/Inactive
          </Typography>
        </Box>
      </Box>
      <Divider />
      <FormWrapper>
        <BasicDetailsC>
          <Box gridColumn={'1 / span 2'} style={{textDecoration: 'underline'}}>
            <Typography>
              Personal Info
            </Typography>
            <Divider />
          </Box>
          <TextFieldInput
            field={firstNameField}
            value={firstName}
            handleChange={handleTextInputChange}
          />
          <TextFieldInput
            field={lastNameField}
            value={lastName}
            handleChange={handleTextInputChange}
          />
          <TextFieldInput
            field={genderField}
            value={gender}
            handleChange={handleTextInputChange}
          />
          <TextFieldInput
            field={last4SSNField}
            value={last4SSN}
            handleChange={handleTextInputChange}
          />
          <TextFieldInput
            field={phoneNumber1Field}
            value={phoneNumber1}
            handleChange={handleTextInputChange}
          />
          <DateInput
            field={dateOfBirthField}
            value={dobInputVal}
            handleChangeWithParams={handleDateChange}
          />
        </BasicDetailsC>
        <AddressInfoC>
          <Box gridColumn={'1 / span 2'} style={{textDecoration: 'underline'}}>
            <Typography>
              Address Info
            </Typography>
            <Divider />
          </Box>
          <TextFieldInput
            field={addressLine1Field}
            value={addressLine1}
            handleChange={handleTextInputChange}
          />
          <TextFieldInput
            field={cityField}
            value={city}
            handleChange={handleTextInputChange}
          />
          <TextFieldInput
            field={stateField}
            value={state}
            handleChange={handleTextInputChange}
          />
          <NumberFieldInput
            field={postalcodeField}
            value={postalcode}
            handleChange={handleTextInputChange}
          />
          <TextFieldInput
            field={countryField}
            value={country}
            handleChange={handleTextInputChange}
          />
        </AddressInfoC>
        <ActionC>
          <Button variant="contained" color="primary" onClick={() => onUpdateDependent(dependentIdx)} disabled={fvSubsLoading === 'pending'}>
            <Box display={'flex'} alignItems={'center'}>
              <Box display={'inline-block'}>Update Dependent {dependentIdx + 1}</Box>
              {
                fvSubsLoading === 'pending' && <Box display={'inline-block'} marginLeft={'8px'}>
                  <CircularProgress size={20} color="primary"/>
                </Box>
              }
            </Box>
          </Button>
        </ActionC>
      </FormWrapper>
    </Box>
  )
};


const FormWrapper = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr',
  paddingBlock: '5px',
  rowGap: '20px',
})

const BasicDetailsC = styled('div')({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
  rowGap: '16px',
  columnGap: '100px',
  marginTop: '20px',
  'container': 'basic-info / inline-size',
  paddingLeft: '5px',
  alignItems: 'center',
})

const AddressInfoC = styled('div')({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
  rowGap: '16px',
  columnGap: '100px',
  marginTop: '20px',
  'container': 'basic-info / inline-size',
  paddingLeft: '5px',
})

const ActionC = styled('div')({
  marginTop: '20px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

/* Optimize Component for when changes is not for this Dependent */
DependentForm = React.memo(DependentForm, ((prev, next) => {
  const prevStr = JSON.stringify(prev);
  const nextStr = JSON.stringify(next);
  if (prevStr !== nextStr) {
    return false;
  }

  return true;
}))

export default DependentForm;
