import React, { useEffect, useCallback, useMemo, useState } from 'react';

// redux
import { useDispatch, useSelector } from 'react-redux';

// components and helpers
import { selectTenantNetwork } from '../Networks/selectors';
import * as htmlUtils from '../Shared/Grid/helpers';
import { selectCurrentTenant } from '../Shared/selectors';
import CheckBoxCell from '../Shared/Table/CheckBoxCell';
import AppKendoTableWrapper from '../Shared/Table/KendoTableWrapper';

import { transform, columns, initialSorting } from './helpers/medicalCentersListMapper';
import { getVendorsList } from './labTestsSlice';
import ManageListDialog from './ManageListDialog';
import { selectTenantLabTests } from './selectors';
import VendorsSelectionWrapper from './VendorsSelectionWrapper';

function VendorsListWrapper(props) {
  const { type, multiSelection, initialSelected, filter, handleSelectionChange, hideButtons, saveObject } = props;

  const currentTenant = useSelector(selectCurrentTenant);
  const labTests = useSelector(selectTenantLabTests);
  const tenantNetwork = useSelector(selectTenantNetwork);

  const [submitting, setSubmitting] = useState(false);
  const [list, setList] = useState([]);

  const [selected, setSelected] = useState(initialSelected || []);
  const [dialogOpen, setDialogOpen] = useState(false);

  const dispatch = useDispatch();

  const loading = labTests && labTests[type] && (labTests[type].loading || labTests[type].submitting);

  const buttons = useMemo(
    () => ({
      testOrderable: [
        {
          title: 'Manage Orderable Tests',
          buttonType: 'action',
          // action to perform when click action button
          action: () => setDialogOpen(true),
          variant: 'contained',
        },
      ],
      testPanels: [
        {
          title: 'Manage Orderable Test Panels',
          buttonType: 'action',
          // action to perform when click action button
          action: () => setDialogOpen(true),
          variant: 'contained',
          disabled: false,
        },
      ],
      vendors: [
        {
          title: 'Choose enabled vendors',
          buttonType: 'action',
          // action to perform when click action button
          action: () => setDialogOpen(true),
          variant: 'contained',
        },
      ],
    }),
    []
  );

  const handleOnChecked = useCallback(
    (item, checked) => {
      const newSelectedList = selected.slice();

      const indexInList = newSelectedList.findIndex((v) => (saveObject ? v._id === item._id : v === item._id));
      const isInTheList = indexInList >= 0;

      if (checked && !isInTheList) {
        newSelectedList.push(saveObject ? item : item._id);
      } else if (!checked && isInTheList) {
        newSelectedList.splice(indexInList, 1);
      }

      setSelected(newSelectedList);

      if (handleSelectionChange) {
        handleSelectionChange(newSelectedList);
      }
    },
    [selected, handleSelectionChange, saveObject]
  );

  const closeDialog = useCallback(() => {
    setDialogOpen(false);
  }, []);

  let cols = [];
  const columnList = columns();
  if (multiSelection) {
    cols = columnList[type].slice(null, columnList[type].length - 1);
    cols.splice(0, 0, {
      id: 'checked',
      label: 'Selected',
      show: true,
      minWidth: 100,
      sortable: false,
      cell: htmlUtils.withOnChange(CheckBoxCell, handleOnChecked),
    });
  } else {
    cols = columnList[type];
  }

  // Initial Load
  useEffect(() => {
    (async () => {
      if (!currentTenant) return;
      setSubmitting(true);
      const allVendors = await dispatch(getVendorsList(currentTenant, true));
      if (tenantNetwork && tenantNetwork.enabledLabVendorsList) {
        const selectedVendors = allVendors.filter((item) => {
          if (tenantNetwork) {
            return tenantNetwork.enabledLabVendorsList.some((v) => v.vendorId === item._id);
          }
          return false;
        });

        setList(selectedVendors);
        setSelected(selectedVendors.map((v) => v._id));
      }

      setSubmitting(false);
    })();
  }, [type, multiSelection, filter, currentTenant]);

  // empty placeholder data
  const data = useMemo(
    () => ({
      total: (list && list.foundItemsCount) || 0,
      pageSize: (list && list.pageSize) || 0,
      page: (list && list.pageNumber) || 0,
      items: (list && transform[type](list, false)) || [],
    }),
    [list, type, selected, saveObject]
  );

  const renderDialog = () => {
    if (dialogOpen) {
      return (
        <ManageListDialog closeDialog={() => closeDialog()}>
          <VendorsSelectionWrapper
            initialSelected={selected}
            type={type}
            // eslint-disable-next-line
            handleSelectionChange={(list) => console.log('New List', list)}
            onCancel={() => closeDialog()}
          />
        </ManageListDialog>
      );
    }
  };

  return (
    <>
      <AppKendoTableWrapper
        data={data}
        initialSort={initialSorting[type].field}
        initialDir={initialSorting[type].dir}
        showButtons={!hideButtons}
        showSearchbar={false}
        tableButtons={buttons[type]}
        columns={cols}
        loading={loading || submitting}
        disableAutoFocus={multiSelection === true ? true : undefined}
      />
      {renderDialog()}
    </>
  );
}

export default VendorsListWrapper;
