// component and styling imports

// material-ui
import Badge from '@material-ui/core/Badge';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Popover from '@material-ui/core/Popover';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import LensIcon from '@material-ui/icons/Lens';
import NotificationsIcon from '@material-ui/icons/Notifications';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import React, { Fragment } from 'react';
import Moment from 'react-moment';
import { Link, useNavigate } from 'react-router-dom';

import AlertsStyles from '../../assets/jss/components/AlertsStyles';
import ProgressBar from '../Shared/Progressbar';

function AlertMenu(props) {
  const { classes, alerts, currentTenant, handleReadAlerts, unreadAlert, imports } = props;
  const navigate = useNavigate();
  const handleBlur = (popupState) => {
    handleReadAlerts();
    popupState.close();
  };

  const isImportRead = (i) => i.status !== 'IN_PROGRESS' && i.read;

  const navigateToImport = (i, popupState) => {
    navigate(`/${currentTenant}/users/import/${i.id}`);
    popupState.close();
  };

  return (
    <PopupState variant="popover" popupId="notifications-menu">
      {(popupState) => (
        <div
          className={classes.notification}
          style={{
            paddingRight: '5px',
            borderRight: 'solid 1px',
            marginRight: '5px',
          }}
        >
          <IconButton color="inherit" {...bindTrigger(popupState)}>
            <Badge badgeContent={unreadAlert ? 1 : 0} variant="dot" classes={{ badge: classes.customBadge }}>
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            onBlur={() => handleBlur(popupState)}
            style={{ maxHeight: '75vh' }}
            disableScrollLock
          >
            <Box className={classes.box}>
              <List component="nav" dense>
                {(alerts && alerts.total > 0) || (imports && imports.length > 0) ? (
                  <>
                    {imports &&
                      imports.map((i) => {
                        const usersProcessingStatus = (i.nbOfUsersProcessed * 100) / i.nbOfUsersToProcess;
                        return (
                          <div key={i.id}>
                            <Grid container justifyContent="space-around" alignContent="center" alignItems="center">
                              <ListItem
                                button
                                onClick={() => navigateToImport(i, popupState)}
                                className={isImportRead(i) ? null : classes.unreadNotification}
                              >
                                <Grid item xs={1}>
                                  <ListItemIcon>
                                    {isImportRead(i) && <LensIcon className={classes.lensIcon} />}
                                  </ListItemIcon>
                                </Grid>
                                <Grid item xs={11}>
                                  <ListItemText
                                    disableTypography
                                    primary={
                                      <div>
                                        <Grid container justifyContent="space-between" alignItems="center">
                                          <Typography style={{ fontWeight: 'bold' }}>
                                            {i.status === 'COMPLETED_SUCCESSFULLY' ||
                                            i.status === 'COMPLETED_WITH_ERRORS'
                                              ? 'Completed'
                                              : 'Processing New Accounts'}
                                          </Typography>

                                          <Typography variant="caption">
                                            <span style={{ color: '#8e8e8f' }}>
                                              <Moment toNow ago>
                                                {i.started}
                                              </Moment>{' '}
                                              ago
                                            </span>
                                          </Typography>
                                        </Grid>
                                        <ProgressBar
                                          color="primary"
                                          variant="determinate"
                                          value={usersProcessingStatus}
                                          className={classes.nbOfUsersToProcessing}
                                          classes={{
                                            root: classes.progressStyles,
                                            barColorPrimary:
                                              usersProcessingStatus === 100 ? classes.barColorSuccess : '',
                                          }}
                                        />{' '}
                                        <Grid
                                          container
                                          justifyContent="space-between"
                                          alignContent="flex-end"
                                          style={{ marginTop: 8 }}
                                        >
                                          <Grid item>
                                            <Typography variant="h6" style={{ fontSize: 12 }}>
                                              <b>{`${i.nbOfUsersProcessed} users processed`}</b>
                                            </Typography>
                                          </Grid>
                                          <Grid item>
                                            <Typography variant="h6" style={{ fontSize: 12 }}>
                                              <b>{`${i.nbOfUsersToProcess - i.nbOfUsersProcessed} users remaining`}</b>
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    }
                                  />
                                </Grid>
                              </ListItem>
                            </Grid>
                          </div>
                        );
                      })}
                    {alerts &&
                      alerts.items.map((alert) => (
                        <div key={alert.id}>
                          <Grid container justifyContent="space-around" alignContent="center" alignItems="center">
                            <ListItem className={!alert.read ? classes.unreadNotification : null}>
                              <Grid item xs={1}>
                                <ListItemIcon>{!alert.read && <LensIcon className={classes.lensIcon} />}</ListItemIcon>
                              </Grid>
                              <Grid item xs={9}>
                                <ListItemText
                                  disableTypography
                                  primary={
                                    <div>
                                      <Typography variant="body2">{alert.userId}</Typography>
                                      <Typography variant="body2">{alert.message}</Typography>
                                    </div>
                                  }
                                />
                              </Grid>
                              <Grid item xs={2}>
                                <Typography variant="caption">
                                  <span>
                                    <Moment toNow ago>
                                      {alert.time}
                                    </Moment>{' '}
                                    ago
                                  </span>
                                </Typography>
                              </Grid>
                            </ListItem>
                          </Grid>
                          <Divider />
                        </div>
                      ))}
                    <Link to={`/${currentTenant}/alerts`}>
                      <Button style={{ float: 'right', padding: 10 }} color="primary">
                        View All Notifications
                      </Button>
                    </Link>
                  </>
                ) : (
                  <Grid container justifyContent="center" alignContent="center" alignItems="center">
                    <ListItem>
                      <ListItemText disableTypography primary={<Typography>No Notifications</Typography>} />
                    </ListItem>
                  </Grid>
                )}
              </List>
            </Box>
          </Popover>
        </div>
      )}
    </PopupState>
  );
}

export default withStyles(AlertsStyles)(AlertMenu);
