export const questionLoadJsonBuilder = (state, details, optionsList) => ({
  id: state.id || 'editorPreview',
  type: state.type,
  name: (state.showTranslation && state[`name-${state.workingLanguage}`]) || state.name,
  description: (state.showTranslation && state[`description-${state.workingLanguage}`]) || state.description,
  help: (state.showTranslation && state[`help-${state.workingLanguage}`]) || state.help,
  label: (state.showTranslation && state[`name-${state.workingLanguage}`]) || state.name,
  shortName: state.shortName,
  image: state.image,
  commentEnabled: state.commentEnabled,
  width: state.width,
  height: state.height,
  rows: state.type && state.type.includes('large') && 3,
  options:
    optionsList ||
    state.options?.map((option) => ({
      ...option,
      value: (state.showTranslation && option[`value-${state.workingLanguage}`]) || option.value,
      instructionText:
        (state.showTranslation && option[`instructionText-${state.workingLanguage}`]) || option.instructionText,
    })),
  fields: state.fields,
  precision: state.precision,
  nodeList: state.nodeList,
  subnodes: state.subnodes,
  interactive: state.interactive,
  testList: state.testList?.map((test) => ({
    ...test.test,
    autoSelectedOrderableTestList: test.autoSelectedOrderableTestList,
  })),

  min: state.minBeforeAfter ? state.min * state.minBeforeAfter : state.min,
  max: state.maxBeforeAfter ? state.max * state.maxBeforeAfter : state.max,
  complexity: state.complexity,
  validationType: state.validationType,
  customValidation: state.customValidation,
  step: state.step,
  status: state.status,
  tags: state.tags,
  multiSelect: state.multiSelect,
  containsNone: state.containsNone,
  containsAll: state.containsAll,
  selectAll: state.selectAll,
  system: details ? state.coding && state.coding.system : state.system,
  code: details ? state.coding && state.coding.code : state.code,
});
