import Grid from '@material-ui/core/Grid';
import React from 'react';
import ContentLoader from 'react-content-loader';

export function TableSkeletonLoading(props) {
  return (
    <Grid container>
      <Grid item xs={12}>
        <ContentLoader
          speed={0.8}
          // width={1500}
          // height={400}
          viewBox={props.desktop ? '0 0 1500 420' : '0 0 500 420'}
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
          {...props}
        >
          {/* mock searchbar and button at top */}
          {props.desktop && <rect x="1337" y="20" rx="0" ry="0" width="160" height="40" />}
          {props.desktop && <rect x="26" y="50" rx="5" ry="5" width="400" height="3" />}
          {!props.desktop && <rect x="26" y="60" rx="0" ry="0" width="160" height="40" />}
          {!props.desktop && <rect x="26" y="30" rx="5" ry="5" width="400" height="3" />}

          {/* first row */}
          {/* <rect x="27" y="140" rx="4" ry="4" width="20" height="20" /> */}
          <rect x="26" y="140" rx="10" ry="10" width="105" height="19" />
          <rect x="188" y="140" rx="10" ry="10" width="169" height="19" />
          <rect x="402" y="140" rx="10" ry="10" width="85" height="19" />
          <rect x="523" y="140" rx="10" ry="10" width="169" height="19" />
          <rect x="731" y="140" rx="10" ry="10" width="85" height="19" />
          <rect x="852" y="140" rx="10" ry="10" width="85" height="19" />
          <rect x="978" y="140" rx="10" ry="10" width="169" height="19" />
          <rect x="1183" y="140" rx="10" ry="10" width="85" height="19" />
          <rect x="1305" y="140" rx="10" ry="10" width="85" height="19" />
          <rect x="1424" y="140" rx="10" ry="10" width="68" height="19" />

          {/* second row */}
          {/* <rect x="26" y="180" rx="4" ry="4" width="20" height="20" /> */}
          <rect x="26" y="180" rx="10" ry="10" width="105" height="19" />
          <rect x="187" y="180" rx="10" ry="10" width="169" height="19" />
          <rect x="401" y="180" rx="10" ry="10" width="85" height="19" />
          <rect x="522" y="180" rx="10" ry="10" width="169" height="19" />
          <rect x="730" y="180" rx="10" ry="10" width="85" height="19" />
          <rect x="851" y="180" rx="10" ry="10" width="85" height="19" />
          <rect x="977" y="180" rx="10" ry="10" width="169" height="19" />
          <rect x="1182" y="180" rx="10" ry="10" width="85" height="19" />
          <rect x="1304" y="180" rx="10" ry="10" width="85" height="19" />
          <circle cx="1456" cy="190" r="12" />

          {/* third row */}
          {/* <rect x="26" y="220" rx="4" ry="4" width="20" height="20" /> */}
          <rect x="26" y="220" rx="10" ry="10" width="105" height="19" />
          <rect x="187" y="220" rx="10" ry="10" width="169" height="19" />
          <rect x="401" y="220" rx="10" ry="10" width="85" height="19" />
          <rect x="522" y="220" rx="10" ry="10" width="169" height="19" />
          <rect x="730" y="220" rx="10" ry="10" width="85" height="19" />
          <rect x="851" y="220" rx="10" ry="10" width="85" height="19" />
          <rect x="977" y="220" rx="10" ry="10" width="169" height="19" />
          <rect x="1182" y="220" rx="10" ry="10" width="85" height="19" />
          <rect x="1304" y="220" rx="10" ry="10" width="85" height="19" />
          <circle cx="1456" cy="230" r="12" />

          {/* fourth row */}
          {/* <rect x="26" y="260" rx="4" ry="4" width="20" height="20" /> */}
          <rect x="26" y="260" rx="10" ry="10" width="105" height="19" />
          <rect x="187" y="260" rx="10" ry="10" width="169" height="19" />
          <rect x="401" y="260" rx="10" ry="10" width="85" height="19" />
          <rect x="522" y="260" rx="10" ry="10" width="169" height="19" />
          <rect x="730" y="260" rx="10" ry="10" width="85" height="19" />
          <rect x="851" y="260" rx="10" ry="10" width="85" height="19" />
          <rect x="977" y="260" rx="10" ry="10" width="169" height="19" />
          <rect x="1182" y="260" rx="10" ry="10" width="85" height="19" />
          <rect x="1304" y="260" rx="10" ry="10" width="85" height="19" />
          <circle cx="1456" cy="270" r="12" />

          {/* fifth row */}
          {/* <rect x="26" y="300" rx="4" ry="4" width="20" height="20" /> */}
          <rect x="26" y="300" rx="10" ry="10" width="105" height="19" />
          <rect x="187" y="300" rx="10" ry="10" width="169" height="19" />
          <rect x="401" y="300" rx="10" ry="10" width="85" height="19" />
          <rect x="522" y="300" rx="10" ry="10" width="169" height="19" />
          <rect x="730" y="300" rx="10" ry="10" width="85" height="19" />
          <rect x="851" y="300" rx="10" ry="10" width="85" height="19" />
          <rect x="851" y="300" rx="10" ry="10" width="85" height="19" />
          <rect x="977" y="300" rx="10" ry="10" width="169" height="19" />
          <rect x="1182" y="300" rx="10" ry="10" width="85" height="19" />
          <rect x="1304" y="300" rx="10" ry="10" width="85" height="19" />
          <circle cx="1456" cy="310" r="12" />

          {/* mock pagination row */}
          <rect x="26" y="380" rx="4" ry="4" width="20" height="20" />
          <rect x="66" y="380" rx="4" ry="4" width="20" height="20" />
          <circle cx="120" cy="390" r="14" />
          <circle cx="160" cy="390" r="14" />
          <circle cx="200" cy="390" r="14" />
          <rect x="240" y="380" rx="4" ry="4" width="20" height="20" />
          <rect x="280" y="380" rx="4" ry="4" width="20" height="20" />
          <rect x="1400" y="380" rx="10" ry="10" width="85" height="19" />
        </ContentLoader>
      </Grid>
    </Grid>
  );
}

export function TabTableSkeletonLoading(props) {
  return (
    <Grid container>
      <Grid item xs={12}>
        <ContentLoader
          speed={0.8}
          // width={1500}
          // height={400}
          viewBox={props.desktop ? '0 0 1500 300' : '0 0 500 500'}
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
          {...props}
        >
          {/* first row */}
          {/* <rect x="27" y="20" rx="4" ry="4" width="20" height="20" /> */}
          <rect x="26" y="20" rx="10" ry="10" width="105" height="19" />
          <rect x="188" y="20" rx="10" ry="10" width="169" height="19" />
          <rect x="402" y="20" rx="10" ry="10" width="85" height="19" />
          <rect x="523" y="20" rx="10" ry="10" width="169" height="19" />
          <rect x="731" y="20" rx="10" ry="10" width="85" height="19" />
          <rect x="852" y="20" rx="10" ry="10" width="85" height="19" />
          <rect x="978" y="20" rx="10" ry="10" width="169" height="19" />
          <rect x="1183" y="20" rx="10" ry="10" width="85" height="19" />
          <rect x="1305" y="20" rx="10" ry="10" width="85" height="19" />
          <rect x="1424" y="20" rx="10" ry="10" width="68" height="19" />

          {/* second row */}
          {/* <rect x="26" y="60" rx="4" ry="4" width="20" height="20" /> */}
          <rect x="26" y="60" rx="10" ry="10" width="105" height="19" />
          <rect x="187" y="60" rx="10" ry="10" width="169" height="19" />
          <rect x="401" y="60" rx="10" ry="10" width="85" height="19" />
          <rect x="522" y="60" rx="10" ry="10" width="169" height="19" />
          <rect x="730" y="60" rx="10" ry="10" width="85" height="19" />
          <rect x="851" y="60" rx="10" ry="10" width="85" height="19" />
          <rect x="977" y="60" rx="10" ry="10" width="169" height="19" />
          <rect x="1182" y="60" rx="10" ry="10" width="85" height="19" />
          <rect x="1304" y="60" rx="10" ry="10" width="85" height="19" />
          <circle cx="1456" cy="70" r="12" />

          {/* third row */}
          {/* <rect x="26" y="100" rx="4" ry="4" width="20" height="20" /> */}
          <rect x="26" y="100" rx="10" ry="10" width="105" height="19" />
          <rect x="187" y="100" rx="10" ry="10" width="169" height="19" />
          <rect x="401" y="100" rx="10" ry="10" width="85" height="19" />
          <rect x="522" y="100" rx="10" ry="10" width="169" height="19" />
          <rect x="730" y="100" rx="10" ry="10" width="85" height="19" />
          <rect x="851" y="100" rx="10" ry="10" width="85" height="19" />
          <rect x="977" y="100" rx="10" ry="10" width="169" height="19" />
          <rect x="1182" y="100" rx="10" ry="10" width="85" height="19" />
          <rect x="1304" y="100" rx="10" ry="10" width="85" height="19" />
          <circle cx="1456" cy="110" r="12" />

          {/* fourth row */}
          {/* <rect x="26" y="140" rx="4" ry="4" width="20" height="20" /> */}
          <rect x="26" y="140" rx="10" ry="10" width="105" height="19" />
          <rect x="187" y="140" rx="10" ry="10" width="169" height="19" />
          <rect x="401" y="140" rx="10" ry="10" width="85" height="19" />
          <rect x="522" y="140" rx="10" ry="10" width="169" height="19" />
          <rect x="730" y="140" rx="10" ry="10" width="85" height="19" />
          <rect x="851" y="140" rx="10" ry="10" width="85" height="19" />
          <rect x="977" y="140" rx="10" ry="10" width="169" height="19" />
          <rect x="1182" y="140" rx="10" ry="10" width="85" height="19" />
          <rect x="1304" y="140" rx="10" ry="10" width="85" height="19" />
          <circle cx="1456" cy="150" r="12" />

          {/* fifth row */}
          {/* <rect x="26" y="180" rx="4" ry="4" width="20" height="20" /> */}
          <rect x="26" y="180" rx="10" ry="10" width="105" height="19" />
          <rect x="187" y="180" rx="10" ry="10" width="169" height="19" />
          <rect x="401" y="180" rx="10" ry="10" width="85" height="19" />
          <rect x="522" y="180" rx="10" ry="10" width="169" height="19" />
          <rect x="730" y="180" rx="10" ry="10" width="85" height="19" />
          <rect x="851" y="180" rx="10" ry="10" width="85" height="19" />
          <rect x="851" y="180" rx="10" ry="10" width="85" height="19" />
          <rect x="977" y="180" rx="10" ry="10" width="169" height="19" />
          <rect x="1182" y="180" rx="10" ry="10" width="85" height="19" />
          <rect x="1304" y="180" rx="10" ry="10" width="85" height="19" />
          <circle cx="1456" cy="190" r="12" />

          {/* mock pagination row */}
          <rect x="26" y="250" rx="4" ry="4" width="20" height="20" />
          <rect x="66" y="250" rx="4" ry="4" width="20" height="20" />
          <circle cx="120" cy="260" r="14" />
          <circle cx="160" cy="260" r="14" />
          <circle cx="200" cy="260" r="14" />
          <rect x="240" y="250" rx="4" ry="4" width="20" height="20" />
          <rect x="280" y="250" rx="4" ry="4" width="20" height="20" />
          <rect x="1400" y="250" rx="10" ry="10" width="85" height="19" />
        </ContentLoader>
      </Grid>
    </Grid>
  );
}
