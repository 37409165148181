import React, { useEffect } from 'react';

// redux
import { useSelector, useDispatch } from 'react-redux';

import { setAllowChange } from '../../LanguageMenu/languageSlice';
import { selectLanguage } from '../../LanguageMenu/selectors';

// mui
import {
  selectTenantVerbiage,
  selectTenantVerbiageDefinitions,
  selectVerbiageLoading,
  selectVerbiageSubmitting,
} from '../selectors';
import { getVerbiage, getVerbiageDefinitions } from '../settingsSlice';

import AppDragExpansionWrapper from './DragExpansionWrapper';

export function SettingsVerbiage(props) {
  const { currentTenant, verbiageType, tabType } = props;

  const language = useSelector(selectLanguage);
  const tenantVerbiage = useSelector(selectTenantVerbiage);
  const verbiageLoading = useSelector(selectVerbiageLoading);
  const submitting = useSelector(selectVerbiageSubmitting);
  const tenantVerbiageDefinitions = useSelector(selectTenantVerbiageDefinitions);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setAllowChange(true));
    if (language) dispatch(getVerbiage(currentTenant.accountId, currentTenant.id, language));
    dispatch(getVerbiageDefinitions(currentTenant.accountId, currentTenant.id));
  }, [currentTenant, verbiageType, language]);

  return (
    <AppDragExpansionWrapper
      data={tenantVerbiage}
      type="verbiage"
      dataLoading={verbiageLoading}
      currentTenant={currentTenant}
      submitting={submitting}
      verbiageDefinitions={tenantVerbiageDefinitions}
      verbiageType={verbiageType}
      tabType={tabType}
    />
  );
}

export default SettingsVerbiage;

export function createVerbiageSettings() {
  return SettingsVerbiage;
}
