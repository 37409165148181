import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// material-ui components
import TextFieldInput from '../Shared/Forms/KendoFields/TextFieldInput';

import { selectCreatingTemplate } from './selectors';
import { createTemplateDef } from './settingsSlice';
import { systemMessagesTabTypes, systemMessagesTypes } from './SystemMessages/helpers/mappings';

const field = [
  {
    type: 'text',
    name: 'id',
    label: 'Id',
    width: 4,
    required: true,
  },
  {
    type: 'text',
    name: 'name',
    label: 'Name',
    width: 4,
    required: true,
  },
];

export function SettingsCreateDialog(props) {
  const { closeImportDialog, open, title, currentTenant, language, messageType } = props;

  const navigate = useNavigate();

  const creatingTemplateLoading = useSelector(selectCreatingTemplate);
  const classes = useStyles();

  const dispatch = useDispatch();

  const [commTemplate, setCommTemplate] = useState({
    id: '',
    language,
    name: '',
    type: messageType,
  });

  const handleChange = useCallback(
    (e) => {
      if (e.target && e.target.value) {
        if (e.target.name === 'name') {
          setCommTemplate({ ...commTemplate, name: e.target.value });
        } else if (e.target.name === 'id') {
          setCommTemplate({ ...commTemplate, id: e.target.value });
        }
      }
    },
    [commTemplate]
  );

  useEffect(() => {
    setCommTemplate({ ...commTemplate, type: messageType });
  }, [messageType]);

  // handles creation of new system message
  const handleCreate = async () => {
    if (commTemplate.name && commTemplate.id) {
      const isSuccessfullyCreated = await dispatch(
        createTemplateDef(currentTenant.accountId, currentTenant.id, commTemplate)
      );
      if (isSuccessfullyCreated) {
        handleClose();
        navigate(
          `/${currentTenant.id}/settings/system-messages/${
            commTemplate.type === systemMessagesTypes[systemMessagesTabTypes.EMAIL]
              ? systemMessagesTabTypes.EMAIL
              : systemMessagesTabTypes.SMS
          }/edit/${commTemplate.id}`
        );
      }
    }
  };

  const handleClose = () => {
    setCommTemplate({ ...commTemplate, id: '', name: '' });
    closeImportDialog();
  };

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
          handleClose();
        }
      }}
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
    >
      <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box>
              <div>
                <TextFieldInput field={field[0]} value={commTemplate?.id || ''} handleChange={handleChange} />
              </div>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box>
              <div>
                <TextFieldInput field={field[1]} value={commTemplate?.name || ''} handleChange={handleChange} />
              </div>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <div>
          <Button disabled={creatingTemplateLoading} onClick={handleClose} color="primary" style={{ marginRight: 8 }}>
            Cancel
          </Button>

          <Button disabled={creatingTemplateLoading} variant="contained" onClick={handleCreate} color="primary">
            {creatingTemplateLoading ? (
              <Grid className={classes.loadingCreateTemplate}>
                <CircularProgress size={28} thickness={6} />
              </Grid>
            ) : null}
            Save
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStyles({
  loadingCreateTemplate: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
  },
});

// middleware
export default SettingsCreateDialog;
