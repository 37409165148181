/* eslint-disable no-unsafe-optional-chaining */
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import React from 'react';
import { useDispatch } from 'react-redux';

import { showConfirmation } from '../../../Confirmation/confirmationSlice';

function DrawShapeDeleteButtons(props) {
  const { shapes } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleConfirmDeletingShape = (shapeId) => {
    dispatch(
      showConfirmation(
        'Confirm',
        'Are you sure you want to delete this selected shape?',
        'annotationShapeDelete',
        shapeId
      )
    );
  };

  return (
    <>
      {shapes.map((shape) => (
        <IconButton
          key={shape.id}
          color="primary"
          className={classes.closeIcon}
          style={{
            left: shape?.x + shape?.width + 20,
            top: shape?.y + 40,
          }}
          onClick={() => handleConfirmDeletingShape(shape.id)}
        >
          <CancelIcon />
        </IconButton>
      ))}
    </>
  );
}

const useStyles = makeStyles(() => ({
  closeIcon: {
    position: 'absolute',

    zIndex: 1,
  },
}));

export default DrawShapeDeleteButtons;
