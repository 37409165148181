/* eslint-disable react/no-array-index-key */
/* eslint-disable no-return-assign */
/* eslint-disable import/no-cycle */
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import AccountTree from '@material-ui/icons/AccountTree';
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import DeleteIcon from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import MedicalCodes from '@material-ui/icons/LocalHospital';
import React, { useState, useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';

import { showConfirmation } from '../../../../Confirmation/confirmationSlice';
import { createInputHelper } from '../../../../Shared/Forms/helpers/createInputHelper';
import utils from '../../../../Shared/Utils/utils';
// import OutcomePreview from "../../../OutcomesBuilder/OutcomePreview";
import { getCodeName, getFieldName } from '../../../QuestionBuilder/helpers/formHelper';
import OutcomePreview from '../../../vcb-package/OutcomePreview';
import { defaultQuestionId, nodeNameField, priorityField } from '../../helpers/formHelper';
import { QuestionnaireBuilderContext } from '../../QuestionnaireBuilderWrapper';

import MedicalCode from './MedicalCode/MedicalCode';

function Outcome(props) {
  const {
    node,
    outcomeIndex,
    nodeIndex,
    previewOutcomeIndex,
    onClickQuestion,
    disableButtons,
    setOutcomeEdit,
    cancelNodeUpdate,
    setDisableButtons,
    captureWindowPosition,
  } = props;

  const [editingCoding, setEditingCoding] = useState(false);
  const {
    nodes,
    setNodes,
    selectedQuestions,
    setSelectedQuestions,
    handleSubmit,
    setQuestionnaireUpdated,
    questionsRef,
  } = useContext(QuestionnaireBuilderContext);
  const classes = useStyles();
  const [outcomeNodes, setOutcomeNodes] = useState([]);
  const [showOutcomeNodes, setShowOutcomeNodes] = useState(false);

  const dispatch = useDispatch();
  const { title, name, id } = node.outcome;

  useEffect(() => {
    const foundNodes = nodes.filter((n) => {
      if (!n.outcome) return false;
      return n.outcome.id === id;
    });

    setOutcomeNodes(foundNodes);
  }, [nodes, id]);

  const addOutcomeNode = () => {
    setOutcomeNodes([
      ...outcomeNodes,
      {
        defaultRoute: {
          defaultNextQuestionId: null,
          coding: null,
        },
        id: utils.getId(),
        outcome: node.outcome,
        priority: 1,
        name: `${node.outcome.name} node ${outcomeNodes.length + 1}`,
        rules: [],
      },
    ]);
  };

  const questionsToSelect = () => {
    const defaultValue = {
      value: 'end',
      label: 'End of questionnaire. (Patient is done)',
    };

    const questions = nodes
      .filter((q) => q.id !== id)
      .map((k) => {
        if (k.name) {
          return {
            value: k.id,
            label: k.name,
          };
        }
        if (k.question) {
          return {
            value: k.id,
            label: k.question.name,
          };
        }
        if (k.outcome) {
          return {
            value: k.id,
            label: k.outcome.name,
          };
        }
        return null;
      });

    return [defaultValue, ...questions];
  };

  const defaultQuestionIdValues = (outcome) => {
    if (!outcome.defaultRoute || !outcome.defaultRoute.defaultNextQuestionId) {
      return { defaultNextQuestionId: 'end' };
    }

    return {
      defaultNextQuestionId: outcome && outcome.defaultRoute && outcome.defaultRoute.defaultNextQuestionId,
    };
  };

  const removeOutcome = () => {
    // find current outcome
    const currentOutcome = nodes.filter((q) => q.outcome && q.outcome.id === id);

    let confirmationText = 'Are you sure you want to delete this outcome?';
    if (currentOutcome && currentOutcome.length) {
      // find nodes for outcome
      const foundNodes = nodes.filter((q) => q.defaultNextQuestionId === currentOutcome[0].id).map((item) => item.name);

      confirmationText = `Deleting this node will require editing the following nodes so their 
      jump logic no longer refers to the node you are deleting:
      <br>-
      ${foundNodes.join('<br>-')}
      <br><br>
      ${confirmationText}`;
    }

    dispatch(
      showConfirmation('Confirm', confirmationText, 'questionnaireQuestionDelete', '', '', '', () => {
        const updatedNodes = nodes.filter((q) => {
          if (!q.outcome) return true;
          return q.outcome.id !== id;
        });
        const updatedSelectedQuestions = selectedQuestions.filter((item) => item.id !== id);
        setQuestionnaireUpdated(true);
        setSelectedQuestions(updatedSelectedQuestions);
        setNodes(updatedNodes);
        handleSubmit(selectedQuestions, updatedNodes);
      })
    );
  };

  const removeOutcomeNode = (id) => {
    const firstNodeId = outcomeNodes[0].id;
    if (id === firstNodeId) return;
    dispatch(
      showConfirmation(
        'Confirm',
        'Are you sure you want to delete this outcome?',
        'questionnaireQuestionDelete',
        '',
        '',
        '',
        () => {
          const updatedNodes = nodes.filter((q) => q.id !== id);
          setQuestionnaireUpdated(true);
          setNodes(updatedNodes);
          handleSubmit(selectedQuestions, updatedNodes);
        }
      )
    );
  };

  const handleNodeNameChange = (value, id) => {
    const updatedOutcomes = outcomeNodes.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          outcome: node.outcome,
          name: value,
        };
      }
      return item;
    });

    setOutcomeNodes(updatedOutcomes);
  };

  const setDefaultQuestionId = (name, value, id) => {
    const updatedOutcomes = outcomeNodes.map((item) => {
      if (item.id === id) {
        if (value === 'end') {
          item = {
            ...item,
            defaultRoute: {
              ...item.defaultRoute,
              [name]: null,
            },
          };
        } else {
          item = {
            ...item,
            defaultRoute: {
              ...item.defaultRoute,
              [name]: value,
            },
          };
        }
      }
      return item;
    });
    setOutcomeNodes(updatedOutcomes);
  };

  const saveOutcomes = () => {
    const savingOutcomeNodes = utils.merge(nodes, outcomeNodes, 'id');
    setQuestionnaireUpdated(true);
    setNodes(savingOutcomeNodes);
    handleSubmit(selectedQuestions, savingOutcomeNodes);
    setShowOutcomeNodes(false);
    setDisableButtons(false);
  };

  const setOutcomePriority = (e, id) => {
    const { value, name } = e.target;
    const updatedOutcomes = outcomeNodes.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          [name]: value,
        };
      }
      return item;
    });

    setOutcomeNodes(updatedOutcomes);
  };

  const setOutcomePriorityWithParams = (name, value, id) => {
    const updatedOutcomes = outcomeNodes.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          [name]: value,
        };
      }
      return item;
    });
    setOutcomeNodes(updatedOutcomes);
  };

  const disabledOutcomePriorityItems = (currentPriorityValue, id) => {
    function countInArray(array, val) {
      return array.filter((item) => item === val).length;
    }

    const items = [];

    if (currentPriorityValue !== outcomeNodes.find((item) => item.id === id).priority) {
      items.push(currentPriorityValue);
    }

    outcomeNodes.forEach((item) => {
      if (item.priority) {
        items.push(item.priority);
      }
    });

    return items.filter((val) => countInArray(items, val) > 1);
  };

  const addCoding = (id) => {
    const updatedOutcomes = outcomeNodes.map((item) => {
      if (item.id === id) {
        if (item.defaultRoute && item.defaultRoute.coding) return item;
        item.defaultRoute = {
          ...item.defaultRoute,
          coding: {
            code: '',
            description: '',
            system: '',
          },
        };
      }
      return item;
    });

    setOutcomeNodes(updatedOutcomes);
  };

  const editCoding = (id) => (name, value) => {
    const updatedOutcomes = outcomeNodes.map((item) => {
      if (item.id === id) {
        if (name === 'codeDescription') {
          item.defaultRoute.coding = {
            ...item.defaultRoute.coding,
            code: value[getCodeName(item.defaultRoute.coding.system)],
            description: value[getFieldName(item.defaultRoute.coding.system)],
          };
        } else {
          item.defaultRoute.coding[name] = value;
        }
      }
      return item;
    });

    setOutcomeNodes(updatedOutcomes);
  };

  const removeMedicalCode = (id) => {
    const updatedOutcomes = outcomeNodes.map((item) => {
      if (item.id === id) {
        item.defaultRoute.coding = null;
      }
      return item;
    });

    setOutcomeNodes(updatedOutcomes);
  };

  const nodeButtonClick = () => {
    captureWindowPosition(nodeIndex);
    if (!showOutcomeNodes) {
      setShowOutcomeNodes(!showOutcomeNodes);
      setDisableButtons(!showOutcomeNodes);
    }
  };

  const cancelOutcomeNodeUpdate = () => {
    setShowOutcomeNodes(false);
    setDisableButtons(false);
    cancelNodeUpdate();
  };

  return (
    <>
      <Grid item xs={5} ref={(el) => (questionsRef.current[nodeIndex] = el)}>
        <Card
          style={{
            padding: 20,
            marginTop: 20,
            backgroundColor: previewOutcomeIndex === outcomeIndex ? '#d6d6d6' : '',
          }}
          onClick={() => onClickQuestion(outcomeIndex, 'outcome', nodeIndex)}
        >
          <Grid container justifyContent="flex-end">
            <Grid item xs={12}>
              <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="h5">
                  {name}:{title}
                </Typography>
                <Chip size="small" label="outcome" color="primary" />
              </Box>
            </Grid>
            <Grid item xs={1}>
              <Tooltip title="Nodes">
                <IconButton onClick={nodeButtonClick} disabled={disableButtons && previewOutcomeIndex !== outcomeIndex}>
                  <AccountTree />
                </IconButton>
              </Tooltip>
            </Grid>
            <Tooltip title="Edit">
              <Grid item xs={1}>
                <IconButton onClick={() => setOutcomeEdit(node.outcome, nodeIndex)} disabled={disableButtons}>
                  <Edit />
                </IconButton>
              </Grid>
            </Tooltip>
            <Grid item xs={1}>
              <Tooltip title="Remove outcome">
                <IconButton onClick={() => removeOutcome(id)} disabled={disableButtons}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item xs={7}>
        {previewOutcomeIndex === outcomeIndex && !showOutcomeNodes && (
          <Grid container direction="column" justifyContent="center" alignItems="center">
            <Grid className={classes.outcomeWrapper}>
              <OutcomePreview formData={node.outcome} previewForBuilder />
            </Grid>
          </Grid>
        )}
        {previewOutcomeIndex === outcomeIndex && showOutcomeNodes && (
          <>
            {outcomeNodes.map((item, index) => (
              <Card
                key={`outcomeCard_${index}`}
                style={{ padding: 20, marginTop: 20 }}
                onClick={() => onClickQuestion(outcomeIndex, 'outcome')}
              >
                <Grid container alignItems="baseline" alignContent="center" spacing={1}>
                  <Grid item xs={8}>
                    {createInputHelper(nodeNameField, { name: item.name }, (e) =>
                      handleNodeNameChange(e.value, item.id)
                    )}
                  </Grid>
                  <Grid item xs={2}>
                    {createInputHelper(
                      priorityField(disabledOutcomePriorityItems(outcomeNodes[index].priority, item.id)),
                      { priority: item.priority },
                      (value) => setOutcomePriority(value, item.id),
                      (value, name) => setOutcomePriorityWithParams(value, name, item.id)
                    )}
                  </Grid>
                  <Grid item xs={2}>
                    <Tooltip title="Add medical code">
                      <IconButton
                        disabled={item.defaultRoute && item.defaultRoute.coding}
                        onClick={() => {
                          setEditingCoding(!editingCoding);
                          addCoding(item.id);
                        }}
                      >
                        <MedicalCodes />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete node">
                      <IconButton onClick={() => removeOutcomeNode(item.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>

                <Typography style={{ marginTop: 20 }} variant="body1">
                  {item.outcome && item.outcome.description && item.outcome.description.replace(/<\/?[^>]+(>|$)/g, '')}
                </Typography>

                {item.defaultRoute && item.defaultRoute.coding && (
                  <MedicalCode
                    coding={item.defaultRoute && item.defaultRoute.coding ? item.defaultRoute.coding : {}}
                    removeMedicalCode={() => {
                      removeMedicalCode(item.id);
                      setEditingCoding(false);
                    }}
                    editCoding={() => editCoding(item.id)}
                  />
                )}
                {createInputHelper(
                  defaultQuestionId(questionsToSelect()),
                  defaultQuestionIdValues(item),
                  () => ({}),
                  (name, value) => setDefaultQuestionId(name, value, item.id)
                )}
              </Card>
            ))}
            <Grid container justifyContent="center">
              <Grid item>
                <Tooltip title="Add node">
                  <IconButton onClick={addOutcomeNode}>
                    <AddCircleOutline />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
            <Card style={{ padding: 10, marginTop: 10 }}>
              <Button variant="contained" color="primary" onClick={saveOutcomes}>
                Save
              </Button>
              <Button variant="outlined" color="primary" style={{ marginLeft: 15 }} onClick={cancelOutcomeNodeUpdate}>
                Cancel
              </Button>
            </Card>
          </>
        )}
      </Grid>
    </>
  );
}

const useStyles = makeStyles(() => ({
  outcomeWrapper: {
    width: 400,
    // marginTop: -55,
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
  },
}));

export default Outcome;
