import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React, { useState, useEffect } from 'react';

import utils from '../../Utils/utils';

// ui reflection of file view
function BooleanField(props) {
  const { field, value, handleChangeWithParams, selectedLanguage } = props;
  const [currentValue, setCurrentValue] = useState(value[field.name]);

  useEffect(() => {
    if (value !== currentValue) {
      setCurrentValue(value[field.name]);
    }
    // eslint-disable-next-line
  }, [field.name, value]);

  const answers = {
    yes: {
      eng: 'Yes',
      spa: 'Sí',
      zho: '是',
      rus: 'да',
      fre: 'Oui',
    },
    no: {
      eng: 'No',
      spa: 'No',
      zho: '是',
      rus: 'Нет',
      fre: 'Non',
    },
  };

  const handleSelect = (value) => {
    setCurrentValue(value);
    handleChangeWithParams(field.name, value);
  };

  const fieldTitle =
    selectedLanguage && field.name && field.name.translations
      ? field.name.translations[selectedLanguage]
      : field.label || field.name;
  const questionDescription =
    selectedLanguage && field.description && field.description.translations
      ? field.description.translations[selectedLanguage]
      : field.description;

  return (
    <Grid item xs={12}>
      <Typography style={{ opacity: 0.5, marginBottom: 10 }}>
        {field.questionPreview ? utils.renderHTMLTags(questionDescription) : fieldTitle}
        {field.required && '*'}
      </Typography>
      <Button
        variant={currentValue === 'Yes' ? 'contained' : 'outlined'}
        color="primary"
        onClick={() => handleSelect('Yes')}
        disabled={field.disabled || field.readOnly}
      >
        {answers.yes[selectedLanguage] || 'Yes'}
      </Button>
      <Button
        variant={currentValue === 'No' ? 'contained' : 'outlined'}
        color="primary"
        onClick={() => handleSelect('No')}
        disabled={field.disabled || field.readOnly}
      >
        {answers.no[selectedLanguage] || 'No'}
      </Button>
    </Grid>
  );
}

export default BooleanField;
