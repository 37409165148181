import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React from 'react';

// ui reflection of file view
function ImageInput(props) {
  const { field, value } = props;

  return (
    <Grid container spacing={1}>
      <Grid item>
        {value ? (
          <img
            src={value}
            alt={field.name}
            style={{
              maxWidth: 200,
              maxHeight: 100,
              backgroundColor: '#F3F3F3',
            }}
          />
        ) : (
          <Typography variant="caption" />
        )}
      </Grid>
    </Grid>
  );
}

export default ImageInput;
