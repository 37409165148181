import {
  Paper,
  Card,
  Box,
} from '@material-ui/core';
import React from 'react';
import AccordionGroup from './helper/AccordionGroup';
import ConsultSimulator from './FirstView/ConsultSimulator';
import RxOrderSimulator from './FirstView/RxOrderSimulator';
import TestKitSimulator from './FirstView/TestKitSimulator';
import EmployeeBenefitSimulator from './FirstView/EmployeeBenefitSimulator';
import NetsuiteSimulator from './Shopify/NetsuiteSimulator';
import WelcomeKitSimulator from './Shopify/WelcomeKitSimulator';
import ExternalIdSimulator from './FirstView/ExternalIdSimulator';
import FvDetailsSimulator from './FirstView/FvDetailsSimulator/FvDetailsSimulator';
import InsuranceSimulator from './InsuranceSimulator'
import { useSelector } from 'react-redux';
import { selectUserDetails, } from '../Users/selectors';
import { selectUserDetailsLoading } from '../Users/selectors';
import TelehealthSimulator from './FirstView/TelehealthSimulator';

function Simulator() {

  const userDetails = useSelector(selectUserDetails);
  const userDetailsLoading = useSelector(selectUserDetailsLoading)
  const userId = userDetails?._id ?? '';


  return (
    <div>
      <Paper style={{ borderRadius: '20px', marginBottom: 30 }} elevation={0}>
        {/* <SimulatorSkeletonLoading /> */}
        <Card
          style={{ borderRadius: '20px', marginBottom: 20, padding: 32, boxSizing: 'border-box', width: '100%' }}
          elevation={0}
        >
          <Box fontSize={25}>FIRST VIEW</Box>

          <AccordionGroup label={'Test Kit Simulator'}>
            <TestKitSimulator />
          </AccordionGroup>
          <AccordionGroup label={'Consult Simulator'}>
            <ConsultSimulator />
          </AccordionGroup>
          <AccordionGroup label={'Rx Order Simulator'}>
            <RxOrderSimulator />
          </AccordionGroup>
          <AccordionGroup label={'Employee Benefit Simulator'}>
            <EmployeeBenefitSimulator />
          </AccordionGroup>
          <AccordionGroup label={'External ID'}>
            <ExternalIdSimulator />
          </AccordionGroup>
          <AccordionGroup label={'FirstView Subscriber'}>
            <FvDetailsSimulator userDetailsId={userDetails?._id} userDetailsLoading={userDetailsLoading}/>
          </AccordionGroup>
          <AccordionGroup label={'Telehealth Simulator'}>
            <TelehealthSimulator userId={userDetails?._id} userDetailsLoading={userDetailsLoading} />
          </AccordionGroup>
        </Card>
      </Paper>
      {/* Insurance Simulator */}
      <Paper>

      </Paper>
        <Card
          style={{ borderRadius: '20px', marginBottom: 20, padding: 32, boxSizing: 'border-box', width: '100%' }}
          elevation={0}
        >
          <Box fontSize={25}>INSURANCE</Box>
          <AccordionGroup label={'Insurance Account'}>
            <InsuranceSimulator userId={userId} userDetailsLoading={userDetailsLoading}/>
          </AccordionGroup>
        </Card>
      <Paper>
        {/* Shopify Simulator */}
        <Card
          style={{ borderRadius: '20px', marginBottom: 20, padding: 32, boxSizing: 'border-box', width: '100%' }}
          elevation={0}
        >
          <Box fontSize={25}>SHOPIFY</Box>
          <AccordionGroup label={'Welcome Kit'}>
            <WelcomeKitSimulator />
          </AccordionGroup>
          <AccordionGroup label={'Order Fulfillment (NETSUITE)'}>
            <NetsuiteSimulator />
          </AccordionGroup>
        </Card>
      </Paper>
    </div>
  );
}

export default Simulator;
