// Kendo
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { MultiSelect } from '@progress/kendo-react-dropdowns';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectVirtualConsult } from '../../../VirtualConsult/selectors';
import { getTags } from '../../../VirtualConsult/virtualConsultSlice';
import { selectCurrentTenant } from '../../selectors';

// ui reflection of file view
function TagMultiSelect(props) {
  const { field, value: initialValue, handleChangeWithParams, disabled } = props;

  const currentTenant = useSelector(selectCurrentTenant);
  const consult = useSelector(selectVirtualConsult);

  const [selected, setSelected] = useState(initialValue || []);
  const [timeout, setStateTimeout] = useState(undefined);

  const dispatch = useDispatch();

  const handleMultiSelectChange = (e) => {
    const selectedItems = e.target.value.map((v) => (field.valueField ? v[field.valueField] : v));
    setSelected(selectedItems);
    handleChangeWithParams(field.name, selectedItems);
  };

  const handleFilterChange = (e) => {
    if (timeout) {
      clearTimeout(timeout);
    }

    if (e.filter.value && e.filter.value.length > 1) {
      setStateTimeout(
        setTimeout(() => {
          dispatch(getTags(currentTenant.id, e.filter.value));
        }, 500)
      );
    }
  };

  return (
    <Grid container justifyContent="center" spacing={2}>
      <Grid item xs={12}>
        <Typography style={{ opacity: 0.5 }}>{field.label}</Typography>
      </Grid>
      <Grid item xs={12}>
        <MultiSelect
          readOnly={field.readOnly}
          disabled={disabled || field.readOnly}
          data={(consult && consult.tags && consult.tags.filter(Boolean)) || []}
          textField={field.labelField}
          placeholder={field.placeholder}
          value={selected}
          onChange={handleMultiSelectChange}
          allowCustom={field.allowCustom}
          loading={false}
          onFilterChange={handleFilterChange}
          filterable
        />
      </Grid>
    </Grid>
  );
}

export default TagMultiSelect;
