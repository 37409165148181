import { withStyles } from '@material-ui/core/styles';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SettingsTemplatesStyles from '../../../assets/jss/components/SettingsTemplatesStyles';
import { setAllowChange } from '../../LanguageMenu/languageSlice';
import { selectLanguage } from '../../LanguageMenu/selectors';
import { showNotification } from '../../Notifications/notificationSlice';
import AppKendoTableWrapper from '../../Shared/Table/KendoTableWrapper';
import { selectTenantTemplates, selectTenantTemplatesLoading } from '../selectors';
import { SettingsConfirmImportDialog } from '../SettingsConfirmImportDialog';
import { SettingsCreateDialog } from '../SettingsCreateDialog';
import { exportZIPTemplates, getAllTemplates, importZIPTemplates } from '../settingsSlice';
import { systemMessagesTabTypes, systemMessagesTypes } from './helpers/mappings';
import { columns, initialSorting, tableButtons } from './helpers/systemMessagesListMapper';

export function SettingsTemplates(props) {
  const { currentTenant, messageType } = props;
  const tenantTemplates = useSelector(selectTenantTemplates);
  const tenantTemplatesLoading = useSelector(selectTenantTemplatesLoading);
  const language = useSelector(selectLanguage);
  const navigate = useNavigate();

  const fileFormats = ['.zip'];

  const [open, setOpen] = useState(false);
  const [importZIPLoading, setImportZIPLoading] = useState(false);
  const [exportZipLoading, setExportZipLoading] = useState(false);
  const [tabPage, setTabPage] = useState(0);
  const dispatch = useDispatch();
  const hiddenFileInputRef = useRef();
  const [createDialog, setCreateDialog] = useState(false);

  useEffect(() => {
    dispatch(getAllTemplates(currentTenant, 0, 10, '', 'name', 'ASC', messageType, language));
    dispatch(setAllowChange(true));
    setTabPage(0);
  }, [currentTenant, messageType, language]);

  // handles all table updates from table wrapper
  const handleTableUpdate = (page, pageSize, sort, direction, searchValue) => {
    dispatch(
      getAllTemplates(currentTenant, page, pageSize, searchValue, sort, direction.toUpperCase(), messageType, language)
    );
    setTabPage(page);
  };

  // onRowClick function in table
  const handleTableClick = (e) => {
    navigate(
      `/${currentTenant.id}/settings/system-messages/${
        messageType === systemMessagesTypes[systemMessagesTabTypes.EMAIL]
          ? systemMessagesTabTypes.EMAIL
          : systemMessagesTabTypes.SMS
      }/edit/${e.id}`
    );
  };

  // handles copy of all FAQs to clipboard
  const handleExport = async () => {
    setExportZipLoading(true);
    await dispatch(exportZIPTemplates(currentTenant));
    setExportZipLoading(false);
  };

  const handleImportZIPTemplates = async (e) => {
    setOpen(false);

    const { name } = e.target.files[0];
    const lastDot = name.lastIndexOf('.');
    const uploadedFileFormat = `.${name.substring(lastDot + 1)}`;

    if (!fileFormats.includes(uploadedFileFormat)) {
      return dispatch(showNotification(`Allowed file formats ${fileFormats.join(', ')}`, 'error'));
    }

    setImportZIPLoading(true);
    await dispatch(importZIPTemplates(currentTenant.accountId, currentTenant.id, e.target.files[0]));
    setImportZIPLoading(false);

    await dispatch(getAllTemplates(currentTenant, 0, 10, '', 'name', 'ASC', messageType, language));
  };

  // buttons
  const buttons = tableButtons(setOpen, importZIPLoading, exportZipLoading, handleExport, setCreateDialog);

  if (!currentTenant) return <div />;

  const isFilteredByType = tenantTemplates && tenantTemplates?.items[0].type === messageType;

  return (
    <>
      <AppKendoTableWrapper
        data={{
          ...tenantTemplates,
          items:
            tenantTemplates &&
            tenantTemplates.items &&
            tenantTemplates.items.map((template) => ({
              ...template,
              currentTenant: currentTenant && currentTenant.id,
              currentAccount: currentTenant && currentTenant.accountId,
              status: template.enabled ? 'Active' : 'Inactive',
            })),
          page: tabPage,
        }}
        showButtons
        tableButtons={buttons}
        initialSort={initialSorting.field}
        initialDir={initialSorting.dir}
        columns={columns}
        currentTenant={currentTenant}
        onRowClick={handleTableClick}
        onTableUpdate={handleTableUpdate}
        loading={!isFilteredByType || tenantTemplatesLoading}
        parent="system-messages"
        // showLanguageMenu={
        //   messageType === "html" || messageType === "text" ? true : false
        // }
      />

      <input
        type="file"
        ref={hiddenFileInputRef}
        onChange={handleImportZIPTemplates}
        style={{ display: 'none' }}
        accept={fileFormats.join(', ')}
      />

      <SettingsConfirmImportDialog
        open={open}
        handleImport={() => hiddenFileInputRef.current.click()}
        title="Import System Messages"
        message={`Are you sure you want to import the copied System Messages? 
          Your current System Messages will be overwritten.`}
        closeImportDialog={() => setOpen(false)}
      />
      <SettingsCreateDialog
        open={createDialog}
        title="Create New System Message"
        closeImportDialog={() => setCreateDialog(false)}
        currentTenant={currentTenant}
        language={language}
        messageType={messageType}
      />
    </>
  );
}

export default withStyles(SettingsTemplatesStyles)(SettingsTemplates);
