/* eslint-disable react/no-array-index-key */
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import React from 'react';
import { useSelector } from 'react-redux';

import { selectAnnotationList } from '../selectors';

import AnnotationAccordion from './AnnotationAccordion';

function AnnotationMenu(props) {
  const { questionId } = props;
  const classes = useStyles();

  const annotationList = useSelector(selectAnnotationList);

  // const [anchorEl, setAnchorEl] = React.useState("test");
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <IconButton color="inherit" className={classes.reportProblemIcon} aria-describedby={id} onClick={handleClick}>
        <ReportProblemIcon style={{ fontSize: 50 }} />
      </IconButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        disableScrollLock
        className={classes.paper}
      >
        <Grid style={{ position: 'relative!important' }}>
          <IconButton color="inherit" className={classes.closeIcon} onClick={handleClose}>
            <CloseIcon />
          </IconButton>

          {annotationList && annotationList.length ? (
            annotationList.map((item, key) =>
              item.questionId === questionId ? (
                <AnnotationAccordion annotationData={item} key={key} itemKey={key} />
              ) : null
            )
          ) : (
            <Typography className={classes.typography}>No data</Typography>
          )}
        </Grid>
      </Popover>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    '&>.MuiPopover-paper': {
      width: '450px!important',
      maxWidth: '450px!important',
      maxHeight: 'calc(100% - 32px)',
      height: '100%',
      padding: 10,
      position: 'relative',
    },
  },
  reportProblemIcon: {
    position: 'fixed',
    right: 30,
    bottom: 30,
    color: 'red',
    cursor: 'pointer',
  },
  closeIcon: {
    position: 'fixed!important',
    top: 15,
    right: 40,
    zIndex: 1,
  },
  typography: {
    padding: theme.spacing(2),
  },
}));

export default AnnotationMenu;
