// Separate function to prevent unnecessary entries in data array
import { getDashboardButtonTitle } from './mappings';

const dashboardButtonBuilder = (state) => {
  const dashboardButtons = [];
  // Primary Dashboard Card
  if (state?.dashboardButtonAction1?.value) {
    dashboardButtons.push({
      action: state.dashboardButtonAction1,
      enabled: state.dashboardButtonEnabled1,
      style: state.dashboardButtonStyle1,
      title: getDashboardButtonTitle(state?.dashboardButtonAction1?.value),
      visible: state.dashboardButtonVisible1,
      colors: [state.dashboardButtonGradientColor1, state.dashboardButtonGradientColor2],
      imageUrl: state.dashboardButtonImageURL1,
      cardTitle: state.dashboardCardTitle1,
      cardBody: state.dashboardCardBody1,
      url: state.dashboardButtonUrl1,
    });
  }

  // Secondary Dashboard Card
  if (state?.dashboardButtonAction2?.value) {
    dashboardButtons.push({
      action: state.dashboardButtonAction2,
      enabled: state.dashboardButtonEnabled2,
      style: state.dashboardButtonStyle2,
      title: getDashboardButtonTitle(state.dashboardButtonAction2.value),
      visible: state.dashboardButtonVisible2,
      colors: [state.dashboardButtonGradientColor3, state.dashboardButtonGradientColor4],
      imageUrl: state.dashboardButtonImageURL2,
      cardTitle: state.dashboardCardTitle2,
      cardBody: state.dashboardCardBody2,
      url: state.dashboardButtonUrl2,
    });
  }

  // Tertiary Dashboard Card
  if (state?.dashboardButtonAction3?.value) {
    dashboardButtons.push({
      action: state.dashboardButtonAction3,
      enabled: state.dashboardButtonEnabled3,
      style: state.dashboardButtonStyle3,
      title: getDashboardButtonTitle(state.dashboardButtonAction3.value),
      visible: state.dashboardButtonVisible3,
      colors: [state.dashboardButtonGradientColor5, state.dashboardButtonGradientColor6],
      imageUrl: state.dashboardButtonImageURL3,
      cardTitle: state.dashboardCardTitle3,
      cardBody: state.dashboardCardBody3,
      url: state.dashboardButtonUrl3,
    });
  }
  return dashboardButtons;
};

const WelcomeScreenButtonBuilder = (state) => {
  const welcomeScreenSettings = {};

  if (state?.welcomeScreenButtonAction?.value) {
    welcomeScreenSettings.welcomeScreenButton = {
      action: state.welcomeScreenButtonAction,
      enabled: state.welcomeScreenButtonEnabled,
      style: state.welcomeScreenButtonStyle,
      title: state?.welcomeScreenButtonTitle,
      visible: state.welcomeScreenButtonVisible,
    };
  }
  if (state?.welcomeScreenSkipButtonAction?.value) {
    welcomeScreenSettings.welcomeScreenSkipButton = {
      action: state.welcomeScreenSkipButtonAction,
      enabled: state.welcomeScreenSkipButtonEnabled,
      title: state?.welcomeScreenSkipButtonTitle,
      visible: state.welcomeScreenSkipButtonVisible,
    };
  }
  return welcomeScreenSettings;
};

export const createNetworkJsonBuilder = (
  state,
  isEnabledVaxRecord,
  isEnabledVaxRecordControl,
  isEnabledVaxSettingsControl
) => ({
  Data: {
    ...state,
    _id: state._id,
    tenantID: state.tenantID,

    // ACCOUNT
    name: state.name,
    companyName: state.companyName,
    appName: state.appName,
    status: state.status,
    addressOne: state.addressOne,
    addressTwo: state.addressTwo,
    city: state.city,
    state: state.state,
    zip: state.zip,
    country: state.country,
    portalDomain: state.portalDomain,
    adminDomain: state.adminDomain,
    supportDomain: state.supportDomain,

    // PROFILE
    shortDescription: state.shortDescription,
    keywords: state.keywords,
    promoVideo: state.promoVideo,
    longDescription: state.longDescription,

    // MEMBER SUPPORT
    supportEmail: state.supportEmail,
    supportPhone: state.supportPhone,
    privacyEmail: state.privacyEmail,
    supportIncomingFax: state.supportIncomingFax,
    supportDirectAddres: state.supportDirectAddres,
    departmentId: state.departmentId,

    // ADMINISTRATIVE CONTACT
    adminContactFirstName: state.adminContactFirstName,
    adminContactLastName: state.adminContactLastName,
    adminContactPosition: state.adminContactPosition,
    adminContactEmail: state.adminContactEmail,
    adminContactOfficePhone: state.adminContactOfficePhone,
    adminContactFax: state.adminContactFax,
    emailSenderAddress: state.emailSenderAddress,

    // ORDERING TEST KIT
    orderingTestKitOption: state.orderingTestKitOption,

    // MEDICAL CONTACT
    medicContactFirstName: state.medicContactFirstName,
    medicContactMiddleName: state.medicContactMiddleName,
    medicContactLastName: state.medicContactLastName,
    medicContactSuffix: state.medicContactSuffix,
    medicContactTitle: state.medicContactTitle,
    medicContactSpeciality: state.medicContactSpeciality,
    medicContactGender: state.medicContactGender,
    medicContactPosition: state.medicContactPosition,
    medicContactEmail: state.medicContactEmail,
    medicContactOfficePhone: state.medicContactOfficePhone,
    medicContactFax: state.medicContactFax,
    medicContactNPI: state.medicContactNPI,
    medicContactDEA: state.medicContactDEA,
    medicContactCAQH: state.medicContactCAQH,
    medicContactMedicare: state.medicContactMedicare,
    medicContactUPIN: state.medicContactUPIN,
    medicContactStates: state.medicContactStates && state.medicContactStates.join(),

    // BILLING CONTACT
    billingContactFirstName: state.billingContactFirstName,
    billingContactLastName: state.billingContactLastName,
    billingContactPosition: state.billingContactPosition,
    billingContactEmail: state.billingContactEmail,
    billingContactOfficePhone: state.billingContactOfficePhone,
    billingContactFax: state.billingContactFax,

    // BILLING INFO
    bankName: state.bankName,
    accountName: state.accountName,
    accountNumber: state.accountNumber,
    routingNumber: state.routingNumber,
    swiftCode: state.swiftCode,
    taxIDEIN: state.taxIDEIN,
    tenantPricingOrCommission: state.tenantPricingOrCommission,
    showPatientRecordInAdminConsole: state.showPatientRecordInAdminConsole,

    requireGuardianMode: state.requireGuardianMode,
    tenantDisplaySettings: state.tenantDisplaySettings,

    // COMMERCIAL TERMS
    monthlyPlatformFee: state.monthlyPlatformFee,
    healthServices: state.healthServices,
    diagnosticServices: state.diagnosticServices,
    prescriptionServices: state.prescriptionServices,
    products: state.products,
    advertising: state.advertising,

    // SYMPTOM CHECKER SETTINGS
    symptomsCheckInterval: state.symptomsCheckInterval || 0,
    reminderNotificationSettings: {
      notificationTime: state.notificationTime,
      notificationMessage: state.notificationMessage,
      maximumNumberOfSnooze: state.maximumNumberOfSnooze,
      timeBetweenSnooze: state.timeBetweenSnooze,
    },

    // IMPORT HEALTH RECORD SETTINGS
    importHealthRecordSettings: {
      importHealthRequireAgreementScreen:
        state.importHealthRequireAgreementScreen != null ? state.importHealthRequireAgreementScreen : false,
      ImportHealthRequireIDVerification:
        state.importHealthRequireIDVerification != null ? state.importHealthRequireIDVerification : false,
      ImportHealthRequireSignature:
        state.importHealthRequireSignature != null ? state.importHealthRequireSignature : false,
    },

    // LAB SETTINGS
    labSettings: state.labSettings,
    enabledLabVendorsList: state.enabledLabVendorsList,

    // LAB ORDER SETTINGS
    labOrderCoverPatientResponsibility: state.labOrderCoverPatientResponsibility,
    labOrderShowDedicatedAgreementScreen: state.labOrderShowDedicatedAgreementScreen,
    labOrderRequireSignature: state.labOrderRequireSignature,
    labOrderCollectInsuranceInformation: state.labOrderCollectInsuranceInformation,

    // USER ONBOARDING SETTINGS
    onboardingShowDedicatedAgreementScreen: state.onboardingShowDedicatedAgreementScreen,
    onboardingCollectInsuranceInformation: state.onboardingCollectInsuranceInformation,
    onboardingRequireSignature: state.onboardingRequireSignature,
    collectDateOfBirth: state.collectDateOfBirth,
    collectDateOfBirthRequired: state.collectDateOfBirthRequired,
    collectSex: state.collectSex,
    collectSexRequired: state.collectSexRequired,
    collectAddress: state.collectAddress,
    collectAddressRequired: state.collectAddressRequired,
    collectStreetAddress: state.collectStreetAddress,
    collectStreetAddressRequired: state.collectStreetAddressRequired,
    collectState: state.collectState,
    collectStateRequired: state.collectStateRequired,
    collectZip: state.collectZip,
    collectZipRequired: state.collectZipRequired,
    collectRace: state.collectRace,
    raceRequired: state.raceRequired,
    collectEthicity: state.collectEthicity,
    ehtnicityRequired: state.ehtnicityRequired,
    verifyEmail: state.verifyEmail,
    verifyEmailRequired: state.verifyEmailRequired,
    collectPhoneNumber: state.collectPhoneNumber,
    collectPhoneNumberRequired: state.collectPhoneNumberRequired,
    verifyPhone: state.verifyPhone,
    verifyPhoneRequired: state.verifyPhoneRequired,
    verifyIdCard: state.verifyIdCard,
    verifyIdCardRequired: state.verifyIdCardRequired,
    uploadProfileImage: state.uploadProfileImage,
    showWelcomeScreen: state.showWelcomeScreen,
    showPreRegistrationWidget: state.showPreRegistrationWidget,
    webSignUpUrl: state.webSignUpUrl,
    webSignUpQRCodeUrl: state.webSignUpQRCodeUrl,
    appleAppDownloadUrl: state.appleAppDownloadUrl,
    androidAppDownloadUrl: state.androidAppDownloadUrl,
    universalAppDownloadUrl: state.universalAppDownloadUrl,
    universalAppDownloadQRCodeUrl: state.universalAppDownloadQRCodeUrl,

    // VACCINATION RECORD SETTINGS
    // [SAF-1023] - Backend needs to distinguish between platform and tenant settings api call
    isAdmin: false,
    verifyVaccinationSettings: {
      enableVaccinationRecord: state.enableVaccinationRecord,
      enableVaccinationRecordControl: isEnabledVaxRecordControl,
      enableVaccinationSettingsControl: isEnabledVaxSettingsControl,

      enableVaccinationAttestation: !state.enableVaccinationRecord ? false : state.enableVaccinationAttestation,
      enableVaccinationScanCredentials: !state.enableVaccinationRecord ? false : state.enableVaccinationScanCredentials,
      enableVaccinationImportCredentials: !state.enableVaccinationRecord
        ? false
        : state.enableVaccinationImportCredentials,

      enableVaccinationViaPatientPortal: !state.enableVaccinationRecord
        ? false
        : state.enableVaccinationViaPatientPortal,
      enableVaccinationConfirmWithProvider: !state.enableVaccinationRecord
        ? false
        : state.enableVaccinationConfirmWithProvider,
      enableVaccinationAppleHealthKit: !state.enableVaccinationRecord ? false : state.enableVaccinationAppleHealthKit,
      // enableVaccinationShare: state.enableVaccinationShare,

      vaccinationAttestationTitle: state.verifyVaccinationAttestationTitle ?? 'Attestation of Vaccination',
      vaccinationAttestationSubTitle: state.verifyVaccinationAttestationSubTitle ?? 'Photo of card or exemption',
      vaccinationScanCredentialsTitle: state.verifyVaccinationScanCredentialsTitle ?? 'Scan a Credential',
      vaccinationScanCredentialsSubTitle:
        state.verifyVaccinationScanCredentialsSubTitle ?? 'Issued from vaccination site',
      vaccinationImportCredentialsTitle: state.verifyVaccinationImportCredentialsTitle ?? 'Import a Credential',
      vaccinationImportCredentialsSubTitle: state.verifyVaccinationImportCredentialsSubTitle ?? 'Stored on your device',

      // NEW STRINGS
      vaccinationViaPatientPortalTitle: state.verifyVaccinationViaPatienPortalTitle ?? 'Verify via Patient Portal',
      vaccinationViaPatientPortalSubTitle:
        state.verifyVaccinationViaPatienPortalSubtitle ?? 'Using account credentials',

      vaccinationConfirmWithProviderTitle: state.verifyVaccinationConfirmWithProviderTitle ?? 'Confirm with Provider',
      vaccinationConfirmWithProviderSubtitle:
        state.verifyVaccinationConfirmWithProviderSubtitle ?? 'Physicians, Clinic, or Pharmacy',

      vaccinationAppleHealthKitTitle: state.verifyVaccinationAppleHealthKitTitleTenant ?? 'Apple Health',
      vaccinationAppleHealthKitSubTitle:
        state.verifyVaccinationAppleHealthKitSubtitleTenant ?? 'Import from Apple Health',
    },

    // SAFEPASS SETTINGS
    enableSafePass: state.enableSafePass,

    // PROCTOR SETTINGS
    enableProctorFeature: state.enableProctorFeature,
    enableProctorFeatureUI: state.enableProctorFeatureUI,

    enableAdherenceAlerts: state.enableAdherenceAlerts,
    enableAdherenceAlertsControl: state.enableAdherenceAlertsControl,
    // DASHBOARD SETTINGS
    dashboardSettings: {
      dashboardChartItems: state.dashboardSettings.dashboardChartItems,
      displayAIHistogramCharts: state.displayAIHistogramCharts,
      enableDashboardSettings: state.enableDashboardSettings,
      enableTestOrders: state.dashboardEnableTestOrders,
      enableTestHistory: state.dashboardEnableTestHistory,
      dashboardButtons: dashboardButtonBuilder(state),
    },

    welcomeScreenSettings: WelcomeScreenButtonBuilder(state),

    // APP SETTINGS
    showClearanceStatusHomeScreen: state.showClearanceStatusHomeScreen,
    enableImportHealthRecord: state.enableImportHealthRecord,
    requireIdVerification: state.requireIdVerification,
    isEmergencyContactInfoEnabled: state.isEmergencyContactInfoEnabled,
    showQRCode: state.showQRCode,
    enableSnackbar: state.enableSnackbar,
    enableVirtualConsult: state.enableVirtualConsult,
    showTestResultOutcome: state.showTestResultOutcome,
    showVCAssessmentOutcome: state.showVCAssessmentOutcome,
    showHomeScreenBackgroundColor: state.showHomeScreenBackgroundColor,
    showLastAssessmentDate: state.showLastAssessmentDate,
    isSAFEPassportEnabled: state.isSAFEPassportEnabled,
    enableFaceIdAndTouchId: state.enableFaceIdAndTouchId,
    useCustomPasswordExpirationValue: state.useCustomPasswordExpirationValue,
    passwordExpiration: state.passwordExpiration,
    enableZendesk: state.enableZendesk,
    // SOCIAL NETWORK
    facebook: state.facebook,
    instagram: state.instagram,
    linkedin: state.linkedin,
    youtube: state.youtube,
    twitter: state.twitter,
    website: state.website,
    blog: state.blog,

    // UI SETTINGS
    appThemeColor: state.appThemeColor || '#2E5266',
    secondaryColor: state.secondaryColor || '#2E5266',
    errorColor: state.errorColor || '#FF6464',
    successColor: state.successColor || '#76D873',
    warningColor: state.warningColor || '#ffc12a',
    reportingTimezone: state.reportingTimezone || 'UTC-04:00',
    homeScreenStyle: state.homeScreenStyle || 'dashboardStyle',
    buttonStyle: state.buttonStyle || 0,
    timeoutDuration: state.timeoutDuration,

    // SHRINK WRAPPED AGREEMENTS
    termsOfService: state.termsOfService,
    privacyPolicy: state.privacyPolicy,
    hipaaRelease: state.hipaaRelease,
    noticeOfPrivacy: state.noticeOfPrivacy,
    informedConsentForTelehealthService: state.informedConsentForTelehealthService,
    informedConsent: state.informedConsent,
    hivConsent: state.hivConsent,

    // GRAPHIC ASSETS
    networkLogoInThemeColor: state.networkLogoInThemeColor,
    emailLogo: state.emailLogo,
    logoSquareLight: state.logoSquareLight,
    logoSquareDark: state.logoSquareDark,
    logoRectangleLight: state.logoRectangleLight,
    logoRectangleDark: state.logoRectangleDark,
    welcomeScreenImage: state.welcomeScreenImage,
    homeScreenBannerUrl: state.homeScreenBannerUrl,
    homeScreenBanner: state.homeScreenBanner,
    favicon: state.favicon,
    loginPopupImage: state.loginPopupImage,

    //  Scheduled appointment
    enableScheduleAppointment: state.enableScheduleAppointment,
    enableScheduleAppointmentControl: state.enableScheduleAppointmentControl,

    // MULTI FACTOR AUTHENTICATION
    mfaSettings: {
      allowTenantMfaConfiguration: state?.allowTenantMfaConfiguration,
      enableDeviceRemembering: state?.enableDeviceRemembering,
      enableSMSVerification: state?.enableSMSVerification,
      enableGoogleAuth: state?.enableGoogleAuth,
      enableOneTimePassword: state?.enableOneTimePassword,
      // healthAdministratorDays: state?.healthAdministratorDays || state?.healthAdministratorDays === 0 ? state?.healthAdministratorDays : 120,
      healthAdministratorDays: state?.healthAdministratorDays || state?.healthAdministratorDays === 0 ? state?.healthAdministratorDays : 120,
      tenantAdministratorDays: state?.tenantAdministratorDays || state?.tenantAdministratorDays === 0 ? state?.tenantAdministratorDays : 120,
      healthCheckerDays: state?.healthCheckerDays || state?.healthCheckerDays === 0 ? state?.healthCheckerDays : 120,
      memberDays: state?.memberDays || state?.memberDays === 0 ? state?.memberDays : 120,
    },

    // ECOMMERCE SETTINGS
    ecommerceSettings: {
      ...state.ecommerceSettings,
      enableWelcomeKit: state.enableWelcomeKit,

      /* fix/SAF-5805: should be null if state value is empty string or zero value */
      shopifyCollections: {
        telehealthAsync: state.telehealthAsync === '' || state.telehealthAsync === 0 ? null : state.telehealthAsync,
        telehealthSync: state.telehealthSync === '' || state.telehealthSync === 0 ? null : state.telehealthSync,
        testKits: state.testKits === '' || state.testKits === 0 ? null : state.testKits,
        welcomeKits: state.welcomeKits === '' || state.welcomeKits === 0 ? null : state.welcomeKits,
      },
    },

    // EMPLOYEE BENEFITS
    benefitSettings: {
      ...state.benefitSettings,
      // fix/SAF-6308 - Only for Platform Admin level
      // employerBenefit: state.employerBenefit,
      // exceedBenefit: state.exceedBenefit,
      // employerBenefitCardSettings: {
      //   backgroundColor: state.employerBenefitCardBgColor,
      //   description: state.employerBenefitCardDescription,
      //   title: state.employerBenefitCardTitle,
      // },
    },

    // DASHBOARD CARD CONFIGURATION
    enableProfileSectionDashboard: state.enableProfileSectionDashboard,
    dashboardCardConfiguration: state.dashboardCardConfiguration,


    // TENANT ACCESS SETTINGS
    enableAccessCodes: state.enableAccessCodes,

    // deployment test
    // ACCOUNT VERIFICATION SETTINGS
    enableAccountVerificationScreen: state?.enableAccountVerificationScreen,
    accountVerificationScreenCriteria: {
      verificationField01: state?.verificationField01,
      verificationField02: state?.verificationField02,
      verificationField03: state?.verificationField03,
    },

    // FOOTER NAVIGATION
    footerConfiguration: {
      menuItem01: "HOME",
      menuItem02: state?.menuItem02 || footerNavigationMapping.CARE_PLANS.value,
      menuItem03: state?.menuItem03 || footerNavigationMapping.ORDER.value,
      menuItem04: state?.menuItem04 || footerNavigationMapping.SETTINGS.value,
    },

    // MOBILE_MENU_ITEMS
    menuItems:{
      enableTakeATest: state?.enableTakeATest,
    }

  },
});
