/* eslint-disable react/no-array-index-key */
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React from 'react';

import utils from '../../../Shared/Utils/utils';

export const defaultConsentLabels = ['OK', 'I agree', 'I accept', 'I consent'];

const actions = [
  {
    id: 'CallNumber',
    label: 'Call Number',
  },
  {
    id: 'RedirectToMobileScreen',
    label: 'Redirect to a Mobile Screen',
  },
  {
    id: 'RedirectToUrl',
    label: 'Redirect to URL',
  },
];

const mobilePages = [
  {
    id: 'Resources',
    label: 'Resources',
  },
  {
    id: 'TakeVirtualConsult',
    label: 'Take Virtual Consult',
  },
  {
    id: 'TestOrderDetails',
    label: 'Test Order Details',
  },
];

export const verbiageSnackbarFormMap = [
  {
    type: 'dynamicList',
    name: 'snackbarActions',
    label: 'Snackbar Action',
    required: true,
    width: 12,
    tooltip: 'Add Snackbar Action',
    buttonType: 'text',
    buttonText: 'Add Action',
    fields: [
      {
        type: 'autocomplete',
        name: 'id',
        label: 'Snackbar Action',
        required: true,
        width: 4,
        enum: actions.map((k) => ({
          value: k.id,
          label: k.label,
        })),
        labelField: 'label',
        valueField: 'value',
      },
      {
        type: 'conditional',
        checkField: 'id',
        checkValue: 'CallNumber',
        targetType: 'text',
        name: 'value',
        label: 'Phone Number',
        required: true,
        width: 4,
        mask: '(999) 999-9999',
        pattern: '^(?!(\\d)\\1{9})(?!0123456789|1234567890|0987654321|9876543210)\\d{10}$',
      },
      {
        type: 'conditional',
        checkField: 'id',
        checkValue: 'CallNumber',
        targetType: 'text',
        name: 'description',
        label: 'Text Label',
        required: false,
        width: 4,
      },
      {
        type: 'conditional',
        checkField: 'id',
        checkValue: 'RedirectToMobileScreen',
        targetType: 'autocomplete',
        name: 'value',
        label: 'Mobile Screen',
        enum: mobilePages.map((k) => ({
          value: k.id,
          label: k.label,
        })),
        labelField: 'label',
        valueField: 'value',
        required: true,
        width: 4,
      },
      {
        type: 'conditional',
        checkField: 'id',
        checkValue: 'RedirectToMobileScreen',
        targetType: 'text',
        name: 'description',
        label: 'Text Label',
        required: false,
        width: 4,
      },
      {
        type: 'conditional',
        checkField: 'id',
        checkValue: 'RedirectToUrl',
        targetType: 'text',
        name: 'value',
        label: 'URL',
        required: true,
        width: 4,
      },
      {
        type: 'conditional',
        checkField: 'id',
        checkValue: 'RedirectToUrl',
        targetType: 'text',
        name: 'description',
        label: 'Text Label',
        required: false,
        width: 4,
      },
    ],
  },
];

export const createActionView = (actions) => (
  <Grid item xs={4}>
    <Card elevation={0} style={{ backgroundColor: '#F3F3F3' }}>
      <CardHeader
        title={
          <Typography variant="subtitle1" color="primary">
            Snack Bar Actions
          </Typography>
        }
      />
      <CardContent>
        {actions.length ? (
          <Grid container>
            <Grid item xs={4}>
              <Typography variant="body2" color="textSecondary" gutterBottom>
                Action
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2" color="textSecondary" gutterBottom>
                Value
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2" color="textSecondary" gutterBottom>
                Text Label
              </Typography>
            </Grid>
            {actions.map((action, i) => (
              <Grid key={`grid-${i}`} container alignItems="center" spacing={1} style={{ paddingTop: 10 }}>
                <Grid item xs={4}>
                  <Typography variant="caption" gutterBottom>
                    {action.id && utils.splitCamelcaseString(action.id)}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="caption" gutterBottom>
                    {action.value && utils.splitCamelcaseString(action.value)}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="caption" gutterBottom>
                    {action.description}
                  </Typography>
                </Grid>
                {i !== actions.length - 1 && (
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                )}
              </Grid>
            ))}
          </Grid>
        ) : (
          <Grid container justifyContent="center">
            <Grid item>
              <Typography variant="caption" gutterBottom>
                There are no actions setup for this snack bar
              </Typography>
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  </Grid>
);
