export const transform = (values) => ({
  ...values,
  assessmentDetails: transformAssessmentDetails(values.assessmentDetails),
  androidStoreAppStableVersion: values.appVersion && values.appVersion.androidStoreAppStableVersion,
  appleStoreAppStableVersion: values.appVersion && values.appVersion.appleStoreAppStableVersion,
});

// set assessment details as true if null
const transformAssessmentDetails = (value) => {
  if (value === null) {
    return true;
  }
  return value;
};

export const AccountFormMap = [
  {
    type: 'groupNoDropdown',
    label: 'DETAILS',
    name: 'ACCOUNT_SECTION',
    fields: [
      {
        type: 'hidden',
        name: '_id',
        label: 'ID',
        required: false,
        width: 4,
      },
      {
        type: 'text',
        name: 'name',
        label: 'Account Name',
        required: true,
        width: 4,
      },
      {
        type: 'multiselect',
        name: 'whitelistedEmailAddressDomains',
        label: 'White Listed Email Address Domains',
        required: false,
        width: 12,
        allowCustom: true,
        placeholder: 'Type the email address domains you wish to be whitelisted',
      },
      {
        type: 'toggle',
        name: 'assessmentDetails',
        label: 'Assessment Details Access',
        required: false,
        width: 4,
        labelPlacement: 'end',
      },
    ],
  },
  {
    type: 'groupNoDropdown',
    label: 'APPLICATION SETTINGS',
    name: 'APP_SECTION',
    sensitive: true,
    fields: [
      // {
      //   type: "text",
      //   name: "auth0Database",
      //   label: "Auth0 Database",
      //   required: false,
      //   width: 3,
      //   show: false,
      // },
      // {
      //   type: "text",
      //   name: "adminAuth0AppId",
      //   label: "Admin Auth0 App ID",
      //   required: false,
      //   width: 3,
      //   show: false,
      // },
      {
        type: 'text',
        name: 'androidStoreAppStableVersion',
        label: 'Android Stable Version',
        required: false,
        width: 3,
      },
      {
        type: 'text',
        name: 'appleStoreAppStableVersion',
        label: 'Apple Stable Version',
        required: false,
        width: 3,
      },
      // {
      //   type: "multiselect",
      //   name: "auth0AppIds",
      //   label: "Auth0 App IDs",
      //   required: false,
      //   width: 12,
      //   allowCustom: true,
      //   show: false,
      // },
      {
        type: 'multiselect',
        name: 'authorizedDomains',
        label: 'Authorized Domains',
        required: false,
        width: 12,
        allowCustom: true,
      },
    ],
  },
  {
    type: 'groupNoDropdown',
    label: 'AUTHENTICATION CONFIGURATION',
    name: 'SSO',
    sensitive: true,
    fields: [
      {
        type: 'toggle',
        name: 'defaultAuthEnabled',
        label: 'Default Authentication Enabled',
        required: true,
        width: 3,
        default: false,
        labelPlacement: 'end',
      },
      {
        type: 'toggle',
        name: 'ssoAuthEnabled',
        label: 'SSO Authentication Enabled',
        required: true,
        width: 3,
        default: false,
        labelPlacement: 'end',
      },
      {
        type: 'conditional',
        checkField: 'ssoAuthEnabled',
        checkValue: true,
        targetType: 'text',
        name: 'ssoConnectionName',
        label: 'SSO Connection Name',
        required: true,
        width: 4,
      },
    ],
  },
];

// send steps for form wrapper, need label and pass data from helper. Steps must be in stepper order.
// if only 1 step, form wrapper will remove stepper bar and back button.
export const formSteps = [
  {
    label: 'Account Details',
    data: AccountFormMap,
  },
];
