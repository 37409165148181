/* eslint-disable no-prototype-builtins */
/* eslint-disable import/no-cycle */
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import MedicalCodes from '@material-ui/icons/LocalHospital';
import React, { useContext, useState } from 'react';

import { createInputHelper } from '../../../../../Shared/Forms/helpers/createInputHelper';
import { nextQuestionId, jumpTypeField } from '../../../helpers/formHelper';
import { transform } from '../../../helpers/questionnaireBuilderMapper';
import { QuestionnaireBuilderContext } from '../../../QuestionnaireBuilderWrapper';
import ConditionList from '../Condition/ConditionList';
import MedicalCode from '../MedicalCode/MedicalCode';

function Jump(props) {
  const {
    jump,
    jumpIndex,
    removeJump,
    setNextQuestionId,
    currentNode,
    isNonConditional,
    updateQuestionNodes,
    nodeIndex,
    addCoding,
    removeMedicalCode,
    editCoding,
  } = props;

  const { nodes } = useContext(QuestionnaireBuilderContext);

  const [jumpType, setJumpType] = useState(() => {
    if (jump.rules[0] && jump.rules[0].type === 'variable') return 'Variable';
    if (!jump.rules.length || isNonConditional) return 'Non-Conditional';
    if (jump.rules[0] && jump.rules[0].hasOwnProperty('score')) return 'Score';
    return 'Conditional';
  });
  const [editingJumpCoding, setEditingJumpCoding] = useState(false);

  const openEditingCoding = () => {
    setEditingJumpCoding(!editingJumpCoding);
    addCoding(jumpIndex);
  };

  const getQuestionType = (type) => {
    if (type === 'large-text' || type === 'small-text') return 'text';
    return type;
  };

  const handleChangeJumpType = (type, value) => {
    if (value === 'Conditional') {
      if (isNonConditional) return;

      const path = `[${nodeIndex}].rules[${jumpIndex}].rules[${0}]`;
      updateQuestionNodes(path, () => ({
        type: currentNode && currentNode.question && currentNode.question.type ? getQuestionType() : null,
        questionId: currentNode && currentNode.question && currentNode.question.id,
        expectedAnswers: [],
        precision: currentNode && currentNode.question && currentNode.question.precision,
      }));

      if (currentNode.question.autoAnswerOption) {
        updateQuestionNodes('question', (data) => ({
          ...data,
          autoAnswerOption: null,
        }));
      }
    } else if (value === 'Score') {
      if (isNonConditional) return;

      const path = `[${nodeIndex}].rules[${jumpIndex}].rules[${0}]`;
      updateQuestionNodes(path, () => ({
        type: 'score',
        questionId: currentNode && currentNode.question && currentNode.question.id,
        score: 0,
        operation: null,
      }));

      if (currentNode.question.autoAnswerOption) {
        updateQuestionNodes('question', (data) => ({
          ...data,
          autoAnswerOption: null,
        }));
      }
    } else if (value === 'Variable') {
      const path = `[${nodeIndex}].rules[${jumpIndex}].rules[${0}]`;
      updateQuestionNodes(path, () => ({
        type: 'variable',
        variableName: '',
        expectedText: '',
        operation: null,
      }));
    } else if (value === 'Non-Conditional') {
      const path = `[${nodeIndex}].rules`;
      updateQuestionNodes(path, () => []);
    }
    setJumpType(value);
  };

  return (
    <Paper style={{ padding: 10, marginTop: 30 }}>
      <Grid container spacing={3}>
        <Grid item xs={2}>
          <Box style={{ display: 'flex' }}>
            <Typography style={{ marginTop: 7 }} variant="h6">
              Jump {jumpIndex + 1}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={8}>
          {createInputHelper(
            jumpTypeField(
              currentNode.question && currentNode.question.type ? currentNode.question.type : null,
              isNonConditional
            ),
            { jumpType },
            () => ({}),
            (type, value) => handleChangeJumpType(type, value)
          )}
        </Grid>
        <Grid item xs={2}>
          {jumpType === 'Conditional' && (
            <Tooltip title="Add medical code">
              <IconButton onClick={openEditingCoding} disabled={jump.coding}>
                <MedicalCodes />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="Remove jump">
            <IconButton onClick={() => removeJump(jumpIndex)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      {jump.coding && (
        <MedicalCode
          coding={jump.coding}
          removeMedicalCode={() => removeMedicalCode(jumpIndex)}
          editCoding={() => editCoding(jumpIndex)}
        />
      )}
      {jumpType === 'Conditional' || jumpType === 'Score' || jumpType === 'Variable' ? (
        <>
          <ConditionList
            jump={jump}
            jumpIndex={jumpIndex}
            currentNode={currentNode}
            updateQuestionNodes={updateQuestionNodes}
            nodeIndex={nodeIndex}
            jumpType={jumpType}
          />
          {createInputHelper(
            nextQuestionId(transform.nodes(nodes)),
            { nextQuestionId: jump.nextQuestionId },
            () => {},
            (name, value) => setNextQuestionId(value, jumpIndex)
          )}
        </>
      ) : null}
    </Paper>
  );
}

export default Jump;
