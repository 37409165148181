import Typography from '@material-ui/core/Typography';
import { Input } from '@progress/kendo-react-inputs';
import React, { useEffect, useState } from 'react';

// import utils from '../../Utils/utils';
import { emailRegex } from '../helpers/contants';

// ui reflection of file view
function EmailInput(props) {
  const { field, initialValue, handleChangeWithParams, disabled, selectedLanguage } = props;
  const [emailValue, setEmailValue] = useState(initialValue || '');
  const [valid, setValid] = useState(true);

  const re = new RegExp(field.pattern || emailRegex);

  useEffect(() => {
    if (initialValue) {
      if (initialValue !== emailValue) {
        setEmailValue(initialValue);
      }

      if (!field.pattern.test(initialValue)) {
        setValid(false);
      }
    }
    if (!initialValue && field.required) {
      setValid(false);
    }
    // eslint-disable-next-line
  }, [initialValue, setValid, field]);

  const handleChangeEmail = (e) => {
    let newValue = e.target.value;
    const { name } = e.target;

    if (newValue && re.test(newValue)) {
      setValid(true);
    } else if (!newValue) {
      setValid(true);
      newValue = '';
    } else {
      setValid(false);
    }

    handleChangeWithParams(name, newValue.trim());
    setEmailValue(newValue);
  };

  const fieldLabel =
    selectedLanguage && field.name && field.name.translations
      ? field.name.translations[selectedLanguage]
      : field.label || field.name;

  const questionDescription =
    selectedLanguage && field.description && field.description.translations
      ? field.description.translations[selectedLanguage]
      : field.description;

  return (
    <>
      {field.questionPreview ? (
        <Typography
          style={{
            opacity: 0.5,
            marginBottom: 10,
          }}
          color={field.required && !emailValue ? 'error' : 'inherit'}
        >
          {questionDescription}
        </Typography>
      ) : null}
      <Input
        autoComplete="off"
        width="100%"
        value={emailValue}
        onChange={handleChangeEmail}
        required={field.required && !emailValue}
        label={field.questionPreview ? 'Email' : fieldLabel}
        name={field.name}
        maxLength={field.maxLength}
        valid={valid}
        // type={"email"}
        style={{ width: '100%' }}
        disabled={field.disabled || disabled}
        validationMessage="Email format is not valid"
        pattern={field.pattern}
      />
    </>
  );
}

export default EmailInput;
