import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import React, { useEffect, useState } from 'react';
import ProfileViewStyles from '../../assets/jss/components/ProfileViewStyles';

const disabledCustomFields = ['User Segment'];

function ProfileMetadata(props) {
  const { userDetails, onUpdateUser, metaDataList, classes, xs } = props;
  const [state, setState] = useState({});
  const [metadata, setMetadata] = useState({});
  const [initialMetadata, setInitialMetadata] = useState({});

  // on update of userDetails, set states of userDetials and metadata
  useEffect(() => {
    if (userDetails) {
      const metaDataByCustomFields = {};
      metaDataList.forEach((item) => {
        metaDataByCustomFields[item.key] = userDetails?.metadata?.[item.key] ? userDetails.metadata[item.key] : '';
      });

      setMetadata(metaDataByCustomFields);
      setInitialMetadata(metaDataByCustomFields);

      // keep old user metadata - fingerprint, _auth0_user_id, User Segment etc.
      setState(userDetails);
    }
  }, [metaDataList, userDetails]);

  // on update of metadata state, call update user api with new metadata
  useEffect(() => {
    if (state && state.id && JSON.stringify(metadata) !== JSON.stringify(initialMetadata)) {
      // add old user metadata - fingerprint, _auth0_user_id, User Segment etc.
      const metaDataWithOldValues = { ...userDetails.metadata, ...metadata };

      const updatedState = {
        ...state,
        metadata: metaDataWithOldValues,
      };
      onUpdateUser(updatedState);
    }
  }, [state, metadata, initialMetadata, userDetails.metadata, onUpdateUser]);

  // trigger blur of event to update details on enter
  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.target.blur();
    }
  };

  const handleUpdateMetadata = (keyword, keywordValue) => {
    setMetadata({
      ...metadata,
      [keyword]: keywordValue,
    });
  };

  return (
    <Grid container>
      {Object.keys(metadata).map((keyword, key) => {
        if (disabledCustomFields.includes(keyword)) return null;
        return (
          // eslint-disable-next-line react/no-array-index-key
          <Grid item xs={xs} key={key} style={{ padding: '20px 50px 20px 0' }} className={classes.inputContainer}>
            <TextField
              label={keyword}
              name={keyword}
              margin="dense"
              defaultValue={metadata[keyword]}
              onBlur={(e) => handleUpdateMetadata(keyword, e.target.value)}
              onKeyPress={(e) => onKeyPress(e)}
              autoComplete="off"
              type="text"
              fullWidth
            />
          </Grid>
        );
      })}
    </Grid>
  );
}

export default withStyles(ProfileViewStyles)(ProfileMetadata);
