/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-expressions */
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { useTheme, withStyles, makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Redux

// helpers, components, and styling imports
import ProfileViewStyles from '../../assets/jss/components/ProfileViewStyles';

// material-ui

// kendo-ui
import { getUserFromToken } from '../Auth/helpers';
import { showConfirmation } from '../Confirmation/confirmationSlice';
import { selectLanguageContent } from '../LanguageMenu/selectors';
import { selectMetaDataList, selectTenantNetwork } from '../Networks/selectors';
import { showNotification } from '../Notifications/notificationSlice';
import { createInputHelper } from '../Shared/Forms/helpers/createInputHelper';
import { countryList, stateList } from '../Shared/Forms/helpers/stateCountryHelper';
import PhoneInputField from '../Shared/Forms/KendoFields/PhoneInputField';
import { selectCurrentTenant } from '../Shared/selectors';
import utils from '../Shared/Utils/utils';
import { roleMappings } from '../Users/helpers/mappings';
import {
  selectCurrentUserPermissions,
  selectGuardianInfo,
  selectUserDetails,
  selectUserPermissions,
} from '../Users/selectors';
import {
  toggleAnalyticsField,
  updateUserPermissions,
  getGuardianInfo,
  updateGuardianInfo,
  createGuardianInfo,
  phoneNumberLookup,
  getUser,
  updateUser, updateUserContact,
} from '../Users/usersSlice';

import { selectUserSegments } from '../VirtualConsult/selectors';
import { countryIncludes, formSelects } from './helpers/mappings';
import {
  sections,
  birthDateForm,
  genderEnums,
  relationshipEnums,
  limitSexDialog,
  requiredFields,
  restrictedFields,
  raceEnums,
  ethnicityEnums,
  guardianEnums,
  clearedUntilDate,
  sidelinedUntilDate,
  titleByName,
  addressProps,
} from './ProfileHelper';
import ProfileMetadata from './ProfileMetadata';
import { ProfileInfoSkeletonLoading } from './ProfileSkeletonLoading';

function ProfileInformation(props) {
  const { classes, refreshUserDetails } = props;

  const currentUser = useSelector(selectCurrentUserPermissions);
  const metaDataList = useSelector(selectMetaDataList);
  const guardianInfo = useSelector(selectGuardianInfo);
  const i18n = useSelector(selectLanguageContent);
  const tenantSetting = useSelector(selectTenantNetwork);

  const currentTenant = useSelector(selectCurrentTenant);
  const userDetails = useSelector(selectUserDetails);

  const userSegments = useSelector(selectUserSegments);

  const userPermissions = useSelector(selectUserPermissions);
  const user = getUserFromToken();

  // const requiredFields = ["firstName", "lastName"];
  const [editing, setEditing] = useState({});
  const [fieldError, setFieldError] = useState('');
  const [state, setState] = useState({});
  const [mobileNumber, setMobileNumber] = useState(null);
  const [mobileCountryCode, setMobileCountryCode] = useState(null);
  const [draft, setDraft] = useState({});
  const [metadata, setMetadata] = useState({});
  // const [userDetails, setUserDetails] = useState(initialUserDetails);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  let tabEvent;

  const dispatch = useDispatch();

  useEffect(() => {
    // get user details if was clicked on Account info tab
    if (refreshUserDetails) {
      (async () => {
        await dispatch(getUser(userDetails.email, true, false));
      })();
    }
  }, [refreshUserDetails]);

  useEffect(() => {
    setState({ ...userDetails });
  }, [userDetails]);

  useEffect(() => {
    if (userDetails) {
      setMetadata(userDetails.metadata);
      setState({ ...userDetails });
      if (userDetails.isGuardianMode) {
        dispatch(getGuardianInfo(userDetails._id));
      }
    }
  }, [userDetails]);

  // on update of metadata state, call update user api with new metadata
  useEffect(() => {
    if (state && state.accountId && JSON.stringify(metadata) !== JSON.stringify(state.metadata)) {
      const updatedState = {
        ...state,
        metadata,
      };
      handleUpdateUser(updatedState);
    }
  }, [state, metadata]);

  const isRestricted = (field, currentUser, userProfile) => {
    // only restrict dob and sex if not own profile
    if (currentUser?.userId !== userProfile.email) {
      return restrictedFields.includes(field) && currentUser?.permission !== 'ADMINISTRATOR';
    }
  };

  const handleUpdateUser = (state) => {
    dispatch(updateUser(state, true));
  };

  const handleInfoDialog = () => {
    dispatch(
      showConfirmation(
        'Why Limit Sex',
        // eslint-disable-next-line
        "HIPAA requires that healthcare providers have a record of the sex assigned at birth. We don't make the rules, just have to follow them.",
        'info'
      )
    );
  };

  const handleUpdateMetadata = (e) => {
    const value = e.target.value.label;

    setMetadata({
      ...metadata,
      'User Segment': value,
    });
  };

  // handles permissions change
  const handleChangePermission = (e) => {
    const value = e.target.value.id;
    dispatch(updateUserPermissions(currentTenant && currentTenant.id, value, userDetails && userDetails.email));
    setEditing({ 'User Permissions': false });
  };

  // handles blur for all fields
  const handleBlur = (e, item1, item2, type) => {
    if (type === 'number') {
      handlePhoneBlur(e, item1, item2);
    } else {
      updateField(e, item1);
    }
  };

  // handles phone number with country code dropdown blur
  const handlePhoneBlur = async (e, countryColumn, numberColumn) => {
    // gets country code and phone number from state
    const countryInfo = countryColumn.split('.');
    const numberInfo = numberColumn.split('.');
    let contact = state[numberInfo[0]] ? JSON.parse(JSON.stringify(state[numberInfo[0]])) : {};
    if (contact.contact) contact = contact.contact;
    const countryCode = contact[countryInfo[countryInfo.length - 1]] || '';
    const number = contact[numberInfo[numberInfo.length - 1]] || '';
    const countryId = countryInfo[countryInfo.length - 1];
    const numberId = numberInfo[numberInfo.length - 1];

    // checks to see if dropdown isn't clicked
    // checks to see if changes were made to phone number
    // if not clicked and changes made, updates values
    if (!e.relatedTarget) {
      setEditing({ [numberColumn]: false });
      if (e.target.value.replace(/[^0-9]/gm, '') !== countryCode.replace(/[^0-9]/gm, '') + number) {
        // checks to see if there is no value
        // creates the updated contact object
        const updatedContact =
          e.target.value === '+' || e.target.value === ''
            ? { ...contact, [countryId]: '', [numberId]: '' }
            : {
              ...contact,
              [countryId]: mobileCountryCode,
              [numberId]: mobileNumber,
            };

        // checks if mobile number belongs to emergencyContact
        // creates the updated state object
        const updatedState = numberColumn.includes('emergencyContact')
          ? {
            ...state,
            emergencyContact: {
              ...state.emergencyContact,
              contact: updatedContact,
            },
          }
          : { ...state, contact: updatedContact };

        if (!numberColumn.includes('emergencyContact')) {
          // const fullNumber = mobileCountryCode.replace(/[^0-9]/gm, '') + mobileNumber
          const phoneWithCode = e.target.value.replace(/[^0-9]/gm, '')

          const res = await dispatch(phoneNumberLookup(phoneWithCode, currentTenant.accountId))
          if (res) {
            const updatedContactData = {
              _id: state._id,
              tenantId: currentTenant.id,
              contact: {
                // faxCode: "",
                // faxNumber: "",
                // homeCountryCode: "",
                // homeNumber: "",
                // mobileCountryCode: "+381",
                // mobileNumber: "612107060",
                // workCountryCode: "",
                // workNumber: ""

                ...contact,
                [countryId]: `+${mobileCountryCode}`,
                [numberId]: mobileNumber,
              }
            }
            dispatch(updateUserContact(updatedContactData));
            // setState({ ...updatedState, contact: updatedContactData.contact});
            setState({ ...updatedState});
          }
        } else {
          // disable phone number lookup when mobile number is blank
          const res = mobileNumber.length
            ? await dispatch(phoneNumberLookup(mobileNumber, currentTenant.accountId))
            : true;

          if (res) {
            // updates state and user with new state object
            setState({ ...updatedState });
            handleUpdateUser(updatedState);
          }
        }


      }
    }
  };

  // handles all text fields including nested items
  const updateField = (e, keyName) => {
    const tempState = JSON.parse(JSON.stringify(state));
    let id = e.target.name;
    let { value } = e.target;

    setFieldError('');
    if (requiredFields.includes(keyName) && !value) {
      return setFieldError('Required');
    }

    if (id === 'state') {
      value = stateList.find((s) => s.name === e.target.value).abbreviation;
    } else if (addressProps.includes(id)) {
      if (value !== '' && (!value.match('^[A-z0-9-]{1,30}$') || value.length < 5)) {
        value = '---';
        setDraft({
          ...draft,
          [keyName]: utils.getPropertyByName(keyName, state),
        });
        dispatch(showNotification('Zip code did not update due to invalid formatting.', 'error'));
      }
    }
    if (value !== undefined && value !== '---') {
      const nested = keyName.split('.');
      let location;
      if (nested.length === 1) {
        location = state[nested[0]];
      } else {
        if (tempState[nested[0]] === null || tempState[nested[0]] === undefined) {
          tempState[nested[0]] = {};
        }
        if (nested.length === 2) {
          location = tempState[nested[0]][nested[1]];
        } else {
          if (tempState[nested[0]][nested[1]] === null || tempState[nested[0]][nested[1]] === undefined) {
            tempState[nested[0]][nested[1]] = {};
          }
          location = tempState[nested[0]][nested[1]][nested[2]];
        }
      }

      if (value !== location) {
        if (keyName.indexOf('.') > -1) {
          const keyParts = keyName.split('.');
          const newValue = tempState[keyParts[0]] ? JSON.parse(JSON.stringify(tempState[keyParts[0]])) : {};
          let currentValue = newValue;
          keyParts.forEach((key, idx) => {
            if (idx === 0 && tempState[key]) {
              // currentValue[key] = {...state[key]};
            } else if (idx > 0) {
              if (idx === keyParts.length - 1) {
                currentValue[key] = value;
              } else {
                currentValue[key] = currentValue[key] || {};
                currentValue = currentValue[key];
              }
            }
          });

          id = keyName;
          value = newValue;
        }
        finishUpdate(keyName.split('.')[0], id, value);
      } else {
        value !== keyName ? (id = keyName) : (id = e.target.name);
        setEditing({ [id]: false });
      }
    } else {
      value !== keyName ? (id = keyName) : (id = e.target.name);
      setEditing({ [id]: false });
    }
    setState({ ...tempState });
  };

  // handles unique dropdown menu selections including nested selections
  const updateDropdown = (option, poppedName, id) => {
    let value;

    // if state, find abbreviation to save instead of full name
    if (poppedName === 'state') {
      stateList.forEach((n) => {
        if (option === n.name) {
          option = n.abbreviation;
        }
      });
    }
    if (id.indexOf('.') > -1) {
      const keyParts = id.split('.');
      const newValue = state[keyParts[0]] ? JSON.parse(JSON.stringify(state[keyParts[0]])) : {};
      let currentValue = newValue;
      keyParts.forEach((key, idx) => {
        if (idx > 0) {
          if (idx === keyParts.length - 1) {
            currentValue[key] = option;
          } else {
            currentValue[key] = currentValue[key] || {};
            currentValue = currentValue[key];
          }
        }
      });
      value = newValue;
    } else {
      value = option;
    }

    if (id === 'gender' && limitSexDialog.includes(value)) {
      handleInfoDialog();
      return;
    }

    finishUpdate(id.split('.')[0], id, value);
  };

  // the function that finishes a user field/dropdown update
  const finishUpdate = (root, id, value) => {
    setEditing({ [id]: false });
    if (value || value === '' || value === null) {
      if (root === 'relatedPersonFirstName' || root === 'relatedPersonLastName' || root === 'relationship') {
        if (guardianInfo.data) {
          if (value !== '') dispatch(updateGuardianInfo({ ...guardianInfo.data, [root]: value }));
        } else if (value !== '') dispatch(createGuardianInfo({ [root]: value, userId: state._id }));
      } else {
        const newState = { ...state, [root]: value };
        const updatedState = newState;

        // call update endpoint if there is a change in state
        if (JSON.stringify(updatedState) !== JSON.stringify(state)) {
          setState({ ...updatedState });
          handleUpdateUser(updatedState);
        }
      }
    }
  };

  // the function that finishes a user field/dropdown update
  // the only function passed to the date field
  const finishUpdateBirthDate = (root, id, value) => {
    setEditing({ [id]: false });
    if (value || value === '' || value === null) {
      // handle date of birth field
      if (
        (!birthDateForm[0].min || birthDateForm[0].min < value) &&
        (!birthDateForm[0].max || value < birthDateForm[0].max)
      ) {
        // update state with no locale value if date, or null if no date
        const updatedState = {
          ...state,
          [id]: value instanceof Date ? utils.formatDateNoLocale(value) : null,
        };
        // change to UTC string to compare if there has been a change to call API or not when focus to blur
        const stringValue = value instanceof Date ? JSON.stringify(value.toUTCString()) : value;
        const stringState = JSON.stringify(
          state && state.birthDate ? new Date(state.birthDate.split('T')[0].split('-').join('/')).toUTCString() : ''
        );
        // call update endpoint if there is a change in state
        if (stringValue !== stringState) {
          handleUpdateUser(updatedState);
        }
        return;
      }
      dispatch(showNotification('Date of Birth did not update due to invalid date entry.', 'error'));
    }
  };

  // the function that finishes a user field/dropdown update
  const finishUpdateClearedSidelinedDates = (root, id, value) => {
    setEditing({ [id]: false });
    if (value || value === '' || value === null) {
      // update state with no locale value if date, or null if no date
      const updatedState = {
        ...state,
        memberOverrideDates: {
          ...state.memberOverrideDates,
          [id]: value instanceof Date ? utils.formatDateNoLocale(value) : null,
        },
      };
      // change to UTC string to compare if there has been a change to call API or not when focus to blur
      const stringValue = value instanceof Date ? JSON.stringify(value.toUTCString()) : value;
      const stringState = JSON.stringify(
        state && state?.memberOverrideDates[id]
          ? new Date(state?.memberOverrideDates[id].split('T')[0].split('-').join('/')).toUTCString()
          : ''
      );
      // call update endpoint if there is a change in state
      if (stringValue !== stringState) {
        handleUpdateUser(updatedState);
      }
    }
  };

  // handles phone number with country code dropdown changes
  const handlePhoneChange = (value, data, numberColumn) => {
    // checks to see if length of number + country code is longer than 15
    if (value.length > 16) {
      setEditing({ [numberColumn]: false });
      dispatch(showNotification('Phone number did not update due to length. Shorten and try again.', 'error'));
    } else {
      // removes country code from number if it exists
      setMobileNumber(data && data.dialCode ? value.slice(data.dialCode.length) : value);
      // if no country selected sets to empty string
      setMobileCountryCode(data && (data.dialCode || ''));
    }
  };

  // trigger blur of event to update details on enter
  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.target.blur();
    }
  };

  const handleTabbedField = (e, id) => {
    const allFields = sections.flat();
    let index = allFields.indexOf(id) + 1;
    // checks to see if all fields are tabbed through
    // skips email, country code, and restricted fields
    // if last tab, goes to next section
    if (allFields.length !== index) {
      if (allFields[index].includes('CountryCode') || allFields[index] === 'email') {
        index += 1;
      } else if (isRestricted(allFields[index], currentUser, userDetails)) {
        index += 2;
      }
      if (e.key === 'Tab') {
        e.preventDefault();
        document.removeEventListener('keydown', tabEvent, true);
        e.target.blur();
        handleFocus(allFields[index]);
      }
    }
  };

  // on focus of editable fields, trigger editing state
  const handleFocus = (id) => {
    if (userDetails.parentIds && userDetails.parentIds.length) return;
    setEditing({ [id]: true });
  };

  // pass in the column array and the desired column width
  // for example: 6 for two sections or 4 for three sections
  const createListItems = (column, width, section) => {
    const listItems = [];

    for (let i = 0; i < column.length; i++) {
      const nameParts = column[i].split('.');
      const poppedName = nameParts.pop();

      // section headers
      let title;
      if (poppedName.includes('CountryCode')) {
        title = titleByName['countryCode'];
      } else if (titleByName[poppedName]) {
        title = titleByName[poppedName];
      } else if (titleByName['relationship'] && section == 'Guardian') {
        title = titleByName[poppedName];
      } else {
        title = poppedName.charAt(0).toUpperCase() + poppedName.slice(1).replace(/([a-z])([A-Z])/g, '$1 $2');
      }

      // section values
      let value;
      let localeValue;
      if (poppedName === 'birthDate' && userDetails?.birthDate) {
        value = utils.formatDate(state[column[i]]) || '---';
      } else if (
        (poppedName === 'clearedUntil' && userDetails?.memberOverrideDates?.clearedUntil) ||
        (poppedName === 'sidelinedUntil' && userDetails?.memberOverrideDates?.sidelinedUntil)
      ) {
        value = utils.formatDate(state?.memberOverrideDates?.[column[i]]) || '---';
      } else if (column[i].includes('.state')) {
        // if state, get full name to display from the saved abbr
        stateList.forEach((n) => {
          if (utils.getPropertyByName(column[i], state) === n.name) {
            value = n.name;
          } else if (utils.getPropertyByName(column[i], state) === n.abbreviation) {
            value = n.name;
          } else if (!n.abbreviation) {
            value = '---';
          }
        });
      } else if (poppedName === 'relatedPersonFirstName') {
        value = guardianInfo?.data?.relatedPersonFirstName;
      } else if (poppedName === 'relatedPersonLastName') {
        value = guardianInfo?.data?.relatedPersonLastName;
      } else if (poppedName === 'relationship' && section == 'Guardian') {
        value = guardianInfo?.data?.relationship;
      } else if (poppedName === 'lastTestDate') {
        value =
          state?.infectionTestDates?.length > 0 && state?.infectionTestDates[0]?.lastTest
            ? utils.formatDate(state?.infectionTestDates[0]?.lastTest)
            : '---';
      } else if (poppedName === 'nextTestDate' && state.infectionTestDates?.length > 0) {
        value =
          state?.infectionTestDates?.length > 0 && state?.infectionTestDates[0]?.nextTest
            ? utils.formatDate(state?.infectionTestDates[0]?.nextTest)
            : '---';
      } else {
        value = utils.getPropertyByName(column[i], state) || '---';
      }

      if (poppedName === 'race' && value !== '---' && !editing[column[i]]) {
        localeValue = utils.getTranslation(i18n, raceEnums, 'value', 'label', value);
      } else if (poppedName === 'ethnicity' && value !== '---' && !editing[column[i]]) {
        localeValue = utils.getTranslation(i18n, ethnicityEnums, 'value', 'label', value);
      }

      let field;
      if (editing[column[i]]) {
        tabEvent = (e) => handleTabbedField(e, column[i]);
        document.addEventListener('keydown', tabEvent, true);

        if (formSelects.includes(title)) {
          field = (
            <FormControl fullWidth>
              <Select
                labelId="dropdownList"
                id="dropdownList"
                value={value}
                name={column[i]}
                labelWidth={100}
                MenuProps={{ disableScrollLock: true }}
                onChange={(e) => {
                  // Clears out zip code field when changing countries
                  if (title === 'Country') {
                    const fieldParent = column[i].replace('.country', '');
                    const zipVal = () => {
                      if (state.emergencyContact != null && fieldParent.startsWith('emergencyContact')) {
                        setState((prevState) => ({
                          ...prevState,
                          emergencyContact: {
                            ...prevState.emergencyContact,
                            address: {
                              ...prevState.emergencyContact.address,
                              zip: '',
                            },
                          },
                        }));
                      } else if (state[fieldParent] !== undefined && state[fieldParent] !== null) {
                        setState((prevState) => ({
                          ...prevState,
                          primaryAddress: {
                            ...prevState.primaryAddress,
                            address: {
                              ...prevState.primaryAddress.address,
                              zip: '',
                            },
                          },
                        }));
                      }
                    };
                    zipVal();
                  }
                  updateDropdown(e.target.value, poppedName, column[i]);
                }}
                onBlur={(e) => handleBlur(e, column[i])}
                autoFocus
              >
                {title === 'Sex' &&
                genderEnums.map((option) => (
                  <MenuItem value={option.code} key={option.code}>
                    {option.code}
                  </MenuItem>
                ))}
                {title === 'Relationship' &&
                relationshipEnums.map((option) => (
                  <MenuItem value={option} key={option}>
                    {utils.capitalizeString(option)}
                  </MenuItem>
                ))}
                {title === 'Guardian Relation' &&
                guardianEnums.map((option) => (
                  <MenuItem value={option.value} key={option.value}>
                    {utils.capitalizeString(option.label)}
                  </MenuItem>
                ))}
                {(title === 'State' || title === 'Birth State') &&
                stateList.map((option) => (
                  <MenuItem value={option.name} key={option.abbreviation}>
                    {option.name}
                  </MenuItem>
                ))}
                {title === 'Country' &&
                countryList.map((option) => (
                  <MenuItem value={option} key={option}>
                    {option}
                  </MenuItem>
                ))}
                {title === 'Race' &&
                raceEnums.map((option) => (
                  <MenuItem value={option.value} key={option.value}>
                    {i18n[option.label]}
                  </MenuItem>
                ))}
                {title === 'Ethnicity' &&
                ethnicityEnums.map((option) => (
                  <MenuItem value={option.value} key={option.value}>
                    {i18n[option.label]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          );
        } else if (title === 'Date of Birth') {
          field = createInputHelper(
            birthDateForm,
            {
              birthDate: userDetails?.birthDate,
            },
            null,
            (name, value) => {
              finishUpdateBirthDate(name, name, value);
            }
          );
        } else if (title === 'Cleared Until Date') {
          field = createInputHelper(
            clearedUntilDate,
            {
              clearedUntil: userDetails?.memberOverrideDates?.clearedUntil,
            },
            null,
            (name, value) => {
              finishUpdateClearedSidelinedDates(name, name, value);
            }
          );
        } else if (title === 'Sidelined Until Date') {
          field = createInputHelper(
            sidelinedUntilDate,
            {
              sidelinedUntil: userDetails?.memberOverrideDates?.sidelinedUntil,
            },
            null,
            (name, value) => {
              finishUpdateClearedSidelinedDates(name, name, value);
            }
          );
        } else if (title === 'Next Test Date' || title === 'Last Test Date') {
          field = <Typography variant="body1">{value}</Typography>;
        } else if (title.split(' ').includes('Phone')) {
          // gets country code and phone number from state
          const countryInfo = column[i - 1].split('.');
          const phoneInfo = column[i].split('.');
          let contact = state[phoneInfo[0]] ? JSON.parse(JSON.stringify(state[phoneInfo[0]])) : {};
          if (contact.contact) contact = contact.contact;
          const countryCode = contact[countryInfo[countryInfo.length - 1]] || '';
          const number = contact[phoneInfo[phoneInfo.length - 1]] || '';

          // value must have a country code and a number passed to work
          // set autoFormat to true to enable custom country-specific masking
          field = (
            <PhoneInputField
              type="editing"
              classes={classes}
              column={column}
              i={i}
              countryCode={countryCode}
              number={number}
              handleChange={handlePhoneChange}
              handleBlur={handleBlur}
              onKeyPress={onKeyPress}
            />
          );
        } else if (title === 'Zip') {
          // Changes what characters are allowed in zip code input depending on which country is selected
          const fieldParent = column[i].replace('.zip', '');
          let countryVal = null;

          if (fieldParent.startsWith('emergencyContact') && state.emergencyContact && state.emergencyContact.address) {
            countryVal = state.emergencyContact.address.country;
          } else if (state[fieldParent] !== null && state[fieldParent] !== undefined) {
            countryVal = state[fieldParent].country;
          }

          const countryCondition = countryIncludes.includes(countryVal);

          field = (
            <TextField
              autoFocus
              name={column[i]}
              inputProps={{
                maxLength: countryCondition ? 5 : 20,
              }}
              // eslint-disable-next-line no-return-assign
              onInput={countryCondition ? (e) => (e.target.value = e.target.value.replace(/[^0-9]/, '')) : undefined}
              fullWidth
              defaultValue={utils.getPropertyByName(column[i], state)}
              autoComplete="off"
              onKeyPress={(e) => onKeyPress(e)}
              onBlur={(e) => handleBlur(e, column[i])}
              onChange={(e) => setDraft({ ...draft, [column[i]]: e.target.value })}
            />
          );
        } else {
          field = (
            <TextField
              inputProps={{
                maxLength: title === 'City' ? 50 : undefined,
              }}
              error={requiredFields.includes(column[i]) && fieldError}
              helperText={requiredFields.includes(column[i]) && fieldError}
              autoFocus
              name={column[i]}
              onBlur={(e) => handleBlur(e, column[i])}
              onKeyPress={(e) => onKeyPress(e)}
              defaultValue={utils.getPropertyByName(column[i], state)}
              autoComplete="off"
              required={column[i] === 'firstName' || column[i] === 'lastName'}
              fullWidth
            />
          );
        }
      } else if (column[i] === 'email') {
        field = (
          <Typography
            variant="body1"
            className={!isRestricted(column[i], currentUser, userDetails) && classes.editHover}
          >
            {isRestricted(column[i], currentUser, userDetails) ? '---' : localeValue || value || '---'}
          </Typography>
        );
      } else if (
        column[i].split('.').includes('mobileNumber') ||
        column[i].split('.').includes('workNumber') ||
        column[i].split('.').includes('homeNumber')
      ) {
        // gets country code and phone number from state

        const countryInfo = column[i - 1].split('.');
        const phoneInfo = column[i].split('.');
        let contact = state[phoneInfo[0]] ? JSON.parse(JSON.stringify(state[phoneInfo[0]])) : {};
        if (contact.contact) contact = contact.contact;
        const countryCode = contact[countryInfo[countryInfo.length - 1]] || '';
        const number = contact[phoneInfo[phoneInfo.length - 1]] || '';

        field = (
          <Tooltip title={!(userDetails.parentIds && userDetails.parentIds.length) ? 'Click to Edit' : ''}>
            <div onClick={() => handleFocus(column[i])}>
              <PhoneInputField classes={classes} countryCode={countryCode} number={number}/>
            </div>
          </Tooltip>
        );
      } else {
        field = (
          <div>
            <Tooltip
              title={
                !isRestricted(column[i], currentUser, userDetails) &&
                !(userDetails.parentIds && userDetails.parentIds.length)
                  ? 'Click to Edit'
                  : ''
              }
            >
              <Typography
                variant="body1"
                className={!isRestricted(column[i], currentUser, userDetails) && classes.editHover}
                onClick={() => !isRestricted(column[i], currentUser, userDetails) && handleFocus(column[i])}
              >
                {isRestricted(column[i], currentUser, userDetails) ? '---' : localeValue || value || '---'}
              </Typography>
            </Tooltip>
          </div>
        );
      }

      if (title !== 'countryCode' && !isRestricted(column[i], currentUser, userDetails)) {
        listItems.push(
          <Grid item xs={width} key={i} className={classes.inputContainer}>
            <Box>
              {/* item header */}
              <InputLabelComponent label={title} customStyles={{ paddingBottom: '5px' }}/>
              {/* item value */}
              {field}
            </Box>
          </Grid>
        );
      }
    }
    return listItems;
  };

  const contactWidth = window.outerWidth < 860 ? 12 : 4;

  let permissionsBoolean;
  const pathname = window.location.pathname.split('/');
  if (pathname[2] === 'profile') {
    permissionsBoolean = false;
  } else if (pathname[4] === user.name) {
    permissionsBoolean = false;
  } else {
    permissionsBoolean = true;
  }

  if (!userDetails) return <ProfileInfoSkeletonLoading/>;

  return (
    <Grid className={classes.infoBoxesUpdated}>
      <SectionComponent label="New User">
        {matches ? createListItems(sections[0], 4) : createListItems(sections[0], 12)}
      </SectionComponent>
      <SectionComponent label="Status">
        {matches ? createListItems(sections[7], 4) : createListItems(sections[7], 12)}
      </SectionComponent>
      <SectionComponent label="Settings">
        <Grid item xs={12} md={4} style={{ padding: '16px 0' }}>
          {!editing['User Permissions'] || editing['User Permissions'] === false ? (
            <div className={classes.inputContainer}>
              <InputLabelComponent label="Role"/>
              {permissionsBoolean ? (
                <Tooltip title={!(userDetails.parentIds && userDetails.parentIds.length) ? 'Click to Edit' : ''}>
                  <Typography
                    variant="body1"
                    style={{ marginTop: '10px' }}
                    className={classes.editHover}
                    onClick={() => handleFocus('User Permissions')}
                  >
                    {' '}
                    {userPermissions && roleMappings.find((r) => r.id === userPermissions.permission)?.label}
                  </Typography>
                </Tooltip>
              ) : (
                <Typography variant="body1" style={{ marginTop: '10px' }} className={classes.noEdit}>
                  {' '}
                  {userPermissions && roleMappings.find((r) => r.id === userPermissions.permission)?.label}
                </Typography>
              )}
            </div>
          ) : (
            <div className={classes.inputContainer}>
              <InputLabelComponent label="Role"/>
              <DropDownList
                name="User Permission"
                data={roleMappings}
                onChange={handleChangePermission}
                required
                value={userPermissions && roleMappings.find((r) => r.id === userPermissions.permission)}
                textField="label"
                style={{
                  width: '100%',
                  fontSize: '14px',
                  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                  fontWeight: 'lighter',
                }}
              />
            </div>
          )}
        </Grid>
        <Grid item xs={12} md={4} style={{ padding: '16px 0' }}>
          {!editing['User Segment'] || editing['User Segment'] === false ? (
            <div className={classes.inputContainer}>
              <InputLabelComponent label="User Segment"/>
              {permissionsBoolean ? (
                <Tooltip title={!(userDetails.parentIds && userDetails.parentIds.length) ? 'Click to Edit' : ''}>
                  <Typography
                    variant="body1"
                    style={{ marginTop: '10px' }}
                    className={classes.editHover}
                    onClick={() => handleFocus('User Segment')}
                  >
                    {metadata?.['User Segment'] || '---'}
                  </Typography>
                </Tooltip>
              ) : (
                <Typography variant="body1" style={{ marginTop: '10px' }} className={classes.noEdit}>
                  {metadata?.['User Segment'] || '---'}
                </Typography>
              )}
            </div>
          ) : (
            <div className={classes.inputContainer}>
              <InputLabelComponent label=" User Segment"/>
              <DropDownList
                name="User Segment"
                data={
                  userSegments &&
                  userSegments.items.map((i) => ({
                    id: i.id,
                    label: i.name,
                  }))
                }
                onChange={(e) => handleUpdateMetadata(e)}
                onBlur={(e) => handleUpdateMetadata(e)}
                value={
                  metadata['User Segment']
                    ? {
                      id: 'User Segment',
                      label: metadata['User Segment'],
                    }
                    : {
                      id: '',
                      label: '---',
                    }
                }
                textField="label"
                style={{
                  width: '100%',
                  fontSize: '14px',
                  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                  fontWeight: 'lighter',
                }}
              />
            </div>
          )}
        </Grid>
        {permissionsBoolean && (
          <Grid item xs={12} md={4} style={{ padding: 15, display: 'flex', alignItems: 'center' }}>
            <FormControl component="fieldset">
              <FormGroup aria-label="position" row>
                <FormControlLabel
                  value="top"
                  control={
                    <Tooltip
                      title={
                        userPermissions && userPermissions.analyticsEnabled ? 'Disable Analytics' : 'Enable Analytics'
                      }
                    >
                      <Switch
                        data-testid="switchAnalytics"
                        checked={
                          userPermissions &&
                          (userPermissions.analyticsEnabled || userPermissions.analyticsEnabled === null)
                        }
                        onChange={() => {
                          if (userDetails.parentIds && userDetails.parentIds.length) return;
                          dispatch(toggleAnalyticsField(currentTenant.id, userDetails.email));
                        }}
                        value="enabled"
                        color="primary"
                      />
                    </Tooltip>
                  }
                  label={
                    <Typography
                      style={{
                        fontWeight: 400,
                        fontSize: 16,
                      }}
                    >
                      Analytics Enabled
                    </Typography>
                  }
                  labelPlacement="start"
                />
              </FormGroup>
            </FormControl>
          </Grid>
        )}
      </SectionComponent>
      <SectionComponent label="Contacts">
        {createListItems(sections[1], contactWidth)}
        {matches ? createListItems(sections[2], 4) : createListItems(sections[2], 12)}
      </SectionComponent>
      <SectionComponent label="Shipping Address">
        {matches ? createListItems(sections[3], 4) : createListItems(sections[3], 12)}
      </SectionComponent>
      <SectionComponent label="Billing Address">
        {matches ? createListItems(sections[4], 4) : createListItems(sections[4], 12)}
      </SectionComponent>
      <SectionComponent label="Emergency Contact">
        {matches ? createListItems(sections[5], 4) : createListItems(sections[5], 12)}
      </SectionComponent>
      {tenantSetting && tenantSetting.requireGuardianMode && userDetails && userDetails.isGuardianMode ? (
        <SectionComponent label="Guardian Info">
          {matches ? createListItems(sections[6], 4, 'Guardian') : createListItems(sections[6], 12, 'Guardian')}
        </SectionComponent>
      ) : null}
      {metaDataList?.length ? (
        <SectionComponent label="Custom Fields">
          <ProfileMetadata
            userDetails={userDetails}
            onUpdateUser={handleUpdateUser}
            userSegments={userSegments}
            metaDataList={metaDataList}
            xs={matches ? 4 : 12}
          />
        </SectionComponent>
      ) : null}
    </Grid>
  );
}

function SectionComponent(props) {
  const { label, children: content } = props;
  const classes = useStyles();

  return (
    <Grid item xs={12} style={{ marginBottom: 16 }}>
      <Card className={classes.basicContainer} style={{ width: '100%', borderRadius: 20 }}>
        <CardContent style={{ padding: 32 }}>
          <Grid container>
            <Grid item xs={12}>
              <Typography className={classes.sectionLabel} style={{ fontSize: 22, fontWeight: 500 }}>
                {label}
              </Typography>
            </Grid>
            <Grid container direction="row" style={{ padding: '0 16px 0 21px' }}>
              {content}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}

function InputLabelComponent(props) {
  const { label, customStyles } = props;
  const classes = useStyles();

  return (
    <Typography variant="h4" className={classes.inputLabel} style={customStyles || {}}>
      {label}
    </Typography>
  );
}

const useStyles = makeStyles(() => ({
  sectionLabel: {
    marginRight: '10px',
    borderRadius: '5px',
    color: '#505358',
    fontFamily: 'Avenir',
  },
  inputLabel: {
    fontSize: '12px!important',
    fontWeight: 'normal!important',
  },
}));

export default withStyles(ProfileViewStyles)(ProfileInformation);
