// export const languageOptions = [
//   {
//     label: "Español",
//     value: "spa",
//   },
//   {
//     label: "中文 (普通話)",
//     value: "zho",
//   },
//   {
//     label: "العربية",
//     value: "ara",
//   }
// ];

export const languageOptions = [
  'spa = Español',
  'zho = 中文 (普通話)',
  'ara = العربية',
  'fre = Français',
  'heb = עִברִית',
  'rus = русский',
];
