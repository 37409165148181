/* eslint-disable import/no-cycle */
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React, { useState, useEffect } from 'react';

import styles from '../../assets/Questionnaire.module.css';
import { createInputHelper } from '../createInputHelper';
import { stateList, countryList } from '../helpers/stateCountryHelper';
import utils from '../utils/utils';

function AddressInput(props) {
  const { field, handleChangeWithParams, selectedLanguage } = props;

  const [setCurrentLanguageDescription] = useState();
  const [currentValue, setCurrentValue] = useState();

  // Map address form using existing input types
  const addressForm = [
    {
      type: 'text',
      name: 'line1',
      label: 'Address Line 1',
      width: 12,
      rows: 1,
      required: true,
      isFieldForm: true, // if it's a form field and not a question
    },
    {
      type: 'text',
      name: 'line2',
      label: 'Address Line 2',
      width: 12,
      rows: 1,
      required: false,
      isFieldForm: true, // if it's a form field and not a question
    },
    {
      type: 'text',
      name: 'city',
      label: 'City',
      width: 12,
      rows: 1,
      required: true,
      isFieldForm: true, // if it's a form field and not a question
    },
    {
      type: 'autocomplete',
      name: 'state',
      label: 'State',
      enum: stateList,
      required: false,
      width: 6,
      labelField: 'name',
      valueField: 'abbreviation',
    },
    {
      type: 'zip',
      name: 'zip',
      label: 'Zip/Postal Code',
      width: 6,
      rows: 1,
      required: false,
      isFieldForm: true, // if it's a form field and not a question
    },
    {
      type: 'autocomplete',
      name: 'country',
      label: 'Country',
      enum: countryList,
      required: false,
      width: 12,
      isFieldForm: true, // if it's a form field and not a question
    },
  ];

  // Set values for currently selected language
  useEffect(() => {
    if (field && field.description) {
      const newLanguageName =
        selectedLanguage && field.description && field.description.translations
          ? field.description.translations[selectedLanguage]
          : field.description;
      setCurrentLanguageDescription(newLanguageName);
    }
  }, [selectedLanguage, field]);

  // Change handler
  const onChange = (e, value) => {
    const name = e?.target?.name || e;
    if (!value) {
      value = e?.target?.value || null;
    }
    const updatedState = { ...currentValue, [name]: value };
    setCurrentValue(updatedState);
    handleChangeWithParams(field.name, updatedState);
  };

  if (!field.name && !field.label) return <div />;

  const fieldTitle =
    selectedLanguage && field.name && field.name.translations
      ? field.name.translations[selectedLanguage]
      : field.label || field.name;
  const questionDescription =
    selectedLanguage && field.description && field.description.translations
      ? field.description.translations[selectedLanguage]
      : field.description;

  return (
    <div>
      <Grid container style={{ position: 'relative' }}>
        <Grid item xs={12} style={{ width: '100%' }}>
          {fieldTitle && (
            <Typography className={field.nameStyle ? styles[`title--${field.nameStyle}`] : styles['title--style1']}>
              {field.questionPreview ? utils.renderHTMLTags(fieldTitle) : fieldTitle}
              {field.required && '*'}
            </Typography>
          )}
          {field.image && (
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <img
                src={field.image}
                alt={fieldTitle}
                style={{
                  display: 'block',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  width: '90%',
                }}
              />
            </Grid>
          )}

          {questionDescription && (
            <Typography
              className={
                field.descriptionStyle
                  ? styles[`description--${field.descriptionStyle}`]
                  : styles['description--style1']
              }
            >
              {utils.renderHTMLTags(questionDescription)}
            </Typography>
          )}
        </Grid>

        {createInputHelper(addressForm, {}, onChange, onChange)}
      </Grid>
    </div>
  );
}

export default AddressInput;
