/* eslint-disable no-unused-vars */
import { systemMessagesTabTypes } from '../Settings/SystemMessages/helpers/mappings';
import { userTypes } from '../Users/helpers/mappings';

export const pages = (currentTenant, user) => [
  {
    key: 'dashboard',
    link: `/${currentTenant && currentTenant.id}`,
    name: 'Dashboard',
  },
  {
    key: 'profile',
    link: `/${currentTenant && currentTenant.id}/profile/${user?.email}`,
    name: 'Profile',
  },
  {
    key: 'members',
    link: `/${currentTenant && currentTenant.id}/users/${userTypes.ACTIVE}`,
    name: 'People',
    root: '/users',
  },
  {
    key: 'tests',
    link: `/${currentTenant && currentTenant.id}/diagnostics`,
    name: 'Diagnostics',
    allowedPermissions: ['ADMINISTRATOR'],
  },
  {
    key: 'encounters',
    link: `/${currentTenant && currentTenant.id}/encounters`,
    name: 'Encounters',
    allowedPermissions: ['ADMINISTRATOR'],
  },
  {
    key: 'medical-center',
    link: `/${currentTenant && currentTenant.id}/medical-center/orderable-tests`,
    name: 'Medical Center',
    root: '/medical-center',
  },
];

export const pages2 = (currentTenant) => [
  {
    key: 'record-requests',
    link: `/${currentTenant && currentTenant.id}/record-requests`,
    name: 'Record Requests',
    allowedPermissions: ['ADMINISTRATOR', 'ACCOUNT_ADMINISTRATOR'],
  },
  {
    key: 'reporting',
    link: `/${currentTenant && currentTenant.id}/reports`,
    name: 'Reports',
    allowedPermissions: ['ADMINISTRATOR'],
    root: '/reports',
  },
  {
    key: 'content',
    link: `/${currentTenant && currentTenant.id}/content`,
    name: 'Content',
    root: '/content',
  },
];

export const settingsPages = (currentTenant) => [
  {
    key: 'settings/configuration',
    link: `/${currentTenant && currentTenant.id}/settings/configuration/tenant-info`,
    name: 'Configuration',
    root: '/settings/configuration',
  },
  {
    key: 'settings/verbiage',
    link: `/${currentTenant && currentTenant.id}/settings/verbiage/screens`,
    name: 'Verbiage',
    root: '/settings/verbiage',
  },
  {
    key: 'settings/system-messages',
    link: `/${currentTenant && currentTenant.id}/settings/system-messages/${systemMessagesTabTypes.EMAIL}`,
    name: 'System Messages',
    root: '/settings/system-messages',
  },
  {
    key: 'settings/message-standardization',
    link: `/${currentTenant && currentTenant.id}/settings/message-standardization`,
    name: 'Message Standardization',
    root: '/settings/message-standardization',
  },
];

export const virtualConsultPages = (currentTenant) => [
  {
    key: 'virtual-consult/questionnaires',
    link: `/${currentTenant && currentTenant.id}/virtual-consult/questionnaires`,
    name: 'Questionnaires',
    root: '/virtual-consult/questionnaire',
  },
  {
    key: 'virtual-consult/questions',
    link: `/${currentTenant && currentTenant.id}/virtual-consult/questions`,
    name: 'Question Store',
    root: '/virtual-consult/questions',
  },
  {
    key: 'virtual-consult/outcomes',
    link: `/${currentTenant && currentTenant.id}/virtual-consult/outcomes`,
    name: 'Outcomes',
    root: '/virtual-consult/outcomes',
  },
  {
    key: 'virtual-consult/employee-tiers',
    link: `/${currentTenant && currentTenant.id}/virtual-consult/user-segments`,
    name: 'User Segment Outcomes',
    root: '/virtual-consult/user-segments',
  },
  {
    key: 'virtual-consult/monitoring',
    link: `/${currentTenant && currentTenant.id}/virtual-consult/monitoring`,
    name: 'Monitoring',
    root: '/virtual-consult/monitoring',
  },
  {
    key: 'virtual-consult/reviewer',
    link: `/${currentTenant && currentTenant.id}/virtual-consult/reviewer`,
    name: 'Reviewer',
    root: '/virtual-consult/reviewer',
  },
];
