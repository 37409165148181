import Grid from '@material-ui/core/Grid';
import React, { useState, useEffect } from 'react';
import { tableSelection } from '../../Grid/helpers';

// ui reflection of file view
function MultiSelectTable(props) {
  const { field, values, handleChangeWithParams } = props;
  const [selected, setSelected] = useState(values || []);

  useEffect(() => {
    if (values !== selected) {
      setSelected(values || []);
    }
  }, [selected, values]);

  const handleChange = (val) => {
    handleChangeWithParams(field.name, val);
    setSelected(val);
  };

  const TableComponent = tableSelection(
    field.component,
    field.tenant,
    field.contentType,
    selected,
    handleChange,
    field.readOnly
  );

  return (
    <Grid container justifyContent="center" spacing={2}>
      <Grid item xs={12}>
        <TableComponent />
      </Grid>
    </Grid>
  );
}

export default MultiSelectTable;
