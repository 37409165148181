// mui
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import { Input } from '@progress/kendo-react-inputs';
import React, { useState } from 'react';

import { homeScreenButtonLabelMapping } from '../helpers/mappings';

const styles = () => ({
  root: {
    marginLeft: '16px',
    marginTop: '-20px',
    width: 'auto!important',
  },
});

function ButtonLabelField(props) {
  const { field, value, handleChangeWithParams, classes } = props;

  const [selectorValue, setSelectorValue] = useState(() => {
    if (value && homeScreenButtonLabelMapping.includes(value)) return value;
    if (value) return 'Custom Label';
    return '';
  });

  const [fieldValue, setFieldValue] = useState(value && selectorValue === 'Custom Label' ? value : '');
  const [timeout, setStateTimeout] = useState(undefined);

  const handleChangeSelect = (e) => {
    const newValue = e.target.value;
    setSelectorValue(newValue);

    if (newValue !== 'Custom Label') {
      handleChangeWithParams(field.name, newValue);
    }
  };

  const handleChangeInput = (e) => {
    setFieldValue(e.target.value);

    if (timeout) {
      clearTimeout(timeout);
    }

    setStateTimeout(
      setTimeout(() => {
        handleChangeWithParams(field.name, e.target.value);
      }, 2000)
    );
  };

  return (
    <Grid container className={classes.root}>
      <Grid item style={{ width: '100%' }}>
        <ComboBox
          label={field.label}
          name={field.name}
          data={homeScreenButtonLabelMapping}
          onChange={handleChangeSelect}
          style={{ width: '100%' }}
          defaultValue={selectorValue}
        />
      </Grid>

      {selectorValue && selectorValue === 'Custom Label' && (
        <Grid item style={{ paddingTop: '10px', width: '100%' }}>
          <Input
            width="100%"
            value={fieldValue}
            onChange={handleChangeInput}
            required
            label="Button Label"
            name="buttonLabel"
            style={{ width: '100%' }}
            autocomplete="new-password"
          />
        </Grid>
      )}
    </Grid>
  );
}

export default withStyles(styles)(ButtonLabelField);
