// mui
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// imports
import { DialogActionsBar } from '@progress/kendo-react-dialogs';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import { Input } from '@progress/kendo-react-inputs';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { stateList } from '../Shared/Forms/helpers/stateCountryHelper';
import { selectAllUsers, selectAllUsersLoading } from '../Users/selectors';
import { getUsers } from '../Users/usersSlice';

import { searchProvider } from './providerListSlice';
import {
  getDataFromAllFacilitiesInTheState,
  getDataFromSpecificFacility,
  getSequoiaDataFromAllFacilies,
  getSequoiaDataFromWithinRange,
} from './recordRequestSlice';
import { selectProviderList, selectProviderListLoading } from './selectors';

function CreateRecordRequestDialog(props) {
  const { dialogOpen, closeDialog, currentTenant } = props;

  const dispatch = useDispatch();
  const allUsers = useSelector(selectAllUsers);
  const allUsersLoading = useSelector(selectAllUsersLoading);
  const providerList = useSelector(selectProviderList);
  const providerListLoading = useSelector(selectProviderListLoading);
  const [timeout, setStateTimeout] = useState(undefined);
  const [users, setUsers] = useState([]);
  const [providers, setProviders] = useState([]);
  const [stateRequired, setStateRequired] = useState(false);
  const [facilityRequired, setFacilityRequired] = useState(false);
  const [postalCodeRequired, setPostalCodeRequired] = useState(false);
  const [hideButtons, setButtonHide] = useState(false);
  const [showFacility, setFacilityHide] = useState(false);
  const [showState, setStateHide] = useState(false);
  const [showPostalCode, setPostalCodeHide] = useState(false);
  const [missingFields, setMissingFields] = useState([]);
  const [recordRequest, setRecordRequest] = useState({
    user: {},
    state: {},
    facility: {},
    postalCode: '',
  });

  const handleCancel = () => {
    setButtonHide(false);
    setFacilityRequired(false);
    setStateRequired(false);
    setPostalCodeRequired(false);
    setFacilityHide(false);
    setStateHide(false);
    setPostalCodeHide(false);
    setMissingFields([]);
    closeDialog();
  };

  const searchByFacility = () => {
    setButtonHide(true);
    setFacilityRequired(true);
    setStateRequired(false);
    setPostalCodeRequired(false);
    setFacilityHide(true);
    setStateHide(false);
    setPostalCodeHide(false);
    setMissingFields([]);
  };

  const searchByState = () => {
    setButtonHide(true);
    setStateRequired(true);
    setFacilityRequired(false);
    setPostalCodeRequired(false);
    setFacilityHide(false);
    setPostalCodeHide(false);
    setStateHide(true);
    setMissingFields([]);
  };

  const searchByPostalCode = () => {
    setButtonHide(true);
    setFacilityRequired(false);
    setStateRequired(false);
    setPostalCodeRequired(true);
    setFacilityHide(false);
    setStateHide(false);
    setPostalCodeHide(true);

    setMissingFields([]);
  };

  const searchAll = () => {
    setButtonHide(true);
    setFacilityHide(false);
    setStateHide(false);
    setStateRequired(false);
    setFacilityRequired(false);
    setPostalCodeRequired(false);
    setPostalCodeHide(false);
    setMissingFields([]);
  };

  const handleCreateRecordRequest = () => {
    const missingFields = [];
    if (!recordRequest.user.email) {
      missingFields.push('User is Required');
    }
    if (!recordRequest.facility._id && facilityRequired && !stateRequired && !postalCodeRequired) {
      missingFields.push('Facility is Required');
    }
    if (!recordRequest.state.abbreviation && stateRequired && !facilityRequired && !postalCodeRequired) {
      missingFields.push('State is Required');
    }
    if (!recordRequest.postalCode && postalCodeRequired && !stateRequired && !facilityRequired) {
      missingFields.push('PostalCode is Required');
    }
    setMissingFields(missingFields);
    if (recordRequest.user.email && !facilityRequired && !stateRequired && !postalCodeRequired) {
      dispatch(getSequoiaDataFromAllFacilies(currentTenant.id, recordRequest.user.nationalHealthId));
    }
    if (recordRequest.user.email && recordRequest.facility._id && facilityRequired) {
      dispatch(
        getDataFromSpecificFacility(currentTenant.id, recordRequest.user.nationalHealthId, recordRequest.facility._id)
      );
    }
    if (recordRequest.user.email && recordRequest.state.abbreviation && stateRequired) {
      dispatch(
        getDataFromAllFacilitiesInTheState(
          currentTenant.id,
          recordRequest.user.nationalHealthId,
          recordRequest.state.abbreviation
        )
      );
    }
    if (recordRequest.postalCode && recordRequest.user.email && postalCodeRequired) {
      const data = {
        safeEMRId: recordRequest.user.nationalHealthId,
        postalCode: recordRequest.postalCode,
      };
      dispatch(getSequoiaDataFromWithinRange(currentTenant.id, data));
    }
    if (missingFields.length === 0) handleCancel();
  };

  const userFilterChange = (e) => {
    if (e.filter) {
      if (timeout) {
        clearTimeout(timeout);
      }
      setStateTimeout(
        setTimeout(() => {
          dispatch(getUsers(currentTenant.id, 0, 10, e.filter.value, 'email', 'ASC'));
        }, 2000)
      );
    }
  };

  const providerFilterChange = (e) => {
    if (e.filter) {
      if (timeout) {
        clearTimeout(timeout);
      }
      const providerData = {
        pageSize: 10,
        facilityType: 'Sequoia',
        nameOnly: e.filter.value,
        pageNumber: 0,
      };
      setStateTimeout(
        setTimeout(() => {
          dispatch(searchProvider(providerData));
        }, 2000)
      );
    }
  };

  const userChange = (e) => {
    setRecordRequest({ ...recordRequest, user: e.target.value });
  };

  const facilityChange = (e) => {
    setRecordRequest({ ...recordRequest, facility: e.target.value });
  };

  const stateChange = (e) => {
    setRecordRequest({ ...recordRequest, state: e.target.value });
  };

  const postalCodeChange = (e) => {
    setRecordRequest({ ...recordRequest, postalCode: e.target.value });
  };
  useEffect(() => {
    if (users && !users.items && currentTenant) {
      dispatch(getUsers(currentTenant.id, 0, 10, '', 'email', 'ASC'));
    }
  }, [currentTenant, users]);

  useEffect(() => {
    if (allUsers) {
      setUsers(allUsers);
    }
  }, [allUsers]);

  useEffect(() => {
    if (providerList && providerList.data && providerList.data.result) {
      setProviders(providerList.data.result);
    }
  }, [providerList]);

  return (
    <Dialog onClose={handleCancel} open={dialogOpen} maxWidth="xl">
      <DialogTitle id="simple-dialog-title">New Record Request</DialogTitle>
      <DialogContent>
        <Grid container style={{ margin: '0px 40px 20px' }}>
          <Grid item xs={8}>
            <ComboBox
              data={users && users.items}
              allowCustom={false}
              suggest
              filterable
              onFilterChange={userFilterChange}
              label="User"
              name="user"
              required
              onChange={userChange}
              style={{ width: '100%' }}
              textField="email"
              value={recordRequest.user}
              loading={allUsersLoading}
            />
          </Grid>
          <Grid item xs={12}>
            <button className="k-button" hidden={hideButtons} onClick={searchByFacility}>
              Search By Facility
            </button>
            <button className="k-button" hidden={hideButtons} onClick={searchByPostalCode}>
              Search By Postal Code
            </button>
            <button className="k-button" hidden={hideButtons} onClick={searchByState}>
              Search By State
            </button>
            <button className="k-button" hidden={hideButtons} onClick={searchAll}>
              Search All
            </button>
          </Grid>
          {showFacility && (
            <Grid item xs={8}>
              <ComboBox
                data={providers}
                allowCustom={false}
                suggest
                filterable
                onFilterChange={providerFilterChange}
                label="Facility"
                name="facility"
                required={facilityRequired}
                onChange={facilityChange}
                style={{ width: '100%' }}
                textField="name"
                value={recordRequest.facility}
                loading={providerListLoading}
              />
            </Grid>
          )}
          {showState && (
            <Grid item xs={8}>
              <ComboBox
                data={stateList || []}
                allowCustom={false}
                suggest
                filterable
                label="State"
                name="state"
                required={stateRequired}
                onChange={stateChange}
                style={{ width: '100%' }}
                textField="name"
                value={recordRequest.state}
              />
            </Grid>
          )}
          {showPostalCode && (
            <Grid item xs={8}>
              <Input
                label="Postal Code"
                required={postalCodeRequired}
                onChange={postalCodeChange}
                value={recordRequest.postalCode}
              />
            </Grid>
          )}
          {missingFields.length > 0 && (
            <Grid item xs={8}>
              <Typography color="error" variant="caption">
                {missingFields.join(', ')}
              </Typography>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActionsBar>
        <button className="k-button" onClick={handleCancel}>
          Cancel
        </button>
        <button className="k-button" onClick={() => handleCreateRecordRequest()}>
          Create Record Request
        </button>
      </DialogActionsBar>
    </Dialog>
  );
}

export default CreateRecordRequestDialog;
