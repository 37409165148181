import 'date-fns';
import { Box, Button, Dialog, DialogContent, DialogTitle, Divider, TextField, Typography, styled } from "@material-ui/core";
import React, { useEffect, useReducer } from "react";
import TextFieldInput from "../../../Shared/Forms/KendoFields/TextFieldInput";
// import DateInput from "../../../Shared/Forms/KendoFields/DateInput";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { addressLine1Field, cityField, countryField, dateOfBirthField, firstNameField, genderField, last4SSNField, last4SSNFieldNumeric, lastNameField, newDependentFn, phoneNumber1Field, postalcodeField, setNewDependentFn, stateField } from "./helpers";
import NumberFieldInput from "../../../Shared/Forms/KendoFields/NumberFieldInput";
import DateFnsUtils from '@date-io/date-fns';

/* default values */
const defaultState = {
  name: {
    firstName: "James",
    lastName: "Thomas",
  },
  phone: {
    phoneNumber1: "555-555-1234",
  },
  "gender": "M",
  "last4SSN": 9999, /* INT */
  dateOfBirth: "01012023" /* MMDDYYYY */,
  address: {
    addressLine1: "5905 Wilshire Blvd",
    city: "Los Angeles",
    state: "CA",
    postalcode: 90036 /* INT */,
    country: "US"
  }
};

const stateReducer = (state, action) => {
  return (action.reset) ?
    {...defaultState}
      :
    {
      ...state,
      // if it is a function
      ...(typeof action === 'function' ? action(state) : action),
    }
};

const DependentModal = ({
  onSubmitNewDependent,
  open,
  onClose
}) => {

  const [formState, setFormState] = useReducer(stateReducer, defaultState);

  useEffect(() => {
    return () => {
      setFormState({reset: true})
    };
  }, []);


  const {
      name: { firstName = "", lastName = "" },
      phone: { phoneNumber1 = "" },
      dateOfBirth = "",
      gender = "",
      last4SSN = 0,

      address: {
        addressLine1 = "",
        city = "",
        state = "",
        postalcode = "",
        country = ""
      }
  } = formState

  /* CONVERT Birth Date to Date Object */
  const dobArrSubStr = dateOfBirth.split('');
  const MM = dobArrSubStr.splice(0,2).join('');
  const DD = dobArrSubStr.splice(0,2).join('');
  const YYYY = dobArrSubStr.splice(0,4).join('');
  const dobInputVal = new Date(`${MM}-${DD}-${YYYY}`);


  const handleStateChange = (fieldName, fieldVal) =>  {
    setFormState(setNewDependentFn(fieldName, fieldVal))
  }

  const handleTextInputChange = (e) => {
    handleStateChange(e.target.name, e.target.value)
  }

  const handleDateChange = (e) => {
    handleStateChange(e.target.name, new Date(e.target.value))
  }

  const handleSubmit = (e) => {
    onSubmitNewDependent(formState)
    setFormState({reset: true})
    onClose()
  }


  return <Dialog
    fullWidth
    maxWidth={'md'}
    open={open}
    onClose={onClose}
    aria-labelledby="max-width-dialog-title"
    className="dependent-dialog"
  >
    <DialogTitle>
      <Typography variant="h6" color="primary">
        Add New Dependent
      </Typography>
    </DialogTitle>
    <DialogContent>
    <Box>
      <FormWrapper>
        <BasicDetailsC>
          <Box gridColumn={'1 / span 2'} style={{textDecoration: 'underline'}}>
            <Typography>
              Personal Info
            </Typography>
            <Divider />
          </Box>
          <TextFieldInput
            field={firstNameField}
            value={firstName}
            handleChange={handleTextInputChange}
          />
          <TextFieldInput
            field={lastNameField}
            value={lastName}
            handleChange={handleTextInputChange}
          />
          {/* genderField is used in other code with "readOnly" true */}
          <TextFieldInput
            field={{...genderField, label: "Gender", readOnly: false}}
            value={gender}
            handleChange={handleTextInputChange}
          />
          <TextFieldInput
            field={last4SSNFieldNumeric}
            value={last4SSN}
            handleChange={handleTextInputChange}
          />
          <TextFieldInput
            field={phoneNumber1Field}
            value={phoneNumber1}
            handleChange={handleTextInputChange}
          />
          <Box width={'100%'}>
            <TextField
              style={{ width: '100%'}}
              value={`${YYYY}-${MM}-${DD}`}
              onChange={handleDateChange}
              name='dateOfBirth'
              id="dateOfBirth"
              label="Date of Birth"
              type="date"
              defaultValue="2017-05-24"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>
        </BasicDetailsC>
        <AddressInfoC>
          <Box gridColumn={'1 / span 2'} style={{textDecoration: 'underline'}}>
            <Typography>
              Address Info
            </Typography>
            <Divider />
          </Box>
          <TextFieldInput
            field={addressLine1Field}
            value={addressLine1}
            handleChange={handleTextInputChange}
          />
          <TextFieldInput
            field={cityField}
            value={city}
            handleChange={handleTextInputChange}
          />
          <TextFieldInput
            field={stateField}
            value={state}
            handleChange={handleTextInputChange}
          />
          <NumberFieldInput
            field={postalcodeField}
            value={postalcode}
            handleChange={handleTextInputChange}
          />
          <TextFieldInput
            field={countryField}
            value={country}
            handleChange={handleTextInputChange}
          />
        </AddressInfoC>
        <ActionC>
            <Button variant='outlined' onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              // disabled={fvSubsLoading === 'pending'}
            >
              {/* <Box display={'flex'} alignItems={'center'}>
                <Box display={'inline-block'}>Update Dependent {dependentIdx + 1}</Box>
                {
                  fvSubsLoading === 'pending' && <Box display={'inline-block'} marginLeft={'8px'}>
                    <CircularProgress size={20} color="primary"/>
                  </Box>
                }
              </Box> */}
              Submit
            </Button>
        </ActionC>
      </FormWrapper>
    </Box>
    </DialogContent>
  </Dialog>;
};

const FormWrapper = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr',
  paddingBlock: '5px',
  rowGap: '20px',
})

const BasicDetailsC = styled('div')({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
  rowGap: '16px',
  columnGap: '100px',
  marginTop: '20px',
  'container': 'basic-info / inline-size',
  paddingLeft: '5px',
  alignItems: 'center',
})

const AddressInfoC = styled('div')({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
  rowGap: '16px',
  columnGap: '100px',
  marginTop: '20px',
  'container': 'basic-info / inline-size',
  paddingLeft: '5px',
})

const ActionC = styled('div')({
  margin: '30px',
  gap: '20px',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
})

export default DependentModal;
