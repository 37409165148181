import { Error } from '@progress/kendo-react-labels';
import { Box, Button, FormControl, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import DropdownField from '../../Shared/Forms/KendoFields/DropdownField';
import TextFieldInput from '../../Shared/Forms/KendoFields/TextFieldInput';
import NumberFieldInput from '../../Shared/Forms/KendoFields/NumberFieldInput';
import { OverlayLoading } from '../helper/SimulatorLoading';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetBenefitLoading,
  loadEmployeeBenefit,
  resetBenefitData,
  updateEmployeeBenefit,
} from '../slice/fvSimulatorSlice';
import {
  selectExternalIdData,
  selectExternalIdLoading
} from '../slice/selector';
import { showNotification } from '../../Notifications/notificationSlice';
import { useLocation } from 'react-router-dom';
import { selectUserDetails } from '../../Users/selectors';
import { useParams } from 'react-router-dom';
import { useReducer, useEffect } from 'react';
import { getUser } from '../../Users/usersSlice';
import { useRef } from 'react';
import ToggleInput from '../../Shared/Forms/KendoFields/ToggleField';
import {
  loadExternalId,
  updateExternalId,
  resetExternalIdLoading,
  resetExternalIdData,
  resetExternalIdToInit
} from '../slice/fvSimulatorSlice';

const externalIdField = {
  type: 'text',
  name: 'externalId',
  label: 'External ID',
  readOnly: false,
};

const externalIdReducer = (state, action) => ({
  ...state,
  // if it is a function
  ...(typeof action === 'function' ? action(state) : action),
});

/* default values */
const defaultState = {
  externalId: '',
};

function ExternalIdSimulator() {
  const dispatch = useDispatch();
  const params = useParams();

  const externalIdLoading = useSelector(selectExternalIdLoading);
  const externalIdDataSlice = useSelector(selectExternalIdData);

  const [externalIdState, setExternalIdState] = useReducer(externalIdReducer, defaultState, (init) => {
    if (externalIdDataSlice) {
      return {
        /* default to false if GET api didn't return any data */
        externalId: externalIdDataSlice ?? '',
      };
    }
    return init;
  });

  /* need to get user's "_id" not just email/userId */
  const { userId } = params;
  const userDetails = useSelector(selectUserDetails);
  const isMounted = useRef(false);

  useEffect(() => {
    const getUserData = async () => {
      dispatch(getUser(userId, true));
    };

    getUserData();

    if (externalIdDataSlice !== null || externalIdDataSlice !== undefined) {
      setExternalIdState({
        externalId: externalIdDataSlice ?? '',
      });
    }
    isMounted.current = true;
  }, [userId, externalIdDataSlice]);
  /* END - getting userDetails for _id */

  const { externalId } = externalIdState;
  const [error, setError] = useState({});

  // const accountId = localStorage.getItem('accountId');
  const tenantId = localStorage.getItem('tenantId');

  const handleIdChange = (e) => {
    /* Clear Error */
    let clearedErr = { ...error };
    delete clearedErr['externalId'];
    setError({ ...clearedErr });

    setExternalIdState({ externalId: e.target.value });
  };

  useEffect(() => {
    const params = {
      tenantId,
      /**
       * Pass the email as userId if userDetails is not yet available
       * where: userId => email address
       * email address also works with GET but will received no data property yet.
       * On next render it will use the proper userDetails?._id and will have data
       */
      userId: userDetails?._id,
    };

    if (userDetails?._id) {
      dispatch(loadExternalId(params));
    }

    return () => {
      dispatch(resetExternalIdToInit());
      dispatch(resetExternalIdData());
    };
  }, [userId, userDetails?._id]);

  useEffect(() => {
    if (externalIdLoading === 'fulfilled' || externalIdLoading === 'rejected') {
      dispatch(resetExternalIdLoading());
    }
  }, [externalIdLoading]);

  /* SUBMIT SIMULATION REQUEST */
  const handleSubmit = async (e) => {
    e.preventDefault();
    const hasError = validateInputs();

    if (hasError) {
      return null;
    }

    const userIdPayload = userDetails?._id ?? null;
    if (!userIdPayload) {
      dispatch(showNotification(`Submission Failed <br />Error: user's _id is required`, 'error'));
    }

    const payload = {
      // tenantID: 'healthcheck-consumer', // for local DEV only
      tenantId,
      // userId,  => using _id instead
      userId: userIdPayload,
      formData: {
        value: externalId,
      },
    };

    try {
      const result = await dispatch(updateExternalId(payload));

      if (result.error) {
        dispatch(showNotification(`Failed to execute simulator <br />Error: ${result.error.message}`, 'error'));
        dispatch(resetExternalIdLoading());
        return;
      }

      if (result.payload[1]?.status >= 400) {
        dispatch(showNotification(`Failed to execute simulator <br />Error: ${result?.payload[1]?.message}`, 'error'));
        dispatch(resetExternalIdLoading());
        return;
      }

      if (result.payload[0]?.success) {
        dispatch(
          showNotification(`${result?.payload[0]?.message} <br />External ID updated successfully`, 'success')
        );
        return;
      }
    } catch (error) {
      dispatch(showNotification(`Failed to execute simulator <br />Error: ${error.message}`, 'error'));
      dispatch(resetExternalIdLoading());
    }
  };

  const validateInputs = () => {
    let hasError = false;
    if (externalId === undefined || externalId === null) {
      setError((err) => ({
        ...err,
        externalId: {
          message: 'External ID cannot be undefined',
        },
      }));
      hasError = true;
    }
    return hasError;
  };

  return (
    <Box width={'100%'} height={'100%'}>
      {(externalIdLoading === 'pending' || !isMounted.current ||
        (externalIdDataSlice === null && externalIdLoading === 'init')) && <OverlayLoading />}
      <Box
        display={'grid'}
        gridTemplateColumns={'repeat(2, minmax(0, 1fr))'}
        gridRowGap={'50px'}
        gridColumnGap={'100px'}
        marginTop={'25px'}
        marginBottom={'25px'}
      >
        <Box textAlign={'left'}>
          <TextFieldInput field={externalIdField} value={externalId} handleChange={handleIdChange} />
          {error?.externalId && <Error>{error.externalId?.message}</Error>}
        </Box>
        <Box gridColumn={'2 / 3 '} display="flex" justifyContent={'center'}>
          <Box textAlign={'left'}>
            <Button variant="contained" color="primary" onClick={handleSubmit} disabled={externalIdLoading === 'pending'}>
              Simulate
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default ExternalIdSimulator;
