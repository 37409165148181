import { Box, TextField, Button, IconButton, InputAdornment, Typography } from '@material-ui/core';
import VisibilityOffOutlined from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlined from '@material-ui/icons/VisibilityOutlined';
import { useFormik } from 'formik';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { paths } from '../../constants';
import { getUserFromToken, randomString } from '../Auth/helpers';
import { selectAccountId, selectDefaultTenantId } from '../Auth/selectors';
import { resetNotificationState } from '../Notifications/notificationSlice';
import { setAuthToken } from '../Shared/sharedSlice';
import { loginAdmin } from './adminSlice';
import { useStyles } from './SignInStyles';
import { ValidationMessage } from './ValidationMessage';
import { loginSchema } from './validationSchema';

export function LoginCredentialsForm(props) {
  const { onStepChange } = props;

  const defaultTenantId = useSelector(selectDefaultTenantId);
  const accountId = useSelector(selectAccountId);

  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [loginError, setLoginError] = useState('');
  const classes = useStyles();
  const navigate = useNavigate();
  const { search } = useLocation();
  const redirectUrl = new URLSearchParams(search).get('redirectUrl');

  useEffect(() => {
    if (redirectUrl) {
      sessionStorage.setItem('redirectUrl', atob(redirectUrl));
    }
  }, [redirectUrl]);

  const onSubmitLogin = async (values, { setSubmitting }) => {
    const state = randomString();
    const nonce = randomString();

    localStorage.setItem('state', state);
    localStorage.setItem('nonce', nonce);

    const result = await dispatch(
      loginAdmin(
        {
          email: values.email,
          password: values.password,
          tenantId: defaultTenantId,
          accountId,
        },
        { redirectUrl }
      )
    );

    setSubmitting(false);

    // if MFA is enabled (SMS, TOTP), move to Enter code form
    if (result?.data && result.data?.sessionId) {
      const { sessionId, challengeName } = result.data;

      // SMS || TOTP
      if (challengeName.value === 'SMS_MFA') {
        onStepChange('enterCodeSMS', challengeName.value, sessionId, values.email);
      } else if (challengeName.value === 'SOFTWARE_TOKEN_MFA') {
        onStepChange('enterCodeTOTP', challengeName.value, sessionId, values.email);
      }

      return;
    }

    if (!result.isOK) {
      setLoginError(result.message || 'Invalid credentials. Email or password was incorrect.');
      return;
    }

    // authentication
    if (result.data && result.data.idToken && result.data.refreshToken) {
      if (result?.redirectUrl) sessionStorage.setItem('redirectUrl', atob(result.redirectUrl));

      localStorage.setItem('id_token', result.data.idToken);
      localStorage.setItem('refresh_token', result.data.refreshToken);

      const user = getUserFromToken();
      localStorage.setItem('user_id', user.email);
      dispatch(setAuthToken(result.data.idToken));
      dispatch(resetNotificationState());
    }
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: loginSchema,
    onSubmit: onSubmitLogin,
  });

  const handlePasswordVisible = () => {
    setShowPassword(!showPassword);
  };

  const goToResetPassword = () => {
    navigate(paths.resetPassword());
  };

  return (
    <>
      <form className={classes.form} onSubmit={formik.handleSubmit} noValidate>
        <Box my={4}>
          <TextField
            className={classes.input}
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            title="Email"
            name="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            error={!!formik.errors.email}
            helperText={formik.errors.email}
            type="email"
            variant="outlined"
          />
          <TextField
            className={classes.input}
            autoComplete="new-password"
            margin="normal"
            required
            fullWidth
            name="password"
            title="Password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
            error={!!(formik.errors.password && formik.touched.password)}
            helperText={formik.touched.password ? formik.errors.password : ''}
            label="Password"
            type={showPassword ? 'text' : 'password'}
            id="password"
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton aria-label="toggle password visibility" onClick={handlePasswordVisible}>
                    {showPassword ? <VisibilityOutlined color="primary" /> : <VisibilityOffOutlined color="primary" />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Button
          className={classes.button}
          type="submit"
          disabled={formik.isSubmitting}
          variant="contained"
          color="primary"
          disableElevation
          aria-label="Sign In"
          fullWidth
        >
          Sign In
        </Button>
      </form>

      <Box my={2}>
        {loginError && <ValidationMessage status={false} message={loginError} closeErrorMessage={setLoginError} />}
      </Box>

      <Box mt={1} pt={3}>
        <Typography className={classes.bottomText} align={'center'}>
          Have trouble signing in?{' '}
          <Box
            component="span"
            title="Reset your password"
            className={classes.link}
            onClick={goToResetPassword}
            color="textPrimary"
          >
            Reset your password
          </Box>
        </Typography>
      </Box>
    </>
  );
}
