export const statusMapping = [
  {
    label: 'Inactive',
    background: 'red',
    color: 'white',
    value: 0,
  },
  {
    label: 'Active',
    background: 'green',
    color: 'white',
    value: 1,
  },
];

export const buttonStyleMapping = [
  {
    label: 'Square',
    value: 0,
  },
  {
    label: 'Rounded',
    value: 1,
  },
];

export const homeScreenStyleMapping = [
  {
    label: 'Dashboard Style',
    value: 'dashboardStyle',
  },
  {
    label: 'Passport Style',
    value: 'passportStyle',
  },
];

export const dashboardButtonStyleMapping = [
  {
    label: 'Solid',
    value: 0,
  },
  {
    label: 'Outline',
    value: 1,
  },
  {
    label: 'Gradient',
    value: 2,
  },
  {
    label: 'Background Image',
    value: 3,
  },
];

export const welcomeScreenButtonStyleMapping = [
  {
    label: 'Solid',
    value: 0,
  },
  {
    label: 'Outline',
    value: 1,
  },
];

export const dashboardButtonActionMapping = [
  {
    label: 'Resources',
    value: {
      _id: 'RedirectToMobileScreen',
      value: 'Resources',
      description: 'Link to resources page',
    },
  },
  {
    label: 'Symptom Checker',
    value: {
      _id: 'RedirectToMobileScreen',
      value: 'SymptomChecker',
      description: 'Begin VCB symptom checker',
    },
  },
  {
    label: 'Take A Test',
    value: {
      _id: 'RedirectToMobileScreen',
      value: 'GetTested',
      description: 'Schedule a test',
    },
  },
  {
    label: 'Redirect to URL',
    value: {
      _id: 'RedirectToUrl',
      value: 'RedirectToUrl',
      description: 'Redirect to URL',
    },
  },
  {
    label: 'Order a test kit',
    value: {
      _id: 'OrderTestKit',
      value: 'OrderTestKit',
      description: 'Order a test kit',
    },
  },
  // hidden for now
  // {
  //   label: "Import Health Records",
  //   value: {
  //     _id: "RedirectToMobileScreen",
  //     value: "ImportHealthRecords",
  //     description: "Import patient health records",
  //   },
  // },
  {
    label: 'Begin Health Service',
    value: {
      _id: 'RedirectToMobileScreen',
      value: 'HealthService',
      description: 'Health Service',
    },
  },
];

export const welcomeScreenButtonActionMapping = [
  {
    label: 'Check for Symptoms',
    value: {
      _id: 'RedirectToMobileScreen',
      value: 'TakeaTest',
      description: 'Take a Test',
    },
  },
  {
    label: 'Start Virtual Consult',
    value: {
      _id: 'RedirectToMobileScreen',
      value: 'StartVirtualConsult',
      description: 'Start Virtual Consult',
    },
  },
  {
    label: 'Begin Health Service',
    value: {
      _id: 'RedirectToMobileScreen',
      value: 'HealthService',
      description: 'Health Service',
    },
  },
];

export const welcomeScreenSkipButtonContentMapping = [
  {
    label: 'Go to Home Screen',
    value: {
      _id: 'RedirectToMobileScreen',
      value: 'GoToHomeScreen',
      description: 'Go to Home Screen',
    },
  },
  {
    label: "I'll do it later",
    value: {
      _id: 'RedirectToMobileScreen',
      value: 'Iwilldoitlater',
      description: "I'll do it later",
    },
  },
  {
    label: 'Skip',
    value: {
      _id: 'RedirectToMobileScreen',
      value: 'Skip',
      description: 'Skip',
    },
  },
];

export function getDashboardButtonTitle(buttonId) {
  let buttonTitle = '';
  if (buttonId) {
    buttonTitle = dashboardButtonActionMapping.find((item) => item.value.value === buttonId)?.label || '';
  }
  return buttonTitle;
}

export const assetsTabsMapping = [
  {
    label: 'Graphic Assets',
    id: 'graphic-assets',
    value: 0,
  },
  {
    label: 'Intro Widgets',
    id: 'intro-widgets',
    value: 1,
  },
  {
    label: 'Fonts',
    id: 'fonts',
    value: 2,
  },
];

export const orderingTestKitMapping = [
  {
    id: 0,
    label: ' None',
    value: 'NONE',
  },
  {
    id: 1,
    label: 'Shopify',
    value: 'SHOPIFY',
  },
  {
    id: 2,
    label: 'First View Financial',
    value: 'FIRST_VIEW_FINANCIAL',
  },
];

export const footerNavigationMapping = {
  // HOME: {
  //   id: 0,
  //   label: " Home",
  //   value: "HOME",
  // },
  CARE_PLANS: {
    id: 1,
    label: "Care Plans",
    value: "CARE_PLANS",
  },
  VACCINATIONS: {
    id: 2,
    label: "Vaccinations",
    value: "VACCINATIONS",
  },
  HELP_CENTER: {
    id: 3,
    label: "Help Center",
    value: "HELP_CENTER",
  },
  DEPENDENTS: {
    id: 4,
    label: "Dependents",
    value: "DEPENDENTS",
  },
  TEST_HISTORY: {
    id: 5,
    label: "Test History",
    value: "TEST_HISTORY",
  },
  ORDER_HISTORY: {
    id: 6,
    label: "Order History",
    value: "ORDER_HISTORY",
  },
  RESOURCES: {
    id: 7,
    label: "Resources",
    value: "RESOURCES",
  },
  CONTACT: {
    id: 8,
    label: "Contact",
    value: "CONTACT",
  },
  SETTINGS: {
    id: 9,
    label: "Settings",
    value: "SETTINGS",
  },
  ORDER: {
    id: 10,
    label: "Order",
    value: "ORDER",
  },
};

