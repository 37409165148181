/* eslint-disable import/no-cycle */
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import React, { useContext, useMemo } from 'react';

import CreateFormWrapper from '../../../Shared/Forms/CreateFormWrapper';
import { profileBuilder } from '../helpers/formHelper';
import { QuestionnaireBuilderContext } from '../QuestionnaireBuilderWrapper';

export function QuestionnaireProfile(props) {
  const { details, type, onDelete, formData } = props;

  const { handleSubmit, nodes, selectedQuestions, setQuestionnaireUpdated } = useContext(QuestionnaireBuilderContext);

  // sets step to it's correctly formatted JSON
  const steps = useMemo(
    () => [
      {
        label: type,
        data: profileBuilder(formData),
      },
    ],
    [type, formData]
  );

  return (
    <Paper style={{ padding: 20, borderRadius: 20 }} elevation={0}>
      <Grid item xs={12}>
        <CreateFormWrapper
          steps={steps}
          handleSubmit={() => {
            setQuestionnaireUpdated(true);
            handleSubmit(selectedQuestions, nodes, formData, true, true);
          }}
          handleDelete={onDelete}
          isNoReset
          formType={details && 'editContent'}
        />
      </Grid>
    </Paper>
  );
}

export default QuestionnaireProfile;
