import { createSlice } from '@reduxjs/toolkit';

import api from '../../../api';
import { handleError } from '../../Shared/Content/APIUtils';

const initialState = {
  ccdView: null,
  ccdViewLoading: null,
};

export const ccdViewerSlice = createSlice({
  name: 'ccdViewer',
  initialState,
  reducers: {
    setCCDViewLoading: (state) => ({
      ...state,
      ccdViewLoading: true,
    }),
    setCCDView: (state, { payload }) => ({
      ...state,
      ccdView: payload,
      ccdViewLoading: false,
    }),
  },
});

export const { setCCDView, setCCDViewLoading } = ccdViewerSlice.actions;

export const getCCDViewer = (safeEMRId) => async (dispatch) => {
  dispatch(setCCDViewLoading());

  const [result, error] = await api.getCCDViewerRequest(safeEMRId);

  if (result) {
    dispatch(setCCDView(result.data));
  }

  if (error && error.message) {
    dispatch(handleError(error));
  }
};

export default ccdViewerSlice.reducer;
