export const weightMappings = [
  'bold',
  // 'bolder',
  // 'lighter',
  'normal',
  'w100',
  'w200',
  'w300',
  'w400',
  'w500',
  'w600',
  'w700',
  'w800',
  'w900',
];

export const styleMappings = [
  'normal',
  'italic',
  'oblique',
  // 'inherit',
];
