import { Box, Button, Typography } from '@material-ui/core';
import { Error } from '@progress/kendo-react-labels';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { sassFalse } from 'sass';
import { showNotification } from '../../Notifications/notificationSlice';
import DropdownField from '../../Shared/Forms/KendoFields/DropdownField';
import TextFieldInput from '../../Shared/Forms/KendoFields/TextFieldInput';
import ToggleInput from '../../Shared/Forms/KendoFields/ToggleField';
import { OverlayLoading } from '../helper/SimulatorLoading';
import { resetTestKitLoading, startSimulateTestKit } from '../slice/fvSimulatorSlice';
import { selectTestKitLoading } from '../slice/selector';

const testKitStatusEnum = ['received', 'error', 'triage', 'pre-transit', 'transit', 'delivered', 'returned', 'failure'];

const testKitDropdownField = {
  type: 'dropdown',
  name: 'testKitSimulator',
  label: 'Status',
  enum: testKitStatusEnum,
};

const testKitIdField = {
  type: 'text',
  name: 'testKitId',
  label: 'Test Kit ID (Optional)',
  readOnly: false,
};

const isNullLocationField = {
  type: 'toggle',
  name: 'isNullLocation',
  label: 'Null Location',
  required: false,
}

function TestKitSimulator() {
  const dispatch = useDispatch();

  const params = useParams();
  const { userId } = params;

  const testKitLoading = useSelector(selectTestKitLoading);

  const [testKitId, setTestKitId] = useState('');
  const [testKitStatus, setTestKitStatus] = useState('');
  // SAF-10464 - include isNullLocation if status is set to "received" only.
  const [isNullLocation, setIsNullLocation] = useState(false);


  const [error, setError] = useState({});

  const accountId = localStorage.getItem('accountId');
  const tenantId = localStorage.getItem('tenantId');

  const handleKitStatusChange = (fieldName, fieldVal) => {
    /* Clear Error */
    let clearedErr = { ...error };
    delete clearedErr['testKitStatus'];
    setError({ ...clearedErr });

    setTestKitStatus(fieldVal);
  };

  // SAF-10464 - include isNullLocation if status is set to "received" only.
  const handleIsNullLocationToggle = (fieldName, fieldVal) => {
    /* Clear Error */
    let clearedErr = { ...error };
    delete clearedErr['isNullLocation'];
    setError({ ...clearedErr });

    console.log(fieldName, fieldVal)
    setIsNullLocation(fieldVal)
  }

  const handleKitIdChange = (e) => {
    setTestKitId(e.target.value);
  };

  useEffect(() => {
    if (testKitLoading === 'fulfilled' || testKitLoading === 'rejected') {
      dispatch(resetTestKitLoading());
    }
  }, [testKitLoading]);

  /* SUBMIT SIMULATION REQUEST */
  const handleSubmit = async (e) => {
    e.preventDefault();
    const hasError = validateInputs();

    if (hasError) {
      return null;
    }

    const testKitIdPayload = testKitId.trim();

    const payload = {
      status: testKitStatus,

      payloadFields: {
        accountID: accountId,
        tenantID: tenantId,
        // tenantID: 'healthcheck-consumer', // for local DEV only
        user: userId,
        // Only include ID property if NOT blank
        ...(testKitIdPayload && testKitIdPayload !== '' && { id: testKitIdPayload }),

        // SAF-10464 - include isNullLocation if status is set to "received" only.
        ...(testKitStatus === 'received' && { isNullLocation: isNullLocation })
      },
    };

    try {
      const result = await dispatch(startSimulateTestKit(payload));

      if (result.error) {
        dispatch(showNotification(`Failed to execute simulator <br />Error: ${result.error.message}`, 'error'));
        return;
      }

      if (result.payload[1]?.status >= 400) {
        dispatch(showNotification(`${result?.payload[1]?.message}`, 'error'));
        return;
      }

      if (result.payload[0]?.success) {
        dispatch(showNotification(`${result?.payload[0]?.message} <br /> ${result?.payload[0]?.data} `, 'success'));
        return;
      }
    } catch (error) {
      dispatch(showNotification(`Failed to execute simulator <br />Error: ${error.message}`, 'error'));
    }
  };

  const validateInputs = () => {
    let hasError = false;
    if (testKitStatus === undefined || testKitStatus === '' || !testKitStatusEnum.includes(testKitStatus)) {
      setError((err) => ({
        ...err,
        testKitStatus: {
          message: 'Test Kit Status field is required',
        },
      }));

      hasError = true;
    }

    return hasError;
  };

  // SAF-10464 - include isNullLocation if status is set to "received" only.
  const renderIsNullLocationToggle = () => {
    // Only show if status is "received"
    if (testKitStatus === 'received') {
      return <Box
        display={'flex'}
        alignItems={'center'}
        marginTop={'30px'}
        marginBottom={'5px'}
        gridColumn={'1 / span 2'}
      >
        <Box marginRight={'20px'}>
          <ToggleInput
            field={isNullLocationField}
            value={isNullLocation}
            handleChangeWithParams={handleIsNullLocationToggle}
          />
        </Box>
        <Box display={'inline-block'} >
          <Typography variant=''>
            If enabled it will set user's location to null.
          </Typography>
        </Box>
      </Box>
    }
    return null;
  }

  return (
    <Box width={'100%'} height={'100%'}>
      {testKitLoading === 'pending' && <OverlayLoading />}
      <Box
        display={'grid'}
        gridTemplateColumns={'repeat(2, minmax(0, 1fr))'}
        gridRowGap={'50px'}
        gridColumnGap={'100px'}
        marginTop={'25px'}
        marginBottom={'25px'}
      >
        <Box textAlign={'center'}>
          <Box>
            <DropdownField
              field={testKitDropdownField}
              value={testKitStatus}
              handleChangeWithParams={handleKitStatusChange}
            />
            {error?.testKitStatus && <Error>{error?.testKitStatus?.message}</Error>}
          </Box>
          {
            renderIsNullLocationToggle()
          }

        </Box>
        <Box textAlign={'center'}>
          <TextFieldInput field={testKitIdField} value={testKitId} handleChange={handleKitIdChange} />
          {error?.testKitIdErr && <Error>Please enter proper kit ID</Error>}
        </Box>
        <Box gridColumn={'2 / 3 '} display="flex" justifyContent={'center'} marginTop="-10px">
          <Button variant="contained" color="primary" onClick={handleSubmit} disabled={testKitLoading === 'pending'}>
            Simulate
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default TestKitSimulator;
