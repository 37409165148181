import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';

import { useSelector } from 'react-redux';
import { selectSystemTotals, selectSystemTotalsLoading } from './selectors';
import { SystemTotalInfoCard, SystemTotalCardLoading } from './SystemTotalInfoCard';

/**
 * Show text info card with custom bg color
 */
function SystemTotals({ isLoading: propsLoading }) {
  const classes = useStyles();

  const statistics = useSelector(selectSystemTotals);
  const systemTotalsLoading = useSelector(selectSystemTotalsLoading);

  const isLoading = propsLoading || systemTotalsLoading;

  return (
    <>
      <Typography variant="h6" className={classes.title}>
        System Totals
      </Typography>

      <Grid container item spacing={3} style={{ marginTop: 15 }}>
        <Grid item xs={6} md={6} lg>
          {isLoading || typeof (statistics?.totalUser ?? null) !== 'number' ? (
            <SystemTotalCardLoading />
          ) : (
            <SystemTotalInfoCard
              title="Total Users"
              value={statistics?.totalUser || 0}
              infoText="Members, Tenant Admin, Health Admins (Platform Admins are not in the count)"
            />
          )}
        </Grid>

        <Grid item xs={6} md={6} lg>
          {isLoading || typeof (statistics?.testCompleted ?? null) !== 'number' ? (
            <SystemTotalCardLoading />
          ) : (
            <SystemTotalInfoCard
              title="Tests Completed"
              value={statistics?.testCompleted || 0}
              infoText="Total tests completed in the tenant to date"
            />
          )}
        </Grid>

        <Grid item xs={6} md={6} lg>
          {isLoading || typeof (statistics?.positiveTest ?? null) !== 'number' ? (
            <SystemTotalCardLoading />
          ) : (
            <SystemTotalInfoCard
              title="Positive Tests"
              value={statistics?.positiveTest || 0}
              infoText="Total Positive tests completed in the tenant to date"
            />
          )}
        </Grid>

        <Grid item xs={6} md={6} lg>
          {isLoading ||
          (typeof (statistics?.vaccinatedUserInPercentage ?? null) !== 'number' &&
            typeof (statistics?.vaccinatedUserInPercentage ?? null) !== 'string') ? (
            <SystemTotalCardLoading />
          ) : (
            <SystemTotalInfoCard
              title="Vaccinated Users"
              value={`${statistics?.vaccinatedUserInPercentage || 0}%`}
              infoText="Percentage of total users"
            />
          )}
        </Grid>
      </Grid>
    </>
  );
}

// Info card styles
const useStyles = makeStyles({
  title: {
    fontFamily: 'Avenir',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 22,
    color: '#505358',
    lineHeight: '33px',
  },
});

export default SystemTotals;
