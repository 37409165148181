import moment from 'moment';
import React, { useEffect, useState } from 'react';

// redux
import { useDispatch, useSelector } from 'react-redux';

// images
import defaultImage from '../../assets/img/photo_default.png';

// components and helpers
// import { getMemberPhotos } from '../Image/imagesSlice';
// import history from '../Shared/Redux/history';
import { selectCurrentTenant } from '../Shared/selectors';
import AppKendoTableWrapper from '../Shared/Table/KendoTableWrapper';

import { getEncounterList } from './encountersSlice';
import { columns } from './helpers/encountersListMapper';
import { encounterTypes, memberStatesLabels } from './helpers/mapping';
import { selectImages, selectEncounters } from './selectors';

function EncountersListWrapper() {
  const images = useSelector(selectImages);
  const encounters = useSelector(selectEncounters);
  const currentTenant = useSelector(selectCurrentTenant);

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      setLoading(true);
      const data = {
        pageSize: 10,
        pageNumber: 0,
        term: '',
        stateFilter: [],
        encounterStatusFilter: [],
        encounterTypeFilter: [],
        tenantFilter: '',
        labReportReceivedFilter: [],
        reportedToGovernmentFilter: [],
        providerFilter: '',
        //   orderAsc: false,
        //   orderByCreated: true,
        //   orderByDeadline: false,
        //   orderByDefault: false,
        //   orderByEncounterType: false,
        //   orderByPatientName: false,
        //   orderByPhysicianName: false,
        //   orderByPriority: false,
        //   orderByState: false,
        //   orderByStatus: false,
      };

      await dispatch(getEncounterList(data));
      setLoading(false);
    })();
  }, [dispatch]);

  const handleTableUpdate = async (page, pageSize, sort, direction, searchValue, filter) => {
    setLoading(true);
    const data = {
      pageNumber: page,
      pageSize,
      term: searchValue,
      encounterStatusFilter: filter?.labStatus || [],
      stateFilter: filter?.state || [],
      orderAsc: direction === 'asc',
      orderByCreated: sort === 'created',
    };

    await dispatch(getEncounterList(data));
    setLoading(false);
  };

  // const handleOpenDetails = (rowData) => {
  //   history.push(`encounters/details/${rowData.id.replace(/\#/, "")}`);
  // };

  const getMemberPhoto = ({ contactInformationPrimaryEmail }) => images[contactInformationPrimaryEmail] || defaultImage;

  const filters = columns(encounters?.data).filter((c) => c.filter);
  // useEffect(() => {
  //   if (!(encounters && encounters.data && encounters.data.length)) return;
  //   const usernames = encounters.data.map(
  //     (en) => en.contactInformationPrimaryEmail
  //   );
  //   dispatch(getMemberPhotos(usernames));
  //   // eslint-disable-next-line
  // }, [encounters]);

  return (
    <AppKendoTableWrapper
      data={{
        ...encounters,
        items: encounters?.data.map((n) => ({
          ...n,
          id: `${n.id}`,
          assessmentLink: `/${currentTenant.id}/encounters/details/${n.id}`,
          patientImage: n ? getMemberPhoto(n) : defaultImage,
          encounterType: encounterTypes[n.encounterType].label,
          status: memberStatesLabels[n.status].label,
          created: moment(n.created).format('MM/DD/YYYY'),
          labStatus: '-',
          notificationSent: '06/11/2021 18:20',
        })),
      }}
      // showButtons={true}
      showSearchbar
      initialSort="created"
      initialDir="desc"
      columns={columns(encounters?.data)}
      filters={filters}
      onTableUpdate={handleTableUpdate}
      // onRowClick={handleOpenDetails}
      loading={loading}
      // showFilterIcon={filters && filters.length > 0}
      showOpenFilters
      oneLineSearchBar
    />
  );
}

export default EncountersListWrapper;
