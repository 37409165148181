import { createSlice } from '@reduxjs/toolkit';

import api from '../../../../../api/index';

const initialState = {};

export const typeaheadSlice = createSlice({
  name: 'typeahead',
  initialState,
  reducers: {
    typeaheadSearchRequest: (state, { payload }) => ({
      ...state,
      [payload]: {
        loading: true,
        error: null,
      },
    }),
    typeaheadSearchSuccess: (state, { payload }) => ({
      ...state,
      [payload.id]: {
        loading: false,
        values: payload.data,
      },
    }),
    typeaheadSearchFailure: (state, { payload }) => ({
      ...state,
      [payload.id]: {
        loading: false,
        error: payload.error,
      },
    }),
    reset: (state, { payload }) => {
      delete state[payload];
      return state;
    },
  },
});

export const { typeaheadSearchRequest, typeaheadSearchSuccess, typeaheadSearchFailure, reset } = typeaheadSlice.actions;

export const search = (id, searchClinical, type, term, size) => async (dispatch) => {
  dispatch(typeaheadSearchRequest(id));

  const data = {
    term,
    top: size || 0,
  };
  const [result, error] = await api.searchRequest(searchClinical, type, data);

  if (result) {
    dispatch(typeaheadSearchSuccess({ data: result, id }));
  }

  if (error && error.message) {
    /* eslint-disable-next-line no-console */
    console.log('ERROR', error);
    dispatch(typeaheadSearchFailure({ id, error: error.message }));
  }
};

export default typeaheadSlice.reducer;
