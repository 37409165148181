import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import DateInput from '../Shared/Forms/KendoFields/DateInput';
import DropdownField from '../Shared/Forms/KendoFields/DropdownField';
import TextFieldInput from '../Shared/Forms/KendoFields/TextFieldInput';
import ToggleInput from '../Shared/Forms/KendoFields/ToggleField';
import { selectTypeahead } from '../Shared/Forms/KendoFields/Typeahead/selectors';
import { search } from '../Shared/Forms/KendoFields/Typeahead/typeaheadSlice';
import { immunizationDialogFormMap } from './helpers/formHelper';

function ImmunizationDialog(props) {
  const { closeDialog, selectedImmunization, dialogMode, updateImmunization } = props;
  const typeahead = useSelector(selectTypeahead);

  const immunization = {
    id: '',
    vaccination: {},
    status: '',
    manufacturer: {},
    startDate: '',
    wasGiven: false,
    isReported: false,
    performer: {},
    requester: {},
    encounter: {},
    location: {},
    route: {},
    site: {},
    doseQuantity: {},
    reaction: [],
    observations: [],
    expiryDate: '',
    lotNumber: '',
    notes: '',
    publicityCode: {},
    vfsEligibility: {},
    publicityCodeEffectiveDate: '',
    registryStatusEffectiveDate: '',
    registryStatus: {},
    operationType: 'Add',
    explanation: '',
  };

  const [immunizationData, setImmunizationData] = useState(dialogMode === 'Edit' ? selectedImmunization : immunization);
  const [immunizations, setImmunizations] = useState([]);
  const [manufacturers, setManufacturers] = useState([]);
  const [routes, setRoutes] = useState([]);
  const [bodySites, setBodySites] = useState([]);
  const [timeout, setStateTimeout] = useState(undefined);

  const dispatch = useDispatch();

  const handleCancel = () => {
    closeDialog();
  };

  const immunizationFilterChange = (e) => {
    if (e?.filter?.value) {
      if (timeout) {
        clearTimeout(timeout);
      }
      setStateTimeout(
        setTimeout(() => {
          dispatch(search('Immunization', false, 'Immunization', e.filter.value, 10));
        }, 1000)
      );
    }
  };

  useEffect(() => {
    if (typeahead.Immunization && typeahead.Immunization.values) {
      const immunizations = [];
      typeahead.Immunization.values.forEach((x, i) => {
        immunizations[i] = {
          ...x,
          dropdownValue: `${x.cvX_Short_Description} ${x.manufacturer_Name ? `(${x.manufacturer_Name})` : ''}`,
        };
      });
      setImmunizations(immunizations);
    }
  }, [typeahead, typeahead.Immunization]);

  const immunizationChange = (e) => {
    setImmunizationData({
      ...immunizationData,
      vaccination: e.target.value,
    });
  };

  const manufacturerFilterChange = (e) => {
    if (e.filter) {
      dispatch(search('Manufacturer', false, 'Manufacturer', e.filter.value, 10));
    }
  };

  useEffect(() => {
    if (typeahead.Manufacturer && typeahead.Manufacturer.values) {
      setManufacturers(typeahead.Manufacturer.values);
    }
  }, [typeahead, typeahead.Manufacturer]);

  const manufacturerChange = (e) => {
    const manufacturer = {
      manufacturer_id: e?.value?.manufacturer_id || '',
      manufacturer_name: e?.value?.manufacturer_name || '',
      mvX_CODE: e?.value?.mvX_CODE || '',
    };
    setImmunizationData({ ...immunizationData, manufacturer });
  };

  const handleFilterChange = (e, filterName) => {
    if (e?.filter?.value) {
      if (timeout) {
        clearTimeout(timeout);
      }
      setStateTimeout(
        setTimeout(() => {
          dispatch(search(filterName, false, filterName, e.filter.value, 10));
        }, 1000)
      );
    }
  };

  useEffect(() => {
    if (typeahead.hl7route && typeahead.hl7route.values) {
      setRoutes(typeahead.hl7route.values);
    }
  }, [typeahead, typeahead.hl7route]);

  const routeChange = (e) => {
    setImmunizationData({ ...immunizationData, route: e.target.value });
  };

  const explanationChange = (e) => {
    setImmunizationData({ ...immunizationData, explanation: e.target.value });
  };

  const notesChange = (e) => {
    setImmunizationData({ ...immunizationData, notes: e.target.value });
  };

  useEffect(() => {
    if (typeahead.hl7site && typeahead.hl7site.values) {
      setBodySites(typeahead.hl7site.values);
    }
  }, [typeahead, typeahead.hl7site]);

  const bodySiteChange = (e) => {
    setImmunizationData({ ...immunizationData, site: e.target.value });
  };

  const field = immunizationDialogFormMap();

  const handleChangeWithParams = useCallback(
    (name, value) => {
      if (name.target && name.target.value) {
        if (name.target.name === 'dose') {
          setImmunizationData({
            ...immunizationData,
            doseQuantity: {
              ...immunizationData.doseQuantity,
              value: Number.parseFloat(name.target.value),
            },
          });
        }
        if (name.target.name === 'doseUnit') {
          setImmunizationData({
            ...immunizationData,
            doseQuantity: {
              ...immunizationData.doseQuantity,
              unit: name.target.value,
            },
          });
        }
        if (name.target.name === 'lotNumber') {
          setImmunizationData({
            ...immunizationData,
            lotNumber: name.target.value,
          });
        }
      } else if (name === 'status') {
        setImmunizationData({ ...immunizationData, status: value });
      } else if (name === 'administeredDate' && value) {
        setImmunizationData({ ...immunizationData, startDate: value });
      } else if (name === 'expiryDate' && value) {
        setImmunizationData({ ...immunizationData, expiryDate: value });
      } else if (name === 'wasGiven' && value) {
        setImmunizationData({ ...immunizationData, wasGiven: value });
      } else if (name === 'isPrimary' && value) {
        setImmunizationData({ ...immunizationData, isPrimary: value });
      } else if (name === 'notes' && value) {
        setImmunizationData({ ...immunizationData, notes: value });
      } else if (name === 'explanation' && value) {
        setImmunizationData({ ...immunizationData, explanation: value });
      }
    },
    [immunizationData]
  );

  const handleAddEdit = () => {
    if (
      immunizationData != null &&
      immunizationData.vaccination != null &&
      Object.keys(immunizationData.vaccination).length !== 0 &&
      immunizationData.status &&
      immunizationData.startDate
    ) {
      if (dialogMode === 'Edit') {
        setImmunizationData({ ...immunizationData, operationType: 'Edit' });
      }
      updateImmunization(immunizationData);
      closeDialog();
    }
  };

  return (
    <Dialog title={`${dialogMode} Immunization`} onClose={handleCancel} width={800}>
      <Grid container spacing={2}>
        <FormFieldComponent>
          <DropdownComponent
            name="immunization"
            label="Immunization Name"
            data={immunizations}
            textField="dropdownValue"
            onFilterChange={immunizationFilterChange}
            onChange={immunizationChange}
            value={
              immunizationData.vaccination != null && Object.keys(immunizationData.vaccination).length !== 0
                ? immunizationData.vaccination
                : null
            }
            loading={typeahead.Immunization ? typeahead.Immunization.loading : false}
          />
        </FormFieldComponent>
        <FormFieldComponent>
          <TextFieldInput
            field={field[0]}
            value={immunizationData.vaccination ? immunizationData.vaccination.cvX_Code : ''}
            disabled
          />
        </FormFieldComponent>
        <FormFieldComponent>
          <DropdownField
            label="Status"
            field={field[6]}
            value={immunizationData.status}
            handleChangeWithParams={handleChangeWithParams}
          />
        </FormFieldComponent>
        <FormFieldComponent>
          <DropdownComponent
            name="manufacturer"
            label="Manufacturer"
            data={manufacturers}
            textField="manufacturer_name"
            onFilterChange={manufacturerFilterChange}
            onChange={manufacturerChange}
            value={immunizationData.manufacturer}
            loading={typeahead.Manufacturer ? typeahead.Manufacturer.loading : false}
          />
        </FormFieldComponent>
        <FormFieldComponent>
          <DropdownComponent
            name="route"
            label="Route"
            data={routes}
            textField="display"
            onFilterChange={(e) => handleFilterChange(e, 'hl7route')}
            onChange={routeChange}
            value={immunizationData.route}
            loading={typeahead.hl7route ? typeahead.hl7route.loading : false}
          />
        </FormFieldComponent>
        <FormFieldComponent>
          <DropdownComponent
            name="bodySite"
            label="Body Site"
            data={bodySites}
            textField="display"
            onFilterChange={(e) => handleFilterChange(e, 'hl7site')}
            onChange={bodySiteChange}
            value={immunizationData.site}
            loading={typeahead.hl7site ? typeahead.hl7site.loading : false}
          />
        </FormFieldComponent>
        <FormFieldComponent>
          <DateInput
            field={field[1]}
            value={immunizationData.startDate}
            handleChangeWithParams={handleChangeWithParams}
          />
        </FormFieldComponent>
        <FormFieldComponent>
          <DateInput
            field={field[2]}
            value={immunizationData.expiryDate}
            handleChangeWithParams={handleChangeWithParams}
          />
        </FormFieldComponent>
        <FormFieldComponent>
          <TextFieldInput field={field[3]} value={immunizationData.explanation} handleChange={explanationChange} />
        </FormFieldComponent>
        <FormFieldComponent>
          <ToggleInput
            field={field[9]}
            value={immunizationData.wasGiven}
            handleChangeWithParams={handleChangeWithParams}
          />
        </FormFieldComponent>
        <FormFieldComponent>
          <ToggleInput
            field={field[10]}
            value={immunizationData.isPrimary}
            handleChangeWithParams={handleChangeWithParams}
          />
        </FormFieldComponent>
        <FormFieldComponent>
          <TextFieldInput
            field={field[7]}
            value={immunizationData.doseQuantity ? immunizationData.doseQuantity.value : ''}
            handleChange={handleChangeWithParams}
          />
        </FormFieldComponent>
        <FormFieldComponent>
          <TextFieldInput
            field={field[8]}
            value={immunizationData.doseQuantity ? immunizationData.doseQuantity.unit : ''}
            handleChange={handleChangeWithParams}
          />
        </FormFieldComponent>
        <FormFieldComponent>
          <TextFieldInput field={field[4]} value={immunizationData.lotNumber} handleChange={handleChangeWithParams} />
        </FormFieldComponent>
        <FormFieldComponent>
          <TextFieldInput field={field[5]} value={immunizationData.notes} handleChange={notesChange} />
        </FormFieldComponent>
      </Grid>
      <DialogActionsBar>
        <button className="k-button" onClick={handleCancel}>
          Cancel
        </button>
        <button className="k-button" onClick={handleAddEdit}>
          {dialogMode === 'Add' ? 'Add' : 'Edit'}
        </button>
      </DialogActionsBar>
    </Dialog>
  );
}

function FormFieldComponent(props) {
  const { children: content } = props;

  return (
    <Grid item xs={4}>
      <Box>
        <div>{content}</div>
      </Box>
    </Grid>
  );
}
function DropdownComponent(props) {
  const { name, label, data, textField, onFilterChange, onChange, value, loading } = props;

  return (
    <ComboBox
      data={data}
      allowCustom={false}
      suggest
      filterable
      onFilterChange={onFilterChange}
      name={name}
      label={label}
      required
      onChange={onChange}
      style={{ width: '100%' }}
      textField={textField}
      value={value}
      loading={loading}
    />
  );
}

export default ImmunizationDialog;
