import Grid from '@material-ui/core/Grid';
import { useTheme, withStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import DragExpansionWrapperStyles from '../../assets/jss/components/GroupedDraggableItemsWrapperStyles';

export function DragExpansionWrapper(props) {
  const { classes, renderItem, data, onOrderChanged } = props;
  const [panelData, setPanelData] = useState(undefined);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  useEffect(() => {
    if (data) {
      setPanelData(data);
    }
  }, [data]);

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  // ======== DRAG AND DROP REORDERING FUNCTIONALITY ======== //
  // fired when reordering with drag and drop
  const onDragEnd = (result) => {
    const { destination, source } = result;
    if (!destination) {
      return;
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    const newData = reorder(data, result.source.index, result.destination.index);

    setPanelData(newData);
    // TODO: save newData
    onOrderChanged(newData);
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} data-testid="dndComponent">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="form-1">
            {(provided) => (
              <Grid container innerRef={provided.innerRef} {...provided.droppableProps}>
                {panelData &&
                  panelData.map((q, i) => (
                    <Draggable key={q.question || `${q.key}_${i}`} draggableId={`draggable${i}`} index={i}>
                      {(provided) => (
                        <Grid
                          container
                          justifyContent="center"
                          alignItems="center"
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          innerRef={provided.innerRef}
                          className={classes.hoverParent}
                        >
                          <Grid item xs={1}>
                            {matches && <DragIndicatorIcon className={classes.hoverChild} />}
                          </Grid>
                          <Grid
                            item
                            xs={11}
                            style={{
                              padding: '10px 0 10px',
                            }}
                          >
                            {renderItem(q, i)}
                          </Grid>
                        </Grid>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </Grid>
            )}
          </Droppable>
        </DragDropContext>
      </Grid>
    </Grid>
  );
}

export default withStyles(DragExpansionWrapperStyles)(DragExpansionWrapper);
