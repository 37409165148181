// material ui
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

// Kendo
import { Pager } from '@progress/kendo-react-data-tools';
import { Grid, GridColumn, GridNoRecords } from '@progress/kendo-react-grid';
import React, { useEffect, useState, useRef } from 'react';

import { TableSkeletonLoading } from './TableSkeletonLoading';

function KendoTable(props) {
  const {
    columns,
    data,
    onRowClick,
    onSort,
    onPaginationChange,
    page,
    pageSize,
    sorted,
    onSelectionChange,
    onHeaderSelectionChange,
    detailsComponent,
    paginationPages,
    loading,
    noRecordsMessage,
    sortingMode,
    selectIds,
    selectAll,
  } = props;

  const columnsToShow = (columns && columns.filter((c) => c.show)) || [];
  const [sortedBy, setSortedBy] = useState(sorted ? [{ ...sorted }] : null);
  const [containerWidth, setContainerWidth] = useState(null);
  const [equalWidth, setEqualWidth] = useState(undefined);
  const container = useRef(null);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const matchesSM = useMediaQuery(theme.breakpoints.up('sm'));

  const UpdatedItems = selectIds
    ? data?.items?.map((item) => ({
        ...item,
        selected: !selectAll ? selectIds.indexOf(item._id) > -1 : !(selectIds.indexOf(item._id) > -1),
      }))
    : data.items;

  useEffect(() => {
    const calculateColumnsWidth = () => {
      if (container && container.current) {
        const columnsWithWidthLimit = columnsToShow.filter((c) => c.minWidth > 0 || c.maxWidth > 0);
        let newEqualWidth = Math.floor(container.current.offsetWidth / columnsToShow.length);

        const columnsToRecalc = columnsWithWidthLimit.filter(
          (c) => c.minWidth > newEqualWidth || newEqualWidth > c.maxWidth
        );

        if (columnsToRecalc.length > 0) {
          const widthsAdded = columnsToRecalc.reduce(
            // eslint-disable-next-line
            (accum, c) => (accum += newEqualWidth < c.minWidth ? c.minWidth : c.maxWidth),
            0
          );

          // the logic was a bit fuzzy, but basically, we try equalize the remaining widths to those without min and max width by removing those with min and max width
          newEqualWidth = Math.floor(
            (container.current.offsetWidth - widthsAdded) / (columnsToShow.length - columnsToRecalc.length)
          );
        }

        if (newEqualWidth !== equalWidth || !newEqualWidth) {
          setEqualWidth(newEqualWidth);
        }
      }
    };

    const handleResize = () => {
      if (matches) {
        calculateColumnsWidth();
      }
    };

    window.addEventListener('resize', handleResize);

    if (container && container.current && containerWidth !== container.current.offsetWidth) {
      calculateColumnsWidth();

      setContainerWidth(container.current.offsetWidth);
    }
  }, [columnsToShow, containerWidth, equalWidth, matches]);

  const handlePageChange = (event) => {
    const { skip, take } = event;
    const page = skip / take;
    if (onPaginationChange) {
      onPaginationChange(page, take);
    }
  };

  const handleSelectionChange = (event) => {
    if (onSelectionChange) {
      onSelectionChange(event.dataItem);
    }
  };

  const rowRender = (trElement, props) => {
    if (props.dataItem.addedToQuestionnaire) {
      const disabled = props.dataItem.addedToQuestionnaire;
      const trProps = {
        style: {
          backgroundColor: 'none',
        },
      };

      if (disabled) {
        const tr = <tr className="k-master-row" role="row" />;
        return React.cloneElement(tr, { ...trProps }, trElement.props.children);
      }
    }

    return React.cloneElement(trElement, {}, trElement.props.children);
  };

  const getColumnWidth = (c) => {
    if (matches) {
      if (c.minWidth && equalWidth < c.minWidth) return c.minWidth;
      if (c.maxWidth && equalWidth > c.maxWidth) return c.maxWidth; // include max width configuration
      return equalWidth;
    }
    return c.width || c.minWidth || c.maxWidth;
  };

  const getPageTotal = (data) => {
    if (data.total && data.total.toString()) return data.total;
    if (data.totalElements && data.totalElements.toString()) return data.totalElements;
    return 0;
  };

  return (
    <div style={{ position: 'relative' }} ref={container}>
      {data && data.items && columns && (
        <>
          {loading ? (
            <TableSkeletonLoading desktop={1} />
          ) : (
            <Grid
              sortable={{ mode: sortingMode }}
              sort={sortedBy}
              style={{ cursor: onRowClick && 'pointer', borderRadius: '20px' }}
              selectedField="selected"
              onSelectionChange={handleSelectionChange}
              onHeaderSelectionChange={onHeaderSelectionChange}
              expandField={detailsComponent && 'expanded'}
              detail={detailsComponent}
              rowRender={rowRender}
              selectable={{
                enabled: true,
                drag: false,
                cell: false,
                mode: 'multiple',
              }}
              onSortChange={(e) => {
                setSortedBy(e.sort);
                if (onSort) {
                  if (sortingMode === 'single') {
                    onSort(e.sort.length ? e.sort[0].field : null, e.sort.length ? e.sort[0].dir : 'asc');
                  } else {
                    onSort(e.sort);
                  }
                }
              }}
              data={UpdatedItems}
              onRowClick={(e) => {
                if (onRowClick) {
                  onRowClick(e.dataItem);
                }
              }}
            >
              <GridNoRecords>{noRecordsMessage}</GridNoRecords>
              {columns
                .filter((c) => c.show)
                .map((c) => (
                  <GridColumn
                    key={c}
                    cell={c.cell}
                    field={c.id}
                    title={c.label}
                    sortable={c.sortable}
                    locked={matches ? false : c.locked}
                    // for select all checkbox - checks if any in list are false
                    headerSelectionValue={
                      data && data.items && UpdatedItems.findIndex((dataItem) => dataItem.selected === false) === -1
                    }
                    // Custom Header Checkbox with Indeterminate state
                    headerCell={c.id === 'selected' && !!c.headerCell ? c.headerCell(selectIds, selectAll, data) : null}
                    width={getColumnWidth(c)}
                  />
                ))}
            </Grid>
          )}
          {typeof page === 'number' && (
            <Pager
              skip={page * pageSize}
              take={pageSize}
              total={getPageTotal(data)}
              buttonCount={matchesSM ? 5 : 1}
              info
              type="numeric"
              pageSizes={matchesSM ? paginationPages : []}
              previousNext
              onPageChange={handlePageChange}
            />
          )}
        </>
      )}
    </div>
  );
}

export default KendoTable;
