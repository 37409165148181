// Assembles questionnaire form data into Json objects needed for API
import { getFieldName } from '../../QuestionBuilder/helpers/formHelper';

export const questionnaireJsonBuilder = (formData) => ({
  ...formData,
  title: translateObject(formData, 'title', formData.languageChoices),
  questionList:
    formData.questionList &&
    formData.questionList.map((question) => ({
      ...question,
      name: translateObject(question, 'name', question.languageChoices),
      description: translateObject(question, 'description', question.languageChoices),
      coding: {
        system: question.system ? question.system : question.coding && question.coding.system,
        code: question.code ? question.code : question.coding && question.coding.code,
        description: question.codeDescription
          ? question.codeDescription[getFieldName(question.system)]
          : question.coding?.description,
      },
      report: {
        target: question.reporterTarget ? question.reporterTarget : question.report && question.report.target,
        text: question.reporterFormat ? question.reporterFormat : question.report && question.report.text,
      },
      help: translateObject(question, 'help', question.languageChoices),
      options:
        question.options &&
        question.options.map((option) => {
          if (typeof option === 'object') {
            return {
              ...option,
              value: translateObject(option, 'value', question.languageChoices),
              instructionText:
                typeof option.instructionText === 'string'
                  ? translateObject(option, 'instructionText', question.languageChoices)
                  : option.instructionText,
            };
          }
          return option;
        }),
      fields:
        question.fields &&
        question.fields.map((field) => ({
          ...field,
          name: translateObject(field, 'fieldName', question.languageChoices),
        })),
      min: question.type === 'date' ? question.min * question.minBeforeAfter : question.min,
      max: question.type === 'date' ? question.max * question.maxBeforeAfter : question.max,
      testList:
        question.testList &&
        question.testList.map((test) => {
          if (test.test) {
            const flattenedTest = {
              laboratoryTestSource: test.test.laboratoryTestSource,
              orderableTestId: test.test.orderableTestId,
              price: test.test.price,
              panel: test.test.panel,
              testId: test.test.testId,
              vendorId: test.test.vendorId,
              vendorName: test.test.vendorName,
            };
            if (test.test?.panel) {
              flattenedTest.autoSelectedOrderableTestList = test.autoSelectedOrderableTestList;
              flattenedTest.orderableTestPanelId = test.test.orderableTestPanelId;
            }
            return flattenedTest;
          }
          return test;
        }),
    })),
  nodes:
    formData.nodes &&
    formData.nodes.map((node) => ({
      ...node,
      links:
        node.links &&
        node.links.map((link) => ({
          ...link,
          name: translateObject(link, 'linkName', formData.languageChoices),
        })),
      question: node.question && {
        ...node.question,
        name: translateObject(node.question, 'name', node.question.languageChoices),
        description: translateObject(node.question, 'description', node.question.languageChoices),
        help: translateObject(node.question, 'help', node.question.languageChoices),
        options:
          node.question.options &&
          node.question.options.map((option) => {
            if (typeof option === 'object') {
              return {
                ...option,
                value: translateObject(option, 'value', node.question.languageChoices),
                instructionText:
                  typeof option.instructionText === 'string'
                    ? translateObject(option, 'instructionText', node.question.languageChoices)
                    : option.instructionText,
              };
            }
            return option;
          }),
        fields:
          node.question.fields &&
          node.question.fields.map((field) => ({
            ...field,
            name: translateObject(field, 'fieldName', node.question.languageChoices),
          })),
        min: node.question.type === 'date' ? node.question.min * node.question.minBeforeAfter : node.question.min,
        max: node.question.type === 'date' ? node.question.max * node.question.maxBeforeAfter : node.question.max,
        testList:
          node.question.testList &&
          node.question.testList.map((test) => {
            if (test.test) {
              const flattenedTest = {
                laboratoryTestSource: test.test.laboratoryTestSource,
                orderableTestId: test.test.orderableTestId,
                price: test.test.price,
                panel: test.test.panel,
                testId: test.test.testId,
                vendorId: test.test.vendorId,
                vendorName: test.test.vendorName,
              };
              if (test.test?.panel) {
                flattenedTest.autoSelectedOrderableTestList = test.autoSelectedOrderableTestList;
                flattenedTest.orderableTestPanelId = test.test.orderableTestPanelId;
              }
              return flattenedTest;
            }
            return test;
          }),
      },
      outcome: node.outcome && {
        ...node.outcome,
        name: translateObject(node.outcome, 'name', node.outcome.languageChoices),
        title: translateObject(node.outcome, 'title', node.outcome.languageChoices),
        description: translateObject(node.outcome, 'description', node.outcome.languageChoices),
        coding: {
          system: node.outcome.system ? node.outcome.system : node.outcome.coding?.system,
          code: node.outcome.code ? node.outcome.code : node.outcome.coding?.code,
          description: node.outcome.codeDescription
            ? node.outcome.codeDescription[getFieldName(node.outcome.system)]
            : node.outcome.coding?.description,
        },
        report: {
          target: node.outcome.reporterTarget ? node.outcome.reporterTarget : node.outcome.report?.target,
          text: node.outcome.reporterFormat ? node.outcome.reporterFormat : node.outcome.report?.text,
        },
        medicationList:
          node.outcome.medicationList &&
          node.outcome.medicationList.map((medication) => ({
            ...medication,
            name: medication.medicationName,
          })),
      },
    })),
});

// Take object from above Json builder and create Json objects for each language in use
const translateObject = (parent, obj, languageChoices) => {
  const translations = {};
  const validated = [];
  let selectedLanguages;
  if (languageChoices && languageChoices.length) {
    selectedLanguages = languageChoices.map((language) => language.split(' = ')[0]);
  }

  if (selectedLanguages) {
    selectedLanguages.forEach((lang) => {
      translations[lang] = parent[`${obj}-${lang}`];
      if (parent[`${obj}Valid-${lang}`]) validated.push(lang);
    });
  }

  // Assign default to English entry
  translations.eng = parent[obj] || parent[`${obj}-eng`];

  // Create object for return
  const translationsObject = {
    translations,
    validatedLanguages: validated,
  };
  return translationsObject;
};
