import React from 'react';
import ContentLoader from 'react-content-loader';

export function SidebarSkeleton() {
  return (
    <ContentLoader viewBox="0 0 100% 100vh" height="100vh" width="100%">
      <rect x="0" y="0" width="56" height="95vh" />
    </ContentLoader>
  );
}
