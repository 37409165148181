/* eslint-disable import/no-cycle */
// component imports
import Grid from '@material-ui/core/Grid';
import React from 'react';

import AutocompleteInput from '../../../Shared/Forms/KendoFields/AutocompleteInput';

import AppAddressInput from './fields/AddressInput';
import BooleanField from './fields/BooleanField';
import AppComboQuestion from './fields/ComboQuestion';
import ConsentForm from './fields/ConsentForm';
import DateInputKendo from './fields/DateInput';
import DropdownField from './fields/DropdownField';
import EmailInput from './fields/EmailInput';
import GraphicSignature from './fields/GraphicSignature';
import Previews from './fields/ImageUploadCrop';
import AppInsuranceInput from './fields/InsuranceInput/InsuranceInput';
import LabOrderQuestion from './fields/LabOrderQuestion/LabOrderQuestion';
import MultipleChoiceInput from './fields/MultipleChoiceInput';
import MultipleTextInput from './fields/MultipleTextInput';
import NumberFieldInput from './fields/NumberFieldInput';
import PhoneInputField from './fields/PhoneInputField';
import ScaleInput from './fields/ScaleInput';
import SliderInput from './fields/SliderInput';
import TextFieldInput from './fields/TextFieldInput';
import VerificationCode from './fields/VerificationCode';

// Map through form helper json to dynamically build form
export function createInputHelper(
  helper,
  values,
  handleChange,
  handleChangeWithParams,
  autoSetValues,
  disabled,
  getChildren,
  selectedLanguage
) {
  const fieldInputs = [];
  helper.forEach((field) => {
    if (
      field.type === 'text' ||
      field.type === 'small-text' ||
      field.type === 'large-text' ||
      field.type === 'password' ||
      (field.type === 'zip' && !field.hidden)
    ) {
      // for password
      if (field.type === 'password') {
        field.sensitive = true;
      }

      fieldInputs.push(
        <Grid key={field.id} item md={field.width} xs={12} style={{ paddingTop: 10, paddingBottom: 10 }}>
          <TextFieldInput
            field={field}
            value={values[field.id]}
            handleChangeWithParams={handleChangeWithParams}
            disabled={disabled || field.disabled}
            selectedLanguage={selectedLanguage}
          />
        </Grid>
      );
    } else if (field.type === 'multiple-small-text') {
      fieldInputs.push(
        <Grid key={field.id} item md={field.width} xs={12} style={{ paddingTop: 10, paddingBottom: 10 }}>
          <MultipleTextInput
            field={field}
            value={values[field.id]}
            handleChangeWithParams={handleChangeWithParams}
            disabled={disabled || field.disabled}
            selectedLanguage={selectedLanguage}
          />
        </Grid>
      );
    } else if (field.type === 'date') {
      fieldInputs.push(
        <Grid key={field.id} item md={field.width} xs={12} style={{ paddingTop: 10, paddingBottom: 10 }}>
          <DateInputKendo
            field={field}
            value={values[field.id]}
            handleChangeWithParams={handleChangeWithParams}
            disabled={disabled}
            selectedLanguage={selectedLanguage}
          />
        </Grid>
      );
    } else if (field.type === 'email') {
      fieldInputs.push(
        <Grid key={field.id} item md={field.width} xs={12} style={{ paddingTop: 10, paddingBottom: 10 }}>
          <EmailInput
            field={field}
            initialValue={values[field.id]}
            disabled={disabled}
            handleChangeWithParams={handleChangeWithParams}
            selectedLanguage={selectedLanguage}
          />
        </Grid>
      );
    } else if (field.type === 'phoneCode' || field.type === 'emailCode') {
      fieldInputs.push(
        <Grid key={field.id} item md={field.width} xs={12} style={{ marginTop: 6 }}>
          <VerificationCode
            field={field}
            value={values[field.id]}
            handleChangeWithParams={handleChangeWithParams}
            autoSetValues={autoSetValues}
            disabled={disabled}
            selectedLanguage={selectedLanguage}
          />
        </Grid>
      );
    } else if (field.type === 'consent') {
      fieldInputs.push(
        <Grid key={field.id} item xs={12} md={field.width} style={{ paddingTop: 10, paddingBottom: 10 }}>
          <ConsentForm
            field={field}
            value={values[field.id]}
            handleChange={handleChange}
            handleChangeWithParams={handleChangeWithParams}
            disabled={disabled}
            selectedLanguage={selectedLanguage}
          />
        </Grid>
      );
    } else if (field.type === 'multiple') {
      fieldInputs.push(
        <Grid key={field.id} item xs={12} md={field.width} style={{ paddingTop: 10, paddingBottom: 10 }}>
          <MultipleChoiceInput
            field={field}
            value={values[field.id]}
            handleChangeWithParams={handleChangeWithParams}
            handleChange={handleChange}
            disabled={disabled}
            selectedLanguage={selectedLanguage}
          />
        </Grid>
      );
    } else if (field.type === 'numeric') {
      fieldInputs.push(
        <Grid key={field.id} item md={field.width} xs={12} style={{ paddingTop: 10, paddingBottom: 10 }}>
          <NumberFieldInput
            field={field}
            value={values[field.id]}
            handleChangeWithParams={handleChangeWithParams}
            disabled={disabled}
            selectedLanguage={selectedLanguage}
          />
        </Grid>
      );
    } else if (field.type === 'scale') {
      fieldInputs.push(
        <Grid key={field.id} item xs={12} md={field.width} style={{ paddingTop: 10, paddingBottom: 10 }}>
          <ScaleInput
            field={field}
            value={values[field.id]}
            handleChangeWithParams={handleChangeWithParams}
            disabled={disabled}
            selectedLanguage={selectedLanguage}
          />
        </Grid>
      );
    } else if (field.type === 'slider') {
      fieldInputs.push(
        <Grid key={field.id} item xs={12} md={field.width} style={{ paddingTop: 10, paddingBottom: 10 }}>
          <SliderInput
            field={field}
            value={values[field.id]}
            handleChangeWithParams={handleChangeWithParams}
            disabled={disabled}
            selectedLanguage={selectedLanguage}
          />
        </Grid>
      );
    } else if (field.type === 'yes-no') {
      fieldInputs.push(
        <Grid key={field.id} item xs={12} md={field.width} style={{ paddingTop: 10, paddingBottom: 10 }}>
          <BooleanField
            field={field}
            value={values[field.id]}
            handleChange={handleChange}
            handleChangeWithParams={handleChangeWithParams}
            disabled={disabled}
            selectedLanguage={selectedLanguage}
          />
        </Grid>
      );
    } else if (field.type === 'phone') {
      fieldInputs.push(
        <Grid key={field.id} item md={field.width} xs={12} style={{ marginTop: 6 }}>
          <PhoneInputField
            field={field}
            value={values[field.id]}
            handleChangeWithParams={handleChangeWithParams}
            autoSetValues={autoSetValues}
            disabled={disabled}
            type="editing"
            selectedLanguage={selectedLanguage}
          />
        </Grid>
      );
    } else if (field.type === 'address') {
      fieldInputs.push(
        <Grid key={field.type} item md={field.width} xs={12} style={{ paddingTop: 10, paddingBottom: 10 }}>
          <AppAddressInput
            field={field}
            value={values[field.id]}
            handleChange={handleChange}
            handleChangeWithParams={handleChangeWithParams}
            selectedLanguage={selectedLanguage}
          />
        </Grid>
      );
    } else if (field.type === 'imageCrop' || field.type === 'photo') {
      fieldInputs.push(
        <Grid key={field.id} item md={field.width} xs={12} style={{ paddingTop: 10, paddingBottom: 10 }}>
          <Previews
            field={field}
            value={values[field.id]}
            handleChangeWithParams={handleChangeWithParams}
            disabled={disabled}
            selectedLanguage={selectedLanguage}
          />
        </Grid>
      );
    } else if (field.type === 'insurance') {
      fieldInputs.push(
        <Grid key={field.type} item md={field.width} xs={12} style={{ paddingTop: 10, paddingBottom: 10 }}>
          <AppInsuranceInput
            field={field}
            value={values[field.id]}
            handleChange={handleChange}
            handleChangeWithParams={handleChangeWithParams}
            selectedLanguage={selectedLanguage}
          />
        </Grid>
      );
    } else if (field.type === 'dropdown' || field.type === 'multiple-drop') {
      fieldInputs.push(
        <Grid key={field.id} item md={field.width} xs={12} style={{ paddingTop: 10, paddingBottom: 10 }}>
          <DropdownField
            field={field}
            value={values[field.name]}
            handleChangeWithParams={handleChangeWithParams}
            disabled={disabled}
            selectedLanguage={selectedLanguage}
          />
        </Grid>
      );
    } else if (field.type === 'autocomplete') {
      fieldInputs.push(
        <Grid key={field.id} item md={field.width} xs={12} style={{ paddingTop: 10, paddingBottom: 10 }}>
          <AutocompleteInput
            field={field}
            initialValue={values[field.name] || field.initialValue}
            handleChangeWithParams={handleChangeWithParams}
            handleChange={handleChange}
            disabled={disabled || field.disabled}
            autoSetValues={autoSetValues}
          />
        </Grid>
      );
    } else if (field.type === 'graphicSignature') {
      fieldInputs.push(
        <Grid key={field.name} item xs={12} style={{ paddingTop: 10, paddingBottom: 10 }}>
          <GraphicSignature
            field={field}
            handleChangeWithParams={handleChangeWithParams}
            disabled={disabled}
            selectedLanguage={selectedLanguage}
          />
        </Grid>
      );
    } else if (field.type === 'combo') {
      fieldInputs.push(
        <Grid key={field.id} item md={field.width} xs={12} style={{ paddingTop: 10, paddingBottom: 10 }}>
          <AppComboQuestion
            field={field}
            value={values[field.id]}
            handleChange={handleChange}
            handleChangeWithParams={handleChangeWithParams}
            selectedLanguage={selectedLanguage}
          />
        </Grid>
      );
    } else if (field.type === 'labOrder') {
      fieldInputs.push(
        <Grid key={field.id} item md={field.width} xs={12} style={{ paddingTop: 10, paddingBottom: 10 }}>
          <LabOrderQuestion
            field={field}
            value={values[field.id]}
            handleChangeWithParams={handleChangeWithParams}
            selectedLanguage={selectedLanguage}
          />
        </Grid>
      );
    }
  });
  return fieldInputs;
}
