// import React from 'react';

import { withCustomProp } from '../../Shared/Grid/helpers';
import * as htmlUtils from '../../Shared/Grid/helpers';
import DetailsActionCell from '../../Shared/Table/DetailsActionCell';
import CovidVaxStatusCustomCell from '../CovidVaxStatusCustomCell';
import OverriddenOnCell from '../Tabs/OverriddenOnCell';
import VaccineRecordActionCell from '../VaccineRecordActionCell';
import VaccineRecordCustomCell from '../VaccineRecordCustomCell';

export const assessmentHistoryColumns = () => [
  {
    id: 'started',
    label: 'Started',
    show: true,
    minWidth: 150,
    sortable: true,
  },
  {
    id: 'completed',
    label: 'Completed',
    show: true,
    minWidth: 150,
    sortable: true,
  },
  {
    id: 'finalOutcome',
    label: 'Outcome',
    show: true,
    minWidth: 150,
    sortable: true,
  },
];

export const consentColumns = (handleOpenContent) => [
  {
    id: 'title',
    label: 'Title',
    show: true,
    minWidth: 190,
    locked: true,
    sortable: true,
  },
  // {
  //   id: "location",
  //   label: "Location",
  //   show: true,
  //   minWidth: 170,
  //   sortable: false,
  // },
  {
    id: 'consented',
    label: 'Time of Consent',
    show: true,
    sortable: true,
    minWidth: 140,
  },
  {
    id: 'archived',
    label: 'Archived',
    show: true,
    minWidth: 170,
    sortable: false,
  },
  {
    id: 'details',
    label: 'Details',
    show: true,
    minWidth: 170,
    sortable: false,
    cell: withCustomProp(DetailsActionCell, 'onClick', handleOpenContent),
  },
];

export const vaccineRecordButtons = (onNewRecordClick) => [
  {
    title: 'New Record',
    buttonType: 'action',
    action: () => {
      onNewRecordClick();
    },
    variant: 'outlined',
  },
];

export const vaccineRecordColumns = () => [
  {
    id: 'issuer',
    label: 'Issuer',
    show: true,
    sortable: true,
    minWidth: 200,
  },
  {
    id: 'productName',
    label: 'Vaccine',
    show: true,
    sortable: true,
    minWidth: 200,
  },
  {
    id: 'lastAdministeredDate',
    label: 'Date Administered',
    show: true,
    sortable: true,
    minWidth: 150,
  },
  {
    id: 'lastEditDate',
    label: 'Date of Attestation',
    show: true,
    sortable: true,
    minWidth: 150,
  },
  {
    id: 'status',
    label: 'Record Status',
    show: true,
    sortable: true,
    minWidth: 150,
    cell: VaccineRecordCustomCell,
  },
  {
    id: 'actions',
    label: 'Actions',
    show: true,
    minWidth: 100,
    sortable: false,
    cell: VaccineRecordActionCell,
  },
];

export const covidVaccineHistoryColumns = () => [
  {
    id: 'vaccinationStatus',
    label: 'Vaccination Status',
    show: true,
    sortable: false,
    minWidth: 200,
  },
  {
    id: 'dateEntered',
    label: 'Date Entered',
    show: true,
    sortable: false,
    minWidth: 200,
  },
  {
    id: 'dateEdited',
    label: 'Date Edited',
    show: true,
    sortable: false,
    minWidth: 200,
  },
  {
    id: 'actions',
    label: 'Actions',
    show: true,
    minWidth: 100,
    sortable: false,
    cell: CovidVaxStatusCustomCell,
  },
];

export const covidVaccineHistoryButtons = (setCovidVaxStatusDialogOpen) => [
  {
    title: 'New Vax Status',
    buttonType: 'action',
    action: () => {
      setCovidVaxStatusDialogOpen(true);
    },
    variant: 'outlined',
  },
];

export const scanHistoryColumns = (handleOpenDetails) => [
  {
    id: 'checked',
    label: 'Time Scanned',
    show: true,
    minWidth: 170,
    sortable: true,
  },
  {
    id: 'checkerId',
    label: 'Verified by',
    show: true,
    minWidth: 200,
    locked: true,
  },
  {
    id: 'outcome',
    label: 'Outcome',
    show: true,
    minWidth: 140,
    sortable: true,
  },
  {
    id: 'overriddenOn',
    align: 'center',
    label: 'Overridden',
    show: false,
    minWidth: 100,
    sortable: true,
    cell: OverriddenOnCell,
  },
  {
    id: 'details',
    label: 'Details',
    show: false,
    minWidth: 170,
    sortable: false,
    cell: htmlUtils.withCustomProp(DetailsActionCell, 'onClick', handleOpenDetails),
  },
];

export const userMessageColumns = (handleOpenContent) => [
  {
    id: 'templateName',
    label: 'Message',
    show: true,
    minWidth: 170,
    sortable: false,
  },
  {
    id: 'communicationType',
    label: 'Type',
    show: true,
    minWidth: 170,
    locked: true,
    sortable: false,
  },
  {
    id: 'sent',
    label: 'Time sent',
    show: true,
    minWidth: 140,
  },
  {
    id: 'success',
    label: 'Status',
    show: true,
    minWidth: 170,
  },
  {
    id: 'details',
    label: 'Details',
    show: true,
    minWidth: 170,
    sortable: false,
    cell: withCustomProp(DetailsActionCell, 'onClick', handleOpenContent),
  },
];

export const profileInfoTabs = (permission, verifyIdCard, haveChildren, enableVaccinationRecord) => [
  {
    label: 'Account Information',
    value: 'default',
    show: true,
  },
  {
    label: 'Assessment History',
    value: 'assessment',
    show: permission === 'ADMINISTRATOR',
  },
  {
    label: 'Testing History',
    value: 'testing',
    show: permission === 'ADMINISTRATOR',
  },
  {
    label: 'Scan History',
    value: 'scans',
    show: permission === 'ADMINISTRATOR',
  },
  {
    label: 'Vaccination Status',
    value: 'covidVaccine',
    show: true,
  },
  {
    label: 'Vaccination Record',
    value: 'vaccineRecords',
    show: permission === 'ADMINISTRATOR' && enableVaccinationRecord,
  },
  {
    label: 'Consents',
    value: 'consents',
    show: true,
  },
  {
    label: 'Messages',
    value: 'messages',
    show: permission === 'ADMINISTRATOR',
  },
  // Hide this tab if Id Verification not enabled for the App in settings
  {
    label: 'ID Verification',
    value: 'verification',
    show: permission === 'ADMINISTRATOR' && verifyIdCard,
  },
  {
    label: 'Dependent accounts',
    value: 'dependentAccounts',
    show: haveChildren,
  },
];

export const dropdownActionMap = (userDetails, userAccountPermission) => [
  // /* Will be open for the future release */
  // {
  //   text: 'Reset Password',
  //   icon: 'reset',
  //   show: true,
  // },
  // / *Removed for 3.1 release */
  // {
  //   text: 'Resend Invitation',
  //   icon: 'email',
  //   show: userDetails?.settings?.firstTimeLogin,
  // },
  // /* Will be open for the future release */
  // {
  //   text: userDetails.status === statusMappings.DEACTIVATED.value ? 'Activate User' : 'Deactivate User',
  //   icon: userDetails.status === statusMappings.DEACTIVATED.value ? 'plus-outline' : 'cancel',
  //   show: userAccountPermission?.permission === 'ACCOUNT_ADMINISTRATOR',
  // },
  {
    text: 'Delete User from Account',
    icon: 'delete',
    show: userAccountPermission?.permission === 'ACCOUNT_ADMINISTRATOR',
  },
];
