export const statusMapping = {
  0: {
    label: 'Inactive',
    background: 'red',
    color: 'white',
  },
  1: {
    label: 'Active',
    background: 'green',
    color: 'white',
  },
};

export const publishMapping = {
  false: {
    label: 'Unpublished',
    background: 'grey',
    color: 'white',
  },
  true: {
    label: 'Published',
    background: 'green',
    color: 'white',
  },
};

export const introWidgetTypes = [
  {
    value: 'Startup',
    label: 'Startup',
  },
  {
    value: 'DiagnosticTest',
    label: 'Diagnostic Test',
  },
];
