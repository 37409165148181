import React from 'react';

// import utils from '../../Shared/Utils/utils';

function UserMessageContentPanel(props) {
  const { dataItem } = props;

  const html = dataItem.content || dataItem.errorMessage;
  return (
    <section>
      <iframe
        srcDoc={html}
        id="content_iFrame"
        style={{
          border: 0,
          height: 600,
          width: '100%',
          paddingTop: 20,
        }}
        title="User Message"
      />
    </section>
  );
}

export default UserMessageContentPanel;
