import { IconButton, Popover, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

function InfoBox(props) {
  const { infoText, position } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <IconButton
        aria-label="info"
        className={[classes.infoIcon, classes[position]].join(' ')}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <InfoIcon style={{ width: 15, height: 15 }} />
      </IconButton>

      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography>{infoText}</Typography>
      </Popover>
    </>
  );
}

InfoBox.propTypes = {
  infoText: PropTypes.oneOf(['topRight', 'bottomRight', 'topLeft', 'bottomLeft']).isRequired,
};

// Info card styles
const useStyles = makeStyles(() => ({
  infoIcon: {
    position: 'absolute',
    color: '#DBDBDB',
    zIndex: 1,
  },
  topRight: {
    top: '6px',
    right: '6px',
  },
  bottomRight: {
    bottom: '6px',
    right: '6px',
  },
  topLeft: {
    top: '6px',
    left: '6px',
  },
  bottomLeft: {
    bottom: '6px',
    left: '6px',
  },
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    boxShadow: 'none',
    padding: 15,
    width: 200,
    borderRadius: 16,
    fontFamily: 'Avenir',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 14,
    lineHeight: '21px',
    color: '#505358',
    border: '1px solid #E0E0E0',
    overflowX: 'unset',
    overflowY: 'unset',
    '&::before': {
      backgroundColor: 'white',
      content: '""',
      display: 'block',
      position: 'absolute',
      width: 12,
      height: 12,
      left: -6,
      transform: 'rotate(45deg)',
      top: 'calc(50% - 6px)',
      borderLeft: '1px solid #E0E0E0',
      borderBottom: '1px solid #E0E0E0',
    },
  },
}));

export default InfoBox;
