import { Error } from '@progress/kendo-react-labels';
import { Box, Button, Divider, FormControl, Typography } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import DropdownField from '../../../Shared/Forms/KendoFields/DropdownField';
import TextFieldInput from '../../../Shared/Forms/KendoFields/TextFieldInput';
import NumberFieldInput from '../../../Shared/Forms/KendoFields/NumberFieldInput';
import { OverlayLoading } from '../../helper/SimulatorLoading';
import { useDispatch, useSelector } from 'react-redux';
import { resetBenefitLoading , loadEmployeeBenefit, resetBenefitData, updateEmployeeBenefit} from '../../slice/fvSimulatorSlice';
import { selectEmployeeBenefitLoading, selectEmployeeBenefitData, selectFvSubsLoading, selectFvSubsData, selectFvSubsErr } from '../../slice/selector';
import { showNotification } from '../../../Notifications/notificationSlice';
import { useLocation } from 'react-router-dom';
import { selectUserDetails } from '../../../Users/selectors';
import { useParams } from 'react-router-dom';
import { useReducer, useEffect } from 'react';
import { getUser } from '../../../Users/usersSlice';
import { useRef } from 'react';
import { addFvDevependent, loadSubsData, resetFvSubsData, resetFvSubsErr, updateFvDependent, updateFvSubscriber } from '../../slice/fvSubsSimulatorSlice';
import Subscriberform from './Subscriberform';
import SubscriberForm from './Subscriberform';
import { addDependent, dependentUpdaterFn, subscriberUpdaterFn } from './helpers';
import DependentForm from './DependentsForm';
import AccordionGroup from '../../helper/AccordionGroup';
import DependentModal from './DependentModal';

const stateReducer = (state, action) => ({
  ...state,
  // if it is a function
  ...(typeof action === 'function' ? action(state) : action),
});

/* default values */
const defaultState = {
  hasData: false,
  subscriber: {
    demographicInfo: {
      token: {
          cardTokenID: null,
      },
      name: {
        firstName: "",
        lastName: "",
      },
      phone: {
        phoneNumber1: "",
      },
      dateOfBirth: "" /* MMDDYYYY */,
      address: {
        addressLine1: "",
        city: "",
        state: "",
        postalcode: null /* INT */,
        country: ""
      }
    },
    healthCare: {
      memberID: "", /* AAAA AAAA e.g. WHM953424 */
      groupID: "", /* XX XX XX e.g 580033 */
      rxBIN: "", /* XXXX e.g 1638*/
      pCN: "", /* XXX e.g 694*/
    },
  },
  dependents: []
};

function FvDetailsSimulator({ userDetailsId, userDetailsLoading}) {
  const dispatch = useDispatch();
  const params = useParams();

  const fvSubsLoading = useSelector(selectFvSubsLoading);
  const fvSubsData = useSelector(selectFvSubsData);
  const fvSubsErr = useSelector(selectFvSubsErr);

  // const [subscriberOriginalData, setSubscriberOriginalData] = useState({});
  // const [dependentsOriginalData, setDependentsOriginalData] = useState([]);



  /* if FvSubs Error is NOT empty */
  const hasFvSubsErr = !(JSON.stringify(fvSubsErr) === '{}') && fvSubsErr !== null;

  const [state, setState] = useReducer(stateReducer, defaultState, (init) => {
    // Load data from Reducer after Thunk fetch
    if (fvSubsData && !hasFvSubsErr) {
      return {
        hasData: true,
        ...fvSubsData,
      };
    }
    return init;
  });

  /* need to get user's "_id" not just email/userId */
  const { userId } = params;
  const userDetails = useSelector(selectUserDetails);
  const isMounted = useRef(false);

  /* MODAL state */
  const [isAddModalOpen, setIsAddModalOpen] = useState(false)
  const handleOpenAddModal = () => {
    setIsAddModalOpen(true)
  }
  const handleCloseAddModal = () => {
    setIsAddModalOpen(false)
  }

  // console.log(userId)
  // console.log(userDetails)
  // console.log(fvSubsLoading)
  // console.log(fvSubsData)
  // console.log(state)

  /* Get User Details based on current userId */
  useEffect(() => {
    const getUserData = async () => {
      dispatch(getUser(userId, true));
    };
    getUserData();

    // Aside from Reducer initialization this will ensure Reducer data is synced with
    // local state reducer.
    // Load data from Reducer after Thunk fetch
    if (fvSubsData && !hasFvSubsErr) {
      setState({
        hasData: true,
        ...fvSubsData,
      });

    }
    isMounted.current = true;
  }, [userId, fvSubsData]);
  /* END - getting userDetails for _id */

  const { hasData, subscriber, dependents } = state;
  const [error, setError] = useState({});

  // console.log(hasData, subscriber, dependents)
  const hasSubsData = !!(subscriber?.token?.cardTokenID)

  // const accountId = localStorage.getItem('accountId');
  const tenantId = localStorage.getItem('tenantId');

  /* load the FirstView subscriber data when External Id is fetched and ready */
  useEffect(() => {
    const params = {
      tenantId,
      externalId: userDetails?.externalId,
    };
    dispatch(loadSubsData(params));

  }, [userId, userDetails?.externalId, dispatch, tenantId ]);

  useEffect(() => {
    return () => {
      dispatch(resetFvSubsErr());
      dispatch(resetFvSubsData());
      setState(defaultState)
    }
  }, [dispatch])

  /* Controls the state of laoding UI */
  useEffect(() => {
    // console.log(fvSubsLoading)
    if (fvSubsLoading === 'fulfilled' || fvSubsLoading === 'rejected') {
      dispatch(resetBenefitLoading());
    }
  }, [fvSubsLoading]);

  const handleSubscriberChange = useCallback((fieldName, fieldVal) => {
    /* Clear Error */
    let clearedErr = { ...error };
    delete clearedErr['subscriber'];
    setError({ ...clearedErr });

    setState(subscriberUpdaterFn(fieldName, fieldVal));

  }, [setState, error]);

  const handleDependentChange = useCallback((fieldName, fieldVal, idx) => {
    /* Clear Error */
    let clearedErr = { ...error };
    delete clearedErr['dependents'];
    setError({ ...clearedErr });

    setState(dependentUpdaterFn(fieldName, fieldVal, idx))

  }, [setState, error])


  const submitEditPrimary = useCallback(async (e) => {
    e.preventDefault();
    const hasError = validateInputs();

    if (hasError) {
      return null;
    }

    const InactivePayload = {
      "demographicInfo": {
          "name": {
              "firstName": null,
              "lastName": null
          },
          "phone": {
              "phoneNumber1": null
          },
          "dateOfBirth": null,
          "address": {
              "addressLine1": null,
              "city": null,
              "state": null,
              "postalcode": null,
              "country": null
          }
      },
      "status": "Inactive"
    }

    const dataPayload = subscriber.status === false ?
        InactivePayload
      :
        {
          ...subscriber,
          status: null
        }

    const payload = {
      tenantId,
      externalId: userDetails?.externalId ?? '',
      // formData: {
      //   ...subscriber,
      //   // IMPORTANT!!
      //   // For updates to take effect need to set the status to NULL,
      //   // otherwise if you want to disable the member then need to set to "Inactive"
      //   status: subscriber.status === 'Active' || subscriber.status === true ?
      //   null : (subscriber.status === false && 'Inactive')
      // }
      formData: dataPayload
    }

    try {
      const result = await dispatch(updateFvSubscriber(payload));

      if (result.error) {
        dispatch(showNotification(`Failed to update FirstView data <br />Error: ${result.error.message}`, 'error'));
        dispatch(resetBenefitLoading());
        return;
      }

      if (result.payload[1]?.status >= 400) {
        dispatch(showNotification(`Failed to update FirstView data <br />Error: ${result?.payload[1]?.message}`, 'error'));
        dispatch(resetBenefitLoading());
        return;
      }

      if (result.payload[0]?.success) {
        dispatch(
          showNotification(`${result?.payload[0]?.message} <br />FirstView data updated successfully`, 'success')
        );
        return;
      }
    } catch (error) {
      dispatch(showNotification(`Failed to update FirstView data <br />Error: ${error.message}`, 'error'));
    }

  }, [dispatch, tenantId, userDetails?.externalId, subscriber]);


  /* SUBMIT UPDATE DEPENDENT */
  const submitUpdatetDependent= useCallback(async (dependentIdx) => {
    const hasError = validateInputs();

    if (hasError) {
      return null;
    }

    const dependentUpdate = dependents[dependentIdx];

    const InactivePayload = {
      "demographicInfo": {
          "name": {
              "firstName": null,
              "lastName": null
          },
          "phone": {
              "phoneNumber1": null
          },
          "dateOfBirth": null,
          "address": {
              "addressLine1": null,
              "city": null,
              "state": null,
              "postalcode": null,
              "country": null
          }
      },
      "status": "Inactive"
    }

    const dataPayload = dependentUpdate.status === false ?
      InactivePayload
      :
      {
        ...(
          (({token, ...props}) => props)(dependentUpdate)
        ),
        status: null
      }

    const payload = {
      tenantId,
      externalId: userDetails?.externalId ?? '',
      depExternalId: String(dependentUpdate?.token?.cardTokenID) ?? '',
      // formData: {
      //   // REMOVE token property in the Update Payload
      //   ...(
      //     (({token, ...props}) => props)(dependentUpdate)
      //   ),
      //   // IMPORTANT!!
      //   // For updates to take effect need to set the status to NULL,
      //   // otherwise if you want to disable the member then need to set to "Inactive"
      //   status: dependentUpdate.status === 'Active' || dependentUpdate.status === true ?
      //     null : (dependentUpdate.status === false && 'Inactive')
      // }
      formData: dataPayload
    }

    try {
      const result = await dispatch(updateFvDependent(payload));

      if (result.error) {
        dispatch(showNotification(`Failed to update Dependent data <br />Error: ${result.error.message}`, 'error'));
        dispatch(resetBenefitLoading());
        return;
      }

      if (result.payload[1]?.status >= 400) {
        dispatch(showNotification(`Failed to update Dependent data <br />Error: ${result?.payload[1]?.message}`, 'error'));
        dispatch(resetBenefitLoading());
        return;
      }

      if (result.payload[0]?.success) {
        dispatch(
          showNotification(
            `${result?.payload[0]?.message} <br />Dependent with externalID: ${dependentUpdate?.token?.cardTokenID} updated successfully`, 'success'
            )
        );
        return;
      }
    } catch (error) {
      dispatch(showNotification(`Failed to update Dependent data <br />Error: ${error.message}`, 'error'));
    }

  }, [dispatch, tenantId, userDetails?.externalId, dependents]);

  /* SUBMIT UPDATE DEPENDENT */
  const handleSubmitNewDependent = useCallback(async (newDependentData) => {
    const hasError = validateInputs();

    if (hasError) {
      return null;
    }

    const payload = {
      tenantId,
      externalId: userDetails?.externalId ?? '',
      formData: {
        demographicInfo: {
          ...newDependentData,
        }
      }
    }

    try {
      const result = await dispatch(addFvDevependent(payload));

      if (result.error) {
        dispatch(showNotification(`Failed to add new Dependent <br />Error: ${result.error.message}`, 'error'));
        dispatch(resetBenefitLoading());
        return;
      }

      if (result.payload[1]?.status >= 400) {
        dispatch(showNotification(`Failed to add new Dependent <br />Error: ${result?.payload[1]?.message}`, 'error'));
        dispatch(resetBenefitLoading());
        return;
      }

      if (result.payload[0]?.success) {
        /* ADD new dependent to array if success */
        // RELOAD State after adding
        const params = {
          tenantId,
          externalId: userDetails?.externalId,
        };
        dispatch(loadSubsData(params));

        dispatch(
          showNotification(
            `${result?.payload[0]?.message} <br /> New Dependent added successfully `, 'success'
            )
        );
        return;
      }
    } catch (error) {
      dispatch(showNotification(`Failed to add new Dependent <br />Error: ${error.message}`, 'error'));
    }

  }, [dispatch, tenantId, userDetails?.externalId]);



  const validateInputs = () => {
    let hasError = false;
    // if (benefitType === undefined || !benefitEnum.includes(benefitType)) {
    //   setError((err) => ({
    //     ...err,
    //     benefitType: {
    //       message: 'Benefit Type is required',
    //     },
    //   }));
    //   hasError = true;
    // }
    return hasError;
  };

  const isLoading = (fvSubsLoading === 'pending' || !isMounted.current || userDetailsLoading)
  const isError = (isMounted.current && hasFvSubsErr && fvSubsErr?.fvDataErr)
  const isDataReady = hasData && hasSubsData;

  // console.log(isLoading, isError, isDataReady)
  // console.log(userDetails?.externalId)

  return (
    <Box width={'100%'} height={'100%'} minHeight={'10vh'}>
      { isLoading && <OverlayLoading />}

      <>
        {/* NEEDS to include "hasFvSubsErr" so it will NOT display the previous data if there's an erro */}
        {
          isError ? (
              <Box minHeight={'15vh'} textAlign={'center'} >
                <Typography variant='body1'>{ fvSubsErr?.fvDataErr?.message ?? 'NO USER DATA' }</Typography>
              </Box>
            )
            :
            isDataReady ? <>
              <Box display={'grid'}>
                <SubscriberForm
                  key={'subscriber'}
                  onSubscriberChange={handleSubscriberChange}
                  subscriber={subscriber}
                  fvSubsLoading={fvSubsLoading}
                  onUpdate={submitEditPrimary}
                  hasData
                />
              </Box>
              <Box marginTop={'40px'}>
                <Divider variant='fullWidth' style={{height: '10px', marginBottom: '8px'}}/>
                <Box display={'flex'} justifyContent={'space-between'} mt={'20px'}>
                  <Typography variant="h6">
                        Dependents
                  </Typography>
                  <Box
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    flexBasis={'20%'}
                    mt={'5'}
                    mb={'5px'}
                  >
                    <Button
                      variant='outlined'
                      color='primary'
                      onClick={handleOpenAddModal}
                    >
                      Add New Dependent
                    </Button>
                  </Box>
                </Box>
              </Box>
              {
                dependents.length > 0 && <Box marginTop={'20px'}>
                  {
                    dependents.map((dependent, idx) => (
                      <AccordionGroup
                        label={`Dependent #${idx + 1} (${dependent?.demographicInfo?.name?.firstName ?? ''}  ${dependent?.demographicInfo?.name?.lastName ?? ''})`}
                        expanded={false}
                      >
                        <DependentForm
                          key={idx + dependent.token?.cardTokenID}
                          dependentData={dependent}
                          fvSubsLoading={fvSubsLoading}
                          onDependentChange={handleDependentChange}
                          onUpdateDependent={submitUpdatetDependent}
                          dependentIdx={idx}
                        />
                      </AccordionGroup>
                    ))
                  }
                </Box>
              }
            </>
            :
            null
        }
      </>
      <>
        <DependentModal
          onSubmitNewDependent={handleSubmitNewDependent}
          onClose={handleCloseAddModal}
          open={isAddModalOpen}
        />
      </>
    </Box>
  );
}

export default FvDetailsSimulator;
