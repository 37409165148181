/* eslint-disable react/no-array-index-key */
import Card from '@material-ui/core/Card';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import { useTheme, withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ProfileViewStyles from '../../assets/jss/components/ProfileViewStyles';
import ContentListWrapper from '../Content/ContentListWrapper';
import BoxSettingsFonts from '../Settings/SettingsFonts/SettingsFonts';
import utils from '../Shared/Utils/utils';
import { assetsTabsMapping } from './helpers/mappings';
import NetworkFormWrapper from './NetworkFormWrapper';

function AssetsInfoBox(props) {
  const { type, classes, currentTenant } = props;

  const theme = useTheme();
  const [value, setValue] = useState(0);
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const navigate = useNavigate();
  let pageComponent;

  useEffect(() => {
    const splitUrl = window.location.href.split('/');
    const page = splitUrl[splitUrl.length - 1];

    if (page !== '') {
      if (page === 'graphic-assets') {
        setValue(0);
      } else if (page === 'intro-widgets') {
        setValue(1);
      } else if (page === 'fonts') {
        setValue(2);
      }
    }
  }, []);

  const handleChange = (e, value) => {
    setValue(value);
    let path;
    if (value === 0) {
      path = 'graphic-assets';
    } else if (value === 1) {
      path = 'intro-widgets';
    } else if (value === 2) {
      path = 'fonts';
    }

    const splitUrl = window.location.pathname.split('/');
    splitUrl.pop();
    navigate(`${splitUrl.join('/')}/${path}`);
  };

  switch (value) {
    case 0:
      pageComponent = <NetworkFormWrapper currentTenant={currentTenant} type={type} />;
      break;
    case 1:
      pageComponent = <ContentListWrapper currentTenant={currentTenant} type={utils.snakeToCamel('intro-widgets')} />;
      break;
    case 2:
      pageComponent = <BoxSettingsFonts currentTenant={currentTenant.id} />;
      break;
    default:
      pageComponent = <NetworkFormWrapper currentTenant={currentTenant} type={type} />;
      break;
  }

  return (
    <Card style={{ borderRadius: '20px' }} elevation={0}>
      <Paper style={{ borderRadius: '20px 20px 0px 0px' }}>
        {matches ? (
          <Tabs
            value={value}
            indicatorColor="primary"
            variant="fullWidth"
            textColor="primary"
            onChange={handleChange}
            aria-label="disabled tabs example"
          >
            {assetsTabsMapping.map((tab, index) => (
              <Tab
                label={tab.label}
                id={tab.id}
                style={{ fontSize: '12px', marginBottom: '-10px' }}
                className={value === tab.value ? classes.activeLink : ''}
                key={index}
              />
            ))}
          </Tabs>
        ) : (
          <FormControl variant="outlined" fullWidth>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={value}
              onChange={(e) => setValue(e.target.value)}
            >
              {assetsTabsMapping.map((tab, index) => (
                <MenuItem value={tab.value} key={index}>
                  {tab.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Paper>
      <div style={{ marginTop: 10 }}>{pageComponent}</div>
    </Card>
  );
}

export default withStyles(ProfileViewStyles)(AssetsInfoBox);
