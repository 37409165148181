import DateFnsUtils from '@date-io/date-fns';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { Error } from '@progress/kendo-react-labels';
import React, { useState, useEffect } from 'react';
import utils from '../../Utils/utils';
import { getCalculatedDate, getDateByPrecision } from '../helpers/precisionDateHelper';

const useDateStyles = makeStyles({
  dateLabel: {
    fontSize: 18,
  },
  dateInput: {
    borderBottom: 'solid 1px #D9E1EC',
    marginLeft: 20,

    '& .MuiIconButton-root': {
      outline: 'none',
    },
  },
  removeMargin: {
    margin: 0,
  },
});

function DateInput(props) {
  const { field, value, handleChangeWithParams, disabled, selectedLanguage, withoutMargin } = props;
  const classes = useDateStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const [minAgeYear, setMinAgeYear] = useState('');
  const [valid, setValid] = useState(true);
  const [minDate, setMinDate] = useState();
  const [maxDate, setMaxDate] = useState();
  const re = /^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/;

  let date;
  if (value) {
    date = value instanceof Date ? new Date(value) : new Date(value.split('T')[0].split('-').join('/'));
  }
  const [fieldState, setFieldState] = useState(date || null);
  const newUser = window.location.href.includes('create');
  const clinicalUser = window.location.href.includes('clinical');
  const questionPreview = window.location.href.includes('questions') || window.location.href.includes('questionnaires');
  const diagnosticReport = window.location.href.includes('diagnostic-report');

  // Convert min and max from int to date for VCB preview
  useEffect(() => {
    const calculatedMin = typeof field.min === 'number' ? getCalculatedDate(field.precision, field.min) : field.min;
    const calculatedMax = typeof field.max === 'number' ? getCalculatedDate(field.precision, field.max) : field.max;
    setMinDate(calculatedMin || field.min);
    setMaxDate(calculatedMax || field.max);
  }, [field.precision, field.questionPreview, field.min, field.max]);

  // Set year for minimum age for user age-related date fields
  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    !newUser && !questionPreview && document.getElementById('datePicker').focus();
    if (field.minAgeRestriction) {
      const today = new Date();
      const restrictedYear = today.getFullYear() - field.minAgeRestriction;
      const restrictedDate = new Date(restrictedYear, today.getMonth(), today.getDate());
      setMinAgeYear(restrictedDate);
    }
  }, [field.minAgeRestriction, newUser, questionPreview]);

  // allows for proper onBlur and onFocus of component
  const handleBlur = (e) => {
    const { value } = e.target;
    const formattedDate = utils.formatDateNoLocale(fieldState);
    // checks if user clicks calendar dropdown button
    if (!e.relatedTarget) {
      if (re.test(formattedDate) && newUser) {
        handleChangeWithParams(field.name, formattedDate);
        setValid(true);
      } else if (re.test(formattedDate) && !newUser) {
        if (field.precision) {
          const preciseValue = getDateByPrecision(field.precision, value);
          handleChangeWithParams(field.name, preciseValue);
          setValid(true);
        } else {
          handleChangeWithParams(field.name, fieldState);
          setValid(true);
        }
      } else if (value === 'month/day/year' && (field.name === 'clearedUntil' || field.name === 'sidelinedUntil')) {
        handleChangeWithParams(field.name, '');
        setValid(true);
      } else if (field.precision) {
        const preciseValue = getDateByPrecision(field.precision, value);
        handleChangeWithParams(field.name, preciseValue);
        setValid(false);
      } else {
        handleChangeWithParams(field.name, value);
        setValid(false);
      }
    }
  };

  // handles user changing information of birthdate (kendoUI: only triggered when year populated)
  const handleUpdateDate = (e) => {
    const dateValue = e.target.value;
    const formattedDate = utils.formatDateNoLocale(dateValue);
    setFieldState(dateValue);
    // member details handle update
    if (
      !newUser &&
      e.nativeEvent.inputType !== 'deleteContentBackward' &&
      e.nativeEvent.inputType !== 'insertText' &&
      dateValue
    ) {
      if (field.precision) {
        const preciseValue = getDateByPrecision(field.precision, dateValue);
        handleChangeWithParams(field.name, preciseValue);
        // Submit date alongside precision answer in VCB
        if (field.questionPreview) {
          const answerObject = {
            createdOn: dateValue,
            answer: preciseValue,
          };
          handleChangeWithParams(field.name, answerObject);
        }
      } else {
        // if user clicks on birthdate, handle update
        handleChangeWithParams(field.name, dateValue);
      }
    }

    // new member handle update
    if (newUser && re.test(formattedDate)) {
      setValid(true);
      handleChangeWithParams(field.name, dateValue);
    } else if (newUser && !re.test(formattedDate)) {
      setValid(false);
      handleChangeWithParams(field.name, dateValue);
    }
  };

  const fieldTitle =
    selectedLanguage && field.name && field.name.translations
      ? field.name.translations[selectedLanguage]
      : field.label || field.name;
  const questionDescription =
    selectedLanguage && field.description && field.description.translations
      ? field.description.translations[selectedLanguage]
      : field.description;

  return (
    <>
      {(newUser || questionPreview || clinicalUser || diagnosticReport) && (
        <Typography variant="caption" style={{ color: '#9E9E9E' }}>
          {field.questionPreview ? utils.renderHTMLTags(questionDescription) : fieldTitle}
        </Typography>
      )}
      <div
        style={{
          width: '100%',
          marginTop: -2,
        }}
      >
        {matches ? (
          <DatePicker
            id="datePicker"
            required={field.required}
            // max={maxDate || undefined}
            // min={minDate || undefined}
            // name={fieldTitle}
            format="MM/dd/yyyy"
            value={fieldState}
            onBlur={(e) => handleBlur(e)}
            onChange={(e) => handleUpdateDate(e)}
            disabled={disabled || field.disabled || field.readOnly}
            width="100%"
            // valid={valid}
          />
        ) : (
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              InputLabelProps={{
                shrink: true,
                className: classes.dateLabel,
              }}
              InputProps={{
                className: `${classes.dateInput} ${withoutMargin ? classes.removeMargin : ''}`,
                disableUnderline: true,
              }}
              id="datePicker"
              required={field.required}
              name={fieldTitle}
              disabled={disabled || field.disabled || field.readOnly}
              onChange={(e) => handleUpdateDate(e)}
              format="MM/dd/yyyy"
              placeholder="mm/dd/yyyy"
              value={fieldState}
              keyboardIcon={<InsertInvitationIcon style={{ fontSize: 25, position: 'relative' }} />}
              maxDate={maxDate || undefined}
              minDate={minDate || undefined}
              autoOk
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </MuiPickersUtilsProvider>
        )}
        {!valid && <Error id="invalidDate">Please enter a valid date</Error>}
        {minDate && fieldState < minDate && (
          <Error id="minDateError">
            Date must be {minDate.getMonth() + 1}/{minDate.getDate()}/{minDate.getFullYear()} or later
          </Error>
        )}
        {maxDate && fieldState > maxDate && (
          <Error id="maxDateError">
            Date must be {maxDate.getMonth() + 1}/{maxDate.getDate()}/{maxDate.getFullYear()} or earlier
          </Error>
        )}
        {maxDate && fieldState < maxDate && field.minAgeRestriction && fieldState && fieldState > minAgeYear && (
          <Error id="minAgeError">
            User must be at least
            {field.minAgeRestriction} years old
          </Error>
        )}
      </div>
    </>
  );
}
export default DateInput;
