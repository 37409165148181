/* eslint-disable react/no-array-index-key */
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import ChartsEmbedSDK from '@mongodb-js/charts-embed-dom';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getNetworkByTenantId } from '../Networks/networksSlice';
import { selectTenantNetwork, selectTenantNetworkLoading } from '../Networks/selectors';
import AppDateRangeSelector from '../Shared/Forms/KendoFields/DateRangeSelector';
import { selectCurrentTenantId } from '../Shared/selectors';
import utils from '../Shared/Utils/utils';

import { getDashboardData } from './dashboardSlice';
import AppOldInfoCard from './OldInfoCard';
import { selectDashboardData } from './selectors';

// Grid spacing
const SPACING = 3;

/**
 * Dashboard component that is based on Java endpoints
 * @returns {*}
 * @constructor
 */
export function Dashboard() {
  const dashboardData = useSelector(selectDashboardData);
  const currentNetwork = useSelector(selectTenantNetwork);
  const currentNetworkLoading = useSelector(selectTenantNetworkLoading);
  const currentTenant = useSelector(selectCurrentTenantId);

  const [dateRangeValue, setDateRangeValue] = useState(null);
  const [dashboardChartItems, setDashboardChartItems] = useState([]);
  const dispatch = useDispatch();
  const sdk = new ChartsEmbedSDK({
    baseUrl: 'https://charts.mongodb.com/charts-safe-dev-gkwsp',
    filter: { tenantId: { $eq: currentTenant } },
  });

  useEffect(() => {
    if (currentNetwork) {
      const chartItems = currentNetwork.dashboardSettings.dashboardChartItems.filter((x) => x.enabled);
      setDashboardChartItems(chartItems);
      const fromDate = dateRangeValue != null ? new Date(dateRangeValue.start) : '';
      const toDate = dateRangeValue != null ? new Date(dateRangeValue.end) : '';
      chartItems.forEach((item, ix) => {
        ix = sdk.createChart({
          chartId: item.detail.mongoChartId,
          getUserToken: () => localStorage.getItem('access_token'),
          height: 300,
          showAttribution: false,
          filter:
            item.detail.mongoChartId === '4c974008-ef68-4af1-a0ba-910c4b797138' && fromDate !== '' && toDate !== ''
              ? {
                  collectionDate: {
                    $gte: utils.formatDateNoLocale(fromDate),
                    $lte: utils.formatDateNoLocale(toDate),
                  },
                }
              : {},
        });
        setTimeout(() => {
          ix.render(document.getElementById(item.dashboardChartId));
        }, 10);
      });
    }
  }, [currentNetwork]);

  useEffect(() => {
    if (dateRangeValue && dateRangeValue.start && dateRangeValue.end) {
      dispatch(getDashboardData(currentTenant, dateRangeValue.start, dateRangeValue.end));
    }
  }, [currentTenant, dateRangeValue]);

  useEffect(() => {
    if (
      currentTenant &&
      currentTenant.id &&
      !currentNetworkLoading &&
      (!currentNetwork || currentTenant.id !== currentNetwork.tenantID)
    ) {
      // Need to get network info
      dispatch(getNetworkByTenantId(currentTenant));
    }
  }, [currentTenant, currentNetwork, currentNetworkLoading]);

  const handleChangeTimePeriod = (dateRangeValue) => {
    setDateRangeValue(dateRangeValue);
  };

  const formatValue = (value, options) => {
    let result = value ? String(value) : 'N/A';
    if (value && options?.suffix) {
      result += options.suffix;
    }

    return result;
  };

  const createMongoChartDiv = () => {
    const divItems = [];
    dashboardChartItems.forEach((x) => {
      divItems.push(
        <Grid item xs={12} md={4}>
          <div id={x.dashboardChartId} />
        </Grid>
      );
    });
    return divItems.map((item, index) => <React.Fragment key={index}>{item}</React.Fragment>);
  };

  return (
    <Grid container item xs={12} spacing={SPACING}>
      {/* Date Range selector */}
      <Grid item container direction="row" justifyContent="flex-end">
        <Box>
          <AppDateRangeSelector onDateRangeChange={handleChangeTimePeriod} />
        </Box>
      </Grid>

      {/* Info cards */}
      <Grid container item spacing={SPACING}>
        {/* Total Users */}
        <Grid item xs={6} md={4} lg>
          <AppOldInfoCard
            value={formatValue(dashboardData?.statistics?.totalUsers?.total)}
            title="Total Users"
            bgColor="#FFC66F"
          />
        </Grid>

        {/* Tests Completed */}
        <Grid item xs={6} md={4} lg>
          <AppOldInfoCard
            value={formatValue(dashboardData?.statistics?.testsCompleted?.total)}
            title="Test Completed"
            text={`${formatValue(dashboardData?.statistics?.testsCompleted?.dailyAverage)} average per day`}
            bgColor="#A9A5E4"
          />
        </Grid>

        {/* Positive Tests */}
        <Grid item xs={6} md={4} lg>
          <AppOldInfoCard
            value={formatValue(dashboardData?.statistics?.positiveTests?.total)}
            title="Positive Tests"
            text={`${formatValue(dashboardData?.statistics?.positiveTests?.dailyAverage)} average per day`}
            bgColor="#F37C7C"
          />
        </Grid>

        {/* Negative Tests */}
        <Grid item xs={6} md={3} lg>
          <AppOldInfoCard
            value={formatValue(dashboardData?.statistics?.negativeTests?.total)}
            title="Negative Tests"
            text={`${formatValue(dashboardData?.statistics?.negativeTests?.dailyAverage)} average per day`}
            bgColor="#47CAAA"
          />
        </Grid>
        {/* Hidden for 3.1 release */}
        {/* Self Assessments Completed */}
        {/* <Grid item xs={6} md={3} lg>
          <AppOldInfoCard
            value={formatValue(
              dashboardData?.statistics?.selfAssessmentsCompleted?.total
            )}
            title="Self Assessments Completed"
            text={`${formatValue(
              dashboardData?.statistics?.selfAssessmentsCompleted?.dailyAverage
            )} average per day`}
            bgColor="#A2A0B5"
          />
        </Grid> */}

        {/* Self Assessments Positive */}
        {/* <Grid item xs={6} md={3} lg>
          <AppOldInfoCard
            value={formatValue(
              dashboardData?.statistics?.selfAssessmentsPositive?.total
            )}
            title="Not Cleared"
            text={`${formatValue(
              dashboardData?.statistics?.selfAssessmentsPositive?.dailyAverage
            )} average per day`}
            bgColor="#3C91DF"
          />
        </Grid> */}

        {/* Self Assessments Negative */}
        {/* <Grid item xs={6} md={3} lg>
          <AppOldInfoCard
            value={formatValue(
              dashboardData?.statistics?.selfAssessmentsNegative?.total
            )}
            title="Cleared"
            text={`${formatValue(
              dashboardData?.statistics?.selfAssessmentsNegative?.dailyAverage
            )} average per day`}
            bgColor="#899976"
          />
        </Grid> */}
      </Grid>

      {/* Appointments and self-assessments */}
      {/* <Grid container item spacing={SPACING}>
        <Grid item xs={12} md={6}>
          {dashboardData?.testsTaken?.data && (
            <LineChart
              data={dashboardData.testsTaken.data}
              timeline={dashboardData.testsTaken.categories}
              height={300}
              title="Test Taken"
            />
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          {dashboardData?.selfAssessments?.data && (
            <LineChart
              data={dashboardData.selfAssessments.data}
              timeline={dashboardData.selfAssessments.categories}
              height={300}
              title="Self Assessments Completed"
            />
          )}
        </Grid>
      </Grid> */}

      {/* Age, Ethnicity and Gender charts */}
      <Grid container item spacing={SPACING}>
        {/* <Grid item xs={12} md={4}>
          {dashboardData?.ageGroups?.data && (
            <BarChart
              showLoading={true}
              title="Age Group Distribution"
              height={300}
              data={dashboardData.ageGroups.data}
              categories={dashboardData.ageGroups.categories}
            />
          )}
        </Grid>
        <Grid item xs={12} md={4}>
          {dashboardData?.ethnicity?.data && (
            <DonutChart
              title="Ethnicity"
              height={300}
              data={dashboardData.ethnicity.data}
            />
          )}
        </Grid>
        <Grid item xs={12} md={4}>
          {dashboardData?.genderGroups?.data && (
            <BarChart
              showLoading={true}
              title="Gender"
              height={300}
              type="column"
              data={dashboardData.genderGroups.data}
              categories={dashboardData.genderGroups.categories}
            />
          )}
        </Grid> */}
        {/* <iframe
        style={{background: "#FFFFFF", border: "none", borderRadius: "2px", boxShadow: "0 2px 10px 0 rgba(70, 76, 79, .2)"}}
        width="640"
        height="480"
        src="https://charts.mongodb.com/charts-safe-dev-gkwsp/embed/charts?id=2678fd0f-8fb8-4923-9616-0bbc6116a151&theme=light"></iframe> */}
        {createMongoChartDiv()}
        {/* <Grid item container direction="row" justifyContent="flex-end">
          <Box>
            <AppDateRangeSelector onDateRangeChange={handleCollectionDateRange} />
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <div id="testChartByDate"></div>
        </Grid>
        <Grid item xs={12} md={4}>
          <div id="specimenChartByCollection"></div>
        </Grid> */}
      </Grid>
    </Grid>
  );
}

export default Dashboard;
