import Grid from '@material-ui/core/Grid';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import React from 'react';

import styles from '../../assets/Questionnaire.module.css';
import utils from '../utils/utils';

export default function SliderInput(props) {
  const { field, value: initialValue, handleChangeWithParams, selectedLanguage } = props;
  const [value, setValue] = React.useState(initialValue || 1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    handleChangeWithParams(field.name, newValue);
  };

  const fieldTitle =
    selectedLanguage && field.name && field.name.translations
      ? field.name.translations[selectedLanguage]
      : field.label || field.name;
  const questionDescription =
    selectedLanguage && field.description && field.description.translations
      ? field.description.translations[selectedLanguage]
      : field.description;

  return (
    <div style={{ marginTop: 35 }}>
      <Grid item xs={12} style={{ width: '100%' }}>
        {fieldTitle && (
          <Typography className={field.nameStyle ? styles[`title--${field.nameStyle}`] : styles['title--style1']}>
            {field.questionPreview ? utils.renderHTMLTags(fieldTitle) : fieldTitle}
            {field.required && '*'}
          </Typography>
        )}
        {field.image && (
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <img
              src={field.image}
              alt={fieldTitle}
              style={{
                display: 'block',
                marginLeft: 'auto',
                marginRight: 'auto',
                width: '90%',
              }}
            />
          </Grid>
        )}
        {questionDescription && (
          <Typography
            className={
              field.descriptionStyle ? styles[`description--${field.descriptionStyle}`] : styles['description--style1']
            }
          >
            {utils.renderHTMLTags(questionDescription)}
          </Typography>
        )}
      </Grid>

      <Grid style={{ marginTop: fieldTitle || questionDescription ? 40 : 0 }}>
        <Slider
          value={value}
          aria-labelledby="discrete-slider-always"
          min={parseFloat(field.min) || 0}
          step={parseFloat(field.step) || 1}
          max={parseFloat(field.max) || 100}
          onChange={handleChange}
          valueLabelDisplay="on"
          disabled={field.disabled}
        />
      </Grid>
    </div>
  );
}
