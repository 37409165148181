import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React, { useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { showConfirmation } from '../Confirmation/confirmationSlice';
import CreateFormWrapper from '../Shared/Forms/CreateFormWrapper';

import { selectCurrentTenant } from '../Shared/selectors';
import { TestFormMap, TestOrderableFormMap, TestPanelsFormMap, transform } from './helpers/formHelper';
import { apiMapper } from './helpers/medicalCentersListMapper';
import { getLabTest, getLabTests, newLabTest, updateLabTest } from './labTestsSlice';
import { MedicalCenterSkeletonLoading, MedicalCenterSkeletonLoadingMobile } from './MedicalCenterSkeletonLoading';
import { selectLabTests } from './selectors';

function MedicalCentersFormWrapper(props) {
  const { type, edit, id } = props;
  const labTests = useSelector(selectLabTests);
  const currentTenant = useSelector(selectCurrentTenant);

  const dispatch = useDispatch();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  let steps;

  useEffect(() => {
    if (currentTenant) {
      if (type === 'testOrderable') {
        dispatch(getLabTests(currentTenant, 'vendors', 'VendorDetail'));
        dispatch(getLabTests(currentTenant, 'formats', 'TestFormat'));
        dispatch(getLabTests(currentTenant, 'test', apiMapper.test));
      } else if (type === 'testPanels') {
        dispatch(getLabTests(currentTenant, 'testOrderable', apiMapper.testOrderable));
      }

      if (id) {
        dispatch(getLabTest(currentTenant, type, apiMapper[type], id));
      }
    }
  }, [currentTenant, id, type]);

  const form = useMemo(() => {
    switch (type) {
      case 'testOrderable':
        return TestOrderableFormMap(
          currentTenant,
          labTests && labTests.test && labTests.test.list,
          labTests && labTests.vendors && labTests.vendors.list,
          labTests && labTests.formats && labTests.formats.list
        );
      case 'testPanels':
        return TestPanelsFormMap(currentTenant, labTests && labTests.testOrderable && labTests.testOrderable.list);
      default:
        return TestFormMap(currentTenant);
    }
  }, [currentTenant, type, labTests]);

  // sets step to it's correctly formatted JSON
  steps = useMemo(
    () => [
      {
        label: type,
        data: form,
      },
    ],
    [type, form]
  );

  const initialValues = useMemo(
    () => (edit && labTests && labTests[type] ? labTests[type].details : { isActive: true }),
    [type, edit, labTests]
  );

  const handleSubmit = useCallback(
    (formData) => {
      const data = transform(currentTenant, formData)[type];
      if (edit) {
        dispatch(updateLabTest(currentTenant, type, apiMapper[type], data));
      } else {
        dispatch(newLabTest(currentTenant, type, apiMapper[type], data));
      }
    },
    [currentTenant, type, edit]
  );

  const handleDelete = useCallback(
    (formData) => {
      dispatch(
        showConfirmation(
          'Confirm',
          'Are you sure you want to delete this content?',
          'contentDelete',
          currentTenant,
          formData._id,
          type
        )
      );
    },
    [currentTenant, type]
  );

  if (!currentTenant) return <div />;

  if (
    (edit && labTests && labTests[type] && labTests[type].loadingDetails) ||
    (type === 'testOrderable' &&
      (!labTests ||
        !labTests.vendors ||
        !labTests.vendors.list ||
        labTests.vendors.loading ||
        !labTests.test ||
        !labTests.test.list ||
        labTests.test.loading ||
        !labTests.formats ||
        !labTests.formats.list ||
        labTests.formats.loading)) ||
    (type === 'testPanels' &&
      (!labTests || !labTests.testOrderable || !labTests.testOrderable.list || labTests.testOrderable.loading))
  ) {
    return (
      <Grid container justifyContent="center">
        {matches ? (
          <Grid item xs={12} md={7}>
            <Paper elevation={0} style={{ borderRadius: 30 }}>
              <MedicalCenterSkeletonLoading />
            </Paper>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Paper elevation={0} style={{ borderRadius: 30 }}>
              <MedicalCenterSkeletonLoadingMobile />
            </Paper>
          </Grid>
        )}
      </Grid>
    );
  }

  return (
    <Grid container justifyContent="center">
      <CreateFormWrapper
        steps={steps}
        handleSubmit={handleSubmit}
        handleDelete={handleDelete}
        initialValues={initialValues}
        formType={edit ? 'editContent' : undefined}
      />
    </Grid>
  );
}

export default MedicalCentersFormWrapper;
