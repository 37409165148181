/* eslint-disable jsx-a11y/img-redundant-alt */
import Grid from '@material-ui/core/Grid';
import React from 'react';

import styles from '../../../assets/Questionnaire.module.css';

function Image(props) {
  const { image } = props;

  return (
    image && (
      <Grid item xs={12} style={{ textAlign: 'center' }}>
        <img src={image} alt="Featured image" className={styles.featuredImage} />
      </Grid>
    )
  );
}

export default Image;
