import moment from 'moment-timezone';

export const timezones = [
  {
    label: 'UTC−12:00 (International Date Line West Time)',
    value: 'UTC-12:00',
  },
  {
    label: 'UTC−11:00 (Samoa Standard Time)',
    value: 'UTC-11:00',
  },
  {
    label: 'UTC−10:00 (Hawaii–Aleutian Standard Time)',
    value: 'UTC-10:00',
  },
  {
    label: 'UTC−09:30 (Marquesas Islands Time)',
    value: 'UTC-09:30',
  },
  {
    label: 'UTC−09:00 (Alaska Standard Time)',
    value: 'UTC-09:00',
  },
  {
    label: 'UTC−08:00 (Pacific Standard Time)',
    value: 'UTC-08:00',
  },
  {
    label: 'UTC−07:00 (Mountain Standard Time)',
    value: 'UTC-07:00',
  },
  {
    label: 'UTC−06:00 (Central Standard Time)',
    value: 'UTC-06:00',
  },
  {
    label: 'UTC−05:00 (Eastern Standard Time)',
    value: 'UTC-05:00',
  },
  {
    label: 'UTC−04:00 (Atlantic Standard Time)',
    value: 'UTC-04:00',
  },
  {
    label: 'UTC−03:30 (Newfoundland Time)',
    value: 'UTC-03:30',
  },
  {
    label: 'UTC−03:00 (Argentina Time)',
    value: 'UTC-03:00',
  },
  {
    label: 'UTC−02:00 (West Greenland Summer Time)',
    value: 'UTC-02:00',
  },
  {
    label: 'UTC−01:00 (Eastern Greenland Time)',
    value: 'UTC-01:00',
  },
  {
    label: 'UTC+00:00 (Greenwich Mean Time)',
    value: 'UTC+00:00',
  },
  {
    label: 'UTC+01:00 (Central European Time)',
    value: 'UTC+01:00',
  },
  {
    label: 'UTC+02:00 (Eastern European Time)',
    value: 'UTC+02:00',
  },
  {
    label: 'UTC+03:00 (East Africa Time)',
    value: 'UTC+03:00',
  },
  {
    label: 'UTC+03:30 (Iran Standard Time)',
    value: 'UTC+03:30',
  },
  {
    label: 'UTC+04:00 (Gulf Standard Time)',
    value: 'UTC+04:00',
  },
  {
    label: 'UTC+04:30 (Afganistan Time)',
    value: 'UTC+04:30',
  },
  {
    label: 'UTC+05:00 (Pakistan Standard Time)',
    value: 'UTC+05:00',
  },
  {
    label: 'UTC+05:30 (Indian Standard Time)',
    value: 'UTC+05:30',
  },
  {
    label: 'UTC+05:45 (Nepal Time)',
    value: 'UTC+05:45',
  },
  {
    label: 'UTC+06:00 (British Indian Ocean Time)',
    value: 'UTC+06:00',
  },
  {
    label: 'UTC+06:30 (Myanmar Standard Time)',
    value: 'UTC+06:30',
  },
  {
    label: 'UTC+07:00 (Thailand Standard Time)',
    value: 'UTC+07:00',
  },
  {
    label: 'UTC+08:00 (Australian Western Standard Time)',
    value: 'UTC+08:00',
  },
  {
    label: 'UTC+08:45 (Central Western Standard Time)',
    value: 'UTC+08:45',
  },
  {
    label: 'UTC+09:00 (Eastern Indonesian Time)',
    value: 'UTC+09:00',
  },
  {
    label: 'UTC+09:30 (Australian Central Standard Time)',
    value: 'UTC+09:30',
  },
  {
    label: 'UTC+10:00 (Australian Eastern Standard Time)',
    value: 'UTC+10:00',
  },
  {
    label: 'UTC+10:30 (Lord Howe Standard Time)',
    value: 'UTC+10:30',
  },
  {
    label: 'UTC+11:00 (Norfolk Island Time)',
    value: 'UTC+11:00',
  },
  {
    label: 'UTC+12:00 (New Zealand Standard Time)',
    value: 'UTC+12:00',
  },
  {
    label: 'UTC+12:45 (Chatham Standard Time)',
    value: 'UTC+12:45',
  },
  {
    label: 'UTC+13:00 (Phoenix Island Time)',
    value: 'UTC+13:00',
  },
  {
    label: 'UTC+14:00 (Line Islands Time)',
    value: 'UTC+14:00',
  },
];

export const geographicallyNamedTimezones = moment.tz.names().map((name) => ({
  label: name,
  value: name,
}));
