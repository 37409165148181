/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { showConfirmation } from '../../Confirmation/confirmationSlice';

import { getQuestionnaireAnnotations, setEditingReviewerQuestion } from './reviewerSlice';
import { selectAnnotationList, selectDeletingAnnotationLoader } from './selectors';

function ReviewerAnnotationWrapper(props) {
  const { currentTenant, questionnaireId, editingAnnotation, setEditingAnnotation, setScreenshotUrl } = props;
  const classes = useStyles();
  const navigate = useNavigate();

  const annotationList = useSelector(selectAnnotationList);
  const [getAnnotationLoader, setGetAnnotationLoader] = useState(false);
  const [isCtrlHolding, setIsCtrlHolding] = useState(false);
  const deletingLoader = useSelector(selectDeletingAnnotationLoader);
  const dispatch = useDispatch();
  const refWrapper = useRef(null);

  useEffect(() => {
    handleGetAnnotations();
    // eslint-disable-next-line
  }, [questionnaireId]);

  useEffect(() => {
    if (deletingLoader && deletingLoader === editingAnnotation?._id) {
      setEditingAnnotation(null);
      setScreenshotUrl(null);
    }
    // eslint-disable-next-line
  }, [deletingLoader]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      const key = e.keyCode || e.which;
      if (key === 17) {
        setIsCtrlHolding(true);
      }
    };

    const handleKeyUp = (e) => {
      const key = e.keyCode || e.which;
      if (key === 17) {
        setIsCtrlHolding(false);
      }
    };

    // if pressed CTRL
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
    // eslint-disable-next-line
  }, []);

  const handleEditAnnotation = async (item) => {
    // if pressed CTRL redirect for editing question
    if (isCtrlHolding) {
      handleFixAnnotation(item?.questionId);
    } else {
      setEditingAnnotation(item);
    }
  };

  const handleGetAnnotations = async () => {
    if (questionnaireId) {
      setGetAnnotationLoader(true);
      await dispatch(getQuestionnaireAnnotations(questionnaireId));
      setGetAnnotationLoader(false);
    }
  };

  const handleDeleteAnnotation = async (deletingAnnotationId) => {
    dispatch(
      showConfirmation(
        'Confirm',
        'Are you sure you want to delete this annotation?',
        'annotationDelete',
        questionnaireId,
        deletingAnnotationId
      )
    );
  };

  const handleFixAnnotation = async (questionId) => {
    if (questionId) {
      await dispatch(setEditingReviewerQuestion(questionId));
      navigate(`/${currentTenant}/virtual-consult/questionnaires/details/${questionnaireId}`);
    }
  };

  if (getAnnotationLoader) {
    return (
      <Grid container justifyContent="center" className={classes.getAnnotationLoader}>
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <Grid className={classes.annotationsWrapper} ref={refWrapper}>
      {annotationList.length ? (
        annotationList.map((item) => (
          <Grid
            className={classes.imgWrapper}
            style={{
              border: editingAnnotation?._id === item._id ? '2px solid #5f5f5f' : '',
            }}
          >
            {deletingLoader && deletingLoader === item._id ? (
              <Grid container justifyContent="center" alignItems="center" className={classes.deletingLoaderWrapper}>
                <CircularProgress />
              </Grid>
            ) : null}

            <Grid className={classes.deleteButton} onClick={() => handleDeleteAnnotation(item._id)}>
              <DeleteIcon />
            </Grid>

            {/* <Grid */}
            {/*  className={classes.fixButton} */}
            {/*  onClick={() => handleFixAnnotation(item.questionId)} */}
            {/* > */}
            {/*  <BuildIcon /> */}
            {/* </Grid> */}

            <img
              width="100%"
              src={item.previewUrl}
              alt="Screenshot"
              className={classes.img}
              onClick={() => handleEditAnnotation(item)}
            />
          </Grid>
        ))
      ) : (
        <div>Annotations</div>
      )}
    </Grid>
  );
}

const useStyles = makeStyles(() => ({
  getAnnotationLoader: {
    padding: 100,
    marginTop: 36,
  },
  annotationsWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  imgWrapper: {
    position: 'relative',
    border: '2px solid white',
    padding: 5,
    borderRadius: 5,
    width: '33%',
  },
  img: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  deleteButton: {
    // border: 1px solid black;
    position: 'absolute',
    padding: 5,
    color: '#8a8a8a',
    backgroundColor: 'rgb(255 255 255 / 40%)',
    borderRadius: 5,
    display: 'flex',
    cursor: 'pointer',
    top: 0,
    right: 0,
    '&:hover': {
      color: '#656565',
    },
  },
  fixButton: {
    // border: 1px solid black;
    position: 'absolute',
    padding: 5,
    color: '#8a8a8a',
    backgroundColor: 'rgb(255 255 255 / 40%)',
    borderRadius: 5,
    display: 'flex',
    cursor: 'pointer',
    bottom: 0,
    right: 0,
    '&:hover': {
      color: '#656565',
    },
  },
  deletingLoaderWrapper: {
    top: 0,
    left: 0,
    position: 'absolute',
    backgroundColor: 'rgb(255 255 255 / 60%)',
    height: '100%',
    zIndex: 1,
  },
}));

export default ReviewerAnnotationWrapper;
