import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentTenant } from '../Shared/selectors';
import AppKendoTableWrapper from '../Shared/Table/KendoTableWrapper';
import { listName } from './helpers/mappings';
import { transform, apiMapper, columns, initialSorting, sortingMapping } from './helpers/medicalCentersListMapper';
import { getPaginatedLabTests } from './labTestsSlice';
import { selectLabTests, selectTenantLabTests } from './selectors';
import { saveTenantLabTests } from './tenantLabTestsSlice';

function MedicalCentersSelectionWrapper(props) {
  const { type, onCancel, filter, saveObject } = props;

  const currentTenant = useSelector(selectCurrentTenant);
  const labTests = useSelector(selectLabTests);
  const tenantLabTests = useSelector(selectTenantLabTests);

  const tenantLabTest =
    (tenantLabTests[type] && tenantLabTests[type].list && tenantLabTests[type].list.map((i) => i._id)) || [];
  const [selected, setSelected] = useState(tenantLabTest || []);
  const list = labTests && labTests[type] && labTests[type].list;
  const loading = labTests && labTests[type] && labTests[type].loading;
  const [enableSave, setEnableSave] = useState(false);
  const tenantTestLoading = tenantLabTests && tenantLabTests[type] && tenantLabTests[type].loading;
  const dispatch = useDispatch();

  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    setSelected(tenantLabTest);
  }, [tenantTestLoading]);

  const handleSelectionChange = useCallback(
    (item) => {
      const newSelectedList = [...selected];

      const indexInList = newSelectedList.findIndex((v) => (saveObject ? v._id === item._id : v === item._id));

      if (indexInList > -1) {
        newSelectedList.splice(indexInList, 1);
      } else {
        newSelectedList.push(saveObject ? item : item._id);
      }

      setSelected(newSelectedList);

      // eslint-disable-next-line
      Array.prototype.diff = function (a) {
        // eslint-disable-next-line react/no-this-in-sfc
        return this.filter((i) => a.indexOf(i) < 0);
      };
      const initialInNew = tenantLabTest.diff(newSelectedList);
      const newInInitial = newSelectedList.diff(tenantLabTest);
      setEnableSave(initialInNew.length > 0 || newInInitial.length > 0);
    },
    [selected, saveObject]
  );

  const handleSelectAll = (value) => {
    setSelected([]);
    setSelectAll(value);
  };

  // on click of select all, select/unselect all items on page
  const headerSelectionChange = (event) => {
    let newSelectIds = [...selected];
    const { checked } = event.syntheticEvent.target;
    if (!checked) {
      newSelectIds = newSelectIds.filter((id) => data.items.map((item) => item._id).indexOf(id) === -1);
    } else {
      newSelectIds = [
        ...newSelectIds,
        ...data.items.filter((item) => newSelectIds.indexOf(item._id) === -1).map((item) => item._id),
      ];
    }

    setSelected(newSelectIds);
    setEnableSave(true);
  };

  const cols = columns(true);

  // Initial Load
  useEffect(() => {
    dispatch(
      getPaginatedLabTests(
        currentTenant.id,
        type,
        apiMapper[type],
        0,
        0,
        sortingMapping[type][initialSorting[type].field],
        initialSorting[type].dir,
        null,
        filter
      )
    );
  }, [type, filter, currentTenant]);

  // empty placeholder data
  const data = useMemo(
    () => ({
      total: (list && list.foundItemsCount) || 0,
      pageSize: (list && list.pageSize) || 0,
      page: (list && list.pageNumber) || 0,
      items: (list && transform[type](list, saveObject ? selected.map((i) => i._id) : selected)) || [],
    }),
    [list, type, selected, saveObject]
  );

  const onSave = useCallback(() => {
    dispatch(saveTenantLabTests(currentTenant.id, type, listName[type], apiMapper[type], selected));
    onCancel();
  }, [selected, type, currentTenant, onCancel]);

  return (
    <Grid container justifyContent="flex-end" spacing={2}>
      <Grid item xs={12}>
        <AppKendoTableWrapper
          data={data}
          initialSort={initialSorting[type].field}
          initialDir={initialSorting[type].dir}
          onSelectionChange={handleSelectionChange}
          onSelectAll={handleSelectAll}
          onHeaderSelectionChange={headerSelectionChange}
          selectIds={selected}
          selectAll={selectAll}
          showButtons={false}
          showSearchbar={false}
          columns={cols[type]}
          loading={loading || tenantTestLoading}
          disableAutoFocus
        />
      </Grid>
      <Grid item>
        <Button color="primary" variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
      </Grid>
      <Grid item>
        <Button color="primary" variant="contained" onClick={onSave} disabled={!enableSave}>
          Save
        </Button>
      </Grid>
    </Grid>
  );
}

export default MedicalCentersSelectionWrapper;
