import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectCurrentTenant } from '../Shared/selectors';
import { selectUserDetails } from '../Users/selectors';
import { getCernerAuthUrl } from './slices/cernerSlice';
import { getEpicAuthUrl } from './slices/epicSlice';
import {
  selectCernerAuthLoading,
  selectCernerAuthUrl,
  selectEpicAuthLoading,
  selectEpicAuthUrl,
} from './slices/selectors';

function EmrConnect() {
  const userDetails = useSelector(selectUserDetails);
  const currentTenant = useSelector(selectCurrentTenant);
  const cernerAuthUrl = useSelector(selectCernerAuthUrl);
  const cernerAuthLoading = useSelector(selectCernerAuthLoading);
  const epicAuthUrl = useSelector(selectEpicAuthUrl);
  const epicAuthLoading = useSelector(selectEpicAuthLoading);

  const dispatch = useDispatch();

  useEffect(() => {
    if (userDetails.nationalHealthId) {
      dispatch(getCernerAuthUrl(userDetails.nationalHealthId, currentTenant.id));
      dispatch(getEpicAuthUrl(userDetails.nationalHealthId, currentTenant.id));
    }
  }, [currentTenant.id, userDetails.nationalHealthId]);

  const syncCerner = () => {
    if (cernerAuthLoading === false) {
      window.open(cernerAuthUrl.data);
    }
  };

  const syncEpic = () => {
    if (epicAuthLoading === false) {
      window.open(epicAuthUrl.data);
    }
  };

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={12}>
        <Card style={{ margin: '10px', padding: 16, boxSizing: 'border-box' }}>
          <Button onClick={syncCerner} color="primary" style={{ marginLeft: 25 }} variant="contained">
            Sync From Cerner
          </Button>
          <Button variant="contained" style={{ marginLeft: 25 }} onClick={syncEpic} color="primary">
            Sync From Epic
          </Button>
        </Card>
      </Grid>
    </Grid>
  );
}

export default EmrConnect;
