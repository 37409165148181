import { createSlice } from '@reduxjs/toolkit';

import api from '../../api';
import { showNotification } from '../Notifications/notificationSlice';
import { handleError } from '../Shared/Content/APIUtils';
import { setFormSubmitting } from '../Shared/Forms/formSlice';
import { updateCurrentTenant } from '../Shared/sharedSlice';

const initialState = {
  tenantNetwork: {
    addressOne: null,
    addressTwo: null,
    adminContactEmail: null,
    adminContactFirstName: null,
    adminContactLastName: null,
    appThemeColor: null,
    buttonStyle: null,
    bypassMLModel: null,
    city: null,
    collectAddress: null,
    collectAddressRequired: null,
    collectStreetAddress: null,
    collectStreetAddressRequired: null,
    collectState: null,
    collectStateRequired: null,
    collectZip: null,
    collectZipRequired: null,
    collectDateOfBirth: null,
    collectDateOfBirthRequired: null,
    collectEthicity: null,
    collectRace: null,
    collectSex: null,
    collectSexRequired: null,
    companyName: null,
    country: null,
    created: null,
    dashboardSettings: {
      dashboardChartItems: [],
      dashboardButtons: [],
      displayAIHistogramCharts: null,
    },
    disableSignUp: null,
    displayUserMetadataOnCheckerResultScreen: null,
    ehtnicityRequired: null,
    emailLogo: null,
    enableExternalBilling: null,
    enableHealthRecordModule: null,
    enableImportHealthRecord: null,
    enableSnackbar: null,
    enableTwoFactorAuthentication: null,
    enableVirtualConsult: null,
    enabledLabVendorsList: [],
    errorColor: null,
    facebook: null,
    favicon: null,
    homeScreenBannerUrl: null,
    homeScreenStyle: null,
    importHealthRecordSettings: null,
    instagram: null,
    isEmergencyContactInfoEnabled: null,
    isSAFEPassportEnabled: null,
    enableFaceIdAndTouchId: null,
    enableProctorFeature: null,
    labOrderCollectInsuranceInformation: null,
    labOrderCoverPatientResponsibility: null,
    labOrderRequireSignature: null,
    labOrderShowDedicatedAgreementScreen: null,
    labSettings: null,
    linkedin: null,
    loginPopupImage: null,
    logoRectangleDark: null,
    logoRectangleLight: null,
    logoSquareDark: null,
    logoSquareLight: null,
    medicContactCAQH: null,
    medicContactEmail: null,
    medicContactFax: null,
    medicContactFirstName: null,
    medicContactGender: null,
    medicContactLastName: null,
    medicContactMiddleName: null,
    medicContactNPI: null,
    medicContactOfficePhone: null,
    medicContactSuffix: null,
    medicContactTitle: null,
    modified: null,
    modifier: null,
    name: null,
    networkLogoInThemeColor: null,
    onboardingCollectInsuranceInformation: null,
    onboardingRequireSignature: null,
    onboardingShowDedicatedAgreementScreen: null,
    primaryColor: null,
    privacyEmail: null,
    raceRequired: null,
    reminderNotificationSettings: null,
    reportingTimezone: null,
    requireGuardianMode: null,
    requireIdVerification: null,
    secondaryColor: null,
    shareStatus: null,
    showClearanceStatusHomeScreen: null,
    showContentNewsfeed: null,
    showHomeScreenBackgroundColor: null,
    showLastAssessmentDate: null,
    showMessenger: null,
    showPatientRecordInAdminConsole: null,
    showPreRegistrationWidget: null,
    showQRCode: null,
    showTestResultOutcome: null,
    showVCAssessmentOutcome: null,
    showWelcomeScreen: null,
    state: null,
    status: null,
    successColor: null,
    supportEmail: null,
    supportPhone: null,
    symptomsCheckInterval: null,
    tenantDisplaySettings: null,
    tenantID: null,
    tenantPricingOrCommission: null,
    tenantType: null,
    timeoutDuration: null,
    uploadProfileImage: null,
    verifyEmail: null,
    verifyEmailRequired: null,
    collectPhoneNumber: null,
    collectPhoneNumberRequired: null,
    verifyIdCard: null,
    verifyIdCardRequired: null,
    verifyPhone: null,
    verifyPhoneRequired: null,
    warningColor: null,
    website: null,
    zip: null,
    _id: null,
  },
  tenantNetworkLoading: false,
  tenantNetworks: null,
  metaDataList: null,
  metaDataListLoading: false,
  checkerScreen: null,
};

export const networksSlice = createSlice({
  name: 'networks',
  initialState,
  reducers: {
    setMetadataList: (state, { payload }) => ({
      ...state,
      metaDataList: payload,
      metaDataListLoading: false,
    }),
    setMetadataListLoading: (state) => ({
      ...state,
      metaDataListLoading: true,
    }),
    getTenantNetworkRequest: (state) => ({
      ...state,
      tenantNetworkLoading: true,
    }),
    getTenantNetworkSuccess: (state, { payload }) => ({
      ...state,
      tenantNetwork: payload,
      tenantNetworkLoading: false,
    }),
    updateNetworkForDashboard: (state, { payload }) => ({
      ...state,
      tenantNetwork: {
        ...state.tenantNetwork,
        dashboardSettings: { ...state.tenantNetwork.dashboardSettings, dashboardChartItems: payload },
      },
      tenantNetworkLoading: false,
    }),
    setCheckerScreenParam: (state, { payload }) => ({
      ...state,
      checkerScreen: payload,
    }),
  },
});

export const {
  getTenantNetworkRequest,
  getTenantNetworkSuccess,
  setMetadataListLoading,
  setMetadataList,
  updateNetworkForDashboard,
  setCheckerScreenParam,
} = networksSlice.actions;

export const getNetworkByTenantId = (tenant) => async (dispatch) => {
  if (!tenant) return;
  dispatch(getTenantNetworkRequest());
  const [res, err] = await api.getNetworkByTenantIdRequest(tenant);
  if (err && err.message) {
    dispatch(handleError(err));
    dispatch(getTenantNetworkSuccess({ data: [], total: 0 }));
  }

  if (res && res.isOK) {
    dispatch(getTenantNetworkSuccess(res.data));
    return res.data;
  }
};

export const getMetaDataList = (tenant) => async (dispatch) => {
  dispatch(setMetadataListLoading());

  const params = { size: 100 };
  const [result, error] = await api.getMetaDataListRequest(tenant, params);

  if (error && error.message) {
    dispatch(handleError(error));
  }

  if (result) {
    dispatch(setMetadataList(result.content || []));
  }
};

export const deleteMetaDataKey = (tenant, metaDataKey) => async (dispatch) => {
  const [result, error] = await api.deleteMetaDataKeyRequest(tenant, metaDataKey);

  if (error && error.message) {
    dispatch(handleError(error));
  }

  if (result && result.success) {
    await dispatch(getMetaDataList(tenant));
    dispatch(showNotification('MetaData Keyword deleted', 'success'));

    return true;
  }
};

export const updateNetwork = (tenant, data) => async (dispatch) => {
  const [result, error] = await api.updateNetworkRequest(tenant, data);

  if (error && error.message) {
    dispatch(setFormSubmitting(false));
    dispatch(handleError(error));
  }

  if (result && result.isOK) {
    dispatch(updateCurrentTenant(data.Data.appThemeColor, data.Data.networkLogoInThemeColor, data.Data.name));
    dispatch(getNetworkByTenantId({ id: tenant }));
    dispatch(setFormSubmitting(false));
    dispatch(showNotification('Successfully Updated', 'success'));
  }
};

export const getCheckerScreenParam = (tenant) => async (dispatch) => {
  const [result, error] = await api.getCheckerScreenParamRequest(tenant);

  if (error && error.message) {
    dispatch(handleError(error));
  }

  if (result) {
    dispatch(setCheckerScreenParam(result.metadataOnCheckerScreenEnabled));
  }
};

export const updateMetaDataKey = (tenant, editingKeyword, newMetaDataKey) => async (dispatch) => {
  const [result, error] = await api.updateMetaDataKeyRequest(tenant, editingKeyword, newMetaDataKey);

  if (error && error.message) {
    dispatch(handleError(error));
    return false;
  }

  if (result) {
    await dispatch(getMetaDataList(tenant));
    await dispatch(showNotification('MetaData Keyword Successfully Updated', 'success'));
    return true;
  }
};

export const addMetaDataKey = (tenant, newMetaDataKey, forceAdd) => async (dispatch) => {
  const [result, error] = await api.addMetaDataKeyRequest(tenant, newMetaDataKey);

  if (error && error.message) {
    dispatch(handleError(error));
    return false;
  }

  if (result) {
    await dispatch(getMetaDataList(tenant));

    if (!forceAdd) {
      await dispatch(showNotification('MetaData Keyword Successfully Added', 'success'));
    }

    return true;
  }
};

export const updateMetaDataKeyEnabling = (tenant, selectedKeyword) => async (dispatch) => {
  const [result, error] = await api.updateMetaDataKeyEnablingRequest(tenant, selectedKeyword);

  if (error && error.message) {
    dispatch(handleError(error));
    return false;
  }

  if (result) {
    await dispatch(showNotification('MetaData Keyword Successfully Enabled', 'success'));

    return true;
  }
};

export const enableDisplayOnCheckerScreen = (tenant, enableVal) => async (dispatch) => {
  const [result, error] = await api.enableDisplayOnCheckerScreenRequest(tenant, enableVal);

  if (error && error.message) {
    dispatch(handleError(error));
    return false;
  }

  if (result) {
    dispatch(setCheckerScreenParam(enableVal));
    await dispatch(
      showNotification(
        `Displaying user metadata on checker result screen ${enableVal ? 'enabled' : 'disabled'}`,
        'success'
      )
    );

    return true;
  }
};

export default networksSlice.reducer;
