import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import LanguageIcon from '@material-ui/core/SvgIcon/SvgIcon';
import Typography from '@material-ui/core/Typography';
import createDOMPurify from 'dompurify';
import React, { useEffect } from 'react';

import styles from './assets/Questionnaire.module.css';
import utils from './components/utils/utils';

const DOMPurify = createDOMPurify(window);

function OutcomePreview(props) {
  const {
    formData,
    handleContinue,
    handleBack,
    buttonType,
    message,
    selectedLanguage,
    languageSupport,
    handleLanguageChange,
    disabled,
    disabledBack,
    previewForBuilder,
  } = props;

  const languageMenu = [
    {
      label: 'English',
      value: 'eng',
    },
    {
      label: 'Español',
      value: 'spa',
    },
    {
      label: '中文 (普通話)',
      value: 'zho',
    },
  ];

  const buttonLanguages = {
    continue: {
      eng: 'Continue',
      spa: 'Seguir',
      zho: '继续',
    },
    back: {
      eng: 'Back',
      spa: 'Regresar',
      zho: '返回',
    },
    startOver: {
      eng: 'Start Over',
      spa: 'Comenzar de nuevo',
      zho: '重来',
    },
  };

  useEffect(() => {
    if (!previewForBuilder && formData && formData.description) {
      // try to find and execute script from description
      utils.findScript(
        formData.description && formData.description.translations
          ? formData.description.translations[selectedLanguage]
          : formData.description
      );
    }
  }, [formData, previewForBuilder, selectedLanguage]);

  const screen = () => {
    if (message) {
      return (
        <Grid container justifyContent="center" style={{ marginTop: 100 }}>
          <Grid item xs={10}>
            <Typography variant="body2">{message}</Typography>
          </Grid>
        </Grid>
      );
    }

    let outcomeTitle =
      formData.title && formData.title.translations ? formData.title.translations[selectedLanguage] : formData.title;
    outcomeTitle = utils.decodeTags(outcomeTitle);
    let outcomeDescription =
      formData.description && formData.description.translations
        ? formData.description.translations[selectedLanguage]
        : formData.description;
    outcomeDescription = utils.decodeTags(outcomeDescription);

    return formData && formData.name ? (
      <Grid
        container
        justifyContent="center"
        spacing={2}
        style={{
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: 50,
          width: '100%',
          padding: 20,
          height: 'inherit',
          overflow: 'auto',
        }}
      >
        <Grid item>
          <Typography className={styles.titleStyles}>
            <span
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(outcomeTitle),
              }}
            />
          </Typography>
        </Grid>
        {formData.image && (
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <img
              src={formData.image}
              alt=""
              style={{
                display: 'block',
                marginLeft: 'auto',
                marginRight: 'auto',
                width: '90%',
              }}
            />
          </Grid>
        )}
        <Grid item>
          <Typography variant="body1">
            <span
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(outcomeDescription),
              }}
            />
          </Typography>
        </Grid>
      </Grid>
    ) : (
      <Grid container justifyContent="center" style={{ marginTop: 100 }}>
        <Grid item xs={10}>
          <Typography variant="body2" style={{ opacity: 0.6 }}>
            Preview of outcome will be shown here during setup.
          </Typography>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container className={styles.container}>
      {languageSupport ? (
        <Grid item xs={12}>
          <div
            style={{
              marginTop: 30,
              marginRight: 5,
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <LanguageIcon />
            <Select
              style={{ width: 130, marginLeft: 5 }}
              labelId="languageSelection"
              id="languageSelection"
              value={selectedLanguage}
              onChange={handleLanguageChange}
            >
              {languageMenu.map((lang, idx) => (
                // eslint-disable-next-line react/no-array-index-key
                <MenuItem key={idx} value={lang.value}>
                  {lang.label}
                </MenuItem>
              ))}
            </Select>
          </div>
        </Grid>
      ) : null}
      <Grid item xs={12}>
        {screen()}
      </Grid>
      <Grid item xs={12} className={styles.buttonsWrapper}>
        <Grid item xs={12} className={styles.buttonSpace}>
          <Button
            className={styles.buttonBack}
            disableRipple
            onClick={() => handleBack()}
            // disabled={true}
            disabled={disabledBack || previewForBuilder}
          >
            {disabledBack && <CircularProgress className={styles.buttonLoading} />}
            {buttonLanguages.back[selectedLanguage] || 'Back'}
          </Button>
        </Grid>

        <Button
          className={styles.buttonContinue}
          variant="contained"
          color="primary"
          disableElevation
          size="large"
          onClick={handleContinue}
          disabled={(disabled || previewForBuilder) && buttonType !== 'startOver'}
        >
          {buttonType === 'continue'
            ? buttonLanguages.continue[selectedLanguage] || 'Continue'
            : buttonLanguages.startOver[selectedLanguage] || 'Start Over'}
        </Button>
      </Grid>
    </Grid>
  );
}

export default OutcomePreview;
