/* eslint-disable import/no-cycle */
import { createSlice } from '@reduxjs/toolkit';
import api from '../../api/index';
import { showNotification } from '../Notifications/notificationSlice';
import { handleError } from '../Shared/Content/APIUtils';

const initialState = {
  details: null,
  loading: false,
  updating: false,
  error: null,
};

export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setAccountRequest: (state) => ({
      ...state,
      loading: true,
      error: null,
    }),
    setAccountRequestSuccess: (state, { payload }) => ({
      ...state,
      details: payload,
      loading: false,
    }),
    setAccountRequestError: (state, { payload }) => ({
      ...state,
      error: payload,
      loading: false,
    }),
    updateAccountRequest: (state) => ({
      ...state,
      updating: true,
      error: null,
    }),
    updateAccountRequestSuccess: (state, { payload }) => ({
      ...state,
      details: payload,
      updating: false,
    }),
    updateAccountRequestError: (state, { payload }) => ({
      ...state,
      error: payload,
      updating: false,
    }),
    setTemplateTenant: (state, { payload }) => ({
      ...state,
      details: {
        ...state.details,
        templateTenant: payload,
      },
    }),
    setAccountNetworkSettings: (state, { payload }) => ({
      ...state,
      accountNetworkSettings: payload,
    }),
    resetState: () => ({ ...initialState }),
  },
});

export const {
  setAccountRequest,
  setAccountRequestSuccess,
  setAccountRequestError,
  updateAccountRequest,
  updateAccountRequestSuccess,
  updateAccountRequestError,
  setTemplateTenant,
  setAccountNetworkSettings,
} = accountSlice.actions;

export const getAccountDetails = () => async (dispatch) => {
  dispatch(setAccountRequest());

  const [result, error] = await api.getAccountDetailsRequest();

  if (error && error.message) {
    dispatch(setAccountRequestError(error.message));
    dispatch(handleError(error));
  }

  if (result) {
    dispatch(setAccountRequestSuccess(result));
    return true;
  }

  return false;
};

export const updateAccountDetails = (data) => async (dispatch) => {
  dispatch(updateAccountRequest());

  const [result, error] = await api.updateAccountRequest(data);

  if (error && error.message) {
    dispatch(updateAccountRequestError(error.message));
    dispatch(handleError(error));
  }

  if (result) {
    dispatch(updateAccountRequestSuccess(result));
    dispatch(showNotification('Successfully Updated', 'success'));
  }
};

export const getTemplateTenant = (id) => async (dispatch) => {
  const [result, error] = await api.getTemplateTenantRequest(id);

  if (error && error.message) {
    dispatch(handleError(error));
  }

  if (result) {
    dispatch(setTemplateTenant(result));
  }
};

export const getAccountNetworkSettings = (accountId) => async (dispatch) => {
  const [result, error] = await api.getNetworkByTenantIdRequest(accountId);

  if (error?.message) {
    dispatch(handleError(error));
  }

  if (result && result.data) {
    dispatch(setAccountNetworkSettings(result.data));
  }
};

export default accountSlice.reducer;
