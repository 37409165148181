/* eslint-disable react/no-array-index-key */
// MUI
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
// import CloseIcon from '@material-ui/icons/Close';
// import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import SendIcon from '@material-ui/icons/Send';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import DiscussionStyles from '../../../../assets/jss/components/DiscussionStyles';
import { sendDiscussionMessage } from '../reviewerSlice';

import MessageWrapper from './MessageWrapper';

function DiscussionContent(props) {
  const { annotationId, shapeData, classes } = props;

  const { id: shapeId, description, discussion } = shapeData;

  const [currentValue, setCurrentValue] = useState('');
  const [isCtrlHolding, setIsCtrlHolding] = useState(false);

  const messengerRef = useRef(null);
  const messagesRef = useRef(null);
  const dispatch = useDispatch();

  const shapeDescription = `Concern: ${description?.concern ? description.concern.join(', ') : ''} \nRecommendation: ${
    description?.recommendation || ''
  } \nReviewer: ${description?.reviewer?.name || ''}`;

  const scrollToBottom = () => {
    if (messagesRef.current) {
      messagesRef.current.scrollTo({
        top: messagesRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, []);

  useEffect(() => {
    const handleKeyDown = (e) => {
      const key = e.keyCode || e.which;
      if (key === 17) {
        setIsCtrlHolding(true);
      }
    };
    const handleKeyUp = () => {
      setIsCtrlHolding(false);
    };

    messengerRef.current.addEventListener('keydown', handleKeyDown);
    messengerRef.current.addEventListener('keyup', handleKeyUp);

    return () => {
      messengerRef.current.removeEventListener('keydown', handleKeyDown);
      messengerRef.current.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  const handleChangeValue = (e) => {
    const { value } = e.target;
    setCurrentValue(value);
  };

  const handleSendMessage = async () => {
    const messageWithShapeDescription =
      `${shapeDescription}\n---------------------------------\n${currentValue}`.replace(/\n/g, '<br />');
    await dispatch(sendDiscussionMessage(annotationId, shapeId, messageWithShapeDescription));
    scrollToBottom();
    setCurrentValue('');
  };

  const handleKeyPress = (e) => {
    const key = e.keyCode || e.which;
    if (key == 13 && isCtrlHolding) {
      handleSendMessage();
    }
  };

  return (
    <Grid className={classes.discussionBlock}>
      <Grid ref={messagesRef} className={classes.messages}>
        {discussion &&
          discussion.length &&
          discussion.map((item, key) => (
            <MessageWrapper
              prevMessage={discussion[key - 1]}
              currentMessage={item}
              nextMessage={discussion[key + 1]}
              key={key}
            />
          ))}
      </Grid>

      <Grid style={{ position: 'relative' }}>
        <TextField
          id="outlined-multiline-static"
          ref={messengerRef}
          label="Message"
          multiline
          rows={2}
          rowsMax={Infinity}
          variant="outlined"
          onChange={(e) => handleChangeValue(e)}
          onKeyPress={(e) => handleKeyPress(e)}
          value={currentValue}
          style={{ width: '100%' }}
        />
        <IconButton
          color="inherit"
          className={classes.sendIcon}
          disabled={!currentValue}
          onClick={handleSendMessage}
          // style={{ color: appThemeColor }}
        >
          <SendIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
}

export default withStyles(DiscussionStyles)(DiscussionContent);
