/* eslint-disable react/no-unstable-nested-components */
import Grid from '@material-ui/core/Grid';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ListFeedback from './ListFeedback';

// redux
import { selectTypeahead } from './selectors';
import { search, reset } from './typeaheadSlice';

// ui reflection of file view
function AutocompleteInput(props) {
  const { field, value, handleChangeWithParams, autoSetValues, disabled } = props;
  const typeahead = useSelector(selectTypeahead);

  const [loading, setLoading] = useState(false);
  const [autocompleteValue, setAutocompleteValue] = useState(value);

  const [timeout, setStateTimeout] = useState(undefined);

  const dispatch = useDispatch();
  const values = typeahead && typeahead[field.name];
  useEffect(() => {
    setAutocompleteValue(value);

    return () => {
      // TODO: call reset action
      dispatch(reset(field.name));
    };
    // }, [field,value]);
  }, []);
  useEffect(() => {
    if (values) {
      setLoading(values.loading);
    }
  }, [values]);

  const handleChange = (e) => {
    let { value } = e.target;
    const { name } = e.target;
    setAutocompleteValue(value);
    if (field.valueField && value) {
      value = value[field.valueField];
    }
    handleChangeWithParams(name, value);

    if (field.setFields && autoSetValues) {
      autoSetValues(
        field.setFields.reduce(
          (accum, f) => ({
            ...accum,
            [f.targetField]: (e.target.value && e.target.value[f.field]) || '',
          }),
          {}
        )
      );
    }
  };

  const handleFilterChange = (event) => {
    const searchType =
      field.type === 'typeaheadVcb' || field.searchType === 'snomedfindings'
        ? field.searchType
        : `${field.searchType}code`;

    if (event.filter.value.length > 2) {
      if (timeout) {
        clearTimeout(timeout);
      }

      setStateTimeout(
        setTimeout(() => {
          dispatch(search(field.name, field.searchClinical, searchType, event.filter.value, field.resultCount || 10));
        }, 500)
      );

      setLoading(true);
    }
  };
  return (
    <Grid item xs={12}>
      <ComboBox
        data={(values && values.values) || []}
        allowCustom
        suggest
        filterable
        onFilterChange={handleFilterChange}
        label={field.label}
        name={field.name}
        required={field.required}
        onChange={handleChange}
        style={{ width: '100%' }}
        textField={field.labelField}
        value={autocompleteValue}
        loading={loading}
        disabled={disabled}
        listNoDataRender={() => <ListFeedback loading={loading} />}
      />
    </Grid>
  );
}

export default AutocompleteInput;
