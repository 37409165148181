// mui
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import { DialogActionsBar } from '@progress/kendo-react-dialogs';
import React from 'react';

function FormDialog(props) {
  const { onCancelSubmit, openConfirmation, confirmation, title, continueText, submitData } = props;

  return (
    <Dialog onClose={onCancelSubmit} width="100%" height="100%" open={openConfirmation} scroll="paper">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Typography>{confirmation}</Typography>
      </DialogContent>
      <DialogActionsBar>
        <button className="k-button" onClick={onCancelSubmit}>
          Cancel
        </button>
        <button className="k-button" onClick={submitData}>
          {continueText}
        </button>
      </DialogActionsBar>
    </Dialog>
  );
}

export default FormDialog;
