import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import DoneRounded from '@material-ui/icons/DoneRounded';
import ErrorIcon from '@material-ui/icons/Error';
import React from 'react';

export function ValidationMessage(props) {
  const classes = useStyles();
  const { status, message, closeErrorMessage } = props;

  function renderBoxIcon() {
    if (status) {
      return (
        <Box className={classes.positiveBox}>
          <DoneRounded className={classes.positiveBoxIcon} />
        </Box>
      );
    }

    return (
      <Box className={classes.negativeBox}>
        <ErrorIcon className={classes.negativeBoxIcon} />
      </Box>
    );
  }

  return (
    <ValidationMessagePaper>
      <Box pt={3} pb={3} pl={1} pr={2}>
        <Grid container>
          <Grid className={classes.boxContainer} alignItems="center" justifyContent="center" item xs={2}>
            {renderBoxIcon()}
          </Grid>
          <Grid className={classes.messageContainer} item xs={9}>
            <Box>
              <Typography className={classes.text}>{message}</Typography>
            </Box>
          </Grid>
          <Grid style={{ alignSelf: 'center' }} item xs={1}>
            <Box style={{ justifyContent: 'center' }}>
              <IconButton style={{ height: 24, width: 24, color: 'white' }} onClick={() => closeErrorMessage('')}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </ValidationMessagePaper>
  );
}

const ValidationMessagePaper = withStyles({
  root: {
    backgroundColor: '#293A5A',
  },
  rounded: {
    borderRadius: 6,
  },
})(Paper);

const useStyles = makeStyles({
  boxContainer: {
    display: 'flex',
  },
  text: {
    fontSize: 13,
    color: '#FFFFFF',
  },
  bold: {
    fontFamily: 'SF UI Text Heavy',
  },
  negativeBox: {
    width: 30,
    height: 30,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    backgroundColor: 'white',
    color: '#FF6464',
  },
  negativeBoxIcon: {
    fontSize: 38,
  },
  positiveBox: {
    fontSize: 15,
    width: 30,
    height: 30,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    color: 'white',
    backgroundColor: '#20BC76',
  },
  positiveBoxIcon: {
    fontSize: 30,
  },
  nowrap: {
    whiteSpace: 'nowrap',
  },
  messageContainer: {
    display: 'flex',
    alignItems: 'center',
  },
});
