import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Dialog } from '@progress/kendo-react-dialogs';
import React, { useState } from 'react';

import SettingsFontsStyles from '../../../assets/jss/components/SettingsFontsStyles';

function FontFormDialog(props) {
  const { closeDialog, handleAddNewFont, createFontLoading, classes, existingFontNames } = props;

  const [fontName, setFontName] = useState('');
  const [fallBackFonts, setFallBackFonts] = useState('');
  const [fontNameError, setFontNameError] = useState('');
  const [fallBackFontsError, setFallBackFontsError] = useState('');

  const handleClose = () => {
    closeDialog();
  };

  const handleSave = () => {
    if (
      existingFontNames?.filter(
        (existingFontName) => existingFontName?.toLowerCase().trim() === fontName.toLowerCase().trim()
      ).length
    )
      return setFontNameError('Name Already In Use!');

    const fontFamily = `${fontName},${fallBackFonts}`;

    handleAddNewFont(fontName, fontFamily);
  };

  const updateFontName = (e) => {
    const { value } = e.target;
    setFontName(value);
  };

  const updateFallBackFonts = (e) => {
    const { value } = e.target;
    setFallBackFonts(value);
  };

  return (
    <Dialog title="Add New Font" onClose={handleClose}>
      <Grid container justifyContent="flex-end" spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Font name"
            name="name"
            onChange={updateFontName}
            onFocus={() => setFontNameError('')}
            margin="normal"
            variant="outlined"
            value={fontName}
            autoComplete="off"
            type="text"
            multiline
            rows={1}
            rowsMax={2}
            required
            fullWidth
            error={!!fontNameError}
            helperText={fontNameError}
          />

          <TextField
            label="Fallback Fonts"
            name="fallBackFonts"
            placeholder="Avenir,Helvetica,Arial,sans-serif"
            onChange={updateFallBackFonts}
            onFocus={() => setFallBackFontsError('')}
            margin="normal"
            variant="outlined"
            value={fallBackFonts}
            autoComplete="off"
            type="text"
            multiline
            rows={1}
            rowsMax={2}
            required
            fullWidth
            error={!!fallBackFontsError}
            helperText={fallBackFontsError}
          />
        </Grid>
        <Grid item>
          <Button color="primary" variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
        </Grid>
        <Grid item style={{ position: 'relative' }}>
          {createFontLoading ? (
            <Grid className={classes.loadingSaveFontWrapper}>
              <CircularProgress size={28} thickness={6} />
            </Grid>
          ) : null}
          <Button
            disabled={!fontName || !fallBackFonts || createFontLoading}
            color="primary"
            variant="contained"
            onClick={handleSave}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
}

export default withStyles(SettingsFontsStyles)(FontFormDialog);
