import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup/FormGroup';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Input } from '@progress/kendo-react-inputs';
import React, { useState, useEffect } from 'react';

import utils from '../../Utils/utils';

const data = [
  {
    text: 'I am the person getting tested',
    value: 'patient',
  },
  {
    text: 'I am the Parent/Guardian',
    value: 'parent_guardian',
  },
  {
    text: 'I am the Power of Attorney',
    value: 'power_of_attorney',
  },
];

// ui reflection of file view
function ConsentForm(props) {
  const { field, handleChange, selectedLanguage } = props;
  const [checked, setChecked] = useState(false);
  const [signedBy, setSignedBy] = useState(data[0]);
  const [fullName, setFullName] = useState('');

  useEffect(() => {
    let consentData = null;
    if (signedBy && signedBy.value && fullName && checked) {
      consentData = [signedBy.value, fullName];
    }
    handleChange(consentData);
  }, [fullName, signedBy, checked]);

  const handleChecked = (event) => {
    const { checked } = event.target;
    setChecked(checked);
  };

  const answer = {
    eng: 'I consent',
    spa: 'Yo consiento',
    zho: '我同意',
    rus: 'Я согласен',
    fre: "J'accepte",
  };

  const handleValueChange = (e) => {
    setSignedBy(e.value);
  };

  const handleChangeFullName = (e) => {
    const { value } = e.target;
    setFullName(value);
  };

  // A properly formatted question for this type should use the 'description' field for the legal agreement
  const questionDescription =
    selectedLanguage && field.description && field.description.translations
      ? field.description.translations[selectedLanguage]
      : field.description;

  return (
    <Grid item xs={12}>
      <Typography
        style={{
          opacity: 0.5,
          marginBottom: 10,
          maxHeight: 270,
          overflow: 'hidden',
          overflowY: 'auto',
        }}
      >
        {utils.renderHTMLAsText(questionDescription)}
        {field.required && '*'}
      </Typography>

      <Grid item xs={12}>
        <Input
          width="100%"
          value={fullName}
          onChange={handleChangeFullName}
          label="Name"
          placeholder="Type full name"
          name="username"
          style={{ width: '100%' }}
          type="text"
          required
        />
      </Grid>

      <Grid item xs={12} style={{ margin: '15px 0' }}>
        <DropDownList
          label="Signed by"
          name="signed_by"
          data={data}
          onChange={handleValueChange}
          required={field.required}
          style={{ width: '100%' }}
          value={signedBy || data[0]}
          textField="text"
        />
      </Grid>

      <Grid item>
        <FormControl component="fieldset">
          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={checked} onChange={handleChecked} name="consent" color="primary" />}
              label={answer[selectedLanguage] || 'I consent'}
            />
          </FormGroup>
        </FormControl>
      </Grid>
    </Grid>
  );
}

export default ConsentForm;
