import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState, useEffect, useCallback } from 'react';

// redux
import { useDispatch, useSelector } from 'react-redux';

import ProfileViewStyles from '../../assets/jss/components/ProfileViewStyles';
import { selectCurrentTenant } from '../Shared/selectors';
import AppKendoTableWrapper from '../Shared/Table/KendoTableWrapper';

import { selectUserDetails } from '../Users/selectors';
import ConditionDialog from './ConditionDialog';
import { conditionColumns as columns } from './helpers/clinicalInfoMapper';
import { createClinicalObjBuilder, convertSelectedConditionObj } from './helpers/createClinicalObjBuilder';
import { getConditionList, postConditionList, postConditionToDrfirst } from './slices/conditionListSlice';
import { selectConditionList, selectConditionListLoading } from './slices/selectors';

function Condition() {
  const classes = useStyles();

  const conditionList = useSelector(selectConditionList);
  const conditionListLoading = useSelector(selectConditionListLoading);
  const userDetails = useSelector(selectUserDetails);
  const currentTenant = useSelector(selectCurrentTenant);

  const [pagedConditionList, setPagedConditionList] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedCondition, setSelectedCondition] = useState({});
  const [dialogMode, setDialogMode] = useState('Add');
  const [tableButtons, setTableButtons] = useState([]);

  const dispatch = useDispatch();

  const sentConditionToDrfirst = useCallback(() => {
    dispatch(postConditionToDrfirst(userDetails.nationalHealthId));
  }, [userDetails.nationalHealthId]);

  const handleRowClick = (e) => {
    const selected = pagedConditionList ? pagedConditionList.find((i) => i.id === e.id) : e;
    const selectedCondition = convertSelectedConditionObj(selected);
    setSelectedCondition(selectedCondition);
    setDialogMode('Edit');
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setSelectedCondition({});
    setDialogMode('Add');
    setDialogOpen(false);
  };
  const handleTableUpdate = (page, pageSize) => {
    setPagedConditionList(conditionList.data.slice((page + 1) * pageSize - pageSize, (page + 1) * pageSize));
  };

  const handleUpdateCondition = (conditionObj) => {
    const condition = createClinicalObjBuilder(conditionObj);
    const conditionList = {
      patientDetailDTOList: [condition],
      safeEMRId: userDetails.nationalHealthId,
      resourceType: 'Condition',
    };
    dispatch(postConditionList(userDetails.nationalHealthId, conditionList));
  };
  useEffect(() => {
    dispatch(getConditionList(userDetails.nationalHealthId));
  }, [currentTenant, userDetails]);

  useEffect(() => {
    if (conditionList) {
      setPagedConditionList(conditionList.data);
      if (conditionList.data) {
        setTableButtons([
          {
            title: 'Add New',
            buttonType: 'action',
            variant: 'outlined',
            action: () => {
              setDialogOpen(true);
            },
          },
          {
            title: 'Sync Drfirst',
            buttonType: 'action',
            variant: 'outlined',
            disabled: conditionList && conditionList.data.length <= 0,
            action: () => {
              sentConditionToDrfirst();
            },
          },
        ]);
      }
    }
  }, [conditionList, sentConditionToDrfirst]);

  const getCondtionName = (data) => {
    if (data.conditionCode) {
      if (data.conditionCode.display) return data.conditionCode.display;
      return '---';
    }
    return '---';
  };
  return (
    <Grid className={`${classes.contentWrapper} ${classes.withTableButtons}`}>
      <AppKendoTableWrapper
        data={{
          ...pagedConditionList,
          items:
            pagedConditionList &&
            pagedConditionList.map((n) => ({
              ...n,
              conditionName: getCondtionName(n),
              dateRecorded: n.dateRecorded ? n.dateRecorded : '---',
              clinicalStatus: n.clinicalStatus,
              verificationStatus: n.verificationstatus,
              severity: n.severity ? n.severity : '---',
            })),
          total: conditionList && conditionList.data.length,
        }}
        initialSort="dateRecorded"
        loading={conditionListLoading}
        initialDir="desc"
        columns={columns()}
        onTableUpdate={handleTableUpdate}
        showButtons
        onRowClick={handleRowClick}
        tableButtons={tableButtons}
      />
      {dialogOpen && (
        <ConditionDialog
          selectedCondition={selectedCondition}
          dialogMode={dialogMode}
          closeDialog={handleCloseDialog}
          updateCondition={handleUpdateCondition}
        />
      )}
    </Grid>
  );
}

const useStyles = makeStyles(ProfileViewStyles);

export default Condition;
