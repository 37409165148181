/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { useTheme, withStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React, { useEffect, useState } from 'react';

// redux
import { useDispatch, useSelector } from 'react-redux';

import { useNavigate } from 'react-router-dom';

import CreateFormStyles from '../../assets/jss/components/CreateFormStyles';
import { selectAccountDetails } from '../Account/selectors';
import { showConfirmation } from '../Confirmation/confirmationSlice';
import { selectLanguage } from '../LanguageMenu/selectors';

// components and helpers
import { updateNetwork } from '../Networks/networksSlice';
import { selectTenantNetwork } from '../Networks/selectors';
import { variables } from '../Settings/SystemMessages/helpers/templateVariables';
import CreateFormWrapper from '../Shared/Forms/CreateFormWrapper';
// mui

import { selectCurrentTenantId } from '../Shared/selectors';
import utils from '../Shared/Utils/utils';
import {
  ContentSkeletonLoading,
  VideoContentSkeletonLoading,
  ArticlesContentSkeletonLoading,
  VideoContentSkeletonLoadingMobile,
  ArticlesContentSkeletonLoadingMobile,
  ContentSkeletonLoadingMobile,
} from './ContentSkeletonLoading';
import {
  addContent,
  getContent,
  getWidgetKeys,
  getWidgetTypes,
  updateContent,
  getMessageContent,
  updateMessageContent,
  addMessageContent,
} from './contentSlice';
import { createContentJsonBuilder } from './helpers/createContentJsonBuilder';
import {
  ContentResourcesFormMap,
  ContentWidgetsFormMap,
  ContentVideosFormMap,
  ContentMessageStandardizationFormMap,
} from './helpers/formHelper';
import { selectContent } from './selectors';

function ContentEditWrapper(props) {
  const { classes, type, edit, id } = props;
  const navigate = useNavigate();
  const currentNetwork = useSelector(selectTenantNetwork);
  const language = useSelector(selectLanguage);
  const content = useSelector(selectContent);
  const account = useSelector(selectAccountDetails);
  const currentTenant = useSelector(selectCurrentTenantId);

  const [editContent, setEditContent] = useState({});
  const [primaryVideo, setPrimaryVideo] = useState(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const [steps, setSteps] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getWidgetTypes(type, id));
    dispatch(getWidgetKeys(type, id));
    if (currentTenant && id && edit) {
      if (type == 'messageStandardizations') dispatch(getMessageContent(type, id));
      else dispatch(getContent(type, currentTenant, id));
    }
  }, [currentTenant, edit, id, type]);

  let contentDetails;
  const introWidgetTypes = [];
  const introWidgetKeys = [];

  // check if video id is equal to the network primary video ID, if so, set primary video to true
  useEffect(() => {
    if (
      content[type] &&
      !content[type].loadingContent &&
      content[type].selected &&
      content[type].selected.data &&
      typeof content[type].selected.data === 'object' &&
      currentNetwork
    ) {
      let contentData = { ...content[type].selected.data };
      if (currentNetwork.primaryVideoID === id) {
        setPrimaryVideo(true);
        contentData.isPrimaryVideo = true;
      }
      contentData = {
        ...contentData,
        isAppIntroWidget: contentData.type === 'AppResponseWidget',
      };
      setEditContent(contentData);
    }
    if (content[type] && !content[type].loadingContent && content[type].types && content[type].keys) {
      for (let key in content[type].types) {
        introWidgetTypes.push({
          value: key,
          label: content[type].types[key],
        });
      }
      for (let key in content[type].keys) {
        introWidgetKeys.push({
          value: parseInt(key),
          label: content[type].keys[key],
        });
      }
      if (type === 'videos') {
        contentDetails = ContentVideosFormMap;
      } else if (type === 'introWidgets') {
        contentDetails = ContentWidgetsFormMap(introWidgetTypes, introWidgetKeys);
      } else if (type === 'messageStandardizations') {
        contentDetails = ContentMessageStandardizationFormMap(introWidgetKeys);
      } else {
        contentDetails = ContentResourcesFormMap;
      }

      // sets step to it's correctly formatted JSON
      setSteps([
        {
          label: type,
          data: contentDetails,
        },
      ]);
    }
  }, [content, currentNetwork, type, id]);

  // checks type to render appropriate JSON for formatting

  const handleSubmit = (formData) => {
    const data = createContentJsonBuilder[type](formData);
    // eslint-disable-next-line no-unused-expressions
    type == 'messageStandardizations' ? (data.tenantId = currentTenant) : (data.Data.tenantId = currentTenant);
    let networkData;
    const currentNetworkTemp = { ...currentNetwork };
    if (primaryVideo || formData.isPrimaryVideo) {
      delete currentNetworkTemp.modified;
      delete currentNetworkTemp.created;
      delete currentNetworkTemp.creator;
      delete currentNetworkTemp.modifier;
    }
    if (formData.isPrimaryVideo && !primaryVideo) {
      currentNetworkTemp.primaryVideoID = id;
      networkData = { Data: currentNetworkTemp };
    } else if (!formData.isPrimaryVideo && primaryVideo) {
      currentNetworkTemp.primaryVideoID = null;
      networkData = { Data: currentNetworkTemp };
    }
    if (edit) {
      // eslint-disable-next-line no-unused-expressions
      type === 'messageStandardizations'
        ? dispatch(updateMessageContent(type, currentTenant, data)).then(() => {
            navigate(
              `/${currentTenant}/settings/${utils.camelToSnake(type).substring(0, utils.camelToSnake(type).length - 1)}`
            );
          })
        : dispatch(updateContent(type, currentTenant, data)).then(() => {
            if (type === 'introWidgets') {
              navigate(`/${currentTenant}/settings/configuration/assets/${utils.camelToSnake(type)}`);
            } else {
              navigate(`/${currentTenant}/content/${utils.camelToSnake(type)}`);
            }
          });
      if ((primaryVideo || formData.isPrimaryVideo) && networkData) {
        dispatch(updateNetwork(currentTenant, networkData, account?.id));
      }
    } else {
      dispatch(
        type === 'messageStandardizations'
          ? addMessageContent(type, currentTenant, data)
          : addContent(type, currentTenant, data)
      ).then((response) => {
        if (type === 'introWidgets') {
          navigate(`/${currentTenant}/settings/configuration/assets/${utils.camelToSnake(type)}`);
        } else {
          navigate(`/${currentTenant}/content/${utils.camelToSnake(type)}`);
        }
        if (primaryVideo || formData.isPrimaryVideo) {
          networkData.Data.primaryVideoID = response.data;
          dispatch(updateNetwork(currentTenant, networkData, account?.id));
        }
      });
    }
  };

  const handleDelete = (formData) => {
    // eslint-disable-next-line no-unused-expressions
    type == 'messageStandardizations'
      ? dispatch(
          showConfirmation(
            'Confirm',
            'Are you sure you want to delete this content?',
            'messageDelete',
            currentTenant,
            formData._id,
            type
          )
        )
      : dispatch(
          showConfirmation(
            'Confirm',
            'Are you sure you want to delete this content?',
            'contentDelete',
            currentTenant,
            formData._id,
            type
          )
        );
  };

  const contentSkeletonLoading = () => {
    switch (type) {
      case 'videos':
        return <VideoContentSkeletonLoading />;
      case 'articles':
        return <ArticlesContentSkeletonLoading />;
      default:
        return <ContentSkeletonLoading />;
    }
  };

  const contentSkeletonLoadingMobile = () => {
    switch (type) {
      case 'videos':
        return <VideoContentSkeletonLoadingMobile />;
      case 'articles':
        return <ArticlesContentSkeletonLoadingMobile />;
      default:
        return <ContentSkeletonLoadingMobile />;
    }
  };

  if (!currentTenant) return null;

  if ((content[type] && content[type].loadingContent) || (edit && Object.keys(editContent).length === 0) || !steps) {
    return (
      <Grid container justifyContent="center">
        {matches ? (
          <Grid item xs={12} md={7}>
            <Paper elevation={0} style={{ borderRadius: 30 }}>
              {contentSkeletonLoading()}
            </Paper>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Paper elevation={0} style={{ borderRadius: 30 }}>
              {contentSkeletonLoadingMobile()}
            </Paper>
          </Grid>
        )}
      </Grid>
    );
  }

  return (
    <Grid container justifyContent="center" className={classes.container}>
      <CreateFormWrapper
        steps={steps}
        handleSubmit={handleSubmit}
        handleDelete={handleDelete}
        formType={edit && 'editContent'}
        initialValues={edit ? { ...editContent, language, variables } : { language, variables }}
      />
    </Grid>
  );
}

export default withStyles(CreateFormStyles)(ContentEditWrapper);
