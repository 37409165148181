/* eslint-disable react/no-array-index-key */

import Grid from '@material-ui/core/Grid';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import ToggleField from '../../Shared/Forms/KendoFields/ToggleField';

import { updateNetworkForDashboard } from '../networksSlice';

export function DashboardSettingWrapper(props) {
  const { currentNetwork } = props;

  const [dashboardChartItems, setDashboardChartItems] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (currentNetwork && currentNetwork.dashboardSettings) {
      let chartItems = _.cloneDeep(currentNetwork.dashboardSettings.dashboardChartItems);
      setDashboardChartItems(chartItems);
    }
  }, [currentNetwork]);

  const getDashboardFields = () => {
    const listItems = [];
    if (currentNetwork?.dashboardSettings?.dashboardChartItems?.length > 0) {
      const fields = [];
      currentNetwork.dashboardSettings.dashboardChartItems.forEach((x) => {
        fields.push({
          type: 'toggle',
          name: x.dashboardChartId,
          label: x.detail.name,
          required: false,
          width: 4,
          labelPlacement: 'end',
          default: x.enabled,
        });
      });
      fields.forEach((x) => {
        listItems.push(
          <Grid item xs={3} style={{ paddingTop: 10, paddingBottom: 10 }}>
            <ToggleField field={x} value={x.default} handleChangeWithParams={handleChangeWithParams} disabled={false} />
          </Grid>
        );
      });
    }
    return listItems.map((item, index) => <React.Fragment key={index}>{item}</React.Fragment>);
  };

  const handleChangeWithParams = (name, value) => {
    const chartItem = dashboardChartItems.find((x) => x.dashboardChartId === name);
    chartItem.enabled = value;
    setDashboardChartItems(dashboardChartItems.filter((x) => x.dashboardChartId === name).push(chartItem));
    dispatch(updateNetworkForDashboard(dashboardChartItems));
  };

  return <Grid container>{getDashboardFields()}</Grid>;
}

export default DashboardSettingWrapper;
