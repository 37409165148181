/* eslint-disable import/namespace */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-return-assign */
/* eslint-disable no-undef */
/* eslint-disable no-unused-expressions */
// check to make sure all mandatory fields are populated before proceeding with lab order creation
import utils from '../../Shared/Utils/utils';

export const checkMandatoryFields = (mandatoryFields, userId, users, userSpecific, setMissingData) => {
  const missingFields = [];
  for (let i = 0; i < mandatoryFields.length; i++) {
    users &&
      users.map((user) => {
        if (userId.includes(user.email)) {
          if (
            !user[mandatoryFields[i]] &&
            (!user.primaryAddress || (user.primaryAddress && !user.primaryAddress[mandatoryFields[i]]))
          ) {
            let fieldDisplay = utils.splitCamelcaseString(mandatoryFields[i].split('.').pop());
            if (fieldDisplay === 'line1') {
              fieldDisplay = 'Address Line 1';
            }
            missingFields.push(utils.capitalizeString(fieldDisplay));
          }
        }
        return null;
      });
  }
  userSpecific && setMissingData(missingFields);
  return missingFields;
};

export const getAssessmentData = (assessmentVal) => {
  let assessmentData = assessmentMapping.EMPTY;

  switch (assessmentVal?.toLowerCase()) {
    case 'negative':
    case '1': {
      return (assessmentData = assessmentMapping.NEGATIVE);
    }
    case 'positive':
    case '2': {
      return (assessmentData = assessmentMapping.POSITIVE);
    }
    case 'invalid': {
      return (assessmentData = assessmentMapping.INVALID);
    }
    case 'error': {
      return (assessmentData = assessmentMapping.ERROR);
    }
    case 'unknown': {
      return (assessmentData = assessmentMapping.UNKNOWN);
    }
    case 'inconclusive': {
      return (assessmentData = assessmentMapping.UNKNOWN);
    }
    case 'n/a': {
      return (assessmentData = assessmentMapping.NA);
    }
    default: {
      // eslint-disable-next-line
      return (assessmentData = assessmentMapping.EMPTY);
    }
  }
};
