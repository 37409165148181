import { makeStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import { useTheme } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import ProfileViewStyles from '../../assets/jss/components/ProfileViewStyles';

import { selectCurrentTenant } from '../Shared/selectors';
import { selectUserDetails } from '../Users/selectors';
import JsonViewer from './JsonViewer';
import XMLData from './XMLViewer';

function ClinicalInformation() {
  const classes = useStyles();

  const userDetails = useSelector(selectUserDetails);
  const currentTenant = useSelector(selectCurrentTenant);

  const theme = useTheme();
  const [value, setValue] = useState(0);
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  let pageComponent;

  useEffect(() => {
    const splitUrl = window.location.href.split('/');
    const page = splitUrl[splitUrl.length - 1];
    if (page !== '') {
      if (page === 'xml') {
        setValue(1);
      }
    }
  }, []);

  const handleChange = (e, value) => {
    setValue(value);
    let path;
    if (value === 1) {
      path = 'xml';
    }
    const splitUrl = window.location.href.split('/');
    if (splitUrl[splitUrl.length - 1] === 'ccd') {
      if (value !== 0) {
        window.history.pushState(path, path, `${splitUrl.join('/')}/${path}`);
      }
    } else {
      splitUrl.pop();
      if (value === 0) {
        window.history.pushState(path, path, `${splitUrl.join('/')}`);
      } else {
        window.history.pushState(path, path, `${splitUrl.join('/')}/${path}`);
      }
    }
  };

  switch (value) {
    case 1:
      pageComponent = <XMLData userDetails={userDetails} currentTenant={currentTenant} />;

      break;
    default:
      pageComponent = <JsonViewer userDetails={userDetails} currentTenant={currentTenant} />;

      break;
  }

  return (
    <Card style={{ borderRadius: '20px' }} elevation={0}>
      <Paper style={{ borderRadius: '20px 20px 0px 0px' }}>
        {matches ? (
          <Tabs
            value={value}
            indicatorColor="primary"
            variant="fullWidth"
            textColor="primary"
            onChange={handleChange}
            aria-label="disabled tabs example"
          >
            <Tab
              label="JSON"
              style={{ fontSize: '12px', marginBottom: '-10px' }}
              className={value === '' || value === 0 ? classes.activeLink : ''}
            />
            <Tab
              label="XML"
              id="xml"
              style={{ fontSize: '12px', marginBottom: '-10px' }}
              className={value === 1 ? classes.activeLink : ''}
            />
          </Tabs>
        ) : (
          <FormControl variant="outlined" fullWidth>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={value}
              onChange={(e) => setValue(e.target.value)}
            >
              <MenuItem value={0} key={0}>
                JSON
              </MenuItem>
              <MenuItem value={1} key={1}>
                XML
              </MenuItem>
            </Select>
          </FormControl>
        )}
      </Paper>
      <div style={{ marginTop: 10 }}>{pageComponent}</div>
    </Card>
  );
}

const useStyles = makeStyles(ProfileViewStyles);

export default ClinicalInformation;
