import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

import api from './api';
import { lsClient } from './ls-client';

const initialState = {
  previewQuestion: null,
  executionId: null,
  answers: {},
  validAnswer: false,
  invalidCode: false,
  isValidPhoneNumber: null,
  verifiedInsurance: null,
  verifyingInsurance: true,
  payorList: null,
  reporterAnswers: {},
};

export const questionnaireSlice = createSlice({
  name: 'questionnaire',
  initialState,
  reducers: {
    setCurrentQuestion: (state, { payload }) => ({
      ...state,
      previewQuestion: {
        ...payload,
        question: {
          ...payload?.question,
          question: payload?.question?.question && {
            ...payload?.question?.question,
            subnodes: payload?.question?.subnodes,
          },
        },
      },
    }),
    setExecutionId: (state, { payload }) => ({
      ...state,
      executionId: payload,
    }),
    setQuestionnaireAnswers: (state, { payload }) => ({
      ...state,
      answers: payload,
    }),
    setIsValidVerificationCode: (state, { payload }) => ({
      ...state,
      validAnswer: payload.validAnswer,
      invalidCode: payload.invalidCode,
    }),
    setIsValidPhoneNumber: (state, { payload }) => ({
      ...state,
      isValidPhoneNumber: payload,
    }),
    setVerifyInsurance: (state, { payload }) => ({
      ...state,
      verifiedInsurance: payload.verifiedInsurance,
      verifyingInsurance: payload.verifyingInsurance,
    }),
    setPayorList: (state, { payload }) => ({
      ...state,
      payorList: payload,
    }),
    setReporterAnswers: (state, { payload }) => ({
      ...state,
      reporterAnswers: payload,
    }),
    resetQuestionnaireForm: () => ({ ...initialState }),
  },
});

export const {
  setCurrentQuestion,
  setQuestionnaireAnswers,
  setExecutionId,
  resetQuestionnaireForm,
  setIsValidVerificationCode,
  setIsValidPhoneNumber,
  setVerifyInsurance,
  setPayorList,
  setReporterAnswers,
} = questionnaireSlice.actions;

export const registerAnonymousUser = (tenantId) => async () => {
  const [result] = await api.registerAnonymousUserRequest(tenantId);
  if (result && result.token) {
    lsClient.questionnaire.setAnonUserAuthToken(result.token);
  }
};

export const startQuestionnaire = (questionnaireId, tenantId, testSiteVariables) => async (dispatch) => {
  const [result] = await api.startQuestionnaireRequest(questionnaireId, tenantId, testSiteVariables);
  // dispatch(clearQuestionnaireForm());
  dispatch(setCurrentQuestion(result));
  if (result && result.executionId) {
    dispatch(saveExecutionId(result.executionId));
    saveQuestionnaireDataToLS('questionnaireId', questionnaireId);
  }
  dispatch(saveQuestionnaireAnswers({}));
};

export const nextQuestionnaireQuestion =
  (tenantId, executionId, data, withReporterFunc) => async (dispatch, getState) => {
    const {
      questionnaire: { previewQuestion },
    } = getState();

    const [result] = await api.getNextQuestionRequest(tenantId, executionId, data);
    dispatch(setCurrentQuestion(result));

    if (withReporterFunc) {
      dispatch(saveAnswerForReporter(previewQuestion, data));
    }
  };

export const getCurrentQuestionnaireQuestion = (tenantId, executionId) => async (dispatch) => {
  const [result] = await api.getCurrentQuestionRequest(tenantId, executionId);
  dispatch(setCurrentQuestion(result));
};

export const prevQuestionnaireQuestion = (tenantId, executionId) => async (dispatch) => {
  const [result] = await api.getPreviousQuestionRequest(tenantId, executionId);
  dispatch(setCurrentQuestion(result));
};

export const jumpDirectlyToNode = (tenantId, executionId, nodeId, withReporterFunc) => async (dispatch) => {
  const [result] = await api.jumpDirectlyToNodeRequest(tenantId, executionId, nodeId);
  dispatch(setCurrentQuestion(result));
  if (result && result.complete) {
    dispatch(questionnaireResult(tenantId, executionId, withReporterFunc));
  }
};

export const questionnaireResult = (tenantId, executionId, withReporterFunc) => async (dispatch) => {
  const [result] = await api.getQuestionnaireResultRequest(tenantId, executionId);
  dispatch(setCurrentQuestion(result));
  if (withReporterFunc) {
    dispatch(saveAnswerForReporter(result.data, null, true));
  }
};

export const getPayorList = (tenantId) => async (dispatch) => {
  const [result, error] = await api.getPayorListRequest(tenantId);

  if (result && result.data) {
    dispatch(setPayorList(result.data));
  }

  if (error && error.message) {
    dispatch(setPayorList(null));
  }
};

export const verifyInsurance = (tenantId, verificationObject) => async (dispatch) => {
  const [result] = await api.verifyInsuranceRequest(tenantId, verificationObject);
  if (result?.success && result?.data?.eligibleStatus === 'Eligible') {
    dispatch(
      setVerifyInsurance({
        verifiedInsurance: true,
        verifyingInsurance: true,
      })
    );
    return true;
  }
  dispatch(
    setVerifyInsurance({
      verifiedInsurance: false,
      verifyingInsurance: false,
    })
  );
  return false;
};

export const sendVerificationCode = (tenantId, request, verificationType) => async (dispatch, getState) => {
  const [result] = await api.sendVerificationCodeRequest(tenantId, request);

  dispatch(setIsValidPhoneNumber(null));
  if (!result || !result.success) {
    return dispatch(setIsValidPhoneNumber(false));
  }

  if (result && result.success) {
    const {
      questionnaire: { previewQuestion },
    } = getState();
    const updatedQuestion = _.cloneDeep(previewQuestion);
    updatedQuestion.question.question = {
      ...updatedQuestion.question.question,
      type: verificationType === 'phone' ? 'phoneCode' : 'emailCode',
      phoneNumber: request.phone ? request.phone : '',
      email: request.email ? request.email : '',
    };
    dispatch(setCurrentQuestion(updatedQuestion));
    dispatch(setIsValidVerificationCode({ validAnswer: false, invalidCode: false }));
  }
};

export const verifyAuthenticationCode = (tenantId, verifyData, verificationType) => async (dispatch) => {
  const [result] = await api.verifyAuthenticationCodeRequest(tenantId, verifyData, verificationType);

  if (result && result.success) {
    return dispatch(setIsValidVerificationCode({ validAnswer: true, invalidCode: false }));
  }
  return dispatch(setIsValidVerificationCode({ validAnswer: false, invalidCode: true }));
};

export const saveQuestionnaireAnswers = (data) => async (dispatch) => {
  dispatch(setQuestionnaireAnswers(data));
  saveQuestionnaireDataToLS('questionnaireAnswers', data);
};

export const saveExecutionId = (executionId) => async (dispatch) => {
  dispatch(setExecutionId(executionId));
  saveQuestionnaireDataToLS('executionId', executionId);
};

// save questionnaire data to localStorage
const saveQuestionnaireDataToLS = (paramName, value) => {
  const storedQuestionnaireData = lsClient.questionnaire.getQuestionnaireData() || {};
  storedQuestionnaireData[paramName] = value;
  lsClient.questionnaire.setQuestionnaireData(storedQuestionnaireData);
};

export const questionnaireFormMounted = () => (dispatch) => {
  const storedQuestionnaireData = lsClient.questionnaire.getQuestionnaireData();
  dispatch(saveQuestionnaireAnswers(storedQuestionnaireData?.questionnaireAnswers));
  dispatch(setExecutionId(storedQuestionnaireData?.executionId));
  return storedQuestionnaireData;
};

export const clearQuestionnaireForm = () => (dispatch) => {
  dispatch(resetQuestionnaireForm());

  lsClient.questionnaire.setAnonUserAuthToken('');
  lsClient.questionnaire.setQuestionnaireData({});
};

export const uploadImage = async (file, name) => {
  const fd = new FormData();
  fd.append('file', file, name);
  const result = await api.uploadImageToS3(fd);
  if (!result[0]) return;
  return result[0];
};

export const saveAnswerForReporter = (currentQuestion, formData, isCompleted) => async (dispatch, getState) => {
  const { reporterAnswers } = getState().questionnaire;
  const updatedReporterAnswers = { ...reporterAnswers };

  const getCurrentQuestionTitle = (questionResult) => {
    if (questionResult.shortName) return questionResult.shortName;
    if (questionResult.title && questionResult.title.translations) {
      return questionResult.title.translations.eng;
    }
    return questionResult.title;
  };

  if (currentQuestion && (currentQuestion.result || currentQuestion.question)) {
    const answerObject = {};
    if (isCompleted) {
      // save result
      answerObject.report = currentQuestion.result.report;
      answerObject.title = getCurrentQuestionTitle(currentQuestion.result);
      answerObject.affirmed =
        currentQuestion.result.description && currentQuestion.result.description.translations
          ? currentQuestion.result.description.translations.eng
          : currentQuestion.result.description;
      answerObject.result = true;
    } else {
      const questionData = currentQuestion.question.question || currentQuestion.question.outcome;
      answerObject.report = questionData.report;
      answerObject.title = questionData.shortName ? questionData.shortName : questionData.name;
      answerObject.type = questionData.type;
      answerObject.comments = questionData.comments;
      answerObject.affirmed = formData.answer.answer;

      if (formData && formData.answer && formData.answer.type === 'multiple') {
        // transform answer for reporter
        formData.answer.answer = formData.answer.answer.map((i) => i.answer);

        answerObject.affirmed = formData.answer.answer;
        answerObject.denied = questionData.options
          .filter(
            (i) =>
              formData.answer.answer.indexOf(i.value && i.value.translations ? i.value.translations.eng : i.value) ===
              -1
          )
          .map((k) => (k.value && k.value.translations ? k.value.translations.eng : k.value));
      }
    }

    if (answerObject.report) {
      updatedReporterAnswers[formData.answer.nodeId] = {
        ...answerObject,
        nodeId: formData.answer.nodeId,
      };
    }
  }

  dispatch(setReporterAnswers(updatedReporterAnswers));
};

export default questionnaireSlice.reducer;
