import { lastTestResultMapping } from './mappings';

/* infectionTestDates */
export const hasLastTestResult = (infectionTestDates) => {
  const testDatesFound = infectionTestDates.filter(
    (t) => t.hasOwnProperty('lastTest') && t.hasOwnProperty('type') && t.type === 'COVID19'
  );

  return testDatesFound.length > 0;
};

export const isLatestPositiveResult = (infectionTestDates) => {
  //   Get the Latest test
  const lastTest =
    infectionTestDates.length > 0 && infectionTestDates.sort((a, b) => new Date(b.lastTest) - new Date(a.lastTest))[0];

  let isTestPositive = false;
  // Recent Test is Considered Positive when "lastPositiveTest" equals the "lastTest"
  if (lastTest && lastTest.hasOwnProperty('lastPositiveTest') && lastTest.hasOwnProperty('lastTest')) {
    isTestPositive = lastTest.lastPositiveTest === lastTest.lastTest; // ?
  }
  return isTestPositive;
};

export const getLastTestResult = (infectionTestDates) => {
  // Check record for most recent test result
  if (infectionTestDates.length === 0 || !hasLastTestResult(infectionTestDates)) {
    return lastTestResultMapping.UNAVAILABLE.value;
  }

  const isTestPositive = isLatestPositiveResult(infectionTestDates);

  if (isTestPositive) {
    return lastTestResultMapping.POSITIVE.value;
  }
  return lastTestResultMapping.NEGATIVE.value;
};

/* isLastPositiveResult */
