/* eslint-disable react/no-array-index-key */
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import QuestionnaireVariablesStyles from '../../../../assets/jss/components/QuestionnaireVariablesStyles';
import { showNotification } from '../../../Notifications/notificationSlice';
import { selectVcbVariablesList } from '../../selectors';
import { saveVCBVariablesList } from '../../virtualConsultSlice';

import QuestionnaireVariableDialog from './QuestionnaireVariableDialog';

export function QuestionnaireVariables(props) {
  const { questionnaireId, classes } = props;

  const vcbVariablesList = useSelector(selectVcbVariablesList);

  const [variablesList, setVariablesList] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [editingKey, setEditingKey] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    setVariablesList(vcbVariablesList[questionnaireId] || []);
    // eslint-disable-next-line
  }, [vcbVariablesList]);

  useEffect(() => {
    if (variablesList) {
      const updatedVcbVariablesList = { ...vcbVariablesList };
      updatedVcbVariablesList[questionnaireId] = variablesList;
      dispatch(saveVCBVariablesList({ vcbVariablesList: updatedVcbVariablesList }));
    }
    // eslint-disable-next-line
  }, [variablesList]);

  const closeDialog = () => {
    setDialogOpen(false);
    setEditingKey(null);
  };

  const handleOpenEditing = (key) => {
    setEditingKey(key);
    setDialogOpen(true);
  };

  const handleUpdateVariable = async (variableEdited) => {
    const updatedVariablesList = [...variablesList];

    if (updatedVariablesList[editingKey]) {
      updatedVariablesList[editingKey] = variableEdited;
      dispatch(showNotification('Variable successfully updated', 'success'));
    } else {
      updatedVariablesList.push(variableEdited);
      dispatch(showNotification('Variable successfully saved', 'success'));
    }
    setVariablesList(updatedVariablesList);
    setEditingKey(null);
    closeDialog();
  };

  const handleDelete = (key) => {
    const updatedVariableList = [...variablesList];
    updatedVariableList.splice(key, 1);
    setVariablesList(updatedVariableList);
    dispatch(showNotification('Variable successfully deleted', 'info'));
    // dispatch(showConfirmation(
    //   "Confirm",
    //   "Are you sure you want to delete this User Segment?",
    //   "segmentDelete",
    //   currentTenant,
    //   userSegmentId
    // ));
  };

  return (
    <Paper style={{ padding: 20, borderRadius: 20 }} elevation={0}>
      <Grid container direction="column" className={classes.container}>
        <>
          {!variablesList || !variablesList.length ? (
            <Typography variant="h6" color="primary">
              There are no variables here yet
            </Typography>
          ) : (
            variablesList.map((item, key) => (
              <Grid container key={key} style={{ width: '50%' }}>
                <Grid item xs={10} md={4} style={{ padding: 10 }}>
                  <TextField
                    label="Variable Name"
                    name="name"
                    margin="normal"
                    value={item.name}
                    autoComplete="off"
                    type="text"
                    fullWidth
                    disabled
                  />
                </Grid>

                <Grid item xs={10} md={4} style={{ padding: 10 }}>
                  <TextField
                    label="Value"
                    name="value"
                    margin="normal"
                    value={item.value}
                    autoComplete="off"
                    type="text"
                    fullWidth
                    disabled
                  />
                </Grid>

                <Grid container item alignItems="flex-end" justifyContent="flex-start" xs={2} md={4}>
                  <>
                    <Tooltip title="Edit User Segment Name">
                      <IconButton onClick={() => handleOpenEditing(key)}>
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete Variable">
                      <IconButton onClick={() => handleDelete(key)} data-testid="expansionPanelDeleteBtn">
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </>
                </Grid>
              </Grid>
            ))
          )}

          <Grid item xs={12} style={{ marginTop: '27px' }}>
            <Button color="primary" variant="contained" onClick={() => setDialogOpen(true)}>
              Add New Variable
            </Button>
          </Grid>
        </>

        {dialogOpen && (
          <QuestionnaireVariableDialog
            editingVariable={variablesList[editingKey] || null}
            onHandleUpdateVariable={handleUpdateVariable}
            onCloseDialog={closeDialog}
          />
        )}
      </Grid>
    </Paper>
  );
}

export default withStyles(QuestionnaireVariablesStyles)(QuestionnaireVariables);
