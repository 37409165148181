import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  message: null,
  visible: false,
  notificationType: 'info',
  isNewUIVersion: false,
  isAutoHide: true,
};

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    displaySnackbarMessage: (state, { payload }) => ({
      ...state,
      message: payload.message,
      visible: true,
      notificationType: payload.notificationType,
      isNewUIVersion: payload.isNewUIVersion,
      isAutoHide: payload.isAutoHide,
    }),
    hideNotification: (state) => ({
      ...state,
      visible: false,
    }),
    resetNotificationState: () => ({ ...initialState }),
  },
});

export const { displaySnackbarMessage, hideNotification, resetNotificationState } = notificationSlice.actions;

export const showNotification =
  (message, notificationType, isAutoHide = true, isNewUIVersion) =>
  async (dispatch) => {
    dispatch(
      displaySnackbarMessage({
        message,
        notificationType,
        isNewUIVersion,
        isAutoHide,
      })
    );
  };

export const hideNotificationAction = () => async (dispatch) => {
  dispatch(hideNotification());

  setTimeout(() => {
    dispatch(resetNotificationState());
  }, 500);
};

export default notificationSlice.reducer;
