/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useCallback, useMemo, useState } from 'react';

// redux
import { useDispatch, useSelector } from 'react-redux';

// components and helpers
import * as htmlUtils from '../Shared/Grid/helpers';
import { selectCurrentTenant } from '../Shared/selectors';
import CheckBoxCell from '../Shared/Table/CheckBoxCell';
import AppKendoTableWrapper from '../Shared/Table/KendoTableWrapper';
import utils from '../Shared/Utils/utils';

import {
  transform,
  apiMapper,
  columns,
  initialSorting,
  sortingMapping,
  paginatedListing,
} from './helpers/medicalCentersListMapper';
import { getLabTestsVendors, getPaginatedLabTests } from './labTestsSlice';
import ManageListDialog from './ManageListDialog';
import MedicalCentersSelectionWrapper from './MedicalCentersSelectionWrapper';
import { selectTenantLabTests, selectTestFormats, selectVendors } from './selectors';

function MedicalCentersListWrapper(props) {
  const { type, multiSelection, initialSelected, filter, handleSelectionChange, hideButtons, saveObject } = props;

  const currentTenant = useSelector(selectCurrentTenant);
  const labTests = useSelector(selectTenantLabTests);
  const vendors = useSelector(selectVendors);
  const testFormats = useSelector(selectTestFormats);

  const [selected, setSelected] = useState(initialSelected || []);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [paginationValues, setPaginationValues] = useState({
    page: 0,
    pageSize: 10,
    sort: initialSorting[type].field,
    direction: initialSorting[type].dir,
  });

  const [selectIds, setSelectIds] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const dispatch = useDispatch();
  const list = labTests && labTests[type] && (type !== 'vendors' ? labTests[type].list : labTests[type].list.result);
  const loading = labTests && labTests[type] && (labTests[type].loading || labTests[type].submitting);

  const buttons = useMemo(
    () => ({
      testOrderable: [
        {
          title: 'Manage Orderable Tests',
          buttonType: 'action',
          // action to perform when click action button
          action: () => setDialogOpen(true),
          variant: 'contained',
        },
      ],
      testPanels: [
        {
          title: 'Manage Orderable Test Panels',
          buttonType: 'action',
          // action to perform when click action button
          action: () => setDialogOpen(true),
          variant: 'contained',
          disabled: false,
        },
      ],
      vendors: [
        {
          title: 'Choose enabled vendors',
          buttonType: 'action',
          // action to perform when click action button
          action: () => setDialogOpen(true),
          variant: 'contained',
        },
      ],
    }),
    []
  );

  // on click of row/row checkbox, select/unselect item if not already in selected
  const selectionChange = (dataItem) => {
    const newSelectIds = [...selectIds];
    const index = selectIds.indexOf(dataItem && dataItem._id);
    if (index === -1) {
      newSelectIds.push(dataItem._id);
    } else {
      newSelectIds.splice(index, 1);
    }
    setSelectIds(newSelectIds);
  };

  // on click of select all, select/unselect all items on page
  const headerSelectionChange = (event) => {
    let newSelectIds = [...selectIds];
    const { checked } = event.syntheticEvent.target;
    if (!checked) {
      newSelectIds = newSelectIds.filter((id) => data.items.map((item) => item._id).indexOf(id) === -1);
    } else {
      newSelectIds = [
        ...newSelectIds,
        ...data.items.filter((item) => newSelectIds.indexOf(item._id) === -1).map((item) => item._id),
      ];
    }

    setSelectIds(newSelectIds);
    // setData(ids);
  };
  const handleSelectAll = (value) => {
    setSelectIds([]);
    setSelectAll(value);
  };

  const handleOnChecked = useCallback(
    (item, checked) => {
      const newSelectedList = selected.slice();

      const indexInList = newSelectedList.findIndex((v) => (saveObject ? v._id === item._id : v === item._id));
      const isInTheList = indexInList >= 0;

      if (checked && !isInTheList) {
        newSelectedList.push(saveObject ? item : item._id);
      } else if (!checked && isInTheList) {
        newSelectedList.splice(indexInList, 1);
      }

      setSelected(newSelectedList);

      if (handleSelectionChange) {
        handleSelectionChange(newSelectedList);
      }
    },
    [selected, handleSelectionChange, saveObject]
  );

  const closeDialog = useCallback(() => {
    setDialogOpen(false);
  }, []);

  const handleTableUpdate = useCallback(
    (page, pageSize, sort, direction, searchValue, filters) => {
      const trimmedSearchValue = searchValue ? utils.removeSpacesAfterLastWord(searchValue) : undefined;

      let newFilter = { ...(filter || {}) };

      if (type === 'testOrderable') {
        if (filters.vendor__name) {
          newFilter.vendorId = [filters.vendor__name._id];
        }

        if (filters.format__format) {
          newFilter.testFormatId = [filters.format__format._id];
        }
      } else {
        newFilter = Object.keys(filters).reduce((accum, f) => {
          accum[f] = filter[f].id;
          return accum;
        }, newFilter);
      }
      setPaginationValues({
        page,
        pageSize,
        sort,
        direction,
      });
      dispatch(
        getPaginatedLabTests(
          currentTenant.id,
          type,
          apiMapper[type],
          0,
          0,
          sort ? sortingMapping[type][sort] : undefined,
          direction,
          trimmedSearchValue,
          newFilter
        )
      );
    },
    [type, filter]
  );

  let cols = [];
  if (multiSelection) {
    cols = columns(false)[type].slice(null, columns(type).length - 1);
    cols.splice(0, 0, {
      id: 'checked',
      label: 'Selected',
      show: true,
      minWidth: 100,
      sortable: false,
      cell: htmlUtils.withOnChange(CheckBoxCell, handleOnChecked),
    });
  } else {
    cols = columns(false, vendors && vendors.list, testFormats && testFormats.list)[type];
  }

  // Initial Load
  useEffect(() => {
    dispatch(
      getPaginatedLabTests(
        currentTenant.id,
        type,
        apiMapper[type],
        0,
        0,
        sortingMapping[type][initialSorting[type].field],
        initialSorting[type].dir,
        null,
        filter
      )
    );
    if (type === 'testOrderable' && !filter && !multiSelection) {
      dispatch(getLabTestsVendors('vendors', apiMapper.vendor));
      dispatch(getLabTestsVendors('testFormat', apiMapper.testFormat));
    }
  }, [type, multiSelection, filter, currentTenant]);

  // empty placeholder data, sets pagination
  const data = useMemo(
    () => ({
      total: (list && list.foundItemsCount) || list?.length || 0,
      pageSize: (list && list.pageSize) || paginationValues?.pageSize || 0,
      page: (list && list.pageNumber) || paginationValues?.page || 0,
      items:
        (list &&
          transform[type](list, saveObject ? selected.map((i) => i._id) : selected).slice(
            (paginationValues?.page + 1) * paginationValues?.pageSize - paginationValues?.pageSize,
            (paginationValues?.page + 1) * paginationValues?.pageSize
          )) ||
        [],
    }),
    [list, type, selected, saveObject, paginationValues]
  );

  const renderDialog = () => {
    if (dialogOpen) {
      return (
        <ManageListDialog closeDialog={() => closeDialog()}>
          <MedicalCentersSelectionWrapper
            initialSelected={data.items.map((i) => i._id)}
            type={type}
            // eslint-disable-next-line
            handleSelectionChange={(list) => console.log('New List', list)}
            onCancel={() => closeDialog()}
          />
        </ManageListDialog>
      );
    }
  };

  const filters = useMemo(
    () => !filter && !multiSelection && cols.filter((c) => c.filter),
    [cols, filter, multiSelection]
  );
  return (
    <>
      <AppKendoTableWrapper
        data={data}
        initialSort={initialSorting[type].field}
        initialDir={initialSorting[type].dir}
        showButtons={!hideButtons}
        showSearchbar
        tableButtons={buttons[type]}
        columns={cols}
        filters={filters}
        showFilterIcon={filters.length > 0}
        loading={loading}
        disableAutoFocus={multiSelection === true ? true : undefined}
        onTableUpdate={paginatedListing[type] ? handleTableUpdate : null}
        onSelectionChange={selectionChange}
        onSelectAll={handleSelectAll}
        onHeaderSelectionChange={headerSelectionChange}
        selectIds={selectIds}
        selectAll={selectAll}
      />
      {renderDialog()}
    </>
  );
}

export default MedicalCentersListWrapper;
