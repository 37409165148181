export const selectTenantSettings = (state) => state.settings.tenantSettings;
export const selectFaqSubmitting = (state) => state.settings.faqSubmitting;
export const selectFaqDeleting = (state) => state.settings.faqDeleting;
export const selectTenantFaqs = (state) => state.settings.faqs;
export const selectFaqLoading = (state) => state.settings.faqLoading;
export const selectTenantVerbiage = (state) => state.settings.verbiage;
export const selectTenantVerbiageDefinitions = (state) => state.settings.verbiageDefinition;
export const selectVerbiageLoading = (state) => state.settings.verbiageLoading;
export const selectVerbiageSubmitting = (state) => state.settings.verbiageSubmitting;
export const selectFontDetailsLoading = (state) => state.settings.fontDetailsLoading;
export const selectFontDetails = (state) => state.settings.fontDetails;
export const selectUpdatingFont = (state) => state.settings.updatingFont;
export const selectTenantFontsLoading = (state) => state.settings.tenantFontsLoading;
export const selectTenantFonts = (state) => state.settings.tenantFonts;
export const selectCreateFontLoading = (state) => state.settings.createFontLoading;
export const selectSubmittingSettings = (state) => state.settings.submittingSettings;
export const selectSubmittingTemplate = (state) => state.settings.submittingTemplate;
export const selectTenantTemplates = (state) => state.settings.tenantTemplates;
export const selectSelectedTemplate = (state) => state.settings.selectedTemplate;
export const selectTemplateLoading = (state) => state.settings.templateLoading;
export const selectSelectedTemplateSettings = (state) => state.settings.selectedTemplateSettings;
export const selectSubmittingTest = (state) => state.settings.submittingTest;
export const selectCustomFontsList = (state) => state.settings.customFontsList || [];
export const selectTenantTemplatesLoading = (state) => state.settings.tenantTemplatesLoading;
export const selectTenantSettingsId = (state) =>
  state.settings.tenantSettings && state.settings.tenantSettings.id ? state.settings.tenantSettings.id : null;
export const selectCreatingTemplate = (state) => state.settings.creatingTemplate;
export const selectStore = (state) => state;
