import { Grid, Accordion, AccordionSummary, Typography, AccordionDetails } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';

const FormContainer = styled('div')({
  width: '100%',
});

function AccordionGroup({ key, label, children, expanded = true }) {
  // const useStyles = makeStyles();
  // const classes = useStyles();

  return (
    <Grid key={key} item xs={12} style={{ paddingTop: 10, paddingBottom: 10, position: 'relative' }}>
      <Accordion defaultExpanded={expanded}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="accordion" id="accordion">
          <Typography variant="body1" color="primary">
            {label}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormContainer>{children}</FormContainer>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
}

export default AccordionGroup;
