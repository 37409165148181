import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  title: null,
  message: null,
  confirmationType: null,
  tenant: null,
  id: null,
  visible: false,
  contentType: null,
  onSuccess: null,
  onNo: null,
  account: null,
};

export const confirmationSlice = createSlice({
  name: 'confirmation',
  initialState,
  reducers: {
    displayConfirmationMessage: (state, { payload }) => ({
      ...state,
      title: payload.title,
      message: payload.message,
      confirmationType: payload.confirmationType,
      tenant: payload.tenant,
      id: payload.id,
      visible: true,
      contentType: payload.contentType,
      onSuccess: payload.onSuccess,
      onNo: payload.onNo,
      account: payload.account,
    }),
    handleConfirmationClose: (state) => ({
      ...state,
      visible: false,
    }),

    resetState: () => ({ ...initialState }),
  },
});

export const { displayConfirmationMessage, handleConfirmationClose, resetState } = confirmationSlice.actions;

// hides confirmation prompt after action is confirmed
export const hideConfirmation = () => (dispatch) => {
  dispatch(handleConfirmationClose());
  setTimeout(() => {
    dispatch(resetState());
  }, 500);
};

// shows message confirming confirmation prompt was acted on with an affirmative response
export const showConfirmation =
  (title, message, confirmationType, tenant, id, contentType, onSuccess = null, onNo = null, account) =>
  (dispatch) => {
    dispatch(
      displayConfirmationMessage({
        title,
        message,
        confirmationType,
        tenant,
        id,
        contentType,
        onSuccess,
        onNo,
        account,
      })
    );
  };

export default confirmationSlice.reducer;
