/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/no-static-element-interactions */
// a NON INTERACTIVE ELEMENT <div ref={ref} tabIndex="0" className="app" onKeyPress={(e) => handleKeyPress(e)}> is being
// given interactive properties
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useScreenshot } from 'use-screenshot-hook';

import { addAnnotation, setDeletingShapeId, updateAnnotation, uploadScreenshot } from '../reviewerSlice';
import { selectQuestionId, selectOutcomeId, selectDeletingShapeId } from '../selectors';

import AnnotationPopup from './AnnotationPopup';
import Canvas from './Canvas';
import DrawShapeDeleteButtons from './DrawShapeDeleteButtons';

function ScreenshotWrapper(props) {
  const {
    questionnaireId,
    screenshotUrl,
    setScreenshotUrl,
    editingAnnotation,
    setEditingAnnotation,
    setExpandOptionForm,
  } = props;

  const classes = useStyles();
  const questionId = useSelector(selectQuestionId);
  const outcomeId = useSelector(selectOutcomeId);
  const deletingShapeId = useSelector(selectDeletingShapeId);
  const [selectedShapeId, setSelectedShapeId] = useState(null);
  // const [img, setImg] = useState(null);
  const [savingLoader, setSavingLoader] = useState(false);
  const [makingScreenshot, setMakingScreenshot] = useState(false);
  const [shapes, setShapes] = useState([]);
  const dispatch = useDispatch();

  const ref = useRef();

  const { image, takeScreenshot } = useScreenshot({
    ref,
  });

  useEffect(() => {
    if (editingAnnotation?._id) {
      setShapes(editingAnnotation.shapes);
    }
    if (editingAnnotation && !selectedShapeId) {
      setSelectedShapeId(editingAnnotation?.shapes.length ? editingAnnotation?.shapes[0].id : null);
    }
    // eslint-disable-next-line
  }, [editingAnnotation]);

  useEffect(() => {
    if (deletingShapeId) {
      handleDeleteShape(deletingShapeId);
    }
    // eslint-disable-next-line
  }, [deletingShapeId]);

  useEffect(() => {
    if (image) {
      saveCurrentAnnotation(image);
    }
    // eslint-disable-next-line
  }, [image]);

  const saveCurrentAnnotation = async (previewUrl) => {
    // const data = imageData.replace(/^data:image\/\w+;base64,/, "");
    // const contentType = "image/png";
    //
    // const blob = b64toBlob(data, contentType);
    // const previewUrl = URL.createObjectURL(blob);

    setSavingLoader(true);

    const previewImgUrl = await dispatch(uploadScreenshot(previewUrl));

    if (editingAnnotation?._id) {
      const updatedAnnotationData = {
        ...editingAnnotation,
        previewUrl: previewImgUrl,
        shapes,
      };

      await dispatch(updateAnnotation(editingAnnotation?._id, updatedAnnotationData));
    } else {
      const screenshotImgUrl = await dispatch(uploadScreenshot(screenshotUrl));
      const annotationData = {
        questionnaireId,
        questionId: questionId || outcomeId,
        screenshotUrl: screenshotImgUrl,
        previewUrl: previewImgUrl,
        shapes,
      };

      await dispatch(addAnnotation(annotationData));
    }

    resetCurrentAnnotation();
    setSavingLoader(false);
  };

  const makeScreenshotWithShapes = () => {
    if (shapes && shapes.length) {
      setMakingScreenshot(true);
      setSelectedShapeId(null);
      setTimeout(() => {
        takeScreenshot();
      }, 500);
    } else {
      resetCurrentAnnotation();
    }
  };

  const handleKeyPress = (e) => {
    const key = e.keyCode || e.which;
    if (selectedShapeId && key === 127) {
      dispatch(setDeletingShapeId(selectedShapeId));
    }
  };

  const handleAddShape = (newShapeData) => {
    const updatedShapes = [...shapes];
    const newShapeId = Object.values(shapes).length + 1;
    newShapeData.id = newShapeId;
    updatedShapes.push(newShapeData);
    setShapes(updatedShapes);
    setSelectedShapeId(newShapeId);
  };

  const handleDeleteShape = (shapeId) => {
    const updatedShapes = shapes.filter((item) => item.id !== shapeId);
    setShapes(updatedShapes);
    setSelectedShapeId(null);
    dispatch(setDeletingShapeId(null));
  };

  const resetCurrentAnnotation = () => {
    setEditingAnnotation(null);
    setMakingScreenshot(false);
    setScreenshotUrl('');
  };

  return (
    <Grid style={{ position: 'relative' }}>
      {/* {img ? ( */}
      {/*  <img */}
      {/*    width={"100%"} */}
      {/*    src={image} */}
      {/*    alt={"Screenshot"} */}
      {/*    className={classes.img} */}
      {/*  /> */}
      {/* ) : null} */}

      {savingLoader && (
        <Grid container justifyContent="center" alignItems="center" className={classes.savingLoaderWrapper}>
          <CircularProgress />
        </Grid>
      )}
      <Grid container justifyContent="flex-start" spacing={2} style={{ marginBottom: 5, width: 375, maxWidth: 375 }}>
        <Grid item>
          <Button color="primary" variant="contained" onClick={makeScreenshotWithShapes}>
            Save
          </Button>
        </Grid>
        <Grid item>
          <Button color="primary" variant="outlined" onClick={makeScreenshotWithShapes}>
            Close
          </Button>
        </Grid>
      </Grid>

      <Grid container justifyContent="center">
        <div
          style={{
            border: '1px solid #cccccc',
            borderRadius: 5,
          }}
        >
          <div ref={ref} tabIndex="0" className="app" onKeyPress={(e) => handleKeyPress(e)}>
            {shapes && shapes.length && !makingScreenshot ? <DrawShapeDeleteButtons shapes={shapes} /> : null}

            {selectedShapeId ? (
              <AnnotationPopup
                selectedShapeId={selectedShapeId}
                setSelectedShapeId={setSelectedShapeId}
                shapes={shapes}
                setShapes={setShapes}
                setExpandOptionForm={setExpandOptionForm}
              />
            ) : null}
            <Canvas
              screenshotUrl={screenshotUrl}
              selectedShapeId={selectedShapeId}
              setSelectedShapeId={setSelectedShapeId}
              shapes={shapes}
              setShapes={setShapes}
              onHandleAddShape={handleAddShape}
            />
          </div>
        </div>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles(() => ({
  savingLoaderWrapper: {
    position: 'absolute',
    backgroundColor: 'rgb(255 255 255 / 60%)',
    height: '100%',
    zIndex: 1,
  },
}));

export default ScreenshotWrapper;
