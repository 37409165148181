export const concernListDefault = [
  'Page sequence',
  'Position',
  'Size',
  'Format',
  'Content',
  'Translation',
  'Validation',
  'Action',
  'Other',
];
export const reviewerListDefault = [
  'Ken Mayer',
  'Dmitry Shats',
  'Craig Duffy',
  'Justin Pearlman',
  'Ralph Lorenz',
  'Azamat',
  'Ashley Williams',
];
