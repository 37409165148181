import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import theme from '../../styles/theme';
import { selectAccountDetails } from '../Account/selectors';
import { selectTenantSettings } from '../Settings/selectors';
import { FormSkeletonLoading, FormSkeletonLoadingMobile } from '../Settings/SettingsFonts/FormSkeletonLoading';
import { updateRootTemplateTenant, updateTenantSettings, getTenantSettings } from '../Settings/settingsSlice';
import CreateFormWrapper from '../Shared/Forms/CreateFormWrapper';
import { initForm } from '../Shared/Forms/formSlice';
import { selectFormData, selectFormUpdatedFields } from '../Shared/Forms/selectors';
import { selectCurrentTenant } from '../Shared/selectors';
import { selectVirtualConsult } from '../VirtualConsult/selectors';
import { getContent, getQuestionnaireConfig, updateQuestionnaireConfig } from '../VirtualConsult/virtualConsultSlice';
import { createNetworkJsonBuilder } from './helpers/createNetworkJsonBuilder';
import {
  NetworkAccountFormMap,
  NetworkSettingsFormMap,
  NetworkGraphicAssetsFormMap,
  NetworkHealthProtocolFormMap,
} from './helpers/formHelper';

import { networkLoadJsonBuilder } from './helpers/networkLoadJsonBuilder';
import { getNetworkByTenantId, updateNetwork } from './networksSlice';
import { selectCheckerScreen, selectTenantNetwork } from './selectors';

function NetworkFormWrapper(props) {
  const { edit, type = '', vcbActions } = props;

  const currentNetwork = useSelector(selectTenantNetwork);
  const currentTenant = useSelector(selectCurrentTenant);
  const account = useSelector(selectAccountDetails);
  const checkerScreen = useSelector(selectCheckerScreen);
  const consult = useSelector(selectVirtualConsult);
  const tenantSettings = useSelector(selectTenantSettings);

  const [state, setState] = useState('');
  const [isEnabledVaxRecord, setIsEnabledVaxRecord] = useState(false);
  const [isEnabledVaxRecordControl, setIsEnabledVaxRecordControl] = useState(false);
  const [isEnabledVaxSettingsControl, setIsEnabledVaxSettingsControl] = useState(false);
  const formData = useSelector(selectFormData);
  const formUpdatedFields = useSelector(selectFormUpdatedFields);

  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  const dispatch = useDispatch();

  // get network data from database
  useEffect(() => {
    if (currentTenant.id !== currentNetwork.tenantID) {
      dispatch(getNetworkByTenantId(currentTenant));
    }
    if (currentTenant) {
      dispatch(getTenantSettings(currentTenant.id));
    }
    // Get available questionnaires for the Health Protocol tab in Settings
    if (
      currentTenant &&
      ((consult && consult.questionnaireConfig && consult.questionnaireConfig.tenantId !== currentTenant.id) ||
        !consult ||
        (consult && !consult.questionnaires && !consult.loadingQuestionnaires) ||
        (consult && !consult.questionnaireConfig && !consult.loadingConfig))
    ) {
      // tenant: '_platform' - get Questionnaire list from Platform Console
      dispatch(getContent('_platform', 'questionnaires', '', 'id', 'asc', 0, 500));
      dispatch(getQuestionnaireConfig(currentTenant.id));
    }
    // eslint-disable-next-line
  }, [currentTenant, edit, vcbActions, consult.questionnaires]);

  useEffect(() => {
    if (
      currentTenant &&
      currentNetwork &&
      tenantSettings &&
      currentTenant.id === currentNetwork.tenantID &&
      consult &&
      consult.questionnaireConfig &&
      consult.questionnaireConfig.tenantId === currentTenant.id
    ) {
      // set Vaccination Record since we need to pass the value back to B.E. during form submit
      setIsEnabledVaxRecord(currentNetwork?.verifyVaccinationSettings.enableVaccinationRecord);
      setIsEnabledVaxRecordControl(currentNetwork?.verifyVaccinationSettings.enableVaccinationRecordControl);
      setIsEnabledVaxSettingsControl(currentNetwork?.verifyVaccinationSettings.enableVaccinationSettingsControl);

      // create single parsed json to populate configuration
      const combinedTenantState = networkLoadJsonBuilder(currentNetwork, tenantSettings, currentTenant, consult);
      setState(combinedTenantState);
      dispatch(initForm(combinedTenantState));
    }
  }, [currentTenant, currentNetwork, tenantSettings, consult]);

  // submit completed form
  const handleSubmit = async (formData) => {
    // // create network JSON to update
    // let networkData = createNetworkJsonBuilder(
    //   formData,
    //   isEnabledVaxRecord,
    //   isEnabledVaxRecordControl,
    //   isEnabledVaxSettingsControl
    // );
    // dispatch(updateNetwork(currentTenant && currentTenant.id, networkData, account?.id));


    /*testing solution*/
    // formUpdatedFields - updated form fields only
    // get fresh network data and combine with updated fields
    const data = await dispatch(getNetworkByTenantId(currentTenant));
    const combinedTenantData = networkLoadJsonBuilder(data, tenantSettings, currentTenant, consult);
    const updatedNetworkData = {...combinedTenantData, ...formUpdatedFields}

    // create network JSON to update
    let networkData = createNetworkJsonBuilder(
      updatedNetworkData,
      isEnabledVaxRecord,
      isEnabledVaxRecordControl,
      isEnabledVaxSettingsControl
    );
    dispatch(updateNetwork(currentTenant && currentTenant.id, networkData, account?.id));
    /*-------------------------*/

    const tenantSettingsCopy = { ...tenantSettings };
    const questionnaireConfigCopy = {
      ...(consult && consult.questionnaireConfig),
    };
    // create tenant settings to update
    tenantSettingsCopy.disableSignUp = formData.disableSignUp;
    tenantSettingsCopy.sendEmail = formData.sendEmail;
    tenantSettingsCopy.sendSMS = formData.sendSMS;
    tenantSettingsCopy.pushNotificationsEnabled = formData.pushNotificationsEnabled;
    tenantSettingsCopy.safeNotificationsEnabled = formData.safeNotificationsEnabled;
    tenantSettingsCopy.sidelineUserWithPositiveTestResultEnabled = formData.sidelineUserWithPositiveTestResultEnabled;
    tenantSettingsCopy.sidelineUserWithPositiveTestResultForDays = formData.sidelineUserWithPositiveTestResultForDays;
    tenantSettingsCopy.symptomaticOutcomeSidelineForDays = formData.symptomaticOutcomeSidelineForDays;
    tenantSettingsCopy.exposureOutcomeSidelineForDays = formData.exposureOutcomeSidelineForDays;
    // Add Metadata elements to formData to prevent overwriting updates made there
    tenantSettingsCopy.metadataOnCheckerScreen = checkerScreen;
    dispatch(updateTenantSettings(currentTenant && currentTenant.id, tenantSettingsCopy));

    // create questionnaire config to update
    let defaultQuestionnaire = null;
    if (consult && consult.questionnaires && consult.questionnaires.content) {
      for (let i = 0; i < consult.questionnaires.content.length; i++) {
        if (Object.values(consult.questionnaires.content[i]).indexOf(formData.defaultQuestionnaireId) > -1) {
          defaultQuestionnaire = formData.defaultQuestionnaireId;
          break;
        }
      }
    }
    questionnaireConfigCopy.defaultQuestionnaireId = defaultQuestionnaire;
    questionnaireConfigCopy.tenantId = currentTenant.id;
    dispatch(updateQuestionnaireConfig(currentTenant && currentTenant.id, questionnaireConfigCopy));

    // check for update in root or template and update tenant respectively
    if (currentTenant && currentTenant.template !== formData.template) {
      dispatch(updateRootTemplateTenant(currentTenant.id, 'is-template'));
    }
    if (currentTenant && currentTenant.root !== formData.root) {
      dispatch(updateRootTemplateTenant(currentTenant.id, 'is-root'));
    }

    // Reset component state to preserve new values across tab navigation
    setState(formData);
  };

  if (!state || !account || !consult || !consult.questionnaires) {
    return matches ? (
      <Grid container justifyContent="center">
        <Grid item md={11}>
          <Paper elevation={0} style={{ borderRadius: 30 }}>
            <FormSkeletonLoading />
          </Paper>
        </Grid>
      </Grid>
    ) : (
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <Paper elevation={0} style={{ borderRadius: 30 }}>
            <FormSkeletonLoadingMobile />
          </Paper>
        </Grid>
      </Grid>
    );
  }

  // send steps for form wrapper, need label and pass data from helper. Steps must be in stepper order.
  const step = () => {
    switch (type) {
      case 'tenant-info':
        return [
          {
            label: 'Tenant Info',
            data: NetworkAccountFormMap,
          },
        ];
      case 'settings':
        return currentNetwork?.tenantDisplaySettings
          ? [
              {
                label: 'Settings',
                data: NetworkSettingsFormMap(
                  account && account.accountType === 'SINGLE_TENANT',
                  currentTenant,
                  currentNetwork,
                  formData
                ),
              },
            ]
          : [
              {
                label: 'Tenant Info',
                data: NetworkAccountFormMap,
              },
            ];
      case 'assets':
        return [
          {
            label: 'Assets',
            data: NetworkGraphicAssetsFormMap,
          },
        ];
      case 'health-protocol':
        return [
          {
            label: 'Health Protocol',
            data: NetworkHealthProtocolFormMap(consult && consult.questionnaires),
          },
        ];
      default:
        return [
          {
            label: 'Tenant Info',
            data: NetworkAccountFormMap,
          },
        ];
    }
  };

  return (
    <Grid container justifyContent="center">
      <CreateFormWrapper
        tabs
        steps={step()}
        handleSubmit={handleSubmit}
        initialValues={state}
        disabledSubmit={formData?.disabledSubmit || false}
        collectUpdatedFields={true}
      />
    </Grid>
  );
}

export default NetworkFormWrapper;
