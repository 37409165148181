import { SvgIcon } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

// kendo
import { DropDownButton, DropDownButtonItem } from '@progress/kendo-react-buttons';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Parent from '../../assets/img/parent.svg';
import ProfileViewStyles from '../../assets/jss/components/ProfileViewStyles';
import { getUserFromToken } from '../Auth/helpers';
import PhoneInputField from '../Shared/Forms/KendoFields/PhoneInputField';
import { complianceMapping, roleMappings } from '../Users/helpers/mappings';
import {
  selectGeneratingNhi,
  selectUserAccountPermission,
  selectUserDetails,
  selectUserPermissions,
} from '../Users/selectors';
import { generateUserNhi } from '../Users/usersSlice';
import { dropdownActionMap } from './helpers/profileListMapper';
import ProfilePicture from './ProfilePicture';
import QRCode from './QRCode';

function MobileCard(props) {
  const { classes, handleItemClick, emailIcon } = props;

  const userDetails = useSelector(selectUserDetails);
  const userAccountPermission = useSelector(selectUserAccountPermission);
  const userPermissions = useSelector(selectUserPermissions);
  const generatingNhi = useSelector(selectGeneratingNhi);
  const user = getUserFromToken();

  // eslint-disable-next-line no-unused-vars
  const [nhiTimeMet, setNhiTimeMet] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (userDetails && userDetails.created) {
      const newDateObj = moment(userDetails.created).add(15, 'm').toDate();
      const currentDate = moment().toDate();
      if (newDateObj <= currentDate) {
        setNhiTimeMet(true);
      } else {
        setNhiTimeMet(false);
      }
    }
  }, [userDetails]);

  let countryCode = '';
  let number = '';
  if (userDetails && userDetails.contact) {
    countryCode = userDetails.contact.mobileCountryCode || '+1';
    number = userDetails.contact.mobileNumber || '';
  }

  const dropdownItem = (props) => (
    <div
      style={{
        display: 'flex',
        gap: 10,
        alignItems: 'center',
        width: 'max-content',
      }}
    >
      <span className={`k-icon k-i-${props.item.icon}`} role="presentation" />
      {props.item.text}
    </div>
  );

  const getAccountStatus = () => {
    if (userDetails && userDetails.settings && userDetails.settings.identityVerificationStatus) {
      if (userDetails.settings.identityVerificationStatus.includes('NOT')) return 'Not Verified';
      return 'Verified';
    }
    return 'Not Verified';
  };

  // get dropdown actions by role
  const dropdownActions = dropdownActionMap(userDetails, userAccountPermission).filter((i) => i.show);

  return (
    <Grid container className={classes.mobileBox} spacing={2} justifyContent="space-between" alignItems="center">
      <Grid item>
        {user?.email !== userDetails?.email && dropdownActions.length && (
          <Grid item id="cog" className={classes.settingsCog}>
            <DropDownButton
              look="bare"
              itemRender={dropdownItem}
              onItemClick={(e) => handleItemClick(e)}
              icon="more-horizontal"
              popupSettings={{
                anchorAlign: {
                  horizontal: 'right',
                  vertical: 'bottom',
                },
                popupAlign: {
                  horizontal: 'right',
                  vertical: 'top',
                },
              }}
            >
              {dropdownActions.map((item) => (
                <DropDownButtonItem text={item.text} icon={item.icon} />
              ))}
            </DropDownButton>
          </Grid>
        )}
        <Grid container alignItems="center" style={{ marginTop: '-5px' }}>
          <Grid item xs={12}>
            {userDetails && userDetails.firstName ? (
              <Typography className={classes.name}>
                {userDetails.firstName} {userDetails.lastName}
                {userDetails && userDetails.isGuardianMode && (
                  <Tooltip title="Guardian">
                    <SvgIcon style={{ marginLeft: 10 }} fontSize={'small'}>
                      <Parent />
                    </SvgIcon>
                  </Tooltip>
                )}
              </Typography>
            ) : (
              <Typography className={classes.emailInfo}>
                {userDetails && userDetails.email}
                {userDetails && userDetails.isGuardianMode && (
                  <Tooltip title="Guardian">
                    <SvgIcon style={{ marginLeft: 10 }} fontSize={'small'}>
                      <Parent />
                    </SvgIcon>
                  </Tooltip>
                )}
              </Typography>
            )}
            <Typography className={classes.info}>
              {roleMappings &&
                userPermissions &&
                userPermissions.permission &&
                roleMappings.find((r) => r.id === userPermissions.permission) &&
                roleMappings.find((r) => r.id === userPermissions.permission).label}
            </Typography>
          </Grid>
          <Grid container>
            <Grid item className={classes.side}>
              <CardContent>
                <ProfilePicture isMobile />
              </CardContent>
            </Grid>
            <Grid>
              <QRCode />
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={classes.details}>
          <div className={classes.infoBox}>
            <div style={{ display: 'inline-flex', marginBottom: '-20px' }}>
              <Typography className={classes.info} style={{ margin: '0 3px' }}>
                Phone:{' '}
              </Typography>
              <PhoneInputField
                type="mobileDisplay"
                classes={classes}
                countryCode={countryCode || '+1'}
                number={number}
              />
            </div>
            <Typography className={classes.info}>
              Email: {userDetails && userDetails.email} {emailIcon}
            </Typography>
            <Typography
              component="div"
              className={userDetails && userDetails.nationalHealthId ? classes.info : classes.nhiBtnInfo}
              id="nhid"
            >
              <Grid container alignItems="center">
                <Grid item xs={12}>
                  Health ID:{' '}
                  {userDetails && userDetails.nationalHealthId ? (
                    userDetails && userDetails.nationalHealthId
                  ) : (
                    <div>
                      {nhiTimeMet ? (
                        <div className={classes.nhiBtnWrapper}>
                          <Button
                            variant="outlined"
                            color="primary"
                            size="small"
                            disabled={generatingNhi}
                            onClick={() => dispatch(generateUserNhi(userDetails.email))}
                          >
                            {generatingNhi ? 'Generating...' : 'Generate NHI'}
                          </Button>
                          {generatingNhi && <CircularProgress size={18} className={classes.nhiBtnProgressMobile} />}
                        </div>
                      ) : (
                        <Typography variant="caption" color="primary">
                          Processing NHI...
                        </Typography>
                      )}
                    </div>
                  )}
                </Grid>
              </Grid>
            </Typography>
            <Typography>
              Account type:{' '}
              {userDetails && userDetails.parentIds && userDetails.parentIds.length ? 'Dependent' : 'Parent'}
            </Typography>
            <Typography className={classes.info}>Account Status: {getAccountStatus()}</Typography>
            <Box>
              <Typography
                className={classes.info}
                style={{
                  display: 'inline-block',
                }}
              >
                Compliance Status:{' '}
              </Typography>
              <Typography
                className={classes.info}
                style={{
                  color: complianceMapping[userDetails.complianceStatus].color,
                  display: 'inline-block',
                }}
              >
                {complianceMapping[userDetails.complianceStatus].label || null}
              </Typography>
            </Box>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default withStyles(ProfileViewStyles)(MobileCard);
