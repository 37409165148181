import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState, useMemo, useEffect } from 'react';

// redux
import { useSelector, useDispatch } from 'react-redux';

import CreateFormStyles from '../../assets/jss/components/CreateFormStyles';
import { showConfirmation } from '../Confirmation/confirmationSlice';
import { selectTenantNetwork } from '../Networks/selectors';

// components and helpers
import CreateFormWrapper from '../Shared/Forms/CreateFormWrapper';
import { selectCurrentTenant } from '../Shared/selectors';

import { createUserJsonBuilder } from './helpers/createUserJsonBuilder';
import { role, basic, roleDependent, basicDependent } from './helpers/formHelper';
import { UserRoleId } from './helpers/mappings';

// mui
// import { selectKendoPagination } from './selectors';
import { addUser, inviteUser, sendEmailInvitation } from './usersSlice';

function UserCreate(props) {
  const { setLargeForm, onCloseDialog, formData } = props;

  const classes = useStyles();

  const currentTenant = useSelector(selectCurrentTenant);
  const tenantSetting = useSelector(selectTenantNetwork);
  const dispatch = useDispatch();

  const [initialValues, setInitialValues] = useState({});
  const guardianCheckBox = {
    type: 'toggle',
    name: 'isGuardianMode',
    label: 'User Requires Legal Guardian Consent',
    width: 12,
    show: false,
  };
  // send steps for form wrapper, need label and pass data from helper. Steps must be in stepper order.
  // if only 1 step, form wrapper will remove stepper bar and back button.
  const inviteSteps = [
    {
      label: 'Invite User',
      data: role,
      // tenantSetting && tenantSetting.requireGuardianMode
      //   ? [...role, guardianCheckBox]
      //   : role,
    },
  ];
  const createSteps = [
    {
      data: tenantSetting && tenantSetting.requireGuardianMode ? [...basic, guardianCheckBox] : basic,
    },
  ];
  const inviteDependentSteps = [
    {
      data: roleDependent,
    },
  ];
  const createDependentSteps = [
    {
      data: basicDependent,
    },
  ];
  const [steps, setSteps] = useState(inviteSteps);

  const type = useMemo(
    () => (steps[0].label === inviteSteps[0].label || steps === inviteDependentSteps ? 'inviteUser' : 'createUser'),
    [steps]
  );

  useEffect(() => {
    if (!formData?.role || type === 'createUser') return;
    if (formData?.role === 'DEPENDENT') {
      setSteps(inviteDependentSteps);
      setInitialValues({
        ...formData,
      });
    } else {
      setSteps(inviteSteps);
      setInitialValues({
        ...formData,
      });
    }
  }, [formData?.role]);

  // handle form submission with appropriate json builder and endpoint
  const handleSubmit = (formData) => {
    formData = {
      ...formData,
      tenantId: currentTenant.id,
      permission: UserRoleId[formData.role],
      accountId: currentTenant.accountId,
    };
    const data = createUserJsonBuilder(formData);
    if (formData.inviteOnly) {
      data.permission = UserRoleId[formData.role];
      data.accountId = currentTenant.accountId;
      dispatch(sendEmailInvitation(currentTenant.id, data));
    } else {
      dispatch(addUser(currentTenant.id, data));
    }
    onCloseDialog();
  };

  // handle form submission with appropriate json builder and endpoint
  const handleInvite = async (formData) => {
    const res = await dispatch(inviteUser(currentTenant, formData?.email, formData?.role));

    if (res) {
      if (formData?.role === 'DEPENDENT') {
        setSteps(createDependentSteps);
      } else {
        setSteps(createSteps);
      }

      setInitialValues({
        ...formData,
      });
      setLargeForm();
    } else {
      onCloseDialog();
    }
  };

  const handleDialog = () => {
    dispatch(
      showConfirmation(
        'Why Limit Sex',
        'HIPAA requires that healthcare providers have a record of the sex assigned' +
          "at birth. We don't make the rules, just have to follow them.",
        'info'
      )
    );
  };

  return (
    <Grid container justifyContent="center" className={classes.dialogWrapper}>
      <CreateFormWrapper
        formType={type}
        handleSubmit={handleSubmit}
        handleInvite={handleInvite}
        handleDialog={handleDialog}
        handleCancel={() => onCloseDialog()}
        steps={steps}
        initialValues={initialValues}
        labelButtonSubmit="Add User"
      />
    </Grid>
  );
}

const useStyles = makeStyles(CreateFormStyles);

export default UserCreate;
