/* eslint-disable no-unused-expressions */
/* eslint-disable computed-property-spacing */
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import React, { useEffect, useMemo, useState } from 'react';
// redux
import { useDispatch, useSelector } from 'react-redux';

import CreateFormStyles from '../../../assets/jss/components/CreateFormStyles';
import { getMasterLabTestsTenant } from '../../Orders/ordersSlice';
import { selectOrderableTests } from '../../Orders/selectors';
import { selectTenantSettings } from '../../Settings/selectors';
import CreateFormWrapper from '../../Shared/Forms/CreateFormWrapper';
import { selectFormData } from '../../Shared/Forms/selectors';
import { selectCurrentTenantId } from '../../Shared/selectors';
import { selectLoadingQuestionnaires, selectOutcomes, selectUserSegments } from '../selectors';
import { createUserSegment, getContent, getUserSegments, updateUserSegment } from '../virtualConsultSlice';

// components and helpers

import { userSegmentFormMap } from './helpers/formHelper';
import { setBaseSegment } from './helpers/setBaseSegment';
import { userSegmentJsonBuilder } from './helpers/userSegmentJsonBuilder';
import { UserSegmentSkeletonLoading } from './UserSegmentSkeltonLoading';

// mui

function UserSegmentFormWrapper(props) {
  const { classes, actions, virtualConsultActions } = props;

  const formData = useSelector(selectFormData);
  const currentTenant = useSelector(selectCurrentTenantId);
  const labTestOrderables = useSelector(selectOrderableTests);
  const outcomes = useSelector(selectOutcomes);
  const outcomesLoading = useSelector(selectLoadingQuestionnaires);
  const userSegments = useSelector(selectUserSegments);
  const tenantSettings = useSelector(selectTenantSettings);

  const [details, setDetails] = useState(false);
  const [type, setType] = useState('');
  const [form, setForm] = useState('');
  const [id, setId] = useState('');
  const [activeLabTests, setActiveLabTests] = useState([]);
  const [usedOutcomeTypes, setUsedOutcomeTypes] = useState([]);
  const [segments, setSegments] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    const url = window.location.pathname;
    if (url.includes('details')) {
      const type = url.split('/')[3];
      const id = url.split('/').pop();
      dispatch(getUserSegments(currentTenant));
      setDetails(true);
      setType(type);
      setId(id);
    }

    if (!labTestOrderables) {
      dispatch(getMasterLabTestsTenant('orderableTests', currentTenant));
    }
    dispatch(getContent(currentTenant, 'outcomes', '', 'id', 'asc', 0, 500));
  }, [currentTenant, actions, virtualConsultActions]);

  // create base user segment based on values in Questionnaire Outcomes
  useEffect(() => {
    if (
      userSegments &&
      userSegments.items &&
      tenantSettings &&
      userSegments.items !== segments &&
      outcomes &&
      outcomes.content &&
      !outcomesLoading
    ) {
      const data = setBaseSegment(userSegments.items, tenantSettings, outcomes && outcomes.content);
      setSegments(data);
    }
  }, [userSegments, segments, tenantSettings, outcomes, outcomesLoading]);

  useEffect(() => {
    if (segments.length > 0 && !form && details) {
      // restructure response JSON to be read by formData to populate and edit
      segments.forEach((n) => {
        const detailsData = n;
        const outcomeSettings = [];
        if (n.id === id && n.testSettingByOutcome) {
          for (let i = 0; i < Object.keys(n.testSettingByOutcome).length; i++) {
            const outcomeKey = Object.keys(n.testSettingByOutcome)[i];
            outcomeSettings.push({
              outcomeKey,
              retestIfPositive: n.testSettingByOutcome[outcomeKey].retestIfPositive,
              retestIfNegative: n.testSettingByOutcome[outcomeKey].retestIfNegative,
              customOutcomeMessage: n.testSettingByOutcome[outcomeKey].customOutcomeMessage,
              customOutcomeImage: n.testSettingByOutcome[outcomeKey].customOutcomeImage,
              test: n.testSettingByOutcome[outcomeKey].vendorTestId
                ? {
                    safeTestId: n.testSettingByOutcome[outcomeKey].safeTestId,
                    vendorTestId: n.testSettingByOutcome[outcomeKey].vendorTestId,
                    vendorKey: n.testSettingByOutcome[outcomeKey].vendorKey,
                    vendorTestName: n.testSettingByOutcome[outcomeKey].vendorTestName,
                    orderableTestId: n.testSettingByOutcome[outcomeKey].orderableTestId,
                  }
                : 'none',
            });
          }
          setForm({ ...detailsData, outcomeSettings });
        }
      });
    }
  }, [segments, type, id, form, details]);

  useEffect(() => {
    // Default list item of "None" before listing other tests
    const noneLabel = { label: 'Same as Outcome', test: 'none' };
    const activeTests = [noneLabel];

    if (labTestOrderables && labTestOrderables.list && labTestOrderables.list.length > 0) {
      activeTests.push(...labTestOrderables.list);
    }

    setActiveLabTests(activeTests);
  }, [labTestOrderables]);

  // loop to create outcome enum - only allow outcome to be used once in form
  useEffect(() => {
    if (formData && formData.outcomeSettings && formData.outcomeSettings.length > 0) {
      const usedOutcomes = [];
      formData.outcomeSettings.map(
        (t) =>
          outcomes &&
          outcomes.content &&
          outcomes.content.filter((outcome) => {
            if (outcome.id !== t.outcomeKey) return null;
            return usedOutcomes.push(outcome.name.translations.eng);
          })[0]
      );
      setUsedOutcomeTypes(usedOutcomes);
    }
  }, [outcomes, formData]);

  // sets step to its correctly formatted JSON
  const steps = useMemo(
    () => [
      {
        label: type,
        data: userSegmentFormMap(formData, activeLabTests, usedOutcomeTypes, details, outcomes),
      },
    ],
    [type, formData, activeLabTests, usedOutcomeTypes, details, outcomes]
  );

  const handleSubmit = (formData) => {
    const byOutcomeData = {};
    // nest the outcomeTest for proper JSON structure. Check for test - if 'none' test selected, set all to null
    formData.outcomeSettings &&
      formData.outcomeSettings.forEach((o) => {
        const outcomeTest = {
          retestIfNegative: o.test !== 'none' && o.retestIfNegative ? o.retestIfNegative : null,
          retestIfPositive: o.test !== 'none' && o.retestIfPositive ? o.retestIfPositive : null,
          safeTestId: o.test !== 'none' ? o.test.safeTestId : null,
          vendorTestId: o.test !== 'none' ? o.test.vendorTestId : null,
          vendorKey: o.test !== 'none' ? o.test.vendorKey : null,
          vendorTestName: o.test !== 'none' ? o.test.vendorTestName : null,
          orderableTestId: o.test !== 'none' ? o.test.orderableTestId : null,
          // strip outcome message of html tags to check if empty and set to null - must be null to assume original outcome message
          customOutcomeMessage:
            o.customOutcomeMessage && o.customOutcomeMessage.replace(/(<([^>]+)>)/gi, '') === ''
              ? null
              : o.customOutcomeMessage,
          // check if image is empty and set to null - must be null to assume original outcome image
          customOutcomeImage: !o.customOutcomeImage ? null : o.customOutcomeImage,
        };
        byOutcomeData[o.outcomeKey] = outcomeTest;
      });
    const data = userSegmentJsonBuilder(formData, byOutcomeData);

    if (details) {
      if (data && data.name === 'Base User Segment') {
        data.default = true;
      }
      dispatch(updateUserSegment(currentTenant, data));
    } else {
      dispatch(createUserSegment(currentTenant, data));
    }
  };

  if (!currentTenant) return <div />;

  if (
    !labTestOrderables ||
    (labTestOrderables && labTestOrderables.loading) ||
    !outcomes ||
    !outcomes.content ||
    (details && Object.keys(form).length === 0) ||
    (details && activeLabTests.length === 0)
  ) {
    return (
      <Grid container justifyContent="center" className={classes.container}>
        <Grid item xs={12} md={10}>
          <Paper style={{ padding: 20 }}>
            <UserSegmentSkeletonLoading />
          </Paper>
        </Grid>
      </Grid>
    );
  }
  return (
    <Grid container justifyContent="center" className={classes.container}>
      <CreateFormWrapper steps={steps} handleSubmit={handleSubmit} initialValues={form} />
    </Grid>
  );
}

export default withStyles(CreateFormStyles)(UserSegmentFormWrapper);
