/* eslint-disable import/no-cycle */
import Grid from '@material-ui/core/Grid';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { showConfirmation } from '../../Confirmation/confirmationSlice';
import { selectFormData } from '../../Shared/Forms/selectors';
import { selectCurrentTenant } from '../../Shared/selectors';

import AppQuestionnaireBuilder from './Tabs/QuestionnaireBuilder';
import AppQuestionnaireProfile from './Tabs/QuestionnaireProfile';

function QuestionnaireFormWrapper(props) {
  const { type, details, id, setType, editingQuestion, setEditingQuestion } = props;
  const currentTenant = useSelector(selectCurrentTenant);
  const formData = useSelector(selectFormData);

  const dispatch = useDispatch();

  const handleDelete = () => {
    dispatch(
      showConfirmation(
        'Confirm',
        'Are you sure you want to delete this questionnaire?',
        'questionnaireDelete',
        currentTenant,
        id
      )
    );
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        {type === 'profile' && (
          <AppQuestionnaireProfile
            details={details}
            formData={formData}
            onDelete={handleDelete}
            currentTenant={currentTenant}
            setType={setType}
          />
        )}
        {type === 'questionnaire' && (
          <AppQuestionnaireBuilder
            details={details}
            currentTenant={currentTenant}
            questionnaireId={id}
            editingQuestion={editingQuestion}
            setEditingQuestion={setEditingQuestion}
          />
        )}
      </Grid>
    </Grid>
  );
}

export default QuestionnaireFormWrapper;
