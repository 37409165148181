import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState, useEffect, useCallback, useMemo } from 'react';

// redux
import { useDispatch, useSelector } from 'react-redux';

import ProfileViewStyles from '../../assets/jss/components/ProfileViewStyles';
import { selectCurrentTenant } from '../Shared/selectors';
import AppKendoTableWrapper from '../Shared/Table/KendoTableWrapper';

// component imports

// mui
import { selectUserDetails } from '../Users/selectors';
import { medicationColumns as columns, medicationTableButtons } from './helpers/clinicalInfoMapper';
import { convertMedicationObjToMedication, convertMedicationToMedicationObj } from './helpers/createClinicalObjBuilder';
import MedicationDialog from './MedicationDialog';
import { getMedicationList, postMedicationList, postMedicationToDrfirst } from './slices/medicationListSlice';
import { selectMedicationList, selectMedicationListLoading } from './slices/selectors';

function Medication() {
  const classes = useStyles();
  const userDetails = useSelector(selectUserDetails);
  const currentTenant = useSelector(selectCurrentTenant);

  const medicationList = useSelector(selectMedicationList);
  const medicationListLoading = useSelector(selectMedicationListLoading);

  const [pagedMedicationList, setPagedMedicationList] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedMedication, setSelectedMedication] = useState({});
  const [dialogMode, setDialogMode] = useState('Add');

  const dispatch = useDispatch();

  const sentMedicationToDrfirst = useCallback(() => {
    dispatch(postMedicationToDrfirst(userDetails.nationalHealthId));
  }, [userDetails.nationalHealthId]);

  const handleRowClick = (e) => {
    const selected = pagedMedicationList ? pagedMedicationList.find((i) => i.id === e.id) : e;
    const selectedMedication = convertMedicationToMedicationObj(selected);
    setSelectedMedication(selectedMedication);
    setDialogMode('Edit');
    setDialogOpen(true);
  };

  const handleUpdateMedication = (medicationObj) => {
    const medication = convertMedicationObjToMedication(medicationObj);
    const medicationList = {
      patientDetailDTOList: [medication],
      safeEMRId: userDetails.nationalHealthId,
      resourceType: 'MedicationStatement',
    };
    dispatch(postMedicationList(userDetails.nationalHealthId, medicationList));
  };

  const handleCloseDialog = () => {
    setSelectedMedication({});
    setDialogMode('Add');
    setDialogOpen(false);
  };

  const handleTableUpdate = (page, pageSize) => {
    setPagedMedicationList(medicationList.data.slice((page + 1) * pageSize - pageSize, (page + 1) * pageSize));
  };

  useEffect(() => {
    dispatch(getMedicationList(userDetails.nationalHealthId));
  }, [currentTenant, userDetails]);

  useEffect(() => {
    if (medicationList) {
      setPagedMedicationList(medicationList.data);
    }
  }, [medicationList, sentMedicationToDrfirst]);

  const tableButtons = useMemo(
    () =>
      medicationTableButtons(setDialogOpen, sentMedicationToDrfirst, medicationList && medicationList.data.length <= 0),
    [medicationList]
  );

  const getRoute = (data) => {
    if (data.dosage) {
      if (data.dosage.route) return data.dosage.route.display;
      return '';
    }
    return '';
  };
  return (
    <Grid className={`${classes.contentWrapper} ${classes.withTableButtons}`}>
      <AppKendoTableWrapper
        data={{
          ...pagedMedicationList,
          items:
            pagedMedicationList &&
            pagedMedicationList.map((n) => ({
              ...n,
              drugName: n.medicationCode ? n.medicationCode.display : '',
              action: n.dosage ? n.dosage.action : '',
              dose: n.dosage ? n.dosage.dose : '',
              doseUnit: n.dosage ? n.dosage.doseUnit : '',
              route: getRoute(n),
              doseTiming: n.dosage ? n.dosage.doseTiming : '',
              doseOther: n.dosage ? n.dosage.doseOther : '',
              quantity: n.quantityValue,
              effectiveStart: n.effectiveStartDate,
              effectiveEnd: n.effectiveEndDate,
            })),
          total: medicationList && medicationList.data.length,
        }}
        initialSort="effectiveStart"
        loading={medicationListLoading}
        initialDir="desc"
        columns={columns()}
        onTableUpdate={handleTableUpdate}
        showButtons
        onRowClick={handleRowClick}
        tableButtons={tableButtons}
      />
      {dialogOpen && (
        <MedicationDialog
          selectedMedication={selectedMedication}
          dialogMode={dialogMode}
          closeDialog={handleCloseDialog}
          updateMedication={handleUpdateMedication}
        />
      )}
    </Grid>
  );
}

const useStyles = makeStyles(ProfileViewStyles);

export default Medication;
