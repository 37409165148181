/* eslint-disable import/no-cycle */
import React from 'react';
import { useSelector } from 'react-redux';

import { getFieldName, getCodeName } from '../../../VirtualConsult/QuestionBuilder/helpers/formHelper';
import utils from '../../Utils/utils';
import { createInputHelper } from '../helpers/createInputHelper';

// redux
import { selectFormData } from '../selectors';

// ui reflection of file view
function ConditionalField(props) {
  const { field, values, handleChange, handleChangeWithParams, autoSetValues, disabled, getChildren } = props;

  const formData = useSelector(selectFormData);

  const checkFieldName = field.checkField && utils.getPropertyByName(field.checkField, formData);

  if (typeof formData?.[field.checkField] !== 'undefined' && field.checkPopulated) {
    if (
      !field.checkNotValue ||
      field.checkNotValues?.contains[utils.getPropertyByName(field.checkField, formData)] ||
      utils.getPropertyByName(field.checkField, formData) !== field.checkNotValue
    ) {
      return createInputHelper(
        [
          {
            ...field,
            type: field.targetType,
          },
        ],
        values,
        handleChange,
        handleChangeWithParams,
        autoSetValues,
        disabled,
        getChildren
      );
    }
  } else if (field.checkField && !field.checkPopulated && (field.checkValue !== undefined || true)) {
    if (checkFieldName === field.checkValue) {
      return createInputHelper(
        [
          {
            ...field,
            type: field.targetType,
          },
        ],
        values,
        handleChange,
        handleChangeWithParams,
        autoSetValues,
        disabled
      );
    }
    if (field.checkValue === 'medicalCode' && checkFieldName) {
      return createInputHelper(
        [
          {
            ...field,
            type: field.targetType,
            labelField: getFieldName(checkFieldName),
            searchType: checkFieldName,
            setFields: [
              {
                field: getCodeName(checkFieldName),
                targetField: 'code',
              },
            ],
          },
          {
            type: 'text',
            name: 'code',
            label: 'Code',
            required: false,
            width: 3,
            readOnly: true,
          },
        ],
        values,
        handleChange,
        handleChangeWithParams,
        autoSetValues,
        disabled
      );
    }
    return <div />;
  }

  return <div />;
}

export default ConditionalField;
