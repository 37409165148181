import Typography from '@material-ui/core/Typography';
import React from 'react';

import styles from '../../../assets/Questionnaire.module.css';
import utils from '../../utils/utils';

function Name(props) {
  const { fieldName, fieldLabel, nameStyle, required, centerName, questionPreview, selectedLanguage } = props;

  const fieldTitle =
    selectedLanguage && fieldName && fieldName.translations
      ? fieldName.translations[selectedLanguage]
      : fieldLabel || fieldName;

  return (
    fieldTitle && (
      <Typography
        align={centerName ? 'center' : 'left'}
        className={nameStyle ? styles[`title--${nameStyle}`] : styles['title--style1']}
      >
        {questionPreview ? utils.renderHTMLTags(fieldTitle) : fieldTitle}
        {required && '*'}
      </Typography>
    )
  );
}

export default Name;
