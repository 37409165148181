import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React, { useState, useEffect } from 'react';
import styles from '../../assets/Questionnaire.module.css';
import utils from '../utils/utils';

// ui reflection of file view
function ScaleInput(props) {
  const { field, value: initialValue, handleChangeWithParams, selectedLanguage } = props;

  const [currentValue, setCurrentValue] = useState(initialValue);

  useEffect(() => {
    if (initialValue && initialValue !== currentValue) {
      setCurrentValue(initialValue);
    }
    // eslint-disable-next-line
  }, [initialValue]);

  const handleSelect = (option) => {
    setCurrentValue(option);
    handleChangeWithParams(field.name, option);
  };

  const fieldTitle =
    selectedLanguage && field.name && field.name.translations
      ? field.name.translations[selectedLanguage]
      : field.label || field.name;
  const questionDescription =
    selectedLanguage && field.description && field.description.translations
      ? field.description.translations[selectedLanguage]
      : field.description;

  return (
    <Grid>
      <Grid item xs={12} style={{ width: '100%' }}>
        {fieldTitle && (
          <Typography className={field.nameStyle ? styles[`title--${field.nameStyle}`] : styles['title--style1']}>
            {field.questionPreview ? utils.renderHTMLTags(fieldTitle) : fieldTitle}
            {field.required && '*'}
          </Typography>
        )}
        {field.image && (
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <img
              src={field.image}
              alt={fieldTitle}
              style={{
                display: 'block',
                marginLeft: 'auto',
                marginRight: 'auto',
                width: '90%',
              }}
            />
          </Grid>
        )}
        {questionDescription && (
          <Typography
            className={
              field.descriptionStyle ? styles[`description--${field.descriptionStyle}`] : styles['description--style1']
            }
          >
            {utils.renderHTMLTags(questionDescription)}
          </Typography>
        )}
      </Grid>
      <Grid container justifyContent="center">
        {field.options &&
          field.options.map((option, idx) => (
            // eslint-disable-next-line react/no-array-index-key
            <Grid key={idx} item xs={3} style={{ flexBasis: 0 }}>
              <Button
                variant={currentValue === option ? 'contained' : 'outlined'}
                onClick={() => handleSelect(option)}
                style={{ padding: 1, margin: 1, border: '1px solid' }}
              >
                {option}
              </Button>
            </Grid>
          ))}
      </Grid>
    </Grid>
  );
}

export default ScaleInput;
