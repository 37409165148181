import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ProfileViewStyles from '../../../assets/jss/components/ProfileViewStyles';
import * as htmlUtils from '../../Shared/Grid/helpers';
import { selectCurrentTenant } from '../../Shared/selectors';
import AppKendoTableWrapper from '../../Shared/Table/KendoTableWrapper';
import utils from '../../Shared/Utils/utils';
import { selectUserDetails, selectUserQuestionnaire, selectUserQuestionnaireLoading } from '../../Users/selectors';
import { getQuestionnaireExecution } from '../../Users/usersSlice';
import { assessmentHistoryColumns } from '../helpers/profileListMapper';
import TabDetailPanel from './TabDetailPanel';

function AssessmentHistory() {
  const classes = useStyles();

  const currentTenant = useSelector(selectCurrentTenant);
  const userDetails = useSelector(selectUserDetails);
  const userQuestionnaire = useSelector(selectUserQuestionnaire);
  const userQuestionnaireLoading = useSelector(selectUserQuestionnaireLoading);

  const [selectedRow] = useState(undefined);

  const dispatch = useDispatch();

  // handles all table updates from table wrapper
  const handleTableUpdate = (page, pageSize, sort, direction) => {
    dispatch(
      getQuestionnaireExecution(page, pageSize, sort, direction.toUpperCase(), userDetails._id, currentTenant.id)
    );
  };

  useEffect(() => {
    dispatch(getQuestionnaireExecution(0, 10, 'started', 'DESC', userDetails._id, currentTenant.id));
  }, [currentTenant, userDetails]);

  const columns = useMemo(() => assessmentHistoryColumns(), []);

  return (
    <Grid className={classes.contentWrapper}>
      <AppKendoTableWrapper
        data={{
          ...userQuestionnaire,
          items:
            userQuestionnaire &&
            userQuestionnaire.content &&
            userQuestionnaire.content.map((n) => ({
              ...n,
              started: n.started && utils.formatDateTime(n.started),
              finalOutcome: n.finalOutcome || 'Incomplete Virtual Consult',
              completed: n.completed && utils.formatDateTime(n.completed),
              outcome: n.outcome && n.outcome.name,
              selected: selectedRow && n.id === selectedRow.id,
            })),
        }}
        initialSort="started"
        initialDir="desc"
        columns={columns}
        loading={userQuestionnaireLoading}
        page={userQuestionnaire && userQuestionnaire.pageable.pageNumber}
        onTableUpdate={handleTableUpdate}
        detailsComponent={htmlUtils.withCustomProp(
          htmlUtils.withCustomProp(TabDetailPanel, 'blacklistItems', ['cacheId', 'testResult', 'testStatus']),
          'property',
          'symptomChecker'
        )}
        parent="virtual-consult"
      />
    </Grid>
  );
}

const useStyles = makeStyles(ProfileViewStyles);

export default AssessmentHistory;
