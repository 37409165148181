/* eslint-disable react/no-array-index-key */
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React, { useState, useEffect } from 'react';

import utils from '../../Utils/utils';

// ui reflection of file view
function ScaleInput(props) {
  const { field, value: initialValue, handleChangeWithParams, selectedLanguage } = props;

  const [currentValue, setCurrentValue] = useState(initialValue);

  useEffect(() => {
    if (initialValue && initialValue !== currentValue) {
      setCurrentValue(initialValue);
    }
    // eslint-disable-next-line
  }, [initialValue]);

  const handleSelect = (option) => {
    setCurrentValue(option);
    handleChangeWithParams(field.name, option);
  };

  const fieldLabel =
    selectedLanguage && field.name && field.name.translations
      ? field.name.translations[selectedLanguage]
      : field.label || field.name;

  const questionDescription =
    selectedLanguage && field.description && field.description.translations
      ? field.description.translations[selectedLanguage]
      : field.description;

  return (
    <div>
      <Typography style={{ opacity: 0.5, marginBottom: 10 }}>
        {field.questionPreview ? utils.renderHTMLTags(questionDescription) : fieldLabel}
        {field.required && '*'}
      </Typography>
      <Grid container justifyContent="center">
        {field.options &&
          field.options.map((option, idx) => (
            <Grid key={idx} item xs={4} style={{ flexBasis: 0 }}>
              <Button
                variant={currentValue === option ? 'contained' : 'outlined'}
                onClick={() => handleSelect(option)}
                style={{ padding: 1, margin: 1, border: '1px solid' }}
              >
                {option}
              </Button>
            </Grid>
          ))}
      </Grid>
    </div>
  );
}

export default ScaleInput;
