import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';
import React from 'react';

export function FullscreenLoader({ isLoading = true }) {
  const classes = useStyle();

  return isLoading ? (
    <div className={classes.progress}>
      <CircularProgress size={80} color="primary" />
    </div>
  ) : null;
}

const useStyle = makeStyles({
  progress: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    height: '100%',
    width: '100%',
    position: 'fixed',
    zIndex: 5,
    backgroundColor: '#f3f3f3',
  },
});
