import React from 'react';

import Questionnaire from '../VirtualConsult/vcb-package/Questionnaire';

function ProviderQuestionnaireWrapper() {
  // TODO: Create variables to trigger subquestionnaires by test type

  // const positiveTests = testList.filter((test) => test.interpretation !== 'NEGATIVE');
  const url = window.location.pathname;
  const tenantId = url.split('/')[1];

  return (
    <Questionnaire
      questionnaireId="7d2f561a-60a6-4380-92c6-ef68d377124b"
      tenantId={tenantId}
      // startFromStoppedQuestion
      withReporterFunc
    />
  );
}

export default ProviderQuestionnaireWrapper;
