import IconButton from '@material-ui/core/IconButton';
import GetAppIcon from '@material-ui/icons/GetApp';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { showConfirmation } from '../Confirmation/confirmationSlice';
import { selectCurrentTenant } from '../Shared/selectors';

function UploadActionCell(props) {
  const { dataItem } = props;

  const currentTenant = useSelector(selectCurrentTenant);

  const dispatch = useDispatch();

  const handleDownload = () => {
    dispatch(
      showConfirmation(
        'Confirm',
        `Do you want to download <span style="font-weight: bold">${dataItem?.fileName || ''}</span> file?`,
        'downloadUploadingFile',
        currentTenant && currentTenant.id,
        dataItem
      )
    );
  };

  return (
    <td>
      <IconButton color="#757575" aria-label="download" onClick={handleDownload}>
        <GetAppIcon />
      </IconButton>
    </td>
  );
}

export default UploadActionCell;
