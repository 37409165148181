import { makeStyles } from '@material-ui/core/';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { showNotification } from '../../../Notifications/notificationSlice';
import { addNewOption, updateOption, deleteOption, getOptionList } from '../reviewerSlice';
import { selectConcernList, selectReviewerList, selectCurrentUserData } from '../selectors';

function OptionForm(props) {
  const { currentTenant, optionType, optionName, expandOptionForm } = props;
  const classes = useStyles();

  const currentUserData = useSelector(selectCurrentUserData);
  const optionList = useSelector(optionType === 'concern' ? selectConcernList : selectReviewerList);

  const [value, setValue] = useState('');
  const [editingOptionId, setEditingOptionId] = useState('');
  const [deletingValue, setDeletingValue] = useState(null);
  const [loading, setLoading] = useState(false);
  const [mountingComponent, setMountingComponent] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const dispatch = useDispatch();
  const inputRef = useRef();

  useEffect(() => {
    if (expandOptionForm === optionType) {
      setIsExpanded(true);
      const timeout = setTimeout(() => {
        inputRef.current.focus();
      }, 500);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [expandOptionForm, optionType]);

  const handleComponentMounting = async () => {
    setMountingComponent(true);
    await dispatch(getOptionList(currentTenant, optionType));
    setMountingComponent(false);
  };

  useEffect(() => {
    handleComponentMounting();
    // eslint-disable-next-line
  }, []);

  const handleChangeValue = (e) => {
    const newValue = e.target.value.replace(/\s+/g, ' ');
    setValue(newValue);
  };

  const checkOptionOnDuplicates = (optionValue) => {
    let isDuplicate = false;
    optionList.forEach((item) => {
      if (item.name.toLowerCase() === optionValue.toLowerCase()) {
        isDuplicate = true;
      }
    });
    return isDuplicate;
  };

  const handleAddNew = async () => {
    const isDuplicate = checkOptionOnDuplicates(value);
    if (isDuplicate) {
      dispatch(showNotification(`This ${optionType} option already exists`, 'error'));
    } else if (value) {
      setLoading(true);
      const newOption = {
        tenantId: currentTenant,
        creator: currentUserData.email,
        name: value,
      };
      await dispatch(addNewOption(currentTenant, optionType, newOption));
      setLoading(false);
      setValue('');
      setEditingOptionId('');
    }
  };

  const handleUpdateOptionItem = async () => {
    const oldOptionData = optionList.filter((item) => item._id === editingOptionId)[0];

    if (editingOptionId && oldOptionData.name !== value) {
      const isDuplicate = checkOptionOnDuplicates(value);
      if (isDuplicate) {
        return dispatch(showNotification(`This ${optionType} option already exists`, 'error'));
      }

      setLoading(true);
      const updatedOption = {
        _id: editingOptionId,
        tenantId: currentTenant,
        modifier: currentUserData.email,
        name: value,
      };
      await dispatch(updateOption(optionType, editingOptionId, updatedOption));
      setLoading(false);
      setValue('');
      setEditingOptionId('');
    }
  };

  const handleDelete = async (optionId) => {
    if (optionId) {
      setDeletingValue(optionId);
      await dispatch(deleteOption(optionType, optionId));
      setDeletingValue(null);
    }
  };

  const handleAddNewOnEnter = (e) => {
    const key = e.keyCode || e.which;

    if (key === 13) {
      handleAddNew();
    }
  };

  const handleUpdateOnEnter = (e) => {
    const key = e.keyCode || e.which;

    if (key === 13) {
      handleUpdateOptionItem();
    }
  };

  return (
    <Grid style={{ marginTop: 20 }}>
      <Accordion defaultExpanded={false} expanded={isExpanded} onChange={() => setIsExpanded(!isExpanded)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={optionType} id={optionType}>
          <Typography variant="body1" color="primary">
            {optionName}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {mountingComponent ? (
            <Grid container justifyContent="center" style={{ padding: 100 }}>
              <CircularProgress />
            </Grid>
          ) : (
            <Grid container xs={12}>
              {optionList.length
                ? optionList.map((option) => (
                    <>
                      <Grid item xs={10} md={8} style={{ position: 'relative' }}>
                        {loading && editingOptionId === option._id ? (
                          <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                            className={classes.updateLoadingWrapper}
                          >
                            <CircularProgress />
                          </Grid>
                        ) : null}

                        <TextField
                          name={`${optionType}Name`}
                          margin="normal"
                          value={editingOptionId === option._id ? value : option.name}
                          onClick={() => {
                            setEditingOptionId(option._id);
                            setValue(option.name);
                          }}
                          onBlur={handleUpdateOptionItem}
                          onChange={(e) => {
                            handleChangeValue(e);
                          }}
                          onKeyPress={(e) => {
                            handleUpdateOnEnter(e);
                          }}
                          autoComplete="off"
                          type="text"
                          fullWidth
                        />
                      </Grid>

                      <Grid container item alignItems="flex-end" justifyContent="flex-start" xs={2} md={4}>
                        {deletingValue === option._id ? (
                          <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                            className={classes.loadingWrapper}
                          >
                            <CircularProgress />
                          </Grid>
                        ) : (
                          <Tooltip title={`Delete ${optionType}`}>
                            <IconButton onClick={() => handleDelete(option._id)} data-testid="expansionPanelDeleteBtn">
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                      </Grid>
                    </>
                  ))
                : null}

              <Grid container xs={12} md={12} style={{ position: 'relative' }}>
                {loading && !editingOptionId ? (
                  <Grid container justifyContent="center" alignItems="center" className={classes.updateLoadingWrapper}>
                    <CircularProgress />
                  </Grid>
                ) : null}
                <TextField
                  inputRef={inputRef}
                  label={`New ${optionType}`}
                  name={`new${optionType}Name`}
                  margin="normal"
                  value={!editingOptionId ? value : ''}
                  autoComplete="off"
                  type="text"
                  placeholder={`Add new ${optionType}`}
                  onBlur={handleAddNew}
                  onFocus={() => {
                    setValue('');
                    setEditingOptionId('');
                  }}
                  onChange={(e) => {
                    handleChangeValue(e);
                  }}
                  onKeyPress={(e) => {
                    handleAddNewOnEnter(e);
                  }}
                  fullWidth
                />
              </Grid>
            </Grid>
          )}
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
}

const useStyles = makeStyles(() => ({
  loadingWrapper: {
    width: 48,
    height: 48,
    '&>.MuiCircularProgress-root': {
      width: '24px!important',
      height: '24px!important',
    },
  },
  updateLoadingWrapper: {
    height: 48,
    position: 'absolute',
    width: '100%',
    marginTop: '16px',
    backgroundColor: 'rgb(255 255 255 / 70%)',
    zIndex: 1,
    '&>.MuiCircularProgress-root': {
      width: '24px!important',
      height: '24px!important',
    },
  },
}));

export default OptionForm;
