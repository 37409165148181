import 'hammerjs';
import { Path, Text, geometry, Layout } from '@progress/kendo-drawing';

import {
  Chart,
  ChartArea,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartCategoryAxisLabels,
  ChartLegend,
  ChartLegendItem,
  ChartSeries,
  ChartSeriesItem,
  ChartSeriesItemTooltip,
  ChartTitle,
} from '@progress/kendo-react-charts';
import PropTypes from 'prop-types';
import React from 'react';
import ChartCard from '../ChartCard';
import InfoBox from './InfoBox';

/**
 * Line chart component
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function LineChart(props) {
  const { title, height, data, timeline, infoText, isTooltipVisible = false } = props;

  // Limit labels on large date spans
  const getLabelStep = () => {
    if (data && data[0] && data[0].data) {
      const labelsLength = data[0].data.length;
      if (labelsLength > 20 && labelsLength <= 60) return 2;
      if (labelsLength > 60 && labelsLength <= 120) return 4;
      if (labelsLength > 120) return 7;
    }
    return 1;
  };

  // Chart pan and zoom settings depend on interactive property
  const chartPan = props.interactive ? { lock: 'y' } : false;
  const chartZoom = props.interactive ? { mousewheel: { lock: 'y' }, selection: { lock: 'y' } } : false;

  const chartSeries = data.map((dataItem) => (
    <ChartSeriesItem
      type="line"
      color={dataItem.name === 'total' ? '#999' : undefined}
      name={dataItem.name}
      data={dataItem.data}
      key={dataItem.name}
    >
      <ChartSeriesItemTooltip
        // format={"{0}"}
        render={({ point }) => <span>{point.value}</span>}
        visible={isTooltipVisible}
      />
    </ChartSeriesItem>
  ));

  return (
    <ChartCard height={height}>
      {infoText && <InfoBox infoText={infoText} position={'topRight'} />}
      <Chart pannable={chartPan} zoomable={chartZoom}>
        <ChartTitle color="#505358" font="16px Avenir" text={title} />
        <ChartLegend position="bottom" orientation="horizontal">
          <ChartLegendItem
            visual={(e) => {
              const color = e.options?.markers?.background;
              const labelColor = e.options?.labels?.color;
              const border = e.series?.border?.color;
              const rect = new geometry.Rect([0, 0], [175, 50]);
              const layout = new Layout(rect, {
                spacing: 5,
                wrap: false,
                alignItems: 'center',
              });

              const marker = new Path({
                fill: {
                  color,
                },
                stroke: {
                  color: border || color,
                  width: 1,
                },
              })
                .moveTo(18, 6)
                .lineTo(18, 11)
                .lineTo(0, 11)
                .lineTo(0, 6)
                .close();

              const label = new Text(e.series.name, [0, 0], {
                font: '12px Avenir',
                fill: {
                  color: labelColor,
                },
              });

              layout.append(marker, label);
              layout.reflow();

              return layout;
            }}
          />
        </ChartLegend>
        <ChartArea height={height - 20} />
        {/* <ChartValueAxis> */}
        {/*  <ChartValueAxisItem minorUnit={1} /> */}
        {/* </ChartValueAxis> */}
        <ChartCategoryAxis>
          <ChartCategoryAxisItem categories={timeline}>
            <ChartCategoryAxisLabels rotation="auto" step={getLabelStep()} />
          </ChartCategoryAxisItem>
        </ChartCategoryAxis>
        <ChartSeries>{chartSeries}</ChartSeries>
      </Chart>
    </ChartCard>
  );
}

LineChart.propTypes = {
  /**
   * Chart title
   */
  title: PropTypes.string,

  /**
   * Chart height
   */
  height: PropTypes.number,
  /**
   * array of next structure: name, data
   */
  data: PropTypes.array.isRequired,

  /**
   * array of strings, like "Feb 01"
   */
  timeline: PropTypes.array.isRequired,

  /**
   * If true then zoom, pan and mousewheel enabled
   */
  interactive: PropTypes.bool,
};

export default LineChart;
