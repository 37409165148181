/* eslint-disable react/no-array-index-key */
import CardContent from '@material-ui/core//CardContent';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentTenant } from '../../Shared/selectors';
import utils from '../../Shared/Utils/utils';
import { selectUserDetails, selectUserIdVerHisData, selectUserIdVerHisLoading } from '../../Users/selectors';
import { getUserIdentityVerificationHistory } from '../../Users/usersSlice';

function UserVerification() {
  const currentTenant = useSelector(selectCurrentTenant);
  const userDetails = useSelector(selectUserDetails);
  const userIdVerHisLoading = useSelector(selectUserIdVerHisLoading);
  const userIdVerHisData = useSelector(selectUserIdVerHisData);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserIdentityVerificationHistory(userDetails.nationalHealthId, currentTenant.id));
  }, [currentTenant, userDetails]);

  const createUserIdVerificationList = () => {
    const listItems = [];
    if (!userIdVerHisLoading && userIdVerHisData && userIdVerHisData.data && userIdVerHisData.data.length) {
      for (let i = 0; i < userIdVerHisData.data.length; i++) {
        const userIdVerData = userIdVerHisData.data[i];

        listItems.push(
          <Accordion style={{ width: '100%', backgroundColor: '#fafafa' }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <Typography>
                <h4>{userIdVerData.transactionId || '---'}</h4>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                <InfoItemComponent label="Verification Date">
                  {' '}
                  {utils.formatDate(userIdVerData.modified) || '---'}
                </InfoItemComponent>

                <InfoItemComponent label="ID Type"> {userIdVerData.documentType || '---'}</InfoItemComponent>

                <InfoItemComponent label="ID First Name"> {userIdVerData.firstName || '---'}</InfoItemComponent>

                <InfoItemComponent label="ID Last Name"> {userIdVerData.lastName || '---'}</InfoItemComponent>

                <InfoItemComponent label="ID DOB"> {utils.formatDate(userIdVerData.dob) || '---'}</InfoItemComponent>

                <InfoItemComponent label="ID Expiration">
                  {' '}
                  {utils.formatDate(userIdVerData.expirationDate) || '---'}
                </InfoItemComponent>

                <InfoItemComponent label="Verification Status">
                  {' '}
                  {userIdVerData.verificationStatus.includes('NOT') ? 'Not Verified' : 'Verified' || '---'}
                </InfoItemComponent>

                <InfoItemComponent label="ID Image"> {userIdVerData.frontImageUrl || '---'}</InfoItemComponent>

                <InfoItemComponent label="Self Potrait"> {userIdVerData.faceImageUrl || '---'}</InfoItemComponent>
              </Grid>
            </AccordionDetails>
          </Accordion>
        );
      }
    } else {
      listItems.push(
        <Grid item xs={12}>
          <Box>
            <div>
              <Typography variant="body1">
                <div style={{ margin: '10px', textAlign: 'center' }}>No History of Identity Verification.</div>
              </Typography>
            </div>
          </Box>
        </Grid>
      );
    }
    return listItems.map((item, index) => <React.Fragment key={index}>{item}</React.Fragment>);
  };

  return (
    <CardContent>
      <Grid container direction="row" justifyContent="flex-start" alignItems="center">
        {createUserIdVerificationList()}
      </Grid>
    </CardContent>
  );
}

function InfoItemComponent(props) {
  const { label, children: content } = props;
  const classes = useStyles();
  return (
    <Grid item xs={3}>
      <Box>
        <Typography variant="h4" className={classes.label}>
          {label}
        </Typography>
        <div>
          <Typography variant="body1">{content}</Typography>
        </div>
      </Box>
    </Grid>
  );
}

const useStyles = makeStyles(() => ({
  label: {
    fontSize: '12px',
    fontWeight: 'bold',
    paddingBottom: '5px',
  },
}));

export default UserVerification;
