/* eslint-disable import/no-cycle */
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React, { useState, useEffect } from 'react';

import styles from '../../assets/Questionnaire.module.css';
import { createInputHelper } from '../createInputHelper';
import utils from '../utils/utils';

/*
IMPORTANT NOTE
For this component to work properly, the 'subnodes' array in the parent node must be copied into the
combo question object after the node is retrieved from the API
*/

function ComboQuestion(props) {
  const { field, handleChangeWithParams, selectedLanguage } = props;
  const [currentValue, setCurrentValue] = useState({});
  const [nodeList, setNodeList] = useState([]);

  // Consolidate subnode information
  useEffect(() => {
    if (field.nodeList?.length && field.subnodes?.length) {
      const assembledNodeList = [];
      field.nodeList.forEach((subnode) => {
        const assembledSubnode = {
          ...subnode,
          ...field.subnodes?.find((matchingSubnode) => subnode.questionId === matchingSubnode?.id),
          id: subnode.questionId,
          questionPreview: true,
          questionnaireCommentEnabled: field.questionnaireCommentEnabled,
        };
        assembledNodeList.push(assembledSubnode);
      });
      setNodeList(assembledNodeList);
    }
  }, [field.nodeList, field.subnodes]);
  const getQuestionName = (name) => {
    if (name && name.target) return name.target.value;
    if (name && name.translations) return name.translations.eng;
    return name;
  };
  const onChange = (name, answer) => {
    name = getQuestionName(name);
    answer = answer?.target ? answer?.target.value : answer;
    const answerSet = { ...currentValue };
    const questionAnswered = nodeList.find((subnode) => subnode.name?.translations?.eng === name);
    // Bail on answer in builder preview
    if (!questionAnswered) return;
    // Answer in state is an object with a key of question Id to keep track of entered answers
    answerSet[questionAnswered.id] = answer;
    setCurrentValue(answerSet);
    let allQuestionsAnswered = true;
    const submittedAnswers = field.nodeList.map((subnode) => {
      const currentQuestion = nodeList.find((question) => question.id === subnode.questionId);
      if (!answerSet[subnode.questionId]) allQuestionsAnswered = false;
      return {
        questionId: currentQuestion?.id,
        answer: answerSet[subnode.questionId],
      };
    });
    // Keep Continue disabled until all child questions have been answered
    if (allQuestionsAnswered) {
      handleChangeWithParams(field.name.translations?.eng, submittedAnswers);
    } else {
      // Disable Continue if an answer is erased or becomes invalid
      handleChangeWithParams(field.name.translations?.eng, '');
    }
  };

  const fieldTitle =
    selectedLanguage && field.name && field.name.translations
      ? field.name.translations[selectedLanguage]
      : field.label || field.name;
  const questionDescription =
    selectedLanguage && field.description && field.description.translations
      ? field.description.translations[selectedLanguage]
      : field.description;

  return (
    <div>
      <Grid item xs={12} style={{ width: '100%' }}>
        {fieldTitle && (
          <Typography className={styles['title--style1']}>
            {field.questionPreview ? utils.renderHTMLTags(fieldTitle) : fieldTitle}
            {field.required && '*'}
          </Typography>
        )}
        {field.image && (
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <img
              src={field.image}
              alt={fieldTitle}
              style={{
                display: 'block',
                marginLeft: 'auto',
                marginRight: 'auto',
                width: '90%',
              }}
            />
          </Grid>
        )}

        {questionDescription && (
          <Typography className={styles['description--style1']}>{utils.renderHTMLTags(questionDescription)}</Typography>
        )}
      </Grid>

      <Grid container style={{ position: 'relative' }}>
        {createInputHelper(nodeList, {}, onChange, onChange, null, false, () => {}, selectedLanguage)}
      </Grid>
    </div>
  );
}

export default ComboQuestion;
