import Tooltip from '@material-ui/core/Tooltip';
import WarningIcon from '@material-ui/icons/Warning';
import React from 'react';

function OverriddenOnCell(props) {
  const { dataItem } = props;

  if (!dataItem.overriddenOn) {
    return <td />;
  }

  return (
    <td>
      <Tooltip title="Manual Override" placement="top">
        <WarningIcon color={!dataItem.selected ? 'error' : ''} onClick={() => dataItem.handleOpenDetails(dataItem)} />
      </Tooltip>
    </td>
  );
}

export default OverriddenOnCell;
