import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React from 'react';

import ExpansionPanelWrapper from './ExpansionPanelWrapper';
import { testAnalyteDataMap } from './helpers/mapping';
import LabSpecimenDetailsWrapper from './LabSpecimenDetailsWrapper';
import ReportWrapper from './ReportWrapper';

function TestItemWrapper(props) {
  const { testData } = props;
  const { testAnalyteList } = testData;

  const renderTitle = () => (
    <Grid container spacing={2}>
      <Grid item md={4}>
        <Typography variant="body2" color="primary">
          <strong>TEST NAME: </strong>
          {testData.testName}
        </Typography>
      </Grid>

      <Grid item md={4}>
        <Typography variant="body2" color="primary">
          <strong>INTERPRETATION: </strong>
          {testData.interpretation.toUpperCase()}
        </Typography>
      </Grid>

      <Grid item md={4}>
        <Typography variant="body2" color="primary">
          <strong>REPORT STATUS: </strong>
          {testData.reportStatus.toUpperCase()}
        </Typography>
      </Grid>
    </Grid>
  );
  return (
    <Grid style={{ marginTop: 15 }}>
      <ExpansionPanelWrapper renderTitle={renderTitle} name="testAnalyteDataMap" defaultExpanded={false}>
        <LabSpecimenDetailsWrapper testData={testData} />

        {testAnalyteList && testAnalyteList.length
          ? testAnalyteList.map((testAnalyteItem) => (
              <Grid
                container
                spacing={1}
                style={{
                  paddingBottom: 15,
                  marginBottom: 15,
                  borderBottom: '1px solid #f1f1f1',
                }}
              >
                {testAnalyteDataMap.map((test) => (
                  <ReportWrapper report={test} data={testAnalyteItem} />
                ))}
              </Grid>
            ))
          : null}
      </ExpansionPanelWrapper>
    </Grid>
  );
}

export default TestItemWrapper;
