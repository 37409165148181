import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import api from '../../api';
import { logout } from '../Shared/sharedSlice';

import { selectDefaultTenantId } from './selectors';
import { parseJwt } from './utils';

export function RefreshToken() {
  const refreshTimerId = useRef(0);
  const dispatch = useDispatch();
  const defaultTenantId = useSelector(selectDefaultTenantId);
  const navigate = useNavigate();

  useEffect(() => {
    function runRefreshJob() {
      const idToken = localStorage.getItem('id_token');
      if (!idToken) return;

      const jwt = parseJwt(idToken);
      if (!(jwt && jwt.exp)) return;

      const tokenExpTime = jwt.exp * 1000 - 1000 * 60 * 3; // Token expiration time - 3 min

      const timeout = tokenExpTime - Date.now();
      if (!timeout) return dispatch(logout(navigate));

      setTimeout(async () => {
        try {
          const refreshToken = localStorage.getItem('refresh_token');
          const [result] = await api.refreshIdToken({
            tenantId: defaultTenantId,
            refreshToken,
          });
          if (result && result.isOK && result.data && result.data.idToken) {
            localStorage.setItem('id_token', result.data.idToken);
            runRefreshJob();
          } else {
            dispatch(logout(navigate));
          }
        } catch (e) {
          // eslint-disable-next-line
          console.log(e);
        }
      }, timeout);
    }

    runRefreshJob();
    return () => clearTimeout(refreshTimerId.current);
  }, []);

  return null;
}
