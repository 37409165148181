import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// import { useNavigate } from 'react-router-dom'
import styles from './assets/Questionnaire.module.css';
import OutcomePreview from './OutcomePreview';
import {
  startQuestionnaire,
  setReporterAnswers,
  registerAnonymousUser,
  getCurrentQuestionnaireQuestion,
  nextQuestionnaireQuestion,
  saveQuestionnaireAnswers,
  clearQuestionnaireForm,
  questionnaireFormMounted,
  prevQuestionnaireQuestion,
  sendVerificationCode,
  jumpDirectlyToNode,
  verifyInsurance,
  uploadImage,
} from './questionnaireSlice';
import QuestionPreview from './QuestionPreview';
import {
  // selectConsult,
  selectQuestionnaireData,
  selectQuestionData,
  selectOutcomeData,
  selectExecutionId,
  selectQuestionnaireAnswers,
  selectFormattedQuestionnaireAnswers,
  selectIsValidAnswer,
} from './selectors';

function Questionnaire(props) {
  const {
    questionnaireId,
    tenantId,
    isVcbAminConsole,
    startFromStoppedQuestion, // if you want start questionnaire from the last question after page reloading
    withReporterFunc,
    allowAnonymous, // Allow questionnaires to be run without user login. This must be activated in the API for each tenant.
  } = props;
  const langSelected = 'eng';
  // const consult = useSelector(selectConsult)
  const executionId = useSelector(selectExecutionId);
  const questionnaireAnswers = useSelector(selectQuestionnaireAnswers);
  const formattedAnswers = useSelector(selectFormattedQuestionnaireAnswers);
  const questionnaireData = useSelector(selectQuestionnaireData);
  const questionData = useSelector(selectQuestionData);
  const outcomeData = useSelector(selectOutcomeData);
  const validAnswer = useSelector(selectIsValidAnswer);

  const [loading, setLoading] = useState(false);
  const [disabledBack, setDisabledBack] = useState(false);
  const [currentQuestionAnswer, setCurrentQuestionAnswer] = useState(null);
  const [questionComment, setQuestionComment] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState('eng');
  const [disabled, setDisabled] = useState(true);
  const [dialog] = useState('');
  const [loadingStartQuestionnaire, setLoadingStartQuestionnaire] = useState(false);
  const dispatch = useDispatch();
  // const navigate = useNavigate()
  // const { REACT_APP_DEFAULT_TIMEZONE } = process.env

  const handleStartQuestionnaire = useCallback(
    async (questionnaireDataMounted) => {
      setLoadingStartQuestionnaire(true);
      // if executionId and startFromStoppedQuestion=true, then try to get the last question where the stop was
      if (
        startFromStoppedQuestion &&
        questionnaireDataMounted?.executionId &&
        questionnaireDataMounted?.questionnaireId === questionnaireId // if was run the same questionnaire
      ) {
        await dispatch(getCurrentQuestionnaireQuestion(tenantId, questionnaireDataMounted?.executionId));
      } else {
        await dispatch(clearQuestionnaireForm());
        if (allowAnonymous) await dispatch(registerAnonymousUser(tenantId));
        await dispatch(startQuestionnaire(questionnaireId, tenantId));
      }
      setLoadingStartQuestionnaire(false);
      dispatch(setReporterAnswers({}));
    },
    // eslint-disable-next-line
    [questionnaireId]
  );

  useEffect(() => {
    const questionnaireDataMounted = dispatch(questionnaireFormMounted());
    handleStartQuestionnaire(questionnaireDataMounted);
    // eslint-disable-next-line
  }, [questionnaireId]);

  // Clear yes/no question answers when the question first loads.
  // This prevents unexpected behavior when a yes/no question is returned to via the 'Back' button
  useEffect(() => {
    setLoading(false);
    if (questionData?.type === 'yes-no') {
      const clearedAnswers = { ...formattedAnswers };
      clearedAnswers[questionData.id] = null;
    }
  }, [formattedAnswers, questionData]);

  useEffect(() => {
    // set stored answers for currentQuestion
    if (questionData?.id) {
      setCurrentQuestionAnswer(formattedAnswers[questionData.id] || '');
    }
    // eslint-disable-next-line
  }, [questionnaireData]);

  // Convert selected language from Onsite Testing abbreviation to VCB abbreviation
  useEffect(() => {
    switch (langSelected) {
      case 'es':
        setSelectedLanguage('spa');
        break;
      case 'zh-cn':
        setSelectedLanguage('zho');
        break;
      default:
        setSelectedLanguage('eng');
    }
  }, []);

  // Automatically progress on Yes/No question without hitting continue
  useEffect(() => {
    if ((currentQuestionAnswer === 'Yes' || currentQuestionAnswer === 'No') && !questionData.commentEnabled) {
      handleContinue();
    }
    // eslint-disable-next-line
  }, [currentQuestionAnswer]);

  const startOverQuestionnaire = () => {
    dispatch(clearQuestionnaireForm());
    handleStartQuestionnaire();
  };

  // Select language from DeviceFrame preview
  const onLanguageChange = (evt) => {
    const newLanguage = evt.target.value;
    setSelectedLanguage(newLanguage);
  };

  const handleBack = async () => {
    setDisabledBack(true);
    await dispatch(prevQuestionnaireQuestion(tenantId, executionId));
    setDisabledBack(false);
  };

  const handleChange = (answer) => {
    const questionAnswer = answer && answer.target ? answer.target.value : answer;
    setCurrentQuestionAnswer(questionAnswer);
  };

  const handleChangeWithParams = (name, value) => {
    // Check for comment from multiple choice answer
    if (typeof name === 'string' && name.includes('comment')) {
      const updatedData = { ...questionComment };
      updatedData[name.replace('_comment', '')] = value;
      setQuestionComment(updatedData);
    } else {
      setCurrentQuestionAnswer(value);
    }
  };

  const handleChangeComment = (name, value) => {
    setQuestionComment(value);
  };

  const handleLinkClick = (nodeId) => {
    dispatch(jumpDirectlyToNode(tenantId, executionId, nodeId, withReporterFunc));
  };

  const handleContinue = async () => {
    setLoading(true);

    if (questionnaireData?.complete) {
      /* eslint-disable-next-line no-console */
      return console.log('!!!Describe the next step since the questionnaire has already been completed');
    }

    let preparedAnswer = currentQuestionAnswer;

    // Upload signature image and update answer with file id
    if (questionData?.type === 'graphicSignature') {
      const uploadResult = await uploadImage(currentQuestionAnswer, 'signature.png');
      if (!uploadResult) {
        setLoading(false);
        /* eslint-disable-next-line no-console */
        console.error('Signature image upload error');
        return;
      }
      preparedAnswer = uploadResult.fileId;
    }

    // save current answer
    if (questionData) {
      const updatedAnswers = { ...questionnaireAnswers };
      const currentQuestionId = questionData.id;
      updatedAnswers[currentQuestionId] = {
        questionId: currentQuestionId,
        answer: preparedAnswer,
        comment: questionComment,
        // type: currentQuestionData.type,
      };
      dispatch(saveQuestionnaireAnswers(updatedAnswers));
    }

    const answer = {
      nodeId: questionnaireData?.node.id,
      answer: preparedAnswer,
      question: questionData,
      type: questionData?.type,
      validate: questionData?.validate,
      // Type check prevents multiple choice answer comment objects from being added here, preventing an API error
      comment: typeof questionComment === 'string' ? questionComment : null,
      name: null,
    };

    // add precision value to answer object if date type
    if (answer.type === 'date') {
      answer.precision = questionData && questionData?.precision;
      answer.createdOn = currentQuestionAnswer.createdOn;
      answer.answer = currentQuestionAnswer.answer;
    }

    if (answer.type === 'multiple') {
      answer.answer = currentQuestionAnswer.map((i) => ({
        answer: i,
        nodeId: null,
        comment: questionComment && questionComment[i],
        type: 'small-text',
      }));
    }

    if (answer.type === 'consent') {
      answer.answer = currentQuestionAnswer && currentQuestionAnswer.length ? currentQuestionAnswer[0] : '';
      answer.name = currentQuestionAnswer && currentQuestionAnswer.length ? currentQuestionAnswer[1] : '';
    }

    if (outcomeData) {
      answer.answer = 'outcome';
      answer.type = 'outcome';
    }

    if ((answer.type === 'phone' || answer.type === 'email') && answer.validate) {
      dispatch(sendVerificationCode(tenantId, { [answer.type]: currentQuestionAnswer }, answer.type));
    } else if (answer.type === 'insurance') {
      // Verify insurance before answer submission
      // Birthdate must be set using this method to allow for test data w/ birthdate of 1880
      const birthDate = new Date(answer.answer.birthDate);
      birthDate.setUTCHours(0, 0, 0, 0);
      const verificationObject = {
        firstName: answer.answer.subscriber.firstName,
        lastName: answer.answer.subscriber.lastName,
        birthDate,
        insuranceId: answer.answer.insuranceId,
        payorId: answer.answer.payorId === 'TEST' ? '9496' : 'answer.answer.payorId',
        group: answer.answer.groupNumber,
        notPolicyHolder: answer.answer.subscriber.relation !== 'Self',
        planStartDate: answer.answer.coverageDate,
      };

      const result = await dispatch(verifyInsurance(tenantId, verificationObject));

      if (!result) return;
      setCurrentQuestionAnswer('');
      setQuestionComment(null);
      dispatch(nextQuestionnaireQuestion(tenantId, executionId, { answer }, withReporterFunc));
    } else {
      // Change type back after verification code
      answer.type = answer.type === 'phoneCode' ? 'phone' : answer.type;
      answer.type = answer.type === 'emailCode' ? 'email' : answer.type;

      setCurrentQuestionAnswer('');
      setQuestionComment(null);

      dispatch(nextQuestionnaireQuestion(tenantId, executionId, { answer }, withReporterFunc));
    }
  };

  // Answer validity checks
  useEffect(() => {
    if (questionData?.type === 'phoneCode' || questionData?.type === 'emailCode') {
      // Check if a valid verification code has been entered
      if (validAnswer) {
        handleContinue();
      }
    } else {
      // Check for any answer present for other question types
      const answerStatus =
        !(currentQuestionAnswer !== null &&
        currentQuestionAnswer !== undefined &&
        currentQuestionAnswer !== false &&
        true &&
        currentQuestionAnswer.toString()
          ? currentQuestionAnswer.toString()
          : currentQuestionAnswer) || currentQuestionAnswer.length === 0;
      setDisabled(answerStatus);
    }
    // eslint-disable-next-line
  }, [validAnswer, currentQuestionAnswer]);

  const largeQuestionText = questionData?.description?.length > 300 || false;

  function renderContent() {
    if (questionData) {
      return (
        <QuestionPreview
          questionnaireCommentEnabled={questionnaireData.node?.commentEnabled}
          formData={questionData}
          links={questionnaireData.node.links}
          isFirstQuestion={questionnaireData?.first}
          handleDeviceButton={() => handleContinue()}
          handleChange={handleChange}
          handleChangeWithParams={handleChangeWithParams}
          handleChangeComment={handleChangeComment}
          handleLinkClick={handleLinkClick}
          handleBack={handleBack}
          loading={loading}
          disabled={disabled}
          disabledBack={disabledBack}
          languageSupport // To be replaced by this prop after API support: {consult.questionnaire.languageSupport}
          selectedLanguage={selectedLanguage}
          handleLanguageChange={onLanguageChange}
          dialog={dialog}
          values={formattedAnswers}
        />
      );
    }

    if (outcomeData) {
      return (
        <OutcomePreview
          formData={outcomeData}
          handleContinue={() =>
            questionnaireData?.complete && isVcbAminConsole ? startOverQuestionnaire() : handleContinue()
          }
          handleBack={handleBack}
          disabled={questionnaireData?.complete}
          disabledBack={disabledBack}
          buttonType={questionnaireData?.complete ? 'startOver' : 'continue'}
          loading={loading}
          selectedLanguage={selectedLanguage}
        />
      );
    }
    return loadingStartQuestionnaire ? (
      <Grid container justifyContent="center" style={{ padding: 100 }}>
        <CircularProgress />
      </Grid>
    ) : (
      <Grid item>
        <OutcomePreview
          handleContinue={startOverQuestionnaire}
          message="Your questionnaire is incomplete.
          Please return to the questionnaire builder to complete the jump logic."
          loading={loading}
          buttonType="startOver"
        />
      </Grid>
    );
  }

  return (
    <Grid className={`questionnaire ${styles.questionnaireWrapper}`} container>
      <Grid container justifyContent="center">
        <Grid item className={`${styles.questionsWrapper} ${largeQuestionText ? styles.largeQuestionItem : ''}`}>
          {renderContent()}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Questionnaire;
