import { createSlice } from '@reduxjs/toolkit';

import api from '../../../api';
import { showNotification } from '../../Notifications/notificationSlice';
import { handleError } from '../../Shared/Content/APIUtils';
import utils from '../../Shared/Utils/utils';

import { dataURLtoFile } from './helpers/helpers';
import { concernListDefault, reviewerListDefault } from './helpers/mapping';

const initialState = {
  questionnaireList: [],
  concernList: [],
  reviewerList: [],
  annotationList: [],
  editingQuestion: null,
  // editingQuestion: "301df0f8-a382-4b03-a724-01837827ef10",
  deletingAnnotationLoader: false,
  deletingShapeId: null,
};

export const reviewerSlice = createSlice({
  name: 'reviewer',
  initialState,
  reducers: {
    setQuestionnaireList: (state, { payload }) => ({
      ...state,
      questionnaireList: payload,
    }),
    setConcernList: (state, { payload }) => ({
      ...state,
      concernList: payload,
    }),
    setReviewerList: (state, { payload }) => ({
      ...state,
      reviewerList: payload,
    }),
    setAnnotationList: (state, { payload }) => ({
      ...state,
      annotationList: payload,
    }),
    setEditingReviewerQuestion: (state, { payload }) => ({
      ...state,
      editingQuestion: payload,
    }),
    setDeletingAnnotationLoader: (state, { payload }) => ({
      ...state,
      deletingAnnotationLoader: payload,
    }),
    setDeletingShapeId: (state, { payload }) => ({
      ...state,
      deletingShapeId: payload,
    }),

    resetReviewer: () => ({ ...initialState }),
  },
});

export const {
  setQuestionnaireList,
  setConcernList,
  setReviewerList,
  setAnnotationList,
  setEditingReviewerQuestion,
  setDeletingAnnotationLoader,
  setDeletingShapeId,
} = reviewerSlice.actions;

export const getQuestionnaireList = (tenantId) => async (dispatch) => {
  const [result, error] = await api.getQuestionnaireListRequest(tenantId);

  if (error && error.message) {
    return dispatch(handleError(error));
  }

  if (result && result.totalElements) {
    dispatch(setQuestionnaireList(result.content));
  }
};

export const getOptionList = (tenantId, optionType) => async (dispatch, getState) => {
  const [result, error] = await api.getOptionListRequest(tenantId, optionType);

  if (error.message) {
    return dispatch(handleError(error, `${utils.capitalizeString(optionType)} list error!`));
  }

  if (result?.success === false) {
    return dispatch(handleError(result, result.message));
  }

  // if status success and no options then set default concern list
  if (result?.success && result.data?.foundItemsCount === 0) {
    const creator = getState()?.users?.currentUser?.email;
    return saveDefaultOptionList(dispatch, tenantId, creator, optionType);
  }

  if (optionType === 'concern') {
    dispatch(setConcernList(result?.data?.result));
  } else {
    dispatch(setReviewerList(result?.data?.result));
  }

  return true;
};

async function saveDefaultOptionList(dispatch, tenantId, creator, optionType) {
  const promiseArr = [];
  const optionListDefault = optionType === 'concern' ? concernListDefault : reviewerListDefault;

  for (let i = 0; i < optionListDefault.length; i++) {
    const optionObj = {
      tenantId,
      creator,
      name: optionListDefault[i],
    };

    promiseArr.push(api.addOptionRequest(optionType, optionObj));
  }

  Promise.all(promiseArr)
    .then(async () => {
      dispatch(getOptionList(tenantId, optionType));
    })
    .catch((err) => {
      /* eslint-disable-next-line no-console */
      console.log(err);
    });
}

export const addNewOption = (tenantId, optionType, optionData) => async (dispatch, getState) => {
  const {
    reviewer: { concernList, reviewerList },
  } = getState();
  const [result, error] = await api.addOptionRequest(optionType, optionData);

  if (error.message) {
    return dispatch(handleError(error, `${utils.capitalizeString(optionType)} list error!`));
  }

  if (result?.success) {
    const optionList = optionType === 'concern' ? concernList : reviewerList;
    const updatedOptionList = [...optionList];
    updatedOptionList.push(result.data);

    if (optionType === 'concern') {
      dispatch(setConcernList(updatedOptionList));
    } else {
      dispatch(setReviewerList(updatedOptionList));
    }

    dispatch(showNotification(`${utils.capitalizeString(optionType)} successfully created`, 'success'));
  }

  return true;
};

export const updateOption = (optionType, editingOptionId, updatedOption) => async (dispatch, getState) => {
  const {
    reviewer: { concernList, reviewerList },
  } = getState();

  const [result, error] = await api.updateOptionRequest(optionType, updatedOption);

  if (error.message) {
    return dispatch(handleError(error, `${utils.capitalizeString(optionType)} list error!`));
  }

  if (result?.success) {
    const optionList = optionType === 'concern' ? concernList : reviewerList;
    const updatedOptionList = [...optionList].map((item) => {
      const updatedItem = { ...item };
      if (updatedItem._id === editingOptionId) {
        updatedItem.name = result?.data.name;
      }
      return updatedItem;
    });

    if (optionType === 'concern') {
      dispatch(setConcernList(updatedOptionList));
    } else {
      dispatch(setReviewerList(updatedOptionList));
    }

    dispatch(showNotification(`${utils.capitalizeString(optionType)} successfully updated`, 'success'));
  }

  return true;
};

export const deleteOption = (optionType, optionId) => async (dispatch, getState) => {
  const {
    reviewer: { concernList, reviewerList },
  } = getState();

  const [result, error] = await api.deleteOptionRequest(optionType, optionId);

  if (error?.message) {
    return dispatch(handleError(error));
  }

  if (result?.success) {
    const optionList = optionType === 'concern' ? concernList : reviewerList;
    const updatedOptionList = [...optionList].filter((item) => item._id !== optionId);

    if (optionType === 'concern') {
      dispatch(setConcernList(updatedOptionList));
    } else {
      dispatch(setReviewerList(updatedOptionList));
    }

    dispatch(showNotification(`${utils.capitalizeString(optionType)} successfully deleted`, 'success'));
  }

  return true;
};

export const getQuestionnaireAnnotations = (questionnaireId) => async (dispatch) => {
  const [result, error] = await api.getQuestionnaireAnnotationsRequest(questionnaireId);

  if (error?.message) {
    return dispatch(handleError(error));
  }

  if (result?.success) {
    dispatch(setAnnotationList(result.data.result));
  }

  return true;
};

export const addAnnotation = (annotationData) => async (dispatch, getState) => {
  const {
    reviewer: { annotationList },
  } = getState();

  const [result, error] = await api.addAnnotationRequest(annotationData);
  if (error?.message) {
    return dispatch(handleError(error));
  }

  if (result?.success && result.data) {
    const updatedAnnotationList = [...annotationList];
    updatedAnnotationList.push(result.data);
    dispatch(setAnnotationList(updatedAnnotationList));
    dispatch(showNotification('Annotation successfully added', 'success'));
  }

  return true;
};

export const updateAnnotation = (editingAnnotationId, annotationData) => async (dispatch, getState) => {
  const {
    reviewer: { annotationList },
  } = getState();

  const [result, error] = await api.updateAnnotationRequest(annotationData);

  if (error?.message) {
    return dispatch(handleError(error));
  }

  if (result?.success && !result.data?.foundItemsCount) {
    const updatedAnnotationList = [...annotationList];
    let updatingKey = '';

    updatedAnnotationList.forEach((item, key) => {
      if (item._id === editingAnnotationId) {
        updatingKey = key;
      }
    });

    if (updatingKey || updatingKey >= 0) {
      updatedAnnotationList[updatingKey] = {
        ...annotationList[updatingKey],
        ...result.data,
      };
      dispatch(setAnnotationList(updatedAnnotationList));
      dispatch(showNotification('Annotation successfully updated', 'success'));
    }
  }

  return true;
};

export const removeAnnotation = (questionnaireId, deletingAnnotationId) => async (dispatch) => {
  dispatch(setDeletingAnnotationLoader(deletingAnnotationId));
  const [result, error] = await api.removeAnnotationRequest(deletingAnnotationId);

  if (error?.message) {
    return dispatch(handleError(error));
  }

  if (result?.success) {
    await dispatch(getQuestionnaireAnnotations(questionnaireId));
    dispatch(showNotification('Annotation successfully deleted', 'success'));
  }

  dispatch(setDeletingAnnotationLoader(false));
  return true;
};

export const uploadScreenshot = (imgBase64) => async (dispatch) => {
  const filename = new Date().getTime();
  const imgFile = dataURLtoFile(imgBase64, filename);

  const [result, error] = await api.uploadScreenshotRequest(imgFile);

  if (error?.message) {
    return dispatch(handleError(error));
  }

  if (result?.success && result?.data?.isSuccess) {
    return result.data.fileUrl;
  }

  return true;
};

export const sendDiscussionMessage = (updateAnnotationId, shapeId, message) => async (dispatch, getState) => {
  const {
    users: {
      currentUser: { email: userId, firstName, lastName },
    },
  } = getState();
  const {
    reviewer: { annotationList },
  } = getState();

  const messageObj = {
    userId,
    firstName,
    lastName,
    message,
  };

  const updatedDiscussionData = [messageObj];

  const [result, error] = await api.updateDiscussionData(updateAnnotationId, shapeId, updatedDiscussionData);

  if (error?.message) {
    return dispatch(handleError(error));
  }

  if (result?.success) {
    // update current annotation discussion
    const updatedAnnotationList = annotationList.map((item) => {
      const updatedItem = { ...item };

      // find annotation by id
      if (updatedItem._id === updateAnnotationId && updatedItem?.shapes.length) {
        // update discussion for shape
        const updatedShapeList = updatedItem.shapes.map((shape) => {
          const updatedShape = { ...shape };
          // find shape by id
          if (updatedShape.id === shapeId) {
            // push updated discussion to shape
            updatedShape.discussion = shape.discussion ? [...shape.discussion] : [];
            updatedShape.discussion.push(messageObj);
          }
          return updatedShape;
        });

        updatedItem.shapes = updatedShapeList;
      }

      return updatedItem;
    });

    dispatch(setAnnotationList(updatedAnnotationList));
  }

  return true;
};

export const resetReviewerState = () => async (dispatch) => {
  dispatch(setAnnotationList([]));
};

export default reviewerSlice.reducer;
