import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState, useEffect, useMemo } from 'react';

// redux
import { useDispatch, useSelector } from 'react-redux';

import ProfileViewStyles from '../../../assets/jss/components/ProfileViewStyles';
import { selectCurrentTenant } from '../../Shared/selectors';
import AppKendoTableWrapper from '../../Shared/Table/KendoTableWrapper';
import utils from '../../Shared/Utils/utils';
import { covidVaxStatusMapping } from '../../Users/helpers/mappings';
import { selectUserVaxStatusInfoLoading, selectUserVaxStatusInfo, selectUserDetails } from '../../Users/selectors';
import { getUserVaxStatusHistory } from '../../Users/usersSlice';

// mui
import CovidVaxStatusDialog from '../CovidVaxStatusDialog';
import { covidVaccineHistoryButtons, covidVaccineHistoryColumns } from '../helpers/profileListMapper';

function CovidVaccineHistory() {
  const classes = useStyles();

  const currentTenant = useSelector(selectCurrentTenant);
  const userDetails = useSelector(selectUserDetails);
  const userVaxStatus = useSelector(selectUserVaxStatusInfo);
  const userVaxStatusLoading = useSelector(selectUserVaxStatusInfoLoading);

  // const [selectedRow, setSelectedRow] = useState(undefined);
  const [covidVaxStatusDialogOpen, setCovidVaxStatusDialogOpen] = useState(false);

  const dispatch = useDispatch();

  // handles all table updates from table wrapper
  const handleTableUpdate = () => {
    dispatch(getUserVaxStatusHistory(userDetails.nationalHealthId));
  };

  useEffect(() => {
    dispatch(getUserVaxStatusHistory(userDetails.nationalHealthId));
  }, [currentTenant, userDetails]);

  const columns = useMemo(() => covidVaccineHistoryColumns(), []);
  const buttons = covidVaccineHistoryButtons(setCovidVaxStatusDialogOpen);

  const onCloseDialog = () => {
    setCovidVaxStatusDialogOpen(false);
  };

  return (
    <Grid className={`${classes.contentWrapper} ${classes.withTableButtons}`}>
      <AppKendoTableWrapper
        data={{
          ...userVaxStatus,
          items:
            userVaxStatus &&
            userVaxStatus.data &&
            userVaxStatus.data.map((n) => ({
              ...n,
              vaccinationStatus: covidVaxStatusMapping.find((x) => x.value === n.vaccinationStatus).label,
              dateEntered: utils.formatDateTime(n.dateEntered),
              dateEdited: n.dateEdited ? utils.formatDateTime(n.dateEdited) : '---',
            })),
        }}
        columns={columns}
        loading={userVaxStatusLoading}
        onTableUpdate={handleTableUpdate}
        showButtons
        tableButtons={buttons}
        disablePagination
        noRecordsMessage={'Click "New Vax Status" to add a vaccination'}
      />
      {covidVaxStatusDialogOpen && (
        <CovidVaxStatusDialog closeDialog={onCloseDialog} dialogMode="New" userId={userDetails.nationalHealthId} />
      )}
    </Grid>
  );
}

const useStyles = makeStyles(ProfileViewStyles);

export default CovidVaccineHistory;
