import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState, useEffect } from 'react';

// redux
import { useDispatch, useSelector } from 'react-redux';

import ProfileViewStyles from '../../assets/jss/components/ProfileViewStyles';
import { selectCurrentTenant } from '../Shared/selectors';
import AppKendoTableWrapper from '../Shared/Table/KendoTableWrapper';

import { selectUserDetails } from '../Users/selectors';
import { prescriptionColumns as columns } from './helpers/clinicalInfoMapper';
import { getPrescriptionList } from './slices/prescriptionListSlice';
import { selectPrescriptionList, selectPrescriptionListLoading } from './slices/selectors';

// component imports

// mui

function Prescription() {
  const classes = useStyles();
  const userDetails = useSelector(selectUserDetails);
  const currentTenant = useSelector(selectCurrentTenant);

  const prescriptionList = useSelector(selectPrescriptionList);
  const prescriptionListLoading = useSelector(selectPrescriptionListLoading);

  const [pagedPrescriptionList, setPagedPrescriptionList] = useState([]);

  const dispatch = useDispatch();

  const handleTableUpdate = (page, pageSize) => {
    setPagedPrescriptionList(prescriptionList.data.slice((page + 1) * pageSize - pageSize, (page + 1) * pageSize));
  };
  useEffect(() => {
    dispatch(getPrescriptionList(userDetails.nationalHealthId));
  }, [currentTenant, userDetails]);
  useEffect(() => {
    if (prescriptionList) setPagedPrescriptionList(prescriptionList.data);
  }, [prescriptionList]);

  return (
    <Grid className={`${classes.contentWrapper} ${classes.withTableButtons}`}>
      <AppKendoTableWrapper
        data={{
          ...pagedPrescriptionList,
          items:
            pagedPrescriptionList &&
            pagedPrescriptionList.map((n) => ({
              ...n,
              id: n.id,
              status: n.status,
            })),
          total: prescriptionList && prescriptionList.data.length,
        }}
        initialSort="id"
        loading={prescriptionListLoading}
        initialDir="desc"
        columns={columns()}
        onTableUpdate={handleTableUpdate}
      />
    </Grid>
  );
}

const useStyles = makeStyles(ProfileViewStyles);

export default Prescription;
