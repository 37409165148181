import { medicalCodes } from '../../QuestionBuilder/helpers/formHelper';
import { languageOptions } from '../../QuestionBuilder/helpers/languageOptions';

export const outcomeType = [
  {
    value: 'instructions',
    label: 'Instructions',
  },
  {
    value: 'test',
    label: 'Test',
  },
  {
    value: 'alarm',
    label: 'Alarm',
  },
  {
    value: 'prescription',
    label: 'Prescription',
  },
  {
    value: 'v_consultation',
    label: 'Request for Telehealth Visit',
  },
];

const referralTypes = [
  {
    value: 'asynchronous',
    label: 'Asynchronous',
  },
  {
    value: 'synchronous',
    label: 'Synchronous',
  },
];

const urgencyOptions = [
  {
    value: 'emergent',
    label: 'Emergent',
  },
  {
    value: 'urgent',
    label: 'Urgent',
  },
  {
    value: 'routine',
    label: 'Routine',
  },
  {
    value: 'elective',
    label: 'Elective',
  },
];

const startOn = [
  {
    value: 'today',
    label: 'Day of result',
  },
  {
    value: 'later',
    label: 'Day(s) after result',
  },
  {
    value: 'sequential',
    label: 'After previous alarm cycle',
  },
];

const notificationTypes = [
  {
    value: 'checker',
    label: 'Trigger symptom checker',
  },
  {
    value: 'app',
    label: 'Open an app',
  },
  {
    value: 'email',
    label: 'Send an email',
  },
  {
    value: 'alarm',
    label: 'Set an audible alarm',
  },
  {
    value: 'vibrate',
    label: 'Set a vibration alarm',
  },
];

const alarmFrequencyTypes = ['Hourly', 'Daily', 'Weekly', 'Every X hours', 'Every X days', 'Until further notice'];

const repeatFor = [
  {
    value: 'once',
    label: 'One time only',
  },
  {
    value: 'numberOfTimes',
    label: 'Total number of notifications',
  },
  {
    value: 'numberOfDays',
    label: 'Number of days',
  },
];

const medicationTypes = [
  {
    value: 'tablet',
    label: 'Tablet',
  },
  {
    value: 'capsule',
    label: 'Capsule',
  },
  {
    value: 'liquid',
    label: 'Liquid',
  },
  {
    value: 'eyedrop',
    label: 'Eyedrop',
  },
  {
    value: 'lotion',
    label: 'Lotion',
  },
  {
    value: 'cream',
    label: 'Cream',
  },
  {
    value: 'ointment',
    label: 'Ointment',
  },
];

const doseUnits = [
  {
    value: 'milligrams',
    label: 'mg',
  },
  {
    value: 'milliliters',
    label: 'mL',
  },
  {
    value: 'micrograms',
    label: 'µg',
  },
  {
    value: 'units',
    label: 'units',
  },
];

const otherDurations = [
  {
    value: 'complete',
    label: 'Take as prescribed until complete',
  },
  {
    value: 'asNeeded',
    label: 'Take as needed',
  },
];

const takenBy = [
  {
    value: 'oral',
    label: 'Orally',
  },
  {
    value: 'sublingual',
    label: 'Sublingual',
  },
  {
    value: 'application to skin',
    label: 'Application to skin',
  },
  {
    value: 'rectal',
    label: 'Rectal',
  },
  {
    value: 'eye',
    label: 'Eye',
  },
  {
    value: 'subcutaneous injection',
    label: 'Subcutaneous injection',
  },
  {
    value: 'intramuscular injection',
    label: 'Intramuscular injection',
  },
  {
    value: 'intravenous',
    label: 'Intravenous',
  },
  {
    value: 'other',
    label: 'Other (see patient instructions)',
  },
];

const frequencyType = [
  'Every X hours',
  'Times per day',
  'Every X days',
  'Times per week',
  'Odd dates',
  'Times per month',
];

const durationUnit = [
  {
    value: 'days',
    label: 'Days',
  },
  {
    value: 'weeks',
    label: 'Weeks',
  },
  {
    value: 'months',
    label: 'Months',
  },
];

export const outcomeFormMap = (formData) => [
  {
    type: 'groupNoDropdown',
    label: 'OUTCOME DETAILS',
    name: 'OUTCOME DETAILS',
    fields: [
      {
        type: 'hidden',
        name: '_id',
        label: 'ID',
        required: false,
        width: 6,
      },
      {
        type: 'autocomplete',
        name: 'type',
        label: 'Outcome Type',
        required: true,
        width: 4,
        enum: outcomeType.map((k) => ({
          value: k.value,
          label: k.label,
        })),
        labelField: 'label',
        valueField: 'value',
        disabledItems: ['Referral'],
        disableClear: true,
      },
      {
        type: 'multiSelectTypeAhead',
        name: 'languageChoices',
        label: 'Select Additional Languages',
        required: false,
        width: formData?.languageChoices?.length > 2 ? 12 : 5,
        typeahead: languageOptions,
      },
      {
        type: formData.languageChoices && formData.languageChoices.length ? 'autocomplete' : 'hidden',
        name: 'workingLanguage',
        label: 'Editing Language',
        required: false,
        width: 3,
        enum: formData.languageChoices
          ? formData.languageChoices.map((k) => ({
              value: k.split(' = ')[0],
              label: k.split(' = ')[1],
            }))
          : [],
        labelField: 'label',
        valueField: 'value',
      },
      {
        type: 'imageCrop',
        name: 'image',
        label: 'Featured Image',
        required: false,
        width: 12,
        acceptedFiles: ['image/png', 'image/jpg', 'image/jpeg', 'image/gif'],
      },
      {
        type: 'text',
        name: 'shortName',
        label: 'Short Name',
        required: true,
        width: 12,
        rows: 1,
      },
      {
        type: 'text',
        name: formData.workingLanguage && formData.showTranslation ? `name-${formData.workingLanguage}` : 'name',
        label: 'Name',
        required: true,
        width: 8,
        rows: 2,
      },
      {
        type: formData.languageChoices && formData.languageChoices.length ? 'toggle' : 'hidden',
        name: 'showNameTranslation',
        label: 'Edit Other Language',
        required: false,
        readOnly: !formData.workingLanguage,
        width: 2,
        labelPlacement: 'top',
        linkField: 'showTranslation',
      },
      {
        type: formData.languageChoices && formData.languageChoices.length ? 'toggle' : 'hidden',
        name: `nameValid-${formData.workingLanguage}`,
        label: 'Translation Validated by Native Speaker',
        required: false,
        readOnly: !formData.showTranslation,
        width: 2,
        labelPlacement: 'top',
      },
      {
        type: 'text',
        name: formData.workingLanguage && formData.showTranslation ? `title-${formData.workingLanguage}` : 'title',
        label: 'Title',
        required: true,
        width: 8,
        rows: 2,
      },
      {
        type: formData.languageChoices && formData.languageChoices.length ? 'toggle' : 'hidden',
        name: 'showTitleTranslation',
        label: 'Edit Other Language',
        required: false,
        readOnly: !formData.workingLanguage,
        width: 2,
        labelPlacement: 'top',
        linkField: 'showTranslation',
      },
      {
        type: formData.languageChoices && formData.languageChoices.length ? 'toggle' : 'hidden',
        name: `titleValid-${formData.workingLanguage}`,
        label: 'Translation Validated by Native Speaker',
        required: false,
        readOnly: !formData.showTranslation,
        width: 2,
        labelPlacement: 'top',
      },
      {
        type: 'html',
        name:
          formData.workingLanguage && formData.showTranslation
            ? `description-${formData.workingLanguage}`
            : 'description',
        label: 'Description',
        required: false,
        width: 8,
        rows: 2,
        checkScriptExecution: true,
      },
      {
        type: formData.languageChoices && formData.languageChoices.length ? 'toggle' : 'hidden',
        name: 'showDescriptionTranslation',
        label: 'Edit Other Language',
        required: false,
        readOnly: !formData.workingLanguage,
        width: 2,
        shared: 'translationToggle',
        labelPlacement: 'top',
        linkField: 'showTranslation',
      },
      {
        type: formData.languageChoices && formData.languageChoices.length ? 'toggle' : 'hidden',
        name: `descriptionValid-${formData.workingLanguage}`,
        label: 'Translation Validated by Native Speaker',
        required: false,
        readOnly: !formData.showTranslation,
        width: 2,
        labelPlacement: 'top',
      },
    ],
  },
  {
    type: 'group',
    label: 'REPORTING',
    name: 'REPORTING',
    fields: [
      {
        type: 'text',
        name: 'reporterTarget',
        label: 'Target',
        required: false,
        width: 12,
      },
      {
        type: 'text',
        name: 'reporterFormat',
        label: 'Text',
        required: false,
        width: 12,
      },
    ],
  },
  {
    type: 'group',
    label: 'OUTCOME MEDICAL CODE',
    name: 'OUTCOME MEDICAL CODE',
    fields: medicalCodes(formData),
  },
  {
    type: formData.type === 'test' ? 'group' : 'hidden',
    label: 'LAB TEST INFORMATION',
    name: 'LAB TEST INFORMATION',
    fields: [
      {
        type: 'dynamicList',
        name: 'testList',
        label: 'Test',
        required: true,
        width: 12,
        buttonType: 'text',
        buttonText: 'Add new test',
        tooltip: 'Add new test',
        fieldset: 'tests',
        fields: [
          {
            type: 'header',
            label: 'New Test',
          },
        ],
      },
    ],
  },

  {
    type: formData.type === 'prescription' ? 'group' : 'hidden',
    label: 'PRESCRIPTION INFORMATION',
    name: 'PRESCRIPTION SPECIFICS',
    fields: [
      {
        type: 'dynamicList',
        name: 'medicationList',
        label: 'Prescription',
        required: true,
        width: 12,
        buttonType: 'text',
        buttonText: 'Add new prescription',
        tooltip: 'Add new prescription',
        fieldset: 'prescriptions',
        expandable: true,
        titleField: 'medicationName',
        fields: [
          {
            type: 'header',
            label: 'New Prescription',
          },
        ],
      },
    ],
  },
  {
    type: formData.type === 'v_consultation' ? 'group' : 'hidden',
    label: 'TELEHEALTH REQUEST INFORMATION',
    name: 'TELEHEALTH REQUEST INFORMATION',
    fields: [
      {
        type: 'small-text',
        name: 'condition',
        label: 'Condition / Reason for Visit',
        required: true,
        width: 12,
      },
      {
        type: 'small-text',
        name: 'speciality',
        label: 'Medical Specialty of Requested Provider',
        required: false,
        width: 12,
      },
      {
        type: 'autocomplete',
        name: 'referralType',
        label: 'Referral Type',
        required: true,
        width: 12,
        enum: referralTypes,
        labelField: 'label',
        valueField: 'value',
      },
      {
        type: 'autocomplete',
        name: 'urgency',
        label: 'Urgency',
        required: false,
        width: 12,
        enum: urgencyOptions,
        labelField: 'label',
        valueField: 'value',
      },
    ],
  },
  {
    type: formData.type === 'alarm' ? 'group' : 'hidden',
    label: 'ALARM INFORMATION',
    name: 'ALARM INFORMATION',
    fields: [
      {
        type: 'large-text',
        name: 'condition',
        label: 'Summary of Instructions',
        required: true,
        width: 12,
      },
      {
        type: 'dynamicList',
        name: 'options',
        label: 'Notification',
        required: true,
        width: 12,
        buttonType: 'text',
        buttonText: 'Add new notification',
        tooltip: 'Add new notification',
        fieldset: 'notifications',
        fields: [
          {
            type: 'header',
            label: 'New Notification',
          },
        ],
      },
    ],
  },
];

export const outcomeChildren = {
  prescriptions: (values) => [
    {
      type: 'small-text',
      name: 'condition',
      label: 'Condition/Diagnosis',
      required: true,
      width: 12,
    },
    {
      type: 'small-text',
      name: 'medicationName',
      label: 'Medication Name',
      required: true,
      width: 12,
    },
    {
      type: 'spacer',
    },
    {
      type: 'header',
      label: 'Dosage and Instructions',
    },
    {
      type: 'numeric',
      name: 'minQuantity',
      label: 'Min Qty',
      required: false,
      min: 0,
      max: values.maxQuantity || null,
      width: 2,
    },
    {
      type: 'numeric',
      name: 'maxQuantity',
      label: 'Max Qty',
      required: false,
      min: values.minQuantity || 0,
      width: 2,
    },
    {
      type: 'autocomplete',
      name: 'medicationType',
      label: 'Medication type',
      required: false,
      width: 8,
      enum: medicationTypes,
      labelField: 'label',
      valueField: 'value',
    },
    {
      type: 'numeric',
      name: 'doseValue',
      label: 'Dose amount',
      min: 0,
      required: false,
      width: 4,
    },
    {
      type: 'autocomplete',
      name: 'doseUnit',
      label: 'Dose unit',
      required: false,
      width: 8,
      enum: doseUnits,
      labelField: 'label',
      valueField: 'value',
    },
    {
      name: 'takenBy',
      label: 'Taken by',
      required: false,
      width: 12,
      enum: takenBy,
      labelField: 'label',
      valueField: 'value',
    },
    {
      type: 'numeric',
      name: 'frequencyValue',
      label: 'Frequency Amount',
      min: 0,
      required: false,
      width: 4,
    },
    {
      type: 'dropdown',
      name: 'frequencyType',
      label: 'Frequency Type',
      enum: frequencyType,
      required: false,
      width: 8,
    },
    {
      type: 'numeric',
      name: 'durationValue',
      label: 'Duration amount',
      min: 0,
      required: false,
      width: 4,
      readOnly: !!(values.otherDurations === 'complete' || values.otherDurations === 'asNeeded'),
    },
    {
      type: 'autocomplete',
      name: 'durationUnit',
      label: 'Duration Unit',
      enum: durationUnit,
      required: false,
      width: 3,
      labelField: 'label',
      valueField: 'value',
      disabled: !!(values.otherDurations === 'complete' || values.otherDurations === 'asNeeded'),
    },
    {
      type: 'autocomplete',
      name: 'otherDurations',
      label: 'Other Duration Options',
      enum: otherDurations,
      required: false,
      width: 5,
      labelField: 'label',
      valueField: 'value',
    },
    {
      type: 'numeric',
      name: 'refills',
      label: 'Refills',
      min: -1,
      required: false,
      width: 4,
    },
    {
      type: 'large-text',
      name: 'patientInstructions',
      label: 'Patient instructions',
      required: false,
      width: 12,
    },
    {
      type: 'large-text',
      name: 'pharmacistInstructions',
      label: 'Pharmacist instructions',
      required: false,
      width: 12,
    },
  ],

  tests: (values, labTestOrderables) => [
    {
      type: 'autocomplete',
      name: 'test',
      label: 'Lab Test',
      required: true,
      width: 12,
      enum:
        (labTestOrderables &&
          labTestOrderables.map((t) => {
            if (t.vendorTestPanelId) {
              return {
                label: `${t.vendorName} / ${t.longName}`,
                test: {
                  safeTestId: null,
                  vendorTestId: t.vendorTestPanelId,
                  vendorKey: t.vendorKey,
                  vendorTestName: t.testFormatName,
                  orderableTestId: t.labTestPanelId,
                },
              };
            }
            return {
              label: `${t.vendorName} / ${t.longName}`,
              test: {
                safeTestId: t.safeTestId,
                vendorTestId: t.vendorTestId,
                vendorKey: t.vendorKey,
                vendorTestName: t.testFormatName,
                orderableTestId: t.labTestOrderableId,
              },
            };
          })) ||
        [],
      disabled: !labTestOrderables || !labTestOrderables.length,
      description:
        (!labTestOrderables || !labTestOrderables.length) && 'No orderable tests or panels selected for tenant.',
      labelField: 'label',
      valueField: 'test',
      matchField: 'orderableTestId',
    },
  ],
  notifications: (values) => [
    {
      type: 'autocomplete',
      name: 'notificationType',
      label: 'Notification Type',
      enum: notificationTypes,
      required: true,
      width: 12,
      labelField: 'label',
      valueField: 'value',
    },
    {
      type: values?.notificationType === 'alarm' || values?.notificationType === 'vibrate' ? 'toggle' : 'hidden',
      name: 'override',
      label: 'Override device settings?',
      required: false,
      width: 12,
      labelPlacement: 'end',
    },
    {
      type: 'autocomplete',
      name: 'startOn',
      label: 'Start',
      enum: startOn,
      required: true,
      width: 8,
      labelField: 'label',
      valueField: 'value',
    },
    {
      type: values?.startOn === 'later' ? 'numeric' : 'hidden',
      name: 'startDelay',
      label: 'Number of days after result',
      min: 1,
      required: values?.startOn === 'later',
      width: 4,
    },
    {
      type: 'dropdown',
      name: 'frequencyType',
      label: 'Frequency',
      enum: alarmFrequencyTypes,
      required: true,
      width: 8,
    },
    {
      type:
        values?.frequencyType === 'Every X hours' || values?.frequencyType === 'Every X days' ? 'numeric' : 'hidden',
      name: 'frequencyAmount',
      label: 'Frequency Amount',
      min: 1,
      required: values?.frequencyType === 'Every X hours' || values?.frequencyType === 'Every X days',
      width: 4,
    },
    {
      type: 'autocomplete',
      name: 'repeatFor',
      label: 'Repeat for',
      enum: repeatFor,
      required: false,
      width: 8,
      labelField: 'label',
      valueField: 'value',
    },
    {
      type: values?.repeatFor === 'numberOfTimes' || values?.repeatFor === 'numberOfDays' ? 'numeric' : 'hidden',
      name: 'repeatNumber',
      label: values?.repeatFor === 'numberOfDays' ? 'Number of days' : 'Total number of notifications',
      min: 1,
      required: values?.repeatFor === 'numberOfTimes' || values?.repeatFor === 'numberOfDays',
      width: 4,
    },
  ],
};
