import React from 'react';
import ContentLoader from 'react-content-loader';

function ExpansionSkeletonLoading(props) {
  return (
    <ContentLoader
      viewBox="0 0 400 160"
      // height={160}
      // width={400}
      speed={0.8}
      backgroundColor="#ecebeb"
      foregroundColor="#f3f3f3"
      {...props}
    >
      <rect x="0" y="8" rx="5" ry="5" width="75%" height="15" />
      <rect x="0" y="25" rx="5" ry="5" width="75%" height="15" />
      <rect x="0" y="42" rx="5" ry="5" width="75%" height="15" />
      <rect x="0" y="59" rx="5" ry="5" width="75%" height="15" />
      <rect x="0" y="76" rx="5" ry="5" width="75%" height="15" />
      <rect x="0" y="93" rx="5" ry="5" width="75%" height="15" />
      <rect x="330" y="8" rx="5" ry="5" width="17%" height="11" />
      <rect x="330" y="22" rx="5" ry="5" width="17%" height="11" />
      <rect x="330" y="36" rx="5" ry="5" width="17%" height="11" />
    </ContentLoader>
  );
}

export default ExpansionSkeletonLoading;
