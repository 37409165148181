import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import DropdownField from '../../Shared/Forms/KendoFields/DropdownField';
import TextFieldInput from '../../Shared/Forms/KendoFields/TextFieldInput';
import { createVerbiage, getVerbiage, getVerbiageDefinitions } from '../settingsSlice';

function VerbiageCreateDialog(props) {
  const { open, handleClose, currentTenant, language } = props;
  const splitUrl = window.location.href.split('/');
  const type = splitUrl[splitUrl.length - 1] === 'screens' ? 'ORDINAL' : 'LEGAL_NOTICE';

  const dispatch = useDispatch();

  const verbiageDefinition = {
    agreement: true,
    confirmationConsentBtnLabel: '',
    document: type === 'LEGAL_NOTICE',
    header: true,
    key: '',
    needConsent: true,
    optional: true,
    subtype: '',
    title: '',
    value: '',
    language,
    type,
  };
  const subTypeEnums = ['Static', 'HomeScreenSnackBar'];

  const field = [
    {
      type: 'text',
      name: 'key',
      label: 'Verbiage Key',
      width: 4,
      required: true,
    },
    {
      type: 'dropdown',
      name: 'subType',
      label: 'Sub Type',
      width: 4,
      required: true,
      enum: subTypeEnums,
    },
    {
      type: 'text',
      name: 'title',
      label: 'Title',
      width: 4,
      required: true,
    },
  ];

  const [verbiageDef, setverbiageDef] = useState(verbiageDefinition);

  useEffect(() => {
    setverbiageDef({ ...verbiageDef, language, type });
  }, [type, language]);

  const handleChangeWithParams = useCallback(
    (name, value) => {
      if (name === 'subType') {
        setverbiageDef({ ...verbiageDef, subtype: value });
      }
      if (name.target && name.target.value) {
        if (name.target.name === 'key') {
          setverbiageDef({ ...verbiageDef, key: name.target.value });
        } else if (name.target.name === 'title') {
          setverbiageDef({ ...verbiageDef, title: name.target.value });
        }
      }
    },
    [verbiageDef]
  );

  // handles creation of new system message
  const handleCreate = async () => {
    if (verbiageDef.title && verbiageDef.key) {
      await dispatch(createVerbiage(currentTenant.accountId, currentTenant.id, verbiageDef));
      dispatch(getVerbiage(currentTenant.accountId, currentTenant.id, language));
      dispatch(getVerbiageDefinitions(currentTenant.accountId, currentTenant.id));
      handleCancel();
    }
  };

  const handleCancel = () => {
    setverbiageDef({
      ...verbiageDef,
      key: '',
      subtype: '',
      title: '',
    });
    handleClose();
  };

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose} aria-labelledby="max-width-dialog-title">
      <DialogTitle id="max-width-dialog-title">Add New Verbiage Definition</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Box>
              <div>
                <TextFieldInput
                  field={field[0]}
                  value={verbiageDef ? verbiageDef.key : ''}
                  handleChange={handleChangeWithParams}
                />
              </div>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box>
              <div>
                <DropdownField
                  field={field[1]}
                  value={verbiageDef ? verbiageDef.subtype : ''}
                  handleChangeWithParams={handleChangeWithParams}
                />
              </div>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box>
              <div>
                <TextFieldInput
                  field={field[2]}
                  value={verbiageDef ? verbiageDef.title : ''}
                  handleChange={handleChangeWithParams}
                />
              </div>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleCreate} color="primary">
          Create
        </Button>
        <Button onClick={handleCancel} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default VerbiageCreateDialog;
