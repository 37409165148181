/* eslint-disable react/no-unstable-nested-components */
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import RemoveIcon from '@material-ui/icons/Remove';
import { Button } from '@progress/kendo-react-buttons';
import { DateInput, DateRangePicker as KendoDateRangePicker } from '@progress/kendo-react-dateinputs';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import DateRangeStyles from '../../../../assets/jss/components/DateRangeStyles';

// Available time periods
const timePeriods = [
  {
    id: 'today',
    text: 'Today',
  },
  {
    id: 'week',
    text: 'Week',
  },
  {
    id: '2weeks',
    text: '2 Weeks',
    default: true,
  },
  {
    id: 'month',
    text: 'Month',
  },
  {
    id: 'year',
    text: 'Year to date',
  },
];

const dateInputSettings = {
  label: null,
};

/**
 * Return start,end dates structure for Kendo date range
 * @param timePeriod
 * @returns {{start: Date, end: Date}}
 */
const calculateDateRangeValue = (timePeriod) => {
  const endDate = moment().toDate();

  let startDate = null;
  // add 1 day for including current date during subtract
  const currentDate = moment().add(1, 'day').startOf('day');

  switch (timePeriod) {
    case 'year':
      startDate = currentDate.startOf('year').toDate();
      break;
    case 'month':
      startDate = currentDate.subtract(1, 'month').startOf('day').toDate();
      break;
    case 'week':
      startDate = currentDate.subtract(1, 'week').startOf('day').toDate();
      break;
    case '2weeks':
      startDate = currentDate.subtract(2, 'week').startOf('day').toDate();
      break;
    case 'today':
      startDate = moment().startOf('day').toDate();
      break;
    default:
      startDate = currentDate.subtract(14, 'day').startOf('day').toDate();
  }

  return {
    start: startDate,
    end: endDate,
  };
};

/**
 * Date range selector
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function DateRangeSelector(props) {
  const {
    maxEndDate,
    disabled,
    showTitle = false,
    inputWidth = 220,
    inputLabel,
    dateButtonsPos = 'start',
    direction = 'column',
    onSetDateRange,
    onDateRangeChange,
  } = props;

  const classes = useStyles();

  const DEFAULT_RANGE = timePeriods.find((i) => i.default === true).id;

  const [selectedDateRangeButton, setSelectedDateRangeButton] = useState(DEFAULT_RANGE);
  const [timePeriod, setTimePeriod] = useState(timePeriods.find((item) => item.id === DEFAULT_RANGE));
  const [customDateRange, setCustomDateRange] = useState('');

  const dateInputDefaultValue = calculateDateRangeValue(timePeriod);
  const [dateRangeValue, setDateRangeValue] = useState(dateInputDefaultValue);

  // Exec date range callback
  useEffect(() => {
    if (onDateRangeChange && dateRangeValue) {
      onDateRangeChange(dateRangeValue);
    }
  }, [dateRangeValue]);

  useEffect(() => {
    if (onSetDateRange) {
      onSetDateRange(timePeriods.find((item) => item.id === DEFAULT_RANGE).text.toLowerCase());
    }
  }, []);

  /**
   * Handle time period selection
   * @param {string} period
   */
  const handleChangeTimePeriod = (period) => {
    setTimePeriod(period);
    setSelectedDateRangeButton(period.id);
    if (onSetDateRange) onSetDateRange(period.text.toLowerCase());

    if (period.id) {
      setCustomDateRange('');
      setDateRangeValue(calculateDateRangeValue(period.id));
    }
  };

  const handleDateRangeChange = (event) => {
    setDateRangeValue({
      start: event.value.start || null,
      end:
        event.value.end && dateButtonsPos === 'start'
          ? moment(event.value.end).endOf('date').toDate()
          : event.value.end,
    });

    if (event.value.start && event.value.end) {
      const dateRange = `${moment(event.value.start).format('ll')} - ${moment(event.value.end).format('ll')}`;

      if (onSetDateRange) {
        onSetDateRange(dateRange);
      }
      setCustomDateRange(dateRange);
    }
    setSelectedDateRangeButton(''); // Uncheck the date range buttons
  };

  return (
    <Grid container direction="column">
      {showTitle && (
        <Grid container direction={'row'}>
          <Typography variant={'h6'} className={classes.dateRangeTitle}>
            Current View:
          </Typography>
          <Typography variant={'caption'} className={classes.dateRangeValue}>
            {customDateRange || timePeriod.text}
          </Typography>
        </Grid>
      )}

      <Grid container direction={direction} style={{ marginTop: 20 }} className={classes.buttonPannel}>
        {dateButtonsPos === 'start' && (
          <DateRangeButtons
            selectedDateRangeButton={selectedDateRangeButton}
            handleChangeTimePeriod={handleChangeTimePeriod}
          />
        )}

        <Grid style={{ marginTop: '-7px' }}>
          <KendoDateRangePicker
            min={new Date(2020, 3, 1)}
            max={maxEndDate ? new Date(maxEndDate) : new Date(2022, 12, 31)}
            onChange={handleDateRangeChange}
            value={dateRangeValue}
            startDateInput={(p) => (
              <>
                <DateInput {...p} width={inputWidth} label={inputLabel?.startLabel} format={'MMM dd, yyyy'} />
                <RemoveIcon style={{ paddingTop: 15 }} />
              </>
            )}
            endDateInput={(p) => (
              <DateInput {...p} width={inputWidth} label={inputLabel?.endLabel} format={'MMM dd, yyyy'} />
            )}
            defaultValue={dateInputDefaultValue}
            startDateInputSettings={dateInputSettings}
            endDateInputSettings={dateInputSettings}
            disabled={disabled}
            className={classes.dateLabel}
          />
        </Grid>

        {dateButtonsPos === 'end' && (
          <Grid style={{ marginTop: direction === 'column' ? 15 : 0 }}>
            <DateRangeButtons
              selectedDateRangeButton={selectedDateRangeButton}
              handleChangeTimePeriod={handleChangeTimePeriod}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

export function DateRangeButtons(props) {
  const { selectedDateRangeButton, handleChangeTimePeriod } = props;

  const classes = useStyles();

  return (
    <Grid>
      {timePeriods.map((period) => (
        <Button
          key={period.id}
          className={selectedDateRangeButton === period.id ? classes.buttonSelected : classes.button}
          onClick={() => {
            handleChangeTimePeriod(period);
          }}
        >
          {period.text}
        </Button>
      ))}
    </Grid>
  );
}

const useStyles = makeStyles(DateRangeStyles);

DateRangeSelector.propTypes = {
  onDateRangeChange: PropTypes.func,
  onSetDateRange: PropTypes.func,
  dateRange: PropTypes.arrayOf(
    PropTypes.shape({
      start: PropTypes.instanceOf(Date),
      end: PropTypes.instanceOf(Date),
    })
  ),
  disabled: PropTypes.bool,
  inputLabel: PropTypes.arrayOf(
    PropTypes.shape({
      startLabel: PropTypes.string,
      endLabel: PropTypes.string,
    })
  ),
  dateButtonsPos: PropTypes.oneOf(['start', 'end']),
  direction: PropTypes.oneOf(['row', 'column']),
  showTitle: PropTypes.bool,
  inputWidth: PropTypes.number,
};

export default DateRangeSelector;
