import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import ProfileViewStyles from '../../../../assets/jss/components/ProfileViewStyles';
import utils from '../../Utils/utils';

const PhoneInputField = (props) => {
  const {
    type,
    classes,
    column,
    i,
    countryCode,
    number,
    handleChange,
    handleChangeWithParams,
    handleBlur,
    onKeyPress,
    field,
    selectedLanguage,
  } = props;
  const regions = ['america', 'europe', 'asia', 'oceania', 'africa'];
  const [phoneNumber, setPhoneNumber] = useState(number || '');
  const [code, setCode] = useState(countryCode || '');

  useEffect(()=>{
    setCode(countryCode || '')
  }, [countryCode])

  const getPhoneInputValue = (num) => {
    if (num) {
      if (code) return code + num;
      return `+1${num}`;
    }
    return null;
  };

  const handlePhoneChange = (value, country) => {
    const number = country.dialCode ? value.slice(country.dialCode.length) : value;
    // removes country code from number if it exists
    setPhoneNumber(number);
    // if no country selected sets to empty string
    setCode(country.dialCode || '');

    if (handleChangeWithParams) {
      handleChangeWithParams(field.name, number);
      if (field.code) {
        handleChangeWithParams(field.code, country.dialCode);
      }
    } else {
      handleChange(value, country, column[i]);
    }
  };

  let phoneInputField;
  if (type === 'display') {
    phoneInputField = (
      <PhoneInput
        inputClass={classes.phoneInputClass}
        inputStyle={{
          width: 'inherit',
          border: 'none',
          borderBottom: 'none',
          padding: '0',
          borderRadius: '0',
          fontWeight: 'lighter',
          fontSize: '15px',
          color: '#000000de',
          backgroundColor: 'inherit',
        }}
        buttonStyle={{ opacity: '0' }}
        value={getPhoneInputValue(number)}
        disabled
        disableDropdown
        placeholder="Unknown"
        specialLabel=""
        country="us"
      />
    );
  } else if (type === 'mobileDisplay') {
    phoneInputField = (
      <PhoneInput
        inputClass={classes.phoneInputClass}
        inputStyle={
          countryCode + number === ''
            ? {
                width: '70px',
                border: 'none',
                borderBottom: 'none',
                padding: '0',
                borderRadius: '0',
                fontWeight: 'lighter',
                fontSize: '15px',
                color: '#000000de',
              }
            : {
                width: 'inherit',
                border: 'none',
                borderBottom: 'none',
                padding: '0',
                borderRadius: '0',
                fontWeight: 'lighter',
                fontSize: '15px',
                color: '#000000de',
              }
        }
        buttonStyle={{ opacity: '0' }}
        value={number ? countryCode + number : null}
        disabled
        disableDropdown
        placeholder="Unknown"
        specialLabel=""
        country="us"
      />
    );
  } else if (type === 'editing') {
    let fieldLabel;
    let questionDescription;
    if (field) {
      fieldLabel =
        selectedLanguage && field.name && field.name.translations
          ? field.name.translations[selectedLanguage]
          : field.label || field.name;
      questionDescription =
        selectedLanguage && field.description && field.description.translations
          ? field.description.translations[selectedLanguage]
          : field.description;
    }

    phoneInputField = (
      <div>
        {field && (
          <Typography
            variant="h4"
            style={{
              fontSize: '12px',
              fontWeight: 'normal',
              paddingBottom: '5px',
              marginBottom: field.questionPreview ? '10px' : '0',
              opacity: '0.8',
            }}
          >
            {field.questionPreview ? utils.renderHTMLTags(questionDescription) : fieldLabel}
            {field.required && '*'}
          </Typography>
        )}
        <PhoneInput
          regions={regions}
          onChange={(value, country) => handlePhoneChange(value, country)}
          enableSearch
          enableLongNumbers
          inputClass={classes.phoneInputClass}
          inputProps={{
            name: field && field.name,
            required: field && field.required,
            autoFocus: !field && true,
          }}
          inputStyle={{
            width: 'inherit',
            border: 'none',
            borderBottom: 'solid 1px',
            borderBottomColor: field && field.required && !phoneNumber ? 'red' : '#ececec',
            padding: '0 14px 0 58px',
            borderRadius: '0',
          }}
          onBlur={(e) => handleBlur && handleBlur(e, column[i - 1], column[i], 'number')}
          specialLabel=""
          value={getPhoneInputValue(phoneNumber)}
          onKeyPress={onKeyPress}
          country="us"
        />
      </div>
    );
  } else {
    phoneInputField = (
      <PhoneInput
        inputClass={classes.phoneInputClass}
        containerClass={classes.editHover}
        inputStyle={{
          width: 'inherit',
          border: 'none',
          borderBottom: 'none',
          padding: '0 14px 0 45px',
          borderRadius: '0',
        }}
        buttonStyle={!number ? { opacity: '20%' } : { opacity: '100%' }}
        value={getPhoneInputValue(number)}
        disabled
        disableDropdown
        specialLabel=""
        placeholder="---"
        country="us"
      />
    );
  }

  return phoneInputField;
};

export default withStyles(ProfileViewStyles)(PhoneInputField);
