import { Box, Button, Divider, Typography } from '@material-ui/core';
import { Error } from '@progress/kendo-react-labels';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { showNotification } from '../../Notifications/notificationSlice';
import DropdownField from '../../Shared/Forms/KendoFields/DropdownField';
import TextFieldInput from '../../Shared/Forms/KendoFields/TextFieldInput';
import {
  rxOrderStatusEnum,
  rxOrderStatusDropdownField,
  rxOrderIdField,
  pharmacyNameField,
  phoneField,
  line1Field,
  cityField,
  stateField,
  zipField,
  countryField,
  preferredField,
} from '../helper/rxOrderConstants';
import { OverlayLoading } from '../helper/SimulatorLoading';
import { resetRxOrderLoading, startSimulateRxOrder } from '../slice/fvSimulatorSlice';
import { selectRxOrderLoading } from '../slice/selector';
import ToggleInput from '../../Shared/Forms/KendoFields/ToggleField';

function RxOrderSimulator() {
  const dispatch = useDispatch();

  const params = useParams();
  const { userId } = params;

  const rxOrderLoading = useSelector(selectRxOrderLoading);

  const [rxOrderId, setRxOrderId] = useState('');
  const [rxOrderStatus, setRxOrderStatus] = useState('');
  const [pharmaName, setPharmaName] = useState('Downtown Pharmacy');
  const [phone, setPhone] = useState('626-338-3964');
  const [line1, setLine1] = useState('3136 Middleville Road');
  const [city, setCity] = useState('Baldwin Park');
  const [stateAddress, setStateAddress] = useState('CA');
  const [zip, setZip] = useState('91706');
  const [country, setCountry] = useState('US');
  const [preferred, setPreferred] = useState(false);

  const [error, setError] = useState({});

  const accountId = localStorage.getItem('accountId');
  const tenantId = localStorage.getItem('tenantId');

  const handleStatusChange = (fieldName, fieldVal) => {
    /* Clear Error */
    let clearedErr = { ...error };
    delete clearedErr['rxOrderStatus'];
    setError({ ...clearedErr });

    setRxOrderStatus(fieldVal);
  };

  const clearTextInputErr = (inputName) => {
    /* Clear Error */
    let clearedErr = { ...error };
    delete clearedErr[inputName];
    setError({ ...clearedErr });
  };

  const handleIdChange = (e) => {
    setRxOrderId(e.target.value);
  };

  const handlePreferredChange = (fieldName, fieldVal) => {
    console.log(fieldName, fieldVal)
    setPreferred(fieldVal)
  }

  const handleTextInputChange = (e) => {
    clearTextInputErr(e.target.name);
    switch (e.target.name) {
      case 'pharmaName':
        setPharmaName(e.target.value);
        break;
      case 'phone':
        setPhone(e.target.value);
        break;
      case 'line1':
        setLine1(e.target.value);
        break;
      case 'city':
        setCity(e.target.value);
        break;
      case 'stateAddress':
        setStateAddress(e.target.value);
        break;
      case 'zip':
        setZip(e.target.value);
        break;
      case 'country':
        setCountry(e.target.value);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (rxOrderLoading === 'fulfilled' || rxOrderLoading === 'rejected') {
      dispatch(resetRxOrderLoading());
    }
  }, [rxOrderLoading]);

  /* SUBMIT SIMULATION REQUEST */
  const handleSubmit = async (e) => {
    e.preventDefault();
    const hasError = validateInputs();

    if (hasError) {
      return null;
    }

    const rxOrderIdPayload = rxOrderId.trim();

    const payload = {
      status: rxOrderStatus,

      payloadFields: {
        accountID: accountId,
        tenantID: tenantId,
        // tenantID: 'healthcheck-consumer', // for local DEV only
        user: userId,
        // Only include ID property if NOT blank
        ...(rxOrderIdPayload && rxOrderIdPayload !== '' && { id: rxOrderIdPayload }),
        pharmacy: {
          preferred,

          // Send Address Details ONLY if it is NOT preferred
          ...(!preferred && {
            name: pharmaName,
            address: {
              phone,
              line1,
              city,
              state: stateAddress,
              zip,
              country,
            },
          })
        },
      },
    };

    try {
      const result = await dispatch(startSimulateRxOrder(payload));

      if (result.error) {
        dispatch(showNotification(`Failed to execute simulator <br />Error: ${result.error.message}`, 'error'));
        return;
      }

      if (result.payload[1]?.status >= 400) {
        dispatch(showNotification(`${result?.payload[1]?.message}`, 'error'));
        return;
      }

      if (result.payload[0]?.success) {
        dispatch(showNotification(`${result?.payload[0]?.message} <br /> ${result?.payload[0]?.data} `, 'success'));
        return;
      }
    } catch (error) {
      // eslint-disable-next-line
      console.log(error);
      dispatch(showNotification(`Failed to execute simulator <br />Error: ${error.message}`, 'error'));
    }
  };

  const validateInputs = () => {
    let hasError = false;
    if ((rxOrderStatus === undefined || rxOrderStatus === '' || !rxOrderStatusEnum.includes(rxOrderStatus)) && !preferred) {
      setError((err) => ({
        ...err,
        rxOrderStatus: {
          message: 'RxOrder Status field is required',
        },
      }));
      hasError = true;
    }
    if ((pharmaName === undefined || pharmaName.trim() === '') && !preferred) {
      setError((err) => ({
        ...err,
        pharmaName: {
          message: 'Pharmacy Name is required',
        },
      }));
      hasError = true;
    }
    if ((phone === undefined || phone.trim() === '') && !preferred) {
      setError((err) => ({
        ...err,
        phone: {
          message: 'Phone Number is required',
        },
      }));
      hasError = true;
    }
    if ((line1 === undefined || line1.trim() === '') && !preferred) {
      setError((err) => ({
        ...err,
        line1: {
          message: 'Address Line 1 is required',
        },
      }));
      hasError = true;
    }
    if ((city === undefined || city.trim() === '') && !preferred) {
      setError((err) => ({
        ...err,
        city: {
          message: 'City is required',
        },
      }));
      hasError = true;
    }
    if ((stateAddress === undefined || stateAddress.trim() === '') && !preferred) {
      setError((err) => ({
        ...err,
        stateAddress: {
          message: 'State is required',
        },
      }));
      hasError = true;
    }
    if ((zip === undefined || zip.trim() === '') && !preferred) {
      setError((err) => ({
        ...err,
        zip: {
          message: 'Zip code is required',
        },
      }));
      hasError = true;
    }
    if ((country === undefined || country.trim() === '') && !preferred) {
      setError((err) => ({
        ...err,
        country: {
          message: 'Country is required',
        },
      }));
      hasError = true;
    }

    return hasError;
  };

  return (
    <Box width={'100%'} height={'100%'}>
      {rxOrderLoading === 'pending' && <OverlayLoading />}
      <Box
        display={'grid'}
        gridTemplateColumns={'repeat(2, minmax(0, 1fr))'}
        gridRowGap={'30px'}
        gridColumnGap={'100px'}
        marginTop={'25px'}
        marginBottom={'25px'}
      >
        <Box textAlign={'center'}>
          <DropdownField
            field={rxOrderStatusDropdownField}
            value={rxOrderStatus}
            handleChangeWithParams={handleStatusChange}
          />
          {error?.rxOrderStatus && <Error>{error?.rxOrderStatus?.message}</Error>}
        </Box>
        <Box textAlign={'center'}>
          <TextFieldInput field={rxOrderIdField} value={rxOrderId} handleChange={handleIdChange} />
          {error?.rxOrderId && <Error>Please enter proper kit ID</Error>}
        </Box>

        {/* PHARMACY inputs */}
        <Divider />
        <Box
          gridColumn={'1 / span 2'}
          display={'flex'}
        >
          <Typography variant='h6'>PHARMACY</Typography>
        </Box>
        <Box
          display={'flex'}
          alignItems={'center'}
          marginTop={'5px'}
          marginBottom={'5px'}
          gridColumn={'1 / span 2'}
        >
          <Box marginRight={'20px'}>
            <ToggleInput
              field={preferredField}
              value={preferred}
              handleChangeWithParams={handlePreferredChange}
            />
          </Box>
          <Box display={'inline-block'} >
            <Typography variant="subtitle2">
              If toggle is enabled then the Pharmacy Fields below will be disabled
            </Typography>
          </Box>
        </Box>
        <Box textAlign={'center'}>
          <TextFieldInput field={pharmacyNameField} value={pharmaName} handleChange={handleTextInputChange} disabled={preferred} />
          {error?.pharmaName && <Error>{error?.pharmaName?.message}</Error>}
        </Box>
        <Box textAlign={'center'}>
          <TextFieldInput field={phoneField} value={phone} handleChange={handleTextInputChange} disabled={preferred}  />
          {error?.phone && <Error>{error?.phone?.message}</Error>}
        </Box>
        <Box textAlign={'center'}>
          <TextFieldInput field={line1Field} value={line1} handleChange={handleTextInputChange} disabled={preferred} />
          {error?.line1 && <Error>{error?.line1?.message}</Error>}
        </Box>
        <Box textAlign={'center'}>
          <TextFieldInput field={cityField} value={city} handleChange={handleTextInputChange} disabled={preferred} />
          {error?.city && <Error>{error?.city?.message}</Error>}
        </Box>
        <Box textAlign={'center'}>
          <TextFieldInput field={stateField} value={stateAddress} handleChange={handleTextInputChange} disabled={preferred}  />
          {error?.stateAddress && <Error>{error?.stateAddress?.message}</Error>}
        </Box>
        <Box textAlign={'center'}>
          <TextFieldInput field={zipField} value={zip} handleChange={handleTextInputChange} disabled={preferred} />
          {error?.zip && <Error>{error?.zip?.message}</Error>}
        </Box>
        <Box textAlign={'center'}>
          <TextFieldInput field={countryField} value={country} handleChange={handleTextInputChange} disabled={preferred} />
          {error?.country && <Error>{error?.country?.message}</Error>}
        </Box>

        {/* PHARMACY - END */}
        <Box gridColumn={'2 / 3'} display="flex" justifyContent={'center'} alignItems={'center'}>
          <Button
            style={{ height: '65%' }}
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={rxOrderLoading === 'pending'}
          >
            Simulate
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default RxOrderSimulator;
