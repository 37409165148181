export const statusMapping = {
  Started: {
    label: 'Generating...',
    background: 'blue',
    color: 'white',
  },
  Completed: {
    label: 'Completed',
    background: 'green',
    color: 'white',
  },
  Ignored: {
    label: 'Ignored',
    background: 'red',
    color: 'white',
  },
};

export const previewReportHeaders = {
  'User Report': [
    'National Health ID',
    'Email',
    'Full Name',
    'Gender',
    'Date of Birth',
    'State',
    'Questionnaire last completed on',
    'Outcome',
    'Clearance Status',
    'Employee Tiers',
    'User Segment',
    'Employee Id',
    'User Segments',
    'Division Name',
    'PPR',
    'EmployeeId',
    'Station Code',
    'Dept Name',
    'EmployeeId',
    'EmployerId',
    'Birth Place',
    'QuestFacilityId',
    'EmployerName',
  ],
  'Scan Report': [
    'National Health ID',
    'Email',
    'Full Name',
    'Phone Number',
    'Checked On',
    'Checked By',
    'Outcome',
    'Overridden Outcome',
    'Overridden Reason',
    'Overridden On',
    'Overridden By',
    'Questionnaire Expired',
    'Symptomatic',
    'Division Name',
    'PPR',
    'EmployeeId',
    'Station Code',
  ],
  'Assessment Report': [
    'National Health ID',
    'Email',
    'Full Name',
    'Gender',
    'Date of Birth',
    'State',
    'Questionnaire Completed On',
    'Outcome',
    'Clearance Status',
  ],
  'LabTest Report': ['National Health ID', 'Email', 'Full Name', 'Gender', 'Date of Birth', 'Result', 'Resulted Date'],
  'CRO Report': ['First Name', 'Last Name', 'Type', 'Gender', 'Date', 'Result'],
  'Active User Report': [
    'Tenant ID',
    'User Role',
    'National Health ID',
    'First Name',
    'Last Name',
    'User Creation Date',
    'Last Login Date',
  ],
};

export const reportTypes = [
  'Assessment Report',
  'Scan Report',
  'User Report',
  'CRO Report',
  'LabTest Report',
  'Active User Report',
  'Log-in Report',
  // "Pending Invites",
];
