export const roleMappings = [
  {
    id: 'ADMINISTRATOR_RESTRICTED',
    label: 'Administrator',
  },
  {
    id: 'ADMINISTRATOR',
    label: 'Health Admin',
  },
  {
    id: 'CHECKER',
    label: 'Health Checker',
  },
  {
    id: 'DEFAULT',
    label: 'Member',
  },
  {
    id: 'PROCTOR',
    label: 'Proctor',
  },
  // {
  //   id: "DEPENDENT",
  //   label: "Dependent",
  // },
];

export const UserRoleId = {
  PLATFORM_ADMINISTRATOR: 0,
  ACCOUNT_ADMINISTRATOR: 1,
  ADMINISTRATOR: 2,
  PROVIDER: 3,
  CHECKER: 4,
  DEFAULT: 5,
  ADMINISTRATOR_RESTRICTED: 6,
  MINIMAL: 7,
  PROCTOR: 8,
};

export const genderMappings = [
  {
    id: 'FEMALE',
    label: 'FEMALE',
  },
  {
    id: 'MALE',
    label: 'MALE',
  },
  {
    id: 'INTERSEX',
    label: 'INTERSEX',
  },
  {
    id: 'OTHER',
    label: 'OTHER',
  },
];

export const statusFilterMappings = [
  {
    id: 'ACTIVE',
    label: 'Active',
  },
  { id: 'INVITED', label: 'Invited' },
  {
    id: 'DEACTIVATED',
    label: 'Deactivated',
  },
];

export const statusMappings = {
  ACTIVE: {
    id: 0,
    label: 'Active',
    background: 'green',
    color: 'white',
    value: 'ACTIVE',
  },
  INVITED: {
    id: 1,
    label: 'Invited',
    background: 'orange',
    color: 'white',
    value: 'INVITED',
  },
  DEACTIVATED: {
    id: 2,
    label: 'Deactivated',
    background: 'red',
    color: 'white',
    value: 'DEACTIVATED',
  },
  INACTIVE: {
    id: 2,
    label: 'Inactive',
    background: 'red',
    color: 'white',
    value: 'INACTIVE',
  },
};

export const statusLabelMappings = {
  ACTIVE: {
    label: 'Active',
    color: 'green',
  },
  INVITED: {
    label: 'Invited',
    color: 'orange',
  },
  DEACTIVATED: {
    label: 'Deactivated',
    color: 'red',
  },
};

export const vaccinationRecordFilterMapppings = [
  {
    id: 'AVAILABLE',
    label: 'AVAILABLE',
  },
  { id: 'PENDING_APPROVAL', label: 'PENDING APPROVAL' },
  {
    id: 'NOT_AVAILABLE',
    label: 'NOT AVAILABLE',
  },
];

export const vaccinationRecordMappings = {
  AVAILABLE: {
    label: 'Available',
    value: 'AVAILABLE',
    color: '#009104',
    bg_color: '#E8FBF0',
  },
  PENDING_APPROVAL: {
    label: 'Pending Approval',
    value: 'PENDING APPROVAL',
    color: '#AF5F00',
    bg_color: '#FDF3DF',
  },
  NOT_AVAILABLE: {
    value: 'NOT_AVAILABLE',
    label: 'Not Available',
    color: '#505358',
    bg_color: '#DFE1E5',
  },
};

export const complianceMapping = {
  OUT_OF_COMPLIANCE: {
    label: 'Non Compliant',
    color: 'red',
  },
  IN_COMPLIANCE: {
    label: 'Compliant',
    color: 'green',
  },
  TEST_NOT_TAKEN: {
    label: 'Test Not Taken',
    color: '#505358',
  },
};

export const covidVaxStatusMapping = [
  {
    label: 'Fully Vaccinated',
    value: 'FullyVaccinated',
  },
  {
    label: 'Partially Vaccinated',
    value: 'PartiallyVaccinated',
  },
  {
    label: 'Unvaccinated',
    value: 'Unvaccinated',
  },
  {
    label: 'Exempt',
    value: 'Exempt',
  },
];

export const lastTestResultFilterMapping = [
  {
    id: 'POSITIVE',
    label: 'Positive',
  },
  {
    id: 'NEGATIVE',
    label: 'Negative',
  },
  {
    id: 'UNKNOWN',
    label: 'Unknown',
  },
  {
    id: 'EQUIVOCAL',
    label: 'Equivocal',
  },
  {
    id: 'INVALID',
    label: 'Invalid',
  },
  {
    id: 'ERROR',
    label: 'Error',
  },
  {
    id: 'INCONCLUSIVE',
    label: 'Inconclusive',
  },
  {
    id: 'UNAVAILABLE',
    label: 'Unavailable',
  },
];
export const lastTestResultMapping = {
  POSITIVE: {
    label: 'Positive',
    value: 'POSITIVE',
    color: '#B1341A',
  },
  NEGATIVE: {
    label: 'Negative',
    value: 'NEGATIVE',
    color: '#009104',
  },
  INVALID: {
    label: 'Invalid',
    value: 'INVALID',
    color: '#F39200',
  },
  UNKNOWN: {
    label: 'UNKNOWN',
    value: 'UNKNOWN',
    color: '#F39200',
  },
  INCONCLUSIVE: {
    label: 'Inconclusive',
    value: 'INCONCLUSIVE',
    color: '#F39200',
  },
  ERROR: {
    label: 'Error',
    value: 'ERROR',
    color: '#F39200',
  },
  EQUIVOCAL: {
    label: 'Equivocal',
    value: 'EQUIVOCAL',
    color: '#F39200',
  },
  UNAVAILABLE: {
    label: 'Unavailable',
    value: 'UNAVAILABLE',
    color: '#DFE1E5',
  },
};

export const initialKendoState = {
  sort: [{ field: 'created', dir: 'desc' }],
  take: 10,
  skip: 0,
  search: '',
  filter: '',
};

export const userTypes = {
  ACTIVE: 'active',
  INVITED: 'invited',
};
