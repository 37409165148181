/* eslint-disable react/no-unstable-nested-components */
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Editor, EditorTools, EditorUtils, ProseMirror } from '@progress/kendo-react-editor';
import { Error } from '@progress/kendo-react-labels';
import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectCustomFontsList, selectTenantSettingsId } from '../../../Settings/selectors';
import { getCustomFontsList } from '../../../Settings/settingsSlice';
import utils from '../../Utils/utils';
import { CustomFontSize } from '../helpers/customFontSize';

const API_ROOT = process.env.REACT_APP_API_ROOT;

const {
  Bold,
  Italic,
  Underline,
  Strikethrough,
  Subscript,
  Superscript,
  ForeColor,
  BackColor,
  CleanFormatting,
  AlignLeft,
  AlignCenter,
  AlignRight,
  Indent,
  Outdent,
  OrderedList,
  UnorderedList,
  Undo,
  Redo,
  FormatBlock,
  Link,
  Unlink,
  InsertImage,
  ViewHtml,
  InsertTable,
  AddRowBefore,
  AddRowAfter,
  AddColumnBefore,
  AddColumnAfter,
  DeleteRow,
  DeleteColumn,
  DeleteTable,
  MergeCells,
  SplitCell,
} = EditorTools;

const useStyles = makeStyles({
  editor: {
    textAlign: 'left',
  },
  toolIcon: {
    '& span.k-tool-icon': {
      height: 'calc( 1.42857em + 16px)!important',
      padding: '8px 8px!important',
    },
  },
  toolInput: {
    '& span.k-input': {
      height: 'calc( 1.42857em + 12px)!important',
      padding: '10px 12px!important',
    },
  },
  toolButton: {
    '& button.k-button': {
      padding: '10px 12px!important',
      height: 'calc( 1.42857em + 16px)!important',
      width: 'calc( 1.42857em + 16px)!important',
    },
  },
  toolDropdown: {
    '& span.k-dropdown': {
      width: '150px!important',
    },
  },
});

function HtmlInputText(props) {
  const classes = useStyles();
  const [, setScript] = useState(null);
  const [scriptExecutionError, setScriptExecutionError] = useState(false);
  const dispatch = useDispatch();
  const { field, value, handleChangeWithParams, debounce = 700 } = props;
  const [currentValue, setCurrentValue] = useState(value ? utils.stripeString(value) : '');
  const currentTenant = useSelector(selectTenantSettingsId);
  const customFontsList = useSelector(selectCustomFontsList);
  const [maxLengthError, setMaxLengthError] = useState(false);
  const [timeout, setStateTimeout] = useState(undefined);

  // Build the Custom Font List Dropdown
  // Get the list of Tenant specific custom fonts, if any exist
  useEffect(() => {
    if (currentTenant) {
      dispatch(getCustomFontsList(currentTenant));
    }
  }, [currentTenant]);

  useEffect(() => {
    if (customFontsList && customFontsList.length) {
      customFontsList.forEach((fontItem) => {
        if (!document.getElementById(fontItem.id)) {
          if (fontItem.url) {
            const link = document.createElement('link');
            link.setAttribute('rel', 'stylesheet');
            link.type = 'text/css';
            link.id = fontItem.id;
            link.href = `${API_ROOT}/admin/v1${fontItem.url}`;
            document.head.appendChild(link);
          }
        }
      });
    }
  }, [customFontsList]);

  const getFontNameToolSettings = () => ({
    style: 'font-family',
    defaultItem: { text: 'Font Name', value: '' },
    items: [
      {
        text: 'Arial',
        value: 'Arial, Helvetica, sans-serif',
        style: { fontFamily: 'Arial, Helvetica, sans-serif' },
      },
      {
        text: 'Avenir',
        value: 'Avenir, -apple-system, BlinkMacSystemFont, Helvetica, Arial, sans-serif',
        style: {
          fontFamily: 'Avenir, -apple-system, BlinkMacSystemFont, Helvetica, Arial, sans-serif',
        },
      },
      {
        text: 'Courier New',
        value: "'Courier New', Courier, monospace",
        style: { fontFamily: "'Courier New', Courier, monospace" },
      },
      {
        text: 'Georgia',
        value: 'Georgia, serif',
        style: { fontFamily: 'Georgia, serif' },
      },
      {
        text: 'Impact',
        value: 'Impact, Charcoal, sans-serif',
        style: { fontFamily: 'Impact, Charcoal, sans-serif' },
      },
      {
        text: 'Lucida Console',
        value: "'Lucida Console', Monaco, monospace",
        style: { fontFamily: "'Lucida Console', Monaco, monospace" },
      },
      {
        text: 'Tahoma',
        value: 'Tahoma, Geneva, sans-serif',
        style: { fontFamily: 'Tahoma, Geneva, sans-serif' },
      },
      {
        text: 'Times New Roman',
        value: "'Times New Roman', Times,serif",
        style: { fontFamily: "'Times New Roman', Times,serif" },
      },
      {
        text: 'Trebuchet MS',
        value: "'Trebuchet MS', Helvetica, sans-serif",
        style: { fontFamily: "'Trebuchet MS', Helvetica, sans-serif" },
      },
      {
        text: 'Verdana',
        value: 'Verdana, Geneva, sans-serif',
        style: { fontFamily: 'Verdana, Geneva, sans-serif' },
      },
      {
        text: 'Whitney',
        value: 'Whitney, Avenir, -apple-system, BlinkMacSystemFont, Helvetica, Arial, sans-serif',
        style: {
          fontFamily: 'Whitney, Avenir, -apple-system, BlinkMacSystemFont, Helvetica, Arial, sans-serif',
        },
      },
      // adding any custom fonts to the default list
      ...customFontsList.map((item) => ({
        text: item.name,
        value: item.fallBackFonts,
        style: {
          fontFamily: item.fallBackFonts,
        },
        // url: `${API_ROOT}/admin${item.url}`
      })),
    ],
  });

  // Creates FontName tool
  let MyFontNameTool = EditorTools.createStyleDropDownList(getFontNameToolSettings());

  function CustomFontTool(props) {
    return <MyFontNameTool {...props} />;
  }

  useEffect(() => {
    if (customFontsList) {
      MyFontNameTool = EditorTools.createStyleDropDownList(getFontNameToolSettings());
      CustomFontTool(props);

      setTools([
        [Bold, Italic, Underline, Strikethrough],
        [Subscript, Superscript],
        ForeColor,
        BackColor,
        [CleanFormatting],
        [AlignLeft, AlignCenter, AlignRight],
        [Indent, Outdent],
        [OrderedList, UnorderedList],
        CustomFontSize,
        CustomFontTool,
        FormatBlock,
        [Undo, Redo],
        [Link, Unlink, InsertImage, ViewHtml],
        [InsertTable],
        [AddRowBefore, AddRowAfter, AddColumnBefore, AddColumnAfter],
        [DeleteRow, DeleteColumn, DeleteTable],
        [MergeCells, SplitCell],
      ]);
    }
  }, [customFontsList]);
  // End of Custom font list

  const [tools, setTools] = useState([
    [Bold, Italic, Underline, Strikethrough],
    [Subscript, Superscript],
    ForeColor,
    BackColor,
    [CleanFormatting],
    [AlignLeft, AlignCenter, AlignRight],
    [Indent, Outdent],
    [OrderedList, UnorderedList],
    CustomFontSize,
    CustomFontTool,
    FormatBlock,
    [Undo, Redo],
    [Link, Unlink, InsertImage, ViewHtml],
    [InsertTable],
    [AddRowBefore, AddRowAfter, AddColumnBefore, AddColumnAfter],
    [DeleteRow, DeleteColumn, DeleteTable],
    [MergeCells, SplitCell],
  ]);

  const editor = useRef(null);

  useEffect(() => {
    if (value) {
      setScript(utils.parseJsScript(value));
      // Ensure content is current for articles editing
      if (field?.name === 'html' && editor.current.view) {
        const { view } = editor.current;
        EditorUtils.setHtml(view, value);
      }
    }
  }, [value]);

  // Initial field values and settings
  useEffect(() => {
    if (field && value && !field.disabled && editor.current.view) {
      const { view } = editor.current;
      EditorUtils.setHtml(view, value);
    }
    if (field && !value && !field.disabled && editor.current.view) {
      const { view } = editor.current;
      EditorUtils.setHtml(view, '');
    }
    if (field?.name.includes('description')) {
      setTools([
        [Bold, Italic, Underline, Strikethrough],
        ForeColor,
        BackColor,
        [CleanFormatting],
        [AlignLeft, AlignCenter, AlignRight],
        [Indent, Outdent],
        [OrderedList, UnorderedList],
        CustomFontSize,
        CustomFontTool,
        FormatBlock,
        [Undo, Redo],
        [Link, Unlink, ViewHtml],
      ]);
    }
    // No dependencies here to prevent unnecessary rerenders, which cause the caret to jump to
    // the end if editing in the middle of the window
    // eslint-disable-next-line
  }, []);

  const handleScriptExecute = () => {
    setScriptExecutionError(false);
    const scriptExecutionResult = utils.findScript(value);
    if (!scriptExecutionResult) {
      setScriptExecutionError(true);
    }
  };

  const onMount = (event) => {
    if (!document.getElementById('htmlContentEditor')) {
      const doc = event.dom.ownerDocument;
      const style = doc.createElement('style');
      style.id = 'htmlContentEditor';
      style.innerHTML = '.k-content { white-space: pre-wrap; }';
      event.dom.ownerDocument.head.appendChild(style);
    }
  };

  // handle changes and save to form data
  const handleExecute = ({ state, transaction }) => {
    const nextState = ProseMirror.EditorState.create({
      doc: transaction.doc,
      selection: transaction.selection,
    });

    let editorValue = EditorUtils.getHtml(nextState);

    // Apply styling elements to Ordered and Un-ordered lists
    const displayNode = (selection) => {
      if (selection) {
        // eslint-disable-next-line array-callback-return
        selection.map((node) => {
          if ((node?.type?.name === 'ordered_list' || node?.type?.name === 'bullet_list') && transaction?.meta?.args) {
            if (!node.attrs?.style) {
              node.attrs.style = `${transaction.meta.args.style}: ${transaction.meta.args.value}; `;
            } else if (node.attrs?.style) {
              if (node.attrs?.style?.includes(transaction.meta?.args?.style)) {
                const styles = node.attrs.style.split(';');
                const stylesToKeep = styles.filter((s) => !s.includes(transaction.meta.args?.style)).join(';');
                node.attrs.style = `${transaction.meta.args.style}: ${transaction.meta.args.value}; ${stylesToKeep}`;
              } else {
                node.attrs.style += `${transaction.meta.args.style}: ${transaction.meta.args.value}; `;
              }
            }
          } else if (
            (node?.type?.name === 'ordered_list' || node?.type?.name === 'bullet_list') &&
            transaction?.meta?.commandName === 'CleanFormatting'
          ) {
            node.attrs.style = undefined;
          }
          // Reset the editor view if this code block runs
          if (typeof node !== 'number') {
            editorValue = EditorUtils.getHtml(
              ProseMirror.EditorState.create({
                doc: transaction.doc,
                selection: transaction.selection,
              })
            );
            const { view } = editor.current;
            EditorUtils.setHtml(view, editorValue);
          }
        });
      }
    };
    if (
      transaction?.curSelection?.$anchor?.parentOffset !== transaction?.curSelection?.$head?.parentOffset &&
      !(transaction?.meta?.pointer || transaction?.meta?.commandName === 'FormatBlock')
    ) {
      displayNode(transaction?.curSelection?.ranges[0].$from?.path);
    }

    if (state.doc.eq(transaction.doc)) {
      return;
    }

    let editedValue = editorValue
      .replaceAll("<li><p>", "<li>")
      .replaceAll("</p></li>", "</li>");
    const editedStripedValue = transaction.doc?.textContent;
    setCurrentValue(editedStripedValue);

    // disable submit if value more then maxLength
    const maxLengthError = transaction.doc?.textContent.length > field?.maxLength || false;
    setMaxLengthError(maxLengthError);
    if (timeout) {
      clearTimeout(timeout);
    }

    setStateTimeout(
      setTimeout(() => {
        handleChangeWithParams("disabledSubmit", maxLengthError || (field?.required && !editedStripedValue));
        handleChangeWithParams(field?.name, editedValue);
      }, debounce)
    );
  };

  return (
    <Grid container>
      {field?.label !== 'HTML' && (
        <Grid item>
          <Typography
            variant="caption"
            style={{ opacity: 0.8, color: field?.required && !currentValue ? "red" : undefined }}
          >
            {field?.label}
          </Typography>
        </Grid>
      )}
      {!field?.disabled ? (
        <>
          <Grid item style={{ width: 'inherit' }}>
            <Editor
              tools={tools}
              ref={editor}
              defaultContent={value}
              onExecute={handleExecute}
              onMount={onMount}
              defaultEditMode="div"
              className={clsx(
                classes.editor,
                classes.toolIcon,
                classes.toolButton,
                classes.toolInput,
                classes.toolDropdown
              )}
              contentStyle={{
                height: field?.height || 267,
              }}
              style={{
                borderColor: field?.required && !currentValue ? "red" : undefined,
              }}
            />
          </Grid>

          {maxLengthError && (
            <Grid>
              <Error id="invalidDate">Max length {field.maxLength}</Error>
            </Grid>
          )}

          {field?.checkScriptExecution && (
            <Grid item style={{ marginTop: 20 }}>
              <Button variant="contained" color="primary" onClick={handleScriptExecute} disabled={false}>
                Check script execution
              </Button>
              {scriptExecutionError && (
                <Typography style={{ padding: '10px 0', color: 'red' }}>
                  Script execution error. Fix our script, please!
                </Typography>
              )}
            </Grid>
          )}
        </>
      ) : (
        <Grid item xs={12}>
          <textarea
            className="k-textarea"
            style={{ height: 100, width: '100%', resize: 'none' }}
            defaultValue={value}
            disabled
          />
        </Grid>
      )}
    </Grid>
  );
}

export default HtmlInputText;
