import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import PropTypes from 'prop-types';
import React from 'react';

import TestIcon from '../../assets/img/cassette.svg';
import RefreshIcon from '../../assets/img/refresh_24dp.svg';
import VaccineIcon from '../../assets/img/vaccine.svg';
import WarningIcon from '../../assets/img/warning_amber_black.svg';
import { CardSkeletonLoading } from '../Content/ContentSkeletonLoading';

import AppInfoBox from './charts/InfoBox';

export function InfoCardLoading() {
  const classes = useStyles();
  return (
    <Paper className={classes.loadingWrapper}>
      <CardSkeletonLoading />
    </Paper>
  );
}

/**
 * Show text vaccinated info card with custom bg color
 * @param props
 */
export function InfoCard(props) {
  const { value, title, dateRange, type, bgColor, text, infoText } = props;
  const classes = useStyles({ bgColor, subText: !!text });
  const getCardIcon = () => {
    if (type === 'nonComplaint') {
      return <WarningIcon />;
    }
    if (type === 'complaint') {
      return <CheckCircleOutlineIcon />;
    }
    if (type === 'vaccinated') {
      return <VaccineIcon />;
    }
    if (type === 'sidelinedUsers') {
      return <PeopleAltOutlinedIcon />;
    }
    if (type === 'testCompleted') {
      return <TestIcon />;
    }
    if (type === 'positive') {
      return <WarningIcon />;
    }
    if (type === 'negative') {
      return <CheckCircleOutlineIcon />;
    }
    if (type === 'invalid') {
      return <RefreshIcon />;
    }
  };
  return (
    <Paper className={classes.root}>
      <Grid container className={classes.container}>
        <Grid className={`${classes.iconWrapper} ${classes[type]}`}>{getCardIcon()}</Grid>
        <Grid className={classes.descriptionWrapper}>
          <Grid className={classes.title}>{title}</Grid>
          <Grid className={classes.value}>{value}</Grid>
          <Grid className={classes.text}>{text}</Grid>
          {dateRange && <Grid className={classes.text}>{dateRange}</Grid>}
        </Grid>
      </Grid>

      {infoText && <AppInfoBox infoText={infoText} position="bottomRight" />}
    </Paper>
  );
}

InfoCard.propTypes = {
  value: PropTypes.string.isRequired,
  title: PropTypes.string,
  type: PropTypes.string,
  bgColor: PropTypes.string,
};

// Info card styles
const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    padding: 16,
    backgroundColor: '#FFFFFF',
    minHeight: 140,
    borderRadius: 20,
    fontFamily: 'Avenir',
    [theme.breakpoints.down(599)]: {
      width: '100%',
      padding: 10,
    },
    [theme.breakpoints.up(600)]: {
      height: '100%',
    },
  },
  container: {
    height: '100%',
    [theme.breakpoints.down(599)]: {
      flexWrap: 'wrap',
      gridGap: 10,
      flexDirection: 'column',
    },
    [theme.breakpoints.up(600)]: {
      flexWrap: 'nowrap',
    },
  },
  loadingWrapper: {
    height: 168,
    backgroundColor: '#ffffff',
    borderRadius: 20,
  },
  descriptionWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: '0 16px',
  },
  iconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    flex: 1,
    padding: 8,
    height: '100%',
    borderRadius: 16,
    '&>svg': {
      width: 32,
      height: 32,
    },
    [theme.breakpoints.up(600)]: {
      maxWidth: 48,
    },
  },
  nonComplaint: {
    backgroundColor: '#FFE5E5',
    '&>svg': {
      fill: '#DC0000',
    },
  },
  complaint: {
    backgroundColor: '#E7FBEA',
    '&>svg': {
      fill: '#198916',
    },
  },
  vaccinated: {
    backgroundColor: '#E9F9FA',
    '&>svg': {
      fill: '#00A3B8',
    },
  },
  sidelinedUsers: {
    backgroundColor: '#F9F2FF',
    '&>svg': {
      fill: '#701891',
    },
  },
  testCompleted: {
    backgroundColor: '#E9F9FA',
    // "&>svg": {
    //   fill: "#26a3b8",
    // },
  },
  positive: {
    backgroundColor: '#FFE5E5',
    '&>svg': {
      fill: '#DC0000',
    },
  },
  negative: {
    backgroundColor: '#E7FAEA',
    '&>svg': {
      fill: '#198916',
    },
  },
  invalid: {
    backgroundColor: '#FDF0C4',
    '&>svg': {
      fill: '#D27E00',
    },
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '22px',
    color: '#505358',
  },
  value: {
    color: '#505358',
    fontSize: 36,
    fontWeight: 500,
    lineHeight: '49px',
  },
  text: {
    color: '#627580',
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '150%',
  },
}));
