import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { paths } from '../../constants';
import CreateFormWrapper from '../Shared/Forms/CreateFormWrapper';
import { selectCurrentTenant } from '../Shared/selectors';
import { memberImport } from './helpers/formHelper';
import { userTypes } from './helpers/mappings';
import { selectPreviewImport, selectCurrentUserPermissions, selectShowBulkUploadJobDialog } from './selectors';
import UploadConfirmationDialog from './UploadConfirmationDialog';
import { bulkImport, resetUploadUsersPreview, setBulkUploadJobDialog } from './usersSlice';

function UserImport() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const currentUser = useSelector(selectCurrentUserPermissions);
  const uploadDialog = useSelector(selectShowBulkUploadJobDialog);
  const currentTenant = useSelector(selectCurrentTenant);
  // const bulkImportResp = useSelector(selectBulkImportResp);
  const previewImport = useSelector(selectPreviewImport);
  // const formData = useSelector(selectFormData);
  const navigate = useNavigate();

  // send steps for form wrapper, need label and pass data from helper. Steps must be in stepper order.
  // if only 1 step, form wrapper will remove stepper bar and back button.
  const steps = [
    {
      label: 'Import Users',
      data: memberImport(currentTenant),
    },
  ];

  const handleClose = () => {
    dispatch(setBulkUploadJobDialog(false));
    navigate(
      // `/${currentTenant.id}/users`
      paths.users(currentTenant?.id, userTypes.ACTIVE)
    );
  };

  const handleSubmit = (formData) => {
    dispatch(bulkImport(currentTenant?.id, formData, currentUser?.userId, currentUser?.accountId));
  };

  const handleShowConfirmation = () => {
    dispatch(resetUploadUsersPreview());
  };

  return (
    <Grid container justifyContent="center" alignItems="center" alignContent="center">
      <Dialog
        open={uploadDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className={classes.dialogHeader}>
          File Upload Successful
        </DialogTitle>
        <Divider />
        <Typography className={classes.dialogContent}>New members will receive an email.</Typography>
        <DialogActions>
          <Button onClick={handleClose} color="primary" variant="contained">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      {previewImport && (
        <UploadConfirmationDialog
          onHandleShowConfirmation={handleShowConfirmation}
          // onHandleContinueUploading={handleContinueUploading}
          previewImport={previewImport}
        />
      )}
      <CreateFormWrapper handleSubmit={handleSubmit} steps={steps} />
    </Grid>
  );
}

export default UserImport;

const useStyles = makeStyles((theme) => ({
  dialogHeader: {
    backgroundColor: theme.palette.primary.main ? theme.palette.primary.main : '#003C71',
    color: '#fff',
  },
  dialogContent: {
    margin: '25px',
    color: '#58585A',
  },
}));
