/* eslint-disable react/no-array-index-key */
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Input } from '@progress/kendo-react-inputs';
import React, { useState, useEffect, useRef } from 'react';

// redux
import { useSelector } from 'react-redux';

import utils from '../../Utils/utils';
import { selectFormData } from '../selectors';
// ui reflection of file view

const styles = () => ({
  buttonVisibleWrapper: {
    position: 'absolute',
    right: 0,
    bottom: '6px',
  },
  buttonVisible: {
    padding: '0',
  },
  input: {
    paddingRight: '30px',
  },
});

function MultipleTextInput(props) {
  const { field, value: initialValue, handleChange, disabled, selectedLanguage } = props;

  const formData = useSelector(selectFormData);
  const [currentValue, setCurrentValue] = useState([]);
  const masktextfield = useRef(null);

  const fieldDisabled = disabled || field.readOnly || (field.disableIfFalse && !formData[field.disableIfFalse]);

  // disable autocomplete through reference
  useEffect(() => {
    if (masktextfield && masktextfield.current) {
      const input = masktextfield.current.element;
      input.autocomplete = 'new-password';
    }
  }, [masktextfield]);

  useEffect(() => {
    if (initialValue && initialValue !== currentValue) {
      setCurrentValue(initialValue);
    } else if (!initialValue) {
      const fieldObjects =
        field &&
        field.fields &&
        field.fields.map((field) => ({
          id: field.id,
        }));
      setCurrentValue(fieldObjects);
    }
    // eslint-disable-next-line
  }, [initialValue, field.fields]);

  const onChange = (evt) => {
    const { value } = evt.target;
    const currentValueSet = [...currentValue];
    const answerObject = {
      id: evt.target.name.split('-')[0],
      value,
    };
    const currentIndex = currentValueSet.findIndex((entry) => entry.id === answerObject.id);
    if (currentIndex > -1) {
      currentValueSet[currentIndex] = answerObject;
    } else {
      currentValueSet.push(answerObject);
    }
    setCurrentValue(currentValueSet);
    handleChange(currentValueSet);
  };

  const questionDescription =
    selectedLanguage && field.description && field.description.translations
      ? field.description.translations[selectedLanguage]
      : field.description;

  if (!field.name || !currentValue) return <div />;

  const getInputLabel = () => {
    if (field['fieldName-eng']) {
      if (selectedLanguage) return field[`fieldName-${selectedLanguage}`];
      return field['fieldName-eng'];
    }
    if (field.name && field.name.translations) {
      if (selectedLanguage) return field.name.translations[selectedLanguage];
      return field.name.translations.eng;
    }
  };

  return (
    <div>
      <Grid container style={{ position: 'relative' }}>
        <Typography
          style={{
            opacity: field.required && !currentValue ? 1 : 0.5,
          }}
          color={field.required && !currentValue ? 'error' : 'inherit'}
        >
          {field.description ? utils.renderHTMLTags(questionDescription) : questionDescription}
        </Typography>
        {field &&
          field.fields &&
          field.fields.map((field, idx) => (
            <Grid key={`${field}-${idx}`} item xs={12}>
              <Input
                width="100%"
                value={currentValue.length && currentValue[idx] && currentValue[idx].value}
                onChange={(evt) => onChange(evt, idx)}
                required={(!initialValue || !currentValue) && field.required}
                label={getInputLabel()}
                name={`${field.id}`}
                pattern={field.pattern}
                style={{ width: '100%' }}
                disabled={fieldDisabled}
                maxLength={field.maxLength}
                autoComplete="new-password"
                type="text"
              />
            </Grid>
          ))}
      </Grid>
    </div>
  );
}

export default withStyles(styles)(MultipleTextInput);
