export const selectConcernList = (state) => state.reviewer.concernList;
export const selectReviewerList = (state) => state.reviewer.reviewerList;
export const selectCurrentUserData = (state) => state?.users?.currentUser;
export const selectDeletingShapeId = (state) => state.reviewer.deletingShapeId;
export const selectDeletingAnnotationLoader = (state) => state.reviewer.deletingAnnotationLoader;
export const selectEditingReviewerQuestion = (state) => state.reviewer.editingQuestion;
export const selectAppThemeColor = (state) => state.shared?.currentTenant?.color;
export const selectQuestionId = (state) => state.questionnaire.previewQuestion?.question?.question?.id;
export const selectOutcomeId = (state) => state.questionnaire.previewQuestion?.question?.outcome?.id;

export const selectAnnotationList = (state) => state.reviewer.annotationList;

export const selectQuestionnaireList = (state) => {
  const selectedLanguage = 'eng';
  const { questionnaireList } = state.reviewer;
  let collectedData = [];
  if (questionnaireList && questionnaireList.length) {
    collectedData = questionnaireList.map((item) => ({
      id: item.id,
      title: item.title?.translations[selectedLanguage] || item.title,
    }));
  }

  return collectedData;
};
