import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { showConfirmation } from '../Confirmation/confirmationSlice';
import { showNotification } from '../Notifications/notificationSlice';
import { createInputHelper } from '../Shared/Forms/helpers/createInputHelper';
import { selectCurrentTenant } from '../Shared/selectors';

// material-ui

// component and styling imports
import { initialSorting } from './helpers/virtualConsultListMapper';
import { bulkUpload } from './virtualConsultSlice';

function ContentImportDialog({ importType, setJsonImportDialog, jsonImportDialog }) {
  const currentTenant = useSelector(selectCurrentTenant);

  const [file, setFile] = useState({});

  const dispatch = useDispatch();

  // Clear file state if import is canceled and reopened
  useEffect(() => {
    setFile({});
  }, [jsonImportDialog]);

  const handleImport = async () => {
    if (!file) return;
    const result = JSON.parse(JSON.parse(file));
    const { type, content } = result;

    if (importType !== type || !type || !content) {
      dispatch(showNotification('This file is incorrect!', 'error'));
      setJsonImportDialog(false);
      return;
    }

    // Update tenant ID to match current tenant
    const updatedContent = content.map((c) => ({
      ...c,
      tenantId: currentTenant.id,
    }));

    setJsonImportDialog(false);
    dispatch(
      showConfirmation(
        'Confirm',
        `Are you sure you want to import the copied ${type}?`,
        'importVirtualConsult',
        currentTenant,
        '',
        '',
        async () => {
          dispatch(
            bulkUpload(type, currentTenant.id, updatedContent, initialSorting[type].field, initialSorting[type].dir)
          );
        }
      )
    );
  };

  return (
    <Dialog
      open={jsonImportDialog}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
          setJsonImportDialog(false);
        }
      }}
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
    >
      <DialogTitle>Import file</DialogTitle>
      <DialogContent>
        {createInputHelper(
          [
            {
              type: 'file',
              name: 'file',
              label: 'Import',
              required: true,
              width: 12,
              uploadLimit: 1,
              acceptedFiles: ['application/json'],
              extendedLimitsDescription: '',
            },
          ],
          file,
          () => ({}),
          (name, value) => {
            if (value.length) {
              const blob = new Blob([value[0]], { type: 'application/json' });
              const reader = new FileReader();
              reader.onload = async (event) => {
                setFile(event.target.result);
              };
              reader.readAsText(blob);
            }
          }
        )}
      </DialogContent>
      <DialogActions>
        <div>
          <Button onClick={() => setJsonImportDialog(false)} color="primary" style={{ marginRight: 8 }}>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleImport} color="primary" disabled={!Object.keys(file).length}>
            Ok
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default ContentImportDialog;
