import React from 'react';

// components and helpers
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AppTabbedPageWrapper from '../../Shared/Pages/TabbedPageWrapper';

import { selectCurrentTenant } from '../../Shared/selectors';
import { systemMessagesTabTypes, systemMessagesTypes } from './helpers/mappings';
import AppSettingsTemplates from './SettingsTemplates';

function SystemMessagesPageWrapper(props) {
  const { messageType } = props;
  const currentTenant = useSelector(selectCurrentTenant);
  const navigate = useNavigate();

  const tabs = [
    {
      label: 'EMAIL & SAFE',
      type: systemMessagesTabTypes.EMAIL,
      url: `settings/system-messages/${systemMessagesTabTypes.EMAIL}`,
      content: (
        <AppSettingsTemplates
          currentTenant={currentTenant}
          messageType={systemMessagesTypes[systemMessagesTabTypes.EMAIL]}
        />
      ),
    },
    {
      label: 'SMS & PUSH NOTIFICATIONS',
      type: systemMessagesTabTypes.SMS,
      url: `settings/system-messages/${systemMessagesTabTypes.SMS}`,
      content: (
        <AppSettingsTemplates
          currentTenant={currentTenant}
          messageType={systemMessagesTypes[systemMessagesTabTypes.SMS]}
        />
      ),
    },
  ];

  const handleTabChange = (tabDetails) => {
    navigate(`/${currentTenant.id}/${tabDetails.url}`);
  };

  if (!currentTenant) return <div />;

  return (
    <AppTabbedPageWrapper
      tabs={tabs}
      openTab={tabs.find((t) => t.type === messageType)}
      onTabChange={handleTabChange}
    />
  );
}

export default SystemMessagesPageWrapper;
