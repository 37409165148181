import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import React from 'react';

import styles from '../../../assets/Questionnaire.module.css';

function SingleTest(props) {
  const { test } = props;

  if (!test) return null;
  return (
    <Card variant="outlined">
      <CardContent>
        <Typography align="center" className={styles.singleTestName}>
          {test.vendorName.toUpperCase()}
        </Typography>
      </CardContent>
    </Card>
  );
}

export default SingleTest;
