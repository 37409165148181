import * as Yup from 'yup';

export function loginSchema() {
  return Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Required'),
    password: Yup.string().required('Required'),
  });
}

export function resetPasSchema() {
  return Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Required'),
  });
}

export function passCodeSchema() {
  return Yup.object().shape({
    password: Yup.string().required('Required'),
    confirmationCode: Yup.string().required('Required'),
  });
}
