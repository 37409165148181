/* eslint-disable import/no-cycle */
import { createSlice } from '@reduxjs/toolkit';
import api from '../../../api/index';
import { showNotification } from '../../Notifications/notificationSlice';
import { handleError } from '../../Shared/Content/APIUtils';
import { getMyTenants } from '../../Shared/sharedSlice';

const initialState = {
  list: null,
  loading: false,
  error: null,
  selected: undefined,
  loadingTenant: true,
  tenantError: undefined,
  addTenantLoading: false,
};

export const tenantsSlice = createSlice({
  name: 'tenants',
  initialState,
  reducers: {
    setTenantsRequest: (state) => ({
      ...state,
      loading: true,
      error: null,
    }),
    setTenantsRequestSuccess: (state, { payload }) => ({
      ...state,
      list: payload,
      loading: false,
    }),
    setTenantsRequestFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
    }),
    setTenantRequest: (state) => ({
      ...state,
      selected: undefined,
      loadingTenant: true,
      tenantError: undefined,
    }),
    setTenantRequestSuccess: (state, { payload }) => ({
      ...state,
      loadingTenant: false,
      addTenantLoading: false,
      selected: payload,
    }),
    setTenantRequestFailure: (state, { payload }) => ({
      ...state,
      loadingTenant: false,
      addTenantLoading: false,
      tenantError: payload,
    }),
    setAddTenantRequest: (state) => ({
      ...state,
      addTenantLoading: true,
    }),
    resetState: () => ({ ...initialState }),
  },
});

export const {
  setTenantsRequest,
  setTenantsRequestSuccess,
  setTenantsRequestFailure,
  setTenantRequest,
  setTenantRequestSuccess,
  setTenantRequestFailure,
  setAddTenantRequest,
} = tenantsSlice.actions;

export const getTenants = (page, pageSize, sort, direction) => async (dispatch) => {
  const params = {
    skip: page * pageSize,
    limit: pageSize,
    sort,
    direction,
  };

  dispatch(setTenantsRequest());

  const [result, error] = await api.getTenantsRequest(params);

  if (error && error.message) {
    dispatch(setTenantsRequestFailure(error.message));
    dispatch(handleError(error));
  }

  if (result) {
    dispatch(setTenantsRequestSuccess(result));
  }
};

export const getTenant = (id) => async (dispatch) => {
  dispatch(setTenantRequest());

  const [result, error] = await api.getTenantRequest(id);

  if (error && error.message) {
    dispatch(setTenantRequestFailure(error.message));
    dispatch(handleError(error));
  }

  if (result) {
    dispatch(setTenantRequestSuccess(result));
  }
};

export const getChannels = () => async (dispatch) => {
  const [result, error] = await api.getChannelsRequest();

  if (error && error.message) {
    dispatch(handleError(error));
  }

  return result || null;
};

export const addTenant = (tenantId, data) => async (dispatch) => {
  dispatch(setAddTenantRequest());

  const [result, error] = await api.addTenantRequest(data);

  if (error && error.message) {
    dispatch(setTenantRequestFailure(error.message));
    dispatch(handleError(error));
  }

  if (result) {
    dispatch(showNotification('Tenant Successfully Created', 'success'));
    dispatch(getMyTenants());
    return true;
  }

  return null;
};

export default tenantsSlice.reducer;
