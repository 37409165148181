import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import createDOMPurify from 'dompurify';
import React from 'react';

const DOMPurify = createDOMPurify(window);

function VerbiagePreviewDialog(props) {
  const { open, handleClose, previewData } = props;
  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose} aria-labelledby="max-width-dialog-title">
      <DialogTitle id="max-width-dialog-title">{previewData && previewData.title}</DialogTitle>
      <DialogContent>
        <span
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(previewData && previewData.value),
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default VerbiagePreviewDialog;
