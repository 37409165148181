import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';

function DashboardCardContainer({ title, height, children }) {
  return (
    <Paper style={{ height: height || 450, marginBottom: 40 }} variant="outlined">
      <Typography variant="body2" style={{ fontWeight: 600, padding: 10, width: '100%' }}>
        {title}
      </Typography>
      <Divider />
      <Box style={{ height: 390 }}>{children}</Box>
    </Paper>
  );
}

DashboardCardContainer.propTypes = {
  title: PropTypes.string,
  // timeMenu: PropTypes.bool,
  // chartHeight: PropTypes.number,
  children: PropTypes.object.isRequired,
};

export default DashboardCardContainer;
