import { createSlice } from '@reduxjs/toolkit';

import api from '../../api';
import { handleError } from '../Shared/Content/APIUtils';

import { defaultLanguageContent } from './defaultLanguageContent';

const initialState = {
  language: 'en',
  version: null,
  allowChange: true,
  languageContent: defaultLanguageContent,
};

export const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    setLanguage: (state, { payload }) => ({
      ...state,
      language: payload,
    }),
    setLanguageVersion: (state, { payload }) => ({
      ...state,
      version: payload,
    }),
    setLanguageContent: (state, { payload }) => ({
      ...state,
      languageContent: payload,
    }),
    setAllowChange: (state, { payload }) => ({
      ...state,
      allowChange: payload,
    }),

    resetLocalization: () => ({ ...initialState }),
  },
});

export const { setLanguage, setLanguageVersion, setLanguageContent, setAllowChange } = languageSlice.actions;

export const getActualLanguageVersion = (tenantId, language) => async (dispatch) => {
  const [result, error] = await api.getActualLanguageVersionRequest(tenantId, language);

  if (error && error.message) {
    return dispatch(handleError(error));
  }

  if (result && result?.data?.version) {
    dispatch(setLanguageVersion(result.data.version));
    dispatch(getLanguageByVersion(tenantId, language, result.data.version));
  }
};

export const getLanguageByVersion = (tenantId, language, version) => async (dispatch) => {
  const [result, error] = await api.getLanguageByVersionRequest(tenantId, language, version);

  if (error && error.message) {
    return dispatch(handleError(error));
  }

  if (result) {
    dispatch(setLanguageContent({ ...defaultLanguageContent, ...result }));
  }
};

export default languageSlice.reducer;
