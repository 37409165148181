import { Path, Text, geometry, Layout } from '@progress/kendo-drawing';
import {
  Chart,
  ChartArea,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartCategoryAxisTitle,
  ChartLegend,
  ChartLegendItem,
  ChartSeries,
  ChartSeriesItem,
  ChartSeriesItemTooltip,
  ChartTitle,
} from '@progress/kendo-react-charts';
import PropTypes from 'prop-types';
import React from 'react';

// components and helpers
import ChartCard from '../ChartCard';
import { getLabelStep } from '../helpers/dashboardHelpers';
import InfoBox from './InfoBox';

/**
 * Bar chart component
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function BarChart(props) {
  const { title, height, data, categories, gap, axisLabel, infoText, isTooltipVisible = false } = props;
  const chartSeries = data.map((dataItem) => (
    <ChartSeriesItem
      key={dataItem.name}
      color={dataItem.color || undefined}
      border={{
        color: dataItem.border || undefined,
      }}
      type={props.type || 'bar'}
      // stack={true}
      gap={gap}
      data={dataItem.data}
      name={dataItem.name}
    >
      <ChartSeriesItemTooltip
        // format={"{0}"}
        render={({ point }) => <span>{point.value}</span>}
        visible={isTooltipVisible}
      />
    </ChartSeriesItem>
  ));

  return (
    <ChartCard height={height}>
      {infoText && <InfoBox infoText={infoText} position={'topRight'} />}
      <Chart>
        <ChartTitle color="#505358" font="16px Avenir" text={title} />
        <ChartLegend position="bottom" orientation="horizontal">
          <ChartLegendItem
            visual={(e) => {
              const color = e.options?.markers?.background;
              const labelColor = e.options?.labels?.color;
              const border = e.series?.border?.color;
              const rect = new geometry.Rect([0, 0], [175, 50]);
              const layout = new Layout(rect, {
                spacing: 5,
                wrap: false,
                alignItems: 'center',
              });

              const marker = new Path({
                fill: {
                  color,
                },
                stroke: {
                  color: border || color,
                  width: 1,
                },
              })
                .moveTo(18, 6)
                .lineTo(18, 11)
                .lineTo(0, 11)
                .lineTo(0, 6)
                .close();

              const label = new Text(e.series.name, [0, 0], {
                font: '12px Avenir',
                fill: {
                  color: labelColor,
                },
              });

              layout.append(marker, label);
              layout.reflow();

              return layout;
            }}
          />
        </ChartLegend>
        <ChartArea height={height - 20} />
        <ChartCategoryAxis>
          <ChartCategoryAxisItem categories={categories} labels={{ rotation: 'auto', step: getLabelStep(categories) }}>
            {axisLabel && <ChartCategoryAxisTitle text={axisLabel} />}
          </ChartCategoryAxisItem>
        </ChartCategoryAxis>
        <ChartSeries>{chartSeries}</ChartSeries>
      </Chart>
    </ChartCard>
  );
}

BarChart.propTypes = {
  height: PropTypes.number.isRequired,
  title: PropTypes.string,
  /**
   * array of next structure: name, data
   */
  data: PropTypes.array.isRequired,

  /**
   * array of categories values, like "Feb 01", or "65-70"
   */
  categories: PropTypes.array.isRequired,

  /**
   * Type of Bar chart: column or bar
   */
  type: PropTypes.string,
};

export default BarChart;
