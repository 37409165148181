import useMediaQuery from '@material-ui/core/useMediaQuery';
import React from 'react';
import ContentLoader from 'react-content-loader';

export function ContentSkeletonLoading(props) {
  return (
    <ContentLoader
      // height={54}
      // width={320}
      speed={0.8}
      viewBox="0 0 320 340"
      backgroundColor="#ecebeb"
      foregroundColor="#f3f3f3"
      {...props}
    >
      {/* Image Title */}
      <rect x="20" y="20" rx="3" ry="3" width="50" height="8" />
      {/* Image Upload */}
      <rect x="100" y="40" rx="3" ry="3" width="120" height="120" />
      {/* Image text inputs */}
      <rect x="20" y="180" rx="3" ry="3" width="280" height="8" />
      <rect x="10" y="200" rx="0" ry="0" width="300" height="0.5" />

      {/* Details Title */}
      <rect x="20" y="215" rx="3" ry="3" width="50" height="8" />

      {/* Details text inputs */}
      <rect x="20" y="240" rx="3" ry="3" width="280" height="8" />

      <rect x="20" y="260" rx="3" ry="3" width="280" height="50" />
      <rect x="258" y="320" rx="3" ry="3" width="40" height="12" />
      <rect x="20" y="320" rx="3" ry="3" width="40" height="12" />
    </ContentLoader>
  );
}

export function VideoContentSkeletonLoading(props) {
  return (
    <ContentLoader
      // height={54}
      // width={320}
      speed={0.8}
      viewBox="0 0 320 535"
      backgroundColor="#ecebeb"
      foregroundColor="#f3f3f3"
      {...props}
    >
      {/* Details Section */}
      <rect x="20" y="20" rx="3" ry="3" width="50" height="13" />
      {/* Title input */}
      <rect x="40" y="50" rx="3" ry="3" width="240" height="8" />
      {/* Description textarea */}
      <rect x="40" y="70" rx="3" ry="3" width="240" height="160" />

      {/* Label Image Upload */}
      <rect x="135" y="240" rx="3" ry="3" width="50" height="10" />
      {/* Image Upload */}
      <rect x="40" y="260" rx="3" ry="3" width="240" height="60" />

      {/* Label Image Upload */}
      <rect x="135" y="330" rx="3" ry="3" width="50" height="10" />
      {/* Image Upload */}
      <rect x="40" y="350" rx="3" ry="3" width="240" height="60" />

      {/* Divider */}
      <rect x="20" y="430" rx="0" ry="0" width="280" height="0.5" />

      {/* Video settings section */}
      <rect x="20" y="450" rx="3" ry="3" width="50" height="13" />
      {/* Setting inputs */}
      <rect x="20" y="470" rx="3" ry="3" width="85" height="8" />
      <rect x="118" y="470" rx="3" ry="3" width="85" height="8" />
      <rect x="215" y="470" rx="3" ry="3" width="85" height="8" />
      {/* Setting toggles */}
      <rect x="20" y="485" rx="3" ry="3" width="40" height="8" />
      <rect x="70" y="485" rx="3" ry="3" width="40" height="8" />
      <rect x="120" y="485" rx="3" ry="3" width="180" height="8" />

      <rect x="260" y="505" rx="3" ry="3" width="40" height="15" />
      <rect x="20" y="505" rx="3" ry="3" width="40" height="15" />
    </ContentLoader>
  );
}

export function ArticlesContentSkeletonLoading(props) {
  return (
    <ContentLoader
      // height={54}
      // width={320}
      speed={0.8}
      viewBox="0 0 320 670"
      backgroundColor="#ecebeb"
      foregroundColor="#f3f3f3"
      {...props}
    >
      {/* Details Section */}
      <rect x="20" y="20" rx="3" ry="3" width="50" height="13" />
      {/* Title input */}
      <rect x="40" y="50" rx="3" ry="3" width="240" height="8" />
      {/* Subtitle input */}
      <rect x="40" y="65" rx="3" ry="3" width="240" height="8" />
      {/* Description textarea */}
      <rect x="40" y="90" rx="3" ry="3" width="240" height="160" />

      {/* Image Upload */}
      <rect x="100" y="270" rx="3" ry="3" width="120" height="60" />
      {/* Image Url */}
      <rect x="40" y="340" rx="3" ry="3" width="240" height="8" />

      {/* Label Video Upload */}
      <rect x="135" y="360" rx="3" ry="3" width="50" height="10" />
      {/* Video Upload */}
      <rect x="100" y="380" rx="3" ry="3" width="120" height="60" />

      {/* Label Video Thumbnail Upload */}
      <rect x="135" y="450" rx="3" ry="3" width="50" height="10" />
      {/* Video Thumbnail Upload */}
      <rect x="100" y="470" rx="3" ry="3" width="120" height="60" />

      {/* Divider */}
      <rect x="20" y="550" rx="0" ry="0" width="280" height="0.5" />

      {/* Video settings section */}
      <rect x="20" y="570" rx="3" ry="3" width="50" height="13" />
      {/* Setting inputs */}
      <rect x="20" y="590" rx="3" ry="3" width="85" height="8" />
      <rect x="118" y="590" rx="3" ry="3" width="85" height="8" />
      <rect x="215" y="590" rx="3" ry="3" width="85" height="8" />
      {/* Setting inputs */}
      <rect x="20" y="605" rx="3" ry="3" width="85" height="8" />
      <rect x="118" y="605" rx="3" ry="3" width="85" height="8" />
      <rect x="240" y="605" rx="3" ry="3" width="40" height="8" />
      <rect x="20" y="620" rx="3" ry="3" width="280" height="8" />

      <rect x="260" y="640" rx="3" ry="3" width="40" height="15" />
      <rect x="20" y="640" rx="3" ry="3" width="40" height="15" />
    </ContentLoader>
  );
}

export function VideoContentSkeletonLoadingMobile(props) {
  return (
    <ContentLoader
      // height={54}
      // width={320}
      speed={0.8}
      viewBox="0 0 300 1180"
      backgroundColor="#ecebeb"
      foregroundColor="#f3f3f3"
      {...props}
    >
      {/* Details Title */}
      <rect x="40" y="40" rx="3" ry="3" width="70" height="15" />
      {/* Title input */}
      <rect x="40" y="80" rx="3" ry="3" width="230" height="10" />
      {/* Description textarea */}
      <rect x="40" y="100" rx="3" ry="3" width="230" height="350" />

      {/* Label Image Upload */}
      <rect x="105" y="470" rx="3" ry="3" width="100" height="15" />
      {/* Image Upload */}
      <rect x="40" y="500" rx="3" ry="3" width="230" height="180" />

      {/* Label Image Upload */}
      <rect x="105" y="700" rx="3" ry="3" width="100" height="15" />
      {/* Image Upload */}
      <rect x="40" y="730" rx="3" ry="3" width="230" height="180" />

      {/* Divider */}
      <rect x="40" y="940" rx="0" ry="0" width="230" height="0.5" />

      {/* Video Settings Section */}
      <rect x="40" y="970" rx="3" ry="3" width="70" height="15" />
      {/* Setting inputs */}
      <rect x="40" y="1000" rx="3" ry="3" width="230" height="10" />
      <rect x="40" y="1020" rx="3" ry="3" width="230" height="10" />
      <rect x="40" y="1040" rx="3" ry="3" width="230" height="10" />
      {/* Setting toggles */}
      <rect x="120" y="1060" rx="3" ry="3" width="60" height="10" />
      <rect x="120" y="1080" rx="3" ry="3" width="60" height="10" />
      <rect x="40" y="1100" rx="3" ry="3" width="230" height="10" />

      {/* Action buttons */}
      <rect x="40" y="1140" rx="3" ry="3" width="60" height="20" />
      <rect x="210" y="1140" rx="3" ry="3" width="60" height="20" />
    </ContentLoader>
  );
}

export function ArticlesContentSkeletonLoadingMobile(props) {
  return (
    <ContentLoader
      // height={54}
      // width={320}
      speed={0.8}
      viewBox="0 0 300 1480"
      backgroundColor="#ecebeb"
      foregroundColor="#f3f3f3"
      {...props}
    >
      {/* Details Title */}
      <rect x="40" y="40" rx="3" ry="3" width="70" height="15" />
      {/* Title input */}
      <rect x="40" y="80" rx="3" ry="3" width="230" height="10" />
      {/* Subtitle input */}
      <rect x="40" y="100" rx="3" ry="3" width="230" height="10" />
      {/* Description textarea */}
      <rect x="40" y="130" rx="3" ry="3" width="230" height="350" />

      {/* Image Upload */}
      <rect x="40" y="510" rx="3" ry="3" width="230" height="180" />
      {/* Image Url */}
      <rect x="40" y="705" rx="3" ry="3" width="230" height="10" />

      {/* Label Video Upload */}
      <rect x="105" y="740" rx="3" ry="3" width="100" height="15" />
      {/* Video Upload */}
      <rect x="40" y="770" rx="3" ry="3" width="230" height="180" />

      {/* Label Video Thumbnail Upload */}
      <rect x="105" y="980" rx="3" ry="3" width="100" height="15" />
      {/* Video Thumbnail Upload */}
      <rect x="40" y="1010" rx="3" ry="3" width="230" height="180" />

      {/* Divider */}
      <rect x="40" y="1220" rx="0" ry="0" width="230" height="0.5" />

      {/* Video Settings Section */}
      <rect x="40" y="1250" rx="3" ry="3" width="70" height="15" />
      {/* Setting inputs */}
      <rect x="40" y="1280" rx="3" ry="3" width="230" height="10" />
      <rect x="40" y="1300" rx="3" ry="3" width="230" height="10" />
      <rect x="40" y="1320" rx="3" ry="3" width="230" height="10" />
      <rect x="40" y="1340" rx="3" ry="3" width="230" height="10" />
      <rect x="40" y="1360" rx="3" ry="3" width="230" height="10" />
      <rect x="120" y="1385" rx="3" ry="3" width="60" height="10" />
      <rect x="40" y="1410" rx="3" ry="3" width="230" height="10" />

      {/* Action buttons */}
      <rect x="40" y="1440" rx="3" ry="3" width="60" height="20" />
      <rect x="210" y="1440" rx="3" ry="3" width="60" height="20" />
    </ContentLoader>
  );
}

export function ContentSkeletonLoadingMobile(props) {
  return (
    <ContentLoader
      // height={54}
      // width={320}
      speed={0.8}
      viewBox="0 0 300 700"
      backgroundColor="#ecebeb"
      foregroundColor="#f3f3f3"
      {...props}
    >
      {/* Image Title */}
      <rect x="40" y="60" rx="3" ry="3" width="70" height="15" />
      {/* Image Upload */}
      <rect x="60" y="110" rx="3" ry="3" width="180" height="180" />
      {/* Image text inputs */}
      <rect x="40" y="350" rx="3" ry="3" width="230" height="10" />
      <rect x="30" y="380" rx="0" ry="0" width="240" height="2" />

      {/* Details Title */}
      <rect x="40" y="420" rx="3" ry="3" width="70" height="15" />
      {/* Details text inputs */}
      <rect x="40" y="480" rx="3" ry="3" width="230" height="10" />
      <rect x="40" y="520" rx="3" ry="3" width="230" height="150" />
    </ContentLoader>
  );
}

export function ChartSkeletonLoading(props) {
  return (
    <ContentLoader
      speed={0.8}
      viewBox="0 0 180 400"
      height={300}
      width={180}
      backgroundColor="#ecebeb"
      foregroundColor="#f3f3f3"
      {...props}
      style={{ width: '100%' }}
    >
      <rect x="20" y="5" rx="0" ry="0" width="1" height="170" />
      <rect x="20" y="175" rx="0" ry="0" width="360" height="1" />

      <rect x="40" y="75" rx="0" ry="0" width="35" height="100" />
      <rect x="80" y="125" rx="0" ry="0" width="35" height="50" />
      <rect x="120" y="105" rx="0" ry="0" width="35" height="70" />
      <rect x="160" y="35" rx="0" ry="0" width="35" height="140" />
      <rect x="200" y="55" rx="0" ry="0" width="35" height="120" />
      <rect x="240" y="15" rx="0" ry="0" width="35" height="160" />
      <rect x="280" y="135" rx="0" ry="0" width="35" height="40" />
      <rect x="320" y="85" rx="0" ry="0" width="35" height="90" />
    </ContentLoader>
  );
}

export function CardSkeletonLoading(props) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up(600));
  return (
    <ContentLoader
      speed={0.8}
      viewBox="0 0 100% 100"
      backgroundColor="#ecebeb"
      foregroundColor="#f3f3f3"
      style={{ width: '100%' }}
      {...props}
    >
      {matches ? (
        <>
          {/* Icon */}
          <rect x="16" y="16" rx="20" ry="20" width="20%" height="130" />
          {/* Title */}
          <rect x="30%" y="16" rx="3" ry="3" width="40%" height="20" />
          {/* Count */}
          <rect x="30%" y="50" rx="3" ry="3" width="70" height="35" />
          {/* Description */}
          <rect x="30%" y="100" rx="3" ry="3" width="40%" height="15" />
          <rect x="30%" y="130" rx="3" ry="3" width="40%" height="15" />
        </>
      ) : (
        <>
          {/* Icon */}
          <rect x="10" y="12" rx="15" ry="15" width="92%" height="25%" />
          {/* Title */}
          <rect x="20" y="70" rx="3" ry="3" width="60%" height="20" />
          {/* Count */}
          <rect x="20" y="100" rx="3" ry="3" width="85" height="35" />
          {/* Description */}
          <rect x="20" y="140" rx="3" ry="3" width="40%" height="15" />
        </>
      )}
    </ContentLoader>
  );
}

export function SystemTotalsCardSkeletonLoading(props) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up(600));
  return (
    <ContentLoader
      speed={0.8}
      width="350"
      height={matches ? '50' : '80'}
      viewBox="0 0 100% 50"
      backgroundColor="#ecebeb"
      foregroundColor="#f3f3f3"
      style={{ width: '100%' }}
      {...props}
    >
      {matches ? (
        <>
          {/* Count */}
          <rect x="25" y="15" rx="3" ry="3" width="31%" height="50" />
          {/* Title */}
          <rect x="48%" y="20" rx="3" ry="3" width="90" height="25" />
        </>
      ) : (
        <>
          {/* Title */}
          <rect x="17" y="9" rx="3" ry="3" width="40%" height="20" />
          {/* Count */}
          <rect x="17" y="50%" rx="3" ry="3" width="40%" height="35%" />
        </>
      )}
    </ContentLoader>
  );
}

export function InfoCardSkeletonLoading(props) {
  return (
    <ContentLoader
      speed={0.8}
      width="350"
      height="170"
      viewBox="0 0 100% 170"
      backgroundColor="#ecebeb"
      foregroundColor="#f3f3f3"
      style={{ width: '100%' }}
      {...props}
    >
      {/* <rect x="0" y="0" rx="30" ry="30" width="100%" height="170" /> */}

      {/* Count */}
      <rect x="25" y="30" rx="3" ry="3" width="70" height="35" />
      {/* Title */}
      <rect x="25" y="95" rx="3" ry="3" width="130" height="15" />
      {/* Subtitle */}
      <rect x="25" y="125" rx="3" ry="3" width="150" height="10" />
    </ContentLoader>
  );
}
