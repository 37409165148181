import { Grid } from '@material-ui/core';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Input } from '@progress/kendo-react-inputs';
import { Error } from '@progress/kendo-react-labels';
import React, { useState, useCallback } from 'react';
import utils from '../../Shared/Utils/utils';

function AddPermissionDialog(props) {
  const { closeDialog, onSubmit } = props;
  const [currentValue, setCurrentValue] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [validationMessage, setValidationMessage] = useState('');

  const handleCancel = () => {
    setValidationMessage('');
    setCurrentValue('');
    closeDialog();
  };

  const onChange = useCallback((e) => {
    setValidationMessage('');
    setCurrentValue(e.target.value);
  }, []);

  const handleSubmit = useCallback(async () => {
    if (onSubmit) {
      setSubmitting(true);

      if (!utils.validateEmail(currentValue)) {
        setValidationMessage('Invalid email');
      } else {
        await onSubmit(currentValue);
        handleCancel();
      }
      setSubmitting(false);
    }
  }, [currentValue, onSubmit]);

  return (
    <Dialog title="New Account Admin Creation" onClose={handleCancel} width={400} height={225}>
      <Grid>
        <Input
          width="100%"
          value={currentValue}
          onChange={onChange}
          required
          label="User email"
          name="userId"
          autoComplete="off"
          style={{ width: '100%' }}
        />
        {validationMessage && <Error id={'test'}>{validationMessage}</Error>}
      </Grid>
      <DialogActionsBar>
        <button className="k-button" onClick={handleCancel}>
          Cancel
        </button>
        <button className="k-button" onClick={handleSubmit} disabled={!currentValue || submitting}>
          Submit
        </button>
      </DialogActionsBar>
    </Dialog>
  );
}

export default AddPermissionDialog;
