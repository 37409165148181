import React, { useState } from 'react';

import './index.css';
import { Layer, Rect, Stage } from 'react-konva';

import { DEFAULTS, SHAPE_TYPES } from './constants';
import Shape from './Shape';

const handleDragOver = (event) => event.preventDefault();

function Canvas(props) {
  const { screenshotUrl, selectedShapeId, setSelectedShapeId, shapes, setShapes, onHandleAddShape } = props;

  const clearSelection = () => {
    setSelectedShapeId(null);
  };

  const [currentShapes, setCurrentShapes] = useState([]);
  const [newShape, setNewShape] = useState([]);
  const [shapePosOnPage, setShapePosOnPage] = useState(null);

  // useEffect(() => {
  //   const getDrawEnd = (e) => {
  //     setShapePosOnPage({ x: e.x, y: e.y });
  //   };
  //   window.addEventListener("mousemove", getDrawEnd);
  //
  //   // returned function will be called on component unmount
  //   return () => {
  //     window.removeEventListener("mousemove", getDrawEnd);
  //   };
  // }, []);

  const handleMouseDown = (event) => {
    if (!selectedShapeId && !event.target.id() && newShape.length === 0) {
      const { x, y } = event.target.getStage().getPointerPosition();
      setNewShape([
        {
          x,
          y,
          width: 0,
          height: 0,
          key: '0',
        },
      ]);
      // set x coordinate on start pain shape
      setShapePosOnPage({ ...shapePosOnPage, x });
    }
  };

  const handleMouseMove = (event) => {
    if (newShape.length === 1) {
      const sx = newShape[0].x;
      const sy = newShape[0].y;
      const { x, y } = event.target.getStage().getPointerPosition();
      setNewShape([
        {
          x: sx,
          y: sy,
          width: x - sx,
          height: y - sy,
          key: '0',
        },
      ]);
    }
  };

  const handleMouseUp = (event) => {
    if (newShape.length === 1) {
      const sx = newShape[0].x;
      const sy = newShape[0].y;
      const { x, y } = event.target.getStage().getPointerPosition();
      // const bottomY = y + (y - sy) + 55;
      const bottomY = y + 55;

      const shapeToAdd = {
        x: parseInt(sx),
        y: parseInt(sy),
        width: parseInt(x - sx),
        height: parseInt(y - sy),
        key: currentShapes.length + 1,
        type: SHAPE_TYPES.RECT,
        fill: DEFAULTS.RECT.FILL,
        stroke: DEFAULTS.RECT.STROKE,
        rotation: DEFAULTS.RECT.ROTATION,
        onPageX: parseInt(shapePosOnPage.x),
        onPageY: parseInt(bottomY),
      };

      if (shapeToAdd.width && shapeToAdd.height) {
        onHandleAddShape(shapeToAdd);
        currentShapes.push(shapeToAdd);
        setCurrentShapes(currentShapes);
      }
      // set y coordinate on finish pain shape
      setShapePosOnPage({ ...shapePosOnPage, y: bottomY });
      setNewShape([]);
    }
  };

  const transformRectangleShape = (transformedShape) => {
    const updatedShapes = shapes.map((item) => {
      if (item.id === selectedShapeId) {
        const updateBottomY = transformedShape.y + transformedShape.height + 55;
        item = {
          ...item,
          height: parseInt(transformedShape.height),
          width: parseInt(transformedShape.width),
          x: parseInt(transformedShape.x),
          y: parseInt(transformedShape.y),
          onPageX: parseInt(transformedShape.x),
          onPageY: parseInt(updateBottomY),
        };
      }
      return item;
    });
    setShapes(updatedShapes);
  };

  const shapesToDraw = [...newShape];

  return (
    <main
      className="canvas"
      style={{
        backgroundImage:
          // "url(https://images.indianexpress.com/2017/04/nature-tree_759.jpg)",
          `url(${screenshotUrl})`,
      }}
      onDragOver={handleDragOver}
    >
      <Stage
        width={376}
        height={window.innerHeight}
        onClick={clearSelection}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
      >
        <Layer>
          {shapes.map((shape) => (
            <Shape
              id={shape.id}
              key={shape.id}
              shape={{ ...shape, id: shape.id }}
              selectedShapeId={selectedShapeId}
              setSelectedShapeId={setSelectedShapeId}
              transformRectangleShape={transformRectangleShape}
            />
          ))}
        </Layer>
        <Layer>
          {shapesToDraw.map((value) => (
            <Rect
              x={value.x}
              y={value.y}
              width={value.width}
              height={value.height}
              fill="transparent"
              stroke={DEFAULTS.RECT.STROKE}
            />
          ))}
        </Layer>
      </Stage>
    </main>
  );
}

export default Canvas;
