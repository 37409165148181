/* eslint-disable no-unused-vars */
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';

// redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import MonitoringStyles from '../../../assets/jss/components/MonitoringStyles';
import DashboardCardContainer from '../../Shared/Charts/DashboardCardContainer';
import OutcomeChart from '../../Shared/Charts/OutcomeChart';

import { transform } from './helpers/MonitoringListMapper';
import * as monitoringActions from './MonitoringActions';

// component and helper imports
import OutcomeToDoList from './OutcomeToDoList';

// material- ui

// test and instructions. In-progress are prescription, v_consultation (telehealth request) and alarm
const dummyData = {
  test: {
    data: {
      total: 10,
      toDo: 5,
    },
  },
  instruction: {
    data: {
      total: 12,
      toDo: 7,
    },
  },
  prescription: {
    data: {
      total: 17,
      toDo: 4,
    },
  },
  vConsultation: {
    data: {
      total: 15,
      toDo: 9,
    },
  },
  alarm: {
    data: {
      total: 17,
      toDo: 4,
    },
  },
};

export function MonitoringPageWrapper() {
  return (
    <Grid container justifyContent="flex-start" spacing={3}>
      <Grid item xs={12} sm={6} lg={3}>
        <DashboardCardContainer title="Tests Outcome">
          <OutcomeChart data={transform.outcomePieChart(dummyData.test.data)} />
        </DashboardCardContainer>
      </Grid>

      <Grid item xs={12} sm={6} lg={3}>
        <DashboardCardContainer title="Instruction Outcome">
          <OutcomeChart data={transform.outcomePieChart(dummyData.instruction.data)} />
        </DashboardCardContainer>
      </Grid>

      <Grid item xs={12} sm={6} lg={3}>
        <DashboardCardContainer title="Prescription Outcome">
          <OutcomeChart data={transform.outcomePieChart(dummyData.prescription.data)} />
        </DashboardCardContainer>
      </Grid>

      <Grid item xs={12} sm={6} lg={3}>
        <DashboardCardContainer title="V_Consultation Outcome">
          <OutcomeChart data={transform.outcomePieChart(dummyData.vConsultation.data)} />
        </DashboardCardContainer>
      </Grid>

      <Grid item xs={12} sm={6} lg={3}>
        <DashboardCardContainer title="Alarm Outcome">
          <OutcomeChart data={transform.outcomePieChart(dummyData.alarm.data)} />
        </DashboardCardContainer>
      </Grid>

      <OutcomeToDoList />
    </Grid>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(monitoringActions, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(withStyles(MonitoringStyles)(MonitoringPageWrapper));
