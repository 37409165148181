/* eslint-disable jsx-a11y/iframe-has-title */
import { useTheme } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React, { useState } from 'react';

import AssessmentWrapper from './AssessmentWrapper';

function LabReportTabs(props) {
  const { encounterData, diagnosticReportInfo } = props;
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  let pageComponent;

  const handleChange = (e, value) => {
    setValue(value);
  };

  switch (value) {
    case 0:
      pageComponent = <AssessmentWrapper encounterData={encounterData} diagnosticReportInfo={diagnosticReportInfo} />;
      break;
    case 1:
      pageComponent = (
        <iframe
          src="https://www.siue.edu/~lmillio/IT598/Resources/04_assessment/Effective%20Online%20Instruction.pdf"
          width="100%"
          height="600"
        >
          This browser does not support PDFs.
        </iframe>
      );
      break;

    default:
      pageComponent = <AssessmentWrapper encounterData={encounterData} diagnosticReportInfo={diagnosticReportInfo} />;
      break;
  }

  return (
    <>
      {matches ? (
        <Tabs
          value={value}
          indicatorColor="primary"
          variant="fullWidth"
          textColor="primary"
          onChange={handleChange}
          aria-label="disabled tabs example"
        >
          <Tab
            label="ASSESSMENT"
            id="assessment"
            style={{
              fontSize: '12px',
              marginBottom: '-10px',
              fontWeight: value === 0 ? 'bold' : '',
            }}
          />

          <Tab
            label="VIEW PDF"
            id="view-pdf"
            style={{
              fontSize: '12px',
              marginBottom: '-10px',
              fontWeight: value === 0 ? 'bold' : '',
            }}
          />
        </Tabs>
      ) : null}
      <div style={{ padding: '10px 0' }}>{pageComponent}</div>
    </>
  );
}

export default LabReportTabs;
