import { createSlice } from '@reduxjs/toolkit';

import api from '../../../api';
import { showNotification } from '../../Notifications/notificationSlice';
import { handleError } from '../../Shared/Content/APIUtils';

const initialState = {
  medicationList: null,
  medicationListLoading: null,
};

export const medicationListSlice = createSlice({
  name: 'medicationList',
  initialState,
  reducers: {
    setMedicationListLoading: (state, { payload }) => ({
      ...state,
      medicationListLoading: payload,
    }),
    setMedicationList: (state, { payload }) => ({
      ...state,
      medicationList: payload,
      medicationListLoading: false,
    }),
  },
});

export const { setMedicationList, setMedicationListLoading } = medicationListSlice.actions;

export const getMedicationList = (safeEMRId) => async (dispatch) => {
  dispatch(setMedicationListLoading(true));

  const [result, error] = await api.getMedicationListRequest(safeEMRId);

  if (result) {
    dispatch(setMedicationList(result));
  }

  if (error && error.message) {
    dispatch(handleError(error));
  }
};

export const postMedicationList = (safeEMRId, data) => async (dispatch) => {
  dispatch(setMedicationListLoading(true));

  const [result, error] = await api.postMedicationListRequest(safeEMRId, data);

  if (result) {
    dispatch(showNotification('Medication Details Successfully Sent', 'success'));
    dispatch(getMedicationList(safeEMRId));
  }

  if (error && error.message) {
    dispatch(handleError(error));
  }
};

export const postMedicationToDrfirst = (safeEMRId) => async (dispatch) => {
  dispatch(setMedicationListLoading(true));

  const [result, error] = await api.postMedicationToDrfirstRequest(safeEMRId);

  if (result) {
    dispatch(showNotification('Drfirst sync Successful', 'success'));
    dispatch(setMedicationListLoading(false));
  }

  if (error && error.message) {
    dispatch(handleError(error));
  }
};

export default medicationListSlice.reducer;
