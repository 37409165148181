export const columns = () => [
  {
    id: 'userId',
    label: 'User ID',
    show: true,
    minWidth: 200,
    locked: true,
  },
  {
    id: 'message',
    label: 'Message',
    show: true,
    sortable: false,
    minWidth: 500,
  },
  {
    id: 'time',
    label: 'Time',
    show: true,
    minWidth: 140,
    sortable: true,
  },
];
