// import { filter } from '@progress/kendo-data-query/dist/npm/transducers';

// import { getCurrentQuestionnaireQuestion } from '../../vcb-package/questionnaireSlice';

import { languageOptions } from './languageOptions';

const questionType = [
  {
    value: 'address',
    label: 'Address',
  },
  {
    value: 'consent',
    label: 'Consent Form',
  },
  {
    value: 'date',
    label: 'Date',
  },
  {
    value: 'email',
    label: 'Email',
  },
  {
    value: 'graphicSignature',
    label: 'Graphic Signature',
  },
  {
    value: 'insurance',
    label: 'Insurance',
  },
  {
    value: 'labOrder',
    label: 'Lab Test Order',
  },
  {
    value: 'large-text',
    label: 'Long Text',
    rows: 3,
  },
  {
    value: 'multiple',
    label: 'Multiple Choice',
  },
  {
    value: 'combo',
    label: 'Multiple-question Combo Node',
  },
  {
    value: 'numeric',
    label: 'Number',
  },
  {
    value: 'password',
    label: 'Password',
  },
  {
    value: 'phone',
    label: 'Phone Number',
  },
  {
    value: 'photo',
    label: 'Photo Upload',
  },
  {
    value: 'scale',
    label: 'Scale',
  },
  {
    value: 'slider',
    label: 'Slider',
  },
  {
    value: 'small-text',
    label: 'Short Text',
  },
  {
    value: 'multiple-small-text',
    label: 'Text with Multiple Blanks',
  },
  {
    value: 'yes-no',
    label: 'Yes/No',
  },
  {
    value: 'zip',
    label: 'Zip Code',
  },
];

const validationTypes = ['None', 'Email', 'Phone', 'SSN', 'URL', 'Zip', 'Other'];

const datePrecisionType = [
  {
    value: 'DAYS',
    label: 'Days',
  },
  {
    value: 'WEEKS',
    label: 'Weeks',
  },
  {
    value: 'MONTHS',
    label: 'Months',
  },
  {
    value: 'YEARS',
    label: 'Years',
  },
];

const dateBeforeAfter = [
  {
    value: -1,
    label: 'Before',
  },
  {
    value: 1,
    label: 'After',
  },
];

const styleList = [
  {
    value: 'style1',
    label: 'Style 1',
  },
  {
    value: 'style2',
    label: 'Style 2',
  },
  {
    value: 'style3',
    label: 'Style 3',
  },
  {
    value: 'hide',
    label: 'Hidden',
  },
];

// commenting out future auto answers (currently not being used)
const autoAnswerTypes = [
  {
    value: null,
    label: 'None',
    type: 'none',
  },

  // provides answer of type AnswerDate
  {
    value: 'age',
    label: 'Age',
    type: 'date',
  },

  // provides AnswerYesNo (if patient have an allergy or not)
  {
    value: 'allergy',
    label: 'Allergy',
    type: 'yes-no',
  },

  // provides answer AnswerYesNo
  {
    value: 'has-condition',
    label: 'Condition',
    type: 'yes-no',
  },

  // provides answer AnswerYesNo (if a patient takes following medication or no)
  {
    value: 'medication',
    label: 'Medication',
    type: 'yes-no',
  },

  // provides AnswerDate (with the last date when the patient took this vaccine)
  {
    value: 'vaccination-last-date',
    label: 'Vaccination Date',
    type: 'date',
  },
  // provides AnswerNumber (how many times the patient was vaccinated with)
  {
    value: 'vaccination-number',
    label: 'Vaccination Number',
    type: 'numeric',
  },

  // provides answer AnswerMultiple with values of test status
  // [INITIALIZED,PRE_ORDERED,UNMATCHED,CANCELLED,REJECTED,COMPLETED]
  {
    value: 'test-status-covid',
    label: 'Covid test status',
    type: 'multiple',
  },

  // provides AnswerMultiple with test result [UNKNOWN, INCONCLUSIVE, POSITIVE, NEGATIVE]
  {
    value: 'test-result-covid',
    label: 'Covid test result',
    type: 'multiple',
  },

  // currenlty not working, can’t create answer from PatientRecord responce
  // {
  //   value: "test-result",
  //   label: "Test result",
  //   type: "multiple",
  // },

  {
    value: 'gender',
    label: 'Gender',
    type: 'multiple',
  },

  // Returns age at which last time vaccination with code was performed
  {
    value: 'age-at-last-vaccination',
    label: 'Age at last vaccination',
    type: 'date',
  },

  // Returns yes-no if current date between specified dates
  {
    value: 'now-between-dates',
    label: 'Now between dates',
    type: 'yes-no',
  },
];

const medCodeTypes = [
  {
    value: 'loinccode',
    label: 'LOINC',
  },
  {
    value: 'cptcode',
    label: 'CPT',
  },
  {
    value: 'icdcode',
    label: 'ICD',
  },
  {
    value: 'prescribabledrug',
    label: 'NDC',
  },
  {
    value: 'snomedcode',
    label: 'SNOMED',
  },
  {
    value: 'masterspecimen',
    label: 'Master Specimen',
  },
  {
    value: 'modifers',
    label: 'Modifiers',
  },
];

const instructionType = [
  {
    value: 'Alert',
    label: 'Alert',
  },
  {
    value: 'Warning',
    label: 'Warning',
  },
  {
    value: 'Instruction',
    label: 'Instruction',
  },
  {
    value: 'Other',
    label: 'Other',
  },
];

const statusType = [
  {
    value: 'ACTIVE',
    label: 'Active',
  },
  {
    value: 'DISABLED',
    label: 'Disabled',
  },
];

export const getFieldName = (system) => {
  let fieldName;
  switch (system) {
    case 'loinccode':
      fieldName = 'component';
      break;
    case 'cptcode':
      fieldName = 'descriptor';
      break;
    case 'icdcode':
      fieldName = 'longDescription';
      break;
    case 'prescribabledrug':
      fieldName = 'drugName';
      break;
    case 'snomedcode':
      fieldName = 'term';
      break;
    case 'masterspecimen':
      fieldName = 'specimenDescription';
      break;
    case 'modifers':
      fieldName = 'hcpcsModifierDescription';
      break;
    default:
      fieldName = '';
  }
  return fieldName;
};

export const getCodeName = (system) => {
  let codeName;
  switch (system) {
    case 'loinccode':
      codeName = 'code';
      break;
    case 'cptcode':
      codeName = 'code';
      break;
    case 'icdcode':
      codeName = 'code';
      break;
    case 'prescribabledrug':
      codeName = 'drugCode';
      break;
    case 'snomedcode':
      codeName = 'conceptID';
      break;
    case 'masterspecimen':
      codeName = 'codeValue';
      break;
    case 'modifers':
      codeName = 'hcpcsModifierId';
      break;
    default:
      codeName = '';
  }
  return codeName;
};

export const questionBuilder = (formData, questionList) => [
  {
    type: 'group',
    label: 'QUESTION SETUP',
    name: 'QUESTION SETTINGS',
    fields: [
      {
        type: 'autocomplete',
        name: 'status',
        label: 'Status',
        required: true,
        width: 4,
        enum: statusType.map((k) => ({
          value: k.value,
          label: k.label,
        })),
        labelField: 'label',
        valueField: 'value',
        initialValue: 'ACTIVE',
        disableClear: true,
      },
      {
        type: 'multiSelectTypeAhead',
        name: 'languageChoices',
        label: 'Select Additional Languages',
        required: false,
        width: formData?.languageChoices?.length > 2 ? 12 : 5,
        typeahead: languageOptions,
      },
      {
        type: formData.languageChoices && formData.languageChoices.length ? 'autocomplete' : 'hidden',
        name: 'workingLanguage',
        label: 'Editing Language',
        required: false,
        width: 3,
        enum: formData.languageChoices
          ? formData.languageChoices.map((k) => ({
              value: k.split(' = ')[0],
              label: k.split(' = ')[1],
            }))
          : [],
        labelField: 'label',
        valueField: 'value',
      },
      {
        type: 'text',
        name: 'note',
        label: 'Administrator notes',
        width: 12,
        rows: 2,
        required: false,
      },
      {
        type: 'autocomplete',
        name: 'type',
        label: 'Question Type',
        required: true,
        width: 12,
        enum: questionType.map((k) => ({
          value: k.value,
          label: k.label,
        })),
        labelField: 'label',
        valueField: 'value',
        disableClear: true,
      },
      {
        type: 'text',
        name: 'shortName',
        label: 'Short Name',
        required: true,
        width: 12,
        rows: 1,
      },
      {
        type: 'text',
        name: formData.workingLanguage && formData.showTranslation ? `name-${formData.workingLanguage}` : 'name',
        label: 'Question',
        required: true,
        width: 8,
        rows: 2,
        checkScriptExecution: true,
      },
      {
        type: formData.languageChoices && formData.languageChoices.length ? 'toggle' : 'hidden',
        name: 'showQuestionTranslation',
        label: 'Edit Other Language',
        required: false,
        readOnly: !formData.workingLanguage,
        width: 2,
        labelPlacement: 'top',
        linkField: 'showTranslation',
      },
      {
        type: formData.languageChoices && formData.languageChoices.length ? 'toggle' : 'hidden',
        name: `nameValid-${formData.workingLanguage}`,
        label: 'Translation Validated by Native Speaker',
        required: false,
        readOnly: !formData.showTranslation,
        width: 2,
        labelPlacement: 'top',
      },
      {
        type: 'group',
        name: 'featuredImage',
        label: 'Optional Featured Image',
        expanded: false,
        fields: [
          {
            type: 'imageCrop',
            name: 'image',
            required: false,
            width: 12,
            acceptedFiles: ['image/png', 'image/jpg', 'image/jpeg', 'image/gif'],
          },
        ],
      },
      {
        type: 'html',
        name:
          formData.workingLanguage && formData.showTranslation
            ? `description-${formData.workingLanguage}`
            : 'description',
        label: 'Description',
        required: false,
        width: 8,
        rows: 2,
        checkScriptExecution: true,
      },
      {
        type: formData.languageChoices && formData.languageChoices.length ? 'toggle' : 'hidden',
        name: 'showDescriptionTranslation',
        label: 'Edit Other Language',
        required: false,
        readOnly: !formData.workingLanguage,
        width: 2,
        shared: 'translationToggle',
        labelPlacement: 'top',
        linkField: 'showTranslation',
      },
      {
        type: formData.languageChoices && formData.languageChoices.length ? 'toggle' : 'hidden',
        name: `descriptionValid-${formData.workingLanguage}`,
        label: 'Translation Validated by Native Speaker',
        required: false,
        readOnly: !formData.showTranslation,
        width: 2,
        labelPlacement: 'top',
      },
      {
        type: 'text',
        name: formData.workingLanguage && formData.showTranslation ? `help-${formData.workingLanguage}` : 'help',
        label: 'Helper Text',
        required: false,
        width: 8,
      },
      {
        type: formData.languageChoices && formData.languageChoices.length ? 'toggle' : 'hidden',
        name: 'showHelperTranslation',
        label: 'Edit Other Language',
        required: false,
        readOnly: !formData.workingLanguage,
        width: 2,
        labelPlacement: 'top',
        linkField: 'showTranslation',
      },
      {
        type: formData.languageChoices && formData.languageChoices.length ? 'toggle' : 'hidden',
        name: `helpValid-${formData.workingLanguage}`,
        label: 'Translation Validated by Native Speaker',
        required: false,
        readOnly: !formData.showTranslation,
        width: 2,
        labelPlacement: 'top',
      },
      {
        type: 'multiselecttag',
        name: 'tags',
        label: 'Tags',
        required: false,
        width: 12,
        allowCustom: true,
      },
      {
        type: 'toggle',
        name: 'commentEnabled',
        label: formData.type === 'multiple' ? 'Allow comments on options' : 'Enable question comment',
        required: false,
        width: 6,
        labelPlacement: 'end',
      },
      {
        type: formData.type === 'phone' ? 'toggle' : 'hidden',
        name: 'validate',
        label: 'Validate phone number via SMS?',
        required: false,
        width: 3,
        labelPlacement: 'end',
      },
      {
        type: formData.type === 'email' ? 'toggle' : 'hidden',
        name: 'validate',
        label: 'Validate Email with six-digit code?',
        required: false,
        width: 3,
        labelPlacement: 'end',
      },
    ],
  },
  {
    type: 'group',
    label: 'REPORTING',
    name: 'REPORTING',
    fields: [
      {
        type: 'text',
        name: 'reporterTarget',
        label: 'Target',
        required: formData.reporterFormat,
        width: 12,
      },
      {
        type: 'text',
        name: 'reporterFormat',
        label: 'Format',
        required: false,
        width: 12,
      },
    ],
  },
  {
    type: 'group',
    label: 'QUESTION MEDICAL CODE',
    name: 'QUESTION MEDICAL CODE',
    fields: medicalCodes(formData, 'question'),
  },
  {
    type:
      formData.type === 'date' ||
      formData.type === 'multiple' ||
      formData.type === 'numeric' ||
      formData.type === 'yes-no'
        ? 'group'
        : 'hidden',
    label: 'QUESTION AUTO ANSWER',
    name: 'QUESTION AUTO ANSWER',
    fields: [
      {
        type: 'autocomplete',
        name: 'autoAnswerType',
        label: 'Auto Answer Option',
        required: false,
        width: 12,
        enum: autoAnswerTypes.flatMap((k) =>
          k.type === formData.type
            ? [
                {
                  value: k.value,
                  label: k.label,
                },
              ]
            : []
        ),
        labelField: 'label',
        valueField: 'value',
      },
      {
        type: formData.autoAnswerType === 'now-between-dates' ? 'date' : 'hidden',
        name: 'start',
        label: 'Start',
        width: 6,
      },
      {
        type: formData.autoAnswerType === 'now-between-dates' ? 'date' : 'hidden',
        name: 'end',
        label: 'End',
        width: 6,
      },
    ],
  },
  {
    type: formData.type === 'multiple' || formData.type === 'multiple-drop' ? 'group' : 'hidden',
    label: 'ANSWER OPTIONS',
    name: 'ANSWER OPTIONS',
    fields: [
      {
        type: formData.type !== 'multiple-drop' ? 'toggle' : 'hidden',
        name: 'multiSelect',
        label: 'Allow multiple answer selection?',
        required: false,
        width: 12,
        labelPlacement: 'end',
      },
      {
        type: formData.type !== 'multiple-drop' ? 'toggle' : 'hidden',
        name: 'containsNone',
        label: "Contains 'None'?",
        required: false,
        width: 4,
        labelPlacement: 'end',
      },
      {
        type: formData.type !== 'multiple-drop' && formData.multiSelect ? 'toggle' : 'hidden',
        name: 'containsAll',
        label: "Contains 'All of the above'?",
        required: false,
        width: 4,
        labelPlacement: 'end',
      },
      {
        type: formData.multiSelect && formData.containsAll ? 'toggle' : 'hidden',
        name: 'selectAll',
        label: "Preselect 'All of the above'?",
        required: false,
        width: 4,
        labelPlacement: 'end',
      },
      {
        type: 'dynamicList',
        name: 'options',
        label: 'Option',
        required: true,
        width: 12,
        tooltip: 'Add New Option',
        sortable: true,
        expandable: true,
        titleField:
          formData.workingLanguage && formData.showTranslation ? `value-${formData.workingLanguage}` : 'value',
        fields: [
          {
            type: 'text',
            name: formData.workingLanguage && formData.showTranslation ? `value-${formData.workingLanguage}` : 'value',
            label: 'Option',
            required: true,
            width: 8,
          },
          {
            type: formData.languageChoices && formData.languageChoices.length ? 'toggle' : 'hidden',
            name: 'showOptionTranslation',
            label: 'Edit Other Language',
            required: false,
            readOnly: !formData.workingLanguage,
            width: 2,
            labelPlacement: 'top',
            linkField: 'showTranslation',
            writeToParent: true,
          },
          {
            type: formData.languageChoices && formData.languageChoices.length ? 'toggle' : 'hidden',
            name: `valueValid-${formData.workingLanguage}`,
            label: 'Translation Validated by Native Speaker',
            required: false,
            readOnly: !formData.showTranslation,
            width: 2,
            labelPlacement: 'top',
          },
          {
            type: 'autocomplete',
            name: 'system',
            label: 'System',
            required: false,
            width: 12,
            enum: medCodeTypes.map((k) => ({
              value: k.value,
              label: k.label,
            })),
            labelField: 'label',
            valueField: 'value',
          },
          {
            type: 'conditional',
            checkField: 'system',
            checkValue: 'medicalCode',
            targetType: 'typeaheadVcb',
            name: 'codeDescription',
            label: 'Code Description',
            required: false,
            width: 9,
          },
          {
            type: 'number',
            name: 'score',
            label: 'Score',
            required: false,
            width: 12,
          },
          {
            type: 'toggle',
            name: 'allowComment',
            label: 'Enable option comment',
            required: false,
            width: 12,
            labelPlacement: 'end',
          },
          {
            type: formData.enableInstructions ? 'group' : 'group',
            label: 'Optional Instructions',
            name: 'OPTIONAL INSTRUCTIONS',
            expanded: false,
            fields: [
              {
                type: 'toggle',
                name: 'enableInstructions',
                label: 'Enable Instructions',
                required: false,
                width: 3,
                labelPlacement: 'end',
              },
              {
                type: 'autocomplete',
                name: 'instructionType',
                label: 'Instruction Type',
                required: false,
                width: 12,
                enum: instructionType.map((k) => ({
                  value: k.value,
                  label: k.label,
                })),
                labelField: 'label',
                valueField: 'value',
              },
              {
                type: 'text',
                name:
                  formData.workingLanguage && formData.showTranslation
                    ? `instructionText-${formData.workingLanguage}`
                    : 'instructionText',
                label: 'Instruction Text',
                width: 8,
                rows: 2,
                required: false,
              },
              {
                type: formData.languageChoices && formData.languageChoices.length ? 'toggle' : 'hidden',
                name: 'showOptionTranslation',
                label: 'Edit Other Language',
                required: false,
                readOnly: !formData.workingLanguage,
                width: 2,
                labelPlacement: 'top',
                linkField: 'showTranslation',
                writeToParent: true,
              },
              {
                type: formData.languageChoices && formData.languageChoices.length ? 'toggle' : 'hidden',
                name: `instructionTextValid-${formData.workingLanguage}`,
                label: 'Translation Validated by Native Speaker',
                required: false,
                readOnly: !formData.showTranslation,
                width: 2,
                labelPlacement: 'top',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: formData.type === 'scale' ? 'group' : 'hidden',
    label: 'SCALE OPTIONS',
    name: 'SCALE OPTIONS',
    fields: [
      {
        type: 'numeric',
        name: 'scaleMin',
        label: 'Minimum Number',
        required: true,
        width: 12,
        max: formData.scaleMax,
      },
      {
        type: 'numeric',
        name: 'scaleMax',
        label: 'Maximum Number',
        required: true,
        width: 12,
        min: formData.scaleMin,
      },
    ],
  },
  // Number Options for numeric or slider
  // Includes: min (lowest allowed), max (highest allowed), step (interval to increase/decrease)
  {
    type: formData.type === 'numeric' || formData.type === 'slider' ? 'group' : 'hidden',
    label: 'NUMBER OPTIONS',
    name: 'NUMBER OPTIONS',
    fields: [
      {
        type: 'numeric',
        name: 'min',
        label: 'Minimum Number',
        required: false,
        width: 12,
        max: formData.max,
      },
      {
        type: 'numeric',
        name: 'max',
        label: 'Maximum Number',
        required: false,
        width: 12,
        min: formData.min,
      },
      {
        type: 'numeric',
        name: 'step',
        label: 'Incremental Step Value',
        required: false,
        width: 12,
        min: 0,
      },
    ],
  },
  // Input validation options for small-text
  // Includes: validationType, customValidation
  {
    type: formData.type === 'small-text' ? 'group' : 'hidden',
    label: 'OPTIONAL INPUT VALIDATION',
    name: 'OPTIONAL INPUT VALIDATION',
    fields: [
      {
        type: 'dropdown',
        name: 'validationType',
        label: 'Validation Type',
        enum: validationTypes,
        required: false,
        width: 12,
      },
      {
        type: 'text',
        name: 'customValidation',
        label: 'Custom RegEx',
        required: formData.validationType === 'Other',
        width: 12,
        readOnly: formData.validationType !== 'Other',
      },
    ],
  },

  // Date Options for date
  // Includes: precision (days/months/years), min, minBeforeAfter, max, maxBeforeAfter
  // Lowest date allowed to be set on execution by using min * minBeforeAfter (-30 = 30 days before questionnaire date)
  // Highest date allowed to be set on execution by using max * maxBeforeAfter (30 = 30 days after questionnaire date)
  {
    type: formData.type === 'date' ? 'group' : 'hidden',
    label: 'DATE OPTIONS',
    name: 'DATE OPTIONS',
    fields: [
      {
        type: 'autocomplete',
        name: 'precision',
        label: 'Precision',
        required: true,
        width: 12,
        enum: datePrecisionType.map((k) => ({
          value: k.value,
          label: k.label,
        })),
        labelField: 'label',
        valueField: 'value',
      },
      {
        type: formData.precision ? 'numeric' : 'hidden',
        name: 'min',
        label: `Lowest date allowed (set number of ${formData.precision})`,
        required: false,
        width: 6,
        min: formData.maxBeforeAfter === -1 && formData.minBeforeAfter === -1 && formData.max,
        max: formData.maxBeforeAfter === 1 && formData.minBeforeAfter === 1 && formData.max,
      },
      {
        type: formData.precision ? 'dropdown' : 'hidden',
        name: 'minBeforeAfter',
        label: 'Before/After questionnaire date',
        required: !!formData.min,
        width: 6,
        enum: dateBeforeAfter,
        labelField: 'label',
        valueField: 'value',
      },
      {
        type: formData.precision ? 'numeric' : 'hidden',
        name: 'max',
        label: `Highest date allowed (set number of ${formData.precision})`,
        required: false,
        width: 6,
        min: formData.minBeforeAfter === 1 && formData.maxBeforeAfter === 1 && formData.min,
        max: formData.minBeforeAfter === -1 && formData.maxBeforeAfter === -1 && formData.min,
      },
      {
        type: formData.precision ? 'dropdown' : 'hidden',
        name: 'maxBeforeAfter',
        label: 'Before/After questionnaire date',
        required: !!formData.max,
        width: 6,
        enum: dateBeforeAfter,
        labelField: 'label',
        valueField: 'value',
      },
    ],
  },
  // Text fields dynamic list for multiple-small-text
  // Includes: short name (id), label (fieldName - multilingual)
  {
    type: formData.type === 'multiple-small-text' ? 'group' : 'hidden',
    label: 'TEXT FIELDS',
    name: 'TEXT FIELDS',
    fields: [
      {
        type: 'dynamicList',
        name: 'fields',
        label: 'Field',
        required: true,
        width: 12,
        buttonType: 'text',
        buttonText: 'Add new field',
        tooltip: 'Add New Field',
        fields: [
          {
            type: 'text',
            name: 'id',
            label: 'Reference Short Name',
            required: true,
            width: 12,
          },
          {
            type: 'text',
            name:
              formData.workingLanguage && formData.showTranslation
                ? `fieldName-${formData.workingLanguage}`
                : 'fieldName-eng',
            label: 'Label for display',
            required: true,
            width: 8,
          },
          {
            type: formData.languageChoices && formData.languageChoices.length ? 'toggle' : 'hidden',
            name: 'showFieldTranslation',
            label: 'Edit Other Language',
            required: false,
            readOnly: !formData.workingLanguage,
            width: 2,
            labelPlacement: 'top',
            linkField: 'showTranslation',
            writeToParent: true,
          },
          {
            type: formData.languageChoices && formData.languageChoices.length ? 'toggle' : 'hidden',
            name: `fieldValid-${formData.workingLanguage}`,
            label: 'Translation Validated by Native Speaker',
            required: false,
            readOnly: !formData.showTranslation,
            width: 2,
            labelPlacement: 'top',
          },
        ],
      },
    ],
  },
  // Password Complexity Options for password
  // Includes: minimum password length and toggles to require capital letters, numbers, or special characters
  // These fields are converted into a RegEx expression in the questionJsonBuilder
  {
    type: formData.type === 'password' ? 'group' : 'hidden',
    label: 'PASSWORD COMPLEXITY OPTIONS',
    name: 'PASSWORD COMPLEXITY OPTIONS',
    fields: [
      {
        type: 'numeric',
        name: 'minLength',
        label: 'Minimum password length',
        required: false,
        width: 6,
        min: 0,
      },
      {
        type: 'toggle',
        name: 'capitalLetters',
        label: 'Require at least one capital letter',
        required: false,
        width: 12,
        labelPlacement: 'end',
      },
      {
        type: 'toggle',
        name: 'numbers',
        label: 'Require at least one number',
        required: false,
        width: 12,
        labelPlacement: 'end',
      },
      {
        type: 'toggle',
        name: 'characters',
        label: 'Require at least one special character',
        required: false,
        width: 12,
        labelPlacement: 'end',
      },
    ],
  },
  // Node list fields dynamic list for combo
  // Includes: questionId, nameStyle, descriptionStyle, helpStyle
  {
    type: formData.type === 'combo' ? 'group' : 'hidden',
    label: 'QUESTIONS ON COMBO PAGE',
    name: 'QUESTIONS ON COMBO PAGE',
    fields: [
      {
        type: 'dynamicList',
        name: 'nodeList',
        label: 'Question',
        required: true,
        width: 12,
        buttonType: 'text',
        buttonText: 'Add question',
        tooltip: 'Add question',
        fields: [
          {
            type: 'autocomplete',
            name: 'questionId',
            label: 'Select Question Node',
            required: true,
            // Exclude other combo nodes, then map to name and id
            enum: questionList?.reduce((filteredQuestions, currentQuestion) => {
              if (currentQuestion.type !== 'combo') {
                const mappedQuestion = {
                  label: currentQuestion.shortName || currentQuestion.name?.translations?.eng || currentQuestion.name,
                  value: currentQuestion.id,
                };
                filteredQuestions.push(mappedQuestion);
              }
              return filteredQuestions;
            }, []),
            // required: false,
            width: 12,
            // Disable items already selected for this combo node
            disabledItems:
              formData.nodeList?.length &&
              questionList?.reduce((selectedQuestions, currentQuestion) => {
                if (formData.nodeList.find((node) => node.questionId === currentQuestion.id)) {
                  const mappedQuestion = currentQuestion.shortName || currentQuestion.name.translations.eng;
                  selectedQuestions.push(mappedQuestion);
                }
                return selectedQuestions;
              }, []),
            labelField: 'label',
            valueField: 'value',
          },
          {
            type: 'dropdown',
            name: 'nameStyle',
            label: 'Style for Question Text',
            required: false,
            width: 8,
            enum: styleList,
            labelField: 'label',
            valueField: 'value',
          },
          {
            type: 'dropdown',
            name: 'descriptionStyle',
            label: 'Style for Description',
            required: false,
            width: 8,
            enum: styleList,
            labelField: 'label',
            valueField: 'value',
          },
          {
            type: 'dropdown',
            name: 'helpStyle',
            label: 'Style for Helper Text',
            required: false,
            width: 8,
            enum: styleList,
            labelField: 'label',
            valueField: 'value',
          },
        ],
      },
    ],
  },
  // Signature Panel Size Options for graphicSignature
  // Includes: width, height (both represented as a percent of remaining screen size)
  {
    type: formData.type === 'graphicSignature' ? 'group' : 'hidden',
    label: 'SIGNATURE PANEL SIZE OPTIONS',
    name: 'SIGNATURE PANEL SIZE OPTIONS',
    fields: [
      {
        type: 'numeric',
        name: 'width',
        label: 'Width (% of screen size)',
        required: false,
        width: 12,
        min: 1,
        max: 100,
      },
      {
        type: 'numeric',
        name: 'height',
        label: 'Height (% of screen size)',
        required: false,
        width: 12,
        min: 1,
        max: 100,
      },
    ],
  },
  // Test list and properties for labOrder
  {
    type: formData.type === 'labOrder' ? 'toggle' : 'hidden',
    name: 'interactive',
    label: 'Allow user input',
    required: false,
    default: false,
    width: 12,
  },
  {
    type: formData.type === 'labOrder' ? 'group' : 'hidden',
    label: 'LAB TEST INFORMATION',
    name: 'LAB TEST INFORMATION',
    fields: [
      {
        type: 'dynamicList',
        name: 'testList',
        label: 'Orderable Tests',
        required: true,
        width: 12,
        tooltip: 'Add new test',
        fields: [
          {
            type: 'header',
            name: 'New Lab Order',
          },
        ],
        fieldset: 'labOrder',
      },
    ],
  },
];

export const medicalCodes = (formData) => [
  {
    type: 'autocomplete',
    name: 'system',
    label: 'System',
    required: false,
    width: 12,
    enum: medCodeTypes.map((k) => ({
      value: k.value,
      label: k.label,
    })),
    labelField: 'label',
    valueField: 'value',
  },
  {
    type: 'typeaheadVcb',
    name: 'codeDescription',
    label: 'Code Description',
    required: false,
    searchClinical: formData.system === 'icdcode' || formData.system === 'prescribabledrug',
    width: 9,
    disabled: !formData.system,
    labelField: getFieldName(formData.system),
    searchType: formData.system,
    resultCount: 20,
    setFields: [
      {
        field: getCodeName(formData.system),
        targetField: 'code',
      },
    ],
  },
  {
    type: 'text',
    name: 'code',
    label: 'Code',
    required: false,
    width: 3,
    readOnly: true,
  },
];

export const questionChildren = {
  labOrder: (values, labTestOrderables) => [
    {
      type: 'autocomplete',
      name: 'test',
      label: 'Lab Test',
      required: true,
      width: 12,
      disableClear: true,
      enum:
        (labTestOrderables &&
          labTestOrderables.map((t) => {
            if (t.vendorTestPanelId) {
              return {
                label: `${t.vendorName} / ${t.longName}`,
                test: {
                  testId: null, // safeTestId
                  vendorId: t.vendorTestPanelId,
                  vendorName: t.vendorName,
                  laboratoryTestSource: t.vendorKey,
                  vendorTestName: t.testFormatName,
                  orderableTestId: t.labTestPanelId,
                  orderableTestPanelId: t.labTestPanelId,
                  price: t.price,
                  panel: true,
                  orderableTestList: t.labOrderableTests,
                },
              };
            }
            return {
              label: `${t.vendorName} / ${t.longName}`,
              test: {
                testId: t.safeTestId,
                vendorId: t.vendorTestId,
                vendorName: t.vendorName,
                laboratoryTestSource: t.vendorKey,
                vendorTestName: t.testFormatName,
                orderableTestId: t.labTestOrderableId,
                price: t.price,
                panel: false,
              },
            };
          })) ||
        [],
      disabled: !labTestOrderables || !labTestOrderables.length,
      description:
        (!labTestOrderables || !labTestOrderables.length) && 'No orderable tests or panels selected for tenant.',
      labelField: 'label',
      valueField: 'test',
      matchField: 'orderableTestId',
    },
    {
      type: values?.test?.panel ? 'multiselect' : 'hidden',
      name: 'autoSelectedOrderableTestList',
      label: 'Tests Preselected for User',
      required: false,
      width: 12,
      buttonType: 'text',
      buttonText: 'Add preselected test',
      tooltip: 'Add exclusion',
      enum: values?.test?.orderableTestList || [],
      labelField: 'longName',
      valueField: 'labTestOrderableId',
      selectAll: true,
    },
  ],
};
