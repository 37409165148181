import { createSlice } from '@reduxjs/toolkit';

import api from '../../../api';
import { handleError } from '../../Shared/Content/APIUtils';

const initialState = {
  procedureList: null,
  procedureListLoading: null,
};

export const procedureListSlice = createSlice({
  name: 'procedureList',
  initialState,
  reducers: {
    setProcedureListLoading: (state, { payload }) => ({
      ...state,
      procedureListLoading: payload,
    }),
    setProcedureList: (state, { payload }) => ({
      ...state,
      procedureList: payload,
      procedureListLoading: false,
    }),
  },
});

export const { setProcedureList, setProcedureListLoading } = procedureListSlice.actions;

export const getProcedureList = (safeEMRId) => async (dispatch) => {
  dispatch(setProcedureListLoading(true));

  const [result, error] = await api.getProcedureListRequest(safeEMRId);

  if (result) {
    dispatch(setProcedureList(result));
  }

  if (error && error.message) {
    dispatch(handleError(error));
  }
};

export default procedureListSlice.reducer;
