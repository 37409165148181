import { createSlice } from '@reduxjs/toolkit';

import api from '../../../api';
import { handleError } from '../../Shared/Content/APIUtils';

const initialState = {
  epicAuthUrl: null,
  epicAuthLoading: null,
};

export const epicSlice = createSlice({
  name: 'epic',
  initialState,
  reducers: {
    setEpicAuthLoading: (state) => ({
      ...state,
      epicAuthLoading: true,
    }),
    setEpicAuthUrl: (state, { payload }) => ({
      ...state,
      epicAuthUrl: payload,
      epicAuthLoading: false,
    }),
  },
});

export const { setEpicAuthUrl, setEpicAuthLoading } = epicSlice.actions;

export const getEpicAuthUrl = (safeEMRId, tenantId) => async (dispatch) => {
  dispatch(setEpicAuthLoading());

  const [result, error] = await api.getEpicAuthUrlRequest(safeEMRId, tenantId);

  if (result) {
    dispatch(setEpicAuthUrl(result));
  }

  if (error && error.message) {
    dispatch(handleError(error));
  }
};

export default epicSlice.reducer;
