import { createSlice } from '@reduxjs/toolkit';

import api from '../../../api';
import { handleError } from '../../Shared/Content/APIUtils';

const initialState = {
  loading: false,
  error: null,
  data: null,
};

export const contactSupportSlice = createSlice({
  name: 'contactSupport',
  initialState,
  reducers: {
    setContactSupportResponse: (state, { payload }) => ({
      ...state,
      loading: false,
      data: {
        ...state.data,
        ...payload,
      },
    }),
    setContactSupportRequest: (state) => ({
      ...state,
      loading: true,
    }),
    resetContactSupport: (state, { payload }) => ({
      ...state,
      loading: false,
      data: payload,
    }),
  },
});

export const { setContactSupportResponse, setContactSupportRequest, resetContactSupport } = contactSupportSlice.actions;

export const sendContactSupport = (purpose, message, userName, email, meta) => async (dispatch) => {
  // Server requires seconds from epoch starts format
  dispatch(setContactSupportRequest());
  const zendeskTicket = JSON.stringify({
    request: {
      subject: purpose,
      comment: { body: message + meta },
      requester: { name: userName, email },
    },
  });
  const response = await api.submitZendeskSupport(zendeskTicket, email);
  if (response && response.length > 0) {
    dispatch(setContactSupportResponse(response));
  } else {
    dispatch(handleError('Issue in connecting with zendesk'));
    dispatch(resetContactSupport(null));
  }
};

export default contactSupportSlice.reducer;
