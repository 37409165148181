import { createSlice } from '@reduxjs/toolkit';

import api from '../../api/index';

const initialState = {};

export const adminSlice = createSlice({
  name: 'adminSlice',
  initialState,
  reducers: {
    loadProfile(store) {
      store.isLoading = true;
    },
    setAdminProfile(state, { payload }) {
      return {
        ...state,
        ...payload,
        error: null,
        isLoading: false,
      };
    },
    setAdminError(store, { payload }) {
      store.error = payload;
      store.isLoading = false;
    },
  },
});

export const { setAdminProfile, setAdminError, loadProfile } = adminSlice.actions;

export const loginAdmin = (data, params) => async () => {
  const [result, error] = await api.loginAdmin(data, params);
  return result || error;
};

export const sendResetPassEmail = (data) => async () => {
  const [result, error] = await api.sendresetPassEmail(data);
  return result || error;
};

export const resetPassword = (data) => async () => {
  const [result, error] = await api.resetPassword(data);
  return result || error;
};

export const sendMfaCode = (mfaMethod, mfaCode, sessionId, userName, accountId) => async () => {
  const [result, error] = await api.sendMfaCode(mfaMethod, mfaCode, sessionId, userName, accountId);
  return result || error;
};
