export const encounterLabStatus = {
  FALSE: 'FALSE',
  ABNORMAL: 'ABNORMAL',
  ALL_NEGATIVE: 'ALL NEGATIVE',
};

export const encounterTypes = {
  DIAGNOSTIC_TEST: {
    id: 0,
    label: 'Diagnostic Test',
  },
  VIRTUAL_CONSOLE_REVIEW: {
    id: 1,
    label: 'Virtual Console Review',
  },
  DIAGNOSTIC_ASSESMENT: {
    id: 2,
    label: 'Diagnostic Assessment',
  },
  TELEHEALTH_CONFERENCE: {
    id: 3,
    label: 'Telehealth Conference',
  },
  VIRTUAL_CONSULT: {
    id: 4,
    label: 'Virtual Consult',
  },
  TELEHEALTH_CONSULT: {
    id: 4,
    label: 'Telehealth Consult',
  },
};

export const encounterStatuses = {
  NOT_ASSIGNED: 0,
  ASSIGNED: 1,
  CLOSED: 2,
  CANCELLED: 3,
  UNKNOWN: 4,
  COMPLETE: 5,
  INCOMPLETE: 6,
};

export const memberStatesLabels = {
  NOT_ASSIGNED: {
    label: 'NOT ASSIGNED',
    background: '#f5343f',
    color: 'white',
  },
  ASSIGNED: {
    label: 'ASSIGNED',
    background: '#4f8bf5',
    color: 'white',
  },
  CLOSED: {
    label: 'CLOSED',
    background: '#1c8000',
    color: 'white',
  },
  CANCELLED: {
    label: 'CANCELLED',
    background: '#9e9e9e',
    color: 'white',
  },
  UNKNOWN: {
    label: 'UNKNOWN',
    background: '#708090',
    color: 'white',
  },
  COMPLETE: {
    label: 'COMPLETE',
    background: '#1c8000',
    color: 'white',
  },
  INCOMPLETE: {
    label: 'INCOMPLETE',
    background: '#4f8bf5',
    color: 'white',
  },
  MO: {
    label: 'INCOMPLETE',
    background: '#4f8bf5',
    color: 'white',
  },
};

export const USAStates = [
  { value: 'AL', label: 'Alabama' },
  { value: 'AK', label: 'Alaska' },
  { value: 'AS', label: 'American Samoa' },
  { value: 'AZ', label: 'Arizona' },
  { value: 'AR', label: 'Arkansas' },
  { value: 'CA', label: 'California' },
  { value: 'CO', label: 'Colorado' },
  { value: 'CT', label: 'Connecticut' },
  { value: 'DE', label: 'Delaware' },
  { value: 'DC', label: 'District Of Columbia' },
  { value: 'FM', label: 'Federated States Of Micronesia' },
  { value: 'FL', label: 'Florida' },
  { value: 'GA', label: 'Georgia' },
  { value: 'GU', label: 'Guam' },
  { value: 'HI', label: 'Hawaii' },
  { value: 'ID', label: 'Idaho' },
  { value: 'IL', label: 'Illinois' },
  { value: 'IN', label: 'Indiana' },
  { value: 'IA', label: 'Iowa' },
  { value: 'KS', label: 'Kansas' },
  { value: 'KY', label: 'Kentucky' },
  { value: 'LA', label: 'Louisiana' },
  { value: 'ME', label: 'Maine' },
  { value: 'MH', label: 'Marshall Islands' },
  { value: 'MD', label: 'Maryland' },
  { value: 'MA', label: 'Massachusetts' },
  { value: 'MI', label: 'Michigan' },
  { value: 'MN', label: 'Minnesota' },
  { value: 'MS', label: 'Mississippi' },
  { value: 'MO', label: 'Missouri' },
  { value: 'MT', label: 'Montana' },
  { value: 'NE', label: 'Nebraska' },
  { value: 'NV', label: 'Nevada' },
  { value: 'NH', label: 'New Hampshire' },
  { value: 'NJ', label: 'New Jersey' },
  { value: 'NM', label: 'New Mexico' },
  { value: 'NY', label: 'New York' },
  { value: 'NC', label: 'North Carolina' },
  { value: 'ND', label: 'North Dakota' },
  { value: 'MP', label: 'Northern Mariana Islands' },
  { value: 'OH', label: 'Ohio' },
  { value: 'OK', label: 'Oklahoma' },
  { value: 'OR', label: 'Oregon' },
  { value: 'PW', label: 'Palau' },
  { value: 'PA', label: 'Pennsylvania' },
  { value: 'PR', label: 'Puerto Rico' },
  { value: 'RI', label: 'Rhode Island' },
  { value: 'SC', label: 'South Carolina' },
  { value: 'SD', label: 'South Dakota' },
  { value: 'TN', label: 'Tennessee' },
  { value: 'TX', label: 'Texas' },
  { value: 'UT', label: 'Utah' },
  { value: 'VT', label: 'Vermont' },
  { value: 'VI', label: 'Virgin Islands' },
  { value: 'VA', label: 'Virginia' },
  { value: 'WA', label: 'Washington' },
  { value: 'WV', label: 'West Virginia' },
  { value: 'WI', label: 'Wisconsin' },
  { value: 'WY', label: 'Wyoming' },
];
