import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Input } from '@progress/kendo-react-inputs';
import { Error } from '@progress/kendo-react-labels';
import React, { useEffect, useState } from 'react';

import styles from '../../assets/Questionnaire.module.css';
import { validationRegex } from '../helpers/validationHelper';
import utils from '../utils/utils';

// ui reflection of file view
function EmailInput(props) {
  const { field, initialValue, handleChangeWithParams, disabled, selectedLanguage } = props;
  const [emailValue, setEmailValue] = useState(initialValue || '');
  const [valid, setValid] = useState(true);

  useEffect(() => {
    if (initialValue) {
      if (initialValue !== emailValue) {
        setEmailValue(initialValue);
      }

      if (!validationRegex.email.test(initialValue)) {
        setValid(false);
      }
    }
    if (!initialValue && field.required) {
      setValid(false);
    }
    // eslint-disable-next-line
  }, [initialValue, setValid, field]);

  const handleChangeEmail = (e) => {
    let newValue = e.target.value;

    if (newValue && validationRegex.email.test(newValue)) {
      setValid(true);
    } else if (!newValue) {
      setValid(true);
      newValue = '';
    } else {
      setValid(false);
    }

    setEmailValue(newValue);
  };

  useEffect(() => {
    if (valid) {
      handleChangeWithParams(field.name, emailValue.trim());
    } else {
      handleChangeWithParams(field.name, '');
    }
  }, [valid, emailValue, field.name]);

  const fieldTitle =
    selectedLanguage && field.name && field.name.translations
      ? field.name.translations[selectedLanguage]
      : field.label || field.name;
  const questionDescription =
    selectedLanguage && field.description && field.description.translations
      ? field.description.translations[selectedLanguage]
      : field.description;

  return (
    <>
      {fieldTitle && (
        <Typography className={field.nameStyle ? styles[`title--${field.nameStyle}`] : styles['title--style1']}>
          {field.questionPreview ? utils.renderHTMLTags(fieldTitle) : fieldTitle}
          {field.required && '*'}
        </Typography>
      )}
      {field.image && (
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <img
            src={field.image}
            alt={fieldTitle}
            style={{
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
              width: '90%',
            }}
          />
        </Grid>
      )}
      {questionDescription && (
        <Typography
          className={
            field.descriptionStyle ? styles[`description--${field.descriptionStyle}`] : styles['description--style1']
          }
        >
          {utils.renderHTMLTags(questionDescription)}
        </Typography>
      )}
      <Input
        autoComplete="off"
        width="100%"
        value={emailValue}
        onChange={handleChangeEmail}
        required={field.required && !emailValue}
        label={field.questionPreview ? 'Email' : field.label}
        name={field.name}
        maxLength={field.maxLength}
        valid={valid}
        style={{ width: '100%' }}
        disabled={field.disabled || disabled}
      />
      {emailValue && !valid ? <Error>Please enter a valid email address</Error> : null}
    </>
  );
}

export default EmailInput;
