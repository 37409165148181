import Grid from '@material-ui/core/Grid';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import ProfileViewStyles from '../../assets/jss/components/ProfileViewStyles';
import { paths } from '../../constants';
import { showNotification } from '../Notifications/notificationSlice';
import { selectCurrentTenant } from '../Shared/selectors';
import AppKendoTableWrapper from '../Shared/Table/KendoTableWrapper';
import utils from '../Shared/Utils/utils';
import { selectAllUsers, selectAllUsersLoading, selectUserDetails } from '../Users/selectors';
import { getMetadataValues, getUsers } from '../Users/usersSlice';

// components and helpers
import CreateOrderDialog from './CreateOrderDialog';

// mui
import CreateOrderPHRDialog from './CreateOrderPHRDialog';
import CreateResultDialog from './CreateResultDialog';
import { columns, tableButtons, initialKendoState } from './helpers/ordersListMapper';
import {
  createLabOrder,
  getDiagnosticsFilters,
  getMasterLabTestsTenant,
  getTenantLabTests,
  getVendorDetail,
  kendoPaginationStateInit,
} from './ordersSlice';
import {
  selectLabOrders,
  selectLabOrdersLoading,
  selectOrderableTestsList,
  selectTenantLabOrders,
  selectTenantLabOrdersLoading,
  selectTestFormat,
  selectVendorsList,
} from './selectors';

const styles = () => ({
  noPadding: {
    padding: 0,
  },
});

function Orders(props) {
  const { userSpecific } = props;

  const classes = useStyles();

  const userDetails = useSelector(selectUserDetails);
  const currentTenant = useSelector(selectCurrentTenant);
  const allUsers = useSelector(selectAllUsers);
  const allUsersLoading = useSelector(selectAllUsersLoading);
  const orderableTests = useSelector(selectOrderableTestsList);
  const vendors = useSelector(selectVendorsList);
  const labOrdersLoading = useSelector(selectLabOrdersLoading);
  const labOrders = useSelector(selectLabOrders);
  const tenantLabOrdersLoading = useSelector(selectTenantLabOrdersLoading);
  const tenantLabOrders = useSelector(selectTenantLabOrders);
  const filter_TestFormat = useSelector(selectTestFormat);

  const [labData, setLabData] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [resultDialogOpen, setResultDialogOpen] = useState(false);
  const [orderDialogOpen, setOrderDialogOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [userNHI, setUserNHI] = useState('');
  const [users, setUsers] = useState(null);
  const [orderableTest, setOrderableTest] = useState(undefined);
  const [specimenId, setSpecimenId] = useState(undefined);
  const [provider, setProvider] = useState(undefined);
  const [result, setResult] = useState(undefined);
  const [collectionDate, setCollectionDate] = useState(undefined);
  const [collectionTime, setCollectionTime] = useState(undefined);
  const [vendorSettings, setVendorSettings] = useState(null);
  const [userSegmentValues, setUserSegmentValues] = useState(null);
  const [userSegmentFilter, setUserSegmentFilter] = useState(null);
  const [transferPage, setTransferPage] = useState(0);
  const [kendoPaginationState, setKendoPaginationState] = useState(initialKendoState);
  const [testFormats, setTestFormats] = useState([]);
  const [vendorsFilter, setVendorsFilter] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Place Kendo state into the store so it is available in actions
  useEffect(() => {
    dispatch(kendoPaginationStateInit(kendoPaginationState));
  }, [kendoPaginationState]);

  // set data to use in table based on tenant or user
  useEffect(() => {
    if (userSpecific && userDetails) {
      setLabData(tenantLabOrders);
      setSearchValue(userDetails.email);
      setUserNHI(userDetails.nationalHealthId);
    } else {
      setLabData(tenantLabOrders);
      (async () => {
        setUserSegmentValues(await dispatch(getMetadataValues(currentTenant.id, 'User Segment')));
      })();
    }
  }, [currentTenant, labOrders, tenantLabOrders, userDetails, userSpecific]);

  useEffect(() => {
    if (vendors && vendors.length) {
      const updatedVendorSettings = {};
      vendors.forEach((item) => {
        updatedVendorSettings[item.vendorKey] = {
          vendorKey: item.vendorKey,
          isRequisitionSupported: item.isRequisitionSupported,
          isResultDocumentSupported: item.isResultDocumentSupported,
          isSpecimenLabelSupported: item.isSpecimenLabelSupported,
        };
      });
      setVendorSettings(updatedVendorSettings);
    }
  }, [vendors]);

  // get all users in array for autocomplete
  useEffect(() => {
    if (allUsers && !allUsersLoading && allUsers.items) {
      setUsers(allUsers);
    }
  }, [allUsers, allUsersLoading]);

  // get all users in array for autocomplete
  useEffect(() => {
    if (!currentTenant) return;

    dispatch(getMasterLabTestsTenant('orderableTests', currentTenant.id));
    dispatch(getVendorDetail('vendors', 'VendorDetail'));
  }, [currentTenant, allUsers]);

  const handleGetOrders = useCallback(() => {
    if (userSpecific && userDetails && currentTenant) {
      dispatch(
        getTenantLabTests(0, 10, 'lastUpdated', 'DESC', undefined, currentTenant.id, undefined, userDetails.email)
      );
    } else {
      dispatch(
        getTenantLabTests(
          kendoPaginationState ? kendoPaginationState.skip : 0,
          kendoPaginationState ? kendoPaginationState.take : 10,
          kendoPaginationState ? kendoPaginationState.sort[0]?.field : 'lastUpdated',
          kendoPaginationState ? kendoPaginationState.sort[0]?.dir?.toUpperCase() : 'DESC',
          kendoPaginationState ? kendoPaginationState.search : '',
          currentTenant.id,
          kendoPaginationState ? kendoPaginationState.filter : '',
          ''
        )
      );
      dispatch(getDiagnosticsFilters('testFormat'));
    }
  }, [currentTenant, userDetails, userSpecific]);

  // get lab tests in array for autocomplete
  useEffect(() => {
    handleGetOrders();
  }, [userSpecific, userDetails, currentTenant, handleGetOrders]);

  useEffect(() => {
    if (filter_TestFormat?.list?.length > 0) {
      setTestFormats(filter_TestFormat.list.map((x) => x.formatId));
    }
  }, [filter_TestFormat]);

  useEffect(() => {
    if (vendors?.length > 0) {
      setVendorsFilter(vendors.map((x) => x.vendorKey));
    }
  }, [vendors]);

  // handles all table updates from table wrapper
  const handleTableUpdate = (page, pageSize, sort, direction, searchValue, filter) => {
    sort = sort || 'lastUpdated';
    if (userSpecific && userDetails && currentTenant) {
      dispatch(
        getTenantLabTests(
          page,
          pageSize,
          sort,
          direction.toUpperCase(),
          undefined,
          currentTenant.id,
          undefined,
          userDetails.email
        )
      );
    } else {
      setKendoPaginationState({
        sort: [{ field: sort, dir: direction }],
        take: pageSize,
        skip: page,
        search: searchValue,
        filter,
      });
      dispatch(
        getTenantLabTests(page, pageSize, sort, direction.toUpperCase(), searchValue, currentTenant.id, filter, '')
      );
    }
  };

  // on text change in searchbar, wait 1 second and fetch results
  const handleTextChange = (e) => {
    const value = e.filter ? e.filter.value : e.target.value;
    if (!value) {
      if (e.filter) {
        setUsers(null);
      }
      dispatch(getUsers(currentTenant && currentTenant.id, 0, 10, '', 'email', 'ASC', null, userSegmentFilter));
      setSearchValue('');
      setUserNHI('');
    } else {
      dispatch(getUsers(currentTenant && currentTenant.id, 0, 10, value, 'email', 'ASC', null, userSegmentFilter));
      setSearchValue(value);
    }
    setTransferPage(0);
  };

  const handleSelectUser = (e) => {
    const { value } = e.target;
    setSearchValue(value ? value.email : '');
    setUserNHI(value ? value.nationalHealthId : '');
  };

  const handleOrderableTestChange = (ot) => {
    setOrderableTest(ot);
  };

  const handleSpecimedIdChange = (ot) => {
    setSpecimenId(ot);
  };

  const handleCreateOrder = (selectedUsers, newUserToggle) => {
    let type = 'tenantLabOrders';
    const userIds = [];
    if (userSpecific && userDetails) {
      type = 'labOrders';
      userIds.push(userDetails.email);
    } else {
      selectedUsers.forEach((user) => {
        userIds.push(user.split('<')[1].split('>')[0]);
      });
    }

    dispatch(
      // eslint-disable-next-line no-undef
      createBulkLabOrder(
        userIds.toString(),
        orderableTest && orderableTest.vendorKey,
        type,
        currentTenant && currentTenant.id,
        {
          internalTestId: orderableTest && orderableTest.safeTestId,
          safeTestName: orderableTest && orderableTest.shortName,
          vendorLabTestId: orderableTest && orderableTest.vendorTestId,
          vendorTestName: orderableTest && orderableTest.vendorName,
        },
        newUserToggle
      )
    );
    handleCloseDialog();
  };

  const handleCreateResult = async () => {
    let type = 'tenantLabOrders';
    if (userSpecific) {
      type = 'labOrders';
    }

    const collectionDateTime = `${collectionDate
      .toJSON()
      .substring(0, collectionDate.toJSON().indexOf('T'))}${collectionTime
      .toJSON()
      .substring(collectionTime.toJSON().indexOf('T'))}`;

    const isUserProfile = userSpecific && userDetails && currentTenant;
    setIsSubmitting(true);

    const res = await dispatch(
      createLabOrder(
        searchValue,
        orderableTest && orderableTest.vendorKey,
        type,
        currentTenant && currentTenant.id,
        {
          nationalHealthId: userNHI,
          isManuallyAdded: true,
          paymentMode: 'Free',
          orderableTestIds: [orderableTest.labTestOrderableId],
          orderableTestResults: [
            {
              orderableTestId: orderableTest.labTestOrderableId,
              collectionDate: collectionDateTime,
              testResult: result,
            },
          ],
          npi: provider && provider.npi,
          specimenId,
        },
        isUserProfile
      )
    );

    if (res?.success) {
      setResultDialogOpen(false);
      setOrderableTest(undefined);
      setResult(undefined);
      setProvider(undefined);
      setCollectionDate(undefined);
      setCollectionTime(undefined);
      dispatch(showNotification('Test Result Successfully Added', 'success'));
    }

    setIsSubmitting(false);
  };

  // handles click on row to go to that user's testing tab on their profile
  const handleRowClick = (e) => {
    const userDetailsPath = paths.profileDetailsTestingTab(currentTenant?.id, userDetails?.email);
    const isPanelRapidTest = e.orderablePanelId && e.status?.toLowerCase() === 'completed'; // && e.details?.testFormat?.isRapidTest;
    let newUrl = '';
    if ((e.source && e.source === 'RAPID_TEST') || isPanelRapidTest) {
      newUrl = `/${currentTenant && currentTenant.id}/diagnostics/rapid-test/${e.nationalHealthId}/${e.orderId}/${
        e.userId
      }`;

      if (userDetailsPath === window.location.pathname) {
        newUrl = paths.profileDetailsRapidTestingReport(
          currentTenant.id,
          userDetails.email,
          e.nationalHealthId,
          e.orderId
        );
      }
    } else {
      newUrl = `/${currentTenant && currentTenant.id}/diagnostics/diagnostic-report/${e.nationalHealthId}/${
        e.orderId
      }/${e.userId}`;
      if (userDetailsPath === window.location.pathname) {
        newUrl = paths.profileDetailsDiagnosticTestingReport(
          currentTenant.id,
          userDetails.email,
          e.nationalHealthId,
          e.orderId
        );
      }
    }
    if (newUrl.length > 0) navigate(newUrl);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setResultDialogOpen(false);
    setOrderableTest(undefined);
    setSearchValue('');
    setUserSegmentFilter('');
    dispatch(getUsers(currentTenant && currentTenant.id, 0, 10, '', 'email', 'ASC'));
  };

  const handleCloseOrderDialog = () => {
    setDialogOpen(false);
    setOrderDialogOpen(false);
    setOrderableTest(undefined);
    setSearchValue('');
    setUserSegmentFilter('');
  };

  const handleFilters = (e) => {
    const { value } = e.target;
    dispatch(getUsers(currentTenant && currentTenant.id, 0, 10, searchValue, 'email', 'ASC', null, null, value));
    setTransferPage(0);
    setUserSegmentFilter(value);
  };

  // handle transfer list pagination in create order dialog
  const handlePageChange = (event) => {
    const { skip, take } = event;
    const page = skip / take;
    setTransferPage(page);
    dispatch(
      getUsers(currentTenant && currentTenant.id, page, 10, searchValue, 'email', 'ASC', null, userSegmentFilter)
    );
  };

  // Filters to pass to table wrapper
  const getColumns = columns(userSpecific, testFormats, vendorsFilter, vendorSettings);
  const filters = (getColumns && !userSpecific && getColumns.filter((c) => c.filter)) || [];

  // Buttons to pass to table wrapper - must contain title, button type and variant type
  const buttons = tableButtons(
    handleGetOrders,
    setCollectionDate,
    setCollectionTime,
    setResultDialogOpen,
    userSpecific,
    setDialogOpen,
    currentTenant,
    setOrderDialogOpen
  );

  const data = {
    total: labData && labData.foundItemsCount,
    pageSize: labData && labData.pageSize,
    ...labData,
    items:
      labData &&
      labData.result &&
      labData.result.map((order) => ({
        ...order,
        created: order.created && utils.formatDateTime(order.created),
        lastUpdated: order.lastUpdated && utils.formatDateTime(order.lastUpdated),
        // received: order.received && utils.formatDateTime(order.received),
        status: order.status && utils.removeUnderscoresTitleCase(order.status),
        testFormat: order?.orderableTests != null ? order?.orderableTests[0].testFormat?.formatId : '',
      })),
  };

  if (!currentTenant) return <div />;

  return (
    <Grid className={userSpecific ? `${classes.contentWrapper} ${classes.withTableButtons}` : null}>
      <AppKendoTableWrapper
        data={data}
        initialSort={initialKendoState.sort[0].field}
        initialDir={initialKendoState.sort[0].dir}
        searchInState={kendoPaginationState.search}
        showSearchbar={!userSpecific}
        showFilterIcon={!userSpecific}
        filters={filters}
        showButtons
        onRowClick={handleRowClick}
        tableButtons={buttons}
        columns={getColumns}
        currentTenant={currentTenant}
        onTableUpdate={handleTableUpdate}
        loading={labOrdersLoading || tenantLabOrdersLoading}
        searchAdornmentPos="end"
      />
      {dialogOpen && (
        <CreateOrderDialog
          dialogOpen={dialogOpen}
          users={users}
          orderableTests={orderableTests}
          userSpecific={userSpecific}
          userDetails={userDetails}
          selectedUser={searchValue}
          selectedOrderableTest={orderableTest}
          filters={userSegmentValues}
          userSegmentFilter={userSegmentFilter}
          page={transferPage}
          pageSize={10}
          handleCreateOrder={handleCreateOrder}
          handleTextChange={handleTextChange}
          handleOrderableTestChange={handleOrderableTestChange}
          handleCancelOrder={handleCloseDialog}
          handleFilters={handleFilters}
          handlePageChange={handlePageChange}
        />
      )}
      {resultDialogOpen && (
        <CreateResultDialog
          closeDialog={handleCloseDialog}
          resultDialogOpen={resultDialogOpen}
          users={users?.items || []}
          tests={orderableTests}
          userSpecific={userSpecific}
          userDetails={userDetails}
          selectedUser={searchValue}
          selectedTest={orderableTest}
          selectedProvider={provider}
          selectedResult={result}
          handleResultChange={(v) => setResult(v)}
          handleSelectUser={handleSelectUser}
          handleCreateResult={handleCreateResult}
          handleTextChange={handleTextChange}
          handleTestChange={handleOrderableTestChange}
          handleSpecimedIdChange={handleSpecimedIdChange}
          handleProviderChange={(v) => setProvider(v)}
          handleCollectionDateChange={(v) => setCollectionDate(v)}
          handleCollectionTimeChange={(v) => setCollectionTime(v)}
          isSubmitting={isSubmitting}
        />
      )}
      {orderDialogOpen && (
        <CreateOrderPHRDialog
          currentTenant={currentTenant}
          onClose={handleCloseOrderDialog}
          open={orderDialogOpen}
          users={users?.items || []}
          tests={orderableTests}
          allUsersLoading={allUsersLoading}
        />
      )}
    </Grid>
  );
}

const useStyles = makeStyles(ProfileViewStyles);

export default withStyles(styles)(withWidth()(Orders));
