import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { useTheme, withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import atob from 'atob';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ProfileViewStyles from '../../assets/jss/components/ProfileViewStyles';
import ProfileCard from '../Profile/ProfileCard';
import {
  ProfileCardSkeletonLoading,
  ProfileCardSkeletonLoadingMobile,
  ProfileTabsSkeletonLoading,
  ProfileDropdownSkeletonLoadingMobile,
} from '../Profile/ProfileSkeletonLoading';
import { selectCurrentTenant } from '../Shared/selectors';
import { selectProfilePicture, selectUserDetails, selectUserPermissionsLoading } from '../Users/selectors';
import { getUser, getUserPermissions } from '../Users/usersSlice';

import { getCCD, getRecordRequest } from './recordRequestSlice';
import { selectCCD, selectCCDLoading, selectRecordRequest, selectRecordRequestLoading } from './selectors';

function RecordRequestDetail(props) {
  const { classes } = props;

  const currentTenant = useSelector(selectCurrentTenant);
  const profilePicture = useSelector(selectProfilePicture);
  const userDetails = useSelector(selectUserDetails);
  const userPermissionsLoading = useSelector(selectUserPermissionsLoading);
  const recordRequest = useSelector(selectRecordRequest);
  const recordRequestLoading = useSelector(selectRecordRequestLoading);
  const ccd = useSelector(selectCCD);
  const ccdLoading = useSelector(selectCCDLoading);

  const dispatch = useDispatch();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const fieldWidth = matches ? 4 : 12;
  const [srcURL, setSrcURL] = useState('');
  const [pdfURL, setPDFURL] = useState('');
  const [providerName, setProviderName] = useState('');
  const [value, setValue] = useState(0);

  useEffect(() => {
    const userEmail = window.location.href.split('/')[6];
    const recordRequestId = window.location.href.split('/')[7];
    dispatch(getRecordRequest(currentTenant.id, recordRequestId));
    dispatch(getUser(userEmail));
    dispatch(getUserPermissions(currentTenant.id, userEmail));
  }, [currentTenant]);

  useEffect(() => {
    if (recordRequest && userDetails) {
      dispatch(getCCD(currentTenant.id, userDetails.nationalHealthId, recordRequest.id));
    }
  }, [recordRequest, userDetails, currentTenant.id]);

  useEffect(() => {
    if (ccd && ccd.length > 0) {
      setValue(0);
      if (
        ccd[0].contentType &&
        (ccd[0].contentType === 'application/xml' || ccd[0].contentType === 'application/rtf')
      ) {
        setProviderName(ccd[0].providerName);
        if (ccd[0].allCCDData != null && ccd[0].allCCDData.length > 1) {
          setSrcURL(ccd[0].allCCDData[0]);
          setPDFURL(ccd[0].allCCDData[1]);
        } else {
          setSrcURL(ccd[0].allCCDData[0]);
          setPDFURL('');
        }
      } else if (ccd[0].contentType && ccd[0].contentType === 'application/pdf') {
        setProviderName(ccd[0].providerName);
        const pdfText = atob(ccd[0].allCCDData[0]);
        setSrcURL(pdfText);
        setPDFURL('');
      }
    }
  }, [ccd]);

  if (
    recordRequestLoading ||
    !recordRequest ||
    !userDetails ||
    !profilePicture ||
    userPermissionsLoading ||
    ccdLoading
  ) {
    return (
      <div>
        {matches ? (
          <div>
            <ProfileCardSkeletonLoading />
            <Paper style={{ borderRadius: '20px', marginBottom: 30 }} elevation={0}>
              <ProfileTabsSkeletonLoading />
            </Paper>
          </div>
        ) : (
          <div>
            <Card style={{ borderRadius: '20px', marginBottom: 20 }} elevation={0}>
              <ProfileCardSkeletonLoadingMobile />
            </Card>
            <Paper style={{ borderRadius: '20px', marginBottom: 30 }} elevation={0}>
              <ProfileDropdownSkeletonLoadingMobile />
            </Paper>
          </div>
        )}
      </div>
    );
  }

  const changeCCD = (index) => {
    if (ccd && ccd.length > 0) {
      if (
        ccd[index].contentType &&
        (ccd[index].contentType === 'application/xml' || ccd[index].contentType === 'application/rtf')
      ) {
        setProviderName(ccd[index].providerName);
        if (ccd[index].allCCDData != null && ccd[index].allCCDData.length > 1) {
          setSrcURL(ccd[index].allCCDData[0]);
          setPDFURL(ccd[index].allCCDData[1]);
        } else {
          setSrcURL(ccd[index].allCCDData[0]);
          setPDFURL('');
        }
      } else if (ccd[index].contentType && ccd[index].contentType === 'application/pdf') {
        setProviderName(ccd[index].providerName);
        const pdfText = atob(ccd[index].allCCDData[0]);
        setSrcURL(pdfText);
        setPDFURL('');
      }
    }
  };
  const showCCD = () => {
    const listItems = [];
    listItems.push();
    if (ccd && ccd.length > 0) {
      ccd.forEach((c, index) => {
        listItems.push(
          <Tab
            label={`CCD ${parseInt(index) + 1}`}
            style={{ fontSize: '12px', marginBottom: '-10px' }}
            className={value === '' || value === index ? classes.activeLink : ''}
          />
        );
      });
    }
    return listItems;
  };

  const handleChange = (e, value) => {
    setValue(value);
    changeCCD(value);
  };
  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <ProfileCard />
      </Grid>
      <Grid item xs={12} className={classes.bottomBox}>
        <Card style={{ width: '100%', marginBottom: '30px', borderRadius: '20px' }}>
          <CardHeader
            style={{ paddingBottom: '0px' }}
            className={classes.cardHeader}
            title={<Typography className={classes.cardHeader}>Record Request Details</Typography>}
          />
          <CardContent>
            <Grid container>
              <CardItem label="Record Request Id" value={recordRequest.id} width={fieldWidth} />
              <CardItem label="Record Request Status" value={recordRequest.recordRequestStatusStr} width={fieldWidth} />
              <CardItem label="Record Request Type" value={recordRequest.recordRequestTypeStr} width={fieldWidth} />
              <CardItem label="Provider Id" value={recordRequest.providerId} width={fieldWidth} />
              <CardItem label="Provider Name" value={recordRequest.provider.name} width={fieldWidth} />
              <CardItem label="Provider Email" value={recordRequest.provider.email} width={fieldWidth} />
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card style={{ width: '100%', marginBottom: '30px', borderRadius: '20px' }}>
          <CardHeader
            style={{ paddingBottom: '0px' }}
            className={classes.cardHeader}
            title={<Typography className={classes.cardHeader}>CCD Details</Typography>}
          />
          <CardContent>
            <Grid container>
              {ccd && ccd.length > 0 ? (
                <Card style={{ width: '100%', borderRadius: '20px' }} elevation={0}>
                  <Paper style={{ borderRadius: '20px 20px 0px 0px' }}>
                    <Tabs
                      indicatorColor="primary"
                      variant="fullWidth"
                      textColor="primary"
                      onChange={handleChange}
                      aria-label="disabled tabs example"
                    >
                      {showCCD()}
                    </Tabs>
                  </Paper>
                  <div>
                    <Grid>
                      <Box>
                        <Typography variant="body1" style={{ marginTop: 10 }}>
                          {`Provider Name : ${providerName}` || '---'}
                        </Typography>
                      </Box>
                    </Grid>
                  </div>
                  <div style={{ marginTop: 10 }}>
                    <iframe width="100%" height="400px" srcDoc={srcURL} title="Record Request" />
                    <iframe
                      hidden={!(pdfURL != null && pdfURL !== '')}
                      width="100%"
                      height="400px"
                      src={`data:application/pdf;base64,${pdfURL}`}
                      title="Record Request"
                    />
                  </div>
                </Card>
              ) : (
                <CardHeader
                  style={{ paddingBottom: '0px' }}
                  className={classes.cardHeader}
                  title={<Typography className={classes.cardHeader}>No CCD Available</Typography>}
                />
              )}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

function CardItem({ label, value, width }) {
  return (
    <Grid item xs={width} style={{ paddingBottom: 10 }}>
      <Box>
        <Typography
          variant="h4"
          style={{
            fontSize: '12px',
            fontWeight: 'normal',
            paddingBottom: '5px',
          }}
        >
          {label}
        </Typography>
        <div>
          <Typography variant="body1">{value ?? '---'}</Typography>
        </div>
      </Box>
    </Grid>
  );
}

export default withStyles(ProfileViewStyles)(RecordRequestDetail);
