/* eslint-disable react/no-array-index-key */
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

const styles = () => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: '15px 0',
    margin: '0 20px',
    height: '1em',
  },
  breadcrumb: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 0 0 5px',
    color: '#65656c',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  link: {
    color: '#65656c',
    textDecoration: 'none',
  },
  noLink: {
    color: '#65656c',
    textDecoration: 'none',
    cursor: 'auto',
  },
});

function AcBreadcrumbs({ classes, breadcrumbs, home, defaultLink }) {
  // helper method to build out paths recursively
  const buildBreadcrumbs = (foundBreadcrumbs = [], currentBreadcrumbs, parentPath) => {
    let foundBreadcrumb;
    let foundBreadcrumbPath;

    // iterate through the current breadcrumbs to see if we can go deeper in the path
    Object.keys(currentBreadcrumbs).forEach((path) => {
      // bounce out if we're not going down the right path
      if (!window.location.href.includes(`${parentPath}/${path}`)) return;

      foundBreadcrumb = currentBreadcrumbs[path];
      foundBreadcrumbPath = path;
    });

    // bounce out if we don't have a breadcrumb
    if (!foundBreadcrumb) return foundBreadcrumbs;

    // add this breadcrumb to the path
    foundBreadcrumbs.push(foundBreadcrumb);

    // if we have children breadcrumbs then continue recursively
    if (foundBreadcrumb.breadcrumbs)
      return buildBreadcrumbs(foundBreadcrumbs, foundBreadcrumb.breadcrumbs, `${parentPath}/${foundBreadcrumbPath}`);

    return foundBreadcrumbs;
  };

  // find breadcrumbs based on the path
  const foundBreadcrumbs = buildBreadcrumbs([], breadcrumbs, defaultLink);

  return (
    <div className={classes.root}>
      <div className={classes.breadcrumb}>
        <Link to={defaultLink} className={classes.link}>
          {home}
        </Link>
      </div>
      {foundBreadcrumbs.map((breadcrumb, i) => {
        const last = i + 1 === foundBreadcrumbs.length;
        if (!breadcrumb.title) return null;
        return (
          <div key={i} className={classes.breadcrumb}>
            /&nbsp;
            {last === true ? (
              breadcrumb.title
            ) : (
              <Link to={breadcrumb.path} className={breadcrumb.path === '#' ? classes.noLink : classes.link}>
                {breadcrumb.title}
              </Link>
            )}
          </div>
        );
      })}
    </div>
  );
}

AcBreadcrumbs.propTypes = {
  breadcrumbs: PropTypes.object,
  classes: PropTypes.object,
  // eslint-disable-next-line react/no-unused-prop-types
  location: PropTypes.object,
  defaultLink: PropTypes.string,
};

AcBreadcrumbs.defaultProps = {
  breadcrumbs: {},
  classes: {},
  location: {
    pathname: {
      includes() {},
    },
  }, // use react-router
  defaultLink: '/',
};

export default withStyles(styles)(AcBreadcrumbs);
