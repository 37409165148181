import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// components and helpers
import { selectTenantSettings } from '../Settings/selectors';
import AppTabbedPageWrapper from '../Shared/Pages/TabbedPageWrapper';

import { selectCurrentTenant } from '../Shared/selectors';
import MedicalCentersListWrapper from './MedicalCentersListWrapper';
import VendorsListWrapper from './VendorsListWrapper';

export function MedicalCentersPageWrapper(props) {
  const { type } = props;
  const currentTenant = useSelector(selectCurrentTenant);
  const tenantSettings = useSelector(selectTenantSettings);
  const navigate = useNavigate();
  const tabs = [
    // Medical Center removed for 3.1 release
    {
      label: 'Orderable Tests',
      type: 'testOrderable',
      url: 'medical-center/orderable-tests',
      content: <MedicalCentersListWrapper {...props} type="testOrderable" />,
    },
    {
      label: 'Orderable Panels',
      type: 'testPanels',
      url: 'medical-center/orderable-panels',
      content: <MedicalCentersListWrapper {...props} type="testPanels" />,
    },
    {
      label: 'Vendors',
      type: 'vendors',
      url: 'medical-center/vendors',
      content: <VendorsListWrapper {...props} type="vendors" />,
    },
  ];

  const handleTabChange = (tabDetails) => {
    navigate(`/${currentTenant.id}/${tabDetails.url}`);
  };

  const tabsToRender = tabs.filter((t) => {
    if (t.key) {
      return tenantSettings.menuItems[t.key] && t;
    }
    return t;
  });

  // Default Sidebar path is /orderable-tests. Change the url to first available if that tab is disabled in Platform Account Management - tenant settings
  useEffect(() => {
    if (tabsToRender) {
      let defaultValue = false;
      tabsToRender.forEach((element) => {
        if (window.location.pathname.includes(element.url)) {
          defaultValue = true;
        }
      });
      if (defaultValue) return;
      handleTabChange(tabsToRender[0]);
    }
  }, []);

  if (!currentTenant) return <div />;

  return (
    <AppTabbedPageWrapper
      tabs={tabsToRender}
      openTab={tabs.find((t) => t.type === type)}
      onTabChange={handleTabChange}
    />
  );
}

export default MedicalCentersPageWrapper;
