export const statusMapping = {
  ASYMPTOMATIC: {
    label: 'Asymptomatic',
    background: 'green',
    color: 'white',
  },
  SYMPTOMATIC: {
    label: 'Symptomatic',
    background: 'red',
    color: 'white',
  },
  EXPOSURE: {
    label: 'Exposure',
    background: 'red',
    color: 'white',
  },
  SECURE_TESTING: {
    label: 'Secure Testing',
    background: 'white',
    color: 'black',
  },
  NOT_COMPLETED: {
    label: 'Not Completed',
    background: 'white',
    color: 'black',
  },
  STAY_HOME: {
    label: 'Stay Home',
    background: 'red',
    color: 'white',
  },
  NO_RESULT: {
    label: 'Result Pending',
    background: 'white',
    color: 'black',
  },
  APPROVED: {
    label: 'Approved',
    background: 'green',
    color: 'white',
  },
  TESTING_AND_HOME: {
    label: 'Testing and Home',
    background: 'white',
    color: 'black',
  },
  TESTING_AND_WORK: {
    label: 'Testing and Work',
    background: 'white',
    color: 'black',
  },
};

export const actionTypes = {
  EDIT: {
    key: 'edit',
    label: 'Edit',
  },
  DELETE: {
    key: 'Delete',
    label: 'Delete',
  },
  ENABLER: {
    key: 'enabler',
    labelTrue: 'Activate',
    labelFalse: 'Deactivate',
  },
  VIEW: {
    key: 'view',
    label: 'View',
  },
  INVITE: {
    key: 'invite',
    label: 'Send Invitation',
  },
  REINVITE: {
    key: 're-invite',
    label: 'Resend Invitation',
  },
};
