/* eslint-disable react/no-array-index-key */
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { withStyles, useTheme } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import DeleteIcon from '@material-ui/icons/Delete';
import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CreateFormStyles from '../../../assets/jss/components/CreateFormStyles';
import { setAllowChange } from '../../LanguageMenu/languageSlice';
import CreateForm from './CreateForm';
import FormDialog from './FormDialog';
import { addFormData, initForm, resetForm, setFormSubmitting } from './formSlice';
import { selectFormData, selectFormUpdatedFields } from './selectors';

const matchesPattern = (value, pattern) => {
  if (!(value instanceof Date)) {
    const re = new RegExp(pattern, 'g');
    let matches;
    // strip masked phone number to check validity of numbers (not allow all numbers in a row)
    if (pattern === '^(?!(\\d)\\1{9})(?!0123456789|1234567890|0987654321|9876543210)\\d{10}$') {
      value = value.replace(/[^A-Z0-9]+/gi, '');
    }
    matches = value.match(re);
    return (matches && (matches.length > 1 || matches[0] !== value)) || !matches;
  }
};

const requiredFieldsReducer = (accum, field) => {
  if (field.type === 'group' || field.type === 'groupNoDropdown') {
    return field.fields.reduce(requiredFieldsReducer, accum);
  }
  if (field.required) {
    accum.push(field);
  } else if (field.pattern) {
    accum.push(field);
  }
  return accum;
};

function CreateFormWrapper(props) {
  const {
    classes,
    handleSubmit,
    steps,
    initialValues,
    tabs,
    handleDelete,
    handleCancel,
    formType,
    confirmation,
    isNoReset,
    labelButtonSubmit,
    getChildren,
    removeDeleteOption,
    isDirtyRequired,
    collectUpdatedFields = false, // collect updated fields only in separate obj
    disabledSubmit = false,
  } = props;
  const formData = useSelector(selectFormData);

  const [activeStep, setActiveStep] = useState(0);
  const [disabled, setDisabled] = useState(true);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [requiredFields, setRequiredFields] = useState(steps && steps[0].data.reduce(requiredFieldsReducer, []));
  const [confirmationDetails, setConfirmationDetails] = useState({});
  const [autoSetValues, setAutoSetValues] = useState(initialValues);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const submitting = formData && formData.submitting;
  const dispatch = useDispatch();

  const [hidden, setHidden] = useState(isDirtyRequired || false);
  // unmount reset state
  useEffect(
    () => () => {
      if (isNoReset) return;
      if (formType !== 'noSubmit') {
        setActiveStep(0);
        dispatch(resetForm());
      }
    },
    [formType, isNoReset]
  );

  // if editing, sets formData to existing form's values, but only upon first initialization
  useEffect(() => {
    if (initialValues && initialValues !== {}) {
      dispatch(initForm(initialValues));

      // ensure data provided to getStepContent is not stale on tabbed page navigation. e.g. settings tabs
      setAutoSetValues(initialValues);

      dispatch(setAllowChange(false));
    }
  }, [initialValues]);

  // loop through required fields and check if formData has value for key
  const isRequiredDataMissing = useCallback(
    (requiredData, dataToProcess = formData) => {
      let isMissingData = false;
      for (let i = 0; i < requiredData.length; i++) {
        const field = requiredData[i];

        if (field.type === 'dynamicList') {
          if (dataToProcess[field.name] && dataToProcess[field.name].length > 0) {
            isMissingData = dataToProcess[field.name].reduce((accum, current, idx) => {
              if (!accum && field.fieldset) {
                const requiredChildren = getChildren(field, dataToProcess[field.name][idx]).filter(
                  (child) => child.required
                );
                accum = isRequiredDataMissing(requiredChildren, current);
              } else if (!accum) {
                const requiredChildren = field.fields.filter((child) => child.required);
                accum = isRequiredDataMissing(requiredChildren, current);
              }
              return accum;
            }, false);
          } else {
            isMissingData = true;
          }
        } else if (field.type === 'conditional') {
          if (dataToProcess[field.checkField] === field.checkValue) {
            isMissingData = isRequiredDataMissing([{ ...field, type: field.targetType }], dataToProcess);
          }
        } else if (field.type === 'numeric' && field.required) {
          // Allow value of 0
          isMissingData = typeof dataToProcess[field.name] !== 'number' || dataToProcess[field.name] === null;
        } else if (
          field.required &&
          (dataToProcess[field.name] || field.type === 'toggle') &&
          (dataToProcess[field.name] !== undefined || field.default !== undefined)
        ) {
          // Check pattern
          if (field.pattern) {
            isMissingData = matchesPattern(dataToProcess[field.name], field.pattern);
          } else if (Array.isArray(dataToProcess[field.name])) {
            isMissingData = dataToProcess[field.name].length < 1;
          } else if (typeof dataToProcess[field.name] === 'string') {
            // Check for whitespace-only
            const value = dataToProcess[field.name].trim();
            isMissingData = !value;
            if (
              (field.minLength && field.minLength > value.length) ||
              (field.maxLength && field.maxLength < value.length)
            ) {
              isMissingData = true;
            }
          } else {
            isMissingData = false;
          }
        } else if (field.pattern && dataToProcess[field.name] && dataToProcess[field.name] !== undefined) {
          // Check pattern
          isMissingData = matchesPattern(dataToProcess[field.name], field.pattern);
        } else {
          isMissingData = !(
            !field.required &&
            field.pattern &&
            (!dataToProcess[field.name] || dataToProcess[field.name] === '')
          );
        }
        if (isMissingData) {
          break;
        }
      }
      return isMissingData;
    },
    [formData, getChildren]
  );

  // loop through required fields and check if formData has value for key
  const checkRequired = useCallback(
    (requiredData) => {
      const shouldDisableSubmit = isRequiredDataMissing(requiredData);
      setDisabled(shouldDisableSubmit);
    },
    [isRequiredDataMissing]
  );

  // check if required fields are populated on formData update
  useEffect(() => {
    checkRequired(requiredFields);
  }, [formData, steps, requiredFields, checkRequired]);

  // on active step change, get all required fields
  useEffect(() => {
    const newRequiredData = steps && steps[activeStep].data.reduce(requiredFieldsReducer, []);
    setRequiredFields(newRequiredData);
  }, [activeStep, steps]);

  const handleSetFormData = useCallback((name, value) => {
    setOpenConfirmation(false);
    dispatch(addFormData(name, name === 'mobileNumber' ? value.replace(/[^0-9]/g, '') : value, collectUpdatedFields));
    dispatch(setAllowChange(false));
  }, []);

  const handleChange = useCallback(
    (e, mapping) => {
      let { value } = e.target;
      let { name } = e.target;
      if (mapping) {
        const highLevelItems = ['content', 'providers', 'medical-center'];
        const menuItems = Object.keys(mapping);
        mapping[name] = e.target.checked;
        // if high level dropdown hidden/shown, also reflect checked value on children routes
        if (highLevelItems.includes(name)) {
          menuItems.forEach((item) => {
            if (item.includes(`${name}/`)) {
              mapping[item] = e.target.checked;
            }
          });
        }

        // check if all children are false, if so, also make parent false. Make sure parent is true if at least one child is true
        if (name.includes('/')) {
          const nested = Object.keys(mapping).filter((id) => id.includes(`${name.split('/')[0]}/`));
          const active = nested.filter((id) => mapping[id]);
          mapping[name.split('/')[0]] = active.length !== 0;
        }
        name = 'menuItems';
        value = mapping;
      }
      setHidden(false);
      handleSetFormData(name, value);
    },
    [handleSetFormData]
  );

  const handleChangeWithParams = useCallback(
    (name, value) => {
      if (name === 'isPrimaryVideo' && value && initialValues && initialValues[name] !== value) {
        setOpenConfirmation(true);
        setConfirmationDetails({
          title: 'Do you want to continue?',
          continueText: 'Ok',
          submitAction: () => handleSetFormData(name, value),
          onCancel: () => handleSetFormData(name, initialValues[name] || false),
          confirmation: 'This video will replace the current primary video in Resources section.',
        });
      } else if (name === 'isPrimaryVideo' && !value && initialValues && initialValues[name] === true) {
        setOpenConfirmation(true);
        setConfirmationDetails({
          title: 'Do you want to continue?',
          continueText: 'Ok',
          submitAction: () => handleSetFormData(name, value),
          onCancel: () => handleSetFormData(name, initialValues[name]),
          confirmation: 'By performing this action, Resources section will no longer have a primary video.',
        });
      } else if (
        name === 'type' &&
        (value === 'scale' || value === 'multiple' || value === 'multiple-drop' || value === 'yes-no')
      ) {
        handleSetFormData('options', []);
        handleSetFormData(name, value);
      } else {
        handleSetFormData(name, value);
      }
      setHidden(false);
    },
    [initialValues, handleSetFormData]
  );

  const handleAutoSetValue = useCallback(
    (values) => {
      setAutoSetValues({
        ...autoSetValues,
        ...values,
      });

      Object.keys(values).forEach((key) => {
        handleSetFormData(key, values[key]);
      });
    },
    [handleSetFormData, autoSetValues, setAutoSetValues]
  );

  // get step content based on active step and passed steps
  const getStepContent = useCallback(
    (activeStep) => (
      <CreateForm
        form={steps && steps[activeStep].data}
        formData={autoSetValues || initialValues || formData || {}}
        handleChange={handleChange}
        handleChangeWithParams={handleChangeWithParams}
        requiredFields={requiredFields}
        disabled={submitting}
        getChildren={getChildren}
        autoSetValues={handleAutoSetValue}
      />
    ),
    [
      steps,
      initialValues,
      formData,
      handleChange,
      handleChangeWithParams,
      requiredFields,
      submitting,
      handleAutoSetValue,
      autoSetValues,
      getChildren,
    ]
  );

  const handleStepClick = (event, newValue) => {
    setActiveStep(newValue);
  };

  const getSteps = () => {
    if (tabs) {
      return (
        <Tabs
          centered
          indicatorColor="primary"
          variant="fullWidth"
          value={activeStep}
          onChange={handleStepClick}
          aria-label="form tabs"
        >
          {steps.map((step, idx) => (
            <Tab key={`tab_${idx}`} label={step.label} value={idx} />
          ))}
        </Tabs>
      );
    }

    return (
      <Stepper activeStep={activeStep} orientation={matches ? 'horizontal' : 'vertical'} style={{ borderRadius: 20 }}>
        {steps.map((step) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={step.label} {...stepProps}>
              <StepLabel {...labelProps}>{step.label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
    );
  };

  // got to next step in form
  const handleNext = () => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  // go to previous step in form
  const handleBack = () => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const submitData = async () => {
    await checkRequired(requiredFields);
    if (!disabled) {
      setOpenConfirmation(false);
      dispatch(setFormSubmitting(true));
      dispatch(setAllowChange(true));
      // eslint-disable-next-line no-unused-expressions
      handleSubmit(formData);
    }
  };

  const onSubmitClick = () => {
    if (confirmation) {
      setOpenConfirmation(true);
      setConfirmationDetails({
        title: 'Are you sure you want to submit the changes?',
        continueText: 'Submit',
        submitAction: submitData,
        confirmation,
      });
    } else {
      submitData();
    }
  };

  const onCancelSubmit = () => {
    setOpenConfirmation(false);
    if (confirmationDetails.onCancel && typeof confirmationDetails.onCancel === 'function') {
      confirmationDetails.onCancel();
    }
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        {steps.length > 1 && getSteps()}
        <div>
          <div className={classes.instructions}>{getStepContent(activeStep)}</div>
          <div
            style={{
              float: tabs || formType === 'editContent' ? 'unset' : 'right',
            }}
            id="button"
          >
            <Grid container justifyContent={tabs ? 'space-evenly' : 'space-between'}>
              {formType === 'editContent' && (
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    disableElevation
                    elevation={0}
                    onClick={() => handleDelete(formData)}
                    className={classes.button}
                    startIcon={<DeleteIcon />}
                    style={{ display: removeDeleteOption ? 'none' : 'flex' }}
                    disabled={submitting || formData.deleting}
                  >
                    {formData.deleting && <CircularProgress size={20} style={{ marginRight: 5 }} />}
                    Delete
                  </Button>
                </Grid>
              )}
              {activeStep > 0 && (
                <Grid item>
                  <Button
                    style={{ display: !removeDeleteOption ? 'none' : 'flex' }}
                    variant="outlined"
                    color="primary"
                    onClick={handleBack}
                    disableElevation
                    elevation={0}
                    className={classes.button}
                  >
                    Back
                  </Button>
                </Grid>
              )}
              {handleCancel && (
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    disableElevation
                    elevation={0}
                    onClick={() => handleCancel()}
                    className={classes.button}
                  >
                    Cancel
                  </Button>
                </Grid>
              )}
              {activeStep === steps.length - 1 && !tabs && formType !== 'noSubmit' && (
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={
                      disabled||
                      submitting ||
                      formData.deleting ||
                      formData?.disabledSubmit
                    }
                    onClick={() => onSubmitClick()}
                    className={classes.button}
                    hidden={hidden}
                  >
                    {submitting && <CircularProgress size={20} style={{ marginRight: 5 }} />}
                    {labelButtonSubmit || 'Submit'}
                  </Button>
                </Grid>
              )}
              {tabs && (
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={
                      disabled ||
                      submitting ||
                      formData.deleting ||
                      disabledSubmit
                    }
                    onClick={() => onSubmitClick()}
                    className={classes.button}
                    style={{ marginBottom: 15 }}
                  >
                    {submitting && <CircularProgress size={20} style={{ marginRight: 5 }} />}
                    Save
                  </Button>
                </Grid>
              )}

              {activeStep < steps.length - 1 && (
                <Grid item>
                  <Button
                    variant={tabs ? 'outlined' : 'contained'}
                    color="primary"
                    disabled={disabled || submitting}
                    onClick={() => handleNext()}
                    className={classes.button}
                  >
                    Next
                  </Button>
                </Grid>
              )}
            </Grid>
          </div>
        </div>
      </Grid>
      {openConfirmation && (
        <FormDialog
          onCancelSubmit={onCancelSubmit}
          openConfirmation={openConfirmation}
          title={confirmationDetails && confirmationDetails.title}
          confirmation={confirmationDetails && confirmationDetails.confirmation}
          submitData={confirmationDetails && confirmationDetails.submitAction}
          continueText={confirmationDetails && confirmationDetails.continueText}
        />
      )}
    </Grid>
  );
}

export default withStyles(CreateFormStyles)(CreateFormWrapper);
