import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../api';

const initialState = {
  testKitLoading: 'idle',
  consultLoading: 'idle',
  rxOrderLoading: 'idle',
  benefitLoading: 'idle',
  benefitData: null,
  externalIdLoading: 'init' /* state:  init | idle | pending | fulfilled | rejected */,
  externalIdData: null,
  error: null,
};

// FIRST VIEW SIMULATOR
const fvSimulatorSlice = createSlice({
  name: 'fvSimulator',
  initialState,
  reducers: {
    resetTestKitLoading: (state) => {
      state.testKitLoading = 'idle';
    },
    resetConsultLoading: (state) => {
      state.consultLoading = 'idle';
    },
    resetRxOrderLoading: (state) => {
      state.rxOrderLoading = 'idle';
    },
    resetBenefitLoading: (state) => {
      state.benefitLoading = 'idle';
    },
    resetBenefitData: (state) => {
      state.benefitData = null;
    },
    resetExternalIdLoading: (state) => {
      state.externalIdLoading = 'idle';
    },
    resetExternalIdToInit: (state) => {
      state.externalIdLoading = 'init';
    },
    resetExternalIdData: (state) => {
      state.externalIdData = null;
    },
  },
  extraReducers: (builder) => {
    /* Simulate Test Kit */
    builder.addCase(startSimulateTestKit.pending, (state, action) => {
      if (action.meta.requestStatus === 'pending') {
        state.testKitLoading = 'pending';
      }
    });
    builder.addCase(startSimulateTestKit.fulfilled, (state, action) => {
      if (action.meta.requestStatus === 'fulfilled') {
        state.testKitLoading = 'fulfilled';
      }
    });
    builder.addCase(startSimulateTestKit.rejected, (state) => {
      state.testKitLoading = 'rejected';
    });

    /*  Simulate Consult */
    builder.addCase(startSimulateConsult.pending, (state, action) => {
      if (action.meta.requestStatus === 'pending') {
        state.consultLoading = 'pending';
      }
    });
    builder.addCase(startSimulateConsult.fulfilled, (state, action) => {
      if (action.meta.requestStatus === 'fulfilled') {
        state.consultLoading = 'fulfilled';
      }
    });
    builder.addCase(startSimulateConsult.rejected, (state) => {
      state.consultLoading = 'rejected';
    });

    /*  Simulate RxOrder */
    builder.addCase(startSimulateRxOrder.pending, (state, action) => {
      if (action.meta.requestStatus === 'pending') {
        state.rxOrderLoading = 'pending';
      }
    });
    builder.addCase(startSimulateRxOrder.fulfilled, (state, action) => {
      if (action.meta.requestStatus === 'fulfilled') {
        state.rxOrderLoading = 'fulfilled';
      }
    });
    builder.addCase(startSimulateRxOrder.rejected, (state) => {
      state.rxOrderLoading = 'rejected';
    });

    /*  Load EmployeeBenefit */
    builder.addCase(loadEmployeeBenefit.pending, (state, action) => {
      if (action.meta.requestStatus === 'pending') {
        state.benefitLoading = 'pending';
      }
    });
    builder.addCase(loadEmployeeBenefit.fulfilled, (state, action) => {
      if (action.meta.requestStatus === 'fulfilled' && action.payload[0].success) {
        state.benefitLoading = 'fulfilled';
      }

      /* No data is returned for  first time simulation */
      state.benefitData = action.payload[0]?.data ?? null;
    });
    builder.addCase(loadEmployeeBenefit.rejected, (state) => {
      state.benefitLoading = 'rejected';
    });
    /* Update Employee Benefit */
    builder.addCase(updateEmployeeBenefit.pending, (state, action) => {
      if (action.meta.requestStatus === 'pending') {
        state.benefitLoading = 'pending';
      }
    });
    builder.addCase(updateEmployeeBenefit.fulfilled, (state, action) => {
      if (action.meta.requestStatus === 'fulfilled') {
        state.benefitLoading = 'fulfilled';
      }

      /* TODO: Consider future cases of 'fulfilled' but with Error on API call */

      if (action.payload[0]?.success) {
        /* No data is returned for  first time simulation */
        state.benefitData = action.payload?.data ?? null;
      }
    });
    builder.addCase(updateEmployeeBenefit.rejected, (state) => {
      state.benefitLoading = 'rejected';
    });

    // EXTERNAL ID Simulator
    /*  Load External Id */
    builder.addCase(loadExternalId.pending, (state, action) => {
      if (action.meta.requestStatus === 'pending') {
        state.externalIdLoading = 'pending';
      }
    });
    builder.addCase(loadExternalId.fulfilled, (state, action) => {
      if (action.meta.requestStatus === 'fulfilled' && action.payload[0].success) {
        state.externalIdLoading = 'fulfilled';
      }

      /* No data is returned for  first time simulation */
      state.externalIdData = action.payload[0]?.data ?? null;
    });
    builder.addCase(loadExternalId.rejected, (state) => {
      state.externalIdLoading = 'rejected';
    });
    /* Update External Id */
    builder.addCase(updateExternalId.pending, (state, action) => {
      if (action.meta.requestStatus === 'pending') {
        state.externalIdLoading = 'pending';
      }
    });
    builder.addCase(updateExternalId.fulfilled, (state, action) => {
      if (action.meta.requestStatus === 'fulfilled') {
        state.externalIdLoading = 'fulfilled';
      }

      /* FIX: Removing logic below because APi is currently not returning the updated data */

      // if (action.payload[0]?.success) {
      //   /* No data is returned for  first time simulation */
      //   state.externalIdData = action.payload?.data ?? null;
      // }
    });
    builder.addCase(updateExternalId.rejected, (state) => {
      state.externalIdLoading = 'rejected';
    });
  },
});

export const {
  resetTestKitLoading,
  resetConsultLoading,
  resetRxOrderLoading,
  resetBenefitLoading,
  resetBenefitData,
  resetExternalIdLoading,
  resetExternalIdToInit,
  resetExternalIdData,
} = fvSimulatorSlice.actions;

export const startSimulateTestKit = createAsyncThunk(
  'fvSimulator/startSimulatorTestKit',
  async (payload, { rejectWithValue }) => {
    try {
      const { status, payloadFields } = payload;
      // console.log('startSimulateTestKit thunk');

      // const result = await new Promise((resolve, reject) => {
      //   setTimeout(() => {
      //     reject('error');
      //   }, 4000);
      // });

      const result = await api.postSimulateTestKit(status, payloadFields);

      return result;
    } catch (error) {
      if (!error.response) {
        throw new Error(error);
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const startSimulateConsult = createAsyncThunk(
  'fvSimulator/startSimulatorConsult',
  async (payload, { rejectWithValue }) => {
    try {
      const { status, payloadFields } = payload;
      const result = await api.postSimulateConsult(status, payloadFields);

      return result;
    } catch (error) {
      if (!error.response) {
        throw new Error(error);
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const startSimulateRxOrder = createAsyncThunk(
  'fvSimulator/startSimulatorRxOrder',
  async (payload, { rejectWithValue }) => {
    try {
      const { status, payloadFields } = payload;
      const result = await api.postSimulateRxOrder(status, payloadFields);

      return result;
    } catch (error) {
      if (!error.response) {
        throw new Error(error);
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const loadEmployeeBenefit = createAsyncThunk(
  'fvSimulator/loadEmployeeBenefit',
  async (params, { rejectWithValue }) => {
    try {
      const { tenantId, userId } = params;
      const result = await api.getSimulateEmployeeBenefit(tenantId, userId);

      return result;
    } catch (error) {
      if (!error.response) {
        throw new Error(error);
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const updateEmployeeBenefit = createAsyncThunk(
  'fvSimulator/updateEmployeeBenefit',
  async (payload, { rejectWithValue }) => {
    try {
      const { tenantId, userId, formData } = payload;

      const result = await api.postSimulateEmployeeBenefit(tenantId, userId, formData);

      return result;
    } catch (error) {
      if (!error.response) {
        throw new Error(error);
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const loadExternalId = createAsyncThunk(
  'fvSimulator/loadExternalId',
  async (params, { rejectWithValue }) => {
  try {
    const { userId } = params;
    const result = await api.getSimulateExternalId(userId);

    return result;
  } catch (error) {
    if (!error.response) {
      throw new Error(error);
    }

    return rejectWithValue(error.response.data);
  }
});

export const updateExternalId = createAsyncThunk(
  'fvSimulator/updateExternalId',
  async (payload, { rejectWithValue }) => {
  try {
    const { userId, formData } = payload;
    const result = await api.patchSimulateExternalId(userId, formData);

    return result;
  } catch (error) {
    if (!error.response) {
      throw new Error(error);
    }

    return rejectWithValue(error.response.data);
  }
});

export default fvSimulatorSlice.reducer;
