import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// components and helpers
import AppTabbedPageWrapper from '../../Shared/Pages/TabbedPageWrapper';

import { selectCurrentTenant } from '../../Shared/selectors';
import { createVerbiageSettings } from './SettingsVerbiage';

const StaticVerbiage = createVerbiageSettings();
const DocumentVerbiage = createVerbiageSettings();

function SettingsVerbiagePageWrapper(props) {
  const { tabType } = props;
  const currentTenant = useSelector(selectCurrentTenant);
  const navigate = useNavigate();

  const tabs = [
    {
      label: 'Screens',
      type: 'static',
      url: 'settings/verbiage/screens',
      content: <StaticVerbiage currentTenant={currentTenant} verbiageType="static" tabType={tabType} />,
    },
    {
      label: 'Legal Notices',
      type: 'documents',
      url: 'settings/verbiage/legal-notices',
      content: <DocumentVerbiage currentTenant={currentTenant} verbiageType="documents" tabType={tabType} />,
    },
  ];

  const handleTabChange = (tabDetails) => {
    navigate(`/${currentTenant.id}/${tabDetails.url}`);
  };

  if (!currentTenant) return <div />;

  return (
    <AppTabbedPageWrapper
      tabs={tabs}
      openTab={tabs.find((t) => t.url.includes(tabType))}
      onTabChange={handleTabChange}
    />
  );
}

export default SettingsVerbiagePageWrapper;
