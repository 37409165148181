import { createSlice } from '@reduxjs/toolkit';
import FileDownload from 'js-file-download';

import api from '../../api';
import { paths } from '../../constants';
import { showNotification } from '../Notifications/notificationSlice';
import { handleError } from '../Shared/Content/APIUtils';
import history from '../Shared/Redux/history';
import { getMyTenants, setCurrentTenant } from '../Shared/sharedSlice';

const initialState = {
  tenantSettings: null,
  tenantSettingsLoading: null,
  tenantTemplates: null,
  tenantTemplatesLoading: null,
  selectedTemplate: null,
  templateLoading: null,
  selectedTemplateSettings: null,
  templateSettingsLoading: null,
  submittingTemplate: null,
  submittingTest: null,
  submittingSettings: null,
  faqs: null,
  faqLoading: null,
  faqSubmitting: null,
  verbiage: null,
  verbiageLoading: null,
  verbiageDefinition: null,
  verbiageSubmitting: null,
  tenantFonts: null,
  tenantFontsLoading: null,
  createFontLoading: null,
  fontDetails: null,
  fontDetailsLoading: null,
  updatingFont: null,
  updateFontFaceLoading: null,
  deleteFontFaceLoading: null,
  uploadFontFaceFileLoading: null,
  customFontsList: null,
  customFontsListLoading: null,
  creatingTemplate: null,
  createdTemplate: null,
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setTenantSettingsLoading: (state) => ({
      ...state,
      tenantSettingsLoading: true,
    }),
    setTenantSettings: (state, { payload }) => ({
      ...state,
      tenantSettings: payload,
      tenantSettingsLoading: false,
    }),
    setTenantSettingsFailure: (state) => ({
      ...state,
      tenantSettingsLoading: false,
    }),
    setTenantTemplatesLoading: (state) => ({
      ...state,
      tenantTemplatesLoading: true,
    }),
    setTenantTemplates: (state, { payload }) => ({
      ...state,
      tenantTemplates: payload,
      tenantTemplatesLoading: false,
    }),
    setTemplateLoading: (state) => ({
      ...state,
      templateLoading: true,
    }),
    setTemplate: (state, { payload }) => ({
      ...state,
      selectedTemplate: payload,
      templateLoading: false,
    }),
    setTemplateSettingsLoading: (state) => ({
      ...state,
      templateSettingsLoading: true,
    }),
    setTemplateSettings: (state, { payload }) => ({
      ...state,
      selectedTemplateSettings: payload,
      templateSettingsLoading: false,
    }),
    setTemplateSubmitting: (state) => ({
      ...state,
      submittingTemplate: true,
    }),
    setTemplateSubmitted: (state) => ({
      ...state,
      submittingTemplate: false,
    }),
    setTestSubmitting: (state) => ({
      ...state,
      submittingTest: true,
    }),
    setTestSubmitted: (state) => ({
      ...state,
      submittingTest: false,
    }),
    setSettingsSubmitting: (state) => ({
      ...state,
      submittingSettings: true,
    }),
    setSettingsSubmitted: (state) => ({
      ...state,
      submittingSettings: false,
    }),
    setFAQsLoading: (state) => ({
      ...state,
      faqLoading: true,
    }),
    setFAQs: (state, { payload }) => ({
      ...state,
      faqs: payload,
      faqLoading: false,
    }),
    setFAQSubmitting: (state) => ({
      ...state,
      faqSubmitting: true,
    }),
    setFAQSubmitted: (state) => ({
      ...state,
      faqSubmitting: false,
    }),
    setVerbiageLoading: (state) => ({
      ...state,
      verbiageLoading: true,
    }),
    setVerbiage: (state, { payload }) => ({
      ...state,
      verbiage: payload,
      verbiageLoading: false,
    }),
    setVerbiageDefinitions: (state, { payload }) => ({
      ...state,
      verbiageDefinition: payload,
    }),
    setVerbiageSubmitting: (state) => ({
      ...state,
      verbiageSubmitting: true,
    }),
    setVerbiageSubmitted: (state) => ({
      ...state,
      verbiageSubmitting: false,
    }),
    setAllTenantFontsLoading: (state) => ({
      ...state,
      tenantFontsLoading: true,
    }),
    setAllTenantFonts: (state, { payload }) => ({
      ...state,
      tenantFonts: payload,
      tenantFontsLoading: false,
    }),
    setCreateFontLoading: (state) => ({
      ...state,
      createFontLoading: true,
    }),
    setCreateFontLoaded: (state, { payload }) => ({
      ...state,
      tenantFonts: payload,
      createFontLoading: false,
    }),
    setFontDetailsLoading: (state) => ({
      ...state,
      fontDetailsLoading: true,
    }),
    setFontDetails: (state, { payload }) => ({
      ...state,
      fontDetails: payload,
      fontDetailsLoading: false,
    }),
    setFontUpdating: (state, { payload }) => ({
      ...state,
      updatingFont: payload,
    }),
    setFontUpdated: (state, { payload }) => ({
      ...state,
      fontDetails: payload,
      updatingFont: false,
    }),
    setUpdateFontFaceLoading: (state, { payload }) => ({
      ...state,
      updateFontFaceLoading: payload,
    }),
    setUpdateFontFaceSuccess: (state, { payload }) => ({
      ...state,
      fontDetails: payload,
      updateFontFaceLoading: false,
    }),
    setDeleteFontFaceLoading: (state) => ({
      ...state,
      deleteFontFaceLoading: true,
    }),
    setDeleteFontFaceSuccess: (state, { payload }) => ({
      ...state,
      fontDetails: payload,
      deleteFontFaceLoading: false,
    }),
    setUploadFontFaceFileLoading: (state, { payload }) => ({
      ...state,
      uploadFontFaceFileLoading: payload,
    }),
    setUploadFontFaceFileSuccess: (state, { payload }) => ({
      ...state,
      fontDetails: payload,
      uploadFontFaceFileLoading: false,
    }),
    setCustomFontsListLoading: (state) => ({
      ...state,
      customFontsListLoading: true,
    }),
    setCustomFontsList: (state, { payload }) => ({
      ...state,
      customFontsList: payload,
      customFontsListLoading: false,
    }),
    resetSettings: () => ({}),
    setTemplateCreating: (state, { payload }) => ({
      ...state,
      creatingTemplate: payload,
    }),
    toggleEnable: (state, { payload: { id, payload } }) => ({
      ...state,
      tenantTemplates: {
        ...state.tenantTemplates,
        items: state.tenantTemplates.items.map((i) =>
          i.id === id
            ? {
                ...i,
                ...payload,
              }
            : i
        ),
      },
    }),
  },
});

export const {
  setTenantSettings,
  setTenantSettingsFailure,
  setTenantSettingsLoading,
  setTenantTemplates,
  setTenantTemplatesLoading,
  setTemplate,
  setTemplateLoading,
  setTemplateSettings,
  setTemplateSettingsLoading,
  setTemplateSubmitting,
  setTemplateSubmitted,
  setTestSubmitting,
  setTestSubmitted,
  setSettingsSubmitting,
  setSettingsSubmitted,
  setFAQs,
  setFAQsLoading,
  setFAQSubmitting,
  setFAQSubmitted,
  setVerbiage,
  setVerbiageLoading,
  setVerbiageDefinitions,
  setVerbiageSubmitting,
  setVerbiageSubmitted,
  setAllTenantFonts,
  setAllTenantFontsLoading,
  setCreateFontLoading,
  setCreateFontLoaded,
  setFontDetails,
  setFontDetailsLoading,
  setFontUpdating,
  setFontUpdated,
  setUpdateFontFaceLoading,
  setUpdateFontFaceSuccess,
  setDeleteFontFaceLoading,
  setDeleteFontFaceSuccess,
  setUploadFontFaceFileLoading,
  setUploadFontFaceFileSuccess,
  setCustomFontsList,
  setCustomFontsListLoading,
  resetSettings,
  setTemplateCreating,
  toggleEnable,
} = settingsSlice.actions;

export const getTenantSettings = (tenant) => async (dispatch) => {
  dispatch(setTenantSettingsLoading());
  const [result, error] = await api.getTenantSettingsRequest(tenant);

  if (result) {
    dispatch(setTenantSettings(result));
    // dispatch(getDefaultMenuItems(tenant, result));
  }

  if (error && error.message) {
    dispatch(handleError(error));
    dispatch(setTenantSettingsFailure());
  }
};

export const updateTenantSettings = (tenant, config, type) => async (dispatch) => {
  dispatch(setTenantSettingsLoading());
  const [result, error] = await api.updateTenantSettingsRequest(tenant, config);

  if (result) {
    if (type === 'outcomes') {
      dispatch(showNotification('Outcome saved successfully', 'success'));
    }
    dispatch(setTenantSettings(result));
  }

  if (error && error.message) {
    dispatch(handleError(error));
  }
};

export const getDefaultMenuItems = (tenant, data) => async (dispatch) => {
  dispatch(setTenantSettingsLoading());
  const [result, error] = await api.getDefaultMenuItemsRequest();

  if (result) {
    const newData = { ...data };
    if (data && data.menuItems) {
      const newMenuItems = { ...newData.menuItems };
      const currentMenuKeys = Object.keys(data.menuItems);
      const defaultMenuKeys = Object.keys(result);
      for (let i = 0; i < defaultMenuKeys.length; i++) {
        newMenuItems[defaultMenuKeys[i]] = currentMenuKeys.includes(defaultMenuKeys[i])
          ? data.menuItems[defaultMenuKeys[i]]
          : true;
      }
      newData.menuItems = newMenuItems;
    } else if (data && !data.menuItems) {
      newData.menuItems = result;
    }
    dispatch(updateTenantSettings(tenant, newData, 'defaultMenu'));
  }

  if (error && error.message) {
    dispatch(handleError(error));
  }
};

export const getAllTemplates =
  (tenant, page, pageSize, query, sort, direction, filter, language = 'en') =>
  async (dispatch) => {
    dispatch(setTenantTemplatesLoading());

    const params = {
      skip: page * pageSize,
      limit: pageSize,
      q: query,
      sort,
      direction,
      type: filter,
      language,
    };
    const [result, error] = await api.getAllTemplatesRequest(tenant.accountId, tenant.id, language, params);

    if (result) {
      dispatch(setTenantTemplates(result));
    }

    if (error && error.message) {
      dispatch(handleError(error));
    }
  };

export const getTemplate = (account, tenant, id, type) => async (dispatch) => {
  dispatch(setTemplateLoading());

  const [result, error] = await api.getTemplateRequest(account, tenant, id);

  if (result) {
    dispatch(setTemplate(result));
  }

  if (error && error.message) {
    if (error.message.includes('.ftl not found')) {
      if (type === 'text') {
        dispatch(setTemplate({ ...result, content: '', specs: null }));
      } else {
        const [result, error] = await api.getNewTemplateRequest(account, tenant, id);

        if (result) {
          dispatch(setTemplate(result));
        }

        if (error && error.message) {
          dispatch(handleError(error));
        }
      }
    } else {
      dispatch(handleError(error));
    }
  }
};

export const getTemplateSettings = (account, tenant, id) => async (dispatch) => {
  dispatch(setTemplateSettingsLoading());

  const [result, error] = await api.getTemplateSettingsRequest(account, tenant, id);

  if (result) {
    dispatch(setTemplateSettings(result));
  }

  if (error && error.message) {
    dispatch(handleError(error));
  }
};

export const saveTemplate = (account, tenant, templateId, tpl) => async (dispatch) => {
  dispatch(setTemplateSubmitting());

  const [result, error] = await api.updateTemplateRequest(account, tenant, templateId, tpl);

  if (result) {
    dispatch(setTemplate(result));
    dispatch(setTemplateSubmitted());
    dispatch(showNotification('Template successfully saved', 'success'));
  }

  if (error && error.message) {
    if (error.message.includes('does not exist for tenant')) {
      const [result, error] = await api.saveTemplateRequest(account, tenant, templateId, tpl);
      if (result) {
        dispatch(setTemplateSubmitted());
        dispatch(showNotification('Template successfully saved', 'success'));
      }
      if (error && error.message) {
        dispatch(setTemplateSubmitted());
        dispatch(handleError(error));
      }
    } else {
      dispatch(setTemplateSubmitted());
      dispatch(handleError(error));
    }
  }
};

export const createTemplateDef = (accountId, tenantId, definition) => async (dispatch) => {
  dispatch(setTemplateCreating(true));

  const [result, error] = await api.createTemplateDef(accountId, tenantId, definition);

  if (error && error.message) {
    dispatch(setTemplateCreating(false));
    dispatch(handleError(error));
  }

  if (result) {
    await dispatch(generateTemplate(accountId, tenantId, definition.id));
    const res = await dispatch(
      createTemplateSettings(accountId, tenantId, definition.id, definition.language, definition.type)
    );
    dispatch(setTemplateCreating(false));
    if (!res) return false;
    return true;
  }
  return false;
};

export const testTemplate = (account, tenant, templateId, email) => async (dispatch) => {
  dispatch(setTestSubmitting());

  const params = { email };
  const [result, error] = await api.testTemplateRequest(account, tenant, templateId, params);

  if (result) {
    dispatch(setTestSubmitted());
    dispatch(showNotification('Template successfully sent', 'success'));
  }

  if (error && error.message) {
    dispatch(setTestSubmitted());
    dispatch(handleError(error));
  }
};

export const saveTemplateSettings = (account, tenant, id, settings) => async (dispatch) => {
  dispatch(setTemplateSettingsLoading());
  dispatch(setSettingsSubmitting());

  const [result, error] = await api.saveTemplateSettingsRequest(account, tenant, id, settings);

  if (result) {
    dispatch(setSettingsSubmitted());
    dispatch(setTemplateSettings(result));
    dispatch(showNotification('Template settings successfully saved', 'success'));
  }

  if (error && error.message) {
    dispatch(setSettingsSubmitted());
    dispatch(handleError(error));
  }
};

export const createTemplateSettings = (accountId, tenantId, id, language) => async (dispatch) => {
  dispatch(setTemplateSettingsLoading());
  dispatch(setSettingsSubmitting());

  const settings = {
    email: true,
    enabled: true,
    push: true,
    safe: true,
    sms: true,
    subject: '',
  };

  const [result, error] = await api.createTemplateSettingsRequest(accountId, tenantId, id, language, settings);

  if (error && error.message) {
    dispatch(setSettingsSubmitted());
    dispatch(handleError(error));
  }

  if (result) {
    dispatch(setSettingsSubmitted());
    dispatch(setTemplateSettings(result));
    // history.push(
    //   `/${tenantId}/settings/system-messages/${
    //     type === systemMessagesTypes[systemMessagesTabTypes.EMAIL]
    //       ? systemMessagesTabTypes.EMAIL
    //       : systemMessagesTabTypes.SMS
    //   }/edit/${id}`
    // );
    dispatch(showNotification('Template settings created successfully', 'success'));
    return true;
  }

  return false;
};

export const generateTemplate = (accountId, tenantId, id) => async (dispatch) => {
  const data = {
    id,
    content: '',
    specs: '',
  };

  // eslint-disable-next-line
  const [result, error] = await api.generateTemplateRequest(accountId, tenantId, id, data);

  if (error && error.message) {
    dispatch(setSettingsSubmitted());
    dispatch(handleError(error));
  }
};

export const transformFaqs = (tenant) => async (dispatch) => {
  dispatch(setFAQsLoading());

  const [result, error] = await api.transformFaqsRequest(tenant);

  if (result) {
    /* eslint-disable-next-line no-console */
    console.log(`FAQ transformed for tenant - ${tenant}`);
  }

  if (error && error.message) {
    dispatch(handleError(error));
  }
};

export const getFaqs = (tenant, faqType) => async (dispatch, getState) => {
  dispatch(setFAQsLoading());
  const { language } = getState().language;

  const params = {
    language,
    TenantFaqType: faqType,
    tenantId: tenant,
  };
  const [result, error] = await api.getFaqsRequest(params);

  if (result) {
    dispatch(setFAQs(result.data));
  }

  if (error && error.message) {
    dispatch(handleError(error));
  }
};

export const updateFaq = (tenantId, faq, faqType) => async (dispatch, getState) => {
  dispatch(setFAQsLoading());
  dispatch(setFAQSubmitting());

  const { language } = getState().language;

  const { id, question, answer, sequenceNo } = faq;

  const body = {
    items: [
      {
        sequenceNo,
        question,
        answer,
      },
    ],
    language,
    tenantId,
    type: faqType,
    _id: id,
  };

  const [result, error] = await api.updateFaqRequest(body);

  if (result) {
    dispatch(setFAQSubmitted());
    dispatch(showNotification('Successfully Updated FAQ.', 'success'));
    return;
  }

  if (error && error.message) {
    dispatch(setFAQSubmitted());
    dispatch(handleError(error));
  }
};

export const addFaq = (tenantId, faq, faqType) => async (dispatch, getState) => {
  dispatch(setFAQsLoading());
  dispatch(setFAQSubmitting());

  const { language } = getState().language;
  const body = {
    items: [faq],
    tenantId,
    type: faqType,
    language,
  };

  const [result, error] = await api.addFaqRequest(body);

  if (result) {
    dispatch(setFAQSubmitted());
    dispatch(showNotification('Successfully Added FAQ.', 'success'));
    return;
  }

  if (error && error.message) {
    dispatch(setFAQSubmitted());
    dispatch(handleError(error));
  }
};

export const importFaq = (tenantId, faq, faqType) => async (dispatch, getState) => {
  dispatch(setFAQsLoading());
  dispatch(setFAQSubmitting());

  const { language } = getState().language;
  const body = {
    items: faq,
    tenantId,
    type: faqType,
    language,
  };

  const [result, error] = await api.importFaqRequest(body);

  if (result) {
    dispatch(setFAQSubmitted());
    dispatch(showNotification('Successfully Added FAQ.', 'success'));
    return;
  }

  if (error && error.message) {
    dispatch(setFAQSubmitted());
    dispatch(handleError(error));
  }
};

export const removeFaq = (faqId) => async (dispatch) => {
  dispatch(setFAQsLoading());
  dispatch(setFAQSubmitting());

  const [result, error] = await api.removeFaqRequest(faqId);

  if (result) {
    dispatch(setFAQSubmitted());
    dispatch(showNotification('Successfully Removed FAQ.', 'success'));
    return;
  }

  if (error && error.message) {
    dispatch(setFAQSubmitted());
    dispatch(handleError(error));
  }
};

export const getVerbiage =
  (account, tenant, language = 'en') =>
  async (dispatch) => {
    dispatch(setVerbiageLoading());

    const [result, error] = await api.getVerbiageRequest(account, tenant, language);

    if (result) {
      dispatch(setVerbiage(result));
    }

    if (error && error.message) {
      dispatch(handleError(error));
    }
  };

export const getVerbiageDefinitions = (account, tenant) => async (dispatch, getState) => {
  const { language } = getState().language;

  const [result, error] = await api.getVerbiageDefinitionsRequest(account, tenant, language);

  if (result) {
    dispatch(setVerbiageDefinitions(result));
  }

  if (error && error.message) {
    dispatch(handleError(error));
  }
};

export const updateVerbiage = (account, tenant, verbiage) => async (dispatch, getState) => {
  dispatch(setVerbiageLoading());
  dispatch(setVerbiageSubmitting());
  const { language } = getState().language;

  const [result, error] = await api.updateVerbiageRequest(account, tenant, language, verbiage);

  if (result) {
    dispatch(getVerbiageDefinitions(account, tenant));
    dispatch(setVerbiage(result));
    dispatch(setVerbiageSubmitted());
    dispatch(showNotification('Verbiage successfully updated.', 'success'));
  }

  if (error && error.message) {
    dispatch(setVerbiageSubmitted());
    dispatch(handleError(error));
  }
};

export const deleteVerbiage = (account, tenant, verbiageKey) => async (dispatch, getState) => {
  dispatch(setVerbiageLoading());
  dispatch(setVerbiageSubmitting());
  const { language } = getState().language;

  const [result, error] = await api.deleteVerbiageRequest(account, tenant, language, verbiageKey);

  if (result) {
    dispatch(setVerbiage(result));

    dispatch(setVerbiageSubmitted());
    dispatch(showNotification('Verbiage successfully deleted.', 'success'));
  }

  if (error && error.message) {
    dispatch(setVerbiageSubmitted());
    dispatch(handleError(error));
  }
};

export const createVerbiage = (account, tenant, verbiage) => async (dispatch) => {
  dispatch(setVerbiageLoading());
  dispatch(setVerbiageSubmitting());

  const [result, error] = await api.createVerbiageDefRequest(account, tenant, verbiage);

  if (result) {
    dispatch(showNotification('Verbiage definition successfully created.', 'success'));
    dispatch(setVerbiageSubmitted());
    return true;
  }

  if (error && error.message) {
    dispatch(setVerbiageSubmitted());
    dispatch(handleError(error));
  }
};

export const archiveVerbiageConsent = (tenant, verbiageKey) => async (dispatch) => {
  const params = {
    verbiageKey,
  };
  const [result, error] = await api.archiveVerbiageConsentRequest(tenant, params);

  if (result) {
    dispatch(showNotification('Related consent successfully archived.', 'success'));
    return result;
  }

  if (error && error.message) {
    dispatch(handleError(error));
  }
};

export const previewVerbiage = (tenant, verbiage) => async (dispatch) => {
  const [result, error] = await api.previewVerbiageRequest(tenant, verbiage);

  if (result) {
    return Promise.resolve(result);
  }

  if (error && error.message) {
    dispatch(handleError(error));
  }
};

export const updateRootTemplateTenant = (tenant, type) => async (dispatch) => {
  const [result, error] = await api.updateRootTemplateTenantRequest(tenant, type);

  if (result) {
    dispatch(setCurrentTenant(result));
    dispatch(getMyTenants());
  }

  if (error && error.message) {
    dispatch(handleError(error));
  }
};

export const getAllFonts = (tenant) => async (dispatch) => {
  dispatch(setAllTenantFontsLoading());

  const [result, error] = await api.getAllFontsRequest(tenant);

  if (result) {
    const reversed = result && result.length ? result : [];

    dispatch(
      setAllTenantFonts({
        items: reversed.reverse(),
        total: reversed.length,
      })
    );
  }

  if (error && error.message) {
    dispatch(handleError(error));
  }
};

export const addNewFont = (tenant, name, fallBackFonts) => async (dispatch, getState) => {
  const { tenantFonts } = getState().settings;
  dispatch(setCreateFontLoading());

  const data = { name, fallBackFonts };
  const [result, error] = await api.addNewFontRequest(tenant, data);

  if (result) {
    const updatedFontsList = tenantFonts && tenantFonts.items ? [...tenantFonts.items] : [];
    updatedFontsList.unshift(result);

    dispatch(
      setCreateFontLoaded({
        items: updatedFontsList,
        total: updatedFontsList.length,
      })
    );
    dispatch(showNotification('New font saved', 'success'));
  }

  if (error && error.message) {
    dispatch(
      setCreateFontLoading({
        items: tenantFonts?.items,
        total: tenantFonts?.items?.length,
      })
    );
    dispatch(handleError(error));
  }
};

export const getFontDetails = (tenantId, fontId) => async (dispatch) => {
  dispatch(setFontDetailsLoading());

  const [result, error] = await api.getFontDetailsRequest(tenantId, fontId);

  if (result) {
    dispatch(setFontDetails(result));
  }

  if (error && error.message) {
    dispatch(handleError(error));
  }
};

export const updateFontData = (tenant, fontId, name, fallBackFonts) => async (dispatch) => {
  dispatch(setFontUpdating(true));

  const data = {
    name,
    fallBackFonts,
  };
  const [result, error] = await api.updateFontDataRequest(tenant, fontId, data);

  if (result) {
    dispatch(setFontUpdated(result));
    dispatch(showNotification('Font successfully saved', 'success'));

    return true;
  }

  if (error && error.message) {
    dispatch(setFontUpdating(false));
    dispatch(handleError(error));
    return false;
  }
};

export const deleteFont = (tenant, fontId) => async (dispatch) => {
  const [result, error] = await api.deleteFontRequest(tenant, fontId);
  if (result) {
    const fontsListLink = paths.settingsNetworkConfigByAssetsTypeTab(tenant, 'assets', 'fonts');
    dispatch(showNotification('Font deleted', 'success'));

    if (window.location.pathname === fontsListLink) {
      dispatch(getAllFonts(tenant));
    } else {
      history.back();
    }
  }

  if (error && error.message) {
    dispatch(handleError(error));
  }
};

export const updateFontFace = (tenant, fontId, fontFaceId, weight, style) => async (dispatch, getState) => {
  dispatch(setUpdateFontFaceLoading(true));

  const { fontDetails } = getState().settings;
  fontFaceId = fontFaceId ? `/${fontFaceId}` : '';

  const data = { weight, style };
  const [result, error] = await api.updateFontFaceRequest(tenant, fontId, fontFaceId, data);

  if (result) {
    const updatedFontFacesList = { ...fontDetails };
    const updatedFontFaceId = Object.keys(result)[0];
    updatedFontFacesList.faces = { ...fontDetails.faces };
    updatedFontFacesList.faces[updatedFontFaceId] = result[updatedFontFaceId];

    dispatch(setUpdateFontFaceSuccess(updatedFontFacesList));
    dispatch(showNotification(`Font-face successfully ${!fontFaceId ? 'added' : 'updated'}`, 'success'));
    return true;
  }

  if (error && error.message) {
    dispatch(setUpdateFontFaceLoading(false));
    dispatch(handleError(error));
    return false;
  }
};

export const deleteFontFace = (tenant, fontId, fontFaceId) => async (dispatch) => {
  dispatch(setDeleteFontFaceLoading());

  const [result, error] = await api.deleteFontFaceRequest(tenant, fontId, fontFaceId);

  if (result) {
    dispatch(setDeleteFontFaceSuccess(result));
    dispatch(showNotification('Font-face deleted', 'success'));
    return true;
  }

  if (error && error.message) {
    dispatch(handleError(error));
    return false;
  }
};

export const uploadFontFaceFile = (tenant, fontId, fontFaceId, fileData) => async (dispatch) => {
  dispatch(setUploadFontFaceFileLoading(true));

  const [result, error] = await api.uploadFontFaceFileRequest(tenant, fontId, fontFaceId, fileData);

  if (result) {
    dispatch(setUploadFontFaceFileSuccess(result));
    dispatch(showNotification('File successfully uploaded', 'success'));
  }

  if (error && error.message) {
    dispatch(setUploadFontFaceFileLoading(false));
    dispatch(handleError(error));
  }
};

export const getCustomFontsList = (tenant) => async (dispatch) => {
  dispatch(setCustomFontsListLoading());

  const [result, error] = await api.getCustomFontsListRequest(tenant);

  if (result) {
    dispatch(setCustomFontsList(result));
  }

  if (error && error.message) {
    dispatch(handleError(error));
  }
};

export const exportZIPTemplates = (tenant) => async (dispatch) => {
  const [result, error] = await api.exportZIPTemplatesRequest(tenant);

  if (result) {
    const tenantName = tenant && tenant.title.replace(/ /g, '_');
    FileDownload(result, `${tenantName}.zip`);
  }

  if (error && error.message) {
    dispatch(handleError(error));
  }
};

export const importZIPTemplates = (account, tenant, fileData) => async (dispatch) => {
  const [result, error] = await api.importZIPTemplatesRequest(account, tenant, fileData);

  if (result) {
    dispatch(showNotification('System messages successfully imported', 'success'));
  }

  if (error && error.message) {
    dispatch(handleError(error));
  }
};

export const toggleEnabled = (id, settings) => async (dispatch) => {
  dispatch(
    toggleEnable({
      id,
      payload: settings,
    })
  );
};

export default settingsSlice.reducer;
