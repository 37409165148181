/* eslint-disable react/no-unstable-nested-components */
import Grid from '@material-ui/core/Grid';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import React, { useState, useEffect } from 'react';

// redux
import { useDispatch, useSelector } from 'react-redux';

import { findProvider } from '../../../../Orders/ordersSlice';
import { selectProviders } from '../../../../Orders/selectors';

import ListFeedback from './ListFeedback';

// ui reflection of file view
function AutocompleteInput(props) {
  const { handleChange, disabled, field, autoSetValues, handleChangeWithParams } = props;

  const providers = useSelector(selectProviders);

  const [loading, setLoading] = useState(false);
  const [autocompleteValue, setAutocompleteValue] = useState(undefined);

  const [timeout, setStateTimeout] = useState(undefined);

  const dispatch = useDispatch();

  useEffect(() => {
    if (providers) {
      setLoading(providers.loading);
    }
  }, [providers]);

  const onChange = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    setAutocompleteValue(value);

    // added for use with Tenant Configuration Medical Director selection and auto population
    if (handleChangeWithParams) {
      handleChangeWithParams(name, value);
    } else {
      handleChange(value);
    }

    if (field && field.setFields && autoSetValues) {
      autoSetValues(
        field.setFields.reduce(
          (accum, f) => ({
            ...accum,
            [f.targetField]: (e.target.value && e.target.value[f.field]) || '',
          }),
          {}
        )
      );
    }
  };

  const handleFilterChange = (event) => {
    if (event.filter.value.length > 2) {
      if (timeout) {
        clearTimeout(timeout);
      }

      setStateTimeout(
        setTimeout(() => {
          dispatch(findProvider(event.filter.value));
        }, 2000)
      );

      setLoading(true);
    }
  };

  return (
    <Grid item xs={12} className={field?.variant || ''}>
      <ComboBox
        data={
          (providers &&
            providers.list &&
            providers.list.map((v) => ({
              ...v,
              fullName: `${v.npi} - ${v.title || ''} ${v.firstName || ''} ${v.middleName || ''} ${v.lastName || ''}`,
            }))) ||
          []
        }
        allowCustom={false}
        suggest
        filterable
        onFilterChange={handleFilterChange}
        label="Select a provider by NPI"
        name="provider"
        required={!(field && field.required === false)}
        onChange={onChange}
        style={{ width: '100%' }}
        textField="fullName"
        value={autocompleteValue}
        loading={loading}
        disabled={disabled}
        listNoDataRender={() => <ListFeedback loading={loading} />}
      />
    </Grid>
  );
}

export default AutocompleteInput;
