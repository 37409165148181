import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// component and styling imports
import SearchbarStyles from '../../assets/jss/components/SearchbarStyles';
import { tagsField } from '../VirtualConsult/QuestionnaireBuilder/helpers/formHelper';
import { selectVirtualConsult } from '../VirtualConsult/selectors';
import { getTags } from '../VirtualConsult/virtualConsultSlice';

import { createInputHelper } from './Forms/helpers/createInputHelper';
import { selectCurrentTenant } from './selectors';

function TagSearch(props) {
  const { tags, disabled, actions, handleChangeTags } = props;

  const currentTenant = useSelector(selectCurrentTenant);
  const consult = useSelector(selectVirtualConsult);

  const [timeout, setStateTimeout] = useState(undefined);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTags(currentTenant.id, ''));
  }, [actions, currentTenant.id]);

  const handleChange = (e) => {
    if (typeof e.target.value !== 'string') return;

    if (!e.target.value || tags.findIndex((tag) => tag === e.target.value) !== -1) return;
    handleChangeTags([...tags, e.target.value]);
  };

  const handleFilterChange = (e) => {
    if (timeout) {
      clearTimeout(timeout);
    }
    setStateTimeout(
      setTimeout(() => {
        dispatch(getTags(currentTenant.id, e.filter.value));
      }, 500)
    );
  };

  return (
    <Grid container spacing={2} alignItems="center" alignContent="center">
      <Grid item xs={2} style={{ paddingBottom: '13px' }}>
        <ComboBox
          data={consult && consult.tags && consult.tags.filter(Boolean)}
          allowCustom
          suggest
          filterable
          onFilterChange={handleFilterChange}
          label="Find tag"
          name="Tags"
          required={false}
          onChange={handleChange}
          style={{ width: '100%' }}
          textField=""
          value=""
          loading={false}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={8}>
        {createInputHelper(
          tagsField,
          { tags },
          () => ({}),
          (name, value) => {
            handleChangeTags(value);
          }
        )}
      </Grid>
    </Grid>
  );
}

export default withStyles(SearchbarStyles)(TagSearch);
