import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import React, { useCallback, useState } from 'react';
// redux
import { useDispatch } from 'react-redux';
import { getUserFromToken } from '../../Auth/helpers';
import { removePermission } from './permissionSlice';

function PermissionsActionCell(props) {
  const { dataItem } = props;
  const [openDialog, setOpenDialog] = useState(false);

  const user = getUserFromToken();

  const dispatch = useDispatch();

  const onRemovePermission = useCallback(() => {
    setOpenDialog(true);
  }, []);

  const handleSubmit = useCallback(async () => {
    await dispatch(removePermission(dataItem.userId));
    setOpenDialog(false);
  }, [dataItem]);

  const handleCancel = useCallback(() => {
    setOpenDialog(false);
  }, []);

  if (user && user.email !== dataItem.userId) {
    return (
      <td>
        <Button variant="contained" color="primary" onClick={onRemovePermission}>
          Remove
        </Button>

        {openDialog && (
          <Dialog title="Removal Confirmation" onClose={handleCancel} width={400} height={225}>
            <Typography>
              Are you sure you want to remove{' '}
              <b>
                <em>{` ${dataItem.userId} `}</em>
              </b>
              from Account Administrators list?
            </Typography>
            <DialogActionsBar>
              <button className="k-button" onClick={handleCancel}>
                Cancel
              </button>
              <button className="k-button" onClick={handleSubmit}>
                OK
              </button>
            </DialogActionsBar>
          </Dialog>
        )}
      </td>
    );
  }

  return (
    <td>
      <p />
    </td>
  );
}

export default PermissionsActionCell;
