/* eslint-disable import/no-cycle */
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import _ from 'lodash';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ProfileViewStyles from '../../assets/jss/components/ProfileViewStyles';
import CreateForm from '../Shared/Forms/CreateForm';
import { addFormData } from '../Shared/Forms/formSlice';
import { selectFormData } from '../Shared/Forms/selectors';
import utils from '../Shared/Utils/utils';
import { AdvancedVendorSettingsMap } from './helpers/formHelper';

function AdvancedVendorSettings(props) {
  const { classes } = props;

  const formData = useSelector(selectFormData);

  const [tab, setTab] = useState(0);
  const [data, setData] = useState(formData && formData.enabledLabVendorsList);

  const dispatch = useDispatch();

  const handleChangeTab = (e, value) => {
    setTab(value);
  };

  // bugfix/SAF-657 - fixed issue when sorting data directly
  const sortDataTabs = data ? [...data] : [];

  const renderTabs = () => {
    if (!data) return null;
    return (
      <Tabs
        value={tab}
        indicatorColor="primary"
        variant="fullWidth"
        textColor="primary"
        onChange={handleChangeTab}
        aria-label="disabled tabs example"
      >
        {sortDataTabs
          .sort((a, b) => a.vendorName.localeCompare(b.vendorName))
          .map((tab) => (
            <Tab
              key={tab.vendorName}
              label={utils.removeUnderscoresTitleCase(tab.vendorName)}
              id={tab.vendorId}
              style={{ fontSize: '12px', marginBottom: '-10px' }}
              className={tab === 0 ? classes.activeLink : ''}
            />
          ))}
      </Tabs>
    );
  };

  const handleChange = (e) => {
    const { value, name } = e.target;
    const updatedVendors = [..._.cloneDeep(data)];

    updatedVendors[tab].labSettings[name] = value;
    setData(updatedVendors);
    dispatch(addFormData('enabledLabVendorsList', updatedVendors));
  };

  if (!formData.enabledLabVendorsList || (formData.enabledLabVendorsList && !formData.enabledLabVendorsList.length)) {
    return (
      <Grid container item xs={12}>
        <Typography style={{ paddingLeft: 16 }}>Please go to the Medical Center and select vendors.</Typography>
      </Grid>
    );
  }

  if (
    !formData.enabledLabVendorsList ||
    (formData.enabledLabVendorsList && !formData.enabledLabVendorsList.length) ||
    !data
  )
    return null;

  return (
    <>
      <Grid container>{renderTabs()}</Grid>
      <Grid container spacing={3}>
        <CreateForm form={AdvancedVendorSettingsMap} formData={data[tab].labSettings} handleChange={handleChange} />
      </Grid>
    </>
  );
}

export default withStyles(ProfileViewStyles)(AdvancedVendorSettings);
