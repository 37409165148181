/* eslint-disable react/no-unstable-nested-components */
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Collapse from '@material-ui/core/Collapse';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
// import { makeStyles } from '@material-ui/core/styles';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Info from '@material-ui/icons/Info';
import SearchIcon from '@material-ui/icons/Search';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import createDOMPurify from 'dompurify';
import { cloneDeep } from 'lodash';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import DragExpansionWrapperStyles from '../../../assets/jss/components/DragExpansionWrapperStyles';
import { showConfirmation } from '../../Confirmation/confirmationSlice';
import { columns, transform } from '../../Content/helpers/contentListMapper';
import { setAllowChange } from '../../LanguageMenu/languageSlice';
import { selectLanguage } from '../../LanguageMenu/selectors';
import CreateFormWrapper from '../../Shared/Forms/CreateFormWrapper';
import { initForm } from '../../Shared/Forms/formSlice';
import HtmlInputText from '../../Shared/Forms/KendoFields/HtmlInputText';
import ImageUploadCrop from '../../Shared/Forms/KendoFields/ImageUploadCrop';
import { selectFormData } from '../../Shared/Forms/selectors';
import { selectCurrentTenant } from '../../Shared/selectors';
import AppKendoTableWrapper from '../../Shared/Table/KendoTableWrapper';
import utils from '../../Shared/Utils/utils';
import SettingsTemplateVariableHelper from '../SystemMessages/SettingsTemplateVariableHelper';

import { verbiageSnackbarFormMap, defaultConsentLabels, createActionView } from './helpers/formHelper';
import { variables } from './helpers/verbiageVariables';

const DOMPurify = createDOMPurify(window);

function SettingsExpansionPanel(props) {
  const {
    classes,
    q,
    data,
    title,
    content,
    onHandleEditing,
    onHandleSave,
    onHandleArchiveConsent,
    onHandleDelete,
    onHandleCancel,
    expandedPanel,
    handleExpansionChange,
    editing,
    editingType,
    identifier,
    type,
    matches,
    onHandleMoveDown,
    onHandleMoveUp,
    submitting,
    deleting,
    availableDefinitions,
    verbiageDefinitions = {},
    allDefsPairs,
    onHandlePreview,
    images,
    verbiageType,
    archiveConsentLoading,
    snackbarDefinitions,
    actions,
  } = props;

  const defEntries = Object.entries(verbiageDefinitions);
  const formData = useSelector(selectFormData);
  const currentTenant = useSelector(selectCurrentTenant);
  const language = useSelector(selectLanguage);
  const navigate = useNavigate();

  const noticeTypeLabels = {
    LEGAL_NOTICE: 'Legal Notice',
    PARENTAL_CONSENT: 'Parental Consent',
    ADMIN_LEGAL_NOTICE: 'Admin Legal Notice',
    ORDINAL: 'Ordinal',
  };

  // const editor = useRef(null);
  const [titleUpdate, setTitleUpdate] = useState('');
  const [variableOpen, setVariableOpen] = useState(false);
  const [outcomeImage, setOutcomeImage] = useState(images ? images[title] : null);
  const [headerDocument, setHeaderDocument] = useState(q.header);
  const [enableNeedConsent, setEnableNeedConsent] = useState(q.needConsent);
  const [newEditorContent, setNewEditorContent] = type === 'faq' ? useState(q.answer) : useState(q.value);
  const [snackbarActions, setSnackbarActions] = useState({});
  const [enableAgreement, setEnableAgreement] = useState(q.agreement);
  const [noticeType, setNoticeType] = useState(q.type || 'LEGAL_NOTICE');
  const [optional, setOptional] = useState(q.optional || false);
  const [confirmationConsentBtnLabel, setConfirmationConsentBtnLabel] = useState(q.confirmationConsentBtnLabel);
  const [availableConsentLabels, setAvailableConsentLabels] = useState(defaultConsentLabels);
  const [disableSave, setDisableSave] = useState(false);
  const [isEmptyValues, setIsEmptyValues] = useState(false);

  // const disableSave = editingType === 'new' && (!titleUpdate || !newEditorContent);

  const dispatch = useDispatch();

  useEffect(() => {
    if (q && Object.keys(q).length > 0) {
      const content = type === 'faq' ? q.answer : q.value;
      setNewEditorContent(content);
    }
  }, [q]);

  useEffect(() => {
    if (data && data.length) {
      const updatedConsentLabels = defaultConsentLabels;
      data.forEach((item) => {
        if (
          item.document &&
          item.confirmationConsentBtnLabel &&
          !updatedConsentLabels.includes(item.confirmationConsentBtnLabel)
        ) {
          updatedConsentLabels.push(item.confirmationConsentBtnLabel);
        }
      });
      setAvailableConsentLabels(updatedConsentLabels);
    }
  }, [data]);

  useEffect(() => {
    if (formData?.snackbarActions) {
      setDisableSave(true);
      let isEmptyValues = false;
      formData.snackbarActions.forEach((item) => {
        isEmptyValues = !item?.id || !item?.value;
      });
      setIsEmptyValues(isEmptyValues);
    }
  }, [formData, newEditorContent]);

  useEffect(() => {
    setDisableSave(
      editingType === 'new' && (!titleUpdate || !newEditorContent || newEditorContent === '<p></p>' || isEmptyValues)
    );
  }, [editingType, titleUpdate, newEditorContent, isEmptyValues]);

  // Check for header updates to update rendering of switch
  useEffect(() => {
    if (verbiageType === 'documents' && q.header !== headerDocument) {
      setHeaderDocument(q.header);
    }
  }, [q]);

  // updates question text on change of textfield in edit mode
  const handleTitleUpdate = (e) => {
    const { value } = e.target;
    setTitleUpdate(value);
  };

  // updates question text on change of textfield in edit mode
  const handleConsentLabelUpdate = (e) => {
    const { value } = e.target;
    setConfirmationConsentBtnLabel(value);
  };

  const handleUpdate = (name, value) => {
    setNewEditorContent(value);
  };
  // handles save of any changes to question/answer
  const handleSave = () => {
    const saveUpdate = (dataToSave) => {
      let toUpdate = cloneDeep(dataToSave);
      onHandleSave(toUpdate);
      setTitleUpdate('');
      onHandleEditing('', '', false);
    };

    let updatedData = JSON.parse(JSON.stringify(data));
    if (type === 'faq') {
      updatedData[editing].question = titleUpdate;
      updatedData[editing].answer = newEditorContent;
    } else if (type === 'verbiage') {
      updatedData[identifier] = { ...updatedData[identifier] };
      updatedData[identifier].value = newEditorContent;
      updatedData[identifier].actions = formData.snackbarActions || [];
      if (editingType === 'new' || verbiageType === 'documents') {
        updatedData[identifier].language = language;
        updatedData[identifier].title = titleUpdate;
        defEntries.forEach((q) => {
          if (q[1].title === titleUpdate) {
            updatedData[identifier].subtype = q[1].subtype;
          }
        });
        if (verbiageType === 'documents') {
          updatedData[identifier].key = allDefsPairs[titleUpdate]
            ? allDefsPairs[titleUpdate]
            : titleUpdate.replace(/[^\w]/g, '');
          updatedData[identifier].needConsent = enableNeedConsent;
          updatedData[identifier].agreement = enableAgreement;
          updatedData[identifier].type = noticeType;
          updatedData[identifier].optional = optional;
          updatedData[identifier].confirmationConsentBtnLabel = confirmationConsentBtnLabel;
        } else {
          updatedData[identifier].key = allDefsPairs[titleUpdate];
        }
      }
    } else if (type === 'outcome') {
      updatedData = {
        outcomesText: data,
        outcomesImages: images,
      };
      updatedData.outcomesText = {
        ...updatedData.outcomesText,
        [title]: newEditorContent,
      };
      updatedData.outcomesImages = {
        ...updatedData.outcomesImages,
        [title]: outcomeImage,
      };
    }
    if (verbiageType === 'documents' && headerDocument !== q.header) {
      const oldHeaderDocument = updatedData.findIndex((legalNotice) => legalNotice.header);
      if (headerDocument && oldHeaderDocument > -1) {
        dispatch(
          showConfirmation(
            'Reassign Header?',
            /* eslint-disable-next-line */
            `Do you wish to change the header from "${updatedData[oldHeaderDocument].title}" to "${updatedData[identifier].title}"?`,
            'reassignVerbiageHeader',
            currentTenant,
            '',
            '',
            () => {
              // If confirmation answered Yes, update headers and save
              updatedData[oldHeaderDocument].header = false;
              updatedData[identifier].header = headerDocument;
              saveUpdate(updatedData);
            },
            () => {
              // If confirmation answered No, do not update headers, but save other changes
              saveUpdate(updatedData);
            }
          )
        );
      } else {
        updatedData[identifier].header = headerDocument;
        saveUpdate(updatedData);
      }
    } else {
      saveUpdate(updatedData);
    }
  };

  // handles preview of any changes to verbiage
  const handlePreview = () => {
    let previewData;
    if (editing) {
      previewData = {
        key: editingType === 'new' ? allDefsPairs[titleUpdate] : data[identifier].key,
        title: editingType === 'new' ? titleUpdate : data[identifier].title,
        // value: newEditorContent,
        value: newEditorContent,
      };
    } else {
      previewData = {
        key: data[identifier].key,
        title: data[identifier].title,
        value: data[identifier].value,
      };
    }
    onHandlePreview(previewData);
  };

  // on cancel, do not save any changes made and exit edit mode. If new FAQ, remove object from list.
  const handleCancel = () => {
    if (editingType === 'new') {
      const updatedData = data.slice(0, -1);
      onHandleCancel(updatedData);
    }
    setTitleUpdate('');
    onHandleEditing('', '', false);
    setHeaderDocument(q.header);
    setEnableNeedConsent(q.needConsent);
    setEnableAgreement(q.agreement);
    setConfirmationConsentBtnLabel(q.confirmationConsentBtnLabel);
    dispatch(setAllowChange(true));
  };

  // on click of 'edit' button,
  const handleEditing = (q) => {
    // if type is vids, redirect to edit page
    if (type === 'videos') {
      const parentPath = window.location.pathname.match(/\/$/)
        ? window.location.pathname
        : `${window.location.pathname}/`;
      navigate(`${parentPath}details/${q._id}`);
    } else {
      // if not vids, get HTML from faq and convert into draft to display in wysiwyg, go into edit mode within expansion panel
      setTitleUpdate(title);
      onHandleEditing(identifier, 'update', true);

      if (data.length) {
        data.forEach((item) => {
          if (item.title === title) {
            const form = { snackbarActions: item.actions };
            dispatch(initForm(form));
            setSnackbarActions(form);
          }
        });
      }
    }

    dispatch(setAllowChange(false));
  };

  const handleDelete = (q) => {
    onHandleDelete(q);
    onHandleEditing('', '', false);
  };

  const handleArchiveConsent = () => {
    const verbiageKey = allDefsPairs[titleUpdate] ? allDefsPairs[titleUpdate] : titleUpdate.replace(/[^\w]/g, '');
    onHandleArchiveConsent(verbiageKey);
  };

  const handleVariablePanel = () => {
    setVariableOpen(!variableOpen);
  };

  const renderHomeSnackBarActions = () => {
    const steps = [
      {
        label: '',
        data: verbiageSnackbarFormMap,
      },
    ];
    return <CreateFormWrapper steps={steps} formType="noSubmit" initialValues={snackbarActions} />;
  };

  const renderExpansionContents = () => {
    if (type === 'videos') {
      return (
        <Grid container>
          <AppKendoTableWrapper data={transform[type]({ data: [q] })} columns={columns(type, currentTenant)} />
        </Grid>
      );
    }
    return (
      <Grid
        item
        style={{ cursor: type === 'verbiage' ? 'text' : 'grab' }}
        onClick={type === 'verbiage' ? handleEditing : null}
        xs={8}
      >
        <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content) }} style={{ overflowWrap: 'anywhere' }} />
      </Grid>
    );
  };

  const handleNoticeTypeChange = (event) => {
    setNoticeType(event.target.value);
  };

  function InfoTooltip(props) {
    const { tip } = props;
    const disabled = editing !== identifier;

    return (
      <Tooltip classes={classes} title={tip} style={{ color: disabled ? '#E0E0E0' : '#2e5266' }} arrow>
        <Info />
      </Tooltip>
    );
  }

  const renderVerbiageConsentSettings = () => {
    const disabled = editing !== identifier;

    return (
      <>
        {/* Legal Notice and Parental Consent radio */}
        {editingType === 'new' && (
          <Grid>
            <FormControl component="fieldset">
              <RadioGroup row name="type" value={noticeType} onChange={handleNoticeTypeChange}>
                <FormControlLabel value="LEGAL_NOTICE" control={<Radio />} label="Legal Notice" />
                <FormControlLabel value="PARENTAL_CONSENT" control={<Radio />} label="Parental Consent Notice" />
              </RadioGroup>
            </FormControl>
          </Grid>
        )}
        {/* Require header, user consent, and agreement switches */}
        <Grid style={{ marginBottom: '35px' }}>
          <Box className={classes.wrapperWithTooltip}>
            <FormControlLabel
              disabled={disabled}
              control={
                <Switch
                  checked={headerDocument}
                  onChange={() => setHeaderDocument(!headerDocument)}
                  name="header"
                  color="primary"
                />
              }
              label={<Typography variant="subtitle1">Show as First Document</Typography>}
              labelPlacement="end"
            />
            {/* eslint-disable-next-line */}
            <InfoTooltip tip="Enable this option to place the document on top of the document list in the Patient Application" />
          </Box>
          <Box className={classes.wrapperWithTooltip}>
            <FormControlLabel
              disabled={disabled}
              control={
                <Switch
                  checked={enableNeedConsent}
                  onChange={() => setEnableNeedConsent(!enableNeedConsent)}
                  name="emailSending"
                  color="primary"
                />
              }
              label={<Typography variant="subtitle1">Required Document</Typography>}
              labelPlacement="end"
            />
            {/* eslint-disable-next-line */}
            <InfoTooltip tip="Enable this option to put the document on the consent/re-consent list when logging in for the first time and when documents are updated." />
          </Box>
          <Box className={classes.wrapperWithTooltip}>
            <FormControlLabel
              disabled={disabled}
              control={
                <Switch
                  checked={enableAgreement}
                  onChange={() => setEnableAgreement(!enableAgreement)}
                  name="isAgreement"
                  color="primary"
                />
              }
              label={<Typography variant="subtitle1">Show in Mobile App</Typography>}
              labelPlacement="end"
            />
            {/* eslint-disable-next-line */}
            <InfoTooltip tip="Enable this option to show the corresponding document in the Legal section of the mobile application" />
          </Box>
          {/* Show optional only for parental consent type */}
          {noticeType === 'PARENTAL_CONSENT' && (
            <FormControlLabel
              disabled={editing !== identifier}
              control={
                <Switch checked={optional} onChange={() => setOptional(!optional)} name="optional" color="primary" />
              }
              label={<Typography variant="subtitle1">Optional</Typography>}
              style={{
                verticalAlign: 'baseline',
              }}
              labelPlacement="end"
            />
          )}
          <div style={{ display: 'flex' }}>
            <Collapse in={enableNeedConsent} style={{ width: '100%' }}>
              <div style={{ marginTop: '10px', display: 'flex' }}>
                <ComboBox
                  data={availableConsentLabels}
                  allowCustom={verbiageType === 'documents'}
                  suggest
                  filterable
                  label="Select or type custom consent label"
                  name="consentLabel"
                  onChange={handleConsentLabelUpdate}
                  style={{ width: '50%' }}
                  defaultValue={confirmationConsentBtnLabel || availableConsentLabels[0]}
                  disabled={editing !== identifier}
                />

                {editing === identifier && editingType !== 'new' && enableNeedConsent ? (
                  <div className={classes.wrapper}>
                    <Button
                      // variant={"outlined"}
                      color="primary"
                      variant="contained"
                      onClick={handleArchiveConsent}
                      disabled={archiveConsentLoading}
                      className={classes.buttonArchiveConsent}
                    >
                      Archive Related Consent
                    </Button>
                    {archiveConsentLoading ? <CircularProgress size={24} className={classes.buttonProgress} /> : null}
                  </div>
                ) : null}
              </div>
            </Collapse>
          </div>
        </Grid>
      </>
    );
  };

  const getExpansionPanelVerbiageLayout = () => {
    const getTitle = () => {
      if (type !== 'verbiage' && title) {
        if (title.includes('_')) return utils.removeUnderscoresTitleCase(title);
        return utils.capitalizeString(title);
      }
      return title;
    };
    if (editing === identifier && type === 'faq')
      return (
        <TextField
          label={verbiageType === 'documents' ? 'Definition' : 'Question'}
          placeholder={`Full ${verbiageType === 'documents' ? 'definition' : 'question'} to be displayed`}
          name="content"
          margin="normal"
          variant="outlined"
          defaultValue={titleUpdate}
          onChange={handleTitleUpdate}
          autoComplete="off"
          multiline
          rows={1}
          rowsMax={2}
          required
          fullWidth
          autoFocus
          onFocus={(e) => e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length)}
          InputLabelProps={{
            style: {
              fontSize: '18px',
              position: 'absolute',
              top: '-10px',
              left: '-4px',
              backgroundColor: 'white',
              padding: '8px',
            },
          }}
        />
      );
    if (editing === identifier && type === 'verbiage' && verbiageType === 'documents') return null;
    return (
      <Grid container justifyContent="space-between">
        <Typography
          variant="h6"
          color="primary"
          style={{
            overflowWrap: 'break-word',
            wordWrap: 'break-word',
            hyphens: 'auto',
            fontSize: 18,
          }}
        >
          {getTitle()}
        </Typography>
        {/* Show Notice type only for documents */}
        {q.document && <Chip label={noticeTypeLabels[q.type] || q.type} />}
      </Grid>
    );
  };

  const getExpansionSettingsLayout = () => {
    if (editing === identifier)
      return (
        <>
          {type === 'verbiage' && (
            <Grid
              item
              style={{
                paddingLeft: '24px',
                position: 'relative',
              }}
            >
              <Button variant="outlined" color="primary" endIcon={<ArrowDropDownIcon />} onClick={handleVariablePanel}>
                ADD SYSTEM MESSAGE VARIABLE
              </Button>

              {type === 'verbiage' && variableOpen && (
                <ClickAwayListener onClickAway={handleVariablePanel}>
                  <Grid item xs={12} className={classes.messageVariablesDropdown}>
                    <SettingsTemplateVariableHelper variables={variables} />
                  </Grid>
                </ClickAwayListener>
              )}
            </Grid>
          )}

          <Grid item style={{ paddingRight: '24px' }}>
            {type === 'verbiage' && (
              <Tooltip title="Preview">
                <IconButton
                  variant="contained"
                  color="primary"
                  onClick={handlePreview}
                  disabled={disableSave || submitting || deleting}
                >
                  <SearchIcon />
                </IconButton>
              </Tooltip>
            )}

            <Button
              variant="contained"
              color="primary"
              onClick={handleSave}
              className={classes.button}
              disabled={disableSave || submitting || deleting}
            >
              {submitting && <CircularProgress size={10} style={{ marginRight: 10 }} />}
              Save Changes
            </Button>
            <Button
              style={{ marginRight: 0 }}
              variant="outlined"
              color="primary"
              onClick={handleCancel}
              className={classes.button}
            >
              Cancel
            </Button>
          </Grid>
        </>
      );
    if (submitting || deleting)
      return (
        <Grid item>
          <CircularProgress size={10} style={{ marginRight: 10 }} />
        </Grid>
      );
    return (
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleEditing(q)}
          className={classes.button}
          disabled={submitting || deleting || editing}
        >
          Edit
        </Button>
        {type !== 'outcome' && (
          <IconButton
            style={{ marginRight: 5 }}
            onClick={() => handleDelete(q)}
            data-testid="expansionPanelDeleteBtn"
            disabled={submitting || deleting || editing}
          >
            <DeleteIcon />
          </IconButton>
        )}
      </Grid>
    );
  };

  return (
    <Accordion
      style={{ marginBottom: '5px', borderRadius: 8 }}
      elevation={0}
      expanded={expandedPanel === `panel${identifier}` || editing === identifier}
      onChange={editing !== identifier ? handleExpansionChange(`panel${identifier}`) : null}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel${identifier}bh-content`}
        id={`panel${identifier}bh-header`}
        data-testid="AccordionSummary"
        style={{ backgroundColor: 'white' }}
      >
        {getExpansionPanelVerbiageLayout()}
        {editing === identifier && (editingType === 'new' || verbiageType === 'documents') && type === 'verbiage' && (
          <ComboBox
            data={availableDefinitions}
            allowCustom={verbiageType === 'documents'}
            suggest
            filterable
            label={
              verbiageType === 'documents' ? 'Select or type new verbiage definition' : 'Select Verbiage Definition'
            }
            name="verbiage"
            required
            onChange={handleTitleUpdate}
            style={{ width: '100%' }}
            defaultValue={title}
          />
        )}
      </AccordionSummary>
      <AccordionDetails data-testid="AccordionDetails">
        {editing === identifier ? (
          <div style={{ width: '100%' }}>
            {type === 'verbiage' && verbiageType === 'documents' ? renderVerbiageConsentSettings() : null}

            {type === 'verbiage' && snackbarDefinitions.includes(title || titleUpdate)
              ? renderHomeSnackBarActions()
              : null}

            {type === 'outcome' && (
              <div style={{ marginBottom: 20 }}>
                <ImageUploadCrop
                  field={{
                    acceptedFiles: ['image/png', 'image/jpg', 'image/jpeg', 'image/svg+xml'],
                  }}
                  value={images[title]}
                  handleChange={(e) => setOutcomeImage(e.target.value)}
                  disabled={false}
                />
              </div>
            )}
            {type !== 'videos' && (
              <HtmlInputText
                value={DOMPurify.sanitize(content)}
                disabled={submitting}
                handleChangeWithParams={handleUpdate}
                debounce={200}
              />
            )}
          </div>
        ) : (
          <Grid container alignItems={type === 'outcome' ? 'center' : null}>
            {type === 'verbiage' && verbiageType === 'documents' ? renderVerbiageConsentSettings() : null}

            {type === 'verbiage' && actions && snackbarDefinitions.includes(title) ? createActionView(actions) : null}

            {type === 'outcome' && (
              <Grid item style={{ marginBottom: 20 }}>
                {images && images[title] && images[title].split('.').pop() === 'svg' ? (
                  <object type="image/svg+xml" data={images[title]} width="200" height="200">
                    Your browser does not support SVG.
                  </object>
                ) : (
                  <img src={images[title]} alt="" />
                )}
              </Grid>
            )}
            {renderExpansionContents()}
          </Grid>
        )}
      </AccordionDetails>
      <Grid
        container
        justifyContent={
          (!matches && type === 'faq') || (type === 'verbiage' && editing === identifier) ? 'space-between' : 'flex-end'
        }
        alignItems="center"
      >
        {!editing && !matches && type === 'faq' && (
          <Grid item>
            {identifier !== data.length && (
              <IconButton style={{ marginRight: 5 }} onClick={() => onHandleMoveDown(q)}>
                <ArrowDownwardIcon />
              </IconButton>
            )}
            {identifier !== 1 && (
              <IconButton style={{ marginRight: 5 }} onClick={() => onHandleMoveUp(q)}>
                <ArrowUpwardIcon />
              </IconButton>
            )}
          </Grid>
        )}
        {getExpansionSettingsLayout()}
      </Grid>
    </Accordion>
  );
}

// const useStyles = makeStyles({});

export default withStyles(DragExpansionWrapperStyles)(SettingsExpansionPanel);
