/* eslint-disable react/no-array-index-key */
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React, { useState, useEffect } from 'react';

// Redux
import { useSelector } from 'react-redux';

import NoAccessView from '../../views/NoAccessView';
import { selectTenantNetwork } from '../Networks/selectors';

// styling and component imports
import Orders from '../Orders/Orders';
import AccessControl from '../Shared/AccessControl';
import { selectCurrentUserPermissions, selectUserDetails } from '../Users/selectors';

import { profileInfoTabs } from './helpers/profileListMapper';
import ProfileInformation from './ProfileInformation';
import AssessmentHistory from './Tabs/AssessmentHistory';
import Consents from './Tabs/Consents';
import CovidVaccineHistory from './Tabs/CovidVaccineHistory';
import DependentUsers from './Tabs/DependentUsers';
import ScanHistory from './Tabs/ScanHistory';
import UserMessages from './Tabs/UserMessages';
import UserVerification from './Tabs/UserVerification';
import VaccineRecords from './Tabs/VaccineRecords';

const deniedPermissions = ['ADMINISTRATOR_RESTRICTED'];

function ProfileInfoBox() {
  const currentNetwork = useSelector(selectTenantNetwork);
  const userDetails = useSelector(selectUserDetails);
  const classes = useStyles();
  const currentUserPermissions = useSelector(selectCurrentUserPermissions);
  const theme = useTheme();
  const [value, setValue] = useState('default');
  const [refreshUserDetails, setRefreshUserDetails] = useState(false);
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  let pageComponent;

  useEffect(() => {
    const splitUrl = window.location.href.split('/');
    const page = splitUrl[splitUrl.length - 1];
    if (page !== '' && !page.includes('@')) {
      setValue(page);
    }
  }, []);

  const handleChange = (e, value) => {
    setValue(value);
    setRefreshUserDetails(false);
    const splitUrl = window.location.href.split('/');
    if (splitUrl[splitUrl.length - 2] === 'profile' || splitUrl[splitUrl.length - 2] === 'details') {
      if (value !== 'default') {
        window.history.pushState(value, value, `${splitUrl.join('/')}/${value}`);
      }
    } else {
      splitUrl.pop();
      if (value === 'default') {
        setRefreshUserDetails(true);
        window.history.pushState(value, value, `${splitUrl.join('/')}`);
      } else {
        window.history.pushState(value, value, `${splitUrl.join('/')}/${value}`);
      }
    }
  };

  const renderNoAccess = () => {
    return <NoAccessView />;
  };

  switch (value) {
    case 'assessment':
      pageComponent = (
        <AccessControl deniedPermissions={deniedPermissions} renderNoAccess={renderNoAccess}>
          <AssessmentHistory />
        </AccessControl>
      );
      break;
    case 'testing':
      pageComponent = (
        <AccessControl deniedPermissions={deniedPermissions} renderNoAccess={renderNoAccess}>
          <Orders userSpecific />
        </AccessControl>
      );
      break;
    case 'scans':
      pageComponent = <ScanHistory />;
      break;
    case 'covidVaccine':
      pageComponent = <CovidVaccineHistory />;
      break;
    case 'vaccineRecords':
      pageComponent = (
        <AccessControl deniedPermissions={deniedPermissions} renderNoAccess={renderNoAccess}>
          <VaccineRecords />
        </AccessControl>
      );
      break;
    case 'consents':
      pageComponent = <Consents />;
      break;
    case 'messages':
      pageComponent = <UserMessages />;
      break;
    case 'verification':
      pageComponent = <UserVerification />;
      break;
    case 'dependentAccounts':
      pageComponent = <DependentUsers />;
      break;
    default:
      pageComponent = <ProfileInformation refreshUserDetails={refreshUserDetails} />;
      break;
  }
  return (
    <>
      {matches ? (
        <Tabs
          value={value}
          indicatorColor="primary"
          variant="fullWidth"
          textColor="primary"
          onChange={handleChange}
          aria-label="disabled tabs example"
        >
          {profileInfoTabs(
            currentUserPermissions?.permission,
            currentNetwork?.verifyIdCard,
            userDetails?.childIds?.length,
            currentNetwork?.verifyVaccinationSettings?.enableVaccinationRecord
          ).map((tab, tabIdx) =>
            tab.show ? (
              <Tab
                key={tabIdx}
                label={tab.label}
                style={{
                  fontSize: '12px',
                  marginBottom: '-10px',
                  minWidth: '90px',
                }}
                id={tab.value}
                value={tab.value}
                className={value === tab.value ? classes.activeLink : ''}
              />
            ) : null
          )}
        </Tabs>
      ) : (
        <FormControl variant="outlined" fullWidth>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={value}
            onChange={(e) => setValue(e.target.value)}
          >
            {profileInfoTabs(
              currentUserPermissions?.permission,
              currentNetwork?.verifyIdCard,
              userDetails?.childIds?.length,
              currentNetwork?.verifyVaccinationSettings?.enableVaccinationRecord
            ).map((listItem, index) =>
              listItem.show ? (
                <MenuItem value={listItem.value} key={index}>
                  {listItem.label}
                </MenuItem>
              ) : null
            )}
          </Select>
        </FormControl>
      )}
      <div style={{ marginTop: 25 }}>{pageComponent}</div>
    </>
  );
}

const useStyles = makeStyles({
  activeLink: {
    fontWeight: 'bold',
  },
});

export default ProfileInfoBox;
