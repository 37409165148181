import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  signinWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '80vh',
    [theme.breakpoints.down(767)]: {
      flexGrow: 1,
      backgroundColor: '#fff',
    },
    paddingTop: '50px',
    paddingBottom: '',
  },
  paper: {
    maxWidth: 648,
    borderRadius: 23,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  formDescriptionText: {
    fontFamily: 'Avenir Next Rounded Medium!important, Helvetica, Arial',
    color: '#505358',
  },
  bottomText: {
    color: '#505358',
  },
  button: {
    fontFamily: 'Avenir Next Rounded Medium !important, Helvetica, Arial',
    fontWeight: '700!important',
    padding: 8,
    fontSize: '14!important',
    minWidth: '100%',
    textTransform: 'none',
    borderRadius: 25,
  },
  link: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'none',
    },
    fontWeight: 700,
    color: theme.palette.primary.main,
  },
  input: {
    '& div input': {
      '&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus, &:-webkit-autofill:active': {
        '-webkit-box-shadow': '0 0 0 30px white inset !important;',
      },
    },
    '& .MuiInputLabel-shrink': {
      paddingTop: 8,
    },
  },
  logoWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  logo: {
    width: 70,
  },
}));
