export const SHAPE_TYPES = {
  RECT: 'rect',
  CIRCLE: 'circle',
};

export const DEFAULTS = {
  RECT: {
    STROKE: '#ff0000',
    // FILL: "rgba(255,255,255,0.5)",
    FILL: 'rgb(19,255,0,0.5)',
    WIDTH: 150,
    HEIGHT: 100,
    ROTATION: 0,
  },
};

export const LIMITS = {
  RECT: {
    MAX: 1000,
    MIN: 10,
  },
};
