import { createSlice } from '@reduxjs/toolkit';

import api from '../../../api';
import { handleError } from '../../Shared/Content/APIUtils';

const initialState = {
  cernerAuthUrl: null,
  cernerAuthLoading: null,
};

export const cernerSlice = createSlice({
  name: 'cerner',
  initialState,
  reducers: {
    setCernerAuthLoading: (state) => ({
      ...state,
      cernerAuthLoading: true,
    }),
    setCernerAuthUrl: (state, { payload }) => ({
      ...state,
      cernerAuthUrl: payload,
      cernerAuthLoading: false,
    }),
  },
});

export const { setCernerAuthUrl, setCernerAuthLoading } = cernerSlice.actions;

export const getCernerAuthUrl = (safeEMRId, tenantId) => async (dispatch) => {
  dispatch(setCernerAuthLoading());

  const [result, error] = await api.getCernerAuthUrlRequest(safeEMRId, tenantId);

  if (result) {
    dispatch(setCernerAuthUrl(result));
  }

  if (error && error.message) {
    dispatch(handleError(error));
  }
};

export default cernerSlice.reducer;
