/* eslint-disable */
import utils from '../../Shared/Utils/utils';

export const transformToForm = {
  report: (formData) => ({
    ...formData,
    observations:
      formData.observations &&
      formData.observations.map((observation, idx) => ({
        ...observation,
        formattedCollectionDate:
          observation.collectionDate && utils.formatDateTimeLocaleJSON(observation.collectionDate).split('T')[0],
        formattedCollectionTime:
          observation.collectionDate &&
          utils.formatDateTimeLocaleJSON(observation.collectionDate).split('T')[1].substring(0, 5),
        formattedResultDate: observation.resultDate && observation.resultDate.split('T')[0],
        formattedResultTime: observation.resultDate && observation.resultDate.split('T')[1].substring(0, 5),
        labPerformingSiteName: observation.performingLabDetail && observation.performingLabDetail.labPerformingSiteName,
        labDirectorFirstName:
          observation.performingLabDetail &&
          observation.performingLabDetail.labDirector &&
          observation.performingLabDetail.labDirector.firstName,
        labDirectorLastName:
          observation.performingLabDetail &&
          observation.performingLabDetail.labDirector &&
          observation.performingLabDetail.labDirector.lastName,
        labAddress:
          observation.performingLabDetail &&
          observation.performingLabDetail.labPerformingSiteAddress &&
          observation.performingLabDetail.labPerformingSiteAddress.lineAddress,
        analyteObservations:
          observation.analyteObservations &&
          observation.analyteObservations.map((analyte, idx1) => ({
            ...analyte,
          })),
      })),
  }),
};

export const transform = (formData) => ({
  report: {
    ...formData,
    observations:
      formData.observations &&
      formData.observations.map((observation, idx) => ({
        ...observation,
        collectionDate: observation.formattedCollectionDate
          ? observation.formattedCollectionTime
            ? `${utils.formatDateNoLocale(observation.formattedCollectionDate)}T${
                observation.formattedCollectionTime
              }:00.000Z`
            : `${observation.formattedCollectionDate} 00: 00:: 00.000Z`
          : null,
        resultDate: observation.formattedResultDate
          ? observation.formattedResultTime
            ? `${utils.formatDateNoLocale(observation.formattedResultDate)}T${observation.formattedResultTime}: 00.000Z`
            : `${observation.formattedResultDate} 00: 00:: 00.000Z`
          : null,
        performingLabDetail: observation.performingLabDetail
          ? {
              ...observation.performingLabDetail,
              labPerformingSiteName: observation.labPerformingSiteName,
              labDirector: {
                ...observation.performingLabDetail.labDirector,
                firstName: observation.labDirectorFirstName,
                lastName: observation.labDirectorLastName,
              },
              labPerformingSiteAddress: {
                ...observation.performingLabDetail.labPerformingSiteAddress,
                lineAddress: observation.labAddress,
              },
            }
          : observation.labPerformingSiteName
          ? {
              labPerformingSiteIdentifier: observation.labPerformingSiteName,
              labPerformingSiteName: observation.labPerformingSiteName,
              labDirector: {
                firstName: observation.labDirectorFirstName,
                lastName: observation.labDirectorLastName,
              },
              labPerformingSiteAddress: {
                lineAddress: observation.labAddress,
              },
            }
          : null,
        analyteObservations:
          observation.analyteObservations &&
          observation.analyteObservations.map((analyte, idx1) => ({
            ...analyte,
            analyteCode: analyte.analyteCode ? analyte.analyteCode : analyte.analyteName,
          })),
      })),
  },
});

export const DiagnosticReportFormMap = (tenant) => [
  {
    type: 'groupNoDropdown',
    label: 'Test Results',
    name: 'Test Results',
    fields: [
      {
        name: 'specimenId',
        label: 'Specimen Id',
        required: false,
        readOnly: true,
        width: 3,
      },
      {
        type: 'dynamicList',
        name: 'observations',
        width: 12,
        sortable: true,
        expandable: true,
        titleField: 'testName',
        hideButton: true,
        fields: [
          {
            type: 'text',
            name: 'testName',
            label: 'Test Name',
            readOnly: true,
            width: 4,
          },
          {
            type: 'text',
            name: 'testCode',
            label: 'Test Code',
            readOnly: true,
            width: 4,
          },
          {
            type: 'text',
            type: 'dropdown',
            name: 'interpretationValue',
            label: 'Interpretation',
            required: true,
            width: 4,
            enum: ['NONE', 'NEGATIVE', 'POSITIVE', 'INCONCLUSIVE', 'PENDINGINTERPRETATION', 'EQUIVOCAL'],
          },
          {
            type: 'text',
            name: 'resultValue',
            label: 'Result Value',
            readOnly: true,
            width: 4,
          },
          {
            type: 'text',
            name: 'resultUnit',
            label: 'Result Unit',
            readOnly: true,
            width: 4,
          },
          {
            type: 'text',
            name: 'resultReferenceRange',
            label: 'Reference Range',
            readOnly: true,
            width: 4,
          },
          {
            type: 'groupNoDropdown',
            label: 'Specimen Details',
            name: 'Specimen Details',
            width: 12,
            fields: [
              {
                type: 'text',
                name: 'specimenId',
                label: 'Specimen Id',
                required: false,
                width: 3,
              },
              {
                type: 'text',
                name: 'resultStatus',
                label: 'status',
                required: true,
                readOnly: true,
                width: 3,
              },
              {
                type: 'date',
                name: 'formattedCollectionDate',
                label: 'Collection Date',
                required: false,
                width: 3,
              },
              {
                type: 'time',
                name: 'formattedCollectionTime',
                label: 'Collection Time',
                required: false,
                width: 3,
                format: 'hh:mm',
              },
              {
                type: 'date',
                name: 'formattedResultDate',
                label: 'Result Date',
                required: false,
                width: 3,
              },
              {
                type: 'time',
                name: 'formattedResultTime',
                label: 'Result Time',
                required: false,
                width: 3,
                format: 'hh:mm',
              },
              {
                type: 'text',
                name: 'labPerformingSiteName',
                label: 'Laboratory',
                width: 3,
              },
              {
                type: 'text',
                name: 'labDirectorFirstName',
                label: 'Lab Director First Name',
                width: 3,
              },
              {
                type: 'text',
                name: 'labDirectorLastName',
                label: 'Lab Director Last Name',
                width: 3,
              },
              {
                type: 'text',
                name: 'labAddress',
                label: 'Lab Address',
                width: 3,
              },
            ],
          },
          {
            type: 'dynamicList',
            name: 'analyteObservations',
            sortable: true,
            expandable: true,
            titleField: 'analyteName',
            width: 12,
            label: 'Analyte',
            fields: [
              {
                type: 'text',
                name: 'analyteName',
                label: 'Analyte',
                width: 3,
                required: true,
              },
              {
                type: 'dropdown',
                name: 'resultValue',
                label: 'Result Value',
                required: false,
                width: 3,
                enum: ['UNKNOWN', 'POSITIVE', 'NEGATIVE', 'EQUIVOCAL', 'INVALID', 'ERROR', 'INCONCLUSIVE'],
              },
              {
                type: 'text',
                name: 'abnormalFlag',
                label: 'Flag',
                width: 3,
              },
              {
                type: 'text',
                name: 'referenceRange',
                label: 'Reference Range',
                width: 3,
              },
              {
                type: 'text',
                name: 'notes',
                label: 'Notes',
                width: 12,
                rows: 4,
              },
            ],
          },
        ],
      },
    ],
  },
];
