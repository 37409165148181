import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import React from 'react';

import { createInputHelper } from './helpers/createInputHelper';

const CreateForm = React.memo((props) => {
  const { form, formData, handleChange, handleChangeWithParams, autoSetValues, disabled, getChildren } = props;

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <Paper
          elevation={0}
          style={{
            padding: 30,
            borderRadius: '20px',
          }}
        >
          <Grid container spacing={2}>
            {createInputHelper(
              form,
              formData,
              handleChange,
              handleChangeWithParams,
              autoSetValues,
              disabled,
              getChildren
            )}
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
});

export default CreateForm;
