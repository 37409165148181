import FontActionCell from '../FontActionCell';

export const columns = [
  {
    id: 'name',
    label: 'Name',
    show: true,
    locked: true,
    width: 170,
    sortable: true,
  },
  {
    id: 'createdOn',
    label: 'Created',
    show: true,
    minWidth: 150,
    sortable: false,
  },
  {
    id: 'action',
    label: 'Action',
    show: true,
    width: 170,
    sortable: false,
    cell: FontActionCell,
  },
];
