export const allergySeverityEnums = ['Mild', 'Moderate', 'Severe'];
export const clinicalStatusEnums = ['Active', 'Inactive', 'Resolved'];
export const verificationStatusEnums = ['Unconfirmed', 'Confirmed', 'Refuted'];
export const allergyCategoryEnums = ['Food', 'Medication'];

export const conditionClinicalStatusEnums = ['Active', 'Recurrence', 'Inactive', 'Remission', 'Resolved'];
export const conditionVerificationStatusEnums = ['Provisional', 'Differential', 'Confirmed', 'Refuted'];

export const immunizationStatusEnums = ['Completed'];

export const medicationStatusEnums = ['Active', 'Completed', 'Intended', 'Stopped', 'OnHold'];

export const observationStatusEnums = [
  'Registered',
  'Final',
  'Preliminary',
  'Amended',
  'Corrected',
  'Cancelled',
  'EnteredInError',
  'Unknown',
];
export const observationCategoryEnums = [
  'Survey',
  'Activity',
  'Therapy',
  'Exam',
  'Procedure',
  'Laboratory',
  'Imaging',
  'Vital Signs',
  'Social History',
];

export const clinicalTabsMapping = [
  {
    label: 'Allergy',
    value: 0,
    id: 'allergy',
  },
  {
    label: 'Diagnosis',
    value: 1,
    id: 'condition',
  },
  {
    label: 'Current Meds',
    value: 2,
    id: 'medication',
  },
  // {
  //   label: 'Prescription',
  //   value: 3,
  //   id: 'prescription',
  // },
  {
    label: 'Immunization',
    value: 4,
    id: 'immunization',
  },
  // {
  //   label: 'Vital',
  //   value: 5,
  //   id: 'vital',
  // },
  {
    label: 'Procedure',
    value: 6,
    id: 'procedure',
  },
  {
    label: 'View CCD',
    value: 7,
    id: 'ccd',
  },
  {
    label: '1up Connect',
    value: 8,
    id: 'quickConnect',
  },
  {
    label: 'EMR Connect',
    value: 9,
    id: 'emrConnect',
  },
  {
    label: 'E Prescribe',
    value: 10,
    id: 'eprescribe',
  },
  {
    label: 'Dosespot',
    value: 11,
    id: 'dosespot',
  },
];

export const clinicalPaths = {
  condition: {
    name: 'condition',
    value: 1,
  },
  medication: {
    name: 'medication',
    value: 2,
  },
  prescription: {
    name: 'prescription',
    value: 3,
  },
  immunization: {
    name: 'immunization',
    value: 4,
  },
  vital: {
    name: 'vital',
    value: 5,
  },
  procedure: {
    name: 'procedure',
    value: 6,
  },
  ccd: {
    name: 'ccd',
    value: 7,
  },
  quickconnect: {
    name: 'quickconnect',
    value: 8,
  },
  emrconnect: {
    name: 'emrconnect',
    value: 9,
  },
  eprescribe: {
    name: 'eprescribe',
    value: 10,
  },
  dosespot: {
    name: 'dosespot',
    value: 11,
  },
};
