import { Box, Button } from '@material-ui/core';
import { Error } from '@progress/kendo-react-labels';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { showNotification } from '../../Notifications/notificationSlice';
import DropdownField from '../../Shared/Forms/KendoFields/DropdownField';
import TextFieldInput from '../../Shared/Forms/KendoFields/TextFieldInput';
import { OverlayLoading } from '../helper/SimulatorLoading';
import { resetConsultLoading, startSimulateConsult } from '../slice/fvSimulatorSlice';
import { selectConsultLoading } from '../slice/selector';

const consultStatusEnum = [
  'created',
  'unassigned',
  'assigned',
  'review',
  'rejected',
  'complete-no-rx',
  'complete-paxlovid',
  'complete-lagevrio',
];

const consultStatusDropdownField = {
  type: 'dropdown',
  name: 'consultSimulator',
  label: 'Status',
  enum: consultStatusEnum,
};

const consultIdField = {
  type: 'text',
  name: 'consultId',
  label: 'Consult ID (Optional)',
  readOnly: false,
};

function ConsultSimulator() {
  const dispatch = useDispatch();

  const params = useParams();
  const { userId } = params;

  const consultLoading = useSelector(selectConsultLoading);

  const [consultId, setConsultId] = useState('');
  const [consultStatus, setConsultStatus] = useState('');
  const [error, setError] = useState({});

  const accountId = localStorage.getItem('accountId');
  const tenantId = localStorage.getItem('tenantId');

  const handleStatusChange = (fieldName, fieldVal) => {
    /* Clear Error */
    let clearedErr = { ...error };
    delete clearedErr['consultStatus'];
    setError({ ...clearedErr });

    setConsultStatus(fieldVal);
  };

  const handleIdChange = (e) => {
    setConsultId(e.target.value);
  };

  useEffect(() => {
    if (consultLoading === 'fulfilled' || consultLoading === 'rejected') {
      dispatch(resetConsultLoading());
    }
  }, [consultLoading]);

  /* SUBMIT SIMULATION REQUEST */
  const handleSubmit = async (e) => {
    e.preventDefault();
    const hasError = validateInputs();

    if (hasError) {
      return null;
    }

    const testKitIdPayload = consultId.trim();

    const payload = {
      status: consultStatus,

      payloadFields: {
        accountID: accountId,
        tenantID: tenantId,
        // tenantID: 'healthcheck-consumer', // for local DEV only
        user: userId,
        // Only include ID property if NOT blank
        ...(testKitIdPayload && testKitIdPayload !== '' && { id: testKitIdPayload }),
      },
    };

    try {
      const result = await dispatch(startSimulateConsult(payload));

      if (result.error) {
        dispatch(showNotification(`Failed to execute simulator <br />Error: ${result.error.message}`, 'error'));
        return;
      }

      if (result.payload[1]?.status >= 400) {
        dispatch(showNotification(`${result?.payload[1]?.message}`, 'error'));
        return;
      }

      if (result.payload[0]?.success) {
        dispatch(showNotification(`${result?.payload[0]?.message} <br /> ${result?.payload[0]?.data} `, 'success'));
        return;
      }
    } catch (error) {
      // eslint-disable-next-line
      console.log(error);
      dispatch(showNotification(`Failed to execute simulator <br />Error: ${error.message}`, 'error'));
    }
  };

  const validateInputs = () => {
    let hasError = false;
    if (consultStatus === undefined || consultStatus === '' || !consultStatusEnum.includes(consultStatus)) {
      setError((err) => ({
        ...err,
        consultStatus: {
          message: 'Consult Status field is required',
        },
      }));

      hasError = true;
    }

    return hasError;
  };

  return (
    <Box width={'100%'} height={'100%'}>
      {consultLoading === 'pending' && <OverlayLoading />}
      <Box
        display={'grid'}
        gridTemplateColumns={'repeat(2, minmax(0, 1fr))'}
        gridRowGap={'50px'}
        gridColumnGap={'100px'}
        marginTop={'25px'}
        marginBottom={'25px'}
      >
        <Box textAlign={'center'}>
          <DropdownField
            field={consultStatusDropdownField}
            value={consultStatus}
            handleChangeWithParams={handleStatusChange}
          />
          {error?.consultStatus && <Error>{error?.consultStatus?.message}</Error>}
        </Box>
        <Box textAlign={'center'}>
          <TextFieldInput field={consultIdField} value={consultId} handleChange={handleIdChange} />
          {error?.consultId && <Error>Please enter proper kit ID</Error>}
        </Box>
        <Box gridColumn={'2 / 3 '} display="flex" justifyContent={'center'}>
          <Button variant="contained" color="primary" onClick={handleSubmit} disabled={consultLoading === 'pending'}>
            Simulate
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default ConsultSimulator;
