import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import React from 'react';

import utils from '../../Utils/utils';

export default function SliderInput(props) {
  const { field, value: initialValue, handleChangeWithParams, selectedLanguage } = props;
  const [value, setValue] = React.useState(initialValue || 1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    handleChangeWithParams(field.name, newValue);
  };

  const fieldLabel =
    selectedLanguage && field.name && field.name.translations
      ? field.name.translations[selectedLanguage]
      : field.label || field.name;

  const questionDescription =
    selectedLanguage && field.description && field.description.translations
      ? field.description.translations[selectedLanguage]
      : field.description;

  return (
    <div>
      <Typography style={{ opacity: 0.5, marginBottom: 40 }}>
        {field.questionPreview ? utils.renderHTMLTags(questionDescription) : fieldLabel}
        {field.required && '*'}
      </Typography>
      <Slider
        value={value}
        aria-labelledby="discrete-slider-always"
        min={parseFloat(field.min) || 0}
        step={parseFloat(field.step) || 1}
        max={parseFloat(field.max) || 100}
        onChange={handleChange}
        valueLabelDisplay="on"
        disabled={field.disabled}
      />
    </div>
  );
}
