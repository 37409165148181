import { Error } from '@progress/kendo-react-labels';
import { Box, Button } from '@material-ui/core';
import React, { useState } from 'react';
import DropdownField from '../../Shared/Forms/KendoFields/DropdownField';
import NumberFieldInput from '../../Shared/Forms/KendoFields/NumberFieldInput';
import { OverlayLoading } from '../helper/SimulatorLoading';
import { useDispatch, useSelector } from 'react-redux';
import { resetBenefitLoading , loadEmployeeBenefit, resetBenefitData, updateEmployeeBenefit} from '../slice/fvSimulatorSlice';
import { selectEmployeeBenefitLoading, selectEmployeeBenefitData } from '../slice/selector';
import { showNotification } from '../../Notifications/notificationSlice';
import { selectUserDetails } from '../../Users/selectors';
import { useParams } from 'react-router-dom';
import { useReducer, useEffect } from 'react';
import { getUser } from '../../Users/usersSlice';
import { useRef } from 'react';


const BENEFIT_TYPE = Object.freeze({
  AMOUNT: 'amount',
  QUANTITY: 'quantity',
});

const benefitEnum = Object.values(BENEFIT_TYPE);

const benefitTypeDropdownField = {
  type: 'dropdown',
  name: 'employeeBenefitType',
  label: 'Employee Benefit Type',
  enum: benefitEnum,
};

const benefitValueField = {
  name: 'employeeBenefitValue',
  label: 'Value',
  readOnly: false,
}

const benefitReducer = (state, action) => ({
  ...state,
  // if it is a function
  ...(typeof action === 'function' ? action(state) : action),
});

/* default values */
const defaultState = {
  benefitType: BENEFIT_TYPE.AMOUNT,
  benefitValue: 0,
};

function EmployeeBenefitSimulator() {
  const dispatch = useDispatch();
  const params = useParams();

  const employeeBenefitLoading = useSelector(selectEmployeeBenefitLoading);
  const employeeBenefitData = useSelector(selectEmployeeBenefitData);

  const [benefitState, setBenefitState] = useReducer(benefitReducer, defaultState, (init) => {
    if (employeeBenefitData) {
      return {
        benefitType: employeeBenefitData.type,
        benefitValue: employeeBenefitData.value,
      };
    }
    return init;
  });

  /* need to get user's "_id" not just email/userId */
  const { userId } = params;
  const userDetails = useSelector(selectUserDetails);
  const isMounted = useRef(false);


  useEffect(() => {
    const getUserData = async () => {
      dispatch(getUser(userId, true));
    };

    getUserData();

    if (employeeBenefitData) {
      setBenefitState({
        /* map "count" value to "quantity" for display */
        benefitType: employeeBenefitData.type === 'count' ? 'quantity' : employeeBenefitData.type,
        benefitValue: employeeBenefitData.value,
      });
    }
    isMounted.current = true;
  }, [userId, employeeBenefitData]);

  /* END - getting userDetails for _id */

  const { benefitType, benefitValue } = benefitState;
  const [error, setError] = useState({});

  // const accountId = localStorage.getItem('accountId');
  const tenantId = localStorage.getItem('tenantId');

  const handleBenefitTypeChange = (fieldName, fieldVal) => {
    /* Clear Error */
    let clearedErr = { ...error };
    delete clearedErr['benefitType'];
    setError({ ...clearedErr });

    setBenefitState({ benefitType: fieldVal });
  };

  const handleBenefitValueChange = (e) => {
    setBenefitState({ benefitValue: e.target.value });
  };

  useEffect(() => {
    const params = {
      tenantId,
      /**
       * Pass the email as userId if userDetails is not yet available
       * where: userId => email address
       * email address also works with GET but will received no data property yet.
       * On next render it will use the proper userDetails?._id and will have data
       */
      userId: userDetails?._id ?? userId,
    };
    dispatch(loadEmployeeBenefit(params));

    return () => {
      dispatch(resetBenefitData());
    };
  }, [userId, userDetails?._id]);

  useEffect(() => {
    if (employeeBenefitLoading === 'fulfilled' || employeeBenefitLoading === 'rejected') {
      dispatch(resetBenefitLoading());
    }
  }, [employeeBenefitLoading]);

  /* SUBMIT SIMULATION REQUEST */
  const handleSubmit = async (e) => {
    e.preventDefault();
    const hasError = validateInputs();

    if (hasError) {
      return null;
    }

    const userIdPayload = userDetails?._id ?? null;
    if (!userIdPayload) return;

    const payload = {
      // tenantID: 'healthcheck-consumer', // for local DEV only
      tenantId,
      // userId,  => using _id instead
      userId: userIdPayload,
      formData: {
        benefitValue,
        /* quantity type is not recognized on Backend (should be count) */
        benefitType: benefitType === 'quantity' ? 'count' : benefitType,
      },
    };

    try {
      const result = await dispatch(updateEmployeeBenefit(payload));

      if (result.error) {
        dispatch(showNotification(`Failed to execute simulator <br />Error: ${result.error.message}`, 'error'));
        dispatch(resetBenefitLoading());
        return;
      }

      if (result.payload[1]?.status >= 400) {
        dispatch(showNotification(`Failed to execute simulator <br />Error: ${result?.payload[1]?.message}`, 'error'));
        dispatch(resetBenefitLoading());
        return;
      }

      if (result.payload[0]?.success) {
        dispatch(showNotification(`${result?.payload[0]?.message} <br />Employee Benefit updated successfully`, 'success'));
        return;
      }
    } catch (error) {
      dispatch(showNotification(`Failed to execute simulator <br />Error: ${error.message}`, 'error'));
    }
  };

  const validateInputs = () => {
    let hasError = false;
    if (benefitType === undefined || !benefitEnum.includes(benefitType)) {
      setError((err) => ({
        ...err,
        benefitType: {
          message: 'Benefit Type is required',
        },
      }));

      hasError = true;
    }

    return hasError;
  };

  return (
    <Box width={'100%'} height={'100%'}>
      {(employeeBenefitLoading === 'pending' || !isMounted.current) && <OverlayLoading />}
      <Box
        display={'grid'}
        gridTemplateColumns={'repeat(2, minmax(0, 1fr))'}
        gridRowGap={'50px'}
        gridColumnGap={'100px'}
        marginTop={'25px'}
        marginBottom={'25px'}
      >
        <Box textAlign={'center'}>
          <DropdownField
            field={benefitTypeDropdownField}
            value={benefitType}
            handleChangeWithParams={handleBenefitTypeChange}
          />
          {error?.testKitStatus && <Error>{error?.testKitStatus?.message}</Error>}
        </Box>
        <Box textAlign={'center'}>
          <NumberFieldInput field={benefitValueField} value={benefitValue} handleChange={handleBenefitValueChange} />
          {error?.testKitIdErr && <Error>Please enter proper kit ID</Error>}
        </Box>
        <Box gridColumn={'2 / 3 '} display="flex" justifyContent={'center'}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={employeeBenefitLoading === 'pending'}
          >
            Simulate
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default EmployeeBenefitSimulator;
