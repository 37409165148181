import { Box, Button, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { showNotification } from "../../Notifications/notificationSlice";
import API from "../../../api";
import { OverlayLoading } from "../helper/SimulatorLoading";

const Insurance = React.memo(({ userId, userDetailsLoading}) => {
  const [isLoading, setIsLoading] = useState(userDetailsLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    setIsLoading(userDetailsLoading);
  }, [userDetailsLoading]);


  const handleSubmit = async () => {

    setIsLoading(true)

    try {
      if (!userId) {
        throw new Error('userId is required')
      }
      const result = await API.patchSimulateInvalidateInsurance(userId)

      console.log(result)

      if (result.error) {
        dispatch(showNotification(`Failed to update Insurance data <br />Error: ${result.error.message}`, 'error'));
        return;
      }

      if (result[1]?.status >= 400) {
        dispatch(showNotification(`Failed to update Insurance data <br />Error: ${result[1]?.message}`, 'error'));
        return;
      }

      if (result[0]?.success) {
        dispatch(
          showNotification(`${result[0]?.message} <br />Insurance data updated successfully`, 'success')
        );
        return;
      }

      if(result.success) {
        dispatch(
          showNotification(`${result?.payload[0]?.message} <br />Insurance data updated successfully`, 'success')
        );
        return;
      }

    } catch (error) {
      dispatch(showNotification(`Failed to update Insurance data <br />Error: ${error.message}`, 'error'));

    } finally {
      setIsLoading(false)
    }
  }


  return (
    <Box width={'100%'} height={'100%'}>
      <Box
        display={'grid'}
        gridTemplateColumns={'repeat(2, minmax(0, 1fr))'}
        gridRowGap={'20px'}
        gridColumnGap={'100px'}
        marginTop={'25px'}
        marginBottom={'25px'}
      >
        {/* <Box textAlign={'left'}>
          <Typography>Invalidate Insurance</Typography>
        </Box> */}
        { userDetailsLoading && <OverlayLoading />}


        <Box gridColumn={'1 / 3 '} justifyContent={'center'} textAlign={'center'}>
          <Typography>This simulator will INVALIDATE the User's Insurance</Typography>
        </Box>

        <Box gridColumn={'1 / 3 '} display="flex" justifyContent={'center'}>
          <Box textAlign={'center'}>
            <Button variant="contained" color="primary" onClick={handleSubmit} disabled={isLoading === 'pending'}>
              Simulate
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
})

export default Insurance;
