import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ProfileViewStyles from '../../../assets/jss/components/ProfileViewStyles';
import { selectCurrentTenant } from '../../Shared/selectors';
import AppKendoTableWrapper from '../../Shared/Table/KendoTableWrapper';
import utils from '../../Shared/Utils/utils';
import { selectUpdating, selectVaxRecords, selectVaxRecordsLoading } from '../../Shared/VaccinationRecord/selectors';
import {
  clearVaxRecords,
  getMasterCode,
  getVaxRecords,
  setVaxRecordsLoading,
} from '../../Shared/VaccinationRecord/vaccinationRecordSlice';
import { selectUserDetails } from '../../Users/selectors';
import { vaccineRecordButtons, vaccineRecordColumns } from '../helpers/profileListMapper';
import VaccineRecordDialog from '../VaccineRecordDialog';
import ConsentContentPanel from './ConsentContentPanel';

function VaccineRecords() {
  const classes = useStyles();

  const currentTenant = useSelector(selectCurrentTenant);
  const userDetails = useSelector(selectUserDetails);
  const vaxRecords = useSelector(selectVaxRecords);
  const vaxRecordsLoading = useSelector(selectVaxRecordsLoading);
  const updating = useSelector(selectUpdating);

  const [safeEMRId, setSafeEMRId] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const [pageInfo, setPageInfo] = useState({
    page: 0,
    size: 10,
    sort: 'status',
    direction: 'desc',
  });

  const [isPageInit, setIsPageInit] = useState(true);
  const [isNewVaxRecordVisible, setNewVaxRecordVisible] = useState(false);
  const dispatch = useDispatch();

  // handles all table updates from table wrapper
  const handleTableUpdate = (page, size, sort, direction) => {
    sort = sort || pageInfo.sort;
    if (sort === 'issuer') {
      sort = 'performer';
    }
    if (sort === 'productName') {
      sort = 'manufacturer_productName';
    }
    setPageInfo({ page, size, sort, direction });
    dispatch(getVaxRecords(safeEMRId, { page, size, sort, direction }));
  };

  const initialVaxRecordObj = {
    administeredDate: '',
    id: '',
    issuer: '',
    lastEditDate: '',
    productName: '',
    safeEMRId,
    status: '',
    type: 'ATTESTATION',
    userDetails,
  };

  useEffect(() => {
    if (updating || vaxRecordsLoading) {
      setLoading(true);
    } else {
      const loadingInitTimer = isPageInit ? 8000 : 4000;
      setTimeout(() => {
        setLoading(false);
      }, loadingInitTimer);

      setIsPageInit(false);
    }
  }, [vaxRecordsLoading, loading, vaxRecords, updating]);

  useEffect(() => {
    setSafeEMRId(userDetails.nationalHealthId);
    if (safeEMRId !== undefined) {
      dispatch(getVaxRecords(safeEMRId, pageInfo));
    }
    dispatch(getMasterCode({ term: 'covid', top: 10 }));

    return function cleanup() {
      setSafeEMRId(undefined);
      dispatch(clearVaxRecords());
      dispatch(setVaxRecordsLoading());
    };
  }, [currentTenant, userDetails, safeEMRId, setVaxRecordsLoading, getVaxRecords, getMasterCode]);

  const handleNewVaccineRecordClick = () => {
    setNewVaxRecordVisible(true);
  };

  const onCloseDialog = () => {
    setNewVaxRecordVisible(false);
  };

  const columns = useMemo(() => vaccineRecordColumns(), []);
  const buttons = vaccineRecordButtons(handleNewVaccineRecordClick);

  const getIssuerValue = (vaxDetails, v) => {
    let issuer = null;

    if (v.performer) {
      issuer = v.performer[0]?.actor?.display;
    }

    if (vaxDetails.type.toUpperCase() === 'SAFEPASS' && (!issuer || issuer.toUpperCase() === 'N/A')) {
      issuer = vaxDetails.performer;
      if (!issuer || issuer.toUpperCase() === 'N/A') {
        issuer = vaxDetails.originalIssuer?.name || 'N/A';
      }
    }

    return issuer;
  };

  return (
    <Grid className={classes.contentWrapper}>
      <AppKendoTableWrapper
        data={{
          pageSize: pageInfo.size,
          total: vaxRecords.total || 0,
          items:
            vaxRecords.items &&
            vaxRecords.items
              .map((v) => {
                return {
                  safeEMRId,
                  userDetails,
                  id: v.id,
                  issuer: (v.vaccinations && getIssuerValue(v, v.vaccinations[0])) ?? '',
                  productName: (v.vaccinations && v.vaccinations[0] && v.vaccinations[0].productName) ?? '',
                  lastAdministeredDate:
                    (v.vaccinations &&
                      v.vaccinations[0] &&
                      v.vaccinations[0].administeredDate &&
                      utils.formatDate(v.vaccinations[0].administeredDate)) ??
                    '',
                  lastEditDate: (v.lastEditDate && utils.formatDate(v.lastEditDate)) ?? '',
                  status: v.status?.toLocaleUpperCase() ?? '',
                  type: v.type,
                };
              })
              // TODO: Get Paginated Data from API
              .filter((v, idx) => idx < pageInfo.size),
        }}
        initialDir="desc"
        columns={columns}
        showButtons
        tableButtons={buttons}
        loading={loading}
        onTableUpdate={handleTableUpdate}
        detailsComponent={ConsentContentPanel}
        showOpenFilters={false}
        noRecordsMessage="No Records Available"
      />
      {isNewVaxRecordVisible && (
        <VaccineRecordDialog
          userDetails={initialVaxRecordObj.userDetails}
          closeDialog={onCloseDialog}
          dialogMode={'CREATE'}
          vaxRecordType={'Attestation'.toUpperCase()}
          vaxRecordStatus={initialVaxRecordObj}
          vaxRecordId={initialVaxRecordObj.id}
          safeEMRId={safeEMRId}
        />
      )}
    </Grid>
  );
}

const useStyles = makeStyles(ProfileViewStyles);

export default VaccineRecords;
