/* eslint-disable react/no-array-index-key */
// material-ui
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { useTheme, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ProfileViewStyles from '../../assets/jss/components/ProfileViewStyles';
import { ProfileTabsSkeletonLoading, ProfileDropdownSkeletonLoadingMobile } from '../Profile/ProfileSkeletonLoading';

import { getPublicHealthReport } from './publicHealthReportSlice';
import { selectPublicHealthReportInfo, selectPublicHealthReportInfoLoading } from './selectors';

export const HEALTHSYSTEM_HHS = 'HHS';
export const HEALTHSYSTEM_CALREDIE = 'CalRedie';
export const HEALTHSYSTEM_ECR = 'ECR';
export const HEALTHSYSTEM_LIFEPOINT = 'LifePoint';
export const HEALTHSYSTEM_HHSCDCPrime = 'HHSCDCPrime';

function PublicHealthReportInfo(props) {
  const { classes, encounterId } = props;
  const publicHealthReportInfo = useSelector(selectPublicHealthReportInfo);
  const publicHealthReportInfoLoading = useSelector(selectPublicHealthReportInfoLoading);

  const dispatch = useDispatch();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    dispatch(getPublicHealthReport(encounterId));
  }, []);

  const createPHReport = () => {
    const listItems = [];
    if (publicHealthReportInfo && publicHealthReportInfo.data && publicHealthReportInfo.data.length > 0) {
      for (let i = 0; i < publicHealthReportInfo.data.length; i++) {
        const report = publicHealthReportInfo.data[i];
        const ReportContentsArr = [];
        for (let i = 0; i < report.data.length; i++) {
          const reportContent = report.data[i];
          if (report.healthsystemName == HEALTHSYSTEM_ECR) {
            ReportContentsArr.push(
              <AccordionDetails>
                <Grid container item xs={12}>
                  <Accordion item xs={12} style={{ width: '100%' }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.heading}>
                        <h4>Sent</h4>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container item xs={12}>
                        <iframe width="100%" height="600px" srcDoc={reportContent.request} title="ECR Request" />
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion item xs={12} style={{ width: '100%' }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.heading}>
                        <h4>Received</h4>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container item xs={12}>
                        <iframe width="100%" height="600px" srcDoc={reportContent.response} title="ECR Response" />
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </AccordionDetails>
            );
          } else {
            ReportContentsArr.push(
              <AccordionDetails>
                <Grid container>
                  <Accordion style={{ width: '100%' }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.heading}>
                        <h4>Sent</h4>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container style={{ 'word-break': 'break-all' }}>
                        {ShowRequest(reportContent.request, report.healthsystemName)}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion style={{ width: '100%' }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.heading}>
                        <h4>Received</h4>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container style={{ 'word-break': 'break-all' }}>
                        {ShowResponse(reportContent.successMessage, report.healthsystemName)}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </AccordionDetails>
            );
          }
        }
        listItems.push(
          <Accordion style={{ width: '100%' }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <Typography className={classes.heading}>
                <h4> {report.healthsystemName}</h4>
              </Typography>
            </AccordionSummary>
            {ReportContentsArr.map((item, index) => (
              <React.Fragment key={index}>{item}</React.Fragment>
            ))}
          </Accordion>
        );
      }
    } else {
      listItems.push(
        <Grid item xs={12}>
          <Box>
            <div>
              <Typography variant="body1" style={{ margin: '5px', textAlign: 'center' }}>
                No Data Found.
              </Typography>
            </div>
          </Box>
        </Grid>
      );
    }
    return listItems.map((item, index) => <React.Fragment key={index}>{item}</React.Fragment>);
  };

  if (publicHealthReportInfoLoading || !publicHealthReportInfo) {
    return (
      <div>
        {matches ? (
          <div>
            <Paper style={{ borderRadius: '20px', marginBottom: 30 }} elevation={0}>
              <ProfileTabsSkeletonLoading />
            </Paper>
          </div>
        ) : (
          <div>
            <Paper style={{ borderRadius: '20px', marginBottom: 30 }} elevation={0}>
              <ProfileDropdownSkeletonLoadingMobile />
            </Paper>
          </div>
        )}
      </div>
    );
  }

  return (
    <Card className={classes.basicContainer} style={{ width: '100%' }}>
      <CardContent>
        <Grid className={classes.infoBoxesUpdated}>
          <Grid container>{createPHReport()}</Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default withStyles(ProfileViewStyles)(PublicHealthReportInfo);

function ShowResponse(reportContent, healthsystemName) {
  if (
    (healthsystemName == HEALTHSYSTEM_HHS ||
      healthsystemName == HEALTHSYSTEM_LIFEPOINT ||
      healthsystemName == HEALTHSYSTEM_HHSCDCPrime) &&
    reportContent
  ) {
    const data = JSON.parse(reportContent);
    const jsondata = JSON.stringify(data, null, 2);
    return (
      <div>
        <pre style={{ whiteSpace: 'pre-wrap' }}>{jsondata}</pre>
      </div>
    );
  }
  if (healthsystemName == HEALTHSYSTEM_CALREDIE && reportContent) {
    return (
      <div>
        <pre style={{ whiteSpace: 'pre-wrap' }}>{reportContent}</pre>
      </div>
    );
  }
  return reportContent;
}
function ShowRequest(reportContent, healthsystemName) {
  if ((healthsystemName == HEALTHSYSTEM_LIFEPOINT || healthsystemName == HEALTHSYSTEM_HHSCDCPrime) && reportContent) {
    const data = JSON.parse(reportContent);
    const jsondata = JSON.stringify(data, null, 2);
    return (
      <div>
        <pre style={{ whiteSpace: 'pre-wrap' }}>{jsondata}</pre>
      </div>
    );
  }
  if ((healthsystemName == HEALTHSYSTEM_HHS || healthsystemName == HEALTHSYSTEM_CALREDIE) && reportContent) {
    return (
      <div>
        <pre style={{ whiteSpace: 'pre-wrap' }}>{reportContent}</pre>
      </div>
    );
  }
  return reportContent;
}
