// imports
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Dialog } from '@progress/kendo-react-dialogs';
import React, { useState } from 'react';

import UserSegmentsSettingsStyles from '../../../assets/jss/components/UserSegmentsSettingsStyles';

function UserSegmentsEditorDialog(props) {
  const { onCloseDialog, editingUserSegment, onHandleUpdateUserSegmentName, updateUserSegmentNameLoading, classes } =
    props;

  const [userSegmentName, setUserSegmentName] = useState(editingUserSegment.name);
  const [userSegmentNameError, setUserSegmentNameError] = useState('');

  const handleClose = () => {
    onCloseDialog();
  };

  const handleSave = () => {
    if (!userSegmentName) return setUserSegmentNameError('Required!');

    const updateSegment = { ...editingUserSegment };
    updateSegment.name = userSegmentName;
    onHandleUpdateUserSegmentName(updateSegment);
  };

  const updateUserSegmentName = (e) => {
    const { value } = e.target;
    setUserSegmentName(value);
  };

  return (
    <Dialog title="Add User Segment" onClose={handleClose}>
      <Grid container justifyContent="flex-end">
        <Grid container alignItems="flex-end">
          <Grid item xs={12}>
            <TextField
              id="input-with-icon-grid"
              name="name"
              margin="normal"
              label="User Segment Name"
              onChange={updateUserSegmentName}
              value={userSegmentName}
              autoComplete="off"
              autoFocus
              type="text"
              fullWidth
              error={!!userSegmentNameError}
              helperText={userSegmentNameError}
            />
          </Grid>
        </Grid>

        <Grid container justifyContent="center" style={{ position: 'relative', marginTop: '20px' }}>
          {updateUserSegmentNameLoading ? (
            <Grid className={classes.loadingUserSegmentEditWrapper}>
              <CircularProgress size={28} thickness={6} />
            </Grid>
          ) : null}
          <Button
            disabled={!userSegmentName || updateUserSegmentNameLoading}
            color="primary"
            variant="contained"
            onClick={handleSave}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
}

export default withStyles(UserSegmentsSettingsStyles)(UserSegmentsEditorDialog);
