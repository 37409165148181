import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import { Input } from '@progress/kendo-react-inputs';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CreateOrderPHRStyles from '../../assets/jss/components/CreateOrderPHRStyles';
import { selectSelectedUser } from '../Users/selectors';
import { getUsers } from '../Users/usersSlice';
import { createPHROrder, getMasterLabTestsTenant } from './ordersSlice';
import { selectOrderableTestsList } from './selectors';

function CreateOrderPHRDialog(props) {
  const { classes, currentTenant, onClose, open, users, allUsersLoading } = props;
  const selectedUser = useSelector(selectSelectedUser);
  const orderableTests = useSelector(selectOrderableTestsList);
  const [timeout, setStateTimeout] = useState(undefined);

  const [tests, setTests] = useState([]);
  const [orderPHR, setPHROrder] = useState({
    user: null,
    appointmentId: '',
    orderableTestIds: [],
    orderableTest: null,
    reasonForOrder: '',
    orderablePanelId: '',
    paymentMode: 2,
    insuranceInfoId: '',
    orderingProviderId: 'ef4fd6fa-53da-4e61-a4ef-79e29506f55f',
    patientSignature: '',
    questionnaireId: '',
    notes: [],
    shippingDetails: {},
    paymentDetails: {},
    questionnaireExecutionId: '',
    nationalHealthId: '',
  });

  const dispatch = useDispatch();

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    if (selectedUser) {
      dispatch(getUsers(currentTenant.id, 0, 10, selectedUser.email, 'email', 'ASC'));
      setPHROrder({
        ...orderPHR,
        user: {
          ...selectedUser,
          fullName: `${selectedUser.firstName || ''} ${selectedUser.lastName || ''} (${selectedUser.email})`,
        },
      });
    }
  }, [selectedUser]);

  useEffect(() => {
    if (orderableTests) {
      setTests(
        orderableTests?.map((ot) => ({
          ...ot,
          fullName: `${ot.vendorName} / ${ot.longName ? ot.longName : ot.shortName}`,
        }))
      );
    }
  }, [orderableTests]);

  const createDisabled = !orderPHR.user || !orderPHR.orderableTest;

  const userFilterChange = (e) => {
    if (e.filter) {
      if (timeout) {
        clearTimeout(timeout);
      }
      setStateTimeout(
        setTimeout(() => {
          dispatch(getUsers(currentTenant.id, 0, 10, e.filter.value, 'email', 'ASC'));
        }, 500)
      );
    }
  };

  const userChange = (e) => {
    setPHROrder({ ...orderPHR, user: e.target.value });
  };
  const reasonChange = (e) => {
    setPHROrder({ ...orderPHR, reasonForOrder: e.target.value });
  };
  const testChange = (e) => {
    setPHROrder({ ...orderPHR, orderableTest: e.target.value });
  };

  const testFilterChange = (e) => {
    if (e.filter) {
      dispatch(getMasterLabTestsTenant('orderableTests', currentTenant.id));
    }
  };

  const createOrder = () => {
    orderPHR.nationalHealthId = orderPHR.user.nationalHealthId;
    orderPHR.orderableTestIds = [orderPHR.orderableTest.labTestOrderableId];
    dispatch(createPHROrder(currentTenant.id, orderPHR));
    onClose();
    dispatch(getUsers(currentTenant && currentTenant.id, 0, 10, '', 'email', 'ASC'));
  };

  return (
    <Dialog
      className={classes.dialogContainer}
      onClose={onClose}
      open={open}
      maxWidth="sm"
      fullWidth
      disableEnforceFocus
    >
      <DialogTitle style={{ padding: '26px 0 0' }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography
            style={{
              fontSize: 24,
              fontWeight: 500,
              color: 'rgba(80, 83, 88, 1)',
            }}
          >
            NEW PHR ORDER
          </Typography>
          <IconButton onClick={onClose} style={{ marginRight: -12 }}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent style={{ padding: '0 0 8px' }}>
        <Grid container>
          <Grid item xs={12}>
            <ComboBox
              data={users.map((u) => ({
                ...u,
                fullName: `${u.firstName || ''} ${u.lastName || ''} (${u.email})`,
              }))}
              allowCustom={false}
              suggest
              filterable
              onFilterChange={userFilterChange}
              label="User"
              name="user"
              required
              onChange={userChange}
              style={{ width: '100%' }}
              textField="fullName"
              value={orderPHR.user}
              loading={allUsersLoading}
              // defaultValue={{...selectedUser, fullName: `${selectedUser.firstName || ''} ${selectedUser.lastName || ''} (${selectedUser.email})`,}}
            />
          </Grid>
          <Grid item xs={12} style={{ marginTop: 10 }}>
            <Input
              label="Reason For Testing"
              required={false}
              onChange={reasonChange}
              value={orderPHR.reasonForOrder}
              style={{ width: '100%' }}
            />
          </Grid>
          <Grid item xs={12} style={{ marginTop: 10 }}>
            <ComboBox
              data={tests || []}
              allowCustom={false}
              suggest
              filterable
              onFilterChange={testFilterChange}
              label="Select Test"
              name="test"
              required
              onChange={testChange}
              style={{ width: '100%' }}
              textField="fullName"
              value={orderPHR.orderableTest}
              loading={allUsersLoading}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center', margin: '47px 0 24px' }}>
        <Button
          variant="outlined"
          color="primary"
          disableElevation
          elevation={0}
          className={classes.actionButton}
          onClick={onClose}
        >
          Cancel
        </Button>
        <Grid item style={{ width: 19 }} />
        <Button
          variant="contained"
          color="primary"
          disableElevation
          elevation={0}
          className={classes.actionButton}
          onClick={createOrder}
          disabled={createDisabled}
        >
          Create Order
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default withStyles(CreateOrderPHRStyles)(CreateOrderPHRDialog);
