export const selectAuthToken = (state) => state.shared.isLoggedIn;

export const selectAccountId = (state) => state.auth.accountId;

export const selectDefaultTenantId = (state) => state.auth.defaultTenantId;

export const selectAccountLoading = (state) => state.auth.accountLoading;

export const selectAccountValid = (state) => state.auth.accountValid;

export const selectAccountError = (state) => state.auth.error;
