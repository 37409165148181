import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { ColorPicker } from '@progress/kendo-react-inputs';
import React, { useState, useEffect } from 'react';

import utils from '../../Utils/utils';

// ui reflection of file view
function ColorPickerInput(props) {
  const { field, value, handleChange, disabled } = props;
  const [currentValue, setCurrentValue] = useState(value && value[field.name]);

  useEffect(() => {
    if (value !== currentValue) {
      setCurrentValue(value);
    }
    // eslint-disable-next-line
  }, [value]);

  const handleValueChange = (e) => {
    const hexValue = utils.rgbToHex(e.value);
    setCurrentValue(hexValue);
    const event = {
      target: {
        value: hexValue,
        name: field.name,
      },
    };
    handleChange(event);
  };

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item>
        <ColorPicker
          name={field.name}
          view="gradient"
          onChange={handleValueChange}
          required={field.required}
          style={{ width: '100%' }}
          // defaultValue={currentValue || field.defaultValue}
          value={currentValue || field.defaultValue}
          disabled={disabled}
          gradientSettings={{ opacity: false }}
        />
      </Grid>
      <Grid item>
        <Typography>{field.label}</Typography>
      </Grid>
      {field.description && (
        <Grid item xs={12}>
          <Typography variant="caption" style={{ opacity: 0.6 }}>
            {field.description}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}

export default ColorPickerInput;
