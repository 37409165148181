import * as htmlUtils from '../../../Shared/Grid/helpers';
import utils from '../../../Shared/Utils/utils';

export const transform = (currentTenant, list) => ({
  ...list,
  items:
    list &&
    list.items.map((item) => ({
      ...item,
      created: utils.formatDate(item.created),
      modified: utils.formatDate(item.created),
      selected: currentTenant === item.id,
    })),
});

export const initialSorting = {
  field: 'title',
  dir: 'asc',
};

export const columns = [
  {
    id: 'logoURL',
    label: 'Logo',
    show: true,
    sortable: false,
    locked: true,
    minWidth: 130,
    cell: htmlUtils.withWidth(htmlUtils.imageCell, 75),
  },
  {
    id: 'title',
    label: 'Title',
    show: true,
    minWidth: 170,
  },
  {
    id: 'color',
    label: 'Color',
    show: true,
    minWidth: 100,
    sortable: false,
    cell: htmlUtils.color,
  },
  {
    id: 'dashboardURL',
    label: 'Dashboard URL',
    show: false,
    minWidth: 170,
    sortable: false,
  },
  {
    id: 'dashboardGroup',
    label: 'Dashboard Group ID',
    show: false,
    minWidth: 170,
    sortable: false,
  },
  {
    id: 'created',
    label: 'Date Created',
    show: true,
    minWidth: 150,
    sortable: false,
  },
  {
    id: 'creator',
    label: 'Created By',
    show: true,
    minWidth: 150,
    sortable: false,
  },
  {
    id: 'modified',
    label: 'Date Modified',
    show: true,
    minWidth: 150,
    sortable: false,
  },

  {
    id: 'modifier',
    label: 'Modified By',
    show: true,
    minWidth: 150,
    sortable: false,
  },
];

export const tableButtons = (tenant) => [
  {
    title: 'New Tenant',
    buttonType: 'link',
    link: `/${tenant && tenant.id}/account/tenants/create`,
    variant: 'contained',
  },
];
