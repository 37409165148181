import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { useTheme, withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CloseIcon from '@material-ui/icons/Close';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import HelpIcon from '@material-ui/icons/Help';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import DragExpansionWrapperStyles from '../../../assets/jss/components/DragExpansionWrapperStyles';
import { showNotification } from '../../Notifications/notificationSlice';
import { variables } from '../../Settings/ExpansionComponents/helpers/verbiageVariables';
import VerbiagePreviewDialog from '../../Settings/ExpansionComponents/VerbiagePreviewDialog';
import { SettingsConfirmImportDialog } from '../../Settings/SettingsConfirmImportDialog';
import { previewVerbiage, updateFaq, updateVerbiage } from '../../Settings/settingsSlice';
import SettingsTemplateVariableHelper from '../../Settings/SystemMessages/SettingsTemplateVariableHelper';
import utils from '../Utils/utils';

import ExpansionPanel from './ExpansionPanel';
import ExpansionSkeletonLoading from './ExpansionSkeletonLoading';

export function ExpansionPanelWrapper(props) {
  const {
    classes,
    currentTenant,
    dataLoading,
    submitting,
    deleting,
    data,
    type,
    verbiageDefinitions,
    onHandleUpdate,
    onHandleDelete,
    onHandleImport,
    draggable,
    expansionButtons,
    showSystemVariablePanel,
    titleValue,
    panelPreview,
    panelEdit,
    panelDelete,
    content,
    panelChips,
    onHandleEdit,
    allOutcomes,
  } = props;
  const [expandedPanel, setExpandedPanel] = useState(false);
  const [panelData, setPanelData] = useState('');
  const [editing, setEditing] = useState('');
  const [editingType, setEditingType] = useState('');
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const [previewData, setPreviewData] = useState({});
  const [variableOpen, setVariableOpen] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [availableDefinitions, setAvailableDefinitions] = useState(false);
  const [allDefsPairs, setAllDefsPairs] = useState(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  const dispatch = useDispatch();

  useEffect(() => {
    if (data) {
      setPanelData(data);
    }
  }, [data]);

  // check what verbiage definitions are being used or not in case of user add for dropdown selection of unused definitions
  useEffect(() => {
    if (verbiageDefinitions && panelData) {
      const allDefs = [];
      const allPairs = {};
      const usedDefs = [];
      const defEntries = Object.entries(verbiageDefinitions);
      defEntries.forEach((q) => {
        allDefs.push(q[1].title);
        allPairs[q[1].title] = q[0];
      });
      panelData.forEach((q) => {
        usedDefs.push(q.title);
      });
      const availableDefs = allDefs.filter((val) => !usedDefs.includes(val));
      setAvailableDefinitions(availableDefs);
      setAllDefsPairs(allPairs);
    }
  }, [verbiageDefinitions, panelData]);

  // expand one panel at a time to view answer to question
  const handleExpansionChange = (panel) => (event, isExpanded) => {
    setExpandedPanel(isExpanded ? panel : false);
  };

  // handle variable messages panel
  const handleVariablePanel = () => {
    setVariableOpen(!variableOpen);
    setTooltipOpen(false);
  };

  // adds new question object to end of array, opens that panel and sets to editing
  const handleAddNew = () => {
    if (type === 'faq') {
      const newSequenceNo = data.length + 1;
      const newData = {
        sequenceNo: newSequenceNo,
        question: '',
        answer: '',
      };
      setPanelData(data.concat(newData));
      setExpandedPanel(`panel${newSequenceNo}`);
      setEditing(newSequenceNo);
      setEditingType('new');
      setVariableOpen(true);
    } else if (type === 'verbiage') {
      const newData = {
        title: '',
        key: '',
        value: '',
      };
      setPanelData(data.concat(newData));
      setExpandedPanel(`panel${panelData.length}`);
      setEditing(panelData.length);
      setEditingType('new');
      setVariableOpen(true);
    }
    window.scrollTo(0, document.body.scrollHeight);
  };

  const handleEditing = (sequenceNo, type, variable) => {
    setEditing(sequenceNo);
    setEditingType(type);
    setVariableOpen(variable);
  };

  const handleCancel = (updateData) => {
    setPanelData(updateData);
  };

  // handles removal of object in array and saves updated items
  const handleDelete = (q) => {
    setLoading(false);
    onHandleDelete(q);
  };

  // handles save of any changes to question/answer
  const handleSave = (updatedData) => {
    setLoading(false);
    setPanelData(updatedData);
    onHandleUpdate(currentTenant, updatedData);
  };

  // handles import of FAQs from clipboard
  const handleImport = () => {
    setOpen(false);
    navigator.clipboard
      .readText()
      .then((text) => {
        // `text` contains the text read from the clipboard
        const parsedJson = JSON.parse(text);
        onHandleImport(parsedJson);
      })
      .catch((err) => {
        // maybe user didn't grant access to read from clipboard, not valid json, or not valid template format
        if (err.message.includes('JSON')) {
          dispatch(
            showNotification(
              `This is not valid ${
                type === 'faq' ? type.toUpperCase() : type
              } formatting. Please try to copy the desired ${type === 'faq' ? type.toUpperCase() : type} again.`,
              'error'
            )
          );
        } else {
          dispatch(showNotification(err.message, 'error'));
        }
      });
  };

  // handles preview of verbiage
  const handlePreview = (updatedData) => {
    dispatch(previewVerbiage(currentTenant, updatedData)).then((response) => {
      setOpenPreview(true);
      setPreviewData(response);
    });
  };

  // mobile reordering
  const handleMoveDown = (q) => {
    setLoading(false);
    let newData;

    if (type === 'faq') {
      newData = reorder(data, q.sequenceNo - 1, q.sequenceNo);
      newData.forEach((faq, i) => {
        if (data.sequenceNo !== i + 1) {
          newData[i].sequenceNo = i + 1;
          if (expandedPanel) {
            setExpandedPanel(`panel${q.sequenceNo}`);
          }
        }
      });
    } else if (type === 'verbiage') {
      // newData = reorder(data, q.sequenceNo - 1, q.sequenceNo);
      // newData.map((faq, i) => {
      //   if (data.sequenceNo !== i + 1) {
      //     newData[i].sequenceNo = i + 1;
      //     if (expandedPanel) {
      //       setExpandedPanel(`panel${q.sequenceNo}`);
      //     }
      //   }
      // });
      // TODO: NEED TO REORDER VERBIAGE IN MOBILE
      /* eslint-disable-next-line no-console */
      console.log('REORDERING VERBIAGE!!!!');
    }
    setPanelData(newData);
    const saveData = {
      id: currentTenant,
      items: newData,
    };
    if (type === 'faq') {
      dispatch(updateFaq(currentTenant, saveData));
    } else if (type === 'verbiage') {
      dispatch(updateVerbiage(currentTenant, newData));
    }
  };

  const handleMoveUp = (q) => {
    setLoading(false);
    let newData;
    if (type === 'faq') {
      newData = reorder(data, q.sequenceNo - 1, q.sequenceNo - 2);
      newData.forEach((data, i) => {
        if (data.sequenceNo !== i + 1) {
          newData[i].sequenceNo = i + 1;
          if (expandedPanel) {
            setExpandedPanel(`panel${q.sequenceNo}`);
          }
        }
      });
    } else if (type === 'verbiage') {
      // newData = reorder(data, q.sequenceNo - 1, q.sequenceNo);
      // newData.map((faq, i) => {
      //   if (data.sequenceNo !== i + 1) {
      //     newData[i].sequenceNo = i + 1;
      //     if (expandedPanel) {
      //       setExpandedPanel(`panel${q.sequenceNo}`);
      //     }
      //   }
      // });
      // TODO: NEED TO REORDER VERBIAGE IN MOBILE
      /* eslint-disable-next-line no-console */
      console.log('REORDERING VERBIAGE!!!!');
    }

    setPanelData(newData);
    const saveData = {
      id: currentTenant,
      items: newData,
    };
    if (type === 'faq') {
      dispatch(updateFaq(currentTenant, saveData));
    } else if (type === 'verbiage') {
      dispatch(updateVerbiage(currentTenant, newData));
    }
  };

  // ======== DRAG AND DROP REORDERING FUNCTIONALITY ======== //
  // fired when reordering with drag and drop
  const onDragEnd = (result) => {
    const { destination, source } = result;
    setLoading(false);
    if (!destination) {
      return;
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    const newData = reorder(data, result.source.index, result.destination.index);

    // update sequence number to match reordered index, if panel open, set expanded panel to updated destination
    if (type === 'faq') {
      newData.forEach((data, i) => {
        if (data.sequenceNo !== i + 1) {
          newData[i].sequenceNo = i + 1;
          if (expandedPanel) {
            setExpandedPanel(`panel${result.destination.index + 1}`);
          }
        }
      });
    }

    setPanelData(newData);
    const saveData = {
      id: currentTenant,
      items: newData,
    };
    if (type === 'faq') {
      dispatch(updateFaq(currentTenant, saveData));
    } else if (type === 'verbiage') {
      dispatch(updateVerbiage(currentTenant, newData));
    }
  };

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  if (!currentTenant) return <div />;
  if (dataLoading && loading) return <ExpansionSkeletonLoading />;
  return (
    <Grid container justifyContent="flex-start" style={{ margin: '40px 0 40px 0px' }}>
      {panelData && (
        <Grid item xs={12} className={classes.mobileFAQButton} justifyContent="flex-end">
          <Button variant="contained" color="primary" onClick={handleAddNew} disabled={typeof editing === 'number'}>
            Add New {type}
          </Button>
        </Grid>
      )}
      <Grid item md={12} xs={12} data-testid="dndComponent">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="form-1">
            {(provided) => (
              <Grid
                container
                innerRef={provided.innerRef}
                {...provided.droppableProps}
                // spacing={24}
              >
                {panelData &&
                  panelData.map((panelData, i) => (
                    <Draggable
                      key={panelData.question || panelData.key}
                      draggableId={`draggable${panelData.question || panelData.key}`}
                      index={i}
                      isDragDisabled={!draggable || editing === panelData.sequenceNo || editing === i || !matches}
                    >
                      {(provided) => (
                        <Grid
                          container
                          justifyContent="center"
                          alignItems="center"
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          innerRef={provided.innerRef}
                          className={classes.hoverParent}
                        >
                          <Grid item xs={1}>
                            {draggable && !editing && matches && <DragIndicatorIcon className={classes.hoverChild} />}
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={12}
                            style={{
                              padding:
                                expandedPanel === `panel${panelData.sequenceNo || i}` ? '10px 0 10px' : '1px 0 1px',
                            }}
                          >
                            <ExpansionPanel
                              panelData={panelData}
                              identifier={panelData.sequenceNo || i}
                              title={panelData[titleValue]}
                              // content={panelData.answer || panelData.value}
                              data={panelData}
                              editing={editing}
                              editingType={editingType}
                              submitting={submitting}
                              deleting={deleting}
                              expandedPanel={expandedPanel}
                              type={type}
                              matches={matches}
                              verbiageDefinitions={verbiageDefinitions}
                              availableDefinitions={availableDefinitions}
                              allDefsPairs={allDefsPairs}
                              panelPreview={panelPreview}
                              panelEdit={panelEdit}
                              panelDelete={panelDelete}
                              panelChips={panelChips}
                              draggable={draggable}
                              content={content}
                              onHandleSave={handleSave}
                              onHandleDelete={handleDelete}
                              handleExpansionChange={handleExpansionChange}
                              onHandleCancel={handleCancel}
                              onHandleEditing={handleEditing}
                              onHandleMoveDown={handleMoveDown}
                              onHandleMoveUp={handleMoveUp}
                              onHandlePreview={handlePreview}
                              onHandleEdit={onHandleEdit}
                              allOutcomes={allOutcomes}
                            />
                          </Grid>
                        </Grid>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </Grid>
            )}
          </Droppable>
        </DragDropContext>
      </Grid>
      {panelData && expansionButtons && (
        <Grid item md={2} className={classes.desktopFAQButton}>
          <Grid container spacing={2} className={classes.sticky}>
            {expansionButtons.map((btn) => (
              <Grid item xs={12}>
                {btn.buttonType === 'link' ? (
                  <Link to={!btn.disabled && btn.link} style={{ textDecoration: 'none' }}>
                    <Button
                      variant={btn.variant}
                      color="primary"
                      style={{ width: '100%' }}
                      disableElevation
                      disabled={btn.disabled}
                    >
                      {btn.title}
                    </Button>
                  </Link>
                ) : (
                  <Button
                    variant={btn.variant}
                    color="primary"
                    onClick={btn.action}
                    style={{ width: '100%' }}
                    disableElevation
                    disabled={btn.disabled}
                  >
                    {btn.title}
                  </Button>
                )}
              </Grid>
            ))}
            <Grid container justifyContent="flex-end">
              {showSystemVariablePanel && variableOpen && (
                <Grid item>
                  <Tooltip title="Close System Message Variables" open={tooltipOpen}>
                    <IconButton
                      onMouseEnter={() => setTooltipOpen(true)}
                      onMouseLeave={() => setTooltipOpen(false)}
                      onClick={handleVariablePanel}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              )}
              {showSystemVariablePanel && !variableOpen && (
                <Grid item>
                  <Tooltip title="View System Message Variables" open={tooltipOpen}>
                    <IconButton
                      onMouseEnter={() => setTooltipOpen(true)}
                      onMouseLeave={() => setTooltipOpen(false)}
                      onClick={handleVariablePanel}
                    >
                      <HelpIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              )}
            </Grid>
            {showSystemVariablePanel && variableOpen && (
              <Grid
                item
                xs={12}
                style={{
                  maxHeight: '50vh',
                  overflow: 'auto',
                }}
              >
                <Paper className={classes.paper} elevation={0}>
                  <SettingsTemplateVariableHelper variables={variables} />
                </Paper>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
      <SettingsConfirmImportDialog
        open={open}
        handleImport={handleImport}
        title={`Import ${type === 'faq' ? type.toUpperCase() : utils.capitalizeString(type)}`}
        message={`Are you sure you want to import the copied ${type === 'faq' ? type.toUpperCase() : type}?
          Your current ${type === 'faq' ? type.toUpperCase() : type} will be overwritten.`}
        closeImportDialog={() => setOpen(false)}
      />
      <VerbiagePreviewDialog open={openPreview} handleClose={() => setOpenPreview(false)} previewData={previewData} />
    </Grid>
  );
}

export default withStyles(DragExpansionWrapperStyles)(ExpansionPanelWrapper);
