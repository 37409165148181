import Button from '@material-ui/core/Button';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';

function DetailsActionCell(props) {
  const { dataItem, onClick } = props;

  return (
    <td>
      <Button
        style={{
          textTransform: 'none',
        }}
        onClick={() => onClick(dataItem)}
      >
        View
        {dataItem.expanded ? (
          <ExpandLessIcon style={{ marginLeft: 5 }} />
        ) : (
          <ExpandMoreIcon style={{ marginLeft: 5 }} />
        )}
      </Button>
    </td>
  );
}

export default DetailsActionCell;
