import { createSlice } from '@reduxjs/toolkit';

import api from '../../../api';
import { handleError } from '../../Shared/Content/APIUtils';

const initialState = {
  xmlView: null,
  xmlViewLoading: null,
};

export const xmlViewerSlice = createSlice({
  name: 'xmlViewer',
  initialState,
  reducers: {
    setXmlViewLoading: (state) => ({
      ...state,
      xmlViewLoading: true,
    }),
    setXmlView: (state, { payload }) => ({
      ...state,
      xmlView: payload,
      xmlViewLoading: false,
    }),
  },
});

export const { setXmlView, setXmlViewLoading } = xmlViewerSlice.actions;

export const getXMLViewer = (safeEMRId, tenantId) => async (dispatch) => {
  dispatch(setXmlViewLoading());

  const [result, error] = await api.getXMLViewerRequest(safeEMRId, tenantId);

  if (result) {
    dispatch(setXmlView(result));
  }

  if (error && error.message) {
    dispatch(handleError(error));
  }
};

export default xmlViewerSlice.reducer;
