import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import ExpansionPanelWrapper from './ExpansionPanelWrapper';
import { mainEncounterInfo } from './helpers/mapping';
import ReportWrapper from './ReportWrapper';

function MainEncounterData(props) {
  const { encounterData } = props;
  const classes = useStyles();

  const renderMainEncounterPanelTitle = () => (
    <Grid container direction="column">
      <Typography variant="body1" color="primary">
        Intake & Order #15273
      </Typography>
    </Grid>
  );

  return (
    <ExpansionPanelWrapper renderTitle={renderMainEncounterPanelTitle} name="mainEncounterInfo">
      <Grid container justifyContent="center" spacing={1}>
        {mainEncounterInfo.map((report) => (
          <ReportWrapper report={report} data={encounterData} />
        ))}
      </Grid>
      <Grid container className={classes.orderedItems}>
        <Grid xs={2}>Ordered Items:</Grid>
        <Grid xs={10}>HIV, Syphilis, HEP-C, Chlamydia, Gonorrhea, HSV2</Grid>
      </Grid>
    </ExpansionPanelWrapper>
  );
}

const useStyles = makeStyles({
  orderedItems: {
    backgroundColor: '#EEEFF2',
    height: 'auto',
    minHeight: '30px',
    marginTop: 8,
    padding: '6px 10px',
    fontWeight: 700,
  },
});

export default MainEncounterData;
