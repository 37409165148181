import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';

import DiscussionStyles from '../../../../assets/jss/components/DiscussionStyles';
import { selectAppThemeColor, selectCurrentUserData } from '../selectors';

function MessageWrapper(props) {
  const { classes, prevMessage, currentMessage } = props;

  const currentUser = useSelector(selectCurrentUserData);
  const appThemeColor = useSelector(selectAppThemeColor);

  const currentUserMessage = currentMessage?.userId === currentUser.email;
  const userSymbols =
    currentMessage.firstName.slice(0, 1).toUpperCase() + currentMessage.lastName.slice(0, 1).toUpperCase();

  return (
    <Grid container direction="row" className={classes.messageWrapper}>
      {!currentUserMessage && currentMessage.userId !== prevMessage?.userId ? (
        <Grid className={classes.senderIcon} style={{ backgroundColor: appThemeColor }}>
          {userSymbols}
        </Grid>
      ) : (
        <Grid className={classes.emptyIcon} />
      )}
      <Grid className={classes.message}>
        {!currentUserMessage && currentMessage.userId !== prevMessage?.userId && (
          <Grid className={classes.userName}>
            {currentMessage.firstName} {currentMessage.lastName}
          </Grid>
        )}

        <Grid className={currentUserMessage ? 'bubble recipient first' : 'bubble sender last'}>
          <span
            dangerouslySetInnerHTML={{
              __html: currentMessage.message,
            }}
          />
          {/* {currentMessage.message} */}
          <Grid className={classes.messageDate} style={{ color: currentUserMessage ? '#848484' : '#e8e8e8' }}>
            {moment(currentMessage.created).format('DD.MM.YYYY')}
          </Grid>
        </Grid>

        {/* <div className="bubble recipient first">Oh that's cool!</div> */}
      </Grid>
    </Grid>
  );
}

export default withStyles(DiscussionStyles)(MessageWrapper);
