/* eslint-disable react/no-array-index-key */
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DeleteIcon from '@material-ui/icons/Delete';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
// import clsx from 'clsx';
import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import Lightbox from 'react-image-lightbox';
import { useDispatch, useSelector } from 'react-redux';

// import Delete from '../../assets/img/delete.svg';
import VaccineRecordDialogStyles from '../../assets/jss/components/VaccineRecordDialogStyles';
// import { getUserFromToken } from '../Auth/helpers';
// import history from '../Shared/Redux/history';

import Previews from '../Shared/Forms/KendoFields/ImageUploadCrop';
import { selectCurrentTenant } from '../Shared/selectors';
import utils from '../Shared/Utils/utils';
import {
  selectVaxRecords,
  selectMasterCode,
  selectVaxRecordImageLoading,
  selectVaxRecordImage,
} from '../Shared/VaccinationRecord/selectors';
import { VaccinationRecordImageSkeletonLoading } from '../Shared/VaccinationRecord/VaccinationRecordSkeletonLoading';

import {
  approveAttestation,
  approveExemption,
  updateAttestation,
  createAttestation,
} from '../Shared/VaccinationRecord/vaccinationRecordSlice';

import { VAX_TYPES } from './helpers/mappings';

import 'react-image-lightbox/style.css';

const SAVE_CONFIRM_TEXT = {
  VIEW: 'SAVE',
  EDIT: 'APPROVE',
  CREATE: 'CONFIRM',
};

const ATTESTATION_TITLE = {
  VIEW: 'Edit Vaccination Record',
  EDIT: 'Review Vaccination Record',
  CREATE: 'New Vaccination Record',
};

function VaccineRecordDialog(props) {
  const {
    closeDialog,
    vaxRecordId,
    dialogMode,
    safeEMRId,
    vaxRecordType,
    fromUser,
    fromProfile /* fix: SAF-3864/SAF-3865 for Vax Tab in Profile page */,
    userDetails,
  } = props;
  const getMaxDose = (mfxCode, masterCodeArr) => {
    const masterCodeItem = masterCodeArr.find((c) => c.mvX_Code === mfxCode || c.cvX_Code === mfxCode);

    return masterCodeItem?.dose_Count || 1;
  };

  const getMaxDoseWithCvxCode = (cvxCode, masterCodeArr) => {
    const masterCodeItem = masterCodeArr.find((c) => c.cvX_Code === cvxCode);

    return masterCodeItem.dose_Count;
  };

  const getVaxMfxCode = (vaccinationsArr) => {
    let firstDoseItem = {};
    // Get Vaccine Code based on the Manufacturing code
    firstDoseItem = vaccinationsArr.find((v) => v.doseNumber === 1);

    // When the first vaccine (doseNumber 1) is not found but vaccinationsArr is not empty
    //  then get the first element's manufacturer code
    if ((firstDoseItem === undefined || firstDoseItem === null) && vaccinationsArr.length > 0) {
      firstDoseItem = vaccinationsArr[0];
    }
    // [SAF-2328] - Using manufacturer code OR manufacturer ID in case code is missing like in "OneUpHealht" type of vax record
    const firstDoseMfxCode = firstDoseItem.manufacturer?.code || firstDoseItem.manufacturer?.id;
    let firstDoseCvxCode = null;

    if (firstDoseItem.vaccinationCode) {
      firstDoseCvxCode = firstDoseItem.vaccinationCode[0]?.codeValue;
    }

    return firstDoseMfxCode || firstDoseCvxCode;
  };

  const getVaxMfxCodeWithCvxCode = (cvxCode, masterCodeArr) => {
    const masterCodeItem = masterCodeArr.find((c) => c.cvX_Code === cvxCode);

    return masterCodeItem.mvX_Code;
  };

  const transformVaxRecord = (vaxItem, vaxItemNo) => {
    const trxVaxItem = {
      id: vaxItem.id,
      vaccinationCode: vaxItem.vaccinationCode || '',
      status: vaxItem.status,
      manufacturer: vaxItem.manufacturer,
      administeredDate: utils.formatDisplayDate(vaxItem.administeredDate),
      doseNumber: vaxItemNo,
      performer: _.cloneDeep(vaxItem.performer),
      operationType: 'Edit',
      errors: {
        issuer: false,
        administeredDate: false,
      },
      isVaccineTypeUpdated: true,
    };

    return trxVaxItem;
  };

  const fixDoseNumber = (vaxRecords) => vaxRecords.map((v, idx) => ({ ...v, doseNumber: idx + 1 }));

  const generateNewRecord = (masterCodeDetails, vaxItemNo, isVaccineTypeUpdated) => {
    const newVaxItem = {
      id: '',
      vaccinationCode: masterCodeDetails.vaccinationCode.map((newVaxCode) => ({
        ...newVaxCode,
      })),
      manufacturer: { ...masterCodeDetails.manufacturer },
      status: 'Completed',
      administeredDate: '',
      doseNumber: vaxItemNo,
      performer: [
        {
          actor: {
            display: '',
          },
        },
      ],
      operationType: 'Add',
      errors: {
        issuer: false,
        administeredDate: false,
      },
      isVaccineTypeUpdated: typeof isVaccineTypeUpdated === 'undefined',
      isBooster: false,
    };
    return newVaxItem;
  };

  const getVaxMasterCodeDetails = (vaxCode) => {
    const vaxDetails = masterCode.find((m) => m.cvX_Code === vaxCode);
    return {
      manufacturer: {
        id: vaxDetails.mvX_Code,
        name: vaxDetails.product_Name,
        code: vaxDetails.mvX_Code,
      },
      vaccinationCode: [
        {
          display: vaxDetails.cvX_Short_Description,
          codeValue: vaxDetails.cvX_Code,
        },
        {
          display: vaxDetails.cpT_description,
          codeValue: vaxDetails.cpT_Code,
        },
      ],
    };
  };

  const getTransformVaxRecords = (vaccinationsArr, masterCode) => {
    let vaxRecords = [];

    const mfxCode = getVaxMfxCode(vaccinationsArr);
    const maxDose = getMaxDose(mfxCode, masterCode);
    const vaxArrCurLength = vaccinationsArr.length;

    if (vaxArrCurLength <= maxDose) {
      vaxRecords = vaccinationsArr.map((vaxItem, vaxItemNo) => {
        if (vaxItemNo < vaxArrCurLength) {
          const transformedRecord = transformVaxRecord(vaxItem, vaxItemNo + 1 /* doseNumber */);
          return transformedRecord;
        }
        // GENERATE NEW VAX RECORD FOR NONEXISTING DOSE
        const masterCodeTrxDetails = getVaxMasterCodeDetails(defaultVaxCode, masterCode);

        // generate new record for non existing dose
        const newRecord = generateNewRecord(masterCodeTrxDetails, vaxItemNo + 1 /* doseNumber */);
        return newRecord;
      });
    }

    // FOR BOOSTER
    if (vaxArrCurLength > maxDose) {
      vaxRecords = vaccinationsArr.map((vaxItem, vaxItemNo) => {
        const transformedRecord = transformVaxRecord(vaxItem, vaxItemNo + 1 /* doseNumber */);
        transformedRecord.isBooster = vaxItemNo + 1 > maxDose;
        return transformedRecord;
      });
    }

    return vaxRecords;
  };

  const [isChangingVaccine, setIsChangingVaccine] = useState(false);
  const masterCode = useSelector(selectMasterCode);
  const tenant = useSelector(selectCurrentTenant);
  const vaxRecordsLists = useSelector(selectVaxRecords);
  const vaxRecordImageLoading = useSelector(selectVaxRecordImageLoading);
  const vaxRecordImage = useSelector(selectVaxRecordImage) || [];

  const vaxDetails = vaxRecordsLists.items?.find((v) => vaxRecordId === v.id);
  const defaultVaxCode = masterCode[0].cvX_Code;

  let mfxCode = '';
  const [newMfxCode, setNewMfxCode] = useState('');
  let maxDose = 0;
  const [newMaxDose, setNewMaxDose] = useState(0);
  let vaxRecords = [];

  useEffect(
    () => () => {
      setNewMaxDose(0);
    },
    []
  );

  // ATTESTATION || SAFEPASS || ONEUPHEALTH
  if (
    vaxRecordType === VAX_TYPES.ATTESTATION ||
    vaxRecordType === VAX_TYPES.SAFEPASS ||
    vaxRecordType === VAX_TYPES.ONEUPHEALTH
  ) {
    /* CREATE THE TRANSFORMED VACCINATION LIST from THE FETCHED API DATA */
    if (vaxDetails && vaxDetails.vaccinations) {
      vaxRecords = getTransformVaxRecords(vaxDetails.vaccinations, masterCode);
      /* CREATE NEW RECORDS FOR EMPTY VAX DETAILS */
    } else {
      /* GENERATE NEW RECORDS */
      const masterCodeTrxDetails = getVaxMasterCodeDetails(defaultVaxCode, masterCode);

      const holdMaxDose = dialogMode === 'CREATE' || !maxDose ? 1 : maxDose;

      for (let vaxItemNo = 0; vaxItemNo < holdMaxDose; vaxItemNo++) {
        vaxRecords.push(generateNewRecord(masterCodeTrxDetails, vaxItemNo + 1 /* doseCount */, false));
      }
    }
    // WHEN MAIN VACCINE IS CHANGED
    if (isChangingVaccine) {
      mfxCode = newMfxCode;
      maxDose = newMaxDose;

      // INITIAL mfxCode and maxDose
    } else {
      const vaxDetailArr = dialogMode === 'CREATE' || !vaxDetails.vaccinations ? vaxRecords : vaxDetails.vaccinations;
      mfxCode = vaxRecords.length > 0 ? getVaxMfxCode(vaxDetailArr) : undefined;
      // TOOD: What if there is no manufacturer indicated on the user side
      maxDose = getMaxDose(mfxCode, masterCode);
    }
    // EXEMPTION
  } else if (vaxRecordType === VAX_TYPES.EXEMPTION) {
    // return;
  }

  const vaxImages = [];
  vaxDetails?.images.forEach((img, idx) => {
    vaxImages[idx] = { ...vaxRecordImage[idx]?.data, ...img };
  });

  const [newVaxRecords, setnewVaxRecords] = useState(vaxRecords);
  const [vaxImageError, setVaxImageError] = useState('');
  const [newVaxImages, addNewVaxImage] = useState(
    vaxRecordType === VAX_TYPES.ATTESTATION || vaxRecordType === VAX_TYPES.EXEMPTION
      ? [
          {
            url: null,
            file: null,
          },
        ]
      : []
  );

  useEffect(() => {
    // SAF-3864 and SAF-3865 - using vaxRecordImage object for displaying image when
    // dialog is opened from the Profile page since old API does not contina image value anymore.
    if (fromProfile && vaxRecordImage && Object.keys(vaxRecordImage).length !== 0) {
      const updatedVaxImages = Object.keys(vaxRecordImage).map((key) => {
        return {
          contentType: vaxRecordImage[key]?.data?.contentType,
          id: vaxRecordImage[key]?.data?.id,
          value: vaxRecordImage[key]?.data?.value,
        };
      });
      addNewVaxImage(updatedVaxImages);
      return;
    }
    if (vaxImages.length > 0 && !_.isEqual(newVaxImages, vaxImages)) {
      addNewVaxImage(vaxImages);
    }
  }, [vaxRecordImage]);

  /* Display Dose Number based on the Dose Count
   *  for a Given Vaccine Manufacturer
   *  If exceeded Dose Count then it is considered as Booster Shots
   */
  const getDoseHeaderStr = (doseIndx) => {
    if (isChangingVaccine) {
      if (doseIndx < newMaxDose) {
        return `Dose ${doseIndx + 1}`;
      }
      // reset count to 1 when dose number exceeded the dose count
      return `Booster ${doseIndx - newMaxDose + 1}`;
    }
    if (doseIndx < maxDose) {
      return `Dose ${doseIndx + 1}`;
    }
    // reset count to 1 when dose number exceeded the dose count
    return `Booster ${doseIndx - maxDose + 1}`;
  };

  const isBooster = (doseIndx) => {
    if (isChangingVaccine) {
      if (doseIndx < newMaxDose) {
        return false;
      }
      return true;
    }
    if (doseIndx < maxDose) {
      return false;
    }
    return true;
  };

  // Styles
  const useStyles = makeStyles(VaccineRecordDialogStyles);
  const classes = useStyles();

  const dispatch = useDispatch();

  const handleCancel = () => {
    // Reset The Form and the State Data
    setnewVaxRecords(_.cloneDeep(vaxRecords));
    closeDialog();
  };

  const validateInputs = (vaxRecords) => {
    let error = false;
    let prevAdministeredDate = null;
    const validatedRecords = vaxRecords.map((vaxItem, idx) => {
      if (!vaxItem.performer || vaxItem.performer[0].actor.display === '') {
        vaxItem.errors.issuer = '*Issuer name is required';
        error = true;
      }

      if (!vaxItem.isVaccineTypeUpdated) {
        vaxItem.errors.vaccination = '*Vaccine is required';
        error = true;
      }
      if (vaxItem.administeredDate === '') {
        vaxItem.errors.administeredDate = '*Date Administered is required';
        error = true;
        prevAdministeredDate = null;
      } else if (prevAdministeredDate && !utils.isAfter(vaxItem.administeredDate, prevAdministeredDate)) {
        vaxItem.errors.administeredDate = `*Date of ${getDoseHeaderStr(idx)} must be later than previous dosage`;
        error = true;
      }

      prevAdministeredDate = utils.formatDisplayDate(vaxItem.administeredDate);
      return vaxItem;
    });

    return [validatedRecords, error];
  };

  const handleSubmitAttestation = () => {
    if (dialogMode === 'VIEW') {
      const [validatedRecords, error] = validateInputs(newVaxRecords);
      const uploadedFile =
        newVaxImages.length === 0 || newVaxImages.find((newVaxImage) => newVaxImage.file || newVaxImage.value);

      if (error || !uploadedFile) {
        setnewVaxRecords(validatedRecords);
        if (!uploadedFile) {
          setVaxImageError('Please upload your vaccination');
        }
        return;
      }

      dispatch(
        updateAttestation(
          vaxDetails.id,
          vaxRecordType,
          newVaxImages,
          JSON.stringify(newVaxRecords),
          safeEMRId,
          fromUser,
          tenant
        )
      );

      closeDialog();
      return;
    }

    if (dialogMode === 'EDIT') {
      const [validatedRecords, error] = validateInputs(newVaxRecords);
      const uploadedFile =
        newVaxImages.length === 0 || newVaxImages.find((newVaxImage) => newVaxImage.file || newVaxImage.value);

      if (error || !uploadedFile) {
        setnewVaxRecords(validatedRecords);
        if (!uploadedFile) {
          setVaxImageError('Please upload your vaccination');
        }
        return;
      }

      dispatch(
        approveAttestation(
          vaxDetails.id,
          vaxRecordType,
          newVaxImages,
          JSON.stringify(newVaxRecords),
          safeEMRId,
          fromUser,
          tenant
        )
      );

      closeDialog();
      return;
    }

    if (dialogMode === 'CREATE') {
      const [validatedRecords, error] = validateInputs(newVaxRecords);
      const uploadedFile = newVaxImages.find((newVaxImage) => newVaxImage.file || newVaxImage.value);

      if (error || !uploadedFile) {
        setnewVaxRecords(validatedRecords);
        if (!uploadedFile) {
          setVaxImageError('Please upload your vaccination');
        }
        return;
      }
      const mutateVaxRecords = newVaxRecords.map((vaxRecord) => ({
        vaccinationCode: vaxRecord.vaccinationCode,
        status: vaxRecord.status,
        manufacturer: vaxRecord.manufacturer,
        administeredDate: utils.formatDisplayDate(vaxRecord.administeredDate),
        doseNumber: vaxRecord.doseNumber,
        performer: vaxRecord.performer,
        operationType: vaxRecord.operationType,
      }));

      dispatch(
        createAttestation(
          vaxRecordType,
          newVaxImages.filter((newVaxImage) => newVaxImage.file).map((newVaxImage) => newVaxImage.file),
          JSON.stringify(mutateVaxRecords),
          safeEMRId
        )
      );

      closeDialog();
    }
  };

  const handleSubmitExemption = () => {
    const uploadedFile = newVaxImages.find((newVaxImage) => newVaxImage.file || newVaxImage.value);

    if (!uploadedFile) {
      if (!uploadedFile) {
        setVaxImageError('Please upload your vaccination');
      }
      return;
    }

    dispatch(
      approveExemption(
        vaxDetails.id,
        safeEMRId,
        fromUser,
        tenant,
        vaxRecordType,
        newVaxImages,
        JSON.stringify(newVaxRecords)
      )
    );

    closeDialog();
  };

  const vaxMenuItems = masterCode.map((m) => (
    <MenuItem value={m.cvX_Code} key={m.cvX_Code}>
      {m.product_Name}
    </MenuItem>
  ));

  const getVaccineName = (cvX_Code) => {
    return masterCode.find((item) => item.cvX_Code === cvX_Code)?.product_Name || '';
  };

  const updateFieldChanged = (idx) => (e) => {
    // IF NEW VACCINATION VALUE IS SELECTED CREATE A NEW FORM DATA
    if (
      idx === 0 &&
      e.target.name === 'vaccination' &&
      (newVaxRecords[0].vaccinationCode[0].codeValue !== e.target.value || !newVaxRecords[0].isVaccineTypeUpdated)
    ) {
      const oldVaxRecords = _.cloneDeep(newVaxRecords.filter((item) => !item.isBooster));
      const oldBoosters = _.cloneDeep(newVaxRecords.filter((item) => item.isBooster));
      const newVaxCvxCode = e.target.value;
      const newMaxDose = getMaxDoseWithCvxCode(newVaxCvxCode, masterCode);

      if (newMaxDose !== oldVaxRecords.length) {
        /* GENERATE NEW RECORDS */
        const masterCodeTrxDetails = getVaxMasterCodeDetails(newVaxCvxCode, masterCode);
        // clear current Vax Form Record
        const newerVaxRecords = [];

        for (let vaxItemNo = 0; vaxItemNo < newMaxDose; vaxItemNo++) {
          const newVaxItem = generateNewRecord(masterCodeTrxDetails, vaxItemNo + 1, false);
          newVaxItem.performer = oldVaxRecords[vaxItemNo]?.performer || newVaxItem.performer;
          newVaxItem.administeredDate = oldVaxRecords[vaxItemNo]?.administeredDate || '';
          newerVaxRecords.push(newVaxItem);
        }

        newerVaxRecords[0].isVaccineTypeUpdated = true;
        const newMfxCode = getVaxMfxCodeWithCvxCode(newVaxCvxCode, masterCode);

        /* set new Mfx Code using the new Cvx Code Value */
        setIsChangingVaccine(true);
        setNewMfxCode(() => newMfxCode);
        setNewMaxDose(() => newMaxDose);
        setnewVaxRecords(newerVaxRecords.concat(oldBoosters));
        return;
      }
    }

    const updatedVaxRecord = newVaxRecords.map((item, itemNo) => {
      // CHANGE THE SHAPE OF THE DATA
      const newItem = {
        id: item.id,
        vaccinationCode: item.vaccinationCode,
        status: item.status,
        manufacturer: item.manufacturer,
        administeredDate: item.administeredDate,
        doseNumber: item.doseNumber,
        performer: _.cloneDeep(item.performer),
        operationType: item.id === '' ? 'Add' : 'Edit',
        errors: {
          issuer: false,
          administeredDate: false,
        },
        isVaccineTypeUpdated: item.isVaccineTypeUpdated,
        isBooster: item.isBooster,
      };

      // MODIFY THE ITEM WITH THE SAME ID FROM FORM CONTROL UI
      // if (item.id === idx) {
      if (itemNo === idx) {
        const inputField = e.target.name;

        switch (inputField) {
          case 'issuer':
            newItem.performer[0].actor.display = e.target.value.toString();

            return newItem;

          case 'vaccination':
            // eslint-disable-next-line no-case-declarations
            const newVaxDetails = getVaxMasterCodeDetails(e.target.value);

            newItem.manufacturer = { ...newVaxDetails.manufacturer };
            newItem.vaccinationCode = newVaxDetails.vaccinationCode.map((newVaxCode, idx) => ({
              // Because Master Code does not have "vaccinationCode.system" attribute
              // so we're getting it from the original item
              ...newItem.vaccinationCode[idx],
              ...newVaxCode,
            }));
            newItem.isVaccineTypeUpdated = true;
            return newItem;

          case 'administeredDate':
            newItem.administeredDate = e.target.value ? utils.formatDisplayDate(e.target.value) : e.target.value;
            return newItem;

          default:
            break;
        }
      }
      // DO NOTHING WHEN ITEM IS NOT BEING MODIFIED
      return item;
    });

    setnewVaxRecords(updatedVaxRecord);
  };

  const handleAddBooster = () => {
    const masterCodeDetails = getVaxMasterCodeDetails(defaultVaxCode, masterCode);

    const nextDoseNumber = newVaxRecords.length + 1;

    const newItem = generateNewRecord(masterCodeDetails, nextDoseNumber, false);
    newItem.isBooster = true;

    /* set new Mfx Code using the new Cvx Code Value */

    maxDose = getMaxDose(newVaxRecords[0].manufacturer.code, masterCode);
    setNewMaxDose(maxDose);

    const updatedRecords = [...newVaxRecords, newItem];
    setnewVaxRecords(updatedRecords);
  };

  const handleDeleteBooster = (vaxItemIndx) => () => {
    const doseNumToDelete = vaxItemIndx + 1;
    const updatedVaxRecords = newVaxRecords.filter((v) => v.doseNumber !== doseNumToDelete);

    // Starting from the position of the removed item.
    // Decrease the DoseNumber for the succeesding Vax Items
    for (let nextItemNo = vaxItemIndx; nextItemNo < updatedVaxRecords.length; nextItemNo++) {
      updatedVaxRecords[nextItemNo].doseNumber -= updatedVaxRecords[nextItemNo].doseNumber;
    }

    const updatedDoseNumber = fixDoseNumber(updatedVaxRecords);

    setnewVaxRecords(updatedDoseNumber);
  };

  const getVaxDialogTitle = (mode) => {
    if (vaxRecordType === VAX_TYPES.ATTESTATION) {
      return ATTESTATION_TITLE[mode] ?? '';
    }

    if (vaxRecordType === VAX_TYPES.EXEMPTION) {
      return mode === 'VIEW' ? 'Exemption Record' : 'Approve Exemption Record';
    }

    if (vaxRecordType === VAX_TYPES.SAFEPASS || vaxRecordType === VAX_TYPES.ONEUPHEALTH) {
      return 'Vaccination Record';
    }
  };

  const handleImageUpload = (x, idx, addEmpty) => {
    setVaxImageError('');
    if (x.target.file || addEmpty) {
      addNewVaxImage((prevVaxImages) => {
        const images = [
          ...prevVaxImages.slice(0, idx),
          {
            url: x.target.value,
            file: x.target.file,
          },
          ...prevVaxImages.slice(idx + 1, prevVaxImages.length),
        ];
        return images;
      });
    } else {
      addNewVaxImage((prevVaxImages) => {
        const images = [...prevVaxImages.slice(0, idx), ...prevVaxImages.slice(idx + 1, prevVaxImages.length)];
        if (images.length === 0 || (images.length < 3 && images[images.length - 1]?.url)) {
          images.push({
            url: null,
            file: null,
          });
        }

        return images;
      });
    }
  };

  /**
   * SHP-4643 - Fix. showing different image when thumbnail is clicked
   * TODO: Improve fix probably using useReducers instead of useState
   */
  // state for Image LightBox
  const [isImgOpen1, setIsImgOpen1] = useState(false);
  const [isImgOpen2, setIsImgOpen2] = useState(false);
  const [isImgOpen3, setIsImgOpen3] = useState(false);

  const setImgOpenFnArr = [() => setIsImgOpen1(true), () => setIsImgOpen2(true), () => setIsImgOpen3(true)];

  const setImgCloseFnArr = [() => setIsImgOpen1(false), () => setIsImgOpen2(false), () => setIsImgOpen3(false)];

  const isImgOpenStateArr = [isImgOpen1, isImgOpen2, isImgOpen3];

  const isAnyImgOpenFn = () => {
    return isImgOpen1 || isImgOpen2 || isImgOpen3;
  };

  const getImgOpenFn = (imgIdx) => {
    return setImgOpenFnArr[imgIdx];
  };

  const getImgCloseFn = (imgIdx) => {
    return setImgCloseFnArr[imgIdx];
  };

  const getIsImgOpenState = (imgIdx) => {
    return isImgOpenStateArr[imgIdx];
  };

  const getActionLength = () => {
    if (vaxRecordType === VAX_TYPES.ATTESTATION) {
      return dialogMode == 'CREATE' || newVaxImages.length > 0 ? 3 : 7;
    }
    return 5;
  };

  const getIssuerValue = (v) => {
    let issuer = null;

    if (v.performer) {
      issuer = v.performer[0]?.actor?.display;
    }

    if (vaxRecordType === VAX_TYPES.SAFEPASS && (!issuer || issuer.toUpperCase() === 'N/A')) {
      issuer = vaxDetails.performer;
      if (!issuer || issuer.toUpperCase() === 'N/A') {
        issuer = vaxDetails.originalIssuer?.name || 'N/A';
      }
    }

    return issuer;
  };

  const getDialogWidth = () => {
    if (
      vaxRecordType === VAX_TYPES.ATTESTATION ||
      vaxRecordType === VAX_TYPES.SAFEPASS ||
      vaxRecordType === VAX_TYPES.ONEUPHEALTH
    ) {
      return dialogMode == 'CREATE' || newVaxImages.length > 0 ? 955 : 440;
    }
    return 600;
  };

  const getMaxImageCount = () => {
    if (vaxRecordType === VAX_TYPES.EXEMPTION) {
      return 1;
    }

    return 3;
  };

  const imagePreview = (newVaxImage, idx) => {
    if (vaxRecordImageLoading && vaxRecordImageLoading[idx]) {
      return (
        <Box className={classes.imageContainerAttestation} key={idx}>
          <VaccinationRecordImageSkeletonLoading key={idx} />
        </Box>
      );
    }

    if (newVaxImage.url || newVaxImage.id) {
      return (
        <Box className={classes.imageContainerAttestation} key={idx}>
          <Box
            className={classes.vaxImg}
            component="img"
            alt={'Vaccination Record'}
            src={newVaxImage.url || `data:image/png;base64,${newVaxImage.value}`}
          />
          <DeleteIcon
            className={classes.previewContainerIcon}
            style={{ top: '10px' }}
            onClick={() => handleImageUpload({ target: { value: null, file: null } }, idx)}
          />
          <ZoomInIcon className={classes.previewContainerIcon} style={{ bottom: '10px' }} onClick={getImgOpenFn(idx)} />
          {getIsImgOpenState(idx) && (
            <Lightbox
              mainSrc={newVaxImage.url || `data:image/png;base64,${newVaxImage.value}`}
              onCloseRequest={getImgCloseFn(idx)}
              reactModalStyle={{
                overlay: {
                  zIndex: 20000,
                },
              }}
              imagePadding={50}
              onImageLoadError={() => setTimeout(getImgCloseFn(idx), 1000)}
            />
          )}
        </Box>
      );
    }

    return (
      <Grid item xs={12} md={12} className={classes.previewBodyContainer} key={idx}>
        <Box
          sx={{
            display: 'flex',
            paddingBottom: '15px',
            marginBottom: '15px',
          }}
        >
          <Previews
            field={{
              type: 'imageCrop',
              name: 'newImage',
              width: 9,
              required: true,
              // acceptedFiles: ['image/png', 'image/jpg', 'image/jpeg'],
              // imageWidth: 1440,
              // imageHeight: 1440,
            }}
            disableCropper
            value={newVaxImage.url}
            handleChange={(x) => handleImageUpload(x, idx)}
            disabled={false}
            hidePreviewPlaceHolder
            fileTypeCaption={'Allowed file types: PNG, JPG'}
            uploadCaption={'Or drag files here. Add up to three photos'}
            skipUploadToResource
          />
        </Box>
      </Grid>
    );
  };

  return (
    <Dialog
      title={getVaxDialogTitle(dialogMode)}
      onClose={handleCancel}
      width={getDialogWidth()}
      className={classes.dialogBox}
      height={670}
      style={{ zIndex: isAnyImgOpenFn() ? '1' : '10001' }}
    >
      {/* Body Content Wrapper */}
      <div
        style={{
          height: '100%',
          width: '100%',
        }}
      >
        {/* Flexbox Body Container */}
        <Box className={classes.flexContainer}>
          {/* Left Pane Wrapper */}
          {vaxRecordType === VAX_TYPES.ATTESTATION ||
          vaxRecordType === VAX_TYPES.SAFEPASS ||
          vaxRecordType === VAX_TYPES.ONEUPHEALTH ? (
            <Box
              className={classes.leftPaneAttestation}
              style={dialogMode !== 'CREATE' && !(newVaxImages.length > 0) ? { flex: '0 0 100%' } : {}}
            >
              {/* Form Wrapper */}
              <Box className={classes.formWrapper}>
                <Box
                  component="div"
                  className={newVaxRecords.length === 1 ? classes.dialogBodyDetailsOneDose : classes.dialogBodyDetails}
                >
                  {dialogMode === 'CREATE' && (
                    <Box component="div">
                      <Typography variant="caption" className={classes.subHeaderText}>
                        Please enter the member&apos;s vaccination info as it appears on their vaccination card:
                      </Typography>
                    </Box>
                  )}
                  {vaxRecordType === VAX_TYPES.SAFEPASS && (
                    <TextField
                      className={classes.textInputDisabled}
                      style={{ marginTop: 0, marginBottom: 20, paddingTop: 5 }}
                      id="memberName"
                      name="memberName"
                      color="secondary"
                      type="text"
                      label="Member Name"
                      margin="dense"
                      disabled
                      fullWidth
                      value={
                        vaxDetails.name
                          ? `${vaxDetails.name.givenName} ${vaxDetails.name.familyName}`
                          : `${userDetails.firstName} ${userDetails.lastName}`
                      }
                      inputProps={{
                        style: {
                          textOverflow: 'ellipsis',
                        },
                      }}
                    />
                  )}
                  {newVaxRecords.map((v, idx) => (
                    <Box component="div" className={classes.dialogDoseListItem} key={idx}>
                      <Box component="div" className={classes.doseHeader}>
                        <Typography variant="h6" className={classes.doseHeaderText}>
                          {getDoseHeaderStr(idx)}
                        </Typography>
                        <Box component="div" className={classes.buttonDelete}>
                          {isBooster(idx) &&
                            (vaxRecordType !== VAX_TYPES.SAFEPASS || vaxRecordType !== VAX_TYPES.ONEUPHEALTH) && (
                              <IconButton aria-label="delete" onClick={handleDeleteBooster(idx)}>
                                <DeleteIcon />
                              </IconButton>
                            )}
                        </Box>
                      </Box>
                      {/* Issuer */}
                      <TextField
                        className={
                          vaxRecordType === VAX_TYPES.SAFEPASS || vaxRecordType === VAX_TYPES.ONEUPHEALTH
                            ? classes.textInputDisabled
                            : classes.issuerInput
                        }
                        error={!!v.errors.issuer}
                        helperText={v.errors.issuer}
                        id="issuer"
                        name="issuer"
                        color="secondary"
                        type="text"
                        label="Issuer"
                        margin="normal"
                        fullWidth
                        value={getIssuerValue(v)}
                        onChange={updateFieldChanged(idx)}
                        disabled={vaxRecordType === VAX_TYPES.SAFEPASS || vaxRecordType === VAX_TYPES.ONEUPHEALTH}
                      />
                      {/* Vaccine */}
                      {vaxRecordType === VAX_TYPES.SAFEPASS || vaxRecordType === VAX_TYPES.ONEUPHEALTH ? (
                        <TextField
                          className={classes.textInputDisabled}
                          id="vaccination"
                          name="vaccination"
                          color="secondary"
                          type="text"
                          label="Vaccine"
                          margin="normal"
                          disabled
                          fullWidth
                          value={getVaccineName(v.vaccinationCode[0].codeValue)}
                          inputProps={{
                            style: {
                              textOverflow: 'ellipsis',
                            },
                          }}
                        />
                      ) : (
                        <FormControl style={{ width: '100%' }} className={classes.vaccineSelect}>
                          <InputLabel error={!!v.errors.vaccination} id="vax-label">
                            Vaccine
                          </InputLabel>
                          <Select
                            error={!!v.errors.vaccination}
                            labelId="vax-label"
                            id="vaccination"
                            name="vaccination"
                            value={v.isVaccineTypeUpdated ? v.vaccinationCode[0].codeValue : ''}
                            onChange={updateFieldChanged(idx)}
                            disabled={vaxRecordType === VAX_TYPES.SAFEPASS || vaxRecordType === VAX_TYPES.ONEUPHEALTH}
                            MenuProps={{
                              style: {
                                zIndex: '20005',
                              },
                            }}
                          >
                            {vaxMenuItems}
                          </Select>
                          <FormHelperText error={!!v.errors.vaccination}>{v.errors.vaccination}</FormHelperText>
                        </FormControl>
                      )}
                      {/* Date Administered */}
                      <TextField
                        className={
                          vaxRecordType === VAX_TYPES.SAFEPASS || vaxRecordType === VAX_TYPES.ONEUPHEALTH
                            ? classes.textInputDisabled
                            : classes.dateInput
                        }
                        error={!!v.errors.administeredDate}
                        helperText={v.errors.administeredDate}
                        id="administeredDate"
                        name="administeredDate"
                        color="secondary"
                        margin="normal"
                        fullWidth
                        type={
                          vaxRecordType === VAX_TYPES.SAFEPASS || vaxRecordType === VAX_TYPES.ONEUPHEALTH
                            ? 'test'
                            : 'date'
                        }
                        label="Date Administered"
                        value={
                          vaxRecordType === VAX_TYPES.SAFEPASS || vaxRecordType === VAX_TYPES.ONEUPHEALTH
                            ? utils.formatDisplayDate(v.administeredDate)
                            : utils.formatDateNoLocale(v.administeredDate)
                        }
                        onChange={updateFieldChanged(idx)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputformat="MM/dd/yyyy"
                        disabled={vaxRecordType === VAX_TYPES.SAFEPASS || vaxRecordType === VAX_TYPES.ONEUPHEALTH}
                        inputProps={{
                          ...(!(vaxRecordType === VAX_TYPES.SAFEPASS || vaxRecordType === VAX_TYPES.ONEUPHEALTH) && {
                            max: new Date().toISOString().split('T')[0],
                          }),
                        }}
                      />
                    </Box>
                  ))}
                  {/* TODO: Enable once B.E. is ready for API   */}
                  {vaxRecordType === VAX_TYPES.ATTESTATION && (
                    <Button
                      style={{
                        textTransform: 'capitalize',
                        color: '#003C71',
                      }}
                      startIcon={<AddCircleOutlineIcon />}
                      onClick={handleAddBooster}
                    >
                      Add Booster
                    </Button>
                  )}
                </Box>

                {/* End - Form Wrapper */}
              </Box>
              {/* End - Left Pane Wrapper */}
            </Box>
          ) : null}

          <Grid className={classes.previewContainer}>
            {newVaxImages.map((newVaxImage, idx) => {
              return imagePreview(newVaxImage, idx);
            })}
            {newVaxImages.length < getMaxImageCount() && (
              <Grid item xs={12} md={12} style={{ textAlign: 'center' }}>
                <Button
                  variant="outlined"
                  onClick={() => handleImageUpload({ target: { value: null, file: null } }, newVaxImages.length, true)}
                  style={{ color: '#2E5266', borderColor: '#2E5266' }}
                >
                  Add Another Photo
                </Button>
              </Grid>
            )}
            {vaxImageError && (
              <Grid item xs={12} md={12} style={{ textAlign: 'center' }}>
                <Typography color="error" variant="caption">
                  {vaxImageError}
                </Typography>
              </Grid>
            )}
          </Grid>
          {/* Flexbox Body Container */}
        </Box>
        {/* End - Body Conten Wrapper  */}
      </div>

      {/* DIALOG ACTION BAR */}
      {(vaxRecordType === VAX_TYPES.ATTESTATION || vaxRecordType === VAX_TYPES.EXEMPTION || dialogMode === 'EDIT') && (
        <DialogActionsBar>
          <Grid container spacing={0}>
            <Grid item xs={getActionLength()}>
              <Button
                className={
                  dialogMode === 'VIEW' || dialogMode === 'CREATE' ? classes.buttonSave : classes.buttonApprove
                }
                onClick={vaxRecordType === VAX_TYPES.ATTESTATION ? handleSubmitAttestation : handleSubmitExemption}
              >
                {SAVE_CONFIRM_TEXT[dialogMode] ?? ' '}
              </Button>
              <Button
                onClick={handleCancel}
                variant="outlined"
                className={
                  dialogMode === 'VIEW' || dialogMode === 'CREATE' ? classes.buttonOutlined : classes.buttonRedCancel
                }
              >
                Cancel
              </Button>
            </Grid>
            <Grid
              item
              xs={
                vaxRecordType === VAX_TYPES.ATTESTATION && (dialogMode == 'CREATE' || newVaxImages.length > 0) ? 9 : 7
              }
            >
              {dialogMode === 'EDIT' ? (
                <Typography component="div" className={classes.actionDialogText}>
                  &quot;Approval for this {vaxRecordType === VAX_TYPES.ATTESTATION ? 'attestation' : 'exemption'} is
                  needed. If the image is illegible, please contact the member and inform them that they need to retake
                  the photo and submit again.&quot;
                </Typography>
              ) : null}
            </Grid>
          </Grid>
        </DialogActionsBar>
      )}
      {/* DIALOG ACTION FOR SAFEPASS */}
      {/* (vaxRecordType === 'SafePass' || dialogMode === 'AVAILABLE') && (
        <DialogActionsBar>
          <Grid container spacing={0}>
            <Grid item xs={vaxRecordType === 'SafePass' ? 3 : 5}>
              <Button className={classes.buttonOutlined} onClick={handleCancel} variant="outlined">
                Close
              </Button>
            </Grid>
          </Grid>
        </DialogActionsBar>
      ) */}
    </Dialog>
  );
}

export default VaccineRecordDialog;
