import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { useState, useEffect } from 'react';

import styles from '../../assets/Questionnaire.module.css';
import utils from '../utils/utils';

const useStyles = makeStyles(() => ({
  yesNoWrapper: {
    width: '100%',
  },
  titleStyles: {
    style1: {
      fontWeight: 'bold',
      fontSize: '24px!important',
      color: 'rgb(16, 6, 159)',
      lineHeight: '30px',
      marginTop: 32,
      marginBottom: 32,
    },
    style2: {},
    style3: {},
    hidden: {
      display: 'none',
    },
  },
  descriptionStyles: {
    style1: {
      opacity: 0.5,
      marginBottom: 10,
      fontSize: 18,
    },
    style2: {},
    style3: {},
    hidden: {
      display: 'none',
    },
  },
  selectFieldsWrapper: {
    width: '100%',
  },
}));

// ui reflection of file view
function BooleanField(props) {
  const classes = useStyles();
  const { field, value, handleChangeWithParams, selectedLanguage } = props;
  const [currentValue, setCurrentValue] = useState(value || '');

  useEffect(() => {
    if (value !== currentValue) {
      setCurrentValue(value);
    }
    // eslint-disable-next-line
  }, [field.name, value]);

  const answers = {
    yes: {
      eng: 'Yes',
      spa: 'Sí',
      zho: '是',
      rus: 'да',
      fre: 'Oui',
    },
    no: {
      eng: 'No',
      spa: 'No',
      zho: '不是',
      rus: 'Нет',
      fre: 'Non',
    },
  };

  const handleSelect = (evt) => {
    setCurrentValue(evt.target.value);
    handleChangeWithParams(field.name, evt.target.value);
  };

  const fieldTitle =
    selectedLanguage && field.name && field.name.translations
      ? field.name.translations[selectedLanguage]
      : field.label || field.name;
  const questionDescription =
    selectedLanguage && field.description && field.description.translations
      ? field.description.translations[selectedLanguage]
      : field.description;

  return (
    <Grid item xs={12}>
      <Grid item xs={12} style={{ width: '100%' }}>
        {fieldTitle && (
          <Typography className={field.nameStyle ? styles[`title--${field.nameStyle}`] : styles['title--style1']}>
            {field.questionPreview ? utils.renderHTMLTags(fieldTitle) : fieldTitle}
            {field.required && '*'}
          </Typography>
        )}
        {field.image && (
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <img
              src={field.image}
              alt={fieldTitle}
              style={{
                display: 'block',
                marginLeft: 'auto',
                marginRight: 'auto',
                width: '90%',
              }}
            />
          </Grid>
        )}

        {questionDescription && (
          <Typography
            className={
              field.descriptionStyle ? styles[`description--${field.descriptionStyle}`] : styles['description--style1']
            }
          >
            {utils.renderHTMLTags(questionDescription)}
          </Typography>
        )}
      </Grid>

      <FormControl component="fieldset" className={classes.yesNoWrapper}>
        <RadioGroup
          aria-label="mc"
          value={currentValue}
          name={field.name && field.name.translations ? field.name.translations[selectedLanguage] : field.name}
          onChange={handleSelect}
        >
          <Grid className={`${styles.checkboxQuestionnaireItem}`}>
            <FormControlLabel
              value="Yes"
              control={
                <Radio
                  color="primary"
                  className={styles.checkboxQuestionnaireRadio}
                  disableRipple
                  style={{
                    color: '#577193',
                  }}
                />
              }
              label={answers.yes[selectedLanguage] || 'Yes'}
            />
          </Grid>
          <Grid className={`${styles.checkboxQuestionnaireItem}`}>
            <FormControlLabel
              value="No"
              control={
                <Radio
                  color="primary"
                  className={styles.checkboxQuestionnaireRadio}
                  disableRipple
                  style={{
                    color: '#577193',
                  }}
                />
              }
              label={answers.no[selectedLanguage] || 'No'}
            />
          </Grid>
        </RadioGroup>
      </FormControl>
    </Grid>
  );
}

export default BooleanField;
