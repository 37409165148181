import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { ResponsivePie } from '@nivo/pie';
import React from 'react';

import utils from '../Utils/utils';

export function PieChart({ data }) {
  function pieChartTooltip(data) {
    const { color, label, value } = data;
    return (
      <div
        style={{
          backgroundColor: '#ffffff',
          display: 'flex',
          alignItems: 'center',
          padding: 5,
          marginRight: 7,
          color: '#606060',
          fontSize: '0.8125rem',
          fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        }}
      >
        <div style={{ backgroundColor: color, width: 15, height: 15 }} />
        <div style={{ paddingLeft: 15 }}>
          <span style={{ fontWeight: 'bold' }}>{label}:</span> {value} outcomes
        </div>
      </div>
    );
  }

  function getPercentLabel(d) {
    const total = data.reduce((acc, item) => acc + item.value, 0);

    const label = (d.value * 100) / total;

    return `${label.toFixed(0)}%`;
  }

  return (
    <Box display="flex" flexDirection="column" flexGrow={1}>
      <Box
        style={{
          height: 280,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {data && data.length ? (
          <ResponsivePie
            data={data}
            margin={{
              top: 30,
              right: 45,
              bottom: 30,
              left: 45,
            }}
            innerRadius={0.75}
            padAngle={0.7}
            cornerRadius={1}
            colors={{ scheme: 'set2' }}
            borderWidth={1}
            borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
            // eslint-disable-next-line react/jsx-no-bind
            radialLabel={getPercentLabel}
            radialLabelsSkipAngle={10}
            radialLabelsTextXOffset={5}
            radialLabelsTextColor="#333333"
            radialLabelsLinkOffset={0}
            radialLabelsLinkDiagonalLength={10}
            radialLabelsLinkHorizontalLength={10}
            radialLabelsLinkStrokeWidth={1}
            radialLabelsLinkColor={{ from: 'color' }}
            slicesLabelsSkipAngle={10}
            slicesLabelsTextColor="#fffffff"
            sliceLabel={(e) => `${utils.numberWithCommas(e.value)}`}
            animate
            motionStiffness={90}
            motionDamping={15}
            enableRadialLabels
            // eslint-disable-next-line react/jsx-no-bind
            tooltip={pieChartTooltip}
          />
        ) : (
          <Box>
            <Typography variant="h6" align="center">
              No data
            </Typography>
          </Box>
        )}
      </Box>
      <Grid style={{ paddingLeft: 15, paddingTop: 20 }} container spacing={0}>
        {data.map((item) => (
          <Grid item key={item.id} xs={6}>
            <Box display="flex" style={{ paddingBottom: 5 }}>
              <Box style={{ width: 15, height: 15, background: item.color }} />
              <Typography variant="body2" style={{ fontSize: 14, paddingLeft: 10 }}>
                {item.label}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default PieChart;
