import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ErrorIcon from '@material-ui/icons/Error';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectCurrentTenant } from '../../Shared/selectors';
import { setCurrentTenant } from '../../Shared/sharedSlice';
import AppKendoTableWrapper from '../../Shared/Table/KendoTableWrapper';
import { transform, columns, initialSorting, tableButtons } from './helpers/tenantListMapper';
import { selectTenantsList, selectTenantsLoading, selectTenantsError } from './selectors';
import { getTenants } from './tenantsSlice';

function ContentList() {
  const tenants = useSelector(selectTenantsList);
  const loading = useSelector(selectTenantsLoading);
  const error = useSelector(selectTenantsError);
  const currentTenant = useSelector(selectCurrentTenant);
  const navigate = useNavigate();

  const handleRowClick = (e) => {
    dispatch(setCurrentTenant(e));
    navigate(`/${e.id}/settings/configuration`);
  };

  const sorting = initialSorting;
  const buttons = tableButtons(currentTenant);
  const cols = columns;

  const dispatch = useDispatch();

  useEffect(() => {
    // Initial load
    dispatch(getTenants(0, 10, sorting.field, sorting.dir.toUpperCase()));
  }, [sorting]);

  // handles all table updates from table wrapper
  const handleTableUpdate = (page, pageSize, sort, direction) => {
    dispatch(getTenants(page, pageSize, sort, direction.toUpperCase()));
  };

  if (error) {
    return (
      <Grid container spacing={2} justifyContent="center" alignContent="center">
        <Grid item>
          <ErrorIcon color="primary" />
        </Grid>
        <Grid item>
          <Typography align="center" variant="caption" style={{ width: '100%' }} color="primary">
            {error}
          </Typography>
        </Grid>
      </Grid>
    );
  }
  return (
    <AppKendoTableWrapper
      data={transform(currentTenant.id, tenants)}
      initialSort={sorting.field}
      initialDir={sorting.dir}
      showButtons
      showSearchbar={false}
      tableButtons={buttons}
      columns={cols}
      currentTenant={currentTenant}
      onRowClick={handleRowClick}
      loading={loading}
      onTableUpdate={handleTableUpdate}
    />
  );
}

export default ContentList;
