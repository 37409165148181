/* eslint-disable no-sequences */
/* eslint-disable no-unused-expressions */
import { languageOptions } from '../../QuestionBuilder/helpers/languageOptions';

export const autoAnswerTypes = [
  {
    value: null,
    label: 'None',
    type: 'none',
  },
  {
    value: 'age',
    label: 'Age',
    type: 'date',
  },
  {
    value: 'allergy',
    label: 'Allergy',
    type: 'yes-no',
  },
  {
    value: 'has-condition',
    label: 'Condition',
    type: 'yes-no',
  },
  {
    value: 'medication',
    label: 'Medication',
    type: 'yes-no',
  },
  {
    value: 'vaccination-last-date',
    label: 'Vaccination Date',
    type: 'date',
  },
  {
    value: 'vaccination-number',
    label: 'Vaccination Number',
    type: 'numeric',
  },
  {
    value: 'test-status-covid',
    label: 'Covid test status',
    type: 'multiple',
  },
  {
    value: 'test-result-covid',
    label: 'Covid test result',
    type: 'multiple',
  },
  {
    value: 'gender',
    label: 'Gender',
    type: 'multiple',
  },
  {
    value: 'now-between-dates',
    label: 'Now between dates',
    type: 'yes-no',
  },
];

const numericalOperations = [
  {
    value: 'GREATER_EQUAL',
    label: 'Greater or equal',
  },
  {
    value: 'GREATER',
    label: 'Greater',
  },
  {
    value: 'EQUAL',
    label: 'Equal',
  },
  {
    value: 'LESS_EQUAL',
    label: 'Less or equal',
  },
  {
    value: 'LESS',
    label: 'Less',
  },
];

const logicalOperations = [
  {
    value: 'CONTAINS',
    label: 'Contains',
  },
  {
    value: 'NOT_CONTAINS',
    label: 'Not contains',
  },
];

const yesNo = [
  {
    value: 'Yes',
    label: 'Yes',
  },
  {
    value: 'No',
    label: 'No',
  },
];

export const profileBuilder = () => [
  {
    type: 'group',
    label: 'Overview',
    name: 'OVERVIEW',
    fields: [
      {
        type: 'text',
        name: 'title',
        label: 'Title',
        required: true,
        width: 6,
      },
      {
        type: 'spacer',
      },
      {
        type: 'toggle',
        name: 'commentEnabled',
        label: 'Allow Comments for this Questionnaire',
        required: false,
        width: 6,
        labelPlacement: 'end',
      },
    ],
  },
];

export const assetsBuilder = () => [
  {
    type: 'group',
    label: 'IMAGE ASSETS',
    name: 'IMAGE ASSETS',
    fields: [],
  },
  {
    type: 'group',
    label: 'VIDEO ASSETS',
    name: 'VIDEO ASSETS',
    fields: [],
  },
];

export const jumpFields = (selectedQuestions, condition, options = []) => [
  {
    type: condition.type !== 'score' && condition.type !== 'variable' ? 'autocomplete' : 'hidden',
    name: 'questionId',
    label: 'If',
    required: true,
    width: 8,
    enum: selectedQuestions,
    labelField: 'label',
    valueField: 'value',
  },
  {
    type: condition.type === 'score' ? 'text' : 'hidden',
    name: 'labelText',
    label: 'If',
    width: 8,
    required: false,
    disabled: true,
  },
  {
    type: condition.type === 'variable' ? 'autocomplete' : 'hidden',
    name: 'variableName',
    label: 'Variable Name',
    width: 8,
    enum: (() => {
      if (Array.isArray(options)) {
        return options.map((o) => ({
          label: o.name,
          value: o.name,
        }));
      }
      return [];
    })(),
    required: false,
    disabled: false,
    labelField: 'label',
    valueField: 'value',
  },
  {
    type:
      condition.type === 'numeric' ||
      condition.type === 'date' ||
      condition.type === 'scale' ||
      condition.type === 'slider' ||
      condition.type === 'score'
        ? 'autocomplete'
        : 'hidden',
    name: 'operation',
    label: 'Operation',
    required: true,
    width: 3,
    enum: numericalOperations,
    labelField: 'label',
    valueField: 'value',
  },
  {
    type:
      condition.type === 'multiple' || condition.type === 'text' || condition.type === 'variable'
        ? 'autocomplete'
        : 'hidden',
    name: 'operation',
    label: 'Operation',
    required: true,
    width: 3,
    enum: logicalOperations,
    labelField: 'label',
    valueField: 'value',
  },
  {
    type: condition.type === 'date' ? 'text' : 'hidden',
    name: 'precision',
    label: 'Precision',
    required: true,
    width: 5,
    readOnly: true,
  },
  {
    type:
      condition.type !== 'numeric' &&
      condition.type !== 'date' &&
      condition.type !== 'multiple' &&
      condition.type !== 'scale' &&
      condition.type !== 'slider' &&
      condition.type !== 'yes-no' &&
      condition.type !== 'score'
        ? 'text'
        : 'hidden',
    name: condition.type !== 'variable' ? 'expectedAnswers' : 'expectedText',
    label: 'Answer',
    required: true,
    width: 9,
    labelPlacement: 'end',
    default: false,
  },
  {
    type:
      condition.type === 'numeric' ||
      condition.type === 'scale' ||
      condition.type === 'slider' ||
      condition.type === 'date'
        ? 'number'
        : 'hidden',
    name: 'expectedAnswers',
    label: 'Answer',
    required: true,
    width: 9,
    labelPlacement: 'end',
    default: false,
  },
  {
    type: condition.type === 'score' ? 'number' : 'hidden',
    name: 'score',
    label: 'Answer',
    required: true,
    width: 9,
    labelPlacement: 'end',
    default: false,
  },
  {
    type: condition.type === 'multiple' || condition.type === 'yes-no' ? 'autocomplete' : 'hidden',
    name: 'expectedAnswers',
    label: 'Answer',
    required: true,
    width: 9,
    enum: (() => {
      if (condition.type === 'yes-no') {
        return yesNo;
      }
      if (Array.isArray(options)) {
        return options.map((k) => ({
          value: k.value,
          label: k.value,
        }));
      }
      return [];
    })(),
    labelField: 'label',
    valueField: 'value',
  },
];

export const jumpTypeField = (questionType, isNonConditional) => {
  let fields = [];
  isNonConditional && isNonConditional === true
    ? (fields = ['Non-Conditional', 'Variable'])
    : (fields = ['Conditional', 'Non-Conditional', 'Variable']);

  if (questionType && questionType === 'multiple') {
    fields.push('Score');
  }

  return [
    {
      type: 'dropdown',
      name: 'jumpType',
      width: 5,
      label: 'Jump type',
      options: fields,
    },
  ];
};

export const nextQuestionId = (questions) => [
  {
    type: 'autocomplete',
    name: 'nextQuestionId',
    label: 'Then jump to',
    required: true,
    width: 12,
    enum: questions,
    labelField: 'label',
    valueField: 'value',
  },
];

export const nodeLinks = (questions) => [
  {
    type: 'dynamicList',
    name: 'links',
    label: 'Other Navigation Links',
    required: false,
    width: 12,
    buttonType: 'text',
    buttonText: 'Add link for alternate navigation',
    fields: [
      {
        type: 'text',
        name: 'shortName',
        label: 'Administrative short name',
        required: true,
        width: 12,
      },
      {
        type: 'text',
        name: 'linkName',
        label: 'Text to Display',
        required: true,
        width: 12,
      },
      {
        type: 'autocomplete',
        name: 'nodeId',
        label: 'Link destination',
        required: true,
        width: 12,
        enum: questions,
        labelField: 'label',
        valueField: 'value',
      },
    ],
  },
];

export const defaultQuestionId = (questions, isConditional) => [
  {
    type: 'autocomplete',
    name: 'defaultNextQuestionId',
    label: isConditional ? 'In all other cases jump to' : 'In all cases jump to',
    required: true,
    width: 12,
    enum: questions,
    labelField: 'label',
    valueField: 'value',
    initialValue: null,
  },
];

export const moreThanRule = (questions) => [
  {
    type: 'number',
    name: 'size',
    label: 'Size',
    required: true,
    width: 6,
    labelPlacement: 'end',
    default: false,
  },
  {
    type: 'autocomplete',
    name: 'operation',
    label: 'Operation',
    required: true,
    width: 3,
    enum: numericalOperations,
    labelField: 'label',
    valueField: 'value',
  },
  {
    type: 'autocomplete',
    name: 'nextQuestionId',
    label: 'Then jump to',
    required: true,
    width: 12,
    enum: questions,
    labelField: 'label',
    valueField: 'value',
  },
];

export const nodeNameField = [
  {
    type: 'text',
    name: 'name',
    label: 'Node name',
    required: false,
    width: 12,
  },
];

export const priorityField = (disabledItems) => [
  {
    type: 'number',
    name: 'priority',
    label: 'Priority',
    required: true,
    width: 12,
    labelPlacement: 'end',
    default: false,
    min: 1,
    disabledItems,
  },
];

export const tagsField = [
  {
    type: 'multiselect',
    name: 'tags',
    label: 'Tags',
    required: false,
    width: 4,
    allowCustom: true,
  },
];

// Deconstruct objects from the API into formData format
export const deconstructJson = (res) => {
  // Function to deconstruct properties which contain a translations object
  const translateObject = (parent, lineItems, languageSource) => {
    // Get languages in use
    let selectedLanguages;
    if (parent.name && parent.name.translations) {
      selectedLanguages = Object.keys(parent.name.translations);
      parent.languageChoices = languageOptions.filter((lang) => selectedLanguages.includes(lang.split(' = ')[0]));
    }

    const workingLanguages = languageSource || parent.languageChoices;
    const languageCodes = workingLanguages && workingLanguages.map((lang) => lang.split(' = ')[0]);

    lineItems.forEach((item) => {
      if (languageCodes) {
        languageCodes.forEach((lang) => {
          parent[`${item}-${lang}`] = parent[item] && parent[item].translations && parent[item].translations[lang];
          parent[`${item}Valid-${lang}`] =
            parent[item] && parent[item].validatedLanguages && parent[item].validatedLanguages.includes(lang);
        });
      }
      // Assign default to English entry
      // Type check added to check for non-object 'options'
      if (typeof parent === 'object') {
        parent[item] = parent[item] && parent[item].translations ? parent[item].translations.eng : parent[item];
      }
    });
    return parent;
  };

  let formObject = JSON.parse(JSON.stringify(res));
  if (formObject.nodes) {
    // Deconstruction of full questionnaire response
    formObject = translateObject(formObject, ['title']);
    formObject.questionList = formObject.questionList.map((question) => {
      question = translateObject(question, ['name', 'description', 'help']);
      question.options =
        question.options &&
        question.options.map(
          (option) => (
            translateObject(option, ['value'], question.languageChoices),
            translateObject(option, ['instructionText'], question.languageChoices)
          )
        );
      question.fields =
        question.fields &&
        question.fields.map((field) => {
          const translatedObject = translateObject(field, ['name'], question.languageChoices);
          // Assign name value to fieldName to avoid conflicts in form
          question.languageChoices.forEach((lang) => {
            const languageCode = lang.split(' = ')[0];
            translatedObject[`fieldName-${languageCode}`] = translatedObject[`name-${languageCode}`];
            translatedObject['fieldName-eng'] = translatedObject.name;
          });
          return translatedObject;
        });
      return question;
    });
    formObject.nodes = formObject.nodes.map((node) => {
      node.links = node.links.map((link) => {
        link = translateObject(link, ['name']);
        link.linkName = link.name;
        return link;
      });
      if (node.question) {
        node.question = translateObject(node.question, ['name', 'description', 'help']);
        node.question.options =
          node.question.options &&
          node.question.options.map((option) => translateObject(option, ['value'], node.question.languageChoices));
        node.question.fields =
          node.question.fields &&
          node.question.fields.map((field) => {
            const translatedObject = translateObject(field, ['name'], node.question.languageChoices);
            // Assign name value to fieldName to avoid conflicts in form
            node.question.languageChoices.forEach((lang) => {
              const languageCode = lang.split(' = ')[0];
              translatedObject[`fieldName-${languageCode}`] = translatedObject[`name-${languageCode}`];
              translatedObject['fieldName-eng'] = translatedObject.name;
            });
            return translatedObject;
          });
      }
      node.outcome = node.outcome && translateObject(node.outcome, ['name', 'title', 'description']);

      if (node.outcome) {
        node.outcome.medicationList =
          node.outcome.medicationList &&
          node.outcome.medicationList.map((medication) => {
            medication.medicationName = medication.name;
            return medication;
          });
      }
      return node;
    });
  } else {
    // Deconstruction of new question or outcome added to questionnaire
    formObject = translateObject(formObject, ['name', 'description', 'help', 'title']);
    // Options line will need additions to the lineItems array to support all outcome options
    formObject.options =
      formObject.options &&
      formObject.options.map((option) => translateObject(option, ['value'], formObject.languageChoices));
    formObject.fields =
      formObject.fields &&
      formObject.fields.map((field) => {
        const translatedObject = translateObject(field, ['name'], formObject.languageChoices);
        // Assign name value to fieldName to avoid conflicts in form
        formObject.languageChoices.forEach((lang) => {
          const languageCode = lang.split(' = ')[0];
          translatedObject[`fieldName-${languageCode}`] = translatedObject[`name-${languageCode}`];
          translatedObject['fieldName-eng'] = translatedObject.name;
        });
        return translatedObject;
      });
  }
  return formObject;
};
