import { createSlice } from '@reduxjs/toolkit';

import api from '../../../api';
import { handleError } from '../../Shared/Content/APIUtils';
import { showNotification } from '../../Notifications/notificationSlice';

const initialState = {
  ssoUrl: null,
  dosespotLoading: null,
};

export const dosespotSlice = createSlice({
  name: 'dosespot',
  initialState,
  reducers: {
    setDosespotLoading: (state) => ({
      ...state,
      dosespotLoading: true,
    }),

    setSSOUrl: (state, { payload }) => ({
      ...state,
      ssoUrl: payload,
      ePrescribeLoading: false,
    }),

    cleanup: () => ({
      ssoUrl: null,
      dosespotLoading: null,
    }),
  },
});

export const { setSSOUrl, setDosespotLoading, cleanup } = dosespotSlice.actions;

export const fetchSSOUrl = (patientId) => async (dispatch) => {
  dispatch(setDosespotLoading());
  const [result, error] = await api.getDosespotSSOUrl(patientId);
  if (result) {
    dispatch(setSSOUrl(result?.data));
  }
  if (error && error.message) {
    dispatch(handleError(error));
  }
};

export const syncWithDosespot = (patientId) => async (dispatch) => {
  dispatch(setDosespotLoading());
  const [result, error] = await api.syncWithDosespot(patientId);
  if (result) {
    dispatch(showNotification(result));
  }
  if (error && error.message) {
    dispatch(handleError(error));
  }
}

export default dosespotSlice.reducer;
