import { Box, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import WarningIcon from '@material-ui/icons/ReportProblemOutlined';
import React, { useEffect, useRef, useState } from 'react';

export function VerificationCode(props) {
  const { onSubmit, isSubmitting, error, codeLength = 6, resetError, divideEvery = 3, checkErrors = true } = props;
  const classes = useStyles({ divideEvery });

  const [values, setValues] = useState([]);
  const inputContainerRef = useRef(null);

  const refs = useRef([...new Array(codeLength)]);

  useEffect(() => {
    checkValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  useEffect(() => {
    if (isSubmitting || values.length !== codeLength) return;
    refs.current = [...refs.current].map((i) => {
      i.value = '';
      return i;
    });
    setValues([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting]);

  const checkValues = async () => {
    if (checkErrors) {
      const codeIsFull = values.filter((v) => Number.isInteger(v)).length === codeLength;
      if (!codeIsFull || isSubmitting || error) return;
    }

    const code = values.join('');
    onSubmit(code);
  };

  const handleInputValue = (_v, index) => {
    const value = !Number.isNaN(_v) ? Number(_v.toString().slice(0, 1)) : '';
    setValues((prev) => {
      const updatedValues = [...prev];
      updatedValues[index] = value;
      return updatedValues;
    });
    refs.current[index].value = value;

    if (Number.isInteger(value) && !Number.isNaN(value) && index + 1 <= refs.current.length - 1) {
      refs.current[index + 1].focus();
      refs.current[index + 1].setSelectionRange(0, 0);
      resetError();
    }
  };

  const handleChange = (e, index) => {
    if (e.nativeEvent.inputType === 'insertFromPaste') return;

    const _v = Number.parseInt(e.target.value, 10);
    handleInputValue(_v, index);
  };

  const onKeyDown = async (e, index) => {
    const currentValue = values[index];
    if (e.keyCode !== 8 && e.keycode !== 46) return;

    resetError();

    if (currentValue || index - 1 < 0) return;

    refs.current[index - 1].focus();
    values.splice(index, 1);
  };

  const onPaste = (e) => {
    const code = e.clipboardData
      .getData('Text')
      .replaceAll(/[^+\d]/g, '')
      .slice(0, codeLength)
      .split('');

    code.forEach((value, index) => handleInputValue(Number(value), index));
  };

  const renderErrorMessage = () => {
    if (!error) return null;

    return (
      <Box className={classes.errorContainer} maxWidth={inputContainerRef.current?.clientWidth || '100%'}>
        <WarningIcon viewBox="0 0 200 200" style={{ fill: '#CE365C', fontSize: 25 }} />
        <Typography className={classes.errorText}>{error || 'That code was not valid. Please try again.'}</Typography>
      </Box>
    );
  };

  return (
    <Box className={classes.contentWrapper}>
      <form>
        <Box className={classes.input}>
          <div className={classes.inputContainer} ref={inputContainerRef}>
            {refs.current.map((item, index) => {
              const inputClass = `input-${index + 1}`;
              return (
                <Box key={inputClass} display="flex" alignItems="center">
                  {divideEvery && !(index % divideEvery) && index !== 0 ? <div className={classes.divider} /> : null}
                  <TextField
                    className={classes.textfield}
                    inputRef={(ref) => {
                      refs.current[index] = ref;
                    }}
                    value={refs.current[index]?.value || ''}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      inputMode: 'numeric',
                    }}
                    autoFocus={index === 0}
                    onChange={(e) => handleChange(e, index)}
                    onPaste={onPaste}
                    onKeyDown={(e) => onKeyDown(e, index)}
                    variant="outlined"
                    disabled={isSubmitting}
                  />
                </Box>
              );
            })}
          </div>
          {renderErrorMessage()}
        </Box>
      </form>
    </Box>
  );
}

const useStyles = makeStyles(() => ({
  textfield: {
    '&.MuiTextField-root': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#C4C4C4',
        borderRadius: 0,
      },
      '& .MuiInputBase-root': {
        height: 94,
        width: 79,
        color: 'black',
      },
      '& .MuiOutlinedInput-input': {
        padding: '18.5px 14px',
      },
    },
  },
  contentWrapper: {
    maxWidth: '500px',
    width: '100%',
    padding: '0 15px',
    backgroundColor: '#fff',
    borderRadius: '8px',
    textAlign: 'center',
  },
  input: {
    display: 'flex',
    flexDirection: 'column',

    '& input': {
      textAlign: 'center',
      fontSize: 36,
      fontWeight: 600,
    },
  },
  errorContainer: {
    display: 'flex',
    width: '100%',
    alignSelf: 'center',
    padding: 18,
    boxSizing: 'border-box',
    backgroundColor: '#FAEBEE',
    marginTop: 16,
    borderRadius: 4,
    border: '1px solid #E8A3B3',
    justifyContent: 'center',
    gap: 8,
    alignItems: 'center',
  },
  errorText: {
    textAlign: 'center',
    color: '#505358',
    fontSize: 14,
  },
  divider: {
    height: 1,
    margin: '0 5px',
    width: 8,
    backgroundColor: '#C4C4C4',
  },
  inputContainer: ({ divideEvery }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 'fit-content',
    alignSelf: 'center',

    '& div:first-child': {
      '& .MuiTextField-root': {
        '& .MuiOutlinedInput-root': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderBottomLeftRadius: 8,
            borderTopLeftRadius: 8,
          },
        },
      },
    },
    '& div:last-child': {
      '& .MuiTextField-root': {
        '& .MuiOutlinedInput-root': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderBottomRightRadius: 8,
            borderTopRightRadius: 8,
          },
        },
      },
    },
    [`& div:nth-child(${divideEvery}n+1)`]: {
      '& .MuiTextField-root': {
        '& .MuiOutlinedInput-root': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderBottomLeftRadius: 8,
            borderTopLeftRadius: 8,
          },
        },
      },
    },
    [`& div:nth-child(${divideEvery}n)`]: {
      '& .MuiTextField-root': {
        '& .MuiOutlinedInput-root': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderBottomRightRadius: 8,
            borderTopRightRadius: 8,
          },
        },
      },
    },
  }),
}));
