import { Box, Grid, useMediaQuery } from '@material-ui/core';
import React from 'react';
import ContentLoader from 'react-content-loader';

export function QuestionBuilderSkeletonLoader() {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down(960));

  return isMobile ? <MobileLoader /> : <DesktopLoader />;
}

function DesktopLoader() {
  return (
    <Box display="flex" gridGap="32px">
      <PaperSkeletonWrapper>
        <QuestionSettings />
      </PaperSkeletonWrapper>
      <QuestionPreview />
    </Box>
  );
}

function MobileLoader() {
  return (
    <Box display="flex" gridGap="32px" flexDirection="column" width="100%">
      <PaperSkeletonWrapper>
        <QuestionSettings />
      </PaperSkeletonWrapper>
    </Box>
  );
}

function QuestionSettings() {
  return (
    <ContentLoader
      speed={0.8}
      viewBox="0 0 100% 100%"
      width="100%"
      height="834px"
      backgroundColor="#ecebeb"
      foregroundColor="#f3f3f3"
    >
      {/* Details Title */}
      <rect x="32" y="32" rx="15" ry="15" width="30%" height="35" />
      <rect x="32" y="92" rx="15" ry="15" width="calc(100% - 64px)" height="25" />
      <rect x="32" y="132" rx="15" ry="15" width="calc(100% - 64px)" height="25" />
      <rect x="32" y="172" rx="15" ry="15" width="calc(100% - 64px)" height="25" />
      <rect x="32" y="212" rx="15" ry="15" width="calc(100% - 64px)" height="25" />
      <rect x="32" y="252" rx="15" ry="15" width="calc(100% - 64px)" height="470" />
      <rect x="16" y="762" rx="15" ry="15" width="90" height="40" />
      <rect x="calc(100% - 108px)" y="762" rx="15" ry="15" width="90" height="40" />
    </ContentLoader>
  );
}

function QuestionPreview() {
  return (
    <Grid xs={6}>
      <ContentLoader
        speed={0.8}
        viewBox="0 0 100% 100%"
        width="100%"
        height="834px"
        backgroundColor="#ecebeb"
        foregroundColor="#f3f3f3"
      >
        <rect x="0" y="0" rx="15" ry="15" width="100%" height="100%" />
      </ContentLoader>
    </Grid>
  );
}

function PaperSkeletonWrapper({ children }) {
  return (
    <Grid item xs={12} style={{ borderRadius: 30, backgroundColor: '#fff' }}>
      {children}
    </Grid>
  );
}
