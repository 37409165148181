// component and styling imports

// material-ui
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import MenuRounded from '@material-ui/icons/Menu';
import React, { useState, useEffect } from 'react';

import NavBarStyles from '../../assets/jss/components/NavBarStyles';
import SidebarList from '../Sidebar/SidebarList';

function MobileMenu(props) {
  const [open, handleOpen] = useState(false);
  const { classes, pages, logout, currentTenant, handleChangeTenant, allTenantsLoading, allTenants } = props;

  const matches = useMediaQuery('(min-width: 960px)');

  useEffect(() => {
    if (matches) {
      handleOpen(false);
    }
  }, [matches]);

  return (
    <div>
      <IconButton color="inherit" className={classes.link} onClick={() => handleOpen(true)}>
        <MenuRounded fontSize="large" />
      </IconButton>
      <SwipeableDrawer
        anchor="right"
        open={open || false}
        onOpen={() => handleOpen(true)}
        onClose={() => handleOpen(false)}
        className={classes.sectionMobile}
      >
        <SidebarList
          type="mobile"
          onMouseLeave={handleOpen}
          currentTenant={currentTenant}
          handleChangeTenant={handleChangeTenant}
          allTenantsLoading={allTenantsLoading}
          allTenants={allTenants}
          logout={logout}
          pages={pages}
        />
      </SwipeableDrawer>
    </div>
  );
}

export default withStyles(NavBarStyles)(MobileMenu);
