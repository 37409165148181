import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { initForm } from '../../Shared/Forms/formSlice';
import { selectCurrentTenant } from '../../Shared/selectors';
import { deconstructJson } from '../QuestionnaireBuilder/helpers/formHelper';
import { getContentById } from '../virtualConsultSlice';

import ReviewerAnnotationWrapper from './ReviewerAnnotationWrapper';
import ReviewerQuestionnaire from './ReviewerQuestionnaire';
import ReviewerSettingsWrapper from './ReviewerSettings/ReviewerSettingsWrapper';
import { resetReviewerState } from './reviewerSlice';
import ScreenshotWrapper from './ScreenshotReviewer/ScreenshotWrapper';

function ReviewerWrapper() {
  const classes = useStyles();

  const currentTenant = useSelector(selectCurrentTenant);

  const [setQuestionnaireData] = useState({});
  const [expandOptionForm, setExpandOptionForm] = useState('');
  const [questionnaireId, setQuestionnaireId] = useState(
    // "b7a95dd0-d39e-4f7f-8490-26d90cacb2f5"
    ''
  );
  const [screenshotUrl, setScreenshotUrl] = useState(
    // "https://www.hihonor.com/content/dam/honor/global/blog/2018/how-to-personalize-your-home-screen-on-honor-phone/blog-img1-how-to-personalize-your-home-screen-on-honor-phone-20181031.jpg"
    ''
  );
  const [editingAnnotation, setEditingAnnotation] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetReviewerState());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (editingAnnotation?._id) {
      setScreenshotUrl(editingAnnotation.screenshotUrl);
    }
    // eslint-disable-next-line
  }, [editingAnnotation]);

  useEffect(() => {
    if (questionnaireId) {
      dispatch(getContentById(currentTenant.id, 'questionnaires', questionnaireId)).then((res) => {
        const questionnaireForm = deconstructJson(res);
        setQuestionnaireData(questionnaireForm);
        dispatch(initForm(questionnaireForm));
      });
    }

    // if questionnaireId id was changed, then reset annotation form
    setEditingAnnotation(null);
    setScreenshotUrl('');
    // eslint-disable-next-line
  }, [questionnaireId]);

  return (
    <Paper
      elevation={0}
      style={{
        padding: 20,
        borderRadius: '20px',
      }}
    >
      {/* {dialogOpen && ( */}
      {/*  <AddSlackUserNameDialog */}
      {/*    dialogOpen={dialogOpen} */}
      {/*    setDialogOpen={setDialogOpen} */}
      {/*  /> */}
      {/* )} */}
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Grid className={classes.column}>
            {/* Settings */}
            <ReviewerSettingsWrapper
              currentTenant={currentTenant.id}
              setQuestionnaireId={setQuestionnaireId}
              expandOptionForm={expandOptionForm}
            />
          </Grid>
        </Grid>

        <Grid item xs={12} md={4} spacing={3}>
          <Grid container justifyContent="center" direction="column" className={classes.column}>
            {screenshotUrl ? (
              <ScreenshotWrapper
                questionnaireId={questionnaireId}
                screenshotUrl={screenshotUrl}
                setScreenshotUrl={setScreenshotUrl}
                editingAnnotation={editingAnnotation}
                setEditingAnnotation={setEditingAnnotation}
                setExpandOptionForm={setExpandOptionForm}
              />
            ) : (
              <ReviewerQuestionnaire
                currentTenant={currentTenant.id}
                questionnaireId={questionnaireId}
                setScreenshotUrl={setScreenshotUrl}
              />
            )}
          </Grid>
        </Grid>

        <Grid item xs={12} md={4} spacing={3}>
          <Grid container justifyContent="center" className={classes.column}>
            {/* Annotations */}
            <ReviewerAnnotationWrapper
              currentTenant={currentTenant.id}
              questionnaireId={questionnaireId}
              editingAnnotation={editingAnnotation}
              setEditingAnnotation={setEditingAnnotation}
              setScreenshotUrl={setScreenshotUrl}
            />
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}

const useStyles = makeStyles(() => ({
  column: {
    border: '1px solid #cccccc',
    padding: 10,
    borderRadius: 5,
  },
}));

export default ReviewerWrapper;
