import {
  allergyCategoryEnums,
  allergySeverityEnums,
  clinicalStatusEnums,
  conditionClinicalStatusEnums,
  conditionVerificationStatusEnums,
  immunizationStatusEnums,
  medicationStatusEnums,
  observationCategoryEnums,
  observationStatusEnums,
  verificationStatusEnums,
} from './mapping';

export const allergyFormMap = (dialogMode) => [
  {
    type: 'typeahead',
    label: 'Allergy Name',
    width: 12,
    readOnly: dialogMode === 'Edit',
    required: true,
    name: 'allergyName',
    labelField: 'allergyDescription',
    searchType: 'allergy',
    searchClinical: false,
    setFields: [
      {
        field: 'codeValue',
        targetField: 'allergyCode',
      },
    ],
  },
  {
    type: 'dropdown',
    name: 'allergyCategory',
    label: 'Allergy Category',
    width: 6,
    required: true,
    enum: allergyCategoryEnums,
  },
  {
    type: 'text',
    name: 'allergyCode',
    label: 'Allergy Code',
    width: 6,
    readOnly: true,
    required: true,
  },
  {
    type: 'typeahead',
    label: 'Reaction',
    width: 12,
    name: 'reactionName',
    labelField: 'term',
    required: true,
    searchType: 'snomed',
    searchClinical: false,
    setFields: [
      {
        field: 'conceptID',
        targetField: 'reactionCode',
      },
    ],
  },
  {
    type: 'text',
    name: 'reactionCode',
    label: 'Reaction Code',
    width: 4,
    required: true,
    readOnly: true,
  },
  {
    type: 'dropdown',
    name: 'reactionSeverity',
    label: 'Severity',
    width: 4,
    required: true,
    enum: allergySeverityEnums,
  },
  {
    type: 'date',
    name: 'onsetDate',
    label: 'Onset Date',
    required: true,
    width: 4,
  },
  {
    type: 'text',
    name: 'symptomsValue',
    label: 'Symptoms Value',
    width: 4,
    required: false,
  },
  {
    type: 'text',
    name: 'symptomsCode',
    label: 'Symptoms Code',
    width: 4,
    required: false,
  },
  {
    type: 'dropdown',
    name: 'clinicalStatus',
    label: 'Clinical Status',
    width: 4,
    required: true,
    enum: clinicalStatusEnums,
  },
  {
    type: 'dropdown',
    name: 'verificationStatus',
    label: 'Verification Status',
    width: 4,
    required: true,
    enum: verificationStatusEnums,
  },
];

export const conditionFormMap = (dialogMode, resolutionDate) => [
  {
    type: 'typeahead',
    name: 'conditionName',
    label: 'Condition Name',
    required: true,
    readOnly: dialogMode === 'Edit',
    width: 12,
    labelField: 'longDescription',
    searchType: 'icd',
    searchClinical: true,
    setFields: [
      {
        field: 'code',
        targetField: 'conditionCode',
      },
    ],
  },
  {
    type: 'text',
    name: 'conditionCode',
    label: 'Condition Code',
    width: 6,
    required: true,
    readOnly: true,
  },
  {
    type: 'text',
    name: 'severity',
    label: 'Severity',
    width: 6,
  },
  {
    type: 'text',
    name: 'bodySiteName',
    label: 'Body Site',
    width: 4,
  },
  {
    type: 'dropdown',
    name: 'clinicalStatus',
    label: 'Clinical Status',
    width: 4,
    required: true,
    enum: conditionClinicalStatusEnums,
  },
  {
    type: 'dropdown',
    name: 'verificationStatus',
    label: 'Verification Status',
    width: 4,
    required: true,
    enum: conditionVerificationStatusEnums,
  },
  {
    type: 'date',
    name: 'onsetDate',
    label: 'OnsetDate',
    required: false,
    width: 4,
    max: new Date(),
  },
  {
    type: 'date',
    name: 'dateRecorded',
    label: 'Date Recorded',
    required: false,
    width: 4,
    max: new Date(),
  },
  {
    type: 'date',
    name: 'resolutionDate',
    label: 'Resolution Date',
    required: false,
    disabled: resolutionDate,
    width: 4,
    max: new Date(),
  },
];

export const immunizationDialogFormMap = () => [
  {
    type: 'text',
    name: 'immunizationCode',
    label: 'Immunization Code',
    width: 4,
    readOnly: true,
    required: true,
  },
  {
    type: 'date',
    name: 'administeredDate',
    label: 'Administered Date',
    required: true,
    width: 4,
  },
  {
    type: 'date',
    name: 'expiryDate',
    label: 'Expiry Date',
    required: false,
    width: 4,
  },
  {
    type: 'text',
    name: 'explanation',
    label: 'Explanation',
    width: 4,
    required: false,
  },
  {
    type: 'text',
    name: 'lotNumber',
    label: 'Lot Number',
    width: 4,
    required: false,
  },
  {
    type: 'text',
    name: 'notes',
    label: 'Notes',
    width: 4,
    required: false,
  },
  {
    type: 'dropdown',
    name: 'status',
    lable: 'Status',
    width: 4,
    required: true,
    enum: immunizationStatusEnums,
  },
  {
    type: 'text',
    name: 'dose',
    label: 'Dose',
    width: 4,
    required: false,
  },
  {
    type: 'text',
    name: 'doseUnit',
    label: ' Dose Unit',
    width: 4,
    required: false,
  },
  {
    type: 'toggle',
    name: 'wasGiven',
    width: 4,
    label: 'Was Given ?',
    required: false,
    labelPlacement: 'end',
  },
  {
    type: 'toggle',
    name: 'isPrimary',
    label: 'Primary Source ?',
    width: 4,
    required: false,
    labelPlacement: 'end',
  },
];

export const medicationDialogFormMap = (dialogMode, strengthEnums, defaultItem) => [
  {
    type: 'typeahead',
    label: 'Medication Name',
    width: 6,
    readOnly: dialogMode === 'Edit',
    required: true,
    name: 'medicationName',
    labelField: 'medicationName',
    searchType: 'rxnorm',
    setFields: [
      {
        field: 'strengthNCode',
        targetField: 'medicationCode',
      },
    ],
    searchClinical: true,
  },
  {
    type: 'dropdown',
    name: 'strength',
    label: 'Strength',
    width: 6,
    required: true,
    enum: strengthEnums,
    labelField: 'text',
    valueField: 'id',
    defaultItem,
  },
  {
    type: 'text',
    name: 'medicationCode',
    label: 'Medication Code',
    width: 4,
    readOnly: true,
    required: true,
  },

  {
    type: 'text',
    name: 'form',
    label: 'Form',
    width: 4,
    required: false,
  },
  {
    type: 'text',
    name: 'action',
    label: 'Action',
    width: 4,
    required: false,
  },
  {
    type: 'text',
    name: 'dose',
    label: 'Dose',
    required: true,
    width: 4,
  },
  {
    type: 'text',
    name: 'doseUnit',
    label: 'Dose Unit',
    width: 4,
    required: false,
  },
  {
    type: 'text',
    name: 'doseTiming',
    label: 'Dose Timing',
    width: 4,
    required: false,
  },
  {
    type: 'text',
    name: 'routeName',
    label: 'Route Name',
    width: 4,
    required: false,
  },
  {
    type: 'date',
    name: 'doseStartTime',
    label: 'Dose Start Time',
    required: false,
    width: 4,
  },
  {
    type: 'date',
    name: 'doseEndTime',
    label: 'Dose End Time',
    required: false,
    width: 4,
  },
  {
    type: 'date',
    name: 'effectiveStartDate',
    label: 'Effective Start Date',
    required: false,
    width: 4,
  },
  {
    type: 'date',
    name: 'effectiveEndDate',
    label: 'Effective End Date',
    required: false,
    width: 4,
  },
  {
    type: 'number',
    name: 'quantityValue',
    label: 'Quantity',
    required: true,
    width: 4,
    min: 1,
  },
  {
    type: 'dropdown',
    name: 'status',
    label: 'Status',
    width: 4,
    required: true,
    enum: medicationStatusEnums,
  },
];

export const observationDialogFormMap = () => [
  {
    type: 'dropdown',
    name: 'observationCategory',
    label: 'Category',
    width: 4,
    required: true,
    enum: observationStatusEnums,
  },
  {
    type: 'dropdown',
    name: 'status',
    label: 'Status',
    width: 4,
    required: true,
    enum: observationCategoryEnums,
  },
  {
    type: 'date',
    name: 'fromDate',
    label: 'From Date',
    required: false,
    width: 4,
    max: new Date(),
  },
  {
    type: 'date',
    name: 'toDate',
    label: 'To Date',
    required: false,
    width: 4,
    max: new Date(),
  },
];
