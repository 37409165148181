/* eslint-disable react/no-array-index-key */
import { makeStyles } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { useTheme } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React, { useState, useEffect } from 'react';
import ProfileViewStyles from '../../assets/jss/components/ProfileViewStyles';
import Allergy from './Allergy';
import CCDViewer from './CCDViewer';
import Condition from './Condition';
import Dosespot from './Dosespot';
import EmrConnect from './EmrConnect';
import EPrescribe from './EPrescribe';
import { clinicalTabsMapping, clinicalPaths } from './helpers/mapping';
import Immunization from './Immunization';
import Medication from './Medication';
import Prescription from './Prescription';
import Procedure from './Procedure';
import QuickConnect from './QuickConnect';
import Vital from './Vital';

function ClinicalInformation() {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  let pageComponent;

  useEffect(() => {
    const splitUrl = window.location.href.split('/');
    const page = splitUrl[splitUrl.length - 1];
    const xml = splitUrl[splitUrl.length - 2];
    if (page !== '') {
      const defaultTab = xml === 'ccd' ? clinicalPaths['ccd']?.value : clinicalPaths[page]?.value;
      setValue(defaultTab || 0);
    }
  }, []);

  const handleChange = (e, value) => {
    setValue(value);
    const path = Object.keys(clinicalPaths)[value - 1];

    const splitUrl = window.location.href.split('/');
    if (splitUrl[splitUrl.length - 1] === 'clinical') {
      if (value !== 0) {
        window.history.pushState(path, path, `${splitUrl.join('/')}/${path}`);
      }
    } else if (splitUrl[splitUrl.length - 1] === 'xml') {
      splitUrl.pop();
      splitUrl.pop();
      if (value === 0) {
        window.history.pushState(path, path, `${splitUrl.join('/')}`);
      } else {
        window.history.pushState(path, path, `${splitUrl.join('/')}/${path}`);
      }
    } else {
      splitUrl.pop();
      if (value === 0) {
        window.history.pushState(path, path, `${splitUrl.join('/')}`);
      } else {
        window.history.pushState(path, path, `${splitUrl.join('/')}/${path}`);
      }
    }
  };

  switch (value) {
    case 1:
      pageComponent = <Condition />;

      break;
    case 2:
      pageComponent = <Medication />;

      break;
    case 3:
      pageComponent = <Prescription />;

      break;
    case 4:
      pageComponent = <Immunization />;

      break;
    case 5:
      pageComponent = <Vital />;

      break;
    case 6:
      pageComponent = <Procedure />;

      break;
    case 7:
      pageComponent = <CCDViewer />;

      break;
    case 8:
      pageComponent = <QuickConnect />;

      break;
    case 9:
      pageComponent = <EmrConnect />;

      break;
    case 10:
      pageComponent = <EPrescribe />;
      break;
    case 11:
      pageComponent = <Dosespot />;
      break;
    default:
      pageComponent = <Allergy />;

      break;
  }

  return (
    <>
      {matches ? (
        <Tabs
          value={value}
          indicatorColor="primary"
          variant="fullWidth"
          textColor="primary"
          onChange={handleChange}
          aria-label="disabled tabs example"
        >
          {clinicalTabsMapping.map((tab, index) => (
            <Tab
              label={tab.label}
              id={tab.id}
              style={{ fontSize: '12px', marginBottom: '-10px' }}
              className={value === tab.value ? classes.activeLink : ''}
              key={index}
              value={tab.value}
            />
          ))}
        </Tabs>
      ) : (
        <FormControl variant="outlined" fullWidth>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={value}
            onChange={(e) => setValue(e.target.value)}
          >
            {clinicalTabsMapping.map((tab, index) => (
              <MenuItem value={tab.value} key={index}>
                {tab.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      <div style={{ marginTop: 25 }}>{pageComponent}</div>
    </>
  );
}

const useStyles = makeStyles(ProfileViewStyles);

export default ClinicalInformation;
