import clamp from 'clamp';
import React, { useRef, useEffect, useCallback } from 'react';
import { Rect as KonvaRectangle, Transformer } from 'react-konva';

import { LIMITS } from './constants';

const boundBoxCallbackForRectangle = (oldBox, newBox) => {
  // limit resize
  if (
    newBox.width < LIMITS.RECT.MIN ||
    newBox.height < LIMITS.RECT.MIN ||
    newBox.width > LIMITS.RECT.MAX ||
    newBox.height > LIMITS.RECT.MAX
  ) {
    return oldBox;
  }
  return newBox;
};

function Rectangle({ id, isSelected, setSelectedShapeId, transformRectangleShape, ...shapeProps }) {
  const shapeRef = useRef();
  const transformerRef = useRef();

  useEffect(() => {
    if (isSelected) {
      transformerRef.current.nodes([shapeRef.current]);
      transformerRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);

  const handleSelect = useCallback(
    (event) => {
      event.cancelBubble = true;

      setSelectedShapeId(id);
    },
    // eslint-disable-next-line
    [id]
  );

  const handleTransform = useCallback(() => {
    const node = shapeRef.current;
    // transformer is changing scale of the node
    // and NOT its width or height
    // but in the store we have only width and height
    // to match the data better we will reset scale on transform end
    const scaleX = node.scaleX();
    const scaleY = node.scaleY();

    // we will reset the scale back
    node.scaleX(1);
    node.scaleY(1);

    const shape = { ...shapeProps };

    if (shape) {
      shape.x = node.x();
      shape.y = node.y();

      shape.rotation = node.rotation();

      shape.width = clamp(
        // increase the width in order of the scale
        node.width() * scaleX,
        // should not be less than the minimum width
        LIMITS.RECT.MIN,
        // should not be more than the maximum width
        LIMITS.RECT.MAX
      );
      shape.height = clamp(node.height() * scaleY, LIMITS.RECT.MIN, LIMITS.RECT.MAX);
      transformRectangleShape(shape);
    }
  }, [shapeProps, transformRectangleShape]);

  return (
    <>
      <KonvaRectangle
        id={id}
        onClick={handleSelect}
        onTap={handleSelect}
        onDragStart={handleSelect}
        ref={shapeRef}
        {...shapeProps}
        draggable
        // onDragEnd={handleDrag}
        onTransformEnd={handleTransform}
        // dragBoundFunc={(pos) => {
        //   // keep dragging inside stage
        //   const newX = pos.x < 1 ? 1 : pos.x;
        //   const newY = pos.y < 1 ? 1 : pos.y;
        //   return {
        //     x: newX,
        //     y: newY,
        //   };
        // }}
      />
      {isSelected && (
        <Transformer
          anchorSize={10}
          borderDash={[6, 2]}
          ref={transformerRef}
          rotateEnabled={false}
          enabledAnchors={[
            'top-left',
            'top-center',
            'top-right',
            'middle-right',
            'middle-left',
            'bottom-right',
            'bottom-left',
            'bottom-center',
          ]}
          boundBoxFunc={boundBoxCallbackForRectangle}
        />
      )}
    </>
  );
}

export default Rectangle;
