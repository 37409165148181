import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
// import * as htmlToImage from 'html-to-image';
// import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';
import React, { useRef, useEffect } from 'react';
import { useScreenshot } from 'use-screenshot-hook';

import Questionnaire from '../vcb-package/Questionnaire';

// import { b64toBlob } from './helpers/helpers';

function ReviewerQuestionnaire(props) {
  const { currentTenant, questionnaireId, setScreenshotUrl } = props;

  // const [img, setImg] = useState('');

  const ref = useRef();

  const { image, takeScreenshot } = useScreenshot({
    ref,
  });

  useEffect(() => {
    (async () => {
      if (image) {
        // const data = image.replace(/^data:image\/\w+;base64,/, "");
        // const contentType = "image/png";
        // const blob = b64toBlob(data, contentType);
        // const blobUrl = URL.createObjectURL(blob);

        setScreenshotUrl(image);
      }
    })();
    // eslint-disable-next-line
  }, [image]);

  const makeScreenshot = async () => {
    takeScreenshot();
    // htmlToImage.toPng(ref.current).then(function (dataUrl) {
    //   setImg(dataUrl);
    // });
    // canvas.toDataURL("image/png");
  };

  return questionnaireId ? (
    <Grid container direction="column" alignItems="center">
      <Grid container justifyContent="center">
        <Button color="primary" variant="contained" onClick={makeScreenshot}>
          Annotate
        </Button>
      </Grid>

      <Grid style={{ width: 375 }} className="test" ref={ref}>
        <Questionnaire
          questionnaireId={questionnaireId}
          tenantId={currentTenant}
          isVcbAminConsole
          startFromStoppedQuestion
        />
      </Grid>
    </Grid>
  ) : (
    <div style={{ textAlign: 'center' }}>Questionnaire preview</div>
  );
}

export default ReviewerQuestionnaire;
