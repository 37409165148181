import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import PhoneEnabledIcon from '@material-ui/icons/PhoneEnabled';
import React from 'react';

import { labOrderJsonBuilder } from './helpers/encounterJsonBuilder';
import { labReport } from './helpers/mapping';
import NotesWrapper from './NotesWrapper';
import ReportWrapper from './ReportWrapper';
import TestItemWrapper from './TestItemWrapper';

function LabReportWrapper(props) {
  const { encounterData, diagnosticReportInfo } = props;
  const testList =
    diagnosticReportInfo?.observationDTOList && diagnosticReportInfo?.observationDTOList.length > 0
      ? labOrderJsonBuilder(diagnosticReportInfo.observationDTOList)
      : [];

  // const testList = getTestData();

  return (
    <>
      <Grid container justifyContent="center" spacing={1}>
        {labReport.map((report) => (
          <ReportWrapper report={report} data={encounterData} />
        ))}
      </Grid>

      {testList && testList.length ? (
        <>
          {testList.map((testItem) => (
            <TestItemWrapper data={encounterData} testData={testItem} />
          ))}
          <Grid container justifyContent="flex-end" style={{ marginTop: 30 }}>
            <Button variant="contained" color="primary">
              Completed
            </Button>
          </Grid>
        </>
      ) : // <Grid>
      //   <Typography variant="overline">No Result Received</Typography>
      // </Grid>
      null}

      <Grid container style={{ marginTop: 30, padding: '10px 0' }}>
        <Button variant="contained" color="primary" endIcon={<PhoneEnabledIcon />}>
          CALL Tajada Coley
        </Button>
      </Grid>

      <NotesWrapper data={encounterData} />
    </>
  );
}

export default LabReportWrapper;
