import { createSlice } from '@reduxjs/toolkit';

import api from '../../../api';
import { handleError } from '../../Shared/Content/APIUtils';

const initialState = {
  accessToken: null,
  accessTokenLoading: null,
};

export const accessTokenSlice = createSlice({
  name: 'accessToken',
  initialState,
  reducers: {
    setAccessTokenLoading: (state) => ({
      ...state,
      accessTokenLoading: true,
    }),
    setAccessToken: (state, { payload }) => ({
      ...state,
      accessToken: payload,
      accessTokenLoading: false,
    }),
  },
});

export const { setAccessToken, setAccessTokenLoading } = accessTokenSlice.actions;

export const getAccessToken = (safeEMRId, tenantId) => async (dispatch) => {
  dispatch(setAccessTokenLoading());

  const [result, error] = await api.getAccessTokenRequest(safeEMRId, tenantId);

  if (result) {
    dispatch(setAccessToken(result));
  }

  if (error && error.message) {
    dispatch(handleError(error));
  }
};

export default accessTokenSlice.reducer;
