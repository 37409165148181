export const userSegmentFormMap = (formData, labTestOrderables, usedOutcomeTypes, details, outcomes) => [
  {
    type: 'groupNoDropdown',
    label: 'USER SEGMENT DETAILS',
    name: 'USER SEGMENT DETAILS',
    fields: [
      {
        type: 'hidden',
        name: 'id',
        label: 'ID',
        required: false,
        width: 6,
      },
      {
        type: 'text',
        name: 'name',
        label: 'Name',
        required: true,
        width: 10,
        readOnly: details,
      },
    ],
  },
  {
    type: 'groupNoDropdown',
    label: 'TEST SETTINGS BY OUTCOME',
    name: 'TEST SETTINGS BY OUTCOME',
    fields: [
      {
        type: 'dynamicList',
        name: 'outcomeSettings',
        label: 'Outcome',
        required: true,
        width: 12,
        tooltip: 'Add Outcome Test',
        buttonType: 'text',
        buttonText: 'Add Outcome',
        buttonDisabled: formData && formData.name === 'Base User Segment',
        fields: [
          {
            type: 'autocomplete',
            name: 'outcomeKey',
            label: 'Outcome',
            required: true,
            width: 10,
            enum:
              outcomes &&
              Object.keys(outcomes.content).map((k) => ({
                value: outcomes.content[k].id,
                label: outcomes.content[k].title.translations.eng,
              })),
            labelField: 'label',
            valueField: 'value',
            disabledItems: usedOutcomeTypes,
            disabled: formData && formData.name === 'Base User Segment',
          },
          {
            type: 'conditional',
            checkField: 'outcomeKey',
            checkPopulated: true,
            targetType: 'imageCrop',
            name: 'customOutcomeImage',
            label: 'Custom Outcome Image',
            required: false,
            width: '10',
            disabled: formData && formData.name === 'Base User Segment',
          },
          {
            type: 'conditional',
            checkField: 'outcomeKey',
            checkPopulated: true,
            targetType: 'html',
            name: 'customOutcomeMessage',
            label: 'Custom Outcome Message',
            required: false,
            width: '10',
            // TODO: Commented for demo
            // disabled: formData && formData.name === "Base User Segment",
            disabled: false,
          },
          {
            type: 'conditional',
            checkField: 'outcomeKey',
            checkPopulated: true,
            targetType: 'autocomplete',
            name: 'test',
            label: 'Lab Test',
            required: true,
            width: 10,
            enum:
              (labTestOrderables &&
                labTestOrderables.map((t) => {
                  if (t.label === 'Same as Outcome') {
                    return {
                      label: 'Same as Outcome',
                      test: 'None',
                    };
                  }

                  if (t.vendorTestPanelId) {
                    return {
                      label: `${t.vendorName} / ${t.longName}`,
                      test: {
                        safeTestId: null,
                        vendorTestId: t.vendorTestPanelId,
                        vendorKey: t.vendorKey,
                        vendorTestName: t.testFormatName,
                        orderableTestId: t.labTestPanelId,
                      },
                    };
                  }
                  return {
                    label: `${t.vendorName} / ${t.longName}`,
                    test: {
                      safeTestId: t.safeTestId,
                      vendorTestId: t.vendorTestId,
                      vendorKey: t.vendorKey,
                      vendorTestName: t.testFormatName,
                      orderableTestId: t.labTestOrderableId,
                    },
                  };
                })) ||
              [],
            labelField: 'label',
            valueField: 'test',
            matchField: 'orderableTestId',
          },
          {
            type: 'conditional',
            checkField: 'test',
            checkPopulated: true,
            checkNotValue: 'none',
            targetType: 'number',
            name: 'retestIfNegative',
            label: 'Number of days till retest if negative',
            required: false,
            width: 10,
            min: 0,
          },
          {
            type: 'conditional',
            checkField: 'test',
            checkPopulated: true,
            checkNotValue: 'none',
            targetType: 'number',
            name: 'retestIfPositive',
            label: 'Number of days till retest if positive',
            required: false,
            width: 10,
            min: 0,
          },
        ],
      },
    ],
  },
];
