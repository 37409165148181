export const statusMapping = {
  Active: {
    label: 'Active',
    background: 'green',
    color: 'white',
  },
  Inactive: {
    label: 'Inactive',
    background: 'red',
    color: 'white',
  },
};

export const systemMessagesTabTypes = {
  EMAIL: 'email-safe',
  SMS: 'sms-push',
};

export const systemMessagesTypes = {
  [systemMessagesTabTypes.EMAIL]: 'html',
  [systemMessagesTabTypes.SMS]: 'text',
};
