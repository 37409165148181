/* eslint-disable react/no-array-index-key */
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import React from 'react';

import utils from '../../Shared/Utils/utils';

function TabDetailPanel(props) {
  const { dataItem, blacklistItems, property } = props;
  // dynamically create panel list items
  const createListItems = (asset) => {
    const entries = Object.entries(asset);
    return entries
      .filter((entry) => blacklistItems && !blacklistItems.includes(entry[0]))
      .reduce((accum, item, idx) => {
        if (idx % 2 === 0) {
          accum.push([item]);
        } else {
          accum[accum.length - 1].push(item);
        }

        return accum;
      }, [])
      .map((row, idx) => (
        <ListItem
          key={idx}
          style={{
            background: idx % 2 === 0 ? 'rgba(0,0,0,0.04)' : 'transparent',
          }}
        >
          <Grid container spacing={2}>
            {row.map((item, index) => (
              <Grid item xs={12} md={6} key={`item${index}`}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <ListItemText
                      primary={
                        <Typography variant="subtitle" style={{ fontWeight: 'bold' }}>
                          {(
                            item[0].charAt(0).toUpperCase() + item[0].slice(1).replace(/([a-z])([A-Z])/g, '$1 $2')
                          ).replace('Nb ', 'No. ')}
                        </Typography>
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    {item[0] === 'symptoms' || item[0] === 'additionalSymptoms' ? (
                      <ListItemText
                        primary={
                          <Typography variant="subtitle" style={{ wordWrap: 'break-word', color: '#65656c' }}>
                            {item[1] && utils.removeUnderscoresTitleCase(utils.distinctValues(item[1]).join(', '))}
                          </Typography>
                        }
                      />
                    ) : (
                      <ListItemText
                        primary={
                          <Typography variant="subtitle" style={{ wordWrap: 'break-word', color: '#65656c' }}>
                            {moment(item[1], moment.ISO_8601, true).isValid() ? (
                              utils.formatDateTime(item[1])
                            ) : (
                              <div>
                                {typeof item[1] === 'string' ? utils.removeUnderscoresTitleCase(item[1]) : item[1]}
                              </div>
                            )}
                          </Typography>
                        }
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </ListItem>
      ));
  };

  return (
    <section>
      <List>{createListItems(dataItem[property])}</List>
    </section>
  );
}

export default TabDetailPanel;
