import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import VisibilityOffOutlined from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlined from '@material-ui/icons/VisibilityOutlined';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { paths } from '../../constants';
import { selectAccountId, selectDefaultTenantId } from '../Auth/selectors';

import { resetPassword, sendResetPassEmail } from './adminSlice';
import { ValidationMessage } from './ValidationMessage';
import { resetPasSchema, passCodeSchema } from './validationSchema';

export function ResetPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [message, setMessage] = useState('');
  const [msgStatus, setMsgStatus] = useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const accountId = useSelector(selectAccountId);
  const defaultTenantId = useSelector(selectDefaultTenantId);
  const [showPassword, setShowPassword] = useState(true);
  const [showNextForm, setShowNextForm] = useState(false);
  const [username, setUsername] = useState('');
  const [formTitle, setformTitle] = useState('Enter your Email registered with us');
  const largeSizeMatched = useMediaQuery(theme.breakpoints.up('md'));
  const marginHorizontalText = largeSizeMatched ? 15 : 3;
  const marginHorizontalForm = largeSizeMatched ? 10 : 3;
  const [showPopup, setShowPopup] = useState(false);

  const onSubmitEmail = async (values, { setSubmitting }) => {
    const result = await dispatch(
      sendResetPassEmail({
        email: values.email,
        tenantId: defaultTenantId,
      })
    );

    if (result.isOK && result.data && result.data.isEmailSent) {
      setUsername(values.email);
      setformTitle('Enter new password with code sent to your email');
      setShowNextForm(true);
    } else {
      setShowPopup(true);
      setSubmitting(false);
    }
  };

  const onSubmitPassword = async (values, { setSubmitting }) => {
    const result = await dispatch(
      resetPassword({
        username,
        password: values.password,
        confirmationCode: values.confirmationCode,
        tenantId: defaultTenantId,
      })
    );

    if (result.isOK && result.data && result.data.isReset) {
      setMsgStatus(true);
      setMessage('Password Reset Successful.');
      setTimeout(() => {
        navigate(paths.signIn());
      }, 3000);
    } else {
      setMsgStatus(false);
      setMessage(result.errorMessage || 'Invalid Code. Please enter correct code sent over email.');
      setSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: resetPasSchema,
    onSubmit: onSubmitEmail,
  });

  const formik2 = useFormik({
    initialValues: {
      password: '',
      confirmationCode: '',
    },
    validationSchema: passCodeSchema,
    onSubmit: onSubmitPassword,
  });

  const handlePasswordVisible = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Box mt={6} className={classes.wrapper}>
      {showPopup && (
        <Dialog
          onClose={() => setShowPopup(false)}
          closeIcon={null}
          width={507}
          height={208}
          className={classes.popup}
          title={<Typography className={classes.forgotPopupTitle}>Password Reset Request Submitted</Typography>}
        >
          <Typography className={classes.forgotPopupBodyText}>
            Check your email for next steps to reset your password.
          </Typography>
          <DialogActionsBar>
            <Button
              className={classes.forgotBackButton}
              onClick={() => navigate(paths.signIn())}
              variant="contained"
              color="primary"
              disableElevation
              size="small"
            >
              Return to Login
            </Button>
          </DialogActionsBar>
        </Dialog>
      )}
      <Paper className={classes.paper} elevation={0}>
        <Box mt={6} mx={marginHorizontalText}>
          <Typography color="textPrimary" variant="h3" align="center" gutterBottom>
            Administration Console
          </Typography>
          <Typography className={classes.formDescriptionText} align="center">
            {formTitle}
          </Typography>
        </Box>
        <Box mx={marginHorizontalForm}>
          <form className={classes.form} hidden={showNextForm} onSubmit={formik.handleSubmit} noValidate>
            <Box mt={4} mb={2}>
              <TextField
                className={classes.input}
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                error={!!formik.errors.email}
                helperText={formik.errors.email}
                type="email"
              />
            </Box>
            <Box my={3} className={classes.buttonContainer}>
              <Button
                className={classes.button}
                type="submit"
                disabled={formik.isSubmitting || !accountId}
                variant="contained"
                color="primary"
                disableElevation
              >
                {!formik.isSubmitting ? 'Submit' : <CircularProgress size={30} color="secondary" />}
              </Button>
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                disableElevation
                onClick={() => navigate(paths.signIn())}
              >
                Cancel
              </Button>
            </Box>
          </form>
          <form className={classes.form} hidden={!showNextForm} onSubmit={formik2.handleSubmit} noValidate>
            <Box mt={2}>
              <TextField
                className={classes.input}
                autoComplete="new-password"
                margin="normal"
                required
                fullWidth
                name="password"
                onChange={formik2.handleChange}
                onBlur={formik2.handleBlur}
                value={formik2.values.password}
                error={!!(formik2.errors.password && formik2.touched.password)}
                helperText={formik2.touched.password ? formik2.errors.password : ''}
                label="Password"
                type={showPassword ? 'password' : 'text'}
                id="password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton aria-label="toggle password visibility" onClick={handlePasswordVisible}>
                        {showPassword ? (
                          <VisibilityOffOutlined color="primary" />
                        ) : (
                          <VisibilityOutlined color="primary" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                className={classes.input}
                margin="normal"
                required
                fullWidth
                id="confirmationCode"
                label="Confirmation Code"
                name="confirmationCode"
                onChange={formik2.handleChange}
                onBlur={formik2.handleBlur}
                value={formik2.values.confirmationCode}
                error={!!formik2.errors.confirmationCode}
                helperText={formik2.errors.confirmationCode}
                type="text"
              />
            </Box>
            <Box my={3} className={classes.buttonContainer}>
              <Button
                className={classes.button}
                type="submit"
                disabled={formik2.isSubmitting}
                variant="contained"
                color="primary"
                disableElevation
              >
                {!formik2.isSubmitting ? 'Reset Password' : <CircularProgress size={30} color="secondary" />}
              </Button>
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                disableElevation
                onClick={() => navigate(paths.signIn())}
              >
                Cancel
              </Button>
            </Box>
          </form>
          <Box my={2}>
            {message && <ValidationMessage status={msgStatus} message={message} closeErrorMessage={setMessage} />}
          </Box>
        </Box>
      </Paper>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '80vh',
    [theme.breakpoints.down(767)]: {
      flexGrow: 1,
      // backgroundColor: "#fff",
    },
  },
  paper: {
    maxWidth: 648,
    borderRadius: 23,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  formDescriptionText: {
    fontFamily: 'SF UI Text Light',
    fontSize: 16,
    color: '#637699',
  },
  bottomText: {
    color: '#405475',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  button: {
    fontWeight: 700,
    padding: 14,
    margin: '0px 14px 14px 14px',
    fontSize: 14,
    minWidth: '45%',
    borderRadius: 5,
  },
  link: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'none',
    },
    fontWeight: 700,
    color: theme.palette.primary.main,
    // color: "gray",
  },
  input: {
    '& div input': {
      '&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus, &:-webkit-autofill:active': {
        '-webkit-box-shadow': '0 0 0 30px white inset !important;',
      },
    },
  },

  popup: {
    backdropFilter: 'blur(2px)',
    '& .k-overlay': {
      opacity: '0.45',
    },
    '& .k-dialog-button-layout-stretched': {
      border: 'none',
    },
  },

  forgotPopupTitle: {
    fontWeight: '500',
    fontSize: '26px',
    lineHeight: '36px',
    color: '#505358',
  },

  forgotPopupBodyText: {
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '22px',
    color: '#898989',
  },
  forgotBackButton: {
    textTransform: 'none',
    width: '166px',
    height: '46px',
    fontWeight: 600,
  },
}));
