import React, { useMemo } from 'react';

// Redux
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// components and helpers
import AppTabbedPageWrapper from '../Shared/Pages/TabbedPageWrapper';

import { selectCurrentTenant } from '../Shared/selectors';
import AccountFormWrapper from './AccountDetails/AccountFormWrapper';
import PermissionsListWrapper from './Permissions/PermissionsListWrapper';
import { selectAccountDetails } from './selectors';
import TenantListWrapper from './TenantManagement/TenantListWrapper';

export function AccountPageWrapper(props) {
  const { type } = props;
  const navigate = useNavigate();
  const account = useSelector(selectAccountDetails);
  const currentTenant = useSelector(selectCurrentTenant);

  const tabs = useMemo(() => {
    const tabs = [
      {
        label: 'Account Details',
        type: 'accountDetails',
        url: 'account/details',
        content: <AccountFormWrapper {...props} type="accountDetails" />,
      },
      {
        label: 'Permission Management',
        type: 'permissions',
        url: 'account/permissions',
        content: <PermissionsListWrapper {...props} />,
      },
    ];

    if (account && account.accountType !== 'SINGLE_TENANT') {
      tabs.push({
        label: 'Tenant Management',
        type: 'tenantManagement',
        url: 'account/tenants',
        content: <TenantListWrapper {...props} type="tenantManagement" />,
      });
    }
    return tabs;
  }, [account, props]);

  const handleTabChange = (tabDetails) => {
    navigate(`/${currentTenant.id}/${tabDetails.url}`);
  };

  return <AppTabbedPageWrapper tabs={tabs} openTab={tabs.find((t) => t.type === type)} onTabChange={handleTabChange} />;
}

export default AccountPageWrapper;
