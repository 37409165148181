import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState, useEffect, useMemo } from 'react';

// redux
import { useDispatch, useSelector } from 'react-redux';

import ProfileViewStyles from '../../assets/jss/components/ProfileViewStyles';
import { selectCurrentTenant } from '../Shared/selectors';
import AppKendoTableWrapper from '../Shared/Table/KendoTableWrapper';

// component imports

// mui
import { selectUserDetails } from '../Users/selectors';
import { vitalColumns as columns, vitalTableButtons } from './helpers/clinicalInfoMapper';
import { selectVitalList, selectVitalListLoading } from './slices/selectors';
import { getVitalList } from './slices/vitalListSlice';
import VitalDialog from './VitalDialog';

function Vital() {
  const classes = useStyles();

  const userDetails = useSelector(selectUserDetails);
  const currentTenant = useSelector(selectCurrentTenant);
  const vitalList = useSelector(selectVitalList);
  const vitalListLoading = useSelector(selectVitalListLoading);

  const [pagedVitalList, setPagedVitalList] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedVital, setSelectedVital] = useState({});
  const [dialogMode, setDialogMode] = useState('Add');

  const dispatch = useDispatch();

  const tableButtons = useMemo(() => vitalTableButtons(setDialogOpen), []);

  const handleRowClick = (e) => {
    setSelectedVital(e);
    setDialogMode('Edit');
    setDialogOpen(true);
  };
  const handleCloseDialog = () => {
    setSelectedVital({});
    setDialogMode('Add');
    setDialogOpen(false);
  };
  const handleTableUpdate = (page, pageSize) => {
    setPagedVitalList(vitalList.data.slice((page + 1) * pageSize - pageSize, (page + 1) * pageSize));
  };
  useEffect(() => {
    dispatch(getVitalList(userDetails.nationalHealthId));
  }, [currentTenant, userDetails]);
  useEffect(() => {
    if (vitalList) setPagedVitalList(vitalList.data);
  }, [vitalList]);

  return (
    <Grid className={`${classes.contentWrapper} ${classes.withTableButtons}`}>
      <AppKendoTableWrapper
        data={{
          ...pagedVitalList,
          items:
            pagedVitalList &&
            pagedVitalList.map((n) => ({
              ...n,
              vitalName: n.vitalName,
              codeValue: n.vitalCode ? n.vitalCode.codeValue : '',
              vitalValue: n.vitalQuantity ? n.vitalQuantity.display : '',
              codeUnit: n.unit,
            })),
          total: vitalList && vitalList.data.length,
        }}
        initialSort="onsetDate"
        loading={vitalListLoading}
        initialDir="desc"
        columns={columns()}
        onTableUpdate={handleTableUpdate}
        showButtons
        onRowClick={handleRowClick}
        tableButtons={tableButtons}
      />
      {dialogOpen && (
        <VitalDialog selectedVital={selectedVital} dialogMode={dialogMode} closeDialog={handleCloseDialog} />
      )}
    </Grid>
  );
}

const useStyles = makeStyles(ProfileViewStyles);

export default Vital;
