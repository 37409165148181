import ContentListWrapper from '../../Content/ContentListWrapper';
import { stateList } from '../../Shared/Forms/helpers/stateCountryHelper';
import MedicalCentersListWrapper from '../MedicalCentersListWrapper';

export const transform = (tenant, formData) => ({
  test: {
    ...formData,
    tenantId: tenant.id,
    isActive: formData.isActive !== false,
  },
  testOrderable: {
    ...formData,
    tenantId: tenant.id,
    isActive: formData.isActive !== false,
  },
  testPanels: {
    ...formData,
    tenantId: tenant.id,
    isActive: formData.isActive !== false,
  },
});

export const TestFormMap = (tenant) => [
  {
    type: 'groupNoDropdown',
    label: 'INFORMATION',
    name: 'INFORMATION',
    fields: [
      {
        type: 'hidden',
        name: '_id',
        label: 'ID',
        required: false,
        width: 6,
      },
      {
        type: 'text',
        name: 'longName',
        label: 'Test Name',
        required: true,
        width: 3,
      },
      {
        type: 'text',
        name: 'shortName',
        label: 'Short Name',
        required: true,
        width: 3,
      },
      {
        type: 'text',
        name: 'loinc',
        label: 'LOINC Code',
        required: true,
        width: 3,
      },
      {
        type: 'toggle',
        name: 'isActive',
        label: 'Is Active',
        required: true,
        width: 2,
        default: true,
      },
    ],
  },
  {
    type: 'groupNoDropdown',
    label: 'DIAGNOSTIC PROFILE',
    name: 'DIAGNOSTIC_PROFILE',
    fields: [
      {
        type: 'text',
        name: 'shortDescription',
        label: 'Short Description',
        required: false,
        width: 12,
      },
      {
        type: 'text',
        name: 'longDescription',
        label: 'Long Description',
        required: false,
        rows: 4,
        width: 12,
      },
      {
        type: 'multiselect',
        name: 'keywords',
        label: 'Keywords',
        required: false,
        width: 12,
        allowCustom: true,
      },
    ],
  },

  {
    type: 'groupNoDropdown',
    label: 'ARTICLES',
    name: 'ARTICLES',
    fields: [
      {
        type: 'multiselecttable',
        name: 'articles',
        required: false,
        width: 12,
        tenant,
        contentType: 'articles',
        component: ContentListWrapper,
      },
    ],
  },
  {
    type: 'groupNoDropdown',
    label: 'VIDEOS',
    name: 'VIDEOS',
    fields: [
      {
        type: 'multiselecttable',
        name: 'videos',
        required: false,
        width: 12,
        tenant,
        contentType: 'videos',
        component: ContentListWrapper,
      },
    ],
  },
];

export const TestOrderableFormMap = (tenant, tests, vendors, formats) => [
  {
    type: 'groupNoDropdown',
    label: 'NEW ORDERABLE TEST',
    name: 'TEST_ORDERABLE',
    fields: [
      {
        type: 'hidden',
        name: '_id',
        label: 'ID',
        required: false,
        width: '6',
      },
      {
        type: 'hidden',
        name: 'isActive',
        label: 'Is Active',
        required: true,
        width: 2,
        default: true,
      },
      {
        type: 'autocomplete',
        name: 'labTestId',
        label: 'Test Name',
        required: true,
        width: 4,
        enum:
          (tests &&
            tests.map((t) => ({
              id: t._id,
              label: t.shortName,
            }))) ||
          [],
        labelField: 'label',
        valueField: 'id',
      },
      {
        type: 'text',
        name: 'shortName',
        label: 'Short Name',
        required: false,
        width: 4,
        readOnly: true,
      },
      {
        type: 'text',
        name: 'loinc',
        label: 'LOINC Code',
        required: false,
        width: 4,
        readOnly: true,
      },
      {
        type: 'autocomplete',
        name: 'vendorDetailId',
        label: 'Lab Vendor',
        required: true,
        width: 4,
        enum:
          (vendors &&
            vendors.map((v) => ({
              id: v._id,
              label: v.name,
            }))) ||
          [],
        labelField: 'label',
        valueField: 'id',
      },
      {
        type: 'autocomplete',
        name: 'testFormatId',
        label: 'Test Format',
        required: true,
        width: 4,
        enum:
          (formats &&
            formats.map((f) => ({
              id: f._id,
              label: f.format,
            }))) ||
          [],
        labelField: 'label',
        valueField: 'id',
      },
      {
        type: 'text',
        name: 'testType',
        label: 'Lab Test Type',
        required: false,
        width: 4,
        readOnly: true,
      },
      {
        type: 'text',
        name: 'orderableCode',
        label: 'Orderable Code',
        required: true,
        width: 6,
      },

      {
        type: 'text',
        name: 'vendorTestID',
        label: 'Vendor Test ID',
        required: true,
        width: 6,
      },
      {
        type: 'number',
        name: 'uploadTimeoutInSeconds',
        label: 'Upload Time-out (in seconds)',
        required: false,
        width: 3,
      },
      {
        type: 'blank',
        width: 9,
      },
      {
        type: 'blank',
        width: 2,
      },
      {
        type: 'checkbox',
        name: 'statesApplicable',
        label: 'Select States',
        required: true,
        width: 8,
        height: 500,
        direction: 'column',
        enum: stateList.map((s) => ({ value: s.abbreviation, label: s.name })),
        labelField: 'label',
        valueField: 'value',
      },
      {
        type: 'blank',
        width: 2,
      },
    ],
  },
  {
    type: 'groupNoDropdown',
    label: 'ASSESSMENT',
    name: 'ASSESSMENT',
    fields: [
      {
        type: 'text',
        name: 'resultValues',
        label: 'Result Values',
        placeholder: 'Positive/Negative',
        required: false,
        width: 4,
        readOnly: true,
      },
      {
        type: 'text',
        name: 'referenceRanges',
        placeholder: '0.00 - 0.99',
        label: 'Reference Ranges',
        required: false,
        width: 4,
        readOnly: true,
      },
      {
        type: 'text',
        name: 'abnormalFlags',
        placeHolder: 'None',
        label: 'Abnormal Flags',
        required: false,
        width: 4,
        readOnly: true,
      },
    ],
  },
  {
    type: 'groupNoDropdown',
    label: 'BILLING INFO',
    name: 'BILLING_INFO',
    fields: [
      {
        type: 'text',
        name: 'cptCodeName',
        label: 'CPT Code Name',
        required: false,
        width: 4,
        readOnly: true,
      },
      {
        type: 'text',
        name: 'cptCode',
        label: 'CPT Code',
        required: true,
        width: 4,
      },
      {
        type: 'text',
        name: 'cptCodeModifier',
        label: 'CPT Code Modifier',
        required: false,
        width: 4,
        readOnly: true,
      },
      {
        type: 'number',
        name: 'price',
        label: 'Price ($)',
        required: true,
        width: 4,
      },
      {
        type: 'text',
        name: 'reimbursment',
        label: 'CMS Fee / Reimbursement',
        required: false,
        width: 4,
        readOnly: true,
      },
      {
        type: 'dropdown',
        name: 'taxable',
        label: 'Taxable',
        required: false,
        width: 4,
        readOnly: true,
        enum: ['Yes', 'No'],
      },
    ],
  },
  {
    type: 'groupNoDropdown',
    label: 'DIAGNOSTIC PROFILE',
    name: 'DIAGNOSTIC_PROFILE',
    fields: [
      {
        type: 'text',
        name: 'shortDescription',
        label: 'Short Description',
        required: false,
        width: 12,
        readOnly: true,
      },
      {
        type: 'text',
        name: 'longDescription',
        label: 'Long Description',
        required: false,
        rows: 4,
        width: 12,
        readOnly: true,
      },
      {
        type: 'multiselect',
        name: 'keywords',
        label: 'Keywords',
        required: false,
        width: 12,
        allowCustom: true,
      },
      {
        type: 'imageCrop',
        name: 'squareImage',
        label: 'Square Image',
        required: false,
        width: 4,
        readOnly: true,
        acceptedFiles: ['image/jpeg', 'image/png'],
        imageWidth: 1440,
        imageHeight: 1440,
      },
      {
        type: 'imageCrop',
        name: 'wideImage',
        label: 'Wide Image',
        required: false,
        width: 4,
        readOnly: true,
        acceptedFiles: ['image/jpeg', 'image/png'],
        imageWidth: 2424,
        imageHeight: 1440,
      },
      {
        type: 'imageCrop',
        name: 'tallImage',
        label: 'Tall Image',
        required: false,
        width: 4,
        readOnly: true,
        acceptedFiles: ['image/jpeg', 'image/png'],
        imageWidth: 800,
        imageHeight: 1824,
      },
    ],
  },

  {
    type: 'groupNoDropdown',
    label: 'ASSOCIATIONS',
    name: 'ASSOCIATIONS',
    fields: [
      {
        type: 'multiselect',
        name: 'conditions',
        label: 'Conditions',
        required: false,
        width: 4,
        allowCustom: true,
      },
      {
        type: 'multiselect',
        name: 'procedures',
        label: 'Procedures',
        required: false,
        width: 4,
        allowCustom: true,
      },
      {
        type: 'multiselect',
        name: 'prescriptionMedications',
        label: 'Prescription Medications',
        required: false,
        width: 4,
        allowCustom: true,
      },
      {
        type: 'multiselect',
        name: 'otcMedications',
        label: 'OTC Medications',
        required: false,
        width: 4,
        allowCustom: true,
      },
      {
        type: 'multiselect',
        name: 'naturalRemedies',
        label: 'Natural Remedies',
        required: false,
        width: 4,
        allowCustom: true,
      },
      {
        type: 'multiselect',
        name: 'specialties',
        label: 'Specialties',
        required: false,
        width: 4,
        allowCustom: true,
      },
    ],
  },

  {
    type: 'groupNoDropdown',
    label: 'ARTICLES',
    name: 'ARTICLES',
    fields: [
      {
        type: 'multiselecttable',
        name: 'articles',
        required: false,
        width: 12,
        tenant,
        contentType: 'articles',
        component: ContentListWrapper,
      },
    ],
  },
  {
    type: 'groupNoDropdown',
    label: 'VIDEOS',
    name: 'VIDEOS',
    fields: [
      {
        type: 'multiselecttable',
        name: 'videos',
        required: false,
        width: 12,
        tenant,
        contentType: 'videos',
        component: ContentListWrapper,
      },
    ],
  },
];

export const TestPanelsFormMap = (tenant) => [
  {
    type: 'groupNoDropdown',
    label: 'INFORMATION',
    name: 'INFORMATION',
    fields: [
      {
        type: 'hidden',
        name: '_id',
        label: 'ID',
        required: false,
        width: '6',
      },
      {
        type: 'text',
        name: 'name',
        label: 'Test Panel Name',
        required: true,
        width: 3,
      },
      {
        type: 'text',
        name: 'description',
        label: 'Description',
        required: true,
        width: 7,
      },
      {
        type: 'toggle',
        name: 'isActive',
        label: 'Is Active',
        required: true,
        width: 2,
        default: true,
      },
      {
        type: 'dropdown',
        name: 'default',
        label: 'Default',
        required: false,
        width: 3,
        enum: ['True', 'False'],
        readOnly: true,
      },
    ],
  },
  {
    type: 'groupNoDropdown',
    label: 'TESTS',
    name: 'TESTS',
    fields: [
      {
        type: 'multiselecttable',
        name: 'labTestIds',
        required: false,
        width: 12,
        tenant,
        contentType: 'testOrderable',
        component: MedicalCentersListWrapper,
      },
    ],
  },
  {
    type: 'groupNoDropdown',
    label: 'BILLING INFO',
    name: 'BILLING_INFO',
    fields: [
      {
        type: 'text',
        name: 'cptCodeName',
        label: 'CPT Code Name',
        required: false,
        width: 4,
        readOnly: true,
      },
      {
        type: 'text',
        name: 'cptCode',
        label: 'CPT Code',
        required: false,
        width: 4,
        readOnly: true,
      },
      {
        type: 'text',
        name: 'cptCodeModifier',
        label: 'CPT Code Modifier',
        required: false,
        width: 4,
        readOnly: true,
      },
      {
        type: 'number',
        name: 'price',
        label: 'Price ($)',
        required: false,
        width: 4,
        readOnly: true,
      },
      {
        type: 'text',
        name: 'reimbursment',
        label: 'CMS Fee / Reimbursement',
        required: false,
        width: 4,
        readOnly: true,
      },
      {
        type: 'dropdown',
        name: 'taxable',
        label: 'Taxable',
        required: false,
        width: 4,
        readOnly: true,
        enum: ['Yes', 'No'],
      },
    ],
  },
  {
    type: 'groupNoDropdown',
    label: 'DIAGNOSTIC PROFILE',
    name: 'DIAGNOSTIC_PROFILE',
    fields: [
      {
        type: 'text',
        name: 'shortDescription',
        label: 'Short Description',
        required: false,
        width: 12,
        readOnly: true,
      },
      {
        type: 'text',
        name: 'longDescription',
        label: 'Long Description',
        required: false,
        rows: 4,
        width: 12,
        readOnly: true,
      },
      {
        type: 'multiselect',
        name: 'keywords',
        label: 'Keywords',
        required: false,
        width: 12,
        allowCustom: true,
        readOnly: true,
      },
      {
        type: 'imageCrop',
        name: 'squareImage',
        label: 'Square Image',
        required: false,
        width: 4,
        readOnly: true,
        acceptedFiles: ['image/jpeg', 'image/png'],
        imageWidth: 1440,
        imageHeight: 1440,
      },
      {
        type: 'imageCrop',
        name: 'wideImage',
        label: 'Wide Image',
        required: false,
        width: 4,
        readOnly: true,
        acceptedFiles: ['image/jpeg', 'image/png'],
        imageWidth: 2424,
        imageHeight: 1440,
      },
      {
        type: 'imageCrop',
        name: 'tallImage',
        label: 'Tall Image',
        required: false,
        width: 4,
        readOnly: true,
        acceptedFiles: ['image/jpeg', 'image/png'],
        imageWidth: 800,
        imageHeight: 1824,
      },
    ],
  },
  {
    type: 'groupNoDropdown',
    label: 'ASSOCIATIONS',
    name: 'ASSOCIATIONS',
    fields: [
      {
        type: 'multiselect',
        name: 'conditions',
        label: 'Conditions',
        required: false,
        width: 4,
        allowCustom: true,
      },
      {
        type: 'multiselect',
        name: 'procedures',
        label: 'Procedures',
        required: false,
        width: 4,
        allowCustom: true,
      },
      {
        type: 'multiselect',
        name: 'prescriptionMedications',
        label: 'Prescription Medications',
        required: false,
        width: 4,
        allowCustom: true,
      },
      {
        type: 'multiselect',
        name: 'otcMedications',
        label: 'OTC Medications',
        required: false,
        width: 4,
        allowCustom: true,
      },
      {
        type: 'multiselect',
        name: 'naturalRemedies',
        label: 'Natural Remedies',
        required: false,
        width: 4,
        allowCustom: true,
      },
      {
        type: 'multiselect',
        name: 'specialties',
        label: 'Specialties',
        required: false,
        width: 4,
        allowCustom: true,
      },
    ],
  },
  {
    type: 'groupNoDropdown',
    label: 'ARTICLES',
    name: 'ARTICLES',
    fields: [
      {
        type: 'multiselecttable',
        name: 'articles',
        required: false,
        width: 12,
        tenant,
        contentType: 'articles',
        component: ContentListWrapper,
        readOnly: true,
      },
    ],
  },
  {
    type: 'groupNoDropdown',
    label: 'VIDEOS',
    name: 'VIDEOS',
    fields: [
      {
        type: 'multiselecttable',
        name: 'videos',
        required: false,
        width: 12,
        tenant,
        contentType: 'videos',
        component: ContentListWrapper,
        readOnly: true,
      },
    ],
  },
];
