/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import Grid from '@material-ui/core/Grid';
import { MultiSelect } from '@progress/kendo-react-dropdowns';
import React, { useState, useEffect } from 'react';

// Redux
import { useDispatch } from 'react-redux';

import { showNotification } from '../../../Notifications/notificationSlice';

// ui reflection of file view
function MultiSelectInput(props) {
  const { field, value: initialValue, handleChangeWithParams, disabled } = props;
  const dispatch = useDispatch();

  const [selected, setSelected] = useState(initialValue || []);

  useEffect(() => {
    if (initialValue && initialValue !== selected) {
      setSelected(initialValue);
    } else if (selected.length === 0 && field.selectAll) {
      const allValues = field.enum.map((member) => (field.valueField ? member[field.valueField] : member));
      setSelected(allValues);
      handleChangeWithParams(field.name, allValues);
    }
  }, [initialValue, field]);

  const handleMultiSelectChange = (e) => {
    const selectedItems = e.target.value.map((v) => (field.valueField ? v[field.valueField] : v));
    setSelected(selectedItems);
    handleChangeWithParams(field.name, selectedItems);
  };

  const listNoDataRender = (element) => {
    const noData = (
      <h4 style={{ fontSize: '1em' }}>
        {field.allowCustom ? 'Type the item you wish to add and press Enter.' : 'No Data Found'}
      </h4>
    );

    return React.cloneElement(element, { ...element.props }, noData);
  };

  const tagRender = (tagData, li) => {
    const itemChildren = (
      <span
        key={li.key}
        onClick={() => handleCopy(tagData.text)}
        style={{
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
        }}
      >
        {li.props.children}
      </span>
    );

    return React.cloneElement(li, li.props, [itemChildren]);
  };

  const handleCopy = (data) => {
    navigator.clipboard
      .writeText(data)
      .then(() => {
        dispatch(showNotification('Successfully copied to clipboard', 'success'));
      })
      .catch((err) => {
        dispatch(showNotification(err.message, 'error'));
      });
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <MultiSelect
          readOnly={field.readOnly}
          disabled={disabled || field.readOnly}
          data={field.enum || []}
          required={field.required}
          textField={field.labelField}
          placeholder={field.placeholder}
          value={
            field.enum
              ? field.enum.filter((v) =>
                  field.valueField ? selected.includes(v[field.valueField]) : selected.includes(v)
                )
              : selected
          }
          tagRender={tagRender}
          onChange={handleMultiSelectChange}
          allowCustom={field.allowCustom}
          listNoDataRender={listNoDataRender}
          label={field.label}
          style={{ width: '100%' }}
        />
      </Grid>
    </Grid>
  );
}

export default MultiSelectInput;
