import Box from '@material-ui/core/Box';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { verifyAuthenticationCode } from '../../questionnaireSlice';
import { selectIsValidCode } from '../../selectors';

function VerificationCode(props) {
  const { field } = props;
  const params = useParams();
  const [values, setValues] = useState([]);
  const [isError, setError] = useState(false);
  const [phone, setPhone] = useState();
  const [email, setEmail] = useState();
  const invalidCode = useSelector(selectIsValidCode);
  const [formattedContact, setFormattedContact] = useState();
  const dispatch = useDispatch();
  const refs = useRef([...Array(6)]);

  const handleChange = (e, index) => {
    const value = e.target.value ? Math.max(0, parseInt(e.target.value)).toString().slice(0, 1) : '';
    const updatedValues = [...values];
    updatedValues[index] = value;
    setValues(updatedValues);

    if (value && index + 1 <= refs.current.length - 1) {
      refs.current[index + 1].focus();
    }
  };

  const onKeyDown = (e, index) => {
    setError(false);

    const currentValue = values[index];
    if ((e.keyCode === 8 || e.keycode === 46) && !currentValue && index - 1 >= 0) {
      refs.current[index - 1].focus();
    }
  };

  const getResult = () => values.join('');

  const clearInputs = () => {
    setValues([]);
  };

  // Check if full sequence is complete and send to API for verification
  useEffect(() => {
    if (values.length === 6) {
      const code = getResult();
      const verifyData =
        field.type === 'phoneCode' ? { Source: phone, numericCode: code } : { Source: email, numericCode: code };
      dispatch(verifyAuthenticationCode(params?.tenant, verifyData, field.type));
    }
    // eslint-disable-next-line
  }, [values, phone]);

  useEffect(() => {
    // Once available to the component, take the phone number that was previously provided by the user and format it for display
    if (field.type === 'phoneCode') {
      const { phoneNumber } = field;
      setPhone(phoneNumber);
      const cleaned = `${phoneNumber}`.replace(/\D/g, '');
      // Verify format
      const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

      if (match) {
        const intlCode = match[1] ? '+1 ' : '';
        setFormattedContact([intlCode, '(', match[2], ') ', match[3], '-', match[4]].join(''));
      }
    } else if (field.type === 'emailCode') {
      setFormattedContact(field.email);
      setEmail(field.email);
    }
    // After code is found invalid, activate error
    if (invalidCode) {
      setError(true);
      clearInputs();
    }
  }, [invalidCode, field.phoneNumber, field.email, field.type]);

  return (
    <Grid>
      <h2>Enter the secure code we sent you</h2>

      <Typography>We sent a 6 digit code to you at</Typography>
      <Typography>{formattedContact}</Typography>
      <Box>
        <Grid container spacing={2}>
          {refs.current.map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Grid key={index} item xs={2}>
              <TextField
                inputRef={(ref) => {
                  refs.current[index] = ref;
                }}
                id="number"
                type="first_input"
                InputLabelProps={{
                  shrink: true,
                }}
                autoFocus={index === 0}
                value={values[index] || ''}
                onChange={(e) => handleChange(e, index)}
                onKeyDown={(e) => onKeyDown(e, index)}
                variant="outlined"
              />
            </Grid>
          ))}
        </Grid>
      </Box>
      {isError ? <FormHelperText error>Invalid Code</FormHelperText> : null}
    </Grid>
  );
}

export default VerificationCode;
