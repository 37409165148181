import Card from '@material-ui/core/Card';
import Icon from '@material-ui/core/Icon';
import { useTheme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import HelpOutlineRoundedIcon from '@material-ui/icons/HelpOutlineRounded';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import catchAllIcon from '../../assets/img/catch-all.svg';
import { showConfirmation } from '../Confirmation/confirmationSlice';
import { selectCurrentTenant } from '../Shared/selectors';
import utils from '../Shared/Utils/utils';
import { statusMappings } from '../Users/helpers/mappings';
import { selectUserDetails, selectUserPermissions } from '../Users/selectors';
import { resetUserPassword, resendInvitation, getUser } from '../Users/usersSlice';

import DesktopCard from './DesktopCard';
import MobileCard from './MobileCard';

function ProfileCard() {
  const params = useParams();
  const { userId } = params;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const currentTenant = useSelector(selectCurrentTenant);
  const userDetails = useSelector(selectUserDetails);
  const userPermissions = useSelector(selectUserPermissions);

  const dispatch = useDispatch();

  const getUserData = async () => {
    await dispatch(getUser(userId, true));
  };

  const handleDelete = (type) => {
    let userData = userDetails;

    let message = `Are you sure you want to remove this user from ${currentTenant && currentTenant.title}?`;

    if (type === 'userDeleteAccount') {
      message = `Are you sure you want to delete this user from the ${
        currentTenant && currentTenant.accountId.toUpperCase()
      } account?`;
      // userData = userPermissions;
    }

    dispatch(showConfirmation('Confirm', message, type, currentTenant && currentTenant.id, userData));
  };

  const handleChangeUserStatus = (type) => {
    const message = `Are you sure you want to ${
      userDetails.status === statusMappings.DEACTIVATED.value ? 'activate' : 'deactivate'
    } this user?`;

    dispatch(
      showConfirmation('Confirm', message, type, currentTenant && currentTenant.id, userDetails, null, getUserData)
    );
  };

  const handlePasswordReset = () => {
    dispatch(
      resetUserPassword({
        email: userDetails?.email,
        tenantId: currentTenant?.id,
      })
    );
  };

  const handleItemClick = (e) => {
    const value = e.item.text;
    if (value === 'Delete User from Account') {
      handleDelete('userDeleteAccount', userPermissions);
    } else if (value === 'Reset Password') {
      handlePasswordReset();
    } else if (value === 'Remove User from Tenant') {
      handleDelete('userRemoveTenant', userDetails);
    } else if (value === 'Activate User' || value === 'Deactivate User') {
      handleChangeUserStatus('userStatusChange');
    } else if (value === 'Resend Invitation') {
      dispatch(resendInvitation(currentTenant, userDetails?.email));
    }
  };

  const toolTip = (title, icon) => (
    <Tooltip disableTouchListener placement="right" title={title}>
      <Icon>{icon}</Icon>
    </Tooltip>
  );

  let emailIcon;
  if (userDetails && userDetails.emailStatus === 'valid') {
    emailIcon = toolTip(
      utils.removeUnderscoresTitleCase(userDetails.emailStatus),
      <CheckCircleOutlineRoundedIcon color="action" />
    );
  } else if (userDetails && (!userDetails.emailStatus || userDetails.emailStatus === 'unknown')) {
    emailIcon = toolTip('Unknown', <HelpOutlineRoundedIcon color="disabled" />);
  } else if (userDetails && userDetails.emailStatus === 'catch-all') {
    emailIcon = toolTip(
      utils.removeUnderscoresTitleCase(userDetails.emailStatus),
      <img src={catchAllIcon} alt="catch-all" width={20} style={{ marginLeft: 8 }} />
    );
  } else {
    emailIcon = toolTip(
      utils.removeUnderscoresTitleCase(userDetails && userDetails.emailStatus),
      <HighlightOffRoundedIcon color="error" />
    );
  }

  return (
    <Card style={{ borderRadius: '20px' }} elevation={0}>
      {!isMobile ? (
        <DesktopCard handleItemClick={handleItemClick} emailIcon={emailIcon} />
      ) : (
        <MobileCard handleItemClick={handleItemClick} emailIcon={emailIcon} />
      )}
    </Card>
  );
}

export default ProfileCard;
