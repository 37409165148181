import React from 'react';
import ContentLoader from 'react-content-loader';

export function MedicalCenterSkeletonLoading(props) {
  return (
    <ContentLoader
      // height={54}
      // width={320}
      speed={0.8}
      viewBox="0 0 320 340"
      backgroundColor="#ecebeb"
      foregroundColor="#f3f3f3"
      {...props}
    >
      {/* Image Title */}
      <rect x="20" y="20" rx="3" ry="3" width="50" height="8" />
      {/* Image Upload */}
      <rect x="100" y="40" rx="3" ry="3" width="120" height="120" />
      {/* Image text inputs */}
      <rect x="20" y="180" rx="3" ry="3" width="280" height="8" />
      <rect x="10" y="200" rx="0" ry="0" width="300" height="0.5" />

      {/* Details Title */}
      <rect x="20" y="215" rx="3" ry="3" width="50" height="8" />

      {/* Details text inputs */}
      <rect x="20" y="240" rx="3" ry="3" width="280" height="8" />

      <rect x="20" y="260" rx="3" ry="3" width="280" height="50" />
      <rect x="258" y="320" rx="3" ry="3" width="40" height="12" />
      <rect x="20" y="320" rx="3" ry="3" width="40" height="12" />
    </ContentLoader>
  );
}

export function MedicalCenterSkeletonLoadingMobile(props) {
  return (
    <ContentLoader
      // height={54}
      // width={320}
      speed={0.8}
      viewBox="0 0 300 700"
      backgroundColor="#ecebeb"
      foregroundColor="#f3f3f3"
      {...props}
    >
      {/* Image Title */}
      <rect x="40" y="60" rx="3" ry="3" width="70" height="15" />
      {/* Image Upload */}
      <rect x="60" y="110" rx="3" ry="3" width="180" height="180" />
      {/* Image text inputs */}
      <rect x="40" y="350" rx="3" ry="3" width="230" height="10" />
      <rect x="30" y="380" rx="0" ry="0" width="240" height="2" />

      {/* Details Title */}
      <rect x="40" y="420" rx="3" ry="3" width="70" height="15" />
      {/* Details text inputs */}
      <rect x="40" y="480" rx="3" ry="3" width="230" height="10" />
      <rect x="40" y="520" rx="3" ry="3" width="230" height="150" />
    </ContentLoader>
  );
}
