export const statusMapping = {
  false: {
    label: 'Inactive',
    background: 'red',
    color: 'white',
  },
  true: {
    label: 'Active',
    background: 'green',
    color: 'white',
  },
};

export const listName = {
  testOrderable: 'labTestOrderableIds',
  testPanels: 'labTestPanelIds',
};

export const testIdName = {
  testOrderable: 'labTestOrderableId',
  testPanels: 'labTestPanelId',
};

export const booleanMapping = {
  false: {
    label: 'No',
    background: 'red',
    color: 'white',
  },
  true: {
    label: 'Yes',
    background: 'green',
    color: 'white',
  },
};
