/* eslint-disable no-lonely-if */
/* eslint-disable-next-line */
import { schemeSet2 } from 'd3-scale-chromatic';
import moment from 'moment';

import utils from '../../../Shared/Utils/utils';

export const transform = {
  dashboardBar: (list, line, field) => {
    // sort data list and format date
    const sortedAndFormattedList = list
      .sort((oldItem, newItem) => new Date(oldItem.date) - new Date(newItem.date))
      .map((item) => ({
        date: moment(item.date).format('DD MMM'),
        [field]: item[field] ? utils.removeUnderscoresTitleCase(item[field].title || item[field]) : '',
        count: item.count,
        originalDate: item.date,
      }));

    // sort line list and format date
    const sortedAndFormattedLineList =
      line &&
      line
        .sort((oldItem, newItem) => new Date(oldItem.date) - new Date(newItem.date))
        .map((item) => ({
          date: moment(item.date).format('DD MMM'),
          count: item.count,
        }));

    return sortedAndFormattedList.reduce((acc, value) => {
      const foundIndex = acc.findIndex((item) => item.date === value.date);

      if (foundIndex !== -1) {
        acc[foundIndex] = { ...acc[foundIndex], [value[field]]: value.count };
      } else {
        // if line is present, add to object with same date
        if (line) {
          sortedAndFormattedLineList.forEach((lineField) => {
            if (lineField.date === value.date) {
              acc.push({
                date: value.date,
                [value[field]]: value.count,
                line: lineField.count,
                originalDate: value.originalDate,
              });
            }
          });
        } else {
          acc.push({
            date: value.date,
            [value[field]]: value.count,
            originalDate: value.originalDate,
          });
        }
      }

      return acc;
    }, []);
  },

  dashboardKeys: (list, line, field) => {
    const keys = [];
    const whitelistStatus = ['ASYMPTOMATIC', 'SYMPTOMATIC', 'EXPOSURE'];
    list.forEach((item) => {
      if (field === 'outcome') {
        if (
          item[field] &&
          item[field].title &&
          field === 'outcome' &&
          whitelistStatus.includes(item[field].title.toUpperCase()) &&
          !keys.includes(utils.removeUnderscoresTitleCase(item[field].title))
        ) {
          keys.push(utils.removeUnderscoresTitleCase(item[field].title));
        }
      } else if (item[field] && !keys.includes(utils.removeUnderscoresTitleCase(item[field]))) {
        keys.push(utils.removeUnderscoresTitleCase(item[field]));
      }
    });
    return keys;
  },

  pieChart: (list) => {
    const pieCharts = [];
    list.forEach((item) => {
      const isObjectPresent = pieCharts.find((o) => o.id.toUpperCase() === item._id);
      if (item.count > 0) {
        if (isObjectPresent) {
          pieCharts.forEach((data, index) => {
            if (data.id.toUpperCase() === item._id) {
              pieCharts[index].value += item.count;
            }
          });
        } else {
          pieCharts.push({
            id: utils.removeUnderscoresTitleCase(item._id),
            label: utils.removeUnderscoresTitleCase(item._id),
            value: item.count,
            color: schemeSet2[pieCharts.length],
          });
        }
      }
    });
    return pieCharts;
  },

  clearancePieChart: (list) => {
    const clearancePieCharts = [];
    const clearedStatus = ['APPROVED', 'TESTING_AND_WORK', 'ASYMPTOMATIC'];
    const notClearedStatus = ['STAY_HOME', 'SECURE_TESTING', 'TESTING_AND_HOME', 'SYMPTOMATIC', 'EXPOSURE'];
    let clearedStatusCount = 0;
    let notClearedStatusCount = 0;
    const unknownStatusCount = list.UNKNOWN;
    if (list && list.completed) {
      clearedStatusCount = list.completed
        .filter(({ outcome }) => clearedStatus.includes(outcome && outcome.title.toUpperCase()))
        .reduce((a, b) => +a + +b.count, 0);
      notClearedStatusCount = list.completed
        .filter(({ outcome }) => notClearedStatus.includes(outcome && outcome.title.toUpperCase()))
        .reduce((a, b) => +a + +b.count, 0);
    }

    if (clearedStatusCount > 0) {
      clearancePieCharts.push({
        id: 'cleared',
        label: 'Cleared',
        value: clearedStatusCount,
        color: schemeSet2[clearancePieCharts.length],
      });
    }
    if (notClearedStatusCount > 0) {
      clearancePieCharts.push({
        id: 'not_cleared',
        label: 'Not Cleared',
        value: notClearedStatusCount,
        color: schemeSet2[clearancePieCharts.length],
      });
    }
    if (unknownStatusCount > 0) {
      clearancePieCharts.push({
        id: 'unknown',
        label: 'Unknown',
        value: unknownStatusCount,
        color: schemeSet2[clearancePieCharts.length],
      });
    }

    return clearancePieCharts;
  },

  questionnairePieChart: (list) => {
    const listKeys = Object.keys(list);
    return listKeys.map((item, index) => ({
      id: item,
      label: item === 'no' ? 'Not Completed' : 'Completed',
      value: list[item],
      color: schemeSet2[index],
    }));
  },

  outcomePieChart: (list) => {
    const listKeys = Object.keys(list);
    return listKeys.map((item, index) => ({
      id: item,
      label: item === 'total' ? 'Total' : 'To Do',
      value: list[item],
      color: schemeSet2[index],
    }));
  },
};
