// MUI
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
// import SendIcon from '@material-ui/icons/Send';
import React, { useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';

import DiscussionStyles from '../../../../assets/jss/components/DiscussionStyles';
// import { sendDiscussionMessage } from '../reviewerSlice';

import DiscussionContent from './DiscussionContent';
// import MessageWrapper from './MessageWrapper';

function DiscussionPopover(props) {
  const { annotationId, shapeData, classes } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);

  useEffect(() => {}, []);

  const handleOpenMessenger = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMessenger = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <IconButton
        color="primary"
        // className={classes.closeIcon}
        style={{
          top: -8,
          left: -8,
        }}
        aria-describedby={id}
        variant="contained"
        onClick={handleOpenMessenger}
      >
        <QuestionAnswerIcon />
      </IconButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseMessenger}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        className={classes.popover}
      >
        <IconButton color="inherit" className={classes.closeMessengerIcon} onClick={handleCloseMessenger}>
          <CloseIcon />
        </IconButton>
        <DiscussionContent annotationId={annotationId} shapeData={shapeData} />
      </Popover>
    </>
  );
}

export default withStyles(DiscussionStyles)(DiscussionPopover);
