import { Dialog } from '@progress/kendo-react-dialogs';
import React from 'react';

function ManageListDialog(props) {
  const { children, closeDialog } = props;

  const handleCancel = () => {
    closeDialog();
  };

  return (
    <Dialog
      title={"Manage Tenant's List"}
      onClose={handleCancel}
      width={window.innerWidth - 20}
      height={window.innerHeight - 20}
    >
      {children}
    </Dialog>
  );
}

export default ManageListDialog;
