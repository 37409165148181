// redux
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { useTheme, withStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// components and helpers
import CreateFormStyles from '../../../assets/jss/components/CreateFormStyles';
import { showConfirmation } from '../../Confirmation/confirmationSlice';
import { getMasterLabTestsTenant } from '../../Orders/ordersSlice';
import { selectOrderableTests } from '../../Orders/selectors';
import { initForm } from '../../Shared/Forms/formSlice';
import { selectFormData } from '../../Shared/Forms/selectors';

// mui
import { selectCurrentTenantId } from '../../Shared/selectors';
import { getFieldName } from '../QuestionBuilder/helpers/formHelper';
import { languageOptions } from '../QuestionBuilder/helpers/languageOptions';
import { selectVirtualConsult } from '../selectors';
import OutcomePreview from '../vcb-package/OutcomePreview';
import { createOutcome, getContent, updateOutcome } from '../virtualConsultSlice';

import { handleFormatJson } from './helpers/outcomeJsonBuilder';
import { OutcomeBuilderSkeletonLoading, OutcomeBuilderSkeletonLoadingMobile } from './OutcomeBuilderSkeletonLoading';
import OutcomeFormWrapper from './OutcomeFormWrapper';

function OutcomeBuilderWrapper(props) {
  const { actions, editOutcome, handleQuestionUpdate, handleCancel } = props;

  const formData = useSelector(selectFormData);
  const labTestOrderables = useSelector(selectOrderableTests);
  const consult = useSelector(selectVirtualConsult);
  const currentTenant = useSelector(selectCurrentTenantId);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const [details, setDetails] = useState(true);
  const [type, setType] = useState('');
  const [id, setId] = useState('');
  const [activeLabTests, setActiveLabTests] = useState([]);

  const dispatch = useDispatch();

  // Initial load
  useEffect(() => {
    window.scrollTo(0, 0);
    const url = window.location.pathname;
    if (url.includes('details')) {
      const type = url.split('/')[3];
      const id = url.split('/').pop();
      dispatch(getContent(currentTenant, type, '', 'id', 'asc', 0, 500, []));
      setType(type);
      setId(id);
    } else {
      setDetails(false);
    }

    dispatch(getMasterLabTestsTenant('orderableTests', currentTenant));
  }, [currentTenant, actions]);

  // Deconstruct JSON object to formData format
  useEffect(() => {
    if (type !== 'questionnaires' && consult[type]) {
      consult[type].content.forEach((n) => {
        const nU = { ...n };
        if (nU.id === id) {
          // deconstruct question medical code data
          if (nU.coding && nU.coding.system) {
            const key = getFieldName(nU.coding.system);
            nU.system = nU.coding.system;
            nU.code = nU.coding.code;
            nU.codeDescription = { [key]: nU.coding.description };
          }

          // Deconstruct translations
          if (nU.name && nU.name.translations) {
            const selectedLanguages = Object.keys(nU.name.translations);
            nU.languageChoices = languageOptions.filter((lang) => selectedLanguages.includes(lang.split(' = ')[0]));

            nU.languageChoices.forEach((lang) => {
              const languageCode = lang.split(' = ')[0];
              nU[`name-${languageCode}`] = nU.name.translations[languageCode];
              nU[`nameValid-${languageCode}`] = nU.name.validatedLanguages.includes(languageCode);
              nU[`title-${languageCode}`] = nU.title.translations[languageCode];
              nU[`titleValid-${languageCode}`] = nU.title.validatedLanguages.includes(languageCode);
              nU[`description-${languageCode}`] = nU.description.translations[languageCode];
              nU[`descriptionValid-${languageCode}`] = nU.description.validatedLanguages.includes(languageCode);
            });
          }

          // Deconstruct translations
          nU.name = nU.name && nU.name.translations ? nU.name.translations.eng : nU.name;
          nU.title = nU.title && nU.title.translations ? nU.title.translations.eng : nU.title;
          nU.description =
            nU.description && nU.description.translations ? nU.description.translations.eng : nU.description;

          // Type-specific fields
          // Deconstruct test list
          if (nU.type === 'test' && nU.testList) {
            nU.testList = nU.testList.map((item) => {
              const itemTest = { ...item };
              const foundTest = activeLabTests.find(
                (test) =>
                  test.labTestOrderableId === itemTest.orderableTestId ||
                  test.labTestPanelId === itemTest.orderableTestId
              );
              if (foundTest) {
                itemTest.test = {
                  safeTestId: itemTest.testId,
                  vendorTestId: itemTest.vendorId,
                  vendorKey: itemTest.laboratoryTestSource,
                  vendorTestName: itemTest.vendorName,
                  orderableTestId: itemTest.orderableTestId,
                };
              } else {
                itemTest.test = null;
              }
              return itemTest;
            });
          }

          // Deconstruct prescription objects
          if (nU.type === 'prescription' && nU.medicationList) {
            nU.medicationList = nU.medicationList.map((medication) => ({
              ...medication,
              minQuantity: medication.quantity && medication.quantity.min,
              maxQuantity: medication.quantity && medication.quantity.max,
              medicationType: medication && medication.type,
              medicationName: medication && medication.name,
              doseUnit: medication.dose && medication.dose.unit,
              doseValue: medication.dose && medication.dose.value,
              frequencyType: medication.frequency && medication.frequency.type,
              frequencyValue: medication.frequency && medication.frequency.value,
              otherDurations:
                (medication.duration && medication.duration.unit === 'complete') ||
                (medication.duration && medication.duration.unit === 'asNeeded')
                  ? medication.duration.unit
                  : null,
              durationUnit:
                medication.duration &&
                medication.duration.unit !== 'complete' &&
                medication.duration.unit !== 'asNeeded'
                  ? medication.duration.unit
                  : null,
              durationValue:
                medication.duration &&
                medication.duration.unit !== 'complete' &&
                medication.duration.unit !== 'asNeeded'
                  ? medication.duration.value
                  : null,
            }));
          }

          // Deconstruct objects in alarm options
          if (nU.type === 'alarm' && nU.options) {
            nU.options = nU.options.map((option) => ({
              ...option,
              frequencyType: option.frequency && option.frequency.type,
              frequencyAmount: option.frequency && option.frequency.value,
            }));
          }

          nU.reporterTarget = nU?.report?.target || '';
          nU.reporterFormat = nU?.report?.text || '';

          dispatch(initForm(nU));
        }
      });
    } else if (type === 'questionnaires') {
      if (editOutcome.coding && editOutcome.coding.system) {
        const key = getFieldName(editOutcome.coding.system);
        editOutcome.system = editOutcome.coding.system;
        editOutcome.code = editOutcome.coding.code;
        editOutcome.codeDescription = {
          [key]: editOutcome.coding.description,
        };
      }
      if (editOutcome.type === 'test') {
        editOutcome.test = {
          safeTestId: editOutcome.testId,
          vendorTestId: editOutcome.vendorId,
          vendorKey: editOutcome.laboratoryTestSource,
          vendorTestName: editOutcome.vendorName,
          orderableTestId: editOutcome.orderableTestId,
        };
      }
      dispatch(initForm(editOutcome));
    }
  }, [consult, type, id, editOutcome, activeLabTests]);

  useEffect(() => {
    if (labTestOrderables && labTestOrderables.list && labTestOrderables.list.length > 0) {
      const activeTests = labTestOrderables.list;
      setActiveLabTests(activeTests);
    }
  }, [labTestOrderables]);

  const handleSubmit = (formData) => {
    const data = handleFormatJson(formData);
    if (details) {
      dispatch(updateOutcome(currentTenant, data, id));
    } else {
      dispatch(createOutcome(currentTenant, data));
    }
  };

  const handleDelete = (formData) => {
    dispatch(
      showConfirmation(
        'Confirm',
        'Are you sure you want to delete this outcome?',
        'outcomeDelete',
        currentTenant,
        formData.id
      )
    );
  };

  if (!currentTenant) return <div />;

  // formData.questionList fixes a rendering bug where the form would finish rendering before the formData is
  // updated, when editing from the Questionnaire Builder
  if (
    (details && !consult[type]) ||
    (labTestOrderables && labTestOrderables.loading) ||
    (details && (formData.questionList || Object.keys(formData).length === 0))
  ) {
    return (
      <Grid container spacing={6}>
        {matches ? (
          <Grid item xs={12} md={12}>
            <Paper elevation={0} style={{ borderRadius: 30 }}>
              <OutcomeBuilderSkeletonLoading />
            </Paper>
          </Grid>
        ) : (
          <Grid item xs={12} md={12}>
            <Paper elevation={0} style={{ borderRadius: 30 }}>
              <OutcomeBuilderSkeletonLoadingMobile />
            </Paper>
          </Grid>
        )}
      </Grid>
    );
  }
  return (
    <Grid container spacing={6}>
      <Grid item xs={12} md={8}>
        <OutcomeFormWrapper
          formData={formData}
          onSubmit={handleQuestionUpdate || handleSubmit}
          handleCancel={handleCancel}
          onDelete={handleDelete}
          details={details}
          activeLabTests={activeLabTests}
          type={type}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <OutcomePreview formData={formData} previewForBuilder selectedLanguage="eng" buttonType="continue" />
      </Grid>
    </Grid>
  );
}

export default withStyles(CreateFormStyles)(OutcomeBuilderWrapper);
