import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../api';

const initialState = {
  doordashLoading: 'idle',
  netsuiteLoading: 'idle',
  welcomeKitLoading: 'idle',
  welcomeKitData: null,
  error: null,
};

// SHOPIFY SIMULATOR
const shopifySimulatorSlice = createSlice({
  name: 'shopifySimulator',
  initialState,
  reducers: {
    resetNetsuiteLoading: (state) => {
      state.netsuiteLoading = 'idle';
    },
    resetWelcomeKitLoading: (state) => {
      state.welcomeKitLoading = 'idle';
    },
    resetWelcomeKitData: (state) => {
      state.welcomeKitData = null;
    },
  },
  extraReducers: (builder) => {
    /* Simulate Netsuite */
    builder.addCase(startSimulateNetsuite.pending, (state, action) => {
      if (action.meta.requestStatus === 'pending') {
        state.netsuiteLoading = 'pending';
      }
    });
    builder.addCase(startSimulateNetsuite.fulfilled, (state, action) => {
      if (action.meta.requestStatus === 'fulfilled') {
        state.netsuiteLoading = 'fulfilled';
      }
    });
    builder.addCase(startSimulateNetsuite.rejected, (state) => {
      state.netsuiteLoading = 'rejected';
    });
    /*  Load WelcomeKit Benefit */
    builder.addCase(loadWelcomeKit.pending, (state, action) => {
      if (action.meta.requestStatus === 'pending') {
        state.welcomeKitLoading = 'pending';
      }
    });
    builder.addCase(loadWelcomeKit.fulfilled, (state, action) => {
      if (action.meta.requestStatus === 'fulfilled' && action.payload[0].success) {
        state.welcomeKitLoading = 'fulfilled';
      }

      /* There will be NO data returned for  first time */
      state.welcomeKitData = action.payload[0]?.data ?? null;
    });
    builder.addCase(loadWelcomeKit.rejected, (state) => {
      state.welcomeKitLoading = 'rejected';
    });
    /* Update WelcomeKit Benefit */
    builder.addCase(updateWelcomeKit.pending, (state, action) => {
      if (action.meta.requestStatus === 'pending') {
        state.welcomeKitLoading = 'pending';
      }
    });
    builder.addCase(updateWelcomeKit.fulfilled, (state, action) => {
      if (action.meta.requestStatus === 'fulfilled' && action.payload[0].success) {
        state.welcomeKitLoading = 'fulfilled';
      }

      /* Note that the PATCH API does not return any data */
      // state.welcomeKitData = action.payload[0]?.data ?? null;
    });
    builder.addCase(updateWelcomeKit.rejected, (state) => {
      state.welcomeKitLoading = 'rejected';
    });
  },
});

export const { resetDoordashLoading, resetNetsuiteLoading, resetWelcomeKitLoading, resetWelcomeKitData } =
  shopifySimulatorSlice.actions;

export const startSimulateNetsuite = createAsyncThunk(
  'shopifySimulator/startSimulateNetsuite',
  async (payload, { rejectWithValue }) => {
    try {
      const { event, formData } = payload;
      const result = await api.postSimulateNetsuite(event, formData);

      return result;
    } catch (error) {
      if (!error.response) {
        throw new Error(error);
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const loadWelcomeKit = createAsyncThunk(
  'shopifySimulator/loadWelcomeKit',
  async (params, { rejectWithValue }) => {
    try {
      const { userId } = params;
      const result = await api.getSimulateWelcomeKit(userId);

      return result;
    } catch (error) {
      if (!error.response) {
        throw new Error(error);
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const updateWelcomeKit = createAsyncThunk(
  'shopifySimulator/updateWelcomeKit',
  async (params, { rejectWithValue }) => {
    try {
      const { userId, formData } = params;
      const result = await api.patchSimulateWelcomeKit(userId, formData);

      return result;
    } catch (error) {
      if (!error.response) {
        throw new Error(error);
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export default shopifySimulatorSlice.reducer;
