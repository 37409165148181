import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { useTheme } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import NoAccessView from '../../views/NoAccessView';
import Clinical from '../ClinicalInformation/Clinical';
import { selectTenantSettings } from '../Settings/selectors';
import AccessControl from '../Shared/AccessControl';
import { selectCurrentTenant } from '../Shared/selectors';
import { selectCurrentUserPermissions } from '../Users/selectors';

import RecordRequestDetail from './RecordRequestDetail';

function RecordRequest(props) {
  const { type } = props;
  const currentTenant = useSelector(selectCurrentTenant);
  const currentUserPermissions = useSelector(selectCurrentUserPermissions);
  const tenantSettings = useSelector(selectTenantSettings);
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  let pageComponent;
  useEffect(() => {
    const splitUrl = window.location.href.split('/');
    if (splitUrl[splitUrl.length - 1] !== '') {
      if (splitUrl[splitUrl.length - 1] === 'clinical' || splitUrl[splitUrl.length - 2] === 'clinical') {
        setValue(1);
      }
    }
  }, [type]);

  const handleChange = (e, value) => {
    setValue(value);
    let path;
    if (value === 1) {
      path = 'clinical';
    }
    const splitUrl = window.location.href.split('/');
    if (splitUrl[splitUrl.length - 3] === 'record-requests' || splitUrl[splitUrl.length - 3] === 'details') {
      if (value !== 0) {
        window.history.pushState(path, path, `${splitUrl.join('/')}/${path}`);
      }
    } else {
      splitUrl.pop();
      if (value === 0) {
        window.history.pushState(path, path, `${splitUrl.join('/')}`);
      } else {
        window.history.pushState(path, path, `${splitUrl.join('/')}/${path}`);
      }
    }
  };

  switch (value) {
    case 1:
      pageComponent =
        tenantSettings && tenantSettings.menuItems && tenantSettings.menuItems['record-requests/clinical'] ? (
          <AccessControl
            deniedPermissions={['ADMINISTRATOR_RESTRICTED']}
            renderNoAccess={() => <NoAccessView permissionsNeeded="Administrator" />}
          >
            <Clinical currentTenant={currentTenant} type={type} />
          </AccessControl>
        ) : (
          <NoAccessView />
        );
      break;
    default:
      pageComponent = <RecordRequestDetail currentTenant={currentTenant} type={type} />;
      break;
  }

  if (!currentTenant) return <div />;

  return (
    <Grid item xs={12}>
      <Grid item xs={4}>
        {matches ? (
          <Tabs
            value={value}
            indicatorColor="primary"
            variant="fullWidth"
            textColor="primary"
            onChange={handleChange}
            aria-label="disabled tabs example"
          >
            <Tab label="Record Request Detail" style={{ fontSize: '12px', marginBottom: '-10px' }} />
            {tenantSettings && tenantSettings.menuItems && tenantSettings.menuItems['recordrequest/clinical'] && (
              <Tab
                label="Clinical"
                id="clinical"
                style={{ fontSize: '12px', marginBottom: '-10px' }}
                disabled={currentUserPermissions && currentUserPermissions.permission === 'ADMINISTRATOR_RESTRICTED'}
              />
            )}
          </Tabs>
        ) : (
          <FormControl variant="outlined" fullWidth>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={value}
              onChange={(e) => setValue(e.target.value)}
            >
              <MenuItem value={0} key={0}>
                Record Request Detail
              </MenuItem>
              <MenuItem value={1} key={1}>
                Clinical
              </MenuItem>
            </Select>
          </FormControl>
        )}
      </Grid>
      <div style={{ marginTop: 10 }}>{pageComponent}</div>
    </Grid>
  );
}

export default RecordRequest;
