import React from 'react';

import { SHAPE_TYPES } from './constants';
import Rectangle from './Rectangle';

function Shape({ shape, selectedShapeId, setSelectedShapeId, transformRectangleShape }) {
  const isSelected = selectedShapeId == shape.id;
  if (shape.type === SHAPE_TYPES.RECT) {
    return (
      <Rectangle
        {...shape}
        isSelected={isSelected}
        setSelectedShapeId={setSelectedShapeId}
        transformRectangleShape={transformRectangleShape}
      />
    );
  }
  // else if (shape.type === SHAPE_TYPES.CIRCLE) {
  //   return <Circle {...shape} isSelected={isSelected} />;
  // }

  return null;
}

export default Shape;
