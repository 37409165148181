import { createSlice } from '@reduxjs/toolkit';

import api from '../../api';
import { showNotification } from '../Notifications/notificationSlice';
import { handleError } from '../Shared/Content/APIUtils';
import { setFormDeleting, setFormSubmitting } from '../Shared/Forms/formSlice';
import history from '../Shared/Redux/history';

const initialState = {
  loadingQuestionnaires: false,
  loadingSegments: false,
  loadingConfig: false,
  reporterAnswers: null,
  vcbVariablesList: [],
  validAnswer: false,
  invalidCode: false,
  executingQuestionnaire: false,
};

export const virtualConsultSlice = createSlice({
  name: 'virtualConsult',
  initialState,
  reducers: {
    setLoadingQuestionnaires: (state, { payload }) => ({
      ...state,
      loadingQuestionnaires: payload,
    }),
    getQuestionnairesReqSuccess: (state, { payload: { type, data } }) => ({
      ...state,
      [type]: data,
      loadingQuestionnaires: false,
    }),
    getSegmentRequest: (state) => ({
      ...state,
      loadingSegments: true,
    }),
    getSegmentSuccess: (state, { payload }) => ({
      ...state,
      segments: payload,
      loadingSegments: false,
    }),
    getSegmentFailure: (state) => ({
      ...state,
      loadingSegments: false,
    }),
    setQuestionnaireConfigLoading: (state, { payload }) => ({
      ...state,
      loadingConfig: payload,
    }),
    setQuestionnaireConfig: (state, { payload }) => ({
      ...state,
      questionnaireConfig: payload,
      loadingConfig: false,
    }),
    setGettingTagsRequest: (state, { payload }) => ({
      ...state,
      loadingConfig: payload,
    }),
    setGettingTagsSuccess: (state, { payload }) => ({
      ...state,
      tags: payload,
      loadingConfig: false,
    }),
    setGettingTagsFailure: (state, { payload }) => ({
      ...state,
      error: payload,
      loadingConfig: false,
    }),
    setAnswerForReport: (state, { payload: { reporterAnswers } }) => ({
      ...state,
      reporterAnswers,
    }),
    clearReporter: (state) => ({
      ...state,
      reporterAnswers: null,
    }),
    saveVCBVariablesList: (state, { payload: { vcbVariablesList } }) => ({
      ...state,
      vcbVariablesList,
    }),
    setVerifyCodeRequest: (state) => ({
      ...state,
      validAnswer: false,
      invalidCode: false,
      executingQuestionnaire: true,
    }),
    setVerifyCodeSuccess: (state) => ({
      ...state,
      validAnswer: true,
      invalidCode: false,
      executingQuestionnaire: false,
    }),
    setVerifyCodeFailure: (state) => ({
      ...state,
      validAnswer: false,
      invalidCode: true,
      executingQuestionnaire: false,
    }),
  },
});

export const {
  setLoadingQuestionnaires,
  getQuestionnairesReqSuccess,
  getSegmentRequest,
  getSegmentSuccess,
  getSegmentFailure,
  setQuestionnaireConfigLoading,
  setQuestionnaireConfig,
  setGettingTagsRequest,
  setGettingTagsSuccess,
  setGettingTagsFailure,
  setAnswerForReport,
  clearReporter,
  saveVCBVariablesList,
  setVerifyCodeRequest,
  setVerifyCodeSuccess,
  setVerifyCodeFailure,
} = virtualConsultSlice.actions;

export const getContent =
  (tenant, type, filter, sort, dir, page, size, tags = [], notSetStore) =>
  async (dispatch) => {
    if (!notSetStore) {
      dispatch(setLoadingQuestionnaires(true));
    }

    const params = new URLSearchParams();
    if (type === 'questions') {
      params.append('name', filter || '');
    } else {
      params.append('title', filter || '');
    }
    params.append('offset', page * size);
    params.append('page', page);
    params.append('size', size);
    params.append('sort', `${sort},${dir}`);
    tags.forEach((tag) => params.append('tag', tag));

    const [result, error] = await api.getQuestionnaireContentRequest(tenant, type, params);

    if (error && error.message) {
      dispatch(handleError(error));
      dispatch(setLoadingQuestionnaires(false));
    }

    if (result) {
      if (notSetStore) return result;

      dispatch(
        getQuestionnairesReqSuccess({
          type,
          data: result,
        })
      );
      return result;
    }
  };

export const getUserSegments = (tenant) => async (dispatch) => {
  dispatch(getSegmentRequest());

  const [result, error] = await api.getUserSegment(tenant);

  if (error && error.message) {
    dispatch(handleError(error));
    dispatch(getSegmentFailure());
  }

  if (result) {
    dispatch(getSegmentSuccess(result));
  }
};

export const deleteQuestion = (tenant, id) => async (dispatch) => {
  dispatch(setFormDeleting(true));
  const [result, error] = await api.deleteQuestionRequest(tenant, id);

  if (error && error.message) {
    dispatch(setFormDeleting(false));
    dispatch(handleError(error));
  }

  if (result?.isOK) {
    dispatch(showNotification('Question successfully deleted', 'success'));
    dispatch(getContent(tenant, 'questions', '', 'updated', 'desc', 0, 10));
    history.push(`/${tenant}/virtual-consult/questions`);
  }
};

export const deleteQuestionnaire = (tenant, id) => async (dispatch) => {
  dispatch(setFormDeleting(true));
  const [result, error] = await api.deleteQuestionnaireRequest(tenant, id);

  if (error && error.message) {
    dispatch(setFormDeleting(false));
    dispatch(handleError(error));
  }

  if (result) {
    dispatch(showNotification('Questionnaire successfully deleted', 'success'));
    history.push(`/${tenant}/virtual-consult/questionnaires`);
  }
};

export const deleteOutcome = (tenant, id) => async (dispatch) => {
  dispatch(setFormDeleting(true));

  const [result, error] = await api.deleteOutcomeRequest(tenant, id);

  if (error && error.message) {
    dispatch(setFormDeleting(false));
    dispatch(handleError(error));
  }

  if (result?.isOK) {
    dispatch(showNotification('Outcome successfully deleted', 'success'));
    dispatch(getContent(tenant, 'outcomes', '', 'title', 'asc', 0, 10));
    history.push(`/${tenant}/virtual-consult/outcomes`);
  }
};

export const deleteUserSegment = (tenant, segmentId) => async (dispatch) => {
  dispatch(getSegmentRequest());
  const [result, error] = await api.deleteUserSegmentRequest(tenant, segmentId);

  if (error && error.message) {
    dispatch(handleError(error));
    dispatch(getSegmentFailure());
    return false;
  }

  if (result) {
    dispatch(showNotification('User Segment successfully deleted', 'success'));
    await dispatch(getUserSegments(tenant));
    return true;
  }
};

export const bulkUpload = (type, tenant, data, sort, dir) => async (dispatch) => {
  const [result, error] = await api.bulkUploadQuestionnaire(tenant, type, data);

  if (error && error.message) {
    dispatch(handleError(error));
  }

  if (result) {
    dispatch(showNotification('Import Successful', 'success'));
    dispatch(getContent(tenant, type, '', sort, dir, 0, 10));
  }
};

export const getQuestionnaireConfig = (tenant) => async (dispatch) => {
  dispatch(setQuestionnaireConfigLoading(true));
  const [result, error] = await api.getQuestionnaireConfigRequest(tenant);

  if (error && error.message) {
    dispatch(handleError(error));
    dispatch(setQuestionnaireConfigLoading(false));
  }

  if (result) {
    dispatch(setQuestionnaireConfig(result));
  }
};

export const updateQuestionnaireConfig = (tenant, config) => async (dispatch) => {
  dispatch(setQuestionnaireConfigLoading(true));
  const [result, error] = await api.updateQuestionnaireConfigRequest(tenant, config);

  if (error && error.message) {
    dispatch(handleError(error));
    dispatch(setQuestionnaireConfigLoading(false));
  }

  if (result) {
    dispatch(setQuestionnaireConfig(result));
  }
};

export const createOutcome = (tenant, data) => async (dispatch) => {
  const [result, error] = await api.createOutcomeRequest(tenant, data);

  if (error && error.message) {
    dispatch(handleError(error));
  }

  if (result) {
    dispatch(showNotification('Outcome successfully created', 'success'));
    history.push(`/${tenant}/virtual-consult/outcomes`);
  }
};

export const updateOutcome = (tenant, data, id) => async (dispatch) => {
  const [result, error] = await api.updateOutcomeRequest(tenant, data, id);

  if (error && error.message) {
    dispatch(handleError(error));
  }

  if (result) {
    dispatch(showNotification('Outcome successfully updated', 'success'));
    history.push(`/${tenant}/virtual-consult/outcomes`);
  }
};

export const getTags = (tenant, query) => async (dispatch) => {
  dispatch(setGettingTagsRequest(true));
  const [result, error] = await api.getTagsRequest(tenant, query);

  if (error && error.message) {
    dispatch(setGettingTagsFailure(error.message));
    dispatch(handleError(error));
  }

  if (result?.content) {
    dispatch(setGettingTagsSuccess(result.content));
  }
};

export const getContentById = (tenant, type, id, notUpdateStore) => async (dispatch) => {
  if (!notUpdateStore) dispatch(setLoadingQuestionnaires(true));

  const [result, error] = await api.getContentByIdRequest(tenant, type, id);

  if (error && error.message) {
    dispatch(setLoadingQuestionnaires(false));
    dispatch(handleError(error));
  }

  if (result) {
    if (!notUpdateStore) {
      dispatch(setFormSubmitting(false));
      dispatch(
        getQuestionnairesReqSuccess({
          type: type.slice(0, -1),
          data: result,
        })
      );
    }
    return result;
  }

  return null;
};

export const createQuestion = (tenant, data) => async (dispatch) => {
  const [result, error] = await api.createQuestionRequest(tenant, data);

  if (error && error.message) {
    dispatch(handleError(error));
  }

  if (result) {
    dispatch(showNotification('Question successfully created', 'success'));
    history.push(`/${tenant}/virtual-consult/questions`);
  }
};

export const updateQuestion = (tenant, data, id) => async (dispatch) => {
  const [result, error] = await api.updateQuestionRequest(tenant, id, data);

  if (error && error.message) {
    dispatch(handleError(error));
  }

  if (result) {
    dispatch(showNotification('Question successfully updated', 'success'));
    history.push(`/${tenant}/virtual-consult/questions`);
  }
};

export const createQuestionnaire = (tenant, data) => async (dispatch) => {
  const [result, error] = await api.createQuestionnaireRequest(tenant, data);

  if (error && error.message) {
    dispatch(handleError(error));
  }

  if (result) {
    dispatch(showNotification('Questionnaire successfully created', 'success'));
    return result;
  }
};

export const updateQuestionnaire = (tenant, data, id, notify, redirect) => async (dispatch) => {
  const [result, error] = await api.updateQuestionnaireRequest(tenant, id, data);

  if (error && error.message) {
    dispatch(handleError(error));
    if (!redirect) return;
    history.push(`/${tenant}/virtual-consult/questionnaires`);
  }

  if (result) {
    if (redirect) {
      dispatch(showNotification('Questionnaire Saved', 'success'));
      history.push(`/${tenant}/virtual-consult/questionnaires`);
    } else if (notify) {
      dispatch(showNotification('Saved', 'success'));
    }
    dispatch(setFormSubmitting(false));
  }
};

export const createUserSegment = (tenant, data) => async (dispatch) => {
  dispatch(getSegmentRequest());

  const [result, error] = await api.createUserSegmentRequest(tenant, data);

  if (error && error.message) {
    dispatch(handleError(error));
    dispatch(getSegmentFailure());
    return false;
  }

  if (result) {
    await dispatch(getUserSegments(tenant));
    dispatch(showNotification('User Segment successfully created', 'success'));
    return true;
  }
};

export const updateUserSegment = (tenant, segment, editPoint) => async (dispatch) => {
  dispatch(getSegmentRequest());

  const [result, error] = await api.updateUserSegmentRequest(tenant, segment);

  if (error && error.message) {
    dispatch(handleError(error));
    dispatch(getSegmentFailure());
    return false;
  }

  if (result) {
    if (editPoint === 'settings') {
      await dispatch(getUserSegments(tenant));
    } else {
      dispatch(getSegmentSuccess(result));
      history.push(`/${tenant}/virtual-consult/user-segments`);
    }
    dispatch(showNotification('User Segment successfully updated', 'success'));

    return true;
  }
};

export const verifyAuthenticationCode = (request, verificationType, tenant) => async (dispatch) => {
  dispatch(setVerifyCodeRequest());

  const [result, error] = await api.verifyAuthenticationCodeRequest(tenant, request);

  if (error && error.message) {
    dispatch(setVerifyCodeFailure());
    dispatch(handleError(error));
    return false;
  }

  if (result?.success) {
    dispatch(
      setVerifyCodeSuccess({
        data: result,
        email: verificationType === 'email' ? request.email : '',
        phoneNumber: verificationType === 'phone' ? request.phone : '',
      })
    );
  } else {
    dispatch(setVerifyCodeFailure());
  }
};

export default virtualConsultSlice.reducer;
