import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import React from 'react';

// mui

function CheckBoxCell(props) {
  const { dataItem, onChange, disabled } = props;

  const checkBoxDisabled = disabled || dataItem.addedToQuestionnaire;
  const checked = dataItem.checked || dataItem.selected || dataItem.addedToQuestionnaire;

  const handleChange = (e) => {
    if (onChange) {
      onChange(dataItem, e.target.checked);
    }
  };

  return (
    <td>
      <Grid container alignItems="center">
        <Grid item>
          <Checkbox
            checked={checked}
            style={{
              color: checked ? 'white' : 'primary',
            }}
            onChange={handleChange}
            disabled={checkBoxDisabled}
          />
        </Grid>
      </Grid>
    </td>
  );
}

export default CheckBoxCell;
