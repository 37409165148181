export const tenantTypeMapping = [
  {
    label: 'Enterprise',
    value: '0',
  },
  {
    label: 'SMB',
    value: '1',
  },
  {
    label: 'Consumer',
    value: '2',
  },
];
