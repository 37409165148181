import { lsClient } from '../../../../ls-client';
import { lsClient as vcbLsClient } from '../ls-client';

import httpClient from './httpClient';

const { REACT_APP_VCB_TENANT, REACT_APP_VCB_QUESTIONNAIRE } = process.env;

const API = {
  async registerAnonymousUserRequest(tenantId) {
    const tenant = tenantId || REACT_APP_VCB_TENANT;
    return doHttpCall('GET', `/virtual-consult/v1/${tenant}/anonymous`, {}, {}, false);
  },
  async startQuestionnaireRequest(questionnaireId, tenantId, testSiteVariables) {
    const questionnaire = questionnaireId || REACT_APP_VCB_QUESTIONNAIRE;
    const tenant = tenantId || REACT_APP_VCB_TENANT;
    return doHttpCall(
      'POST',
      `/virtual-consult/v1/${tenant}/questionnaires/start`,
      testSiteVariables,
      { questionnaire },
      true
    );
  },
  async getNextQuestionRequest(tenantId, executionId, data) {
    const tenant = tenantId || REACT_APP_VCB_TENANT;
    return doHttpCall('POST', `/virtual-consult/v1/${tenant}/questionnaires/${executionId}/answer`, data, {}, true);
  },
  async getPreviousQuestionRequest(tenantId, executionId) {
    const tenant = tenantId || REACT_APP_VCB_TENANT;
    return doHttpCall('GET', `/virtual-consult/v1/${tenant}/questionnaires/${executionId}/back`);
  },
  async getCurrentQuestionRequest(tenantId, executionId) {
    const tenant = tenantId || REACT_APP_VCB_TENANT;
    return doHttpCall('GET', `/virtual-consult/v1/${tenant}/questionnaires/${executionId}/node`);
  },
  async sendVerificationCodeRequest(tenantId, data) {
    const tenant = tenantId || REACT_APP_VCB_TENANT;
    return doHttpCall('POST', `/vcb-ehr/v1/member/verification/SendAuthenticationCode/${tenant}`, data);
  },
  async verifyAuthenticationCodeRequest(tenantId, data) {
    const tenant = tenantId || REACT_APP_VCB_TENANT;
    return doHttpCall('POST', `/vcb-ehr/v1/member/verification/CheckAuthenticationCode/${tenant}`, data);
  },
  async jumpDirectlyToNodeRequest(tenantId, executionId, nodeId) {
    const tenant = tenantId || REACT_APP_VCB_TENANT;
    return doHttpCall('GET', `/virtual-consult/v1/${tenant}/questionnaires/${executionId}/node/${nodeId}`);
  },
  async getQuestionnaireResultRequest(tenantId, executionId) {
    const tenant = tenantId || REACT_APP_VCB_TENANT;
    return doHttpCall('GET', `/virtual-consult/v1/${tenant}/questionnaires/${executionId}/result`);
  },
  async verifyInsuranceRequest(tenantId, verificationObject) {
    const tenant = tenantId || REACT_APP_VCB_TENANT;
    return doHttpCall('POST', `/vcb-ehr/v1/SetInsurance/${tenant}`, verificationObject);
  },
  async getPayorListRequest(tenantId) {
    const tenant = tenantId || REACT_APP_VCB_TENANT;
    return doHttpCall('GET', `/vcb-ehr/v1/PayorList/${tenant}`);
  },
  async uploadImageToS3(file) {
    return doHttpCall('UPLOAD', '/virtual-consult/v1/upload/answer/photo', file);
  },
};

export default API;

function createResponse(result, error) {
  return [result, { message: error }];
}

async function doHttpCall(method, url, payload, params, requireToken = true) {
  try {
    const accessToken = lsClient.tokens.getIdToken();
    const idToken = accessToken && lsClient.tokens.getIdToken();
    const accountId = localStorage.getItem('accountId');
    const tenantId = localStorage.getItem('tenantId');
    let anonymousToken = '';

    if (!accessToken) {
      anonymousToken = vcbLsClient.questionnaire.getAnonUserAuthToken('anonUserAuthToken');
    }

    let result = null;
    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json;charset=UTF-8',
      Accept: 'application/json',
      'X-SF-ACCOUNT': accountId,
      'X-SF-TENANT': tenantId,
    };

    if (method === 'UPLOAD') {
      headers['Content-Type'] = 'multipart/form-data';
    }

    // Default to token for signed-in user, even if anonymous is allowed
    if (requireToken && accessToken) {
      headers.Authorization = `Bearer ${accessToken}`;
      headers.Id_Token = idToken;
    } else if (requireToken && anonymousToken) {
      headers['Authorization-anon'] = `Bearer ${anonymousToken}`;
    }

    if (method === 'GET') {
      result = await httpClient.get(url, { headers, params });
    } else if (method === 'POST') {
      result = await httpClient.post(url, { ...payload }, { headers, params });
    } else if (method === 'UPLOAD') {
      result = await httpClient.post(url, payload, { headers, params });
    }

    if (!(result && result.data)) {
      return createResponse(null, 'Data not found');
    }

    return createResponse(result.data);
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        /* eslint-disable-next-line no-console */
        console.log('Unauthorized');
      }
    } else if (error.request) {
      /* eslint-disable-next-line no-console */
      console.log(error.request);
    } else {
      /* eslint-disable-next-line no-console */
      console.log('Error', error.message);
    }
    return createResponse(null, 'Unknown error');
  }
}
