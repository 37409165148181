// Returns a date that is calculated to be a certain number of days, months, or years
// from the current date
import moment from 'moment';

export const getCalculatedDate = (precision, value) => {
  const precisionForMoment = precision.toLowerCase();
  // 'value' can be negative, which accounts for subtraction
  const calculatedDate = moment().add(value, precisionForMoment).format();
  return new Date(calculatedDate);
};

export const getDateByPrecision = (precision, value) => {
  switch (precision) {
    case 'DAYS':
      return getDaysDuration(value);
    case 'WEEKS':
      return getWeeksDuration(value);
    case 'MONTHS':
      return getMonthsDuration(value);
    case 'YEARS':
      return getYearsDuration(value);
    default:
  }
};

const getDaysDuration = (value) => {
  // The number of milliseconds in one day
  const ONE_DAY = 1000 * 60 * 60 * 24;
  const today = new Date();
  const valueDate = new Date(value);

  // Calculate the difference in milliseconds
  const differenceMs = Math.abs(today - valueDate);

  // Convert back to days and return
  return Math.round(differenceMs / ONE_DAY);
};

const getWeeksDuration = (value) => {
  // The number of milliseconds in one week
  const ONE_WEEK = 7 * 24 * 60 * 60 * 1000;
  const today = new Date();
  const valueDate = new Date(value);

  // Calculate the difference in milliseconds
  const differenceMs = Math.abs(today - valueDate);

  // Convert back to weeks and return
  return Math.round(differenceMs / ONE_WEEK);
};

const getMonthsDuration = (value) => {
  const today = new Date();
  const valueDate = new Date(value);

  const year1 = valueDate.getFullYear();
  const year2 = today.getFullYear();
  let month1 = valueDate.getMonth();
  let month2 = today.getMonth();

  if (month1 === 0) {
    // Have to take into account
    month1++;
    month2++;
  }
  return (year2 - year1) * 12 + (month2 - month1);
};

const getYearsDuration = (value) => {
  const today = new Date();
  const valueDate = new Date(value);

  const ageDifMs = today - valueDate;
  const ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};
