import { SvgIcon } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
// import _ from 'lodash';
import React from 'react';

import Parent from '../../assets/img/parent.svg';

function UsersParentCell(props) {
  const { dataItem } = props;
  return (
    <td>
      <p>
        {dataItem && dataItem.isGuardianMode && (
          <Tooltip title="Guardian">
            <SvgIcon fontSize={'small'}>
              <Parent />
            </SvgIcon>
          </Tooltip>
        )}
      </p>
    </td>
  );
}

export default UsersParentCell;
