import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// components and helpers
import AppTabbedPageWrapper from '../Shared/Pages/TabbedPageWrapper';
import { selectCurrentTenant } from '../Shared/selectors';
import AssetsInfoBox from './AssetsInfoBox';
import NetworkFormWrapper from './NetworkFormWrapper';
import { selectTenantNetwork } from './selectors';

function NetworkPageWrapper(props) {
  const { type, assetsType } = props;
  const currentTenant = useSelector(selectCurrentTenant);
  const currentNetwork = useSelector(selectTenantNetwork);
  const navigate = useNavigate();

  if (!currentNetwork?.tenantID || !currentTenant) return null;

  const tabs = [
    {
      label: 'Tenant Info',
      type: 'tenant-info',
      url: 'settings/configuration/tenant-info',
      content: <NetworkFormWrapper currentTenant={currentTenant} type={type} />,
      show: true,
    },
    {
      label: 'Settings',
      type: 'settings',
      url: 'settings/configuration/settings',
      // Show/Hide value set in Platform Tenant Settings
      enable: 'tenantDisplaySettings',
      content: <NetworkFormWrapper currentTenant={currentTenant} type={type} />,
      show: true,
    },
    {
      label: 'Assets',
      type: 'assets',
      url: `settings/configuration/assets/${assetsType || 'graphic-assets'}`,
      content: <AssetsInfoBox currentTenant={currentTenant} type={type} assetsType={assetsType} />,
      show: true,
    },
    {
      label: 'Health Protocol',
      type: 'health-protocol',
      url: 'settings/configuration/health-protocol',
      content: <NetworkFormWrapper currentTenant={currentTenant} type={type} />,
      show: true,
    },
  ];

  const handleTabChange = (tabDetails) => {
    navigate(`/${currentTenant && currentTenant.id}/${tabDetails.url}`);
  };

  // eslint-disable-next-line array-callback-return
  const tabsToRender = tabs.filter((t) => {
    if (t.show) {
      if (t.enable) {
        return currentNetwork[t.enable] && t;
      }
      return t;
    }
  });

  return (
    <AppTabbedPageWrapper
      tabs={tabsToRender}
      openTab={tabsToRender.find((t) => t.type === type)}
      onTabChange={handleTabChange}
    />
  );
}

export default NetworkPageWrapper;
