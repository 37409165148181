/* eslint-disable no-unused-expressions */
/* eslint-disable import/namespace */
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

// redux

import Logo from '../../assets/img/logo.png';

import NavBarStyles from '../../assets/jss/components/NavBarStyles';
import { selectAccountDetails, selectAccountDetailsLoading } from '../Account/selectors';
import { readAllAlerts } from '../Alerts/alertsSlice';
import { selectMenuAlerts } from '../Alerts/selectors';
import { getUserFromToken } from '../Auth/helpers';
import { selectAuthToken } from '../Auth/selectors';
import { resetSettings } from '../Settings/settingsSlice';
import AccessControl from '../Shared/AccessControl';
import {
  selectAllTenants,
  selectAllTenantsLoading,
  selectCurrentTenant,
  selectCurrentTenantId,
} from '../Shared/selectors';
import { setCurrentTenant, logout } from '../Shared/sharedSlice';
import {
  selectCurrentUser,
  selectProfilePicture,
  selectTenantImports,
  selectCurrentUserLoading,
} from '../Users/selectors';
import { getUserPic, resetUsers } from '../Users/usersSlice';

// component imports
import AlertMenu from './AlertMenu';
import DesktopMenu from './DesktopMenu';
import MobileMenu from './MobileMenu';
import TenantDropdown from './TenantDropdown';

export function Navbar(props) {
  const { classes } = props;

  const isAuthenticated = useSelector(selectAuthToken);
  const currentTenant = useSelector(selectCurrentTenant);
  const currentTenantId = useSelector(selectCurrentTenantId);
  const allTenantsLoading = useSelector(selectAllTenantsLoading);
  const allTenants = useSelector(selectAllTenants);
  const tenantImports = useSelector(selectTenantImports);
  const profilePicture = useSelector(selectProfilePicture);
  const currentUser = useSelector(selectCurrentUser);
  const userDetailsLoading = useSelector(selectCurrentUserLoading);
  const accountLoading = useSelector(selectAccountDetailsLoading);
  const account = useSelector(selectAccountDetails);
  const tenantAlerts = useSelector(selectMenuAlerts);
  const [unreadAlert, setUnreadAlert] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const doLogout = () => {
    dispatch(logout(navigate));
  };

  const user = getUserFromToken();

  // url for homepage
  const homePage = `/${currentTenantId}`;

  // check if profile picture, if no picture get for navbar image
  useEffect(() => {
    if (user && !profilePicture && currentTenant) {
      dispatch(getUserPic(user.email, true));
    }
  }, [profilePicture, currentTenant]);

  // on alert update, check for any unread alerts to trigger alert badge
  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    tenantAlerts &&
      tenantAlerts.items.map((alert) => {
        if (!alert.read) {
          setUnreadAlert(true);
        }
        return null;
      });
  }, [tenantAlerts]);

  // change current tenant
  const handleChangeTenant = (value) => {
    if (value === currentTenantId) return;

    allTenants.items.map((tenant) => {
      if (tenant.id === value && tenant.id !== currentTenantId) {
        // reset some redux content to not carry over into newly selected tenant
        dispatch(resetUsers());
        dispatch(resetSettings());
        dispatch(setCurrentTenant(tenant));
      }
      return null;
    });
    navigate(`/${value}`);
  };

  const handleReadAlerts = () => {
    let pageSize;
    if (window.outerWidth < 961) {
      pageSize = 6;
    } else {
      pageSize = 10;
    }
    dispatch(readAllAlerts(currentTenantId, pageSize));
    setUnreadAlert(false);
  };

  if (allTenantsLoading || userDetailsLoading || accountLoading || !isAuthenticated) {
    return (
      <AppBar className={classes.navBar} position="fixed">
        <Toolbar>
          <Grid item />
        </Toolbar>
      </AppBar>
    );
  }

  return (
    <AppBar className={classes.navBar} position="fixed">
      <Toolbar>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item>
            <Grid container spacing={4} alignItems="center">
              <Grid item className={classes.logoWrapper}>
                <Link to={homePage}>
                  <img
                    className={classes.logo}
                    src={currentTenant?.logoURL || Logo}
                    style={{ width: 50, padding: 0 }}
                    alt="Tenant Logo"
                  />
                </Link>
              </Grid>
              {currentTenant && (
                <Grid item className={classes.tenantGrid} xs={8} style={{ paddingLeft: 0 }}>
                  <TenantDropdown
                    currentTenant={currentTenant}
                    handleChangeTenant={handleChangeTenant}
                    allTenantsLoading={allTenantsLoading}
                    allTenants={allTenants}
                    account={account}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid className={classes.navigationLinks} item style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {/* <div className={classes.sectionDesktop}> */}
            {/*  <LanguageMenu /> */}
            {/* </div> */}
            {currentUser && (
              <div className={classes.sectionDesktop}>
                <AccessControl allowedPermissions={['ADMINISTRATOR']} renderNoAccess={() => <div />}>
                  <AlertMenu
                    alerts={tenantAlerts}
                    imports={tenantImports}
                    currentTenant={currentTenant && currentTenant.id}
                    handleReadAlerts={handleReadAlerts}
                    unreadAlert={unreadAlert}
                  />
                </AccessControl>
                <DesktopMenu currentTenant={currentTenant} user={currentUser} logout={doLogout} />
              </div>
            )}

            <div className={classes.sectionMobile}>
              <AccessControl allowedPermissions={['ADMINISTRATOR']} renderNoAccess={() => <div />}>
                <AlertMenu
                  alerts={tenantAlerts}
                  imports={tenantImports}
                  currentTenant={currentTenant && currentTenant.id}
                  handleReadAlerts={handleReadAlerts}
                  unreadAlert={unreadAlert}
                />
              </AccessControl>
              <MobileMenu
                user={currentUser}
                currentTenant={currentTenant}
                handleChangeTenant={handleChangeTenant}
                allTenantsLoading={allTenantsLoading}
                allTenants={allTenants}
                logout={doLogout}
              />
            </div>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

export default withStyles(NavBarStyles)(Navbar);
