import Grid from '@material-ui/core/Grid';
import React, { useCallback, useEffect, useState } from 'react';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { selectCurrentTenant } from '../Shared/selectors';
import AppKendoTableWrapper from '../Shared/Table/KendoTableWrapper';
import utils from '../Shared/Utils/utils';

import CreateRecordRequestDialog from './CreateRecordRequestDialog';
import { getAllRecordRequests } from './recordRequestSlice';
import { selectAllRecordRequests, selectAllRecordRequestsLoading } from './selectors';

// components and helpers

// kendo

function RecordRequestList() {
  const currentTenant = useSelector(selectCurrentTenant);
  const allRecordRequests = useSelector(selectAllRecordRequests);
  const allRecordRequestsLoading = useSelector(selectAllRecordRequestsLoading);

  const [pagedRecordRequestList, setPagedRecordRequestList] = useState([]);
  const [listTotal, setListTotal] = useState(0);
  const [dialogOpen, setDialogOpen] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const tableButtons = [
    {
      title: 'Add New',
      buttonType: 'action',
      variant: 'outlined',
      action: () => {
        setDialogOpen(true);
      },
    },
  ];
  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const initialSorting = {
    field: 'dateCreated',
    dir: 'desc',
  };

  const params = {
    pageNumber: 0,
    pageSize: 10,
    sort: initialSorting.field,
    direction: initialSorting.dir,
    searchParam: '',
  };

  useEffect(() => {
    dispatch(getAllRecordRequests(currentTenant.id, params));
  }, [currentTenant]);

  useEffect(() => {
    if (allRecordRequests && allRecordRequests.data) {
      setPagedRecordRequestList(allRecordRequests.data);
      setListTotal(allRecordRequests.total);
    }
  }, [allRecordRequests]);

  const handleTableUpdate = (page, pageSize, sort, direction, filter) => {
    params.pageNumber = page;
    params.pageSize = pageSize;
    params.sort = sort;
    params.direction = direction;
    params.searchParam = filter;
    dispatch(getAllRecordRequests(currentTenant.id, params));
  };

  // onRowClick function in table
  const handleRowClick = useCallback(
    (e) => {
      navigate(`/${currentTenant.id}/record-requests/details/${e.userEmailId}/${e.id}`);
    },
    [currentTenant]
  );

  // columns to pass to table
  const columns = [
    {
      id: 'id',
      label: 'ID #',
      show: true,
      locked: true,
      minWidth: 170,
    },
    {
      id: 'nationalHealthId',
      label: 'National Health ID',
      show: true,
      filter: true,
      minWidth: 155,
    },
    {
      id: 'userEmailId',
      label: 'User Email',
      show: true,
      filter: true,
      minWidth: 170,
    },
    {
      id: 'recordRequestStatusStr',
      label: 'Status',
      show: true,
      minWidth: 150,
    },
    {
      id: 'recordRequestTypeStr',
      label: 'Type',
      show: true,
      minWidth: 135,
    },
    {
      id: 'provider.name',
      label: 'Provider Name',
      show: true,
      filter: true,
      minWidth: 170,
    },
    {
      id: 'dateCreated',
      label: 'Date Created',
      show: true,
      filter: true,
      minWidth: 150,
      sortable: true,
    },
    {
      id: 'dateModified',
      label: 'Date modified',
      show: true,
      filter: true,
      minWidth: 150,
      sortable: true,
    },
  ];
  const filters = columns && columns.filter((c) => c.filter);

  const data = {
    ...pagedRecordRequestList,
    total: listTotal,
    items:
      pagedRecordRequestList &&
      pagedRecordRequestList.map((recordRequest) => ({
        ...recordRequest,
        id: recordRequest.id,
        nationalHealthId: recordRequest.nationalHealthId,
        userEmailId: recordRequest.userEmailId,
        recordRequestStatusStr: recordRequest.recordRequestStatusStr,
        recordRequestTypeStr: recordRequest.recordRequestTypeStr,
        provider: recordRequest.provider,
        dateCreated: utils.formatDateTime(recordRequest.dateCreated),
        dateModified: utils.formatDateTime(recordRequest.dateModified),
      })),
  };

  if (!currentTenant) return <div />;

  return (
    <Grid container>
      <AppKendoTableWrapper
        data={data}
        initialSort={initialSorting.field}
        initialDir={initialSorting.dir}
        showSearchbar
        filters={filters}
        columns={columns}
        onTableUpdate={handleTableUpdate}
        currentTenant={currentTenant}
        loading={allRecordRequestsLoading}
        onRowClick={handleRowClick}
        showButtons
        tableButtons={tableButtons}
      />
      {dialogOpen && (
        <CreateRecordRequestDialog
          currentTenant={currentTenant}
          dialogOpen={dialogOpen}
          closeDialog={handleCloseDialog}
        />
      )}
    </Grid>
  );
}

export default RecordRequestList;
