export const statusMapping = {
  0: {
    label: 'Inactive',
    background: 'red',
    color: 'white',
  },
  1: {
    label: 'Active',
    background: 'green',
    color: 'white',
  },
};

export const homeScreenButtonLabelMapping = ['Symptom Checker', 'Take A Test', 'Custom Label'];
