/* eslint-disable import/no-cycle */
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Error } from '@progress/kendo-react-labels';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import styles from '../../../assets/Questionnaire.module.css';
import { getPayorList } from '../../../questionnaireSlice';
import { selectPayorList, selectIsVerifiedInsurance } from '../../../selectors';
import { createInputHelper } from '../../createInputHelper';
import utils from '../../utils/utils';

import { insuranceForm, validateRequiredFields } from './insuranceInputHelper';

function InsuranceInput(props) {
  const { field, handleChangeWithParams, selectedLanguage } = props;
  const params = useParams();
  const tenantId = params.tenant;
  const verifiedInsurance = useSelector(selectIsVerifiedInsurance);
  const payorList = useSelector(selectPayorList);
  const [currentLanguageFields, setCurrentLanguageFields] = useState({
    name: '',
    description: '',
  });
  const [loading, setLoading] = useState(false);
  const [currentValue, setCurrentValue] = useState({
    subscriber: { relation: 'Self' },
    'subscriber-relation': 'Self',
  });

  const dispatch = useDispatch();

  // Retrieve insurance provider list upon initial load
  useEffect(() => {
    (async () => {
      setLoading(true);
      await dispatch(getPayorList(tenantId));
      setLoading(false);
    })();
  }, [dispatch, tenantId]);

  // Set values for currently selected language
  useEffect(() => {
    if (field && field.name) {
      const newLanguageName =
        selectedLanguage && field.name && field.name.translations
          ? field.name.translations[selectedLanguage]
          : field.label || field.name;
      const newLanguageDescription =
        selectedLanguage && field.description && field.description.translations
          ? field.description.translations[selectedLanguage]
          : field.description;
      setCurrentLanguageFields({
        name: newLanguageName,
        description: newLanguageDescription,
      });
    }
  }, [selectedLanguage, field]);

  // Change handler
  const onChange = (e, value) => {
    const name = e.target ? e.target.name : e;
    if (!value && typeof value !== 'boolean') {
      value = e.target?.value;
    }
    const updatedState = { ...currentValue, [name]: value };

    // Assemble subscriber keys into expected 'subscriber' object
    if (name === 'subscriber-firstName' || name === 'subscriber-lastName' || name === 'subscriber-relation') {
      const subscriberKey = name.split('-')[1];
      updatedState.subscriber[subscriberKey] = value;
      updatedState.subscriber.name = `${updatedState.subscriber.firstName} ${updatedState.subscriber.lastName}`;
    }
    setCurrentValue(updatedState);

    // Activate Continue if all fields are present
    const allFieldsEntered = validateRequiredFields(updatedState);
    if (allFieldsEntered) handleChangeWithParams(name, updatedState);
  };

  if (loading) {
    return (
      <Grid container justifyContent="center" style={{ paddingBottom: 25 }}>
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <div>
      <Grid container style={{ position: 'relative' }}>
        <Grid item xs={12} style={{ width: '100%' }}>
          {currentLanguageFields.name && (
            <Typography className={field.nameStyle ? styles[`title--${field.nameStyle}`] : styles['title--style1']}>
              {field.questionPreview ? utils.renderHTMLTags(currentLanguageFields.name) : currentLanguageFields.name}
              {field.required && '*'}
            </Typography>
          )}
          {field.image && (
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <img
                src={field.image}
                alt={currentLanguageFields.name}
                style={{
                  display: 'block',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  width: '90%',
                }}
              />
            </Grid>
          )}
          {currentLanguageFields.description && (
            <Typography
              className={
                field.descriptionStyle
                  ? styles[`description--${field.descriptionStyle}`]
                  : styles['description--style1']
              }
            >
              {utils.renderHTMLTags(currentLanguageFields.description)}
            </Typography>
          )}
        </Grid>
        {createInputHelper(insuranceForm(payorList, selectedLanguage), currentValue, onChange, onChange)}
      </Grid>
      {verifiedInsurance === false && <Error style={{ marginTop: 20 }}>Invalid Insurance Data</Error>}
    </div>
  );
}

export default InsuranceInput;
