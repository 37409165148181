import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Input } from '@progress/kendo-react-inputs';
import React, { useState, useEffect } from 'react';

import styles from '../../assets/Questionnaire.module.css';
import utils from '../utils/utils';

const data = [
  {
    text: 'I am the person getting tested',
    value: 'patient',
  },
  {
    text: 'I am the Parent/Guardian',
    value: 'parent_guardian',
  },
  {
    text: 'I am the Power of Attorney',
    value: 'power_of_attorney',
  },
];

// ui reflection of file view
function ConsentForm(props) {
  const { field, handleChangeWithParams, selectedLanguage } = props;
  const classes = useStyles();
  const [checked, setChecked] = useState(false);
  const [signedBy, setSignedBy] = useState(data[0]);
  const [fullName, setFullName] = useState('');

  useEffect(() => {
    let consentData = null;
    if (signedBy && signedBy.value && fullName && checked) {
      consentData = [signedBy.value, fullName];
    }
    handleChangeWithParams(field.name, consentData);
    // eslint-disable-next-line
  }, [fullName, signedBy, checked]);

  const handleChecked = (event) => {
    const { checked } = event.target;
    setChecked(checked);
  };

  const answer = {
    eng: 'I consent',
    spa: 'Yo consiento',
    zho: '我同意',
    rus: 'Я согласен',
    fre: "J'accepte",
  };

  const handleValueChange = (e) => {
    setSignedBy(e.value);
  };

  const handleChangeFullName = (e) => {
    const { value } = e.target;
    setFullName(value);
  };

  const fieldTitle =
    selectedLanguage && field.name && field.name.translations
      ? field.name.translations[selectedLanguage]
      : field.label || field.name;
  const questionDescription =
    selectedLanguage && field.description && field.description.translations
      ? field.description.translations[selectedLanguage]
      : field.description;

  return (
    <Grid item xs={12} key={field.id}>
      <Grid item xs={12} style={{ width: '100%' }}>
        {fieldTitle && (
          <Typography className={field.nameStyle ? styles[`title--${field.nameStyle}`] : styles['title--style1']}>
            {field.questionPreview ? utils.renderHTMLTags(fieldTitle) : fieldTitle}
            {field.required && '*'}
          </Typography>
        )}
        {field.image && (
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <img
              src={field.image}
              alt={fieldTitle}
              style={{
                display: 'block',
                marginLeft: 'auto',
                marginRight: 'auto',
                width: '90%',
              }}
            />
          </Grid>
        )}

        {questionDescription && (
          <Typography
            style={{
              opacity: 0.5,
              marginBottom: 10,
              maxHeight: 270,
              overflow: 'hidden',
              overflowY: 'auto',
            }}
          >
            {utils.renderHTMLTags(questionDescription)}
          </Typography>
        )}
      </Grid>

      <Grid item xs={12}>
        <Input
          width="100%"
          value={fullName}
          onChange={handleChangeFullName}
          label="Name"
          placeholder="Type full name"
          name="username"
          style={{ width: '100%' }}
          type="text"
          required
        />
      </Grid>

      <Grid item xs={12} style={{ margin: '15px 0' }}>
        <DropDownList
          label="Signed by"
          name="signed_by"
          data={data}
          onChange={handleValueChange}
          required={field.required}
          style={{ width: '100%' }}
          value={signedBy || data[0]}
          textField="text"
        />
      </Grid>

      <Grid item>
        <FormControl component="fieldset" className={classes.selectFieldsWrapper}>
          <FormGroup>
            <Grid className={`${styles.checkboxQuestionnaireItem}`}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked}
                    onChange={handleChecked}
                    name="consent"
                    className={styles.checkboxQuestionnaire}
                    disableRipple
                    style={{
                      color: '#577193',
                    }}
                  />
                }
                label={answer[selectedLanguage] || 'I consent'}
              />
            </Grid>
          </FormGroup>
        </FormControl>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles(() => ({
  yesNoWrapper: {
    width: '100%',
  },
  titleStyles: {
    fontWeight: 'bold',
    fontSize: '24px!important',
    color: 'rgb(16, 6, 159)',
    lineHeight: '30px',
    marginTop: 32,
    marginBottom: 32,
  },
  descriptionStyles: {
    opacity: 0.5,
    marginBottom: 10,
    fontSize: 18,
  },
  selectFieldsWrapper: {
    width: '100%',
  },
}));

export default ConsentForm;
