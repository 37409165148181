import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useEffect, useState } from 'react';

import ShapeItem from './ShapeItem';

function AnnotationAccordion(props) {
  const { annotationData, itemKey } = props;

  const [recommendationText, setRecommendationText] = useState('Annotation');

  useEffect(() => {
    if (annotationData?.shapes && annotationData.shapes.length && annotationData.shapes[0].description) {
      let updatedRecommendationText = 'Annotation';
      const { recommendation, concern: selectedConcern } = annotationData.shapes[0].description;

      // try to get recommendationText
      if (recommendation) {
        updatedRecommendationText = annotationData?.shapes[0]?.description?.recommendation;
        // try to get list of concern
      } else if (selectedConcern.length) {
        updatedRecommendationText = selectedConcern.join(', ');
      }

      setRecommendationText(updatedRecommendationText);
    }
  }, [annotationData]);

  return (
    <Grid style={{ marginTop: 20 }}>
      <Accordion
        defaultExpanded={false}
        // defaultExpanded={itemKey === 1 ? true : false}
        // expanded={itemKey === 0 ? true : false}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={itemKey} id={itemKey}>
          <Typography variant="body1" color="primary">
            {recommendationText}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid direction="column">
            <Grid style={{ position: 'relative' }}>
              {/* <img src={annotationData.previewUrl} /> */}
              <img src={annotationData.screenshotUrl} alt="Screenshot" />

              {annotationData.shapes && annotationData.shapes.length
                ? annotationData.shapes.map((shape) => <ShapeItem annotationId={annotationData._id} shape={shape} />)
                : null}
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
}

export default AnnotationAccordion;
