/* eslint-disable no-unused-expressions */
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { Error } from '@progress/kendo-react-labels';
import moment from 'moment';
import React, { useState, useEffect } from 'react';

import styles from '../../assets/Questionnaire.module.css';
import { getCalculatedDate, getDateByPrecision } from '../helpers/precisionDateHelper';
import utils from '../utils/utils';

function DateInput(props) {
  const { field, value, handleChangeWithParams, disabled, selectedLanguage } = props;
  const [minAgeYear, setMinAgeYear] = useState('');
  const [valid, setValid] = useState(true);
  const [minDate, setMinDate] = useState();
  const [maxDate, setMaxDate] = useState();
  const re = /^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/;

  let date;
  if (value) {
    date = value instanceof Date ? new Date(value) : new Date(moment().subtract(value.answer, 'days'));
  }
  const [fieldState, setFieldState] = useState(date || null);
  const newUser = window.location.href.includes('create');
  const clinicalUser = window.location.href.includes('clinical');
  const questionPreview = window.location.href.includes('questions') || window.location.href.includes('questionnaires');

  // Convert min and max from int to date for VCB preview
  useEffect(() => {
    const calculatedMin = typeof field.min === 'number' ? getCalculatedDate(field.precision, field.min) : field.min;
    const calculatedMax = typeof field.max === 'number' ? getCalculatedDate(field.precision, field.max) : field.max;
    setMinDate(calculatedMin || field.min);
    setMaxDate(calculatedMax || field.max);
  }, [field.precision, field.questionPreview, field.min, field.max]);

  // Set year for minimum age for user age-related date fields
  useEffect(() => {
    !newUser && !questionPreview && document.getElementById('datePicker').focus();
    if (field.minAgeRestriction) {
      const today = new Date();
      const restrictedYear = today.getFullYear() - field.minAgeRestriction;
      const restrictedDate = new Date(restrictedYear, today.getMonth(), today.getDate());
      setMinAgeYear(restrictedDate);
    }
  }, [field.minAgeRestriction, newUser, questionPreview]);

  // allows for proper onBlur and onFocus of component
  const handleBlur = (e) => {
    const { value } = e.target;
    const formattedDate = utils.formatDateNoLocale(fieldState);
    // checks if user clicks calendar dropdown button
    if (!e.relatedTarget) {
      if (re.test(formattedDate) && newUser) {
        handleChangeWithParams(field.name, formattedDate);
        setValid(true);
      } else if (re.test(formattedDate) && !newUser) {
        if (field.precision) {
          const preciseValue = getDateByPrecision(field.precision, value);
          handleChangeWithParams(field.name, preciseValue);
          setValid(true);
        } else {
          handleChangeWithParams(field.name, fieldState);
          setValid(true);
        }
      } else if (value === 'month/day/year') {
        handleChangeWithParams(field.name, '');
        setValid(true);
      } else if (field.precision) {
        const preciseValue = getDateByPrecision(field.precision, value);
        handleChangeWithParams(field.name, preciseValue);
        setValid(false);
      } else {
        handleChangeWithParams(field.name, value);
        setValid(false);
      }
    }
  };

  // handles user changing information of birthdate (kendoUI: only triggered when year populated)
  const handleUpdateDate = (e) => {
    const dateValue = e.target.value;
    const formattedDate = utils.formatDateNoLocale(dateValue);
    setFieldState(dateValue);
    // member details handle update
    if (
      !newUser &&
      e.nativeEvent.inputType !== 'deleteContentBackward' &&
      e.nativeEvent.inputType !== 'insertText' &&
      dateValue
    ) {
      if (field.precision) {
        const preciseValue = getDateByPrecision(field.precision, dateValue);
        handleChangeWithParams(field.name, preciseValue);
        // Submit date alongside precision answer in VCB
        if (field.questionPreview) {
          const answerObject = {
            createdOn: dateValue,
            answer: preciseValue,
          };
          handleChangeWithParams(field.name, answerObject);
        }
      } else {
        // if user clicks on birthdate, handle update
        handleChangeWithParams(field.name, dateValue);
      }
    }

    // new member handle update
    if (newUser && re.test(formattedDate)) {
      setValid(true);
      handleChangeWithParams(field.name, dateValue);
    } else if (newUser && !re.test(formattedDate)) {
      setValid(false);
      handleChangeWithParams(field.name, dateValue);
    }
  };

  const fieldTitle =
    selectedLanguage && field.name && field.name.translations
      ? field.name.translations[selectedLanguage]
      : field.label || field.name;
  const questionDescription =
    selectedLanguage && field.description && field.description.translations
      ? field.description.translations[selectedLanguage]
      : field.description;

  return (
    <>
      {(newUser || questionPreview || clinicalUser) && !field?.isFieldForm && (
        <Grid item xs={12} style={{ width: '100%' }}>
          {fieldTitle && (
            <Typography className={field.nameStyle ? styles[`title--${field.nameStyle}`] : styles['title--style1']}>
              {field.questionPreview ? utils.renderHTMLTags(fieldTitle) : fieldTitle}
              {field.required && '*'}
            </Typography>
          )}
          {field.image && (
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <img
                src={field.image}
                alt={fieldTitle}
                style={{
                  display: 'block',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  width: '90%',
                }}
              />
            </Grid>
          )}

          {questionDescription && (
            <Typography
              className={
                field.descriptionStyle
                  ? styles[`description--${field.descriptionStyle}`]
                  : styles['description--style1']
              }
            >
              {utils.renderHTMLTags(questionDescription)}
            </Typography>
          )}
        </Grid>
      )}
      {field?.isFieldForm && fieldTitle && (
        <Typography
          style={{
            fontSize: 13,
            color: '#74737f',
          }}
        >
          {field.questionPreview ? utils.renderHTMLTags(fieldTitle) : fieldTitle}
          {field.required && '*'}
        </Typography>
      )}
      <div
        style={{
          width: '100%',
          border: field.required ? 'solid 1px rgba(243,23,0,0.5)' : '',
          marginTop: -6,
        }}
      >
        <DatePicker
          label={field?.isFieldForm ? fieldTitle : ''}
          id={field.name?.translations?.eng || field.name}
          required={field.required}
          max={maxDate || undefined}
          min={minDate || undefined}
          name={fieldTitle}
          value={fieldState}
          onBlur={(e) => handleBlur(e)}
          onChange={(e) => handleUpdateDate(e)}
          disabled={disabled || field.disabled || field.readOnly}
          width="100%"
          valid={valid}
        />
        {!valid && <Error id="invalidDate">Please enter a valid date</Error>}
        {minDate && fieldState < minDate && (
          <Error id="minDateError">
            Year must be
            {minDate.getFullYear()} or later
          </Error>
        )}
        {maxDate && fieldState > maxDate && (
          <Error id="maxDateError">
            Date must be {maxDate.getMonth() + 1}/{maxDate.getDate()}/{maxDate.getFullYear()} or earlier
          </Error>
        )}
        {maxDate && fieldState < maxDate && field.minAgeRestriction && fieldState && fieldState > minAgeYear && (
          <Error id="minAgeError">
            User must be at least
            {field.minAgeRestriction} years old
          </Error>
        )}
      </div>
    </>
  );
}
export default DateInput;
