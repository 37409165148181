import FileDownload from 'js-file-download';
import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// redux

import { showNotification } from '../Notifications/notificationSlice';

// components and helpers
import { initForm } from '../Shared/Forms/formSlice';
import { selectCurrentTenant } from '../Shared/selectors';
import AppKendoTableWrapper from '../Shared/Table/KendoTableWrapper';

import ContentImportDialog from './ContentImportDialog';
import { columns, initialSorting, tableButtons, transform } from './helpers/virtualConsultListMapper';
import { selectLoadingQuestionnaires, selectVirtualConsult } from './selectors';
import { getContent, getContentById } from './virtualConsultSlice';

function VirtualConsultListWrapper(props) {
  const { type, actions } = props;

  const currentTenant = useSelector(selectCurrentTenant);
  const consult = useSelector(selectVirtualConsult);
  const loadingQuestionnaires = useSelector(selectLoadingQuestionnaires);
  const [selectAll, setSelectAll] = useState(false);

  const [jsonImportDialog, setJsonImportDialog] = useState(false);
  const [data, setData] = useState([]);
  const [selectIds, setSelectIds] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Initial Load
  useEffect(() => {
    dispatch(getContent(currentTenant.id, type, '', initialSorting[type].field, initialSorting[type].dir, 0, 10));
  }, [currentTenant.id, actions, type]);

  // handles all table updates from table wrapper
  const onTableUpdate = (page, pageSize, sort, direction, searchValue, filter, tags) => {
    dispatch(getContent(currentTenant.id, type, searchValue, sort, direction, page, pageSize, tags));
  };

  // add selected property to every list object for checkbox based on if item has already been selected for export
  useEffect(() => {
    if (consult && consult[type] && data !== consult[type]) {
      const selectData = consult[type].content.map((dataItem) => ({
        selected: selectIds.includes(dataItem.id),
        ...dataItem,
      }));
      setData(selectData);
    }
    // eslint-disable-next-line
  }, [consult, type]);

  const handleRowClick = useCallback(
    (e) => {
      dispatch(initForm(e));
      navigate(`/${currentTenant.id}/virtual-consult/${type}/details/${e.id}`);
    },
    [currentTenant, type]
  );

  // on click of row/row checkbox, select/unselect item if not already in selected
  const selectionChange = (dataItem) => {
    const newSelectIds = [...selectIds];
    const index = newSelectIds.indexOf(dataItem.id);
    if (index > -1) {
      newSelectIds.splice(index, 1);
    } else {
      newSelectIds.push(dataItem.id);
    }
    const selectedData = data.map((item) => {
      if (item.id === dataItem.id) {
        item.selected = !dataItem.selected;
      }
      return item;
    });
    setSelectIds(newSelectIds);
    setData(selectedData);
  };

  // on click of select all, select/unselect all items on page
  const headerSelectionChange = (event) => {
    const newSelectIds = [...selectIds];
    const { checked } = event.syntheticEvent.target;
    const ids = data.map((item) => {
      const index = newSelectIds.indexOf(item.id);
      if (checked) {
        if (index === -1) {
          newSelectIds.push(item.id);
        }
      } else if (index > -1) {
        newSelectIds.splice(index, 1);
      }
      item.selected = checked;
      return item;
    });
    setSelectIds(newSelectIds);
    setData(ids);
  };

  const exportData = async () => {
    const data = await Promise.all(
      selectIds.map(async (id) => dispatch(getContentById(currentTenant.id, type, id, true)))
    );
    const jsonString = JSON.stringify({
      type,
      content: data,
    });

    const fileName = `${[type]}_${Math.random().toString(36).substring(5)}`;
    const json = JSON.stringify(jsonString);
    const blob = new Blob([json], { type: 'application/json' });

    FileDownload(blob, `${fileName}.json`);

    await dispatch(showNotification('The file is downloading', 'success'));

    // deselect items in list and disable export
    setSelectIds([]);
    const selectData = consult[type].content.map((dataItem) => ({
      selected: false,
      ...dataItem,
    }));
    setData(selectData);
  };

  const handleSelectAll = (value) => {
    setSelectIds([]);
    setSelectAll(value);
  };

  const buttons = tableButtons[type](currentTenant, exportData, setJsonImportDialog, selectIds);

  const sorting = initialSorting[type];

  if (!type || !consult) {
    return null;
  }

  // Data from the API is converted to its table format in the return
  // (See data={transform... line)
  return (
    <>
      <ContentImportDialog
        importType={type}
        jsonImportDialog={jsonImportDialog}
        setJsonImportDialog={setJsonImportDialog}
      />
      <AppKendoTableWrapper
        data={transform(data, consult[type], type)}
        onSelectionChange={selectionChange}
        onHeaderSelectionChange={headerSelectionChange}
        initialSort={sorting.field}
        initialDir={sorting.dir}
        showButtons
        showSearchbar
        showTagSearch={type === 'questions'}
        sorted={sorting}
        tableButtons={buttons}
        columns={columns[type]}
        currentTenant={currentTenant}
        onRowClick={handleRowClick}
        loading={loadingQuestionnaires}
        page={consult[type] && consult[type].pageable.pageNumber}
        onTableUpdate={onTableUpdate}
        paginationPages={[10, 25, 50, 100]}
        parent="virtual-consult"
        selectIds={selectIds}
        onSelectAll={handleSelectAll}
        selectAll={selectAll}
        setSelectIds={setSelectIds}
      />
    </>
  );
}

export default VirtualConsultListWrapper;
