import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React from 'react';

import ExpansionPanelWrapper from './ExpansionPanelWrapper';
import { testSpecimenDetails } from './helpers/mapping';
import ReportWrapper from './ReportWrapper';

function LabSpecimenDetailsWrapper(props) {
  const { testData } = props;

  const renderTitle = () => (
    <Grid container direction="column">
      <Typography variant="button" color="primary" style={{ fontWeight: 700 }}>
        Specimen Details
      </Typography>
    </Grid>
  );

  return (
    <Grid style={{ marginBottom: 15 }}>
      <ExpansionPanelWrapper renderTitle={renderTitle} name="testSpecimenDetails">
        <Grid container spacing={1}>
          {testSpecimenDetails.map((report) => (
            <ReportWrapper report={report} data={testData} />
          ))}
        </Grid>
      </ExpansionPanelWrapper>
    </Grid>
  );
}

export default LabSpecimenDetailsWrapper;
