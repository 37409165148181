import Grid from '@material-ui/core/Grid';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectTenantSettings } from '../../Settings/selectors';

export function TabbedPageWrapper({ tabs, openTab, onTabChange, allowTabChange = true }) {
  const tenantSettings = useSelector(selectTenantSettings);
  const [initialOpenTab] = useState(openTab);
  const [activeTab, setActiveTab] = useState((openTab && tabs.findIndex((t) => t.url === openTab.url)) || 0);

  useEffect(() => {
    if (
      tabs?.length > 0 &&
      initialOpenTab &&
      tenantSettings &&
      tenantSettings.menuItems &&
      initialOpenTab === openTab
    ) {
      const tabsToShow =
        (tabs?.[0].url?.includes('account') && tabs) ||
        (tabs?.[0].url?.includes('virtual-consult') && tabs) ||
        (tabs?.[0].url?.includes('content') && tabs) ||
        (tabs?.[0].url?.includes('system-messages') && tabs) ||
        (tabs?.[0].url?.includes('reports') && tabs) ||
        tabs.filter((t) => t.url in tenantSettings.menuItems && tenantSettings.menuItems[t.url]);

      const isInRender = tabsToShow.findIndex((t) => initialOpenTab.url === t.url);

      if (isInRender > -1) {
        setActiveTab(isInRender);
        onTabChange(tabsToShow[isInRender]);
      }
    }

    // Catch to reset activeTab if parent route is reopened
    if (activeTab && openTab && activeTab !== openTab) {
      const openTabIdx = tabs.findIndex((t) => t.url === openTab.url);
      setActiveTab(openTabIdx);
    }
  }, [openTab, activeTab, tenantSettings, initialOpenTab, tabs, onTabChange]);

  if (!tenantSettings || !tenantSettings.menuItems) return null;

  const tabsToRender =
    (tabs?.[0]?.url?.includes('account') && tabs) ||
    (tabs?.[0]?.url?.includes('tenant-info') && tabs) ||
    (tabs?.[0]?.url?.includes('virtual-consult') && tabs) ||
    (tabs?.[0]?.url?.includes('screens') && tabs) ||
    (tabs?.[0]?.url?.includes('reports') && tabs) ||
    (tabs?.[0]?.url?.includes('content') && tabs) ||
    (tabs?.[0]?.url?.includes('users') && tabs) ||
    (tabs?.[0]?.url?.includes('system-messages') && tabs) ||
    (tabs?.[0]?.url?.includes('medical-center') && tabs) ||
    tabs.filter((t) => t.url in tenantSettings.menuItems && tenantSettings.menuItems[t.url]);

  if (initialOpenTab >= 0 && openTab === initialOpenTab) {
    const tab = tabs[initialOpenTab];
    const tabToRender = tabsToRender.find((t) => t.url === tab.url);
    if (!tabToRender) {
      onTabChange(tabsToRender?.[0]);
      return null;
    }
  }

  const handleTabChange = (event, newValue) => {
    if (!allowTabChange) return;
    setActiveTab(newValue);

    if (onTabChange) {
      onTabChange(tabsToRender[newValue]);
    }
  };

  if (activeTab > tabsToRender.length - 1) return null;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Tabs
          centered
          indicatorColor="primary"
          variant="fullWidth"
          value={activeTab}
          onChange={handleTabChange}
          aria-label="form tabs"
        >
          {tabsToRender.map((tab, idx) => (
            // eslint-disable-next-line react/no-array-index-key
            <Tab key={`tab_${idx}`} label={tab.label} value={tab.value || idx} disabled={tab.disabled} />
          ))}
        </Tabs>
      </Grid>
      <Grid item xs={12}>
        {tabsToRender[activeTab]?.content}
      </Grid>
    </Grid>
  );
}

export default TabbedPageWrapper;
