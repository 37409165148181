import * as htmlUtils from '../../Shared/Grid/helpers';
import utils from '../../Shared/Utils/utils';
import SelectionHeaderCell from '../../Users/SelectionHeaderCell';
import ActionsCellWrapper from '../ActionsCellWrapper';

import { statusMapping } from './mappings';

export const initialSorting = {
  questionnaires: {
    field: 'updated',
    dir: 'desc',
  },
  questions: {
    field: 'updated',
    dir: 'desc',
  },
  outcomes: {
    field: 'title',
    dir: 'asc',
  },
  questionsQuestionnaire: {
    field: 'name',
    dir: 'asc',
  },
  outcomesQuestionnaire: {
    field: 'title',
    dir: 'asc',
  },
};

export const transform = (list, pagination, type) => ({
  ...list,
  items:
    list &&
    list.map((item) => ({
      ...item,
      _id: item.id,
      created: item.created && utils.formatDateTime(item.created),
      updated: item.updated && utils.formatDateTime(item.updated),
      status: item.status ? item.status : 'DISABLED',
      contentType: type,
      title: item.title && item.title.translations ? item.title.translations.eng : item.title,
      name: item.name && item.name.translations ? item.name.translations.eng : item.name,
      description:
        item.description && item.description.translations ? item.description.translations.eng : item.description,
      help: item.help && item.help.translations ? item.help.translations.eng : item.help,
    })),
  number: pagination && (pagination.number || 0),
  total: pagination && (pagination.totalElements || 0),
  pageSize: pagination && (pagination.size || 0),
});

export const transformSelectList = (searchResults, pagination) => ({
  ...searchResults,
  items:
    searchResults &&
    searchResults.map((item) => ({
      ...item,
      _id: item.id,
      name: item.name && item.name.translations ? item.name.translations.eng : item.name,
      title: item.title && item.title.translations ? item.title.translations.eng : item.title,
      description:
        item.description && item.description.translations ? item.description.translations.eng : item.description,
      updated: item.updated && utils.formatDateTime(item.updated),
    })),
  total: pagination && (pagination.totalElements || 0),
  pageSize: pagination && (pagination.size || 0),
});

export const columns = {
  questionnaires: [
    {
      id: 'id',
      label: 'ID',
      show: false,
      sortable: false,
      minWidth: 170,
    },
    {
      id: 'selected',
      title: '',
      show: true,
      minWidth: 60,
      maxWidth: 70,
      sortable: true,
      headerCell: SelectionHeaderCell,
    },
    {
      id: 'title',
      label: 'Title',
      show: true,
      locked: true,
      minWidth: 170,
      sortable: true,
    },
    {
      id: 'introduction',
      label: 'Introduction',
      show: false,
      locked: false,
      minWidth: 170,
      sortable: true,
    },
    {
      id: 'created',
      label: 'Created',
      show: true,
      minWidth: 170,
      sortable: true,
    },
    {
      id: 'createdBy',
      label: 'Creator',
      show: true,
      minWidth: 170,
      sortable: true,
    },
    {
      id: 'updated',
      label: 'Modified',
      show: true,
      minWidth: 170,
      sortable: true,
    },
  ],
  questions: [
    {
      id: 'id',
      label: 'ID',
      show: false,
      sortable: false,
      minWidth: 170,
    },
    {
      id: 'selected',
      title: '',
      show: true,
      minWidth: 60,
      maxWidth: 70,
      sortable: true,
      headerCell: SelectionHeaderCell,
    },
    {
      id: 'name',
      label: 'Title',
      show: true,
      locked: true,
      minWidth: 370,
      sortable: true,
    },
    {
      id: 'description',
      label: 'Description',
      show: false,
      locked: false,
      minWidth: 170,
      sortable: true,
    },
    {
      id: 'introduction',
      label: 'Introduction',
      show: false,
      minWidth: 170,
      sortable: true,
    },
    {
      id: 'readOnly',
      label: 'Read Only',
      show: false,
      minWidth: 170,
      sortable: true,
    },
    {
      id: 'required',
      label: 'Required',
      show: false,
      minWidth: 170,
      sortable: true,
    },
    {
      id: 'type',
      label: 'Type',
      show: true,
      minWidth: 170,
      sortable: false,
    },
    {
      id: 'updated',
      label: 'Last Updated',
      show: true,
      minWidth: 170,
      sortable: true,
    },
    {
      id: 'created',
      label: 'Created',
      show: false,
      minWidth: 170,
      sortable: true,
    },
    {
      id: 'status',
      label: 'Status',
      show: false,
      minWidth: 170,
      sortable: true,
      cell: htmlUtils.withMapping(htmlUtils.tag, statusMapping),
    },
    {
      id: 'createdBy',
      label: 'Created By',
      show: false,
      minWidth: 170,
      sortable: true,
    },
    {
      id: 'action',
      label: 'Action',
      show: true,
      minWidth: 100,
      cell: ActionsCellWrapper,
    },
  ],
  outcomes: [
    {
      id: 'id',
      label: 'ID',
      show: false,
      sortable: false,
      minWidth: 170,
    },
    {
      id: 'selected',
      title: '',
      show: true,
      minWidth: 60,
      maxWidth: 70,
      sortable: true,
      headerCell: SelectionHeaderCell,
    },
    {
      id: 'title',
      label: 'Title',
      show: true,
      minWidth: 170,
      sortable: true,
    },
    {
      id: 'type',
      label: 'Type',
      show: true,
      minWidth: 170,
      sortable: false,
    },
    {
      id: 'actions',
      label: 'Actions',
      show: true,
      minWidth: 170,
      sortable: false,
      cell: ActionsCellWrapper,
    },
  ],
};

export const tableButtons = {
  questions: (tenant, exportData, setJsonImport, selectIds) => [
    {
      title: 'Import',
      buttonType: 'action',
      variant: 'outlined',
      disabled: false,
      action: () => setJsonImport((prevValue) => !prevValue),
    },
    {
      title: 'Export',
      buttonType: 'action',
      variant: 'outlined',
      disabled: !selectIds.length,
      action: exportData,
    },
    {
      title: 'New Question',
      buttonType: 'link',
      link: `/${tenant && tenant.id}/virtual-consult/questions/create`,
      variant: 'contained',
      disabled: false,
    },
  ],
  outcomes: (tenant, exportData, setJsonImport, selectIds) => [
    {
      title: 'Import',
      buttonType: 'action',
      variant: 'outlined',
      disabled: false,
      action: () => setJsonImport((prevValue) => !prevValue),
    },
    {
      title: 'Export',
      buttonType: 'action',
      variant: 'outlined',
      disabled: !selectIds.length,
      action: exportData,
    },
    {
      title: 'New Outcome',
      buttonType: 'link',
      link: `/${tenant && tenant.id}/virtual-consult/outcomes/create`,
      variant: 'contained',
      disabled: false,
    },
  ],
  questionnaires: (tenant, exportData, setJsonImport, selectIds) => [
    {
      title: 'Import',
      buttonType: 'action',
      variant: 'outlined',
      disabled: false,
      action: () => setJsonImport((prevValue) => !prevValue),
    },
    {
      title: 'Export',
      buttonType: 'action',
      variant: 'outlined',
      disabled: !selectIds.length,
      action: exportData,
    },
    {
      title: 'New Questionnaire',
      buttonType: 'link',
      link: `/${tenant && tenant.id}/virtual-consult/questionnaires/create`,
      variant: 'contained',
      disabled: false,
    },
  ],
};
