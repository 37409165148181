import React, { useEffect } from 'react';
// redux
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentTenant } from '../Shared/selectors';
import AppKendoTableWrapper from '../Shared/Table/KendoTableWrapper';
import utils from '../Shared/Utils/utils';
import { getAlertsList } from './alertsSlice';
import { columns } from './helpers/mapping';
import { selectAllAlerts, selectAllAlertsLoading } from './selectors';

// components and helpers

function Alerts() {
  const alerts = useSelector(selectAllAlerts);
  const alertsLoading = useSelector(selectAllAlertsLoading);
  const currentTenant = useSelector(selectCurrentTenant);

  const dispatch = useDispatch();

  // handles all table updates from table wrapper
  const handleTableUpdate = (page, pageSize, sort, direction, searchValue) => {
    dispatch(getAlertsList(currentTenant.id, 'allAlerts', page, pageSize, searchValue, sort, direction.toUpperCase()));
  };

  useEffect(() => {
    // Initial load when change from one content page to another
    dispatch(getAlertsList(currentTenant.id, 'allAlerts', 0, 10, '', 'time', 'DESC'));
  }, [currentTenant]);

  if (!currentTenant) return null;

  return (
    <AppKendoTableWrapper
      data={{
        ...alerts,
        items:
          alerts &&
          alerts.items &&
          alerts.items.map((n) => ({
            ...n,
            time: utils.formatDateTime(n.time),
          })),
      }}
      initialSort="time"
      initialDir="desc"
      showSearchbar
      showFilterIcon={false}
      showButtons={false}
      columns={columns()}
      currentTenant={currentTenant}
      onTableUpdate={handleTableUpdate}
      loading={alertsLoading}
    />
  );
}

export default Alerts;
