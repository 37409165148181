import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState, useEffect, useMemo, useCallback } from 'react';

// redux
import { useDispatch, useSelector } from 'react-redux';

import ProfileViewStyles from '../../../assets/jss/components/ProfileViewStyles';
import { selectCurrentTenant } from '../../Shared/selectors';
import AppKendoTableWrapper from '../../Shared/Table/KendoTableWrapper';
import utils from '../../Shared/Utils/utils';
import { selectUserMessagesLoading, selectUserMessages, selectUserDetails } from '../../Users/selectors';
import { getUserMessages } from '../../Users/usersSlice';

// component imports

// mui
import { userMessageColumns } from '../helpers/profileListMapper';

import UserMessageContentPanel from './UserMessageContentPanel';

function UserMessages() {
  const classes = useStyles();

  const currentTenant = useSelector(selectCurrentTenant);
  const userDetails = useSelector(selectUserDetails);

  const userMessagesLoading = useSelector(selectUserMessagesLoading);
  const userMessages = useSelector(selectUserMessages);

  const [selectedRow, setSelectedRow] = useState(undefined);

  const dispatch = useDispatch();

  // handles all table updates from table wrapper
  const handleTableUpdate = (page, pageSize, sort, direction) => {
    dispatch(getUserMessages(page, pageSize, sort, direction.toUpperCase(), userDetails.email, currentTenant.id));
  };

  const removeSelected = () => {
    setSelectedRow(undefined);
  };

  // open questionnaire details
  const handleOpenContent = useCallback(
    (data) => {
      if (selectedRow && selectedRow.id === data.id) {
        removeSelected();
      } else {
        setSelectedRow(data);
      }
    },
    [selectedRow]
  );

  useEffect(() => {
    dispatch(getUserMessages(0, 10, 'sent', 'DESC', userDetails.email, currentTenant.id));
  }, [currentTenant, userDetails]);

  const columns = useMemo(() => userMessageColumns(handleOpenContent), [handleOpenContent]);

  return (
    <Grid className={classes.contentWrapper}>
      <AppKendoTableWrapper
        data={{
          ...userMessages,
          items:
            userMessages &&
            userMessages.items &&
            userMessages.items.map((n) => ({
              ...n,
              communicationType: utils.removeUnderscoresTitleCase(n.communicationType),
              sent: utils.formatDateTime(n.sent),
              success: n.success ? 'Delivered' : 'Failed',
              selected: selectedRow && n.id === selectedRow.id,
              expanded: selectedRow && n.id === selectedRow.id,
            })),
        }}
        initialSort="sent"
        initialDir="desc"
        columns={columns}
        loading={userMessagesLoading}
        onRowClick={handleOpenContent}
        onTableUpdate={handleTableUpdate}
        detailsComponent={UserMessageContentPanel}
      />
    </Grid>
  );
}

const useStyles = makeStyles(ProfileViewStyles);

export default UserMessages;
