import Button from '@material-ui/core//Button';
import Dialog from '@material-ui/core//Dialog';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { DialogActionsBar } from '@progress/kendo-react-dialogs';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';

// React Crop
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import ImporterStyles from '../../../../assets/jss/components/ImporterStyles';
import utils from '../../Utils/utils';

import logo from './resources/upload-empty.png';

const API_ROOT = process.env.REACT_APP_API_ROOT;

function Previews({
  field,
  value,
  handleChange,
  classes,
  disabled,
  disableCropper,
  hidePreviewPlaceHolder,
  uploadCaption,
  fileTypeCaption,
  skipUploadToResource,
}) {
  const [currentValue, setCurrentValue] = useState(value);
  const [file, setFile] = useState(field.value);
  const [loading, setLoading] = useState(false);
  const [showCropper, setShowCropper] = useState(false);
  const [draftBlob, setDraftBlob] = useState(undefined);
  const [draftCrop, setDraftCrop] = useState(undefined);
  const [error, setError] = useState('');
  const [imageType, setImageType] = useState('image/png');
  const [fileName, setFileName] = useState('newfile.png');

  const imgRef = useRef(null);
  const [crop, setCrop] = useState();

  useEffect(() => {
    if (value !== currentValue) {
      setCurrentValue(value);
    }
    // eslint-disable-next-line
  }, [value]);

  // const inputProps = `${field.acceptedFiles ? field.acceptedFiles.join(', ') : 'image/png, image/jpg, image/jpeg'}`
  const acceptedInputTypes = field.acceptedFiles
    ? field.acceptedFiles.reduce((prev, curr) => {
        return {
          ...prev,
          [curr]: [],
        };
      }, {})
    : {
        'image/png': ['.png', '.jpg', '.jpeg'],
      };

  const { getRootProps, getInputProps } = useDropzone({
    disabled: disabled || field.disabled || field.readOnly,
    accept: acceptedInputTypes,
    onDrop: (acceptedFiles, failedFiles) => {
      if (acceptedFiles.length + failedFiles.length > 1) {
        setError('Only upload one file at a time');
      } else if (acceptedFiles.length > 0) {
        if (acceptedFiles[0].type === 'image/svg+xml' || disableCropper) {
          uploadCrop(acceptedFiles[0]);
        } else {
          setImageType(acceptedFiles[0].type);
          setFileName(acceptedFiles[0].name);

          // Reset fields
          setDraftBlob(undefined);
          // setImgRef(undefined);
          setDraftCrop(undefined);
          setCrop({
            aspect: field.imageWidth / field.imageHeight,
            width: field.imageWidth,
          });

          setFile(
            Object.assign(acceptedFiles[0], {
              preview: URL.createObjectURL(acceptedFiles[0]),
            })
          );
          setShowCropper(true);
          setError('');

          setCrop(undefined); // Makes crop preview update between images.
          const reader = new FileReader();
          reader.addEventListener('load', () =>
            setFile(
              Object.assign(acceptedFiles[0], {
                preview: reader.result.toString() || '',
              })
            )
          );
          reader.readAsDataURL(acceptedFiles[0]);
        }
      } else if (failedFiles.length > 0) {
        setError(failedFiles[0].errors[0].message);
      }
    },
  });

  const onCancel = () => {
    setFile(field.value);
    setShowCropper(false);
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const uploadCrop = async (droppedFile) => {
    if (droppedFile == null) {
      return;
    }

    if (skipUploadToResource) {
      try {
        const localURL = await toBase64(droppedFile);
        const event = {
          target: {
            value: localURL,
            name: field.name.translations ? field.name.translations.eng : field.name,
            file: droppedFile,
          },
        };

        handleChange(event);
        setCurrentValue(localURL);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
      return;
    }

    const fd = new FormData();
    fd.append('file', droppedFile, droppedFile.name);
    const id_token = localStorage.getItem('id_token');
    // const user_id = localStorage.getItem('user_id');

    setLoading(true);

    const request = new XMLHttpRequest();
    // setLoading(true);
    request.onreadystatechange = () => {
      if (request.readyState === XMLHttpRequest.DONE) {
        const { status } = request;

        if (status === 0 || (status >= 200 && status < 400)) {
          const parsedData = JSON.parse(request.responseText);
          let valueToSet = parsedData.data;
          let previewUrl = valueToSet;

          if (field.type === 'photo') {
            valueToSet = parsedData.fileId;
            previewUrl = parsedData.previewUrl;
          }

          const event = {
            target: {
              value: valueToSet,
              name: field.name.translations ? field.name.translations.eng : field.name,
              file: droppedFile,
            },
          };
          handleChange(event);
          setCurrentValue(previewUrl);
        } else {
          // Oh no! There has been an error with the request!
          /* eslint-disable-next-line no-console */
          console.log('Something went wrong');
        }
        setLoading(false);
      }
    };

    const accountId = localStorage.getItem('accountId');
    const tenantId = localStorage.getItem('tenantId');

    let uploadUrl = `${API_ROOT}/resources/v1/content/image/upload/safe-content-cache/temp`;
    if (field.type === 'photo') {
      uploadUrl = `${API_ROOT}/virtual-consult/upload/answer/photo`;
    }

    request.open('POST', uploadUrl, true);
    request.setRequestHeader('Access-Control-Allow-Origin', '*');
    request.setRequestHeader('Accept', 'application/json');
    request.setRequestHeader('Authorization', `Bearer ${id_token}`);
    request.setRequestHeader('Id_Token', id_token);
    // request.setRequestHeader("userId", user_id);
    request.setRequestHeader('X-SF-ACCOUNT', accountId);
    request.setRequestHeader('X-SF-TENANT', tenantId);

    request.send(fd);
  };

  const onSaveCrop = () => {
    uploadCrop(draftBlob || file);

    setFile(
      Object.assign(draftBlob || file, {
        preview: draftCrop,
      })
    );
    setShowCropper(false);
  };

  const getCroppedImg = useCallback(
    (image, cropInfo, fileName) => {
      const canvas = document.createElement('canvas');
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      canvas.width = cropInfo.width;
      canvas.height = cropInfo.height;
      const ctx = canvas.getContext('2d');

      ctx.drawImage(
        image,
        cropInfo.x * scaleX,
        cropInfo.y * scaleY,
        cropInfo.width * scaleX,
        cropInfo.height * scaleX,
        0,
        0,
        cropInfo.width,
        cropInfo.height
      );

      return new Promise((resolve) => {
        canvas.toBlob((blob) => {
          if (!blob) {
            // reject(new Error('Canvas is empty'));
            /* eslint-disable-next-line no-console */
            console.error('Canvas is empty');
            return;
          }
          blob.name = fileName;
          blob.lastModifiedDate = new Date();
          setDraftBlob(blob);

          window.URL.revokeObjectURL(draftCrop);
          const imageUrl = window.URL.createObjectURL(blob);
          resolve(imageUrl);
        }, imageType);
      });
    },
    [draftCrop, imageType]
  );

  const makeClientCrop = useCallback(
    async (img, cropInfo) => {
      if (img && cropInfo.width && cropInfo.height) {
        const croppedImageUrl = await getCroppedImg(img, cropInfo, fileName);
        setDraftCrop(croppedImageUrl);
      }
    },
    [fileName, getCroppedImg]
  );

  const onCropComplete = useCallback(
    (cropInfo) => {
      makeClientCrop(imgRef.current, cropInfo);
    },
    [makeClientCrop]
  );

  // If you setState the crop in here you should return false.
  const onImageLoaded = useCallback(
    async (image) => {
      URL.revokeObjectURL(file.preview);
    },
    // eslint-disable-next-line
    [field, fileName, crop, file],
  );

  const handleRemoveImage = () => {
    const event = {
      target: {
        value: '',
        name: field.name,
        file: null,
      },
    };

    handleChange(event);
    setCurrentValue('');
  };

  const labelStyle = () => {
    if (field.required && !currentValue) {
      return {
        opacity: 1,
        color: 'red',
      };
    }
    return {
      opacity: 0.5,
    };
  };

  const imagePreview = () => {
    if (hidePreviewPlaceHolder && !currentValue) {
      return null;
    }

    return <img src={currentValue || logo} style={{ maxWidth: '100%' }} alt="thumbnail" />;
  };

  return (
    <Grid container spacing={2} justifyContent="center">
      {field.type !== 'assetsWrapper' && field.label && (
        <Grid item xs={12}>
          <Typography align="center" style={labelStyle()}>
            {field.questionPreview ? utils.renderHTMLTags(field.label) : field.label}
          </Typography>
        </Grid>
      )}

      <Grid item xs={12}>
        <div className={currentValue || field.type === 'assetsWrapper' ? classes.boxPreview : classes.box}>
          <Grid container justifyContent="flex-end">
            {currentValue && !(disabled || field.disabled || field.readOnly) && (
              <Grid item>
                <Tooltip title="Remove Image">
                  <IconButton style={{ marginTop: -20 }} onClick={handleRemoveImage}>
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            )}
            <Grid item xs={12} {...getRootProps({ className: 'dropzone' })} style={{ outline: 'none' }}>
              <input {...getInputProps()} disabled={disabled} />
              <div
                className={!currentValue ? classes.thumbnail : null}
                style={{
                  backgroundColor: field.backgroundColor && currentValue ? field.backgroundColor : '',
                  marginBottom: 10,
                }}
              >
                {loading ? <CircularProgress /> : imagePreview()}
              </div>
              {!(disabled || field.disabled || field.readOnly) && (
                <Button
                  size="small"
                  className={classes.button}
                  variant="contained"
                  disableElevation
                  color="primary"
                  disabled={loading || disabled || field.disabled || field.readOnly}
                >
                  Browse
                </Button>
              )}
            </Grid>
            {!(disabled || field.disabled || field.readOnly) && (
              <Grid item xs={12}>
                <Typography variant="caption" style={{ opacity: 0.5 }}>
                  {uploadCaption ?? 'Or drag image here.'}
                </Typography>
              </Grid>
            )}
          </Grid>

          {showCropper && (
            <Dialog
              onClose={(event, reason) => {
                if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                  onCancel();
                }
              }}
              width="100%"
              height="100%"
              open={showCropper}
              scroll="paper"
            >
              <DialogTitle>Select the desired area</DialogTitle>
              <DialogContent>
                {file && file.preview && (
                  <ReactCrop
                    crop={crop}
                    ruleOfThirds
                    keepSelection
                    onComplete={onCropComplete}
                    onChange={(_, percentCrop) => setCrop(percentCrop)}
                    onImageLoaded={onImageLoaded}
                    style={{
                      backgroundColor: field.backgroundColor ? field.backgroundColor : '',
                    }}
                  >
                    <img ref={imgRef} alt={'image-crop'} src={file.preview} />
                  </ReactCrop>
                )}
              </DialogContent>
              <DialogActionsBar>
                <button className="k-button" onClick={onCancel}>
                  Cancel
                </button>
                <button className="k-button" onClick={onSaveCrop}>
                  Save
                </button>
              </DialogActionsBar>
            </Dialog>
          )}

          {/* <img style={{ display: "none" }} ref={imgRef} /> */}
        </div>
      </Grid>
      {(field.imageWidth || field.imageHeight || field.acceptedFiles || !!fileTypeCaption) && (
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          {!fileTypeCaption && field.acceptedFiles && (
            <Typography variant="caption" component="p" style={{ opacity: 0.5 }}>
              {`Allowed file types: ${field.acceptedFiles
                .map((f) => f.substring(f.indexOf('/') + 1).toUpperCase())
                .join(', ')}`}
            </Typography>
          )}
          {fileTypeCaption && (
            <Typography variant="caption" component="p" style={{ opacity: 0.5 }}>
              {fileTypeCaption}
            </Typography>
          )}
          {(field.imageWidth || field.imageHeight) && (
            <Typography variant="caption" component="p" style={{ opacity: 0.5 }}>
              {`Image size must be at least ${field.imageWidth} x ${field.imageHeight}`}
            </Typography>
          )}
        </Grid>
      )}
      {error && (
        <Box>
          <Typography color="error" variant="caption">
            {error}
          </Typography>
        </Box>
      )}
    </Grid>
  );
}

export default withStyles(ImporterStyles)(Previews);
