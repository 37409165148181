import * as htmlUtils from '../../../Shared/Grid/helpers';
import SelectionHeaderCell from '../../../Users/SelectionHeaderCell';
import { addedToQuestionnaire } from '../../helpers/mappings';

export const transform = {
  nodes: (data) =>
    data.map((k) => {
      if (k.name) {
        return {
          value: k.id,
          label: k.shortName || k.name,
        };
      }
      if (k.question && !k.name) {
        return {
          value: k.id,
          label: k.question.shortName || k.question.name,
        };
      }
      if (k.outcome && !k.name) {
        return {
          value: k.id,
          label: k.outcome.shortName || k.outcome.name,
        };
      }
      return null;
    }),
  otherNodes: (data, currentNode) =>
    data
      .filter((item) => item.id !== currentNode.id)
      .map((k) => {
        if (k.name) {
          return {
            value: k.id,
            label: k.shortName || k.name,
          };
        }
        if (k.question && !k.name) {
          return {
            value: k.id,
            label: k.question.shortName || k.question.name,
          };
        }
        if (k.outcome && !k.name) {
          return {
            value: k.id,
            label: k.outcome.shortName || k.outcome.name,
          };
        }
        return null;
      }),
};

export const columns = {
  questions: [
    {
      id: 'selected',
      title: '',
      show: true,
      minWidth: 60,
      maxWidth: 70,
      sortable: true,
      headerCell: SelectionHeaderCell,
    },
    {
      id: 'addedToQuestionnaire',
      label: 'Added to Questionnaire',
      show: true,
      minWidth: 150,
      sortable: true,
      cell: htmlUtils.withMapping(htmlUtils.tag, addedToQuestionnaire),
    },
    {
      id: 'name',
      label: 'Question',
      show: true,
      minWidth: 700,
      sortable: true,
    },
    {
      id: 'type',
      label: 'Type',
      show: true,
      minWidth: 100,
      sortable: false,
    },
    {
      id: 'updated',
      label: 'Last Updated  ',
      show: true,
      minWidth: 170,
      sortable: true,
    },
  ],
  outcomes: [
    {
      id: 'id',
      label: 'ID',
      show: false,
      sortable: true,
      minWidth: 170,
    },

    {
      id: 'selected',
      title: '',
      show: true,
      minWidth: 60,
      maxWidth: 70,
      sortable: true,
      headerCell: SelectionHeaderCell,
    },
    {
      id: 'addedToQuestionnaire',
      label: 'Added to Questionnaire',
      show: true,
      minWidth: 150,
      sortable: true,
      cell: htmlUtils.withMapping(htmlUtils.tag, addedToQuestionnaire),
    },
    {
      id: 'type',
      label: 'Type',
      show: true,
      minWidth: 170,
      sortable: false,
    },
    {
      id: 'title',
      label: 'Title',
      show: true,
      minWidth: 170,
      sortable: true,
    },
    {
      id: 'name',
      label: 'Name',
      show: true,
      locked: false,
      minWidth: 170,
      sortable: true,
    },
    {
      id: 'description',
      label: 'Description',
      show: true,
      locked: false,
      minWidth: 170,
      sortable: true,
    },
  ],
};
