import { createSlice } from '@reduxjs/toolkit';

import api from '../../api';
import { handleError } from '../Shared/Content/APIUtils';

const initialState = {
  providerList: null,
  providerListLoading: null,
};

export const providerListSlice = createSlice({
  name: 'providerList',
  initialState,
  reducers: {
    setProviderListLoading: (state) => ({
      ...state,
      providerListLoading: true,
    }),
    setProviderList: (state, { payload }) => ({
      ...state,
      providerList: payload,
      providerListLoading: false,
    }),
  },
});

export const { setProviderListLoading, setProviderList } = providerListSlice.actions;

export const searchProvider = (providerData) => async (dispatch) => {
  dispatch(setProviderListLoading());

  const [result, error] = await api.searchProviderRequest(providerData);

  if (result) {
    dispatch(setProviderList(result));
  }

  if (error && error.message) {
    dispatch(handleError(error));
  }
};

export default providerListSlice.reducer;
