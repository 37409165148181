/* eslint-disable react/no-array-index-key */
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectCurrentTenant } from '../Shared/selectors';
import { covidVaxStatusMapping } from '../Users/helpers/mappings';
import { manageUserVaxStatus } from '../Users/usersSlice';

function CovidVaxStatusDialog(props) {
  const { closeDialog, selectedVaxStatus, dialogMode, userId } = props;

  const VaxStatus = {
    id: '',
    safeEMRId: userId,
    vaccinationStatus: '',
  };

  const currentTenant = useSelector(selectCurrentTenant);

  const [vaxStatusData, setVaxStatusData] = useState(dialogMode === 'Edit' ? selectedVaxStatus : VaxStatus);
  const [title, setTitle] = useState('');

  const dispatch = useDispatch();

  const handleCancel = () => {
    closeDialog();
  };

  const handleChangeWithParams = useCallback(
    (name, value) => {
      setVaxStatusData({ ...vaxStatusData, vaccinationStatus: value });
    },
    [vaxStatusData]
  );

  useEffect(() => {
    if (dialogMode === 'Edit') {
      setVaxStatusData({
        ...vaxStatusData,
        vaccinationStatus: covidVaxStatusMapping.find((x) => x.label === selectedVaxStatus.vaccinationStatus).value,
      });
      setTitle('If you made a mistake you can edit this vax status here.');
    } else setTitle("As of today, what is the employee's vaccination status?");
  }, [dialogMode]);

  const handleAddEdit = () => {
    if (
      vaxStatusData != null &&
      vaxStatusData.vaccinationStatus != null &&
      Object.keys(vaxStatusData.vaccinationStatus).length !== 0 &&
      vaxStatusData.safeEMRId
    ) {
      dispatch(manageUserVaxStatus(vaxStatusData, currentTenant?.id));
      closeDialog();
    }
  };

  return (
    <Dialog title={`${dialogMode} Vax Status`} onClose={handleCancel} width={600}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <strong>{title}</strong>
          <p>
            According to OSHA, an employee who is considered &quot;Fully Vaccinated&quot; must have received all of the
            mandatory doses for the given vaccination.
          </p>
          <RadioGroup
            row={false}
            name="covidVaxStatus"
            value={vaxStatusData.vaccinationStatus}
            onChange={handleChangeWithParams}
          >
            {covidVaxStatusMapping &&
              covidVaxStatusMapping.map((option, i) => (
                <Grid key={`radio-${i}`}>
                  <FormControlLabel
                    value={option && option.value}
                    control={<Radio color="primary" />}
                    label={option && option.label}
                  />
                </Grid>
              ))}
          </RadioGroup>
        </Grid>
      </Grid>
      <DialogActionsBar>
        <button className="k-button" onClick={handleCancel}>
          Cancel
        </button>
        <button className="k-button" disabled={!vaxStatusData.vaccinationStatus} onClick={handleAddEdit}>
          Confirm
        </button>
      </DialogActionsBar>
    </Dialog>
  );
}

export default CovidVaxStatusDialog;
