import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import DescriptionIcon from '@material-ui/icons/Description';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import React, { useState } from 'react';

// redux
import { useDispatch } from 'react-redux';

import utils from '../Shared/Utils/utils';
// mui

import ReportPreviewDialog from './ReportPreviewDialog';
import { downloadReport, getReportPreview } from './reportsSlice';

function ReportsCustomCell(props) {
  const { dataItem } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [previewData, setPreviewData] = useState('');
  const [openPreview, setOpenPreview] = useState(false);
  const [loadingPreview, setLoadingPreview] = useState(false);

  const dispatch = useDispatch();

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleDownloadResults = () => {
    if (dataItem) {
      const fileName = `${dataItem?.type.toLowerCase().replace(' ', '-')}_${utils.formatDateReport(new Date())}.csv`;
      dispatch(downloadReport(dataItem.tenantId, dataItem._id, fileName));
      handleCloseMenu();
    }
  };

  const handleReportPreview = async () => {
    setLoadingPreview(true);

    if (dataItem) {
      const res = await dispatch(getReportPreview(dataItem.tenantId, dataItem._id));
      setPreviewData(res);
      setOpenPreview(true);
      setLoadingPreview(false);
      handleCloseMenu();
    }
  };

  const getActions = () => {
    const menuItems = [];

    if (dataItem && dataItem.status && dataItem.status.toUpperCase() === 'COMPLETED') {
      menuItems.push(
        <MenuItem key="preview" onClick={handleReportPreview}>
          <ListItemIcon>
            <DescriptionIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Preview Report</Typography>
        </MenuItem>,
        <MenuItem key="results" onClick={handleDownloadResults}>
          <ListItemIcon>
            <CloudDownloadIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Download Report</Typography>
        </MenuItem>
      );
    }

    return menuItems;
  };

  const menu = getActions();

  if (menu && menu.length) {
    return (
      <td>
        <Grid container alignItems="center">
          <Grid item>
            <IconButton aria-controls="actions-menu" aria-haspopup="true" onClick={handleOpenMenu}>
              <MoreHorizIcon />
            </IconButton>
          </Grid>

          <Menu
            id="actions-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
            load="true"
            disableScrollLock
          >
            <div className={`loading-preview-wrapper ${loadingPreview ? 'loading-preview-visible' : ''}`}>
              <CircularProgress size={24} className="loading-preview" />
            </div>

            {menu}
          </Menu>

          {openPreview ? (
            <ReportPreviewDialog
              open={openPreview}
              handleClose={() => setOpenPreview(false)}
              previewData={previewData}
              reportType={dataItem.type}
            />
          ) : null}
        </Grid>
      </td>
    );
  }
  return (
    <td>
      <p />
    </td>
  );
}

export default ReportsCustomCell;
