import { createSlice } from '@reduxjs/toolkit';

import api from '../../api';
import { showNotification } from '../Notifications/notificationSlice';
import { handleError } from '../Shared/Content/APIUtils';

import { testIdName } from './helpers/mappings';

const initialState = {};

export const tenantLabTestsSlice = createSlice({
  name: 'tenantLabTests',
  initialState,
  reducers: {
    setTenantLabTestsLoading: (state, { payload }) => ({
      ...state,
      [payload]: {
        ...state[payload],
        loading: true,
        error: null,
      },
    }),
    setTenantLabTests: (state, { payload }) => {
      if (payload.pageNumber) {
        return {
          ...state,
          [payload.testType]: {
            list: {
              ...payload.data,
              pageNumber: payload.pageNumber,
            },
            loading: false,
          },
        };
      }
      return {
        ...state,
        [payload.testType]: {
          list: payload.data,
          loading: false,
        },
      };
    },
    setTenantLabTestsFailure: (state, { payload }) => ({
      ...state,
      [payload.testType]: {
        ...state[payload.testType],
        error: payload.error,
        loading: false,
      },
    }),
    labTestsSubmitting: (state, { payload }) => ({
      ...state,
      [payload]: {
        ...state[payload],
        submitting: true,
      },
    }),
    labTestsSubmitted: (state, { payload }) => ({
      ...state,
      [payload]: {
        ...state[payload],
        submitting: false,
      },
    }),
  },
});

export const {
  setTenantLabTests,
  setTenantLabTestsLoading,
  setTenantLabTestsFailure,
  labTestsSubmitting,
  labTestsSubmitted,
} = tenantLabTestsSlice.actions;

export const saveTenantLabTests = (tenant, type, listName, endpoint, labTestIds) => async (dispatch) => {
  dispatch(labTestsSubmitting(type));

  const data = {
    tenantId: tenant,
    [listName]: labTestIds,
  };
  const [result, error] = await api.saveTenantLabTestsRequest(endpoint, tenant, data);

  if (result) {
    dispatch(labTestsSubmitted(type));
    dispatch(showNotification('List updated successfully', 'success'));
    dispatch(getTenantLabTests(tenant, type, endpoint, listName));
  }

  if (error && error.message) {
    dispatch(labTestsSubmitted(type));
    dispatch(handleError(error));
  }
};

export const getTenantLabTests = (tenant, type, endpoint) => async (dispatch) => {
  dispatch(setTenantLabTestsLoading(type));

  const [masterResult, masterError] = await api.getLabTestsVendorsRequest(endpoint);

  if (masterResult) {
    const [result, error] = await api.getPaginatedLabTestsRequest(tenant, type);

    if (result) {
      const labTestFiltered = masterResult.filter((i) => {
        const foundTest = result.find((item) => item[testIdName[type]] === i._id);
        return !!foundTest;
      });
      dispatch(setTenantLabTests({ data: labTestFiltered, testType: type }));
    }

    if (error && error.message) {
      dispatch(setTenantLabTestsFailure({ testType: type, error: error.message }));
      dispatch(handleError(error));
    }
  }

  if (masterError && masterError.message) {
    dispatch(setTenantLabTestsFailure({ testType: type, error: masterError.message }));
    dispatch(handleError(masterError));
  }
};

export default tenantLabTestsSlice.reducer;
