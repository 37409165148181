import { CircularProgress, Typography, MenuItem, Menu, ListItemIcon, Grid, withStyles } from '@material-ui/core';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import BlockOutlinedIcon from '@material-ui/icons/BlockOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';

// mui
import React, { useState } from 'react';

import ActionCellStyles from '../../../assets/jss/components/ActionCellStyles';
import ActionButton from '../Forms/KendoFields/ActionButton';
import { actionTypes } from './helpers/mappings';

function ActionsCell(props) {
  const {
    dataItem,
    menu,
    statusFieldName,
    handleActionClick,
    enablerPositiveLabel,
    enablerNegativeLabel,
    disabled,
    loading,
    classes,
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const isEnabled = statusFieldName ? dataItem[statusFieldName] : dataItem.enabled;

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleAction = (actionType) => {
    handleCloseMenu();
    handleActionClick(actionType);
  };

  const getActions = (menuItem) => {
    // Construct menu from keywords passed in from Mapper
    switch (menuItem) {
      case actionTypes.EDIT.key:
        return (
          <MenuItem key={actionTypes.EDIT.key} onClick={() => handleAction(actionTypes.EDIT.key)}>
            <ListItemIcon style={{ minWidth: 35 }}>
              <EditOutlinedIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">{actionTypes.EDIT.label}</Typography>
          </MenuItem>
        );

      case actionTypes.DELETE.key:
        return (
          <MenuItem key={actionTypes.DELETE.key} onClick={() => handleAction(actionTypes.DELETE.key)}>
            <ListItemIcon style={{ minWidth: 35 }}>
              <DeleteOutlinedIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">{actionTypes.DELETE.label}</Typography>
          </MenuItem>
        );

      case actionTypes.ENABLER.key:
        return (
          <MenuItem key="enabler" onClick={() => handleAction(actionTypes.ENABLER.key)} disabled={disabled}>
            <ListItemIcon style={{ minWidth: '35px' }}>
              {isEnabled ? <BlockOutlinedIcon /> : <AddCircleOutlineOutlinedIcon />}
            </ListItemIcon>
            <Typography variant="inherit">
              {isEnabled
                ? enablerNegativeLabel || actionTypes.ENABLER.labelFalse
                : enablerPositiveLabel || actionTypes.ENABLER.labelTrue}
            </Typography>
          </MenuItem>
        );

      case actionTypes.VIEW.key:
        return (
          <MenuItem key={actionTypes.VIEW.key} onClick={() => handleAction('details')}>
            <ListItemIcon style={{ minWidth: '35px' }}>
              <VisibilityOutlinedIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">{actionTypes.VIEW.label}</Typography>
          </MenuItem>
        );

      case actionTypes.INVITE.key:
        return (
          <MenuItem key={actionTypes.INVITE.key} onClick={() => handleAction(actionTypes.INVITE.key)}>
            <ListItemIcon style={{ minWidth: '35px' }}>
              <MailOutlineIcon />
            </ListItemIcon>
            <Typography variant="inherit">{actionTypes.INVITE.key}</Typography>
          </MenuItem>
        );

      case actionTypes.REINVITE.key:
        return (
          <MenuItem key={actionTypes.REINVITE.key} onClick={() => handleAction(actionTypes.REINVITE.key)}>
            <ListItemIcon style={{ minWidth: '35px' }}>
              <MailOutlineIcon />
            </ListItemIcon>
            <Typography variant="inherit">{actionTypes.REINVITE.label}</Typography>
          </MenuItem>
        );

      default:
        return null;
    }
  };

  if (menu && menu.length) {
    return (
      <td>
        <Grid container alignItems={'center'}>
          <Grid item>
            <ActionButton onClick={handleOpenMenu} />
          </Grid>

          <Menu
            className={'actions-menu'}
            anchorEl={anchorEl}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
            disableScrollLock
          >
            <div style={{ visibility: loading ? 'visible' : 'hidden' }}>
              <Grid className={classes.actionLoading}>
                <CircularProgress size={28} thickness={6} />
              </Grid>
            </div>

            {menu.map((item) => getActions(item))}
          </Menu>
        </Grid>
      </td>
    );
  }
  return (
    <td>
      <p />
    </td>
  );
}

export default withStyles(ActionCellStyles)(ActionsCell);
