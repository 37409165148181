import Typography from '@material-ui/core/Typography';
import React from 'react';

import styles from '../../../assets/Questionnaire.module.css';
import utils from '../../utils/utils';

function Description(props) {
  const { description, descriptionStyle, selectedLanguage } = props;

  const fieldDescription =
    selectedLanguage && description && description.translations
      ? description.translations[selectedLanguage]
      : description;

  return (
    fieldDescription && (
      <Typography
        className={descriptionStyle ? styles[`description--${descriptionStyle}`] : styles['description--style1']}
      >
        {utils.renderHTMLTags(fieldDescription)}
      </Typography>
    )
  );
}

export default Description;
