import format from 'date-fns/format'


export const statusField = {
  type: 'toggle',
  name: 'status',
  label: 'Status',
  required: false,
}

export const tokenIdField = {
  type: 'text',
  name: 'cardTokenID',
  label: 'Card Token ID (READ ONLY)',
  readOnly: true,
};

export const memberIDField = {
  type: 'text',
  name: 'memberID',
  label: 'Member ID',
  readOnly: false,
};

export const groupIDField = {
  type: 'text',
  name: 'groupID',
  label: 'Group ID',
  readOnly: false,
};

export const rxBINField = {
  type: 'text',
  name: 'rxBIN',
  label: 'rxBIN',
  readOnly: false,
};

export const pCNField = {
  type: 'text',
  name: 'pcn',
  label: 'pCN',
  readOnly: false,
};


/* PERSONAL INFO */
export const firstNameField = {
  type: 'text',
  name: 'firstName',
  label: 'First Name',
  readOnly: false,
};

export const lastNameField = {
  type: 'text',
  name: 'lastName',
  label: 'Last Name',
  readOnly: false,
};

export const genderField = {
  type: 'text',
  name: 'gender',
  label: 'Gender (READ-ONLY)',
  readOnly: true,
};

export const last4SSNField = {
  type: 'text',
  name: 'last4SSN',
  label: 'Last 4 SSN (READ-ONLY)',
  readOnly: true,
};

// export const last4SSNFieldNumeric = {
//   type: 'text',
//   name: 'last4SSN',
//   label: 'Last 4 SSN',
//   readOnly: false,
// };

export const last4SSNFieldNumeric = {
  name: 'last4SSN',
  label: 'Last 4 SSN',
  max: 9999,
  spinners: false,
  readOnly: false,
  numFormat: 'last4ssn', // will remove comma
};


export const phoneNumber1Field = {
  type: 'text',
  name: 'phoneNumber1',
  label: 'Phone Number',
  readOnly: false,
};

export const dateOfBirthField= {
  type: 'date',
  name: 'dateOfBirth',
  label: 'Date of Birth',
};


/* ADDRESS FIELDS */
export const addressLine1Field = {
  type: 'text',
  name: 'addressLine1',
  label: 'Address Line 1',
  readOnly: false,
};

export const cityField = {
  type: 'city',
  name: 'city',
  label: 'City',
  readOnly: false,
};

export const stateField = {
  type: 'text',
  name: 'state',
  label: 'State',
  readOnly: false,
};

export const postalcodeField = {
  name: 'postalcode',
  label: 'Postal Code',
  spinners: false,
  readOnly: false,
  numFormat: 'custom', // will remove comma
};

export const countryField = {
  type: 'text',
  name: 'country',
  label: 'Country',
  readOnly: false,
};



/* useReducer Updater Functions */
export const subscriberUpdaterFn = (fieldName, fieldVal) =>  (state) => {
    let newState = structuredClone(state)

    switch(fieldName.toUpperCase()) {
      case 'STATUS':
        return {
          ...state,
          subscriber: {
            ...state.subscriber,
            status: fieldVal,
          }
        }

      /* HEALTH INFO UPDATER */
      case 'MEMBERID':
        return {
          ...state,
          subscriber: {
            ...state.subscriber,
            healthCare: {
              ...state.subscriber.healthCare,
              memberID: fieldVal
            }
          }
        }
      case 'GROUPID':
        return {
          ...state,
          subscriber: {
            ...state.subscriber,
            healthCare: {
              ...state.subscriber.healthCare,
              groupID: fieldVal
            }
          }
        }
      case 'RXBIN':
        return {
          ...state,
          subscriber: {
            ...state.subscriber,
            healthCare: {
              ...state.subscriber.healthCare,
              rxBIN: fieldVal
            }
          }
        }
      case 'PCN':
        return {
          ...state,
          subscriber: {
            ...state.subscriber,
            healthCare: {
              ...state.subscriber.healthCare,
              pcn: fieldVal
            }
          }
        }

      /* PERSONAL INFO */
      case 'FIRSTNAME':
        newState.subscriber.demographicInfo.name.firstName = fieldVal;
        return newState;

      case 'LASTNAME':
        newState.subscriber.demographicInfo.name.lastName = fieldVal;
        return newState;

      case 'PHONENUMBER1':
        console.log()
        newState.subscriber.demographicInfo.phone.phoneNumber1 = fieldVal;
        return newState;

      case 'DATEOFBIRTH':
        const dateStr = format(fieldVal, 'MMddyyyy');
        newState.subscriber.demographicInfo.dateOfBirth = dateStr;
        return newState;

      /* ADDRESS */
      case 'ADDRESSLINE1':
        newState.subscriber.demographicInfo.address.addressLine1 = fieldVal;
        return newState;

      case 'CITY':
        newState.subscriber.demographicInfo.address.city = fieldVal;
        return newState;

      case 'STATE':
        newState.subscriber.demographicInfo.address.state = fieldVal;
        return newState;

      case 'POSTALCODE':
        newState.subscriber.demographicInfo.address.postalcode = fieldVal;
        return newState;

      case 'COUNTRY':
        newState.subscriber.demographicInfo.address.country = fieldVal;
        return newState;

      /* ADDRESS UPDATER */
      default:
        return state;
    }
  }

  //default values for demographic info
  // This is used to avoid error when demograpic info was not successfully set during
  // Dependent creation.
  export const defaultDemographicInfo = {
    name: { firstName: "First Name", lastName: "Last Name" },
    phone: { phoneNumber1: "(555) 5555 555" },
    dateOfBirth: "01012023",
    gender: "M",
    last4SSN: 0,

    address: {
      addressLine1: "Willshire Blvd",
      city: "Los Angeles",
      state: "California",
      postalcode: 0,
      country: "US"
    }
  }

  export const dependentUpdaterFn = (fieldName, fieldVal, idx) => (state) => {
    let newState = structuredClone(state);
    const { dependents } = newState;

    /* IMPORTANT!
        If the newly created Dependent didn't return any Demographic obj property
        then we need to set a new one
    */
    let demographicInfo = dependents[idx]?.demographicInfo
      ? dependents[idx].demographicInfo
      : dependents[idx].demographicInfo = defaultDemographicInfo /* assign default value if undefined */;
    const { name, phone, address } = demographicInfo;

    // console.log(state)
    // console.log(demographicInfo)
    // console.log(dependents[idx])
    console.log(fieldName, fieldVal)

    switch(fieldName.toUpperCase()) {
      case 'STATUS':
        // FIX: since status field is outside of demographicsInfo
        // note that this dependents array reference variable is still attached to th
        // the newState variable.
        dependents[idx].status = fieldVal;
        return {
          ...newState,
        }

      /* PERSONAL INFO */
      case 'FIRSTNAME':
        name.firstName = fieldVal;
        return {...newState, ...demographicInfo};

      case 'LASTNAME':
        name.lastName = fieldVal;
        return newState;

      case 'PHONENUMBER1':
        phone.phoneNumber1 = fieldVal;
        return newState;

      case 'DATEOFBIRTH':
        const dateStr = format(fieldVal, 'MMddyyyy');
        demographicInfo.dateOfBirth = dateStr;
        return newState;

      /* ADDRESS */
      case 'ADDRESSLINE1':
        address.addressLine1 = fieldVal;
        return newState;

      case 'CITY':
        address.city = fieldVal;
        return newState;

      case 'STATE':
        address.state = fieldVal;
        return newState;

      case 'POSTALCODE':
        address.postalcode = fieldVal;
        return newState;

      case 'COUNTRY':
        address.country = fieldVal;
        return newState;

      default:
        return state;
      }
  }

  function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
  }

  export const addDependent = (newDependent) => (state) => {
    let newState = structuredClone(state);
    newState.dependents.push(newDependent)
    console.log(newState)
    return newState;
  }


  export const setNewDependentFn = (fieldName, fieldVal) => (state) => {
    let newState = structuredClone(state);
    const {
      name,
      phone,
      address,
    } = newState;

    switch(fieldName.toUpperCase()) {
      /* PERSONAL INFO */
      case 'FIRSTNAME':
        name.firstName = fieldVal;
        return newState;

      case 'LASTNAME':
        name.lastName = fieldVal;
        return newState;

      case 'PHONENUMBER1':
        phone.phoneNumber1 = fieldVal;
        return newState;

      case 'DATEOFBIRTH':
        const dateStr = isValidDate(fieldVal) ? format(fieldVal, 'MMddyyyy') : '01012023';
        newState.dateOfBirth = dateStr;
        return newState;

      case 'GENDER':
        newState.gender = fieldVal;
        return newState;

      case 'LAST4SSN':
        newState.last4SSN = Number(fieldVal);
        return newState;

      /* ADDRESS */
      case 'ADDRESSLINE1':
        address.addressLine1 = fieldVal;
        return newState;

      case 'CITY':
        address.city = fieldVal;
        return newState;

      case 'STATE':
        address.state = fieldVal;
        return newState;

      case 'POSTALCODE':
        address.postalcode = fieldVal;
        return newState;

      case 'COUNTRY':
        address.country = fieldVal;
        return newState;

      default:
        return state;
      }
  }