import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

import utils from '../Shared/Utils/utils';

function ReportWrapper(props) {
  const { report, data } = props;
  const { fields } = report;

  const classes = useStyles();

  return (
    <Grid md={report.width} xs={12} className={classes.reportWrapper} item>
      {/* header */}
      {report.header && <Grid className={classes.reportHeader}>{report.header.toUpperCase()}</Grid>}

      {/* content */}
      {report.name || fields ? (
        <Grid className={classes.reportBody}>
          <Grid container>
            {fields && fields.length ? (
              fields.map((field) => (
                <>
                  <Grid xs={6} className={classes.reportBodyItem} style={{ fontWeight: 'bold' }}>
                    {field.label.toUpperCase()}:
                  </Grid>

                  <Grid xs={6} className={classes.reportBodyItem}>
                    {data[field.name]}
                  </Grid>
                </>
              ))
            ) : (
              <Grid container justifyContent="flex-end">
                <Grid xs={report.valueWidth || 12} className={classes.reportBodyItem}>
                  {report.additionalText ? utils.renderHTMLTags(`<strong>${report.additionalText} </strong>`) : ''}
                  {data[report.name]}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      ) : null}
    </Grid>
  );
}

const useStyles = makeStyles({
  reportWrapper: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: 12,
    padding: 2,
  },
  reportHeader: {
    backgroundColor: '#EEEFF2',
    height: 30,
    padding: '8px 10px',
    fontWeight: 700,
  },
  reportBody: {
    height: '100%',
    minHeight: 60,
    backgroundColor: '#F9F9F9',
    padding: '8px 0',
  },
  reportBodyItem: {
    padding: '0 10px',
    marginBottom: 7,
  },
});

export default ReportWrapper;
