import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAccountDetails } from '../Account/selectors';
import { getNetworkByTenantId, updateNetwork } from '../Networks/networksSlice';
import { selectCurrentTenant } from '../Shared/selectors';
import AppKendoTableWrapper from '../Shared/Table/KendoTableWrapper';
import { transform, columns, initialSorting } from './helpers/medicalCentersListMapper';
import { getVendorsList } from './labTestsSlice';
import { selectLabTests } from './selectors';

function VendorsSelectionWrapper(props) {
  const { type, onCancel, initialSelected, filter, saveObject } = props;
  const currentTenant = useSelector(selectCurrentTenant);
  const labTests = useSelector(selectLabTests);
  const account = useSelector(selectAccountDetails);

  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(initialSelected || []);
  const [enableSave, setEnableSave] = useState(false);
  const list = labTests && labTests[type] && labTests[type].list;
  const [selectAll, setSelectAll] = useState(false);

  const dispatch = useDispatch();

  const handleSelectionChange = useCallback(
    (item) => {
      const newSelectedList = [...selected];
      setEnableSave(true);

      const indexInList = newSelectedList.findIndex((v) => (saveObject ? v._id === item._id : v === item._id));

      if (indexInList > -1) {
        newSelectedList.splice(indexInList, 1);
      } else {
        newSelectedList.push(saveObject ? item : item._id);
      }

      setSelected(newSelectedList);

      const initialInNew = initialSelected.filter((i) => newSelectedList.indexOf(i) < 0);
      const newInInitial = newSelectedList.filter((i) => initialSelected.indexOf(i) < 0);
      setEnableSave(initialInNew.length > 0 || newInInitial.length > 0);
    },
    [selected, saveObject]
  );

  const cols = columns(true);

  // Initial Load
  useEffect(() => {
    dispatch(getVendorsList(currentTenant));
  }, [type, filter, currentTenant]);

  // empty placeholder data
  const data = useMemo(
    () => ({
      total: (list && list.foundItemsCount) || 0,
      pageSize: (list && list.pageSize) || 0,
      page: (list && list.pageNumber) || 0,
      items: (list && transform[type](list, saveObject ? selected.map((i) => i._id) : selected)) || [],
    }),
    [list, type, selected, saveObject]
  );

  const onSave = async () => {
    setLoading(true);
    try {
      const currentNetwork = await dispatch(getNetworkByTenantId(currentTenant));

      const selectedVendors = list
        .filter((item) => selected.some((id) => id === item._id))
        .map((v) => ({
          vendorId: v._id,
          vendorName: v.name,
          vendorKey: v.vendorKey,
          labSettings: {
            accountId: '',
            receivingApplication: '',
            sendingApplication: '',
            receivingFacility: '',
            sendingFacility: '',
          },
        }))
        .map((v) => {
          if (currentNetwork.enabledLabVendorsList) {
            const existingVendor = currentNetwork.enabledLabVendorsList.find((item) => item.vendorId === v.vendorId);
            if (existingVendor) return existingVendor;
          }
          return v;
        });

      const updatedNetwork = {
        Data: {
          ...currentNetwork,
          enabledLabVendorsList: selectedVendors,
        },
      };

      await dispatch(updateNetwork(currentTenant.id, updatedNetwork, account?.id));
    } catch (e) {
      setLoading(false);
    }
    onCancel();
  };

  const handleSelectAll = (value) => {
    setSelected([]);
    setSelectAll(value);
  };

  // on click of select all, select/unselect all items on page
  const headerSelectionChange = (event) => {
    let newSelectIds = [...selected];
    const { checked } = event.syntheticEvent.target;
    if (!checked) {
      newSelectIds = newSelectIds.filter((id) => data.items.map((item) => item._id).indexOf(id) === -1);
    } else {
      newSelectIds = [
        ...newSelectIds,
        ...data.items.filter((item) => newSelectIds.indexOf(item._id) === -1).map((item) => item._id),
      ];
    }

    setSelected(newSelectIds);
    setEnableSave(true);
  };

  return (
    <Grid container justifyContent="flex-end" spacing={2}>
      <Grid item xs={12}>
        <AppKendoTableWrapper
          data={data}
          initialSort={initialSorting[type].field}
          initialDir={initialSorting[type].dir}
          onSelectionChange={handleSelectionChange}
          onSelectAll={handleSelectAll}
          onHeaderSelectionChange={headerSelectionChange}
          selectIds={selected}
          selectAll={selectAll}
          showButtons={false}
          showSearchbar={false}
          columns={cols[type]}
          loading={loading}
          disableAutoFocus
        />
      </Grid>
      <Grid item>
        <Button color="primary" variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
      </Grid>
      <Grid item>
        <Button color="primary" variant="contained" onClick={onSave} disabled={!enableSave}>
          Save
        </Button>
      </Grid>
    </Grid>
  );
}

export default VendorsSelectionWrapper;
