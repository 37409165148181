/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/namespace */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable no-return-assign */
import moment from 'moment';
import utils from '../../Shared/Utils/utils';

import { DURATION } from './mapping';

const RACES = {
  AMERICAN_INDIAN_OR_ALASKA_NATIVE: 'Native Americans or Alaska native',
  ASIAN: 'Asian',
  BLACK_OR_AFRICAN_AMERICAN: 'Black or African American',
  NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER: 'Native Hawaiian or Pacific Islander',
  WHITE: 'White',
  OTHER: 'Other',
};

const testMapping = {
  negative: {
    name: 'Negative',
    color: '#43B040',
  },
  positive: {
    name: 'Positive',
    color: '#F04343',
  },
  invalid: {
    name: 'Invalid',
    color: '#F0AC46',
  },
};

export const getLabelStep = (categories) => {
  if (categories) {
    const labelsLength = categories.length;
    if (labelsLength > 20 && labelsLength <= 60) return 2;
    if (labelsLength > 60) return 4;
  }
  return 1;
};

/**
 * Format date that comes from the server to applicable for charts
 * @param inDate
 * @returns {string}
 */
export const formatDate = (inDate) => {
  let date = inDate;
  if (date && (date.endsWith('AM') || date.endsWith('PM'))) {
    date = date.substring(0, date.length - 2);
  }
  return moment(date).format('MM/D');
};

/**
 * Format number
 * @param num
 * @returns {*|string|string|number}
 */
export const formatNumber = (num) => {
  let outNum = num;

  // Try to use Intl object to format number to '1,000.999'
  if (!Number.isNaN(num)) {
    if (Intl) {
      const intl = new Intl.NumberFormat('en-US');
      outNum = intl.format(num);

      if (outNum === 'NaN') outNum = num;
    } else {
      outNum = num.toFixed(3);
    }
  }
  return outNum;
};

/**
 * Convert demographics internal data to human friendly
 * @param data
 */
export const processEthnicity = (data) => ({
  data: data.map((item) => ({
    value: item.value,
    label: utils.removeUnderscoresTitleCase((item.label = RACES[item.label] || item.label)),
  })),
});

/**
 * Prepare gender data for BarChart
 * @param data
 * @returns {{data: [{data: []}], categories: []}}
 */
export const processGenderGroups = (data) => {
  const result = {
    data: [
      {
        data: [],
      },
    ],
    categories: [],
  };
  for (const genderId in data) {
    result.data[0].data.push(data[genderId]);
    result.categories.push(utils.removeUnderscoresTitleCase(data[genderId].label));
  }

  return result;
};

/**
 * Prepare data for Bar char
 * @param data {Array} Data to processs
 * @param options? {Object} optional parameter
 * @param options.formatTimeline {Boolean} format time line if true
 * @returns {{data: [{data: []}], categories: []}}
 */
export const processBarChartData = (data, options) => {
  const result = {
    data: [
      {
        data: [],
      },
    ],
    categories: [],
  };
  for (const item of data) {
    result.data[0].data.push(item.value);
    if (options?.formatTimeline) {
      result.categories.push(formatDate(item.label));
    } else {
      result.categories.push(item.label);
    }
  }
  return result;
};

export const processSidelinedMembers = (data) => {
  const result = {
    data: [
      {
        name: 'Present',
        data: [],
        color: '#F0AC46',
      },
      {
        name: 'Future',
        data: [],
        // color: "#ffecdc",
        color: '#FFF',
        border: '#F0AC46',
      },
    ],
    categories: [],
  };
  for (const item in data) {
    result.categories.push(formatDate(item.label));

    const isCurrentDate = moment(new Date(item.label)).isSame(moment(), 'day');
    if (isCurrentDate) {
      result.data[0].data.push(item.value);
      result.data[1].data.push(0);
    } else {
      result.data[1].data.push(item.value);
    }
  }

  return result;
};

const formatLabel = (date, duration) => {
  let formattedDate = date;
  /* eslint no-case-declarations: 2 */
  switch (duration) {
    case DURATION.DAILY:
      formattedDate = formatDate(date);
      break;
    case DURATION.WEEKLY:
      // eslint-disable-next-line no-case-declarations
      const splitted = date.split('-');
      formattedDate =
        splitted.length > 1 ? [formatDate(splitted[0]), formatDate(splitted[1])].join(' - ') : formatDate(splitted[0]);
      break;
    default:
      formattedDate = date;
  }

  return formattedDate;
};

export const processTestDistribution = (data, duration) => {
  const categories = [];
  const res = {
    negative: {
      name: 'Negative',
      data: [],
      color: '#43B040',
    },
    positive: {
      name: 'Positive',
      data: [],
      color: '#F04343',
    },
    invalid: {
      name: 'Invalid',
      data: [],
      color: '#F0AC46',
    },
  };

  for (const key in data) {
    data[key].forEach((item) => {
      res[key].data.push(item.value);
      const formattedDate = formatLabel(item.label, duration);
      if (!categories.includes(formattedDate)) categories.push(formattedDate);
    });
  }
  return { data: Object.values(res), categories };
};

export const processVaccinationDistribution = (data, duration) => {
  const categories = [];
  const result = {
    fullyVaccinated: {
      name: 'Fully Vaccinated',
      data: [],
      color: '#198916',
    },
    partiallyVaccinated: {
      name: 'Partially Vaccinated',
      data: [],
      color: '#F0AC46',
    },
    unvaccinated: {
      name: 'Unvaccinated',
      data: [],
      color: '#00A3B8',
    },
    exempt: {
      name: 'Exempt',
      data: [],
      color: '#3F50B5',
    },
  };

  for (const key in data) {
    data[key].forEach((item) => {
      result[key].data.push(item.value);
      const formattedDate = formatLabel(item.label, duration);
      if (!categories.includes(formattedDate)) categories.push(formattedDate);
    });
  }

  return { data: Object.values(result), categories };
};

export const processTotalTestStatus = (inputData) => {
  const result = [];
  for (const key in inputData) {
    if (key !== 'total') {
      result.push({
        value: inputData[key]?.percentage,
        label: testMapping[key].name,
        color: testMapping[key].color,
      });
    }
  }

  return {
    data: result,
  };
};

export const processTotalVaccinationStatus = (inputData) => {
  const vaccination = {
    vaccinated: {
      name: 'Vaccinated',
      color: '#198916',
    },
    unvaccinated: {
      name: 'Unvaccinated',
      color: '#00A3B8',
    },
    exempt: {
      name: 'Exempt',
      color: '#3F50B5',
    },
  };

  const result = [];
  for (const key in inputData) {
    result.push({
      value: inputData[key],
      label: vaccination[key].name,
      color: vaccination[key].color,
    });
  }

  return {
    data: result,
  };
};

export const processActiveInvitedStat = (inputData) => {
  const activeInvited = {
    active: {
      name: 'Active',
      data: [],
      color: '#00A3B8',
    },
    invited: {
      name: 'Invited',
      color: '#3F50B5',
    },
  };

  const result = { data: [] };
  for (const key in inputData) {
    result.data.push({
      value: inputData[key],
      label: activeInvited[key].name,
      color: activeInvited[key].color,
    });
  }

  return result;
};

export const processHistogramData = (data, type) => {
  const histogramRange = (type) => {
    // eslint-disable-next-line default-case
    switch (type) {
      case 'Execution Time':
        return 50;
      case 'Response Time':
        return 80;
    }
  };
  const result = {
    data: [
      {
        data: [],
      },
    ],
    categories: [],
    axisLabel: `${type} (seconds)`,
  };
  for (let i = 0; i <= histogramRange(type); i++) {
    const step = type === 'Execution Time' ? i : i / 4;
    const dataItem = data.find((item) => item.label === `${step}`);

    if (dataItem) {
      result.data[0].data.push(dataItem.value || '0');
      result.categories.push(dataItem.label || `${step}`);
    } else {
      result.data[0].data.push('0');
      result.categories.push(`${step}`);
    }
  }
  return result;
};

/**
 * Convert gender distribution to suitable format
 * @param data Data from endpoint required to convert
 */
export const processMultiLineChart = (data) => {
  const result = {
    data: [],
    categories: [],
  };
  for (const category in data) {
    // const categoryData = data[category];
    result.data.push({
      name: utils.splitCamelcaseString(category).toLowerCase(),
      data: data[category].map((item) => item.value),
    });
    if (result.categories.length == 0) {
      result.categories = data[category].map((item) => formatDate(item.label));
    }
  }
  return result;
};

/**
 * Combine statistics responses into one structure
 * @param data {Object} collected statistic data to combine
 * @returns {{testTaken: {total: number, dailyAverage: number}, specimenCollected: {total: number, dailyAverage: number}, insured: {total: number, dailyAverage: number}, testsCompleted: {total: number, dailyAverage: number}}}
 */
export const combineStatistics = (data) => ({
  sidelinedUsers: {
    value: formatNumber(data?.sidelinedUsers?.value),
    total: formatNumber(data?.sidelinedUsers?.total),
    percentage: formatNumber(data?.sidelinedUsers?.percentage),
  },
  positiveTests: {
    value: formatNumber(data?.positiveTests?.value),
    total: formatNumber(data?.positiveTests?.total),
    percentage: formatNumber(data?.positiveTests?.percentage),
  },
  negativeTests: {
    value: formatNumber(data?.negativeTests?.value),
    total: formatNumber(data?.negativeTests?.total),
    percentage: formatNumber(data?.negativeTests?.percentage),
  },
  invalidTests: {
    value: formatNumber(data?.invalidTests?.value),
    total: formatNumber(data?.invalidTests?.total),
    percentage: formatNumber(data?.invalidTests?.percentage),
  },
  totalTests: {
    value: formatNumber(data?.totalTests?.value),
    total: formatNumber(data?.totalTests?.total),
    percentage: formatNumber(data?.totalTests?.percentage),
  },
});
