import { createSlice } from '@reduxjs/toolkit';

import api from '../../api';
import { handleError } from '../Shared/Content/APIUtils';

const initialState = {
  publicHealthReportInfo: null,
  publicHealthReportInfoLoading: null,
};

export const publicHealthReportSlice = createSlice({
  name: 'publicHealthReport',
  initialState,
  reducers: {
    setPublicHealthReportInfoLoading: (state) => ({
      ...state,
      publicHealthReportInfoLoading: true,
    }),
    setPublicHealthReportInfo: (state, { payload }) => ({
      ...state,
      publicHealthReportInfo: payload,
      publicHealthReportInfoLoading: false,
    }),
  },
});

export const { setPublicHealthReportInfo, setPublicHealthReportInfoLoading } = publicHealthReportSlice.actions;

export const getPublicHealthReport = (encounterId) => async (dispatch) => {
  dispatch(setPublicHealthReportInfoLoading());

  const [result, error] = await api.getPublicHealthReportRequest(encounterId);

  if (error && error.message) {
    dispatch(handleError(error));
  }

  if (result) {
    dispatch(setPublicHealthReportInfo(result));
  }
};

export default publicHealthReportSlice.reducer;
