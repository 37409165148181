import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import React from 'react';

function SettingsToggles(props) {
  const {
    type,
    enabledTemplate,
    handleSettingsToggleChange,
    enabledSMS,
    enabledPushNotifications,
    enabledEmail,
    enabledSAFE,
  } = props;
  return (
    <Grid container>
      <Grid style={{ display: 'inline-flex' }} item xs={12}>
        <Switch
          checked={enabledTemplate}
          onChange={() => handleSettingsToggleChange('enabled', !enabledTemplate, type)}
          name="emailSending"
          color="primary"
        />
        <Typography style={{ lineHeight: 2.5 }} variant={'subtitle1'}>
          Enable
        </Typography>
      </Grid>
      <Grid style={{ display: 'inline-flex', marginLeft: '15px' }} item xs={12}>
        <Switch
          checked={type === 'email' ? enabledEmail : enabledSMS}
          onChange={() =>
            handleSettingsToggleChange(
              type === 'email' ? 'email' : 'sms',
              type === 'email' ? !enabledEmail : !enabledSMS,
              type
            )
          }
          name="emailSending"
          color="primary"
        />
        <Typography style={{ lineHeight: 2.5 }} variant="subtitle1">
          {type === 'email' ? 'Email' : 'SMS'}
        </Typography>
      </Grid>
      <Grid style={{ display: 'inline-flex', marginLeft: '15px' }} item xs={12}>
        <Switch
          checked={type === 'email' ? enabledSAFE : enabledPushNotifications}
          onChange={() =>
            handleSettingsToggleChange(
              type === 'email' ? 'safe' : 'push',
              type === 'email' ? !enabledSAFE : !enabledPushNotifications,
              type
            )
          }
          name="emailSending"
          color="primary"
        />
        <Typography style={{ lineHeight: 2.5 }} variant="subtitle1">
          {type === 'email' ? 'App Messenger' : 'Push Notifications'}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default SettingsToggles;
