export const selectAllergyList = (state) => state.allergyList.allergyList;
export const selectAllergyListLoading = (state) => state.allergyList.allergyListLoading;
export const selectConditionList = (state) => state.conditionList.conditionList;
export const selectConditionListLoading = (state) => state.conditionList.conditionListLoading;
export const selectMedicationList = (state) => state.medicationList.medicationList;
export const selectMedicationListLoading = (state) => state.medicationList.medicationListLoading;
export const selectPrescriptionList = (state) => state.prescriptionList.prescriptionList;
export const selectPrescriptionListLoading = (state) => state.prescriptionList.prescriptionListLoading;
export const selectImmunizationList = (state) => state.immunizationList.immunizationList;
export const selectImmunizationListLoading = (state) => state.immunizationList.immunizationListLoading;
export const selectVitalList = (state) => state.vitalList.vitalList;
export const selectVitalListLoading = (state) => state.vitalList.vitalListLoading;
export const selectProcedureList = (state) => state.procedureList.procedureList;
export const selectProcedureListLoading = (state) => state.procedureList.procedureListLoading;
export const selectCCDView = (state) => state.ccdViewer.ccdView;
export const selectCCDViewLoading = (state) => state.ccdViewer.ccdViewLoading;
export const selectXMLView = (state) => state.xmlViewer.xmlView;
export const selectXMLViewLoading = (state) => state.xmlViewer.xmlViewLoading;
export const selectEPrescribe = (state) => state.ePrescribe.ePrescribe;
export const selectEpicAuthUrl = (state) => state.epic.epicAuthUrl;
export const selectEpicAuthLoading = (state) => state.epic.epicAuthLoading;
export const selectCernerAuthUrl = (state) => state.cerner.cernerAuthUrl;
export const selectCernerAuthLoading = (state) => state.cerner.cernerAuthLoading;
export const selectAccessToken = (state) => state.accessToken.accessToken;
export const selectAccessTokenLoading = (state) => state.accessToken.accessTokenLoading;
