import { getDashboardButtonTitle, footerNavigationMapping } from './mappings';

export const networkLoadJsonBuilder = (currentNetwork, tenantSettings, currentTenant, consult) => ({
  // current network data
  ...currentNetwork,

  // ORDERING TEST KIT
  orderingTestKitOption: currentNetwork.orderingTestKitOption,

  medicContactStates: currentNetwork.medicContactStates && currentNetwork.medicContactStates.split(','),
  quest_accountId: currentNetwork?.labSettings?.quest?.accountId,
  quest_receivingApplication: currentNetwork?.labSettings?.quest?.receivingApplication,
  quest_sendingApplication: currentNetwork?.labSettings?.quest?.sendingApplication,
  quest_receivingFacility: currentNetwork?.labSettings?.quest?.receivingFacility,
  quest_sendingFacility: currentNetwork?.labSettings?.quest?.sendingFacility,

  // Primary Dashboard Card
  dashboardButtonAction1: currentNetwork?.dashboardSettings?.dashboardButtons?.[0]?.action,
  dashboardButtonEnabled1: currentNetwork?.dashboardSettings?.dashboardButtons?.[0]?.enabled,
  dashboardButtonStyle1: currentNetwork?.dashboardSettings?.dashboardButtons?.[0]?.style || 0,
  dashboardButtonGradientColor1: currentNetwork?.dashboardSettings?.dashboardButtons?.[0]?.colors?.[0] ?? '#ffffff',
  dashboardButtonGradientColor2: currentNetwork?.dashboardSettings?.dashboardButtons?.[0]?.colors?.[1] ?? '#ffffff',
  dashboardCardTitle1: currentNetwork?.dashboardSettings?.dashboardButtons?.[0]?.cardTitle ?? '',
  dashboardCardBody1: currentNetwork?.dashboardSettings?.dashboardButtons?.[0]?.cardBody ?? '',
  dashboardButtonImageURL1: currentNetwork?.dashboardSettings?.dashboardButtons?.[0]?.imageUrl ?? '',
  dashboardButtonTitle1:
    currentNetwork?.dashboardSettings?.dashboardButtons?.[0]?.title ||
    getDashboardButtonTitle(currentNetwork?.dashboardSettings?.dashboardButtons?.[0]?.action?.value),
  dashboardButtonVisible1: currentNetwork?.dashboardSettings?.dashboardButtons?.[0]?.visible,
  dashboardButtonUrl1: currentNetwork?.dashboardSettings?.dashboardButtons?.[0]?.url ?? '',

  // Secondary Dashboard Card
  dashboardButtonAction2: currentNetwork?.dashboardSettings?.dashboardButtons?.[1]?.action,
  dashboardButtonStyle2: currentNetwork?.dashboardSettings?.dashboardButtons?.[1]?.style || 0,
  dashboardButtonGradientColor3: currentNetwork?.dashboardSettings?.dashboardButtons?.[1]?.colors?.[0] ?? '#ffffff',
  dashboardButtonGradientColor4: currentNetwork?.dashboardSettings?.dashboardButtons?.[1]?.colors?.[1] ?? '#ffffff',
  dashboardCardTitle2: currentNetwork?.dashboardSettings?.dashboardButtons?.[1]?.cardTitle ?? '',
  dashboardCardBody2: currentNetwork?.dashboardSettings?.dashboardButtons?.[1]?.cardBody ?? '',
  dashboardButtonImageURL2: currentNetwork?.dashboardSettings?.dashboardButtons?.[1]?.imageUrl ?? '',
  dashboardButtonEnabled2: currentNetwork?.dashboardSettings?.dashboardButtons?.[1]?.enabled,
  dashboardButtonTitle2:
    currentNetwork?.dashboardSettings?.dashboardButtons?.[1]?.title ||
    getDashboardButtonTitle(currentNetwork?.dashboardSettings?.dashboardButtons?.[1]?.action?.value),
  dashboardButtonVisible2: currentNetwork?.dashboardSettings?.dashboardButtons?.[1]?.visible,
  dashboardButtonUrl2: currentNetwork?.dashboardSettings?.dashboardButtons?.[1]?.url ?? '',

  // Tertiary Dashboard Card
  dashboardButtonAction3: currentNetwork?.dashboardSettings?.dashboardButtons?.[2]?.action,
  dashboardButtonStyle3: currentNetwork?.dashboardSettings?.dashboardButtons?.[2]?.style || 0,
  dashboardButtonGradientColor5: currentNetwork?.dashboardSettings?.dashboardButtons?.[2]?.colors?.[0] ?? '#ffffff',
  dashboardButtonGradientColor6: currentNetwork?.dashboardSettings?.dashboardButtons?.[2]?.colors?.[1] ?? '#ffffff',
  dashboardCardTitle3: currentNetwork?.dashboardSettings?.dashboardButtons?.[2]?.cardTitle ?? '',
  dashboardCardBody3: currentNetwork?.dashboardSettings?.dashboardButtons?.[2]?.cardBody ?? '',
  dashboardButtonImageURL3: currentNetwork?.dashboardSettings?.dashboardButtons?.[2]?.imageUrl ?? '',
  dashboardButtonEnabled3: currentNetwork?.dashboardSettings?.dashboardButtons?.[2]?.enabled,
  dashboardButtonTitle3:
    currentNetwork?.dashboardSettings?.dashboardButtons?.[2]?.title ||
    getDashboardButtonTitle(currentNetwork?.dashboardSettings?.dashboardButtons?.[2]?.action?.value),
  dashboardButtonVisible3: currentNetwork?.dashboardSettings?.dashboardButtons?.[2]?.visible,
  dashboardButtonUrl3: currentNetwork?.dashboardSettings?.dashboardButtons?.[2]?.url ?? '',

  // Recent Care Services Dashboard Card
  dashboardEnableTestOrders: currentNetwork?.dashboardSettings?.enableTestOrders,

  // Recent Test History Dashboard Card
  dashboardEnableTestHistory: currentNetwork?.dashboardSettings?.enableTestHistory,

  enableDashboardSettings: currentNetwork?.dashboardSettings?.enableDashboardSettings,

  welcomeScreenButtonAction: currentNetwork?.welcomeScreenSettings?.welcomeScreenButton?.action,
  welcomeScreenButtonEnabled: currentNetwork?.welcomeScreenSettings?.welcomeScreenButton?.enabled,
  welcomeScreenButtonStyle: currentNetwork?.welcomeScreenSettings?.welcomeScreenButton?.style || 0,
  welcomeScreenButtonTitle: currentNetwork?.welcomeScreenSettings?.welcomeScreenButton?.title,
  welcomeScreenButtonVisible: currentNetwork?.welcomeScreenSettings?.welcomeScreenButton?.visible,

  welcomeScreenSkipButtonAction: currentNetwork?.welcomeScreenSettings?.welcomeScreenSkipButton?.action,
  welcomeScreenSkipButtonEnabled: currentNetwork?.welcomeScreenSettings?.welcomeScreenSkipButton?.enabled,
  welcomeScreenSkipButtonTitle: currentNetwork?.welcomeScreenSettings?.welcomeScreenSkipButton?.title,
  welcomeScreenSkipButtonVisible: currentNetwork?.welcomeScreenSettings?.welcomeScreenSkipButton?.visible,

  displayAIHistogramCharts: currentNetwork?.dashboardSettings?.displayAIHistogramCharts,

  // tenant settings data
  menuItems: tenantSettings.menuItems,
  disableSignUp: tenantSettings.disableSignUp === null ? true : tenantSettings.disableSignUp,
  sendEmail: tenantSettings.sendEmail,
  sendSMS: tenantSettings.sendSMS,
  sidelineUserWithPositiveTestResultEnabled: tenantSettings.sidelineUserWithPositiveTestResultEnabled,
  sidelineUserWithPositiveTestResultForDays: tenantSettings.sidelineUserWithPositiveTestResultForDays,
  symptomaticOutcomeSidelineForDays: tenantSettings.symptomaticOutcomeSidelineForDays,
  exposureOutcomeSidelineForDays: tenantSettings.exposureOutcomeSidelineForDays,
  pushNotificationsEnabled: tenantSettings.pushNotificationsEnabled,
  safeNotificationsEnabled: tenantSettings.safeNotificationsEnabled,
  symptomsCheckInterval: currentNetwork.symptomsCheckInterval || 0,
  notificationTime: currentNetwork.reminderNotificationSettings.notificationTime,
  notificationMessage: currentNetwork.reminderNotificationSettings.notificationMessage,
  maximumNumberOfSnooze: currentNetwork.reminderNotificationSettings.maximumNumberOfSnooze,
  timeBetweenSnooze: currentNetwork.reminderNotificationSettings.timeBetweenSnooze,

  // current tenant data
  root: currentTenant.root,
  template: currentTenant.template,

  // questionnaire config
  defaultQuestionnaireId: consult?.questionnaireConfig?.defaultQuestionnaireId,

  importHealthRequireAgreementScreen: currentNetwork?.importHealthRecordSettings?.importHealthRequireAgreementScreen,
  importHealthRequireIDVerification: currentNetwork?.importHealthRecordSettings?.importHealthRequireIDVerification,
  importHealthRequireSignature: currentNetwork?.importHealthRecordSettings?.importHealthRequireSignature,

  // proctorSettings
  enableProctorFeature: currentNetwork.enableProctorFeature,

  // vaccinationSettings
  enableVaccinationRecord: currentNetwork?.verifyVaccinationSettings.enableVaccinationRecord,
  enableVaccinationAttestation: currentNetwork?.verifyVaccinationSettings.enableVaccinationAttestation,
  enableVaccinationScanCredentials: currentNetwork?.verifyVaccinationSettings.enableVaccinationScanCredentials,
  enableVaccinationImportCredentials: currentNetwork?.verifyVaccinationSettings.enableVaccinationImportCredentials,

  enableVaccinationViaPatientPortal: currentNetwork?.verifyVaccinationSettings.enableVaccinationViaPatientPortal,
  enableVaccinationConfirmWithProvider: currentNetwork?.verifyVaccinationSettings.enableVaccinationConfirmWithProvider,
  enableVaccinationAppleHealthKit: currentNetwork?.verifyVaccinationSettings.enableVaccinationAppleHealthKit,
  // enableVaccinationShare: null

  verifyVaccinationAttestationTitle: currentNetwork?.verifyVaccinationSettings?.vaccinationAttestationTitle
    ? currentNetwork.verifyVaccinationSettings?.vaccinationAttestationTitle
    : 'Attestation of Vaccination',
  verifyVaccinationAttestationSubTitle: currentNetwork?.verifyVaccinationSettings?.vaccinationAttestationSubTitle
    ? currentNetwork.verifyVaccinationSettings?.vaccinationAttestationSubTitle
    : 'Photo of card or exemption',
  verifyVaccinationScanCredentialsTitle: currentNetwork?.verifyVaccinationSettings?.vaccinationScanCredentialsTitle
    ? currentNetwork.verifyVaccinationSettings?.vaccinationScanCredentialsTitle
    : 'Scan a Credential',
  verifyVaccinationScanCredentialsSubTitle: currentNetwork?.verifyVaccinationSettings
    ?.vaccinationScanCredentialsSubTitle
    ? currentNetwork.verifyVaccinationSettings?.vaccinationScanCredentialsSubTitle
    : 'Issued from vaccination site',
  verifyVaccinationImportCredentialsTitle: currentNetwork?.verifyVaccinationSettings?.vaccinationImportCredentialsTitle
    ? currentNetwork.verifyVaccinationSettings?.vaccinationImportCredentialsTitle
    : 'Import a Credential',
  verifyVaccinationImportCredentialsSubTitle: currentNetwork?.verifyVaccinationSettings
    ?.vaccinationImportCredentialsSubTitle
    ? currentNetwork.verifyVaccinationSettings?.vaccinationImportCredentialsSubTitle
    : 'Stored on your device',

  verifyVaccinationViaPatienPortalTitle: currentNetwork?.verifyVaccinationSettings?.vaccinationViaPatientPortalTitle
    ? currentNetwork.verifyVaccinationSettings?.vaccinationViaPatientPortalTitle
    : 'Verify via Patient Portal',
  verifyVaccinationViaPatienPortalSubtitle: currentNetwork?.verifyVaccinationSettings
    ?.vaccinationViaPatientPortalSubTitle
    ? currentNetwork.verifyVaccinationSettings?.vaccinationViaPatientPortalSubTitle
    : 'Using account credentials',

  verifyVaccinationConfirmWithProviderTitle: currentNetwork?.verifyVaccinationSettings
    ?.vaccinationConfirmWithProviderTitle
    ? currentNetwork?.verifyVaccinationSettings?.vaccinationConfirmWithProviderTitle
    : 'Confirm with Provider',

  verifyVaccinationConfirmWithProviderSubtitle: currentNetwork?.verifyVaccinationSettings
    ?.vaccinationConfirmWithProviderSubTitle
    ? currentNetwork?.verifyVaccinationSettings?.vaccinationConfirmWithProviderSubTitle
    : 'Physicians, Clinic, or Pharmacy',

  verifyVaccinationAppleHealthKitTitleTenant: currentNetwork?.verifyVaccinationSettings?.vaccinationAppleHealthKitTitle
    ? currentNetwork.verifyVaccinationSettings?.vaccinationAppleHealthKitTitle
    : 'Apple Healthkit',
  verifyVaccinationAppleHealthKitSubtitleTenant: currentNetwork?.verifyVaccinationSettings
    ?.vaccinationAppleHealthKitSubTitle
    ? currentNetwork.verifyVaccinationSettings?.vaccinationAppleHealthKitSubTitle
    : 'Import from Apple Health',

  // safePass settings
  enableSafePass: currentNetwork.enableSafePass,

  // scheduled appointment
  enableScheduleAppointment: currentNetwork?.enableScheduleAppointment,
  enableScheduleAppointmentControl: currentNetwork?.enableScheduleAppointmentControl,

  // Password expiration
  useCustomPasswordExpirationValue: currentNetwork?.useCustomPasswordExpirationValue,
  passwordExpiration: currentNetwork?.passwordExpiration,

  // MULTI FACTOR AUTHENTICATION
  allowTenantMfaConfiguration: currentNetwork?.mfaSettings?.allowTenantMfaConfiguration,
  enableDeviceRemembering: currentNetwork?.mfaSettings?.enableDeviceRemembering,
  enableSMSVerification: currentNetwork?.mfaSettings?.enableSMSVerification,
  enableGoogleAuth: currentNetwork?.mfaSettings?.enableGoogleAuth,
  enableOneTimePassword: currentNetwork?.mfaSettings?.enableOneTimePassword,
  healthAdministratorDays: currentNetwork?.mfaSettings?.healthAdministratorDays,
  tenantAdministratorDays: currentNetwork?.mfaSettings?.tenantAdministratorDays,
  healthCheckerDays: currentNetwork?.mfaSettings?.healthCheckerDays,
  memberDays: currentNetwork?.mfaSettings?.memberDays,

  // ECOMMERCE SETTINGS
  enableWelcomeKit:
    currentNetwork?.ecommerceSettings?.enableWelcomeKit ?? false,
  telehealthAsync:
    currentNetwork?.ecommerceSettings?.shopifyCollections?.telehealthAsync ??
    "",
  telehealthSync:
    currentNetwork?.ecommerceSettings?.shopifyCollections?.telehealthSync ?? "",
  testKits:
    currentNetwork?.ecommerceSettings?.shopifyCollections?.testKits ?? "",
  welcomeKits:
    currentNetwork?.ecommerceSettings?.shopifyCollections?.welcomeKits ?? "",
  /* Save settings on form state for Ecommerce object which are not yet implemented on form */
  ecommerceSettings: currentNetwork?.ecommerceSettings,

  // EMPLOYEE BENEFITS
  employerBenefit: currentNetwork?.benefitSettings?.employerBenefit ?? false,
  exceedBenefit: currentNetwork?.benefitSettings?.exceedBenefit ?? false,

  /* Employer Benefit Card Settings */
  employerBenefitCardBgColor:
    currentNetwork?.benefitSettings?.employerBenefitCardSettings?.backgroundColor ?? '#ffffff',
  employerBenefitCardDescription:
    currentNetwork?.benefitSettings?.employerBenefitCardSettings?.description ?? '',
  employerBenefitCardTitle:
    currentNetwork?.benefitSettings?.employerBenefitCardSettings?.title ?? '',
  /* Save settings on form state for Benefit Settings which are not yet implemented on form */
  benefitSettings: currentNetwork?.benefitSettings,

  // DASHBOARD CARD CONFIGURATION
  enableProfileSectionDashboard: currentNetwork.enableProfileSectionDashboard,
  dashboardCardConfiguration: currentNetwork.dashboardCardConfiguration,

  // TENANT ACCESS SETTINGS
  enableAccessCodes: currentNetwork?.enableAccessCodes,

  // ACCOUNT VERIFICATION SETTINGS
  enableAccountVerificationScreen: currentNetwork?.enableAccountVerificationScreen,
  verificationField01: currentNetwork?.accountVerificationScreenCriteria?.verificationField01,
  verificationField02: currentNetwork?.accountVerificationScreenCriteria?.verificationField02,
  verificationField03: currentNetwork?.accountVerificationScreenCriteria?.verificationField03,

  // FOOTER NAVIGATION
  menuItem01: "HOME",
  menuItem02: currentNetwork?.footerConfiguration?.menuItem02 || footerNavigationMapping.CARE_PLANS.value,
  menuItem03: currentNetwork?.footerConfiguration?.menuItem03 || footerNavigationMapping.ORDER.value,
  menuItem04: currentNetwork?.footerConfiguration?.menuItem04 || footerNavigationMapping.SETTINGS.value,

  // MOBILE_MENU_ITEMS
  enableTakeATest: currentNetwork?.menuItems?.enableTakeATest,

});
