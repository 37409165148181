import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import React from 'react';

import Loader from '../../../Loader';

function ListFeedback({ loading }) {
  const useStyles = makeStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      padding: 24,
      textAlign: 'center',
    },
  });
  const classes = useStyles();
  const theme = useTheme();

  if (loading) {
    return (
      <div className={classes.root}>
        <Loader size={60} color={theme.palette.primary.main} />
        <Typography variant="caption">Loading...</Typography>
      </div>
    );
  }
  return (
    <div className={classes.root}>
      <ErrorOutline color="primary" />
      <Typography variant="caption" style={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
        No data was found
      </Typography>
    </div>
  );
}

export default ListFeedback;
