import { SvgIcon } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { DropDownButton, DropDownButtonItem } from '@progress/kendo-react-buttons';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Parent from '../../assets/img/parent.svg';

// kendo
import ProfileViewStyles from '../../assets/jss/components/ProfileViewStyles';
import { getUserFromToken } from '../Auth/helpers';
import { selectTenantNetwork } from '../Networks/selectors';
import PhoneInputField from '../Shared/Forms/KendoFields/PhoneInputField';
import { complianceMapping } from '../Users/helpers/mappings';
import { selectGeneratingNhi, selectUserAccountPermission, selectUserDetails } from '../Users/selectors';
import { generateUserNhi, updateUser } from '../Users/usersSlice';
import { dropdownActionMap } from './helpers/profileListMapper';
import ProfilePicture from './ProfilePicture';
import QRCode from './QRCode';

function DesktopCard(props) {
  const { classes, handleItemClick, emailIcon } = props;

  const tenantSetting = useSelector(selectTenantNetwork);
  const userDetails = useSelector(selectUserDetails);
  const userAccountPermission = useSelector(selectUserAccountPermission);
  const generatingNhi = useSelector(selectGeneratingNhi);

  const [nhiTimeMet, setNhiTimeMet] = useState(false);
  const [metadata, setMetadata] = useState({});
  const [state, setState] = useState({});
  const user = getUserFromToken();

  const dispatch = useDispatch();

  useEffect(() => {
    if (userDetails && userDetails.created) {
      const newDateObj = moment(userDetails.created).add(15, 'm').toDate();
      const currentDate = moment().toDate();
      if (newDateObj <= currentDate) {
        setNhiTimeMet(true);
      } else {
        setNhiTimeMet(false);
      }
    }
  }, [userDetails]);

  // on update of userDetails, set states of userDetials and metadata
  useEffect(() => {
    if (userDetails) {
      setMetadata(userDetails.metadata);
      setState(userDetails);
    }
  }, [userDetails]);

  // on update of metadata state, call update user api with new metadata
  useEffect(() => {
    if (state && state.id && JSON.stringify(metadata) !== JSON.stringify(state.metadata)) {
      const updatedState = {
        ...state,
        metadata,
      };
      handleUpdateUser(updatedState);
    }
  }, [state, metadata]);

  const handleUpdateUser = (state) => {
    dispatch(updateUser(state, true));
  };

  let countryCode = '';
  let number = '';
  if (userDetails && userDetails.contact) {
    countryCode = userDetails.contact.mobileCountryCode || '+1';
    number = userDetails.contact.mobileNumber || '';
  }

  const dropdownItem = (props) => (
    <div
      style={{
        display: 'flex',
        gap: 10,
        alignItems: 'center',
        width: 'max-content',
      }}
    >
      <span className={`k-icon k-i-${props.item.icon}`} role="presentation" />
      {props.item.text}
    </div>
  );

  const getAccountStatus = () => {
    if (userDetails?.settings?.identityVerificationStatus) {
      if (userDetails.settings.identityVerificationStatus.includes('NOT')) return 'Not Verified';
      return 'Verified';
    }
    return 'Not Verified';
  };

  // get dropdown actions by role
  const dropdownActions = dropdownActionMap(userDetails, userAccountPermission).filter((i) => i.show);

  return (
    <Grid container className={classes.desktopBox} spacing={2} justifyContent="space-between" alignItems="center">
      <Grid item xs={12} style={{ padding: 32 }}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item xs={10} />
          <Grid item xs={12}>
            <Grid container justifyContent="space-between">
              <Box display="flex">
                <Grid item className={classes.side}>
                  <CardContent style={{ padding: '15px 0 0', marginRight: 15 }}>
                    <ProfilePicture />
                  </CardContent>
                </Grid>
                <Grid
                  item
                  className={classes.details}
                  style={{
                    minWidth: '380px',
                  }}
                >
                  <Box display="flex" gridGap="45px">
                    <Box display="flex" flexDirection="column" gridGap="13px">
                      <FieldItem
                        label="Member Name"
                        value={`${userDetails?.firstName} ${userDetails?.lastName}`}
                        endAdornment={
                          tenantSetting?.requireGuardianMode &&
                          userDetails?.isGuardianMode && (
                            <Tooltip title="Guardian">
                              <SvgIcon style={{ marginLeft: 10 }} fontSize={'small'}>
                                <Parent />
                              </SvgIcon>
                            </Tooltip>
                          )
                        }
                      />
                      <FieldItem
                        label="Mobile Phone"
                        value={!number ? 'Unknown' : null}
                        endAdornment={
                          number ? (
                            <PhoneInputField
                              type="display"
                              classes={classes}
                              countryCode={countryCode || '+1'}
                              number={number}
                            />
                          ) : null
                        }
                      />
                      <FieldItem
                        label="Email"
                        value={userDetails?.email}
                        endAdornment={<Box className={classes.emailInfo}>{emailIcon}</Box>}
                      />
                    </Box>

                    <Box display="flex" flexDirection="column" gridGap="13px">
                      <FieldItem
                        label="Health ID"
                        value={userDetails?.nationalHealthId || null}
                        endAdornment={
                          !userDetails?.nationalHealthId ? (
                            <div>
                              {nhiTimeMet ? (
                                <div className={classes.nhiBtnWrapper}>
                                  <Button
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    disabled={generatingNhi}
                                    onClick={() => {
                                      dispatch(generateUserNhi(userDetails.email));
                                    }}
                                  >
                                    {generatingNhi ? 'Generating...' : 'Generate NHI'}
                                  </Button>
                                  {generatingNhi && <CircularProgress size={18} className={classes.nhiBtnProgress} />}
                                </div>
                              ) : (
                                <Typography variant="caption" color="primary">
                                  Processing NHI...
                                </Typography>
                              )}
                            </div>
                          ) : null
                        }
                      />
                      <FieldItem label="Account Status" value={getAccountStatus()} />
                      <FieldItem
                        label="Compliance Status"
                        value={
                          userDetails?.complianceStatus ? complianceMapping[userDetails.complianceStatus].label : null
                        }
                        color={complianceMapping[userDetails.complianceStatus]?.color}
                      />
                    </Box>
                  </Box>
                  {userDetails && userDetails.parentIds && userDetails.parentIds.length ? (
                    <Grid container style={{ marginTop: '5px' }}>
                      <Grid item style={{ minWidth: '115px' }}>
                        <Typography
                          style={{
                            fontWeight: 'bold',
                            fontSize: '15px',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          Account Type
                        </Typography>
                      </Grid>
                      <Grid item style={{ marginLeft: '10px' }}>
                        <Typography style={{ fontWeight: 'lighter', fontSize: '15px' }}> Dependent</Typography>
                      </Grid>
                    </Grid>
                  ) : null}
                </Grid>
              </Box>
              <Grid
                item
                style={{
                  marginRight: '-5px',
                }}
              >
                <Grid item>
                  {user?.email !== userDetails?.email && dropdownActions.length ? (
                    <Grid
                      item
                      className={classes.settingsCog}
                      style={{
                        float: 'right',
                      }}
                    >
                      <DropDownButton
                        look="bare"
                        onItemClick={(e) => handleItemClick(e)}
                        icon="more-horizontal"
                        itemRender={dropdownItem}
                        popupSettings={{
                          anchorAlign: {
                            horizontal: 'right',
                            vertical: 'bottom',
                          },
                          popupAlign: {
                            horizontal: 'right',
                            vertical: 'top',
                          },
                        }}
                      >
                        {dropdownActions.map((item) => (
                          <DropDownButtonItem text={item.text} icon={item.icon} />
                        ))}
                      </DropDownButton>
                    </Grid>
                  ) : (
                    <Grid item md={1} style={{ paddingTop: 10, paddingBottom: 10 }} key="blank-" />
                  )}
                </Grid>
                <QRCode />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

function FieldItem(props) {
  const { label, value, endAdornment, color } = props;
  const fontColor = 'rgba(80, 83, 88, 1)';

  const renderEndAdornment = () => {
    if (!endAdornment) return null;

    return endAdornment;
  };
  return (
    <Box display="flex" flex={1} flexDirection="column">
      <Typography
        style={{
          fontWeight: 600,
          color: fontColor,
          width: 'max-content',
          fontSize: 14,
        }}
      >
        {label}
      </Typography>
      <Box display="flex">
        <Typography style={{ color: color || fontColor, fontWeight: 400, fontSize: 14 }}>{value}</Typography>
        {renderEndAdornment()}
      </Box>
    </Box>
  );
}

export default withStyles(ProfileViewStyles)(DesktopCard);
