import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
// import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles, useTheme, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ProfileViewStyles from '../../assets/jss/components/ProfileViewStyles';
// import { selectLanguageContent } from '../LanguageMenu/selectors';
import { ProfileDropdownSkeletonLoadingMobile, ProfileInfoSkeletonLoading } from '../Profile/ProfileSkeletonLoading';
import utils from '../Shared/Utils/utils';
import { getRapidTest, getRapidTestImage } from './rapidTestSlice';
import { selectRapidTest, selectRapidTestLoading, selectRapidImage, selectRapidImageLoading } from './selectors';

function RapidTestDetail(props) {
  const { classes, userDetails, orderDetails, path } = props;
  // const languageContent = useSelector(selectLanguageContent);
  const rapidTest = useSelector(selectRapidTest);
  const rapidTestLoading = useSelector(selectRapidTestLoading);
  const rapidImage = useSelector(selectRapidImage);
  const rapidImageLoading = useSelector(selectRapidImageLoading);

  const [state, setState] = useState({});
  const [scanState, setScanState] = useState({});
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  const dispatch = useDispatch();

  useEffect(() => {
    if (orderDetails && orderDetails._id && !state.test) {
      dispatch(getRapidTest(orderDetails._id));
    }

    if (orderDetails && orderDetails._id && orderDetails._id != rapidImage?.data.test._id) {
      dispatch(getRapidTestImage(orderDetails._id));
    }
  }, [orderDetails]);

  useEffect(() => {
    if (rapidTest && rapidTest.data) {
      setState(rapidTest.data);
    }

    if (rapidImage && rapidImage.data) {
      setScanState(rapidImage.data);
    }
  }, [rapidTest, rapidImage]);

  const getTestDetailColor = (testDetail) => {
    switch (testDetail) {
      case 'POSITIVE':
        return '#FF0000';
      case 'NEGATIVE':
        return '#009104';
      case 'INVALID':
        return '#ffa500';
      case 'UNKNOWN':
        return '#505358';
      default:
        return '#505358';
    }
  };

  const getResults = (orderDetails, metadata) => {
    const isPanel =
      orderDetails.details && orderDetails.details.orderablePanel && orderDetails.details.orderablePanel.panelId;
    if (isPanel) {
      return orderDetails.testResultsSummary?.map((r, index) => {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <React.Fragment key={index}>
            <span>{r?.orderableTestName}: &nbsp;</span>
            <span style={{ color: getTestDetailColor(r?.patientTestResult) }}>{r?.patientTestResult}</span>
            <br />
          </React.Fragment>
        );
      });
    }
    return metadata?.outcome || '---';
  };

  // Map test tags to ensure values end up in correct fields as the order and even the number of elements in the tags array can change
  const deviceTags = () => {
    let make = '---';
    let model = '---';
    let software = '---';
    let platform = '---';
    let appVersion = '---';
    let device = '---';

    if (state?.test?.tags) {
      // eslint-disable-next-line array-callback-return
      state.test.tags.map((tag) => {
        const tagValues = tag.split('_');
        const type = tagValues[0];
        let value = '';
        for (let i = 1; i < tagValues.length; i++) {
          value = `${value + tagValues[i]} `;
        }

        // eslint-disable-next-line default-case
        switch (type) {
          case 'OS':
            platform = value;
            break;
          case 'VER':
            appVersion = value;
            break;
          case 'DEVICE':
            device = value;
            break;
        }
      });
    }

    if (scanState && scanState.captureInfo) {
      make = scanState.captureInfo['Exif IFD0 - Make'];
      model = scanState.captureInfo['Exif IFD0 - Model'];
      software = scanState.captureInfo['Exif IFD0 - Software'];
    }

    return (
      <>
        <InfoItemComponent label="Make">{make}</InfoItemComponent>

        <InfoItemComponent label="Model">{model}</InfoItemComponent>

        <InfoItemComponent label="Software">{software}</InfoItemComponent>

        <InfoItemComponent label="Platform">{platform}</InfoItemComponent>

        <InfoItemComponent label="App Version">{appVersion}</InfoItemComponent>

        <InfoItemComponent label="Device ID">{device}</InfoItemComponent>
      </>
    );
  };

  const createPatientDetails = () => (
    <>
      <InfoItemComponent label="Gender">{userDetails.gender || '---'}</InfoItemComponent>

      <InfoItemComponent label="Date Of Birth">
        {userDetails.birthDate ? utils.formatDate(userDetails.birthDate) : '---'}
      </InfoItemComponent>

      <InfoItemComponent label="Race">{utils.removeUnderscores(userDetails.race) || '---'}</InfoItemComponent>

      <InfoItemComponent label="Ethnicity">{utils.removeUnderscores(userDetails.ethnicity) || '---'}</InfoItemComponent>

      <InfoItemComponent label="Address 1">
        {(userDetails.primaryAddress && userDetails.primaryAddress.line1) || '---'}
      </InfoItemComponent>

      <InfoItemComponent label="Address 2">
        {(userDetails.primaryAddress && userDetails.primaryAddress.line2) || '---'}
      </InfoItemComponent>

      <InfoItemComponent label="City">
        {(userDetails.primaryAddress && userDetails.primaryAddress.city) || '---'}
      </InfoItemComponent>

      <InfoItemComponent label="State">
        {(userDetails.primaryAddress && userDetails.primaryAddress.state) || '---'}
      </InfoItemComponent>

      <InfoItemComponent label="Zip">
        {(userDetails.primaryAddress && userDetails.primaryAddress.zip) || '---'}
      </InfoItemComponent>
    </>
  );
  const createRapidTestDetail = () => {
    if (orderDetails && state && state.test) {
      const { metadata, status } = state.test;
      const isPanel =
        orderDetails.details && orderDetails.details.orderablePanel && orderDetails.details.orderablePanel.panelId;
      return (
        <>
          <InfoItemComponent width={6} label="Test Name">
            {GetOrderItems()}
          </InfoItemComponent>

          <InfoItemComponent width={6} label="Test Type">
            {state.test?.metadata ? state.test.metadata.type : '---' || '---'}
          </InfoItemComponent>

          <InfoItemComponent width={6} label="Date">
            {state.test ? utils.formatTestImageDate(state.test?.collectionDate) : '---' || '---'}
          </InfoItemComponent>

          <InfoItemComponent width={6} label="Status">
            {status || '---'}
          </InfoItemComponent>

          <InfoItemComponent
            label="Auto Assessment"
            field="testResult"
            width={6}
            customStyle={
              isPanel
                ? {}
                : {
                    color: metadata?.outcome ? getTestDetailColor(metadata.outcome) : '#000000',
                    fontWeight: 'bold',
                  }
            }
          >
            {getResults(orderDetails, metadata)}
          </InfoItemComponent>

          <InfoItemComponent
            label="Self Assessment"
            field="testResult"
            width={6}
            customStyle={{
              color: metadata?.selfAssessmentOutcome
                ? getTestDetailColor(metadata.selfAssessmentOutcome.toUpperCase())
                : '#000000',
              fontWeight: 'bold',
            }}
          >
            {(metadata?.selfAssessmentOutcome &&
              (metadata.selfAssessmentOutcome !== 'Equivocal'
                ? metadata.selfAssessmentOutcome.toUpperCase()
                : 'NOT SURE')) ||
              '---'}
          </InfoItemComponent>

          <InfoItemComponent width={6} label="Manufacturer">
            {state.test.metadata ? state.test.metadata.manufacturer : '---' || '---'}
          </InfoItemComponent>

          <InfoItemComponent width={6} label="SKU">
            {/* {state.test ? state.test.orderId?.split('_')[0] : '---' || '---'} */}
            {orderDetails?.details?.orderablePanel?.skuId || state.labTestOrderable.skuId || '---'}
          </InfoItemComponent>

          <InfoItemComponent width={6} label="Provider">
            {GetOrderingProvider()}
          </InfoItemComponent>

          <InfoItemComponent width={6} label="Encounter ID">
            {orderDetails.encounterId || '---'}
          </InfoItemComponent>
        </>
      );
    }
    if (!rapidTestLoading) {
      return (
        <Grid item xs={12}>
          <Box>
            <Typography
              variant="h4"
              style={{
                fontSize: '12px',
                fontWeight: 'bold',
                paddingBottom: '5px',
              }}
            >
              No Rapid Test Detail Found.
            </Typography>
          </Box>
        </Grid>
      );
    }
  };
  /**
   const createLabDetails = () => {
    if (orderDetails && state && state.test) {
      return (
        <>
          <InfoItemComponent label="Lab Vendor">
            {orderDetails.details?.labVendor?.name ? orderDetails.details.labVendor.name : '---' || '---'}
          </InfoItemComponent>

          <InfoItemComponent label="Order Items">{GetOrderItems()}</InfoItemComponent>

          <InfoItemComponent label="Lab Order Status">{orderDetails.statusDisplayText || '---'}</InfoItemComponent>

          <InfoItemComponent label="Type">
            {state.test?.metadata ? state.test.metadata.type : '---' || '---'}
          </InfoItemComponent>

          <InfoItemComponent label="SKU">
            {state.test ? state.test.orderId?.split('_')[0] : '---' || '---'}
          </InfoItemComponent>

          <InfoItemComponent label="Laboratory / Manufacturer">
            {state.test.metadata ? state.test.metadata.manufacturer : '---' || '---'}
          </InfoItemComponent>

          {state.isSelfAssessment ? (
            <InfoItemComponent label="Self Assessment Result">
              {state.test?.metadata?.selfAssessmentOutcome && state.test.metadata.selfAssessmentOutcome !== 'Equivocal'
                ? state.test.metadata.selfAssessmentOutcome.toUpperCase()
                : 'NOT SURE'}
            </InfoItemComponent>
          ) : null}
        </>
      );
    }
    if (!rapidTestLoading) {
      return (
        <Grid item xs={12}>
          <Box>
            <Typography
              variant="h4"
              style={{
                fontSize: '12px',
                fontWeight: 'bold',
                paddingBottom: '5px',
              }}
            >
              No Rapid Test Detail Found.
            </Typography>
          </Box>
        </Grid>
      );
    }
  };
   */

  const GetOrderItems = () => {
    let analyteItems = '---';
    if (orderDetails.details && orderDetails.details.orderablePanel && orderDetails.details.orderablePanel.panelId) {
      analyteItems = orderDetails?.details?.orderablePanel?.shortName || '--';
    } else if (orderDetails.details && orderDetails.details.orderableTest) {
      analyteItems = orderDetails.details.orderableTest.customName;
    }
    return analyteItems;
  };

  const GetOrderingProvider = () => {
    let providerName = '---';
    if (orderDetails.details && orderDetails.details.orderingProvider) {
      // eslint-disable-next-line
      providerName = `${orderDetails.details.orderingProvider.firstName} ${orderDetails.details.orderingProvider.middleName} ${orderDetails.details.orderingProvider.lastName}`;
    }
    return providerName;
  };

  if (!rapidTest || rapidTestLoading || !rapidImage || rapidImageLoading) {
    return (
      <div>
        {matches ? (
          <div>
            <Paper style={{ borderRadius: '20px', marginBottom: 30 }} elevation={0}>
              <ProfileInfoSkeletonLoading />
            </Paper>
          </div>
        ) : (
          <div>
            <Paper style={{ borderRadius: '20px', marginBottom: 30 }} elevation={0}>
              <ProfileDropdownSkeletonLoadingMobile />
            </Paper>
          </div>
        )}
      </div>
    );
  }

  return (
    <>
      {path === 0 && (
        <Card className={classes.basicContainer} style={{ width: '100%' }}>
          <CardContent>
            <Grid className={classes.infoBoxesUpdated}>
              <Grid container>
                <Typography variant="h4">Patient Details</Typography>
                <Grid container style={{ padding: '0 16px' }}>
                  {createPatientDetails()}
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}

      {path === 1 && (
        <Grid container direction="row" justifyContent="space-between">
          <Card className={classes.basicContainer} style={{ width: '69%' }}>
            <CardContent>
              <Grid className={classes.infoBoxesUpdated}>
                <Grid container>
                  <Typography variant="h4">Test Details</Typography>
                  <Grid container style={{ padding: '0 16px' }}>
                    {createRapidTestDetail()}
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          <Card className={classes.basicContainer} style={{ width: '29%' }}>
            <CardContent>
              <Grid className={classes.infoBoxesUpdated}>
                <Typography variant="h4">Test Image</Typography>
                {scanState.scanImageData ? (
                  <img
                    src={`data:image/png;base64,${scanState.scanImageData}`}
                    width="100%"
                    style={{ borderRadius: 20, marginTop: 24 }}
                    alt={'Test Image'}
                  />
                ) : (
                  <Typography variant="body1">No Scan Image Available</Typography>
                )}
              </Grid>
            </CardContent>
          </Card>

          <Card className={classes.basicContainer} style={{ width: '100%', marginTop: '30px' }}>
            <CardContent>
              <Grid className={classes.infoBoxesUpdated}>
                <Grid container>
                  <Typography variant="h4">Device Details</Typography>
                  <Grid container style={{ padding: '0 16px' }}>
                    {deviceTags()}
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      )}
    </>
  );
}

function InfoItemComponent(props) {
  const { label, field, children: content, width, customStyle } = props;
  const classes = useStyles();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Grid item xs={width || (matches ? 4 : 12)}>
      <Box>
        <Typography variant="h5" className={classes.label}>
          {label}
        </Typography>
        <div>
          <Typography variant="body1" style={field === 'testResult' ? customStyle : {}}>
            {' '}
            {content}
          </Typography>
        </div>
      </Box>
    </Grid>
  );
}

const useStyles = makeStyles(() => ({
  label: {
    fontSize: '12px',
    fontWeight: 'bold',
    paddingBottom: '2px',
  },
}));

export default withStyles(ProfileViewStyles)(RapidTestDetail);
