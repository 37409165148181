import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { MultiSelect } from '@progress/kendo-react-dropdowns';
import { Checkbox } from '@progress/kendo-react-inputs';
import React, { useState, useEffect, useCallback } from 'react';

// ui reflection of file view
const CheckboxInput = React.memo((props) => {
  const { field, initialValue, handleChangeWithParams, disabled } = props;
  const [selected, setSelected] = useState(initialValue || []);

  useEffect(() => {
    if (initialValue !== selected) {
      setSelected(initialValue || []);
    }
    // eslint-disable-next-line
  }, [initialValue]);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  const handleToggle = useCallback(
    (value, e) => {
      const selectedItems = selected.slice();
      if (e.value && !selectedItems.includes(value)) {
        // Add value to list
        selectedItems.push(value);
      } else if (!e.value && selectedItems.includes(value)) {
        // Remove value from list
        selectedItems.splice(selectedItems.indexOf(value), 1);
      }
      setSelected(selectedItems);
      handleChangeWithParams(field.name, selectedItems);
    },
    [selected, handleChangeWithParams, setSelected, field]
  );

  const handleMultiSelectChange = (e) => {
    const selectedItems = e.target.value.map((v) => (field.valueField ? v[field.valueField] : v));
    setSelected(selectedItems);
    handleChangeWithParams(field.name, selectedItems);
  };

  return (
    <Grid container justifyContent="center" spacing={2}>
      {matches ? (
        <>
          <Grid item xs={12}>
            <Typography align="center">{field.label}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item container direction={field.direction} style={{ height: field.height }}>
            {field.enum.map((item) => (
              <Grid item>
                <Checkbox
                  disabled={disabled}
                  label={field.labelField ? item[field.labelField] : item.label}
                  value={field.valueField ? item[field.valueField] : item.value}
                  checked={selected.includes(item.value)}
                  onChange={(e) => handleToggle(item.value, e)}
                />
              </Grid>
            ))}
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={12}>
            <Typography style={{ opacity: 0.5 }}>{field.label}</Typography>
          </Grid>
          <Grid item xs={12}>
            <MultiSelect
              disabled={disabled}
              data={field.enum}
              textField={field.labelField}
              value={field.enum.filter((v) =>
                field.valueField ? selected.includes(v[field.valueField]) : selected.includes(v)
              )}
              onChange={handleMultiSelectChange}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
});

export default CheckboxInput;
