import { createSlice } from '@reduxjs/toolkit';

import api from '../../api';
import { handleError } from '../Shared/Content/APIUtils';

const initialState = {
  rapidTest: null,
  rapidTestLoading: null,
  rapidImage: null,
  rapidImageLoading: null,
  rapidMembrane: null,
  rapidMembraneLoading: null,
  rapidKit: null,
  rapidKitLoading: null,
};

export const rapidTestSlice = createSlice({
  name: 'rapidTest',
  initialState,
  reducers: {
    setRapidTestLoading: (state) => ({
      ...state,
      rapidTestLoading: true,
    }),
    setRapidTest: (state, { payload }) => ({
      ...state,
      rapidTest: payload,
      rapidTestLoading: false,
    }),
    setRapidImageLoading: (state) => ({
      ...state,
      rapidImageLoading: true,
    }),
    setRapidImage: (state, { payload }) => ({
      ...state,
      rapidImage: payload,
      rapidImageLoading: false,
    }),
    setRapidMembraneLoading: (state) => ({
      ...state,
      rapidMembraneLoading: true,
    }),
    setRapidMembrane: (state, { payload }) => ({
      ...state,
      rapidMembrane: payload,
      rapidMembraneLoading: false,
    }),
    setRapidKitLoading: (state) => ({
      ...state,
      rapidKitLoading: true,
    }),
    setRapidKit: (state, { payload }) => ({
      ...state,
      rapidKit: payload,
      rapidKitLoading: false,
    }),
  },
});

export const {
  setRapidTest,
  setRapidTestLoading,
  setRapidImage,
  setRapidImageLoading,
  setRapidMembrane,
  setRapidMembraneLoading,
  setRapidKit,
  setRapidKitLoading,
} = rapidTestSlice.actions;

export const getRapidTest = (encounterId) => async (dispatch) => {
  dispatch(setRapidTestLoading());

  const [result, error] = await api.getRapidTestRequest(encounterId);

  if (error && error.message) {
    dispatch(handleError(error));
  }

  if (result) {
    dispatch(setRapidTest(result));
  }
};

export const getRapidTestImage = (imageId) => async (dispatch) => {
  dispatch(setRapidImageLoading());

  const [result, error] = await api.getRapidTestImageRequest(imageId);

  if (error && error.message) {
    dispatch(handleError(error));
  }

  if (result) {
    dispatch(setRapidImage(result));
  }
};

export const getRapidTestImageMembrane = (membraneId) => async (dispatch) => {
  dispatch(setRapidMembraneLoading());

  const [result, error] = await api.getRapidTestImageMembraneRequest(membraneId);

  if (error && error.message) {
    dispatch(handleError(error));
  }

  if (result) {
    dispatch(setRapidMembrane(result));
  }
};

export const getRapidTestImageKit = (kitId) => async (dispatch) => {
  dispatch(setRapidKitLoading());

  const [result, error] = await api.getRapidTestImageKitRequest(kitId);

  if (error && error.message) {
    dispatch(handleError(error));
  }

  if (result) {
    dispatch(setRapidKit(result));
  }
};

export default rapidTestSlice.reducer;
