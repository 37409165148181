/* eslint-disable react/no-array-index-key */
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { Dialog } from '@progress/kendo-react-dialogs';
import React from 'react';

import { procedureDialogFields } from './helpers/clinicalInfoMapper';
import { createProcedureDataObj } from './helpers/createClinicalObjBuilder';

function ProcedureDialog(props) {
  const { closeDialog, selectedProcedure } = props;
  const classes = useStyles();

  const procedureData = createProcedureDataObj(selectedProcedure);
  const fields = procedureDialogFields();

  const handleCancel = () => {
    closeDialog();
  };

  return (
    <Dialog title="Procedure" onClose={handleCancel} width={600}>
      <Grid container>
        {fields.map((item, key) => (
          <Grid item xs="4" key={key}>
            <Box>
              <Typography variant="h4" className={classes.label}>
                {item.label}
              </Typography>
              <div>
                <Typography variant="body1" className={classes.value}>
                  {' '}
                  {procedureData[item.id]}
                </Typography>
              </div>
            </Box>
          </Grid>
        ))}
      </Grid>
      {/* <DialogActionsBar>   //Commented the ADD or EDIT action as it is not implemented for procedure
        <button className="k-button" onClick={handleCancel}>
          Cancel
        </button>
        <button className="k-button">
          {dialogMode === "Add" ? "Add" : "Edit"}
        </button>
      </DialogActionsBar> */}
    </Dialog>
  );
}

const useStyles = makeStyles(() => ({
  label: {
    fontSize: '12px',
    paddingBottom: '5px',
  },
  value: {
    paddingBottom: '5px',
  },
}));

export default ProcedureDialog;
