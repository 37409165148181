import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import React from 'react';
import { useDispatch } from 'react-redux';
import { showConfirmation } from '../../Confirmation/confirmationSlice';

function FontActionCell(props) {
  const { dataItem } = props;
  const { currentTenant, id: fontId } = dataItem;
  const dispatch = useDispatch();

  const handleDelete = () => {
    dispatch(
      showConfirmation('Confirm', 'Are you sure you want to delete this font?', 'fontDelete', currentTenant, fontId)
    );
  };

  return (
    <td>
      <IconButton onClick={handleDelete} data-testid="expansionPanelDeleteBtn">
        <DeleteIcon />
      </IconButton>
    </td>
  );
}

export default FontActionCell;
