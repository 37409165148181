/* eslint-disable import/no-cycle */
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import React from 'react';

import { createInputHelper } from '../helpers/createInputHelper';

// ui reflection of file view
function RemovableGroupedItems(props) {
  const {
    index,
    fields,
    label,
    parentFieldName,
    values,
    handleChange,
    handleChangeWithParams,
    handleRemove,
    autoSetValues,
    disabled,
    disabledBtn,
    hideAction,
    getChildren,
  } = props;

  return (
    <Card>
      {(label || (!hideAction && !disabled)) && (
        <CardHeader
          title={
            <Typography variant="body1" color="primary">
              {`${label} ${index + 1}`}
            </Typography>
          }
          action={
            disabled || hideAction ? null : (
              <Tooltip title="Remove">
                <IconButton onClick={() => handleRemove && handleRemove(index)} disabled={disabledBtn}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )
          }
        />
      )}
      <CardContent>
        <Grid container spacing={2}>
          {fields.map((f) =>
            createInputHelper(
              [
                {
                  ...f,
                  checkField: f.checkField ? `${parentFieldName}.${index}.${f.checkField}` : undefined,
                  searchTypeField: f.searchTypeField ? `${parentFieldName}.${index}.${f.searchTypeField}` : undefined,
                  labelFieldProperty: f.labelFieldProperty
                    ? `${parentFieldName}.${index}.${f.labelFieldProperty}`
                    : undefined,
                },
              ],
              values,
              handleChange,
              handleChangeWithParams,
              autoSetValues,
              disabled || f.sensitive,
              getChildren
            )
          )}
        </Grid>
      </CardContent>
    </Card>
  );
}

export default RemovableGroupedItems;
