import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles, useTheme } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import ProfileViewStyles from '../../assets/jss/components/ProfileViewStyles';
import { getUserFromToken } from '../Auth/helpers';
import { getOrderDetails } from '../Orders/ordersSlice';
import { selectOrderDetails, selectOrderDetailsLoading } from '../Orders/selectors';
import ProfileCard from '../Profile/ProfileCard';
import {
  ProfileCardSkeletonLoading,
  ProfileCardSkeletonLoadingMobile,
  ProfileTabsSkeletonLoading,
  ProfileDropdownSkeletonLoadingMobile,
} from '../Profile/ProfileSkeletonLoading';
import { selectCurrentTenant } from '../Shared/selectors';
import {
  selectProfilePicture,
  selectUserDetails,
  selectUserDetailsLoading,
  selectUserPermissionsLoading,
} from '../Users/selectors';
import { getUser, getUserPermissions } from '../Users/usersSlice';

import DiagnosticReportInfo from './DiagnosticReportInfo';
import { getDiagnosticReport, setDiagnosticReport } from './diagnosticReportSlice';
import PublicHealthReportInfo from './PublicHealthReportInfo';
import {
  selectDiagnosticReportInfo,
  selectDiagnosticReportInfoLoading,
  selectPublicHealthReportInfo,
} from './selectors';

function DiagnosticReport(props) {
  const { classes, type } = props;

  const userDetails = useSelector(selectUserDetails);
  const userDetailsLoading = useSelector(selectUserDetailsLoading);
  const profilePicture = useSelector(selectProfilePicture);
  const userPermissionsLoading = useSelector(selectUserPermissionsLoading);
  const getOrderDetailsLoading = useSelector(selectOrderDetailsLoading);
  const orderDetails = useSelector(selectOrderDetails);
  const diagnosticReportInfo = useSelector(selectDiagnosticReportInfo);
  const diagnosticReportInfoLoading = useSelector(selectDiagnosticReportInfoLoading);
  const publicHealthReportInfo = useSelector(selectPublicHealthReportInfo);
  const currentTenant = useSelector(selectCurrentTenant);

  const { encounterId, nationalHealthId, userId } = useParams();

  const [value, setValue] = useState(0);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const user = getUserFromToken();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  let pageComponent;

  useEffect(() => {
    dispatch(getUser(userId));
    dispatch(getUserPermissions(currentTenant.id, userId));
    dispatch(getOrderDetails(encounterId));
  }, [currentTenant.id, type]);

  useEffect(() => {
    if (!orderDetails) return;
    if (!orderDetails?.details?.orderableTest?.isRtl) {
      dispatch(getDiagnosticReport(nationalHealthId, encounterId, currentTenant.id, userId));
    } else {
      const rtlResult = {
        data: {
          orderId: orderDetails.id,
          comments: null,
          encodedPdfObservation: null,
          observations: orderDetails?.testResults?.map((t) => {
            return {
              orderableTestId: t.orderableTestId,
              testName: t.orderableTestName,
              collectionDate: t.collectionDate,
              resultDate: null,
              interpretationDate: null,
              interpretationValue: t.interpretationValue,
              abnormalFlag: null,
              resultReferenceRange: t?.resultReferenceRange,
              units: null,
              performingLabDetail: null,
              resultStatus: t.resultStatus,
              resultValue: t.resultValue,
              resultUnit: t?.resultUnit,
              analyteObservations: null,
              comments: null,
            };
          }) || [],
        },
      };
      dispatch(setDiagnosticReport(rtlResult));
    }
  }, [orderDetails]);

  const handleChange = (e, value) => {
    setValue(value);
    let path;
    if (value === 0) {
      path = 'Report';
    } else if (value === 1) {
      path = 'Phr';
    }
    return path;
  };
  // eslint-disable-next-line default-case
  switch (value) {
    case 0:
      pageComponent = (
        <DiagnosticReportInfo
          user={user}
          diagnosticReportInfo={diagnosticReportInfo}
          currentTenant={currentTenant}
          userDetails={userDetails}
          orderDetails={orderDetails}
        />
      );
      break;
    case 1:
      pageComponent = (
        <PublicHealthReportInfo
          user={user}
          publicHealthReportInfo={publicHealthReportInfo}
          currentTenant={currentTenant}
          userDetails={userDetails}
          encounterId={encounterId}
        />
      );
      break;
  }

  if (!currentTenant) return null;

  if (diagnosticReportInfo === 'error') {
    const { tenant, userId } = params;
    navigate(`/${tenant}/users/details/${userId}/testing`);
    return <div>...loading</div>;
  }

  if (
    userDetailsLoading ||
    !userDetails ||
    !profilePicture ||
    userPermissionsLoading ||
    diagnosticReportInfoLoading ||
    !diagnosticReportInfo ||
    getOrderDetailsLoading
  ) {
    return (
      <div>
        {matches ? (
          <div>
            <ProfileCardSkeletonLoading />
            <Paper style={{ borderRadius: '20px', marginBottom: 30 }} elevation={0}>
              <ProfileTabsSkeletonLoading />
            </Paper>
          </div>
        ) : (
          <div>
            <Card style={{ borderRadius: '20px', marginBottom: 20 }} elevation={0}>
              <ProfileCardSkeletonLoadingMobile />
            </Card>
            <Paper style={{ borderRadius: '20px', marginBottom: 30 }} elevation={0}>
              <ProfileDropdownSkeletonLoadingMobile />
            </Paper>
          </div>
        )}
      </div>
    );
  }
  // profile page
  return (
    diagnosticReportInfo !== 'error' && (
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <ProfileCard />
        </Grid>
        <Grid item xs={12} className={classes.bottomBox}>
          <Card style={{ borderRadius: '20px' }} elevation={0}>
            <Paper style={{ borderRadius: '20px 20px 0px 0px' }}>
              <Tabs
                centered
                value={value}
                indicatorColor="primary"
                variant="fullWidth"
                textColor="primary"
                onChange={handleChange}
                aria-label="disabled tabs example"
              >
                <Tab
                  label="Diagnostics Report"
                  style={{ fontSize: '12px', marginBottom: '-10px' }}
                  className={value === '' || value === 1 ? classes.activeLink : ''}
                />
                <Tab
                  item
                  xs={4}
                  label="Public Health Record"
                  style={{ fontSize: '12px', marginBottom: '-10px' }}
                  className={value === 2 ? classes.activeLink : ''}
                />
              </Tabs>
            </Paper>
            <div className={classes.basicContainer} style={{ width: '100%' }}>
              {pageComponent}
            </div>
          </Card>
        </Grid>
      </Grid>
    )
  );
}

export default withStyles(ProfileViewStyles)(DiagnosticReport);
