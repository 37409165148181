import { createSlice } from '@reduxjs/toolkit';

import api from '../../api';
import { handleError } from '../Shared/Content/APIUtils';

import * as dashboardUtils from './helpers/dashboardHelpers';

const initialState = {
  loading: false,
  error: null,
  metadataFiltersList: null,
  data: {
    ageGroups: null,
    testsTaken: null,
    ethnicity: null,
    genderGroups: null,
    selfAssessments: null,
    statistics: null,
  },
};

export const dashboardSlice = createSlice({
  name: 'dashboardOld',
  initialState,
  reducers: {
    setUpdateDashboard: (state, { payload }) => ({
      ...state,
      data: {
        ...state.data,
        ...payload,
      },
    }),
    getDashboardDataFailure: (state, { payload }) => ({
      ...state,
      error: payload,
    }),
    setMetadataFiltersList: (state, { payload }) => ({
      ...state,
      metadataFiltersList: payload,
    }),
  },
});

export const { setUpdateDashboard, getDashboardDataFailure, setMetadataFiltersList } = dashboardSlice.actions;

export const getDashboardData = (tenantId, start, end) => async (dispatch) => {
  // Server requires seconds from epoch starts format
  const startParam = Math.round(start.getTime() / 1000);
  const endParam = Math.round(end.getTime() / 1000);
  const params = {
    start: startParam,
    end: endParam,
  };

  // Age Groups Distribution
  const [ageGroupsRes, ageGroupsErr] = await api.getDashboardDataRequest(tenantId, params, 'age-group-distribution');
  if (ageGroupsErr && ageGroupsErr.message) {
    return dispatch(handleError(ageGroupsErr));
  }
  if (ageGroupsRes && ageGroupsRes.data) {
    const data = dashboardUtils.processBarChartData(ageGroupsRes.data);
    dispatch(setUpdateDashboard({ ageGroups: data }));
  }

  // Tests taken
  const [testsTakenRes, testsTakenErr] = await api.getDashboardDataRequest(tenantId, params, 'tests-taken');
  if (testsTakenErr && testsTakenErr.message) {
    return dispatch(handleError(testsTakenErr));
  }
  if (testsTakenRes) {
    const data = dashboardUtils.processMultiLineChart(testsTakenRes);
    dispatch(setUpdateDashboard({ testsTaken: data }));
  }

  // Ethnicity
  const [ethnicityRes, ethnicityErr] = await api.getDashboardDataRequest(tenantId, params, 'ethnicity-distribution');
  if (ethnicityErr && ethnicityErr.message) {
    return dispatch(handleError(ethnicityErr));
  }
  if (ethnicityRes && ethnicityRes.data) {
    const data = dashboardUtils.processEthnicity(ethnicityRes);
    dispatch(setUpdateDashboard({ ethnicity: data }));
  }

  // Gender Groups
  const [genderGroupsRes, genderGroupsErr] = await api.getDashboardDataRequest(tenantId, params, 'gender-distribution');
  if (genderGroupsErr && genderGroupsErr.message) {
    return dispatch(handleError(genderGroupsErr));
  }
  if (genderGroupsRes) {
    const data = dashboardUtils.processGenderGroups(genderGroupsRes);
    dispatch(setUpdateDashboard({ genderGroups: data }));
  }

  // Self Assessment
  const [selfAssessmentsRes, selfAssessmentsErr] = await api.getDashboardDataRequest(
    tenantId,
    params,
    'self-assessments-completed'
  );
  if (selfAssessmentsErr && selfAssessmentsErr.message) {
    return dispatch(handleError(selfAssessmentsErr));
  }
  if (selfAssessmentsRes) {
    const data = dashboardUtils.processMultiLineChart(selfAssessmentsRes);
    dispatch(setUpdateDashboard({ selfAssessments: data }));
  }

  const totalUsersStat = api.getDashboardDataRequest(tenantId, params, 'population');

  const testStatusStat = api.getDashboardDataRequest(tenantId, params, 'patient-test-status', 'dashboard');

  const selfAssessmentsStat = api.getDashboardDataRequest(tenantId, params, 'self-assessment-statistic');

  Promise.all([totalUsersStat, testStatusStat, selfAssessmentsStat])
    .then((responses) => {
      /* eslint-disable-next-line */
      responses.forEach(([result, error]) => {
        if (error) throw error;
      });
      const data = {
        statistics: dashboardUtils.combineStatistics({
          totalUsers: responses[0][0],
          testsCompleted: {
            total: responses[1][0]?.total,
            average: responses[1][0]?.average,
          },
          positiveTests: responses[1][0]?.tests?.POSITIVE,
          negativeTests: responses[1][0]?.tests?.NEGATIVE,
          selfAssessmentsCompleted: {
            total: responses[2][0]?.total,
            average: responses[2][0]?.average,
          },
          selfAssessmentsPositive: responses[2][0]?.positive,
          selfAssessmentsNegative: responses[2][0]?.negative,
          selfAssessmentsUnknown: responses[2][0]?.unknown,
        }),
      };

      dispatch(setUpdateDashboard({ statistics: data.statistics }));
    })
    .catch((e) => {
      const error = { ...e, message: e?.message || 'Dashboard data request error' };
      dispatch(getDashboardDataFailure(error));
    });
};

export default dashboardSlice.reducer;
