import { createSlice } from '@reduxjs/toolkit';
import FileDownload from 'js-file-download';

import api from '../../api';
import { getUserFromToken } from '../Auth/helpers';
import { showNotification } from '../Notifications/notificationSlice';
import { getTenantSettings } from '../Settings/settingsSlice';
import { handleError } from '../Shared/Content/APIUtils';
import { setFormSubmitting } from '../Shared/Forms/formSlice';
import utils from '../Shared/Utils/utils';
import { getUserSegments } from '../VirtualConsult/virtualConsultSlice';
import { initialKendoState, statusMappings, UserRoleId } from './helpers/mappings';

const initialState = {
  showBulkUploadJobDialog: false,
  allUploadsListLoading: true,
  otherUserPictureLoading: false,
  allUploadsList: [],
  prevFilterState: {},
  selectAllUsers: false,
  selectUserIds: [],
  isSelectAllTriggered: false,
  userDeleteAccountLoading: false,
};

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    resetUsers: (state) => ({
      ...state,
      tenantUsers: {},
    }),

    setUserIdentityVerificationHistoryLoading: (state) => ({
      ...state,
      userIdVerHisLoading: true,
    }),

    setUserIdentityVerificationHistory: (state, { payload }) => ({
      ...state,
      userIdVerHisLoading: false,
      userIdVerHisData: payload,
    }),

    setUserQ: (state, { payload }) => ({
      ...state,
      userQuestionnaire: payload,
    }),

    setUserQLoading: (state, { payload }) => ({
      ...state,
      userQuestionnaireLoading: payload,
    }),

    setUserUploadInfo: (state, { payload, userId }) => {
      const existingImports = (state.bulk && state.bulk.imports && state.bulk.imports.slice()) || [];
      const existingImportIds = existingImports.map((i) => i.id);
      const updatedImports =
        (payload &&
          payload.items &&
          payload.items.filter(
            (i) => existingImportIds.includes(i.id) || (i.uploadedBy === userId && i.status === 'IN_PROGRESS')
          )) ||
        [];

      return {
        ...state,
        bulk: {
          ...state.bulk,
          imports: updatedImports,
        },
      };
    },

    setBulkUploadInfo: (state, { payload }) => {
      const imports = (state.bulk && state.bulk.imports && state.bulk.imports.slice()) || [];
      const importIndex = imports.findIndex((i) => i.id === payload.id);

      if (importIndex > -1) {
        imports.splice(importIndex, 1);
      }
      imports.push(payload);

      return {
        ...state,
        bulk: {
          ...state.bulk,
          imports,
        },
      };
    },

    setBulkUploadUsers: (state, { payload }) => {
      const newImports = (state.bulk && state.bulk.imports && state.bulk.imports.slice()) || [];
      newImports.push(payload);
      return {
        ...state,
        bulk: {
          ...state.bulk,
          imports: newImports,
          submitting: false,
          error: null,
        },
      };
    },
    setBulkUploadJobDialog: (state, { payload }) => ({
      ...state,
      showBulkUploadJobDialog: payload,
    }),
    setBulkUploadUsersFailure: (state, { payload }) => ({
      ...state,
      bulk: {
        ...state.bulk,
        submitting: false,
        error: payload,
      },
    }),

    setBulkUploadUsersPreview: (state, { payload }) => ({
      ...state,
      previewImport: payload,
    }),
    setBulkUploadUsersLoading: (state) => ({
      ...state,
      bulk: {
        ...state.bulk,
        submitting: true,
        error: null,
      },
    }),
    setUserMessagesLoading: (state) => ({
      ...state,
      userMessagesLoading: true,
    }),
    setUserMessages: (state, { payload }) => ({
      ...state,
      userMessages: payload,
      userMessagesLoading: false,
    }),
    setUserPicLoading: (state, { payload }) => ({
      ...state,
      userPictureLoading: payload,
    }),
    setUserPic: (state, { payload }) => ({
      ...state,
      profilePicture: payload,
      userPictureLoading: false,
    }),
    setUploadedUserPic: (state, { payload }) => ({
      ...state,
      profilePicture: payload,
    }),
    setOtherUserPicLoading: (state, { payload }) => ({
      ...state,
      otherUserPictureLoading: payload,
    }),
    setOtherUserPic: (state, { payload }) => ({
      ...state,
      otherUser: payload.userId,
      otherUserProfilePic: payload.img,
      otherUserPictureLoading: false,
    }),
    setOtherUserQR: (state, { payload }) => ({
      ...state,
      otherUser: payload.userId,
      otherUserQR: payload.img,
      userQRLoading: false,
    }),
    setUserAccountPermissionLoading: (state, { payload }) => ({
      ...state,
      userAccountPermissionLoading: payload,
    }),
    setUserAccountPermission: (state, { payload }) => ({
      ...state,
      userAccountPermission: payload,
      userAccountPermissionLoading: false,
    }),
    setUserPermissionsLoading: (state, { payload }) => ({
      ...state,
      userPermissionsLoading: payload,
    }),
    setUserPermissions: (state, { payload }) => ({
      ...state,
      userPermissions: payload,
      userPermissionsLoading: false,
    }),
    setCurrentUserPermissionsLoading: (state, { payload }) => ({
      ...state,
      currentUserPermissionsLoading: payload,
    }),
    setCurrentUserPermissions: (state, { payload }) => ({
      ...state,
      currentUserPermissions: payload,
      currentUserPermissionsLoading: false,
    }),
    setUserQRLoading: (state) => ({
      ...state,
      userQRLoading: true,
    }),
    setGeneratingUserNhi: (state) => ({
      ...state,
      generatingNhi: true,
    }),
    setUserPaginationState: (state, { payload }) => ({
      ...state,
      userPagination: payload,
    }),
    setCurrentUserLoading: (state, { payload }) => ({
      ...state,
      currentUserLoading: payload,
    }),
    setCurrentUser: (state, { payload }) => ({
      ...state,
      currentUser: payload,
      currentUserLoading: false,
    }),
    setUserDetailsLoading: (state) => ({
      ...state,
      userDetailsLoading: true,
    }),
    setUser: (state, { payload }) => ({
      ...state,
      userDetails: payload,
      userDetailsLoading: false,
      generatingNhi: false,
    }),
    setTenantUsers: (state, { payload }) => ({
      ...state,
      tenantUsers: payload,
      tenantUsersLoading: false,
    }),
    setTenantInvitedUsers: (state, { payload }) => ({
      ...state,
      tenantInvitedUsers: payload,
      tenantInvitedUsersLoading: false,
    }),
    setTenantUsersLoading: (state) => ({
      ...state,
      tenantUsersLoading: true,
    }),
    setTenantInvitedUsersLoading: (state) => ({
      ...state,
      tenantInvitedUsersLoading: true,
    }),
    resetUploadUsersPreview: (state) => ({
      ...state,
      previewImport: null,
    }),
    setGuardianInfoLoading: (state) => ({
      ...state,
      guardianInfoLoading: true,
    }),
    setGuardianInfo: (state, { payload }) => ({
      ...state,
      guardianInfoLoading: false,
      guardianInfo: payload,
    }),
    setGuardianInfoUpdating: (state) => ({
      ...state,
      guardianInfoUpdating: true,
    }),
    setGuardianInfoCreating: (state) => ({
      ...state,
      guardianInfoCreating: true,
    }),
    setUserVaxStatusLoading: (state) => ({
      ...state,
      userVaxStatusLoading: true,
    }),
    setUserVaxStatusInfo: (state, { payload }) => ({
      ...state,
      userVaxStatusLoading: false,
      userVaxStatusInfo: payload,
    }),
    setUserVaxStatusUpdating: (state) => ({
      ...state,
      userVaxStatusUpdating: true,
    }),
    setUserVaxStatusCreating: (state) => ({
      ...state,
      userVaxStatusCreating: true,
    }),
    setAllUploadsListLoading: (state, { payload }) => ({
      ...state,
      allUploadsListLoading: payload,
    }),
    setAllUploadsList: (state, { payload }) => ({
      ...state,
      allUploadsListLoading: false,
      allUploadsList: payload,
    }),
    setPrevFilterState: (state, { payload }) => ({
      ...state,
      prevFilterState: payload,
    }),
    setTenantUserTotalsLoading: (state, { payload }) => ({
      ...state,
      tenantUserTotalsLoading: payload,
    }),
    setTenantUserTotals: (state, { payload }) => ({
      ...state,
      tenantUserTotals: payload,
    }),
    setSelectAllUsers: (state, { payload }) => ({ ...state, selectAllUsers: payload }),
    setSelectUserIds: (state, { payload }) => ({ ...state, selectUserIds: payload }),
    setIsSelectAllTriggered: (state, { payload }) => ({ ...state, isSelectAllTriggered: payload }),
    setUserDeleteAccountLoading: (state, { payload }) => ({
      ...state,
      userDeleteAccountLoading: payload,
    }),
    resetUserDetails: (state) => ({ ...state, userDetails: null }),
  },
});

export const {
  setAllUploadsListLoading,
  setAllUploadsList,
  resetUploadUsersPreview,
  setTenantUsers,
  setTenantUsersLoading,
  setTenantInvitedUsers,
  setTenantInvitedUsersLoading,
  setUserPaginationState,
  setCurrentUserLoading,
  setCurrentUser,
  setUser,
  setUserDetailsLoading,
  setGeneratingUserNhi,
  setUserQRLoading,
  setOtherUserQR,
  setUserAccountPermission,
  setUserAccountPermissionLoading,
  setCurrentUserPermissions,
  setCurrentUserPermissionsLoading,
  setUserPermissions,
  setUserPermissionsLoading,
  setUserPicLoading,
  setUserPic,
  setOtherUserPicLoading,
  setOtherUserPic,
  setUploadedUserPic,
  setUserMessagesLoading,
  setUserMessages,
  setBulkUploadUsersFailure,
  setBulkUploadJobDialog,
  setBulkUploadUsersLoading,
  setBulkUploadInfo,
  setUserUploadInfo,
  setUserQ,
  setUserIdentityVerificationHistory,
  setUserIdentityVerificationHistoryLoading,
  resetUsers,
  setGuardianInfoLoading,
  setGuardianInfo,
  setGuardianInfoUpdating,
  setGuardianInfoCreating,
  setUserVaxStatusLoading,
  setUserVaxStatusInfo,
  setUserVaxStatusUpdating,
  setUserVaxStatusCreating,
  setUserQLoading,
  setPrevFilterState,
  resetUserDetails,
  setSelectAllUsers,
  setSelectUserIds,
  setIsSelectAllTriggered,
  setTenantUserTotalsLoading,
  setTenantUserTotals,
  setUserDeleteAccountLoading,
} = usersSlice.actions;

export const getUserTotals = () => async (dispatch) => {
  dispatch(setTenantUserTotalsLoading(true));

  const [result, error] = await api.getUserTotalsRequest();

  dispatch(setTenantUserTotalsLoading(false));

  if (error && error.message) {
    return dispatch(handleError(error));
  }

  if (result?.success && result?.data) {
    dispatch(setTenantUserTotals(result.data));
  }
};

export const getUsers =
  (
    tenant,
    page,
    pageSize,
    query,
    sort,
    direction,
    permission,
    gender,
    userSegment,
    status,
    vaccineRecordStatus,
    lastTestResult,
    dateRangeFilter
  ) =>
  async (dispatch) => {
    const params = {
      pageNumber: page,
      pageSize,
      searchParam: query,
      sort,
      direction,
      permission,
      gender,
      userSegment,
      status,
      vaccineRecordStatus,
      lastTestResult,
      testResultFrom: dateRangeFilter?.testResultFrom ?? '',
      testResultTo: dateRangeFilter?.testResultTo ?? '',
    };

    dispatch(setTenantUsersLoading());
    const [result, error] = await api.getUsersRequest(params);

    // [SAF-2546] - API call cancelled due to new filter applied on the User table
    if (error && error.status === 499) {
      return;
    }

    if (error && error.message) {
      return dispatch(handleError(error));
    }

    if (result && result.data) {
      dispatch(setTenantUsers(result.data));
    }
  };

export const getInvitedUsers = (tenant, page, pageSize, query, sort, direction) => async (dispatch) => {
  const params = {
    pageNumber: page,
    pageSize,
    searchParam: query,
    sort,
    direction,
  };

  dispatch(setTenantInvitedUsersLoading());
  const [result, error] = await api.getInvitedUsersRequest(params);

  if (error && error.message) {
    return dispatch(handleError(error));
  }

  if (result?.isOK) {
    dispatch(setTenantInvitedUsers(result));
  }
};

export const getMetadataValues = (tenant, metadataKey) => async (dispatch) => {
  const [result, error] = await api.getMetadataValuesRequest(tenant, metadataKey);

  if (error && error.message) {
    dispatch(handleError(error));
    return null;
  }

  if (result) {
    let values = result;
    if (metadataKey === 'User Segment') {
      const sorted =
        (result.length &&
          result.sort((a, b) => {
            const segmentA = a.toUpperCase();
            const segmentB = b.toUpperCase();
            if (segmentA < segmentB) return -1;
            if (segmentA > segmentB) return 1;
            return 0;
          })) ||
        [];
      if (sorted.includes('Base User Segment')) {
        values = sorted;
      } else {
        values = [...sorted, 'Base User Segment'];
      }
    }
    return values;
  }
};

export const getUserChildren =
  (tenant, userId, page = 0, pageSize = 20, query, sort, direction, permission, gender, userSegment) =>
  async (dispatch) => {
    dispatch(setTenantUsersLoading());
    const params = {
      skip: page * pageSize,
      limit: pageSize,
      q: query,
      sort,
      direction,
      permission,
      gender,
      userSegment,
    };
    const [result, error] = await api.getUserChildrenRequest(tenant, userId, params);

    if (error && error.message) {
      return dispatch(handleError(error));
    }

    if (result) {
      dispatch(setTenantUsers(result));
    }
  };

export const getCurrentUser = () => async (dispatch) => {
  dispatch(setCurrentUserLoading(true));

  const [result, error] = await api.getCurrentUserRequest();

  if (error && error.message) {
    dispatch(setCurrentUserLoading(false));
    return dispatch(handleError(error));
  }

  if (result && result.data) {
    dispatch(setCurrentUser(result.data));
  }
};

export const getUser =
  (userId, withComplianceStatus, withLoading = true) =>
  async (dispatch, getState) => {
    if (withLoading) dispatch(setUserDetailsLoading());
    const [result, error] = await api.getUserRequest(userId);
    if (error && error.message) {
      dispatch(handleError(error));
    }

    if (result && result.data) {
      let userData = result.data;
      if (withComplianceStatus) {
        const { tenantID } = getState().networks.tenantNetwork;
        const complianceRes = await dispatch(getUserCompliance(tenantID, userData._id));
        if (complianceRes) {
          userData = { ...userData, complianceStatus: complianceRes };
        }
      }

      dispatch(setUser(userData));
      return userData;
    }

    return null;
  };

export const getUserCompliance = (tenantId, healthId) => async () => {
  const [result] = await api.getUserComplianceStatusRequest(tenantId, healthId);

  if (result && result.isOK) {
    return result.data.status;
  }

  return null;
};

export const generateUserNhi = (userId) => async (dispatch) => {
  dispatch(setGeneratingUserNhi());

  const [result, error] = await api.generateUserNhiRequest(userId);

  if (error && error.message) {
    return dispatch(handleError(error));
  }

  if (result) {
    dispatch(setUser(result));
    dispatch(showNotification('Successfully Generated National Health ID', 'success'));
  }
};

export const getUserQRCode = (userId) => async (dispatch) => {
  dispatch(setUserQRLoading());

  const params = { id: userId, width: 130 };
  const [result, error] = await api.getUserQRCodeRequest(params);

  if (error && error.message) {
    return dispatch(handleError(error));
  }

  if (result) {
    const url = URL.createObjectURL(result);
    const img = new Image();
    img.src = url;
    img.alt = 'user qr code';

    dispatch(setOtherUserQR({ img, userId }));
  }
};

export const resetUserPassword = (data) => async (dispatch) => {
  const [result, error] = await api.sendresetPassEmail(data);

  if (error && error.message) {
    return dispatch(handleError(error));
  }

  if (result) {
    dispatch(showNotification('Reset Password Email Sent Successfully', 'success'));
  }
};

export const getUserAccountPermission = () => async (dispatch) => {
  dispatch(setUserAccountPermissionLoading(true));
  const [result, error] = await api.getUserAccountPermissionRequest();

  if (error && error.message) {
    dispatch(setUserAccountPermissionLoading(false));
    return dispatch(handleError(error));
  }

  if (result) {
    dispatch(setUserAccountPermission(result));
  }
};

export const deleteUserAccount = (data) => async (dispatch) => {
  // const [result, error] = await api.deleteUserAccountRequest(type === 'userDeleteAccount' ? data.id : data._id);

  dispatch(setUserDeleteAccountLoading(true));
  const [result, error] = await api.deleteUserAccountRequest(data._id);

  dispatch(setUserDeleteAccountLoading(false));
  if (error && error.message) {
    dispatch(setFormSubmitting(false));
    dispatch(handleError(error));
    return false;
  }

  if (result) {
    dispatch(showNotification('User successfully deleted from account.', 'success'));
    return true;
  }
};

export const userStatusChange = (tenant, userData, status, onSuccess) => async (dispatch) => {
  const [result, error] = await api.changeUserStatusRequest({
    _id: userData._id,
    status,
  });

  if (error && error.message) {
    dispatch(setFormSubmitting(false));
    dispatch(handleError(error));
  }

  if (result) {
    dispatch(
      showNotification(
        `User successfully ${status === statusMappings.ACTIVE.id ? 'activated' : 'deactivate'}`,
        'success'
      )
    );

    if (onSuccess) {
      onSuccess();
    }
  }

  return true;
};

export const inviteUser = (tenant, uId, role) => async (dispatch) => {
  const data = {
    email: uId,
    tenantId: tenant.id,
    permission: UserRoleId[role],
  };
  const [result, error] = await api.inviteUserRequest(data);

  if (error && error.message) {
    dispatch(setFormSubmitting(false));
    if (error.message === "This user doesn't exist.") {
      return true;
      // eslint-disable-next-line
    } else if (error.status === 422 && error.message === 'User already created.') {
      dispatch(handleError(null, 'An account with this email already exists'));
    }
    return false;
  }

  if (result) {
    dispatch(setFormSubmitting(false));
    // dispatch(showNotification('User invite successfully sent.', 'success'));
    return true;
  }
};

export const resendInvitation = (currentTenant, userData) => async (dispatch) => {
  const data = {
    accountId: currentTenant.accountId,
    tenantId: currentTenant.id,
    email: userData.email,
    firstName: userData.firstName,
    lastName: userData.lastName,
    reinviteReason: 0,
    accessCode: userData.pin,
    permission: userData.permission,
  };

  const [result, error] = await api.resendInvitationRequest(data);

  if (error && error.message) {
    dispatch(setFormSubmitting(false));
    if (error.message === "This user doesn't exist.") {
      return true;
    }
    dispatch(handleError(error));
    return false;
  }

  if (result) {
    dispatch(setFormSubmitting(false));
    dispatch(showNotification('User invite successfully sent.', 'success'));
    return false;
  }
};

export const deleteUserTenant = (data, tenant) => async (dispatch) => {
  const [result, error] = await api.deleteUserTenantRequest(tenant, data.email);

  if (error && error.message) {
    dispatch(setFormSubmitting(false));
    dispatch(handleError(error));
    return false;
  }

  if (result) {
    dispatch(showNotification('User successfully removed from tenant.', 'success'));
    return true;
  }
};

export const getUserPermissions = (tenant, userId, currentUser) => async (dispatch) => {
  if (currentUser) {
    dispatch(setCurrentUserPermissionsLoading(true));
  } else {
    dispatch(setUserPermissionsLoading(true));
  }

  const [result, error] = await api.getUserPermissionsRequest(tenant, userId);

  if (error && error.message) {
    if (currentUser) {
      dispatch(setCurrentUserPermissionsLoading(false));
    } else {
      dispatch(setUserPermissionsLoading(false));
    }
    dispatch(handleError(error));
  }

  if (result) {
    if (currentUser) {
      dispatch(setCurrentUserPermissions(result));
    } else {
      dispatch(setUserPermissions(result));
    }
  }
};

export const updateUserPermissions = (tenant, permission, uId) => async (dispatch) => {
  const params = {
    uId,
  };
  const [result, error] = await api.updateUserPermissionsRequest(tenant, permission, params);

  if (error && error.message) {
    dispatch(setFormSubmitting(false));
    dispatch(handleError(error));
  }

  if (result) {
    dispatch(setUserPermissions(result));
    dispatch(showNotification('Permission successfully updated.', 'success'));
  }
};

export const toggleAnalyticsField = (tenant, userId) => async (dispatch) => {
  const [result, error] = await api.toggleAnalyticsFieldRequest(tenant, userId);

  if (error && error.message) {
    dispatch(setFormSubmitting(false));
    dispatch(handleError(error));
  }

  if (result) {
    dispatch(setUserPermissions(result));
    dispatch(showNotification('User Successfully Updated', 'success'));
  }
};

export const getUserPic = (userId, currentUser, UUID) => async (dispatch) => {
  if (currentUser) {
    dispatch(setUserPicLoading(true));
  } else {
    dispatch(setOtherUserPicLoading(true));
  }

  const params = { username: userId };

  async function whichUserPic() {
    if (currentUser) {
      return api.getUserPicRequest(params);
    }
    return api.getOtherUserPicRequest(UUID);
  }

  const [result, error] = await whichUserPic();

  if (error && error.message) {
    if (currentUser) {
      dispatch(setUserPicLoading(false));
    } else {
      dispatch(setOtherUserPicLoading(false));
    }

    dispatch(handleError(error));
  }

  if (result) {
    if (currentUser) {
      // convert response to blob
      const img = utils.createImg(result);
      dispatch(setUserPic(img));
    } else {
      // convert response to blob
      let img = '';
      if (result.data) {
        img = {
          src: utils.convertBase64ToBlobUrl(result.data),
        };
      } else {
        // try to get default pic
        const [defaultPic, error] = await api.getUserPicRequest({ username: 'default-user-pic' });
        img = defaultPic && !error?.message ? utils.createImg(defaultPic) : '';
      }
      dispatch(
        setOtherUserPic({
          img,
          userId,
        })
      );
    }
  }
};

export const uploadUserPic = (file) => async (dispatch) => {
  const url = URL.createObjectURL(file);
  const img = new Image();
  img.src = url;
  img.alt = 'user profile avatar';

  const [result, error] = await api.uploadUserPicRequest(file);

  if (error && error.message) {
    dispatch(handleError(error));
  }

  if (result) {
    dispatch(setUploadedUserPic(img));
    dispatch(showNotification('Profile Picture Successfully Uploaded', 'success'));
  }
};

export const sendEmailInvitation = (tenant, params) => async (dispatch) => {
  const [result, error] = await api.sendEmailInvitationRequest(params);

  if (error && error.message) {
    dispatch(setFormSubmitting(false));

    if (error.status === 422 && error.message === 'PREVIOUSLY_VERIFIED_COMPLETE') {
      dispatch(handleError(null, 'An account with this email already exists'));
    } else {
      dispatch(handleError(error));
    }
  }

  if (result?.data && result?.isOK) {
    dispatch(showNotification('User Invitation Sent', 'success'));
    dispatch(
      getInvitedUsers(
        tenant,
        initialKendoState.skip,
        initialKendoState.take,
        initialKendoState.search,
        initialKendoState.sort[0]?.field,
        initialKendoState.sort[0]?.dir?.toUpperCase(),
        initialKendoState.filter
      )
    );
    dispatch(getUserTotals());
  }
  // else if (!isOK && result?.errorMessage) {
  //   dispatch(handleError(result));
  // }
};

export const addUser = (tenant, data) => async (dispatch) => {
  const [result, error] = await api.addUserRequest(data);

  if (error && error.message) {
    dispatch(setFormSubmitting(false));
    dispatch(handleError(error));
  }

  if (result?.isOK) {
    dispatch(showNotification('User Successfully Added', 'success'));
    dispatch(getUserTotals());
    dispatch(
      getUsers(
        tenant,
        initialKendoState.skip,
        initialKendoState.take,
        initialKendoState.search,
        initialKendoState.sort[0]?.field,
        initialKendoState.sort[0]?.dir?.toUpperCase(),
        initialKendoState.filter
      )
    );
  } else if (!result?.isOK && result?.errorMessage) {
    dispatch(handleError(result));
  }
};

export const updateUser =
  (data, withComplianceStatus = false) =>
  async (dispatch) => {
    const [result] = await api.updateUserRequest(data);

    if (result && result.errorMessage) {
      dispatch(setFormSubmitting(false));
      dispatch(handleError(result));
    }

    if (result && result.data) {
      dispatch(getUser(result.data.email, withComplianceStatus, false));
      dispatch(showNotification('User Successfully Updated', 'success'));
    }
  };

export const updateUserContact = (data) => async (dispatch) => {
  const [result, error] = await api.updateUserContactRequest(data);

  if (error?.message) {
    dispatch(handleError(error));
  }

  if (result?.isOK) {
    dispatch(showNotification('Changes saved!', 'success'));
    return false;
  }
};

export const updateUserStatus = (tenant, email, accountId) => async (dispatch) => {
  const [result] = await api.updateUserStatusRequest(tenant, email, accountId);

  if (result && result.errorMessage) {
    dispatch(handleError(result));
  }

  // if (result && result.data) {
  //   dispatch(setUser(result.data));
  //   dispatch(showNotification("User Successfully Updated", "success"));
  // }
};

export const getUserMessages = (page, pageSize, sort, direction, username, tenant) => async (dispatch) => {
  const params = {
    skip: page * pageSize,
    limit: pageSize,
    sort,
    direction,
    username,
  };
  dispatch(setUserMessagesLoading());
  const [result, error] = await api.getUserMessagesRequest(tenant, params);

  if (error && error.message) {
    dispatch(handleError(error));
  }

  if (result) {
    dispatch(setUserMessages(result));
  }
};

export const bulkImport = (tenant, data, userId, account) => async (dispatch) => {
  dispatch(setBulkUploadUsersLoading());

  const payloadData = {
    file: data.files[0],
    tenant,
    creatorEmail: userId,
    account,
  };
  const [result, error] = await api.bulkImportRequest(payloadData);

  if (error && error.message) {
    dispatch(setFormSubmitting(false));
    dispatch(handleError(error));
    dispatch(setBulkUploadUsersFailure(error.message || ''));
  }

  if (result && result.data) {
    dispatch(setFormSubmitting(false));
    dispatch(setBulkUploadJobDialog(true));
    // if (preview) {
    //   dispatch(setBulkUploadUsersPreview(result));
    // } else {
    //   dispatch(setBulkUploadUsers(result));
    // history.push(`/${tenant.id}/users/import/${result.data}`);
    // }
  }
};

export const getBulkInfo = (tenant, id) => async (dispatch) => {
  const [result, error] = await api.getBulkInfoRequest(tenant, id);

  if (error && error.message) {
    dispatch(handleError(error));
  }

  if (result) {
    dispatch(setBulkUploadInfo(result));
  }
};

export const getUserBulks = (tenant, email, skip, limit, sort, direction) => async (dispatch) => {
  const params = {
    skip: skip || 0,
    limit: limit || 5,
    sort,
    direction,
    light: true,
  };
  const [result, error] = await api.getUserBulksRequest(tenant, params);

  if (error && error.message) {
    dispatch(handleError(error));
  }

  if (result) {
    dispatch(setUserUploadInfo(result, email));
  }
};

export const getQuestionnaireExecution =
  (page, pageSize, sortColumn, direction, userId, tenant) => async (dispatch) => {
    const sort = `${sortColumn},${direction}`;
    const params = {
      page,
      skip: page * pageSize,
      size: pageSize,
      sort,
      userId,
    };
    dispatch(setUserQLoading(true));
    const [result, error] = await api.getQuestionnaireExecutionRequest(tenant, params);
    dispatch(setUserQLoading(false));

    if (error && error.message) {
      dispatch(handleError(error));
    }

    if (result) {
      dispatch(setUserQ(result));
    }
  };

export const getUserIdentityVerificationHistory = (userId, tenant) => async (dispatch) => {
  dispatch(setUserIdentityVerificationHistoryLoading());

  const [result, error] = await api.getUserIdentityVerificationHistoryRequest(tenant, userId);

  if (error && error.message) {
    dispatch(handleError(error));
  }

  if (result) {
    dispatch(setUserIdentityVerificationHistory(result));
  }
};

export const getGuardianInfo = (userId) => async (dispatch) => {
  dispatch(setGuardianInfoLoading());

  const [result, error] = await api.getGuardianInfoRequest(userId);

  if (error && error.message) {
    dispatch(handleError(error));
  }

  if (result) {
    dispatch(setGuardianInfo(result));
  }
};

export const createGuardianInfo = (data) => async (dispatch) => {
  dispatch(setGuardianInfoCreating());

  const [result, error] = await api.createGuardianInfoRequest(data);

  if (error && error.message) {
    dispatch(handleError(error));
  }

  if (result) {
    dispatch(setGuardianInfo(result));
    dispatch(showNotification('Guardian Info Successfully Created', 'success'));
  }
};

export const updateGuardianInfo = (data) => async (dispatch) => {
  dispatch(setGuardianInfoUpdating());

  const [result, error] = await api.updateGuardianInfoRequest(data);

  if (error && error.message) {
    dispatch(handleError(error));
  }

  if (result) {
    dispatch(setGuardianInfo(result));
    dispatch(showNotification('Guardian Info Successfully Updated', 'success'));
  }
};

export const getUserVaxStatusHistory = (userId) => async (dispatch) => {
  dispatch(setUserVaxStatusLoading());

  const [result, error] = await api.getUserVaxStatusHistoryRequest(userId);

  if (error && error.message) {
    dispatch(handleError(error));
  }

  if (result) {
    dispatch(setUserVaxStatusInfo(result));
  }
};

export const manageUserVaxStatus = (data, tenantId) => async (dispatch, getState) => {
  const userEmail = getState()?.users?.userDetails?.email;

  dispatch(setUserVaxStatusCreating());
  const [result, error] = await api.manageUserVaxStatusRequest(data, tenantId);

  if (error && error.message) {
    dispatch(handleError(error));
  }

  if (result) {
    if (data.id.length > 0) dispatch(showNotification('Vax Status Updated', 'success'));
    else dispatch(showNotification('Vax Status Created', 'success'));
    dispatch(getUserVaxStatusHistory(data.safeEMRId));
    dispatch(getUser(userEmail));
  }
};

export const getAllUploads = (pageNumber, pageSize, tenant) => async (dispatch) => {
  const accountId = localStorage.getItem('accountId');
  const params = {
    pageNumber,
    pageSize,
    accountId,
    tenantId: tenant,
  };

  const [result, error] = await api.getAllUploadsRequest(tenant, params);

  dispatch(setAllUploadsListLoading(false));

  if (error && error.message) {
    dispatch(handleError(error));
  }

  if (result?.data) {
    dispatch(setAllUploadsList(result?.data));
  }
};

export const downloadUploadingFile = (tenantId, uploadID, fileName) => async (dispatch) => {
  const accountId = localStorage.getItem('accountId');
  const [result, error] = await api.downloadUploadingFileRequest({
    accountId,
    tenantId,
    uploadID,
  });

  if (error && error.message) {
    dispatch(handleError(error, 'Unable to download file. Please try again later.'));
  }

  if (result) {
    FileDownload(result, fileName);
  }
};

export const phoneNumberLookup = (number, accountId) => async (dispatch) => {
  const params = { accountId };

  const [result, error] = await api.getPhoneLookupRequest(number, params);

  if (error && error.message) {
    dispatch(handleError(error));
  }

  if (result && result.data && result.data.isUnique && result.data.isValid) return true;

  const errorMessage = result.data.message === 'Phone number is not valid' ? 'Please enter a valid phone number' : result.data.message
  dispatch(showNotification(errorMessage, 'error'));
  return false;
};

export const getInitialData = (tenantId) => async (dispatch, getState) => {
  const user = getUserFromToken();
  const accountId = getState().account.details?.id;

  dispatch(getTenantSettings(tenantId));
  dispatch(getUserPermissions(tenantId, user && user.email, true));
  dispatch(getUserAccountPermission());
  dispatch(getUserSegments(tenantId));

  if (accountId) dispatch(updateUserStatus(tenantId, user.email, accountId));
};

export default usersSlice.reducer;
