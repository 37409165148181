import * as htmlUtils from '../../Shared/Grid/helpers';
// import { withCustomProp } from '../../Shared/Grid/helpers';
import OrdersCustomCell from '../ordersCustomTableCells/OrdersCustomCell';
import OrdersResultCell from '../ordersCustomTableCells/OrdersCustomResultCell';

export const initialKendoState = {
  sort: [{ field: 'lastUpdated', dir: 'desc' }],
  take: 10,
  skip: 0,
  search: '',
  filter: '',
};

export const columns = (userSpecific, testFormats, sources, vendorSettings) => {
  if (userSpecific) {
    return [
      {
        id: 'id',
        label: 'ID',
        show: false,
      },
      {
        id: 'created',
        label: 'Time of Test',
        show: true,
        minWidth: 140,
        locked: true,
      },
      {
        id: 'source',
        label: 'Test',
        show: true,
        minWidth: 170,
      },
      {
        id: 'status',
        label: 'Status',
        show: true,
        minWidth: 170,
      },
      {
        id: 'selfAssessment',
        label: 'Self Assessment',
        show: true,
        minWidth: 170,
        cell: htmlUtils.withCustomProp(OrdersResultCell, 'assessmentType', 'selfAssessment'),
      },
      {
        id: 'autoAssessment',
        label: 'Auto Assessment',
        show: true,
        minWidth: 170,
        cell: htmlUtils.withCustomProp(OrdersResultCell, 'assessmentType', 'autoAssessment'),
      },
      {
        id: 'testFormat',
        label: 'Test Type',
        show: true,
        minWidth: 170,
        // filter: true,
        // options: sources
      },
      {
        id: 'lastUpdated',
        label: 'Last Updated',
        show: !!userSpecific,
        minWidth: 170,
      },
      {
        id: 'action',
        label: 'Action',
        show: true,
        minWidth: 100,
        sortable: false,
        cell: htmlUtils.withCustomProp(OrdersCustomCell, 'vendorSettings', vendorSettings),
      },
    ];
  }
  return [
    {
      id: 'id',
      label: 'ID',
      show: false,
    },
    {
      id: 'nationalHealthId',
      label: 'Healthcare ID',
      show: true,
      minWidth: 170,
      locked: true,
    },
    {
      id: 'userId',
      label: 'Email',
      show: true,
      minWidth: 220,
    },
    {
      id: 'status',
      label: 'Status',
      show: true,
      minWidth: 170,
    },
    {
      id: 'result',
      label: 'Result',
      show: true,
      minWidth: 200,
      cell: htmlUtils.withCustomProp(OrdersResultCell, 'assessmentType', 'diagnosticsResult'),
    },
    {
      id: 'source',
      label: 'Source',
      show: true,
      minWidth: 170,
      filter: true,
      options: sources,
    },
    {
      id: 'testFormat',
      label: 'Test Format',
      show: true,
      minWidth: 170,
      filter: true,
      options: testFormats,
    },
    {
      id: 'orderId',
      label: 'Order ID',
      show: true,
      minWidth: 170,
    },
    {
      id: 'created',
      label: 'Created',
      show: true,
      minWidth: 140,
    },
    {
      id: 'lastUpdated',
      label: 'Last Updated',
      show: true,
      minWidth: 150,
    },
    // {
    //   id: "received",
    //   label: "Received",
    //   show: true,
    //   minWidth: 140,
    // },
    {
      id: 'action',
      label: 'Action',
      show: true,
      minWidth: 100,
      sortable: false,
      cell: htmlUtils.withCustomProp(OrdersCustomCell, 'vendorSettings', vendorSettings),
    },
  ];
};

export const tableButtons = (
  handleGetOrders,
  setCollectionDate,
  setCollectionTime,
  setResultDialogOpen,
  userSpecific,
  setDialogOpen,
  currentTenant,
  setOrderDialogOpen
) => [
  {
    title: 'Refresh Orders',
    buttonType: 'icon',
    variant: 'outlined',
    action: handleGetOrders,
    icon: 'Sync',
  },
  {
    title: 'New Result',
    buttonType: 'action',
    variant: 'outlined',
    action: () => {
      setCollectionDate(new Date());
      setCollectionTime(new Date());
      setResultDialogOpen(true);
    },
  },
  // {
  //   title: "New Order",
  //   // button types: link, action
  //   buttonType: "action",
  //   // button variants: contained, outlined
  //   variant: "contained",
  //   // action to perform when click action button
  //   action: () => setDialogOpen(true),
  // },
  {
    title: 'New Order',
    // button types: link, action
    buttonType: 'action',
    // button variants: contained, outlined
    variant: 'contained',
    // action to perform when click action button
    action: () => setOrderDialogOpen(true),
  },
];
