/* eslint-disable react/no-array-index-key */
// imports
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import React from 'react';

// import utils from '../Shared/Utils/utils';

import { vitalDialogFields } from './helpers/clinicalInfoMapper';
import { createVitalDataObj } from './helpers/createClinicalObjBuilder';

function VitalDialog(props) {
  const { closeDialog, selectedVital, dialogMode } = props;
  const classes = useStyles();

  const vitalData = createVitalDataObj(selectedVital);
  const fields = vitalDialogFields();

  const handleCancel = () => {
    closeDialog();
  };

  return (
    <Dialog title={`${dialogMode} Vital`} onClose={handleCancel} width={600}>
      <Grid container>
        {fields.map((item, key) => (
          <Grid item xs="4" key={key}>
            <Box>
              <Typography variant="h4" className={classes.label}>
                {item.label}
              </Typography>
              <div>
                <Typography variant="body1" className={classes.value}>
                  {' '}
                  {vitalData[item.id]}
                </Typography>
              </div>
            </Box>
          </Grid>
        ))}
      </Grid>
      <DialogActionsBar>
        <button className="k-button" onClick={handleCancel}>
          Cancel
        </button>
        <button className="k-button">{dialogMode == 'Add' ? 'Add' : 'Edit'}</button>
      </DialogActionsBar>
    </Dialog>
  );
}

const useStyles = makeStyles(() => ({
  label: {
    fontSize: '12px',
    paddingBottom: '5px',
  },
  value: {
    paddingBottom: '5px',
  },
}));

export default VitalDialog;
