import React from 'react';

import { complianceMapping } from './helpers/mappings';

function UserComplainceCell(props) {
  const { dataItem } = props;

  return (
    <td>
      <span
        style={{
          fontWeight: 600,
          color: complianceMapping[dataItem.complianceStatus].color,
        }}
      >
        {complianceMapping[dataItem.complianceStatus].label.toUpperCase()}
      </span>
    </td>
  );
}

export default UserComplainceCell;
