import { createSlice } from '@reduxjs/toolkit';

import api from '../../../api';
import { handleError } from '../../Shared/Content/APIUtils';

const initialState = {
  prescriptionList: null,
  prescriptionListLoading: null,
};

export const prescriptionListSlice = createSlice({
  name: 'prescriptionList',
  initialState,
  reducers: {
    setPrescriptionListLoading: (state, { payload }) => ({
      ...state,
      prescriptionListLoading: payload,
    }),
    setPrescriptionList: (state, { payload }) => ({
      ...state,
      prescriptionList: payload,
      prescriptionListLoading: false,
    }),
  },
});

export const { setPrescriptionList, setPrescriptionListLoading } = prescriptionListSlice.actions;

export const getPrescriptionList = (safeEMRId) => async (dispatch) => {
  dispatch(setPrescriptionListLoading(true));

  const [result, error] = await api.getPrescriptionListRequest(safeEMRId);

  if (result) {
    dispatch(setPrescriptionList(result));
  }

  if (error && error.message) {
    dispatch(handleError(error));
  }
};

export default prescriptionListSlice.reducer;
