import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState, useEffect, useMemo, useCallback } from 'react';

// redux
import { useDispatch, useSelector } from 'react-redux';

import ProfileViewStyles from '../../../assets/jss/components/ProfileViewStyles';
import { getUserScanHistory } from '../../Orders/ordersSlice';
import { selectScanHistory, selectScanHistoryLoading } from '../../Orders/selectors';

// component imports
import * as htmlUtils from '../../Shared/Grid/helpers';
import { selectCurrentTenant } from '../../Shared/selectors';
import AppKendoTableWrapper from '../../Shared/Table/KendoTableWrapper';
import utils from '../../Shared/Utils/utils';

// mui
import { selectUserDetails } from '../../Users/selectors';
import { scanHistoryColumns } from '../helpers/profileListMapper';

import TabDetailPanel from './TabDetailPanel';

function ScanHistory() {
  const classes = useStyles();

  const currentTenant = useSelector(selectCurrentTenant);
  const userDetails = useSelector(selectUserDetails);
  const userScans = useSelector(selectScanHistory);
  const userScansLoading = useSelector(selectScanHistoryLoading);

  const [selectedRow, setSelectedRow] = useState(undefined);

  const dispatch = useDispatch();

  // handles all table updates from table wrapper
  const handleTableUpdate = (page, pageSize, sort, direction) => {
    dispatch(getUserScanHistory(page, pageSize, sort, direction.toUpperCase(), userDetails.email, currentTenant.id));
  };

  // open questionnaire details
  const handleOpenDetails = useCallback(
    (data) => {
      if (selectedRow && selectedRow.id === data.id) {
        removeSelected();
      } else {
        setSelectedRow(data);
      }
    },
    [selectedRow]
  );

  const removeSelected = () => {
    setSelectedRow(undefined);
  };

  useEffect(() => {
    dispatch(getUserScanHistory(0, 10, 'checked', 'DESC', userDetails.email, currentTenant.id));
  }, [currentTenant, userDetails]);

  const columns = useMemo(() => scanHistoryColumns(handleOpenDetails), []);

  return (
    <Grid className={classes.contentWrapper}>
      <AppKendoTableWrapper
        data={{
          ...userScans,
          items:
            userScans &&
            userScans.items &&
            userScans.items.map((n) => ({
              ...n,
              checked: utils.formatDateTime(n.checked),
              outcome: n.outcome.outcome && utils.removeUnderscoresTitleCase(n.outcome.outcome),
              questionnaireExpired: n.outcome.questionnaireExpired ? 'Yes' : 'No',
              lastQuestionnaire: n.outcome.lastQuestionnaire && utils.formatDateTime(n.outcome.lastQuestionnaire),
              selected: selectedRow && n.id === selectedRow.id,
              expanded: selectedRow && n.id === selectedRow.id,
              overriddenOn: !!n.outcome.overriddenOn,
              overriddenReason: n.outcome && n.outcome.overriddenReason ? n.outcome.overriddenReason : '',
              handleOpenDetails,
            })),
        }}
        initialSort="checked"
        initialDir="desc"
        columns={columns}
        loading={userScansLoading}
        onRowClick={handleOpenDetails}
        onTableUpdate={handleTableUpdate}
        detailsComponent={htmlUtils.withCustomProp(
          htmlUtils.withCustomProp(TabDetailPanel, 'blacklistItems', [
            'user',
            'questionnaireExpired',
            'lastQuestionnaire',
          ]),
          'property',
          'outcome'
        )}
      />
    </Grid>
  );
}

const useStyles = makeStyles(ProfileViewStyles);

export default ScanHistory;
