/* eslint-disable no-unused-expressions */
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
// import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import React, { useRef, useState } from 'react';
import EmailEditor from 'react-email-editor';
import DragExpansionWrapperStyles from '../../../assets/jss/components/DragExpansionWrapperStyles';
import { SettingsConfirmImportDialog } from '../SettingsConfirmImportDialog';
import { variables, tagVariables } from './helpers/templateVariables';
import SettingsTemplateVariableHelper from './SettingsTemplateVariableHelper';
import SubmitTestTemplateDialog from './SubmitTestTemplateDialog';

const API_ROOT = process.env.REACT_APP_API_ROOT;

function SettingsTemplateEditor(props) {
  const {
    tenantCustomFontsList,
    handleSaveTemplate,
    handleTestTemplate,
    selectedTemplate,
    confirmCopy,
    errorNotification,
    submitting,
    submittingTest,
    templateId,
    classes,
  } = props;
  const [pastedTemplate, setPastedTemplate] = useState(false);
  const [open, setOpen] = useState(false);
  const [templateLoaded, setTemplateLoaded] = useState(false);
  const [variableOpen, setVariableOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const editor = useRef(null);

  // on load of editor, load in saved template props JSON, or if templateJson found from a paste, load in the new pasted json
  const onLoad = () => {
    const templateJSON = JSON.parse(selectedTemplate.specs);
    if (editor && editor.current) {
      editor.current.loadDesign(templateJSON);
    } else {
      setTimeout(onLoad, 3000);
    }
  };

  // wait for design to fully load to allow for copy of template
  const onDesignLoad = () => {
    setTemplateLoaded(true);
  };

  // exporting design to save with html and json
  const exportHtml = () => {
    editor &&
      editor.current &&
      editor.current.exportHtml((data) => {
        const { design, html } = data;
        handleSaveTemplate(templateId, html, design);
      });
    setPastedTemplate(false);
  };

  const onTestTemplate = (email) => {
    if (handleTestTemplate) {
      handleTestTemplate(email);
    }
    setDialogOpen(false);
  };

  const revertTemplate = () => {
    const parsedJson = JSON.parse(selectedTemplate.specs);
    editor && editor.current && editor.current.loadDesign(parsedJson);
    setPastedTemplate(false);
  };

  // on click of 'copy template' save json to user clipboard
  const copyTemplate = () => {
    editor.current.exportHtml((data) => {
      const { design } = data;
      const jsonString = JSON.stringify(design);
      navigator.clipboard
        .writeText(jsonString)
        .then(() => {
          confirmCopy();
        })
        .catch((err) => {
          errorNotification(err.message);
        });
    });
  };

  // if user wants to paste template, set state to true to hide editor and show paste textbox
  const importTemplate = () => {
    navigator.clipboard
      .readText()
      .then((text) => {
        // `text` contains the text read from the clipboard
        const parsedJson = JSON.parse(text);
        if (!parsedJson.counters || !parsedJson.body) {
          errorNotification('This is not a valid template. Please try to copy the desired template again.');
        } else {
          editor && editor.current && editor.current.loadDesign(parsedJson);
          setPastedTemplate(true);
        }
      })
      .catch((err) => {
        // maybe user didn't grant access to read from clipboard, not valid json, or not valid template format
        errorNotification(err.message);
      });
    setOpen(false);
  };

  const handleVariablePanel = () => {
    setVariableOpen(!variableOpen);
  };

  // Custom font support is only available in premium plans https://docs.unlayer.com/docs/font-management
  const options = {
    // TODO migrate to {{ }} mustache templates
    version: '1.2.235',
    fonts: {
      showDefaultFonts: true,
      customFonts: [
        {
          label: 'Whitney',
          value: 'Whitney, Avenir, -apple-system, BlinkMacSystemFont, Helvetica, Arial, sans-serif',
          url: `${window.location.origin}/index.css`,
        },
        {
          label: 'Avenir',
          value: 'Avenir, -apple-system, BlinkMacSystemFont, Helvetica, Arial, sans-serif',
          url: `${window.location.origin}/index.css`,
        },
        // adding custom fonts
        ...tenantCustomFontsList.map((item) => ({
          label: item.name,
          value: item.fallBackFonts,
          url: `${API_ROOT}/admin/v1${item.url}`,
        })),
      ],
    },
    translations: {
      'en-US': {
        'labels.merge_tags': 'Message Variables',
      },
    },
    mergeTags: tagVariables,
    tools: {
      text: {
        properties: {
          text: {
            value:
              '<span style="font-family: Avenir, -apple-system, BlinkMacSystemFont, Helvetica, Arial, sans-serif; ' +
              'font-size: 14px; line-height: 19.6px;">This is a new Text block. Change the text.</span>',
          },
        },
      },
    },
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Paper style={{ padding: 30, borderRadius: 20 }} elevation={0}>
          <Grid container spacing={2}>
            {variableOpen && (
              <Grid item xs={12} md={3}>
                <Typography className={classes.messageVariableTitle} align="center">
                  SYSTEM MESSAGE VARIABLES
                </Typography>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    className={classes.messageVariablesDropdown}
                    style={{
                      height: '80vh',
                    }}
                  >
                    <ClickAwayListener onClickAway={handleVariablePanel}>
                      <SettingsTemplateVariableHelper variables={variables} />
                    </ClickAwayListener>
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Grid item xs={12} md={variableOpen ? 9 : 12}>
              <EmailEditor
                ref={editor}
                onLoad={onLoad}
                maxWidth="40vw"
                minHeight="80vh"
                onDesignLoad={onDesignLoad}
                projectId="5314"
                options={options}
              />
            </Grid>
          </Grid>
          <Grid container alignItems="center" alignContent="center">
            <Grid item xs={12} style={{ marginTop: 15 }}>
              <Grid container spacing={2} justifyContent="space-between">
                <Grid item xs={6} md={7}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={copyTemplate}
                    disabled={!templateLoaded || submitting}
                  >
                    Copy Template
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => setOpen(true)}
                    disabled={submitting}
                    style={{ marginLeft: 5 }}
                  >
                    Import Template
                  </Button>
                  <Button variant="outlined" color="primary" onClick={handleVariablePanel} style={{ marginLeft: 5 }}>
                    ADD SYSTEM MESSAGE VARIABLE
                  </Button>
                </Grid>
                <Grid item xs={6} md={5} style={{ textAlign: 'right' }}>
                  {pastedTemplate && (
                    <Button variant="outlined" color="primary" onClick={revertTemplate} disabled={submitting}>
                      Revert
                    </Button>
                  )}
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => setDialogOpen(true)}
                    style={{ marginLeft: 5 }}
                    disabled={submitting || submittingTest}
                  >
                    {submittingTest && <CircularProgress color="primary" size={20} />}
                    Test Saved Template
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={exportHtml}
                    style={{ marginLeft: 5 }}
                    disabled={submitting}
                  >
                    {submitting && <CircularProgress color="primary" size={20} />}
                    Save Template
                  </Button>
                </Grid>
                <Grid item />
                <Grid
                  item
                  xs={6}
                  style={{
                    paddingLeft: '24px',
                    position: 'relative',
                    textAlign: 'right',
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <SettingsConfirmImportDialog
        open={open}
        handleImport={importTemplate}
        title="Import Template"
        message="Are you sure you want to import this template? Your current template will be overwritten."
        closeImportDialog={() => setOpen(false)}
      />
      {dialogOpen && <SubmitTestTemplateDialog closeDialog={() => setDialogOpen(false)} onSubmit={onTestTemplate} />}
    </Grid>
  );
}

export default withStyles(DragExpansionWrapperStyles)(SettingsTemplateEditor);
