// redux
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { useTheme, withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import ProfileViewStyles from '../../assets/jss/components/ProfileViewStyles';
import { getUserFromToken } from '../Auth/helpers';
import PublicHealthReportInfo from '../DiagnosticReport/PublicHealthReportInfo';
import { getOrderDetails } from '../Orders/ordersSlice';
import { selectOrderDetails, selectOrderDetailsLoading } from '../Orders/selectors';

// styling and component imports
import ProfileCard from '../Profile/ProfileCard';
import {
  ProfileCardSkeletonLoading,
  ProfileCardSkeletonLoadingMobile,
  ProfileTabsSkeletonLoading,
  ProfileDropdownSkeletonLoadingMobile,
} from '../Profile/ProfileSkeletonLoading';
import { selectCurrentTenant } from '../Shared/selectors';
import {
  selectProfilePicture,
  selectUserDetails,
  selectUserDetailsLoading,
  selectUserPermissionsLoading,
  selectUserPictureLoading,
} from '../Users/selectors';
import { getUser, getUserPermissions } from '../Users/usersSlice';

import AIInfo from './AIInfo';
import { rapidTestTabsMapping } from './helpers/mapping';
import RapidTestDetail from './RapidTestDetail';
// import ScanInfo from './ScanInfo';

// material-UI

function RapidTestReport(props) {
  const { classes, type } = props;
  const location = useLocation();
  const userDetailsLoading = useSelector(selectUserDetailsLoading);
  const userDetails = useSelector(selectUserDetails);
  const currentTenant = useSelector(selectCurrentTenant);
  const userPictureLoading = useSelector(selectUserPictureLoading);
  const profilePicture = useSelector(selectProfilePicture);
  const userPermissionsLoading = useSelector(selectUserPermissionsLoading);
  const getOrderDetailsLoading = useSelector(selectOrderDetailsLoading);
  const orderDetails = useSelector(selectOrderDetails);

  const dispatch = useDispatch();

  const { userId: emailId, encounterId } = useParams();

  const getUserData = async () => {
    await dispatch(getUser(emailId, true));
  };

  const user = getUserFromToken();
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  useEffect(() => {
    if (location?.state?.from === 'userDetails') {
      setValue(1);
    }
  }, [location]);

  useEffect(() => {
    dispatch(getUser(emailId));
    dispatch(getUserPermissions(currentTenant.id, emailId));
    dispatch(getOrderDetails(encounterId));
    getUserData();
  }, [currentTenant.id, type]);

  const handleChange = (e, value) => setValue(value);

  const renderPageComponent = () => {
    // eslint-disable-next-line default-case
    switch (value) {
      case 0:
      case 1:
        return (
          <RapidTestDetail
            loading={
              userDetailsLoading ||
              !userDetails ||
              userPictureLoading ||
              !profilePicture ||
              userPermissionsLoading ||
              !orderDetails ||
              getOrderDetailsLoading
            }
            user={user}
            path={value}
            currentTenant={currentTenant}
            userDetails={userDetails}
            orderDetails={orderDetails}
          />
        );
      // case 2:
      //   return (
      //     <ScanInfo
      //       user={user}
      //       orderDetails={orderDetails}
      //       loading={
      //         userDetailsLoading || !userDetails || userPictureLoading || !profilePicture || userPermissionsLoading
      //       }
      //     />
      //   );
      case 2:
        return (
          <AIInfo
            user={user}
            orderDetails={orderDetails}
            loading={
              userDetailsLoading || !userDetails || userPictureLoading || !profilePicture || userPermissionsLoading
            }
          />
        );
      case 3:
        return (
          <PublicHealthReportInfo
            user={user}
            currentTenant={currentTenant}
            userDetails={userDetails}
            encounterId={encounterId}
          />
        );
    }
  };

  if (!currentTenant) return <div />;

  if (
    userDetailsLoading ||
    !userDetails ||
    !profilePicture ||
    userPermissionsLoading ||
    getOrderDetailsLoading ||
    !orderDetails
  ) {
    return (
      <div>
        {matches ? (
          <div>
            <ProfileCardSkeletonLoading />
            <Paper style={{ borderRadius: '20px', marginBottom: 30 }} elevation={0}>
              <ProfileTabsSkeletonLoading />
            </Paper>
          </div>
        ) : (
          <div>
            <Card style={{ borderRadius: '20px', marginBottom: 20 }} elevation={0}>
              <ProfileCardSkeletonLoadingMobile />
            </Card>
            <Paper style={{ borderRadius: '20px', marginBottom: 30 }} elevation={0}>
              <ProfileDropdownSkeletonLoadingMobile />
            </Paper>
          </div>
        )}
      </div>
    );
  }

  // profile page
  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <ProfileCard />
      </Grid>

      <Grid item xs={12} className={classes.bottomBox}>
        <Tabs
          centered
          value={value}
          indicatorColor="primary"
          variant="fullWidth"
          textColor="primary"
          onChange={handleChange}
          aria-label="disabled tabs example"
        >
          {rapidTestTabsMapping.map((tab, index) => (
            <Tab
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              label={tab.label}
              xs={4}
              style={{ fontSize: '12px', marginBottom: '-10px' }}
              className={value === tab.value ? classes.activeLink : ''}
            />
          ))}
        </Tabs>
        <div style={{ marginTop: 25 }}>{renderPageComponent()}</div>
      </Grid>
    </Grid>
  );
}

export default withStyles(ProfileViewStyles)(RapidTestReport);
