// material-ui components
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';

// actions taken upon confirmation/cancellation of an action
export function SettingsConfirmImportDialog(props) {
  const { closeImportDialog, handleImport, open, message, title } = props;

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
          closeImportDialog();
        }
      }}
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
    >
      <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <Typography variant="body1">{message}</Typography>
      </DialogContent>
      <DialogActions>
        <div>
          <Button onClick={closeImportDialog} color="primary" style={{ marginRight: 8 }}>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleImport} color="primary">
            Ok
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

const styles = (theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    width: '80%',
    maxHeight: 435,
  },
});

// middleware
export default withStyles(styles)(SettingsConfirmImportDialog);
