import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Grid from '@material-ui/core/Grid';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';

function ExpansionPanelWrapper(props) {
  const { children, name, renderTitle, defaultExpanded = true } = props;

  return (
    <Accordion defaultExpanded={defaultExpanded} style={{ marginTop: 24 }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={name} id={name}>
        {renderTitle()}
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid key={name} item xs={12} style={{ paddingTop: 10, paddingBottom: 10 }}>
            {children}
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

export default ExpansionPanelWrapper;
