export const mainEncounterInfo = [
  // Patient Details
  {
    header: 'Patient Details',
    width: 4,
    fields: [
      {
        name: 'patientId',
        label: 'Patient Id',
      },
      {
        name: 'patientName',
        label: 'Name',
      },
      {
        name: 'patientGender',
        label: 'Gender',
      },
      {
        name: 'patientDOB',
        label: 'DOB',
      },
      {
        name: 'patientPhone',
        label: 'Phone',
      },
      {
        name: 'patientEmail',
        label: 'Email',
      },
    ],
  },

  // Physician Details
  {
    header: 'Physician Details',
    width: 4,
    fields: [
      {
        name: 'physicianName',
        label: 'Name',
      },
      {
        name: 'physicianSpecialty',
        label: 'Specialty',
      },
      {
        name: 'physicianAddress',
        label: 'Address',
      },
      {
        name: 'physicianPhone',
        label: 'Phone',
      },
    ],
  },

  // Encounter Details
  {
    header: 'Encounter Details',
    width: 4,
    fields: [
      {
        name: 'encounterType',
        label: 'Type',
      },
      {
        name: 'encounterStatus',
        label: 'Status',
      },
      {
        name: 'encounterPriority',
        label: 'Priority',
      },
      {
        name: 'encounterDeadline',
        label: 'Deadline',
      },
      {
        name: 'encounterClientAccountNo',
        label: 'Client Account NO.',
      },
    ],
  },
];

export const labReport = [
  {
    header: 'Lab report',
    width: 12,
  },
  {
    header: 'Specimen Id:',
    name: 'specimenId',
    width: 3,
  },
  {
    header: 'Lab Ref#',
    name: 'labRef',
    width: 3,
  },
  {
    header: 'Collected:',
    name: 'collected',
    width: 2,
  },
  {
    header: 'Received:',
    name: 'received',
    width: 2,
  },
  {
    header: 'Reported:',
    name: 'reported',
    width: 2,
  },
  {
    header: 'Performing Site',
    width: 12,
  },
  {
    header: '',
    width: 12,
    name: 'laboratoryDirector',
    additionalText: 'Laboratory Director:',
    valueWidth: 6,
  },
  {
    header: '',
    width: 12,
    name: 'labOrderComments',
    additionalText: 'COMMENTS:',
    valueWidth: 12,
  },
];

export const testSpecimenDetails = [
  {
    header: 'Specimen Id',
    name: 'specimenId',
    width: 4,
  },
  {
    header: 'Lab Ref#',
    name: 'specimenLabRef',
    width: 4,
  },
  {
    header: 'Collected',
    name: 'specimenCollected',
    width: 4,
  },
  {
    header: 'Performing Site',
    width: 12,
  },
  {
    header: '',
    width: 12,
    name: 'specimenPerformingSite',
    valueWidth: 12,
  },
  {
    header: '',
    width: 12,
    name: 'specimenLaboratoryDirector',
    additionalText: 'Laboratory Director:',
    valueWidth: 6,
  },
];

export const testAnalyteDataMap = [
  {
    header: 'Analyte',
    name: 'testAnalyte',
    width: 2,
  },
  {
    header: 'Result Value',
    name: 'testResultValue',
    width: 2,
  },
  {
    header: 'Flag',
    name: 'testFlag',
    width: 2,
  },
  {
    header: 'UNITS',
    name: 'testUnits',
    width: 2,
  },
  {
    header: 'Ref. Range',
    name: 'testRefRange',
    width: 2,
  },
  {
    header: 'Lab',
    name: 'testLab',
    width: 2,
  },
  {
    header: 'Analyte Notes',
    name: 'testAnalyteNotes',
    width: 12,
  },
  {
    header: 'Lab Notes',
    name: 'testLabNotes',
    width: 6,
  },
  {
    header: 'Physician Assessment and Notes',
    name: 'physicianAssessmentAndNotes',
    fields: [
      {
        label: 'Interpretation',
        name: 'testInterpretation',
      },
      {
        label: 'Notes',
        name: 'testNotes',
      },
    ],
    width: 6,
  },
];
