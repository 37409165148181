import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState, useEffect } from 'react';

// ui reflection of file view
function CheckboxGroup(props) {
  const classes = useStyles();
  const { concernList, selectedConcern, handleSelectConcern } = props;
  const [currentValues, setCurrentValues] = useState({});

  useEffect(() => {
    const formattedValues = {};
    if (selectedConcern && selectedConcern.length) {
      selectedConcern.forEach((item) => {
        formattedValues[item] = true;
      });
    }
    setCurrentValues(formattedValues);
  }, [selectedConcern]);

  const handleMultiChange = (event) => {
    const optionName = event.target.name;
    const { checked } = event.target;

    let newMultiValue = {};
    newMultiValue = {
      ...currentValues,
      [optionName]: checked,
    };
    const trueValues = Object.keys(newMultiValue).filter((k) => newMultiValue[k]);
    setCurrentValues(newMultiValue);
    handleSelectConcern(trueValues);
  };

  if (!concernList || !concernList.length) {
    return <div>Add at least one &apos;Concern&apos; in settings section</div>;
  }

  return (
    <FormControl component="fieldset">
      <FormGroup>
        {concernList.map((option, i) => {
          const num = i;
          return (
            <Grid key={`option-${num}`}>
              <FormControlLabel
                control={
                  <Checkbox
                    className={classes.root}
                    id={option._id}
                    checked={currentValues[option.name] || false}
                    onChange={handleMultiChange}
                    name={option.name}
                    color="primary"
                  />
                }
                label={option.name}
              />
            </Grid>
          );
        })}
      </FormGroup>
    </FormControl>
  );
}

const useStyles = makeStyles(() => ({
  root: {
    padding: 3,
  },
}));

export default CheckboxGroup;
