import { Error } from '@progress/kendo-react-labels';
import { Box, Button, FormControl, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import DropdownField from '../../Shared/Forms/KendoFields/DropdownField';
import TextFieldInput from '../../Shared/Forms/KendoFields/TextFieldInput';
import NumberFieldInput from '../../Shared/Forms/KendoFields/NumberFieldInput';
import { OverlayLoading } from '../helper/SimulatorLoading';
import { useDispatch, useSelector } from 'react-redux';
import { resetDoordashLoading, resetNetsuiteLoading, startSimulateDoordash, startSimulateNetsuite } from '../slice/shopifySimulatorSlice';
import { selectDoordashLoading, selectNetsuiteLoading } from '../slice/selector';
import { showNotification } from '../../Notifications/notificationSlice';
import { useLocation } from 'react-router-dom';
import { selectUserDetails } from '../../Users/selectors';
import { useParams } from 'react-router-dom';
import { useReducer, useEffect } from 'react';
import { getUser } from '../../Users/usersSlice';
import { useRef } from 'react';
import { NETSUITE_STATUS_TYPE } from '../helper/shopifyConstants';

const netsuiteOptions = Object.values(NETSUITE_STATUS_TYPE);

const netsuiteDropdownField = {
  type: 'dropdown',
  name: 'netsuiteStatus',
  label: 'Status',
  labelField: 'display',
  itemKey: 'value',
  options: netsuiteOptions,
};

const idField = {
  type: 'text',
  name: 'netsuiteId',
  label: 'Order ID (Optional)',
  readOnly: false,
};

const netsuiteReducer = (state, action) => ({
  ...state,
  // if it is a function
  ...(typeof action === 'function' ? action(state) : action),
});

const defaultState = {
  netsuiteStatus: netsuiteOptions[0],
  netsuiteId: '',
};

function NetsuiteSimulator() {
  const dispatch = useDispatch();
  const params = useParams();

  const netsuiteLoading = useSelector(selectNetsuiteLoading);
  const [netsuiteState, setNetsuiteState] = useReducer(netsuiteReducer, defaultState, (init) => {
    return init;
  });

  /* need to get user's "_id" not just email/userId */
  const { userId } = params;
  const userDetails = useSelector(selectUserDetails);
  const isMounted = useRef(false);

  useEffect(() => {
    const getUserData = async () => {
      dispatch(getUser(userId, true));
    };
    getUserData();

    isMounted.current = true;
  }, [userId]);
  /* END - getting userDetails for _id */

  const { netsuiteStatus, netsuiteId } = netsuiteState;
  const [error, setError] = useState({});

  // const accountId = localStorage.getItem('accountId');
  const tenantId = localStorage.getItem('tenantId');

  const handleStatusChange = (fieldName, fieldVal) => {
    /* Clear Error */
    const clearedErr = { ...error };
    delete clearedErr['netsuiteStatus'];
    setError({ ...clearedErr });

    setNetsuiteState({ netsuiteStatus: fieldVal });
  };

  const handleIdChange = (e) => {
    setNetsuiteState({ netsuiteId: e.target.value });
  };

  useEffect(() => {
    if (netsuiteLoading === 'fulfilled' || netsuiteLoading === 'rejected') {
      dispatch(resetNetsuiteLoading());
    }
  }, [netsuiteLoading]);

  /* SUBMIT SIMULATION REQUEST */
  const handleSubmit = async (e) => {
    e.preventDefault();
    const hasError = validateInputs();

    if (hasError) {
      return null;
    }

    const userIdPayload = userDetails?._id ?? null;
    if (!userIdPayload) return;

    const payload = {
      event: netsuiteStatus.value,
      // userId: userIdPayload,
      formData: {
        id: netsuiteId,
        user: userId,
      },
    };

    try {
      const result = await dispatch(startSimulateNetsuite(payload));

      if (result.error) {
        dispatch(showNotification(`Failed to execute simulator <br />Error: ${result.error.message}`, 'error'));
        dispatch(resetNetsuiteLoading());
        return;
      }

      if (result.payload[1]?.status >= 400) {
        dispatch(showNotification(`Failed to execute simulator <br />Error: ${result?.payload[1]?.message}`, 'error'));
        dispatch(resetNetsuiteLoading());
        return;
      }

      if (result.payload[0]?.success) {
        dispatch(
          showNotification(`${result?.payload[0]?.message} <br />Order updated successfully`, 'success')
        );
        return;
      }
    } catch (error) {
      dispatch(showNotification(`Failed to execute simulator <br />Error: ${error.message}`, 'error'));
      dispatch(resetNetsuiteLoading());
    }
  };

  const validateInputs = () => {
    let hasError = false;
    if (!netsuiteStatus) {
      setError((err) => ({
        ...err,
        netsuiteStatus: {
          message: 'Status is required',
        },
      }));

      hasError = true;
    }

    return hasError;
  };

  return (
    <Box width={'100%'} height={'100%'}>
      {(netsuiteLoading === 'pending' || !isMounted.current) && <OverlayLoading />}
      <Box
        display={'grid'}
        gridTemplateColumns={'repeat(2, minmax(0, 1fr))'}
        gridRowGap={'50px'}
        gridColumnGap={'100px'}
        marginTop={'25px'}
        marginBottom={'25px'}
      >
        <Box textAlign={'center'}>
          <DropdownField
            field={netsuiteDropdownField}
            value={netsuiteStatus}
            handleChangeWithParams={handleStatusChange}
          />
          {error?.netsuiteStatus && <Error>{error?.netsuiteStatus?.message}</Error>}
        </Box>
        <Box textAlign={'center'}>
          <TextFieldInput field={idField} value={netsuiteId} handleChange={handleIdChange} />
          {error?.netsuiteId && <Error>Please enter proper kit ID</Error>}
        </Box>
        <Box gridColumn={'2 / 3 '} display="flex" justifyContent={'center'}>
          <Button variant="contained" color="primary" onClick={handleSubmit} disabled={netsuiteLoading === 'pending'}>
            Simulate
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default NetsuiteSimulator;
