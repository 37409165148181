export const initialSorting = {
  field: 'email',
  dir: 'asc',
};

// columns to pass to table
export const columns = () => [
  {
    id: 'title',
    label: 'Outcome title',
    show: true,
    minWidth: 170,
  },
  {
    id: 'created',
    label: 'Created At',
    show: true,
    minWidth: 170,
  },
  {
    id: 'updated',
    label: 'Updated At',
    show: true,
    minWidth: 170,
  },
];
