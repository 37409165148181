export const selectConsult = (state) => state.questionnaire;
export const selectQuestionnaireData = (state) => state.questionnaire.previewQuestion;
export const selectQuestionData = (state) => state.questionnaire.previewQuestion?.question?.question;
export const selectOutcomeData = (state) => state.questionnaire.previewQuestion?.question?.outcome;
export const selectExecutionId = (state) => state.questionnaire.executionId;
export const selectIsValidCode = (state) => state.questionnaire.invalidCode;
export const selectIsValidAnswer = (state) => state.questionnaire.validAnswer;
export const selectIsValidPhoneNumber = (state) => state.questionnaire.isValidPhoneNumber;

export const selectQuestionnaireAnswers = (state) => state.questionnaire.answers;
export const selectPayorList = (state) => state.questionnaire.payorList;
export const selectIsVerifiedInsurance = (state) => state.questionnaire.verifiedInsurance;

export const selectFormattedQuestionnaireAnswers = (state) => {
  const { questionnaire } = state;
  const questionnaireAnswers = {};
  if (questionnaire && questionnaire.answers && Object.values(questionnaire.answers).length) {
    Object.values(questionnaire.answers).forEach((item) => {
      // @ts-ignore
      questionnaireAnswers[item.questionId] = item.answer;
    });
  }
  return questionnaireAnswers;
};

export const selectReporterAnswers = (state) => Object.values(state.questionnaire.reporterAnswers) || [];
