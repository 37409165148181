/* eslint-disable import/no-cycle */
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import React, { useState } from 'react';

import QuestionnairePreview from './Builder/QuestionnairePreview';
import QuestionnaireSettings from './Builder/QuestionnaireSettings';

export function QuestionnaireBuilder(props) {
  const { currentTenant, editingQuestion, setEditingQuestion } = props;
  const [isSelected, setSelectedStatus] = useState(false);
  const [type, setType] = useState('');
  const [disableButtons, setDisableButtons] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleOpenDialog = (type) => {
    setSelectedStatus(true);
    setType(type);
    setDialogOpen(true);
    setDisableButtons(false);
  };

  const handleCancel = () => {
    setType('');
    setDialogOpen(false);
    setSelectedStatus(false);
  };

  return (
    <Grid container spacing={6}>
      <Grid justifyContent="flex-end" style={{ marginTop: 30 }} container>
        <Grid item xs={4}>
          <Button variant="outlined" color="primary" onClick={() => handleOpenDialog('questions')}>
            Add Question(s)
          </Button>
          <Button
            variant="outlined"
            color="primary"
            style={{ marginLeft: 15 }}
            onClick={() => handleOpenDialog('outcomes')}
          >
            Add Outcome(s)
          </Button>
        </Grid>
      </Grid>
      {isSelected ? (
        <Grid item xs={12}>
          <QuestionnaireSettings
            setSelectedStatus={setSelectedStatus}
            isSelected={isSelected}
            currentTenant={currentTenant}
            type={type}
            dialogOpen={dialogOpen}
            handleCloseDialog={handleCancel}
            handleCancel={handleCancel}
          />
        </Grid>
      ) : (
        <Grid item xs={12}>
          <QuestionnairePreview
            setSelectedStatus={setSelectedStatus}
            setDisableButtons={setDisableButtons}
            disableButtons={disableButtons}
            editingQuestion={editingQuestion}
            setEditingQuestion={setEditingQuestion}
            isSelected={isSelected}
          />
        </Grid>
      )}
    </Grid>
  );
}

export default QuestionnaireBuilder;
