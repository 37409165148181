import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React, { useEffect, useState } from 'react';

import styles from '../../../assets/Questionnaire.module.css';
import StandardInfo from '../fieldProps/StandardInfo';

import SingleTest from './SingleTest';
import TestPanel from './TestPanel';

function LabOrderQuestion(props) {
  const { field, handleChangeWithParams, selectedLanguage } = props;
  const { testList, interactive } = field;

  const [selectedTests, setSelectedTests] = useState([]);

  // Initial 'answer'
  useEffect(() => {
    const initialSelections = field.testList?.map((test) => {
      if (test.panel) {
        const id = test.orderableTestPanelId;
        const panelSelections = {};
        test.orderableTestList?.forEach((orderableTest) => {
          panelSelections[orderableTest.labTestOrderableId] = test.autoSelectedOrderableTestList?.includes(
            orderableTest.labTestOrderableId
          );
        });
        return {
          id,
          tests: panelSelections,
        };
      }
      return {
        id: test.orderableTestId,
      };
    });
    setSelectedTests(initialSelections);
    handleChangeWithParams(field.name, initialSelections);
  }, [interactive, field.testList, field.name]);

  const handleChange = (parentIdx, selectionId) => {
    const newSelections = [...selectedTests];
    const testChanged = newSelections[parentIdx];
    testChanged.tests[selectionId] = !testChanged.tests[selectionId];

    // Verify at least one test for every panel
    const atLeastOneSelected = Object.keys(testChanged.tests).some((test) => testChanged.tests[test]);
    if (atLeastOneSelected) {
      handleChangeWithParams(field.name, newSelections);
    } else {
      handleChangeWithParams(field.name, null);
    }
    setSelectedTests(newSelections);
  };

  if (testList?.length === 0 || !testList?.every((test) => test.orderableTestId)) {
    return (
      <Grid container spacing={1} justifyContent="center">
        <Grid item>
          <CircularProgress style={{ color: '#4c4fd9' }} />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <StandardInfo field={field} selectedLanguage={selectedLanguage} centerName />
      </Grid>
      {testList.map((test, idx) => (
        <Grid key={test.orderableTestId} container>
          <Grid item xs={12}>
            <Typography align="center" gutterBottom className={styles.labOrderPrice}>
              {!test.price ? 'Free' : `$${test.price} or Insurance co-pay`}
            </Typography>

            {test.panel ? (
              <TestPanel
                testPanel={test}
                panelIdx={idx}
                interactive={field.interactive}
                selectedTests={selectedTests}
                handleChange={handleChange}
              />
            ) : (
              <SingleTest test={test} />
            )}
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
}

export default LabOrderQuestion;
