/* eslint-disable react/no-array-index-key */
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import React, { useState, useEffect } from 'react';

const ITEM_HEIGHT = 54;
const MENU_PADDING = 16;

export function MultiSelectFilter(props) {
  const { filterName, filterLabel, options, selectedByDefault, textField, handleFilter, filterValue } = props;
  const [selectedFilters, setSelectedFilters] = useState(selectedByDefault || []);

  useEffect(() => {
    setSelectedFilters(filterValue || []);
  }, [filterValue]);

  const renderValue = () => {
    let value = '';

    if (selectedFilters.length) {
      value = options
        .filter((item) => selectedFilters.indexOf(item.id) > -1)
        .map((k) => k.label)
        .join(', ');
    }

    return value;
  };

  const handleChange = (event) => {
    setSelectedFilters(event.target.value);
    handleFilter({
      target: {
        name: filterName,
        value: event.target.value,
      },
    });
  };

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 5 + MENU_PADDING,
        width: 250,
      },
    },
    getContentAnchorEl: null,
  };

  return (
    <div id="test-id" style={{ position: 'relative' }}>
      <FormControl style={{ width: '100%' }}>
        <InputLabel id="multiple-select-filter">{filterLabel}</InputLabel>
        <Select
          labelId="multiple-select-filter"
          id="multiple-select"
          multiple
          value={selectedFilters}
          onChange={handleChange}
          input={<Input />}
          renderValue={() => renderValue()}
          MenuProps={MenuProps}
        >
          {options.map((option, idx) => (
            <MenuItem key={idx} value={option.id}>
              <Checkbox color="primary" checked={selectedFilters.indexOf(option.id) > -1} />
              <ListItemText primary={option[textField]} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

export default MultiSelectFilter;
