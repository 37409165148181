import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ProfileViewStyles from '../../../assets/jss/components/ProfileViewStyles';
import { getConsents } from '../../Shared/Consent/consentsSlice';
import { selectConsents, selectConsentsLoading } from '../../Shared/Consent/selectors';
import { selectCurrentTenant } from '../../Shared/selectors';
import AppKendoTableWrapper from '../../Shared/Table/KendoTableWrapper';
import utils from '../../Shared/Utils/utils';
import { selectUserDetails } from '../../Users/selectors';
import { consentColumns } from '../helpers/profileListMapper';

// mui

import ConsentContentPanel from './ConsentContentPanel';

function Consents() {
  const currentTenant = useSelector(selectCurrentTenant);
  const userDetails = useSelector(selectUserDetails);

  const classes = useStyles();

  // const [openDetail, setOpenDetail] = useState(false);
  const consents = useSelector(selectConsents);
  const loading = useSelector(selectConsentsLoading);

  const [selectedRow, setSelectedRow] = useState(undefined);

  const dispatch = useDispatch();

  // handles all table updates from table wrapper
  const handleTableUpdate = (page, pageSize, sort, direction) => {
    dispatch(getConsents(page, pageSize, sort, direction.toUpperCase(), userDetails.email, currentTenant.id));
  };

  const removeSelected = () => {
    setSelectedRow(undefined);
  };

  // open questionnaire details
  const handleOpenContent = useCallback(
    (data) => {
      if (selectedRow && selectedRow.id === data.id) {
        removeSelected();
      } else {
        setSelectedRow(data);
      }
    },
    [selectedRow]
  );

  useEffect(() => {
    dispatch(getConsents(0, 10, 'consented', 'DESC', userDetails.email, currentTenant.id));
  }, [currentTenant, userDetails]);

  const columns = useMemo(() => consentColumns(handleOpenContent), [handleOpenContent]);

  return (
    <Grid className={classes.contentWrapper}>
      <AppKendoTableWrapper
        data={{
          ...consents,
          items:
            consents &&
            consents.items &&
            consents.items.map((n) => ({
              ...n,
              consented: utils.formatDateTime(n.consented),
              archived: n.archived ? 'Yes' : 'No',
              selected: selectedRow && n.id === selectedRow.id,
              expanded: selectedRow && n.id === selectedRow.id,
            })),
        }}
        initialSort="sent"
        initialDir="desc"
        columns={columns}
        loading={loading}
        onRowClick={handleOpenContent}
        onTableUpdate={handleTableUpdate}
        detailsComponent={ConsentContentPanel}
      />
    </Grid>
  );
}

const useStyles = makeStyles(ProfileViewStyles);

export default Consents;
