import { withStyles } from '@material-ui/core/styles';
import { Dialog } from '@progress/kendo-react-dialogs';
import axios from 'axios';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import CreateFormStyles from '../../assets/jss/components/CreateFormStyles';
import { showNotification } from '../Notifications/notificationSlice';
import SendFormWrapper from '../Shared/Forms/SendFormWrapper';

const API_ROOT = process.env.REACT_APP_API_ROOT;

function SendMessageDialog(props) {
  const { closeDialog, classes, data } = props;
  const [dynamic] = useState(false);
  const [disableForm, setDisableForm] = useState(false);
  const dispatch = useDispatch();
  const handleCancel = () => {
    closeDialog();
  };
  const handleSubmit = (message) => {
    setDisableForm(true);
    const id_token = localStorage.getItem('id_token');
    const accountId = localStorage.getItem('accountId');
    const tenantId = localStorage.getItem('tenantId');

    axios({
      method: 'POST',
      url: `${API_ROOT}/user/v1/bulkaction/sendmessage/${tenantId}`,
      data: { ...data, customMessage: message },
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json;charset=UTF-8',
        Accept: 'application/json',
        Authorization: `Bearer ${id_token}`,
        Id_Token: id_token,
        'X-SF-ACCOUNT': accountId,
        'X-SF-TENANT': tenantId,
      },
    })
      .then(() => {
        setDisableForm(false);
        dispatch(showNotification('Message Successfully Sent', 'success'));
        closeDialog();
      })
      .catch(() => {
        setDisableForm(false);
        dispatch(showNotification('An error occured', 'error'));
        closeDialog();
      });
  };

  return (
    <Dialog
      title="Select Message Type"
      onClose={handleCancel}
      width={dynamic ? undefined : 900}
      height={dynamic ? undefined : 550}
      maxWidth="xl"
      fullWidth
      className={classes.root}
    >
      <SendFormWrapper handleCancel={handleCancel} handleSubmit={handleSubmit} disableForm={disableForm} />
    </Dialog>
  );
}

export default withStyles(CreateFormStyles)(SendMessageDialog);
