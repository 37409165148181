import { createSlice } from '@reduxjs/toolkit';

import api from '../../api';
import { showNotification } from '../Notifications/notificationSlice';
import { handleError } from '../Shared/Content/APIUtils';

const initialState = {
  allRecordRequests: null,
  allRecordRequestsLoading: null,
  ccd: null,
  ccdLoading: null,
  oneupSyncStatus: null,
  oneupSyncStatusLoading: null,
  recordRequest: null,
  recordRequestLoading: null,
};

export const recordRequestSlice = createSlice({
  name: 'recordRequest',
  initialState,
  reducers: {
    setAllRecordRequestsLoading: (state) => ({
      ...state,
      allRecordRequestsLoading: true,
    }),
    setAllRecordRequests: (state, { payload }) => ({
      ...state,
      allRecordRequests: payload,
      allRecordRequestsLoading: false,
    }),
    setRecordRequestLoading: (state) => ({
      ...state,
      recordRequestLoading: true,
    }),
    setRecordRequest: (state, { payload }) => ({
      ...state,
      recordRequest: payload,
      recordRequestLoading: false,
    }),
    setCCDLoading: (state) => ({
      ...state,
      ccdLoading: true,
    }),
    setCCD: (state, { payload }) => ({
      ...state,
      ccd: payload,
      ccdLoading: false,
    }),
    setOneupSyncStatusLoading: (state) => ({
      ...state,
      oneupSyncStatusLoading: true,
    }),
    setOneupSyncStatus: (state, { payload }) => ({
      ...state,
      oneupSyncStatus: payload,
      oneupSyncStatusLoading: false,
    }),
  },
});

export const {
  setAllRecordRequests,
  setAllRecordRequestsLoading,
  setRecordRequest,
  setRecordRequestLoading,
  setCCD,
  setCCDLoading,
  setOneupSyncStatus,
  setOneupSyncStatusLoading,
} = recordRequestSlice.actions;

export const getAllRecordRequests = (tenant, params) => async (dispatch) => {
  dispatch(setAllRecordRequestsLoading());

  const [result, error] = await api.getAllRecordRequestsRequest(tenant, params);

  if (result) {
    dispatch(setAllRecordRequests(result));
  }

  if (error && error.message) {
    dispatch(handleError(error));
  }
};

export const getRecordRequest = (tenant, recordRequestId) => async (dispatch) => {
  dispatch(setRecordRequestLoading());

  const [result, error] = await api.getRecordRequestRequest(tenant, recordRequestId);

  if (result && result.data) {
    dispatch(setRecordRequest(result.data));
  }

  if (error && error.message) {
    dispatch(handleError(error));
  }
};

export const getCCD = (tenant, userId, encounterId) => async (dispatch) => {
  dispatch(setCCDLoading());

  const [result, error] = await api.getCCDRequest(tenant, userId, encounterId);

  if (result && result.data) {
    dispatch(setCCD(result.data));
  }

  if (error && error.message) {
    dispatch(handleError(error));
  }
};

export const getDataFromSpecificFacility = (tenant, userId, facilityId) => async (dispatch) => {
  const [result, error] = await api.getDataFromSpecificFacilityRequest(tenant, userId, facilityId);

  if (result && result.data) {
    dispatch(showNotification('Successfully Created', 'success'));
    dispatch(getAllRecordRequests(tenant));
  }

  if (error && error.message) {
    dispatch(handleError(error));
  }
};

export const getDataFromAllFacilitiesInTheState = (tenant, userId, state) => async (dispatch) => {
  const [result, error] = await api.getDataFromAllFacilitiesInTheStateRequest(tenant, userId, state);

  if (result && result.data) {
    dispatch(showNotification('Successfully Created', 'success'));
    dispatch(getAllRecordRequests(tenant));
  }

  if (error && error.message) {
    dispatch(handleError(error));
  }
};

export const getSequoiaDataFromWithinRange = (tenant, data) => async (dispatch) => {
  const [result, error] = await api.getSequoiaDataFromWithinRangeRequest(tenant, data);

  if (result && result.data) {
    dispatch(showNotification('Successfully Created', 'success'));
    dispatch(getAllRecordRequests(tenant));
  }

  if (error && error.message) {
    dispatch(handleError(error));
  }
};

export const getSequoiaDataFromAllFacilies = (tenant, userId) => async (dispatch) => {
  const [result, error] = await api.getSequoiaDataFromAllFaciliesRequest(tenant, userId);

  if (result && result.data) {
    dispatch(showNotification('Successfully Created', 'success'));
    dispatch(getAllRecordRequests(tenant));
  }

  if (error && error.message) {
    dispatch(handleError(error));
  }
};

export const retrivePatientDemographis = (patientData, tenantId, safeEMRId) => async (dispatch) => {
  const [result, error] = await api.retrivePatientDemographisRequest(patientData, tenantId, safeEMRId);

  if (result && result.success) {
    dispatch(showNotification('Patient  Demographics synced successfully', 'success'));
  }

  if (error && error.message) {
    dispatch(handleError(error));
  }
};

export const getOneupSyncStatus = (oneupSyncStatusId, tenanId) => async (dispatch) => {
  dispatch(setOneupSyncStatusLoading());

  const [result, error] = await api.getOneupSyncStatusRequest(tenanId, oneupSyncStatusId);

  if (result && result.data) {
    dispatch(setOneupSyncStatus(result.data));
  }

  if (error && error.message) {
    dispatch(handleError(error));
  }
};

export default recordRequestSlice.reducer;
