import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import ExpansionPanelWrapper from './ExpansionPanelWrapper';
import LabReportTabs from './LabReportTabs';

function LabReportWrapper(props) {
  const { encounterData, orderDetails, diagnosticReportInfo } = props;

  const renderTitle = () => (
    <Grid container direction="column">
      <Typography variant="button" color="primary" style={{ fontWeight: 700 }}>
        LAB REPORT
      </Typography>

      <Typography variant="subtitle1" color="primary">
        Results Interpretated: 5/6
      </Typography>

      <Typography variant="subtitle1" color="primary">
        Negative: 5
      </Typography>

      <Typography variant="subtitle1" color="primary">
        Pending Interpretation: 1
      </Typography>
    </Grid>
  );

  return (
    <ExpansionPanelWrapper renderTitle={renderTitle} name="labReport">
      <LabReportTabs
        encounterData={encounterData}
        orderDetails={orderDetails}
        diagnosticReportInfo={diagnosticReportInfo}
      />
    </ExpansionPanelWrapper>
  );
}

export default LabReportWrapper;
