import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState, useEffect, useMemo } from 'react';

// redux
import { useDispatch, useSelector } from 'react-redux';

import ProfileViewStyles from '../../assets/jss/components/ProfileViewStyles';
import { selectCurrentTenant } from '../Shared/selectors';
import AppKendoTableWrapper from '../Shared/Table/KendoTableWrapper';

import { selectUserDetails } from '../Users/selectors';
import { procedureColumns as columns, procedureTableButtons } from './helpers/clinicalInfoMapper';
import ProcedureDialog from './ProcedureDialog';

// mui
import { getProcedureList } from './slices/procedureList';
import { selectProcedureList, selectProcedureListLoading } from './slices/selectors';

function Procedure() {
  const classes = useStyles();

  const userDetails = useSelector(selectUserDetails);
  const currentTenant = useSelector(selectCurrentTenant);
  const procedureList = useSelector(selectProcedureList);
  const procedureListLoading = useSelector(selectProcedureListLoading);

  const [pagedProcedureList, setPagedProcedureList] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedProcedure, setSelectedProcedure] = useState({});
  const [dialogMode, setDialogMode] = useState('Add');

  const dispatch = useDispatch();

  const tableButtons = useMemo(() => procedureTableButtons(setDialogOpen), []);

  const handleRowClick = (e) => {
    setSelectedProcedure(e);
    setDialogMode('Edit');
    setDialogOpen(true);
  };
  const handleCloseDialog = () => {
    setSelectedProcedure({});
    setDialogMode('Add');
    setDialogOpen(false);
  };
  const handleTableUpdate = (page, pageSize) => {
    setPagedProcedureList(procedureList.data.slice((page + 1) * pageSize - pageSize, (page + 1) * pageSize));
  };
  useEffect(() => {
    dispatch(getProcedureList(userDetails.nationalHealthId));
  }, [currentTenant, userDetails]);
  useEffect(() => {
    if (procedureList) setPagedProcedureList(procedureList.data);
  }, [procedureList]);

  return (
    <Grid className={`${classes.contentWrapper} ${classes.withTableButtons}`}>
      <AppKendoTableWrapper
        data={{
          ...pagedProcedureList,
          items:
            pagedProcedureList &&
            pagedProcedureList.map((n) => ({
              ...n,
              procedure: n.procedureCode ? n.procedureCode.display : '',
              bodySite: n.bodySite ? n.bodySite.display : '',
              practiceName: '',
              note: n.notes,
              reason: n.reason ? n.reason.display : '',
            })),
          total: procedureList && procedureList.data.length,
        }}
        initialSort="onsetDate"
        loading={procedureListLoading}
        initialDir="desc"
        columns={columns()}
        onTableUpdate={handleTableUpdate}
        showButtons
        onRowClick={handleRowClick}
        tableButtons={tableButtons}
      />
      {dialogOpen && (
        <ProcedureDialog
          selectedProcedure={selectedProcedure}
          dialogMode={dialogMode}
          closeDialog={handleCloseDialog}
        />
      )}
    </Grid>
  );
}

const useStyles = makeStyles(ProfileViewStyles);

export default Procedure;
