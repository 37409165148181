import React, { useEffect } from 'react';

// components and helpers
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AppTabbedPageWrapper from '../Shared/Pages/TabbedPageWrapper';

import { selectCurrentTenant } from '../Shared/selectors';
import ActiveUserList from './ActiveUserList';
import { userTypes } from './helpers/mappings';
import InvitedUserList from './InvitedUserList';
import { selectTenantUserTotals, selectUsersLoading } from './selectors';
import { getUserTotals, setTenantUserTotals } from './usersSlice';

function UserPageWrapper(props) {
  const { userType } = props;
  const currentTenant = useSelector(selectCurrentTenant);
  const userTotals = useSelector(selectTenantUserTotals);
  const usersLoading = useSelector(selectUsersLoading);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setTenantUserTotals(null));
    dispatch(getUserTotals());
  }, [userType]);

  const tabs = [
    {
      label: `ACTIVE USERS ${userTotals?.activeTotal && !usersLoading ? `(${userTotals?.activeTotal})` : '(0)'}`,
      type: userTypes.ACTIVE,
      url: `users/${userTypes.ACTIVE}`,
      content: <ActiveUserList userType={userType} />,
    },
    {
      label: `INVITED USERS ${userTotals?.invitedTotal && !usersLoading ? `(${userTotals?.invitedTotal})` : '(0)'}`,
      type: userTypes.INVITED,
      url: `users/${userTypes.INVITED}`,
      content: <InvitedUserList userType={userType} />,
    },
  ];

  const handleTabChange = (tabDetails) => {
    navigate(`/${currentTenant.id}/${tabDetails.url}`);
  };

  if (!currentTenant) return <div />;

  return (
    <AppTabbedPageWrapper tabs={tabs} openTab={tabs.find((t) => t.type === userType)} onTabChange={handleTabChange} />
  );
}

export default UserPageWrapper;
