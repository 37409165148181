/* eslint-disable no-unused-expressions */
import moment from 'moment';

import { getFieldName } from './formHelper';

export const handleLabOrderBuild = (testList) =>
  testList.map((test) => {
    const flattenedTest = {
      laboratoryTestSource: test.test.laboratoryTestSource,
      orderableTestId: test.test.orderableTestId,
      price: test.test.price,
      panel: test.test.panel,
      testId: test.test.testId,
      vendorId: test.test.vendorId,
      vendorName: test.test.vendorName,
    };
    if (test.test.panel) {
      flattenedTest.autoSelectedOrderableTestList = test.autoSelectedOrderableTestList;
      flattenedTest.orderableTestPanelId = test.test.orderableTestPanelId;
    }
    return flattenedTest;
  });

export const handleOptionsListBuild = (formData) => {
  const options = [];
  if (formData.type === 'scale') {
    for (let i = formData.scaleMin; i <= formData.scaleMax; i++) {
      options.push(i);
    }
  } else if (formData.type === 'multiple' || formData.type === 'multiple-drop') {
    formData.options &&
      formData.options.forEach((o) => {
        options.push(o.option);
      });
  }

  return options;
};

// Assemble password complexity string for 'password' questions
export const handlePasswordComplexity = (formData) => {
  let complexity = '';
  if (formData.numbers) {
    complexity += '(?=.*\\d)';
  }
  if (formData.capitalLetters) {
    complexity += '(?=.*[A-Z])';
  }
  if (formData.characters) {
    complexity += '(?=.*[\\W])';
  }
  if (formData.minLength) {
    complexity += `(?=.{${formData.minLength},})`;
  }
  return complexity ? `^${complexity}` : '';
};

export const handleFormatJson = (formData, optionsList) => {
  let data;

  // Deconstruct selected languages in use
  let selectedLanguages;
  if (formData.languageChoices && formData.languageChoices.length) {
    selectedLanguages = formData.languageChoices.map((language) => language.split(' = ')[0]);
  }

  // Assemble password complexity string for 'password' questions
  if (formData.type === 'password') {
    formData.complexity = handlePasswordComplexity(formData);
  }

  // parse through saved formData to setup mc options with medical codes and translations
  if (formData.type === 'multiple' || formData.type === 'multiple-drop') {
    const multipleOptions = [];
    formData.options &&
      formData.options.forEach((o) => {
        const key = getFieldName(o.system);
        const optionObj = {
          value: translateObject(o, 'value', selectedLanguages),
          coding: {
            system: o.system,
            code: o.code,
            description: o.codeDescription && o.codeDescription[key],
          },
          score: o.score,
          allowComment: o.allowComment,
          enableInstructions: o.enableInstructions,
          instructionType: o.instructionType,
          instructionText: translateObject(o, 'instructionText', selectedLanguages),

          none: o.none,
          all: o.all,
        };
        multipleOptions.push(optionObj);
      });

    data = optionsQuestionJsonBuilder(formData, multipleOptions, selectedLanguages);
  } else if (formData.type === 'scale') {
    data = optionsQuestionJsonBuilder(formData, optionsList, selectedLanguages);
  } else if (formData.type === 'numeric' || formData.type === 'slider') {
    data = numericQuestionJsonBuilder(formData, selectedLanguages);
  } else if (formData.type === 'yes-no' && formData.autoAnswerOption === 'now-between-dates') {
    data = nowBetweenDatesQuestionJsonBuilder(formData, selectedLanguages);
  } else {
    data = simpleQuestionJsonBuilder(formData, selectedLanguages);
  }

  return data;
};

export const optionsQuestionJsonBuilder = (state, options, languages) => ({
  autoAnswerOption: !state.autoAnswerType
    ? null
    : {
        type: state.autoAnswerType,
      },
  id: state.id,
  introduction: state.introduction,
  name: translateObject(state, 'name', languages),
  description: translateObject(state, 'description', languages),
  help: translateObject(state, 'help', languages),
  image: state.image,
  shortName: state.shortName,
  note: state.note,
  commentEnabled: state.commentEnabled,
  options,
  type: state.type,
  status: state.status,
  tags: state.tags,
  multiSelect: state.multiSelect,
  containsNone: state.containsNone,
  containsAll: state.multiSelect && state.containsAll,
  selectAll: state.multiSelect && state.containsAll && state.selectAll,
  coding: {
    system: state.system,
    code: state.code,
    description: state.codeDescription && state.codeDescription[getFieldName(state.system)],
  },
  score: state.score,
  report: {
    target: state.reporterTarget,
    text: state.reporterFormat,
  },
});

export const numericQuestionJsonBuilder = (state, languages) => ({
  autoAnswerOption: !state.autoAnswerType
    ? null
    : {
        type: state.autoAnswerType,
        coding: {
          system: state.system,
          code: state.code,
          description: state.codeDescription && state.codeDescription[getFieldName(state.system)],
        },
      },
  id: state.id,
  introduction: state.introduction,
  max: state.max,
  min: state.min,
  step: state.step,
  name: translateObject(state, 'name', languages),
  description: translateObject(state, 'description', languages),
  help: translateObject(state, 'help', languages),
  shortName: state.shortName,
  image: state.image,
  note: state.note,
  commentEnabled: state.commentEnabled,
  type: state.type,
  status: state.status,
  tags: state.tags,
  coding: {
    system: state.system,
    code: state.code,
    description: state.codeDescription && state.codeDescription[getFieldName(state.system)],
  },
  report: {
    target: state.reporterTarget,
    text: state.reporterFormat,
  },
});

export const simpleQuestionJsonBuilder = (state, languages) => ({
  autoAnswerOption: !state.autoAnswerType
    ? null
    : {
        type: state.autoAnswerType,
        coding: {
          system: state.system,
          code: state.code,
          description: state.codeDescription && state.codeDescription[getFieldName(state.system)],
        },
      },
  id: state.id,
  introduction: state.introduction,
  name: translateObject(state, 'name', languages),
  description: translateObject(state, 'description', languages),
  help: translateObject(state, 'help', languages),
  shortName: state.shortName,
  image: state.image,
  note: state.note,
  commentEnabled: state.commentEnabled,
  type: state.type,
  status: state.status,
  tags: state.tags,
  validate: state.validate,
  // fields for date
  precision: state.precision,
  min: state.type === 'date' ? state.min * state.minBeforeAfter : state.min,
  max: state.type === 'date' ? state.max * state.maxBeforeAfter : state.max,
  // fields for small-text
  validationType: state.validationType,
  customValidation: state.customValidation,
  // fields for multiple-small-text
  fields: state.fields?.map((field) => ({
    id: field.id,
    name: translateObject(field, 'fieldName', languages),
  })),
  // fields for graphicSignature
  width: state.width,
  height: state.height,
  // complexity field for password
  complexity: state.complexity,
  // nodeList of subnodes for combo
  nodeList: state.nodeList,
  // fields for labOrder
  interactive: state.interactive,
  testList: state.testList ? handleLabOrderBuild(state.testList) : undefined,
  coding: {
    system: state.system,
    code: state.code,
    description: state.codeDescription && state.codeDescription[getFieldName(state.system)],
  },
  report: {
    target: state.reporterTarget,
    text: state.reporterFormat,
  },
});

export const nowBetweenDatesQuestionJsonBuilder = (state, languages) => ({
  autoAnswerOption: {
    type: state.autoAnswerType,
    start: moment(state.start).format('MM-DD'),
    end: moment(state.end).format('MM-DD'),
    coding: {
      system: state.system,
      code: state.code,
      description: state.codeDescription && state.codeDescription[getFieldName(state.system)],
    },
  },
  id: state.id,
  introduction: state.introduction,
  name: translateObject(state, 'name', languages),
  description: translateObject(state, 'description', languages),
  help: translateObject(state, 'help', languages),
  shortName: state.shortName,
  image: state.image,
  note: state.note,
  commentEnabled: state.commentEnabled,
  type: state.type,
  status: state.status,
  tags: state.tags,
  precision: state.precision,
  coding: {
    system: state.system,
    code: state.code,
    description: state.codeDescription && state.codeDescription[getFieldName(state.system)],
  },
  report: {
    target: state.reporterTarget,
    text: state.reporterFormat,
  },
});

// Take object from above Json builders and create Json objects to represent languages in use
const translateObject = (state, obj, languages) => {
  const translations = {};
  const validated = [];
  if (languages) {
    languages.forEach((lang) => {
      translations[lang] = state[`${obj}-${lang}`];
      if (state[`${obj}Valid-${lang}`]) validated.push(lang);
    });
  }

  // Assign default to English entry
  translations.eng = state[obj] || state[`${obj}-eng`];

  // Create object for return
  const translationsObject = {
    translations,
    validatedLanguages: validated,
  };
  return translationsObject;
};
