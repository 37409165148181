import { statusMapping, publishMapping } from '../../Shared/Content/mapping';
import * as htmlUtils from '../../Shared/Grid/helpers';
import utils from '../../Shared/Utils/utils';
import ActionsCellWrapper from '../ActionsCellWrapper';

export const transform = {
  articles: (list, selected) => ({
    ...list,
    items:
      list &&
      list.data &&
      list.data.map((article) => ({
        ...article,
        created: utils.formatDate(article.created),
        checked: selected && selected.includes(article._id),
      })),
  }),
  blogs: (list, selected) => ({
    ...list,
    items:
      list &&
      list.data &&
      list.data.map((blog) => ({
        ...blog,
        created: utils.formatDate(blog.created),
        checked: selected && selected.includes(blog._id),
      })),
  }),
  introWidgets: (list, selected) => ({
    ...list,
    items:
      list &&
      list.data &&
      list.data.map((widget) => ({
        ...widget,
        created: utils.formatDate(widget.created),
        checked: selected && selected.includes(widget._id),
      })),
  }),
  messageStandardizations: (list, selected) => ({
    ...list,
    items:
      list &&
      list.result &&
      list.result.map((widget) => ({
        ...widget,
        created: utils.formatDate(widget.created),
        checked: selected && selected.includes(widget._id),
      })),
    total: list && list.foundItemsCount,
    pageSize: list && list.pageSize,
  }),
  resources: (list, selected) => ({
    ...list,
    items:
      list &&
      list.data &&
      list.data.map((resource) => ({
        ...resource,
        created: utils.formatDate(resource.created),
        checked: selected && selected.includes(resource._id),
      })),
  }),
  videos: (list, selected) => ({
    ...list,
    items:
      list &&
      list.data &&
      list.data.map((video) => ({
        ...video,
        created: utils.formatDate(video.created),
        checked: selected && selected.includes(video._id),
      })),
  }),
};

export const initialSorting = {
  articles: {
    field: '_id',
    dir: 'asc',
  },
  blogs: {
    field: '_id',
    dir: 'asc',
  },
  introWidgets: {
    field: 'order',
    dir: 'asc',
  },
  messageStandardizations: {
    field: 'order',
    dir: 'asc',
  },
  resources: {
    field: '_id',
    dir: 'asc',
  },
  videos: {
    field: '_id',
    dir: 'asc',
  },
};

export const columns = (type) => {
  const contentTypes = {
    articles: [
      {
        id: 'imageURL',
        label: 'Image',
        show: true,
        minWidth: 130,
        sortable: false,
        cell: htmlUtils.withWidth(htmlUtils.imageCell, 200),
      },
      {
        id: 'title',
        label: 'Title',
        show: true,
        locked: true,
        minWidth: 170,
      },
      {
        id: 'externalLinkURL',
        label: 'External Link',
        show: false,
        sortable: false,
        minWidth: 150,
        cell: htmlUtils.linkCell,
      },
      {
        id: 'html',
        label: 'HTML',
        show: false,
        minWidth: 250,
        sortable: false,
        cell: htmlUtils.htmlCell,
      },
      {
        id: 'created',
        label: 'Date Created',
        show: true,
        minWidth: 150,
        sortable: true,
      },
      {
        id: 'owner',
        label: 'Owner',
        show: true,
        minWidth: 170,
      },
      {
        id: '_id',
        label: 'ID',
        show: false,
        minWidth: 250,
        sortable: true,
      },
      {
        id: 'publish',
        label: 'Status',
        show: true,
        minWidth: 125,
        sortable: true,
        cell: htmlUtils.withMapping(htmlUtils.tag, publishMapping),
      },
      {
        id: 'actions',
        label: 'Actions',
        show: true,
        minWidth: 125,
        sortable: false,
        cell: htmlUtils.withCustomMultiProp(ActionsCellWrapper, {
          type: 'articles',
        }),
      },
    ],
    blogs: [
      {
        id: 'imageURL',
        label: 'Image',
        show: true,
        minWidth: 130,
        locked: true,
        sortable: false,
        cell: htmlUtils.withWidth(htmlUtils.imageCell, 75),
      },
      {
        id: 'title',
        label: 'Title',
        show: true,
        minWidth: 170,
      },
      {
        id: 'html',
        label: 'Body Text',
        show: false,
        minWidth: 250,
        sortable: false,
        cell: htmlUtils.htmlCell,
      },
      {
        id: 'externalLinkURL',
        label: 'External Link',
        show: false,
        sortable: false,
        minWidth: 150,
        cell: htmlUtils.linkCell,
      },
      {
        id: 'created',
        label: 'Created',
        show: true,
        minWidth: 150,
        sortable: true,
      },
      {
        id: '_id',
        label: 'ID',
        show: false,
        minWidth: 250,
        sortable: true,
      },
      {
        id: 'status',
        label: 'Status',
        show: false,
        minWidth: 125,
        sortable: true,
        cell: htmlUtils.withMapping(htmlUtils.tag, statusMapping),
      },
    ],
    introWidgets: [
      {
        id: 'order',
        label: 'Order Number',
        show: true,
        minWidth: 130,
        locked: false,
        sortable: true,
      },
      {
        id: 'imageURL',
        label: 'Image',
        show: true,
        minWidth: 130,
        locked: true,
        sortable: false,
        cell: htmlUtils.withWidth(htmlUtils.imageCell, 75),
      },
      {
        id: 'title',
        label: 'Title',
        show: true,
        minWidth: 170,
        cell: htmlUtils.titleCell,
      },
      {
        id: 'type',
        label: 'Type',
        show: true,
        minWidth: 170,
      },
      {
        id: 'html',
        label: 'Body Text',
        show: false,
        minWidth: 250,
        sortable: false,
        cell: htmlUtils.htmlCell,
      },
      {
        id: 'externalLinkURL',
        label: 'External Link',
        show: false,
        sortable: false,
        minWidth: 150,
        cell: htmlUtils.linkCell,
      },
      {
        id: 'created',
        label: 'Created',
        show: true,
        minWidth: 150,
        sortable: true,
      },
      {
        id: '_id',
        label: 'ID',
        show: false,
        minWidth: 250,
        sortable: true,
      },
      {
        id: 'status',
        label: 'Status',
        show: false,
        minWidth: 125,
        sortable: true,
        cell: htmlUtils.withMapping(htmlUtils.tag, statusMapping),
      },
      {
        id: 'actions',
        label: 'Actions',
        show: true,
        minWidth: 125,
        sortable: false,
        cell: htmlUtils.withCustomMultiProp(ActionsCellWrapper, {
          type: 'introWidgets',
        }),
      },
    ],
    messageStandardizations: [
      {
        id: 'imageUrl',
        label: 'Image',
        show: true,
        minWidth: 130,
        locked: true,
        sortable: false,
        cell: htmlUtils.withWidth(htmlUtils.imageCell, 75),
      },
      {
        id: 'title',
        label: 'Title',
        show: true,
        minWidth: 170,
      },
      {
        id: 'html',
        label: 'Body Text',
        show: false,
        minWidth: 250,
        sortable: false,
        cell: htmlUtils.htmlCell,
      },
      {
        id: 'externalLinkURL',
        label: 'External Link',
        show: false,
        sortable: false,
        minWidth: 150,
        cell: htmlUtils.linkCell,
      },
      {
        id: 'created',
        label: 'Created',
        show: true,
        minWidth: 150,
        sortable: true,
      },
      {
        id: '_id',
        label: 'ID',
        show: false,
        minWidth: 250,
        sortable: true,
      },
      {
        id: 'status',
        label: 'Status',
        show: false,
        minWidth: 125,
        sortable: true,
        cell: htmlUtils.withMapping(htmlUtils.tag, statusMapping),
      },
      {
        id: 'actions',
        label: 'Actions',
        show: true,
        minWidth: 125,
        sortable: false,
        cell: htmlUtils.withCustomMultiProp(ActionsCellWrapper, {
          type: 'messageStandardizations',
        }),
      },
    ],
    resources: [
      {
        id: 'imageURL',
        label: 'Image',
        show: true,
        minWidth: 130,
        locked: true,
        sortable: false,
        cell: htmlUtils.withWidth(htmlUtils.imageCell, 75),
      },
      {
        id: 'title',
        label: 'Title',
        show: true,
        minWidth: 170,
      },
      {
        id: 'externalLinkURL',
        label: 'External Link',
        show: true,
        sortable: false,
        minWidth: 150,
        cell: htmlUtils.linkCell,
      },
      {
        id: 'html',
        label: 'HTML',
        show: true,
        minWidth: 250,
        sortable: false,
        cell: htmlUtils.htmlCell,
      },
      {
        id: 'created',
        label: 'Created',
        show: true,
        minWidth: 150,
        sortable: true,
      },
      {
        id: '_id',
        label: 'ID',
        show: false,
        minWidth: 250,
        sortable: true,
      },
      {
        id: 'status',
        label: 'Status',
        show: true,
        minWidth: 125,
        sortable: true,
        cell: htmlUtils.withMapping(htmlUtils.tag, statusMapping),
      },
    ],
    videos: [
      {
        id: 'thumbnailURL',
        label: 'Thumbnail',
        show: true,
        locked: true,
        minWidth: 150,
        sortable: false,
        cell: htmlUtils.withWidth(htmlUtils.imageCell, 200),
      },
      {
        id: 'title',
        label: 'Title',
        show: true,
        minWidth: 170,
      },
      {
        id: 'created',
        label: 'Date Created',
        show: true,
        minWidth: 170,
      },
      {
        id: 'owner',
        label: 'Owner',
        show: true,
        minWidth: 170,
      },
      {
        id: 'publish',
        label: 'Status',
        show: true,
        minWidth: 170,
        cell: htmlUtils.withMapping(htmlUtils.tag, publishMapping),
      },
      {
        id: 'actions',
        label: 'Actions',
        show: true,
        minWidth: 125,
        sortable: false,
        cell: htmlUtils.withCustomMultiProp(ActionsCellWrapper, {
          type: 'videos',
        }),
      },
      {
        id: 'size',
        label: 'Size',
        show: false,
        width: 200,
      },
      {
        id: 'mediaURL',
        label: 'Media URL',
        show: false,
        minWidth: 270,
        sortable: false,
        cell: htmlUtils.withWidth(htmlUtils.videoCell, 250),
      },
      {
        id: '_id',
        label: 'ID',
        show: false,
        minWidth: 250,
        sortable: true,
      },
      {
        id: 'status',
        label: 'Status',
        show: false,
        minWidth: 125,
        sortable: true,
        cell: htmlUtils.withMapping(htmlUtils.tag, statusMapping),
      },
    ],
  };

  return contentTypes[type];
};

export const tableButtons = {
  articles: (tenant) => [
    {
      title: 'New Article',
      buttonType: 'link',
      link: `/${tenant && tenant.id}/content/articles/create`,
      variant: 'contained',
    },
  ],
  blogs: (tenant) => [
    {
      title: 'New Blog',
      buttonType: 'link',
      link: `/${tenant && tenant.id}/content/blogs/create`,
      variant: 'contained',
    },
  ],
  introWidgets: (tenant) => [
    {
      title: 'New Intro Widget',
      buttonType: 'link',
      link: `/${tenant && tenant.id}/settings/configuration/assets/intro-widgets/create`,
      variant: 'contained',
    },
  ],
  messageStandardizations: (tenant) => [
    {
      title: 'New Message Standarization',
      buttonType: 'link',
      link: `/${tenant && tenant.id}/settings/message-standardization/create`,
      variant: 'contained',
    },
  ],
  resources: (tenant) => [
    {
      title: 'New Resource',
      buttonType: 'link',
      link: `/${tenant && tenant.id}/content/resources/create`,
      variant: 'contained',
    },
  ],
  videos: (tenant) => [
    {
      title: 'Add New Videos',
      buttonType: 'link',
      link: `/${tenant && tenant.id}/content/videos/create`,
      variant: 'contained',
    },
  ],
};
