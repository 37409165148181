import * as htmlUtils from '../../Shared/Grid/helpers';
// import { withCustomMultiProp } from '../../Shared/Grid/helpers';
import LinkCell from '../LinkCell';
// import MemberPhotoCell from '../MemberPhotoCell';
import SendEmailCell from '../SendEmailCell';

import { memberStatesLabels, encounterStatuses, encounterLabStatus } from './mapping';

export const initialSorting = {
  field: 'name',
  dir: 'DESC',
};

export const columns = (data) => [
  {
    id: 'id',
    label: 'ID #',
    show: true,
    minWidth: 120,
    sortable: true,
    cell: htmlUtils.withCustomMultiProp(LinkCell, {
      propLabelName: 'id',
      redirectLink: 'assessmentLink',
    }),
  },
  // {
  //   id: "photo",
  //   label: "Photo",
  //   show: true,
  //   minWidth: 100,
  //   sortable: false,
  //   cell: MemberPhotoCell,
  // },
  {
    id: 'memberName',
    label: 'Patient Name',
    show: true,
    sortable: false,
    minWidth: 130,
    cell: htmlUtils.withCustomMultiProp(LinkCell, {
      propLabelName: 'memberName',
      // redirectLink: "assessmentLink",
    }),
  },
  {
    id: 'encounterType',
    label: 'Encounter Type',
    show: true,
    minWidth: 130,
    sortable: false,
    // filter: true,
  },
  {
    id: 'created',
    label: 'Date',
    show: true,
    minWidth: 100,
    sortable: true,
  },
  {
    id: 'assigneeName',
    label: 'Assignee',
    show: true,
    minWidth: 190,
    filter: true,
    multiSelectFilter: true,
    labelField: 'label',
    options:
      data?.reduce((providerList, cur) => {
        if (!providerList.some((provider) => provider.value === cur.assigneeName)) {
          const entry = {
            label: cur.assigneeName,
            value: cur.assigneeName,
          };
          providerList.push(entry);
        }
        return providerList;
      }, []) || [],
    cell: htmlUtils.withCustomMultiProp(LinkCell, {
      propLabelName: 'assigneeName',
      // redirectLink: "assessmentLink",
    }),
    sortable: false,
  },
  // {
  //   id: "state",
  //   label: "State",
  //   show: true,
  //   minWidth: 90,
  //   sortable: false,
  //   filter: true,
  //   multiSelectFilter: true,
  //   labelField: "label",
  //   options: USAStates.map((item) => ({
  //     id: item.value,
  //     label: item.label,
  //   })),
  // },
  {
    id: 'labStatus',
    label: 'Lab Status',
    show: true,
    minWidth: 120,
    sortable: false,
    filter: true,
    multiSelectFilter: true,
    labelField: 'label',
    options: Object.keys(encounterLabStatus).map((s) => ({
      id: s,
      label: encounterLabStatus[s],
    })),
    // selectedByDefault: ["NOT_ASSIGNED"],
  },
  {
    id: 'status',
    label: 'Encounter Status',
    show: true,
    minWidth: 120,
    cell: htmlUtils.withMapping(htmlUtils.tag, memberStatesLabels),
    sortable: false,
    filter: true,
    multiSelectFilter: true,
    labelField: 'label',
    options: Object.keys(encounterStatuses).map((s) => ({
      id: s,
      label: memberStatesLabels[s].label,
    })),
    // selectedByDefault: ["NOT_ASSIGNED"],
  },
  {
    id: 'notificationSent',
    label: 'Last Notification',
    show: true,
    minWidth: 100,
    sortable: false,
    cell: htmlUtils.withCustomMultiProp(LinkCell, {
      propLabelName: 'notificationSent',
      // redirectLink: "assessmentLink",
    }),
  },
  {
    id: 'email',
    label: 'Email',
    show: true,
    minWidth: 80,
    sortable: false,
    cell: htmlUtils.withCustomProp(SendEmailCell),
  },
];
