/* eslint-disable import/namespace */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
import Grid from '@material-ui/core/Grid';
import React, { useState, useEffect, useMemo, useCallback } from 'react';
// redux
import { useDispatch, useSelector } from 'react-redux';
import AppKendoTableWrapper from '../../Shared/Table/KendoTableWrapper';
import utils from '../../Shared/Utils/utils';
import AddPermissionDialog from './AddPermissionDialog';
import { initialKendoState } from './helpers/mapping';
import { columns, permissionButtons } from './helpers/permissionListMapper';
import { getList, setPermission, setPermissionPaginationState } from './permissionSlice';
import { selectPermissionsLoading, selectPermissionsList } from './selectors';

function PermissionListWrapper() {
  const loading = useSelector(selectPermissionsLoading);
  const permissions = useSelector(selectPermissionsList);
  const [kendoPaginationState, setKendoPaginationState] = useState(initialKendoState);
  const [dialogOpen, setDialogOpen] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPermissionPaginationState(kendoPaginationState));
  }, [kendoPaginationState]);

  // handles all table updates from table wrapper
  const handleTableUpdate = (page, pageSize, sort, direction) => {
    setKendoPaginationState({
      sort: [{ field: sort, dir: direction }],
      take: pageSize,
      skip: page,
    });
    dispatch(getList(page, pageSize, sort, direction.toUpperCase()));
  };

  const buttons = useMemo(() => permissionButtons(setDialogOpen), []);

  useEffect(() => {
    dispatch(
      getList(
        kendoPaginationState?.skip,
        kendoPaginationState?.take,
        kendoPaginationState?.sort[0]?.field,
        kendoPaginationState.sort[0]?.dir?.toUpperCase()
      )
    );
  }, []);

  const handleNewPermission = useCallback((userId) => {
    dispatch(setPermission(userId));
    setDialogOpen(false);
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <AppKendoTableWrapper
          data={{
            ...permissions,
            items:
              permissions &&
              permissions.items &&
              permissions.items.map((n) => ({
                ...n,
                modified: utils.formatDateTime(n.modified),
              })),
          }}
          showButtons
          initialSort={kendoPaginationState?.sort[0]?.field}
          initialDir={kendoPaginationState.sort[0]?.dir}
          columns={columns}
          loading={loading}
          onTableUpdate={handleTableUpdate}
          tableButtons={buttons}
        />
      </Grid>
      {dialogOpen && <AddPermissionDialog closeDialog={() => setDialogOpen(false)} onSubmit={handleNewPermission} />}
    </Grid>
  );
}

export default PermissionListWrapper;
