/* eslint-disable jsx-a11y/no-static-element-interactions */
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Surface, Path, geometry, drawDOM, exportImage } from '@progress/kendo-drawing';
import React, { useEffect, useState, useRef } from 'react';

import styles from '../../assets/Questionnaire.module.css';
import utils from '../utils/utils';

const { Point } = geometry;

function GraphicSignature(props) {
  const { field, handleChangeWithParams, selectedLanguage } = props;
  const surfaceElement = useRef();
  const [surface, setSurface] = useState();
  const [path, setPath] = useState();

  useEffect(() => {
    if (surface) surface.destroy();
    const surfaceOptions = {
      height: `${field.height}%`,
      width: '100%',
    };
    const newSurface = Surface.create(surfaceElement.current, surfaceOptions);
    setSurface(newSurface);
    return () => {
      if (surface) surface.destroy();
    };
  }, [field.height]);

  // Exports the drawn signature to a file and saves it to S3 bucket
  const handleExport = async () => {
    // Convert the DOM element to a drawing using kendo.drawing.drawDOM
    const group = await drawDOM(surfaceElement.current);
    const drawingData = await exportImage(group);
    const blob = await (await fetch(drawingData)).blob();
    return blob;
  };

  const handleMouseDown = (evt) => {
    const newPath = new Path({
      stroke: {
        color: '#E4141B',
        width: 2,
        lineCap: 'round',
        lineJoin: 'round',
      },
    });

    const offset = {
      top: surfaceElement.current.offsetTop,
      left: surfaceElement.current.offsetLeft,
    };

    const newPoint = new Point(evt.pageX - offset.left, evt.pageY - offset.top);

    for (let i = 0; i < 1; i++) {
      newPath.lineTo(newPoint.clone().translate(i * 1, 0));
    }

    surface.draw(newPath);
    setPath(newPath);
  };

  const handleMouseMove = (evt) => {
    const currentPath = path;
    if (!path) {
      return;
    }

    const offset = {
      top: surfaceElement.current.offsetTop,
      left: surfaceElement.current.offsetLeft,
    };

    const newPoint = new Point(evt.pageX - offset.left, evt.pageY - offset.top);

    currentPath.lineTo(newPoint);
    setPath(currentPath);
  };

  const handleMouseUp = async () => {
    setPath();
    const drawing = await handleExport();
    handleChangeWithParams(field.name, drawing);
  };

  const handleClear = () => {
    surface.clear();
    handleChangeWithParams(field.name, null);
  };

  const fieldTitle =
    selectedLanguage && field.name && field.name.translations
      ? field.name.translations[selectedLanguage]
      : field.label || field.name;
  const questionDescription =
    selectedLanguage && field.description && field.description.translations
      ? field.description.translations[selectedLanguage]
      : field.description;

  return (
    <>
      {fieldTitle && (
        <Typography className={styles.titleStyles}>
          {field.questionPreview ? utils.renderHTMLTags(fieldTitle) : fieldTitle}
          {field.required && '*'}
        </Typography>
      )}
      {field.image && (
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <img
            src={field.image}
            alt={fieldTitle}
            style={{
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
              width: '90%',
            }}
          />
        </Grid>
      )}
      {questionDescription && (
        <Typography className={styles.descriptionStyles}>{utils.renderHTMLTags(questionDescription)}</Typography>
      )}
      <Grid
        container
        direction="column"
        justifyContent="center"
        style={{
          width: `${field.width}%`,
          height: '240px',
          margin: '1rem auto',
        }}
      >
        <Grid item className={styles.signatureCanvas}>
          <div
            ref={(el) => {
              surfaceElement.current = el;
            }}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            style={{ border: '1px solid black' }}
          />
        </Grid>
        <Grid item className={styles.signatureButtonField}>
          <Button className={styles.buttonClear} onClick={handleClear}>
            Clear
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

export default GraphicSignature;
