/* eslint-disable import/no-cycle */
import { createStore, applyMiddleware } from 'redux';
import createDebounce from 'redux-debounced';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import rootReducer from '../index';

const defaultState = {};

export const defaultStore = createStore(
  // config for AsyncStorage of redux store
  rootReducer,
  defaultState,
  composeWithDevTools(applyMiddleware(createDebounce(), thunk))
);
