import React, { useCallback, useEffect, useState } from 'react';

// redux
import { useDispatch, useSelector } from 'react-redux';

import { selectCurrentTenant } from '../Shared/selectors';
import AppKendoTableWrapper from '../Shared/Table/KendoTableWrapper';

import { columns, initialSorting, tableButtons, transform } from './helpers/reportingListMapper';
import { createReport, getReports } from './reportsSlice';
import { selectReports, selectReportsLoading } from './selectors';

// components and helpers

function ReportingListWrapper() {
  const currentTenant = useSelector(selectCurrentTenant);
  const reports = useSelector(selectReports);
  const reportsLoading = useSelector(selectReportsLoading);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [sort, setSort] = useState(initialSorting.field);
  const [direction, setDirection] = useState(initialSorting.dir);

  const dispatch = useDispatch();

  // REQUEST NEW REPORT GENERATION
  const handleGetReports = useCallback(
    (noLoad, refresh) => {
      dispatch(getReports(currentTenant.id, page, pageSize, '', sort, direction.toUpperCase(), null, noLoad, refresh));
    },
    [currentTenant, page, pageSize, sort, direction]
  );

  // Initial Load
  useEffect(() => {
    if (currentTenant) {
      handleGetReports();
    }
  }, [currentTenant, handleGetReports]);

  // handles all table updates from table wrapper
  const handleTableUpdate = (page, pageSize, sort, direction, searchValue, filter) => {
    dispatch(
      getReports(
        currentTenant.id,
        page,
        pageSize,
        searchValue,
        sort,
        direction.toUpperCase(),
        filter && filter.permission
      )
    );
    setPage(page);
    setPageSize(pageSize);
    setSort(sort);
    setDirection(direction);
  };

  // REFRESH TABLE TO FETCH LATEST
  const handleRefreshTable = useCallback(() => {
    handleGetReports(true, true);
  }, [handleGetReports]);

  // REQUEST NEW REPORT GENERATION
  const handleRequestReport = useCallback(
    (reportType) => {
      // Hardcoding report type as only one report type at this time. Will add dialog with selection when multiple reports available.
      dispatch(createReport(currentTenant.id, reportType, page, pageSize, '', sort, direction.toUpperCase(), null));
    },
    [currentTenant, page, pageSize, sort, direction]
  );

  const buttons = tableButtons(
    handleRefreshTable,
    () => handleRequestReport('ASSESSMENT_REPORT'),
    () => handleRequestReport('SCAN_REPORT'),
    () => handleRequestReport('USER_REPORT'),
    () => handleRequestReport('CRO_REPORT'),
    () => handleRequestReport('LABTEST_REPORT'),
    () => handleRequestReport('ACTIVE_USER_REPORT'),
    () => handleRequestReport('LOGIN_REPORT')
  );

  const data = {
    total: (reports && reports.foundItemsCount) || 0,
    pageSize: (reports && reports.pageSize) || 0,
    items: (reports && reports.result) || [],
  };

  return (
    <div>
      <AppKendoTableWrapper
        data={transform(data)}
        initialSort={sort}
        initialDir={direction}
        showButtons
        showSearchbar={false}
        tableButtons={buttons}
        columns={columns}
        currentTenant={currentTenant}
        loading={reportsLoading}
        onTableUpdate={handleTableUpdate}
      />
    </div>
  );
}

export default ReportingListWrapper;
