import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import { NumericTextBox } from '@progress/kendo-react-inputs';
import { Error } from '@progress/kendo-react-labels';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectFormData } from '../selectors';

// ui reflection of file view
function NumberFieldInput(props) {
  const { field, value: initialValue, handleChange, disabled, selectedLanguage } = props;
  const formData = useSelector(selectFormData);

  const [currentValue, setCurrentValue] = useState(Number(initialValue) || initialValue === 0 ? Number(initialValue) : field.defaultValue);
  const fieldDisabled = disabled || field.readOnly || (field.disableIfFalse && !formData[field.disableIfFalse]);

  const classes = useStyles();

  useEffect(() => {
    if (initialValue && initialValue !== currentValue) {
      setCurrentValue(initialValue);
    }
    // eslint-disable-next-line
  }, [initialValue]);

  const onChange = (e) => {
    setCurrentValue(e.value);
    const event = {
      target: {
        value: e.value,
        name: e.target.name,
      },
    };
    if (handleChange) handleChange(event);
  };

  const numberFormat = (fieldName) => {
    switch (fieldName) {
      case 'price':
      case 'reimbursment': {
        return 'n2';
      }
      case 'last4ssn':
        console.log('here')
        return '0000'
      case 'custom': {
        return '#';
      }
      default:
        return undefined;
    }
  };

  const fieldLabel =
    field.shortName && selectedLanguage && field.name && field.name.translations
      ? field.name.translations[selectedLanguage]
      : field.shortName && (field.label || field.name);

  const questionDescription =
    selectedLanguage && field.description && field.description.translations
      ? field.description.translations[selectedLanguage]
      : field.description;

  const getNumericTextLabel = () => {
    if (field.shortName) return field.shortName;
    if (selectedLanguage && field.name && field.name.translations) return field.name.translations[selectedLanguage];
    return field.label || field.name;
  };

  return (
    <div>
      <div className={`${field.variant || ''} ${field.title ? classes.labelOutside : ''}`}>
        {field?.title && field.titlePlacement === 'start' ? (
          <Typography
            variant="body2"
            style={{
              marginRight: 10,
              paddingTop: 17,
            }}
          >
            {field.title}
          </Typography>
        ) : null}
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label style={{ width: !field?.title ? '100%' : '' }}>
        <NumericTextBox
          width={!field.title ? '100%' : ''}
          value={currentValue}
          onChange={onChange}
          required={field.required}
          label={getNumericTextLabel()}
          format={numberFormat(field.numFormat || field.name)}
          spinners={field.spinners} // display the up and down arrow
          name={
            selectedLanguage && field.name && field.name.translations
              ? field.name.translations[selectedLanguage]
              : field.name
          }
          min={typeof field.min === 'number' ? field.min : undefined}
          max={typeof field.max === 'number' ? field.max : undefined}
          step={parseFloat(field.step) || 1}
          style={{ width: '100%' }}
          disabled={fieldDisabled}
          placeholder={field.placeholder}
        />
        </label>
        {field.unit && (
          <div style={{alignSelf: 'flex-end', marginLeft: 5}}>{field.unit}</div>
        )}
      </div>
      {field.helper && (
        <Typography variant="caption" color="textSecondary">
          {field.helper}
        </Typography>
      )}
      {typeof field.min === 'number' && (
        <div>
          {currentValue < field.min && (
            <Error id="minNumberError">
              Number must be equal or higher than {field.min}
            </Error>
          )}
        </div>
      )}
      {typeof field.max === 'number' && (
        <div>
          {currentValue > field.max && (
            <Error id="maxNumberError">
              Number must be equal or lower than {field.max}
            </Error>
          )}
        </div>
      )}
      {field.disabledItems &&
        !!field.disabledItems.length &&
        field.disabledItems.some((item) => item === currentValue) && (
          <Error id="maxNumberError">
            This priority is already used (The right questionnaire outcome is not guaranteed)
          </Error>
        )}
    </div>
  );
}

const useStyles = makeStyles({
  labelOutside: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: '20%',
  },
});

export default NumberFieldInput;
