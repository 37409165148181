/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import React, { useState, useCallback, useEffect } from 'react';

import { createInputHelper } from '../Shared/Forms/helpers/createInputHelper';

import { conditionFormMap } from './helpers/formHelper';

function ConditionDialog(props) {
  const { closeDialog, selectedCondition, dialogMode, updateCondition } = props;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  const condition = {
    id: '',
    conditionCode: '',
    conditionSystem: 'http://hl7.org/fhir/sid/icd-10-cm',
    categoryName: 'problem-list-item',
    categoryCode: 'problem-list-item',
    categorysystem: 'http://argonaut.hl7.org',
    notes: [
      {
        message: '',
        sender: '',
        date: '',
      },
    ],
    createdBy: '',
    createdAt: '',
    onsetDate: '',
    resolutionDate: '',
    severity: '',
    bodySiteName: '',
    bodySiteCode: '',
    bodySiteSystem: 'http://hl7.org/fhir/sid/icd-10-cm',
    operationType: 'Add',
    rcopiaId: '',
  };
  const [errors, setErrors] = useState(
    dialogMode === 'Edit'
      ? {
          conditionName: false,
          conditionCode: false,
          verificationStatus: false,
          clinicalStatus: false,
        }
      : {
          conditionName: true,
          conditionCode: true,
          verificationStatus: true,
          clinicalStatus: true,
        }
  );
  const [conditionData, setConditionData] = useState(dialogMode === 'Edit' ? selectedCondition : condition);

  const [formValid, setFormValid] = useState(false);
  const [resolutionDate, setResolutionDate] = useState(
    !(
      selectedCondition &&
      (selectedCondition?.clinicalStatus == 'inactive' || selectedCondition?.clinicalStatus == 'remission')
    )
  );
  const [form, setForm] = useState(conditionFormMap(dialogMode, resolutionDate));

  // Be sure that all required fields are filled
  useEffect(() => {
    setFormValid(conditionData.conditionName && conditionData.conditionCode && conditionData.verificationStatus);
  }, [conditionData]);

  // const [newConditionData, setNewConditionData] = useState(condition);
  const handleCancel = () => {
    closeDialog();
  };

  const updateData = useCallback(
    (name, value) => {
      const localErrors = errors;
      const localConditionData = conditionData;
      switch (name) {
        case 'conditionName':
        case 'icdCodeName':
          localErrors.conditionName = value.length <= 0;
          localConditionData.conditionName = value;
          break;
        case 'conditionCode':
          localErrors.conditionCode = value.length <= 0;
          localConditionData.conditionCode = value;
          break;
        case 'bodySiteName':
          localConditionData.bodySiteName = value;
          break;
        case 'severity':
          localConditionData.severity = value;
          break;
        case 'verificationStatus':
          localErrors.verificationStatus = value.length <= 0;
          localConditionData.verificationStatus = value;
          break;
        case 'clinicalStatus':
          localErrors.clinicalStatus = value.length <= 0;
          localConditionData.clinicalStatus = value;
          break;
        case 'onsetDate':
          localConditionData.onsetDate = value;
          break;
        case 'dateRecorded':
          localConditionData.dateRecorded = value;
          break;
        case 'resolutionDate':
          localConditionData.resolutionDate = value;
          break;
        default:
          break;
      }
      setConditionData((prevState) => ({
        ...prevState,
        ...localConditionData,
      }));
      setErrors((prevState) => ({ ...prevState, ...localErrors }));
    },
    [conditionData, errors]
  );

  const submit = () => {
    const localConditionData = conditionData;

    for (const key in errors) {
      if (errors[key] !== null && errors[key] !== false) return;
    }
    if (dialogMode === 'Edit') {
      localConditionData.operationType = 'Edit';
    } else if (dialogMode === 'Add') {
      localConditionData.operationType = 'Add';
    }
    setConditionData((prevState) => ({ ...prevState, ...localConditionData }));
    updateCondition(localConditionData);
    closeDialog();
  };

  const handleAutoSetValue = useCallback(
    (values) => {
      for (const key in values) {
        updateData(key, values[key]);
      }
    },
    [updateData]
  );

  const handleChangeWithParams = useCallback(
    (name, value) => {
      if (name === 'conditionName') {
        if (value) updateData(name, value.longDescription);
        else updateData(name, '');
      } else if (name == 'clinicalStatus') {
        if (value == 'Inactive' || value == 'Remission') {
          setResolutionDate(false);
        } else {
          setResolutionDate(true);
          updateData('resolutionDate', null);
        }

        updateData(name, value);
      } else {
        updateData(name, value);
      }
    },
    [updateData]
  );

  const handleChangeTypahead = useCallback(
    (event) => {
      const { name } = event.target;
      const { value } = event.target;
      updateData(name, value);
    },
    [updateData]
  );

  useEffect(() => {
    const tempForm = form;

    tempForm.forEach((element) => {
      if (element.name == 'resolutionDate') {
        element.disabled = resolutionDate;
        setForm(JSON.parse(JSON.stringify(tempForm)));
      }
    });
  }, [resolutionDate]);

  return (
    <Dialog
      title={`${dialogMode} Condition/Diagnosis`}
      onClose={handleCancel}
      width={matches ? 800 : null}
      height={matches ? null : '100%'}
      style={{ zIndex: 101 }}
    >
      <Grid container spacing={2}>
        {createInputHelper(form, conditionData, handleChangeTypahead, handleChangeWithParams, handleAutoSetValue)}
      </Grid>
      <DialogActionsBar>
        <button className="k-button" onClick={handleCancel} style={{ margin: 5 }}>
          Cancel
        </button>
        <button className="k-button" onClick={submit} disabled={!formValid} style={{ margin: 5 }}>
          {dialogMode === 'Add' ? 'Add' : 'Edit'}
        </button>
      </DialogActionsBar>
    </Dialog>
  );
}

export default ConditionDialog;
