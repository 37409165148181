import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import { EditorUtils } from '@progress/kendo-react-editor';
import React, { useState, useRef } from 'react';
import DragExpansionWrapperStyles from '../../../assets/jss/components/DragExpansionWrapperStyles';
import { UserSegmentPanelDetails } from '../../VirtualConsult/UserSegment/UserSegmentPanelDetails';

function SettingsExpansionPanel(props) {
  const {
    classes,
    panelData,
    data,
    title,
    onHandleEditing,
    onHandleSave,
    onHandleDelete,
    onHandleCancel,
    expandedPanel,
    handleExpansionChange,
    editing,
    editingType,
    identifier,
    type,
    matches,
    onHandleMoveDown,
    onHandleMoveUp,
    submitting,
    deleting,
    availableDefinitions,
    allDefsPairs,
    onHandlePreview,
    images,
    panelPreview,
    panelEdit,
    panelDelete,
    draggable,
    panelChips,
    allOutcomes,
  } = props;
  const [titleUpdate, setTitleUpdate] = useState('');
  const [outcomeImage] = useState(images ? images[title] : null);
  const editor = useRef(null);
  const disableSave = editingType === 'new' && (!titleUpdate || !EditorUtils.getHtml(editor.current.view.state));

  // updates question text on change of textfield in edit mode
  const handleTitleUpdate = (e) => {
    const { value } = e.target;
    setTitleUpdate(value);
  };

  // handles save of any changes to question/answer
  const handleSave = () => {
    let updatedData = data;
    const newEditorContent = EditorUtils.getHtml(editor.current.view.state);
    if (type === 'faq') {
      updatedData[editing - 1].question = titleUpdate;
      updatedData[editing - 1].answer = newEditorContent;
    } else if (type === 'verbiage') {
      updatedData[identifier].value = newEditorContent;
      if (editingType === 'new') {
        updatedData[identifier].title = titleUpdate;
        updatedData[identifier].key = allDefsPairs[titleUpdate];
      }
    } else if (type === 'outcome') {
      updatedData = {
        outcomesText: data,
        outcomesImages: images,
      };
      updatedData.outcomesText = {
        ...updatedData.outcomesText,
        [title]: newEditorContent,
      };
      updatedData.outcomesImages = {
        ...updatedData.outcomesImages,
        [title]: outcomeImage,
      };
    }

    setTitleUpdate('');
    onHandleSave(updatedData);
    onHandleEditing('', '', false);
  };

  // handles preview of any changes to verbiage
  const handlePreview = () => {
    let previewData;
    if (editing) {
      previewData = {
        key: editingType === 'new' ? allDefsPairs[titleUpdate] : data[identifier].key,
        title: editingType === 'new' ? titleUpdate : data[identifier].title,
        value: EditorUtils.getHtml(editor.current.view.state),
      };
    } else {
      previewData = {
        key: data[identifier].key,
        title: data[identifier].title,
        value: data[identifier].value,
      };
    }
    onHandlePreview(previewData);
  };

  // on cancel, do not save any changes made and exit edit mode. If new FAQ, remove object from list.
  const handleCancel = () => {
    if (editingType === 'new') {
      const updatedData = data.slice(0, -1);
      onHandleCancel(updatedData);
    }
    setTitleUpdate('');
    onHandleEditing('', '', false);
  };

  // on click of 'edit' button, get HTML from faq and convert into draft to display in wysiwyg, go into edit mode within expansion panel
  const handleEditing = () => {
    setTitleUpdate(title);
    onHandleEditing(identifier, 'update', true);
  };

  const getEditingPanelButton = () => {
    if (editing === identifier)
      return (
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            className={classes.button}
            disabled={disableSave || submitting || deleting}
          >
            {submitting && <CircularProgress size={10} style={{ marginRight: 10 }} />}
            Save Changes
          </Button>
          <Button variant="outlined" color="primary" onClick={handleCancel} className={classes.button}>
            Cancel
          </Button>
        </Grid>
      );
    if (submitting || deleting)
      return (
        <Grid item>
          <CircularProgress size={10} style={{ marginRight: 10 }} />
        </Grid>
      );
    return (
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleEditing()}
          className={classes.button}
          disabled={submitting || deleting}
        >
          Edit
        </Button>
      </Grid>
    );
  };

  return (
    <Accordion
      style={{ marginBottom: '5px' }}
      elevation={0}
      expanded={expandedPanel === `panel${identifier}` || editing === identifier}
      onChange={editing !== identifier && handleExpansionChange(`panel${identifier}`)}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel${identifier}bh-content`}
        id={`panel${identifier}bh-header`}
        data-testid="AccordionSummary"
      >
        <Grid container justifyContent="space-between">
          <Grid item>
            {editing === identifier && type === 'faq' ? (
              <TextField
                label="Question"
                placeholder="Full question to be displayed"
                name="content"
                margin="normal"
                variant="outlined"
                defaultValue={titleUpdate}
                onChange={handleTitleUpdate}
                autoComplete="off"
                multiline
                rows={1}
                rowsMax={2}
                required
                fullWidth
                autoFocus
              />
            ) : (
              <Typography
                variant="h6"
                color="primary"
                style={{
                  overflowWrap: 'break-word',
                  wordWrap: 'break-word',
                  hyphens: 'auto',
                }}
              >
                {title}
              </Typography>
            )}
            {editing === identifier && editingType === 'new' && type === 'verbiage' && (
              <ComboBox
                data={availableDefinitions}
                allowCustom={false}
                suggest
                filterable
                label="Select Verbiage Definition"
                name="verbiage"
                required
                onChange={handleTitleUpdate}
                style={{ width: '100%' }}
              />
            )}
          </Grid>
          <Grid item>
            {panelChips &&
              panelChips.map((chip) => {
                if (chip.title !== title) return null;
                return <Chip color="primary" label={chip.label} />;
              })}
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails data-testid="AccordionDetails">
        {type === 'segments' && <UserSegmentPanelDetails data={panelData} allOutcomes={allOutcomes} />}
      </AccordionDetails>

      {/* PANEL ACTION MANAGEMENT */}
      <Grid container justifyContent={!matches && type === 'faq' ? 'space-between' : 'flex-end'} alignItems="center">
        {/* MOBILE REORDERING BUTTONS */}
        {draggable && !editing && !matches && (
          <Grid item>
            {identifier !== data.length && (
              <IconButton style={{ marginRight: 5 }} onClick={() => onHandleMoveDown(panelData)}>
                <ArrowDownwardIcon />
              </IconButton>
            )}
            {identifier !== 1 && (
              <IconButton style={{ marginRight: 5 }} onClick={() => onHandleMoveUp(panelData)}>
                <ArrowUpwardIcon />
              </IconButton>
            )}
          </Grid>
        )}
        {/* PREVIEW PANEL BUTTON */}
        {panelPreview && (
          <Grid item>
            <Tooltip title="Preview">
              <IconButton
                variant="contained"
                color="primary"
                onClick={handlePreview}
                // className={classes.button}
                disabled={disableSave || submitting || deleting}
              >
                <SearchIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        )}
        {/* EDIT PANEL BUTTON */}
        {panelEdit.type === 'link' ? (
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => panelEdit.action(panelData)}
              className={classes.button}
              disabled={submitting || deleting}
            >
              Edit
            </Button>
          </Grid>
        ) : (
          <div>{getEditingPanelButton()}</div>
        )}
        {/* DELETE PANEL BUTTON */}
        {panelDelete && !panelData.default && (
          <IconButton
            style={{ marginRight: 5 }}
            onClick={() => onHandleDelete(panelData)}
            data-testid="expansionPanelDeleteBtn"
            disabled={submitting || deleting}
          >
            <DeleteIcon />
          </IconButton>
        )}
      </Grid>
    </Accordion>
  );
}

export default withStyles(DragExpansionWrapperStyles)(SettingsExpansionPanel);
