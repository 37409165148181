import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import ReactPlayer from 'react-player';

import ImporterStyles from '../../../../assets/jss/components/ImporterStyles';

import logo from './resources/upload-empty.png';

function VideoUploadComponent(props) {
  const { classes, field, value, handleChange, disabled } = props;
  const [currentValue, setCurrentValue] = useState(value) || null;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const API_ROOT = process.env.REACT_APP_API_ROOT;

  useEffect(() => {
    if (value !== currentValue) {
      setCurrentValue(value || null);
    }
    // eslint-disable-next-line
  }, [value]);

  const handleSubmit = (file) => {
    if (file !== null) {
      setError('');
      const fileSize = file.size / 1024 / 1024;
      if (fileSize > 100) {
        setError('File size exceeds 100MB');
      } else {
        const fd = new FormData();
        fd.append('file', file);

        const id_token = localStorage.getItem('id_token');
        // const user_id = localStorage.getItem('user_id');

        const request = new XMLHttpRequest();
        setLoading(true);
        request.onreadystatechange = () => {
          if (request.readyState === XMLHttpRequest.DONE) {
            const { status } = request;
            if (status === 0 || (status >= 200 && status < 400)) {
              setLoading(false);
              const valueToSet = JSON.parse(request.responseText).data;
              const event = {
                target: {
                  value: valueToSet,
                  name: field.name,
                },
              };
              setCurrentValue(valueToSet);
              handleChange(event);
            } else {
              // Oh no! There has been an error with the request!
              const parsedError = JSON.parse(request.responseText);
              const errorText = parsedError.message.split(';')[0];
              setError(errorText);
              setLoading(false);
            }
          }
        };

        const accountId = localStorage.getItem('accountId');
        const tenantId = localStorage.getItem('tenantId');
        request.open('POST', `${API_ROOT}/resources/v1/content/image/upload/safe-content-cache/temp`, true);

        request.setRequestHeader('Access-Control-Allow-Origin', '*');
        request.setRequestHeader('Accept', 'application/json');
        request.setRequestHeader('Authorization', `Bearer ${id_token}`);
        request.setRequestHeader('Id_Token', id_token);
        // request.setRequestHeader("userId", user_id);
        request.setRequestHeader('X-SF-ACCOUNT', accountId);
        request.setRequestHeader('X-SF-TENANT', tenantId);
        request.send(fd);
      }
    }
  };

  const handleRemoveVideo = () => {
    const event = {
      target: {
        value: '',
        name: field.name,
      },
    };

    handleChange(event);
    setCurrentValue('');
  };

  const { getRootProps, getInputProps } = useDropzone({
    disabled: disabled || field.disabled || field.readOnly,
    accept: { 'video/*': field.acceptedFiles || ['.mp4, .x-m4v'] },
    onDrop: (acceptedFiles, failedFiles) => {
      if (acceptedFiles.length + failedFiles.length > 1) {
        setError('Only upload one file at a time');
      } else if (acceptedFiles.length > 0) {
        handleSubmit(acceptedFiles[0]);
        setError('');
      } else if (failedFiles.length > 0) {
        setError(failedFiles[0].errors[0].message);
      }
    },
  });

  // let imageSrc = logo;
  let imageSrc = '';
  if (currentValue !== null && currentValue !== undefined) {
    imageSrc = currentValue;
  }

  const getIMGElement = (src) => {
    if (src)
      return (
        <div className="player-wrapper">
          <ReactPlayer url={imageSrc} className="react-player" width="100%" height="100%" controls volume pip />
        </div>
      );
    return <img src={logo} style={{ maxWidth: '100%' }} alt="thumbnail" />;
  };

  return (
    <Grid container spacing={2}>
      {field.label && (
        <Grid item xs={12}>
          <Typography align="center" style={{ opacity: 0.5 }}>
            {field.label}
          </Typography>
        </Grid>
      )}

      <Grid item xs={12}>
        <div className={!currentValue ? classes.box : classes.boxPreview}>
          <Grid container justifyContent="flex-end">
            {currentValue && (
              <Grid item>
                <Tooltip title="Remove Video">
                  <IconButton style={{ marginTop: -20 }} onClick={handleRemoveVideo}>
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            )}
            <Grid item xs={12} {...getRootProps({ className: 'dropzone' })} style={{ outline: 'none' }}>
              <input {...getInputProps()} disabled={disabled} />
              <div className={!currentValue ? classes.thumbnail : null}>
                {loading ? <CircularProgress /> : getIMGElement(imageSrc)}
              </div>
              <Button
                className={classes.button}
                variant="contained"
                disableElevation
                color="primary"
                disabled={loading || disabled || field.disabled || field.readOnly}
              >
                Browse
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="caption" style={{ opacity: 0.5 }}>
                Or drag video here.
              </Typography>
            </Grid>
          </Grid>
        </div>
      </Grid>
      {error && (
        <Typography color="error" variant="caption">
          {error}
        </Typography>
      )}
    </Grid>
  );
}

export default withStyles(ImporterStyles)(VideoUploadComponent);
