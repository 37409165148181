import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import React, { useMemo } from 'react';

import CreateFormWrapper from '../../Shared/Forms/CreateFormWrapper';

import { questionBuilder, questionChildren } from './helpers/formHelper';

function QuestionSettings(props) {
  const { type, formData, onSubmit, onDelete, details, handleCancel, questionList, activeLabTests } = props;

  // sets step to its correctly formatted JSON
  const steps = useMemo(
    () => [
      {
        label: type,
        data: questionBuilder(formData, questionList, activeLabTests),
      },
    ],
    [type, formData, questionList, activeLabTests]
  );

  // Allows form to implement logic when rendering child items
  const getQuestionChildren = (field, itemValues) => questionChildren[field.fieldset](itemValues, activeLabTests);

  return (
    <Paper style={{ padding: 20, borderRadius: 20 }} elevation={0}>
      <Grid item xs={12}>
        <CreateFormWrapper
          steps={steps}
          handleSubmit={onSubmit}
          handleDelete={onDelete}
          handleCancel={handleCancel}
          initialValues=""
          formType={type === 'questionnaires' ? 'questionDetail' : details && 'editContent'}
          getChildren={getQuestionChildren}
        />
      </Grid>
    </Paper>
  );
}

export default QuestionSettings;
