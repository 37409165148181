export const defaultLanguageContent = {
  menu_option_call_support: 'Call Support',
  menu_option_message_support: 'Message Support',
  menu_option_cancel: 'Cancel',
  menu_option_log_out_confirmation: 'Are you sure that you want to log out?',
  acuant_camera_align: 'ALIGN',
  acuant_camera_manual_capture: 'ALIGN & TAP',
  acuant_camera_move_closer: 'MOVE CLOSER',
  acuant_camera_hold_steady: 'HOLD STEADY',
  acuant_camera_capturing: 'CAPTURING',
  acuant_camera_outside_view: 'OUTSIDE VIEW',
  error: 'Error',
  login_error_title: 'Error',
  data_loading_failed_error: 'Failed to load data. Please try again.',
  sign_up_error: 'Sign Up Failed. Please try again.',
  login_error: 'Login Failed. Please try again.',
  login_credentials_error: 'Wrong email or password.',
  reseting_password_error: 'Error resetting password',
  reset_password_error: 'Password reset failed. Please try again.',
  no_internet_connection: 'Looks like you are not connected to the internet.',
  check_network_settings: 'Please check your network settings and try again.',
  slow_internet_connection: 'Seems you have a slow internet connection. Please check your network.',
  scan_user_QR_code_error: 'Can’t verify the code',
  network_connection:
    'Looks like you are not connected to the internet. Please check your network settings and try again.',
  oops_title: 'Oops',
  something_went_wrong_message: 'Looks like something went wrong. Please try again later.',
  not_connected_internet_message: 'Looks like you are not connected to the internet.',
  tap_to_capture_message: 'Tap to capture',
  allow_camera_from_settings: 'Please allow camera access from settings.',
  search_placeholder_text: 'Search',
  hi_welcome_to_message: 'Hi %@,\nWelcome to %@',
  ok: 'ok',
  search_placeholder: 'Search',
  failed_to_make_call_error_message: 'Failed to make call.',
  complete_daily_assessment_message: 'Complete your daily self-assessment',
  ready_message_title: 'Ready!',
  not_cleared_for_work: 'You are not yet cleared for work',
  cleared_for_work: 'You are cleared for work',
  your_test_activated_message: 'Your test is activated and ready to scan',
  test_kit_type: 'TEST KIT TYPE',
  logout_alert_message: 'Are you sure that you want to log out?',
  cancel_title: 'Cancel',
  logout_title: 'Log Out',
  home_button: 'HOME',
  continue_button: 'CONTINUE',
  ok_button: 'OK',
  symptom_checker_button: 'SYMPTOM CHECKER',
  get_tested_button: 'GET TESTED',
  import_health_records_button: 'IMPORT HEALTH RECORDS',
  resource_button: 'RESOURCES',
  retry_button: 'RETRY',
  that_works_button: 'THAT WORKS',
  cancel_button: 'CANCEL',
  edit_photo_button: 'Edit Photo',
  take_photo_button: 'Take Photo',
  choose_from_gallery_button: 'Choose From Gallery',
  retake_button: 'Retake',
  save_card_button: 'SAVE CARD',
  reenter_info_button: 'REENTER INFO',
  done_button: 'DONE',
  done_bar_button: 'Done',
  save_button: 'Save',
  delete_button: 'Delete',
  close_button: 'Close',
  back_button: 'Back',
  preset_test_button: 'TAKE COVID-19 TEST',
  start_virtual_consult_button: 'START VIRTUAL CONSULT',
  sign_in_button: 'SIGN IN',
  sign_up_button: 'SIGN UP',
  submit_button: 'SUBMIT',
  get_started_button: 'GET STARTED',
  terms_and_conditions_link: 'Terms and Conditions',
  rating_screen_title: 'Rating',
  photo_capture_screen_title: 'Photo Capture',
  edit_photo_screen_title: 'Edit Photo',
  terms_and_conditions_screen_title: 'Terms & Conditions',
  messenger_screen_title: 'Secure Messenger',
  credit_cards_screen_title: 'Credit Cards',
  add_credit_card_screen_title: 'Add Credit Card',
  resources_screen_title: 'Resources',
  settings_screen_title: 'Settings',
  edit_profile_screen_title: 'Edit Profile',
  feedback_screen_title: 'Get In Touch',
  help_center_screen_title: 'Help Center',
  emergency_contact_screen_title: 'Emergency Contact',
  passcode_screen_title: 'Passcode',
  tenant_selection_title: 'Tenant Selection',
  how_was_expirience: 'How was your experience?',
  how_was_expirience_with: 'How was your experience with\n %@?',
  thanks_for_rating_message: 'Thank you for providing feedback!',
  choose_rating_message: 'Please choose your rating.',
  comment_placeholder: 'Add a comment',
  rf_accessibility_back: 'Back',
  rf_accessibility_close: 'Close',
  rf_accessibility_comment_textview: 'Comment TextView',
  terms_and_conditions: 'Terms and\nConditions',
  terms_of_service: 'Terms of Service',
  privacy_policy: 'Privacy Policy',
  privacy_practices_notice: 'Notice of Privacy Practices',
  hipaa_consent: 'HIPAA Consent',
  informed_consent: 'Informed Consent',
  end_user_license_agreement: 'End User License Agreement',
  ln_accessibility_back: 'Back',
  menu_button: 'Menu',
  resources: 'Resources',
  test_history: 'Test History',
  settings: 'Settings',
  help_center: 'Help Center',
  registration_faq: 'Registration',
  contact_support: 'Contact Support',
  legal_notices: 'Legal Notices',
  log_out: 'Log Out',
  import_health_records: 'Import Health Records',
  get_tested: 'Get Tested',
  checker_mode: 'Checker Mode',
  health_record: 'Health Record',
  health_bot: 'HealthBot',
  patient_record_my_chart: 'My Chart',
  family_mode: 'Manage Accounts',
  m_accessibility_profile_image: 'User profile avatar',
  no_messages: 'No Messages Yet',
  safe_care_team: 'SAFE Care Team',
  from: 'From',
  msg_accessibility_message: 'Message from %@. Subject: %@',
  msg_accessibility_message_without_sender: 'Message with subject: %@',
  msg_accessibility_message_without_subject: 'Message without subject from %@',
  msg_accessibility_message_without_details: 'Message without subject and sender',
  msg_accessibility_back: 'Back',
  msg_accessibility_close: 'Close',
  msg_accessibility_previus: 'Previuos Message',
  msg_accessibility_next: 'Next Message',
  pm_tap_to_add_payment_method: 'Tap below to add a payment method',
  credit_card_added: 'New Credit Card Added',
  failed_to_add_credit_card: 'Credit Card Failed',
  saved_cards: 'Saved Cards',
  failed_to_delete_card_message: 'Failed to delete card. Please try again later.',
  credit_card_mm_yy: 'MM/YY',
  credit_card_number: 'Credit Card Number',
  credit_card_cvc: 'CVC',
  credit_card_name_on: 'Name on Card',
  credit_card_zip_code: 'Zip Code',
  credit_card_add_button: 'ADD CREDIT CARD',
  pm_accessibility_delete: 'Delete Card',
  pm_accessibility_back: 'Back',
  pm_accessibility_close: 'Close',
  pm_accessibility_textfield: 'Textfield',
  no_resources_message: 'No resources available.',
  videos_section_title: 'Videos',
  articles_section_title: 'Articles',
  r_accessability_back: 'Back',
  r_accessability_searchbar: 'Resources Searchbar',
  active_tenant_settings_section: 'ACTIVE TENANT',
  accout_settings_section: 'ACCOUNT',
  local_notifications_settings_section: 'LOCAL NOTIFICATIONS',
  security_settings_section: 'SECURITY',
  support_settings_section: 'SUPPORT',
  legal_notices_settings_section: 'LEGAL NOTICES',
  edit_profile_settings: 'Edit Profile',
  payment_method_settings: 'Payment Methods',
  insurance_settings: 'My Insurance',
  emergency_contact_settings: 'Emergency Contact',
  self_assessment_settings: 'Self-Assessment',
  passcode_settings: 'Passcode',
  touch_face_id_settings: 'Touch ID/Face ID',
  help_center_settings: 'Help Center',
  send_feedback_settings: 'Send Feedback',
  resources_settings: 'Resources',
  terms_and_conditions_settings: 'Terms And Conditions',
  enable_biometrics_login_settings: 'Enable Face/Touch ID for login',
  log_out_button: 'SIGN OUT',
  s_accessibility_back_button: 'Back',
  s_accessibility_close_button: 'Close',
  first_name: 'First Name',
  last_name: 'Last Name',
  sex: 'Sex at Birth',
  date_of_birth: 'Date of Birth',
  email: 'Email',
  phone_number: 'Phone Number',
  relationship: 'Relationship',
  race: 'Race',
  ethnicity: 'Ethnicity',
  state_reporting_participation: 'Participate in state-level reporting',
  state_reporting_participation_description:
    'The following optional information will be used to help your state understand the spread of diseases',
  empty_first_last_names_error_message: 'First name & Last name are mandatory!',
  empty_first_name_error_message: 'First name is mandatory!',
  empty_last_name_error_message: 'Last name is mandatory!',
  empty_gender_error_message: 'Gender is mandatory!',
  empty_birthdate_error_message: 'Birthdate is Mandatory!',
  empty_email_error_message: 'Email address is mandatory!',
  empty_phone_error_message: 'Phone number is mandatory!',
  empty_address_error_message: 'Address 1 is mandatory!',
  empty_street_error_message: 'Street is mandatory!',
  empty_city_error_message: 'City is mandatory!',
  empty_state_error_message: 'State is mandatory!',
  empty_country_error_message: 'Country is mandatory!',
  empty_zip_code_error_message: 'Please enter 5 digits zipcode.',
  empty_insurance_error_message: 'Insurance ID is mandatory!',
  empty_group_number_error_message: 'Group Number is mandatory!',
  empty_plan_name_error_message: 'Plan Name is mandatory!',
  empty_coverage_date_error_message: 'Coverage Date is mandatory!',
  empty_ssn_error_message: 'SSN is mandatory!',
  empty_marital_status_error_message: 'Marital Status is mandatory!',
  invalid_sex_error_message:
    'Healthcare law requires to know your sex assigned at birth. You can update preferences later.',
  invalid_phone_error_message: 'Please enter a correct phone number.',
  duplicate_phone_error_message: 'Member with the specified phone already exists.',
  invalid_zip_code_error_message: 'Zip code is invalid.',
  invalid_email_error_message: 'Email address is invalid',
  to_young_error_title: 'Sorry, you are not old enough!',
  to_young_error_message: 'You must be at least 18 years or older.',
  street_address: 'Street Address',
  address_one: 'Address 1',
  address_two: 'Address 2',
  apt_and_ste_short: 'Apt/Ste',
  city: 'City',
  state: 'State',
  country: 'Country',
  zip_code: 'Zip Code',
  edit_profile_from_checkout_screen_title: 'Member Account',
  personal_information_profile_section: 'PERSONAL INFORMATION',
  home_address_profile_section: 'HOME ADDRESS',
  male_gender: 'Male',
  female_gender: 'Female',
  intersex_gender: 'Intersex',
  other_gender: 'Other',
  gender_not_to_specify: 'I prefer not to specify',
  confirm_address_screen_title: 'Confirm Address',
  race_american_indian_or_alaskan: 'American Indian or Alaska Native',
  race_asian: 'Asian',
  race_black_or_african_american: 'Black or African American',
  race_hawaiian_ot_pacific: 'Native Hawaiian or Other Pacific Islander',
  race_other: 'Other Race',
  race_white: 'White',
  race_not_to_specify: 'I prefer not to specify',
  ethnicity_hispanic_or_latino: 'Hispanic or Latino',
  ethnicity_not_hispanic_or_latino: 'Not Hispanic or Latino',
  ethnicity_not_to_specify: 'I prefer not to specify',
  ep_accessibility_back: 'Back',
  ep_accessibility_close: 'Close',
  ep_accessibility_textfield: 'Textfield',
  select_purpose: 'Select Purpose',
  registration_questions_subject: 'Registration Questions',
  techniacl_issues_subject: 'Technical Issues',
  medical_questions_subject: 'Medical Questions',
  hr_subject: 'Speak With HR Representative',
  feedback_subject: 'Provide Feedback',
  other_subject: 'Other',
  feedback_send: 'SEND',
  message_sent: 'Message Sent!',
  will_be_in_touch: 'We will be in touch shortly.',
  failed_to_send_feedback: "We couldn't send your reply, Please try again",
  enter_some_feedback_text: 'Please enter some text.',
  feedback_placeholder: 'Type Message Here',
  f_accessibility_close: 'Close',
  f_accessibility_call: 'Call Support',
  f_accessibility_subject: 'Subject',
  question_label_text: 'Question',
  answer_label_text: 'Answer',
  hc_accessibility_back: 'Back',
  hc_accessibility_searchbar: 'FAQ searchbar',
  hc_accessibility_contact_support: 'Contact Support',
  family_relationship: 'Family Member',
  friend_relationship: 'Friend',
  agent_relationship: 'Agent',
  caregiver_relationship: 'Caregiver',
  guardian_relationship: 'Guardian',
  next_of_kin_relationship: 'Next of Kin',
  personal_relationship: 'It’s Personal',
  ec_accessibility_back: 'Close',
  ec_accessibility_textfield: 'Textfield',
  use_passcode_text: 'Use Passcode',
  passcode_usage_description:
    'After 3 consecutive incorrect passcode attempts, you will be signed out of your account. When you sign in, you ' +
    'can reset your passcode.',
  enter_passcode: 'Enter Passcode',
  re_enter_passcode: 'Re-Enter Passcode',
  change_passcode_text: 'Change Passcode',
  enter_old_passcode_text: 'Enter old passcode',
  enter_new_passcode_text: 'Enter new passcode',
  passcode_entered_of: 'of',
  passcode_numbers_entered: 'numbers entered',
  pc_accessibility_back: 'Back',
  pc_accessibility_passcode_switch: 'Passcode Switch',
  pc_accessibility_change_passcode: 'Change Passcode',
  secure_call_text: 'Secure Voice Call',
  speaker_text: 'speaker',
  mute_text: 'mute',
  video_text: 'video',
  flip_text: 'flip',
  end_text: 'end',
  provider_name: 'HealthCheck',
  failed_to_make_call: 'Failed to make call.',
  reconecting_text: 'Reconnecting...',
  connected_text: 'Connected',
  calling_feature_camera_access_message:
    'Camera access is required for call. Please enable camera permission in phone settings',
  calling_feature_mic_access_message:
    'Microphone access is required for call. Please enable microphone permission in phone settings',
  pickup_date_field_placeholder: 'Pickup Date',
  ready_time_field_placeholder: 'Ready Time',
  latest_available_time_field_placeholder: 'Latest Time Available',
  schedule_pickup_screen_title: 'Schedule Pickup',
  schedule_pickup_no_available_option: "We couldn't find any available options to schedule a pickup.",
  select_date_message: 'Please select date first.',
  select_ready_time_message: 'Ready Time is mandatory!',
  select_latest_available_time_message: 'Latest Time Available is mandatory!',
  failed_to_schedule_pickup: 'Failed to schedule Pickup.',
  today: 'Today',
  tomorrow: 'Tomorrow',
  day_after_tomorrow: 'Day After Tomorrow',
  pickup_schedulet_with_time_and_location_message:
    'Your test kit has been scheduled to be picked up at the time and location below.',
  pickup_scheduled_message: 'Your test kit has been scheduled.',
  pickup_confirmation_alert_title: 'FedEx pickup confirmation',
  instructions_details_message: 'Location of package or special instructions',
  sp_accessibility_back: 'Back',
  sp_accessibility_close: 'Close',
  sp_accessibility_textfield: 'Textfield',
  drop_off_confirmation_failed: 'Failed to confirm drop off.',
  drop_off_location_data_not_found: 'No data were found for drop off location',
  united_states: 'United States',
  failed_to_get_pdf_message: 'PDF document is not available.',
  cancel_pickup_confirmation_message: 'Are you sure you want to cancel pickup?',
  pickup_cancelation_failed: 'Pickup cancelation failed.',
  yes: 'Yes',
  no: 'No',
  import_health_records_skip: 'Skip',
  connect_text: 'Connect',
  disconnect_text: 'Disconnect',
  ihr_accessibility_back: 'Back',
  ihr_accessibility_close: 'Close',
  account_info_screen_title: 'Account Info',
  set_password_screen_title: 'Set Password',
  forgot_password_screen_title: 'Forgot Password',
  agree_terms_label: 'I agree to the',
  lowercase_letter_label: '1 Lowercase letter',
  at_least_characters_label: 'At least 8 characters',
  upper_letter_label: '1 Uppercase letter',
  number_label: '1 Number',
  special_character_label: '1 Special Character (!@#$%^&*)',
  dont_have_account_label: "Don't have an account?",
  already_have_account_label: 'Already have an account?',
  enter_forgot_password_email_label: 'Please enter the email address associated with your account',
  sso_sign_in_button: 'SSO Sign In',
  forgot_password_email_message:
    'We have sent you an email with password reset information. Please follow the instructions in that email. ' +
    'Thank you.',
  already_have_account_message:
    'It looks like you already have an account using that email address.\nPlease try signing in.',
  login_email: 'Email',
  login_password: 'Password',
  login_set_password: 'Set Password',
  login_confirm_password: 'Confirm Password',
  login_placeholder_birthday: 'Birthday',
  login_placeholder_sex: 'Sex at Birth',
  login_placeholder_zip_code: 'Zip Code',
  login_placeholder_phone_number: 'Phone Number',
  select_tenant_description_message: 'Select the health service you would like to sign into',
  input_textfield__accessibility_label: 'Textfield',
  back_button_accessibility_label: 'Back',
  email_textfield: 'email textfield',
  password_textfield: 'password textfield',
  hide_password_button: 'hide password',
  show_password_button: 'show password',
  tems_and_conditions_checked: 'Unaccept Terms And Conditions',
  tems_and_conditions_unchecked: 'Accept Terms And Conditions',
  password_requirement: 'Password requirement.',
  password_requirement_matches: 'Matches',
  password_requirement_not_matches: 'Not matches',
  secured_text: 'Secured text',
  touch_face_id_switch: 'Touch/Face Id Switch',
  enabled_voice_over: 'Enabled',
  disabled_voice_over: 'Disabled',
  email_verification_title: 'Verify Email',
  email_verification_desc: 'A verification code has been sent to your email address. Please enter it below to proceed.',
  email_verification_error: "The verification code you entered doesn't match. Please try again.",
  email_verified_desc: 'Your Email Has Been Verified!',
  email_verification_resent: 'Code Re-sent',
  email_verification_submit: 'CONTINUE',
  email_verification_resend: 'SEND CODE AGAIN',
  override_button: 'OVERRIDE',
  fever_button: 'FEVER',
  visible_sympthoms_button: 'VISIBLE SYMPTOMS',
  reported_sympthoms_button: 'REPORTED SYMPTOMS',
  ready_for_work_button: 'READY FOR WORK',
  not_ready_for_work_button: 'NOT READY FOR WORK',
  'n/a': 'N/A',
  rapid_scan_ready: 'Ready!',
  select_reason_label: 'Select Reason',
  last_assessment_label: 'LAST ASSESSMENT',
  hold_camera_over_qr_label: 'Hold camera over QR Code',
  stay_home_message: 'STAY_HOME',
  fail_update_error_message: 'Failed to update data. Please try again.',
  scan_error_camera_access: 'Camera access is necessary to scan a profile. Please allow camera access from settings.',
  scan_error_no_camera: 'Your device do not have camera. Please use device with camera.',
  scan_error_fail_to_configure: 'Failed to configure your camera, please try again.',
  scan_user_profile_settings_action: 'Settings',
  appointment_confirmation_title: 'Appointment confirmation',
  schedule_appointment_screen_title: 'Schedule Appointment',
  drop_off_by_title: 'Drop off by ',
  drop_off_confirmation: 'Drop off confirmation',
  find_drop_off_location_screen_title: 'Find Drop Off Location',
  open_label: 'Open',
  closed_label: 'Closed',
  select_this_location_button: 'SELECT THIS LOCATION',
  select_facility_button: 'SELECT FACILITY',
  get_directions_button: 'GET DIRECTIONS',
  drop_off_within_24_message: 'Please drop off your test kit to your chosen location below within 24 hours',
  no_result_found: 'No result found.',
  no_web_adress_message: 'no web adress',
  enter_city_or_zipcode_message: 'Search by city or code',
  enter_zipcode_message: 'Search by Zipcode',
  month: 'Month',
  search_zipcode_done_button: 'Done',
  fri: 'FRI',
  mon: 'MON',
  sat: 'SAT',
  sun: 'SUN',
  thu: 'THU',
  tue: 'TUE',
  wed: 'WED',
  sa_proccessing: 'Processing',
  sa_ok_button: 'OK',
  hours: 'HOURS',
  appointment_rescheduled_label: 'Your Appointment is Rescheduled!',
  sa_accessibility_back: 'Back',
  sa_accessibility_close: 'Close',
  test_order_details_screen_title: 'Test Order Details',
  test_results_screen_title: 'Test Results',
  lab_order_screen_title: 'Lab Order',
  test_history_screen_title: 'Test History',
  no_orders_label_message: 'If any lab orders are made, they will appear here',
  no_orders_title_message: 'No Lab Orders Yet',
  test_name: 'Test Name',
  reference_lab: 'Reference Lab',
  order_manufacturer: 'Manufacturer',
  order_date: 'Order Date',
  collection_date: 'Collection Date',
  collection_time: 'Collection Time',
  shipping_carrier: 'Shipping Carrier',
  status: 'Status',
  track_package: 'Track Package',
  register_kit: 'Register Kit (Scan code)',
  pickup_address: 'Pickup Address',
  drop_off_address: 'Drop Off Address',
  cancel_pickup: 'Cancel Pickup',
  result: 'Result',
  test_result: 'Test Result',
  appointment_time: 'Appointment Time',
  location: 'Location',
  address: 'Address',
  reschedule_appointment: 'Reschedule Appointment',
  download_lab_order: 'Download Lab Order',
  download_lab_report: 'Download Lab Report',
  diagnostic_order: 'Diagnostic Order',
  th_accessibility_close: 'Close',
  th_accessibility_back: 'Back',
  scan_test_camera_error_message:
    'Camera access is necessary to scan the test. Please open your device settings to allow camera access.',
  collection_instructions_screen_title: 'Collection Instructions',
  video_instructions_screen_title: 'Instructional Video',
  register_test_kit_screen_title: 'Register Test Kit',
  assess_results: 'Assess Results',
  shipping_options_screen_title: 'Shipping Options',
  find_drop_off_location_button: 'FIND DROP OFF LOCATION',
  schedule_pickup_button: 'SCHEDULE PICKUP',
  get_started_now_button: 'GET STARTED NOW',
  start_timer_button: 'START TIMER',
  return_options_label: 'Return options',
  test_kit_type_label: 'TEST KIT TYPE',
  whats_included_label: 'WHATS INCLUDED',
  ready_label: 'Ready',
  test_ready_title: 'Test Ready',
  test_processing_title: 'Test Processing',
  description_label: 'Description',
  scanning_not_supporter_error_message: 'Scanning not supported',
  collection_instructions_error_message: 'Collection Instructions',
  no_data_loaded_error_message: 'No data loaded',
  not_support_scaning_error_message:
    'Your device does not support scanning a code from an item. Please use a device with a camera.',
  understand_and_agree_message: 'I understand & agree to these guidelines',
  pickup_or_drop_off_message:
    'You can either schedule a time for the package to be picked up or pick from thousands of FedEx locations ' +
    'to drop off.',
  wait_specimen_processed_message:
    'Your test is being processed, you will receive a message when the results are ready.',
  camera_scaning_hint: 'Hold camera over barcode or QR code on your Test Kit',
  play_collection_instruction_video: 'PLAY VIDEO',
  skip_collection_instruction_video: 'SKIP VIDEO',
  insturctional_video_invalid_url: 'Seems video URL is invalid.',
  replay_video_details_label_text: 'Replay Video',
  assess_results_desc: 'What do you see on your test strip?',
  need_guidance: 'Need some guidance?',
  confirm_sa_results: 'CONFIRM TEST RESULT',
  abandon_test_flow_title: 'Cancel Test',
  abandon_test_flow_message: 'Are you sure you want to cancel your test?',
  expiration_title: 'Time Expired',
  expiration_message: 'The time to complete the test has expired. This test result is Invalid.',
  end_test_action: 'End Test',
  what_your_results_mean: 'What your results mean?',
  test_loading_failed_error: 'Failed to initiate R&D mode. Please try again.',
  test_kit_scan_error_title: 'Test Error',
  test_kit_scan_error_message: 'Unable to load test information, please try again later.',
  test_kit_settings_action: 'Settings',
  test_kit_cancel_action: 'Cancel',
  scan_code_instruction_title: 'Scan Test Kit Code',
  scan_code_instruction_text:
    'To register your test, scan the square code using your device camera. The code may be located on the test ' +
    'itself, the pouch, or the box.',
  scan_code_button: 'SCAN CODE',
  scan_code_contact_support: 'Contact Support',
  tk_accessibility_close: 'Close',
  tk_accessibility_back: 'Back',
  face_id_screen_title: 'Face ID',
  request_biomertic_message:
    'This application uses your fingerprint to protect your personal information. Tapping the Cancel button ' +
    'will log you out.',
  unlock_with_touch_face_label: 'Unlock with Touch/Face ID',
  biometry_not_available:
    'This Device does not support TouchID or FaceID. Please log back in with your email and password.',
  biometry_touch_permissions_not_granted:
    'TouchID is not enabled for the app. To enable it go to your device settings or use your email and password to ' +
    'log back in.',
  biometry_face_permissions_not_granted:
    'FaceID is not enabled for the app. To enable it go to your device settings or use your email and password to ' +
    'log back in.',
  biometry_touch_locked: 'TouchID not recognized. Please log back in with your email and password.',
  biometry_face_locked: 'FaceID not recognized. Please log back in with your email and password.',
  biometry_touch_not_enrolled:
    'TouchID is not enabled on your device. To enable it go to your settings or use your email and password to ' +
    'log back in.',
  biometry_face_not_enrolled:
    'FaceID is not enabled on your device. To enable it go to your settings or use your email and password to ' +
    'log back in.',
  biometry_go_to_settings: 'Go to Settings',
  tfid_accessibility_back: 'Back',
  tfid_accessibility_close: 'Close',
  tfid_accessibility_switch: 'Touch/Face ID Switch',
  virtual_consult_screen_title: 'Virtual Consult',
  without_insurance_label: 'WITHOUT INSURANCE',
  insurance_coverege_and_cost_label: 'Insurance coverage and cost varies. Continue for details.',
  select_option_label: 'Select Option',
  symptom_checker_daily_reminder: 'Daily Reminder',
  virtual_consult_slide_to_pick: 'Slide to pick',
  virtual_consult_day: ' day',
  virtual_consult_days: ' days',
  vc_accessibility_back_button: 'Back',
  vc_accessibility_close_button: 'Close',
  vc_accessibility_decrease_value: 'Decrease Value',
  vc_accessibility_increase_value: 'Increase Value',
  vc_accessibility_selected: 'Selected',
  vc_accessibility_unselected: 'Unselected',
  symptom_checker_reminder_screen_title: 'Self-Assessment',
  scr_accessibility_back: 'Back',
  scr_accessinility_daily_reminder_switch: 'Daily reminder Switch',
  member_account_screen_title: 'Member Account',
  coupon_code_screen_title: 'Coupon Code',
  checkout_screen_title: 'Checkout',
  payment_methods_title: 'Payment Methods',
  order_summary_title: 'Order Summary',
  shipping_address_title: 'Shipping Address',
  appointment_title: 'Appointment',
  account_details_title: 'Account Details',
  order_details_title: 'Order Details',
  insurance_title: 'Insurance',
  charges_label: 'Charges',
  total_label: 'Total',
  checkout_update_label: 'Update',
  checkout_confirm_label: 'Confirm',
  varies_label: 'varies',
  same_as_label: 'Same as ',
  confirm_order_label: 'CONFIRM ORDER',
  appointment_confirmed_label: 'Your Appointment is Confirmed!',
  insuranse_provider_pay_label: 'Would you like to use your insurance provider to pay for this test?',
  have_your_plan_label: 'Have your plan info handy',
  use_my_insurance_label: 'Use my insurance',
  pay_with_credit_card_label: 'Pay with Credit Card',
  pay_retail_price_label: 'I will pay retail price',
  billing_address_error_message: 'Billing address is mandatory.',
  payment_method_error_message: 'Payment method is mandatory.',
  shipping_address_error_message: 'Shipping address is mandatory.',
  insurance_eligability_test_failed_message: 'We were unable to verify your insurance eligibility.',
  checkout_placeholder_add_coupon_code: 'Add Coupon Code',
  checkout_i_agree_label: 'I agree to the terms & conditions',
  checkout_add_insuranse_provider: 'Add Insurance Provider',
  checkout_insuranse_provider: 'Insurance Provider',
  checkout_payment_method: 'Payment Method',
  checkout_add_payment_method: 'Add Payment Method',
  checkout_shipping_address: 'Shipping Address',
  checkout_add_shipping_address: 'Add Shipping Address',
  checkout_billing_address: 'Billing Address',
  checkout_add_billing_address: 'Add Billing Address',
  checkout_invalid_coupon: 'INVALID COUPON CODE',
  checkout_placeholder_coupon_code: 'Coupon Code',
  checkout_add_member_account: 'Add Member Account',
  checkout_member_account: 'Member Account',
  checkout_member_phone: 'Member Phone Number',
  checkout_add_member_phone: 'Add Member Phone Number',
  checkout_what_you_pay: 'What you pay after insurance has been applied',
  checkout_taxes_fees: 'Taxes & Fees',
  checkout_insurance_coverage: 'Insurance coverage',
  checkout_coupon_coverage: 'Coupon Coverage',
  checkout_accessibility_back: 'Back',
  checkout_accessibility_close: 'Close',
  checkout_accessibility_price: 'Price',
  checkout_accessibility_textfield: 'Textfield',
  esignature_label: 'eSignature',
  please_sign_to_confirm_label: 'Please sign to confirm your consent to retrieve your health records.',
  clear_button: 'CLEAR',
  es_accessibility_back: 'Back',
  es_accessibility_signature_area: 'ESignature Area',
  select_a_test_screen_title: 'Select a Test',
  could_not_get_tests_message: 'Could not get tests, please try after sometime.',
  no_test_or_panel_label: 'No Test/Panel is available',
  no_test_available_label: 'No Test is available',
  test_panel_label: 'Test Panel',
  test_type_label: 'Test Type',
  get_tested_options_screen_title: 'Testing Options',
  select_option_for_testing: 'Select how you would like to get tested?',
  have_test_kit_option: 'I have a Test Kit',
  order_test_kit_option: 'Order a Test Kit',
  schedule_appointment_option: 'Schedule Appointment',
  gt_accessibility_back: 'Back',
  gt_accessibility_close: 'Close',
  add_insurance_screen_title: 'Add Insurance Provider',
  find_insurance_provider_placeholder: 'Type Insurance Provider Name',
  confirm_insurance_screen_title: 'Confirm Insurance',
  select_add_insurance_option_message: 'Select how you would like to add your insurance info',
  scan_insurance_button_title: 'Scan Insurance ID Card',
  scan_insurance_button_description: 'Automatically enter insurance info',
  find_your_insurance_button_title: 'Find your Insurance Provider',
  find_your_insurance_button_description: 'Manually enter insurance info',
  select_insurance_provider_message: 'Select your insurance provider from the list below or use search above.',
  insurance_provider_go_button: 'GO',
  verify_quality_title: 'Verify Quality',
  verify_quality_message: 'Make sure that all data on your document is fully visible, glare free and not blurred',
  insurance_looks_good_button_title: 'LOOKS GOOD',
  insurance_retake_button: 'RETAKE',
  insurance_scan_back_message: 'Ok great, now lets scan the back of the card',
  insurance_proccessing_scan_message: 'Processing Scan',
  insurance_provider_added_message: 'Your insurance provider has been successfully added.',
  insurance_failed_message_title: 'Oops',
  insurance_failed_message_details:
    'Please go back to correct the information entered and make sure your profile information matches the ' +
    'information saved with the insurance provider.',
  insurance_update_policy_button: 'UPDATE POLICY INFO',
  insurance_verifying_eligibility: 'Verifying Eligibility',
  insurance_update_button: 'UPDATE',
  insurance_id_field_name: 'Insurance ID',
  insurance_group_number_field_name: 'Group Number',
  insurance_plan_name_field_name: 'Plan Name',
  insurance_coverage_date_field_name: 'Coverage Date',
  insurance_camera_error_message: 'Camera access is absolutely necessary to scan the card.',
  insurance_proccessing_error_title: 'Processing issue',
  insurance_try_again_button: 'Try Again',
  insurance_failed_to_scan_message: 'Failed to scan document. Please try again.',
  policy_holder_screen_title: 'Policy Holder Info',
  policy_holder_not_a_policy_holder: 'I am not a policy holder',
  policy_holder_first_name: 'First name',
  policy_holder_last_name: 'Last name',
  policy_holder_phone_number: 'Phone number',
  policy_holder_date_of_birth: 'Date of Birth',
  policy_holder_relationship: 'Relationship',
  policy_holder_street_address: 'Street Address',
  policy_holder_apt_and_ste_short: 'Apt/Ste',
  policy_holder_city: 'City',
  policy_holder_state: 'State',
  policy_holder_zip: 'Zip Code',
  policy_holder_family_member: 'Family Member',
  policy_holder_friend: 'Friend',
  policy_holder_agent: 'Agent',
  policy_holder_caregiver: 'Caregiver',
  policy_holder_guardian: 'Guardian',
  policy_holder_next_of_kin: 'Next of Kin',
  policy_holder_personal: 'It’s Personal',
  in_accessibility_not_policy_holder_checkbox: "I'm not a policy holder checkbox",
  in_accessibility_selected: 'Selected',
  in_accessibility_unselected: 'Unselected',
  in_accessibility_textfield: 'Textfield',
  in_accessibility_back: 'Back',
  in_accessibility_close: 'Close',
  local_notifications_screen_title: 'Self-Assessment',
  local_notificcations_daily_reminder: 'Daily Reminder',
  local_notifications_complete_assessment_message_title: 'Complete your daily self-assessment',
  local_notifications_complete_assessment_message_message:
    'Before heading to work, take your daily self-assessment and help keep our customers healthy.',
  local_notifications_setting_notifications_error: 'Error setting notifications.',
  local_notifications_time_to_recive_reminder: 'Time to receive daily self-assessment reminder',
  local_notifications_days_to_recive_reminder: 'Days to receive daily self-assessment reminder',
  local_notification_sunday: 'Sunday',
  local_notification_monday: 'Monday',
  local_notification_tuesday: 'Tuesday',
  local_notification_wednesday: 'Wednesday',
  local_notification_thursday: 'Thursday',
  local_notification_friday: 'Friday',
  local_notification_saturday: 'Saturday',
  ln_accessinility_daily_reminder_switch: 'Daily reminder Switch',
  import_health_kit_no_data_to_import: 'Seems like there are no health records to import from Health App.',
  import_health_kit_data_shared: 'Health App data shared successfully.',
  import_health_kit_permissions_needed: 'App needs permission to access Health App to save clinical data.',
  import_health_kit_tap_to_sync_message: 'Tap below to link Healthkit.',
  link_health_kit_button_title: 'Link Healthkit',
  import_health_kit_dismiss: 'Dismiss',
  import_health_kit_grant_permissions: 'Grant Permission',
  import_health_kit_success_title: 'Success',
  ihk_accessibility_back: 'Back',
  import_options: 'Import Options',
  request_from_provider: 'Request From Provider',
  physician_clinic_or_lab: 'Physician, Clinic or Lab',
  how_would_you_look_like_to_import: 'Select how you would like to import your records',
  import_from_patient_portal: 'Import from Patient Portal',
  using_account_credentionals: 'Using account credentials',
  import_from_health_app: 'Import from Health App',
  connect_using_your_apple_id: 'Connect using your Apple ID',
  enter_testing_info: 'Enter Testing Info',
  health_kit_service_permissions_needed: 'App needs permission to access Health App to save clinical data.',
  health_kit_information_could_not_be_saved_message: 'Health information could not be saved!',
  health_app_not_available_message: 'Health App is not available.',
  health_kit_unable_to_proceed: 'Unable to proceed',
  authorization: 'Authorization',
  search_providers: 'Search Providers',
  sync_patient_portal: 'Sync Patient Portal',
  connect: 'Connect',
  select_portal_providers_message: 'Select your patient portal provider from the list below or use search above.',
  care_team_here_to_help: 'Our Care Team is here to help.',
  contact_care_team_button: 'CONTACT CARE TEAM',
  "can't_find_patient_portal_message": "Can't find your patient portal?\nNo problem!",
  connected_cell_button: 'CONNECTED',
  provider_linked_message: 'Your provider has been linked and status will be updated shortly.',
  pp_accessibility_back: 'Back',
  provider_lookup_search_providers: 'Search Providers',
  you_are_authorizing_message: 'You are authorizing the app to retrieve your health records from your provider.',
  import_test_ressults: 'import test results',
  import_health_record: 'import health record',
  test_results_display_details_message:
    'Test results are never displayed anywhere but on the screen of your own phone.',
  confirm_record_request: 'Confirm Record Request',
  find_providers: 'Find My Provider',
  select_provider_description_message: 'Select provider from the list below or use search above.',
  "can't_find_provider": "Can't find your provider?\nNo problem!",
  our_care_team_here: 'Our Care Team is here to help.',
  provider_lookup_contact_care_team_button: 'CONTACT CARE TEAM',
  record_requested_message: "Record Requested. We'll notify you when your records are available.",
  pl_accessibility_back: 'Back',
  scan_cassete_processing: 'Processing Image',
  scan_cassete_quality_confirm: 'Confirming Quality',
  scan_cassete_processing_result: 'Processing Results',
  scan_cassete_processed: 'Test processed successfully!',
  scan_cassete_processing_failed: 'Processing failed',
  "scan_cassete_image_uploaded_we'll_notify_about_results": 'Image uploaded! We will alert you when results are ready',
  scan_cassete_processing_completed: 'Processing Completed',
  scan_cassete_result_availability_details:
    'The test results will be available in the Test History when processing is completed',
  scan_cassete_unmached: 'Unmatched ....',
  scan_cassete_being_processed: 'Your test is being processed, you will receive a message when the results are ready.',
  scan_cassete_looks_good: 'Looks Good!',
  scan_cassete_rescan: 'Rescan',
  scan_cassete_dismiss: 'Dismiss',
  scan_cassete_try_again: 'TRY AGAIN',
  scan_cassete_cancel: 'CANCEL',
  scan_cassete_position_perfect: 'Perfect!',
  scan_cassete_position_auto_initiate: '...Scan will auto initiate when correctly positioned…',
  scan_cassete_position_inside_guides: 'Position the test cassette inside the guides…',
  scan_cassete_position_do_not_tilt: 'Don’t tilt the camera. Hold the camera as flat and as square as possible…',
  scan_cassete_result_unable_to_proceed: 'Unable to proceed',
  scan_cassete_unable_to_classify: 'Your results are inconclusive.',
  scan_cassete_tested_positive_covid: 'You have tested positive for COVID-19.',
  scan_cassete_tested_negative_covid: 'You have tested negative for COVID-19.',
  scan_cassete_network_failed: 'We can’t reach our network right now. Please check your Internet connection.',
  scan_cassete_survey_intro: 'Please complete a quick usability survey. This will only take a few minutes!',
  info_resources_button: 'INFO RESOURCES',
  error_outcome_info: 'Error getting data. Plese try again later.',
  stc_accessibility_back: 'Back',
  stc_accessibility_close: 'Close',
  scan_cassete_service_network_error: 'Error getting data from server',
  user_agreement_agree: 'I Agree',
  ua_accessibility_close: 'Close',
  ua_accessibility_back: 'Back',
  last_assesment: 'LAST ASSESSMENT',
  up_accessibility_profile_image: 'User profile avatar',
  up_accessibility_profile_image_hint: 'Shows avatar edit options',
  up_accessibility_qr_code_image: 'User QR Code',
  ed_accessibility_filter: 'Filter',
  patient_record_tap_to_sync: 'Tap below to Sync Patient Portal or link Healthkit',
  patient_record_sync_patient_portal: 'Sync Patient Portal',
  patient_record_notes: 'Notes',
  pateint_records_note_view_details: 'Based on the lab results I would like to preform further',
  patient_records_contact_information: 'Contact Information',
  patient_records_title_vaccinations: 'Vaccinations',
  patient_records_title_allergies: 'Allergies',
  patient_records_title_conditions: 'Conditions',
  patient_records_title_pocedure: 'Procedure',
  patient_records_title_medications: 'Medications',
  patient_records_title_vitals: 'Vitals',
  patient_records_title_demographic: 'Demographic Info',
  patient_records_title_family_details: 'Family Details',
  patient_records_title_social_history: 'Social History',
  patient_records_first_name: 'First Name',
  patient_records_last_name: 'Last Name',
  patient_records_date_of_birth: 'Date of Birth',
  patient_records_ssn: 'SSN',
  patient_records_sex: 'Sex',
  patient_records_marital_status: 'Marital Status',
  patient_records_race: 'Race',
  patient_records_ethnicity: 'Ethnicity',
  patient_records_status: 'Status',
  patient_records_phone: 'Phone',
  patient_records_mobile: 'Mobile',
  patient_records_email: 'Email',
  patient_records_street_address: 'Street Address',
  patient_records_apt_or_suit: 'Apt/Suite',
  patient_records_city: 'City',
  patient_records_state: 'State',
  patient_records_zip: 'Zip',
  patient_records_country: 'Country',
  patient_records_insurance_provider: 'Insurance Provider',
  patient_records_insurance_id: 'Insurance ID',
  patient_records_group_number: 'Group Number',
  patient_records_plan_name: 'Plan Name',
  patient_records_effective_date: 'Effective Date',
  patient_records_expiry_date: 'Expiry Date',
  patient_records_policyHolder_id: 'Policy Holder ID',
  patient_records_policyHolder_Name: 'Policy Holder Name',
  patient_records_beneficiary_id: 'Benefeciary ID',
  patient_records_beneficiary_name: 'Beneficiary Name',
  patient_records_guardian: 'Legal Gaurdian',
  patient_records_emergency_contact: 'Emergency Contact',
  patient_records_relation: 'Relation',
  patient_records_gender: 'Gender',
  patient_records_height: 'Height',
  patient_records_weight: 'Weight',
  patient_records_bmi: 'BMI',
  patient_records_temperature: 'Temperature',
  patient_records_bp: 'BP',
  patient_records_pulse: 'Pulse',
  patient_records_o2_saturation: 'O2 Saturation',
  patient_records_respitory_rate: 'Respitory Rate',
  patient_records_dose: 'Dose Instructions',
  patient_records_medication: 'Medication',
  patient_records_date_prescribed: 'Date Prescribed',
  patient_records_prescribed_by: 'Prescribed By',
  patient_records_prescription_status: 'Prescription Status',
  patient_records_onset_date_and_code: 'Onset Date|Code',
  patient_records_onset_date: 'Onset Date',
  patient_records_code: 'Code',
  patient_records_type: 'Type',
  patient_records_severity: 'Severity',
  patient_records_reaction: 'Reaction',
  patient_records_notes: 'Notes',
  patient_records_allergy_name: 'Allergy',
  patient_records_verification_status: 'Verification Status',
  patient_records_clinicalStatus: 'Clinical Status',
  patient_records_condition_name: 'Condition Name',
  patient_records_body_site: 'Body Site',
  patient_records_date_recorded: 'Date Recorded',
  patient_records_date_resolution: 'Resolution Date',
  patient_records_time: 'Time',
  patient_records_primary_diagnosis: 'Primary Diagnosis',
  patient_records_vaccine_name: 'Vaccine Name',
  patient_records_injection_rout: 'Injection Rout',
  patient_records_injection_site: 'Injection Site',
  patient_records_provider: 'Provider',
  patient_records_date_vaccinated: 'Date Vaccinated',
  patient_records_expirationDate: 'Expiration Date',
  patient_records_manufacture: 'Manufacture',
  patient_records_lot_number: 'Lot Number',
  patient_records_procedure_description: 'Procedure Description',
  patient_records_reason: 'Reason',
  patient_records_practitioner: 'Practitioner',
  patient_records_on_set: 'On Set',
  patient_records_smoking_status: 'Smoking Status',
  patient_records_alcohol_use: 'Alcohol Use',
  patient_records_illicit_drug_user: 'Illicit Drug User',
  patient_records_sexual_preference: 'Sexual Preference',
  patient_records_occupation_status: 'Occupation Status',
  patient_records_employer: 'Employer',
  patient_records_education_level: 'Education Level',
  patient_records_major: 'Major',
  patient_records_student: 'Student',
  patient_records_children: 'Children',
  patient_records_relationship_status: 'Relatioship Status',
  patient_records_relationship: 'Relationship',
  patient_records_conditions: 'Conditions',
  patient_records_demographic_info: 'Demographic Info',
  patient_records_contant_information: 'Contact Information',
  patient_records_emergency_contant: 'Emergency Contact',
  pr_accessibility_close: 'Close',
  pr_accessibility_back: 'Back',
  patient_records_title_add_allergy: 'Add Allergy',
  please_fill_all_the_mandatory_fields: 'Please fill all the mandatory fields',
  please_fill_reaction_with_severity: 'Please fill reaction, it is necessary with severity.',
  please_fill_severity_with_reaction: 'Please fill severity, it is necessary with reaction.',
  patient_record_add_allergy: 'ADD ALLERGY',
  patient_record_update_allergy: 'UPDATE ALLERGY',
  patient_record_add: 'Add',
  patient_record_add_update_error: 'Something went wrong! Please try again later.',
  patient_record_add_note: 'Add Note',
  patient_record_do_you_want_to_discard_all_changes: 'Do you want to discard all changes?',
  patient_record_import_health_record: 'IMPORT HEALTH RECORD',
  patient_record_ehtnicity_hispanicOrLatino: 'Hispanic or Latino',
  patient_record_ehtnicity_notHispanicOrLatino: 'Not Hispanic or Latino',
  patient_record_ehtnicity_unknown: 'Unknown',
  patient_record_marital_status_single: 'Single',
  patient_record_race_married: 'Married',
  patient_record_race_widowed: 'Widowed',
  patient_record_race_divorced: 'Divorced',
  patient_record_race_other: 'Other',
  patient_record_empty_race_error_message: 'Race is mandatory.',
  patient_record_empty_ethincity_error_message: 'Ethnicity is mandatory.',
  patient_record_securily_import_health_record: 'Tap below to securely import health records',
  patient_record_securily_import_health_record_or: 'Tap below to securely import health records or',
  patient_records_title_add_family_details: 'Add Family Details',
  patient_records_realtionship_father: 'Father',
  patient_records_realtionship_mother: 'Mother',
  patient_records_realtionship_brother: 'Brother',
  patient_records_realtionship_sister: 'Sister',
  patient_records_realtionship_grandmother: 'Grandmother',
  patient_records_realtionship_grandfather: 'Grandfather',
  patient_records_invalid_condition_field: 'Condition is invalid',
  patient_records_reaction_type_field_food: 'Food',
  patient_records_reaction_type_field_medication: 'Medication',
  patient_records_reaction_type_field_environment: 'Environment',
  patient_records_reaction_type_field_biologic: 'Biologic',
  patient_records_reaction_type_field_unconfirmed: 'Unconfirmed',
  patient_records_reaction_type_field_confirmed: 'Confirmed',
  patient_records_reaction_type_field_refuted: 'Refuted',
  patient_records_reaction_type_field_enteredInError: 'Entered-In-Error',
  patient_records_reaction_type_field_active: 'Active',
  patient_records_reaction_type_field_inactive: 'Inactive',
  patient_records_reaction_type_field_resolved: 'Resolved',
  patient_records_reaction_type_field_mild: 'Mild',
  patient_records_reaction_type_field_moderate: 'Moderate',
  patient_records_reaction_type_field_severe: 'Severe',
  patient_records_title_add_social_history: 'Add Social History',
  patient_records_title_alcoholuse_current_drinker: 'Current drinker of alcohol (finding)',
  patient_records_title_alcoholuse_current_non_drinker: 'Current non-drinker of alcohol (finding)',
  patient_records_title_alcoholuse_stopped_drinking: 'Stopped drinking alcohol (finding)',
  patient_records_title_smoking_status_current_every_day_smoker: 'Current every day smoker',
  patient_records_title_smoking_status_current_some_day_dmoker: 'Current some day smoker',
  patient_records_title_smoking_status_former_smoker: 'Former smoker',
  patient_records_title_smoking_status_never_smoker: 'Never smoker',
  patient_records_title_smoking_status_smoker_unknown_current: 'Smoker, current status unknown',
  patient_records_title_smoking_status_unknown: 'Unknown if ever smoked',
  patient_records_title_smoking_status_heave_tobacoo_smoker: 'Current Heavy tobacco smoker',
  patient_records_title_smoking_status_light_tobacoo_smoker: 'Current Light tobacoo smoker',
  patient_records_title_sexual_orientation_lesbian_gay_homosexual: 'Lesbian, gay or homosexual',
  patient_records_title_sexual_orientation_strait_or_heterosexual: 'Straight or heterosexual',
  patient_records_title_sexual_orientation_bisexual: 'Bisexual',
  patient_records_title_sexual_orientation_unknown: 'Unknown',
  patient_records_title_drug_use_cocaine: 'Cocaine user',
  patient_records_title_drug_use_accidental_heroine_overdose: 'Accidental heroin overdose',
  patient_records_title_drug_use_methamphetamine_abuse: 'Methamphetamine abuse',
  patient_records_title_drug_use_opiate_misuse: 'Opiate misuse',
  patient_records_title_drug_use_history_of_drug_abuse: 'History of drug abuse',
  please_fill_atleat_one_social_history: 'Please fill at least one social history detail',
  patient_record_invalid_zip_code_error_message: 'Zip code %@ is invalid',
  patient_record_invalid_email_error_message: 'Email address is invalid',
  patient_record_invalid_ssn_error_message: 'SSN is invalid',
  patient_record_invalid_phone_error_message: 'Phone number %@ is invalid',
  patient_records_title_add_conditions: 'Add Condition',
  clinical_status_cant_be: "Status can't be",
  with_resolution_date: 'with Resolution date. Please update Status',
  patient_records_title_add_vitals: 'Add Vitals',
  please_fill_atleat_one_vital: 'Please fill at least one vital detail',
  blood_pressure_values_incorrect: 'Blood Pressure values are incorrect',
  hippa_reiquires_sex_info:
    'HIPAA requires that healthcare providers have a record of the sex assigned at birth. We don’t make the rules, ' +
    'just have to follow them.',
  why_limit_sex: 'Why limit Sex?',
  less: 'less',
  more: 'more',
  without_insurance: 'without insurance',
  checkout_please_add_payment_method: 'Please add Payment Method',
  checkout_please_add_shipping_address: 'Please add Shipping Address',
  processing: 'Processing',
  product_detail: 'product details',
  product_detail_not_available: 'Product details are not available.',
  checkout_my_shipping_address: 'My Shipping Address',
  no_video_resource: 'No Videos Available',
  no_article_resource: 'No Articles Available',
  articles: 'Articles',
  videos: 'Videos',
  no_appointment_available: 'No Appointments Available.',
  select_another_day: 'Select another day.',
  you_had: 'You had',
  you_missed: 'You missed',
  you_rejected: 'You rejected',
  you_cancelled: 'You cancelled',
  click_here: 'click here',
  basic_info_name: 'Name:',
  basic_info_age: 'Age:',
  basic_info_gender: 'Gender:',
  basic_info_location: 'Location:',
  health_bot_no_search_messages: 'No messages found!',
  health_bot_no_messages: 'No messages',
  health_bot_no_messages_description: 'There are no messages to display at this time.',
  preview_positive: 'Positive',
  preview_negative: 'Negative',
  preview_notsure: 'Not Sure',
  preview_select_test_result: 'Assessment:',
  preview_confirm_result: 'Confirm Result',
  preview_retake: 'Retake',
  preview_equivocal: 'Equivocal',
  your_test_is_being_processed: 'Your test is being processed',
  "can't_find_insurnace_provider_message": "Can't find your insurance provider?\nNo problem!",
  unable_product_detail_not_available: 'Unable to get product details.',
  "i'll_do_it_later": "I'll do it later",
  import_your_health_records_to_save_time:
    'Import your health record to save time and ensures you get the best possible care.',
  change_drop_off_location: 'Change drop-off location',
  no_insurance_details: 'No Insurance details found.',
  hb_accessibility_close: 'Close',
  hb_accessibility_back: 'Back',
  at: 'at',
  wm_settings_title: 'Settings',
  wm_camera_usage_permissions_title: 'Camera Usage Permissions',
  wm_camera_usage_permissions_message:
    'Camera usage permissions must be granted in order to be able to scan orders and test kits.\nGo to settings?',
  wm_scan_order_title: 'Scan Order Code',
  wm_register_test_kit_title: 'Register Test Kit',
  wm_go: 'SUBMIT',
  wm_confirm: 'CONFIRM',
  wm_scan_next: 'SCAN NEXT',
  wm_login_email: 'Email',
  wm_login_password: 'Password',
  wm_enter_order_id: 'Enter Order ID',
  wm_enter_test_id: 'Enter Test ID',
  wm_search_user_by: 'Search User by Name or Email',
  wm_scan_order_message: 'Scan code on E-ticket or enter Order ID',
  wm_registration_confirmation_message: 'Test successfully registered!',
  wm_name: 'Name:',
  wm_date_of_birth: 'D.O.B:',
  wm_sex: 'Sex:',
  wm_order_id: 'Order ID:',
  wm_empty_table_message_default:
    'No results displayed.\nPlease use the search bar above to find a person by name or email.',
  wm_empty_table_message: '0 results found.',
  wm_scan_order_segment: 'Scan Order',
  wm_search_user_segment: 'Search User',
  wm_cant_find_order_error: 'We cannot find the order ID. Please try a different order ID.',
  wm_search_user_placeholder: 'Search Users by Name or Email',
};
