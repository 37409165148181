import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { TreeView } from '@progress/kendo-react-treeview';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectReporterAnswers } from '../../vcb-package/selectors';
import { buildTreeData } from './helpers/helpers';

function Reporter() {
  const [treeData, setTreeData] = useState([]);
  const classes = useStyles();

  const reporterAnswers = useSelector(selectReporterAnswers);

  useEffect(() => {
    if (reporterAnswers && reporterAnswers.length) {
      const updatedTreeData = buildTreeData(reporterAnswers);

      setTreeData(updatedTreeData);
    }
  }, [reporterAnswers]);

  const onItemClick = (event) => {
    event.item.selected = !event.item.selected;
  };

  const onExpandChange = (event) => {
    event.item.expanded = !event.item.expanded;
  };

  return (
    <Grid style={{ maxWidth: '460px' }}>
      {treeData.length ? (
        <TreeView
          data={treeData}
          expandIcons
          onExpandChange={onExpandChange}
          onItemClick={onItemClick}
          aria-multiselectable
          className={classes.root}
        />
      ) : null}
    </Grid>
  );
}

const useStyles = makeStyles(() => ({
  root: {
    '& .k-treeview-lines': {
      paddingLeft: 0,
      paddingBottom: '20px!important',
    },
    '& .k-animation-container': {
      overflow: 'inherit',
    },
  },
}));

export default Reporter;
