// import { introWidgetTypes } from '../../Shared/Content/mapping';

// const categoryList = [
//   {
//     value: "Wellness & Lifestyle",
//     label: "Wellness & Lifestyle",
//   },
//   {
//     value: "Medical Information",
//     label: "Medical Information",
//   },
//   {
//     value: "Disease Prevention",
//     label: "Disease Prevention",
//   },
//   {
//     value: "News and Updates",
//     label: "News and Updates",
//   },
//   {
//     value: "Miscellaneous",
//     label: "Miscellaneous",
//   },
//   {
//     value: "Treatment Information",
//     label: "Treatment Information",
//   },
// ];

export const ContentResourcesFormMap = [
  {
    type: 'groupNoDropdown',
    label: 'DETAILS',
    name: 'DETAILS',
    fields: [
      {
        type: 'text',
        name: 'title',
        label: 'Title',
        required: true,
        width: 10,
      },
      {
        type: 'text',
        name: 'subtitle',
        label: 'Subtitle',
        required: false,
        width: 10,
      },
      {
        type: 'html',
        name: 'html',
        label: 'HTML',
        required: false,
        width: 12,
      },
      {
        type: 'hidden',
        name: '_id',
        label: 'ID',
        required: false,
        width: 6,
      },
      {
        type: 'imageCrop',
        name: 'imageURL',
        required: true,
        label: 'Image',
        width: 9,
      },
      {
        type: 'text',
        name: 'externalLinkURL',
        label: 'External Link URL',
        required: false,
        width: 10,
        disableIfFalse: 'imageURL',
      },
      {
        type: 'video',
        name: 'videoFile',
        label: 'Video File',
        required: false,
        width: 12,
        acceptedFiles: ['.mp4', '.x-m4v'],
      },
      {
        type: 'imageCrop',
        name: 'videoThumbnail',
        label: 'Video Thumbnail',
        required: false,
        width: 10,
        acceptedFiles: ['image/png'],
      },
    ],
  },
  {
    type: 'groupNoDropdown',
    label: 'ARTICLE SETTINGS',
    name: 'ARTICLE_SETTINGS',
    fields: [
      {
        type: 'text',
        name: 'author',
        label: 'Author',
        required: true,
        width: 4,
      },
      {
        type: 'text',
        name: 'owner',
        label: 'Owner',
        required: true,
        width: 4,
      },
      // {
      //   type: "autocomplete",
      //   name: "category",
      //   label: "Category",
      //   required: false,
      //   enum: categoryList,
      //   width: "4",
      //   labelField: "label",
      //   valueField: "value",
      // },
      {
        type: 'text',
        name: 'photoCredit',
        label: 'Photo Credit',
        required: false,
        width: 4,
      },
      {
        type: 'text',
        name: 'videoCredit',
        label: 'Video Credit',
        required: false,
        width: 4,
      },
      {
        type: 'text',
        name: 'copyright',
        label: 'Copyright',
        required: false,
        width: 4,
        allowCustom: true,
      },
      {
        type: 'toggle',
        name: 'publish',
        label: 'Publish',
        required: false,
        width: 4,
      },
      {
        type: 'multiselect',
        name: 'keywords',
        label: 'Keywords',
        required: true,
        width: 12,
        allowCustom: true,
      },
    ],
  },
];

export const ContentWidgetsFormMap = (introWidgetTypes, introWidgetKeys) => [
  {
    type: 'groupNoDropdown',
    label: 'INFORMATION',
    name: 'INFORMATION',
    fields: [
      {
        type: 'hidden',
        name: 'status',
        label: 'Status',
        enum: ['INACTIVE', 'ACTIVE'],
        required: false,
        width: 4,
      },
      {
        type: 'hidden',
        name: 'networkID',
        label: 'Network',
        required: false,
        width: 9,
      },
      {
        type: 'autocomplete',
        name: 'type',
        label: 'Type',
        required: true,
        width: 12,
        enum:
          introWidgetTypes &&
          introWidgetTypes.map((k) => ({
            value: k.value,
            label: k.label,
            isAppIntroWidget: k.value == 'AppResponseWidget',
          })),
        labelField: 'label',
        valueField: 'value',
        setFields: [
          {
            field: 'isAppIntroWidget',
            targetField: 'isAppIntroWidget',
          },
        ],
      },
      {
        type: 'conditional',
        checkField: 'isAppIntroWidget',
        checkValue: false,
        targetType: 'number',
        name: 'order',
        label: 'Order Number',
        required: true,
        width: 12,
        min: 1,
      },
      {
        type: 'conditional',
        checkField: 'isAppIntroWidget',
        checkValue: true,
        targetType: 'autocomplete',
        name: 'widgetKey',
        label: 'Keys',
        required: false,
        width: 12,
        enum:
          introWidgetKeys &&
          introWidgetKeys.map((k) => ({
            value: k.value,
            label: k.label,
          })),
        labelField: 'label',
        valueField: 'value',
      },
    ],
  },
  {
    type: 'groupNoDropdown',
    label: 'UPLOAD IMAGE',
    name: 'UPLOAD_IMAGE',
    fields: [
      {
        type: 'hidden',
        name: '_id',
        label: 'ID',
        required: false,
        width: 6,
      },
      {
        type: 'imageCrop',
        name: 'imageURL',
        required: false,
        width: 9,
      },
      {
        type: 'text',
        name: 'externalLinkURL',
        label: 'External Link URL',
        required: false,
        width: 9,
        disableIfFalse: 'imageURL',
      },
    ],
  },
  {
    type: 'groupNoDropdown',
    label: 'DETAILS',
    name: 'DETAILS',
    fields: [
      {
        type: "html",
        name: "title",
        label: "Title",
        height: 80,
        required: true,
        width: 12,
        variant: 'outlined',
      },
      {
        type: 'html',
        name: 'html',
        label: 'HTML',
        required: false,
        width: 12,
      },
      {
        type: 'clickAwayListner',
        required: false,
        width: 12,
      },
      {
        type: 'text',
        name: 'description',
        label: 'Description',
        required: false,
        width: 6,
      },
      {
        type: 'text',
        name: 'navigationTitle',
        label: 'Navigation Title',
        required: false,
        width: 6,
      },
      {
        type: 'text',
        name: 'backgroundColor',
        label: 'Background Color',
        required: false,
        width: 6,
      },
      {
        type: 'toggle',
        name: 'showSupportLink',
        label: 'Show SupportLink Color',
        required: false,
        width: 6,
        default: false,
        labelPlacement: 'end',
      },
    ],
  },
  {
    type: 'groupNoDropdown',
    label: 'UPLOAD VIDEO',
    name: 'UPLOAD VIDEO',
    fields: [
      {
        type: 'video',
        name: 'videoURL',
        // label: "Video",
        required: false,
        width: 12,
        acceptedFiles: ['video/mp4', 'video/x-m4v', 'video/*'],
      },
    ],
  },
  {
    type: 'dynamicList',
    name: 'buttons',
    label: 'Button',
    required: false,
    width: 12,
    tooltip: 'Add Button',
    buttonType: 'text',
    buttonText: 'Add Button',
    fields: [
      {
        type: 'text',
        name: 'buttonId',
        label: 'Id',
        required: true,
        width: 6,
      },
      {
        type: 'text',
        name: 'label',
        label: 'Label',
        required: true,
        width: 6,
      },
    ],
  },
];
export const ContentMessageStandardizationFormMap = (introWidgetKeys) => [
  {
    type: 'groupNoDropdown',
    label: 'INFORMATION',
    name: 'INFORMATION',
    fields: [
      {
        type: 'hidden',
        name: 'status',
        label: 'Status',
        enum: ['INACTIVE', 'ACTIVE'],
        required: false,
        width: 4,
      },
      {
        type: 'hidden',
        name: 'networkID',
        label: 'Network',
        required: false,
        width: 9,
      },
      {
        type: 'autocomplete',
        name: 'widgetKey',
        label: 'Keys',
        required: false,
        width: 12,
        enum:
          introWidgetKeys &&
          introWidgetKeys.map((k) => ({
            value: k.value,
            label: k.label,
          })),
        labelField: 'label',
        valueField: 'value',
      },
    ],
  },
  {
    type: 'groupNoDropdown',
    label: 'UPLOAD IMAGE',
    name: 'UPLOAD_IMAGE',
    fields: [
      {
        type: 'hidden',
        name: '_id',
        label: 'ID',
        required: false,
        width: 6,
      },
      {
        type: 'imageCrop',
        name: 'imageUrl',
        required: false,
        width: 9,
      },
      {
        type: 'text',
        name: 'externalLinkURL',
        label: 'External Link URL',
        required: false,
        width: 9,
        disableIfFalse: 'imageUrl',
      },
    ],
  },
  {
    type: 'groupNoDropdown',
    label: 'DETAILS',
    name: 'DETAILS',
    fields: [
      {
        type: 'text',
        name: 'title',
        label: 'Title',
        required: true,
        width: 10,
      },
      {
        type: 'html',
        name: 'html',
        label: 'HTML',
        required: false,
        width: 12,
      },
      {
        type: 'clickAwayListner',
        required: false,
        width: 12,
      },
      {
        type: 'text',
        name: 'description',
        label: 'Description',
        required: false,
        width: 6,
      },
      {
        type: 'text',
        name: 'navigationTitle',
        label: 'Navigation Title',
        required: false,
        width: 6,
      },
      {
        type: 'text',
        name: 'backgroundColor',
        label: 'Background Color',
        required: false,
        width: 6,
      },
      {
        type: 'toggle',
        name: 'showSupportLink',
        label: 'Show SupportLink Color',
        required: false,
        width: 6,
        default: false,
        labelPlacement: 'end',
      },
    ],
  },
  {
    type: 'groupNoDropdown',
    label: 'UPLOAD VIDEO',
    name: 'UPLOAD VIDEO',
    fields: [
      {
        type: 'video',
        name: 'videoUrl',
        // label: "Video",
        required: false,
        width: 12,
        acceptedFiles: ['video/mp4', 'video/x-m4v', 'video/*'],
      },
    ],
  },
  {
    type: 'dynamicList',
    name: 'buttons',
    label: 'Button',
    required: false,
    width: 12,
    tooltip: 'Add Button',
    buttonType: 'text',
    buttonText: 'Add Button',
    fields: [
      {
        type: 'text',
        name: 'buttonId',
        label: 'Id',
        required: true,
        width: 6,
      },
      {
        type: 'text',
        name: 'label',
        label: 'Label',
        required: true,
        width: 6,
      },
    ],
  },
];

export const ContentVideosFormMap = [
  {
    type: 'groupNoDropdown',
    label: 'UPLOAD THUMBNAILS',
    name: 'UPLOAD_THUMBNAILS',
    fields: [
      {
        type: 'hidden',
        name: '_id',
        label: 'ID',
        required: false,
        width: 6,
      },
      {
        type: 'hidden',
        name: 'size',
        label: 'Size',
        required: false,
        width: 4,
      },
      {
        type: 'hidden',
        name: 'networkID',
        label: 'Network',
        required: false,
        width: 12,
      },
      {
        type: 'imageCrop',
        name: 'thumbnailURL',
        label: 'Small Thumbnail',
        required: false,
        width: 5,
      },
      {
        type: 'imageCrop',
        name: 'largeImageURL',
        label: 'Large Thumbnail',
        required: false,
        width: 5,
      },
    ],
  },
  {
    type: 'groupNoDropdown',
    label: 'DETAILS',
    name: 'DETAILS',
    fields: [
      {
        type: 'toggle',
        name: 'isResource',
        label: 'Is Resource',
        required: false,
        width: 4,
        disableIfTrue: 'isPrimaryVideo',
        labelPlacement: 'top',
      },
      {
        type: 'toggle',
        name: 'isPrimaryVideo',
        label: 'Primary Resource Video',
        required: false,
        width: 4,
        disableIfTrue: 'isResource',
      },
      {
        type: 'text',
        name: 'title',
        label: 'Title',
        required: true,
        width: 10,
      },
      {
        type: 'html',
        name: 'description',
        label: 'Description',
        required: false,
        width: 10,
      },
      {
        type: 'video',
        name: 'mediaURL',
        label: 'Video File',
        required: false,
        width: 10,
        acceptedFiles: ['.mp4', '.x-m4v'],
      },
    ],
  },
  {
    type: 'groupNoDropdown',
    label: 'VIDEO SETTINGS',
    name: 'VIDEO SETTINGS',
    fields: [
      {
        type: 'text',
        name: 'owner',
        label: 'Owner',
        required: true,
        width: 4,
      },
      // {
      //   type: "autocomplete",
      //   name: "wellness",
      //   label: "Category",
      //   required: false,
      //   enum: categoryList,
      //   width: "6",
      //   labelField: "label",
      //   valueField: "value",
      // },
      {
        type: 'text',
        name: 'urlLink',
        label: 'URL',
        required: false,
        width: 4,
      },
      {
        type: 'text',
        name: 'embedCode',
        label: 'Embed Code',
        required: false,
        width: 4,
      },
      {
        type: 'toggle',
        name: 'featured',
        label: 'Featured',
        required: false,
        width: 3,
        labelPlacement: 'end',
      },
      {
        type: 'toggle',
        name: 'publish',
        label: 'Publish',
        required: false,
        width: 3,
        labelPlacement: 'end',
      },
      {
        type: 'multiselect',
        name: 'keywords',
        label: 'Keywords',
        required: true,
        width: 6,
        allowCustom: true,
      },
    ],
  },
];
