import { emailRegex } from '../../../Shared/Forms/helpers/contants';
import { stateList, countryList } from '../../../Shared/Forms/helpers/stateCountryHelper';

import { tenantTypeMapping } from './mapping';

export const TenantResourcesFormMap = (channels) => [
  {
    type: 'group',
    label: 'TENANT INFORMATION',
    name: 'TENANT_INFORMATION',
    fields: [
      {
        type: 'text',
        name: 'companyName',
        label: 'Full Company Name',
        required: true,
        width: 4,
      },
      {
        type: 'text',
        name: 'title',
        label: 'Tenant Company Name',
        required: true,
        width: 4,
      },
      {
        type: 'dropdown',
        name: 'tenantType',
        label: 'Tenant Type',
        enum: tenantTypeMapping,
        required: true,
        width: 4,
        labelField: 'label',
        valueField: 'value',
      },
      {
        type: 'dropdown',
        name: 'channelPartnerId',
        label: 'Channel Partner',
        enum: [{ _id: 'none', name: 'None' }, ...channels].map((n) => ({
          value: n._id,
          label: n.name,
        })),
        required: false,
        width: 4,
        labelField: 'label',
        valueField: 'value',
      },
      {
        type: 'spacer',
      },
      // {
      //   type: "text",
      //   name: "portalDomain",
      //   label: "Portal Domain",
      //   required: false,
      //   width: "4",
      // },
      {
        type: 'text',
        name: 'addressLine1',
        label: 'Address Line 1',
        required: false,
        width: 4,
      },
      {
        type: 'text',
        name: 'addressLine2',
        label: 'Address Line 2',
        required: false,
        width: 4,
      },
      {
        type: 'text',
        name: 'city',
        label: 'City',
        required: false,
        width: 4,
      },
      {
        type: 'autocomplete',
        name: 'state',
        label: 'State',
        enum: stateList,
        required: false,
        width: 4,
        labelField: 'name',
        valueField: 'abbreviation',
      },
      {
        type: 'text',
        name: 'zipCode',
        label: 'Zip',
        required: false,
        width: 4,
        maxLength: 5,
        pattern: '^[0-9]{5}$',
      },
      {
        type: 'autocomplete',
        name: 'country',
        label: 'Country',
        enum: countryList,
        required: false,
        width: 4,
      },
    ],
  },
  {
    type: 'group',
    label: 'ROOT ADMINISTRATOR INFORMATION',
    name: 'ROOT_ADMINISTRATOR_INFORMATION',
    fields: [
      {
        type: 'text',
        name: 'primaryContactFirstName',
        label: 'Primary Contact First Name',
        required: true,
        width: 4,
      },
      {
        type: 'text',
        name: 'primaryContactLastName',
        label: 'Primary Contact Last Name',
        required: true,
        width: 4,
      },
      {
        type: 'text',
        name: 'primaryContactEmail',
        label: 'Primary Contact Email',
        required: true,
        width: 4,
        maxLength: 250,
        pattern: emailRegex,
      },
      {
        type: 'phone',
        name: 'primaryContactMobileNumber',
        code: 'primaryContactMobileCountryCode',
        label: 'Mobile Number',
        required: true,
        width: 4,
      },
      {
        type: 'phone',
        name: 'primaryContactWorkNumber',
        code: 'primaryContactWorkCountryCode',
        label: 'Work Number',
        required: true,
        width: 4,
      },
    ],
  },
  {
    type: 'group',
    label: 'TENANT MEMBER SUPPORT',
    name: 'TENANT_SUPPORT',
    fields: [
      {
        type: 'text',
        name: 'supportEmail',
        label: 'Support Email',
        required: false,
        width: 4,
        maxLength: 250,
        pattern: emailRegex,
      },
      {
        type: 'text',
        name: 'privacyEmail',
        label: 'Privacy Email',
        required: false,
        width: 4,
        maxLength: 250,
        pattern: emailRegex,
      },
      {
        type: 'text',
        name: 'supportPhoneNumber',
        label: 'Support Phone',
        required: false,
        width: 4,
        mask: '(999) 999-9999',
        pattern: '^(?!(\\d)\\1{9})(?!0123456789|1234567890|0987654321|9876543210)\\d{10}$',
      },
      {
        type: 'text',
        name: 'incomingFaxNumber',
        label: 'Incoming Fax',
        required: false,
        width: 4,
        mask: '(999) 999-9999',
        pattern: '^(?!(\\d)\\1{9})(?!0123456789|1234567890|0987654321|9876543210)\\d{10}$',
      },
      {
        type: 'text',
        name: 'directAddress',
        label: 'Direct Address',
        required: false,
        width: 4,
        maxLength: 500,
      },
    ],
  },
  {
    type: 'group',
    label: 'SETTINGS',
    name: 'SETTINGS',
    fields: [
      {
        type: 'toggle',
        name: 'labOrderCollectInsuranceInformation',
        label: 'Collect Insurance Information',
        required: false,
        width: 4,
        labelPlacement: 'end',
      },
      {
        type: 'toggle',
        name: 'labOrderCoverPatientResponsibility',
        label: 'Cover Patient Responsibility',
        required: false,
        width: 4,
        labelPlacement: 'end',
      },
      {
        type: 'toggle',
        name: 'labOrderShowDedicatedAgreementScreen',
        label: 'Show Dedicated Agreement Screen',
        required: false,
        width: 4,
        labelPlacement: 'end',
      },
      {
        type: 'toggle',
        name: 'labOrderRequireSignature',
        label: 'Require Patient Signature',
        required: false,
        width: 4,
        labelPlacement: 'end',
      },
    ],
  },
];

// sets step to it's correctly formatted JSON
export const tenantSteps = (channels) => [
  {
    label: 'Tenant',
    data: TenantResourcesFormMap(channels),
  },
];
