import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Dialog } from '@progress/kendo-react-dialogs';
import React, { useState } from 'react';

import QuestionnaireVariablesStyles from '../../../../assets/jss/components/QuestionnaireVariablesStyles';

const defaultVariableModel = {
  name: '',
  value: '',
};

function QuestionnaireVariableDialog(props) {
  const { editingVariable, onCloseDialog, onHandleUpdateVariable } = props;

  const [variableData, setVariableData] = useState(editingVariable || defaultVariableModel);

  const handleClose = () => {
    onCloseDialog();
  };

  const handleSave = () => {
    onHandleUpdateVariable(variableData);
  };

  const updateVariable = (paramName, e) => {
    const { value } = e.target;
    const updatedVariable = { ...variableData };
    updatedVariable[paramName] = value;
    setVariableData(updatedVariable);
  };

  return (
    <Dialog title="Add New Variable" onClose={handleClose}>
      <Grid container justifyContent="flex-end">
        <Grid container alignItems="flex-end">
          <Grid item xs={12}>
            <TextField
              id="input-with-icon-grid"
              name="name"
              margin="normal"
              label="Name"
              onChange={(e) => updateVariable('name', e)}
              value={variableData.name}
              autoComplete="off"
              autoFocus
              type="text"
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              id="input-with-icon-grid"
              name="value"
              margin="normal"
              label="Value"
              onChange={(e) => updateVariable('value', e)}
              value={variableData.value}
              autoComplete="off"
              type="text"
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid container justifyContent="center" style={{ position: 'relative', marginTop: '20px' }}>
          <Button
            disabled={!variableData || !variableData.name || !variableData.value}
            color="primary"
            variant="contained"
            onClick={handleSave}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
}

export default withStyles(QuestionnaireVariablesStyles)(QuestionnaireVariableDialog);
