// redux
import _ from 'lodash';
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { paths } from '../../constants';
import { selectTenantNetwork } from '../Networks/selectors';
import { resetForm } from '../Shared/Forms/formSlice';
import { selectFormData } from '../Shared/Forms/selectors';
// kendo
import { selectCurrentTenant } from '../Shared/selectors';
import AppKendoTableWrapper from '../Shared/Table/KendoTableWrapper';
import utils from '../Shared/Utils/utils';

import { initialKendoState, roleMappings, UserRoleId } from './helpers/mappings';
import { columns, tableButtons } from './helpers/usersListMapper';
import {
  selectCurrentUserPermissions,
  selectInvitedUsers,
  selectInvitedUsersLoading,
  selectTenantUserTotalsLoading,
} from './selectors';
import UserCreationDialog from './UserCreationDialog';
import { setUserPaginationState, getInvitedUsers } from './usersSlice';

function ActiveUserList({ userType }) {
  const currentNetwork = useSelector(selectTenantNetwork);
  const invitedUsersLoading = useSelector(selectInvitedUsersLoading);
  const userTotals = useSelector(selectTenantUserTotalsLoading);
  const invitedUsers = useSelector(selectInvitedUsers);
  const currentTenant = useSelector(selectCurrentTenant);
  const currentUser = useSelector(selectCurrentUserPermissions);
  const formData = useSelector(selectFormData) || {};
  const [kendoPaginationState, setKendoPaginationState] = useState(initialKendoState);
  // eslint-disable-next-line
  const [searchState, setSearchState] = useState(kendoPaginationState.search);
  const tenantSetting = useSelector(selectTenantNetwork);

  const [userCreationDialogOpen, setUserCreationDialogOpen] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Place Kendo state into the store so it is available in actions
  useEffect(() => {
    dispatch(setUserPaginationState(kendoPaginationState));
  }, [kendoPaginationState]);

  // handles all table updates from table wrapper
  // Added a debounce when filtering the User List
  const handleTableUpdate = _.debounce((page, pageSize, sort, direction, searchValue, filter) => {
    // Added default sort
    if (!sort) {
      sort = 'created';
      direction = 'DESC';
    }
    let sortValue = sort;
    if (sort === 'inviteDate') {
      sortValue = 'created';
    }

    setKendoPaginationState({
      sort: [{ field: sort, dir: direction }],
      take: pageSize,
      skip: page,
      search: searchValue,
      filter,
    });

    dispatch(getInvitedUsers(currentTenant.id, page, pageSize, searchValue.trim(), sortValue, direction.toUpperCase()));
  }, 500);

  useEffect(() => {
    dispatch(
      getInvitedUsers(
        currentTenant.id,
        kendoPaginationState?.skip,
        kendoPaginationState?.take,
        kendoPaginationState?.search,
        kendoPaginationState?.sort[0]?.field,
        kendoPaginationState.sort[0]?.dir?.toUpperCase(),
        kendoPaginationState?.filter
      )
    );
  }, [currentTenant]);

  // onRowClick function in table
  // eslint-disable-next-line
  const handleRowClick = useCallback(
    (e) => {
      navigate(paths.profileDetails(currentTenant.id, e.email));
    },
    [currentTenant]
  );

  // Filters to pass to table wrapper
  const columnList = useMemo(
    () =>
      columns(
        null,
        currentUser && currentUser.permission,
        tenantSetting && tenantSetting.requireGuardianMode,
        currentNetwork?.isSAFEPassportEnabled,
        currentNetwork?.verifyVaccinationSettings?.enableVaccinationRecord
      )[userType],
    [currentUser]
  );

  // Buttons to pass to table wrapper - must contain title, button type and variant type
  const buttons = tableButtons(currentTenant, setUserCreationDialogOpen);

  const data = {
    total: invitedUsers && invitedUsers.total,
    pageSize: invitedUsers && invitedUsers.pageSize,
    items:
      invitedUsers &&
      invitedUsers.data &&
      invitedUsers.data.map((user) => {
        const userRole = _.invert(UserRoleId)[user.permission];
        const b = {
          ...user,
          // permission: Object.assign({}, ...Object.entries(UserRoleId).map(([a,b]) => ({ [b]: a })))[user.permission],
          permission: user.permission,
          permissionValue: roleMappings.find((i) => i.id === userRole).label,
          // permission: _.invert(UserRoleId)[user.permission],
          inviteDate: utils.formatDisplayDate(user.created),
        };
        return b;
      }),
  };

  const onCloseDialog = () => {
    setUserCreationDialogOpen(false);
    dispatch(resetForm());
  };

  return (
    <>
      <AppKendoTableWrapper
        data={data}
        sortingMode="single"
        initialSort={initialKendoState.sort[0].field}
        initialDir={initialKendoState.sort[0].dir}
        searchInState={searchState}
        showSearchbar
        showOpenFilters={false}
        searchAdornmentPos="end"
        showButtons
        tableButtons={buttons}
        columns={columnList}
        currentTenant={currentTenant}
        loading={invitedUsersLoading || userTotals}
        // onRowClick={handleRowClick}
        onTableUpdate={handleTableUpdate}
      />
      {userCreationDialogOpen && <UserCreationDialog closeDialog={onCloseDialog} formData={formData} />}
    </>
  );
}

export default ActiveUserList;
