import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState, useEffect, useCallback } from 'react';

// redux
import { useDispatch, useSelector } from 'react-redux';

import ProfileViewStyles from '../../assets/jss/components/ProfileViewStyles';
import { selectCurrentTenant } from '../Shared/selectors';
import AppKendoTableWrapper from '../Shared/Table/KendoTableWrapper';
import utils from '../Shared/Utils/utils';

import { selectUserDetails } from '../Users/selectors';
import AllergyDialog from './AllergyDialog';

// mui
import { allergyColumns } from './helpers/clinicalInfoMapper';
import { getAllergyList, postAllergyList, postAllergyToDrfirst } from './slices/allergyListSlice';
import { selectAllergyList, selectAllergyListLoading } from './slices/selectors';

function Allergy() {
  const classes = useStyles();

  const userDetails = useSelector(selectUserDetails);
  const currentTenant = useSelector(selectCurrentTenant);

  const allergyList = useSelector(selectAllergyList);
  const allergyListLoading = useSelector(selectAllergyListLoading);

  const [pagedAllergyList, setPagedAllergyList] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedAllergy, setSelectedAllergy] = useState({});
  const [dialogMode, setDialogMode] = useState('Add');
  const [tableButtons, setTableButtons] = useState([]);

  const dispatch = useDispatch();

  const sentAllergyToDrfirst = useCallback(() => {
    dispatch(postAllergyToDrfirst(userDetails.nationalHealthId));
  }, [userDetails.nationalHealthId]);

  const handleRowClick = (e) => {
    const selected = pagedAllergyList ? pagedAllergyList.find((i) => i.id === e.id) : e;
    const selectedAllergy = convertAllergyToAllergyObj(selected);
    setSelectedAllergy(selectedAllergy);
    setDialogMode('Edit');
    setDialogOpen(true);
  };
  const convertAllergyToAllergyObj = (allergy) => {
    const allergyObj = {
      id: allergy.id,
      allergyName: allergy.allergyCode ? allergy.allergyCode.display : '',
      allergyCode: allergy.allergyCode ? allergy.allergyCode.codeValue : '',
      allergySystem: allergy.allergyCode ? allergy.allergyCode.system : '',
      type: allergy.type,
      allergyCategory: allergy.allergyCategory,
      clinicalStatus: allergy.clinicalStatus,
      verificationStatus: allergy.verificationStatus,
      onsetDate: utils.formatDateNoLocale(allergy.onsetDate),
      reactionName: allergy.reaction ? allergy.reaction.displayName : '',
      reactionCode: allergy.reaction ? allergy.reaction.codeValue : '',
      reactionSeverity: allergy.reaction ? allergy.reaction.severity : '',
      symptomsCode: allergy.symptomsCode,
      symptomsValue: allergy.symptomsValue,
      createdBy: allergy.createdBy,
      createdAt: allergy.createdAt,
      operationType: allergy.operationType,
      rcopiaId: allergy.rcopiaId,
    };
    return allergyObj;
  };

  const convertAllergyObjToAllergy = (allergyObj) => {
    const allergy = {
      id: allergyObj.id,
      allergyCode: {
        display: allergyObj.allergyName,
        codeValue: allergyObj.allergyCode,
        system: allergyObj.allergySystem,
      },
      type: 'Allergy',
      allergyCategory: allergyObj.allergyCategory,
      clinicalStatus: allergyObj.clinicalStatus,
      verificationStatus: allergyObj.verificationStatus,
      onsetDate: utils.formatDateNoLocale(allergyObj.onsetDate),
      reaction: {
        displayName: allergyObj.reactionName,
        codeValue: allergyObj.reactionCode,
        severity: allergyObj.reactionSeverity,
      },
      symptomsCode: allergyObj.symptomsCode,
      symptomsValue: allergyObj.symptomsValue,
      createdBy: allergyObj.createdBy,
      createdAt: allergyObj.createdAt,
      operationType: allergyObj.operationType,
      rcopiaId: allergyObj.rcopiaId,
    };
    return allergy;
  };

  const handleUpdateAllergy = (allergyObj) => {
    const allergy = convertAllergyObjToAllergy(allergyObj);
    const allergyList = {
      patientDetailDTOList: [allergy],
      safeEMRId: userDetails.nationalHealthId,
      resourceType: 'Allergy',
    };
    dispatch(postAllergyList(userDetails.nationalHealthId, allergyList));
  };

  const handleCloseDialog = () => {
    setSelectedAllergy({});
    setDialogMode('Add');
    setDialogOpen(false);
  };
  const handleTableUpdate = (page, pageSize) => {
    setPagedAllergyList(allergyList.data.slice((page + 1) * pageSize - pageSize, (page + 1) * pageSize));
  };
  useEffect(() => {
    dispatch(getAllergyList(userDetails.nationalHealthId));
  }, [currentTenant, userDetails]);
  useEffect(() => {
    if (allergyList) {
      setPagedAllergyList(allergyList.data);
      if (allergyList.data) {
        setTableButtons([
          {
            title: 'Add New',
            buttonType: 'action',
            variant: 'outlined',
            action: () => {
              setDialogOpen(true);
            },
          },
          {
            title: 'Sync Drfirst',
            buttonType: 'action',
            variant: 'outlined',
            disabled: allergyList && allergyList.data.length <= 0,
            action: () => {
              sentAllergyToDrfirst();
            },
          },
        ]);
      }
    }
  }, [allergyList, sentAllergyToDrfirst]);

  const columns = allergyColumns();

  return (
    <Grid className={`${classes.contentWrapper} ${classes.withTableButtons}`}>
      <AppKendoTableWrapper
        data={{
          ...pagedAllergyList,
          items:
            pagedAllergyList &&
            pagedAllergyList.map((n) => ({
              ...n,
              allergy: n.allergyCode ? n.allergyCode.display : '',
              onsetDate: utils.formatDateNoLocale(n.onsetDate),
              clinicalStatus: n.clinicalStatus,
              verificationStatus: n.verificationStatus,
              reaction: n.reaction ? n.reaction.displayName : '',
              severity: n.reaction ? n.reaction.severity : '',
              type: n.type,
              code: n.reaction ? n.reaction.codeValue : '',
            })),
          total: allergyList && allergyList.data && allergyList.data.length,
        }}
        initialSort="onsetDate"
        loading={allergyListLoading}
        initialDir="desc"
        columns={columns}
        onTableUpdate={handleTableUpdate}
        showButtons
        onRowClick={handleRowClick}
        tableButtons={tableButtons}
      />
      {dialogOpen && (
        <AllergyDialog
          selectedAllergy={selectedAllergy}
          dialogMode={dialogMode}
          closeDialog={handleCloseDialog}
          updateAllergy={handleUpdateAllergy}
        />
      )}
    </Grid>
  );
}

const useStyles = makeStyles(ProfileViewStyles);

export default Allergy;
