/* eslint-disable react/no-array-index-key */
import moment from 'moment';
import React from 'react';

import utils from '../../Shared/Utils/utils';

export const encounterJsonBuilder = (encounterData) => {
  const data = {
    encounterId: encounterData._id,

    // //patient data
    // patientId: "48894",
    // patientName: "Tajada Coley",
    // patientGender: "FEMALE",
    // patientDOB: "01/08/1995",
    // patientPhone: "(973)862-3512",
    // patientEmail: "tajadaccoley@gmail.com",

    // patient data
    patientId: '*48894',
    patientName: `${encounterData?.users?.firstName || '-'} ${encounterData?.users?.lastName || '-'}`,
    patientGender: encounterData?.users?.gender || '-',
    patientDOB: encounterData?.users?.birthDate ? moment(encounterData?.users?.birthDate).format('MM/DD/YYYY') : '-',
    patientPhone: `${encounterData?.users?.contact.mobileCountryCode || '-'} ${
      encounterData?.users?.contact.mobileNumber || '-'
    }`,
    patientEmail: encounterData?.users?.email || '-',

    // physician data
    // physicianName: "Dr. Chet Tharpe, MD",
    // physicianSpecialty: "None*",
    // physicianAddress: "6051 Grand Council Street, na Charleston, SC 29492",
    // physicianPhone: "(601)559-6188",
    physicianId: encounterData?.provider._id || '-',
    physicianEmail: encounterData?.provider.contactInformationPrimaryEmail || '-',
    physicianName: `${encounterData?.provider.firstName || '-'} ${encounterData?.provider.lastName || '-'}`,
    physicianFirstName: encounterData?.provider.firstName || '-',
    physicianLastName: encounterData?.provider.lastName || '-',
    physicianSpecialty: 'None*',
    physicianAddress: `${encounterData?.provider.homeAddressAddressOne}, 
      ${encounterData?.provider.homeAddressAddressCity}, 
      ${encounterData?.provider.homeAddressCountry}, ${encounterData?.provider.homeAddressState} 
      ${encounterData?.provider.homeAddressZip}`,
    physicianPhone: encounterData?.provider.contactInformationPrimaryMobilePhone || '-',

    // encounter details
    encounterType: 'Intake & Order',
    encounterStatus: 'Pending Interpretation',
    encounterPriority: 'Normal',
    encounterDeadline: '05/28/2021',
    encounterClientAccountNo: '',

    // lab reporter
    specimenId: 'CZ096394B',
    labRef: '15273',
    collected: '05/27/2021 17:38',
    received: '05/28/2021 03:33',
    reported: '05/29/2021 04:04',
    laboratoryDirector: 'Director`s Name',
    labOrderComments: 'Self',

    // test list
    testList: [
      {
        testName: 'HEPATITIS C AB W/REFL TO HCV RNA, QN, PCR^SERUM',
        interpretation: 'NEGATIVE',
        reportStatus: 'Final',
        testAnalyte: 'INDEX',
        testResultValue: 'NON-REACTIVE',
        testFlag: 'Normal',
        testUnits: 'unt',
        testRefRange: 'NON-REACTIVE',
        testLab: 'lab',
        testAnalyteNotes: 'testAnalyteNotes',
        testLabNotes: 'lab notes',
        testInterpretation: 'NEGATIVE',
        testNotes: 'None',
        specimenId: 'CZ096394B',
        specimenLabRef: '15273',
        specimenCollected: '05/29/2021 04:04',
        specimenPerformingSite: 'Performing Site',
        specimenOrderingPhysician: 'Physician',
      },
      {
        testName: 'HSV 2 IGG, TYPE SPECIFIC AB',
        interpretation: 'PENDING INTERPRETATION',
        reportStatus: 'Final',
        testAnalyte: 'INDEX',
        testResultValue: 'NON-REACTIVE',
        testFlag: 'Normal',
        testUnits: 'unt',
        testRefRange: 'NON-REACTIVE',
        testLab: 'lab',
        testAnalyteNotes: 'testAnalyteNotes',
        testLabNotes: 'lab notes',
        testInterpretation: 'NEGATIVE',
        testNotes: 'None',
        specimenId: 'CZ096394B',
        specimenLabRef: '15273',
        specimenCollected: '05/29/2021 04:04',
        specimenPerformingSite: 'Performing Site',
        specimenOrderingPhysician: 'Physician',
      },
    ],
    // notes:
    //   encounterData.notes && encounterData.notes.length
    //     ? encounterData.notes.map((note) => ({
    //         noteStatus: "No Response",
    //         noteText: note.text,
    //         noteDate: moment(note.created).format("DD/MM/YYYY HH:mm:ss"),
    //       }))
    //     : [],

    notes: encounterData?.notes || [],
  };

  return data;
};

export const getTestData = () => [
  {
    testName: 'HSV 2 IGG, TYPE SPECIFIC AB',
    interpretation: 'NEGATIVE',
    reportStatus: 'Final',
    specimenId: 'CZ096394B',
    specimenLabRef: '15273',
    specimenCollected: '05/29/2021 04:04',
    specimenPerformingSite: 'Performing Site',
    specimenLaboratoryDirector: 'Physician',
    testAnalyteList: [
      {
        testAnalyte: 'INDEX1',
        testResultValue: 'NON-REACTIVE1',
        testFlag: 'Normal1',
        testUnits: 'unt1',
        testRefRange: 'NON-REACTIVE1',
        testLab: 'lab1',
        testAnalyteNotes: 'testAnalyteNotes1',
        testLabNotes: 'lab notes1',
        testInterpretation: 'NEGATIVE1',
        testNotes: 'None1',
      },
      {
        testAnalyte: 'INDEX2',
        testResultValue: 'NON-REACTIVE2',
        testFlag: 'Normal2',
        testUnits: 'unt2',
        testRefRange: 'NON-REACTIVE2',
        testLab: 'lab2',
        testAnalyteNotes: 'testAnalyteNotes2',
        testLabNotes: 'lab notes2',
        testInterpretation: 'NEGATIVE2',
        testNotes: 'None2',
      },
    ],
  },
];

export const userDataJsonBuilder = (userData) => {
  const data = {
    // patient data
    patientId: '*48894',
    patientName: `${userData?.users?.firstName} ${userData?.users?.lastName}`,
    patientGender: userData?.users?.gender,
    patientDOB: moment(userData?.users?.birthDate).format('MM/DD/YYYY'),
    patientPhone: `${userData?.users?.contact.mobileCountryCode} ${userData?.users?.contact.mobileNumber}`,
    patientEmail: userData?.users?.email,
  };

  return data;
};

export const labOrderJsonBuilder = (labOrderData) => {
  const data = labOrderData.map((labOrder) => {
    const analyteItems = [];
    const analytes = labOrder.analyteObservationList;

    analytes.forEach((analyte) => {
      if (
        !(analyte.resultValue && analyte.resultValue.value) ||
        (analyte.resultValue && analyte.resultValue.value && !analyte.resultValue.value.includes('DNR'))
      ) {
        if (analyte.code) {
          const analyteName = analyte.code.find((x) => x.system.includes('Vendorresulttestid'));

          const analyteItemData = {};
          analyteItemData.testAnalyte = (analyteName && analyteName.display) || '---';

          analyteItemData.testResultValue =
            analyte.resultValue && analyte.unit
              ? `${analyte.resultValue.value} ${analyte.unit}`
              : analyte.resultValue.value || '---';

          analyteItemData.testFlag = analyte.abnormalFlag || '---';
          analyteItemData.testUnits = analyte.unit || '---';
          analyteItemData.testRefRange = analyte.referenceRange || '---';

          analyteItemData.testAnalyteNotes = analyte.comments
            ? analyte.comments.split('\n').map((i, key) =>
                !(!i || false || i === ' ' || i.length === 0) ? (
                  <div key={key}>{i}</div>
                ) : (
                  <div style={{ marginBottom: '12px' }} key={key}>
                    {i}
                  </div>
                )
              )
            : '---';

          analyteItemData.testLab = '*lab';
          analyteItemData.testLabNotes = '*lab notes';
          analyteItemData.testInterpretation = '*NEGATIVE';
          analyteItemData.testNotes = '*None';

          analyteItems.push(analyteItemData);
        }
      }
    });

    return {
      testName: labOrder.code.find((x) => x.system.includes('vendortestid'))?.display || '---',
      interpretation: labOrder.interpretationValue,
      reportStatus: labOrder.status,

      // Specimen details
      specimenId: (labOrder.specimenDetailDTO && labOrder.specimenDetailDTO.specimenId) || '---',
      specimenLabRef: labOrder.code.find((x) => x.system.includes('vendortestid'))?.codeValue || '---',
      specimenCollected:
        labOrder.specimenDetailDTO && labOrder.specimenDetailDTO.collectionDateTime
          ? utils.formatDateTimeLocale(labOrder.specimenDetailDTO.collectionDateTime)
          : '---',
      specimenPerformingSite: labOrder.performingLabDetail
        ? labOrder.performingLabDetail.labPerformingSiteName
        : '---' || '---',

      specimenLaboratoryDirector:
        labOrder.performingLabDetail && labOrder.performingLabDetail.labDirector
          ? `${labOrder.performingLabDetail.labDirector.firstName} ${labOrder.performingLabDetail.labDirector.lastName}`
          : '---' || '---',

      // Analyte details
      testAnalyteList: analyteItems,
    };
  });

  return data;
};
