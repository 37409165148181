/* eslint-disable no-unused-vars */
import React, { useCallback, useState, useMemo } from 'react';
import { connect, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// redux
import { bindActionCreators } from 'redux';
// kendo
import { paths } from '../../../constants';
import { selectCurrentTenant } from '../../Shared/selectors';
import AppKendoTableWrapper from '../../Shared/Table/KendoTableWrapper';
import { selectAllUsers, selectAllUsersLoading, selectCurrentUserPermissions } from '../../Users/selectors';

import { initialSorting, columns } from './helpers/outcomeListMapper';
import * as monitoringActions from './MonitoringActions';

function Users() {
  const allUsersLoading = useSelector(selectAllUsersLoading);
  const allUsers = useSelector(selectAllUsers);
  const currentTenant = useSelector(selectCurrentTenant);
  const currentUser = useSelector(selectCurrentUserPermissions);

  let navigate = useNavigate();
  const [userSegmentValues] = useState([]);

  // handles all table updates from table wrapper
  // TODO: This method is not returning anything yet it is passed to a parameter prop
  const handleTableUpdate = () => {};

  // onRowClick function in table
  const handleRowClick = useCallback(
    (e) => {
      navigate(paths.profileDetails(currentTenant.id, e.email));
    },
    [currentTenant]
  );

  // Filters to pass to table wrapper
  const columnList = useMemo(
    () => columns(userSegmentValues, currentUser && currentUser.permission),
    [currentUser, userSegmentValues]
  );
  const filters = columnList && columnList.filter((c) => c.filter);

  const data = {
    total: allUsers && allUsers.total,
    pageSize: allUsers && allUsers.pageSize,
    items: [],
  };
  //
  return (
    <AppKendoTableWrapper
      data={data}
      initialSort={initialSorting.field}
      initialDir={initialSorting.dir}
      showSearchbar
      filters={filters}
      columns={columnList}
      currentTenant={currentTenant}
      loading={allUsersLoading}
      onRowClick={handleRowClick}
      onTableUpdate={handleTableUpdate}
      disableAutoFocus
    />
  );
}

function mapDispatchToProps(dispatch) {
  return {
    monitoringActions: bindActionCreators(monitoringActions, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(Users);
