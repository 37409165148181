// components and helpers

// mui
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import React, { useMemo } from 'react';

import CreateFormStyles from '../../../assets/jss/components/CreateFormStyles';
import CreateFormWrapper from '../../Shared/Forms/CreateFormWrapper';

import { outcomeFormMap, outcomeChildren } from './helpers/formHelper';

function OutcomeFormWrapper(props) {
  const { onSubmit, onDelete, formData, type, details, activeLabTests, handleCancel } = props;

  // sets step to it's correctly formatted JSON
  const steps = useMemo(
    () => [
      {
        label: type,
        data: outcomeFormMap(formData, activeLabTests),
      },
    ],
    [type, formData, activeLabTests]
  );

  // Allows form to implement logic when rendering child items
  const getOutcomeChildren = (field, itemValues) => outcomeChildren[field.fieldset](itemValues, activeLabTests);

  return (
    <Paper style={{ padding: 20, borderRadius: 20 }} elevation={0}>
      <Grid item xs={12}>
        <CreateFormWrapper
          steps={steps}
          handleSubmit={onSubmit}
          handleDelete={onDelete}
          handleCancel={handleCancel}
          formType={type === 'questionnaires' ? 'outcomeDetail' : details && 'editContent'}
          initialValues=""
          getChildren={getOutcomeChildren}
        />
      </Grid>
    </Paper>
  );
}

export default withStyles(CreateFormStyles)(OutcomeFormWrapper);
