import { createSlice } from '@reduxjs/toolkit';

import api from '../../api';
import { handleError } from '../Shared/Content/APIUtils';

const initialState = {
  encounterList: null,
  // encounterList: [
  //   {
  //     id: 1,
  //     photo: "",
  //     fullName: "Loga Puni",
  //     encounterType: "Intake & Order",
  //     date: "09/17/2020",
  //     assignee: "Monika Dixit",
  //     state: "AL",
  //     priority: "Normal",
  //     status: "1",
  //   },
  //   {
  //     id: 2,
  //     photo: "",
  //     fullName: "Random Reddy",
  //     encounterType: "Intake & Order",
  //     date: "03/17/2020",
  //     assignee: "Monika Dixit",
  //     state: "CA",
  //     priority: "Normal",
  //     status: "2",
  //   },
  // ],
  encounterDetails: null,
  // encounterDetails:
  // {
  //   users: {
  //     accountId: "healthcheck",
  //     billingAddress: {
  //       city: "Ply",
  //       country: "USA",
  //       line1: "123 Test",
  //       state: "MI",
  //       zip: "48170",
  //     },
  //     birthDate: "2000-07-08T00:00:00Z",
  //     contact: {
  //       faxCode: "+1",
  //       homeCountryCode: "+1",
  //       mobileCountryCode: "+1",
  //       mobileNumber: "5862295898",
  //       mobileNumberverified: false,
  //       workCountryCode: "+1",
  //     },
  //     email: "miroslav@safehealth.me",
  //     emailStatus: "valid",
  //     firstName: "miroslav",
  //     gender: "OTHER",
  //     lastName: "tester",
  //     metadata: {
  //       fingerprint: "32008US06037",
  //       "_covid19-status": "SYMPTOMATIC",
  //       _auth0_user_id: "auth0|5f2b0d461718e40038d52558",
  //       "User Segment": "Demo",
  //     },
  //     notificationOnRegistrationSent: false,
  //     primaryAddress: {
  //       city: "NY",
  //       country: "United States",
  //       line1: "123 Test",
  //       state: "AL",
  //       zip: "55555",
  //     },
  //     rootAdminAccount: false,
  //     settings: {
  //       acceptEmailNotification: false,
  //       acceptMessageNotification: false,
  //       acceptSMSNotification: false,
  //       badgeSharingEnabled: false,
  //       biometricEnabled: false,
  //       firstTimeLogin: false,
  //       verificationStatus: "NOTVERIFIED",
  //     },
  //     shippingAddress: {
  //       city: "Ply",
  //       country: "USA",
  //       line1: "123 Test",
  //       state: "MI",
  //       zip: "48170",
  //     },
  //     superhero: false,
  //     tenantId: "health",
  //     nationalHealthId: "0097000043478",
  //     _class: "com.safe.domain.user.User",
  //     _id: "474b06f7-6dd2-40d8-9868-5ca2c16f698c",
  //     created: "2020-08-05T19:49:24.669Z",
  //     creator: "kriti@safehealth.me",
  //     modified: "2020-10-23T05:58:27.211Z",
  //     modifier: "miroslav@safehealth.me",
  //   },
  //   provider: {
  //     safeUniqueID: "P63731816233477873830",
  //     userUUID: "07bace54-759e-4db5-ba94-de4ba29bc47d",
  //     providerType: "INDIVIDUAL",
  //     providerUserType: "PLATFORM_PROVIDER",
  //     isSafeProvider: true,
  //     doesProviderWantsToBeSAFEPlatformProvider: true,
  //     payTierType: "TIER_1",
  //     passwordType: "PERMANENT",
  //     safeApplicationStatus: "APPLIED_APPROVED",
  //     providerPresence: "ONLINE",
  //     incompleteEncounters: 78,
  //     isPhoneVerified: false,
  //     isIDVerified: "VERIFIED",
  //     firstName: "Michael",
  //     middleName: "Leroy",
  //     lastName: "Brown",
  //     suffix: "II",
  //     sex: "OTHER",
  //     dateOfBirth: "1984-07-16T00:00:00Z",
  //     ssn: 0,
  //     shortSSN: 0,
  //     languages: "EN",
  //     identificationDocumentURLs: [],
  //     practiceStates: ["CA"],
  //     contactInformationPrimaryEmail: "vukasin@gtv.com",
  //     contactInformationPersonalEmail: "vukasin@gtv.com",
  //     contactInformationDirectAddress: "",
  //     contactInformationPrimaryMobilePhone: "9036756778",
  //     contactInformationOfficePhone: "6699666666",
  //     contactInformationFax: "9036752333",
  //     homeAddressAddressOne: "810 Lucas Dr",
  //     homeAddressAddressTwo: "",
  //     homeAddressAddressCity: "Athens",
  //     homeAddressState: "TX",
  //     homeAddressZip: "75751-3446",
  //     homeAddressCountry: "USA",
  //     isGayFriendly: false,
  //     isTreatingHIV: false,
  //     isAcceptingPatients: false,
  //     professionalIdentificationsPrimaryCredential: "5ed02fb3569a321c40763d95",
  //     professionalIdentificationsPrimarySpecialty: "5ed02a209b912a5ad0b465fc",
  //     npi: "1306849450",
  //     professionalIdentificationsCAQHApplicationType: 0,
  //     professionalIdentificationsCAQHOrganizationID: 6119,
  //     professionalIdentificationsCAQHRegionID: 0,
  //     professionalIdentificationsUPIN: "",
  //     professionalIdentificationsDEA: "Dea",
  //     professionalIdentificationsDEALicenseDocumetnURLs: [],
  //     professionalCertifications: [
  //       {
  //         boardCertifications: "ACOG",
  //         issueDate: "2018-09-30T21:00:00Z",
  //         expirationDate: "2020-08-30T21:00:00Z",
  //         lastRecertificationDate: "2020-08-05T21:00:00Z",
  //       },
  //     ],
  //     professionalLicenses: [
  //       {
  //         state: "CA",
  //         number: "CA1245",
  //         issueDate: "2020-08-05T21:00:00Z",
  //         expirationDate: "2023-01-05T22:00:00Z",
  //         type: "5ed02f93569a321c40763d5f",
  //         stateLinceceDocumentURLs: [],
  //       },
  //     ],
  //     medicareLicenses: [
  //       {
  //         number: "CA1245",
  //         state: "CA",
  //       },
  //     ],
  //     educationResidencyInstitution: "St. Johns",
  //     educationResidencySpecialty: "5ed02a209b912a5ad0b465fc",
  //     educationResidencyStartDate: "2000-03-27T22:00:00Z",
  //     educationResidencyEndDate: "2006-02-27T22:00:00Z",
  //     educationResidencyCity: "Los Angeles",
  //     educationResidencyState: "CA",
  //     educationMedicalSchoolName: "USC",
  //     educationMedicalSchoolCountry: "USA",
  //     educationMedicalSchoolStartDate: "1995-07-27T21:00:00Z",
  //     educationMedicalSchoolGraduationDate: "1999-07-27T21:00:00Z",
  //     educationMedicalSchoolCity: "Los Angeles",
  //     educationMedicalSchoolState: "CA",
  //     educationUndergraduateSchoolName: "St. Johns",
  //     educationUndergraduateSchoolCountry: "USA",
  //     educationUndergraduateSchoolStartDate: "1990-07-27T21:00:00Z",
  //     educationUndergraduateSchoolGraduationDate: "1995-07-27T21:00:00Z",
  //     educationUndergraduateSchoolCity: "Los Angeles",
  //     educationUndergraduateSchoolState: "CA",
  //     employmentHistory: [],
  //     attestations: [
  //       {
  //         question:
  //           "Have you ever been disciplined, excluded from, debarred, suspended, reprimanded, sanctioned, censured, disqualified or otherwise restricted in regard to participation in the Medicare or Medicaid program, or in regard to other federal or state governmental healthcare plans or programs?",
  //         answer: true,
  //       },
  //       {
  //         question:
  //           "Are you currently or have you ever been the subject of an investigation by any hospital, licensing authority, DEA or CDS authorizing entities, education or training program, Medicare or Medicaid program, or any other private, federal or state health program or a defendant in any civil action that is reasonably related to your qualifications, competence, functions, or duties as a medical professional for alleged fraud, an act of violence, child abuse or a sexual offense or sexual misconduct?",
  //         answer: true,
  //       },
  //       {
  //         question:
  //           "To your knowledge, has information pertaining to you ever been reported to the National Practitioner Data Bank or Healthcare Integrity and Protection Data Bank?",
  //         answer: true,
  //       },
  //       {
  //         question:
  //           "Have you ever received sanctions from or are you currently the subject of investigation by any regulatory agencies (e.g., CLIA, OSHA, etc.)?",
  //         answer: true,
  //       },
  //       {
  //         question:
  //           "Have you ever been convicted of, pled guilty to, pled nolo contendere to, sanctioned, reprimanded, restricted, disciplined or resigned in exchange for no investigation or adverse action within the last ten years for sexual harassment or other illegal misconduct?",
  //         answer: true,
  //       },
  //       {
  //         question:
  //           "Are you currently being investigated or have you ever been sanctioned, reprimanded, or cautioned by a military hospital, facility, or agency, or voluntarily terminated or resigned while under investigation or in exchange for no investigation by a hospital or healthcare facility of any military agency?",
  //         answer: false,
  //       },
  //       {
  //         question:
  //           "Are you currently an active duty service member or civilian employee of the government prohibited from treating Medicare and Medicaid beneficiaries under CFR 199.6?",
  //         answer: false,
  //       },
  //       {
  //         question:
  //           "Have you ever, while under investigation or to avoid an investigation, voluntarily withdrawn or prematurely terminated your status as a student or employee in any internship, residency, fellowship, preceptorship, or other clinical education program?",
  //         answer: true,
  //       },
  //       {
  //         question:
  //           "Were you ever placed on probation, disciplined, formally reprimanded, suspended or asked to resign during an internship, residency, fellowship, preceptorship or other clinical education program? If you are currently in a training program, have you been placed on probation, disciplined, formally reprimanded, suspended or asked to resign?",
  //         answer: true,
  //       },
  //       {
  //         question:
  //           "Have you ever chosen not to re-certify or voluntarily surrendered your board certification(s) while under investigation?",
  //         answer: true,
  //       },
  //       {
  //         question:
  //           "Have any of your board certifications or eligibility ever been revoked?",
  //         answer: true,
  //       },
  //       {
  //         question:
  //           "Have your Federal DEA and/or State Controlled Dangerous Substances (CDS) certificate(s) or authorization(s) ever been challenged, denied, suspended, revoked, restricted, denied renewal, or voluntarily or involuntarily relinquished?",
  //         answer: true,
  //       },
  //       {
  //         question:
  //           "Have you ever voluntarily or involuntarily surrendered, limited your privileges or not reapplied for privileges while under investigation?",
  //         answer: false,
  //       },
  //       {
  //         question:
  //           "Have you ever been terminated for cause or not renewed for cause from participation, or been subject to any disciplinary action, by any managed care organizations (including HMOs, PPOs, or provider organizations such as IPAs, PHOs)?",
  //         answer: false,
  //       },
  //       {
  //         question:
  //           "Have your clinical privileges or medical staff membership at any hospital or healthcare institution, voluntarily or involuntarily, ever been denied, suspended, revoked, restricted, denied renewal or subject to probationary or to other disciplinary conditions for reasons other than non-completion of medical record when quality of care was not adversely affected or have proceedings toward any of those ends been instituted or recommended by any hospital or healthcare institution, medical staff or committee, or governing board? | Hospital Privileges and Other Affiliations",
  //         answer: false,
  //       },
  //       {
  //         question:
  //           "Do you use any chemical substances that would in any way impair or limit your ability to practice medicine and perform the functions of your job with reasonable skill and safety?",
  //         answer: true,
  //       },
  //       {
  //         question:
  //           "Are you currently or have you ever engaged in the illegal use of drugs? (`Currently` means sufficiently recent to justify a reasonable belief that the use of drugs may have an ongoing impact on one's ability to practice medicine. It is not limited to the day of, or within a matter of days or weeks before the date of application, rather that it has occurred recently enough to indicate the individual is actively engaged in such conduct. `Illegal use of drugs` refers to drugs whose possession or distribution is unlawful under the Controlled Substances Act, 21 U.S.C. 1 812.22. It `does not include the use of a drug taken under supervision by a licensed health care professional, or other uses authorized by the Controlled Substances Act or other provision of Federal law.` The term does include, however, the unlawful use of prescription controlled substances.)",
  //         answer: true,
  //       },
  //       {
  //         question:
  //           "Do you have any reason to believe that you would pose a risk to the safety or well being of your patients?",
  //         answer: true,
  //       },
  //       {
  //         question:
  //           "Are you unable to perform the essential functions of a practitioner in your area of practice even with reasonable accommodation?",
  //         answer: true,
  //       },
  //       {
  //         question:
  //           "Have you ever been convicted of, pled guilty to, or pled nolo contendere to any felony?",
  //         answer: true,
  //       },
  //       {
  //         question:
  //           "Have you ever been court-martialed for actions related to your duties as a medical professional?",
  //         answer: true,
  //       },
  //       {
  //         question:
  //           "Have you ever been convicted of, pled guilty to, pled nolo contendere to, sanctioned, reprimanded, restricted, disciplined or resigned in exchange for no investigation or adverse action within the last ten years for sexual harassment or other illegal misconduct?",
  //         answer: true,
  //       },
  //       {
  //         question:
  //           "Has your professional liability coverage ever been cancelled, restricted, declined or not renewed by the carrier based on your individual liability history?",
  //         answer: true,
  //       },
  //       {
  //         question:
  //           "Have you ever been assessed a surcharge, or rated in a high-risk class for your specialty, by your professional liability insurance carrier, based on your individual liability history?",
  //         answer: true,
  //       },
  //       {
  //         question:
  //           "Have you ever had any professional liability actions (pending, settled, arbitrated, mediated or litigated)? If yes, provide information for each case.",
  //         answer: true,
  //       },
  //       {
  //         question:
  //           "Has there ever been any challenge to your licensure, registration or certification?",
  //         answer: true,
  //       },
  //       {
  //         question:
  //           "Has your license, registration or certification to practice in your profession, ever been voluntarily or involuntarily relinquished, denied, suspended, revoked, restricted, or have you ever been subject to a fine, reprimand, consent order, probation or any conditions or limitations by any state or professional licensing, registration or certification board?",
  //         answer: true,
  //       },
  //       {
  //         question:
  //           "Appeal Rights: I confirm I have read and understand my rights in the appeal process",
  //         answer: true,
  //       },
  //       {
  //         question:
  //           "Credentialing Rights: I confirm I have read and understand my rights in the credentialing process",
  //         answer: true,
  //       },
  //       {
  //         question:
  //           "Do you hold a controlling interest of 5% or greater of a jointly owned healthcare business?",
  //         answer: true,
  //       },
  //     ],
  //     getPaidBankName: "JPMorgan Chace Bank",
  //     getPaidAccountName: "MIchael Brown",
  //     getPaidPersonalBusinessAccount: "PERSONAL",
  //     getPaidPersonalAccountType: "CHECKING",
  //     getPaidPersonalRoutingNumber: 12345678,
  //     getPaidPersonalAccountNumber: 123456,
  //     taxInformationSendDocumentsTo: "",
  //     taxInformationLegalEntity: "",
  //     taxInformationTaxPayerIDNumber: "",
  //     taxInformationIdentificationDocumentURLs: [],
  //     taxInformationTaxInfoFullName: "Michael Brown",
  //     taxInformationDate: "2020-07-27T21:00:00Z",
  //     providerProfileTagline: "",
  //     providerProfileShortBio: "",
  //     providerProfileBio: "",
  //     providerProfileWebsite: "",
  //     providerProfileFacebookAccount: "",
  //     providerProfileInstagramAccount: "",
  //     providerProfileTwitterAccount: "",
  //     providerProfileYoutubeLink: "",
  //     providerProfileGoogleBusiness: "",
  //     providerProfileYelp: "",
  //     providerProfileHealthGrades: "",
  //     providerProfileBlog: "",
  //     _id: "5ed7b6987331c60940b6f4bd",
  //     created: "2020-06-03T14:41:28.371Z",
  //     creator: "system",
  //     modified: "2021-06-08T11:28:36.091Z",
  //     modifier: "07bace54-759e-4db5-ba94-de4ba29bc47d",
  //   },
  //   questionnaireExecutionsV2: {
  //     userId: "474b06f7-6dd2-40d8-9868-5ca2c16f698c",
  //     tenantId: "health",
  //     codes: [],
  //     completed: "2020-10-23T21:14:17.121Z",
  //     outcomeList: [
  //       {
  //         left: 2,
  //         right: {
  //           links: [],
  //           title: {
  //             translations: {
  //               eng: "EXPOSED",
  //             },
  //           },
  //           class:
  //             "com.safe.v2.questionnaire.domain.outcome.instructions.OutcomeInstructions",
  //         },
  //       },
  //       {
  //         left: 1,
  //         right: {
  //           links: [],
  //           title: {
  //             translations: {
  //               eng: "SYMPTOMATIC",
  //             },
  //           },
  //           class:
  //             "com.safe.v2.questionnaire.domain.outcome.instructions.OutcomeInstructions",
  //         },
  //       },
  //     ],
  //     finalOutcome: {},
  //     answers: [
  //       {
  //         nodeId: "1",
  //         sequenceNumber: 1,
  //         class: "com.safe.v2.form.domain.answer.AnswerQuestionMultiple",
  //         questionnaireId: "5f1c3c672fec9272e58e962d",
  //       },
  //       {
  //         nodeId: "2",
  //         sequenceNumber: 2,
  //         class: "com.safe.v2.form.domain.answer.AnswerQuestionNumber",
  //         questionnaireId: "5f1c3c672fec9272e58e962d",
  //       },
  //       {
  //         nodeId: "3",
  //         sequenceNumber: 3,
  //         class: "com.safe.v2.form.domain.answer.AnswerQuestionNumber",
  //         questionnaireId: "5f1c3c672fec9272e58e962d",
  //       },
  //       {
  //         nodeId: "4",
  //         sequenceNumber: 4,
  //         class: "com.safe.v2.form.domain.answer.AnswerQuestionMultiple",
  //         questionnaireId: "5f1c3c672fec9272e58e962d",
  //       },
  //       {
  //         nodeId: "5",
  //         sequenceNumber: 5,
  //         class: "com.safe.v2.form.domain.answer.AnswerQuestionYesNo",
  //         questionnaireId: "5f1c3c672fec9272e58e962d",
  //       },
  //     ],
  //     sentFHIR: true,
  //     class: "com.safe.v2.questionnaire.domain.Encounter",
  //     _id: "64afcb2a-8278-4c32-8879-aed1a8ed5381",
  //     created: "0001-01-01T00:00:00",
  //     modified: "0001-01-01T00:00:00",
  //   },
  //   patientTest: {
  //     userUUID: "4220c888-bc09-471f-b377-aecec8859bc8",
  //     _class: "com.safe.domain.laboratory.PatientTest",
  //     userId: "8066559436@yopmail.com",
  //     nationalHealthId: "0094000166692",
  //     tenantId: "health",
  //     source: "QUEST_LABS",
  //     orderId: "9655aa3f4a5844be",
  //     status: "PRE_ORDERED",
  //     encounterId: "70c20895-2d6a-4cf6-84d3-7d1c1e2f3029",
  //     isSyncedWithEHR: false,
  //     result: "UNKNOWN",
  //     lastUpdated: "2020-08-22T03:23:02.54Z",
  //     internalTestId: "57",
  //     labTestOrderableId: "5f3a446c82db0d0001d415b4",
  //     appointmentId: "5f083ea9740acc00012835ed",
  //     paymentMode: "Unknown",
  //     manuallyAdded: true,
  //     availableForProvider: false,
  //     collectionDate: "2020-07-24T16:48:13Z",
  //     testClassification: "NOT_SPECIFIED",
  //     isOnsiteTest: false,
  //     elapsedTimeOnCapture: 0,
  //     elapsedTimeOnAI: 0,
  //     elapsedTimeOnSubmissionTimer: 0,
  //     retryCount: 0,
  //     _id: "a9886c86-5f0d-4112-a43d-4abc55769dc9",
  //     created: "2020-08-22T03:23:02.54Z",
  //     modified: "2020-12-15T19:25:52.779Z",
  //     modifier: "system",
  //   },
  //   appointment: {
  //     nationalHealthId: "0090000022200",
  //     confirmationId: "TXX72544",
  //     appointmentStart: "2020-07-30T14:00:00+00:00",
  //     appointmentEnd: "2020-07-30T14:15:00+00:00",
  //     locationCode: "HNX",
  //     locationId: "13016",
  //     locationName: "Brookwood Medical Plaza",
  //     timeZone: "America/Chicago",
  //     status: "Succeeded",
  //     cancelDateTime: "0001-01-01T00:00:00Z",
  //     _id: "5f083ea9740acc00012835ed",
  //     created: "2020-07-10T10:10:49.065Z",
  //     creator: "loga2597@gmail.com",
  //     modified: "2020-07-10T10:10:49.065Z",
  //     modifier: "loga2597@gmail.com",
  //   },
  //   labTestOrderable: {
  //     loinc: "",
  //     orderableName: "HIV Ag",
  //     shortDescription:
  //       "HIV tests are designed to detect antibodies to the HIV virus or the HIV virus itself",
  //     longDescription:
  //       "HIV tests are designed to detect antibodies to the HIV virus or the HIV virus itself",
  //     vendorDetailId: "5ebd23f844b4557ecc808efe",
  //     testFormatId: "5ebd241e44b4557ecc808eff",
  //     specimenTypeId: "5ee1df6b19e897caaccf08c4",
  //     uploadTimeoutInSeconds: 0,
  //     vendorTestID: "11",
  //     introWidgetIds: [],
  //     price: 50,
  //     statesApplicable: [
  //       "AL",
  //       "AK",
  //       "AS",
  //       "AZ",
  //       "AR",
  //       "CA",
  //       "CO",
  //       "CT",
  //       "DE",
  //       "DC",
  //       "FM",
  //       "FL",
  //       "GA",
  //       "GU",
  //       "HI",
  //       "ID",
  //       "IL",
  //       "IN",
  //       "IA",
  //       "KS",
  //       "KY",
  //       "LA",
  //       "ME",
  //       "MH",
  //       "MD",
  //       "MA",
  //       "MI",
  //       "MN",
  //       "MS",
  //       "MO",
  //       "MT",
  //       "NE",
  //       "NV",
  //       "NH",
  //       "NJ",
  //       "NM",
  //       "NY",
  //       "NC",
  //       "ND",
  //       "MP",
  //       "OH",
  //       "OK",
  //       "OR",
  //       "PW",
  //       "PA",
  //       "PR",
  //       "RI",
  //       "SC",
  //       "SD",
  //       "TN",
  //       "TX",
  //       "UT",
  //       "VT",
  //       "VI",
  //       "VA",
  //       "WA",
  //       "WV",
  //       "WI",
  //       "WY",
  //     ],
  //     images: [],
  //     articles: [],
  //     videos: [],
  //     billingDetail: {
  //       taxable: false,
  //     },
  //     association: {},
  //     isActive: true,
  //     isOrderable: true,
  //     isSelfAssessmentMode: false,
  //     kitRegistrationRequired: false,
  //     requiresResultsVerification: false,
  //     kitManufacturer: "5ebd23f844b4557ecc808efe",
  //     instructions: "",
  //     customTestName: "HIV Antigen",
  //     testImageAssetHeight: 0,
  //     testImageAssetWidth: 0,
  //     frequencyUnit: "days",
  //     frequencyValue: 0,
  //     processingTimeUnit: "days",
  //     processingTimeValue: 0,
  //     testClassification: "NOT_SPECIFIED",
  //     isMLDisabled: false,
  //     _id: "5f3a446c82db0d0001d415b4",
  //     created: "2020-08-17T08:48:00Z",
  //     creator: "System",
  //     modified: "2020-12-15T19:47:19.127Z",
  //     modifier: "system",
  //   },
  //   vendorDetail: {
  //     name: "Molecular Testing Labs",
  //     vendorKey: "MTL",
  //     address: {
  //       address1: "Molecular Testing Labs",
  //       address2: "14401 SE 1st St.",
  //       city: " Vancouver",
  //       state: "WS",
  //       zip: "98684",
  //     },
  //     contactDetail: {
  //       firstName: "Molecular Testing",
  //       lastName: "Labs",
  //       phoneNumber: "855-400-6002",
  //       faxNumber: "",
  //       email: "https://moleculartestinglabs.com/",
  //     },
  //     isRequisitionSupported: false,
  //     isResultDocumentSupported: true,
  //     isSpecimenLabelSupported: false,
  //     isActive: true,
  //     _id: "5ebd23f844b4557ecc808efe",
  //     created: "2020-05-14T06:52:33.443Z",
  //     creator: "jaskaran@safehealth.me",
  //     modified: "2020-10-07T07:50:32.413Z",
  //     modifier: "System",
  //   },
  //   providerOrganizationOrderable: {
  //     providerOrganizationId: "601b8406e165ee4b978d2b43",
  //     labTestOrderableId: "5f3a446c82db0d0001d415b4",
  //     _id: "601cd6b83b134b355194b037",
  //     created: "2021-02-05T05:25:12.549Z",
  //     modified: "2021-02-05T05:35:18.754Z",
  //   },
  //   providerOrganization: {
  //     name: "Helix Testing Center - 03",
  //     facilityTypeId: "5f5df534057e4955c474ed43",
  //     facilityType: "Standup",
  //     facilityKey: "HTC03",
  //     address: {},
  //     contactPersons: [{}],
  //     workingHours: {
  //       days: [],
  //     },
  //     schedulingOnline: true,
  //     isActive: true,
  //     ehrVendor: "",
  //     lisVendor: "Helix",
  //     isSafeFacility: false,
  //     isVaccineFacility: false,
  //     isPharamacy: false,
  //     isCQEntry: false,
  //     isCovidAntibody: false,
  //     isCovidInfection: false,
  //     _id: "601b8406e165ee4b978d2b43",
  //     created: "2021-02-04T05:20:06.149Z",
  //     creator: "",
  //     modified: "2021-02-05T05:35:18.732Z",
  //     modifier: "",
  //   },
  //   status: "ASSIGNED",
  //   type: "VIRTUAL_CONSOLE_REVIEW",
  //   memberID: "474b06f7-6dd2-40d8-9868-5ca2c16f698c",
  //   providerID: "5ed7b6987331c60940b6f4bd",
  //   tenantID: "health",
  //   questionnaireExecutionID: "64afcb2a-8278-4c32-8879-aed1a8ed5381",
  //   providerInitials: "MB",
  //   outcomeStatus: "0",
  //   identifier: "E0471064909644",
  //   notes: [],
  //   medicationNotes: [],
  //   referralNotes: [],
  //   diagnosticNotes: [],
  //   medications: [],
  //   referrals: [],
  //   diagnostics: [],
  //   duration: 0,
  //   amountCharged: 0,
  //   isSyncedWithEHR: false,
  //   _id: "70c20895-2d6a-4cf6-84d3-7d1c1e2f3029",
  //   created: "2021-02-04T14:51:46.493Z",
  //   creator: "system",
  //   modified: "2021-02-04T14:51:46.512Z",
  //   modifier: "system",
  // },
};

export const encountersSlice = createSlice({
  name: 'encounters',
  initialState,
  reducers: {
    setEncounterList: (state, { payload }) => ({
      ...state,
      encounterList: payload,
    }),
    setEncounterDetails: (state, { payload }) => ({
      ...state,
      encounterDetails: payload,
    }),
    resetEncounters: () => ({ ...initialState }),
  },
});

export const { setEncounterList, setEncounterDetails, resetEncounters } = encountersSlice.actions;

export const getEncounterList = (data) => async (dispatch) => {
  const [result, error] = await api.getEncounterListRequest(data);

  if (error && error.message) {
    return dispatch(handleError(error));
  }

  if (result?.success) {
    dispatch(
      setEncounterList({
        data: result.data.result,
        total: result.data.foundItemsCount,
      })
    );
  }
};

export const getEncounterData = (encounterId) => async (dispatch) => {
  const [result, error] = await api.getEncounterDataRequest(encounterId);

  if (error && error.message) {
    return dispatch(handleError(error));
  }

  if (result?.success) {
    dispatch(setEncounterDetails(result.data));
  }
};

export const updateEncounterNotes = (encounterId, noteObj) => async (dispatch) => {
  const [result, error] = await api.updateEncounterNotesRequest(encounterId, noteObj);

  if (error && error.message) {
    dispatch(handleError(error));
    return false;
  }

  if (result?.success) {
    return true;
  }

  return false;
};

export default encountersSlice.reducer;
