import TextField from '@material-ui/core/TextField';
import React, { useState, useEffect } from 'react';

// redux
import { useSelector } from 'react-redux';

import { selectFormData } from '../selectors';
import ClearIcon from "@material-ui/icons/Clear";
import { IconButton } from "@material-ui/core";

// ui reflection of file view
function TimePickerInput(props) {
  const { field, value: initialValue, handleChange, disabled } = props;
  const formData = useSelector(selectFormData);

  const [currentValue, setCurrentValue] = useState(initialValue);
  const fieldDisabled = disabled || field.readOnly || (field.disableIfFalse && !formData[field.disableIfFalse]);

  useEffect(() => {
    if (initialValue && initialValue !== currentValue) {
      setCurrentValue(initialValue);
    }
    // eslint-disable-next-line
  }, [initialValue]);

  const onChange = (e) => {
    setCurrentValue(e.target.value);
    if (handleChange) handleChange(e);
  };

  return (
    <div style={{ position: "relative" }}>
      <TextField
        id="time"
        name={field.name}
        label={field.label}
        value={currentValue || ''}
        type="time"
        onChange={onChange}
        InputLabelProps={{
          shrink: true,
        }}
        style={{ width: '100%' }}
        disabled={fieldDisabled}
      />
      {/*<IconButton style={{ position: "absolute", top: ".5rem", margin: "auto", right: "0.5rem" }}>*/}
      {/*  <ClearIcon/>*/}
      {/*</IconButton>*/}
    </div>
  );
}

export default TimePickerInput;
