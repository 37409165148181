/* eslint-disable import/no-cycle */
import { createSlice } from '@reduxjs/toolkit';
import api from '../../../api/index';
import { showNotification } from '../../Notifications/notificationSlice';
import { handleError } from '../../Shared/Content/APIUtils';
import { initialKendoState } from './helpers/mapping';

const initialState = {
  list: null,
  loading: false,
  error: null,
  submitting: false,
  submitError: null,
  removing: false,
  removeError: null,
  permissionPagination: initialKendoState,
};

export const permissionSlice = createSlice({
  name: 'permission',
  initialState,
  reducers: {
    setListRequest: (state) => {
      return {
        ...state,
        loading: true,
        error: null,
      };
    },
    setListRequestSuccess: (state, { payload }) => {
      return {
        ...state,
        list: payload,
        loading: false,
      };
    },
    setListRequestError: (state, { payload }) => {
      return {
        ...state,
        error: payload,
        loading: false,
      };
    },
    setPermissionRequest: (state) => {
      return {
        ...state,
        submitting: true,
        submitError: null,
      };
    },
    setPermissionRequestSuccess: (state) => {
      return {
        ...state,
        submitting: false,
      };
    },
    setPermissionRequestError: (state, { payload }) => {
      return {
        ...state,
        submitError: payload,
        submitting: false,
      };
    },
    setPermissionPaginationState: (state, { payload }) => ({
      ...state,
      permissionPagination: payload,
    }),
    removePermissionRequest: (state) => {
      return {
        ...state,
        removing: true,
        removeError: null,
      };
    },
    removePermissionRequestSuccess: (state) => {
      return {
        ...state,
        removing: false,
      };
    },
    removePermissionRequestError: (state, { payload }) => {
      return {
        ...state,
        removing: false,
        removeError: payload,
      };
    },
    resetState: () => ({ ...initialState }),
  },
});

export const {
  setListRequest,
  setListRequestSuccess,
  setListRequestError,
  setPermissionRequest,
  setPermissionRequestSuccess,
  setPermissionRequestError,
  setPermissionPaginationState,
  removePermissionRequest,
  removePermissionRequestSuccess,
  removePermissionRequestError,
} = permissionSlice.actions;

export const getList = (page, pageSize, sort, direction) => async (dispatch) => {
  const params = {
    skip: page * pageSize,
    limit: pageSize,
    sort,
    direction,
  };
  dispatch(setListRequest());

  const [result, error] = await api.getPermissionListRequest(params);

  if (error && error.message) {
    dispatch(setListRequestError(error.message));
    dispatch(handleError(error));
  }

  if (result) {
    dispatch(setListRequestSuccess(result));
  }
};

export const setPermission = (userId) => async (dispatch, getState) => {
  dispatch(setPermissionRequest());

  const [result, error] = await api.setPermissionRequest(userId);

  if (error && error.message) {
    dispatch(setPermissionRequestError(error.message));
    dispatch(handleError(error));
  }

  if (result) {
    const { permissionPagination } = getState().accountPermissions;

    dispatch(setPermissionRequestSuccess());
    dispatch(showNotification('Permission successfully set', 'success'));
    dispatch(
      getList(
        permissionPagination ? permissionPagination.skip : 0,
        permissionPagination ? permissionPagination.take : 10,
        permissionPagination ? permissionPagination.sort[0]?.field : 'userId',
        permissionPagination ? permissionPagination.sort[0]?.dir?.toUpperCase() : 'ASC'
      )
    );
  }
};

export const removePermission = (userId) => async (dispatch, getState) => {
  dispatch(removePermissionRequest());

  const [result, error] = await api.removePermissionRequest(userId);

  if (error && error.message) {
    dispatch(removePermissionRequestError(error.message));
    dispatch(handleError(error));
  }

  if (result) {
    const { permissionPagination } = getState().accountPermissions;

    dispatch(removePermissionRequestSuccess());
    dispatch(showNotification('Permission successfully removed', 'success'));
    dispatch(
      getList(
        permissionPagination ? permissionPagination.skip : 0,
        permissionPagination ? permissionPagination.take : 10,
        permissionPagination ? permissionPagination.sort[0]?.field : 'userId',
        permissionPagination ? permissionPagination.sort[0]?.dir?.toUpperCase() : 'ASC'
      )
    );
  }
};

export default permissionSlice.reducer;
