const fhirVersions = {
  DSTU2: 'dstu2',
  STU3: 'stu3',
  R4: 'r4',
};

const displayInOrder = [
  'patient',
  'allergyIntolerance',
  'medicationStatement',
  'condition',
  'observation',
  'familyMemberHistory',
  'diagnosticReport',
  'immunization',
  'encounter',
  'procedure',
  'documentReference',
  'medicationRequest',
];

module.exports = {
  fhirVersions,
  displayInOrder,
};
