import * as htmlUtils from '../../Shared/Grid/helpers';
import utils from '../../Shared/Utils/utils';
import SelectionHeaderCell from '../../Users/SelectionHeaderCell';

import { booleanMapping, statusMapping } from './mappings';

export const transform = {
  testOrderable: (data, selected) =>
    data.map((t) => ({
      ...t,
      ...utils.flattenKeys('test', t.labTest),
      ...utils.flattenKeys('vendor', t.vendorDetail),
      ...utils.flattenKeys('format', t.testFormat),
      ...utils.flattenKeys('specimenType', t.specimenType),
      selected: selected && selected.includes(t._id),
      created: utils.formatDateTime(t.created),
      modified: utils.formatDateTime(t.modified),
    })),
  testPanels: (data, selected) =>
    data.map((p) => ({
      ...p,
      vendorTestIds: utils.distinctValues(utils.extractField(p.labTestOrderables, 'vendorTestID')).join(', '),
      loinc: utils.distinctValues(utils.extractField(p.labTestOrderables, 'loinc')).join(', '),
      selected: selected && selected.includes(p._id),
      format: p.isFacility ? 'Facility Test' : 'Rapid Test',
      vendor: p.vendorDetail?.name,
    })),
  vendors: (data, selected) =>
    data.map((p) => ({
      ...p,
      selected: selected && selected.includes(p._id),
    })),
};

export const sortingMapping = {
  testOrderable: {
    orderableCode: 'orderByOrderableCode',
    shortDescription: 'orderByShortDescription',
    test__loinc: 'orderByLOINC',
  },
  testPanels: {
    shortName: 'orderByShortName',
    longName: 'orderByLongName',
  },
};

export const initialSorting = {
  testOrderable: {
    field: '_id',
    dir: 'asc',
  },
  testPanels: {
    field: 'shortName',
    dir: 'asc',
  },
  vendors: {
    field: '_id',
    dir: 'asc',
  },
};

export const columns = (showSelected, vendors, testFormats) => {
  const columnList = {
    testOrderable: [
      {
        id: 'selected',
        title: '',
        show: showSelected,
        minWidth: 100,
        sortable: true,
        headerCell: SelectionHeaderCell,
      },
      {
        id: 'customTestName',
        label: 'Test Name',
        show: true,
        sortable: false,
        minWidth: 170,
      },
      {
        id: 'vendor__name',
        label: 'Lab Vendor',
        show: true,
        minWidth: 170,
        sortable: false,
        filter: true,
        options: vendors,
        labelField: 'name',
      },
      {
        id: 'specimenType__format',
        label: 'Specimen Type',
        show: true,
        locked: false,
        minWidth: 170,
        sortable: false,
      },
      {
        id: 'format__format',
        label: 'Test Type',
        show: true,
        locked: false,
        minWidth: 170,
        sortable: false,
        filter: true,
        options: testFormats,
        labelField: 'format',
      },
      {
        id: 'vendorTestID',
        label: 'Vendor Test ID',
        show: true,
        minWidth: 170,
        sortable: false,
      },
      {
        id: 'modified',
        label: 'Last Updated',
        show: true,
        sortable: false,
        minWidth: 170,
      },
      {
        id: 'isActive',
        label: 'Status',
        show: true,
        minWidth: 170,
        sortable: false,
        cell: htmlUtils.withMapping(htmlUtils.tag, statusMapping),
      },
      // {
      // 	id: "actions",
      // 	label: "Actions",
      // 	show: true,
      // 	minWidth: 200,
      // 	sortable: false,
      // 	cell: htmlUtils.withCustomProp(ActionsCell, "listType", "testOrderable"),
      // },
    ],
    testPanels: [
      {
        id: 'selected',
        title: '',
        show: showSelected,
        minWidth: 100,
        sortable: true,
        headerCell: SelectionHeaderCell,
      },
      {
        id: 'shortName',
        label: 'Test Name',
        show: true,
        locked: true,
        minWidth: 170,
        sortable: false,
      },
      {
        id: 'longDescription',
        label: 'Description',
        show: true,
        sortable: true,
        minWidth: 170,
      },
      {
        id: 'format',
        label: 'Test Format',
        show: true,
        sortable: false,
        minWidth: 170,
      },
      {
        id: 'vendor',
        label: 'Lab Vendor',
        show: true,
        minWidth: 170,
        sortable: false,
      },
      {
        id: 'vendorTestIds',
        label: 'Vendor Test ID',
        show: true,
        minWidth: 170,
        sortable: false,
      },
      {
        id: 'isActive',
        label: 'Status',
        show: true,
        minWidth: 170,
        sortable: false,
        cell: htmlUtils.withMapping(htmlUtils.tag, statusMapping),
      },
      // {
      // 	id: "actions",
      // 	label: "Actions",
      // 	show: true,
      // 	minWidth: 200,
      // 	sortable: false,
      // 	cell: htmlUtils.withCustomProp(ActionsCell, "listType", "testPanels"),
      // },
    ],
    vendors: [
      {
        id: 'selected',
        title: '',
        show: showSelected,
        minWidth: 100,
        sortable: true,
        headerCell: SelectionHeaderCell,
      },
      {
        id: 'name',
        label: 'Name',
        show: true,
        sortable: true,
        minWidth: 170,
      },
      {
        id: 'isRequisitionSupported',
        label: 'Requisition Supported',
        show: true,
        minWidth: 170,
        sortable: true,
        cell: htmlUtils.withMapping(htmlUtils.tag, booleanMapping),
      },
      {
        id: 'isResultDocumentSupported',
        label: 'Result Document Supported',
        show: true,
        sortable: true,
        minWidth: 170,
        cell: htmlUtils.withMapping(htmlUtils.tag, booleanMapping),
      },
      {
        id: 'isSpecimenLabelSupported',
        label: 'Specimen Label Supported',
        show: true,
        sortable: true,
        minWidth: 170,
        cell: htmlUtils.withMapping(htmlUtils.tag, booleanMapping),
      },
      {
        id: 'isActive',
        label: 'Status',
        show: true,
        minWidth: 170,
        sortable: false,
        cell: htmlUtils.withMapping(htmlUtils.tag, statusMapping),
      },
    ],
  };
  return columnList;
};

export const apiMapper = {
  testOrderable: 'LabTestOrderable',
  testPanels: 'LabTestPanel',
  test: 'LabTest',
  vendors: 'vendors',
  vendor: 'VendorDetail',
  testFormat: 'TestFormat',
};

export const paginatedListing = {
  testFormat: false,
  vendor: true,
  test: true,
  testOrderable: true,
  testPanels: true,
  healthService: false,
};
