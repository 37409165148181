/* eslint-disable import/no-cycle */
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';

import { createInputHelper } from '../../../../../Shared/Forms/helpers/createInputHelper';
import { selectVcbVariablesList } from '../../../../selectors';
import { jumpFields } from '../../../helpers/formHelper';
import { QuestionnaireBuilderContext } from '../../../QuestionnaireBuilderWrapper';

function Condition(props) {
  const {
    item,
    conditionIndex,
    handleChangeAnswer,
    handleChangeWithParams,
    addCondition,
    removeCondition,
    currentNode,
  } = props;

  const vcbVariablesList = useSelector(selectVcbVariablesList);

  const { selectedQuestions } = useContext(QuestionnaireBuilderContext);

  const getQuestionOptions = () => {
    const { questionId } = item;
    const foundQuestion = selectedQuestions.find((q) => q.id === questionId);

    // Assemble internal variables and variables based on question answers into options list
    if (item.type === 'variable') {
      const options = Object.values(vcbVariablesList)[0] || [];
      selectedQuestions.forEach((question) => {
        if (!options.some((o) => o.name.includes(`${question.shortName}.answer`))) {
          options.push({ name: `${question.shortName}.answer` });
        }
      });
      return options;
    }

    if (!foundQuestion) return [];

    if (item.type === 'slider') {
      return {
        max: foundQuestion.max || 100,
        min: foundQuestion.min || 0,
        step: foundQuestion.step || 1,
      };
    }

    return foundQuestion.options;
  };

  const jumpValues = (jump, questionData) => {
    if (jump.type === 'numeric' || jump.type === 'scale' || jump.type === 'slider' || jump.type === 'multiple-size') {
      return {
        questionId: jump.questionId,
        expectedAnswers: jump.answer,
        operation: jump.operation,
      };
    }
    if (jump.type === 'multiple' || jump.type === 'multiple-drop') {
      return {
        questionId: jump.questionId,
        expectedAnswers: jump.expectedAnswer,
        operation: jump.operation,
      };
    }
    if (jump.type === 'score') {
      return {
        questionId: jump.questionId,
        score: jump.score,
        operation: jump.operation,
        labelText: 'Score',
      };
    }
    if (jump.type === 'text') {
      return {
        questionId: jump.questionId,
        expectedAnswers: jump.expectedAnswer,
        operation: jump.operation,
      };
    }
    if (jump.type === 'date') {
      return {
        questionId: jump.questionId,
        expectedAnswers: jump.expected,
        precision: questionData.precision,
        operation: jump.operation,
      };
    }
    if (jump.type === 'yes-no') {
      return {
        questionId: jump.questionId,
        expectedAnswers: jump.expectedAnswer,
      };
    }
    if (jump.type === 'variable') {
      return {
        variableName: jump.variableName,
        expectedText: jump.expectedText,
        operation: jump.operation,
      };
    }
  };

  const getUsedAnswers = () => {
    if (currentNode.question.type === 'multiple' || currentNode.question.type === 'multiple-drop') {
      const usedAnswers = currentNode.rules.map((rule) => {
        const answer = rule.rules.filter((r) => r.type.includes('multiple')).map((r) => r.expectedAnswer);
        return answer[0];
      });
      return usedAnswers || [];
    }
    return [];
  };

  return (
    <Grid container spacing={1}>
      <Typography variant="body2" style={{ padding: 5, marginTop: 27 }}>
        {conditionIndex + 1}
      </Typography>
      {createInputHelper(
        jumpFields(
          [
            {
              value: currentNode.question.id,
              label: currentNode.question.name,
            },
          ],
          item,
          getQuestionOptions(),
          getUsedAnswers()
        ),
        jumpValues(item, currentNode.question),
        (e) => handleChangeAnswer(e.target.value, conditionIndex),
        (name, value) => handleChangeWithParams(name, value, conditionIndex),
        false,
        false
      )}
      <Grid item xs={3} style={{ marginTop: 15 }}>
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <Tooltip title="Add conditional">
            <IconButton onClick={addCondition} style={{ padding: 6 }}>
              <AddCircleOutlineIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Remove conditional">
            <IconButton onClick={() => removeCondition(conditionIndex)} style={{ padding: 6 }}>
              <RemoveCircleOutlineIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Grid>
    </Grid>
  );
}

export default Condition;
