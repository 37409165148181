import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { showConfirmation } from '../Confirmation/confirmationSlice';
import { selectCurrentTenant } from '../Shared/selectors';
import ActionsCell from '../Shared/Table/ActionsCell';

// redux

function ActionsCellWrapper(props) {
  const { dataItem } = props;

  const currentTenant = useSelector(selectCurrentTenant);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const menuItems = ['edit', 'delete'];

  const handleActionClick = (selection) => {
    const { id, contentType } = dataItem;
    const conformationType = contentType.substring(0, contentType.length - 1);

    if (selection === 'edit') {
      navigate(`/${currentTenant.id}/virtual-consult/${contentType}/details/${id}`);
    }

    if (selection === 'delete') {
      dispatch(
        showConfirmation(
          'Confirm',
          `Are you sure you want to delete this ${conformationType}?`,
          `${conformationType}Delete`,
          currentTenant.id,
          id
        )
      );
    }
  };

  return <ActionsCell dataItem={dataItem} menu={menuItems} handleActionClick={handleActionClick} />;
}

export default ActionsCellWrapper;
