import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getQuestionnaireList } from '../reviewerSlice';
import { selectQuestionnaireList } from '../selectors';

import OptionForm from './OptionForm';

const optionList = [
  {
    type: 'concern',
    title: 'Concerns',
  },
  {
    type: 'reviewer',
    title: 'Reviewers',
  },
];

function ReviewerSettingsWrapper(props) {
  const { currentTenant, setQuestionnaireId, expandOptionForm } = props;

  const questionnaireList = useSelector(selectQuestionnaireList);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getQuestionnaireList(currentTenant));
  }, [currentTenant, dispatch]);

  const handleSelect = (e, value) => {
    if (value?.id) {
      setQuestionnaireId(value.id);
    }
  };

  return (
    <Grid>
      <Autocomplete
        id="clear-on-escape"
        options={questionnaireList}
        getOptionLabel={(option) => option.title}
        clearOnEscape
        onChange={handleSelect}
        renderInput={(params) => <TextField {...params} label="Questionnaire" variant="outlined" />}
      />

      <h4>Settings</h4>

      {optionList.map((item) => (
        <OptionForm
          currentTenant={currentTenant}
          optionType={item.type}
          optionName={item.title}
          expandOptionForm={expandOptionForm}
        />
      ))}
    </Grid>
  );
}

export default ReviewerSettingsWrapper;
