import { IconButton } from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import React from 'react';

function ActionButton(props) {
  return (
    <IconButton aria-controls="actions-menu" aria-label="Actions" aria-haspopup="true" onClick={props.onClick}>
      <MoreHorizIcon />
      <span style={{ display: 'none' }}>Actions</span>
    </IconButton>
  );
}

export default ActionButton;
