/* eslint-disable import/no-cycle */
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import axios from 'axios';
import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import QuestionnaireBuilderStyles from '../../../../../assets/jss/components/QuestionnaireBuilderStyles';
import { selectFormData } from '../../../../Shared/Forms/selectors';
import { selectCurrentTenant } from '../../../../Shared/selectors';
import AppKendoTableWrapper from '../../../../Shared/Table/KendoTableWrapper';
import utils from '../../../../Shared/Utils/utils';
import { initialSorting, transformSelectList } from '../../../helpers/virtualConsultListMapper';
import { selectVirtualConsult, selectLoadingQuestionnaires } from '../../../selectors';
import { getContent } from '../../../virtualConsultSlice';
import { deconstructJson } from '../../helpers/formHelper';
import { columns } from '../../helpers/questionnaireBuilderMapper';
import { QuestionnaireBuilderContext } from '../../QuestionnaireBuilderWrapper';

const API_ROOT = process.env.REACT_APP_API_ROOT;

// This component renders the dialog and table for importing questions and outcomes into a questionnaire
function QuestionnaireSettings(props) {
  const { actions, setSelectedStatus, isSelected, type, handleCancel, dialogOpen, classes, userSpecific } = props;

  const currentTenant = useSelector(selectCurrentTenant);
  const formData = useSelector(selectFormData);
  const consult = useSelector(selectVirtualConsult);
  const loading = useSelector(selectLoadingQuestionnaires);

  const { nodes, setNodes, selectedQuestions, setSelectedQuestions, handleSubmit, setQuestionnaireUpdated } =
    useContext(QuestionnaireBuilderContext);

  const sorting = initialSorting[`${type}Questionnaire`];
  const [data, setData] = useState([]);
  const [selectIds, setSelectIds] = useState([]);
  const [savedIds, setSavedIds] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const disabledAddButton = !_.difference(selectIds, savedIds).length;

  const dispatch = useDispatch();

  // Initial load of table data
  useEffect(() => {
    dispatch(getContent(currentTenant.id, type, '', sorting.field, sorting.dir, 0, 10));
  }, [currentTenant.id, actions, type, sorting]);

  // add selected property to every list object for checkbox based on if question/outcome has already been selected for questionnaire
  useEffect(() => {
    if (consult && consult[type] && data !== consult[type]) {
      const selectedOutcomesIds = nodes.filter((item) => item.outcome).map((item) => item.outcome.id);
      const selectedQuestionIds = selectedQuestions.map((q) => q.id);
      const savedData = [...selectedOutcomesIds, ...selectedQuestionIds];
      const selectedIds = [...savedData, ...selectIds];

      const selectData = consult[type].content.map((dataItem) => ({
        addedToQuestionnaire: selectedIds.includes(dataItem.id),
        selected: selectedIds.includes(dataItem.id),
        ...dataItem,
      }));
      setData(selectData);
      setSelectIds(selectedIds);
      setSavedIds(savedData);
    }
    // eslint-disable-next-line
  }, [consult, type]);

  // on click of row/row checkbox, select/unselect item if not already in questionnaire
  // Keywords: question import, render selection
  const selectionChange = (dataItem) => {
    const newSelectIds = [...selectIds];
    const index = newSelectIds.indexOf(dataItem.id);
    if (index > -1) {
      newSelectIds.splice(index, 1);
    } else {
      newSelectIds.push(dataItem.id);
    }
    const selectedData = data.map((item) => {
      if (item.id === dataItem.id && !savedIds.includes(item.id)) {
        item.selected = !dataItem.selected;
      }
      return item;
    });
    setSelectIds(newSelectIds);
    setData(selectedData);
  };

  // on click of select all, select/unselect all items on page if not already in questionnaire
  const headerSelectionChange = (event) => {
    const newSelectIds = [...selectIds];
    const { checked } = event.syntheticEvent.target;
    const ids = data.map((item) => {
      const index = newSelectIds.indexOf(item.id);
      if (checked) {
        if (index === -1 && !savedIds.includes(item.id)) {
          newSelectIds.push(item.id);
        }
      } else if (index > -1) {
        newSelectIds.splice(index, 1);
      }
      // only remove selected attribute if item is not in savedIds (already in questionnaire)
      if (!savedIds.includes(item.id)) {
        item.selected = checked;
      }
      return item;
    });
    setSelectIds(newSelectIds);
    setData(ids);
  };

  const addSelected = async () => {
    const fetchedContent = await getContentByIds();
    // Check for additional questions embedded in combo node
    const missingComboNodeQuestions = fetchedContent.reduce((missingComboNodeQuestionIds, currentItem) => {
      const missingIds =
        currentItem.type === 'combo'
          ? currentItem.nodeList.map((subnode) => {
              if (!savedIds.includes(subnode.questionId) && !selectIds.includes(subnode.questionId))
                return subnode.questionId;
              return [];
            })
          : [];
      missingComboNodeQuestionIds.push(...missingIds);
      return missingComboNodeQuestionIds;
    }, []);
    const additionalContent = await getContentByIds(missingComboNodeQuestions);
    const allNewContent = fetchedContent.concat(additionalContent);
    const deconstructedContent = allNewContent.map((item) => deconstructJson(item));
    let newSelectNodes;
    let newSelections;
    const comboNodes = [];
    let comboNodeMemberIds = [];
    if (type === 'questions') {
      // Process new questions
      const newNodes = deconstructedContent.map((item) => ({
        id: item.id,
        autoAnswerOption: null,
        defaultNextQuestionId: null,
        name: item.name && item.name.translations ? item.name.translations.eng : item.name,
        question: item,
        outcome: null,
        rules: [],
      }));
      // make sure there is always a question as first node
      if (nodes.length && nodes[0].outcome) {
        newSelectNodes = [...newNodes, ...nodes];
      } else {
        newSelectNodes = [...nodes, ...newNodes];
      }
      // Remove combo node member questions from node list
      comboNodeMemberIds = newSelectNodes.reduce((memberIds, currentNode) => {
        if (currentNode.question?.type === 'combo') {
          comboNodes.push(currentNode.id);
          const questionIds = currentNode.question.nodeList.map((subnode) => subnode.questionId);
          memberIds.push(...questionIds);
        }
        return memberIds;
      }, []);
      newSelectNodes = newSelectNodes.filter((node) => !comboNodeMemberIds.includes(node.id));
    } else {
      // Process new outcomes
      const newNodes = deconstructedContent.map((item, index) => ({
        id: item.id,
        autoAnswerOption: null,
        defaultNextQuestionId: null,
        priority: deconstructedContent.length + index + 1,
        question: null,
        outcome: item,
        name: item.name && item.name.translations ? item.name.translations.eng : item.name,
        rules: [],
      }));

      newSelectNodes = [...nodes, ...newNodes];
    }
    newSelections = [...selectedQuestions, ...deconstructedContent].filter(
      (question, i, arr) => arr.findIndex((item) => item.id === question.id) === i
    );
    // Rearrange final question list to group combo node members with parent question
    const comboNodeMembers = [];
    const selectionsWithoutMembers = [];
    const sortedSelections = [];
    newSelections.forEach((question) => {
      if (comboNodeMemberIds.includes(question.id)) {
        comboNodeMembers.push(question);
      } else {
        selectionsWithoutMembers.push(question);
      }
    });
    selectionsWithoutMembers.forEach((question) => {
      if (question.type === 'combo') {
        // Select parent question...
        const groupedNodes = [question];
        // ...then add each child question in sequence
        question.nodeList.forEach((subnode) => {
          const foundNode = comboNodeMembers.find((member) => member.id === subnode.questionId);
          if (foundNode) groupedNodes.push(foundNode);
        });
        sortedSelections.push(...groupedNodes); // Add newly grouped items to full question list
      } else {
        sortedSelections.push(question);
      }
    });
    // Now, with questions rearranged and grouped, update the questionnaire
    setQuestionnaireUpdated(true);
    setSelectedQuestions(sortedSelections);
    setSelectedStatus(!isSelected);
    setNodes(newSelectNodes);
    handleSubmit(sortedSelections, newSelectNodes, formData);
  };

  // Retrieve the full objects from the database for newly added questions or outcomes
  const getContentByIds = async (additionalContent) => {
    const id_token = localStorage.getItem('id_token');
    const accountId = localStorage.getItem('accountId');
    const tenantId = localStorage.getItem('tenantId');
    // If called to retrieve additional content, use those Ids instead of selected Ids in state
    const idsToRetrieve = additionalContent || selectIds;
    const content = await Promise.all(
      idsToRetrieve.map(async (id) => {
        if (!savedIds.includes(id)) {
          try {
            const result = await axios({
              method: 'GET',
              url: `${API_ROOT}/virtual-consult/v1/${currentTenant.id}/${type}/${id}`,
              withCredentials: true,
              crossorigin: true,
              headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json;charset=UTF-8',
                Accept: 'application/json',
                Authorization: `Bearer ${id_token}`,
                Id_Token: id_token,
                'X-SF-ACCOUNT': accountId,
                'X-SF-TENANT': tenantId,
              },
            });
            return result.data;
          } catch (e) {
            return e;
          }
        }
      })
    );
    const filteredQuestions = content.filter((result) => !(result instanceof Error));
    return filteredQuestions.filter(Boolean);
  };

  // handles all table updates from table wrapper
  const onTableUpdate = (page, pageSize, sort, direction, searchValue, filter, tags) => {
    dispatch(getContent(currentTenant.id, type, searchValue, sort, direction, page, pageSize, tags));
  };

  const handleSelectAll = (value) => {
    setSelectIds([]);
    setSelectAll(value);
  };

  let cols = columns[type];

  return (
    <Dialog onClose={handleCancel} open={dialogOpen} maxWidth={!userSpecific ? 'xl' : 'sm'} fullWidth>
      <DialogTitle id="simple-dialog-title">
        {`Select ${utils.capitalizeString(type)} To Add`}
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleCancel}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid item xs={12}>
          <AppKendoTableWrapper
            data={transformSelectList(data, consult[type])}
            onSelectionChange={selectionChange}
            onHeaderSelectionChange={headerSelectionChange}
            initialSort={sorting.field}
            initialDir={sorting.dir}
            showButtons
            showSearchbar
            showTagSearch={type === 'questions'}
            sorted={sorting}
            columns={cols}
            currentTenant={currentTenant}
            page={consult[type] && consult[type].pageable.pageNumber}
            onRowClick={selectionChange}
            loading={loading}
            onTableUpdate={onTableUpdate}
            selectIds={selectIds}
            onSelectAll={handleSelectAll}
            selectAll={selectAll}
            setSelectIds={setSelectIds}
          />
        </Grid>
        <Grid container>
          <Grid item xs={6}>
            <Button variant="contained" color="primary" onClick={addSelected} disabled={disabledAddButton}>
              {`Add selected ${type}`}
            </Button>
            <Button variant="outlined" color="primary" style={{ marginLeft: 15 }} onClick={handleCancel}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default withStyles(QuestionnaireBuilderStyles)(QuestionnaireSettings);
