export const selectOneupSyncStatus = (state) => state.recordRequest.oneupSyncStatus;
export const selectOneupSyncStatusLoading = (state) => state.recordRequest.oneupSyncStatusLoading;
export const selectRecordRequest = (state) => state.recordRequest.recordRequest;
export const selectRecordRequestLoading = (state) => state.recordRequest.recordRequestLoading;
export const selectCCD = (state) => state.recordRequest.ccd;
export const selectCCDLoading = (state) => state.recordRequest.ccdLoading;
export const selectAllRecordRequests = (state) => state.recordRequest.allRecordRequests;
export const selectAllRecordRequestsLoading = (state) => state.recordRequest.allRecordRequestsLoading;

export const selectProviderList = (state) => state.providerList.providerList;
export const selectProviderListLoading = (state) => state.providerList.providerListLoading;
