/* eslint-disable no-nested-ternary */
/* eslint-disable array-callback-return */
/* eslint-disable no-prototype-builtins */
/* eslint-disable import/no-cycle */
// TODO: This entire file needs to be rewritten to remove these linting errors
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { initForm, resetForm } from '../../../../Shared/Forms/formSlice';
import { selectFormData } from '../../../../Shared/Forms/selectors';
import { selectCurrentTenant } from '../../../../Shared/selectors';
import { setEditingReviewerQuestion } from '../../../Reviewer/reviewerSlice';
import { selectAnnotationList } from '../../../Reviewer/selectors';
import { clearReporter, getContentById } from '../../../virtualConsultSlice';
import { deconstructJson } from '../../helpers/formHelper';
import { QuestionnaireBuilderContext } from '../../QuestionnaireBuilderWrapper';

import Outcome from './Outcome';
import Question from './Question';
import QuestionEditForm from './QuestionEditForm';

function QuestionnairePreview(props) {
  const { setDisableButtons, disableButtons, editingQuestion, setEditingQuestion, actions } = props;
  const currentTenant = useSelector(selectCurrentTenant);
  const formData = useSelector(selectFormData);

  const {
    nodes,
    setNodes,
    selectedQuestions,
    setSelectedQuestions,
    handleSubmit,
    setQuestionnaireUpdated,
    windowPosition,
    setWindowPosition,
    questionsRef,
  } = useContext(QuestionnaireBuilderContext);

  const dispatch = useDispatch();

  const annotationList = useSelector(selectAnnotationList);

  const [previewQuestionId, setPreviewQuestionId] = useState(null);
  const [previewOutcomeIndex, setPreviewOutcomeIndex] = useState(null);
  const [questionnaireData, setQuestionnaireData] = useState({});
  const [labeledQuestions, setLabeledQuestions] = useState([]);
  const [questionsWithAnnotations, setQuestionsWithAnnotations] = useState([]);

  // Saves the current window position to state
  const captureWindowPosition = (idx) => {
    setWindowPosition(idx);
  };

  useEffect(() => {
    if (annotationList && annotationList.length) {
      const questionIds = annotationList.filter((item) => !item.done).map((item) => item.questionId);
      setQuestionsWithAnnotations(questionIds);
    }
    // eslint-disable-next-line
  }, [annotationList]);

  useEffect(() => {
    dispatch(clearReporter());
  }, [actions]);

  // Label Combo node components for styled rendering
  useEffect(() => {
    if (selectedQuestions.length) {
      let subnodes = [];
      const labelComboNodes = selectedQuestions.map((question) => {
        if (question.type === 'combo') {
          subnodes = question.nodeList.map((subnode) => subnode.questionId);
          return {
            ...question,
            combo: 'parent',
          };
        }
        if (subnodes.includes(question.id)) {
          return {
            ...question,
            combo: 'child',
          };
        }
        return question;
      });
      setLabeledQuestions(labelComboNodes);
    }
  }, [selectedQuestions]);

  useEffect(() => {
    if (!nodes.length) {
      const defaultNodes = selectedQuestions.map((item) => {
        if (item.hasOwnProperty('links') || item.hasOwnProperty('testId')) {
          return {
            id: item.id,
            autoAnswerOption: null,
            defaultNextQuestionId: null,
            question: null,
            outcome: item,
            rules: [],
          };
        }
        return {
          id: item.id,
          autoAnswerOption: null,
          defaultNextQuestionId: null,
          question: item,
          outcome: null,
          rules: [],
        };
      });
      setNodes(defaultNodes);
    }
  }, [nodes.length, selectedQuestions, setNodes]);

  // Return to current window position once page is loaded
  useEffect(() => {
    if (questionsRef.current[nodes.length - 1]) {
      let position = windowPosition;
      // Check if we don't try to scroll to the removed item that can be cause of error
      if (!questionsRef.current[windowPosition]) {
        if (windowPosition > 0 && questionsRef.current[windowPosition - 1]) {
          position = windowPosition - 1;
        } else {
          return;
        }
      }
      questionsRef.current[position].scrollIntoView();
      // Offset for header
      window.scrollBy(0, -75);
    }
    // eslint-disable-next-line
  }, [questionsRef[selectedQuestions.length - 1], nodes, disableButtons]);

  // Triggers editing functions on a question
  const setQuestionEdit = (question, index) => {
    captureWindowPosition(index);
    setEditingQuestion(question);
    setQuestionnaireData(formData);
  };

  const handleClickQuestion = (id, type, index) => {
    captureWindowPosition(index);
    if (disableButtons && id !== previewQuestionId) return;
    if (type === 'question') {
      setPreviewOutcomeIndex(null);
      setPreviewQuestionId(id);
    } else {
      setPreviewQuestionId(null);
      setPreviewOutcomeIndex(id);
    }
  };

  // on cancel of question update, fetch latest questionnaire content and reset formdata
  const handleQuestionUpdateCancel = async () => {
    const url = window.location.pathname;
    const id = url.split('/').pop();
    dispatch(setEditingReviewerQuestion(null));
    setEditingQuestion({});
    const questionnaireFromDb = await dispatch(getContentById(currentTenant.id, 'questionnaires', id));
    await dispatch(resetForm());
    await dispatch(initForm(deconstructJson(questionnaireFromDb)));
  };

  // on cancel of node/jump changes, reset form/nodes to saved questionnaire
  const cancelNodeUpdate = () => {
    const url = window.location.pathname;
    const id = url.split('/').pop();
    dispatch(getContentById(currentTenant.id, 'questionnaires', id)).then((res) => {
      const questionnaireForm = deconstructJson(res);
      dispatch(resetForm('Cancel Node')).then(() => dispatch(initForm(questionnaireForm)));
      setNodes(questionnaireForm.nodes);
      setSelectedQuestions(questionnaireForm.questionList);
    });
  };

  return (
    <Paper style={{ padding: 20 }}>
      {!nodes.length && !labeledQuestions.length ? (
        <Grid container justifyContent="center">
          <Grid item>
            <Typography variant="body1" color="inherit">
              Add questions or outcomes to begin building
            </Typography>
          </Grid>
        </Grid>
      ) : Object.keys(editingQuestion).length ? (
        <QuestionEditForm
          editingQuestion={editingQuestion}
          setEditingQuestion={setEditingQuestion}
          questionnaireData={questionnaireData}
          nodes={nodes}
          setNodes={setNodes}
          selectedQuestions={selectedQuestions}
          setSelectedQuestions={setSelectedQuestions}
          handleSubmit={handleSubmit}
          setQuestionnaireUpdated={setQuestionnaireUpdated}
          handleQuestionUpdateCancel={handleQuestionUpdateCancel}
        />
      ) : (
        // Render questions list to Questionnaire Builder
        <Grid container spacing={4}>
          {labeledQuestions
            .filter((item) => !item.hasOwnProperty('links') && !item.hasOwnProperty('testId'))
            .map((item, index) => {
              // Do not show combo node children at this level
              if (item.combo !== 'child') {
                return (
                  <Question
                    key={item.id}
                    question={item}
                    questionIndex={index}
                    setDisableButtons={setDisableButtons}
                    previewQuestionId={previewQuestionId}
                    disableButtons={disableButtons}
                    onClickQuestion={handleClickQuestion}
                    setQuestionEdit={setQuestionEdit}
                    cancelNodeUpdate={cancelNodeUpdate}
                    captureWindowPosition={captureWindowPosition}
                    labeledQuestions={labeledQuestions}
                    questionsWithAnnotations={questionsWithAnnotations}
                    questionList={selectedQuestions}
                  />
                );
              }
            })}
          {nodes
            .filter((item) => item.outcome)
            .filter((v, i, a) => a.findIndex((t) => t.outcome.id === v.outcome.id) === i)
            .map((item, index) => (
              <Outcome
                key={item.id}
                node={item}
                outcomeIndex={index}
                nodeIndex={selectedQuestions.length + index}
                previewOutcomeIndex={previewOutcomeIndex}
                setPreviewOutcomeIndex={setPreviewOutcomeIndex}
                onClickQuestion={handleClickQuestion}
                disableButtons={disableButtons}
                previewQuestionId={previewQuestionId}
                setOutcomeEdit={setQuestionEdit}
                cancelNodeUpdate={cancelNodeUpdate}
                setDisableButtons={setDisableButtons}
                captureWindowPosition={captureWindowPosition}
              />
            ))}
        </Grid>
      )}
    </Paper>
  );
}

export default QuestionnairePreview;
