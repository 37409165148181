/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/namespace */
import { createSlice } from '@reduxjs/toolkit';
import api from '../../api';
import { handleError } from '../Shared/Content/APIUtils';
import utils from '../Shared/Utils/utils';
import * as dashboardUtils from './helpers/dashboardHelpers';
import { DURATION } from './helpers/mapping';

const initialState = {
  loading: false,
  error: null,
  metadataFiltersList: null,
  data: {
    statistics: null,
    testsTaken: null,
    symptomChecker: null,
    sidelinedMembers: null,
    vaccinationDistribution: null,
    totalTestStatus: null,
    totalVaccinationStatus: null,
    activeInvitedStatus: null,
  },
  systemTotals: null,
  systemTotalsLoading: false,
};

export const legacyDashboardSlice = createSlice({
  name: 'legacyDashboard',
  initialState,
  reducers: {
    setUpdateDashboard: (state, { payload }) => ({
      ...state,
      data: {
        ...state.data,
        ...payload,
        loading: false,
      },
    }),
    setSystemTotals: (state, { payload }) => ({
      ...state,
      systemTotals: payload,
    }),
    setSystemTotalsLoading: (state, { payload }) => ({
      ...state,
      systemTotalsLoading: payload,
    }),
    setLoadingDashboard: (state) => ({
      ...state,
      data: {
        loading: true,
      },
    }),
    getDashboardDataFailure: (state, { payload }) => ({
      ...state,
      error: payload,
    }),
  },
});

export const {
  setUpdateDashboard,
  getDashboardDataFailure,
  setSystemTotals,
  setSystemTotalsLoading,
  setLoadingDashboard,
} = legacyDashboardSlice.actions;

export const getDashboardStatistics = (tenantId, start, end) => async (dispatch) => {
  dispatch(setLoadingDashboard(true));

  // Server requires seconds from epoch starts format
  const startParam = utils.formatToUnixTime(start);
  const endParam = utils.formatToUnixTime(end);

  const dateDiff = utils.diffByDates(end, start, 'months');

  // 1 - daily by default
  let duration = DURATION.DAILY;

  if (dateDiff >= 2 && dateDiff <= 3) {
    duration = DURATION.WEEKLY;
  } else if (dateDiff >= 4) {
    duration = DURATION.MONTHLY;
  }

  const params = { tenantId };

  // Sideline Users Stats & Sideline Members (Future Trend) Graph
  const sidelineStat = api.getDashboardStatisticsRequest('get-sideline-users-stats', params, startParam, endParam);

  // Statistics
  const testStatusStat = api.getDashboardStatisticsRequest('get-lab-test', params, startParam, endParam);

  // Symptom Checker
  const symptomChecker = api.getDashboardStatisticsRequest(
    'get-symptom-checker-distribution',
    params,
    startParam,
    endParam
  );

  // Vaccination
  const vaccinationStat = api.getDashboardStatisticsRequest('get-vaccination', params, startParam, endParam);

  // Active vs. Invited
  const activeInvitedStat = api.getDashboardStatisticsRequest('get-active-invited-user', params, startParam, endParam);

  await Promise.all([sidelineStat, testStatusStat, symptomChecker, vaccinationStat, activeInvitedStat])
    .then((responses) => {
      /* eslint-disable-next-line */
      responses.forEach(([result, error]) => {
        if (error) throw error;
      });

      const statistics = dashboardUtils.combineStatistics({
        sidelinedUsers: responses[0][0]?.data?.stats,
        positiveTests: responses[1][0]?.data?.stats?.positive,
        negativeTests: responses[1][0]?.data?.stats?.negative,
        invalidTests: responses[1][0]?.data?.stats?.invalid,
        totalTests: responses[1][0]?.data?.stats?.total,
      });

      const sidelinedMembers = dashboardUtils.processSidelinedMembers(
        responses[0][0]?.data?.distribution?.sideLineDistribution
      );

      const testsTaken = dashboardUtils.processTestDistribution(responses[1][0]?.data?.distribution, duration);

      const symptomChecker = dashboardUtils.processMultiLineChart(responses[2][0]?.data);

      const vaccinationDistribution = dashboardUtils.processVaccinationDistribution(
        responses[3][0]?.data?.distribution,
        duration
      );

      const totalTestStatus = dashboardUtils.processTotalTestStatus(responses[1][0]?.data?.stats);

      const totalVaccinationStatus = dashboardUtils.processTotalVaccinationStatus(responses[3][0]?.data?.stats);

      const activeInvitedStatus = dashboardUtils.processActiveInvitedStat(responses[4][0]?.data);

      const data = {
        statistics,
        testsTaken,
        symptomChecker,
        sidelinedMembers,
        vaccinationDistribution,
        totalTestStatus,
        totalVaccinationStatus,
        activeInvitedStatus,
      };
      dispatch(setUpdateDashboard(data));
    })
    .catch((e) => {
      const status = e?.response?.status;
      const error = { ...e, message: e?.message || 'Dashboard data request error', status };
      dispatch(getDashboardDataFailure(error));
      dispatch(handleError(error));
    });
};

export const getSystemTotals = (tenantId) => async (dispatch) => {
  // System Totals
  dispatch(setSystemTotalsLoading(true));
  const [res, err] = await api.getDashboardSystemTotalsRequest({ tenantId });
  dispatch(setSystemTotalsLoading(false));

  if (err?.message) {
    return dispatch(handleError(err));
  }

  if (res?.success) {
    dispatch(setSystemTotals(res?.data));
  }
};

export default legacyDashboardSlice.reducer;
