/* eslint-disable import/no-cycle */
import { createSlice } from '@reduxjs/toolkit';
import FileDownload from 'js-file-download';

import api from '../../api/index';
import { showNotification } from '../Notifications/notificationSlice';
import { handleError } from '../Shared/Content/APIUtils';
import { setFormSubmitting } from '../Shared/Forms/formSlice';

const initialState = {
  getOrderDetailsLoading: false,
  orderDetails: null,
  labOrdersLoading: false,
  labOrders: null,
  tenantLabOrdersLoading: false,
  tenantLabOrders: null,
  createOrderLoading: false,
  ordersPagination: false,
  scanHistoryLoading: false,
};

export const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    kendoPaginationStateInit: (state, { payload }) => ({
      ...state,
      ordersPagination: payload,
    }),
    getMasterLabTestRequest: (state, { payload: { testType } }) => ({
      ...state,
      [testType]: {
        ...state[testType],
        loading: true,
        error: null,
      },
    }),
    getMasterLabTestSuccess: (state, { payload: { data, testType } }) => ({
      ...state,
      [testType]: {
        list: data,
        loading: false,
      },
    }),
    getMasterLabTestFailure: (state, { payload: { error, testType } }) => ({
      ...state,
      [testType]: {
        ...state[testType],
        error,
        loading: false,
      },
    }),
    getLabOrderDetailsRequest: (state) => ({
      ...state,
      getOrderDetailsLoading: true,
    }),
    getLabOrderDetailsSuccess: (state, { payload }) => ({
      ...state,
      orderDetails: payload,
      getOrderDetailsLoading: false,
    }),
    getLabRequest: (state) => ({
      ...state,
      labOrdersLoading: true,
    }),
    getLabRequestSuccess: (state, { payload }) => ({
      ...state,
      labOrders: payload,
      labOrdersLoading: false,
    }),
    getTenantLabRequest: (state) => ({
      ...state,
      tenantLabOrdersLoading: true,
    }),
    getTenantLabRequestSuccess: (state, { payload }) => ({
      ...state,
      tenantLabOrders: payload,
      tenantLabOrdersLoading: false,
    }),
    getDiagnosticsFiltersRequest: (state, { payload: { testType } }) => ({
      ...state,
      [testType]: {
        ...state[testType],
        loading: true,
        error: null,
      },
    }),
    getDiagnosticsFiltersSuccess: (state, { payload: { data, testType } }) => ({
      ...state,
      [testType]: {
        list: [...data],
        loading: false,
      },
    }),
    getDiagnosticsFiltersFailure: (state, { payload: { error, testType } }) => ({
      ...state,
      [testType]: {
        ...state[testType],
        error,
        loading: false,
      },
    }),
    createLabRequest: (state) => ({
      ...state,
      createOrderLoading: true,
    }),
    createLabSuccess: (state, { payload: { data, type } }) => ({
      ...state,
      [type]: {
        total: state[type].total + 1,
        items: [data, ...state[type].items],
      },
      createOrderLoading: false,
    }),
    findProviderRequest: (state) => ({
      ...state,
      providers: {
        loading: true,
        error: null,
      },
    }),
    findProviderSuccess: (state, { payload }) => ({
      ...state,
      providers: {
        list: payload,
        loading: false,
      },
    }),
    findProviderFailure: (state, { payload }) => ({
      ...state,
      providers: {
        loading: false,
        error: payload,
      },
    }),
    getUserScanRequest: (state) => ({
      ...state,
      scanHistoryLoading: true,
    }),
    getUserScanSuccess: (state, { payload }) => ({
      ...state,
      scanHistory: payload,
      scanHistoryLoading: false,
    }),

    resetState: () => ({ ...initialState }),
  },
});

export const {
  kendoPaginationStateInit,
  getMasterLabTestRequest,
  getMasterLabTestSuccess,
  getMasterLabTestFailure,
  getLabOrderDetailsRequest,
  getLabOrderDetailsSuccess,
  getLabRequest,
  getLabRequestSuccess,
  getTenantLabRequest,
  getTenantLabRequestSuccess,
  getDiagnosticsFiltersRequest,
  getDiagnosticsFiltersSuccess,
  getDiagnosticsFiltersFailure,
  createLabRequest,
  createLabSuccess,
  findProviderRequest,
  findProviderSuccess,
  findProviderFailure,
  getUserScanRequest,
  getUserScanSuccess,
} = ordersSlice.actions;

export const getMasterLabTestsTenant = (type, tenant, onlyFacility) => async (dispatch) => {
  const [labTestOrderableIdsResponse, errLabTestOrderableIds] = await api.getLabTestOrderableIdsRequest(
    tenant,
    onlyFacility
  );

  const [labTestPanelIdsResponse, errLabTestPanelIds] = await api.getLabTestPanelIdsRequest(tenant);

  if (errLabTestOrderableIds?.message || errLabTestPanelIds?.message) {
    dispatch(
      getMasterLabTestFailure({
        error: errLabTestOrderableIds?.message || errLabTestPanelIds?.message,
        testType: type,
      })
    );
    dispatch(handleError(errLabTestOrderableIds?.message || errLabTestPanelIds?.message));
  }

  if (labTestOrderableIdsResponse && labTestPanelIdsResponse) {
    dispatch(
      getMasterLabTestSuccess({
        data: [...labTestOrderableIdsResponse, ...labTestPanelIdsResponse],
        testType: type,
      })
    );
  }
};

export const createPHROrder = (tenant, data) => async (dispatch) => {
  const [result, error] = await api.createPHROrderRequest(tenant, data);

  if (error?.message) {
    dispatch(setFormSubmitting(false));
    dispatch(handleError(error));
  }

  if (result) {
    dispatch(showNotification('Lab Order Successfully Created', 'success'));
  }
};

export const getOrderDetails = (encounterId) => async (dispatch) => {
  dispatch(getLabOrderDetailsRequest());

  const [result, error] = await api.getOrderDetailsRequest(encounterId);

  if (error?.message) {
    dispatch(handleError(error));
  }

  if (result) {
    dispatch(getLabOrderDetailsSuccess(result.data));
  }
};

export const getVendorDetail = (type, endpoint) => async (dispatch) => {
  dispatch(getMasterLabTestRequest({ testType: type }));

  const [result, error] = await api.getVendorDetailRequest(endpoint);
  if (error?.message) {
    dispatch(
      getMasterLabTestFailure({
        error: error.message,
        testType: type,
      })
    );
    dispatch(handleError(error.message));
  }

  if (result) {
    dispatch(
      getMasterLabTestSuccess({
        data: result,
        testType: type,
      })
    );
  }
};

export const getLabTests = (page, pageSize, sort, direction, username, tenant) => async (dispatch) => {
  dispatch(getLabRequest());

  const params = {
    pageNumber: page,
    pageSize,
    sort,
    direction,
  };

  const [result, error] = await api.getLabTestsRequest(tenant, username, params);

  if (error?.message) {
    dispatch(handleError(error.message));
  }

  if (result?.data) {
    dispatch(getLabRequestSuccess(result.data));
  }
};

export const getTenantLabTests =
  (page, pageSize, sort, direction, searchValue, tenant, filter, userId) => async (dispatch) => {
    dispatch(getTenantLabRequest());

    const data = {
      pageNumber: page,
      pageSize,
      term: searchValue || '',
      tenantId: tenant,
      source: filter ? filter.source : '',
      testFormat: filter ? filter.testFormat : '',
      userId,
      orderAsc: direction === 'ASC',
      orderSortBy: sort,
    };

    const [result, error] = await api.getTenantLabTestsRequest(tenant, data);

    if (error?.message) {
      dispatch(handleError(error.message));
    }

    if (result?.data) {
      dispatch(getTenantLabRequestSuccess(result.data));
    }
  };

export const getDiagnosticsFilters = (type) => async (dispatch) => {
  dispatch(
    getDiagnosticsFiltersRequest({
      testType: type,
    })
  );

  const [result, error] = await api.getDiagnosticsFiltersRequest(type);

  if (error?.message) {
    dispatch(
      getDiagnosticsFiltersFailure({
        error: error.message,
        testType: type,
      })
    );
    dispatch(handleError(error.message));
  }

  if (result) {
    dispatch(
      getDiagnosticsFiltersSuccess({
        data: result,
        testType: type,
      })
    );
  }
};

export const createBulkLabOrder = (usersToTest, source, type, tenant, data, onlyForNewUser) => async (dispatch) => {
  const params = {
    usersToTest,
    onlyForNewUser,
  };

  const [result, error] = await api.createBulkLabOrderRequest(tenant, source, params, data);

  if (error?.message) {
    dispatch(setFormSubmitting(false));
    dispatch(handleError(error.message));
  }

  if (result) {
    dispatch(showNotification('Lab Order Successfully Created. Sync table for order updates.', 'success'));
  }
};

export const createLabOrder = (userToTest, source, type, tenant, data, isUserProfile) => async (dispatch, getState) => {
  const kendoState = getState().orders.ordersPagination;

  dispatch(createLabRequest());

  const [result, error] = await api.createLabOrderRequest(tenant, data);

  if (error?.message) {
    dispatch(setFormSubmitting(false));
    dispatch(handleError(error.message));
    return { success: false };
  }

  if (result) {
    if (isUserProfile) {
      dispatch(
        getTenantLabTests(
          kendoState ? kendoState.skip : 0,
          kendoState ? kendoState.take : 10,
          kendoState ? kendoState.sort[0]?.field : 'lastUpdated',
          kendoState ? kendoState.sort[0]?.dir?.toUpperCase() : 'DESC',
          undefined,
          tenant,
          undefined,
          userToTest
        )
      );
    } else {
      dispatch(
        getTenantLabTests(
          kendoState ? kendoState.skip : 0,
          kendoState ? kendoState.take : 10,
          kendoState ? kendoState.sort[0]?.field : 'lastUpdated',
          kendoState ? kendoState.sort[0]?.dir?.toUpperCase() : 'DESC',
          kendoState ? kendoState.search : '',
          tenant,
          kendoState ? kendoState.filter : ''
        )
      );
    }
    return { success: true };
  }
};

export const renewLabTest = (testId, userId, tenant) => async (dispatch, getState) => {
  const kendoState = getState().orders.ordersPagination;
  const [result, error] = await api.renewLabTestRequest(testId);

  if (error?.message) {
    dispatch(handleError(error.message));
  }

  if (result) {
    dispatch(showNotification('Renew Test Email Successfully Sent', 'success'));
    dispatch(getLabTests(0, 10, 'created', 'DESC', userId));
    dispatch(
      getTenantLabTests(
        kendoState ? kendoState.skip : 0,
        kendoState ? kendoState.take : 10,
        kendoState ? kendoState.sort[0]?.field : 'lastUpdated',
        kendoState ? kendoState.sort[0]?.dir?.toUpperCase() : 'DESC',
        kendoState ? kendoState.search : '',
        tenant
      )
    );
  }
};

export const downloadResults = (testId, name) => async (dispatch) => {
  const [result, error] = await api.downloadResultsRequest(testId);

  if (error?.message) {
    dispatch(handleError(error.message, 'Impossible to get the results for this patient test.'));
  }

  if (result) {
    FileDownload(result, `${name}.pdf`);
  }
};

export const downloadRequisition = (testId, name) => async (dispatch) => {
  const [result, error] = await api.downloadRequisitionRequest(testId);

  if (error?.message) {
    dispatch(handleError(error.message, 'Impossible to get the results for this patient test.'));
  }

  if (result) {
    FileDownload(result, `${name}.pdf`);
  }
};

export const downloadLabel = (testId, name) => async (dispatch) => {
  const [result, error] = await api.downloadLabelRequest(testId);

  if (error?.message) {
    dispatch(handleError(error.message, 'Impossible to get the results for this patient test.'));
  }

  if (result) {
    FileDownload(result, `${name}.pdf`);
  }
};

export const findProvider =
  (query, skip = 0, limit = 20) =>
  async (dispatch) => {
    const params = {
      q: query,
      skip,
      limit,
    };

    dispatch(findProviderRequest());
    const [result, error] = await api.findProviderRequest(params);

    if (error?.message) {
      dispatch(findProviderFailure(error.message));
      dispatch(handleError(error.message));
    }

    if (result) {
      dispatch(findProviderSuccess(result.data));
    }
  };

export const getUserScanHistory = (page, pageSize, sort, direction, username, tenant) => async (dispatch) => {
  sort = sort === 'overriddenOn' ? 'outcome.overriddenOn' : sort;
  const params = {
    skip: page * pageSize,
    limit: pageSize,
    sort,
    direction,
    username,
  };

  dispatch(getUserScanRequest());
  const [result, error] = await api.getUserScanHistoryRequest(tenant, params);

  if (error?.message) {
    dispatch(handleError(error.message));
  }

  if (result) {
    dispatch(getUserScanSuccess(result));
  }
};

export default ordersSlice.reducer;
