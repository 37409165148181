/* eslint-disable no-prototype-builtins */
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import Delete from '@material-ui/icons/Delete';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import React, { useEffect, useState } from 'react';

import { createInputHelper } from '../../../../Shared/Forms/helpers/createInputHelper';
import { nodeNameField } from '../../helpers/formHelper';

function Node(props) {
  const {
    node,
    nodeIndex,
    handleNodeNameChange,
    addNode,
    removeNode,
    children,
    setShowAutoGenerate,
    showAutoGenerate,
  } = props;

  useEffect(() => {
    if (node && node.question && node.question.type !== 'multiple') return;

    if (node && node.rules && !node.rules.length) return;

    const ruleIndex =
      node && node.rules.findIndex((rule) => rule && rule.rules[0] && rule.rules[0].hasOwnProperty('size'));

    if (ruleIndex !== -1) {
      setShowAutoGenerate(true);
    }
  }, [node, setShowAutoGenerate, showAutoGenerate]);

  const [showNode, setShowNode] = useState(true);

  return (
    <Paper style={{ padding: 15, marginTop: 25 }}>
      <Grid container>
        <Grid item xs={9}>
          {createInputHelper(nodeNameField, { name: node.name }, (e) => handleNodeNameChange(e.value, nodeIndex))}
        </Grid>
        <Grid item xs={3}>
          <Tooltip title="Add node">
            <IconButton onClick={addNode}>
              <AddCircleOutline />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete node">
            <IconButton onClick={() => removeNode(node.id)}>
              <Delete />
            </IconButton>
          </Tooltip>
          {showNode ? (
            <IconButton onClick={() => setShowNode(false)}>
              <ExpandLess />
            </IconButton>
          ) : (
            <IconButton onClick={() => setShowNode(true)}>
              <ExpandMore />
            </IconButton>
          )}
        </Grid>
      </Grid>

      {showNode && children}
    </Paper>
  );
}

export default Node;
