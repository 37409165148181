/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import Grid from '@material-ui/core/Grid';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import React, { useState, useCallback, useMemo } from 'react';
import { createInputHelper } from '../Shared/Forms/helpers/createInputHelper';
import { medicationDialogFormMap } from './helpers/formHelper';

function MedicationDialog(props) {
  const { closeDialog, selectedMedication, dialogMode, updateMedication } = props;

  const medication = {
    id: '',
    medicationSystem: '',
    dateAsserted: '',
    hasTaken: 'N',
    dosageText: '',
    action: '',
    doseUnit: '',
    doseTiming: '',
    doseOther: '',
    routeName: '',
    routeCode: '',
    routeSystem: '',
    form: '',
    frequency: 0,
    period: 0,
    periodUnits: '',
    comment: '',
    effectiveStartDate: '',
    effectiveEndDate: '',
    doseStartTime: '',
    doseEndTime: '',
    quantityCode: '',
    quantityUnit: '',
    operationType: '',
    rcopiaId: '',
    prescriptionRcopiaId: '',
    quantityValue: 0,
  };

  const [errors, setErrors] = useState(
    dialogMode === 'Edit'
      ? {
          medicationName: false,
          medicationCode: false,
          status: false,
          strength: false,
          dose: false,
        }
      : {
          medicationName: true,
          medicationCode: true,
          status: true,
          strength: true,
          dose: true,
          quantityValue: true,
        }
  );
  const [medicationData, setMedicationData] = useState(dialogMode === 'Edit' ? selectedMedication : medication);

  const [strengthEnums, setStrengthEnums] = useState(dialogMode === 'Edit' ? [] : []);

  const [defaultItem, setDefaultItem] = useState(
    dialogMode === 'Edit' ? { text: medicationData.strength, id: medicationData.medicationCode } : null
  );

  const updateData = useCallback(
    (name, value) => {
      const localErrors = errors;
      const localMedicationData = medicationData;
      switch (name) {
        case 'medicationName':
          localErrors.medicationName = value.length <= 0;
          localMedicationData.medicationName = value;
          break;
        case 'medicationCode':
        case 'code':
          localErrors.medicationCode = value.length <= 0;
          localMedicationData.medicationCode = value;
          break;
        case 'strength':
          localErrors.strength = value.length <= 0;
          localMedicationData.strength = value;
          break;
        case 'form':
          localMedicationData.form = value;
          break;
        case 'action':
          localMedicationData.action = value;
          break;
        case 'dose':
          localErrors.dose = value.length <= 0;
          localMedicationData.dose = value;
          break;
        case 'doseUnit':
          localMedicationData.doseUnit = value;
          break;
        case 'doseTiming':
          localMedicationData.doseTiming = value;
          break;
        case 'routeName':
          localMedicationData.routeName = value;
          break;
        case 'doseStartTime':
          localMedicationData.doseStartTime = value;
          break;
        case 'doseEndTime':
          localMedicationData.doseEndTime = value;
          break;
        case 'effectiveStartDate':
          localMedicationData.effectiveStartDate = value;
          break;
        case 'effectiveEndDate':
          localMedicationData.effectiveEndDate = value;
          break;
        case 'quantityValue':
          localErrors.quantityValue = value <= 0;
          localMedicationData.quantityValue = value;
          break;
        case 'status':
          localErrors.status = value.length <= 0;
          localMedicationData.status = value;
          break;
        case 'notes':
          localMedicationData.notes[0].message = value;
          break;
        default:
          break;
      }
      setMedicationData((prevState) => ({
        ...prevState,
        ...localMedicationData,
      }));
      setErrors((prevState) => ({ ...prevState, ...localErrors }));
    },
    [errors, medicationData]
  );

  const submit = () => {
    const localMedicationData = medicationData;
    for (const key in errors) {
      if (errors[key] !== null && errors[key] !== false) return;
    }
    if (dialogMode === 'Edit') {
      localMedicationData.operationType = 'Edit';
    } else if (dialogMode === 'Add') {
      localMedicationData.operationType = 'Add';
    }
    setMedicationData((prevState) => ({
      ...prevState,
      ...localMedicationData,
    }));
    updateMedication(localMedicationData);
    closeDialog();
  };

  const handleCancel = () => {
    closeDialog();
  };

  const handleAutoSetValue = useCallback((values) => {
    const tempStrengthEnums = [];
    for (const key in values) {
      const temp = values[key];
      if (temp) {
        for (const key1 in temp) {
          const obj = {};
          obj.text = key1;
          obj.id = { strength: key1, code: temp[key1] };
          tempStrengthEnums.push(obj);
        }
      } else {
        setDefaultItem(null);
      }
    }
    setStrengthEnums(tempStrengthEnums);
  }, []);

  const handleChangeWithParams = useCallback(
    (name, value) => {
      if (name === 'strength') {
        for (const key in value) {
          updateData(key, `${value[key]}`);
          if (key === 'strength') {
            setDefaultItem({ text: value.strength, id: value.code });
          }
        }
      } else if (name === 'medicationName') {
        if (value) updateData(name, value.medicationName);
        else {
          updateData(name, '');
          updateData('strength', '');
          updateData('code', '');
        }
      } else {
        updateData(name, value);
      }
    },
    [updateData]
  );

  const handleChangeTypahead = useCallback(
    (event) => {
      const { name } = event.target;
      const { value } = event.target;
      updateData(name, value);
    },
    [updateData]
  );

  const formFields = useMemo(
    () => medicationDialogFormMap(dialogMode, strengthEnums, defaultItem),
    [dialogMode, strengthEnums, defaultItem]
  );

  return (
    <Dialog title={`${dialogMode} Medication`} onClose={handleCancel} width={800}>
      <Grid container spacing={2}>
        {createInputHelper(
          formFields,
          medicationData,
          handleChangeTypahead,
          handleChangeWithParams,
          handleAutoSetValue
        )}
      </Grid>
      <DialogActionsBar>
        <button className="k-button" onClick={handleCancel}>
          Cancel
        </button>
        <button className="k-button" onClick={submit} disabled={Object.values(errors).some((i) => i === true)}>
          {dialogMode === 'Add' ? 'Add' : 'Edit'}
        </button>
      </DialogActionsBar>
    </Dialog>
  );
}

export default MedicationDialog;
