/* eslint-disable react/no-array-index-key */
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Radio from '@material-ui/core/Radio';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MetaDataSettingsStyles from '../../../assets/jss/components/MetaDataSettingsStyles';
import { showConfirmation } from '../../Confirmation/confirmationSlice';
import { showNotification } from '../../Notifications/notificationSlice';
import {
  addMetaDataKey,
  enableDisplayOnCheckerScreen,
  getCheckerScreenParam,
  getMetaDataList,
  updateMetaDataKey,
  updateMetaDataKeyEnabling,
} from '../networksSlice';
import { selectCheckerScreen, selectMetaDataList } from '../selectors';
import MetaDataEditorDialog from './MetaDataEditorDialog';

const disabledControls = ['User Segment'];

export function MetaDataListWrapper(props) {
  const { currentTenant, classes } = props;

  const metaDataList = useSelector(selectMetaDataList);
  const checkerScreen = useSelector(selectCheckerScreen);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [metaDataListLocal, setMetaDataListLocal] = useState([]);
  const [editingMetaDataKey, setEditingMetaDataKey] = useState(null);
  const [updateMetaDataKeyLoading, setUpdateMetaDataKeyLoading] = useState(false);
  const [displayOnCheckerScreen, setDisplayOnCheckerScreen] = useState(false);
  const [selectedKeyword, setSelectedKeyword] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMetaDataList(currentTenant.id));
    dispatch(getCheckerScreenParam(currentTenant.id));
  }, [currentTenant.id]);

  const handleUpdateMetaDataKeyword = useCallback(
    async (keywordValue, editingKeyword, forceAdd) => {
      setUpdateMetaDataKeyLoading(true);

      if (editingKeyword) {
        await dispatch(updateMetaDataKey(currentTenant.id, editingKeyword, keywordValue));
      } else {
        await dispatch(addMetaDataKey(currentTenant.id, keywordValue, forceAdd));
      }

      setUpdateMetaDataKeyLoading(false);
      setEditingMetaDataKey(null);
      closeDialog();
    },
    [currentTenant.id]
  );

  useEffect(() => {
    if (metaDataList && metaDataList.length) {
      metaDataList.forEach((item) => {
        if (item.enabled) setSelectedKeyword(item.key);
      });

      const isExistUserSegment = metaDataList.find((x) => x.key === 'User Segment');
      if (!isExistUserSegment) handleUpdateMetaDataKeyword('User Segment', null, true);

      setMetaDataListLocal(metaDataList);
    }
  }, [handleUpdateMetaDataKeyword, metaDataList]);

  useEffect(() => {
    setDisplayOnCheckerScreen(checkerScreen);
  }, [checkerScreen]);

  const handleDelete = (metaDataKey) => {
    dispatch(
      showConfirmation(
        'Confirm',
        'Are you sure you want to delete this metadata field?',
        'metaDataKeyDelete',
        currentTenant,
        metaDataKey
      )
    );
  };

  const handleOpenEditing = (editingKeyword) => {
    setDialogOpen(true);
    setEditingMetaDataKey(editingKeyword);
  };

  const handleSelectForCheckerScreen = (selectedKeyword) => {
    dispatch(updateMetaDataKeyEnabling(currentTenant.id, selectedKeyword));
    setSelectedKeyword(selectedKeyword);
  };

  const handleChangeDisplayingKeywords = () => {
    if (!metaDataListLocal || !metaDataListLocal.length) {
      return dispatch(showNotification('Please create a custom metadata field first', 'error'));
    }

    setDisplayOnCheckerScreen(!displayOnCheckerScreen);
    dispatch(enableDisplayOnCheckerScreen(currentTenant.id, !displayOnCheckerScreen));
  };

  const closeDialog = () => {
    setDialogOpen(false);
    setEditingMetaDataKey(null);
  };

  return (
    <Grid container className={classes.container}>
      <Grid item xs={7}>
        {metaDataListLocal && metaDataListLocal.length
          ? metaDataListLocal.map((item, key) => (
              <Grid container key={key}>
                <Grid item xs={6} style={{ paddingRight: 6 }}>
                  <TextField
                    label="Metadata key"
                    name="name"
                    margin="normal"
                    value={item.key}
                    autoComplete="off"
                    type="text"
                    fullWidth
                    disabled
                  />
                </Grid>

                <Grid container item alignItems="flex-end" xs={3}>
                  {!disabledControls.includes(item.key) && (
                    <>
                      <Tooltip title="Edit Metadata Key">
                        <IconButton onClick={() => handleOpenEditing(item.key)}>
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete Metadata Key">
                        <IconButton onClick={() => handleDelete(item.key)} data-testid="expansionPanelDeleteBtn">
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                </Grid>
                <Grid
                  container
                  item
                  alignItems="flex-end"
                  xs={3}
                  style={{ position: 'relative', justifyContent: 'center' }}
                >
                  {displayOnCheckerScreen && key === 0 && (
                    <Typography className={classes.radioHeader}>Show On Checker Result Screen</Typography>
                  )}
                  {displayOnCheckerScreen && !disabledControls.includes(item.key) && (
                    <Grid item>
                      <Radio
                        color="primary"
                        style={{ padding: '12px' }}
                        checked={selectedKeyword === item.key}
                        onChange={() => handleSelectForCheckerScreen(item.key)}
                        value={item.key || false}
                        name={`radio-button-${item.key}`}
                        inputProps={{ 'aria-label': item.key }}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            ))
          : null}

        <Grid item xs={12} style={{ marginTop: '27px' }}>
          <Button color="primary" variant="contained" onClick={() => setDialogOpen(true)}>
            Add MetaData
          </Button>
        </Grid>
      </Grid>

      <Grid item xs={5} container alignItems="baseline" justifyContent="center" style={{ paddingTop: '25px' }}>
        <FormGroup row>
          <FormControlLabel
            control={
              <Switch
                checked={displayOnCheckerScreen || false}
                onChange={handleChangeDisplayingKeywords}
                name="emailSending"
                color="primary"
              />
            }
            label={<Typography variant="subtitle1">Display User Metadata On Checker Result Screen</Typography>}
            labelPlacement="end"
          />
        </FormGroup>
      </Grid>

      {dialogOpen && (
        <MetaDataEditorDialog
          onCloseDialog={closeDialog}
          updateMetaDataKeyLoading={updateMetaDataKeyLoading}
          editingMetaDataKey={editingMetaDataKey}
          onHandleUpdateMetaDataKeyword={handleUpdateMetaDataKeyword}
        />
      )}
    </Grid>
  );
}

export default withStyles(MetaDataSettingsStyles)(MetaDataListWrapper);
