import { Card } from '@material-ui/core';
import React from 'react';
import ContentLoader from 'react-content-loader';

export function ProfileCardSkeletonLoading() {
  return (
    <Card
      style={{ borderRadius: '20px', marginBottom: 20, padding: 32, boxSizing: 'border-box', width: '100%' }}
      elevation={0}
    >
      <ContentLoader
        height={145}
        width="100%"
        speed={0.8}
        viewBox="0 0 100% 350"
        backgroundColor="#ecebeb"
        foregroundColor="#f3f3f3"
      >
        {/* profile card */}
        <rect x="8" y="0" rx="16" ry="16" width="140" height="145" />
        <rect x="165" y="0" rx="3" ry="10" width="15%" height="30" />
        <rect x="165" y="calc(50% - 15px)" rx="3" ry="10" width="15%" height="30" />
        <rect x="165" y="calc(100% - 30px)" rx="3" ry="10" width="15%" height="30" />

        <rect x="calc(165px + 15% + 20px)" y="0" rx="3" ry="10" width="15%" height="30" />
        <rect x="calc(165px + 15% + 20px)" y="calc(50% - 15px)" rx="3" ry="10" width="15%" height="30" />
        <rect x="calc(165px + 15% + 20px)" y="calc(100% - 30px)" rx="3" ry="10" width="15%" height="30" />

        {/* /!* QR Code *!/ */}
        <rect x="calc(100% - (145px + 8px))" y="0" rx="16" ry="16" width="140" height="145" />
      </ContentLoader>
    </Card>
  );
}

export function ProfileCardSkeletonLoadingMobile(props) {
  return (
    <ContentLoader
      // height={54}
      // width={320}
      speed={0.8}
      viewBox="0 0 200 210"
      backgroundColor="#ecebeb"
      foregroundColor="#f3f3f3"
      {...props}
    >
      {/* profile card */}
      <rect x="25" y="20" rx="3" ry="3" width="150" height="20" />
      <rect x="50" y="45" rx="3" ry="3" width="100" height="15" />

      {/* Picture and QR Code */}
      {/* <circle cx="50" cy="105" r="40" /> */}
      <rect x="25" y="70" rx="3" ry="3" width="65" height="65" />
      <rect x="115" y="70" rx="3" ry="3" width="65" height="65" />

      <rect x="50" y="155" rx="3" ry="3" width="100" height="10" />
      <rect x="30" y="170" rx="3" ry="3" width="140" height="10" />
      <rect x="50" y="185" rx="3" ry="3" width="100" height="10" />
    </ContentLoader>
  );
}

export function ProfileTabsSkeletonLoading(props) {
  return (
    <ContentLoader
      // height={54}
      // width={320}
      speed={0.8}
      viewBox="0 0 320 70"
      backgroundColor="#ecebeb"
      foregroundColor="#f3f3f3"
      {...props}
    >
      {/* tabs */}
      <rect x="10" y="3" rx="3" ry="3" width="40" height="5" />
      <rect x="75" y="3" rx="3" ry="3" width="40" height="5" />
      <rect x="140" y="3" rx="3" ry="3" width="40" height="5" />
      <rect x="205" y="3" rx="3" ry="3" width="40" height="5" />
      <rect x="270" y="3" rx="3" ry="3" width="40" height="5" />
      <rect x="0" y="11" rx="0" ry="0" width="320" height="1" />

      {/* forms */}
    </ContentLoader>
  );
}

export function ProfileInfoSkeletonLoading(props) {
  return (
    <ContentLoader
      height={350}
      // width={320}
      speed={0.8}
      viewBox="0 0 100% 170"
      backgroundColor="#ecebeb"
      foregroundColor="#f3f3f3"
      style={{ width: '100%' }}
      {...props}
    >
      <rect x="50" y="30" rx="3" ry="3" width="15%" height="30" />
      {/* Divider */}
      <rect x="50" y="78" rx="3" ry="3" width="94%" height="2" />

      {/* Label & Input */}
      <rect x="50" y="100" rx="3" ry="3" width="7%" height="20" />
      <rect x="50" y="130" rx="3" ry="3" width="13%" height="30" />
      {/* Label & Input */}
      <rect x="50" y="190" rx="3" ry="3" width="7%" height="20" />
      <rect x="50" y="220" rx="3" ry="3" width="13%" height="30" />
      {/* Label & Input */}
      <rect x="50" y="280" rx="3" ry="3" width="7%" height="20" />
      <rect x="50" y="310" rx="3" ry="3" width="13%" height="30" />

      {/* Label & Input */}
      <rect x="34%" y="100" rx="3" ry="3" width="7%" height="20" />
      <rect x="34%" y="130" rx="3" ry="3" width="13%" height="30" />
      {/* Label & Input */}
      <rect x="34%" y="190" rx="3" ry="3" width="7%" height="20" />
      <rect x="34%" y="220" rx="3" ry="3" width="13%" height="30" />
      {/* Label & Input */}
      <rect x="34%" y="280" rx="3" ry="3" width="7%" height="20" />
      <rect x="34%" y="310" rx="3" ry="3" width="13%" height="30" />

      {/* Label & Input */}
      <rect x="67%" y="100" rx="3" ry="3" width="7%" height="20" />
      <rect x="67%" y="130" rx="3" ry="3" width="13%" height="30" />
      {/* Label & Input */}
      <rect x="67%" y="190" rx="3" ry="3" width="7%" height="20" />
      <rect x="67%" y="220" rx="3" ry="3" width="13%" height="30" />
      {/* Label & Input */}
      <rect x="67%" y="280" rx="3" ry="3" width="7%" height="20" />
      <rect x="67%" y="310" rx="3" ry="3" width="13%" height="30" />
    </ContentLoader>
  );
}

export function AIInfoSkeletonLoading(props) {
  return (
    <ContentLoader
      height={480}
      // width={320}
      speed={0.8}
      viewBox="0 0 100% 170"
      backgroundColor="#ecebeb"
      foregroundColor="#f3f3f3"
      style={{ width: '100%' }}
      {...props}
    >
      {/* Label & Input */}
      <rect x="50" y="50" rx="3" ry="3" width="10%" height="20" />
      <rect x="50" y="80" rx="3" ry="3" width="42%" height="30" />

      {/* Label & Input */}
      <rect x="50" y="140" rx="3" ry="3" width="10%" height="20" />
      <rect x="50" y="170" rx="3" ry="3" width="42%" height="30" />

      {/* Label & Input */}
      <rect x="50" y="230" rx="3" ry="3" width="10%" height="20" />
      <rect x="50" y="260" rx="3" ry="3" width="42%" height="30" />

      {/* Label & Input */}
      <rect x="50" y="320" rx="3" ry="3" width="10%" height="20" />
      <rect x="50" y="350" rx="3" ry="3" width="42%" height="30" />

      {/* Label & Input */}
      <rect x="50" y="410" rx="3" ry="3" width="10%" height="20" />
      <rect x="50" y="440" rx="3" ry="3" width="42%" height="30" />

      {/* Detected Kit */}
      <rect x="57%" y="50" rx="3" ry="3" width="9%" height="30" />
      <rect x="57%" y="95" rx="3" ry="3" width="9%" height="370" />

      {/* Detected Membrane */}
      <rect x="80%" y="50" rx="3" ry="3" width="9%" height="30" />
      <rect x="80%" y="95" rx="3" ry="3" width="9%" height="370" />
    </ContentLoader>
  );
}

export function ScanSkeletonLoading(props) {
  return (
    <ContentLoader
      height={480}
      // width={320}
      speed={0.8}
      viewBox="0 0 100% 170"
      backgroundColor="#ecebeb"
      foregroundColor="#f3f3f3"
      style={{ width: '100%' }}
      {...props}
    >
      {/* Label & Input */}
      <rect x="50" y="50" rx="3" ry="3" width="10%" height="20" />
      <rect x="50" y="80" rx="3" ry="3" width="42%" height="30" />

      {/* Label & Input */}
      <rect x="50" y="140" rx="3" ry="3" width="10%" height="20" />
      <rect x="50" y="170" rx="3" ry="3" width="42%" height="30" />

      {/* Label & Input */}
      <rect x="50" y="230" rx="3" ry="3" width="10%" height="20" />
      <rect x="50" y="260" rx="3" ry="3" width="42%" height="30" />

      {/* Label & Input */}
      <rect x="50" y="320" rx="3" ry="3" width="10%" height="20" />
      <rect x="50" y="350" rx="3" ry="3" width="42%" height="30" />

      {/* Label & Input */}
      <rect x="50" y="410" rx="3" ry="3" width="10%" height="20" />
      <rect x="50" y="440" rx="3" ry="3" width="42%" height="30" />

      {/* Right image */}
      <rect x="63%" y="30" rx="3" ry="3" width="21%" height="442" />
    </ContentLoader>
  );
}

export function ProfileDropdownSkeletonLoadingMobile(props) {
  return (
    <ContentLoader
      // height={54}
      // width={320}
      speed={0.8}
      viewBox="0 0 200 210"
      backgroundColor="#ecebeb"
      foregroundColor="#f3f3f3"
      {...props}
    >
      {/* profile card */}
      <rect x="25" y="10" rx="3" ry="3" width="150" height="20" />
      <rect x="0" y="40" rx="0" ry="0" width="320" height="1" />
    </ContentLoader>
  );
}

export function ProfilePicSkeletonLoader() {
  return (
    <ContentLoader
      speed={0.8}
      viewBox="0 0 140 140"
      backgroundColor="#ecebeb"
      foregroundColor="#f3f3f3"
      style={{ marginTop: -8, marginLeft: 6 }}
    >
      {/* profile picture */}
      <rect x="0" y="0" rx="16" ry="16" width="130" height="130" />
    </ContentLoader>
  );
}
