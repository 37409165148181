import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Collapse from '@material-ui/core/Collapse';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import FileDownload from 'js-file-download';
import React, { useState } from 'react';

const { parse } = require('json2csv');

function UploadConfirmationDialog(props) {
  const { previewImport, onHandleShowConfirmation, onHandleContinueUploading } = props;
  const classes = useStyles();
  const [loadingContinueUploadUsers] = useState(false);
  const [showListErrors, setShowListErrors] = useState(false);

  const handleClose = () => {
    onHandleShowConfirmation(false);
  };

  const handleShowListErrors = () => {
    setShowListErrors(!showListErrors);
  };

  const handleContinueUploading = () => {
    onHandleContinueUploading();
  };

  const handleDownloadListErrors = () => {
    const listErrors = previewImport.errorMessages.map((item) => ({
      Error: item,
    }));
    const csv = parse(listErrors, { fields: ['Error'] });
    FileDownload(csv, 'importUsersErrorList.csv');
  };

  return (
    <Dialog
      open
      onClose={handleClose}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">Bulk User Import</DialogTitle>

      <Grid className={classes.uploadDataInfoWrapper}>
        <Typography variant="h6" className={classes.infoText}>
          Total users: {previewImport.nbOfUsersToProcess}
        </Typography>

        {previewImport.nbOfUsersWithErrors && previewImport.nbOfUsersWithErrors > 0 ? (
          <Grid container>
            <Typography variant="h6" className={classes.infoText}>
              Users with errors: {previewImport.nbOfUsersWithErrors}
            </Typography>

            <Typography variant="h6" className={classes.viewListErrorsControl} onClick={handleShowListErrors}>
              View list of errors
            </Typography>

            <Divider orientation="vertical" flexItem classes={{ root: classes.divider }} />

            <Typography variant="h6" className={classes.downloadListErrorsControl} onClick={handleDownloadListErrors}>
              Download
            </Typography>
          </Grid>
        ) : (
          <Typography variant="h6" className={classes.infoText}>
            Users with errors: {previewImport.nbOfUsersWithErrors ? previewImport.nbOfUsersWithErrors : 0}
          </Typography>
        )}
      </Grid>

      {previewImport.nbOfUsersWithErrors && previewImport.nbOfUsersWithErrors > 0 ? (
        <DialogContent dividers className={classes.dialogContent}>
          <Collapse in={showListErrors}>
            <DialogContentText
              id="scroll-dialog-description"
              // ref={descriptionElementRef}
              tabIndex={-1}
            >
              {previewImport.errorMessages.length ? previewImport.errorMessages.map((error) => <p>{error}</p>) : null}
            </DialogContentText>
          </Collapse>
        </DialogContent>
      ) : null}

      <Grid className={classes.uploadDataNote}>
        <strong>Note: </strong>
        <span style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
          If a user exists in the system and is included in the CSV, the user&apos;s data will be overwritten.
        </span>
      </Grid>

      {previewImport.nbOfUsersWithErrors && previewImport.nbOfUsersWithErrors > 0 ? (
        <DialogActions>
          <Button color="primary" variant="contained" onClick={handleClose}>
            Ok
          </Button>
        </DialogActions>
      ) : (
        <DialogActions>
          <Button color="primary" variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <Button color="primary" variant="contained" onClick={handleContinueUploading}>
            {loadingContinueUploadUsers && (
              <Grid className={classes.loadingContinueUploadUsers}>
                <CircularProgress size={28} thickness={6} />
              </Grid>
            )}
            Continue
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}

export default UploadConfirmationDialog;

const useStyles = makeStyles({
  loadingContinueUploadUsers: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
  },
  uploadDataInfoWrapper: {
    padding: '0 24px',
  },
  infoText: {
    fontSize: '16px',
  },
  viewListErrorsControl: {
    fontSize: '16px',
    textDecoration: 'underline',
    marginLeft: '30px',
    cursor: 'pointer',
  },
  downloadListErrorsControl: {
    fontSize: '16px',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  dialogContent: {
    padding: '0 24px',
    marginTop: '16px',
  },
  uploadDataNote: {
    padding: '20px 24px 0 24px',
  },
  divider: {
    backgroundColor: 'black',
    width: '1.5px',
    margin: '0 7px',
  },
});
