import React, { useState, useEffect, useCallback } from 'react';

// redux
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// helpers and components
import ActionsCell from '../../Shared/Table/ActionsCell';
import { saveTemplateSettings, toggleEnabled } from '../settingsSlice';
import { systemMessagesTabTypes, systemMessagesTypes } from './helpers/mappings';

function SettingsActionsCellWrapper(props) {
  const { dataItem } = props;

  const [enabledTemplate, setEnabledTemplate] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (dataItem) {
      setEnabledTemplate(dataItem.enabled);
    }
  }, [dataItem]);

  const menuItems = ['edit', 'delete'];

  const handleActionClick = useCallback(
    (selection) => {
      const { currentTenant, id, type, currentAccount } = dataItem;

      if (selection === 'enabler') {
        const subTypes = [];
        if (type === systemMessagesTypes[systemMessagesTabTypes.EMAIL]) {
          subTypes.push('email', 'safe');
        } else {
          subTypes.push('sms', 'push');
        }

        const updatedStatus = !enabledTemplate;
        setEnabledTemplate(updatedStatus);

        const settings = {
          enabled: updatedStatus,
          [subTypes[0]]: updatedStatus,
          [subTypes[1]]: updatedStatus,
        };
        dispatch(saveTemplateSettings(currentAccount, currentTenant, id, settings)).then(() =>
          dispatch(toggleEnabled(id, settings))
        );
      } else {
        navigate(
          `/${currentTenant}/settings/system-messages/${
            type === systemMessagesTypes[systemMessagesTabTypes.EMAIL]
              ? systemMessagesTabTypes.EMAIL
              : systemMessagesTabTypes.SMS
          }/${selection}/${id}`
        );
      }
    },
    [dataItem, enabledTemplate]
  );

  return <ActionsCell dataItem={dataItem} menu={menuItems} handleActionClick={handleActionClick} />;
}

export default SettingsActionsCellWrapper;
