import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { useSelector } from 'react-redux';
import AppStyles from '../../assets/jss/components/AppStyles';
import { systemMessagesTabTypes } from '../Settings/SystemMessages/helpers/mappings';
import { userTypes } from '../Users/helpers/mappings';
import { selectCurrentTenantId } from './selectors';
import AcBreadcrumbs from './Utils/breadcrumbUtil';

export function Breadcrumbs(props) {
  const { classes, id, userId } = props;
  const currentTenant = useSelector(selectCurrentTenantId);

  // define breadcrumbs
  const breadcrumbs = {
    users: {
      path: `/${currentTenant}/users/${userTypes.ACTIVE}`,
      title: 'People',
      breadcrumbs: {
        create: {
          path: `/${currentTenant}/users/create`,
          title: 'Create User',
        },
        import: {
          path: `/${currentTenant}/users/import`,
          title: 'Import Users',
          breadcrumbs: {
            'view-all-uploads': {
              path: '/view-all-uploads',
              title: 'View All Uploads',
            },
          },
        },
        details: {
          path: `/${currentTenant}/users/details/${userId}/testing`,
          title: 'User Details',
          breadcrumbs: {
            [userId]: {
              path: `/${currentTenant}/users/details/${userId}`,
              breadcrumbs: {
                testing: {
                  path: `/${currentTenant}/users/details/${userId}/testing`,
                  breadcrumbs: {
                    'diagnostic-report': {
                      path: `/${currentTenant}/users/details/${userId}/testing/diagnostic-report`,
                      title: 'Diagnostic Report',
                    },
                    'rapid-test': {
                      path: `/${currentTenant}/users/details/${userId}/testing/rapid-test`,
                      title: 'Diagnostic Report',
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
    account: {
      path: '#',
      title: 'Account',
      breadcrumbs: {
        details: {
          path: `/${currentTenant}/account/details`,
          title: 'Details',
        },
        permissions: {
          path: `/${currentTenant}/account/permissions`,
          title: 'Permission Management',
        },
        tenants: {
          path: `/${currentTenant}/account/tenants`,
          title: 'Tenant Management',
          breadcrumbs: {
            create: {
              path: `/${currentTenant}/account/tenants/create`,
              title: 'Create Tenant',
            },
            details: {
              path: `/${currentTenant}/account/tenants/details`,
              title: 'Tenant Details',
            },
          },
        },
      },
    },
    settings: {
      path: '#',
      title: 'Settings',
      breadcrumbs: {
        'system-messages': {
          path: '#',
          title: 'System Messages',
          breadcrumbs: {
            [systemMessagesTabTypes.EMAIL]: {
              path: `/${currentTenant}/settings/system-messages/${systemMessagesTabTypes.EMAIL}`,
              title: 'EMAIL & SAFE',
              breadcrumbs: {
                edit: {
                  path: '/edit',
                  title: 'Editor',
                },
              },
            },
            [systemMessagesTabTypes.SMS]: {
              path: `/${currentTenant}/settings/system-messages/${systemMessagesTabTypes.SMS}`,
              title: 'SMS & PUSH',
              breadcrumbs: {
                edit: {
                  path: '/edit',
                  title: 'Editor',
                },
              },
            },
          },
        },
        verbiage: {
          path: '#',
          title: 'Verbiage',
          breadcrumbs: {
            screens: {
              path: `/${currentTenant}/settings/verbiage/screens`,
              title: 'Screens',
            },
            'legal-notices': {
              path: `/${currentTenant}/settings/verbiage/legal-notices`,
              title: 'Legal Notices',
            },
          },
        },
        'message-standardization': {
          path: '#',
          title: 'Message Standardization',
          breadcrumbs: {
            screens: {
              path: `/${currentTenant}/settings/message-standardization`,
              title: 'Message Standardization',
            },
          },
        },
        configuration: {
          path: '#',
          title: 'Configuration',
          breadcrumbs: {
            'tenant-info': {
              path: `/${currentTenant}/settings/configuration/tenant-info`,
              title: 'Tenant Info',
            },
            settings: {
              path: `/${currentTenant}/settings/configuration/settings`,
              title: 'Settings',
            },
            assets: {
              path: `/${currentTenant}/settings/configuration/assets`,
              title: 'Assets',
              breadcrumbs: {
                'graphic-assets': {
                  path: `/${currentTenant}/settings/configuration/assets/graphic-assets`,
                  title: 'Graphic Assets',
                },
                'intro-widgets': {
                  path: `/${currentTenant}/settings/configuration/assets/intro-widgets`,
                  title: 'Intro Widgets',
                  breadcrumbs: {
                    create: {
                      path: '/create',
                      title: 'Create Intro Widget',
                    },
                    details: {
                      path: '/details',
                      title: 'Intro Widget Details',
                    },
                  },
                },
                fonts: {
                  path: `/${currentTenant}/settings/configuration/assets/fonts`,
                  title: 'Fonts',
                  breadcrumbs: {
                    details: {
                      path: '/details',
                      title: 'Font Details',
                    },
                  },
                },
              },
            },
            'health-protocol': {
              path: `/${currentTenant}/settings/configuration/health-protocol`,
              title: 'Health Protocol',
            },
          },
        },
      },
    },
    diagnostics: {
      path: `/${currentTenant}/diagnostics`,
      title: 'Diagnostics',
      breadcrumbs: {
        'diagnostic-report': {
          path: `/${currentTenant}/diagnostics/diagnostic-report`,
          title: 'Diagnostic Report',
        },
        'rapid-test': {
          path: `/${currentTenant}/diagnostics/rapid-test`,
          title: 'Diagnostic Report',
        },
      },
    },
    encounters: {
      title: 'Encounters',
      path: `/${currentTenant}/encounters`,
      breadcrumbs: {
        details: {
          title: 'Details',
          path: `/${currentTenant}/encounters/details/${id}`,
        },
      },
    },
    alerts: {
      path: `/${currentTenant}/alerts`,
      title: 'Alerts',
    },
    profile: {
      path: `/${currentTenant}/profile`,
      title: 'Profile',
    },
    'global-dashboard': {
      path: `/${currentTenant}/global-dashboard`,
      title: 'Global Dashboard',
    },
    content: {
      path: `/${currentTenant}/content`,
      title: 'Content',
      breadcrumbs: {
        faq: {
          path: '/faq',
          title: 'Help Center',
        },
        'registration-faq': {
          path: '/registration-faq',
          title: 'Registration',
        },
        articles: {
          path: `/${currentTenant}/content/articles`,
          title: 'Articles',
          breadcrumbs: {
            create: {
              path: '/create',
              title: 'Create Article',
            },
            details: {
              path: '/details',
              title: 'Article Details',
            },
            import: {
              path: '/import',
              title: 'Article Import',
            },
          },
        },
        blogs: {
          path: `/${currentTenant}/content/blogs`,
          title: 'Blogs',
          breadcrumbs: {
            create: {
              path: '/create',
              title: 'Create Blog',
            },
            details: {
              path: '/details',
              title: 'Blog Details',
            },
          },
        },
        'intro-widgets': {
          path: `/${currentTenant}/content/intro-widgets`,
          title: 'Intro Widgets',
          breadcrumbs: {
            create: {
              path: '/create',
              title: 'Create Intro Widget',
            },
            details: {
              path: '/details',
              title: 'Intro Widget Details',
            },
          },
        },
        resources: {
          path: `/${currentTenant}/content/resources`,
          title: 'Resources',
          breadcrumbs: {
            create: {
              path: '/create',
              title: 'Create Resource',
            },
            details: {
              path: '/details',
              title: 'Resource Details',
            },
          },
        },
        videos: {
          path: `/${currentTenant}/content/videos`,
          title: 'Videos',
          breadcrumbs: {
            create: {
              path: '/create',
              title: 'Create Video',
            },
            details: {
              path: '/details',
              title: 'Video Details',
            },
          },
        },
      },
    },
    'medical-center': {
      path: '#',
      title: 'Medical Center',
      breadcrumbs: {
        'orderable-tests': {
          path: `/${currentTenant}/medical-center/orderable-tests`,
          title: 'Orderable Tests',
          breadcrumbs: {
            create: {
              path: `/${currentTenant}/medical-center/orderable-tests/create`,
              title: 'Create Orderable Test',
            },
            details: {
              path: `/${currentTenant}/medical-center/orderable-tests/details`,
              title: 'Edit Orderable Test',
            },
          },
        },
        'orderable-panels': {
          path: `/${currentTenant}/medical-center/orderable-panels`,
          title: 'Orderable Panels',
          breadcrumbs: {
            create: {
              path: `/${currentTenant}/medical-center/orderable-panels/create`,
              title: 'Create Orderable Panel',
            },
            details: {
              path: `/${currentTenant}/medical-center/orderable-panels/details`,
              title: 'Edit Orderable Panel',
            },
          },
        },
        vendors: {
          path: `/${currentTenant}/medical-center/vendors`,
          title: 'Vendors',
          breadcrumbs: {
            create: {
              path: `/${currentTenant}/medical-center/vendors/create`,
              title: 'Create Orderable Panel',
            },
            details: {
              path: `/${currentTenant}/medical-center/vendors/details`,
              title: 'Edit Orderable Panel',
            },
          },
        },
      },
    },
    'virtual-consult': {
      path: '#',
      title: 'Virtual Consult',
      breadcrumbs: {
        questionnaires: {
          path: `/${currentTenant}/virtual-consult/questionnaires`,
          title: 'Questionnaires',
          breadcrumbs: {
            create: {
              path: `/${currentTenant}/virtual-consult/questionnaires/create`,
              title: 'Create Questionnaire',
              breadcrumbs: {
                profile: {
                  path: 'profile',
                  title: 'Profile',
                },
                questionnaire: {
                  path: 'questionnaire',
                  title: 'Questionnaire Builder',
                },
                assets: {
                  path: 'assets',
                  title: 'Assets',
                },
                preview: {
                  path: 'preview',
                  title: 'Preview',
                },
              },
            },
            details: {
              path: `/${currentTenant}/virtual-consult/questionnaires/edit`,
              title: 'Edit Questionnaire',
            },
          },
        },
        questions: {
          path: `/${currentTenant}/virtual-consult/questions`,
          title: 'Question Store',
          breadcrumbs: {
            create: {
              path: `/${currentTenant}/virtual-consult/questions/create`,
              title: 'Create Question',
            },
            details: {
              path: `/${currentTenant}/virtual-consult/questions/edit`,
              title: 'Edit Question',
            },
          },
        },
        outcomes: {
          path: `/${currentTenant}/virtual-consult/outcomes`,
          title: 'Outcomes',
          breadcrumbs: {
            create: {
              path: `/${currentTenant}/virtual-consult/outcomes/create`,
              title: 'Create Outcome',
            },
            details: {
              path: `/${currentTenant}/virtual-consult/outcomes/edit`,
              title: 'Edit Outcome',
            },
          },
        },
        'user-segments': {
          path: `/${currentTenant}/virtual-consult/user-segments`,
          title: 'User Segment Outcomes',
          breadcrumbs: {
            details: {
              path: `/${currentTenant}/virtual-consult/user-segments/details`,
              title: 'Edit User Segment Outcomes',
            },
          },
        },
        'questionnaire-outcomes': {
          path: `/${currentTenant}/virtual-consult/questionnaire-outcomes`,
          title: 'Outcomes',
        },
        monitoring: {
          path: `/${currentTenant}/virtual-consult/monitoring`,
          title: 'Monitoring',
        },
        reviewer: {
          path: `/${currentTenant}/virtual-consult/reviewer`,
          title: 'Reviewer',
        },
      },
    },
    reports: {
      path: `/${currentTenant}/reports`,
      title: 'Reports',
      breadcrumbs: {
        'custom-report': {
          path: `/${currentTenant}/reports/custom-report`,
          title: 'Custom Report',
        },
      },
    },
    'record-requests': {
      path: `/${currentTenant}/record-requests`,
      title: 'Record Requests',
    },
    'rapid-test': {
      path: `/${currentTenant}/rapid-test`,
      title: 'Diagnostic Report',
    },
  };

  if (!currentTenant) return null;

  return (
    <div className={classes.breadcrumbs}>
      <AcBreadcrumbs breadcrumbs={breadcrumbs} defaultLink={`/${currentTenant}`} home="Dashboard" />
    </div>
  );
}

Breadcrumbs.propTypes = {
  // projectId: PropTypes.string,
};

export default withStyles(AppStyles)(Breadcrumbs);
