import React from 'react';
import { useSelector } from 'react-redux';

// eslint-disable-next-line import/no-named-as-default
import NotFoundView from '../../views/NotFoundView';
import { FullscreenLoader } from '../AppLoader/FullscreenLoader';
import { selectTenantNetworkLoading } from '../Networks/selectors';
import { selectCurrentUserLoading } from '../Users/selectors';

import { RefreshToken } from './RefreshToken';
import { selectAuthToken, selectAccountError, selectAccountLoading } from './selectors';

export function AuthLayout({ children }) {
  const accountDetailsLoading = useSelector(selectAccountLoading);
  const userDetailsLoading = useSelector(selectCurrentUserLoading);
  const currentNetworkLoading = useSelector(selectTenantNetworkLoading);
  const isLoggedIn = useSelector(selectAuthToken);
  const isLoading = isLoggedIn && (accountDetailsLoading || userDetailsLoading || currentNetworkLoading);
  const error = useSelector(selectAccountError);

  if (error) {
    return <NotFoundView message={error} />;
  }

  return (
    <>
      <RefreshToken />
      <FullscreenLoader isLoading={isLoading} />
      {children}
    </>
  );
}
