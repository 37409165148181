/* eslint-disable react/no-array-index-key */
/* eslint-disable import/no-cycle */
import React, { useContext } from 'react';

import { QuestionnaireBuilderContext } from '../../../QuestionnaireBuilderWrapper';

import Condition from './Condition';

const ConditionList = (props) => {
  const { jump, jumpIndex, jumpType, currentNode, updateQuestionNodes, nodeIndex } = props;

  const { selectedQuestions } = useContext(QuestionnaireBuilderContext);

  const handleChangeWithParams = (type, value, conditionIndex) => {
    const foundQuestion = selectedQuestions.filter((v) => v.question).find((item) => item.question.id === value);
    const path = `[${nodeIndex}].rules[${jumpIndex}].rules[${conditionIndex}]`;
    if (type === 'expectedAnswers') {
      updateQuestionNodes(path, (data) => {
        if (
          data.type === 'numeric' ||
          data.type === 'scale' ||
          data.type === 'slider' ||
          data.type === 'multiple-size'
        ) {
          return {
            type: data.type,
            questionId: data.questionId,
            answer: value,
            operation: data.operation,
          };
        }
        if (data.type === 'multiple' || data.type === 'multiple-drop') {
          return {
            type: data.type,
            questionId: data.questionId,
            expectedAnswer: value,
            operation: data.operation,
          };
        }
        if (data.type === 'score') {
          return {
            type: data.type,
            questionId: data.questionId,
            score: value,
            operation: data.operation,
          };
        }
        if (data.type === 'text') {
          return {
            type: data.type === 'large-text' || data.type === 'small-text' ? 'text' : data.type,
            questionId: data.questionId,
            expectedAnswer: value,
            operation: data.operation,
          };
        }
        if (data.type === 'date') {
          return {
            type: data.type,
            questionId: data.questionId,
            expected: value,
            operation: data.operation,
            precision: data.precision || 'YEARS',
          };
        }
        if (data.type === 'yes-no') {
          return {
            type: data.type,
            questionId: data.questionId,
            expectedAnswer: value,
          };
        }
        return data;
      });
      return;
    }

    if (type === 'precision') {
      updateQuestionNodes(path, (data) => ({
        ...data,
        precision: value,
      }));
    }

    if (type === 'operation') {
      updateQuestionNodes(path, (data) => ({
        ...data,
        operation: value,
      }));
    }

    if (type === 'variableName') {
      updateQuestionNodes(path, (data) => ({
        ...data,
        variableName: value,
      }));
    }
    if (!foundQuestion) return;

    updateQuestionNodes(path, (r) => {
      if (
        foundQuestion.question.type === 'numeric' ||
        foundQuestion.question.type === 'scale' ||
        foundQuestion.question.type === 'slider'
      ) {
        return {
          ...r,
          questionId: value,
          type: foundQuestion ? foundQuestion.question.type : null,
          operation: 'GREATER',
        };
      }
      if (foundQuestion.question.type === 'date') {
        return {
          type: foundQuestion.question.type,
          questionId: value,
          expected: r.expected,
          operation: r.operation,
          precision: r.precision || 'YEARS',
        };
      }
      return {
        ...r,
        questionId: value,
        type: foundQuestion ? foundQuestion.question.type : null,
      };
    });
  };

  const handleChangeAnswer = (value, conditionIndex) => {
    const path = `[${nodeIndex}].rules[${jumpIndex}].rules[${conditionIndex}]`;
    updateQuestionNodes(path, (data) => {
      if (data.type === 'numeric' || data.type === 'scale' || data.type === 'slider') {
        return {
          type: data.type,
          questionId: data.questionId,
          answer: value,
          operation: data.operation,
        };
      }
      if (data.type === 'date') {
        return {
          type: data.type,
          questionId: data.questionId,
          expected: value,
          operation: data.operation,
          precision: data.precision || 'YEARS',
        };
      }
      if (data.type === 'multiple' || data.type === 'multiple-drop') {
        return {
          type: data.type,
          questionId: data.id,
          expectedAnswers: value,
          operation: data.operation,
        };
      }
      if (data.type === 'text') {
        return {
          type: data.type === 'large-text' || data.type === 'small-text' ? 'text' : data.type,
          questionId: data.questionId,
          expectedAnswer: value,
          operation: data.operation,
        };
      }
      if (data.type === 'score') {
        return {
          type: data.type,
          questionId: data.questionId,
          score: value,
          operation: data.operation,
        };
      }
      if (data.type === 'variable') {
        return {
          type: 'variable',
          variableName: data.variableName,
          expectedText: value,
          operation: data.operation,
        };
      }
    });
  };

  const removeCondition = (conditionIndex) => {
    if (currentNode.rules[jumpIndex].rules.length === 1) return;

    const path = `[${nodeIndex}].rules[${jumpIndex}].rules`;

    updateQuestionNodes(path, (item) => item.filter((c, i) => i !== conditionIndex));
  };

  const addCondition = () => {
    const path = `[${nodeIndex}].rules[${jumpIndex}].rules`;
    updateQuestionNodes(path, (item) => {
      if (jumpType === 'Score') {
        return [
          ...item,
          {
            type: 'score',
            questionId: currentNode.question.id,
            score: null,
          },
        ];
      }
      return [
        ...item,
        {
          type:
            currentNode.question.type === 'large-text' || currentNode.question.type === 'small-text'
              ? 'text'
              : currentNode.question.type,
          questionId: currentNode.question.id,
          expectedAnswer: '',
          precision: currentNode.question.precision,
        },
      ];
    });
  };

  return jump.rules.map((item, conditionIndex) => (
    <Condition
      key={conditionIndex}
      item={item}
      rulesLength={jump.rules.length}
      conditionIndex={conditionIndex}
      handleChangeAnswer={handleChangeAnswer}
      handleChangeWithParams={handleChangeWithParams}
      addCondition={addCondition}
      removeCondition={removeCondition}
      currentNode={currentNode}
    />
  ));
};

export default ConditionList;
