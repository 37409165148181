// profile sections
export const sections = [
  // section 1
  ['firstName', 'middleName', 'lastName', 'gender', 'birthDate', 'birthPlace', 'race', 'ethnicity'],
  // section 2
  [
    'contact.mobileCountryCode',
    'contact.mobileNumber',
    'contact.homeCountryCode',
    'contact.homeNumber',
    'contact.workCountryCode',
    'contact.workNumber',
    'email',
  ],
  // section 3
  [
    'primaryAddress.line1',
    'primaryAddress.line2',
    'primaryAddress.city',
    'primaryAddress.state',
    'primaryAddress.zip',
    'primaryAddress.country',
  ],
  // section 4
  [
    'shippingAddress.line1',
    'shippingAddress.line2',
    'shippingAddress.city',
    'shippingAddress.state',
    'shippingAddress.zip',
    'shippingAddress.country',
  ],
  // section 5
  [
    'billingAddress.line1',
    'billingAddress.line2',
    'billingAddress.city',
    'billingAddress.state',
    'billingAddress.zip',
    'billingAddress.country',
  ],
  // emergency contact section
  [
    'emergencyContact.firstName',
    'emergencyContact.lastName',
    'emergencyContact.relationship',
    'emergencyContact.contact.mobileCountryCode',
    'emergencyContact.contact.mobileNumber',
    'emergencyContact.contact.workCountryCode',
    'emergencyContact.contact.workNumber',
    'emergencyContact.email',
    'emergencyContact.address.line1',
    'emergencyContact.address.line2',
    'emergencyContact.address.city',
    'emergencyContact.address.state',
    'emergencyContact.address.zip',
    'emergencyContact.address.country',
  ],
  // guardian info section
  ['relatedPersonFirstName', 'relatedPersonLastName', 'relationship'],
  // section 8 (Status)
  ['clearedUntil', 'lastTestDate', 'sidelinedUntil', 'nextTestDate'],
];

export const birthDateForm = [
  {
    type: 'date',
    name: 'birthDate',
    label: 'Date of Birth',
    required: false,
    width: 12,
    pattern: '^(0[1-9]|1[012])[-/.](0[1-9]|[12][0-9]|3[01])[-/.](19|20)\\d\\d$',
    max: new Date(),
    minAgeRestriction: '18',
  },
];

export const clearedUntilDate = [
  {
    type: 'date',
    name: 'clearedUntil',
    label: 'Cleared Until Date',
    required: false,
    width: 12,
    pattern: '^(0[1-9]|1[012])[-/.](0[1-9]|[12][0-9]|3[01])[-/.](19|20)\\d\\d$',
  },
];

export const sidelinedUntilDate = [
  {
    type: 'date',
    name: 'sidelinedUntil',
    label: 'Sidelined Until Date',
    required: false,
    width: 12,
    pattern: '^(0[1-9]|1[012])[-/.](0[1-9]|[12][0-9]|3[01])[-/.](19|20)\\d\\d$',
  },
];

// options for gender field
export const genderEnums = [
  {
    name: 'Female',
    code: 'FEMALE',
  },
  {
    name: 'Male',
    code: 'MALE',
  },
  {
    name: 'Intersex',
    code: 'INTERSEX',
  },
  {
    name: 'Other',
    code: 'OTHER',
  },
];

export const relationshipEnums = [
  'Family Member',
  'Friend',
  'Agent',
  'Caregiver',
  'Guardian',
  'Next of Kin',
  "It's Personal",
];

export const guardianEnums = [
  {
    label: 'Parent',
    value: 'Parent',
  },
  {
    label: 'Caregiver',
    value: 'Caregiver',
  },
  {
    label: 'Legal Guardian',
    value: 'LegalGuardian',
  },
  {
    label: 'Power of Attorney',
    value: 'PowerOfAttorney',
  },
];

export const raceEnums = [
  {
    label: 'race_american_indian_or_alaskan',
    value: 'AMERICAN_INDIAN_OR_ALASKA_NATIVE',
  },
  {
    label: 'race_asian',
    value: 'ASIAN',
  },
  {
    label: 'race_black_or_african_american',
    value: 'BLACK_OR_AFRICAN_AMERICAN',
  },
  {
    label: 'race_hawaiian_ot_pacific',
    value: 'NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER',
  },
  {
    label: 'race_other',
    value: 'OTHER',
  },
  {
    label: 'race_caucasian',
    value: 'WHITE',
  },
];

export const ethnicityEnums = [
  {
    label: 'ethnicity_hispanic_or_latino',
    value: 'HISPANIC_OR_LATINO',
  },
  {
    label: 'ethnicity_not_hispanic_or_latino',
    value: 'NOT_HISPANIC_OR_LATINO',
  },
  {
    label: null,
    value: 'Unknown',
  },
];

export const limitSexDialog = ['INTERSEX', 'OTHER'];

export const requiredFields = ['firstName', 'lastName'];

export const restrictedFields = ['gender', 'birthDate'];

export const titleByName = {
  line1: 'Address 1',
  line2: 'Address 2',
  gender: 'Sex',
  race: 'Race',
  ethnicity: 'Ethnicity',
  birthDate: 'Date of Birth',
  clearedUntil: 'Cleared Until Date',
  sidelinedUntil: 'Sidelined Until Date',
  birthPlace: 'Birth State',
  mobileNumber: 'Mobile Phone',
  workNumber: 'Work Phone',
  homeNumber: 'Home Phone',
  relatedPersonFirstName: 'First Name',
  relatedPersonLastName: 'Last Name',
  relationship: 'Guardian Relation',
  countryCode: 'Country Code',
};

export const addressProps = [
  'zip',
  'primaryAddress.zip',
  'shippingAddress.zip',
  'billingAddress.zip',
  'emergencyContact.address.zip',
];
