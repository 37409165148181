/* DOORDASH */
export const DOORDASH_STATUS_TYPE = Object.freeze({
  DASHER_CONFIRMED: {
    value: 'DASHER_CONFIRMED',
    display: 'DASHER_CONFIRMED',
  },
  DASHER_CONFIRMED_PICKUP_ARRIVAL: {
    value: 'DASHER_CONFIRMED_PICKUP_ARRIVAL',
    display: 'DASHER_CONFIRMED_PICKUP_ARRIVAL',
  },
  DASHER_PICKED_UP: {
    value: 'DASHER_PICKED_UP',
    display: 'DASHER_PICKED_UP',
  },
  DASHER_CONFIRMED_DROPOFF_ARRIVAL: {
    value: 'DASHER_CONFIRMED_DROPOFF_ARRIVAL',
    display: 'DASHER_CONFIRMED_DROPOFF_ARRIVAL',
  },
  DASHER_DROPPED_OFF: {
    value: 'DASHER_DROPPED_OFF',
    display: 'DASHER_DROPPED_OFF',
  },
  DELIVERY_RETURN_INITIALIZED: {
    value: 'DELIVERY_RETURN_INITIALIZED',
    display: 'DELIVERY_RETURN_INITIALIZED',
  },
  DASHER_CONFIRMED_RETURN_ARRIVAL: {
    value: 'DASHER_CONFIRMED_RETURN_ARRIVAL',
    display: 'DASHER_CONFIRMED_RETURN_ARRIVAL',
  },
  DELIVERY_RETURNED: {
    value: 'DELIVERY_RETURNED',
    display: 'DELIVERY_RETURNED',
  },
  DELIVERY_CANCELLED: {
    value: 'DELIVERY_CANCELLED',
    display: 'DELIVERY_CANCELLED',
  },
});

export const DOORDASH_STATUS_TYPE_ARCHIVED = Object.freeze({
  QUOTE: {
    id: 'quote',
    text: 'Quote',
  },
  CREATED: {
    id: 'created',
    text: 'Created',
  },
  CONFIRMED: {
    id: 'confirmed',
    text: 'Confirmed',
  },
  ENROUTE_TO_PICKUP: {
    id: 'enroute_to_pickup',
    text: 'Enroute to pickup',
  },
  ARRIVED_AT_PICKUP: {
    id: 'arrived_at_pickup',
    text: 'Arrived at pickup',
  },
  PICKED_UP: {
    id: 'picked_up',
    text: 'Picked up',
  },
  ENROUTE_TO_DROPOFF: {
    id: 'enroute_to_dropoff',
    text: 'Enroute to dropoff',
  },
  ARRIVED_AT_DROPOFF: {
    id: 'arrived_at_dropoff',
    text: 'Arrived at dropoff',
  },
  DELIVERED: {
    id: 'delivered',
    text: 'Delivered',
  },
  ENROUTE_TO_RETURN: {
    id: 'enroute_to_return',
    text: 'Enroute to return',
  },
  ARRIVED_AT_RETURN: {
    id: 'arrived_at_return',
    text: 'Arrived at return',
  },
  RETURNED: {
    id: 'returned',
    text: 'Returned',
  },
  CANCELLED: {
    id: 'cancelled',
    text: 'Cancelled',
  },
});

/* NETSUITE */
export const NETSUITE_STATUS_TYPE = Object.freeze({
  ATTEMPTED_DELIVERY: {
    value: 'ATTEMPTED_DELIVERY',
    display: 'Attempted Delivery',
  },
  CONFIRMED: {
    value: 'CONFIRMED',
    display: 'Confirmed',
  },
  DELIVERED: {
    value: 'DELIVERED',
    display: 'Delivered',
  },
  FAILURE: {
    value: 'FAILURE',
    display: 'Failure',
  },
  IN_TRANSIT: {
    value: 'IN_TRANSIT',
    display: 'In Transit',
  },
  LABEL_PRINTED: {
    value: 'LABEL_PRINTED',
    display: 'Label Printed',
  },
  LABEL_PURCHASED: {
    value: 'LABEL_PURCHASED',
    display: 'Label Purchased',
  },
  OUT_FOR_DELIVERY: {
    value: 'OUT_FOR_DELIVERY',
    display: 'Out for Delivery',
  },
  READY_FOR_PICKUP: {
    value: 'READY_FOR_PICKUP',
    display: 'Ready for Pickup',
  },
});
