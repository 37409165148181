import { createSlice } from '@reduxjs/toolkit';
import api from '../../../api';
import { showNotification } from '../../Notifications/notificationSlice';
import { initialKendoState } from '../../Users/helpers/mappings';
import { setTenantUsersLoading, getUsers } from '../../Users/usersSlice';
import { handleError } from '../Content/APIUtils';

const initialState = {
  error: null,
  list: [],
  masterCodeList: null,
  loading: false,
  updating: false,
  imgs: [],
};

export const vaccinationRecordsSlice = createSlice({
  name: 'vaccinationRecords',
  initialState,
  reducers: {
    clearLatestVaxRecord: (state) => ({
      ...state,
      latestVaxRecord: {},
      error: {},
      latestVaxRecordLoading: {},
      imgLoading: {},
    }),
    setLatestVaxRecordLoading: (state, { payload }) => ({
      ...state,
      error: null,
      latestVaxRecordLoading: {
        ...state.latestVaxRecordLoading,
        [payload.userId]: true,
      },
    }),
    setLatestVaxRecord: (state, { payload }) => ({
      ...state,
      latestVaxRecordLoading: {
        ...state.latestVaxRecordLoading,
        [payload.userId]: false,
      },
      latestVaxRecord: {
        ...state.latestVaxRecord,
        [payload.userId]: payload.latestVaxRecord,
      },
    }),
    setLatestVaxRecordError: (state, { payload }) => ({
      ...state,
      latestVaxRecordLoading: {
        ...state.latestVaxRecordLoading,
        [payload.userId]: false,
      },
      error: payload.error,
    }),
    clearVaxRecordImage: (state) => ({
      ...state,
      imgs: {},
      error: {},
      imgLoading: {},
    }),
    setVaxRecordImageLoading: (state, { payload }) => ({
      ...state,
      imgLoading: {
        ...state.imgLoading,
        [payload.idx]: true,
      },
      error: null,
    }),
    addVaxRecordImage: (state, { payload }) => ({
      ...state,
      imgLoading: {
        ...state.imgLoading,
        [payload.idx]: false,
      },
      imgs: {
        ...state.imgs,
        [payload.idx]: payload.result,
      },
    }),
    setVaxRecordImageError: (state, { payload }) => ({
      ...state,
      imgLoading: {
        ...state.imgLoading,
        [payload.idx]: false,
      },
      error: payload.error,
    }),
    clearVaxRecords: (state) => ({
      ...state,
      list: {},
    }),
    setVaxRecordsLoading: (state) => ({
      ...state,
      loading: true,
      error: null,
    }),
    setVaxRecords: (state, { payload }) => ({
      ...state,
      list: payload,
      loading: false,
    }),
    addVaxRecord: (state, { payload }) => ({
      ...state,
      list: {
        items: [...(state.list.items || []), payload],
      },
    }),
    setVaxRecordsError: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
    }),
    // MASTER CODE
    setMasterCodeLoading: (state) => ({
      ...state,
      loading: true,
      error: null,
    }),
    setMasterCode: (state, { payload }) => ({
      ...state,
      masterCodeList: payload,
      loading: false,
    }),
    setMasterCodeError: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
    }),
    // UPDATE ATTESTATION
    setUpdateAttestationLoading: (state) => ({
      ...state,
      loading: true,
    }),
    setUpdateAttestation: (state) => ({
      ...state,
      loading: false,
    }),
    setUpdateAttestationError: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
    }),
    // APPROVE
    // UPDATE ATTESTATION
    setApproveVaccineRecordLoading: (state) => ({
      ...state,
      loading: true,
      updating: true,
    }),
    setApproveVaccineRecord: (state) => ({
      ...state,
      loading: false,
      updating: false,
    }),
    setApproveVaccineRecordError: (state, { payload }) => ({
      ...state,
      loading: false,
      updating: false,
      error: payload,
    }),
  },
});

export const {
  setLatestVaxRecordLoading,
  setLatestVaxRecord,
  setLatestVaxRecordError,
  clearLatestVaxRecord,
  setVaxRecordImageLoading,
  addVaxRecordImage,
  setVaxRecordImageError,
  clearVaxRecordImage,
  setVaxRecordsLoading,
  setVaxRecords,
  setVaxRecordsError,
  setMasterCodeLoading,
  setMasterCodeError,
  setMasterCode,
  setUpdateAttestationLoading,
  setUpdateAttestation,
  setUpdateAttestationError,
  setApproveVaccineRecordLoading,
  setApproveVaccineRecord,
  setApproveVaccineRecordError,
  clearVaxRecords,
  addVaxRecord,
} = vaccinationRecordsSlice.actions;

let prevPageInfo = {
  page: 0,
  size: 10,
  sort: 'lastAdministeredDate',
  direction: 'asc',
};

export const getLatestVaxRecord = (id, userId) => async (dispatch) => {
  dispatch(setLatestVaxRecordLoading({ userId }));
  const [result, error] = await api.getLatestVaccinationRecord(id);

  if (result) {
    dispatch(addVaxRecord(result.data));
    dispatch(setLatestVaxRecord({ latestVaxRecord: result.data, userId }));
  }

  if (error?.message) {
    /* eslint-disable-next-line no-console */
    console.log('ERROR', error);
    dispatch(setLatestVaxRecordError({ userId, error }));
    dispatch(handleError(error));
  }
};

// SAF-3864 and SAF-3865 - We need to refetch and update User's Vax data together with the
// latest image data using the "getVaxRecordWithLatestImg"
export const getVaxRecordWithLatestImg = (id, userId) => async (dispatch) => {
  dispatch(setLatestVaxRecordLoading({ userId }));
  const [result, error] = await api.getLatestVaccinationRecord(id);

  if (result) {
    dispatch(setLatestVaxRecord({ latestVaxRecord: result.data, userId }));
  }

  if (error?.message) {
    /* eslint-disable-next-line no-console */
    console.log('ERROR', error);
    dispatch(setLatestVaxRecordError({ userId, error }));
    dispatch(handleError(error));
  }
};
// END - SAF-3864/SAF-3865

export const getVaxRecordImage = (safeEMRId, imageId, idx) => async (dispatch) => {
  dispatch(setVaxRecordImageLoading({ idx }));

  const [result, error] = await api.getVaccinationRecordImage(safeEMRId, imageId);
  if (result) {
    dispatch(addVaxRecordImage({ idx, result }));
  }

  if (error?.message) {
    /* eslint-disable-next-line no-console */
    console.log('ERROR', error);
    dispatch(setVaxRecordImageError({ idx, error }));
    dispatch(handleError(error));
  }
};

export const getVaxRecords = (safeEMRid, pageInfo) => async (dispatch) => {
  prevPageInfo = pageInfo || prevPageInfo;
  dispatch(setVaxRecordsLoading());

  const [result, error] = await api.getVaccinationRecords(safeEMRid, prevPageInfo);

  if (result) {
    dispatch(setVaxRecords(result.data));
  }

  if (error?.message) {
    /* eslint-disable-next-line no-console */
    console.log('ERROR', error);
    dispatch(setVaxRecordsError(error));
    dispatch(handleError(error));
  }
};

export const getMasterCode =
  ({ term, top }) =>
  async (dispatch) => {
    const params = {
      term,
      top,
    };
    const [result, error] = await api.getMasterCode(params);
    //   const [] = await api.getVaccinationRecords(params.safeEMRid);

    if (result) {
      dispatch(setMasterCode(result));
    }

    if (error?.message) {
      /* eslint-disable-next-line no-console */
      console.log('ERROR', error);
      dispatch(setMasterCodeError(error));
      dispatch(handleError(error));
    }
  };

export const updateAttestation =
  (id, type, images, vaccinations, safeEMRId, fromUser, tenant) => async (dispatch, state) => {
    if (fromUser) {
      dispatch(setTenantUsersLoading());
    } else {
      dispatch(setUpdateAttestationLoading());
    }

    const params = {
      id,
      type,
      images,
      vaccinations,
    };
    const result = await api.updateAttestation(params, safeEMRId);
    const actualResult = result[0] || result;
    // if (error) {
    if (!actualResult.success) {
      /* eslint-disable-next-line no-console */
      console.log('ERROR', result.error);
      dispatch(setUpdateAttestationError(result.error));
      dispatch(handleError(result.error));
      dispatch(showNotification('Failed to update the vaccination record', 'error'));
    } else if (actualResult.success) {
      dispatch(showNotification('Vaccination record updated successfully.', 'success'));
    }

    if (fromUser) {
      const prevFilterState = state().users.prevFilterState;
      dispatch(
        getUsers(
          tenant,
          initialKendoState?.skip,
          initialKendoState?.take,
          prevFilterState?.searchValue ?? '',
          initialKendoState?.sort[0]?.field,
          initialKendoState.sort[0]?.dir?.toUpperCase(),
          prevFilterState?.permission?.id,
          prevFilterState?.gender?.id,
          prevFilterState?.userSegment,
          prevFilterState?.status?.id,
          prevFilterState?.vaccineRecordStatus,
          prevFilterState?.lastTestResult,
          prevFilterState?.dateRangeFilter
        )
      );
      dispatch(clearVaxRecords());
      dispatch(clearLatestVaxRecord());
    } else {
      dispatch(getVaxRecords(safeEMRId));
    }
  };

export const createAttestation = (type, images, vaccinations, safeEMRId) => async (dispatch) => {
  dispatch(setUpdateAttestationLoading());
  const params = {
    type,
    images,
    vaccinations,
  };
  const result = await api.createAttestation(params, safeEMRId);
  const actualResult = result[0] || result[1] || result;

  if (actualResult) {
    const isSuccess = actualResult?.success;

    if (isSuccess) {
      const id = actualResult.data?.id;
      const [approvalResult, approvalError] = await api.approveAttestation(safeEMRId, id);
      if (approvalResult) {
        dispatch(setApproveVaccineRecord());
        dispatch(getVaxRecords(safeEMRId));
        dispatch(showNotification('New record added successfully', 'info'));
        return;
      }
      if (approvalError?.message) {
        dispatch(setApproveVaccineRecordError(approvalError));
        dispatch(handleError(approvalError));
      }
    } else {
      /* eslint-disable-next-line no-lonely-if */
      if (actualResult.message.toUpperCase() === 'DUPLICATE RECORD') {
        dispatch(showNotification('This Vaccination record already exists in our system.', 'error'));
      } else {
        dispatch(showNotification(actualResult.message, 'error'));
      }
    }
  }

  // if (error) {
  if (actualResult.error) {
    /* eslint-disable-next-line no-console */
    console.log('ERROR', result.error);
    dispatch(setUpdateAttestationError(result.error));
    dispatch(handleError(result.error));
  }
  dispatch(getVaxRecords(safeEMRId));
};

export const approveAttestation =
  (id, type, images, vaccinations, safeEMRId, fromUser, tenant) => async (dispatch, state) => {
    if (fromUser) {
      dispatch(setTenantUsersLoading());
    } else {
      dispatch(setApproveVaccineRecordLoading());
    }

    const params = {
      id,
      type,
      images,
      vaccinations,
    };
    const [updateResult, updateError] = await api.updateAttestation(params, safeEMRId);
    const [approvalResult, approvalError] = await api.approveAttestation(safeEMRId, id);

    if (updateError?.message) {
      /* eslint-disable-next-line no-console */
      console.log('UPDATE ERROR', updateError, approvalError);
      dispatch(setApproveVaccineRecordError(updateError));
      dispatch(handleError(updateError));
    }
    if (approvalError?.message) {
      /* eslint-disable-next-line no-console */
      console.log('UPDATE ERROR', updateError, approvalError);
      dispatch(setApproveVaccineRecordError(updateError));
      dispatch(handleError(updateError));
    }

    if (updateResult || approvalResult) {
      if (fromUser) {
        const prevFilterState = state().users.prevFilterState;
        dispatch(
          getUsers(
            tenant,
            initialKendoState?.skip,
            initialKendoState?.take,
            prevFilterState?.searchValue ?? '',
            initialKendoState?.sort[0]?.field,
            initialKendoState.sort[0]?.dir?.toUpperCase(),
            prevFilterState?.permission?.id,
            prevFilterState?.gender?.id,
            prevFilterState?.userSegment,
            prevFilterState?.status?.id,
            prevFilterState?.vaccineRecordStatus,
            prevFilterState?.lastTestResult,
            prevFilterState?.dateRangeFilter
          )
        );
        dispatch(clearVaxRecords());
        dispatch(clearLatestVaxRecord());
      } else {
        dispatch(setApproveVaccineRecord());
        dispatch(getVaxRecords(safeEMRId));
      }
      dispatch(showNotification('Vaccination record has been approved.', 'success'));
    }
  };

export const approveExemption =
  (id, safeEMRId, fromUser, tenant, type, images, vaccinations) => async (dispatch, state) => {
    if (fromUser) {
      dispatch(setTenantUsersLoading());
    } else {
      dispatch(setApproveVaccineRecordLoading());
    }

    const params = {
      id,
      type,
      images,
      vaccinations,
    };

    const [updateResult, updateError] = await api.updateAttestation(params, safeEMRId);
    const [approvalResult, approvalError] = await api.approveAttestation(safeEMRId, id);

    if (approvalError?.message || updateError?.message) {
      /* eslint-disable-next-line no-console */
      console.log('APPROVAL ERROR', approvalError?.message || updateError?.message);
      dispatch(setApproveVaccineRecordError(approvalError?.message || updateError?.message));
      dispatch(handleError(approvalError?.message || updateError?.message));
    }

    if (approvalResult && updateResult) {
      if (fromUser) {
        const prevFilterState = state().users.prevFilterState;
        dispatch(
          getUsers(
            tenant,
            initialKendoState?.skip,
            initialKendoState?.take,
            prevFilterState?.searchValue ?? '',
            initialKendoState?.sort[0]?.field,
            initialKendoState.sort[0]?.dir?.toUpperCase(),
            prevFilterState?.permission?.id,
            prevFilterState?.gender?.id,
            prevFilterState?.userSegment,
            prevFilterState?.status?.id,
            prevFilterState?.vaccineRecordStatus,
            prevFilterState?.lastTestResult,
            prevFilterState?.dateRangeFilter
          )
        );
        dispatch(clearVaxRecords());
        dispatch(clearLatestVaxRecord());
      } else {
        dispatch(setApproveVaccineRecord());
        dispatch(getVaxRecords(safeEMRId));
      }
    }
  };

export default vaccinationRecordsSlice.reducer;
