import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState, useEffect } from 'react';

// redux
import { useDispatch, useSelector } from 'react-redux';

import ProfileViewStyles from '../../assets/jss/components/ProfileViewStyles';
import { selectCurrentTenant } from '../Shared/selectors';
import AppKendoTableWrapper from '../Shared/Table/KendoTableWrapper';
import utils from '../Shared/Utils/utils';

import { selectUserDetails } from '../Users/selectors';
import { immunizationColumns as columns, immunizationTableButtons } from './helpers/clinicalInfoMapper';
import { convertImmunizationObjToImmunization, createImmunizationObj } from './helpers/createClinicalObjBuilder';
import ImmunizationDialog from './ImmunizationDialog';
import {
  getImmunizationList,
  postImmunizationList,
  pullImmunization,
  pushImmunization,
} from './slices/immunizationListSlice';

// mui
import { selectImmunizationList, selectImmunizationListLoading } from './slices/selectors';

function Immunization() {
  const classes = useStyles();

  const userDetails = useSelector(selectUserDetails);
  const currentTenant = useSelector(selectCurrentTenant);
  const immunizationList = useSelector(selectImmunizationList);
  const immunizationListLoading = useSelector(selectImmunizationListLoading);

  const [pagedImmunizationList, setPagedImmunizationList] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedImmunization, setSelectedImmunization] = useState({});
  const [dialogMode, setDialogMode] = useState('Add');

  const dispatch = useDispatch();

  const pushImmunizationAction = () => {
    dispatch(pushImmunization(currentTenant.id, userDetails.nationalHealthId));
  };
  const pullImmunizationAction = () => {
    dispatch(pullImmunization(currentTenant.id, userDetails.nationalHealthId));
  };

  const tableButtons = immunizationTableButtons(setDialogOpen, pushImmunizationAction, pullImmunizationAction);

  const handleRowClick = (e) => {
    setSelectedImmunization(createImmunizationObj(pagedImmunizationList.find((x) => x.id === e.id)));
    setDialogMode('Edit');
    setDialogOpen(true);
  };
  const handleCloseDialog = () => {
    setSelectedImmunization({});
    setDialogMode('Add');
    setDialogOpen(false);
  };
  const handleTableUpdate = (page, pageSize) => {
    setPagedImmunizationList(immunizationList.data.slice((page + 1) * pageSize - pageSize, (page + 1) * pageSize));
  };
  useEffect(() => {
    dispatch(getImmunizationList(userDetails.nationalHealthId));
  }, [currentTenant, userDetails]);
  useEffect(() => {
    if (immunizationList) setPagedImmunizationList(immunizationList.data);
  }, [immunizationList]);

  const handleUpdateImmunization = (immunizationObj) => {
    const immunization = convertImmunizationObjToImmunization(immunizationObj);
    const immunizationList = {
      patientDetailDTOList: [immunization],
      safeEMRId: userDetails.nationalHealthId,
      resourceType: 'Immunization',
    };
    dispatch(postImmunizationList(userDetails.nationalHealthId, immunizationList));
  };

  return (
    <Grid className={`${classes.contentWrapper} ${classes.withTableButtons}`}>
      <AppKendoTableWrapper
        data={{
          ...pagedImmunizationList,
          items:
            pagedImmunizationList &&
            pagedImmunizationList.map((n) => ({
              ...n,
              immunizationName: n.vaccinationCode ? n.vaccinationCode[0].display : '',
              manufacturer: n.manufacturer ? n.manufacturer.name : '',
              startDate: utils.formatDateTimeLocale(n.administeredDate),
              status: n.status,
              route: n.route ? n.route.display : '',
              site: n.bodySite ? n.bodySite.display : '',
              provider:
                n.practitioner && n.practitioner.length > 0 && n.practitioner[0].actor
                  ? n.practitioner[0].actor.display
                  : '',
            })),
          total: immunizationList && immunizationList.data.length,
        }}
        initialSort="started"
        loading={immunizationListLoading}
        initialDir="desc"
        columns={columns()}
        onTableUpdate={handleTableUpdate}
        showButtons
        onRowClick={handleRowClick}
        tableButtons={tableButtons}
      />
      {dialogOpen && (
        <ImmunizationDialog
          selectedImmunization={selectedImmunization}
          dialogMode={dialogMode}
          closeDialog={handleCloseDialog}
          updateImmunization={handleUpdateImmunization}
        />
      )}
    </Grid>
  );
}

const useStyles = makeStyles(ProfileViewStyles);

export default Immunization;
