/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { filterBy } from '@progress/kendo-data-query';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import React, { useState, useEffect, useRef } from 'react';

import utils from '../../Utils/utils';

// ui reflection of file view
function AutocompleteInput(props) {
  const { field, initialValue, handleChangeWithParams, disabled, autoSetValues } = props;

  const [data, setData] = useState(
    field.disabledItems ? field.enum.filter((item) => !field.disabledItems.includes(item)) : field.enum
  );
  const [autocompleteValue, setAutocompleteValue] = useState(
    initialValue && field.valueField ? field.enum.find((s) => s[field.valueField] === initialValue) : initialValue
  );
  const [loading, setLoading] = useState(false);
  const [timeout, setStateTimeout] = useState(undefined);
  const combobox = useRef(null);

  // disable autocomplete through reference
  useEffect(() => {
    if (combobox && combobox.current) {
      const input = combobox.current.element.querySelector('input');
      input.autocomplete = 'new-password';
    }
  }, [combobox]);

  useEffect(() => {
    if (
      !autocompleteValue ||
      (field.valueField === 'id' &&
        ((initialValue && initialValue !== autocompleteValue) || initialValue !== undefined))
    ) {
      // set based on enum valueField, if present
      let selectedValue = initialValue;
      if (field.valueField && field.matchField) {
        selectedValue = field.enum.find(
          (s) =>
            JSON.stringify(s[field.valueField][field.matchField]) ===
            JSON.stringify(initialValue && initialValue[field.matchField] ? initialValue[field?.matchField] : null)
        );
      } else if (field.valueField) {
        selectedValue = field.enum.find((s) => JSON.stringify(s[field.valueField]) === JSON.stringify(initialValue));
      }

      setAutocompleteValue(selectedValue);
    }
    // eslint-disable-next-line
  }, [initialValue]);

  const handleChange = (e) => {
    let { value } = e.target;
    let { name } = e.target;
    // If linked to other fields on the page, use the link logic to update
    if (field.linkField) {
      handleChangeWithParams(field.linkField, value, field.writeToParent);
    } else {
      setAutocompleteValue(value);
      if (field.valueField && value) {
        value = value[field.valueField];
        handleChangeWithParams(name, value);

        if (field.setFields && autoSetValues) {
          autoSetValues(
            field.setFields.reduce((accum, f) => {
              return {
                ...accum,
                [f.targetField]: f.transform
                  ? f.transform(e.target.value)
                  : e.target.value && utils.getPropertyByName(f.field, e.target.value),
              };
            }, {})
          );
        }
        // Update forms if the Kendo Combobox built in Remove using 'x' is used, or if values are changed to null
        // } else if (field.valueField && name) {
        //   // Update forms if the Kendo Combobox built in Remove using 'x' is used, or if values are changed to null
        //   // Prevent Question Type from being set to null
        //   if (name === 'type') {
        //     name = { ...autocompleteValue };
        //     setAutocompleteValue(name);
        //     return;
        //   }
        //   handleChangeWithParams(name, value);
      } else {
        // Default if no valueField
        handleChangeWithParams(name, value);
      }
    }
  };

  const filterData = (filter) => {
    const allData = field.enum.slice();
    if (filter.field) {
      return filterBy(allData, filter);
    } else {
      var regex = new RegExp(filter.value, "i");
      return allData.filter((s) => s.search(regex) > -1);
    }
  };

  const handleFilterChange = (event) => {
    if (timeout) {
      clearTimeout(timeout);
    }

    setStateTimeout(
      setTimeout(() => {
        const newData = filterData(event.filter);
        setData(newData);
        setLoading(false);
      }, 500)
    );

    setLoading(true);
  };

  const getFooter = () => {
    const items = [];
    if (field.disabledItems) {
      field.disabledItems.forEach((item, i) => {
        if (item !== initialValue) {
          items.push(
            <li style={{ cursor: 'default', opacity: 0.6 }} key={`li_${i}`}>
              {item}
            </li>
          );
        }
      });
    }
    return <div style={{ marginLeft: 25 }}>{items}</div>;
  };

  const renderItem = (li, { dataItem }) => {
    const isOptionDisabled = field?.disableOptions && field?.disableOptions?.length && field.disableOptions.includes(dataItem?.value);

    return (
      <span
        aria-selected={false}
        className={"k-item"}
        aria-disabled={disabled}
        onClick={!isOptionDisabled ? li.props.onClick : null}
        style={isOptionDisabled ? {
          color: "#ccc",
          cursor: "default",
        } : {}}
      >
        {li.props.children}
      </span>
    );
  }

  return (
    <Grid
      item
      xs={12}
      className={`${field.variant || ""} ${disabled ? "disabled" : ""}`}
    >
      <ComboBox
        // data={data.filter((item) =>
        //   field.disabledItems ? !field.disabledItems.some((disabled) => disabled === item.label) : true
        // )}
        data={
          field.name !== "country" && field.name !== "orderingTestKitOption" // Ignore country to leave USA at the top of the list
            ? field.labelField
            ? [...data].sort(utils.sortBy(field.labelField))
            : [...data].sort()
            : [...data]
        }
        clearButton={!field.disableClear}
        allowCustom={false}
        suggest
        filterable
        onFilterChange={handleFilterChange}
        label={field.label}
        name={field.name}
        required={field.required}
        onChange={handleChange}
        itemRender={renderItem}
        style={{ width: '100%' }}
        textField={field.labelField}
        value={autocompleteValue}
        loading={loading}
        disabled={disabled || field.disabled}
        ref={combobox}
        footer={field.disabledItems && getFooter()}
      />
      {field.description && (
        <Typography variant="caption" style={{ opacity: 0.6 }}>
          {field.description}
        </Typography>
      )}
    </Grid>
  );
}

export default AutocompleteInput;
