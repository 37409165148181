/* eslint-disable react/no-unstable-nested-components */
import Grid from '@material-ui/core/Grid';
import { ComboBox } from '@progress/kendo-react-dropdowns';

// redux
import axios from 'axios';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { selectCurrentTenant } from '../../selectors';

import ListFeedback from './Typeahead/ListFeedback';

const API_ROOT = process.env.REACT_APP_API_ROOT;

function UserSearchInput(props) {
  const { field, value, handleChangeWithParams } = props;

  const currentTenant = useSelector(selectCurrentTenant);

  const [loading, setLoading] = useState(false);
  const [autocompleteValue, setAutocompleteValue] = useState(value);
  const [allUsers, setAllUsers] = useState([]);

  const [timeout, setStateTimeout] = useState(undefined);

  // use component state because changing redux state create visible changes on the users table below create user popup
  const getUsers = (query) => {
    const id_token = localStorage.getItem('id_token');
    const accountId = localStorage.getItem('accountId');
    const tenantId = localStorage.getItem('tenantId');

    axios({
      method: 'GET',
      url: `${API_ROOT}/admin/v1/user/${currentTenant.id}`,
      params: {
        skip: 0,
        limit: 10,
        q: query,
        sort: 'email',
        direction: 'ASC',
      },
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json;charset=UTF-8',
        Accept: 'application/json',
        Authorization: `Bearer ${id_token}`,
        Id_Token: id_token,
        'X-SF-ACCOUNT': accountId,
        'X-SF-TENANT': tenantId,
      },
    })
      .then((response) => {
        const filteredUsers = response.data.items.filter((user) => !user.parentIds.length);
        setAllUsers(filteredUsers);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleFilterChange = (event) => {
    if (event.filter.value.length > 2) {
      if (timeout) {
        clearTimeout(timeout);
      }

      setStateTimeout(
        setTimeout(() => {
          getUsers(event.filter.value.trim());
        }, 500)
      );

      setLoading(true);
    }
  };

  const handleChange = (e) => {
    const { value } = e.target;
    const { name } = e.target;
    setAutocompleteValue(value);

    handleChangeWithParams(name, value.email);
  };

  return (
    <Grid item xs={12}>
      <ComboBox
        data={allUsers}
        allowCustom
        suggest
        filterable
        onFilterChange={handleFilterChange}
        label={field.label}
        name={field.name}
        required={field.required}
        onChange={handleChange}
        style={{ width: '100%' }}
        textField="email"
        dataItemKey="id"
        value={autocompleteValue}
        loading={loading}
        listNoDataRender={() => <ListFeedback loading={loading} />}
      />
    </Grid>
  );
}

export default UserSearchInput;
