import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React, { useEffect, useState, useRef } from 'react';

// package imports
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { useSelector } from 'react-redux';

import styles from '../../assets/Questionnaire.module.css';
import { selectIsValidPhoneNumber } from '../../selectors';
import { validationRegex } from '../helpers/validationHelper';
import utils from '../utils/utils';

function PhoneInputField(props) {
  const { column, i, countryCode, number, handleChangeWithParams, handleBlur, onKeyPress, field, selectedLanguage } =
    props;
  // Enable regions for localization
  const regions = ['america', 'europe', 'asia', 'oceania', 'africa'];
  const inputRef = useRef();
  const [phoneNumber, setPhoneNumber] = useState(number || '');
  const [isError, setError] = useState(false);
  const [code, setCode] = useState(countryCode || '');
  const isValidPhoneNumber = useSelector(selectIsValidPhoneNumber);

  useEffect(() => {
    if (!isValidPhoneNumber && isValidPhoneNumber !== null) {
      setError(true);
    }
  }, [isValidPhoneNumber]);

  const handlePhoneChange = (value, country) => {
    const number = country.dialCode ? value.slice(country.dialCode.length) : value;
    // removes country code from number if it exists
    setPhoneNumber(number);
    // if no country selected sets to empty string
    setCode(country.dialCode || '');
    const valid = validationRegex.phone.test(number);
    if (valid) {
      setError(false);
      handleChangeWithParams(field.name, number);
    } else {
      setError(true);
      handleChangeWithParams(field.name, '');
    }
  };

  const fieldTitle =
    selectedLanguage && field.name && field.name.translations
      ? field.name.translations[selectedLanguage]
      : field.label || field.name;
  const questionDescription =
    selectedLanguage && field.description && field.description.translations
      ? field.description.translations[selectedLanguage]
      : field.description;

  return (
    <Grid>
      <Grid item xs={12} style={{ width: '100%' }}>
        {fieldTitle && (
          <Typography className={field.nameStyle ? styles[`title--${field.nameStyle}`] : styles['title--style1']}>
            {field.questionPreview ? utils.renderHTMLTags(fieldTitle) : fieldTitle}
            {field.required && '*'}
          </Typography>
        )}
        {field.image && (
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <img
              src={field.image}
              alt={fieldTitle}
              style={{
                display: 'block',
                marginLeft: 'auto',
                marginRight: 'auto',
                width: '90%',
              }}
            />
          </Grid>
        )}
        {questionDescription && (
          <Typography
            className={
              field.descriptionStyle ? styles[`description--${field.descriptionStyle}`] : styles['description--style1']
            }
          >
            {utils.renderHTMLTags(questionDescription)}
          </Typography>
        )}
      </Grid>

      <PhoneInput
        ref={inputRef}
        regions={regions}
        onChange={(value, country) => handlePhoneChange(value, country)}
        enableSearch
        enableLongNumbers
        countryCodeEditable={false}
        showDropdown={false}
        inputClass={styles.phoneInputClass}
        inputProps={{
          name: field && field.name,
          required: field && field.required,
          autoFocus: !field && true,
        }}
        inputStyle={{
          width: 'inherit',
          border: 'none',
          borderBottom: 'solid 1px',
          borderBottomColor: field && field.required && !phoneNumber ? 'red' : '#ececec',
          padding: '0 14px 0 58px',
          borderRadius: '0',
          height: '50px',
        }}
        dropdownStyle={{
          maxHeight: '90%',
          position: 'fixed',
          top: '10px',
          left: '10px',
        }}
        onBlur={(e) => handleBlur && handleBlur(e, column[i - 1], column[i], 'number')}
        specialLabel=""
        value={phoneNumber ? code + phoneNumber : null}
        onKeyPress={onKeyPress}
        country="us"
      />
      {isError ? <FormHelperText error>Invalid Phone Number</FormHelperText> : null}
    </Grid>
  );
}

export default PhoneInputField;
