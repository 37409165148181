import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { showNotification } from '../Notifications/notificationSlice';
import { searchProvider } from '../RecordRequest/providerListSlice';
import { retrivePatientDemographis, getOneupSyncStatus } from '../RecordRequest/recordRequestSlice';
import {
  selectOneupSyncStatus,
  selectOneupSyncStatusLoading,
  selectProviderList,
  selectProviderListLoading,
} from '../RecordRequest/selectors';

import { selectCurrentTenant } from '../Shared/selectors';
import { selectUserDetails } from '../Users/selectors';
import { getAccessToken, setAccessToken } from './slices/accessTokenSlice';
import { selectAccessToken, selectAccessTokenLoading } from './slices/selectors';

const ONEUP_CLIENTID = process.env.REACT_APP_ONEUP_CLIENTID;

function QuickConnect() {
  const userDetails = useSelector(selectUserDetails);
  const currentTenant = useSelector(selectCurrentTenant);
  const providerList = useSelector(selectProviderList);
  const providerListLoading = useSelector(selectProviderListLoading);
  const oneupSyncStatus = useSelector(selectOneupSyncStatus);
  const oneupSyncStatusLoading = useSelector(selectOneupSyncStatusLoading);
  const accessToken = useSelector(selectAccessToken);
  const accessTokenLoading = useSelector(selectAccessTokenLoading);

  const [url] = useState('');
  const [timeout, setStateTimeout] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState([]);
  const [healthSystemValue, setHealthSystemValue] = useState('');
  const [iframeToggle] = useState(false);
  const iframeRef = useRef(null);
  const [intervalfunction, setIntervalFunction] = useState(undefined);

  const dispatch = useDispatch();

  useEffect(() => {
    if (providerList) {
      setLoading(providerListLoading);
      setValues(providerList.data.result);
    }
  }, [providerList, providerListLoading]);

  useEffect(() => {
    if (healthSystemValue) {
      dispatch(getAccessToken(userDetails.nationalHealthId, currentTenant.id));
    }
  }, [currentTenant.id, healthSystemValue, userDetails.nationalHealthId]);

  useEffect(() => {
    if (accessToken && accessToken.data && healthSystemValue && ONEUP_CLIENTID) {
      window.open(
        `https://api.1up.health/connect/system/clinical/${healthSystemValue}?client_id=${ONEUP_CLIENTID}&access_token=${accessToken.data.access_token}&state=${accessToken.data.oneupStatusId}`
      );
      setIntervalFunction(
        setInterval(() => {
          dispatch(getOneupSyncStatus(accessToken.data.oneupStatusId, currentTenant.id));
        }, 3000)
      );
    }
  }, [accessToken, accessTokenLoading, healthSystemValue]);

  useEffect(() => {
    if (accessToken && accessToken.data && accessToken.data.access_token) {
      if (oneupSyncStatus === 'Success') {
        const patientDetails = {
          access_Token: accessToken.data.access_token,
          oneup_User_Id: accessToken.data.oneup_user_id,
          app_User_Id: userDetails.nationalHealthId,
          healthSystemId: parseInt(healthSystemValue),
        };
        dispatch(retrivePatientDemographis(patientDetails, currentTenant.id, userDetails.nationalHealthId));
        setIntervalFunction(clearInterval(intervalfunction));
        dispatch(setAccessToken(undefined));
      } else if (oneupSyncStatus === 'Failure') {
        dispatch(showNotification('Oops! Oneup returning false while sync.', 'error'));
        setIntervalFunction(clearInterval(intervalfunction));
        dispatch(setAccessToken(undefined));
      }
    }
  }, [
    oneupSyncStatusLoading,
    oneupSyncStatus,
    accessToken,
    userDetails.nationalHealthId,
    healthSystemValue,
    currentTenant.id,
    intervalfunction,
    dispatch,
  ]);

  const handleChange = (event) => {
    if (event.target.value) setHealthSystemValue(event.target.value.facilityKey);
  };

  const handleFilterChange = (event) => {
    const { value } = event.filter;
    if (timeout) {
      clearTimeout(timeout);
    }
    setStateTimeout(
      setTimeout(() => {
        const providerData = {
          pageSize: 20,
          facilityType: '1up',
          nameOnly: value,
          pageNumber: 0,
        };
        dispatch(searchProvider(providerData));
      }, 500)
    );

    setLoading(true);
  };

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={12}>
        <Card style={{ margin: '10px', padding: 16, boxSizing: 'border-box' }}>
          <ComboBox
            data={values}
            allowCustom
            suggest
            filterable
            onFilterChange={handleFilterChange}
            label="Facility Name"
            name="facilityName"
            required
            onChange={handleChange}
            style={{ width: '50%' }}
            textField="name"
            loading={loading}
          />
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card>
          {iframeToggle ? (
            <iframe ref={iframeRef} src={url} height="800px" width="100%" title="Quick Connect" />
          ) : (
            <div />
          )}
        </Card>
      </Grid>
    </Grid>
  );
}

export default QuickConnect;
