import moment from 'moment';

import { branchTypes } from './mapping';

export const buildTreeData = (answers) => {
  const treeData = [];
  // Three main branches: answerByQuestion, answerByTarget, and formattedByTarget
  Object.keys(branchTypes).forEach((branchType) => {
    const result = buildTheBranch(answers, branchType);
    if (result.items.length) {
      treeData.push(result);
    }
  });
  return treeData;
};

// Three main branches: answerByQuestion, answerByTarget, and formattedByTarget
// This function builds branches of the tree by looping through data
export const buildTheBranch = (data, branchType) => {
  const tree = {
    text: branchTypes[branchType],
    expanded: true,
    items: [],
  };

  function _find(rootItems, text) {
    if (!rootItems) return null;

    const idx = rootItems.findIndex((el) => el.text === text);
    return idx >= 0 ? rootItems[idx] : null;
  }

  data.forEach((d) => {
    if (branchType === 'answerByQuestion') {
      tree.items.push({
        text: d.title,
        expanded: true,
        items: getAnswersQuestionForTree(d),
      });
    } else if (branchType === 'answerByTarget' || branchType === 'formattedByTarget') {
      if (!d.report.target) {
        /* eslint-disable-next-line no-console */
        console.log(`No path for question: ${data.title}`);
      } else {
        // Report/2History and Report/1Test parsing by slash represents parent child relations
        // The number in front of each items is optional and represents relative position of siblings

        let path = d.report.target.trim().replace(/^\/|\/$/g, ''); // clear from additional symbols

        // check if the last after slash is number, that is question priority
        let questionPriority = path.match(/\/(\d+)$/);
        if (questionPriority && questionPriority[1]) {
          path = path.replace(/\/(\d+)$/g, '');
          questionPriority = questionPriority[1];
        }

        path = path.split('/');

        let root = tree;
        path.forEach((p, idx) => {
          // Report/2History and Report/1Test The number in front of each items is optional and represents sorting value
          // get sort value for path item
          const sortVal = p.match(/(^\d+)(\w.*$)/);
          if (sortVal && sortVal[1]) {
            p = sortVal[2] ? sortVal[2] : p;
          }

          root =
            _find(root.items, p) ||
            (() => {
              const newEl = {
                text: p,
                expanded: true,
                items: [],
                sortVal: sortVal && sortVal[1] ? sortVal[1] : 100,
              };
              root.items.push(newEl);

              if (root.items && root.items.length) {
                root.items.sort((a, b) => a.sortVal - b.sortVal);
              }

              return newEl;
            })();

          // question items
          if (idx === path.length - 1) {
            let answerData = null;
            if (branchType === 'formattedByTarget') {
              answerData = {
                text: getFormattedAnswer(d),
              };
            } else {
              answerData = {
                text: d.title,
                expanded: true,
                items: getAnswersQuestionForTree(d),
              };
            }
            // if exists then add sorting value for item
            if (questionPriority) answerData.sortVal = questionPriority;

            root.items.push(answerData);

            // if exists sorting value make sorting for items
            if (questionPriority && root.items && root.items.length > 1) {
              root.items.sort((a, b) => a.sortVal - b.sortVal);
            }
          }
        });
      }
    } else {
      /* eslint-disable-next-line no-console */
      console.log(`Branch type=${branchType} expected answerByQuestion, answerByTarget, formattedByTarget`);
    }
  });
  return tree;
};

function getAnswersQuestionForTree(data) {
  const result = [];

  if (data.affirmed) {
    let affirmed =
      typeof data.affirmed === 'object' && ['address', 'insurance', 'multiple-small-text'].indexOf(data.type) === -1
        ? data.affirmed.join(', ')
        : data.affirmed;

    // Deconstruct address object
    if (data.type === 'address') {
      const { line1, line2, city, state, zip, country } = data.affirmed;
      affirmed = `${line1}${line2 ? ', ' : ' '}${line2 || ''}, ${city}, ${state} ${zip}${country ? ', ' : ' '}${
        country || ''
      }`;
    } else if (data.type === 'insurance') {
      // Deconstruct insurance object
      const { insuranceProvider, insuranceId, groupNumber, coverageDate, subscriber } = data.affirmed;
      const { name, relation } = subscriber;
      affirmed = `Provider: ${insuranceProvider},
      ID: ${insuranceId},
      Group Number: ${groupNumber},
      Coverage Date: ${moment(coverageDate).format('MM/DD/YYYY')},
      Subscriber Name: ${name || 'Self'},
      Subscriber Relation: ${relation || 'Self'}`;
    } else if (data.type === 'date') {
      affirmed =
        affirmed instanceof Date ? new Date(affirmed) : moment().subtract(affirmed.answer, 'days').format('MM.DD.YYYY');
    } else if (data.type === 'password') {
      affirmed = 'Affirmed';
    } else if (data.type === 'photo') {
      affirmed = 'Uploaded';
    } else if (data.type === 'multiple-small-text') {
      affirmed = affirmed.map((item) => item.value).join(', ');
    }

    if (data.result) {
      result.push({ text: `${affirmed.replace(/(<([^>]+)>)/gi, '')}` });
    } else {
      result.push({ text: `AFFIRMS: ${affirmed}` });
    }
  }

  if (data.denied) {
    result.push({ text: `DENIES: ${data.denied.join(', ')}` });
  }

  if (data.comments) {
    result.push({
      text: `COMMENTS: ${Object.values(data.comments).join(', ')}`,
    });
  }

  // display question target
  if (data.report && data.report.target) {
    result.push({
      text: `TARGET: ${data.report.target}`,
    });
  }

  // display question format string
  if (data.report && data.report.text) {
    result.push({
      text: `FORMAT: ${data.report.text}`,
    });
  }

  return result;
}

function getFormatedAnswers(data, comments) {
  let answerAffirmed = data.map((item) => {
    const answerComment = comments ? comments[item] : '';
    item = item.replace(/\(.*?\)/g, '');
    item = item.replace(/\s+/g, ' ');
    return answerComment ? `${item} (${answerComment})` : item;
  });

  answerAffirmed = answerAffirmed
    .join(', ')
    .replace(/,([^,]*)$/, ' and$1')
    .toLocaleLowerCase();

  return answerAffirmed;
}

export const getFormattedAnswer = (answer) => {
  let formattedAnswer = '';
  if (answer.report && answer.report.text) {
    let answerString = '';

    // date question
    if (answer.type === 'date') {
      const formatObj = answer.report.text.match(/\{(d{1,}\\-m{1,}\\-y{1,})\}/i);
      let formatString = '';

      if (formatObj && formatObj[1]) {
        formatString = moment()
          .subtract(answer.affirmed - 1, 'days')
          .format(formatObj[1].toUpperCase());
      }
      answerString = answer.report.text.replace(formatObj[0], formatString);

      if (answer.report.text.match(/\(\)/gi)) {
        const answerComment = answer.comments ? ` (${Object.values(answer.comments)[0]})` : '';
        answerString = answerString.replace('()', answerComment);
      }
      formattedAnswer = answer.report.text.replace(formatObj[0], answerString);
    } else if (answer.type === 'multiple' && answer.report.text) {
      const variants = answer.report.text.split('|');

      if (answer.affirmed && answer.affirmed.length && answer.denied && answer.denied.length) {
        // "The patient reported {y} while denying {n}."
        const formatVariant = variants[0];
        if (formatVariant) {
          // add affirmed items
          const affirmedAnswers = getFormatedAnswers(answer.affirmed, answer.comments);
          formattedAnswer = formatVariant.trim().replace(/\{y\}/, affirmedAnswers);

          // add denied items
          const deniedAnswers = getFormatedAnswers(answer.denied, answer.comments);
          formattedAnswer = formattedAnswer.replace(/\{n\}/, deniedAnswers);
        }
      } else if (answer.affirmed && answer.affirmed.length && (!answer.denied || !answer.denied.length)) {
        // "The patient reported {y}."
        const formatVariant = variants[1];
        if (formatVariant) {
          // add affirmed items
          const affirmedAnswers = getFormatedAnswers(answer.affirmed, answer.comments);
          formattedAnswer = formatVariant.trim().replace(/\{y\}/, affirmedAnswers);
        }
      } else if ((!answer.affirmed || !answer.affirmed.length) && answer.denied && answer.denied.length) {
        // "The patient denied {n} "
        const formatVariant = variants[2];
        if (formatVariant) {
          // add denied items
          const deniedAnswers = getFormatedAnswers(answer.denied, answer.comments);
          formattedAnswer = formatVariant.trim().replace(/\{n\}/, deniedAnswers);
        }
      } else {
        // "The patient did not answer {q}."
        const formatVariant = variants[3];
        if (formatVariant) {
          formattedAnswer = formatVariant.trim().replace(/\{q\}/, answer.title);
        }
      }
      // } else if (answer.type === "yes-no") {
      //   const variants = answer.report.text.split("|");
      //   if (variants.length) {
      //     variants.forEach((variant) => {
      //       variant = variant.trim();
      //       console.log(11111111111, answer.affirmed);
      //       if (answer.affirmed === "Yes" && variant.match(/(^\{Y\})(\w.*$)/)) {
      //         // for YES
      //         formattedAnswer = variant;
      //       } else if (
      //         answer.affirmed === "No" &&
      //         variant.match(/(^\{N\})(\w.*$)/)
      //       ) {
      //         // for NO
      //         formattedAnswer = variant;
      //       } else if (!answer.affirmed && variant.match(/(^\{E\})(\w.*$)/)) {
      //         //for ELSE
      //         formattedAnswer = variant;
      //       }
      //
      //       // replace {Y}, {N}, {E} at the start of the string
      //       formattedAnswer = formattedAnswer.replace(/^\{.*\}/, "");
      //     });
      //
      //     //add comment
      //     const comment = answer.comments ? ` (${answer.comments.comment})` : "";
      //     // set comment instead of ()
      //     if (formattedAnswer.match(/\(\)/)) {
      //       formattedAnswer = formattedAnswer.replace(/\(\)/, comment);
      //     } else if (comment) {
      //       // if . at the end then replace to comment
      //       if (formattedAnswer.match(/\.$/g)) {
      //         formattedAnswer = formattedAnswer.replace(/\.$/, `${comment}.`);
      //       } else {
      //         formattedAnswer = `${formattedAnswer} ${comment}`;
      //       }
      //     }
      //   }
    } else {
      if (answer.result) {
        answerString = answer.affirmed.replace(/(<([^>]+)>)/gi, '');
      } else {
        answerString = answer.affirmed;
      }

      formattedAnswer = answer.report.text.replace(/\{a\}/, answerString); // add comment
      const comment = answer.comments ? ` (${answer.comments.comment})` : '';
      if (comment) {
        // if . at the end then replace to comment
        if (formattedAnswer.match(/\.$/g)) {
          formattedAnswer = formattedAnswer.replace(/\.$/, `${comment}.`);
        } else {
          formattedAnswer = `${formattedAnswer} ${comment}`;
        }
      }
    }
  }
  return formattedAnswer;
};
