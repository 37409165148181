/* eslint-disable react/no-array-index-key */
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import React, { Fragment, useEffect, useState } from 'react';

// redux
import { useDispatch, useSelector } from 'react-redux';

// mui

import SettingsFontsStyles from '../../../assets/jss/components/SettingsFontsStyles';
import { showConfirmation } from '../../Confirmation/confirmationSlice';
import { selectCurrentTenant } from '../../Shared/selectors';
import { selectFontDetails, selectFontDetailsLoading, selectUpdatingFont } from '../selectors';
import { getFontDetails, updateFontData } from '../settingsSlice';

import FontFaceItem from './FontFaceItem';
import { FormSkeletonLoading } from './FormSkeletonLoading';

function FontEditorWrapper(props) {
  const { classes, fontId } = props;
  const currentTenant = useSelector(selectCurrentTenant);
  const fontDetails = useSelector(selectFontDetails);
  const fontDetailsLoading = useSelector(selectFontDetailsLoading);
  const updatingFont = useSelector(selectUpdatingFont);

  const [editingFont, setEditingFont] = useState(false);
  const [updatedFontName, setUpdatedFontName] = useState('');
  const [updatedFallBackFonts, setUpdatedFallBackFonts] = useState('');
  const [, setUpdateFontStatus] = useState(false);
  const [fontFacesList, setFontFacesList] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (fontDetails) {
      setUpdatedFontName(fontDetails.name);
      setUpdatedFallBackFonts(fontDetails.fallBackFonts);
    }

    const convertedFontFacesList = [];
    if (fontDetails && fontDetails.faces && Object.keys(fontDetails.faces).length) {
      Object.keys(fontDetails.faces).forEach((key) => {
        let objItem = Object.assign(fontDetails.faces[key]);
        objItem = { ...objItem, fontFaceId: key };
        convertedFontFacesList.push(objItem);
      });
    }
    setFontFacesList(convertedFontFacesList);
  }, [fontDetails]);

  useEffect(() => {
    dispatch(getFontDetails(currentTenant.id, fontId));
  }, [currentTenant, fontId]);

  const handleDelete = () => {
    dispatch(
      showConfirmation('Confirm', 'Are you sure you want to delete this font?', 'fontDelete', currentTenant.id, fontId)
    );
  };

  const handleUpdateFont = async () => {
    if (!updatedFontName || !updatedFallBackFonts) return;
    const currentTenantId = currentTenant.id;
    const updateStatus = await dispatch(updateFontData(currentTenantId, fontId, updatedFontName, updatedFallBackFonts));

    setUpdateFontStatus(updateStatus);
    if (updateStatus) setEditingFont(false);
  };

  const handleUpdateFontName = (e) => {
    const { value } = e.target;
    setUpdatedFontName(value);
  };

  const handleUpdateFallBackFonts = (e) => {
    const { value } = e.target;
    setUpdatedFallBackFonts(value);
  };

  const handleAddFontFace = () => {
    const defaultFontFace = {
      fontFaceId: '',
      fileUrl: '',
      originalFileName: '',
      style: '',
      weight: '',
    };

    const updatedFontFaceList = fontFacesList.slice();
    updatedFontFaceList.push(defaultFontFace);
    setFontFacesList(updatedFontFaceList);

    setTimeout(() => {
      window.scrollTo(0, 10000);
    }, 100);
  };

  if (fontDetailsLoading) {
    return (
      <Grid container justifyContent="center">
        <Grid item xs={12} md={7}>
          <Paper elevation={0} style={{ borderRadius: 30 }}>
            <FormSkeletonLoading />
          </Paper>
        </Grid>
      </Grid>
    );
  }

  const getFontSettingsLayout = () => {
    if (editingFont)
      return (
        <div>
          <Button variant="outlined" size="small" onClick={() => setEditingFont(false)} disabled={updatingFont}>
            Cancel
          </Button>
          <Tooltip title="Save Updates">
            <IconButton disabled={!updatedFontName || !updatedFallBackFonts} onClick={handleUpdateFont}>
              {updatingFont ? (
                <CircularProgress color="primary" size={20} />
              ) : (
                <SaveIcon color={!updatedFontName || !updatedFallBackFonts ? 'disabled' : 'primary'} />
              )}
            </IconButton>
          </Tooltip>
        </div>
      );
    if (updatingFont) return <CircularProgress color="primary" />;
    return (
      <>
        <Tooltip title="Edit Font">
          <IconButton onClick={() => setEditingFont(true)} style={{ height: '45px' }}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete Font">
          <IconButton onClick={handleDelete} data-testid="expansionPanelDeleteBtn">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </>
    );
  };
  return (
    <Grid container justifyContent="center" className={classes.container}>
      <Grid item xs={12}>
        <Paper style={{ padding: 20, borderRadius: 20 }} elevation={0}>
          <Grid container justifyContent="space-between" alignContent="center" alignItems="center">
            <Grid item>
              <Typography variant="h6">Font Settings</Typography>
            </Grid>
            <Grid item>{getFontSettingsLayout()}</Grid>
            <Grid container direction="column" style={{ margin: '20px 0 20px' }}>
              {!editingFont ? (
                <>
                  <Grid>
                    <Grid>
                      <Typography color="primary" variant="caption">
                        Font name
                      </Typography>
                    </Grid>
                    <Grid>
                      <Typography variant="subtitle1">{fontDetails && fontDetails.name}</Typography>
                    </Grid>
                  </Grid>

                  <Grid style={{ marginTop: '20px' }}>
                    <Grid>
                      <Typography color="primary" variant="caption">
                        Font Family
                      </Typography>
                    </Grid>
                    <Grid>
                      <Typography variant="subtitle1">{fontDetails && fontDetails.fallBackFonts}</Typography>
                    </Grid>
                  </Grid>
                </>
              ) : (
                <>
                  <TextField
                    label="Font name"
                    name="name"
                    onChange={handleUpdateFontName}
                    margin="normal"
                    variant="outlined"
                    value={updatedFontName}
                    autoComplete="off"
                    type="text"
                    multiline
                    rows={1}
                    rowsMax={2}
                    required
                    fullWidth
                    InputLabelProps={{
                      className: classes.InputLabelProps,
                    }}
                  />
                  <TextField
                    label="Font Family"
                    name="fallBackFonts"
                    placeholder="Your Font Name,Avenir,Helvetica,Arial,sans-serif"
                    onChange={handleUpdateFallBackFonts}
                    margin="normal"
                    variant="outlined"
                    value={updatedFallBackFonts}
                    autoComplete="off"
                    type="text"
                    multiline
                    rows={1}
                    rowsMax={2}
                    required
                    fullWidth
                    InputLabelProps={{
                      className: classes.InputLabelProps,
                    }}
                  />
                </>
              )}
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      <Grid item xs={12} style={{ marginTop: '30px' }}>
        <Paper style={{ padding: 20, borderRadius: 20 }} elevation={0}>
          <Grid container justifyContent="space-between" alignContent="center" alignItems="center">
            <Typography variant="h6">Font Faces</Typography>

            <Button color="primary" variant="contained" onClick={handleAddFontFace}>
              Add new
            </Button>
          </Grid>

          <Grid container direction="column" style={{ margin: '20px 0 20px' }}>
            {fontFacesList.length ? (
              fontFacesList.map((item, key) => (
                <FontFaceItem
                  key={key}
                  identifier={key}
                  fontFaceData={item}
                  currentTenant={currentTenant}
                  fontId={fontId}
                  fontFacesList={fontFacesList}
                  setFontFacesList={setFontFacesList}
                />
              ))
            ) : (
              <Typography
                variant="h6"
                style={{
                  textAlign: 'center',
                  fontSize: '16px',
                  fontWeight: 'normal',
                }}
              >
                No font-faces
              </Typography>
            )}
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default withStyles(SettingsFontsStyles)(FontEditorWrapper);
