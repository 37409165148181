import IconButton from '@material-ui/core/IconButton';
import EmailIcon from '@material-ui/icons/Email';
import React from 'react';
import { useDispatch } from 'react-redux';

import { showNotification } from '../Notifications/notificationSlice';

function LinkCell() {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(showNotification('Provider successfully notified!', 'success'));
  };

  return (
    <td>
      <IconButton color="inherit" style={{ cursor: 'pointer' }} onClick={handleClick}>
        <EmailIcon style={{ fontSize: 30 }} />
      </IconButton>
    </td>
  );
}

export default LinkCell;
