// redux
import { withStyles } from '@material-ui/core/styles';
import { orderBy } from '@progress/kendo-data-query';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// imports
import SettingsFontsStyles from '../../../assets/jss/components/SettingsFontsStyles';

// mui
import AppKendoTableWrapper from '../../Shared/Table/KendoTableWrapper';
import utils from '../../Shared/Utils/utils';
import { selectCreateFontLoading, selectTenantFonts, selectTenantFontsLoading } from '../selectors';
import { addNewFont, getAllFonts } from '../settingsSlice';

import FontFormDialog from './FontFormDialog';
import { columns } from './helpers/fontListMapper';

export function SettingsFonts(props) {
  const { currentTenant } = props;
  const tenantFonts = useSelector(selectTenantFonts);
  const tenantFontsLoading = useSelector(selectTenantFontsLoading);
  const createFontLoading = useSelector(selectCreateFontLoading);

  const [fonts, setFonts] = useState(tenantFonts);
  const [dialogOpen, setDialogOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (tenantFonts) {
      const orderTenantFonts = { ...tenantFonts };
      orderTenantFonts.items = orderBy(orderTenantFonts.items, [{ field: 'name', dir: 'asc' }]);
      setFonts(orderTenantFonts);
    }
  }, [tenantFonts]);

  // onRowClick function in table
  const handleTableClick = (e) => {
    navigate(`/${currentTenant}/settings/configuration/assets/fonts/details/${e.id}`);
  };

  const handleAddNewFont = async (name, fallBackFonts) => {
    await dispatch(addNewFont(currentTenant, name, fallBackFonts));
    closeDialog();
  };

  useEffect(() => {
    dispatch(getAllFonts(currentTenant));
  }, [currentTenant]);

  const buttons = [
    {
      title: 'Add New Font',
      buttonType: 'action',
      // action to perform when click action button
      action: () => setDialogOpen(true),
      variant: 'contained',
    },
  ];

  const handleTableUpdate = (pagesize, page, sort, direction) => {
    if (sort) {
      const updatingFonts = { ...fonts };
      updatingFonts.items = orderBy(updatingFonts.items, [{ field: sort, dir: direction }]);
      setFonts(updatingFonts);
    }
  };

  const closeDialog = () => {
    setDialogOpen(!dialogOpen);
  };

  if (!currentTenant) return <div />;

  return (
    <>
      <AppKendoTableWrapper
        data={{
          ...fonts,
          items: fonts?.items?.map((font) => ({
            ...font,
            createdOn: utils.formatDate(font.createdOn),
            currentTenant,
          })),
        }}
        showButtons
        tableButtons={buttons}
        columns={columns}
        onRowClick={handleTableClick}
        loading={tenantFontsLoading}
        initialDir="asc"
        initialSort="name"
        onTableUpdate={handleTableUpdate}
        disablePagination
        existingFontNames={tenantFonts && tenantFonts.items?.map((font) => font.name)}
      />
      {dialogOpen && (
        <FontFormDialog
          closeDialog={() => closeDialog()}
          onCancel={() => closeDialog()}
          createFontLoading={createFontLoading}
          handleAddNewFont={handleAddNewFont}
          existingFontNames={fonts && fonts.items?.map((font) => font.name)}
        />
      )}
    </>
  );
}

export default withStyles(SettingsFontsStyles)(SettingsFonts);
