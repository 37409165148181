// material-ui
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import { makeStyles } from '@material-ui/core/styles';
import AddAPhotoRoundedIcon from '@material-ui/icons/AddAPhotoRounded';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getUserFromToken } from '../Auth/helpers';
import { showNotification } from '../Notifications/notificationSlice';
import {
  selectOtherUser,
  selectOtherUserPicLoading,
  selectOtherUserProfilePic,
  selectProfilePicture,
  selectUserDetails,
} from '../Users/selectors';
import { getUserPic, uploadUserPic } from '../Users/usersSlice';
import { ProfilePicSkeletonLoader } from './ProfileSkeletonLoading';

function ProfilePicture({ isMobile = false }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [hover, handleHover] = useState(false);
  const user = getUserFromToken();
  const otherUser = useSelector(selectOtherUser);
  const userDetails = useSelector(selectUserDetails);
  const otherUserProfilePicLoading = useSelector(selectOtherUserPicLoading);

  const mimetypes = ['image/png', 'image/jpg', 'image/jpeg'];

  const { userId: userProfile } = useParams();
  const userBoolean = userProfile === user.email;

  useEffect(() => {
    if (!userBoolean) {
      if (!otherUser || otherUser !== userProfile) {
        dispatch(getUserPic(userProfile, userBoolean, userDetails._id));
      }
    }
  }, [otherUser, userBoolean, userProfile]);

  const onHover = () => {
    if (userBoolean) {
      handleHover(true);
    }
  };

  const onLeave = () => {
    if (userBoolean) {
      handleHover(false);
    }
  };

  const openFileUpload = () => {
    if (userBoolean) {
      const uploader = document.querySelector('input[type=file]');
      uploader.click();
      uploader.addEventListener('change', handleFiles, false);

      // eslint-disable-next-line no-inner-declarations
      function handleFiles() {
        // eslint-disable-next-line react/no-this-in-sfc
        const files = this.files[0];
        if (mimetypes.includes(files.type)) {
          dispatch(uploadUserPic(files));
        } else {
          dispatch(showNotification(`File type must be: ${mimetypes.join(', ')}`, 'error'));
        }
      }
    }
  };

  if (otherUserProfilePicLoading) return <ProfilePicSkeletonLoader />;

  return (
    <Card
      onMouseOver={onHover}
      onMouseLeave={onLeave}
      onClick={openFileUpload}
      className={classes.addPhotoIcon}
      id="uploadPhotoModal"
      elevation={0}
    >
      <MediaComponent isCurrentUser={userBoolean} isMobile={isMobile} hover={hover} mimetypes={mimetypes} />
    </Card>
  );
}

export default ProfilePicture;

function MediaComponent(props) {
  const { isCurrentUser, isMobile, hover, mimetypes } = props;
  const otherUserProfilePic = useSelector(selectOtherUserProfilePic);
  const profilePicture = useSelector(selectProfilePicture);
  let imgSrc;
  let cursor;
  let title = '';

  if (isCurrentUser) {
    imgSrc = profilePicture?.src || '';
    title = 'Upload New Photo';
  } else {
    imgSrc = otherUserProfilePic?.src || '';
    cursor = 'default';
  }

  return (
    <CardActionWrapper isCurrentUser={isCurrentUser} hover={hover} mimetypes={mimetypes}>
      <CardMedia
        component="img"
        alt="user profile avatar"
        src={imgSrc}
        title={title}
        height="150px"
        style={{ width: isMobile ? '100%' : 'inherit', cursor }}
      />
    </CardActionWrapper>
  );
}

function CardActionWrapper(props) {
  const { isCurrentUser, children, hover, mimetypes } = props;
  const classes = useStyles();

  return isCurrentUser ? (
    <CardActionArea>
      {children}
      {hover && (
        <div>
          <AddAPhotoRoundedIcon color="action" />
          <input type="file" id="uploadfile" accept={mimetypes.join(', ')} className={classes.modalUpload} />
        </div>
      )}
    </CardActionArea>
  ) : (
    children
  );
}

const useStyles = makeStyles((theme) => ({
  modalUpload: {
    visibility: 'hidden',
    position: 'absolute',
    top: '10px',
  },
  addPhotoIcon: {
    borderRadius: 16,
    '& svg': {
      visibility: 'hidden',
      position: 'absolute',
      bottom: '10px',
      right: '10px',
    },
    '&:hover': {
      '& svg': {
        visibility: 'visible',
      },
    },
    [theme.breakpoints.up('md')]: {
      margin: '-15px 10px 0 10px',
    },
  },
}));
