/* eslint-disable no-unused-expressions */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

// redux
// import { selectAccountDetails } from '../Account/selectors';
import { paths } from '../../constants';
import { showConfirmation } from '../Confirmation/confirmationSlice';
import { selectCurrentTenant } from '../Shared/selectors';
import ActionsCell from '../Shared/Table/ActionsCell';

import { actionTypes } from '../Shared/Table/helpers/mappings';
import { initialKendoState, statusMappings, userTypes } from './helpers/mappings';
import { selectCurrentUserPermissions } from './selectors';
import { getUser, getUsers, resendInvitation } from './usersSlice';

function UsersActionsCellWrapper(props) {
  const { dataItem } = props;
  const { userType } = useParams();

  // const account = useSelector(selectAccountDetails);
  const currentTenant = useSelector(selectCurrentTenant);
  const currentUser = useSelector(selectCurrentUserPermissions);
  // const userAccountPermission = useSelector(selectUserAccountPermission);
  const kendoState = initialKendoState;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getUserList = () => {
    dispatch(
      getUsers(
        currentTenant.id,
        kendoState ? kendoState.skip : 0,
        kendoState ? kendoState.take : 10,
        kendoState ? kendoState.search : '',
        kendoState ? kendoState.sort[0]?.field : 'created',
        kendoState ? kendoState.sort[0]?.dir?.toUpperCase() : 'DESC',
        kendoState ? kendoState.filter : null
      )
    );
  };

  // const handleChange = (type) => {
  //   // default message if only removing from tenant
  //   const message = `Are you sure you want to remove this user from ${currentTenant && currentTenant.title}?`;

  //   dispatch(showConfirmation('Confirm', message, type, currentTenant && currentTenant.id, dataItem));
  // };

  const handleChangeUserStatus = (type) => {
    const { status } = dataItem;

    // default message if only removing from tenant
    let message = `Are you sure you want to remove this user from ${currentTenant && currentTenant.title}?`;

    if (type === 'userStatusChange') {
      message = `Are you sure you want to ${
        status === statusMappings.DEACTIVATED.value ? 'activate' : 'deactivate'
      } this user`;
    }

    dispatch(
      showConfirmation('Confirm', message, type, currentTenant && currentTenant.id, dataItem, null, getUserList)
    );
  };

  const handleActionClick = (selection) => {
    const { email } = dataItem;

    if (selection === actionTypes.EDIT.key) {
      dispatch(getUser(email));
      navigate(paths.profileDetails(currentTenant.id, email));
    }
    //
    // if (selection === "remove") {
    //   handleChange("userRemoveTenant");
    // }

    if (selection === actionTypes.ENABLER.key) {
      handleChangeUserStatus('userStatusChange');
    }

    if (selection === actionTypes.INVITE.key) {
      // eslint-disable-next-line
      console.log('Send Invitation');
    }

    if (selection === actionTypes.REINVITE.key) {
      dispatch(resendInvitation(currentTenant, dataItem));
    }
  };

  const buildMenu = () => {
    const menuItems = [];

    if (userType === userTypes.ACTIVE) {
      menuItems.push(actionTypes.EDIT.key);
    }

    if (userType === userTypes.INVITED && currentUser?.userId !== dataItem?.email) {
      menuItems.push(actionTypes.REINVITE.key);
      // dataItem?.settings?.firstTimeLogin
      //   ? menuItems.push(actionTypes.REINVITE.key)
      //   : menuItems.push(actionTypes.INVITE.key);
    }

    if (currentUser?.userId !== dataItem?.email) {
      // if (account && account.accountType !== "SINGLE_TENANT")
      //   menuItems.push({
      //     type: "remove",
      //     label: "Remove from Tenant",
      //   });
      // //will be open for the future release
      // if (
      //   userAccountPermission &&
      //   userAccountPermission.permission === "ACCOUNT_ADMINISTRATOR"
      // )
      //   menuItems.push({
      //     type: "enabler",
      //     label: "Deactivate",
      //     enablerProp: "status",
      //   });
    }
    return menuItems;
  };

  return <ActionsCell dataItem={dataItem} menu={buildMenu()} handleActionClick={handleActionClick} />;
}

export default UsersActionsCellWrapper;
