// imports
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Input } from '@progress/kendo-react-inputs';
import React, { useState, useCallback } from 'react';

import { getUserFromToken } from '../../Auth/helpers';

function SubmitTestTemplateDialog(props) {
  const user = getUserFromToken();

  const { closeDialog, onSubmit } = props;
  const [currentValue, setCurrentValue] = useState(user && user.email);

  const handleCancel = () => {
    setCurrentValue('');
    closeDialog();
  };

  const onChange = useCallback((e) => {
    setCurrentValue(e.target.value);
  }, []);

  const handleSubmit = useCallback(() => {
    if (onSubmit) {
      onSubmit(currentValue);
    }
  }, [currentValue, onSubmit]);

  return (
    <Dialog title="Send Test Email Template" onClose={handleCancel} width={400} height={225}>
      <Input
        width="100%"
        value={currentValue}
        onChange={onChange}
        required
        label="User email"
        name="userId"
        autoComplete="off"
        style={{ width: '100%' }}
      />
      <DialogActionsBar>
        <button className="k-button" onClick={handleCancel}>
          Cancel
        </button>
        <button className="k-button" onClick={handleSubmit} disabled={!currentValue}>
          Submit
        </button>
      </DialogActionsBar>
    </Dialog>
  );
}

export default SubmitTestTemplateDialog;
