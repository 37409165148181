import { createSlice } from '@reduxjs/toolkit';

import api from '../../api';
import { showNotification } from '../Notifications/notificationSlice';
import { handleError } from '../Shared/Content/APIUtils';
import { setFormSubmitting } from '../Shared/Forms/formSlice';
import history from '../Shared/Redux/history';
import utils from '../Shared/Utils/utils';

import { testIdName } from './helpers/mappings';
import { getMock } from './mock';
import {
  labTestsSubmitted,
  labTestsSubmitting,
  setTenantLabTests,
  setTenantLabTestsFailure,
  setTenantLabTestsLoading,
} from './tenantLabTestsSlice';

const initialState = {};

export const labTestsSlice = createSlice({
  name: 'labTests',
  initialState,
  reducers: {
    setLabTestsLoading: (state, { payload }) => ({
      ...state,
      [payload]: {
        ...state[payload],
        loading: true,
        error: null,
      },
    }),
    setLabTests: (state, { payload }) => {
      if (payload.pageNumber) {
        return {
          ...state,
          [payload.testType]: {
            list: {
              ...payload.data,
              pageNumber: payload.pageNumber,
            },
            loading: false,
          },
        };
      }
      return {
        ...state,
        [payload.testType]: {
          list: payload.data,
          loading: false,
        },
      };
    },
    setLabTestsFailure: (state, { payload }) => ({
      ...state,
      [payload.testType]: {
        ...state[payload.testType],
        error: payload.error,
        loading: false,
      },
    }),
    setLabTestDetailsLoading: (state, { payload }) => ({
      ...state,
      [payload]: {
        ...state[payload],
        loadingDetails: true,
      },
    }),
    setLabTestDetails: (state, { payload }) => ({
      ...state,
      [payload.testType]: {
        ...state[payload.testType],
        loadingDetails: false,
        details: payload.data,
      },
    }),
    setLabTestDetailsFailure: (state, { payload }) => ({
      ...state,
      [payload.testType]: {
        ...state[payload.testType],
        loadingDetails: false,
        loadingError: payload.error,
      },
    }),
    setVendorsList: (state, { payload }) => ({
      ...state,
      vendors: {
        list: payload,
        loading: false,
      },
    }),
  },
});

export const {
  setLabTests,
  setLabTestsLoading,
  setLabTestsFailure,
  setLabTestDetails,
  setLabTestDetailsLoading,
  setLabTestDetailsFailure,
  setVendorsList,
} = labTestsSlice.actions;

export const getLabTests = (tenant, type) => async (dispatch) => {
  dispatch(setLabTestsLoading(type));

  setTimeout(() => {
    dispatch(setLabTests({ data: getMock[type], testType: type }));
  });
};

export const getLabTestsVendors = (type, endpoint) => async (dispatch) => {
  dispatch(setLabTestsLoading(type));

  const [result, error] = await api.getLabTestsVendorsRequest(endpoint);

  if (result) {
    dispatch(setLabTests({ data: result, testType: type }));
  }

  if (error && error.message) {
    dispatch(setLabTestsFailure({ testType: type, error: error.message }));
    dispatch(handleError(error));
  }
};

export const getPaginatedLabTests =
  (tenant, type, endpoint, pageNumber, pageSize, sort, direction, term, filter) => async (dispatch) => {
    dispatch(setLabTestsLoading(type));
    dispatch(setTenantLabTestsLoading(type));

    const payload = {
      pageNumber,
      pageSize,
      isPathogen: false,
      term: term || '',
      ...filter,
    };

    if (sort) {
      payload[sort] = true;
      payload.orderAsc = direction === 'asc';
    }

    const [masterResult, masterError] = await api.getPaginatedLabTestsMasterRequest(endpoint, payload);

    if (masterResult && masterResult.result) {
      dispatch(setLabTests({ data: masterResult.result, testType: type, pageNumber }));

      const [result, error] = await api.getPaginatedLabTestsRequest(tenant, type);

      if (result) {
        const labTestFiltered = masterResult.result.filter((i) => {
          const foundTest = result.find((item) => item[testIdName[type]] === i._id);
          return !!foundTest;
        });
        dispatch(setTenantLabTests({ data: labTestFiltered, testType: type }));
      }

      if (error && error.message) {
        dispatch(
          setTenantLabTestsFailure({
            error: error.message,
            testType: type,
          })
        );
        dispatch(handleError(error));
      }
    }

    if (masterError && masterError.message) {
      dispatch(setLabTestsFailure({ error: masterError.message, testType: type }));
      dispatch(handleError(masterError));
    }
  };

export const getLabTest = (tenant, type, endpoint, id) => async (dispatch) => {
  dispatch(setLabTestDetailsLoading(type));

  const [result, error] = await api.getLabTestRequest(endpoint, tenant, id);

  if (result) {
    dispatch(setLabTestDetails({ testType: type, data: result }));
  }

  if (error && error.message) {
    dispatch(setLabTestsFailure({ testType: type, error: error.message }));
    dispatch(handleError(error));
  }
};

export const newLabTest = (tenant, type, endpoint, data) => async (dispatch) => {
  dispatch(labTestsSubmitting(type));

  const [result, error] = await api.newLabTestRequest(endpoint, tenant, data);

  if (result) {
    dispatch(labTestsSubmitted(type));
    dispatch(showNotification('Successfully Created', 'success'));
    history.push(`/${tenant.id}/medical-center/${utils.camelToSnake(type)}`);
  }

  if (error && error.message) {
    dispatch(labTestsSubmitted(type));
    dispatch(setFormSubmitting(false));
    dispatch(handleError(error));
  }
};

export const updateLabTest = (tenant, type, endpoint, data) => async (dispatch) => {
  dispatch(labTestsSubmitting(type));

  const [result, error] = await api.updateLabTestRequest(endpoint, tenant, data);

  if (result) {
    dispatch(labTestsSubmitted(type));
    dispatch(showNotification('Successfully Created', 'success'));
    history.push(`/${tenant.id}/medical-center/${utils.camelToSnake(type)}`);
  }

  if (error && error.message) {
    dispatch(labTestsSubmitted(type));
    dispatch(setFormSubmitting(false));
    dispatch(handleError(error));
  }
};

export const getVendorsList = (type, notSetToStore) => async (dispatch) => {
  dispatch(setLabTestsLoading('vendors'));

  const [result, error] = await api.getVendorsListRequest();

  if (result) {
    if (notSetToStore) return result;

    dispatch(setVendorsList(result));

    return result;
  }

  if (error && error.message) {
    dispatch(setLabTestsFailure({ testType: type, error: error.message }));
    dispatch(handleError(error));
  }
};

export default labTestsSlice.reducer;
