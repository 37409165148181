import React from 'react';
import ContentLoader from 'react-content-loader';

export function OutcomeBuilderSkeletonLoading(props) {
  return (
    <ContentLoader speed={2} viewBox="0 0 1500 1450" backgroundColor="#f3f3f3" foregroundColor="#ecebeb" {...props}>
      <rect x="100" y="100" rx="3" ry="3" width="200" height="30" />

      <rect x="100" y="170" rx="3" ry="3" width="170" height="20" />
      <rect x="100" y="200" rx="3" ry="3" width="1000" height="40" />

      <rect x="400" y="260" rx="3" ry="3" width="275" height="160" />

      <rect x="100" y="440" rx="3" ry="3" width="70" height="20" />
      <rect x="100" y="470" rx="3" ry="3" width="1000" height="40" />

      <rect x="100" y="520" rx="3" ry="3" width="70" height="20" />
      <rect x="100" y="550" rx="3" ry="3" width="1000" height="40" />

      <rect x="100" y="610" rx="2" ry="2" width="70" height="20" />
      <rect x="100" y="640" rx="2" ry="2" width="1000" height="240" />
      {/* <rect x="10" y="10" rx="2" ry="2" width="1000" height="1450" /> */}
      <rect x="1150" y="30" rx="2" ry="2" width="300" height="700" />
    </ContentLoader>
  );
}

export function OutcomeBuilderSkeletonLoadingMobile(props) {
  return (
    <ContentLoader speed={2} viewBox="0 0 800 1550" backgroundColor="#f3f3f3" foregroundColor="#ecebeb" {...props}>
      <rect x="100" y="100" rx="3" ry="3" width="200" height="30" />

      <rect x="100" y="170" rx="3" ry="3" width="170" height="20" />
      <rect x="100" y="200" rx="3" ry="3" width="600" height="40" />

      <rect x="25 0" y="260" rx="3" ry="3" width="275" height="160" />

      <rect x="100" y="440" rx="3" ry="3" width="70" height="20" />
      <rect x="100" y="470" rx="3" ry="3" width="600" height="40" />

      <rect x="100" y="520" rx="3" ry="3" width="70" height="20" />
      <rect x="100" y="550" rx="3" ry="3" width="600" height="40" />

      <rect x="100" y="610" rx="2" ry="2" width="70" height="20" />
      <rect x="100" y="640" rx="2" ry="2" width="600" height="240" />
    </ContentLoader>
  );
}
