import Grid from '@material-ui/core/Grid';
import React from 'react';
import { useNavigate } from 'react-router-dom';

function LinkCell(props) {
  const { dataItem, propLabelName, redirectLink } = props;
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate(dataItem[redirectLink] || '');
  };

  return (
    <td>
      <Grid style={{ color: '#1976d2', cursor: 'pointer' }} onClick={handleRedirect}>
        {dataItem[propLabelName]}
      </Grid>
    </td>
  );
}

export default LinkCell;
