/* First View Simulator Selectors */
export const selectTestKitLoading = (state) => state.fvSimulator.testKitLoading;
export const selectConsultLoading = (state) => state.fvSimulator.consultLoading;
export const selectRxOrderLoading = (state) => state.fvSimulator.rxOrderLoading;
export const selectEmployeeBenefitLoading = (state) => state.fvSimulator.benefitLoading;
export const selectEmployeeBenefitData = (state) => state.fvSimulator.benefitData;
export const selectExternalIdLoading = (state) => state.fvSimulator.externalIdLoading;
export const selectExternalIdData = (state) => state.fvSimulator.externalIdData;
/* Shopify Simulator Selectors */
export const selectDoordashLoading = (state) => state.shopifySimulator.doordashLoading;
export const selectNetsuiteLoading = (state) => state.shopifySimulator.netsuiteLoading;
export const selectWelcomeKitLoading = (state) => state.shopifySimulator.welcomeKitLoading;
export const selectWelcomeKitData = (state) => state.shopifySimulator.welcomeKitData;
/* FV Data selectors */
export const selectFvSubsLoading = (state) => state.fvSubsSimulator.fvSubsLoading;
export const selectFvSubsData = (state) => state.fvSubsSimulator.fvSubsData;
export const selectFvSubsErr = (state) => state.fvSubsSimulator.error;