import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectDiagnosticReportInfo } from '../DiagnosticReport/selectors';
import { getEncounterData } from '../Encounters/encountersSlice';
import { encounterStatuses, memberStatesLabels } from '../Encounters/helpers/mapping';
import { selectEncounterDetails } from '../Encounters/selectors';
import { selectOrderDetails } from '../Orders/selectors';
import { getUser } from '../Users/usersSlice';
import { encounterJsonBuilder } from './helpers/encounterJsonBuilder';
import LabReportWrapper from './LabReportWrapper';
import MainEncounterData from './MainEncounterData';

function EncounterDetailsWrapper(props) {
  const { encounterId } = props;

  // S: User answred {a}, aswer {a}
  // O:
  // A:
  // P:
  // Time spend:

  const encounterData = useSelector(selectEncounterDetails);
  const orderDetails = useSelector(selectOrderDetails);
  const diagnosticReportInfo = useSelector(selectDiagnosticReportInfo);

  const [loading, setLoading] = useState(false);
  const [parsedEncounterData, setParsedEncounterData] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(2);

  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      setLoading(true);
      await dispatch(getEncounterData(encounterId));
      setLoading(false);
    })();
  }, [dispatch, encounterId]);

  useEffect(() => {
    if (encounterData) {
      const data = encounterJsonBuilder(encounterData);
      dispatch(getUser(encounterData.patientTest?.userId));

      setParsedEncounterData(data);

      getUserData(encounterData.memberID);

      // //get order details by orderId
      // const encounterIdTest = encounterData.patientTest.encounterId";
      // const userId = encounterData.patientTest.nationalHealthId";
      // const emailId = encounterData.patientTest.userId";

      // get order details by orderId
      // const encounterIdTest = 'debc0ebc650f4de9';
      // const userId = '0091000222703';
      // const emailId = 'shstestcase3@yopmail.com';

      // dispatch(getOrderDetails(encounterIdTest))
      //
      // diagnosticReportActions.getDiagnosticReport(
      //   userId,
      //   encounterIdTest,
      //   currentTenant.id,
      //   emailId
      // );
    }
    // eslint-disable-next-line
  }, [encounterData]);

  const getUserData = async () => {
    // console.log(1111111111, userId);
    // const [result] = await api.getPatientDataRequest(userId);
    // console.log(22222222222, result);
  };

  const handleChangeStatus = (e) => {
    setSelectedStatus(e.target.value);
  };

  if (loading || !parsedEncounterData) {
    return (
      <Grid container justifyContent="center" style={{ padding: 100 }}>
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <Paper
      elevation={0}
      style={{
        padding: 20,
        borderRadius: '20px',
      }}
    >
      <Grid container>
        <Grid item md={3}>
          <Typography style={{ fontWeight: 'bold', fontSize: 12 }}>ENCOUNTER #{encounterData?.encounterId}</Typography>
          <Typography style={{ fontWeight: 'bold', color: '#03a9f4' }}>INTAKE & ORDER</Typography>
        </Grid>
        <Grid item md={3}>
          <FormControl>
            <InputLabel id="demo-simple-select-label">Status</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedStatus}
              onChange={handleChangeStatus}
            >
              {Object.keys(encounterStatuses).map((status) => (
                <MenuItem value={encounterStatuses[status]}>{memberStatesLabels[status].label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      {/* Main Encounter Data */}
      <MainEncounterData encounterData={parsedEncounterData} />

      {/* /!*Lab Report*!/ */}
      <LabReportWrapper
        encounterData={parsedEncounterData}
        orderDetails={orderDetails}
        diagnosticReportInfo={diagnosticReportInfo}
      />
    </Paper>
  );
}

export default EncounterDetailsWrapper;
