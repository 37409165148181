import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog } from '@progress/kendo-react-dialogs';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CreateFormStyles from '../../assets/jss/components/CreateFormStyles';
import CreateFormWrapper from '../Shared/Forms/CreateFormWrapper';
import { selectCurrentTenant } from '../Shared/selectors';
import { createUserJsonBuilder } from './helpers/createUserJsonBuilder';
import { basicInvite } from './helpers/formHelper';
import { UserRoleId } from './helpers/mappings';
import { addUser, inviteUser, sendEmailInvitation } from './usersSlice';

function UserCreationDialog(props) {
  const { closeDialog, formData } = props;

  const classes = useStyles();

  const currentTenant = useSelector(selectCurrentTenant);
  const dispatch = useDispatch();

  // eslint-disable-next-line
  const [initialValues, setInitialValues] = useState({});

  // eslint-disable-next-line
  const guardianCheckBox = {
    type: 'toggle',
    name: 'isGuardianMode',
    label: 'User Requires Legal Guardian Consent',
    width: 12,
    show: false,
  };

  // handle form submission with appropriate json builder and endpoint
  const handleSubmit = async (formData) => {
    const res = await dispatch(inviteUser(currentTenant, formData?.email, formData?.role));

    if (!res) return;

    formData = {
      ...formData,
      tenantId: currentTenant.id,
      permission: UserRoleId[formData.role],
      accountId: currentTenant.accountId,
    };
    const data = createUserJsonBuilder(formData);

    // send invite only for role MEMBER
    if (UserRoleId[formData.role] === UserRoleId.DEFAULT) {
      data.permission = UserRoleId[formData.role];
      data.accountId = currentTenant.accountId;
      dispatch(sendEmailInvitation(currentTenant.id, data));
    } else {
      dispatch(addUser(currentTenant.id, data));
    }
    handleCancel();
  };

  const steps = [
    {
      data: [
        ...basicInvite,
        // guardianCheckBox
      ],
    },
  ];

  const handleCancel = () => {
    closeDialog();
  };

  return (
    <Dialog
      title="New User"
      onClose={handleCancel}
      width={700}
      // height={dynamic ? undefined : 400}
      maxWidth="xl"
      fullWidth
      className={classes.root}
    >
      <Grid container justifyContent="center" className={classes.dialogWrapper}>
        <CreateFormWrapper
          labelButtonSubmit="Add User"
          steps={steps}
          initialValues={initialValues}
          disabledSubmit={formData?.disabledSubmit || false}
          handleSubmit={handleSubmit}
          handleCancel={() => handleCancel()}
        />
      </Grid>
    </Dialog>
  );
}

const useStyles = makeStyles(CreateFormStyles);

export default UserCreationDialog;
