// imports

// mui
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import createDOMPurify from 'dompurify';
import React, { useState } from 'react';

import utils from '../../Shared/Utils/utils';

const DOMPurify = createDOMPurify(window);

export function UserSegmentPanelDetails(props) {
  const { data, allOutcomes } = props;
  const [retestView, setRetestView] = useState('');

  const handleRetestExpand = (outcome) => {
    if (retestView === outcome) {
      setRetestView('');
    } else {
      setRetestView(outcome);
    }
  };

  const createTextView = (outcome) => (
    <Grid item xs={12}>
      {outcome.customOutcomeMessage && (
        <Card elevation={0} style={{ backgroundColor: '#F3F3F3' }}>
          <CardHeader
            title={
              <Typography variant="subtitle1" color="primary">
                Custom Outcome Message
              </Typography>
            }
          />
          <CardContent>
            <span
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(outcome.customOutcomeMessage),
              }}
            />
          </CardContent>
        </Card>
      )}
    </Grid>
  );

  const createRetestView = (outcome) => (
    <Grid item xs={12}>
      <Card elevation={0} style={{ backgroundColor: '#F3F3F3' }}>
        <CardHeader
          title={
            <Typography variant="subtitle1" color="primary">
              Retest Details
            </Typography>
          }
        />
        <CardContent>
          <Grid container>
            <Grid item xs={5}>
              <Typography color="textSecondary" gutterBottom>
                Number of days until retest if negative
              </Typography>
            </Grid>
            <Grid item>
              <Typography color="textSecondary" gutterBottom>
                {outcome.retestIfNegative}
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={5}>
              <Typography color="textSecondary" gutterBottom>
                Number of days until retest if positive
              </Typography>
            </Grid>
            <Grid item>
              <Typography color="textSecondary" gutterBottom>
                {outcome.retestIfPositive}
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={5}>
              <Typography color="textSecondary" gutterBottom>
                Number of days to sideline if negative
              </Typography>
            </Grid>
            <Grid item>
              <Typography color="textSecondary" gutterBottom>
                {outcome.sidelineIfNegative}
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={5}>
              <Typography color="textSecondary" gutterBottom>
                Number of days to sideline if positive
              </Typography>
            </Grid>
            <Grid item>
              <Typography color="textSecondary" gutterBottom>
                {outcome.sidelineIfPositive}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );

  const getTranslation = (outcomeName) => {
    if (outcomeName?.title?.translations?.eng) {
      if (outcomeName.title.translations.eng.includes(' ')) {
        return utils.removeUnderscoresTitleCase(outcomeName.title.translations.eng);
      }
      return utils.capitalizeString(outcomeName.title.translations.eng);
    }
    return null;
  };

  const getVendorKey = (vendorKey) => {
    if (vendorKey) {
      if (vendorKey === 'LABCORP') return 'LabCorp';
      return utils.removeUnderscoresTitleCase(vendorKey);
    }
    return '---';
  };

  const createSegmentData = () => {
    const byOutcomeObject = data && data.testSettingByOutcome;
    const outcomes = Object.keys(byOutcomeObject);
    let outcomeTest;
    const outcomesArray = [];
    if (outcomes.length > 0) {
      for (let i = 0; i < outcomes.length; i++) {
        const outcomeName = allOutcomes && allOutcomes.filter((outcome) => outcome.id === outcomes[i])[0];

        outcomeTest = (
          <div>
            <Grid container alignItems="center" spacing={2} style={{ padding: 10 }} key={`grid-${i}`}>
              <Grid item xs={4}>
                {getTranslation(outcomeName)}
              </Grid>
              <Grid item xs={3}>
                {getVendorKey(byOutcomeObject[outcomes[i]].vendorKey)}
              </Grid>
              <Grid item xs={4}>
                {byOutcomeObject[outcomes[i]].vendorTestName || '---'}
              </Grid>
              <Grid item xs={1}>
                {(byOutcomeObject[outcomes[i]].vendorTestName || byOutcomeObject[outcomes[i]].customOutcomeMessage) && (
                  <Tooltip title="View Details">
                    <IconButton size="small" onClick={() => handleRetestExpand(outcomes[i])}>
                      <MoreHorizIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </Grid>
              {retestView === outcomes[i] && (
                <Grid container>
                  {byOutcomeObject[outcomes[i]].customOutcomeMessage && createTextView(byOutcomeObject[outcomes[i]])}
                  {byOutcomeObject[outcomes[i]].vendorTestName && createRetestView(byOutcomeObject[outcomes[i]])}
                </Grid>
              )}
            </Grid>
            <Divider />
          </div>
        );
        outcomesArray.push(outcomeTest);
      }
    } else {
      outcomesArray.push(
        <Grid container justifyContent="center" style={{ marginTop: 30 }}>
          <Grid item>
            <Typography variant="body2" color="textSecondary">
              No Outcomes Configured
            </Typography>
          </Grid>
        </Grid>
      );
    }

    return outcomesArray;
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="subtitle1" color="primary">
              Outcome
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle1" color="primary">
              Vendor
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle1" color="primary">
              Test
            </Typography>
          </Grid>
          <Grid item xs={1} />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {createSegmentData()}
      </Grid>
    </Grid>
  );
}

export default UserSegmentPanelDetails;
