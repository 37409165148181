import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// components and helpers
import AppTabbedPageWrapper from '../Shared/Pages/TabbedPageWrapper';

import { selectCurrentTenant } from '../Shared/selectors';
import CustomReportListWrapper from './CustomReportListWrapper';
import ReportingListWrapper from './ReportingListWrapper';

function ReportingPageWrapper(props) {
  const { type } = props;
  const currentTenant = useSelector(selectCurrentTenant);
  const navigate = useNavigate();
  const tabs = [
    {
      label: 'Reports',
      type: 'reports',
      url: 'reports/',
      content: <ReportingListWrapper currentTenant={currentTenant} type={type} />,
    },
    {
      label: 'Custom Report',
      type: 'custom',
      url: 'reports/custom-report',
      content: <CustomReportListWrapper type={type} />,
    },
  ];

  const handleTabChange = (tabDetails) => {
    navigate(`/${currentTenant && currentTenant.id}/${tabDetails.url}`);
  };
  if (!currentTenant) return <div />;

  return <AppTabbedPageWrapper tabs={tabs} openTab={tabs.find((t) => t.type === type)} onTabChange={handleTabChange} />;
}

export default ReportingPageWrapper;
