import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import CreateFormStyles from '../../../assets/jss/components/CreateFormStyles';
import { showConfirmation } from '../../Confirmation/confirmationSlice';
import { getMasterLabTestsTenant } from '../../Orders/ordersSlice';
import { selectOrderableTests } from '../../Orders/selectors';
import { addFormData, initForm, resetForm } from '../../Shared/Forms/formSlice';
import { selectFormData } from '../../Shared/Forms/selectors';
import { selectCurrentTenantId } from '../../Shared/selectors';
import { initialSorting } from '../helpers/virtualConsultListMapper';
import AnnotationMenu from '../Reviewer/AnnotationMenu/AnnotationMenu';
import { selectAnnotationList } from '../Reviewer/selectors';
import { selectVirtualConsult } from '../selectors';

import QuestionPreview from '../vcb-package/QuestionPreview';
import { createQuestion, getContent, getContentById, getTags, updateQuestion } from '../virtualConsultSlice';
import { getFieldName } from './helpers/formHelper';
import { languageOptions } from './helpers/languageOptions';
import { handleFormatJson, handleOptionsListBuild } from './helpers/questionJsonBuilder';

import { questionLoadJsonBuilder } from './helpers/questionLoadJsonBuilder';
import { QuestionBuilderSkeletonLoader } from './QuestionBuilderSkeletonLoader';
import QuestionSettings from './QuestionSettings';

function QuestionBuilderWrapper(props) {
  const { actions, editQuestion, handleQuestionUpdate, handleCancel, selectedQuestions } = props;

  const annotationList = useSelector(selectAnnotationList);
  const formData = useSelector(selectFormData);
  const labTestOrderables = useSelector(selectOrderableTests);
  const consult = useSelector(selectVirtualConsult);
  const currentTenant = useSelector(selectCurrentTenantId);

  const [details, setDetails] = useState(false);
  const [type, setType] = useState('');
  const [optionsList, setOptionsList] = useState([]);
  const [form, setForm] = useState(null);
  const [questionList, setQuestionList] = useState();
  const [questionsWithAnnotations, setQuestionsWithAnnotations] = useState([]);
  const [activeLabTests, setActiveLabTests] = useState(null);

  const { id } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetForm());
    };
  }, []);

  useEffect(() => {
    dispatch(getTags(currentTenant, ''));
    window.scrollTo(0, 0);
  }, [actions, currentTenant]);

  useEffect(() => {
    if (annotationList && annotationList.length) {
      const questionIds = annotationList.filter((item) => !item.done).map((item) => item.questionId);
      setQuestionsWithAnnotations(questionIds);
    }
    // eslint-disable-next-line
  }, [annotationList]);

  // Initial load
  useEffect(() => {
    const url = window.location.pathname;
    const type = url.split('/')[3];
    if (url.includes('details')) {
      if (type === 'questionnaires') {
        setQuestionList(selectedQuestions);
      } else {
        dispatch(getContentById(currentTenant, 'questions', id));
      }
      setDetails(true);
    }
    setType(type);
    // Load full question list for Combo Node
    if (type === 'questions') {
      dispatch(
        getContent(
          currentTenant,
          'questions',
          '',
          initialSorting[type].field,
          initialSorting[type].dir,
          0,
          1000,
          [],
          true
        )
      ).then((res) => setQuestionList(res.content));
    }
    dispatch(getMasterLabTestsTenant('orderableTests', currentTenant));
    // eslint-disable-next-line
  }, [currentTenant, actions, editQuestion]);

  const handleChange = () => {
    /* eslint-disable-next-line no-console */
    console.log('Changing!');
  };

  const handleChangeWithParams = () => {
    /* eslint-disable-next-line no-console */
    console.log('Changing with params!');
  };

  const initializeForm = useCallback(
    (n) => {
      const nU = JSON.parse(JSON.stringify(n));
      // deconstruct question medical code data
      if (nU.coding && nU.coding.system) {
        const key = getFieldName(nU.coding.system);
        nU.system = nU.coding.system;
        nU.code = nU.coding.code;
        nU.codeDescription = { [key]: nU.coding.description };
      }
      // deconstruct options medical code data
      if (nU.options && nU.options.length > 0 && nU.type !== 'scale') {
        for (let i = 0; i < nU.options.length; i++) {
          const key = nU.options[i].coding && getFieldName(nU.options[i].coding.system);
          nU.options[i].system = nU.options[i].coding && nU.options[i].coding.system;
          nU.options[i].code = nU.options[i].coding && nU.options[i].coding.code;
          nU.options[i].codeDescription = nU.options[i].coding && {
            [key]: nU.options[i].coding.description,
          };
        }
      }

      // Set "Before" (-1) or "After" (1) based on min/max value
      if (nU.type === 'date' && typeof nU.min === 'number' && !nU.minBeforeAfter) {
        nU.minBeforeAfter = nU.min <= 0 ? -1 : 1;
        nU.min = Math.abs(nU.min);
      }
      if (nU.type === 'date' && typeof nU.max === 'number' && !nU.maxBeforeAfter) {
        nU.maxBeforeAfter = nU.max <= 0 ? -1 : 1;
        nU.max = Math.abs(nU.max);
      }

      // deconstruct scale options
      if (nU.options && nU.options.length > 0 && nU.type === 'scale') {
        nU.scaleMin = Math.min.apply(null, nU.options);
        nU.scaleMax = Math.max.apply(null, nU.options);
      }

      // deconstruct auto answer data
      if (nU.autoAnswerOption && nU.autoAnswerOption.type !== 'now-between-dates') {
        nU.autoAnswerType = nU.autoAnswerOption.type;
      } else if (nU.autoAnswerOption && nU.autoAnswerOption.type === 'now-between-dates') {
        nU.start = moment(`${nU.autoAnswerOption.start}-${moment().year()}`).format('MM-DD-YYYY');
        nU.end = moment(`${nU.autoAnswerOption.end}-${moment().year()}`).format('MM-DD-YYYY');
        nU.autoAnswerType = nU.autoAnswerOption.type;
      }

      // Deconstruct password complexity
      if (nU.complexity) {
        if (nU.complexity.includes('d')) {
          nU.numbers = true;
        }
        if (nU.complexity.includes('A-Z')) {
          nU.capitalLetters = true;
        }
        if (nU.complexity.includes('W')) {
          nU.characters = true;
        }
        nU.minLength = parseInt(nU.complexity.replace(/\D/g, ''));
      }

      // Deconstruct labOrder fields
      if (nU.type === 'labOrder' && nU.testList) {
        nU.testList = nU.testList.map((item) => {
          const foundTest = activeLabTests.find(
            (test) => test.labTestOrderableId === item.orderableTestId || test.labTestPanelId === item.orderableTestId
          );
          if (foundTest) {
            item.test = { ...item };
            item.test.orderableTestList = item.test.panel && foundTest.labOrderableTests;
          } else {
            item.test = null;
          }
          return item;
        });
      }

      // Deconstruct translations
      if (nU.name && nU.name.translations) {
        const selectedLanguages = Object.keys(nU.name.translations);
        nU.languageChoices = languageOptions.filter((lang) => selectedLanguages.includes(lang.split(' = ')[0]));
        nU.languageChoices.forEach((lang) => {
          const languageCode = lang.split(' = ')[0];
          nU[`name-${languageCode}`] = nU.name.translations[languageCode];
          nU[`nameValid-${languageCode}`] = nU.name.validatedLanguages.includes(languageCode);
          nU[`description-${languageCode}`] = nU.description.translations[languageCode];
          nU[`descriptionValid-${languageCode}`] = nU.description.validatedLanguages.includes(languageCode);
          nU[`help-${languageCode}`] = nU.help.translations[languageCode];
          nU[`helpValid-${languageCode}`] = nU.help.validatedLanguages.includes(languageCode);
          nU.options =
            nU.options && nU.type !== 'scale'
              ? nU.options.map((option) => ({
                  ...option,
                  [`value-${languageCode}`]: option.value.translations[languageCode],
                  [`valueValid-${languageCode}`]: option.value.validatedLanguages.includes(languageCode),
                  [`instructionText-${languageCode}`]: option.instructionText.translations[languageCode],
                  [`instructionTextValid-${languageCode}`]:
                    option.instructionText.validatedLanguages.includes(languageCode),
                }))
              : nU.options;
          nU.fields =
            nU.fields &&
            nU.fields.map((field) => ({
              ...field,
              [`fieldName-${languageCode}`]: field.name.translations[languageCode],
            }));
        });
      }

      // Set default blanks to English value
      nU.name = nU.name && nU.name.translations ? nU.name.translations.eng : nU.name;
      nU.description = nU.description && nU.description.translations ? nU.description.translations.eng : nU.description;
      nU.help = nU.help && nU.help.translations ? nU.help.translations.eng : nU.help;
      nU.options =
        nU.options && nU.type !== 'scale'
          ? nU.options.map((option) => ({
              ...option,
              value: option.value && option.value.translations ? option.value.translations.eng : option.value,
              instructionText:
                option.instructionText && option.instructionText.translations
                  ? option.instructionText.translations.eng
                  : option.instructionText,
            }))
          : nU.options;
      nU.fields =
        nU.fields &&
        nU.fields.map((field) => ({
          ...field,
          'fieldName-eng': field.name && field.name.translations ? field.name.translations.eng : field.name,
        }));

      nU.reporterTarget = nU?.report?.target || '';
      nU.reporterFormat = nU?.report?.text || '';

      dispatch(initForm(nU));
    },
    [languageOptions, activeLabTests]
  );

  // Set orderable lab tests once loaded from API
  useEffect(() => {
    if (labTestOrderables && labTestOrderables.list && labTestOrderables.list.length > 0) {
      const activeTests = labTestOrderables.list;
      setActiveLabTests(activeTests);
    }
  }, [labTestOrderables]);

  // Initialize new question form with default status Active
  useEffect(() => {
    if (!details) {
      initializeForm({ status: 'ACTIVE' });
    }
  }, [details, initializeForm]);

  // Initialize question form on edit
  useEffect(() => {
    if (details && type === 'questions' && consult.question) {
      initializeForm(consult.question);
    } else if (type === 'questionnaires') {
      initializeForm(editQuestion);
    }
  }, [consult, type, initializeForm, editQuestion]);

  // get options from nested list to display preview
  useEffect(() => {
    const options = handleOptionsListBuild(formData);
    setOptionsList(options);
  }, [formData]);

  // question json builder for preview on load and change
  useEffect(() => {
    if (formData.id !== id) return;

    if (formData.type) {
      let formBuild;
      if (formData.type === 'scale') {
        formBuild = questionLoadJsonBuilder(formData, details, optionsList);
      } else {
        formBuild = questionLoadJsonBuilder(formData, details);
      }
      setForm(formBuild);
    }
  }, [formData, details, optionsList]);

  // reset auto answer type value on question type change
  useEffect(() => {
    if (!form) return;

    if (formData.type !== form.type) {
      dispatch(addFormData('autoAnswerType', null));
    }
  }, [form?.type, formData]);

  // Handle 'None' or 'All' option in multiple choice questions
  useEffect(() => {
    if (!formData.options) return;
    const formOptions = [...formData.options];
    const foundOptions = {
      none: formOptions.findIndex((opt) => opt.none),
      all: formOptions.findIndex((opt) => opt.all),
    };
    const noneToggled = !!(
      (formData.containsNone && foundOptions.none === -1) ||
      (!formData.containsNone && foundOptions.none !== -1)
    );
    const allToggled = !!(
      (formData.containsAll && foundOptions.all === -1) ||
      (!formData.containsAll && foundOptions.all !== -1)
    );
    const getOptions = () => {
      if (noneToggled) return 'none';
      if (allToggled) return 'all';
      return null;
    };
    const changedToggle = getOptions();

    if (changedToggle) {
      const newValue = changedToggle === 'none' ? 'None' : 'All';
      // Create a new option if the toggle was switched to true
      const newOption = foundOptions[changedToggle] === -1 && {
        value: `${newValue} of the above`,
        coding: {
          system: null,
          code: null,
          description: null,
        },
        none: noneToggled,
        all: allToggled,
      };
      if (!newOption) {
        // Toggle turned off; remove option
        formOptions.splice(foundOptions[changedToggle], 1);
      } else {
        // Put new option last, unless it's 'All of the above' and 'containsNone' is also present
        const secondToLast = changedToggle === 'all' && formData.containsNone;
        const insertPoint = secondToLast ? formOptions.length - 1 : formOptions.length;
        formOptions.splice(insertPoint, 0, newOption);
      }
      dispatch(addFormData('options', formOptions));
    }
  }, [formData]);

  // Turn off multiple choice All of the Above settings if MultiSelect is toggled off
  useEffect(() => {
    if (!formData.multiSelect && formData.containsAll) {
      dispatch(addFormData('containsAll', false));
    }
  }, [formData?.multiSelect, formData.containsAll]);

  const handleSubmit = () => {
    // Validate custom RegEx fields
    const data = handleFormatJson(formData, optionsList);
    if (data.customValidation) {
      try {
        // eslint-disable-next-line no-new
        new RegExp(data.customValidation);
      } catch {
        return;
      }
    }
    if (details) {
      dispatch(updateQuestion(currentTenant, data, id));
    } else {
      dispatch(createQuestion(currentTenant, data));
    }
  };

  const handleDelete = () => {
    dispatch(
      showConfirmation(
        'Confirm',
        'Are you sure you want to delete this question?',
        'questionDelete',
        currentTenant,
        formData.id
      )
    );
  };

  if (!currentTenant) return <div />;

  if (details && (!questionList || !activeLabTests || !formData.id)) {
    return <QuestionBuilderSkeletonLoader />;
  }

  return (
    <Grid container spacing={6}>
      <Grid item xs={12} md={8}>
        {Object.keys(formData).length === 0 ? null : (
          <QuestionSettings
            formData={{ ...formData }}
            onSubmit={handleQuestionUpdate || handleSubmit}
            handleCancel={handleCancel}
            onDelete={handleDelete}
            details={details}
            type={type}
            tags={consult.tags}
            questionList={questionList}
            activeLabTests={activeLabTests}
          />
        )}
      </Grid>
      <Grid item xs={12} md={4}>
        <QuestionPreview
          formData={form}
          handleChange={handleChange}
          handleChangeWithParams={handleChangeWithParams}
          handleChangeComment={handleChangeWithParams}
          questionnaireCommentEnabled
          previewForBuilder
          selectedLanguage={(formData.showTranslation && formData.workingLanguage) || 'eng'}
          values={{}}
          currentTenant={currentTenant}
          questionList={questionList}
          fromQuestionStore={type === 'questions'}
        />
      </Grid>
      {questionsWithAnnotations && questionsWithAnnotations.indexOf(editQuestion?.id) !== -1 && (
        <AnnotationMenu questionId={formData?.id} />
      )}
    </Grid>
  );
}

export default withStyles(CreateFormStyles)(QuestionBuilderWrapper);
