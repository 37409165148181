import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import SettingsFontsStyles from '../../../assets/jss/components/SettingsFontsStyles';
import { showNotification } from '../../Notifications/notificationSlice';
import { deleteFontFace, updateFontFace, uploadFontFaceFile } from '../settingsSlice';

import { weightMappings, styleMappings } from './helpers/mappings';

function FontFaceItem(props) {
  const { currentTenant, classes, identifier, fontId, fontFaceData, fontFacesList, setFontFacesList } = props;

  const fileFormats = ['.otf', '.ttf', '.woff'];
  const [selectedWeight, setSelectedWeight] = useState(fontFaceData.weight);
  const [selectedStyle, setSelectedStyle] = useState(fontFaceData.style);
  const [editingKey, setEditingKey] = useState('');
  const [updatingItemLoading, setUpdatingItemLoading] = useState(false);
  const [uploadFileLoading, setUploadFileLoading] = useState(false);
  const hiddenFileInputRef = useRef();
  const dispatch = useDispatch();

  const handleChangeWeight = (e) => {
    setSelectedWeight(e.target.value);
    setEditingKey(identifier);
  };

  const handleChangeStyle = (e) => {
    setSelectedStyle(e.target.value);
    setEditingKey(identifier);
  };

  const handleUpdateFontFace = async () => {
    setUpdatingItemLoading(true);
    const updateRes = await dispatch(
      updateFontFace(currentTenant.id, fontId, fontFaceData && fontFaceData.fontFaceId, selectedWeight, selectedStyle)
    );
    setUpdatingItemLoading(false);

    if (!updateRes) return false;
    setEditingKey('');
  };

  const handleDeleteFontFace = async () => {
    if (fontFaceData && fontFaceData.fontFaceId) {
      const deleteStatus = await dispatch(deleteFontFace(currentTenant.id, fontId, fontFaceData.fontFaceId));
      if (!deleteStatus) return;
    }

    const updatedFontFacesList = fontFacesList.slice();
    delete updatedFontFacesList[identifier];
    setFontFacesList(updatedFontFacesList);
  };

  const handleUploadFileInput = async (e) => {
    const { name } = e.target.files[0];
    const lastDot = name.lastIndexOf('.');
    const uploadedFileFormat = `.${name.substring(lastDot + 1)}`;

    if (!fileFormats.includes(uploadedFileFormat)) {
      return dispatch(showNotification(`Allowed file formats ${fileFormats.join(', ')}`, 'error'));
    }

    setUploadFileLoading(true);
    await dispatch(
      uploadFontFaceFile(currentTenant.id, fontId, fontFaceData && fontFaceData.fontFaceId, e.target.files[0])
    );
    setUploadFileLoading(false);
  };

  return (
    <Card style={{ width: '100%', marginBottom: '30px' }}>
      <CardContent>
        <Grid container className={classes.fontFaceBox}>
          <Grid item xs={12}>
            <Typography variant="h4" style={{ marginBottom: '25px' }}>
              font-face {identifier + 1}
            </Typography>
          </Grid>
          <Grid item xs={11} md={6} style={{ paddingRight: '2.25vw' }}>
            <Typography variant="h4" className={classes.fontFaceSettingsLabel}>
              font-weight
            </Typography>
            <DropDownList
              name="font-weight"
              data={weightMappings}
              onChange={(e) => handleChangeWeight(e)}
              required
              value={selectedWeight}
              style={{ width: '100%' }}
            />
          </Grid>

          <Grid item xs={11} md={6} style={{ paddingRight: '2.25vw' }}>
            <Typography variant="h4" className={classes.fontFaceSettingsLabel}>
              font-style
            </Typography>
            <DropDownList
              name="font-style"
              data={styleMappings}
              onChange={(e) => handleChangeStyle(e)}
              required
              value={selectedStyle}
              style={{ width: '100%' }}
            />
          </Grid>
        </Grid>

        <Grid
          container
          justifyContent={fontFaceData && fontFaceData.fontFaceId ? 'space-between' : 'flex-end'}
          alignItems="center"
          style={{
            marginTop: '10px',
          }}
        >
          {fontFaceData && fontFaceData.fontFaceId ? (
            <Grid style={{ position: 'relative' }}>
              <Grid container alignItems="center">
                <input
                  type="file"
                  ref={hiddenFileInputRef}
                  onChange={handleUploadFileInput}
                  style={{ display: 'none' }}
                  accept={fileFormats.join(', ')}
                />
                <Button
                  className={classes.button}
                  variant="contained"
                  disableElevation
                  color="primary"
                  onClick={() => hiddenFileInputRef.current.click()}
                  disabled={uploadFileLoading}
                >
                  {uploadFileLoading ? (
                    <Grid className={classes.loadingSaveFontWrapper}>
                      <CircularProgress size={28} thickness={6} />
                    </Grid>
                  ) : null}
                  {fontFaceData && fontFaceData.originalFileName && fontFaceData.fileUrl ? 'Upload new' : 'Browse'}
                </Button>

                <Typography variant="h4" className={classes.uploadedFileName}>
                  {fontFaceData && fontFaceData.originalFileName}
                </Typography>
              </Grid>

              <Typography variant="h4" className={classes.allowFormatFiles}>
                Allowed file formats {fileFormats.join(', ')}
              </Typography>
            </Grid>
          ) : null}

          <Grid item style={{ position: 'relative' }}>
            {!fontFaceData || !fontFaceData.fontFaceId || editingKey === identifier ? (
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setEditingKey(identifier);
                  handleUpdateFontFace();
                }}
                className={classes.button}
                disabled={!selectedWeight || !selectedStyle || (editingKey === identifier && updatingItemLoading)}
              >
                {editingKey === identifier && updatingItemLoading ? (
                  <Grid className={classes.loadingSaveFontWrapper}>
                    <CircularProgress size={28} thickness={6} />
                  </Grid>
                ) : null}

                {editingKey === identifier && fontFaceData && fontFaceData.fontFaceId ? 'Update' : 'Save'}
              </Button>
            ) : null}

            <IconButton
              style={{ marginRight: 5 }}
              onClick={() => handleDeleteFontFace()}
              data-testid="expansionPanelDeleteBtn"
            >
              <DeleteIcon />
            </IconButton>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default withStyles(SettingsFontsStyles)(FontFaceItem);
