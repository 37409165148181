import utils from '../../Shared/Utils/utils';

export const createClinicalObjBuilder = (conditionObj) => ({
  id: conditionObj.id,
  conditionCode: {
    codeValue: conditionObj.conditionCode,
    system: conditionObj.conditionSystem,
    display: conditionObj.conditionName,
  },
  category: {
    display: conditionObj.categoryName,
    codeValue: conditionObj.categoryCode,
    system: conditionObj.categorySystem,
  },
  clinicalStatus: conditionObj.clinicalStatus,
  verificationstatus: conditionObj.verificationStatus,
  notes: conditionObj.notes,
  createdBy: conditionObj.createdAt,
  createdAt: conditionObj.createdBy,
  onsetDate: conditionObj.onsetDate,
  dateRecorded: conditionObj.dateRecorded,
  resolutionDate: conditionObj.resolutionDate,
  severity: conditionObj.severity,
  bodySite: {
    display: conditionObj.bodySiteName,
    codeValue: conditionObj.bodySiteCode,
    system: conditionObj.bodySiteSystem,
  },
  operationType: conditionObj.operationType,
  rcopiaId: conditionObj.rcopiaId,
});

export const convertSelectedConditionObj = (condition) => ({
  id: condition.id,
  conditionName: condition.conditionCode ? condition.conditionCode.display : '',
  conditionCode: condition.conditionCode ? condition.conditionCode.codeValue : '',
  conditionSystem: condition.conditionCode ? condition.conditionCode.system : '',
  categoryName: condition.category ? condition.category.display : '',
  categoryCode: condition.category ? condition.category.codeValue : '',
  categorySystem: condition.category ? condition.category.system : '',
  clinicalStatus: condition.clinicalStatus,
  verificationStatus: condition.verificationstatus,
  createdBy: condition.createdBy,
  createdAt: condition.createdAt,
  dateRecorded: condition.dateRecorded,
  onsetDate: condition.onsetDate,
  resolutionDate: condition.resolutionDate,
  severity: condition.severity,
  bodySiteName: condition.bodySite ? condition.bodySite.display : '',
  bodySiteCode: condition.bodySite ? condition.bodySite.codeValue : '',
  bodySiteSystem: condition.bodySite ? condition.bodySite.system : '',
  operationType: condition.operationType,
  notes: condition.notes,
  rcopiaId: condition.rcopiaId,
});

export const convertImmunizationObjToImmunization = (immunizationObj) => ({
  id: immunizationObj.id,
  vaccinationCode: [
    {
      display: immunizationObj.vaccination.cvX_Short_Description,
      codeValue: immunizationObj.vaccination.cvX_Code.toString(),
      system: 'http://hl7.org/fhir/sid/cvx',
    },
    {
      display: immunizationObj.vaccination.cpT_description,
      codeValue: immunizationObj.vaccination.cpT_Code.toString(),
      system: 'http://www.ama-assn.org/go/cpt',
    },
  ],
  status: immunizationObj.status,

  manufacturer: immunizationObj?.manufacturer?.manufacturer_id
    ? {
        id: immunizationObj?.manufacturer?.manufacturer_id || '',
        name: immunizationObj?.manufacturer?.manufacturer_name || '',
        code: immunizationObj?.manufacturer?.mvX_CODE || '',
      }
    : null,
  administeredDate: immunizationObj.startDate ? utils.formatDateNoLocale(immunizationObj.startDate) : null,
  route: {
    display: immunizationObj.route.display,
    codeValue: immunizationObj.route.code,
    system: immunizationObj.route.system,
  },
  bodySite: {
    display: immunizationObj.site.display,
    codeValue: immunizationObj.site.code,
    system: immunizationObj.site.system,
  },
  ExpiryDate: immunizationObj.expiryDate ? utils.formatDateNoLocale(immunizationObj.expiryDate) : null,
  LotNumber: immunizationObj.lotNumber,
  doseQuantity: immunizationObj.doseQuantity,
  wasGiven: immunizationObj.wasGiven,
  isPrimarySource: immunizationObj.isPrimary,
  Notes: [
    {
      message: immunizationObj.notes,
    },
  ],
  explanation: {
    reasonGiven: {
      display: immunizationObj.wasGiven ? immunizationObj.explanation : '',
      codeValue: immunizationObj.wasGiven ? immunizationObj.explanation : '',
    },
    reasonNotGiven: {
      codeValue: !immunizationObj.wasGiven ? immunizationObj.explanation : '',
      display: !immunizationObj.wasGiven ? immunizationObj.explanation : '',
    },
  },
  operationType: immunizationObj.operationType,
});

export const createImmunizationObj = (selectedImmunization) => ({
  ...selectedImmunization,
  vaccination: {
    cvX_Short_Description: selectedImmunization.vaccinationCode[0].display,
    cvX_Code: selectedImmunization.vaccinationCode[0].codeValue,
    cpT_description: selectedImmunization.vaccinationCode[0].display,
    cpT_Code: selectedImmunization.vaccinationCode[0].codeValue,
    dropdownValue: `${selectedImmunization.vaccinationCode[0].display} (${
      selectedImmunization.manufacturer && selectedImmunization.manufacturer.name
        ? selectedImmunization.manufacturer.name
        : ''
    })`,
  },

  manufacturer: selectedImmunization?.manufacturer?.id
    ? {
        manufacturer_id: selectedImmunization?.manufacturer?.id || '',
        manufacturer_name: selectedImmunization?.manufacturer?.name || '',
        mvX_CODE: selectedImmunization?.manufacturer?.code || '',
      }
    : null,
  route: {
    display: selectedImmunization.route ? selectedImmunization.route.display : '',
    code: selectedImmunization.route ? selectedImmunization.route.codeValue : '',
    system: selectedImmunization.route ? selectedImmunization.route.system : '',
  },
  site: {
    display: selectedImmunization.bodySite ? selectedImmunization.bodySite.display : '',
    code: selectedImmunization.bodySite ? selectedImmunization.bodySite.codeValue : '',
    system: selectedImmunization.bodySite ? selectedImmunization.bodySite.system : '',
  },
  startDate: selectedImmunization.administeredDate,
  wasGiven: !!selectedImmunization.explanation?.reasonGiven?.codeValue,
  notes: selectedImmunization.notes?.length > 0 ? selectedImmunization.notes[0].message : '',
  isPrimary: selectedImmunization.isPrimarySource,
  explanation: selectedImmunization.explanation?.reasonGiven?.codeValue
    ? selectedImmunization.explanation?.reasonGiven?.codeValue
    : selectedImmunization.explanation?.reasonNotGiven?.codeValue,
  operationType: 'Edit',
});

const getRouteInfo = (data, type) => {
  if (data.dosage) {
    if (data.dosage.route) return data.dosage.route[type];
    return '';
  }
  return '';
};
export const convertMedicationToMedicationObj = (medication) => ({
  id: medication.id,
  status: medication.status,
  medicationName: medication.medicationCode ? medication.medicationCode.display : '',
  medicationCode: medication.medicationCode ? medication.medicationCode.codeValue : '',
  medicationSystem: medication.medicationCode ? medication.medicationCode.system : '',
  dateAsserted: medication.dateAsserted,
  hasTaken: medication.hasTaken,
  dosageText: medication.dosage ? medication.dosage.dosageText : '',
  action: medication.dosage ? medication.dosage.action : '',
  dose: medication.dosage ? medication.dosage.dose : '',
  doseUnit: medication.dosage ? medication.dosage.doseUnit : '',
  doseTiming: medication.dosage ? medication.dosage.doseTiming : '',
  doseOther: medication.dosage ? medication.dosage.doseOther : '',
  strength: medication.dosage ? medication.dosage.strength : '',
  routeName: getRouteInfo(medication, 'display'),
  routeCode: getRouteInfo(medication, 'codeValue'),
  routeSystem: getRouteInfo(medication, 'system'),
  form: medication.form,
  frequency: medication.frequency,
  period: medication.period,
  periodUnits: medication.periodUnits,
  comment: medication.comment,
  effectiveStartDate: medication.effectiveStartDate,
  effectiveEndDate: medication.effectiveEndDate,
  doseStartTime: medication.doseStartTime,
  doseEndTime: medication.doseEndTime,
  quantityCode: medication.quantityCode,
  quantityUnit: medication.quantityUnit,
  quantityValue: medication.quantityValue,
  operationType: medication.operationType,
  rcopiaId: medication.rcopiaId,
  prescriptionRcopiaId: medication.prescriptionRcopiaId,
});

export const convertMedicationObjToMedication = (medicationObj) => ({
  id: medicationObj.id,
  status: medicationObj.status,
  medicationCode: {
    display: medicationObj.medicationName,
    codeValue: medicationObj.medicationCode,
    system: medicationObj.medicationSystem,
  },
  dateAsserted: medicationObj.dateAsserted,
  hasTaken: medicationObj.hasTaken,
  dosage: {
    dosageText: medicationObj.dosageText,
    action: medicationObj.action,
    dose: medicationObj.dose,
    doseUnit: medicationObj.doseUnit,
    doseTiming: medicationObj.doseTiming,
    doseOther: medicationObj.doseOther,
    strength: medicationObj.strength,
    route: {
      display: medicationObj.routeName,
      codeValue: medicationObj.routeCode,
      system: medicationObj.system,
    },
  },
  form: medicationObj.form,
  frequency: medicationObj.frequency,
  period: medicationObj.period,
  periodUnits: medicationObj.periodUnits,
  comment: medicationObj.comment,
  effectiveStartDate: medicationObj.effectiveStartDate,
  effectiveEndDate: medicationObj.effectiveEndDate,
  doseStartTime: medicationObj.doseStartTime,
  doseEndTime: medicationObj.doseEndTime,
  quantityUnit: medicationObj.quantityUnit,
  quantityCode: medicationObj.quantityCode,
  quantityValue: medicationObj.quantityValue,
  operationType: medicationObj.operationType,
  rcopiaId: medicationObj.rcopiaId,
  prescriptionRcopiaId: medicationObj.prescriptionRcopiaId,
});

export const createProcedureDataObj = (selectedProcedure) => ({
  procedureCode: selectedProcedure?.procedureCode?.display || '---',
  procedureCategory: selectedProcedure?.procedureCategory?.display || '---',
  procedureCodeValue: selectedProcedure?.procedureCode?.codeValue || '---',
  bodySite: selectedProcedure?.bodySite?.display || '---',
  performedStartDate: selectedProcedure?.performedStartDate
    ? utils.formatDateNoLocale(selectedProcedure.performedStartDate)
    : '---',
  performedEndDate: selectedProcedure?.performedEndDate
    ? utils.formatDateNoLocale(selectedProcedure.performedEndDate)
    : '---',
  practitioner: selectedProcedure?.practitioner?.name || '---',
  reason: selectedProcedure?.reason?.display || '---',
  reasonCodeValue: selectedProcedure?.reason?.codeValue || '---',
  status: selectedProcedure?.status || '---',
  notes: selectedProcedure?.notes || '---',
});

export const createVitalDataObj = (selectedVital) => ({
  vitalName: selectedVital?.vitalName || '---',
  vitalCode: selectedVital?.vitalCode?.display || '---',
  vitalCodeValue: selectedVital?.vitalCode?.codeValue || '---',
  vitalQuantity: selectedVital?.vitalQuantity?.display || '---',
  vitalQuantityCodeValue: selectedVital?.vitalQuantity?.codeValue || '---',
  effectiveDateTime: selectedVital?.effectiveDateTime
    ? utils.formatDateNoLocale(selectedVital.effectiveDateTime)
    : '---',
  status: selectedVital?.status || '---',
});
