export const selectAllUsersLoading = (state) => state.users.tenantUsersLoading;
export const selectAllUsers = (state) => state.users.tenantUsers;
export const selectInvitedUsersLoading = (state) => state.users.tenantInvitedUsersLoading;
export const selectUsersLoading = (state) => state.users.tenantUsersLoading || state.users.tenantInvitedUsersLoading;
export const selectInvitedUsers = (state) => state.users.tenantInvitedUsers;
export const selectCurrentUserPermissions = (state) => state.users.currentUserPermissions;
export const selectCurrentUserPermissionsLoading = (state) => state.users.currentUserPermissionsLoading;
export const selectTenantImports = (state) => state.users && state.users.bulk && state.users.bulk.imports;
export const selectProfilePicture = (state) => state.users.profilePicture;
export const selectUserPictureLoading = (state) => state.users.userPictureLoading;
export const selectCurrentUser = (state) => state.users.currentUser;
export const selectCurrentUserLoading = (state) => state.users.currentUserLoading;
export const selectKendoPagination = (state) => state.users.userPagination;
export const selectOtherUser = (state) => state.users.otherUser;
export const selectOtherUserQR = (state) => state.users.otherUserQR;
export const selectOtherUserProfilePic = (state) => state.users.otherUserProfilePic;
export const selectOtherUserPicLoading = (state) => state.users.otherUserPictureLoading;
export const selectSelectedUser = (state) => state.users && state.users.userDetails;
export const selectUserDetails = (state) => state.users.userDetails;
export const selectUserDetailsLoading = (state) => state.users.userDetailsLoading;
export const selectUserAccountPermission = (state) => state.users.userAccountPermission;
export const selectUserPermissions = (state) => state.users.userPermissions;
export const selectUserPermissionsLoading = (state) => state.users.userPermissionsLoading;
export const selectGeneratingNhi = (state) => state.users.generatingNhi;
export const selectBulkImportResp = (state) => state.users.bulk;
export const selectShowBulkUploadJobDialog = (state) => state.users.showBulkUploadJobDialog;
export const selectPreviewImport = (state) => state.users.previewImport;
export const selectUserQuestionnaire = (state) => state.users.userQuestionnaire;
export const selectUserQuestionnaireLoading = (state) => state.users.userQuestionnaireLoading;
export const selectUserMessagesLoading = (state) => state.users.userMessagesLoading;
export const selectUserMessages = (state) => state.users.userMessages;
export const selectUserIdVerHisLoading = (state) => state.users.userIdVerHisLoading;
export const selectUserIdVerHisData = (state) => state.users.userIdVerHisData;
export const selectGuardianInfoLoading = (state) => state.users.guardianInfoLoading;
export const selectGuardianInfo = (state) => state.users.guardianInfo;
export const selectUserVaxStatusInfoLoading = (state) => state.users.userVaxStatusLoading;
export const selectUserVaxStatusInfo = (state) => state.users.userVaxStatusInfo;
export const selectAllUploadsListLoading = (state) => state.users.allUploadsListLoading;
export const selectAllUploadsList = (state) => state.users.allUploadsList;
export const selectPrevFilterState = (state) => state.users.prevFilterState;
export const selectCheckboxAllUsers = (state) => state.users.selectAllUsers;
export const selectCheckboxUserIds = (state) => state.users.selectUserIds;
export const selectIsSelectAllTriggered = (state) => state.users.isSelectAllTriggered;
export const selectTenantUserTotalsLoading = (state) => state.users.tenantUserTotalsLoading;
export const selectTenantUserTotals = (state) => state.users.tenantUserTotals;
export const selectUserDeleteAccountLoading = (state) => state.users.userDeleteAccountLoading;
