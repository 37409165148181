import { createSlice } from '@reduxjs/toolkit';

import api from '../../../api';
import { showNotification } from '../../Notifications/notificationSlice';
import { handleError } from '../../Shared/Content/APIUtils';

const initialState = {
  conditionList: null,
  conditionListLoading: null,
};

export const conditionListSlice = createSlice({
  name: 'conditionList',
  initialState,
  reducers: {
    setConditionListLoading: (state, { payload }) => ({
      ...state,
      conditionListLoading: payload,
    }),
    setConditionList: (state, { payload }) => ({
      ...state,
      conditionList: payload,
      conditionListLoading: false,
    }),
  },
});

export const { setConditionList, setConditionListLoading } = conditionListSlice.actions;

export const getConditionList = (safeEMRId) => async (dispatch) => {
  dispatch(setConditionListLoading(true));

  const [result, error] = await api.getConditionListRequest(safeEMRId);

  if (result) {
    dispatch(setConditionList(result));
  }

  if (error && error.message) {
    dispatch(handleError(error));
  }
};

export const postConditionList = (safeEMRId, data) => async (dispatch) => {
  dispatch(setConditionListLoading(true));

  const [result, error] = await api.postConditionListRequest(data);

  if (result) {
    dispatch(showNotification('Condition Details Successfully Sent', 'success'));
    dispatch(getConditionList(safeEMRId));
  }

  if (error && error.message) {
    dispatch(handleError(error));
  }
};

export const postConditionToDrfirst = (safeEMRId) => async (dispatch) => {
  dispatch(setConditionListLoading(true));

  const [result, error] = await api.postConditionToDrfirstRequest(safeEMRId);

  if (result) {
    dispatch(showNotification('Drfirst sync Successful', 'success'));
    dispatch(setConditionListLoading(false));
  }

  if (error && error.message) {
    dispatch(handleError(error));
  }
};

export default conditionListSlice.reducer;
