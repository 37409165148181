import { Card, CircularProgress } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import React from 'react';
import ContentLoader from 'react-content-loader';

export function SimulatorCardSkeletonLoading() {
  return (
    <Card
      style={{ borderRadius: '20px', marginBottom: 20, padding: 32, boxSizing: 'border-box', width: '100%' }}
      elevation={0}
    >
      <ContentLoader
        height={145}
        width="100%"
        speed={0.8}
        viewBox="0 0 100% 350"
        backgroundColor="#ecebeb"
        foregroundColor="#f3f3f3"
      >
        {/* profile card */}
        <rect x="8" y="0" rx="16" ry="16" width="140" height="145" />
        <rect x="165" y="0" rx="3" ry="10" width="15%" height="30" />
        <rect x="165" y="calc(50% - 15px)" rx="3" ry="10" width="15%" height="30" />
        <rect x="165" y="calc(100% - 30px)" rx="3" ry="10" width="15%" height="30" />

        <rect x="calc(165px + 15% + 20px)" y="0" rx="3" ry="10" width="15%" height="30" />
        <rect x="calc(165px + 15% + 20px)" y="calc(50% - 15px)" rx="3" ry="10" width="15%" height="30" />
        <rect x="calc(165px + 15% + 20px)" y="calc(100% - 30px)" rx="3" ry="10" width="15%" height="30" />

        {/* /!* QR Code *!/ */}
        <rect x="calc(100% - (145px + 8px))" y="0" rx="16" ry="16" width="140" height="145" />
      </ContentLoader>
    </Card>
  );
}

export function SimulatorCardSkeletonLoadingMobile(props) {
  return (
    <ContentLoader
      // height={54}
      // width={320}
      speed={0.8}
      viewBox="0 0 200 210"
      backgroundColor="#ecebeb"
      foregroundColor="#f3f3f3"
      {...props}
    >
      {/* profile card */}
      <rect x="25" y="20" rx="3" ry="3" width="150" height="20" />
      <rect x="50" y="45" rx="3" ry="3" width="100" height="15" />

      {/* Picture and QR Code */}
      {/* <circle cx="50" cy="105" r="40" /> */}
      <rect x="25" y="70" rx="3" ry="3" width="65" height="65" />
      <rect x="115" y="70" rx="3" ry="3" width="65" height="65" />

      <rect x="50" y="155" rx="3" ry="3" width="100" height="10" />
      <rect x="30" y="170" rx="3" ry="3" width="140" height="10" />
      <rect x="50" y="185" rx="3" ry="3" width="100" height="10" />
    </ContentLoader>
  );
}

export function SimulatorSkeletonLoading(props) {
  return (
    <ContentLoader
      height={500}
      // width={320}
      speed={0.8}
      viewBox="0 0 100% 170"
      backgroundColor="#ecebeb"
      foregroundColor="#f3f3f3"
      style={{ width: '100%', paddingBottom: '30px' }}
      {...props}
    >
      {/* Label & Input */}
      <rect x="50" y="50" rx="3" ry="3" width="10%" height="20" />
      <rect x="50" y="80" rx="3" ry="3" width="90%" height="30" />

      {/* Label & Input */}
      <rect x="50" y="140" rx="3" ry="3" width="10%" height="20" />
      <rect x="50" y="170" rx="3" ry="3" width="90%" height="30" />

      {/* Label & Input */}
      <rect x="50" y="230" rx="3" ry="3" width="10%" height="20" />
      <rect x="50" y="260" rx="3" ry="3" width="90%" height="30" />

      {/* Label & Input */}
      <rect x="50" y="320" rx="3" ry="3" width="10%" height="20" />
      <rect x="50" y="350" rx="3" ry="3" width="90%" height="30" />

      {/* Label & Input */}
      <rect x="50" y="410" rx="3" ry="3" width="10%" height="20" />
      <rect x="50" y="440" rx="3" ry="3" width="90%" height="30" />
    </ContentLoader>
  );
}

const OverlayLoadingWrapper = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  height: '150px',
  left: '-3px',

  '& > .progress-wrapper': {
    width: '75px',
    background: 'rgba(178,178,178,0.20)',
    zIndex: '20000',
    borderRadius: '10px',
    textAlign: 'center',
    height: '75px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    top: '-30px',
  },
});

export function OverlayLoading() {
  return (
    <OverlayLoadingWrapper>
      <div className="progress-wrapper">
        <CircularProgress color="primary" size={'3rem'} />
      </div>
    </OverlayLoadingWrapper>
  );
}
