import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FhirResource, fhirVersions } from 'fhir-react';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import 'fhir-react/build/style.css';
import 'fhir-react/build/bootstrap-reboot.min.css';
import ExpansionSkeletonLoading from '../Shared/ExpansionPanel/ExpansionSkeletonLoading';
import utils from '../Shared/Utils/utils';

import { getCCDViewer } from './slices/ccdViewerSlice';
import { selectCCDView, selectCCDViewLoading } from './slices/selectors';

const { displayInOrder: resourcesListToDisplayInOrder } = require('./resourcesConfig');

function JsonViewer(props) {
  const { userDetails, currentTenant } = props;
  const ccdView = useSelector(selectCCDView);
  const ccdViewLoading = useSelector(selectCCDViewLoading);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCCDViewer(userDetails.nationalHealthId, currentTenant.id));
  }, [currentTenant, userDetails]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {typeof ccdViewLoading !== 'undefined' && !ccdViewLoading && ccdView !== 'undefined' && ccdView != null ? (
          resourcesListToDisplayInOrder.map((resourceType) => (
            <div key={resourceType}>
              {typeof ccdView[resourceType] !== 'undefined' &&
              ccdView[resourceType] != null &&
              ccdView[resourceType].entry.length > 0 ? (
                <Accordion style={{ width: '100%', backgroundColor: '#fafafa' }}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <Typography variant="h6" style={{ marginLeft: 15, marginBottom: '10px' }}>
                      {utils.capitalizeString(resourceType)}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container>
                      {typeof ccdView[resourceType] !== 'undefined' && ccdView[resourceType] != null
                        ? ccdView[resourceType].entry != null &&
                          ccdView[resourceType].entry.map((resourceContainer) => (
                            <Grid key={resourceContainer.resource.id} item xs={12}>
                              <FhirResource fhirResource={resourceContainer.resource} fhirVersion={fhirVersions.R4} />
                            </Grid>
                          ))
                        : ''}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              ) : (
                ''
              )}
            </div>
          ))
        ) : (
          <ExpansionSkeletonLoading />
        )}
      </Grid>
    </Grid>
  );
}

export default JsonViewer;
