/* eslint-disable react/no-unused-prop-types */
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

import { SystemTotalsCardSkeletonLoading } from '../Content/ContentSkeletonLoading';

import AppInfoBox from './charts/InfoBox';

export function SystemTotalCardLoading() {
  const classes = useStyles();
  return (
    <Paper className={classes.loadingWrapper}>
      <SystemTotalsCardSkeletonLoading />
    </Paper>
  );
}

export function SystemTotalInfoCard(props) {
  const { value, title, infoText } = props;
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <Grid className={classes.descriptionWrapper}>
        <Grid className={classes.value}>{value}</Grid>
        <Grid className={classes.title}>{title}</Grid>
      </Grid>

      {infoText && <AppInfoBox infoText={infoText} position="topRight" />}
    </Paper>
  );
}

SystemTotalInfoCard.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  title: PropTypes.string,
  type: PropTypes.string,
  bgColor: PropTypes.string,
};

// Info card styles
const useStyles = makeStyles((theme) => ({
  root: () => ({
    padding: '8px 24px',
    backgroundColor: '#FFFFFF',
    // height: 168,
    borderRadius: 20,
    // boxShadow: "0 1 1 0 #FFFFFF",
    position: 'relative',
    [theme.breakpoints.down(599)]: {
      padding: '6px 18px',
    },
  }),
  loadingWrapper: {
    height: 65,
    backgroundColor: '#ffffff',
    borderRadius: 20,
    [theme.breakpoints.down(599)]: {
      height: 80,
    },
  },
  descriptionWrapper: {
    display: 'flex',
    color: '#505358',
    fontFamily: 'Avenir',
    fontWeight: 500,
    [theme.breakpoints.down(599)]: {
      flexDirection: 'column-reverse',
      width: 'calc(100% - 20px)',
    },
    [theme.breakpoints.up(600)]: {
      alignItems: 'center',
    },
  },
  value: {
    fontSize: 36,
    lineHeight: '49px',
  },
  title: {
    fontSize: 16,
    lineHeight: '150%',
    [theme.breakpoints.up(600)]: {
      marginLeft: 16,
    },
  },
}));
