import { createSlice } from '@reduxjs/toolkit';

import api from '../../../api';
import { handleError } from '../../Shared/Content/APIUtils';

const initialState = {
  ePrescribe: null,
  ePrescribeLoading: null,
};

export const ePrescribeSlice = createSlice({
  name: 'ePrescribe',
  initialState,
  reducers: {
    setEPrescribeLoading: (state) => ({
      ...state,
      ePrescribeLoading: true,
    }),
    setEPrescribe: (state, { payload }) => ({
      ...state,
      ePrescribe: payload,
      ePrescribeLoading: false,
    }),
  },
});

export const { setEPrescribe, setEPrescribeLoading } = ePrescribeSlice.actions;

export const postPatient = (data) => async (dispatch) => {
  dispatch(setEPrescribeLoading());

  const [result, error] = await api.postPatientRequest(data);

  if (result) {
    dispatch(setEPrescribe(result));
  }

  if (error && error.message) {
    dispatch(handleError(error));
  }
};

export default ePrescribeSlice.reducer;
