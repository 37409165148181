/* eslint-disable no-unused-vars */
// NOT SURE IF THIS FILE IS BEING USED
/* eslint-disable-next-line */
const whitelistItems = ['SYMPTOMATIC', 'ASYMPTOMATIC', 'EXPOSURE'];

/* eslint-disable-next-line */
export const setBaseSegment = (data, tenantSettings, outcomes) => {
  /* eslint-disable-next-line */
  const baseSegment = Object.create({});
  for (let i = 0; i < data.length; i++) {
    // TODO: Commented out for demo
    // if (data[i].name === "Base User Segment") {
    //   for (let j = 0; j < whitelistItems.length; j++) {
    //     // find outcome that includes whitelisted items and extract ID
    //     let outcomeId =
    //       outcomes &&
    //       outcomes.filter((outcome) => {
    //         return outcome.title.toUpperCase() === whitelistItems[j];
    //       })[0];
    //     if (outcomeId) {
    //       baseSegment[outcomeId.id] = {
    //         ...data[i].testSettingByOutcome[outcomeId.id],
    //         customOutcomeMessage:
    //           tenantSettings.outcomesText &&
    //           tenantSettings.outcomesText[whitelistItems[j]]
    //             ? tenantSettings.outcomesText[whitelistItems[j]]
    //             : `Message for ${[whitelistItems[j]]}`,
    //         customOutcomeImage: tenantSettings.outcomesImage[whitelistItems[j]],
    //       };
    //     }
    //   }
    //   data[i]["testSettingByOutcome"] = baseSegment;
    // }
  }
  return data;
};
