/* eslint-disable no-unused-expressions */
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ContentStyles from '../../assets/jss/components/ContentStyles';
import { setAllowChange } from '../LanguageMenu/languageSlice';
import { selectLanguage } from '../LanguageMenu/selectors';
import * as htmlUtils from '../Shared/Grid/helpers';
import { selectCurrentTenant } from '../Shared/selectors';
import CheckBoxCell from '../Shared/Table/CheckBoxCell';
import AppKendoTableWrapper from '../Shared/Table/KendoTableWrapper';
import { getContentList, getMessageStandardizationList } from './contentSlice';
import { transform, columns, initialSorting, tableButtons } from './helpers/contentListMapper';
import { selectContent } from './selectors';

function ContentList(props) {
  const { type, pageSize, multiSelection, hideButtons, selected, handleSelectionChange, disabled } = props;

  const [paginationState, setPaginationState] = useState({
    page: 0,
    pageSize: 10,
    sort: initialSorting[type].field,
    direction: initialSorting[type].dir.toUpperCase(),
    searchValue: '',
    filter: null,
  });

  const currentTenant = useSelector(selectCurrentTenant);
  const language = useSelector(selectLanguage);
  const content = useSelector(selectContent);

  const contentsLoading = content && content[type] && content[type].loading;
  const deleteContentLoading = content && content[type] && content[type].deleteContentLoading;
  const [list, setList] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    setList(content && content[type] && content[type].list);
  }, [content, type]);

  useEffect(() => {
    dispatch(setAllowChange(true));
  }, []);

  const handleTableUpdate = useCallback(
    (page, pageSize, sort, direction, searchValue, filter) => {
      setPaginationState({
        page,
        pageSize,
        sort,
        direction,
        searchValue,
        filter,
      });
      const data = {
        term: searchValue,
        tenantId: currentTenant.id,
        sortAsc: direction.toUpperCase() == 'ASC',
        pageNumber: page,
        pageSize,
      };
      type == 'messageStandardizations'
        ? dispatch(getMessageStandardizationList(data, type))
        : dispatch(
            getContentList(type, currentTenant.id, page, pageSize, searchValue, sort, direction.toUpperCase(), filter)
          );
    },
    [currentTenant, type]
  );

  const handleOnChecked = useCallback(
    (item, checked) => {
      const newSelectedList = selected.slice();

      if (checked && !newSelectedList.includes(item._id)) {
        newSelectedList.push(item._id);
      } else if (!checked && newSelectedList.includes(item._id)) {
        const index = newSelectedList.findIndex((i) => i === item._id);
        newSelectedList.splice(index, 1);
      }

      if (handleSelectionChange) {
        handleSelectionChange(newSelectedList);
      }
    },
    [selected, handleSelectionChange]
  );

  const sorting = initialSorting[type];
  const buttons = tableButtons[type](currentTenant);

  let cols = [];
  if (multiSelection) {
    cols = columns(type, currentTenant).slice();
    cols.splice(0, 0, {
      id: 'checked',
      label: 'Selected',
      show: true,
      minWidth: 100,
      sortable: false,
      cell: htmlUtils.withOnChange(CheckBoxCell, handleOnChecked, disabled),
    });
  } else {
    cols = columns(type, currentTenant);
  }

  const getInitialContentList = () => {
    const data = {
      term: paginationState.searchValue,
      tenantId: currentTenant.id,
      sortAsc: paginationState.direction.toUpperCase() == 'ASC',
      pageNumber: paginationState.page,
      pageSize: paginationState.pageSize,
    };

    type == 'messageStandardizations'
      ? dispatch(getMessageStandardizationList(data, type))
      : dispatch(
          getContentList(
            type,
            currentTenant.id,
            paginationState.page,
            paginationState.pageSize,
            paginationState.searchValue,
            paginationState.sort,
            paginationState.direction.toUpperCase(),
            paginationState.filter
          )
        );
  };

  useEffect(() => {
    if (!content || !content[type] || !content[type].list || !multiSelection) {
      getInitialContentList();
    }
  }, [type, currentTenant, pageSize, multiSelection, language]);

  useEffect(() => {
    if (deleteContentLoading) {
      getInitialContentList();
    }
  }, [deleteContentLoading]);

  if (!currentTenant) return <div />;

  return (
    <Grid>
      <AppKendoTableWrapper
        data={transform[type](list, selected || [])}
        initialSort={sorting.field}
        initialDir={sorting.dir}
        showButtons={!hideButtons}
        showSearchbar
        searchAdornmentPos="end"
        tableButtons={buttons}
        columns={cols || []}
        currentTenant={currentTenant}
        onTableUpdate={handleTableUpdate}
        loading={contentsLoading}
        disableAutoFocus={multiSelection === true ? true : undefined}
        parent="content"
        // showLanguageMenu={true}
      />
    </Grid>
  );
}

export default withStyles(ContentStyles)(ContentList);
