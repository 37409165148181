export const selectVaxRecordsLoading = (state) => state.vaccinationRecords && state.vaccinationRecords.loading;
export const selectVaxRecords = (state) => state.vaccinationRecords && state.vaccinationRecords.list;
export const selectMasterCode = (state) => state.vaccinationRecords && state.vaccinationRecords.masterCodeList;
export const selectMasterCodeLoading = (state) => state.vaccinationRecords && state.vaccinationRecords.list;
export const selectUpdating = (state) => state.vaccinationRecords && state.vaccinationRecords.updating;
export const selectVaxRecordImageLoading = (state) => state.vaccinationRecords && state.vaccinationRecords.imgLoading;
export const selectVaxRecordImage = (state) => state.vaccinationRecords && state.vaccinationRecords.imgs;
export const selectLatestVaxRecordLoading = (state) =>
  state.vaccinationRecords && state.vaccinationRecords.latestVaxRecordLoading;
export const selectLatestVaxRecord = (state) => state.vaccinationRecords && state.vaccinationRecords.latestVaxRecord;
