export const lsClient = {
  questionnaire: {
    setQuestionnaireData(data) {
      localStorage.setItem('questionnaireData', JSON.stringify(data));
    },
    getQuestionnaireData() {
      return JSON.parse(localStorage.getItem('questionnaireData'));
    },

    setAnonUserAuthToken(data) {
      localStorage.setItem('anonUserAuthToken', data);
    },
    getAnonUserAuthToken() {
      return localStorage.getItem('anonUserAuthToken');
    },
  },
};
