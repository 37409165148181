import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { useTheme, withStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// styling and component imports
import ProfileViewStyles from '../../assets/jss/components/ProfileViewStyles';
import { getMetaDataList } from '../Networks/networksSlice';
import { selectCurrentTenant } from '../Shared/selectors';
import { selectUserDetails, selectUserDetailsLoading, selectUserPermissionsLoading } from '../Users/selectors';
import { getUser, getUserPermissions, resetUserDetails } from '../Users/usersSlice';

import ProfileCard from './ProfileCard';
import ProfileInfoBox from './ProfileInfoBox';

// material-UI
import {
  ProfileCardSkeletonLoading,
  ProfileCardSkeletonLoadingMobile,
  ProfileTabsSkeletonLoading,
  ProfileDropdownSkeletonLoadingMobile,
} from './ProfileSkeletonLoading';

function Profile(props) {
  const { classes, type } = props;

  const userDetailsLoading = useSelector(selectUserDetailsLoading);
  const currentTenant = useSelector(selectCurrentTenant);
  const userPermissionsLoading = useSelector(selectUserPermissionsLoading);
  const userDetails = useSelector(selectUserDetails);
  const params = useParams();
  const { userId } = params;

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up(960));

  const dispatch = useDispatch();

  const getUserData = async () => {
    await dispatch(getUser(userId, true));
  };

  useEffect(() => {
    const prevPageKey = 'PREVIOUS_PAGE';
    const memberPageVal = 'MEMBER_PAGE';

    localStorage.setItem(prevPageKey, memberPageVal);
    return () => {
      dispatch(resetUserDetails());
    };
  }, []);

  useEffect(() => {
    dispatch(getUserPermissions(currentTenant.id, userId));
    dispatch(getMetaDataList(currentTenant.id));
    getUserData();
  }, [type, currentTenant.id]);

  if (userDetailsLoading || !userDetails || userPermissionsLoading) {
    return (
      <div>
        {matches ? (
          <div>
            <ProfileCardSkeletonLoading />
            <Paper style={{ borderRadius: '20px', marginBottom: 30 }} elevation={0}>
              <ProfileTabsSkeletonLoading />
            </Paper>
          </div>
        ) : (
          <div>
            <Card style={{ borderRadius: '20px', marginBottom: 20 }} elevation={0}>
              <ProfileCardSkeletonLoadingMobile />
            </Card>
            <Paper style={{ borderRadius: '20px', marginBottom: 30 }} elevation={0}>
              <ProfileDropdownSkeletonLoadingMobile />
            </Paper>
          </div>
        )}
      </div>
    );
  }

  // profile page
  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <ProfileCard />
      </Grid>
      <Grid item xs={12} className={classes.bottomBox}>
        <ProfileInfoBox />
      </Grid>
    </Grid>
  );
}

export default withStyles(ProfileViewStyles)(Profile);
