import { getFieldName } from '../../QuestionBuilder/helpers/formHelper';

export const outcomeJsonBuilder = (state, languages) => ({
  ...state,
  name: translateObject(state, 'name', languages),
  title: translateObject(state, 'title', languages),
  description: translateObject(state, 'description', languages),
  coding: {
    system: state.system,
    code: state.code,
    description: state.codeDescription && state.codeDescription[getFieldName(state.system)],
  },
  report: {
    target: state.reporterTarget,
    text: state.reporterFormat,
  },

  // Test outcome fields -- set first entry to default Test entries
  testId: state.type === 'test' && state.testList.length && state.testList[0].test.safeTestId,
  vendorId: state.type === 'test' && state.testList.length && state.testList[0].test.vendorTestId,
  vendorName: state.type === 'test' && state.testList.length && state.testList[0].test.vendorTestName,
  laboratoryTestSource: state.type === 'test' && state.testList.length && state.testList[0].test.vendorKey,
  orderableTestId:
    state.type === 'test' &&
    state.testList.length &&
    (state.testList[0].test.orderableTestId || state.testList[0].test.labTestPanelId),
  testList:
    state.type === 'test' &&
    state.testList.map((item) => ({
      testId: item.test.safeTestId,
      vendorId: item.test.vendorTestId,
      vendorName: item.test.vendorTestName,
      laboratoryTestSource: item.test.vendorKey,
      orderableTestId: item.test.orderableTestId || item.test.labTestPanelId,
    })),

  // Prescription outcome fields
  medicationList:
    state.type === 'prescription' && state.medicationList
      ? state.medicationList.map((medication) => ({
          ...medication,
          quantity: {
            min: medication.minQuantity,
            max: medication.maxQuantity,
          },
          providerReview: medication.providerReview,
          condition: medication.condition,
          name: medication.medicationName,
          type: medication.medicationType,
          takenBy: medication.takenBy,
          dose: {
            value: medication.doseValue,
            unit: medication.doseUnit,
          },
          duration: {
            value: !medication.otherDurations ? medication.durationValue : null,
            unit: !medication.otherDurations ? medication.durationUnit : medication.otherDurations,
          },
          frequency: {
            value: medication.frequencyValue,
            type: medication.frequencyType,
          },
          refills: medication.refills,
          patientInstructions: medication.patientInstructions,
          pharmacyInstructions: medication.pharmacistInstructions,
        }))
      : null,

  // Alarm outcome fields
  options:
    state.type === 'alarm' && state.options
      ? state.options.map((option) => ({
          ...option,
          frequency: option.frequencyType && {
            type: option.frequencyType,
            value: option.frequencyAmount,
          },
        }))
      : null,
});

export const handleFormatJson = (formData) => {
  let data;
  // Deconstruct selected languages in use
  let selectedLanguages;
  if (formData.languageChoices && formData.languageChoices.length) {
    selectedLanguages = formData.languageChoices.map((language) => language.split(' = ')[0]);
  }

  data = outcomeJsonBuilder(formData, selectedLanguages);

  return data;
};

// Take object from above Json builders and create Json objects to represent languages in use
const translateObject = (state, obj, languages) => {
  const translations = {};
  const validated = [];
  if (languages) {
    languages.forEach((lang) => {
      translations[lang] = state[`${obj}-${lang}`];
      if (state[`${obj}Valid-${lang}`]) validated.push(lang);
    });
  }

  // Assign default to English entry
  translations.eng = state[obj];

  // Create object for return
  const translationsObject = {
    translations,
    validatedLanguages: validated,
  };
  return translationsObject;
};
