/* eslint-disable react/no-array-index-key */
/* eslint-disable no-return-assign */
/* eslint-disable computed-property-spacing */
/* eslint-disable import/no-cycle */
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import AccountTree from '@material-ui/icons/AccountTree';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import ReportProblem from '@material-ui/icons/ReportProblem';
import { updateWith, isEmpty } from 'lodash';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { showConfirmation } from '../../../../Confirmation/confirmationSlice';
import { showNotification } from '../../../../Notifications/notificationSlice';
import { createInputHelper } from '../../../../Shared/Forms/helpers/createInputHelper';
// import ReportProblemIcon from "@material-ui/icons/ReportProblem";
// import QuestionPreview from "../../../QuestionBuilder/QuestionPreview";
import { selectCurrentTenant } from '../../../../Shared/selectors';
import utils from '../../../../Shared/Utils/utils';
import { selectEditingReviewerQuestion } from '../../../Reviewer/selectors';
import QuestionPreview from '../../../vcb-package/QuestionPreview';
import { autoAnswerTypes } from '../../helpers/formHelper';
import { QuestionnaireBuilderContext } from '../../QuestionnaireBuilderWrapper';

import JumpList from './Jump/JumpList';
import Node from './Node';

function Question(props) {
  const {
    question,
    questionIndex,
    setQuestionEdit,
    setDisableButtons,
    previewQuestionId,
    onClickQuestion,
    disableButtons,
    cancelNodeUpdate,
    captureWindowPosition,
    labeledQuestions,
    questionsWithAnnotations,
    questionList,
  } = props;

  const currentTenant = useSelector(selectCurrentTenant);

  const {
    nodes,
    setNodes,
    selectedQuestions,
    setSelectedQuestions,
    handleSubmit,
    setQuestionnaireUpdated,
    questionsRef,
  } = useContext(QuestionnaireBuilderContext);

  const editingQuestionFromReviewer = useSelector(selectEditingReviewerQuestion);

  const classes = useStyles();
  const [showJumps, setShowJumps] = useState(false);
  const [questionNodes, setQuestionNodes] = useState([]);
  const [showAutoGenerate, setShowAutoGenerate] = useState(false);
  const [deleteQuestionNode, setDeleteQuestionNode] = useState(false);
  const [questionToDelete, setQuestionToDelete] = useState();
  const { type, name, description, note, combo, autoAnswerOption, id } = question || {};

  const questionWrapperRef = useRef();
  const editQuestionRef = useRef();

  const dispatch = useDispatch();

  useEffect(() => {
    if (editingQuestionFromReviewer && editingQuestionFromReviewer === id) {
      setTimeout(() => {
        questionWrapperRef.current.scrollIntoView({
          block: 'center',
        });
        editQuestionRef.current.click();
      }, 100);
    }
    // eslint-disable-next-line
  }, []);

  const handleClickJumpsIcon = () => {
    captureWindowPosition(questionIndex);
    if (!showJumps) {
      setShowJumps(!showJumps);
      setDisableButtons(!showJumps);
      const foundNodes = nodes.filter((n) => {
        if (!n.question) return false;
        return n.question.id === id;
      });

      if (foundNodes.length) {
        return setQuestionNodes(foundNodes);
      }

      const questionNode = {
        defaultNextQuestionId: null,
        id: utils.getId(),
        question: {
          ...question,
          name: `${question.name}: node ${questionNodes.length + 1}`,
        },
        rules: [],
      };

      if (
        type !== 'numeric' &&
        type !== 'multiple' &&
        type !== 'multiple-drop' &&
        type !== 'date' &&
        type !== 'scale' &&
        type !== 'slider' &&
        type !== 'yes-no' &&
        type !== 'multiple-size'
      ) {
        questionNode.rules = null;
      } else if (question.type === 'numeric') {
        questionNode.rules = [
          {
            nextQuestionId: null,
            rules: [
              {
                type,
                questionId: question.id,
                answer: 0,
                operation: 'GREATER',
              },
            ],
          },
        ];
      } else {
        questionNode.rules = [
          {
            nextQuestionId: null,
            rules: [
              {
                type,
                questionId: question.id,
                expectedAnswers: [],
              },
            ],
          },
        ];
      }
      setQuestionNodes([questionNode]);
    }
  };

  const renderQuestionPreview = () => {
    const questionPreview = { ...question };

    if (type !== 'small-text' && type !== 'numeric') {
      questionPreview.name = '';
    }
    if (type === 'numeric') {
      questionPreview.min = null;
    }
    questionPreview.width = 6;
    return createInputHelper(
      [questionPreview],
      {},
      () => ({}),
      () => ({}),
      false,
      false
    );
  };

  const renderSubnodes = (nodeList) => {
    const subnodeComponents = nodeList.map((subnode) => {
      const item = labeledQuestions.find((question) => question.id === subnode.questionId);
      const index = labeledQuestions.indexOf(item);
      return (
        <Question
          key={subnode.questionId}
          question={item}
          questionIndex={index}
          setDisableButtons={setDisableButtons}
          previewQuestionId={previewQuestionId}
          disableButtons={disableButtons}
          onClickQuestion={onClickQuestion}
          setQuestionEdit={setQuestionEdit}
          cancelNodeUpdate={cancelNodeUpdate}
          captureWindowPosition={captureWindowPosition}
        />
      );
    });
    return subnodeComponents;
  };

  const removeQuestion = (questionId) => {
    const foundNodes = nodes
      .filter((n) => {
        if (!n.defaultNextQuestionId) return false;
        return n.defaultNextQuestionId === questionId;
      })
      .map((item) => item.question.name);
    let confirmationText = 'Are you sure you want to delete this question?';
    if (foundNodes.length) {
      confirmationText = `Deleting this node will require editing the following nodes so their 
      jump logic no longer refers to the node you are deleting:
      <br>-
      ${foundNodes.join('<br>-')}
      <br><br>
      ${confirmationText}`;
    }

    // Prevent deletion of final question with length check
    if (selectedQuestions.length > 1) {
      dispatch(
        showConfirmation('Confirm', confirmationText, 'questionnaireQuestionDelete', currentTenant, '', '', () => {
          // These actions trigger a useEffect to complete deletion
          // The use of a separate useEffect allows for a follow-up confirmation in the event of a 'combo' question deletion
          setQuestionToDelete(questionId);
          setDeleteQuestionNode(true);
        })
      );
    } else {
      dispatch(showNotification('Questionnaires must have at least one question', 'error'));
    }
  };

  // Handle the rest of deletion. Separated into this useEffect to allow for a follow-up confirmation dialog
  useEffect(() => {
    // Function to complete deletion process for options below
    const finishDeletion = (updatedSelectedQuestions, updatedNodes) => {
      setQuestionnaireUpdated(true);
      setNodes([...updatedNodes]);
      setSelectedQuestions([...updatedSelectedQuestions]);
      handleSubmit([...updatedSelectedQuestions], [...updatedNodes]);
      setDeleteQuestionNode(false);
      setQuestionToDelete();
    };

    if (deleteQuestionNode && questionToDelete) {
      let selectedQuestion;
      let updatedSelectedQuestions;
      let updatedNodes;

      // Grab question object for combo node check
      selectedQuestion = selectedQuestions.find((item) => item.id === questionToDelete);
      updatedSelectedQuestions = selectedQuestions.filter((item) => item.id !== questionToDelete);
      updatedNodes = nodes.filter((item) => {
        if (!item.question) return true;
        return item.question.id !== questionToDelete;
      });

      // Follow-up confirmation for Combo Node deletion
      if (selectedQuestion.type === 'combo') {
        dispatch(
          showConfirmation(
            'Delete Combo Node',
            'Do you also wish to delete the questions inside this Combo Node?',
            'questionnaireQuestionDelete',
            currentTenant,
            '',
            '',
            () => {
              // "Yes" -- Delete member questions
              selectedQuestion.nodeList.forEach((subnode) => {
                updatedSelectedQuestions = updatedSelectedQuestions.filter((item) => item.id !== subnode.questionId);
              });
              finishDeletion(updatedSelectedQuestions, updatedNodes);
            },
            () => {
              // "No" -- do not delete member questions and instead convert them to nodes
              const convertQuestionsToNodes = updatedSelectedQuestions.filter((item) =>
                selectedQuestion.nodeList.find((subnode) => subnode.questionId === item.id)
              );
              const newNodes = convertQuestionsToNodes.map((item) => ({
                id: item.id,
                autoAnswerOption: null,
                defaultNextQuestionId: null,
                name: item.name && item.name.translations ? item.name.translations.eng : item.name,
                question: item,
                outcome: null,
                rules: [],
              }));
              updatedNodes = [...updatedNodes, ...newNodes];
              finishDeletion(updatedSelectedQuestions, updatedNodes);
            }
          )
        );
      } else {
        // Complete deletion for non-combo question types
        finishDeletion(updatedSelectedQuestions, updatedNodes);
      }
    }
  }, [
    currentTenant,
    deleteQuestionNode,
    handleSubmit,
    nodes,
    questionToDelete,
    selectedQuestions,
    setNodes,
    setQuestionnaireUpdated,
    setSelectedQuestions,
  ]);

  const questionPreviewData = () => {
    const selectedQuestion = { ...selectedQuestions.find((q) => q.id === id) };

    if (isEmpty(selectedQuestion)) return null;

    if (selectedQuestion.type === 'numeric') {
      selectedQuestion.min = null;
    }

    return { ...selectedQuestion };
  };

  const addNode = () => {
    setQuestionNodes([
      ...questionNodes,
      {
        defaultNextQuestionId: null,
        id: utils.getId(),
        question,
        name: `${question.name}: node ${questionNodes.length + 1}`,
        rules: [],
      },
    ]);
  };

  const removeNode = (nodeId) => {
    const firstNodeId = questionNodes[0].id;

    if (nodeId === firstNodeId) return;

    const updatedNodes = nodes.filter((n) => n.id !== nodeId);
    const updatedQuestionNodes = questionNodes.filter((n) => n.id !== nodeId);

    setQuestionNodes(updatedQuestionNodes);
    setNodes(updatedNodes);
  };

  const updateQuestionNodes = (path, updater) => {
    const updatedQuestionNodes = updateWith(questionNodes, path, updater);
    setQuestionNodes([...updatedQuestionNodes]);
  };

  const handleNodeNameChange = (val, nodeIndex) => {
    updateQuestionNodes(`[${nodeIndex}]`, (data) => ({
      ...data,
      name: val,
    }));
  };

  const selectQuestionAsFirst = () => {
    let updatedNodes = [...nodes];
    let updatedQuestions = [...selectedQuestions];

    const question = selectedQuestions.find((item) => item.id === id);
    const node = nodes.find((item) => {
      if (!item.question) return false;
      return item.question.id === id;
    });

    updatedNodes = updatedNodes.filter((item) => {
      if (!item.question) return true;
      return item.question.id !== id;
    });

    updatedNodes.unshift(node);

    updatedQuestions = updatedQuestions.filter((item) => item.id !== id);
    updatedQuestions.unshift(question);

    // Move combo node member questions to immediately follow the parent question
    if (question.type === 'combo') {
      question.nodeList.forEach((subnode, idx) => {
        const index = 1 + idx;
        const foundSubnode = selectedQuestions.find((item) => item.id === subnode.questionId);
        updatedQuestions = updatedQuestions.filter((item) => item.id !== foundSubnode.id);
        updatedQuestions.splice(index, 0, foundSubnode);
      });
    }
    setQuestionnaireUpdated(true);
    setNodes(updatedNodes.filter(Boolean));
    setSelectedQuestions(updatedQuestions);
    handleSubmit(updatedQuestions, updatedNodes.filter(Boolean));
  };

  const parsedDescription = description && type !== 'consent' ? utils.renderHTMLAsText(description) : '';

  const getBackgroundColor = () => {
    if (combo === 'parent') return '#fff8de';
    if (previewQuestionId === id) return '#d6d6d6';
    return '';
  };

  return (
    <>
      <Grid item xs={combo !== 'child' ? 5 : 12} ref={(el) => (questionsRef.current[questionIndex] = el)}>
        <Card
          ref={questionWrapperRef}
          style={{
            padding: 20,
            backgroundColor: () => getBackgroundColor(),
          }}
          onClick={() => onClickQuestion(id, 'question', questionIndex)}
        >
          <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h6">
              {questionIndex + 1}.{name}
            </Typography>
            <Chip size="small" label={question.type} color="primary" />
          </Box>
          <Typography variant="body1">{parsedDescription}</Typography>
          <Typography variant="body2">{note}</Typography>

          {combo === 'parent' ? renderSubnodes(question.nodeList) : renderQuestionPreview()}

          <Grid container>
            <Grid style={{ marginTop: 20 }} item xs={combo === 'child' ? 9 : 8}>
              {autoAnswerOption && (
                <Typography variant="body2">
                  Auto answer: {autoAnswerTypes.find((item) => item.value === autoAnswerOption.type).label}
                </Typography>
              )}
            </Grid>
            <Grid item xs={1}>
              {questionIndex !== 0 && combo !== 'child' && (
                <Tooltip title="Set as first question">
                  <IconButton onClick={selectQuestionAsFirst} disabled={disableButtons}>
                    <ArrowUpward />
                  </IconButton>
                </Tooltip>
              )}
            </Grid>
            {combo !== 'child' && (
              <Grid item xs={1}>
                <Tooltip title="Jumps">
                  <IconButton onClick={handleClickJumpsIcon} disabled={disableButtons && previewQuestionId !== id}>
                    <AccountTree />
                  </IconButton>
                </Tooltip>
              </Grid>
            )}
            <Tooltip title="Edit">
              <Grid item xs={1}>
                <IconButton
                  ref={editQuestionRef}
                  onClick={() => setQuestionEdit(question, questionIndex)}
                  disabled={disableButtons}
                >
                  <Edit />
                </IconButton>
              </Grid>
            </Tooltip>
            {/* if question has annotations then show annotation icon */}
            {questionsWithAnnotations && questionsWithAnnotations.indexOf(question.id) != -1 && (
              <Tooltip title="Annotations" style={{ position: 'absolute' }}>
                <Grid item xs={1}>
                  <IconButton onClick={() => setQuestionEdit(question, questionIndex)} disabled={disableButtons}>
                    <ReportProblem style={{ color: 'red' }} />
                  </IconButton>
                </Grid>
              </Tooltip>
            )}
            {combo !== 'child' && (
              <Grid item xs={1}>
                <Tooltip title="Remove">
                  <IconButton onClick={() => removeQuestion(question.id)} disabled={disableButtons}>
                    <Delete />
                  </IconButton>
                </Tooltip>
              </Grid>
            )}
          </Grid>
        </Card>
      </Grid>

      <Grid item xs={7}>
        {previewQuestionId === id && !showJumps ? (
          <Grid container direction="column" justifyContent="center" alignItems="center">
            <Grid className={classes.questionWrapper}>
              <QuestionPreview
                formData={questionPreviewData()}
                handleChange={() => ({})}
                handleChangeWithParams={() => ({})}
                handleChangeComment={() => ({})}
                disabled
                questionnaireCommentEnabled
                previewForBuilder
                values={{}}
                questionList={questionList}
              />
            </Grid>
          </Grid>
        ) : null}

        {showJumps && previewQuestionId === id ? (
          <>
            {questionNodes.map((node, nodeIndex) => (
              <Node
                key={`node_${nodeIndex}`}
                node={node}
                nodeIndex={nodeIndex}
                handleNodeNameChange={handleNodeNameChange}
                addNode={addNode}
                removeNode={removeNode}
                setShowAutoGenerate={setShowAutoGenerate}
                updateQuestionNodes={updateQuestionNodes}
                showAutoGenerate={showAutoGenerate}
              >
                <JumpList
                  question={question}
                  selectedQuestions={selectedQuestions}
                  setShowJumps={setShowJumps}
                  currentNode={node}
                  setDisableButtons={setDisableButtons}
                  nodeIndex={nodeIndex}
                  updateQuestionNodes={updateQuestionNodes}
                  questionNodes={questionNodes}
                  showAutoGenerate={showAutoGenerate}
                  setShowAutoGenerate={setShowAutoGenerate}
                  cancelQuestionNodeUpdate={cancelNodeUpdate}
                />
              </Node>
            ))}
          </>
        ) : null}
      </Grid>
    </>
  );
}

const useStyles = makeStyles(() => ({
  questionWrapper: {
    width: 400,
    // marginTop: -55,
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
  },
}));

export default Question;
