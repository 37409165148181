/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import Grid from '@material-ui/core/Grid';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import React, { useState, useCallback, useMemo, useEffect } from 'react';

import { createInputHelper } from '../Shared/Forms/helpers/createInputHelper';

import { allergyFormMap } from './helpers/formHelper';

function AllergyDialog(props) {
  const { closeDialog, selectedAllergy, dialogMode, updateAllergy } = props;

  const allergy = {
    id: '',
    allergySystem: '',
    type: '',
    onsetDate: '',
    symptomsCode: '',
    symptomsValue: '',
    createdBy: '',
    createdAt: '',
    operationType: 'Add',
    rcopiaId: '',
  };

  const [errors, setErrors] = useState(
    dialogMode === 'Edit'
      ? {
          allergyName: false,
          allergyCode: false,
          reactionName: false,
          reactionCode: false,
          reactionSeverity: false,
          allergyCategory: false,
          verificationStatus: false,
          clinicalStatus: false,
        }
      : {
          allergyName: true,
          allergyCode: true,
          reactionName: true,
          reactionCode: true,
          reactionSeverity: true,
          allergyCategory: true,
          verificationStatus: true,
          clinicalStatus: true,
        }
  );
  const [allergyData, setAllergyData] = useState(dialogMode === 'Edit' ? selectedAllergy : allergy);
  const [formValid, setFormValid] = useState(false);

  // FHIR Allergy Severity standards as described by
  // http://hl7.org/fhir/R4/valueset-reaction-event-severity.html

  useEffect(() => {
    setFormValid(
      allergyData.allergyName &&
        allergyData.allergyCategory &&
        allergyData.reactionName &&
        allergyData.reactionSeverity &&
        allergyData.onsetDate &&
        allergyData.clinicalStatus &&
        allergyData.verificationStatus
    );
  }, [allergyData]);

  const updateData = useCallback(
    (name, value) => {
      const localErrors = errors;
      const localAllergyData = allergyData;
      switch (name) {
        case 'allergyName':
          localErrors.allergyName = value.length <= 0;
          localAllergyData.allergyName = value;
          break;
        case 'allergyCode':
          localErrors.allergyCode = value.length <= 0;
          localAllergyData.allergyCode = value;
          break;
        case 'allergyCategory':
          localErrors.allergyCategory = value.length <= 0;
          localAllergyData.allergyCategory = value;
          break;
        case 'reactionName':
          localErrors.reactionName = value.length <= 0;
          localAllergyData.reactionName = value;
          break;
        case 'reactionCode':
          localErrors.reactionCode = value.length <= 0;
          localAllergyData.reactionCode = value;
          break;
        case 'reactionSeverity':
          localErrors.reactionSeverity = value.length <= 0;
          localAllergyData.reactionSeverity = value;
          break;
        case 'verificationStatus':
          localErrors.verificationStatus = value.length <= 0;
          localAllergyData.verificationStatus = value;
          break;
        case 'clinicalStatus':
          localErrors.clinicalStatus = value.length <= 0;
          localAllergyData.clinicalStatus = value;
          break;
        case 'onsetDate':
          localAllergyData.onsetDate = value || '';
          break;
        case 'symptomsValue':
          localAllergyData.symptomsValue = value;
          break;
        case 'symptomsCode':
          localAllergyData.symptomsCode = value;
          break;
        case 'notes':
          localAllergyData.notes[0].message = value;
          break;
        default:
          break;
      }
      setAllergyData((prevState) => ({ ...prevState, ...localAllergyData }));
      setErrors((prevState) => ({ ...prevState, ...localErrors }));
    },
    [allergyData, errors]
  );

  const submit = () => {
    const localAllergyData = allergyData;
    for (const key in errors) {
      if (errors[key] !== null && errors[key] !== false) return;
    }
    if (dialogMode === 'Edit') {
      localAllergyData.operationType = 'Edit';
    } else if (dialogMode === 'Add') {
      localAllergyData.operationType = 'Add';
    }
    setAllergyData((prevState) => ({ ...prevState, ...localAllergyData }));
    updateAllergy(localAllergyData);
    closeDialog();
  };

  const handleCancel = () => {
    closeDialog();
  };

  const handleAutoSetValue = useCallback(
    (values) => {
      for (const key in values) {
        updateData(key, values[key]);
      }
    },
    [updateData]
  );

  const handleChangeWithParams = useCallback(
    (name, value) => {
      if (name === 'allergyName') {
        if (value) updateData(name, value.allergyDescription);
        else updateData(name, '');
      } else if (name === 'reactionName') {
        if (value) updateData(name, value.term);
        else updateData(name, '');
      } else {
        updateData(name, value);
      }
    },
    [updateData]
  );

  const handleChangeTypahead = useCallback(
    (event) => {
      const { name } = event.target;
      const { value } = event.target;
      updateData(name, value);
    },
    [updateData]
  );

  const formFields = useMemo(() => allergyFormMap(dialogMode), []);

  return (
    <Dialog title={`${dialogMode} Allergy`} onClose={handleCancel} width={800}>
      <Grid container spacing={2}>
        {createInputHelper(formFields, allergyData, handleChangeTypahead, handleChangeWithParams, handleAutoSetValue)}
      </Grid>
      <DialogActionsBar>
        <button className="k-button" onClick={handleCancel}>
          Cancel
        </button>
        <button className="k-button" onClick={submit} disabled={!formValid}>
          {dialogMode === 'Add' ? 'Add' : 'Update'}
        </button>
      </DialogActionsBar>
    </Dialog>
  );
}

export default AllergyDialog;
