import React, { useState, useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { showConfirmation } from '../Confirmation/confirmationSlice';

// helpers and components
import { selectCurrentTenant } from '../Shared/selectors';
import ActionsCell from '../Shared/Table/ActionsCell';
import { toggleEnabled, updateContent } from './contentSlice';
import { createContentJsonBuilder } from './helpers/createContentJsonBuilder';

function ActionsCellWrapper(props) {
  const { type, dataItem, contentSection, actions } = props;
  const [loading, setLoading] = useState(false);
  const currentTenant = useSelector(selectCurrentTenant);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const menuItems = ['edit', 'delete'];
  if (type !== 'introWidgets' && type !== 'messageStandardizations') {
    menuItems.push('enabler');
  }

  const handleActionClick = useCallback(
    (selection) => {
      const { _id: contentId } = dataItem;

      if (selection === 'edit') {
        const parentPath = window.location.pathname.match(/\/$/)
          ? window.location.pathname
          : `${window.location.pathname}/`;
        navigate(`${parentPath}details/${contentId}`);
      } else if (selection === 'delete') {
        dispatch(
          showConfirmation(
            'Confirm',
            'Are you sure you want to delete this content?',
            type == 'messageStandardizations' ? 'messageDelete' : 'contentDelete',
            currentTenant.id,
            contentId,
            type
          )
        );
      } else if (selection === 'enabler') {
        setLoading(true);
        const { publish } = dataItem;
        const updatedStatus = !publish;
        const updatedDataItem = { ...dataItem };
        updatedDataItem['publish'] = updatedStatus;
        let data = createContentJsonBuilder[type](updatedDataItem);
        dispatch(updateContent(type, currentTenant.id, data));
        dispatch(toggleEnabled(type, contentId, updatedStatus));
        setLoading(false);
      }
    },
    [actions, contentSection, currentTenant, dataItem, type]
  );

  return (
    <ActionsCell
      dataItem={dataItem}
      statusFieldName={'publish'}
      menu={menuItems}
      handleActionClick={handleActionClick}
      enablerPositiveLabel={'Publish'}
      enablerNegativeLabel={'Unpublish'}
      loading={loading}
    />
  );
}

export default ActionsCellWrapper;
