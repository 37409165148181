// mui
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React from 'react';

import Previews from '../Shared/Forms/KendoFields/ImageUploadCrop';
import TextFieldInput from '../Shared/Forms/KendoFields/TextFieldInput';

function NetworkAssetsWrapper(props) {
  const { field, handleChange, disabled, value } = props;
  return (
    <Grid container spacing={6}>
      <Grid item xs={12} md={5}>
        <Typography variant="subtitle1" style={{ marginBottom: 20 }}>
          {field.label}
        </Typography>
        <Typography variant="caption" style={{ opacity: 0.7 }}>
          {field.description}
        </Typography>
        {field.url && (
          <TextFieldInput
            field={field.url}
            value={value[field.url.name]}
            handleChange={handleChange}
            disabled={disabled}
          />
        )}
      </Grid>
      <Grid item xs={12} md={7}>
        <Previews field={field} value={value[field.name]} handleChange={handleChange} disabled={disabled} />
      </Grid>
    </Grid>
  );
}

export default NetworkAssetsWrapper;
