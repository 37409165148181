import { createSlice } from '@reduxjs/toolkit';

import api from '../../api';
import { handleError } from '../Shared/Content/APIUtils';

const initialState = {
  accountId: null,
  accountLoading: true,
  accountValid: false,
  defaultTenantId: '',
  error: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAccountId: (state, { payload }) => ({
      ...state,
      accountId: payload,
    }),
    isAccountValid: (state, { payload }) => ({
      ...state,
      accountValid: payload.isOK,
      accountId: payload.accountId,
      defaultTenantId: payload.data,
      accountLoading: false,
    }),
    setAccountLoading: (state, { payload }) => ({
      ...state,
      accountLoading: payload,
    }),
    setAccountError: (state, { payload }) => ({
      ...state,
      error: payload,
    }),
  },
});

export const { setAccountId, setAccountLoading, isAccountValid, setAccountError } = authSlice.actions;

export const validateAccountId = (accountId) => async (dispatch) => {
  dispatch(setAccountLoading(true));

  const [result, error] = await api.validateAccountIdRequest(accountId);
  if (result && !result.errorMessage) {
    result.accountId = accountId;
    dispatch(setAccountError(undefined));
    dispatch(isAccountValid(result));
  } else if (result && result.errorMessage) {
    dispatch(setAccountLoading(false));
    dispatch(setAccountError(result.errorMessage));
  }

  if (error && error.message) {
    dispatch(setAccountError(undefined));
    dispatch(setAccountLoading(false));
    dispatch(handleError(error, null, false));
  }
};

export default authSlice.reducer;
