/* eslint-disable react/prefer-stateless-function */
/* eslint-disable max-classes-per-file */
// TODO: This entire file needs to be rewriten to use pure function components instead of classes
import React from 'react';
import ReactPlayer from 'react-player';

import createDOMPurify from "dompurify";
const DOMPurify = createDOMPurify(window);

import utils from '../Utils/utils';

export function html_encode(str) {
  const div = document.createElement('div');
  div.innerHTML = str;
  return div;
}

export class htmlCell extends React.Component {
  render() {
    const iframe = {
      width: '100%',
      height: '100%',
      border: 0,
      backgroundColor: 'white',
    };
    return (
      <td colSpan={this.props.colSpan} className={this.props.className} style={this.props.style}>
        <iframe
          src={utils.createIFrameContentData(this.props.dataItem[this.props.field])}
          id="iframe"
          style={iframe}
          title="HTML content"
        />
      </td>
    );
  }
}

export class linkCell extends React.Component {
  render() {
    return (
      <td colSpan={this.props.colSpan} className={this.props.className} style={this.props.style}>
        <div style={{ wordWrap: 'break-word' }}>
          <a target="_blank" rel="noopener noreferrer" href={html_encode(this.props.dataItem[this.props.field])}>
            {this.props.dataItem[this.props.field]}
          </a>
        </div>
      </td>
    );
  }
}

export const withWidth = (WrappedComponent, width) =>
  class extends React.Component {
    render() {
      return <WrappedComponent {...this.props} width={width} />;
    }
  };

export class imageCell extends React.Component {
  render() {
    const imageStyle = {
      width: 50,
      height: 50,
      objectFit: 'cover',
    };

    return (
      <td colSpan={this.props.colSpan} className={this.props.className} style={this.props.style}>
        {this.props.dataItem[this.props.field] && (
          <img style={imageStyle} src={this.props.dataItem[this.props.field]} alt={this.props.dataItem.altText} />
        )}
      </td>
    );
  }
}

export class titleCell extends React.Component {
  render() {
    return (
      <td
        colSpan={this.props.colSpan}
        className={this.props.className}
        style={this.props.style}
      >
        <span
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(this.props.dataItem.title),
          }}
        />
      </td>
    );
  }
}

export class videoCell extends React.Component {
  render() {
    return (
      <td colSpan={this.props.colSpan} className={this.props.className} style={this.props.style}>
        <div className="player-wrapper">
          <ReactPlayer
            url={this.props.dataItem[this.props.field]}
            className="react-player"
            width={this.props.width || '100%'}
            height="100%"
            controls
            volume
            pip
          />
        </div>
      </td>
    );
  }
}

export const withMapping = (WrappedComponent, mapping) =>
  class extends React.Component {
    render() {
      return <WrappedComponent {...this.props} mapping={mapping} />;
    }
  };

export const tag = (props) => {
  let background;
  let color;
  let field;

  if (props.mapping) {
    const item = props.mapping[props.dataItem[props.field]];
    background = item ? item.background : '';
    color = item ? item.color : '';
    field = item ? item.label : '';
  } else {
    field = props.dataItem[props.field];
  }

  return (
    <td colSpan={props.colSpan} className={props.className} style={props.style}>
      <span
        style={{
          padding: '0 5px',
          textAlign: 'center',
          borderRadius: 5,
          opacity: 0.8,
          background,
          color,
          minWidth: 65,
          display: 'inline-block',
          fontWeight: 'bold',
        }}
      >
        {field}
      </span>
    </td>
  );
};

export const color = (props) => (
  <td colSpan={props.colSpan} className={props.className} style={props.style}>
    <span
      style={{
        padding: '0 5px',
        borderRadius: 5,
        border: 'solid 1px grey',
        textAlign: 'center',
        background: props.dataItem[props.field] || undefined,
        width: 20,
        height: 20,
        display: 'inline-block',
      }}
    />
  </td>
);

export const withOnChange = (WrappedComponent, onChange, disabled) =>
  class extends React.Component {
    render() {
      return <WrappedComponent {...this.props} onChange={onChange} disabled={disabled} />;
    }
  };

export const tableSelection = (TableComponent, tenant, type, selected, handleSelection, disabled) =>
  class extends React.Component {
    render() {
      return (
        <TableComponent
          {...this.props}
          handleSelectionChange={handleSelection}
          selected={selected}
          currentTenant={tenant}
          type={type}
          multiSelection
          hideButtons
          disabled={disabled}
        />
      );
    }
  };

export const withCustomProp = (WrappedComponent, propName, propValue) =>
  class extends React.Component {
    render() {
      const props = {
        ...this.props,
        [propName]: propValue,
      };
      return <WrappedComponent {...props} />;
    }
  };

export const withCustomMultiProp = (WrappedComponent, customProps) =>
  class extends React.Component {
    render() {
      let props = { ...this.props };
      if (customProps && Object.keys(customProps).length) {
        props = { ...props, ...customProps };
      }
      return <WrappedComponent {...props} />;
    }
  };
