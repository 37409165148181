import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { createTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import React, { useState } from 'react';

const positiveTheme = createTheme({
  palette: {
    primary: { main: '#D63535' },
    secondary: { main: '#003C71' },
  },
});

const equivocalTheme = createTheme({
  palette: {
    primary: { main: '#FA9C13' },
    secondary: { main: '#003C71' },
  },
});

const negativeTheme = createTheme({
  palette: {
    primary: { main: '#7ED321' },
    secondary: { main: '#003C71' },
  },
});

function TestResultField({ onChange, disabled }) {
  const [selected, setSelected] = useState(undefined);
  const classes = useStyles();
  const onChangeResult = (v) => {
    setSelected(v);
    onChange(v);
  };

  return (
    <Grid container className={classes.content}>
      <Grid item style={{ padding: 0 }}>
        <ThemeProvider theme={positiveTheme}>
          <Button
            variant="outlined"
            color={selected === 'POSITIVE' ? 'primary' : 'secondary'}
            disabled={disabled}
            style={{ width: 160, height: 45 }}
            onClick={() => onChangeResult('POSITIVE')}
          >
            Positive
          </Button>
        </ThemeProvider>
      </Grid>
      <Grid item style={{ padding: 0 }}>
        <ThemeProvider theme={equivocalTheme}>
          <Button
            variant="outlined"
            color={selected === 'INCONCLUSIVE' ? 'primary' : 'secondary'}
            disableElevation
            elevation={0}
            disabled={disabled}
            style={{ width: 160, height: 45 }}
            onClick={() => onChangeResult('INCONCLUSIVE')}
          >
            Invalid
          </Button>
        </ThemeProvider>
      </Grid>
      <Grid item style={{ padding: 0 }}>
        <ThemeProvider theme={negativeTheme}>
          <Button
            variant="outlined"
            color={selected === 'NEGATIVE' ? 'primary' : 'secondary'}
            disableElevation
            elevation={0}
            disabled={disabled}
            style={{ width: 160, height: 45 }}
            onClick={() => onChangeResult('NEGATIVE')}
          >
            Negative
          </Button>
        </ThemeProvider>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  content: {
    justifyContent: 'space-between',
    direction: 'row',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      gridGap: 15,
    },
  },
}));

export default TestResultField;
