import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React, { useState, useEffect } from 'react';
import utils from '../../Utils/utils';
// eslint-disable-next-line import/no-cycle
import { createInputHelper } from '../helpers/createInputHelper';
import { stateList, countryList } from '../helpers/stateCountryHelper';

function AddressInput(props) {
  const { field, handleChangeWithParams, selectedLanguage } = props;

  const [currentLanguageDescription, setCurrentLanguageDescription] = useState();
  const [currentValue, setCurrentValue] = useState();

  // Map address form using existing input types
  const addressForm = [
    {
      type: 'text',
      name: 'line1',
      label: 'Address Line 1',
      width: 12,
      rows: 1,
      required: true,
    },
    {
      type: 'text',
      name: 'line2',
      label: 'Address Line 2',
      width: 12,
      rows: 1,
      required: false,
    },
    {
      type: 'text',
      name: 'city',
      label: 'City',
      width: 12,
      rows: 1,
      required: true,
    },
    {
      type: 'autocomplete',
      name: 'state',
      label: 'State',
      enum: stateList,
      required: false,
      width: 6,
      labelField: 'name',
      valueField: 'abbreviation',
    },
    {
      type: 'zip',
      name: 'zip',
      label: 'Zip/Postal Code',
      width: 6,
      rows: 1,
      required: false,
    },
    {
      type: 'autocomplete',
      name: 'country',
      label: 'Country',
      enum: countryList,
      required: false,
      width: 12,
    },
  ];

  // Set values for currently selected language
  useEffect(() => {
    if (field && field.description) {
      const newLanguageName =
        selectedLanguage && field.description && field.description.translations
          ? field.description.translations[selectedLanguage]
          : field.description;
      setCurrentLanguageDescription(newLanguageName);
    }
  }, [selectedLanguage, field]);

  // Change handler
  const onChange = (e, value) => {
    const name = e?.target?.name || e;
    if (!value) {
      value = e?.target?.value || null;
    }
    const updatedState = { ...currentValue, [name]: value };
    setCurrentValue(updatedState);
    handleChangeWithParams(name, updatedState);
  };

  if (!field.name && !field.label) return <div />;

  return (
    <div>
      <Grid container style={{ position: 'relative' }}>
        <Grid item xs={12}>
          <Typography style={{ opacity: 0.5, marginBottom: 10 }}>
            {field.questionPreview ? utils.renderHTMLTags(currentLanguageDescription) : currentLanguageDescription}
          </Typography>
        </Grid>
        {createInputHelper(addressForm, {}, onChange, onChange)}
      </Grid>
    </div>
  );
}

export default AddressInput;
