import { EditorTools } from '@progress/kendo-react-editor';
import React from 'react';

const fontSizeToolSettings = {
  style: 'font-size',
  defaultItem: { text: 'Font Size', value: '' },
  items: [
    { text: '1 (8pt)', value: '8pt' },
    { text: '2 (10pt)', value: '10pt' },
    { text: '3 (12pt)', value: '12pt' },
    { text: '4 (14pt)', value: '14pt' },
    { text: '5 (18pt)', value: '18pt' },
    { text: '6 (20pt)', value: '20pt' },
    { text: '7 (22pt)', value: '22pt' },
    { text: '8 (24pt)', value: '24pt' },
    { text: '9 (36pt)', value: '36pt' },
  ],
};

// Creates FontSize tool
const MyFontSizeTool = EditorTools.createStyleDropDownList(fontSizeToolSettings);

// Styles the FontSize tool
function CustomFontSize(props) {
  return <MyFontSizeTool {...props} />;
}

export { CustomFontSize };
