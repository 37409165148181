import jwt_decode from 'jwt-decode';

export const getUserFromToken = () => {
  const token = localStorage.getItem('id_token');
  if (!token || token === 'null' || token === 'undefined') return null;
  return jwt_decode(token);
};

export const randomString = (length = 32) => {
  const bytes = new Uint8Array(length);
  const result = [];
  const charset = '0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz-._~';

  const { crypto } = window;
  const random = crypto.getRandomValues(bytes);
  for (let i = 0; i < random.length; i += 1) result.push(charset[random[i] % charset.length]);

  return result.join('');
};
