export const createContentJsonBuilder = {
  articles: (state) => ({
    Data: {
      _id: state._id,
      title: state.title,
      html: state.html,
      imageURL: state.imageURL,
      externalLinkURL: state.externalLinkURL,
      status: state.status,
      subtitle: state.subtitle,
      videoFile: state.videoFile,
      videoThumbnail: state.videoThumbnail,
      author: state.author,
      owner: state.owner,
      category: state.category,
      photoCredit: state.photoCredit,
      videoCredit: state.videoCredit,
      publish: state.publish,
      keywords: state.keywords,
      copyright: state.copyright,
      language: state.language,
    },
  }),
  blogs: (state) => ({
    Data: {
      _id: state._id,
      title: state.title,
      html: state.html,
      imageURL: state.imageURL,
      externalLinkURL: state.externalLinkURL,
      status: state.status,
    },
  }),
  introWidgets: (state) => ({
    Data: {
      _id: state._id,
      status: state.status,
      networkID: state.networkID,
      order: state.order,
      title: state.title,
      html: state.html,
      imageURL: state.imageURL,
      externalLinkURL: state.externalLinkURL,
      type: state.type,
      videoURL: state.videoURL,
      widgetKey: state.widgetKey,
      showSupportLink: state.showSupportLink,
      buttons: state.buttons,
      description: state.description,
      backgroundColor: state.backgroundColor,
      navigationTitle: state.navigationTitle,
      language: state.language || 'en',
    },
  }),
  messageStandardizations: (state) => ({
    _id: state._id,
    status: state.status,
    networkID: state.networkID,
    order: state.order,
    title: state.title,
    html: state.html,
    imageUrl: state.imageUrl,
    externalLinkURL: state.externalLinkURL,
    type: state.type,
    videoUrl: state.videoUrl,
    widgetKey: state.widgetKey,
    showSupportLink: state.showSupportLink,
    buttons: state.buttons,
    description: state.description,
    backgroundColor: state.backgroundColor,
    navigationTitle: state.navigationTitle,
    language: state.language || 'en',
  }),
  resources: (state) => ({
    Data: {
      _id: state._id,
      title: state.title,
      html: state.html,
      imageURL: state.imageURL,
      externalLinkURL: state.externalLinkURL,
      status: state.status,
    },
  }),
  videos: (state) => ({
    Data: {
      _id: state._id,
      title: state.title,
      size: state.size,
      networkID: state.networkID,
      description: state.description,
      mediaURL: state.mediaURL,
      thumbnailURL: state.thumbnailURL,
      largeImageURL: state.largeImageURL,
      status: state.status,
      isResource: state.isResource || false,
      owner: state.owner,
      wellness: state.wellness,
      publish: state.publish,
      featured: state.featured,
      keywords: state.keywords,
      externalLinkURL: state.externalLinkURL,
      urlLink: state.urlLink,
      embedCode: state.embedCode,
      language: state.language,
    },
  }),
};
