/* eslint-disable react/no-array-index-key */
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
// imports
import { filterBy } from '@progress/kendo-data-query';
import { Pager, TextFilter } from '@progress/kendo-react-data-tools';
import { DialogActionsBar } from '@progress/kendo-react-dialogs';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import React, { useEffect, useState } from 'react';

import CreateOrderDialogStyles from '../../assets/jss/components/CreateOrderDialogStyles';
import AppSearchBar from '../Shared/Searchbar';

import { checkMandatoryFields } from './helpers/ordersUtils';

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function CreateOrderDialog(props) {
  const {
    classes,
    dialogOpen,
    handleTextChange,
    handleCancelOrder,
    users,
    orderableTests,
    handleOrderableTestChange,
    handleCreateOrder,
    userSpecific,
    userDetails,
    selectedOrderableTest,
    filters,
    handleFilters,
    page,
    pageSize,
    handlePageChange,
    userSegmentFilter,
  } = props;
  const [missingData, setMissingData] = useState([]);
  const [autocompleteValue, setAutocompleteValue] = useState(undefined);
  const [data, setData] = useState(
    orderableTests &&
      orderableTests.map((ot) => ({
        ...ot,
        fullName: `${ot.vendorName} / ${ot.longName ? ot.longName : ot.shortName}`,
      }))
  );
  const [loading, setLoading] = useState(false);
  const [timeout, setStateTimeout] = useState(undefined);
  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState([]);
  const [right, setRight] = React.useState([]);
  const [newUserToggle, setNewUserToggle] = React.useState(false);
  const [mandatoryFields] = React.useState([
    'nationalHealthId',
    'firstName',
    'lastName',
    'birthDate',
    'gender',
    'line1',
    'city',
    'state',
    'zip',
  ]);
  const disabledSave = (!userSpecific && right.length === 0) || !selectedOrderableTest;
  const leftChecked = intersection(checked, not(left, right));
  const rightChecked = intersection(checked, right);

  useEffect(() => {
    const userData = [];
    // eslint-disable-next-line no-unused-expressions
    users &&
      users.items &&
      users.items.map((u) => userData.push(`${u.firstName || ''} ${u.lastName || ''} <${u.email}>`));
    setLeft(userData);
    if (userSpecific && userDetails) {
      checkMandatoryFields(mandatoryFields, userDetails.email, [userDetails], userSpecific, setMissingData);
    }
  }, [users, userDetails, userSpecific, mandatoryFields]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleCancel = () => {
    setMissingData([]);
    handleCancelOrder();
  };

  // when rendering user list, check if user has already been selected to disable with text on left transfer list
  const checkUserSelection = (userId) => {
    if (right.includes(userId)) {
      return true;
    }
  };

  // handles selection of order autocomplete combobox
  const handleChange = (e) => {
    const { value } = e.target;
    setAutocompleteValue(value);
    handleOrderableTestChange(value);
  };

  // handles filtering of orders autocomplete combobox
  const filterData = (filter) => {
    if (!orderableTests) return [];

    const allData = orderableTests
      .map((ot) => ({
        ...ot,
        fullName: `${ot.vendorName} / ${ot.longName ? ot.longName : ot.shortName}`,
      }))
      .slice();
    if (filter.field) {
      return filterBy(allData, filter);
    }
    const regex = new RegExp(filter.value, 'i');
    return allData.filter((s) => s.search(regex) > -1);
  };

  const handleFilterChange = (event) => {
    if (timeout) {
      clearTimeout(timeout);
    }

    setStateTimeout(
      setTimeout(() => {
        const newData = filterData(event.filter);
        setData(newData);
        setLoading(false);
      }, 500)
    );

    setLoading(true);
  };

  const customList = (title, items) => (
    <Card>
      <CardHeader className={classes.cardHeader} title={<Typography variant="subtitle1">{title}</Typography>} />
      <Divider />
      {title === 'Users' && (
        <div>
          <Grid container justifyContent="center" style={{ padding: 10 }}>
            <Grid item xs={12}>
              <AppSearchBar
                handleTextChange={handleTextChange}
                placeholder={`Search ${title}`}
                disableAutoFocus
                width={12}
                handleClear={() => handleTextChange({ target: { value: '' } })}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ padding: '10px 10px' }}>
            <ComboBox
              placeholder="User Segments"
              data={filters || []}
              type={TextFilter}
              onChange={handleFilters}
              style={{ width: '100%' }}
            />
          </Grid>
        </div>
      )}
      <List className={title === 'Selected' ? classes.selectedList : classes.list} dense component="div" role="list">
        {items.map((value, i) => {
          const labelId = `transfer-list-all-item-${value}-label`;
          const missingFields = checkMandatoryFields(mandatoryFields, value, users.items, userSpecific, setMissingData);
          const selectedUser = checkUserSelection(value);
          return (
            <ListItem
              key={value + i}
              role="listitem"
              button
              onClick={handleToggle(value)}
              disabled={title === 'Users' && (missingFields.length > 0 || selectedUser)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={
                    title === 'Users'
                      ? checked.indexOf(value) !== -1 && !rightChecked.includes(value)
                      : checked.indexOf(value) !== -1
                  }
                  tabIndex={-1}
                  disableRipple
                  color="primary"
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText
                id={labelId}
                primary={value}
                secondary={
                  title === 'Users' &&
                  (selectedUser || missingFields.length > 0) && (
                    <Typography color="error" variant="caption">
                      {selectedUser ? 'User Selected' : `Missing fields: ${missingFields.join(', ')}`}
                    </Typography>
                  )
                }
              />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
      {title === 'Users' && (
        <Pager
          skip={page * pageSize}
          take={pageSize}
          total={
            users && users.items && !userSegmentFilter && right.length && users.total - right.length >= 0
              ? users.total - right.length
              : users.total
          }
          buttonCount={5}
          info
          type="numeric"
          previousNext
          onPageChange={handlePageChange}
        />
      )}
    </Card>
  );

  const testCombo = (width) => (
    <Grid container style={{ padding: '0px 40px 20px' }}>
      <Grid item md={width} xs={12}>
        <ComboBox
          data={data || []}
          allowCustom={false}
          suggest
          filterable
          onFilterChange={handleFilterChange}
          label="Test Type"
          name="testType"
          required
          onChange={handleChange}
          style={{ width: '100%' }}
          textField="fullName"
          value={autocompleteValue}
          loading={loading}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl component="fieldset">
          <FormGroup aria-label="position" row>
            <FormControlLabel
              value="top"
              control={
                <Switch
                  checked={newUserToggle}
                  onChange={() => setNewUserToggle(!newUserToggle)}
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              }
              label={
                <Typography variant="body2">Only place order for users with no previous orders or results.</Typography>
              }
              labelPlacement="end"
            />
          </FormGroup>
        </FormControl>
      </Grid>
    </Grid>
  );

  return (
    <Dialog onClose={handleCancel} open={dialogOpen} maxWidth={!userSpecific ? 'xl' : 'sm'} fullWidth>
      <DialogTitle id="simple-dialog-title">
        New Test Order
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleCancel}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {userSpecific && userDetails ? (
          <Grid container alignItems="center" direction="column">
            <Grid item>
              <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                {`${userDetails.firstName} ${userDetails.lastName} <${userDetails.email}>`}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {missingData.length > 0 ? (
                <Grid container style={{ paddingTop: 10 }} justifyContent="center" spacing={4}>
                  <Grid item>
                    <Typography variant="caption" color="error">
                      Lab order cannot be processed for this user due to the following missing information:
                    </Typography>
                    <br />
                    <br />
                    <Typography variant="caption" color="error">
                      {missingData.join(', ')}
                    </Typography>
                  </Grid>
                </Grid>
              ) : (
                testCombo(12)
              )}
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={1} justifyContent="center" alignItems="center" className={classes.root}>
            {testCombo(5)}
            <Grid item md={6} xs={12}>
              {customList('Users', left)}
            </Grid>
            <Grid item>
              <Grid container direction="column" alignItems="center">
                <Button
                  variant="outlined"
                  size="small"
                  className={classes.button}
                  onClick={handleCheckedRight}
                  disabled={leftChecked.length === 0}
                  aria-label="move selected right"
                >
                  &gt;
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  className={classes.button}
                  onClick={handleCheckedLeft}
                  disabled={rightChecked.length === 0}
                  aria-label="move selected left"
                >
                  &lt;
                </Button>
              </Grid>
            </Grid>
            <Grid item md={5} xs={12}>
              {customList('Selected', right)}
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActionsBar>
        <Grid container justifyContent="center">
          <Button
            variant="contained"
            color="primary"
            disableElevation
            elevation={0}
            className={classes.actionButton}
            onClick={() => handleCreateOrder(right, newUserToggle)}
            disabled={disabledSave || missingData.length > 0}
          >
            Create Order
          </Button>
        </Grid>
      </DialogActionsBar>
    </Dialog>
  );
}

export default withStyles(CreateOrderDialogStyles)(CreateOrderDialog);
