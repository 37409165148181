import { makeStyles } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import SidebarStyles from '../../assets/jss/components/SidebarStyles';
import { selectAuthToken } from '../Auth/selectors';
import { selectCurrentTenant } from '../Shared/selectors';
import utils from '../Shared/Utils/utils';
import SidebarList from './SidebarList';
import { SidebarSkeleton } from './SidebarSkeleton';

function Sidebar() {
  const classes = useStyles();
  const currentTenant = useSelector(selectCurrentTenant);
  const isLoggedIn = useSelector(selectAuthToken);

  const [open, setOpen] = useState(false);

  const handleMouseOver = () => {
    setOpen(true);
  };

  const handleMouseLeave = () => {
    setOpen(false);
  };

  if (!localStorage.getItem('id_token') || !currentTenant || currentTenant === 'None') {
    if (isLoggedIn) {
      return <SidebarSkeleton />;
    }
    return <div />;
  }

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
    >
      <Divider />
      <Grid container justifyContent="space-between" style={{ height: '100%' }}>
        <Grid item xs={12}>
          <SidebarList onMouseLeave={handleMouseLeave} open={open} />
        </Grid>
        <Grid
          item
          xs={12}
          style={{ marginBlockStart: 'auto', marginBottom: '40px', color: '#7E7E7E', textAlign: 'center' }}
        >
          <Typography variant="caption" align="center" className={classes.version}>
            {utils.appVersion()}
          </Typography>
        </Grid>
      </Grid>
    </Drawer>
  );
}

const useStyles = makeStyles(SidebarStyles);

export default Sidebar;
