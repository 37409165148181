import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Input } from '@progress/kendo-react-inputs';
import React, { useState, useEffect, useRef } from 'react';

import styles from '../../assets/Questionnaire.module.css';
import utils from '../utils/utils';

function MultipleTextInput(props) {
  const { field, value: initialValue, handleChangeWithParams, disabled, formData, selectedLanguage } = props;
  const [currentValue, setCurrentValue] = useState([]);
  const masktextfield = useRef(null);

  const fieldDisabled = disabled || field.readOnly || (field.disableIfFalse && !formData[field.disableIfFalse]);

  // disable autocomplete through reference
  useEffect(() => {
    if (masktextfield && masktextfield.current) {
      const input = masktextfield.current.element;
      input.autocomplete = 'new-password';
    }
  }, [masktextfield]);

  useEffect(() => {
    if (initialValue && initialValue !== currentValue) {
      setCurrentValue(initialValue);
    } else if (!initialValue) {
      const fieldObjects =
        field &&
        field.fields &&
        field.fields.map((field) => ({
          id: field.id,
        }));
      setCurrentValue(fieldObjects);
    }
    // eslint-disable-next-line
  }, [initialValue, field.fields]);

  const onChange = (evt) => {
    const { value } = evt.target;
    const currentValueSet = [...currentValue];
    const answerObject = {
      id: evt.target.name.split('-')[0],
      value,
    };
    const currentIndex = currentValueSet.findIndex((entry) => entry.id === answerObject.id);
    if (currentIndex > -1) {
      currentValueSet[currentIndex] = answerObject;
    } else {
      currentValueSet.push(answerObject);
    }
    setCurrentValue(currentValueSet);
    handleChangeWithParams(field.name, currentValueSet);
  };

  const fieldTitle =
    selectedLanguage && field.name && field.name.translations
      ? field.name.translations[selectedLanguage]
      : field.label || field.name;
  const questionDescription =
    selectedLanguage && field.description && field.description.translations
      ? field.description.translations[selectedLanguage]
      : field.description;

  if (!field.name) return <div />;

  const getInputLabel = (field) => {
    if (field['fieldName-eng'] && selectedLanguage) return field[`fieldName-${selectedLanguage}`];
    if (field['fieldName-eng']) return field['fieldName-eng'];
    if (field.name && field.name.translations) {
      if (selectedLanguage) return field.name.translations[selectedLanguage];
      return field.name.translations;
    }
  };

  return (
    <div>
      <Grid item xs={12} style={{ width: '100%' }}>
        {fieldTitle && (
          <Typography className={field.nameStyle ? styles[`title--${field.nameStyle}`] : styles['title--style1']}>
            {field.questionPreview ? utils.renderHTMLTags(fieldTitle) : fieldTitle}
            {field.required && '*'}
          </Typography>
        )}
        {field.image && (
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <img
              src={field.image}
              alt={fieldTitle}
              style={{
                display: 'block',
                marginLeft: 'auto',
                marginRight: 'auto',
                width: '90%',
              }}
            />
          </Grid>
        )}
        {questionDescription && (
          <Typography
            className={
              field.descriptionStyle ? styles[`description--${field.descriptionStyle}`] : styles['description--style1']
            }
          >
            {utils.renderHTMLTags(questionDescription)}
          </Typography>
        )}
      </Grid>

      <Grid container style={{ position: 'relative' }}>
        {field &&
          field.fields &&
          field.fields.map((field, idx) => (
            // eslint-disable-next-line react/no-array-index-key
            <Grid key={`${field}-${idx}`} item xs={12}>
              <Input
                width="100%"
                value={currentValue?.length && currentValue[idx]?.value}
                onChange={(evt) => onChange(evt, idx)}
                required={(!initialValue || !currentValue) && field.required}
                label={getInputLabel(field)}
                name={`${field.id}`}
                pattern={field.pattern}
                style={{ width: '100%' }}
                disabled={fieldDisabled}
                maxLength={field.maxLength}
                autoComplete="new-password"
                type="text"
              />
            </Grid>
          ))}
      </Grid>
    </div>
  );
}

export default MultipleTextInput;
