import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { MaskedTextBox, Input } from '@progress/kendo-react-inputs';
import { Error } from '@progress/kendo-react-labels';
import React, { useState, useEffect, useRef } from 'react';

// redux
import { useSelector } from 'react-redux';

import utils from '../../Utils/utils';
import { selectFormData } from '../selectors';
// ui reflection of file view

const styles = () => ({
  buttonVisibleWrapper: {
    position: 'absolute',
    right: 0,
    bottom: '6px',
  },
  buttonVisible: {
    padding: '0',
  },
  input: {
    paddingRight: '30px',
  },
});

function TextFieldInput(props) {
  const { field, value: initialValue, classes, handleChange, disabled, selectedLanguage } = props;
  const formData = useSelector(selectFormData);

  const [currentValue, setCurrentValue] = useState(initialValue || '');
  const [valid, setValid] = useState(true);
  const [scriptExecutionError, setScriptExecutionError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [sensitiveVisible, setSensitiveVisible] = useState(false);
  const masktextfield = useRef(null);
  const re = /^(?!(\d)\1{9})(?!0123456789|1234567890|0987654321|9876543210)\d{10}$/;
  const fieldDisabled = disabled || field.readOnly || (field.disableIfFalse && !formData[field.disableIfFalse]);

  // disable autocomplete through reference
  useEffect(() => {
    if (masktextfield && masktextfield.current) {
      const input = masktextfield.current.element;
      input.autocomplete = 'new-password';
    }
  }, [masktextfield]);

  useEffect(() => {
    if (initialValue !== currentValue) {
      setCurrentValue(initialValue || '');
    }
    if (!initialValue && field.required) {
      setValid(false);
    }
    if (initialValue && field.mask === '(999) 999-9999') {
      checkPhoneValidity(initialValue);
    }
    if (field.complexity) {
      const validPassword = checkComplexityValidity(initialValue);
      setValid(validPassword);
    }
    // eslint-disable-next-line
  }, [initialValue]);

  // Construct password error message consisting of specific complexity requirements
  useEffect(() => {
    if (field.complexity) {
      let constructedMessage = 'Password must contain ';
      const conditions = [];
      const minLength = field.complexity.replace(/\D/g, '');
      if (minLength) {
        conditions.push(`at least ${minLength} characters`);
      }
      if (field.complexity.includes('A-Z')) {
        conditions.push('at least one capital letter');
      }
      if (field.complexity.includes('d')) {
        conditions.push('at least one number');
      }
      if (field.complexity.includes('W')) {
        conditions.push('at least one special character');
      }

      conditions.forEach((condition, idx) => {
        if (conditions.length === 1) {
          constructedMessage += condition;
        } else if (idx === conditions.length - 1) {
          constructedMessage += `and ${condition}`;
        } else {
          constructedMessage += `${condition}${conditions.length > 2 ? ',' : ''} `;
        }
      });
      setErrorMessage(constructedMessage);
    }
  }, [field.complexity]);

  const onChange = (e) => {
    setValid(true);
    setErrorMessage('');
    setScriptExecutionError(false);
    let value = e.target.value;
    let event = e;
    // if masking left in input, clear currentValue and set to valid
    if (handleChange && field.mask === '(999) 999-9999') {
      checkPhoneValidity(value);
      if (value === '(___) ___-____') {
        value = '';
        setValid(true);
        event = {
          target: {
            value: '',
            name: field.name,
          },
        };
      }

      setCurrentValue(value);
      handleChange(event, null);
    } else if (handleChange && field.complexity) {
      // Check if entry matches password complexity requirements
      const validPassword = checkComplexityValidity(value);
      setValid(validPassword);
      setCurrentValue(value);
      handleChange(e, null);
    } else if (handleChange) {
      value = value.replace(/\s+/g, ' ');
      if (field.pattern) setValid(new RegExp(field.pattern).test(value));
      // SAF-4378 -validate URL on dashboardButtonUrl
      if (/dashboardButtonUrl[123]/g.test(field.name)) {
        if (!new RegExp(field.pattern).test(value))
          setErrorMessage(`${field.label} must be a proper URL value (e.g. https://www.safehealth.me)`);
      }
      if (field.minLength && field.minLength > value.trim().length) {
        setValid(false);
        if (field.displayErrorMessage)
          setErrorMessage(`${field.label} must be more then ${field.minLength} characters`);
      }
      if (field.maxLength && field.maxLength < value.length) {
        setValid(false);
        if (field.displayErrorMessage)
          setErrorMessage(`${field.label} must be less or equal ${field.maxLength} characters`);
      }
      setCurrentValue(value);
      handleChange(e, null);
    }
  };

  const handleBlur = (e) => {
    let value = e.target.value;
    value = value.replace(/\s+/g, ' ').trim();
    e.target.value = e.target.value.replace(/\s+/g, ' ').trim();

    setCurrentValue(value);
    handleChange(e, null);
  };

  // if phone number, check regex validity to show error text and enable/disable submit
  const checkPhoneValidity = (value) => {
    if (re.test(value.replace(/[^A-Z0-9]+/gi, ''))) {
      setValid(true);
      return true;
    }
    setValid(false);
    return false;
  };

  // If password with minimum complexity requirements, check validity
  const checkComplexityValidity = (value) => {
    const complexityRe = new RegExp(field.complexity);
    return complexityRe.test(value);
  };

  const handleScriptExecute = () => {
    setScriptExecutionError(false);
    const scriptExecutionResult = utils.findScript(currentValue);
    if (!scriptExecutionResult) {
      setScriptExecutionError(true);
    }
  };

  const fieldLabel =
    selectedLanguage && field.name && field.name.translations
      ? field.name.translations[selectedLanguage]
      : field.label || field.name;

  const questionDescription =
    selectedLanguage && field.description && field.description.translations
      ? field.description.translations[selectedLanguage]
      : field.description;

  if (!field.name) return <div />;
  if (field.rows || field.type === 'large-text') {
    return (
      <>
        <Typography
          style={{
            opacity: field.required && !currentValue ? 1 : 0.5,
            fontSize: 10,
          }}
          color={field.required && !currentValue ? 'error' : 'inherit'}
        >
          {field.questionPreview ? utils.renderHTMLTags(questionDescription) : fieldLabel}
          {field.required && '*'}
        </Typography>
        <textarea
          rows={field.rows}
          className="k-textarea"
          onChange={onChange}
          required={field.required}
          label={field.label}
          name={field.name}
          autoComplete="off"
          style={{ maxWidth: '100%', minWidth: '100%' }}
          value={currentValue}
          disabled={fieldDisabled}
        />
        {field.checkScriptExecution && (
          <>
            <Button
              style={{ marginTop: 20 }}
              variant="contained"
              color="primary"
              onClick={handleScriptExecute}
              disabled={false}
            >
              Check script execution
            </Button>
            {scriptExecutionError && (
              <Typography style={{ padding: '10px 0', color: 'red' }}>
                Script execution error. Fix our script, please!
              </Typography>
            )}
          </>
        )}
      </>
    );
  }

  const getInputType = () => {
    if (field.sensitive) {
      if (sensitiveVisible) return 'text';
      return 'password';
    }
    return 'text';
  };

  return (
    <div>
      <Grid
        container
        className={`${field.variant || ""} ${fieldDisabled ? "disabled" : ""}`}
        style={{ position: 'relative' }}
      >
        <Grid item xs={12}>
          {field.mask ? (
            <MaskedTextBox
              mask={field.mask}
              value={currentValue}
              onChange={onChange}
              required={field.required && !currentValue}
              label={
                selectedLanguage && field.name && field.name.translations
                  ? field.name.translations[selectedLanguage]
                  : field.label || field.name
              }
              name={
                selectedLanguage && field.name && field.name.translations
                  ? field.name.translations[selectedLanguage]
                  : field.name
              }
              width="100%"
              pattern={field.pattern}
              disabled={fieldDisabled}
              ref={masktextfield}
            />
          ) : (
            <Input
              width="100%"
              value={currentValue}
              onChange={onChange}
              required={(!initialValue || !currentValue) && field.required}
              label={
                selectedLanguage && field.name && field.name.translations
                  ? field.name.translations[selectedLanguage]
                  : field.label || field.name
              }
              name={
                selectedLanguage && field.name && field.name.translations
                  ? field.name.translations[selectedLanguage]
                  : field.name
              }
              onBlur={handleBlur}
              pattern={field.pattern}
              style={{ width: '100%' }}
              disabled={fieldDisabled}
              // maxLength={field.maxLength}
              // minLength={field.minLength}
              autoComplete="new-password"
              type={getInputType()}
              valid={valid}
            />
          )}
        </Grid>
        {field.sensitive && (
          <Grid item className={classes.buttonVisibleWrapper}>
            <Tooltip title={sensitiveVisible ? 'Hide value' : 'Show value'}>
              <IconButton className={classes.buttonVisible} onClick={() => setSensitiveVisible(!sensitiveVisible)}>
                {sensitiveVisible ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
              </IconButton>
            </Tooltip>
          </Grid>
        )}
        {!valid && field.mask === '(999) 999-9999' && currentValue.replace(/[^A-Z0-9]+/gi, '').length === 10 && (
          <Error id="invalidPhoneNumber">Please enter a valid phone number</Error>
        )}
        {!valid && field.complexity && <Error id="invalidPassword">{errorMessage}</Error>}
        {!valid && errorMessage && <Error id="invalidField">{errorMessage}</Error>}
      </Grid>
    </div>
  );
}

export default withStyles(styles)(TextFieldInput);
