import { createSlice } from '@reduxjs/toolkit';
import FileDownload from 'js-file-download';

import api from '../../api';
import { showNotification } from '../Notifications/notificationSlice';
import { handleError } from '../Shared/Content/APIUtils';

const initialState = {
  reports: [],
  reportsLoading: false,
};

export const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    setReportsLoading: (state, { payload }) => ({
      ...state,
      reportsLoading: payload,
    }),
    setReports: (state, { payload }) => ({
      ...state,
      reports: payload,
      reportsLoading: false,
    }),
    clearCustomReportData: (state) => ({
      ...state,
      reports: [],
    }),
  },
});

export const { setReports, setReportsLoading, clearCustomReportData } = reportsSlice.actions;

export const getReports =
  (tenant, page, pageSize, query, sort, direction, filter, noLoad, refresh) => async (dispatch) => {
    dispatch(setReportsLoading(!noLoad));

    const params = {
      skip: page * pageSize,
      limit: pageSize,
      q: query,
      sort,
      direction,
      permission: filter,
    };

    const [result, error] = await api.getReportsRequest(tenant, params);

    if (result) {
      dispatch(setReports(result));
      if (refresh) {
        dispatch(showNotification('Reports successfully synced', 'success'));
      }
    }

    if (error && error.message) {
      dispatch(handleError(error));
    }
  };

export const downloadReport = (tenant, reportId, fileName) => async (dispatch) => {
  const [result, error] = await api.downloadReportRequest(tenant, reportId);

  if (result) {
    FileDownload(result, fileName);
  }

  if (error && error.message) {
    dispatch(handleError(error, 'Unable to download report. Please try again later.'));
  }
};

export const getReportPreview = (tenant, reportId) => async (dispatch) => {
  const [result, error] = await api.getReportPreviewRequest(tenant, reportId);

  if (result) {
    return new TextDecoder().decode(result);
  }

  if (error && error.message) {
    dispatch(handleError(error, 'Unable to download report. Please try again later.'));
    return false;
  }
};

export const createReport =
  (tenant, reportType, page, pageSize, query, sort, direction, filter) => async (dispatch) => {
    const params = { reportType };
    const [result, error] = await api.createReportRequest(tenant, params);

    if (result) {
      dispatch(
        showNotification('Your report request has been received and will be available in this page shortly.', 'info')
      );
      dispatch(getReports(tenant, page, pageSize, query, sort, direction, filter, true));
    }

    if (error && error.message) {
      dispatch(handleError(error));
    }
  };

export const getCustomReport =
  (tenant, reportType, dateFrom, dateTo, page, pageSize, sort, direction, filter) => async (dispatch) => {
    dispatch(setReportsLoading(true));

    const data = {
      fromDate: dateFrom,
      toDate: dateTo,
      type: reportType,
      testResult: filter.testResult,
      testName: filter.testName,
      gender: filter.gender,
      tenantId: filter.tenantId,
      accountId: filter.accountId,
      fromLastLoginDate: filter.fromLastLoginDate,
      toLastLoginDate: filter.toLastLoginDate,
    };
    const [result, error] = await api.getCustomReportRequest(tenant, data);

    if (error && error.message) {
      dispatch(handleError(error));
    }

    if (result) {
      dispatch(setReports(result));
    }

    dispatch(setReportsLoading(false));
  };

export default reportsSlice.reducer;
