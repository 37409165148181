import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import React, { useState, useEffect } from 'react';

import styles from '../../assets/Questionnaire.module.css';
import utils from '../utils/utils';

// ui reflection of file view
function DropdownField(props) {
  const { field, value, handleChangeWithParams, disabled, selectedLanguage } = props;
  const [currentValue, setCurrentValue] = useState(
    value && field.valueField ? field.enum.find((s) => s[field.valueField] === value) : value
  );

  useEffect(() => {
    if (field && field.name === 'status' && value && value.selected) {
      if (value.selected.data.status === 0) {
        setCurrentValue('INACTIVE');
      } else setCurrentValue('ACTIVE');
    } else if (currentValue !== value) {
      setCurrentValue(field.valueField ? field.enum.find((s) => s[field.valueField] === value) : value);
    }
    // eslint-disable-next-line
  }, [field, value, setCurrentValue]);

  const handleValueChange = (e) => {
    setCurrentValue(e.target.value);
    handleChangeWithParams(field.name, field.valueField ? e.target.value[field.valueField] : e.target.value);
  };

  const itemRender = (li) => {
    const itemChildren = <span>{li.props.children === 'DEFAULT' ? 'MEMBER' : li.props.children}</span>;
    return React.cloneElement(li, li.props, itemChildren);
  };

  const fieldTitle =
    selectedLanguage && field.name && field.name.translations
      ? field.name.translations[selectedLanguage]
      : field.label || field.name;
  const questionDescription =
    selectedLanguage && field.description && field.description.translations
      ? field.description.translations[selectedLanguage]
      : field.description;

  return (
    <Grid item xs={12}>
      {!field?.isFieldForm && (
        <Grid item xs={12} style={{ width: '100%' }}>
          {fieldTitle && (
            <Typography className={field.nameStyle ? styles[`title--${field.nameStyle}`] : styles['title--style1']}>
              {field.questionPreview ? utils.renderHTMLTags(fieldTitle) : fieldTitle}
              {field.required && '*'}
            </Typography>
          )}
          {questionDescription && (
            <Typography
              className={
                field.descriptionStyle
                  ? styles[`description--${field.descriptionStyle}`]
                  : styles['description--style1']
              }
            >
              {utils.renderHTMLTags(questionDescription)}
            </Typography>
          )}
        </Grid>
      )}

      <DropDownList
        label={field.label || field.name}
        name={field.name}
        data={field.enum || field.options || []}
        onChange={handleValueChange}
        required={field.required}
        style={{ width: '100%' }}
        value={currentValue || null}
        itemRender={itemRender}
        disabled={disabled || field.disabled || field.readOnly}
        textField={field.labelField}
        defaultItem={field.defaultItem}
      />
    </Grid>
  );
}

export default DropdownField;
