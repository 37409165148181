/* eslint-disable react/no-array-index-key */
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import React, { Fragment, useState, useEffect } from 'react';

import TextFieldInput from './TextFieldInput';

// ui reflection of file view
function MultipleChoiceInput(props) {
  const { field, value: initialValue, handleChange, selectedLanguage } = props;
  const [currentValue, setCurrentValue] = useState(initialValue || '');
  const [currentMultiValue, setCurrentMultiValue] = useState(initialValue || []);

  useEffect(() => {
    if (initialValue !== currentValue) {
      setCurrentValue(initialValue || '');
    }
    // eslint-disable-next-line
  }, [initialValue]);

  const handleSingleChange = (event) => {
    setCurrentValue(event.target.value);
    handleChange([event.target.value]);
  };

  const handleMultiChange = (event) => {
    const { name } = event.target;
    const { checked } = event.target;
    let newMultiValue = {};
    const selectedOption = field.options.find((opt) => {
      const optionValue =
        selectedLanguage && opt.value.translations ? opt.value.translations[selectedLanguage] : opt.value;
      return optionValue === name;
    });
    const noneOption = field.options.find((opt) => {
      const optionValue =
        selectedLanguage && opt.value.translations ? opt.value.translations[selectedLanguage] : opt.value;
      // optionValue === "None" is needed for backward compatibility
      return opt.none || optionValue === 'None';
    });
    if (selectedOption === noneOption) {
      const multiValueClone = { ...currentMultiValue };
      // eslint-disable-next-line no-return-assign
      Object.keys(multiValueClone).forEach((v) => (multiValueClone[v] = false));
      newMultiValue = { ...multiValueClone, [name]: checked };
    } else {
      const noneOptionName =
        selectedLanguage && noneOption && noneOption.value.translations
          ? noneOption.value.translations[selectedLanguage]
          : noneOption && noneOption.value;
      newMultiValue = {
        ...currentMultiValue,
        [name]: checked,
        [noneOptionName]: false,
      };
    }
    const trueValues = Object.keys(newMultiValue).filter((k) => newMultiValue[k]);
    setCurrentMultiValue(newMultiValue);
    handleChange(trueValues);
  };

  const renderCommentForAnswer = (optionValue) => {
    // change field name related to answer
    const commentField = { ...field.answerCommentField };
    commentField.name = `${optionValue}_${commentField.name}`;
    return (
      <>
        <TextFieldInput field={commentField} value="" handleChange={handleChange} disabled={field.disabled} />
        <Divider style={{ marginTop: 15 }} />
      </>
    );
  };

  const getFormControlLabel = (option) => {
    if (option && option.value && option.value.translations) return option.value.translations.eng;
    if (option && option.value) return option.value;
    return false;
  };

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          {field.multiSelect ? (
            <FormControl component="fieldset">
              <FormGroup>
                {field.options &&
                  field.options.map((option, i) => (
                    <Grid key={`option-${i}`}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              currentMultiValue[
                                option && option.value && option.value.translations
                                  ? option.value.translations.eng
                                  : option && option.value
                              ] || false
                            }
                            onChange={handleMultiChange}
                            name={
                              option && option.value && option.value.translations
                                ? option.value.translations.eng
                                : option && option.value
                            }
                            color="primary"
                          />
                        }
                        label={
                          selectedLanguage && option && option.value && option.value.translations
                            ? option.value.translations[selectedLanguage]
                            : option && option.value
                        }
                      />
                      {field.questionPreview &&
                        field.commentEnabled &&
                        field.questionnaireCommentEnabled &&
                        renderCommentForAnswer(
                          option && option.value && option.value.translations
                            ? option.value.translations.eng
                            : option && option.value
                        )}
                    </Grid>
                  ))}
              </FormGroup>
            </FormControl>
          ) : (
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="mc"
                name={field.name && field.name.translations ? field.name.translations[selectedLanguage] : field.name}
                value={currentValue}
                onChange={handleSingleChange}
              >
                {field.options &&
                  field.options.map((option, i) => (
                    <Grid key={`radio-${i}`}>
                      <FormControlLabel
                        value={getFormControlLabel(option)}
                        control={<Radio color="primary" />}
                        label={
                          selectedLanguage && option && option.value && option.value.translations
                            ? option.value.translations[selectedLanguage]
                            : option && option.value
                        }
                      />
                      {field.questionPreview &&
                        field.commentEnabled &&
                        renderCommentForAnswer(
                          option && option.value && option.value.translations
                            ? option.value.translations.eng
                            : option && option.value
                        )}
                    </Grid>
                  ))}
              </RadioGroup>
            </FormControl>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default MultipleChoiceInput;
