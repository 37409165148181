import Grid from '@material-ui/core/Grid';
import { MultiSelect } from '@progress/kendo-react-dropdowns';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { reset, search } from './typeaheadSlice';

// ui reflection of file view
function TypeaheadMultiSelect(props) {
  const { field, value: initialValue, handleChangeWithParams, disabled } = props;
  const [selected, setSelected] = useState(initialValue || []);
  const [loading, setLoading] = useState(false);
  const [timeout, setStateTimeout] = useState(undefined);

  const dispatch = useDispatch();

  const values = field.typeahead;

  useEffect(() => {
    if (initialValue) {
      setSelected(initialValue || []);
    }

    return () => {
      dispatch(reset(field.name));
    };
  }, [initialValue, field]);

  useEffect(() => {
    if (values) {
      setLoading(values.loading);
    }
  }, [values]);

  const handleMultiSelectChange = (e) => {
    const selectedItems = e.target.value.map((v) => (field.valueField ? v[field.valueField] : v));
    setSelected(selectedItems);
    handleChangeWithParams(field.name, selectedItems);
    dispatch(reset(field.name));
  };

  const handleFilterChange = (event) => {
    if (timeout) {
      clearTimeout(timeout);
    }

    if (event.filter.value && event.filter.value.length > 2) {
      setStateTimeout(
        setTimeout(() => {
          dispatch(search(field.name, field.searchType, event.filter.value, 10));
        }, 500)
      );
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <MultiSelect
          readOnly={field.readOnly}
          disabled={disabled || field.readOnly}
          data={values || []}
          textField={field.labelField}
          label={field.placeholder || field.label}
          style={{ width: '100%' }}
          value={selected}
          onChange={handleMultiSelectChange}
          allowCustom={field.allowCustom}
          loading={loading}
          onFilterChange={handleFilterChange}
          filterable
        />
      </Grid>
    </Grid>
  );
}

export default TypeaheadMultiSelect;
