/* eslint-disable import/no-cycle */
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Delete from '@material-ui/icons/Delete';
import MedicalCodes from '@material-ui/icons/LocalHospital';
import React, { useContext, useState } from 'react';

import { createInputHelper } from '../../../../Shared/Forms/helpers/createInputHelper';
import { moreThanRule } from '../../helpers/formHelper';
import { transform } from '../../helpers/questionnaireBuilderMapper';
import { QuestionnaireBuilderContext } from '../../QuestionnaireBuilderWrapper';

import MedicalCode from './MedicalCode/MedicalCode';

function MoreThanOption({
  currentNode,
  updateQuestionNodes,
  nodeIndex,
  jumpIndex,
  addCoding,
  jump,
  removeMedicalCode,
  editCoding,
  removeJump,
}) {
  const [editingJumpCoding, setEditingJumpCoding] = useState(false);

  const openEditingCoding = () => {
    setEditingJumpCoding(!editingJumpCoding);
    addCoding(jumpIndex);
  };

  const { nodes } = useContext(QuestionnaireBuilderContext);

  const handleSizeChange = (value) => {
    updateQuestionNodes(`[${nodeIndex}].rules[${jumpIndex}].rules[0]`, (rule) => ({
      ...rule,
      size: value,
    }));
  };

  const handleChangeWithParams = (name, value) => {
    if (name === 'operation') {
      updateQuestionNodes(`[${nodeIndex}].rules[${jumpIndex}].rules[0]`, (rule) => ({
        ...rule,
        operation: value,
      }));
    }

    if (name === 'nextQuestionId') {
      updateQuestionNodes(`[${nodeIndex}].rules[${jumpIndex}]`, (rule) => ({
        ...rule,
        nextQuestionId: value,
      }));
    }
  };

  const jumpValues = () => {
    const rule = currentNode.rules[jumpIndex];

    if (!rule) {
      return {};
    }
    const { nextQuestionId } = rule;

    return {
      ...rule.rules[0],
      nextQuestionId,
    };
  };

  if (!currentNode.rules.length) return null;

  return (
    <Paper style={{ padding: 10, marginTop: 30 }}>
      <Grid container spacing={3}>
        <Grid item xs={10}>
          <Typography variant="h6">
            {' '}
            Jump
            {jumpIndex + 1} - More than answers
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <Tooltip title="Add medical code">
            <IconButton onClick={openEditingCoding}>
              <MedicalCodes />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={1}>
          <Tooltip title="Remove jump">
            <IconButton onClick={() => removeJump(jumpIndex)}>
              <Delete />
            </IconButton>
          </Tooltip>
        </Grid>

        {editingJumpCoding && jump.coding && (
          <Grid item xs={12}>
            <MedicalCode
              coding={jump.coding}
              removeMedicalCode={() => removeMedicalCode(jumpIndex)}
              editCoding={() => editCoding(jumpIndex)}
            />
          </Grid>
        )}

        {createInputHelper(
          moreThanRule(transform.nodes(nodes)),
          jumpValues(),
          (e) => handleSizeChange(e.target.value),
          (name, value) => handleChangeWithParams(name, value)
        )}
      </Grid>
    </Paper>
  );
}

export default MoreThanOption;
