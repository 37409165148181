import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Dialog } from '@progress/kendo-react-dialogs';
import React, { useState } from 'react';

import MetaDataSettingsStyles from '../../../assets/jss/components/MetaDataSettingsStyles';

function MetaDataEditorDialog(props) {
  const { onCloseDialog, editingMetaDataKey, onHandleUpdateMetaDataKeyword, updateMetaDataKeyLoading, classes } = props;

  const [keywordValue, setKeywordValue] = useState(editingMetaDataKey || '');
  const [keywordError, setKeywordError] = useState('');

  const handleClose = () => {
    onCloseDialog();
  };

  const handleSave = () => {
    if (!keywordValue) return setKeywordError('Required!');

    onHandleUpdateMetaDataKeyword(keywordValue, editingMetaDataKey);
  };

  const updateKeywordValue = (e) => {
    const { value } = e.target;
    setKeywordValue(value);
  };

  return (
    <Dialog title="Add Metadata" onClose={handleClose}>
      <Grid container justifyContent="flex-end">
        <Grid container alignItems="flex-end">
          {/* <Grid item xs={3} style={{marginBottom: '16px'}}> */}
          {/*  Keyword */}
          {/* </Grid> */}
          <Grid item xs={12}>
            <TextField
              id="input-with-icon-grid"
              name="name"
              margin="normal"
              label="Metadata Key"
              onChange={updateKeywordValue}
              value={keywordValue}
              autoComplete="off"
              autoFocus
              type="text"
              fullWidth
              error={!!keywordError}
              helperText={keywordError}
            />
          </Grid>
        </Grid>

        <Grid container justifyContent="center" style={{ position: 'relative', marginTop: '20px' }}>
          {updateMetaDataKeyLoading ? (
            <Grid className={classes.loadingMetaDataEditWrapper}>
              <CircularProgress size={28} thickness={6} />
            </Grid>
          ) : null}
          <Button
            disabled={!keywordValue || updateMetaDataKeyLoading}
            color="primary"
            variant="contained"
            onClick={handleSave}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
}

export default withStyles(MetaDataSettingsStyles)(MetaDataEditorDialog);
