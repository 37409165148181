import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { selectLanguageContent } from '../LanguageMenu/selectors';
import { selectTenantNetwork } from '../Networks/selectors';
import { createContentPanels } from '../Settings/ExpansionComponents/SettingsDragWrapper';
import { selectTenantSettings } from '../Settings/selectors';

// components and helpers
import AppTabbedPageWrapper from '../Shared/Pages/TabbedPageWrapper';

import { selectCurrentTenant } from '../Shared/selectors';
import ContentListWrapper from './ContentListWrapper';

const FaqPanels = createContentPanels();
// const VideoPanels = createContentPanels();

export function ContentPageWrapper(props) {
  const { type } = props;

  const i18n = useSelector(selectLanguageContent);
  const currentTenant = useSelector(selectCurrentTenant);
  const tenantSettings = useSelector(selectTenantSettings);
  const contentSettings = useSelector(selectTenantNetwork);
  const navigate = useNavigate();

  if (!tenantSettings || !contentSettings) {
    return null;
  }

  const tabs = [
    {
      label: i18n.help_center,
      type: 'helpCenter',
      url: 'content/faq',
      content: <FaqPanels currentTenant={currentTenant} type="faq" faqType={0} />,
    },
    {
      label: i18n.registration_faq,
      type: 'registrationFaq',
      url: 'content/registration-faq',
      content: <FaqPanels currentTenant={currentTenant} type="faq" faqType={1} />,
      disabled: !contentSettings.enableRegistrationFAQ,
    },
    {
      label: i18n.articles,
      type: 'articles',
      url: 'content/articles',
      content: <ContentListWrapper currentTenant={currentTenant} type="articles" />,
    },
    {
      label: i18n.videos,
      type: 'videos',
      url: 'content/videos',
      content: <ContentListWrapper currentTenant={currentTenant} type="videos" />,
    },
  ].filter((tab) => tenantSettings.menuItems[tab.url] || tab.type === 'registrationFaq');

  const handleTabChange = (tabDetails) => {
    navigate(`/${currentTenant.id}/${tabDetails.url}`);
  };

  if (!currentTenant) return <div />;

  return <AppTabbedPageWrapper tabs={tabs} openTab={tabs.find((t) => t.type === type)} onTabChange={handleTabChange} />;
}

export default ContentPageWrapper;
