import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import AppStyles from '../../../assets/jss/components/AppStyles';

function Loader({ size = 28, thickness = 6 }) {
  const classes = useStyles();

  return (
    <Grid className={classes.buttonLoading}>
      <CircularProgress size={size} thickness={thickness} />
    </Grid>
  );
}

const useStyles = makeStyles(AppStyles);

export default Loader;
