/* eslint-disable import/no-named-as-default */
import { Grid, Paper } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import moment from 'moment/moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AppNotFoundView from '../../views/NotFoundView';
import { ChartSkeletonLoading } from '../Content/ContentSkeletonLoading';
import { selectTenantNetwork } from '../Networks/selectors';
import { showNotification } from '../Notifications/notificationSlice';
import DateRangeSelector from '../Shared/Forms/KendoFields/DateRangeSelector';
import { selectCurrentTenantId } from '../Shared/selectors';
import utils from '../Shared/Utils/utils';
import BarChart from './charts/BarChart';
import DonutChart from './charts/DonutChart';
import LineChart from './charts/LineChart';
import { InfoCard, InfoCardLoading } from './InfoCard';
import { getDashboardStatistics, getSystemTotals } from './legacyDashboardSlice';
import { selectLegacyDashboardData } from './selectors';
import SystemTotals from './SystemTotals';

// Grid spacing
const SPACING = 3;

/**
 * Dashboard component
 * @returns {*}
 * @constructor
 */
function LegacyDashboard() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  const currentNetwork = useSelector(selectTenantNetwork);
  const currentTenant = useSelector(selectCurrentTenantId);
  const dashboardData = useSelector(selectLegacyDashboardData);

  const [dateRangeValue, setDateRangeValue] = useState(null);
  const [dateRange, setDateRange] = useState(null);
  const [isSymptomCheckerEnabled] = useState(true);
  const dispatch = useDispatch();

  const showInvalidDateError = () => {
    dispatch(showNotification('Please select a valid date', 'error'));
  };

  useEffect(() => {
    if (dateRangeValue && dateRangeValue.start && dateRangeValue.end) {
      if (
        new Date(dateRangeValue.start).getTime() > 0 &&
        new Date(dateRangeValue.end).getTime() > 0 &&
        utils.diffByDates(moment(), dateRangeValue.start, 'days') &&
        utils.diffByDates(moment(), dateRangeValue.end, 'days')
      ) {
        if (currentTenant !== currentNetwork.tenantID) return;
        dispatch(getDashboardStatistics(currentTenant, dateRangeValue.start, dateRangeValue.end));
      } else showInvalidDateError();
    }
  }, [currentNetwork, currentTenant, dateRangeValue]);

  useEffect(() => {
    dispatch(getSystemTotals(currentTenant));
  }, [currentTenant]);

  // // display Symptom Checker chart of Symptom Checker enabled in Settings
  // useEffect(() => {
  //   if (currentNetwork?.dashboardSettings?.dashboardButtons) {
  //     currentNetwork?.dashboardSettings?.dashboardButtons.forEach(
  //       (itemButton) => {
  //         if (
  //           itemButton.enabled &&
  //           itemButton?.action?.value === "SymptomChecker"
  //         ) {
  //           setIsSymptomCheckerEnabled(true);
  //         }
  //       }
  //     );
  //   }
  // }, [currentNetwork]);

  const handleChangeTimePeriod = (dateRangeValue) => {
    setDateRangeValue(dateRangeValue);
  };

  const formatValue = (value, options) => {
    let result = value ? String(value) : 'N/A';
    if (value && options?.suffix) {
      result += options.suffix;
    }

    return result;
  };

  if (!currentTenant || currentTenant === 'None') return <AppNotFoundView currentTenant={currentTenant} />;

  return (
    <Grid container item xs={12} spacing={SPACING} style={{ margin: 0, padding: '10px 0 0 5px' }}>
      {/* System Totals */}
      <Grid item container direction="column" style={{ marginTop: !matches ? 45 : 0 }}>
        <SystemTotals isLoading={!dashboardData?.statistics?.sidelinedUsers?.percentage} />
      </Grid>

      {/* Date Range selector */}
      <Grid item container direction="row" style={{ marginTop: 10 }}>
        <DateRangeSelector
          maxEndDate={Date.now()}
          showTitle
          inputWidth={107}
          dateButtonsPos={'start'}
          onDateRangeChange={handleChangeTimePeriod}
          onSetDateRange={setDateRange}
          direction={'row'}
        />
      </Grid>

      <Grid container item spacing={SPACING} style={{ marginTop: -8 }}>
        {/* Sidelined Users */}
        <Grid item xs={6} md={6} lg>
          {!dashboardData?.statistics?.sidelinedUsers?.percentage ? (
            <InfoCardLoading />
          ) : (
            <InfoCard
              value={
                dashboardData?.statistics?.sidelinedUsers?.percentage
                  ? `${dashboardData?.statistics?.sidelinedUsers?.percentage}%`
                  : 'N/A'
              }
              title="Sidelined Users:"
              text={`${formatValue(dashboardData?.statistics?.sidelinedUsers?.value)} of ${formatValue(
                dashboardData?.statistics?.sidelinedUsers?.total
              )}`}
              dateRange={dateRange}
              infoText={
                'Users who cannot work in given time filter range. Shows percentage of workforce with actual ' +
                'headcount.'
              }
              type="sidelinedUsers"
            />
          )}
        </Grid>

        {/* Tests Completed */}
        <Grid item xs={6} md={6} lg>
          {!dashboardData?.statistics?.totalTests?.total ? (
            <InfoCardLoading />
          ) : (
            <InfoCard
              value={
                dashboardData?.statistics?.totalTests?.percentage
                  ? `${dashboardData?.statistics?.totalTests?.percentage}%`
                  : 'N/A'
              }
              title="Tests Completed:"
              text={`${formatValue(dashboardData?.statistics?.totalTests?.value)} of ${formatValue(
                dashboardData?.statistics?.totalTests?.total
              )}`}
              dateRange={dateRange}
              infoText={'Percentage of work force who have completed tests in a given time filter range.'}
              type="testCompleted"
            />
          )}
        </Grid>

        {/* Positive Tests */}
        <Grid item xs={6} md={6} lg>
          {!dashboardData?.statistics?.positiveTests?.total ? (
            <InfoCardLoading />
          ) : (
            <InfoCard
              value={
                dashboardData?.statistics?.positiveTests?.percentage
                  ? `${dashboardData?.statistics?.positiveTests?.percentage}%`
                  : 'N/A'
              }
              title="Positive Tests:"
              text={`${formatValue(dashboardData?.statistics?.positiveTests?.value)} of ${formatValue(
                dashboardData?.statistics?.positiveTests?.total
              )}`}
              dateRange={dateRange}
              infoText={
                'Percentage of work force who have tested positive a given time filter range. This represents ' +
                'action that a company should take.'
              }
              type="positive"
            />
          )}
        </Grid>

        {/* Negative Tests */}
        <Grid item xs={6} md={6} lg>
          {!dashboardData?.statistics?.negativeTests?.total ? (
            <InfoCardLoading />
          ) : (
            <InfoCard
              value={
                dashboardData?.statistics?.negativeTests?.percentage
                  ? `${dashboardData?.statistics?.negativeTests?.percentage}%`
                  : 'N/A'
              }
              title="Negative Tests:"
              text={`${formatValue(dashboardData?.statistics?.negativeTests?.value)} of ${formatValue(
                dashboardData?.statistics?.negativeTests?.total
              )}`}
              dateRange={dateRange}
              infoText={
                'Percentage of work force who have tested Negative in a given time filter range. This stat ' +
                'provides additional visibility the health of your team.'
              }
              type="negative"
            />
          )}
        </Grid>

        {/* Invalid Tests */}
        <Grid item xs={6} md={6} lg>
          {!dashboardData?.statistics?.invalidTests?.total ? (
            <InfoCardLoading />
          ) : (
            <InfoCard
              value={
                dashboardData?.statistics?.invalidTests?.percentage
                  ? `${dashboardData?.statistics?.invalidTests?.percentage}%`
                  : 'N/A'
              }
              title="Invalid Tests:"
              text={`${formatValue(dashboardData?.statistics?.invalidTests?.value)} of ${formatValue(
                dashboardData?.statistics?.invalidTests?.total
              )}`}
              dateRange={dateRange}
              infoText={
                'Percentage of work force who have tested Invalid in a given time filter range. This stat provides ' +
                'additional visibility the health of your team.'
              }
              type="invalid"
            />
          )}
        </Grid>
      </Grid>

      {/* Charts */}
      <Grid container item spacing={SPACING}>
        <Grid item xs={isSymptomCheckerEnabled ? 6 : 12}>
          {dashboardData?.testsTaken?.data && !dashboardData?.loading && (
            <BarChart
              showLoading
              title={`Tests Taken (Negative vs. Positive vs. Invalid)\n${dateRange}`}
              height={300}
              type="column"
              gap={2}
              data={dashboardData?.testsTaken.data}
              categories={dashboardData?.testsTaken.categories}
              infoText={
                'Shows the testing trend over the filtered time range. Allows a user to see if daily numbers are ' +
                'increasing or decreasing.'
              }
              isTooltipVisible
            />
          )}
          {!dashboardData?.testsTaken?.data && (
            <Paper elevation={0} style={{ borderRadius: 30 }}>
              <ChartSkeletonLoading />
            </Paper>
          )}
        </Grid>

        {isSymptomCheckerEnabled && (
          <Grid item xs={12} md={6}>
            {dashboardData?.symptomChecker?.data && !dashboardData?.loading && (
              <LineChart
                data={dashboardData.symptomChecker.data}
                timeline={dashboardData.symptomChecker.categories}
                height={300}
                title={`Symptom Checker\n${dateRange}`}
                infoText={
                  'Shows the number of symptom checker questionnaires taken over the filtered time range. Allows a ' +
                  'user to see if daily numbers are increasing or decreasing.'
                }
                isTooltipVisible
              />
            )}
            {!dashboardData?.symptomChecker?.data && (
              <Paper elevation={0} style={{ borderRadius: 30 }}>
                <ChartSkeletonLoading />
              </Paper>
            )}
          </Grid>
        )}
      </Grid>

      <Grid container item spacing={SPACING}>
        <Grid item xs={6}>
          {dashboardData?.sidelinedMembers?.data && !dashboardData?.loading && (
            <BarChart
              showLoading
              title={`Sidelined Members (Future Trend)`}
              height={300}
              type="column"
              gap={2}
              data={dashboardData.sidelinedMembers.data}
              categories={dashboardData.sidelinedMembers.categories}
              infoText={'Future trend that shows when a workforce will potentially be available to work again.'}
              isTooltipVisible
            />
          )}
          {!dashboardData?.sidelinedMembers?.data && (
            <Paper elevation={0} style={{ borderRadius: 30 }}>
              <ChartSkeletonLoading />
            </Paper>
          )}
        </Grid>

        <Grid item xs={6}>
          {dashboardData?.vaccinationDistribution?.data && !dashboardData?.loading && (
            <BarChart
              showLoading
              title={`Vaccination\n${dateRange}`}
              height={300}
              type="column"
              gap={2}
              data={dashboardData.vaccinationDistribution.data}
              categories={dashboardData.vaccinationDistribution.categories}
              infoText={
                'Daily vaccination status breakout of members added to the system within in a given time filter range.'
              }
              isTooltipVisible
            />
          )}
          {!dashboardData?.vaccinationDistribution?.data && (
            <Paper elevation={0} style={{ borderRadius: 30 }}>
              <ChartSkeletonLoading />
            </Paper>
          )}
        </Grid>
      </Grid>

      {/* Total Test Status, Total Vaccination Status and Active vs Invited */}
      <Grid container item spacing={SPACING}>
        <Grid item xs={12} md={4}>
          {dashboardData?.totalTestStatus?.data && !dashboardData?.loading && (
            <DonutChart
              title={`Total Test Status\n${dateRange}`}
              height={300}
              data={dashboardData.totalTestStatus.data}
              infoText={
                'Percentage of tests results recorded within the system across the entire member population within ' +
                'in a given time filter range.'
              }
              isTooltipVisible
            />
          )}
          {!dashboardData?.totalTestStatus?.data && dashboardData?.loading && (
            <Paper elevation={0} style={{ borderRadius: 30 }}>
              <ChartSkeletonLoading />
            </Paper>
          )}
        </Grid>

        <Grid item xs={12} md={4}>
          {dashboardData?.totalVaccinationStatus?.data && !dashboardData?.loading && (
            <DonutChart
              title={`Total Vaccination Status\n${dateRange}`}
              height={300}
              data={dashboardData.totalVaccinationStatus.data}
              infoText={'Percentage of vaccination status of member population within in a given time filter range.'}
              isTooltipVisible
            />
          )}
          {!dashboardData?.totalVaccinationStatus?.data && dashboardData?.loading && (
            <Paper elevation={0} style={{ borderRadius: 30 }}>
              <ChartSkeletonLoading />
            </Paper>
          )}
        </Grid>

        <Grid item xs={12} md={4}>
          {dashboardData?.activeInvitedStatus?.data && !dashboardData?.loading && (
            <DonutChart
              title={`Active vs Invited\n${dateRange}`}
              height={300}
              data={dashboardData.activeInvitedStatus.data}
              infoText={
                'Percentage of user within the tenant who have either activated their account or pending account ' +
                'activation within in a given time filter range.'
              }
              isTooltipVisible
            />
          )}
          {!dashboardData?.activeInvitedStatus?.data && dashboardData?.loading && (
            <Paper elevation={0} style={{ borderRadius: 30 }}>
              <ChartSkeletonLoading />
            </Paper>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default LegacyDashboard;
