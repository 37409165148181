import PermissionsActionCell from '../PermissionsActionCell';

export const columns = [
  {
    id: 'userId',
    label: 'User',
    show: true,
    minWidth: 170,
    locked: true,
  },
  {
    id: 'modified',
    label: 'Modified',
    show: true,
    minWidth: 170,
    sortable: true,
  },
  {
    id: 'modifier',
    label: 'Modifier',
    show: true,
    minWidth: 170,
    sortable: true,
  },
  {
    id: 'actions',
    label: 'Actions',
    show: true,
    sortable: false,
    minWidth: 170,
    cell: PermissionsActionCell,
  },
];

export const permissionButtons = (setDialogOpen) => [
  {
    title: 'Add Account Admin',
    buttonType: 'action',
    variant: 'contained',
    action: () => setDialogOpen(true),
  },
];
