import { Box, Paper, Typography, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import React, { useState } from 'react';
import logo from '../../assets/img/SafeLogo.png';
import { signInStepMapping, signInFormMap } from './signInStepMapping';
import { useStyles } from './SignInStyles';

export function SignIn() {
  const [signInStep, setSignInStep] = useState('loginCredentials');
  const [mfaMethod, setMfaMethod] = useState('');
  const [session, setSession] = useState('');
  const [userName, setUserName] = useState('');

  const classes = useStyles();
  const theme = useTheme();
  const largeSizeMatched = useMediaQuery(theme.breakpoints.up('md'));
  const marginHorizontalText = largeSizeMatched ? 15 : 3;
  const marginHorizontalForm = largeSizeMatched ? 10 : 3;

  const { title, description } = signInStepMapping[signInStep];
  const Form = signInFormMap[signInStep];

  const handleStepChange = (step, method, sessionId, email) => {
    setSignInStep(step);
    setMfaMethod(method);

    if (sessionId) {
      setSession(sessionId);
    }

    if (email) {
      setUserName(email);
    }
  };

  const handleReset = () => {
    setSignInStep('loginCredentials');
    setSession('');
    setUserName('');
    setMfaMethod('');
  };

  return (
    <Box className={classes.signinWrapper}>
      <Paper className={classes.paper} elevation={0}>
        <Box mt={6} className={classes.logoWrapper}>
          <img className={classes.logo} src={logo} alt="SafeLogo" />
        </Box>
        <Box mt={3} mx={marginHorizontalText}>
          <Typography color="textPrimary" variant="h3" align="center" gutterBottom>
            {title}
          </Typography>
          <Typography className={classes.formDescriptionText} align="center">
            {description}
          </Typography>
        </Box>
        <Box mx={marginHorizontalForm} pb={6}>
          <Form
            onStepChange={handleStepChange}
            sessionId={session}
            userName={userName}
            onReset={handleReset}
            mfaMethod={mfaMethod}
          />
        </Box>
      </Paper>
    </Box>
  );
}
