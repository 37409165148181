import { showNotification } from '../../Notifications/notificationSlice';
import { logout } from '../sharedSlice';

export const handleError = (error, customMessage, isAutoHide) => (dispatch) => {
  if (error?.status === 401) {
    dispatch(logout());
  }
  if (error) {
    /* eslint-disable-next-line no-console */
    console.log('ERROR', error);
    if (error.response && error.response.data && (error.response.data.message || error.response.data.errorMessage)) {
      dispatch(
        showNotification(
          error.response.data.message ||
            error.response.data.errorMessage ||
            customMessage ||
            'Oops! Something went wrong. Please try again.',
          'error',
          isAutoHide
        )
      );
    } else if (error.data && error.data.errorMessage) {
      dispatch(
        showNotification(
          error.data.errorMessage || customMessage || 'Oops! Something went wrong. Please try again.',
          'error',
          isAutoHide
        )
      );
    } else if (error && error.message) {
      dispatch(
        showNotification(
          customMessage || error.message || 'Oops! Something went wrong. Please try again.',
          'error',
          isAutoHide
        )
      );
    } else if (error && error.errorMessage) {
      dispatch(
        showNotification(
          customMessage || error.errorMessage || 'Oops! Something went wrong. Please try again.',
          'error',
          isAutoHide
        )
      );
    } else {
      dispatch(showNotification('Oops! Something went wrong. Please try again.', 'error', isAutoHide));
    }
  } else {
    /* eslint-disable-next-line no-console */
    console.log('ERROR');
    dispatch(showNotification(customMessage || 'Oops! Something went wrong. Please try again.', 'error', isAutoHide));
  }
};
