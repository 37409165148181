const relationOptions = ['Self', 'Spouse', 'Parent', 'Child', 'Sibling', 'Legal guardian', 'Other'];

const translatedLabels = {
  insuranceProvider: {
    eng: 'Insurance Provider',
  },
  insuranceId: {
    eng: 'Insurance ID',
  },
  groupNumber: {
    eng: 'Group Number',
  },
  coverageDate: {
    eng: 'Coverage Date',
  },
  isSubscriber: {
    eng: 'I am the subscriber',
  },
  'subscriber-firstName': {
    eng: "Subscriber's First Name",
  },
  'subscriber-lastName': {
    eng: "Subscriber's Last Name",
  },
  birthDate: {
    eng: "Subscriber's Date of Birth",
  },
  'subscriber-relation': {
    eng: 'Subscriber Relation',
  },
};

export const insuranceForm = (providers, selectedLanguage) => {
  const lang = selectedLanguage || 'eng';
  return [
    {
      id: 'payorId',
      type: 'multiple-drop',
      name: 'payorId',
      label: translatedLabels.insuranceProvider[lang],
      width: 12,
      enum: providers
        ? providers.map((provider) => ({
            name: provider.name,
            value: provider.payorId,
          }))
        : [],
      required: true,
      labelField: 'name',
      valueField: 'value',
      isFieldForm: true, // if it's a form field and not a question
    },
    {
      id: 'insuranceId',
      type: 'text',
      name: 'insuranceId',
      label: translatedLabels.insuranceId[lang],
      width: 12,
      required: true,
      isFieldForm: true, // if it's a form field and not a question
    },
    {
      id: 'groupNumber',
      type: 'text',
      name: 'groupNumber',
      label: translatedLabels.groupNumber[lang],
      width: 12,
      required: true,
      isFieldForm: true, // if it's a form field and not a question
    },
    {
      id: 'coverageDate',
      type: 'date',
      name: 'coverageDate',
      label: translatedLabels.coverageDate[lang],
      required: true,
      max: new Date(),
      width: 12,
      isFieldForm: true, // if it's a form field and not a question
    },
    {
      id: 'subscriber-firstName',
      type: 'text',
      name: 'subscriber-firstName',
      label: translatedLabels['subscriber-firstName'][lang],
      required: true,
      width: 12,
      isFieldForm: true, // if it's a form field and not a question
    },
    {
      id: 'subscriber-lastName',
      type: 'text',
      name: 'subscriber-lastName',
      label: translatedLabels['subscriber-lastName'][lang],
      required: true,
      width: 12,
      isFieldForm: true, // if it's a form field and not a question
    },
    {
      id: 'birthDate',
      type: 'date',
      name: 'birthDate',
      label: translatedLabels.birthDate[lang],
      required: true,
      max: new Date(),
      min: new Date('01/01/1880'),
      width: 12,
      isFieldForm: true, // if it's a form field and not a question
    },
    {
      id: 'subscriber-relation',
      type: 'multiple-drop',
      name: 'subscriber-relation',
      label: translatedLabels['subscriber-relation'][lang],
      enum: relationOptions,
      required: true,
      width: 12,
      isFieldForm: true, // if it's a form field and not a question
    },
  ];
};

export const validateRequiredFields = (values) => {
  let dataIsMissing = false;
  const fields = insuranceForm();
  for (let i = 0; i < fields.length; i++) {
    const field = fields[i];
    if (field.required) {
      dataIsMissing = values[field.name] === null || values[field.name] === undefined;
      if (dataIsMissing) break;
    }
  }
  return !dataIsMissing;
};
