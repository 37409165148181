import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import { paths } from '../constants';

import { selectAuthToken } from './Auth/selectors';
import Confirmation from './Confirmation/Confirmation';
import AppNavbar from './Navbar/Navbar';
import Sidebar from './Sidebar/Sidebar';

export function Layout({ children }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isAuthorized = useSelector(selectAuthToken);

  useEffect(() => {
    const id_token = localStorage.getItem('id_token');

    if (!id_token && !new RegExp([paths.signIn(), paths.resetPassword()].join('|')).test(pathname)) {
      navigate(paths.signIn(), { replace: true });
    }
  }, [isAuthorized, pathname]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Confirmation />
      <AppNavbar />
      <Sidebar />
      <Grid container justifyContent="space-around" className={classes.appWrapper}>
        <Grid item xs={11} className={classes.sidebar}>
          <main className={classes.mainHolder}>{children}</main>
        </Grid>
      </Grid>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  appWrapper: {
    paddingTop: 30,
    paddingBottom: 30,
    [theme.breakpoints.up('md')]: {
      paddingTop: 10,
    },
  },
  mainHolder: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    [theme.breakpoints.down(767)]: {
      flexGrow: 1,
    },
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    overflow: 'hidden',
  },
  success: {
    backgroundColor: 'rgba(91, 189, 114, 0.95)',
  },
  sidebar: {
    paddingLeft: 0,
    [theme.breakpoints.up('md')]: {
      paddingLeft: 50,
    },
  },
}));
