/* eslint-disable react/no-array-index-key */
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from '@material-ui/core/Typography';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import React, { useState, useEffect } from 'react';

import styles from '../../assets/Questionnaire.module.css';
import utils from '../utils/utils';

import TextFieldInput from './TextFieldInput';

const answerCommentField = {
  type: 'text',
  name: 'comment',
  label: 'Add a Comment',
  required: false,
  width: 12,
  rows: 1,
};

// ui reflection of file view
function MultipleChoiceInput(props) {
  const { field, value: initialValue, handleChangeWithParams, selectedLanguage } = props;
  const [currentValue, setCurrentValue] = useState(initialValue || '');
  const [currentMultiValue, setCurrentMultiValue] = useState(initialValue || []);

  // Initial load
  useEffect(() => {
    if (!initialValue) {
      // clear out previous question state
      setCurrentMultiValue([]);
    }
    if (field.multiSelect && initialValue) {
      // Set selections to previously selected values
      const multiValues = {};
      initialValue.forEach((item) => {
        multiValues[item] = true;
      });
      setCurrentMultiValue(multiValues);
    } else if (field.multiSelect && field.containsAll && field.selectAll) {
      const allOfTheAbove = handleAllOption(field.selectAll);
      setCurrentMultiValue(allOfTheAbove);
    } else if (initialValue !== currentValue) {
      // Single-select initial value
      setCurrentValue(initialValue || '');
    }

    // eslint-disable-next-line
  }, [initialValue, field.multiSelect, field.containsAll, field.selectAll]);

  const handleAllOption = (checked, allOption) => {
    let newMultiValue = {};
    if (checked) {
      const allOfTheAbove = {};
      field.options.forEach((option) => {
        const valueName = option.value?.translations?.eng || option.value;
        allOfTheAbove[valueName] = !option.none;
      });
      newMultiValue = { ...allOfTheAbove };
    } else {
      newMultiValue = { ...currentMultiValue, [allOption]: false };
    }
    return newMultiValue;
  };

  const handleSingleChange = (event) => {
    setCurrentValue(event.target.value);
    handleChangeWithParams(field.name, [event.target.value]);
  };

  const handleMultiChange = (event) => {
    const { name } = event.target;
    const { checked } = event.target;
    const noneOption = field.options.find((option) => option.none);
    const noneOptionName = noneOption?.value.translations?.eng || noneOption?.value;
    const allOption = field.options.find((option) => option.all);
    const allOptionName = allOption?.value.translations?.eng || allOption?.value;
    let newMultiValue = {};
    if (noneOptionName === name) {
      const multiValueClone = { ...currentMultiValue };
      Object.keys(multiValueClone).forEach((v) => {
        multiValueClone[v] = false;
      });
      newMultiValue = { ...multiValueClone, [name]: checked };
    } else if (allOptionName === name) {
      newMultiValue = handleAllOption(checked, allOptionName);
    } else {
      newMultiValue = { ...currentMultiValue, [name]: checked };
      if (noneOptionName) newMultiValue[noneOptionName] = false;
      const checkAll = checked && Object.values(newMultiValue).filter((val) => val === true).length;
      const totalLength = field.options.filter((option) => !option.none && !option.all).length;
      if (allOptionName && checkAll === totalLength) newMultiValue[allOptionName] = true;
      if (allOptionName && !checked) newMultiValue[allOptionName] = false;
    }
    const trueValues = Object.keys(newMultiValue).filter((k) => newMultiValue[k]);
    setCurrentMultiValue(newMultiValue);
    handleChangeWithParams(field.name, trueValues);
  };

  const renderCommentForAnswer = (optionValue) => {
    // change field name related to answer
    const commentField = { ...answerCommentField };
    commentField.name = `${optionValue}_${commentField.name}`;
    commentField.questionComment = true;
    return (
      <Grid className={styles.checkboxCommentItem}>
        <TextFieldInput
          field={commentField}
          value=""
          handleChangeWithParams={handleChangeWithParams}
          disabled={field.disabled}
        />
        <Divider style={{ marginTop: 15 }} />
      </Grid>
    );
  };

  const renderAlertForAnswer = (type, text) => {
    const instructionCSS = () => {
      switch (type) {
        case 'Alert':
          return styles.instruction_Alert;

        case 'Warning':
          return styles.instruction_Warning;

        case 'Instruction':
          return styles.instruction_Instruction;
        default:
          return styles.instruction_Other;
      }
    };
    return (
      <Grid className={instructionCSS()}>
        {type === 'Alert' && <ReportProblemOutlinedIcon style={{ marginRight: 10 }} />}
        <Typography>{text}</Typography>
      </Grid>
    );
  };

  const fieldTitle =
    selectedLanguage && field.name && field.name.translations
      ? field.name.translations[selectedLanguage]
      : field.label || field.name;
  const questionDescription =
    selectedLanguage && field.description && field.description.translations
      ? field.description.translations[selectedLanguage]
      : field.description;
  const getInstructionText = (selectedLanguage, option) => {
    if (selectedLanguage && option[`instructionText-${selectedLanguage}`]) {
      return option[`instructionText-${selectedLanguage}`];
    }
    if (typeof option.instructionText === 'object') {
      return option?.instructionText?.translations[`${selectedLanguage}`];
    }
    return option.instructionText;
  };
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} style={{ width: '100%' }}>
        {fieldTitle && (
          <Typography className={field.nameStyle ? styles[`title--${field.nameStyle}`] : styles['title--style1']}>
            {field.questionPreview ? utils.renderHTMLTags(fieldTitle) : fieldTitle}
            {field.required && '*'}
          </Typography>
        )}
        {field.image && (
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <img
              src={field.image}
              alt={fieldTitle}
              style={{
                display: 'block',
                marginLeft: 'auto',
                marginRight: 'auto',
                width: '90%',
              }}
            />
          </Grid>
        )}
        {questionDescription && (
          <Typography
            className={
              field.descriptionStyle ? styles[`description--${field.descriptionStyle}`] : styles['description--style1']
            }
          >
            {utils.renderHTMLTags(questionDescription)}
          </Typography>
        )}
      </Grid>

      <Grid item xs={12}>
        {field.multiSelect ? (
          <FormControl component="fieldset" className={styles.selectFieldsWrapper}>
            <FormGroup>
              {field.options &&
                field.options.map((option, i) => {
                  const checkedOption =
                    currentMultiValue[
                      option.value && option.value.translations ? option.value.translations.eng : option.value
                    ] || false;

                  const showCommentField =
                    checkedOption && field.questionPreview && field.commentEnabled && field.questionnaireCommentEnabled;

                  const showAlertField =
                    checkedOption &&
                    field.questionPreview &&
                    option.enableInstructions &&
                    option.instructionType &&
                    option.instructionText;

                  const instructionTextDisplay = getInstructionText(selectedLanguage, option);

                  const alertColor = () => {
                    switch (option.instructionType) {
                      case 'Alert':
                        return '#EA0F0F';

                      case 'Warning':
                        return '#F89D11';

                      default:
                        return '#EEEDF9';
                    }
                  };

                  return (
                    <Grid
                      key={`checkbox-${i}`}
                      className={
                        showAlertField && (option.instructionType === 'Alert' || option.instructionType === 'Warning')
                          ? styles.checkboxQuestionnaireItemWhiteText
                          : styles.checkboxQuestionnaireItem
                      }
                      style={{
                        backgroundColor: showAlertField && alertColor(),
                        color: '#fff',
                      }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={checkedOption}
                            onChange={handleMultiChange}
                            name={
                              option && option.value && option.value.translations
                                ? option.value.translations.eng
                                : option && option.value
                            }
                            className={styles.checkboxQuestionnaire}
                            disableRipple
                            style={{
                              color: '#577193',
                            }}
                          />
                        }
                        label={
                          selectedLanguage && option.value && option.value.translations
                            ? option.value.translations[selectedLanguage]
                            : option.value
                        }
                        className={styles.makeWhite}
                      />
                      {showAlertField && renderAlertForAnswer(option.instructionType, instructionTextDisplay)}
                      {showCommentField && option.allowComment
                        ? renderCommentForAnswer(
                            option.value && option.value.translations ? option.value.translations.eng : option.value
                          )
                        : null}
                    </Grid>
                  );
                })}
            </FormGroup>
          </FormControl>
        ) : (
          <FormControl component="fieldset" className={styles.yesNoWrapper}>
            <RadioGroup
              aria-label="mc"
              name={field.name && field.name.translations ? field.name.translations[selectedLanguage] : field.name}
              value={currentValue}
              onChange={handleSingleChange}
            >
              {field.options &&
                field.options.map((option, i) => {
                  const optionLabel =
                    selectedLanguage && option && option.value && option.value.translations
                      ? option.value.translations[selectedLanguage]
                      : option && option.value;

                  const showCommentField =
                    optionLabel === currentValue &&
                    field.questionPreview &&
                    field.commentEnabled &&
                    field.questionnaireCommentEnabled;

                  const showAlertField =
                    optionLabel === currentValue &&
                    field.questionPreview &&
                    option.enableInstructions &&
                    option.instructionType &&
                    option.instructionText;

                  const instructionTextDisplay = getInstructionText(selectedLanguage, option);
                  const getInstructionTranslation = (option) => {
                    if (option && option.value && option.value.translations) return option.value.translations.eng;
                    if (option && option.value) return option.value;
                    return false;
                  };
                  const alertColor = () => {
                    switch (option.instructionType) {
                      case 'Alert':
                        return '#EA0F0F';

                      case 'Warning':
                        return '#F89D11';

                      default:
                        return '#EEEDF9';
                    }
                  };
                  return (
                    <Grid
                      key={`radio-${i}`}
                      className={
                        showAlertField && (option.instructionType === 'Alert' || option.instructionType === 'Warning')
                          ? styles.checkboxQuestionnaireItemWhiteText
                          : styles.checkboxQuestionnaireItem
                      }
                      style={{
                        backgroundColor: showAlertField && alertColor(),
                      }}
                    >
                      <FormControlLabel
                        value={getInstructionTranslation(option)}
                        control={
                          <Radio
                            color="primary"
                            className={styles.checkboxQuestionnaireRadio}
                            disableRipple
                            style={{
                              color: '#577193',
                            }}
                          />
                        }
                        label={optionLabel}
                      />
                      {showAlertField && renderAlertForAnswer(option.instructionType, instructionTextDisplay)}
                      {showCommentField &&
                        renderCommentForAnswer(
                          option && option.value && option.value.translations
                            ? option.value.translations.eng
                            : option && option.value
                        )}
                    </Grid>
                  );
                })}
            </RadioGroup>
          </FormControl>
        )}
      </Grid>
    </Grid>
  );
}

export default MultipleChoiceInput;
