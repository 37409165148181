/* eslint-disable import/no-cycle */
// MUI
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { ExportToCsv } from 'export-to-csv';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { createInputHelper } from '../helpers/createInputHelper';

// ui reflection of file view
function FieldGroupCard(props) {
  const { field, values, handleChange, handleChangeWithParams, disabled, getChildren, autoSetValues } = props;
  const [editing, setEditing] = useState(false);
  const navigate = useNavigate();

  const options = {
    useKeysAsHeaders: true,
    filename: 'member-import-template',
    useBom: false,
    showLabels: true,
  };

  const csvExporter = new ExportToCsv(options);

  const handleCsvDownload = () => {
    csvExporter.generateCsv(field.csvData);
  };

  const handleRedirect = (url) => {
    navigate(url);
  };

  return (
    <Card>
      <CardHeader
        title={
          <Grid container alignItems="center" spacing={4}>
            <Grid item style={{ paddingLeft: 24 }}>
              <Typography variant="body1" color="primary">
                {field.label}
              </Typography>
            </Grid>
          </Grid>
        }
        action={
          <Grid container alignItems="center" spacing={4}>
            {field?.buttons?.length &&
              field?.buttons.map((item) => (
                <Grid item>
                  <Tooltip title={item.btnTooltip}>
                    <Button
                      variant={item.buttonVariant || 'outlined'}
                      color="primary"
                      onClick={() => handleRedirect(item.link)}
                    >
                      {item.button}
                    </Button>
                  </Tooltip>
                </Grid>
              ))}
            {field.buttonType === 'csvImport' && (
              <Grid item>
                <Tooltip title={field.btnTooltip}>
                  <Button variant={field.buttonVariant || 'outlined'} color="primary" onClick={handleCsvDownload}>
                    {field.button}
                  </Button>
                </Tooltip>
              </Grid>
            )}
            {field.sensitive && (
              <Grid item>
                <Tooltip title={editing ? 'Lock' : 'Unock'}>
                  <IconButton onClick={() => setEditing(!editing)}>
                    {editing ? <LockOpenIcon /> : <LockIcon />}
                  </IconButton>
                </Tooltip>
              </Grid>
            )}
          </Grid>
        }
      />
      <CardContent>
        <Grid container spacing={2}>
          {createInputHelper(
            field.fields,
            values,
            handleChange,
            handleChangeWithParams,
            autoSetValues,
            disabled || (field.sensitive && !editing),
            getChildren
          )}
        </Grid>
      </CardContent>
    </Card>
  );
}

export default FieldGroupCard;
