import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React from 'react';

// ui reflection of file view
function ImageUrlInput(props) {
  const { field, value, handleChange } = props;

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography>{field.label}</Typography>
      </Grid>
      <Grid item>
        {value ? (
          <img
            src={value}
            alt={field.name}
            style={{
              maxWidth: 200,
              maxHeight: 100,
              backgroundColor: '#F3F3F3',
            }}
          />
        ) : (
          <Typography variant="caption">{`No ${field.label} URL Provided`}</Typography>
        )}
      </Grid>
      <Grid item>
        <textarea
          className="k-textarea"
          name={field.name}
          rows="3"
          placeholder={`Paste ${field.label} URL Here...`}
          onChange={handleChange}
        >
          {value}
        </textarea>
      </Grid>
    </Grid>
  );
}

export default ImageUrlInput;
