import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ContentStyles from '../../../assets/jss/components/ContentStyles';
import { getVideos } from '../../Content/contentSlice';
import { selectVideos } from '../../Content/selectors';
import { selectLanguage } from '../../LanguageMenu/selectors';
import { selectFaqDeleting, selectFaqLoading, selectFaqSubmitting, selectTenantFaqs } from '../selectors';
import { getFaqs } from '../settingsSlice';
import AppDragExpansionWrapper from './DragExpansionWrapper';

export function SettingsDragWrapper(props) {
  const { type, currentTenant, faqType } = props;

  const language = useSelector(selectLanguage);
  const videos = useSelector(selectVideos);
  const tenantFaqs = useSelector(selectTenantFaqs);
  const faqLoading = useSelector(selectFaqLoading);
  const submitting = useSelector(selectFaqSubmitting);
  const deleting = useSelector(selectFaqDeleting);

  const dispatch = useDispatch();

  useEffect(() => {
    if (type === 'faq') {
      dispatch(getFaqs(currentTenant.id, faqType));
      // // transform Faqs - to new structure for language support
      // dispatch(transformFaqs(currentTenant));
    } else {
      dispatch(getVideos(currentTenant.id));
    }
  }, [currentTenant, type, language]);

  const faqItems = !tenantFaqs ? [] : tenantFaqs.map((faq) => ({ ...faq.items[0], id: faq._id }));

  return (
    <Grid>
      <AppDragExpansionWrapper
        data={type === 'faq' ? tenantFaqs && faqItems : videos && videos.list && videos.list.data}
        updateId={tenantFaqs?._id}
        type={type}
        faqType={faqType}
        dataLoading={type === 'faq' ? faqLoading : videos && videos.loading}
        currentTenant={currentTenant}
        submitting={submitting}
        deleting={deleting}
      />
    </Grid>
  );
}

export default withStyles(ContentStyles)(SettingsDragWrapper);

export function createContentPanels() {
  return withStyles(ContentStyles)(SettingsDragWrapper);
}
