import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const formSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    initForm: (state, { payload }) => ({
      ...payload,
    }),
    addData: (state, { payload }) => ({
      ...state,
      [payload.name]: payload.value,
    }),
    addUpdatedField: (state, { payload }) => ({
      ...state,
      updatedFields: {
        ...state.updatedFields,
        [payload.name]: payload.value,
      }
    }),
    setFormSubmitting: (state, { payload }) => ({
      ...state,
      submitting: payload,
    }),
    setFormDeleting: (state, { payload }) => ({
      ...state,
      deleting: payload,
    }),

    resetForm: () => initialState,
  },
});
const { addData, addUpdatedField } = formSlice.actions;
export const { initForm, resetForm, setFormSubmitting, setFormDeleting } = formSlice.actions;

export const addFormData = (name, value, collectUpdatedFields) => async (dispatch) => {
  dispatch(addData({ name, value }));

  if (collectUpdatedFields) dispatch(addUpdatedField({ name, value }));
};

export default formSlice.reducer;
