import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles(() => ({
  unknownResult: {
    color: '#505358',
  },
  positiveResult: {
    color: '#B1341A',
    backgroundColor: '#FCECE7',
    padding: '2px 4px 2px 4px',
    borderRadius: '5px',
    textTransform: 'capitalize',
  },
  negativeResult: {
    color: '#009104',
    backgroundColor: '#E8FBF0',
    padding: '2px 4px 2px 4px',
    borderRadius: '5px',
    textTransform: 'capitalize',
  },
  invalidResult: {
    color: '#ffa500',
  },
  na: {
    color: '#505358',
  },
  openForResults: {
    backgroundColor: '#E1F1FF',
    padding: '2px 4px 2px 4px',
    borderRadius: '5px',
    color: '#003C71',
    textTransform: 'capitalize',
  },
}));

function OrdersResultCell(props) {
  const { dataItem, assessmentType } = props;
  const classes = useStyles();

  let resultClass = classes.unknownResult;

  const getResponse = () => {
    let assessmentVal = 'N/A';
    const panelId = dataItem?.orderablePanelId;
    if (
      panelId &&
      ['autoAssessment', 'diagnosticsResult'].includes(assessmentType) &&
      dataItem.status?.toLowerCase() === 'completed'
    ) {
      assessmentVal = 'PANEL';
    } else if (!panelId && dataItem?.metadata?.selfAssessmentOutcome && assessmentType === 'selfAssessment') {
      assessmentVal = dataItem?.metadata?.selfAssessmentOutcome;
    } else if (!panelId && dataItem?.metadata?.outcome && assessmentType === 'autoAssessment') {
      assessmentVal = dataItem?.metadata?.outcome;
    } else if (!panelId && dataItem?.orderableTestResults?.[0]?.testResult && assessmentType === 'diagnosticsResult') {
      assessmentVal = dataItem?.orderableTestResults?.[0]?.testResult;
    }

    switch (assessmentVal) {
      case 'NEGATIVE':
      case 'Negative':
      case '1': {
        return <span className={classes.negativeResult}>NEGATIVE</span>;
      }
      case 'POSITIVE':
      case 'Positive':
      case '2': {
        return <span className={classes.positiveResult}>POSITIVE</span>;
      }
      case 'N/A': {
        resultClass = classes.na;
        return 'N/A';
      }
      case 'INVALID':
      case 'Invalid': {
        resultClass = classes.invalidResult;
        return 'Invalid';
      }
      case 'ERROR': {
        resultClass = classes.na;
        return 'Error';
      }
      case 'PANEL': {
        return <span className={classes.openForResults}>OPEN FOR RESULTS</span>;
      }
      default: {
        resultClass = classes.unknownResult;
        return 'Unknown';
      }
    }
  };

  const result = getResponse();

  if (result === 'N/A') {
    resultClass = classes.na;
  }

  return (
    <td className={resultClass} style={{ fontWeight: 700 }}>
      {result}
    </td>
  );
}

export default OrdersResultCell;
