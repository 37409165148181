import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// import { updateEncounterNotes } from '../Encounters/encountersSlice';
import { getFormattedAnswer } from '../VirtualConsult/QuestionnaireBuilder/Reporter/helpers/helpers';
import { selectReporterAnswers } from '../VirtualConsult/vcb-package/selectors';

function NoteForm({ noteText, setNoteText, sendingNote, onHandleSend }) {
  const reporterAnswers = useSelector(selectReporterAnswers);

  // answer ids that are already in SOAP note
  const [answersInSOAPNote, setAnswersInSOAPNote] = useState([]);

  // const dispatch = useDispatch();

  const handleChange = (e) => {
    const { value } = e.target;
    setNoteText(value);
  };

  useEffect(() => {
    if (reporterAnswers && reporterAnswers.length) {
      const updatedAnswersInSOAPNote = [...answersInSOAPNote];
      const subjective = [];
      const objective = [];
      const assessment = [];
      const plan = [];

      reporterAnswers.forEach((answerObj) => {
        // if the answer is not yet on the list
        if (updatedAnswersInSOAPNote.indexOf(answerObj.nodeId) == -1) {
          const { target } = answerObj.report;

          // Subjective path note
          if (target.match(/Subjective/gi)) {
            subjective.push(getFormattedAnswer(answerObj));

            // Objective path note
          } else if (target.match(/Objective/gi)) {
            objective.push(getFormattedAnswer(answerObj));

            // Assessment path note
          } else if (target.match(/Assessment/gi)) {
            assessment.push(getFormattedAnswer(answerObj));

            // Plan path note
          } else if (target.match(/Plan/gi)) {
            plan.push(getFormattedAnswer(answerObj));
          }

          updatedAnswersInSOAPNote.push(answerObj.nodeId);
        }
      });

      let updatedNoteText = noteText;

      if (subjective.length) {
        updatedNoteText = noteTextFormatter(
          updatedNoteText, // note text
          'Objective', // replace up to value
          subjective // answers arr
        );
      }

      if (objective.length) {
        updatedNoteText = noteTextFormatter(
          updatedNoteText, // note text
          'Assessment', // replace up to value
          objective // answers arr
        );
      }

      if (assessment.length) {
        updatedNoteText = noteTextFormatter(
          updatedNoteText, // note text
          'Plan', // replace up to value
          assessment // answers arr
        );
      }

      if (plan.length) {
        updatedNoteText = noteTextFormatter(
          updatedNoteText, // note text
          'Time spent', // replace up to value
          plan // answers arr
        );
      }

      setNoteText(updatedNoteText);
      setAnswersInSOAPNote(updatedAnswersInSOAPNote);
    }
  }, [reporterAnswers]);

  const noteTextFormatter = (text, searchTerm, answersArr) => {
    const findText = text.match(new RegExp(`(.*?)(\n|\n\n)${searchTerm}`));

    if (findText && findText[1]) {
      const answers =
        findText[1].substr(-1) == ':'
          ? `${findText[1] + answersArr.join(', ')}\n`
          : `${findText[1]}, ${answersArr.join(', ')}`;
      text = text.replace(findText[1], answers);
    }

    return text;
  };

  return (
    <>
      <TextField
        id="outlined-multiline-static"
        multiline
        rows={10}
        variant="outlined"
        fullWidth
        value={noteText}
        onChange={handleChange}
      />

      <Grid style={{ marginTop: 30 }}>
        <Button variant="outlined" color="primary" disabled={!noteText || sendingNote} onClick={onHandleSend} fullWidth>
          Submit
        </Button>
      </Grid>

      <Grid item container spacing={3} style={{ paddingTop: 30 }}>
        <Grid md={6} style={{ padding: 10 }}>
          <Button variant="contained" color="primary" fullWidth>
            Informed
          </Button>
        </Grid>

        <Grid md={6} style={{ padding: 10 }}>
          <Button variant="outlined" color="primary" fullWidth>
            No Response
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

export default NoteForm;
