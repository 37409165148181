import { createSlice } from '@reduxjs/toolkit';

import api from '../../../api';
import { showNotification } from '../../Notifications/notificationSlice';
import { handleError } from '../../Shared/Content/APIUtils';

const initialState = {
  allergyList: {},
  allergyListLoading: null,
};

export const allergyListSlice = createSlice({
  name: 'allergyList',
  initialState,
  reducers: {
    setAllergyListLoading: (state, { payload }) => ({
      ...state,
      allergyListLoading: payload,
    }),
    setAllergyList: (state, { payload }) => ({
      ...state,
      allergyList: payload,
      allergyListLoading: false,
    }),
  },
});

export const { setAllergyList, setAllergyListLoading } = allergyListSlice.actions;

export const getAllergyList = (safeEMRId) => async (dispatch) => {
  dispatch(setAllergyListLoading(true));

  const [result, error] = await api.getAllergyListRequest(safeEMRId);

  if (error && error.message) {
    return dispatch(handleError(error));
  }

  if (result) {
    dispatch(setAllergyList(result));
  }
};

export const postAllergyList = (safeEMRId, data) => async (dispatch) => {
  dispatch(setAllergyListLoading(true));

  const [result, error] = await api.postAllergyListRequest(safeEMRId, data);

  if (result) {
    dispatch(showNotification('Allergy Details Successfully Sent', 'success'));
    dispatch(getAllergyList(safeEMRId));
  }

  if (error && error.message) {
    dispatch(handleError(error));
  }
};

export const postAllergyToDrfirst = (safeEMRId) => async (dispatch) => {
  dispatch(setAllergyListLoading(true));

  const [result, error] = await api.postAllergyToDrfirstRequest(safeEMRId);

  if (result) {
    dispatch(showNotification('Drfirst sync Successful', 'success'));
    dispatch(setAllergyListLoading(false));
  }

  if (error && error.message) {
    dispatch(handleError(error));
  }
};

export default allergyListSlice.reducer;
