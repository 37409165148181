/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable import/no-cycle */
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { showNotification } from '../../Notifications/notificationSlice';
import { initForm, resetForm, setFormSubmitting } from '../../Shared/Forms/formSlice';
import { selectFormData } from '../../Shared/Forms/selectors';
import AppTabbedPageWrapper from '../../Shared/Pages/TabbedPageWrapper';
import { selectCurrentTenantId } from '../../Shared/selectors';
import { outcomeType } from '../OutcomesBuilder/helpers/formHelper';
import { getOptionList, getQuestionnaireAnnotations } from '../Reviewer/reviewerSlice';
import { selectEditingReviewerQuestion } from '../Reviewer/selectors';
import { selectVirtualConsult } from '../selectors';
import Questionnaire from '../vcb-package/Questionnaire';
import { setReporterAnswers } from '../vcb-package/questionnaireSlice';
import { createQuestionnaire, getContentById, updateQuestionnaire } from '../virtualConsultSlice';

import { deconstructJson } from './helpers/formHelper';
import { questionnaireJsonBuilder } from './helpers/questionnaireJsonBuilder';
import QuestionnaireFormWrapper from './QuestionnaireFormWrapper';
import Reporter from './Reporter/Reporter';
import AppQuestionnaireVariables from './Tabs/QuestionnaireVariables';

export const QuestionnaireBuilderContext = React.createContext(null);

function QuestionnaireBuilderWrapper() {
  const currentTenant = useSelector(selectCurrentTenantId);
  const editingReviewerQuestion = useSelector(selectEditingReviewerQuestion);
  const formData = useSelector(selectFormData);
  const consult = useSelector(selectVirtualConsult);

  const [details, setDetails] = useState(false);
  const [create, setCreate] = useState(false);
  const [type, setType] = useState('');
  const [id, setId] = useState('');
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [nodes, setNodes] = useState([]);
  const [editingQuestion, setEditingQuestion] = useState({});
  const [originalQuestionnaireState, setOriginalQuestionnaireState] = useState();
  const [windowPosition, setWindowPosition] = useState(0);
  // Monitors the state of the questionnaire and prevents autosave if no change by user
  const [questionnaireUpdated, setQuestionnaireUpdated] = useState(false);
  // Refs to control window position in Questionnaire Builder
  const questionsRef = useRef([]);
  const outcomeTypes = outcomeType.map((i) => i.value);

  const allowTabChange = formData.title;
  const disabledTab = !id || Object.keys(editingQuestion).length > 0;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Cleanup function for form
  useEffect(() => () => dispatch(resetForm()), []);

  // Add or remove refs for questionnaire form if number of questions changes
  useEffect(() => {
    questionsRef.current = questionsRef.current.slice(0, selectedQuestions.length);
  }, [selectedQuestions.length, nodes.length]);

  useEffect(() => {
    if (editingReviewerQuestion) {
      setType('questionnaire');
    }
  }, [editingReviewerQuestion]);

  // Load questionnaire to be edited
  const setQuestionnaire = useCallback(() => {
    const url = window.location.pathname;
    if (url.includes('details')) {
      // let type = url.split("/")[5];
      const id = url.split('/').pop();

      dispatch(getContentById(currentTenant, 'questionnaires', id)).then((res) => {
        const questionnaireForm = deconstructJson(res);
        dispatch(initForm(questionnaireForm));
        setNodes(questionnaireForm.nodes);
        setSelectedQuestions(questionnaireForm.questionList);
        setOriginalQuestionnaireState(questionnaireForm);
      });

      dispatch(getQuestionnaireAnnotations(id));
      dispatch(getOptionList(currentTenant, 'concern'));

      setDetails(true);
      // setType(type);
      setId(id);
    } else {
      setCreate(true);
    }
  }, [currentTenant, dispatch]);

  useEffect(() => {
    setQuestionnaire();
  }, [currentTenant, setQuestionnaire]);

  const handleSubmit = async (
    selectedQuestions,
    selectedNodes,
    updatedFormData = formData,
    notify = true,
    redirect = false
  ) => {
    let data;
    if (!details) {
      data = {
        ...updatedFormData,
        created: moment(formData.created).toISOString(),
        updated: moment(formData.updated).toISOString(),
      };
    } else {
      data = {
        ...updatedFormData,
        created: moment(formData.created).toISOString(),
        updated: moment(formData.updated).toISOString(),
        nodes: [...selectedNodes],
        questionList: [...selectedQuestions.filter((item) => outcomeTypes.indexOf(item.type) === -1)],
      };
    }
    data = questionnaireJsonBuilder(data);
    if (details) {
      // Check questions and nodes to prevent sudden data loss due to network error
      // An exception is made if the profile page is intentionally edited
      if (
        (originalQuestionnaireState.title && selectedQuestions.length) ||
        data.commentEnabled !== originalQuestionnaireState.commentEnabled ||
        data.title !== originalQuestionnaireState.title
      ) {
        await dispatch(
          updateQuestionnaire(currentTenant, { ...data, tenantId: currentTenant }, id, notify, redirect)
        ).then(() => setQuestionnaire());
      } else {
        dispatch(setFormSubmitting(false)).then(() =>
          dispatch(showNotification('Questionnaires must have at least one question', 'error'))
        );
      }
    } else {
      await dispatch(
        createQuestionnaire(currentTenant, {
          ...data,
          tenantId: currentTenant,
          redirect,
        })
      ).then((res) => {
        setDetails(true);
        setId(res.id);
        if (!redirect) {
          navigate(`/${currentTenant}/virtual-consult/questionnaires/details/questionnaire/${res.id}`);
        } else {
          navigate(`/${currentTenant}/virtual-consult/questionnaires`);
        }
      });
    }
  };

  // const handleQuestionnaireComplete = () => {
  //   console.log('Questionnaire Completed');
  // };

  const tabs = [
    {
      label: 'Profile',
      type: 'profile',
      disabled: false,
      url: details
        ? `virtual-consult/questionnaires/details/profile/${id}`
        : 'virtual-consult/questionnaires/create/profile',
      content: (
        <QuestionnaireBuilderContext.Provider
          value={{
            nodes,
            setNodes,
            selectedQuestions,
            setSelectedQuestions,
            handleSubmit,
            setQuestionnaireUpdated,
          }}
        >
          <QuestionnaireFormWrapper
            details={details}
            formData={formData}
            type="profile"
            currentTenant={currentTenant}
            id={id}
            setType={setType}
            editingQuestion={editingQuestion}
            setEditingQuestion={setEditingQuestion}
          />
        </QuestionnaireBuilderContext.Provider>
      ),
    },
    {
      label: 'Questionnaire Variables',
      type: 'variables',
      disabled: !formData.title,
      url: details
        ? `virtual-consult/questionnaires/details/variables/${id}`
        : 'virtual-consult/questionnaires/create/variables',
      content: (
        <AppQuestionnaireVariables
          questionnaireId={id}
          // currentTenant={currentTenant}
        />
      ),
    },
    {
      label: 'Questionnaire Builder',
      type: 'questionnaire',
      disabled: !formData.title,
      url: details
        ? `virtual-consult/questionnaires/details/questionnaire/${id}`
        : 'virtual-consult/questionnaires/create/questionnaire',
      content: (
        <QuestionnaireBuilderContext.Provider
          value={{
            nodes,
            setNodes,
            selectedQuestions,
            setSelectedQuestions,
            handleSubmit,
            setQuestionnaireUpdated,
            windowPosition,
            setWindowPosition,
            questionsRef,
          }}
        >
          <QuestionnaireFormWrapper
            details={details}
            type="questionnaire"
            currentTenant={currentTenant}
            id={id}
            setType={setType}
            editingQuestion={editingQuestion}
            setEditingQuestion={setEditingQuestion}
          />
        </QuestionnaireBuilderContext.Provider>
      ),
    },
    {
      label: 'Questionnaire Preview',
      type: 'preview',
      disabled: disabledTab,
      url: `virtual-consult/questionnaires/details/preview/${id}`,
      content: (
        <Grid container direction="column" justifyContent="center" alignItems="center">
          <Grid item>
            <Questionnaire
              questionnaireId={consult?.questionnaire?.id}
              tenantId={currentTenant}
              isVcbAminConsole
              startFromStoppedQuestion={false}
              withReporterFunc
            />
          </Grid>
          <Grid item>
            <Reporter />
          </Grid>
        </Grid>
      ),
    },
  ];

  const handleTabChange = (tabDetails) => {
    const currentUrl = window.location.pathname;
    const redirectUrl = `/${currentTenant}/${tabDetails.url}`;
    if (currentUrl !== redirectUrl && questionnaireUpdated) {
      setQuestionnaireUpdated(false);
      handleSubmit(selectedQuestions, nodes, formData, true, false);
      navigate(redirectUrl);
    }

    if (tabDetails?.type === 'preview') {
      dispatch(setReporterAnswers({}));
    }
  };
  if (!currentTenant) return <div />;
  if (!type && !details && !create) return null;

  return (
    <>
      {/* {dialogOpen && ( */}
      {/*  <AddSlackUserNameDialog */}
      {/*    dialogOpen={dialogOpen} */}
      {/*    setDialogOpen={setDialogOpen} */}
      {/*  /> */}
      {/* )} */}

      <AppTabbedPageWrapper
        tabs={tabs}
        openTab={tabs.find((t) => t.type === type)}
        onTabChange={handleTabChange}
        allowTabChange={!!allowTabChange}
      />
    </>
  );
}

export default QuestionnaireBuilderWrapper;
