/* eslint-disable react/no-array-index-key */
/* eslint-disable import/no-cycle */
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Input } from '@progress/kendo-react-inputs';
import React from 'react';

import NetworkAssetsWrapper from '../../../Networks/NetworkAssetsWrapper';
import AddressInput from '../KendoFields/AddressInput';
import AutocompleteInput from '../KendoFields/AutocompleteInput';
import BooleanField from '../KendoFields/BooleanField';
import ButtonLabelField from '../KendoFields/ButtonLabelField';
import CheckboxInput from '../KendoFields/CheckboxInput';
import ColorPickerInput from '../KendoFields/ColorPickerInput';
import ConditionalField from '../KendoFields/ConditionalField';
import ConsentForm from '../KendoFields/ConsentForm';

import CustomClickAwayListner from '../KendoFields/CustomClickAwayListner';
import DateInput from '../KendoFields/DateInput';
import DropdownField from '../KendoFields/DropdownField';
import DynamicListField from '../KendoFields/DynamicListField';
import EmailInput from '../KendoFields/EmailInput';
import FieldGroupCard from '../KendoFields/FieldGroupCard';
import FileUploader from '../KendoFields/FileUploader';
import HtmlInputText from '../KendoFields/HtmlInputText';
import ImageInput from '../KendoFields/ImageInput';
import Previews from '../KendoFields/ImageUploadCrop';
import ImageUrlInput from '../KendoFields/ImageUrlInput';
import MeasurementField from '../KendoFields/MeasurementField';
import MultipleChoiceInput from '../KendoFields/MultipleChoiceInput';
import MultipleTextInput from '../KendoFields/MultipleTextInput';
import MultiSelectInput from '../KendoFields/MultiSelectInput';
import MultiSelectTable from '../KendoFields/MultiSelectTable';
import NumberFieldInput from '../KendoFields/NumberFieldInput';
import PhoneInputField from '../KendoFields/PhoneInputField';
import ScaleInput from '../KendoFields/ScaleInput';
import SliderInput from '../KendoFields/SliderInput';
import TagMultiSelect from '../KendoFields/TagMultiSelect';
import TextFieldInput from '../KendoFields/TextFieldInput';
import TimePicker from '../KendoFields/TimePicker';
import ToggleInput from '../KendoFields/ToggleField';
import ProvidersTypeahead from '../KendoFields/Typeahead/ProvidersTypeahead';
import Typeahead from '../KendoFields/Typeahead/Typeahead';
import TypeaheadMultiSelect from '../KendoFields/Typeahead/TypeaheadMultiSelect';
import UserSearchInput from '../KendoFields/UserSearchInput';
import VerificationCode from '../KendoFields/VerificationCode';
import VideoUploadComponent from '../KendoFields/VideoUploadComponent';

// Map through form helper json to dynamically build form
export function createInputHelper(
  helper,
  values,
  handleChange,
  handleChangeWithParams,
  autoSetValues,
  disabled,
  getChildren,
  selectedLanguage
) {
  const fieldInputs = [];
  helper.forEach((field, i) => {
    if (
      field.type === 'text' ||
      field.type === 'small-text' ||
      field.type === 'large-text' ||
      (field.type === 'zip' && !field.hidden)
    ) {
      fieldInputs.push(
        <Grid key={field.name} item md={field.width} xs={12} style={{ paddingTop: 10, paddingBottom: 10 }}>
          <TextFieldInput
            field={field}
            value={values[field.name] || field?.initialValue}
            handleChange={handleChange}
            disabled={disabled || field.disabled}
            selectedLanguage={selectedLanguage}
          />
        </Grid>
      );
    } else if (field.type === 'password') {
      fieldInputs.push(
        <Grid key={field.name} item md={field.width} xs={12} style={{ paddingTop: 10, paddingBottom: 10 }}>
          <TextFieldInput
            field={{ ...field, sensitive: true }}
            value={values[field.name]}
            handleChange={handleChange}
            disabled={disabled || field.disabled}
            selectedLanguage={selectedLanguage}
          />
        </Grid>
      );
    } else if (field.type === 'multiple-small-text') {
      fieldInputs.push(
        <Grid key={field.name} item md={field.width} xs={12} style={{ paddingTop: 10, paddingBottom: 10 }}>
          <MultipleTextInput
            field={field}
            value={values[field.name]}
            handleChange={handleChange}
            disabled={disabled || field.disabled}
            selectedLanguage={selectedLanguage}
          />
        </Grid>
      );
    } else if (field.type === 'number' || field.type === 'numeric') {
      fieldInputs.push(
        <Grid key={field.name} item md={field.width} xs={12} style={{ paddingTop: 10, paddingBottom: 10 }}>
          <NumberFieldInput
            field={field}
            value={values[field.name]}
            handleChange={handleChange}
            disabled={disabled}
            selectedLanguage={selectedLanguage}
          />
        </Grid>
      );
    } else if (field.type === 'date') {
      fieldInputs.push(
        <Grid key={field.name} item md={field.width} xs={12} style={{ paddingTop: 10, paddingBottom: 10 }}>
          <DateInput
            field={field}
            value={values[field.name]}
            handleChangeWithParams={handleChangeWithParams}
            disabled={disabled}
            selectedLanguage={selectedLanguage}
          />
        </Grid>
      );
    } else if (field.type === 'toggle') {
      const toggleValue =
        (values[field.name] === null || values[field.name] === undefined) && field.defaultValue
          ? field.defaultValue
          : values[field.name];
      fieldInputs.push(
        <Grid
          key={field.name}
          item
          md={field.width}
          xs={12}
          style={{
            paddingTop: 10,
            paddingBottom: 10,
            paddingLeft: field.customPaddingLeft ?? 0,
          }}
        >
          <ToggleInput
            field={field}
            value={toggleValue}
            handleChangeWithParams={handleChangeWithParams}
            disabled={disabled}
          />
          {field.requiredField && (
            <Grid
              key={field.requiredField.name}
              item
              md={field.requiredField.width}
              xs={12}
              style={{ paddingTop: 20, paddingLeft: 20 }}
            >
              <ToggleInput
                field={field.requiredField}
                value={values[field.requiredField.name]}
                handleChangeWithParams={handleChangeWithParams}
              />
            </Grid>
          )}
          {field.fields && (
            <Grid container style={{ marginLeft: 20 }}>
              {createInputHelper(field.fields, values, handleChange, handleChangeWithParams, autoSetValues)}
            </Grid>
          )}
        </Grid>
      );
    } else if (field.type === 'dropdown' || field.type === 'multiple-drop') {
      fieldInputs.push(
        <Grid key={field.name} item md={field.width} xs={12} style={{ paddingTop: 10, paddingBottom: 10 }}>
          <DropdownField
            field={field}
            value={values[field.name]}
            handleChangeWithParams={handleChangeWithParams}
            disabled={disabled}
          />
        </Grid>
      );
    } else if (field.type === 'autocomplete') {
      fieldInputs.push(
        <Grid key={field.name} item md={field.width} xs={12} style={{ paddingTop: 10, paddingBottom: 10 }}>
          <AutocompleteInput
            field={field}
            initialValue={values[field.name] ?? field.initialValue}
            handleChangeWithParams={handleChangeWithParams}
            handleChange={handleChange}
            disabled={disabled || field.disabled || field.readOnly}
            autoSetValues={autoSetValues}
          />
        </Grid>
      );
    } else if (field.type === 'typeahead' || field.type === 'typeaheadVcb') {
      let valueObj = null;
      if (values[field.name]) {
        valueObj = {};
        valueObj[field.labelField] = values[field.name];
      }
      fieldInputs.push(
        <Grid key={field.name} item md={field.width} xs={12} style={{ paddingTop: 10, paddingBottom: 10 }}>
          <Typeahead
            field={field}
            value={field.type === 'typeaheadVcb' ? values[field.name] : valueObj}
            handleChangeWithParams={handleChangeWithParams}
            handleChange={handleChange}
            autoSetValues={autoSetValues}
            disabled={disabled}
          />
        </Grid>
      );
    } else if (field.type === 'multiSelectTypeAhead') {
      fieldInputs.push(
        <Grid key={field.name} item md={field.width} xs={12} style={{ paddingTop: 10, paddingBottom: 10 }}>
          <TypeaheadMultiSelect
            field={field}
            value={values[field.name]}
            handleChangeWithParams={handleChangeWithParams}
            handleChange={handleChange}
            autoSetValues={autoSetValues}
            disabled={disabled}
          />
        </Grid>
      );
    } else if (field.type === 'multiselecttag') {
      fieldInputs.push(
        <Grid key={field.name} item md={field.width} xs={12} style={{ paddingTop: 10, paddingBottom: 10 }}>
          <TagMultiSelect
            field={field}
            value={values[field.name]}
            handleChangeWithParams={handleChangeWithParams}
            handleChange={handleChange}
            autoSetValues={autoSetValues}
            disabled={disabled}
          />
        </Grid>
      );
    } else if (field.type === 'multiselect') {
      fieldInputs.push(
        <Grid key={field.name} item md={field.width} xs={12} style={{ paddingTop: 10, paddingBottom: 10 }}>
          <MultiSelectInput
            field={field}
            value={values[field.name]}
            handleChangeWithParams={handleChangeWithParams}
            handleChange={handleChange}
            disabled={disabled || field.disabled}
          />
        </Grid>
      );
    } else if (field.type === 'multiselecttable') {
      fieldInputs.push(
        <Grid key={field.name} item md={field.width} xs={12} style={{ paddingTop: 10, paddingBottom: 10 }}>
          <MultiSelectTable
            field={field}
            values={values[field.name]}
            handleChangeWithParams={handleChangeWithParams}
            disabled={disabled}
          />
        </Grid>
      );
    } else if (field.type === 'header') {
      fieldInputs.push(
        <Grid item xs={12} style={{ paddingTop: 10, paddingBottom: 10 }} key={`header-${i}`}>
          <Typography variant="body1" color="primary">
            {field.label}
          </Typography>
          {field.description && (
            <Typography variant="caption" component="p" style={{ opacity: 0.5 }}>
              {field.description}
            </Typography>
          )}
        </Grid>
      );
    } else if (field.type === 'spacer') {
      fieldInputs.push(
        <Grid
          item
          xs={12}
          style={{
            paddingTop: field.paddingTop || 10,
            paddingBottom: field.paddingBottom || 10,
            marginTop: field.marginTop || 10,
            marginBottom: field.marginBottom || 10,
          }}
          key={`spacer-${i}`}
        />
      );
    } else if (field.type === 'html') {
      fieldInputs.push(
        <Grid key={field.name} item md={field.width} xs={12} style={{ paddingTop: 10, paddingBottom: 10 }}>
          <HtmlInputText
            field={field}
            value={values[field.name]}
            disabled={disabled || field.disabled}
            handleChangeWithParams={handleChangeWithParams}
          />
        </Grid>
      );
    } else if (field.type === 'imageCrop' || field.type === 'photo') {
      fieldInputs.push(
        <Grid key={field.name} item md={field.width} xs={12} style={{ paddingTop: 10, paddingBottom: 10 }}>
          <Previews
            field={field}
            value={values[field.name]}
            handleChange={handleChange}
            disabled={disabled}
            fileTypeCaption={field?.fileTypeCaption}
          />
        </Grid>
      );
    } else if (field.type === 'email') {
      fieldInputs.push(
        <Grid key={field.name} item md={field.width} xs={12} style={{ paddingTop: 10, paddingBottom: 10 }}>
          <EmailInput
            field={field}
            initialValue={values[field.name]}
            disabled={disabled}
            handleChangeWithParams={handleChangeWithParams}
            selectedLanguage={selectedLanguage}
          />
        </Grid>
      );
    } else if (field.type === 'colorpicker') {
      fieldInputs.push(
        <Grid item key={field.name} xs={12} md={field.width} style={{ paddingTop: 10, paddingBottom: 10 }}>
          <ColorPickerInput field={field} value={values[field.name]} disabled={disabled} handleChange={handleChange} />
        </Grid>
      );
    } else if (field.type === 'grouped-colorpicker') {
      fieldInputs.push(
        <Grid item key={field.name} xs={12} md={field.width} style={{ paddingTop: 10, paddingBottom: 10 }}>
          {field.pickers.map((picker) => (
            <ColorPickerInput
              key={picker.name}
              field={picker}
              value={values[picker.name]}
              disabled={disabled || picker.disabled}
              handleChange={handleChange}
            />
          ))}
        </Grid>
      );
    } else if (field.type === 'video') {
      fieldInputs.push(
        <Grid key={field.name} item xs={12} md={field.width} style={{ paddingTop: 10, paddingBottom: 10 }}>
          <VideoUploadComponent
            field={field}
            value={values[field.name]}
            handleChange={handleChange}
            disabled={disabled}
          />
        </Grid>
      );
    } else if (field.type === 'checkbox') {
      fieldInputs.push(
        <Grid key={field.name} item xs={12} md={field.width} style={{ paddingTop: 10, paddingBottom: 10 }}>
          <CheckboxInput
            field={field}
            initialValue={values[field.name]}
            handleChangeWithParams={handleChangeWithParams}
            disabled={disabled}
          />
        </Grid>
      );
    } else if (field.type === 'blank') {
      fieldInputs.push(
        <Grid
          item
          md={field.width}
          style={{ paddingTop: 10, paddingBottom: 10 }}
          disabled={disabled}
          key={`blank-${i}`}
        />
      );
    } else if (field.type === 'divider') {
      fieldInputs.push(
        <Grid key={`divider-${i}`} item xs={12} style={{ paddingTop: 10, paddingBottom: 10 }}>
          <Divider style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }} />
        </Grid>
      );
    } else if (field.type === 'groupNoDropdown') {
      fieldInputs.push(
        <Grid key={field.name} item xs={12} style={{ paddingTop: 10, paddingBottom: 10 }}>
          <FieldGroupCard
            field={field}
            values={values}
            handleChange={handleChange}
            handleChangeWithParams={handleChangeWithParams}
            autoSetValues={autoSetValues}
            disabled={disabled}
            getChildren={getChildren}
          />
        </Grid>
      );
    } else if (field.type === 'group') {
      fieldInputs.push(
        <Grid key={field.name} item xs={12} style={{ paddingTop: 10, paddingBottom: 10 }}>
          <Accordion defaultExpanded={field.expanded === false ? field.expanded : true}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={field.name} id={field.name}>
              <Typography variant="body1" color="primary">
                {field.label}
              </Typography>
              {field.button && <Button variant="outlined">{field.button}</Button>}
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                {createInputHelper(
                  field.fields,
                  values,
                  handleChange,
                  handleChangeWithParams,
                  autoSetValues,
                  disabled,
                  getChildren
                )}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      );
    } else if (field.type === 'file') {
      fieldInputs.push(
        <Grid key={field.name} item xs={12} md={field.width} style={{ paddingTop: 10, paddingBottom: 10 }}>
          <FileUploader
            field={field}
            value={values[field.name]}
            handleChangeWithParams={handleChangeWithParams}
            disabled={disabled}
          />
        </Grid>
      );
    } else if (field.type === 'imageUrl') {
      fieldInputs.push(
        <Grid key={field.name} item xs={12} md={field.width} style={{ paddingTop: 10, paddingBottom: 10 }}>
          <ImageUrlInput field={field} value={values[field.name]} handleChange={handleChange} disabled={disabled} />
        </Grid>
      );
    } else if (field.type === 'image') {
      fieldInputs.push(
        <Grid key={field.name} item xs={12} md={field.width} style={{ paddingTop: 10, paddingBottom: 10 }}>
          <ImageInput field={field} value={values[field.name]} handleChange={handleChange} disabled={disabled} />
        </Grid>
      );
    } else if (field.type === 'yes-no') {
      fieldInputs.push(
        <Grid key={field.name} item xs={12} md={field.width} style={{ paddingTop: 10, paddingBottom: 10 }}>
          <BooleanField
            field={field}
            value={values}
            handleChange={handleChange}
            handleChangeWithParams={handleChangeWithParams}
            disabled={disabled}
            selectedLanguage={selectedLanguage}
          />
        </Grid>
      );
    } else if (field.type === 'multiple') {
      fieldInputs.push(
        <Grid key={field.name} item xs={12} md={field.width} style={{ paddingTop: 10, paddingBottom: 10 }}>
          <MultipleChoiceInput
            field={field}
            value={values[field.name]}
            handleChangeWithParams={handleChangeWithParams}
            handleChange={handleChange}
            disabled={disabled}
            selectedLanguage={selectedLanguage}
          />
        </Grid>
      );
    } else if (field.type === 'consent') {
      fieldInputs.push(
        <Grid key={field.name} item xs={12} md={field.width} style={{ paddingTop: 10, paddingBottom: 10 }}>
          <ConsentForm
            field={field}
            value={values}
            handleChange={handleChange}
            handleChangeWithParams={handleChangeWithParams}
            disabled={disabled}
            selectedLanguage={selectedLanguage}
          />
        </Grid>
      );
    } else if (field.type === 'scale') {
      fieldInputs.push(
        <Grid key={field.name} item xs={12} md={field.width} style={{ paddingTop: 10, paddingBottom: 10 }}>
          <ScaleInput
            field={field}
            value={values[field.name]}
            handleChangeWithParams={handleChangeWithParams}
            disabled={disabled}
            selectedLanguage={selectedLanguage}
          />
        </Grid>
      );
    } else if (field.type === 'slider') {
      fieldInputs.push(
        <Grid key={field.name} item xs={12} md={field.width} style={{ paddingTop: 10, paddingBottom: 10 }}>
          <SliderInput
            field={field}
            value={values[field.name]}
            handleChangeWithParams={handleChangeWithParams}
            disabled={disabled}
            selectedLanguage={selectedLanguage}
          />
        </Grid>
      );
    } else if (field.type === 'dynamicList') {
      fieldInputs.push(
        <Grid key={field.name} item xs={12} md={field.width} style={{ paddingTop: 10, paddingBottom: 10 }}>
          <DynamicListField
            field={field}
            initialValue={values[field.name]}
            handleChangeWithParams={handleChangeWithParams}
            disabled={disabled}
            getChildren={getChildren}
          />
        </Grid>
      );
    } else if (field.type === 'measurement') {
      fieldInputs.push(
        <Grid key={field.name} item md={field.width} xs={12} style={{ paddingTop: 10, paddingBottom: 10 }}>
          <MeasurementField
            field={field}
            initialValue={values[field.name]}
            handleChange={handleChange}
            handleChangeWithParams={handleChangeWithParams}
            disabled={disabled}
          />
        </Grid>
      );
    } else if (field.type === 'conditional') {
      fieldInputs.push(
        <ConditionalField
          key={field.name}
          field={field}
          values={values}
          handleChange={handleChange}
          handleChangeWithParams={handleChangeWithParams}
          autoSetValues={autoSetValues}
          disabled={disabled}
        />
      );
    } else if (field.type === 'time') {
      fieldInputs.push(
        <Grid key={field.name} item md={field.width} xs={12} style={{ marginTop: 6 }}>
          <TimePicker
            field={field}
            value={values[field.name]}
            handleChange={handleChange}
            autoSetValues={autoSetValues}
            disabled={disabled}
          />
        </Grid>
      );
    } else if (field.type === 'providersTypeAhead') {
      fieldInputs.push(
        <Grid key={field.name} item md={field.width} xs={12} style={{ marginTop: 6 }}>
          <ProvidersTypeahead
            field={field}
            value={values[field.name]}
            handleChangeWithParams={handleChangeWithParams}
            autoSetValues={autoSetValues}
            disabled={disabled}
          />
        </Grid>
      );
    } else if (field.type === 'phone') {
      fieldInputs.push(
        <Grid key={field.name} item md={field.width} xs={12} style={{ marginTop: 6 }}>
          <PhoneInputField
            field={field}
            value={values[field.name]}
            handleChangeWithParams={handleChangeWithParams}
            autoSetValues={autoSetValues}
            disabled={disabled}
            type="editing"
            selectedLanguage={selectedLanguage}
          />
        </Grid>
      );
    } else if (field.type === 'phoneCode' || field.type === 'emailCode') {
      fieldInputs.push(
        <Grid key={field.name} item md={field.width} xs={12} style={{ marginTop: 6 }}>
          <VerificationCode
            field={field}
            value={values[field.name]}
            handleChangeWithParams={handleChangeWithParams}
            autoSetValues={autoSetValues}
            disabled={disabled}
            selectedLanguage={selectedLanguage}
          />
        </Grid>
      );
    } else if (field.type === 'assetsWrapper') {
      fieldInputs.push(
        <Grid key={field.name} item md={field.width} xs={12} style={{ paddingTop: 10, paddingBottom: 10 }}>
          <NetworkAssetsWrapper field={field} value={values} handleChange={handleChange} disabled={disabled} />
        </Grid>
      );
    } else if (field.type === 'customField') {
      fieldInputs.push(
        <Grid key={field.type} item md={field.width} xs={12} style={{ paddingTop: 10, paddingBottom: 10 }}>
          {field.value}
        </Grid>
      );
    } else if (field.type === 'buttonLabelField') {
      fieldInputs.push(
        <Grid key={field.type} item md={field.width} xs={12} style={{ paddingTop: 10, paddingBottom: 10 }}>
          <ButtonLabelField field={field} value={values[field.name]} handleChangeWithParams={handleChangeWithParams} />
        </Grid>
      );
    } else if (field.type === 'address') {
      fieldInputs.push(
        <Grid key={field.type} item md={field.width} xs={12} style={{ paddingTop: 10, paddingBottom: 10 }}>
          <AddressInput
            field={field}
            value={values[field.name]}
            handleChange={handleChange}
            handleChangeWithParams={handleChangeWithParams}
            selectedLanguage={selectedLanguage}
          />
        </Grid>
      );
    } else if (field.type === 'removeAutofill') {
      // hack to remove autofill, need at least one input as "on" with others as "off" or "new-password"
      fieldInputs.push(<Input autoComplete="on" style={{ display: 'none' }} key={`autofill_${i}`} />);
    } else if (field.type === 'userInputSearch') {
      fieldInputs.push(
        <UserSearchInput
          field={field}
          value={values[field.name]}
          handleChange={handleChange}
          handleChangeWithParams={handleChangeWithParams}
        />
      );
    } else if (field.type == 'clickAwayListner') {
      fieldInputs.push(
        <Grid key={field.name} item md={field.width} xs={12} style={{ paddingTop: 10, paddingBottom: 10 }}>
          <CustomClickAwayListner variables={values.variables} />
        </Grid>
      );
    } else if (field.type === 'sub-group') {
      fieldInputs.push(
        <Grid
          key={field.name}
          item
          md={field.width}
          xs={12}
          style={{
            paddingTop: 10,
            paddingBottom: 10,
          }}
        >
          {createInputHelper(
            field.fields,
            values,
            handleChange,
            handleChangeWithParams,
            autoSetValues,
            disabled,
            getChildren
          )}
        </Grid>
      );
    } else if (field.type === 'customComponent') {
      fieldInputs.push(field.value);
    }
  });
  return fieldInputs;
}
