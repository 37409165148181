// gets base64 image and provides a callback with a rotated base64 image
// https://gist.github.com/Zyndoras/6897abdf53adbedf02564808aaab94db
export function rotateImage(srcBase64, degrees, callback) {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  const image = new Image();

  image.onload = () => {
    canvas.width = degrees % 180 === 0 ? image.width : image.height;
    canvas.height = degrees % 180 === 0 ? image.height : image.width;

    ctx.translate(canvas.width / 2, canvas.height / 2);
    ctx.rotate((degrees * Math.PI) / 180);
    ctx.drawImage(image, image.width / -2, image.height / -2);

    callback(canvas.toDataURL());
  };

  image.src = srcBase64;
}

export function formatImgDimension(dimension) {
  if (dimension !== '---') {
    return `${dimension} pixels`;
  }

  return '---';
}
