import { createSlice } from '@reduxjs/toolkit';

import api from '../../../api';
import { showNotification } from '../../Notifications/notificationSlice';
import { handleError } from '../../Shared/Content/APIUtils';

const initialState = {
  immunizationList: null,
  immunizationListLoading: null,
};

export const immunizationListSlice = createSlice({
  name: 'immunizationList',
  initialState,
  reducers: {
    setImmunizationListLoading: (state, { payload }) => ({
      ...state,
      immunizationListLoading: payload,
    }),
    setImmunizationList: (state, { payload }) => ({
      ...state,
      immunizationList: payload,
      immunizationListLoading: false,
    }),
  },
});

export const { setImmunizationList, setImmunizationListLoading } = immunizationListSlice.actions;

export const getImmunizationList = (safeEMRId) => async (dispatch) => {
  dispatch(setImmunizationListLoading(true));

  const [result, error] = await api.getImmunizationListRequest(safeEMRId);

  if (result) {
    dispatch(setImmunizationList(result));
  }

  if (error && error.message) {
    dispatch(handleError(error));
  }
};

export const postImmunizationList = (safeEMRId, data) => async (dispatch) => {
  dispatch(setImmunizationListLoading(true));

  const [result, error] = await api.postImmunizationListRequest(safeEMRId, data);

  if (result) {
    dispatch(showNotification('Immunization Details Successfully Sent', 'success'));
    dispatch(getImmunizationList(safeEMRId));
  }

  if (error && error.message) {
    dispatch(handleError(error));
  }
};

export const pullImmunization = (tenant, safeEMRId) => async (dispatch) => {
  const [result, error] = await api.pullImmunizationRequest(tenant, safeEMRId);

  if (result) {
    dispatch(showNotification('Immunization Pull Successful', 'success'));
  }

  if (error && error.message) {
    dispatch(handleError(error));
  }
};

export const pushImmunization = (tenant, safeEMRId) => async (dispatch) => {
  const [result, error] = await api.pushImmunizationRequest(tenant, safeEMRId);

  if (result) {
    dispatch(showNotification('Push Immunization Successful', 'success'));
  }

  if (error && error.message) {
    dispatch(handleError(error));
  }
};

export default immunizationListSlice.reducer;
