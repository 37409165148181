import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import React, { Fragment, useEffect, useState } from 'react';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { selectCurrentTenant } from '../Shared/selectors';

import { selectTenantImports } from './selectors';
import { getBulkInfo } from './usersSlice';

const HighLinearProgress = withStyles({
  root: {
    height: 19,
    borderRadius: 19,
  },
})(LinearProgress);

function UserImportStatus(props) {
  const { id } = props;
  const classes = useStyles();
  const currentTenant = useSelector(selectCurrentTenant);
  const imports = useSelector(selectTenantImports);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [interval, setStateInterval] = useState(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    dispatch(getBulkInfo(currentTenant, id));

    const initialInterval = setInterval(() => {
      dispatch(getBulkInfo(currentTenant, id));
    }, 5000);

    setStateInterval(initialInterval);
    return () => clearInterval(initialInterval);
  }, [currentTenant, id]);

  useEffect(() => {
    const info = imports && imports.length > 0 && imports.find((i) => i.id === id);
    setData(info);

    if (info && (info.status === 'COMPLETED_SUCCESSFULLY' || info.status === 'COMPLETED_WITH_ERRORS')) {
      clearInterval(interval);
      setStateInterval(null);
    }
  }, [imports, id, interval]);

  if (!data) {
    // Error message
    return null;
  }

  const onHomeClick = () => {
    navigate(`/${currentTenant.id}`);
  };

  return (
    <Grid container justifyContent="center" alignItems="center" alignContent="center" spacing={3}>
      <Grid item xs={12}>
        <Paper style={{ padding: 24, borderRadius: 30 }} elevation={0}>
          {data && data.nbOfUsersToProcess > data.nbOfUsersProcessed && (
            <Grid xs={12} className={classes.uploadSuccessContainer}>
              <Grid container xs={12}>
                <Typography variant="h6">Upload Successful</Typography>
                <CheckCircleIcon color="primary" className={classes.iconSuccess} />
              </Grid>

              <Grid className={classes.uploadSuccess}>
                <HighLinearProgress
                  color="primary"
                  variant="determinate"
                  value={100}
                  classes={{
                    barColorPrimary: classes.barColorSuccess,
                  }}
                />
              </Grid>
            </Grid>
          )}

          <Grid container spacing={2} justifyContent="flex-start">
            <Grid item>
              <Grid item container>
                <Grid container xs={12}>
                  {data && data.nbOfUsersToProcess === data.nbOfUsersProcessed ? (
                    <>
                      <Typography variant="h6">Processing Completed</Typography>
                      <CheckCircleIcon color="primary" className={classes.iconSuccess} />
                    </>
                  ) : (
                    <Typography variant="h6">Processing New Accounts</Typography>
                  )}
                </Grid>

                <Grid item xs={12} className={classes.processingAccountsDescription}>
                  <Typography className={classes.usersCount} variant="caption">
                    {data && data.nbOfUsersToProcess > data.nbOfUsersProcessed
                      ? `You have uploaded ${data.nbOfUsersToProcess} users. We are processing the 
                      data and will let you know when its completed. You can return to this 
                      screen at any time by clicking the notification bell in the upper right.`
                      : 'Your CSV file was successfully uploaded and processed.'}
                  </Typography>
                </Grid>

                {data && data.nbOfUsersToProcess > data.nbOfUsersProcessed && (
                  <>
                    <Grid item xs={12} className={classes.processingAccountsProgress}>
                      <HighLinearProgress
                        color="primary"
                        variant="determinate"
                        value={(data.nbOfUsersProcessed * 100) / data.nbOfUsersToProcess}
                      />
                    </Grid>

                    <Grid
                      item
                      container
                      justifyContent="space-between"
                      alignContent="flex-end"
                      className={classes.processingAccountsCounters}
                    >
                      <Grid item>
                        <Typography variant="h6" className={classes.usersCount}>
                          <b>{`${data.nbOfUsersProcessed} users processed`}</b>
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="h6" className={classes.usersCount}>
                          <b>{`${data.nbOfUsersToProcess - data.nbOfUsersProcessed} users remaining`}</b>
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                )}

                {data && data.nbOfUsersToProcess === data.nbOfUsersProcessed && (
                  <Grid className={classes.processedUsers}>
                    <Grid item xs={12}>
                      <Typography variant="caption" className={classes.usersCount}>
                        <b>{`${data.nbOfUsersSuccessfullyCreated} users`}</b>
                        {' were added.'}
                      </Typography>
                    </Grid>
                    {data.nbOfUsersWithErrors > 0 && (
                      <Grid item xs={12}>
                        <Typography variant="caption" className={classes.usersCount}>
                          <b>{`${data.nbOfUsersWithErrors} users`}</b>
                          {' were unable to be added.'}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      <Grid item container justifyContent="flex-end">
        <Grid item>
          <Button variant="contained" color="primary" onClick={onHomeClick}>
            Back Home
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default UserImportStatus;

const useStyles = makeStyles({
  uploadSuccessContainer: {
    marginBottom: 40,
  },
  uploadSuccess: {
    marginTop: 12,
  },
  barColorSuccess: {
    backgroundColor: '#79DE76!important',
  },
  processingAccountsDescription: {
    marginTop: 12,
  },
  processingAccountsProgress: {
    marginTop: 17,
  },
  processingAccountsCounters: {
    marginTop: 17,
  },
  processedUsers: {
    marginTop: 15,
  },
  iconSuccess: {
    marginLeft: '15px',
    fontSize: '31px',
    color: '#5FCD74',
  },
  usersCount: {
    fontSize: 14,
  },
  loadingContinueUploadUsers: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
  },
  uploadDataInfoWrapper: {
    padding: '0 24px',
  },
  infoText: {
    fontSize: '16px',
  },
  viewListErrorsControl: {
    fontSize: '16px',
    textDecoration: 'underline',
    marginLeft: '30px',
    cursor: 'pointer',
  },
  downloadListErrorsControl: {
    fontSize: '16px',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  dialogContent: {
    padding: '0 24px',
    marginTop: '16px',
  },
  uploadDataNote: {
    padding: '20px 24px 0 24px',
  },
  divider: {
    backgroundColor: 'black',
    width: '1.5px',
    margin: '0 7px',
  },
});
