/* eslint-disable */
import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectEPrescribe } from './slices/selectors';

// mui
import Grid from '@material-ui/core/Grid';
import { selectUserDetails } from '../Users/selectors';
import { selectCurrentTenant } from '../Shared/selectors';
import { cleanup, fetchSSOUrl, syncWithDosespot } from './slices/dosespotSlice';
import { Button } from '@material-ui/core';
import { SyncAlt } from '@material-ui/icons';
import { showNotification } from '../Notifications/notificationSlice';

const Dosespot = () => {
  const userDetails = useSelector(selectUserDetails);
  const currentTenant = useSelector(selectCurrentTenant);
  const ssoUrl = useSelector((state) => state.dosespot?.ssoUrl);
  const dispatch = useDispatch();


  const checkMandatoryFields = (userDetails) => {
    const missed = [];
    const fields = {
      firstName: userDetails?.firstName || false,
      lastName: userDetails?.lastName || false,
      birthDate: userDetails?.birthDate || false,
      gender: userDetails?.gender || false,
      mobileNumber: userDetails?.contact?.mobileNumber || false,
      address1: userDetails?.primaryAddress?.line1 || false,
      city: userDetails?.primaryAddress?.city || false,
      state: userDetails?.primaryAddress?.state || false,
      zip: userDetails?.primaryAddress?.zip || false,
    };

    for (let i in fields) {
      if (!fields[i]) missed.push(i);
    }

    return missed;
  };

  useEffect(() => {
    if (!userDetails) return;

    if (userDetails?.externalIds?.doseSpotPatientId) {
      dispatch(fetchSSOUrl(userDetails?.nationalHealthId));
    } else {
      const mandatoryFields = checkMandatoryFields(userDetails)
      dispatch(showNotification(`Mandatory fields are missing: <br> - ${mandatoryFields.join('<br>- ')}`, 'error'));
    }
  }, [userDetails]);

  useEffect(() => {
    return () => {
        dispatch(cleanup());
        return dispatch(syncWithDosespot(userDetails?.nationalHealthId));
    }
  }, [])


  return (
    <Grid>
      <iframe src={ssoUrl} height="800" width="100%" title={'Dosespot'} />
    </Grid>
  );
};

export default Dosespot;
