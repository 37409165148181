import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { paths } from '../../constants';
import { selectTenantSettings } from '../Settings/selectors';
import { selectCurrentUserPermissions, selectUserAccountPermission } from '../Users/selectors';

// check allowed permissions against both tenant and account permissions for access
const checkPermissions = (currentUserPermissions, allowedPermissions, deniedPermissions, userAccountPermission) => {
  if (!userAccountPermission || !currentUserPermissions) return false;

  if ((allowedPermissions.length === 0 && deniedPermissions.length === 0) || !userAccountPermission) {
    return true;
  }

  if (deniedPermissions.length > 0) {
    return !deniedPermissions.includes(currentUserPermissions?.permission || userAccountPermission?.permission);
  }

  return allowedPermissions.includes(currentUserPermissions?.permission || userAccountPermission?.permission);
  // || userAccountPermission.permission === 'ACCOUNT_ADMINISTRATOR'
};

// check restricted pages against tenant settings and account admin permissions for "admin" access
const checkRestrictedPages = (currentPage, tenantSettings, userAccountPermission) => {
  if (!tenantSettings || !currentPage || !userAccountPermission) {
    return true;
  }
  const pages = Object.keys(tenantSettings.menuItems);
  const accountAdminAccess = currentPage.split('/')[0] === 'account';
  const accountAdmin = accountAdminAccess && userAccountPermission.permission === 'ACCOUNT_ADMINISTRATOR';
  return (!accountAdminAccess && !pages.includes(currentPage)) || tenantSettings.menuItems[currentPage] || accountAdmin;
};

const AccessControl = (props) => {
  const { allowedPermissions, deniedPermissions, currentPage, children, renderNoAccess } = props;

  const currentUserPermissions = useSelector(selectCurrentUserPermissions);
  const userAccountPermission = useSelector(selectUserAccountPermission);
  const tenantSettings = useSelector(selectTenantSettings);
  const { search } = useLocation();

  const permitted = checkPermissions(
    currentUserPermissions,
    allowedPermissions,
    deniedPermissions,
    userAccountPermission
  );

  const pageShow = checkRestrictedPages(currentPage, tenantSettings, userAccountPermission);

  if (permitted && pageShow) {
    return children;
  }

  if (new RegExp([paths.signIn(), paths.resetPassword()].join('|')).test(window.location.href)) {
    const redirectUrl = new URLSearchParams(search).get('redirectUrl');
    if (redirectUrl) sessionStorage.setItem('redirectUrl', atob(redirectUrl));

    window.location.href = paths.start();
    return;
  }
  return renderNoAccess();
};

AccessControl.defaultProps = {
  allowedPermissions: [],
  deniedPermissions: [],
  currentUserPermissions: [],
  renderNoAccess: () => null,
};

// Compose AccessControl component with redux
export default AccessControl;
