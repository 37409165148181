import Grid from '@material-ui/core/Grid';
import Popover from '@material-ui/core/Popover';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';

import DiscussionPopover from './DiscussionPopover';

function ScreenshotDescription(props) {
  const { annotationId, shape } = props;
  const { description } = shape;

  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Grid
        className={classes.rectangle}
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        style={{
          left: shape.x,
          top: shape.y,
          width: shape.width,
          height: shape.height,
        }}
      >
        <DiscussionPopover annotationId={annotationId} shapeData={shape} />
      </Grid>

      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography>
          <strong>Concern:</strong> {description?.concern ? description.concern.join(', ') : ''}
        </Typography>
        <Typography>
          <strong>Recommendation:</strong> {description?.recommendation || ''}
        </Typography>
        <Typography>
          <strong>Reviewer:</strong> {description?.reviewer?.name || ''}
        </Typography>
      </Popover>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  rectangle: {
    position: 'absolute',
    background: 'rgb(19,255,0,0.5)',
    border: '2px solid #ff0000',
    cursor: 'pointer',
  },
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
  },
}));

export default ScreenshotDescription;
