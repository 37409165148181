import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/Info';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import FactCheckIcon from '../../assets/img/checklist_icon.svg';
import EditIcon from '../../assets/img/edit.svg';
import { selectVaxRecords } from '../Shared/VaccinationRecord/selectors';
import {
  clearVaxRecordImage,
  getVaxRecordImage,
  getVaxRecordWithLatestImg,
} from '../Shared/VaccinationRecord/vaccinationRecordSlice';
import { VAX_TYPES } from './helpers/mappings';

// import CovidVaxStatusDialog from './CovidVaxStatusDialog';
import VaccineRecordDialog from './VaccineRecordDialog';

function VaccineRecordActionCell(props) {
  const { dataItem } = props;
  const vaxRecordType = dataItem.type.toUpperCase();

  const [anchorEl, setAnchorEl] = useState(null);
  const [vaxRecStatusDialogOpen, setVaxRecStatusDialogOpen] = useState(false);

  const vaxRecordsLists = useSelector(selectVaxRecords);
  const vaxDetails = vaxRecordsLists.items?.find((v) => dataItem.id === v.id);

  const dispatch = useDispatch();
  const classes = useStyle();

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenVaxRecDialogOpen = () => {
    dispatch(clearVaxRecordImage());
    vaxDetails.images?.forEach((image, idx) => {
      dispatch(getVaxRecordImage(dataItem.safeEMRId, image.id, idx));
    });

    // SAF-3864 and SAF-3865 - We need to refetch and update User's Vax data together with the
    // latest image data using the "getVaxRecordWithLatestImg"
    const vaxRecordId = vaxDetails.id;
    const userIdentityId = dataItem.userDetails.identityId;

    if (vaxRecordId) {
      dispatch(getVaxRecordWithLatestImg(vaxRecordId, userIdentityId));
    }

    // END - SAF-3864/SAF-3865

    setVaxRecStatusDialogOpen(true);
    handleCloseMenu();
  };

  const onCloseDialog = () => {
    setVaxRecStatusDialogOpen(false);
  };

  const getActions = () => {
    const menuItems = [];

    if (dataItem.status === 'AVAILABLE' && vaxRecordType === VAX_TYPES.ATTESTATION) {
      menuItems.push([
        <MenuItem key="viewDetails" onClick={handleOpenVaxRecDialogOpen} className={classes.actionItem}>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit" style={{ paddingLeft: 10, paddingRight: 10 }}>
            Edit
          </Typography>
        </MenuItem>,
      ]);
    } else if (dataItem.status === 'AVAILABLE' && vaxRecordType === VAX_TYPES.EXEMPTION) {
      menuItems.push([
        <MenuItem key="reviewExemptionRecord" onClick={handleOpenVaxRecDialogOpen} className={classes.actionItem}>
          <ListItemIcon>
            <InfoIcon fontSize="medium" />
          </ListItemIcon>
          <Typography variant="inherit" style={{ paddingLeft: 10, paddingRight: 10 }}>
            View
          </Typography>
        </MenuItem>,
      ]);
    } else if (
      dataItem.status === 'AVAILABLE' &&
      (vaxRecordType === VAX_TYPES.SAFEPASS || vaxRecordType === VAX_TYPES.ONEUPHEALTH)
    ) {
      menuItems.push([
        <MenuItem key="reviewSafePassRecord" onClick={handleOpenVaxRecDialogOpen} className={classes.actionItem}>
          <ListItemIcon>
            <InfoIcon fontSize="medium" />
          </ListItemIcon>
          <Typography variant="inherit" style={{ paddingLeft: 10, paddingRight: 10 }}>
            View
          </Typography>
        </MenuItem>,
      ]);
    } else if (dataItem.status === 'PENDING_APPROVAL') {
      menuItems.push([
        <MenuItem key="reviewPendingRecord" onClick={handleOpenVaxRecDialogOpen} className={classes.actionItem}>
          <ListItemIcon>
            <FactCheckIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit" style={{ paddingLeft: 10, paddingRight: 10 }}>
            Review Record
          </Typography>
        </MenuItem>,
      ]);
    }

    return menuItems;
  };

  const menu = getActions();

  if (menu && menu.length) {
    return (
      <td>
        <Grid container alignItems="center">
          <Grid item>
            <IconButton aria-controls="actions-menu" aria-haspopup="true" onClick={handleOpenMenu}>
              <MoreHorizIcon />
            </IconButton>
          </Grid>
          <Menu id="actions-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleCloseMenu}>
            {menu}
          </Menu>
        </Grid>
        {vaxRecStatusDialogOpen && (
          <VaccineRecordDialog
            userDetails={dataItem.userDetails}
            closeDialog={onCloseDialog}
            vaxRecordStatus={dataItem}
            dialogMode={dataItem.status === 'AVAILABLE' ? 'VIEW' : 'EDIT'}
            vaxRecordType={vaxRecordType}
            vaxRecordId={dataItem.id}
            safeEMRId={dataItem.safeEMRId}
            fromProfile
          />
        )}
      </td>
    );
  }
  return (
    <td>
      <p />
    </td>
  );
}

const useStyle = makeStyles({
  actionItem: {
    '& .MuiListItemIcon-root': {
      minWidth: '30px',
    },
  },
});

export default VaccineRecordActionCell;
