import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import XMLViewer from 'react-xml-viewer';
import ExpansionSkeletonLoading from '../Shared/ExpansionPanel/ExpansionSkeletonLoading';
import { selectXMLView, selectXMLViewLoading } from './slices/selectors';
import { getXMLViewer } from './slices/xmlViewerSlice';

// const { displayInOrder: resourcesListToDisplayInOrder } = require('./resourcesConfig');

function XMLData(props) {
  const { userDetails, currentTenant } = props;
  const xmlView = useSelector(selectXMLView);
  const xmlViewLoading = useSelector(selectXMLViewLoading);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getXMLViewer(userDetails.nationalHealthId, currentTenant.id));
  }, [currentTenant, userDetails]);

  const downloadTxtFile = useCallback(() => {
    const element = document.createElement('a');
    const file = new Blob([xmlView], { type: 'text/plain;charset=utf-8' });
    element.href = URL.createObjectURL(file);
    element.download = `${userDetails.nationalHealthId}-ccd.xml`;
    document.body.appendChild(element);
    element.click();
  }, [xmlView]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {typeof xmlViewLoading !== 'undefined' && !xmlViewLoading && xmlView !== 'undefined' && xmlView != null ? (
          <Accordion style={{ width: '100%', backgroundColor: '#fafafa' }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <Typography variant="h6" style={{ marginLeft: 15, marginBottom: '10px' }}>
                XML Viewer
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    width: '100%',
                  }}
                >
                  <Button
                    class="MuiButtonBase-root MuiButton-root MuiButton-outlined 
                      MuiButton-outlinedPrimary MuiButton-disableElevation"
                    variant="contained"
                    color="primary"
                    onClick={downloadTxtFile}
                  >
                    Download
                  </Button>
                </div>

                <Grid item xs={12}>
                  <XMLViewer xml={xmlView} />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        ) : (
          <ExpansionSkeletonLoading />
        )}
      </Grid>
    </Grid>
  );
}

export default XMLData;
