// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import { withStyles } from '@material-ui/core/styles';
import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded';
import LanguageIcon from '@material-ui/icons/Language';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import NavBarStyles from '../../assets/jss/components/NavBarStyles';
import { selectCurrentTenant } from '../Shared/selectors';

import { setLanguage, getActualLanguageVersion } from './languageSlice';
import { selectLanguage, selectAllowChange } from './selectors';

// As this is expanded, it should be moved into a database
const supportedLanguages = [
  {
    name: 'English',
    value: 'en',
  },
  {
    name: 'Spanish',
    value: 'es',
  },
];

function LanguageMenu(props) {
  const { classes, mobile } = props;

  const language = useSelector(selectLanguage);
  const allowChange = useSelector(selectAllowChange);

  const currentTenant = useSelector(selectCurrentTenant);
  const [currentLanguage, setCurrentLanguage] = useState('English');

  const dispatch = useDispatch();

  const changeLanguage = (evt, popupState) => {
    const value = evt.currentTarget.dataset.language;
    const name = evt.currentTarget.dataset.languageName;
    dispatch(setLanguage(value));
    setCurrentLanguage(name);
    popupState.close();
  };

  // Set defaults on load
  useEffect(() => {
    if (!language) {
      dispatch(setLanguage('en'));
    } else if (language && !currentLanguage) {
      const languageFromState = supportedLanguages.find((lang) => lang.name === language);
      setCurrentLanguage(languageFromState);
    }
  }, [language, currentLanguage]);

  // get actual language
  useEffect(() => {
    if (language) {
      dispatch(getActualLanguageVersion(currentTenant.id, language));
    }
  }, [language]);

  return (
    <PopupState variant="popover" popupId="language-menu">
      {(popupState) => (
        <div style={{ margin: 'auto' }}>
          <Button
            disableElevation
            disabled={!allowChange}
            variant="contained"
            color="primary"
            {...bindTrigger(popupState)}
            className={mobile ? classes.mobileBtn : classes.languageSelector}
            startIcon={mobile && <LanguageIcon />}
          >
            {`Form Language${!mobile ? `: ${currentLanguage}` : ''}`}
            {!mobile && <ArrowDropDownRoundedIcon className={classes.arrowIcon} fontSize="small" />}
          </Button>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <Box p={2} onMouseLeave={() => popupState.close()}>
              {supportedLanguages.map((lang) => (
                <MenuItem
                  key={lang.value}
                  data-language={lang.value}
                  data-language-name={lang.name}
                  onClick={(evt) => {
                    changeLanguage(evt, popupState);
                  }}
                  selected={lang.name === currentLanguage}
                >
                  {lang.name}
                </MenuItem>
              ))}
            </Box>
          </Popover>
        </div>
      )}
    </PopupState>
  );
}

export default withStyles(NavBarStyles)(LanguageMenu);
