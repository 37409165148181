import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import AddBoxIcon from '@material-ui/icons/AddBox';
import CloseIcon from '@material-ui/icons/Close';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectAnnotationList, selectConcernList, selectReviewerList } from '../selectors';

import CheckboxGroup from './helpers/CheckboxGroup';

function AnnotationPopup(props) {
  const { selectedShapeId, setSelectedShapeId, shapes, setShapes, setExpandOptionForm } = props;
  const classes = useStyles();

  const annotationList = useSelector(selectAnnotationList);
  const concernList = useSelector(selectConcernList);
  const reviewerList = useSelector(selectReviewerList);

  const [selectedConcern, setSelectedConcern] = useState([]);
  const [reviewRecommendation, setReviewRecommendation] = useState('');
  const [selectedReviewer, setSelectedReviewer] = useState(null);
  const [popupPosition, setPopupPosition] = useState({});

  useEffect(() => {
    if (selectedShapeId) {
      // get last reviewer from last annotation
      const lastReviewer = getLastReviewer();

      // set current shape concern
      const currentShapeData = shapes.filter((item) => item.id === selectedShapeId)[0];
      setSelectedConcern(currentShapeData?.description?.concern || []);

      // set current shape recommendation
      setReviewRecommendation(currentShapeData?.description?.recommendation || '');

      // set current shape reviewer
      const lastShapeReviewer = shapes[shapes.length - 2] || null;
      const currentReviewer = currentShapeData?.description?.reviewer
        ? currentShapeData.description.reviewer
        : lastShapeReviewer?.description?.reviewer || lastReviewer;
      setSelectedReviewer(currentReviewer || null);

      // set current shape position on page
      setPopupPosition({
        x: currentShapeData?.onPageX,
        y: currentShapeData?.onPageY,
      });
    }
    // eslint-disable-next-line
  }, [shapes, selectedShapeId]);

  const getLastReviewer = () => {
    const lastAnnotationShapes = annotationList[annotationList.length - 1]?.shapes;
    const lastShapeKey = lastAnnotationShapes && lastAnnotationShapes.length ? lastAnnotationShapes.length - 1 : null;

    const lastReviewer =
      lastAnnotationShapes && lastShapeKey >= 0 ? lastAnnotationShapes[lastShapeKey]?.description?.reviewer : null;

    return lastReviewer;
  };

  const updateShapeInfo = (optionName, optionValue) => {
    const updatedShapes = shapes.map((item) => {
      const updatedItem = { ...item };
      if (updatedItem.id === selectedShapeId) {
        updatedItem.description = {
          ...updatedItem.description,
          [optionName]: optionValue,
        };
      }

      return updatedItem;
    });

    setShapes(updatedShapes);
    // resetForm();
  };

  // const resetForm = () => {
  //   setSelectedConcern([]);
  //   setReviewRecommendation('');
  //   setSelectedReviewer(null);
  //   setSelectedShapeId(null);
  // };

  const handleSelectConcern = (data) => {
    setSelectedConcern(data);
    updateShapeInfo('concern', data);
  };

  const handleChangeRecommendation = (e) => {
    const { value } = e.target;
    setReviewRecommendation(value);
    updateShapeInfo('recommendation', value);
  };

  const handleSelectReviewer = (e, value) => {
    setSelectedReviewer(value);
    updateShapeInfo(
      'reviewer',
      value?._id
        ? {
            _id: value._id,
            name: value.name,
          }
        : {}
    );
  };

  return (
    <Grid className={classes.popupWrapper} style={{ top: popupPosition?.y, left: popupPosition?.x }}>
      <IconButton color="primary" className={classes.closeIcon} onClick={() => setSelectedShapeId(null)}>
        <CloseIcon />
      </IconButton>

      <Grid container direction="row">
        <Grid item className={classes.column}>
          <Typography variant="h6" color="primary">
            Concern
          </Typography>
          <CheckboxGroup
            concernList={concernList}
            selectedConcern={selectedConcern}
            handleSelectConcern={handleSelectConcern}
          />
          <IconButton color="primary" onClick={() => setExpandOptionForm('concern')} style={{ marginLeft: -20 }}>
            <AddBoxIcon />
          </IconButton>
        </Grid>

        <Grid
          item
          className={classes.column}
          style={{
            borderRight: '1px solid #cccccc',
            borderLeft: '1px solid #cccccc',
          }}
        >
          <Typography variant="h6" color="primary">
            Recommendation
          </Typography>
          <TextField
            id="outlined-multiline-static"
            multiline
            rows={4}
            variant="outlined"
            placeholder="Description"
            value={reviewRecommendation}
            onChange={(e) => handleChangeRecommendation(e)}
          />
        </Grid>

        <Grid item className={classes.column}>
          <Typography variant="h6" color="primary">
            Reviewer
          </Typography>
          <Autocomplete
            value={selectedReviewer}
            id="clear-on-escape"
            options={reviewerList}
            getOptionLabel={(option) => option.name}
            clearOnEscape
            onChange={handleSelectReviewer}
            renderInput={(params) => <TextField {...params} label="Reviewer" variant="outlined" />}
          />
          <IconButton color="primary" onClick={() => setExpandOptionForm('reviewer')} style={{ marginLeft: -15 }}>
            <AddBoxIcon />
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles(() => ({
  popupWrapper: {
    border: '1px solid #cccccc',
    padding: 10,
    borderRadius: 5,
    // height: 300,
    position: 'absolute',
    zIndex: 1,
    backgroundColor: 'white',
    display: 'flex',
    flexWrap: 'wrap',
    width: 600,
  },
  closeIcon: {
    position: 'absolute',
    top: -2,
    right: -2,
  },
  column: {
    flexGrow: 1,
    width: '33%',
    padding: '0 15px 15px 15px',
  },
}));

export default AnnotationPopup;
