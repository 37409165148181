// styling imports

// material-ui
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import PersonRoundedIcon from '@material-ui/icons/PersonRounded';
import PowerSettingsNewRoundedIcon from '@material-ui/icons/PowerSettingsNewRounded';
import React from 'react';
import { Link } from 'react-router-dom';

import contactSupport from '../../assets/img/ContactSupport.png';
import NavBarStyles from '../../assets/jss/components/NavBarStyles';
import AccessControl from '../Shared/AccessControl';

function DropdownBox(props) {
  const { classes, logout, user, currentTenant, userAccountPermission, onMenuClick } = props;
  const accountAdminAccess = userAccountPermission && userAccountPermission.permission === 'ACCOUNT_ADMINISTRATOR';

  return (
    <Box p={2}>
      <AccessControl allowedPermissions={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']} renderNoAccess={() => <div />}>
        <Link to={`/${currentTenant && currentTenant.id}/profile/${user && user.email}`} className={classes.innerLink}>
          <MenuItem className={classes.menuItem} onClick={onMenuClick}>
            <PersonRoundedIcon className={classes.icon} fontSize="small" />
            My Profile
          </MenuItem>
        </Link>
        <Divider />
      </AccessControl>
      <AccessControl allowedPermissions={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']} renderNoAccess={() => <div />}>
        <Link to={`/${currentTenant && currentTenant.id}/contact/${user && user.email}`} className={classes.innerLink}>
          <MenuItem className={classes.menuItem} onClick={onMenuClick}>
            <img src={contactSupport} style={{ height: '20px', paddingRight: '4px' }} alt="Support" />
            Contact Support
          </MenuItem>
        </Link>
        <Divider />
      </AccessControl>
      {accountAdminAccess && (
        <div>
          <Link to={`/${currentTenant && currentTenant.id}/account`} className={classes.innerLink}>
            <MenuItem className={classes.menuItem} onClick={onMenuClick}>
              <LockOpenIcon className={classes.icon} fontSize="small" />
              Account Settings
            </MenuItem>
          </Link>
          <Divider />
        </div>
      )}
      <MenuItem onClick={logout} className={classes.menuItem}>
        <PowerSettingsNewRoundedIcon className={classes.icon} fontSize="small" />
        Logout
      </MenuItem>
    </Box>
  );
}

export default withStyles(NavBarStyles)(DropdownBox);
