import React from 'react';

import { vaccinationRecordMappings } from './helpers/mappings';

function VaccineRecordCustomCell(props) {
  const { dataItem } = props;

  return (
    <td>
      <span
        style={{
          fontWeight: 600,
          color: vaccinationRecordMappings[dataItem.status].color,
        }}
      >
        {vaccinationRecordMappings[dataItem.status].label.toUpperCase()}
      </span>
    </td>
  );
}

export default VaccineRecordCustomCell;
