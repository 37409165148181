/* eslint-disable jsx-a11y/label-has-associated-control */
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { NumericTextBox } from '@progress/kendo-react-inputs';
import { Error } from '@progress/kendo-react-labels';
import React, { useState, useEffect } from 'react';

// kendo

import styles from '../../assets/Questionnaire.module.css';
import utils from '../utils/utils';

// ui reflection of file view
function NumberFieldInput(props) {
  const { field, value: initialValue, handleChangeWithParams, disabled, selectedLanguage } = props;
  const [currentValue, setCurrentValue] = useState(initialValue);
  const fieldDisabled = disabled || field.readOnly || field.disableIfFalse;

  useEffect(() => {
    if (initialValue && initialValue !== currentValue) {
      setCurrentValue(initialValue);
    }
    // eslint-disable-next-line
  }, [initialValue]);

  const onChange = (e) => {
    setCurrentValue(e.value);
    if (handleChangeWithParams) handleChangeWithParams(field.name, e.value);
  };

  const fieldTitle =
    selectedLanguage && field.name && field.name.translations
      ? field.name.translations[selectedLanguage]
      : field.label || field.name;
  const questionDescription =
    selectedLanguage && field.description && field.description.translations
      ? field.description.translations[selectedLanguage]
      : field.description;
  const getTextBoxLabel = (field) => {
    if (field.shortName) return field.shortName;
    if (selectedLanguage && field.name && field.name.translations) {
      return field.name.translations[selectedLanguage];
    }
    return field.label || field.name;
  };

  return (
    <Grid>
      <Grid item xs={12} style={{ width: '100%' }}>
        {fieldTitle && (
          <Typography className={field.nameStyle ? styles[`title--${field.nameStyle}`] : styles['title--style1']}>
            {field.questionPreview ? utils.renderHTMLTags(fieldTitle) : fieldTitle}
            {field.required && '*'}
          </Typography>
        )}
        {field.image && (
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <img
              src={field.image}
              alt={fieldTitle}
              style={{
                display: 'block',
                marginLeft: 'auto',
                marginRight: 'auto',
                width: '90%',
              }}
            />
          </Grid>
        )}
        {questionDescription && (
          <Typography
            className={
              field.descriptionStyle ? styles[`description--${field.descriptionStyle}`] : styles['description--style1']
            }
          >
            {utils.renderHTMLTags(questionDescription)}
          </Typography>
        )}
      </Grid>

      <label>
        <NumericTextBox
          width="100%"
          value={currentValue}
          onChange={onChange}
          required={field.required}
          label={getTextBoxLabel(field)}
          name={
            selectedLanguage && field.name && field.name.translations
              ? field.name.translations[selectedLanguage]
              : field.name
          }
          min={typeof field.min === 'number' ? field.min : undefined}
          max={typeof field.max === 'number' ? field.max : undefined}
          step={parseFloat(field.step) || 1}
          style={{ width: '100%' }}
          disabled={fieldDisabled}
        />
      </label>
      {field.helper && (
        <Typography variant="caption" color="textSecondary">
          {field.helper}
        </Typography>
      )}
      {typeof field.min === 'number' && (
        <div>
          {currentValue < field.min && (
            <Error id="minNumberError">
              Number must be equal or higher than
              {field.min}
            </Error>
          )}
        </div>
      )}
      {typeof field.max === 'number' && (
        <div>
          {currentValue > field.max && (
            <Error id="maxNumberError">
              Number must be equal or lower than
              {field.max}
            </Error>
          )}
        </div>
      )}
      {field.disabledItems &&
        !!field.disabledItems.length &&
        field.disabledItems.some((item) => item === currentValue) && (
          <Error id="maxNumberError">
            This priority is already used (The right questionnaire outcome is not guaranteed)
          </Error>
        )}
    </Grid>
  );
}

export default NumberFieldInput;
