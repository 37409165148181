import { createSlice } from '@reduxjs/toolkit';

import api from '../../../api';
import { handleError } from '../../Shared/Content/APIUtils';

const initialState = {
  vitalList: null,
  vitalListLoading: null,
};

export const vitalListSlice = createSlice({
  name: 'vitalList',
  initialState,
  reducers: {
    setVitalListLoading: (state, { payload }) => ({
      ...state,
      vitalListLoading: payload,
    }),
    setVitalList: (state, { payload }) => ({
      ...state,
      vitalList: payload,
      vitalListLoading: false,
    }),
  },
});

export const { setVitalList, setVitalListLoading } = vitalListSlice.actions;

export const getVitalList = (safeEMRId) => async (dispatch) => {
  dispatch(setVitalListLoading(true));

  const [result, error] = await api.getVitalListRequest(safeEMRId);

  if (result) {
    dispatch(setVitalList(result));
  }

  if (error && error.message) {
    dispatch(handleError(error));
  }
};

export default vitalListSlice.reducer;
