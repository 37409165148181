import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import React from 'react';

import { createInputHelper } from '../../../../../Shared/Forms/helpers/createInputHelper';
import { getFieldName, medicalCodes } from '../../../../QuestionBuilder/helpers/formHelper';

function MedicalCode(props) {
  const { coding, removeMedicalCode, editCoding } = props;

  const medicalCodeData = () => {
    const data = {};
    const key = getFieldName(coding.system);
    data.system = coding.system;
    data.code = coding.code;
    if (!key) {
      data.codeDescription = null;
    } else {
      data.codeDescription = { [key]: coding.description };
    }
    return data;
  };

  return (
    <Paper style={{ padding: 10, margin: 5 }}>
      <Grid container>
        <Grid item xs={11}>
          <Typography variant="h6">Medical Code</Typography>
        </Grid>
        <Grid item xs={1}>
          <Tooltip title="Remove medical code">
            <IconButton onClick={removeMedicalCode}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Grid>
        {createInputHelper(
          medicalCodes(coding),
          medicalCodeData(),
          () => ({}),
          (type, value) => editCoding()(type, value)
        )}
      </Grid>
    </Paper>
  );
}

export default MedicalCode;
