import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';

// Kendo
import { Checkbox } from '@progress/kendo-react-inputs';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectFormData } from '../selectors';

// ui reflection of file view
function ToggleInput(props) {
  const { field, value: initialValue, handleChangeWithParams, disabled } = props;
  const formData = useSelector(selectFormData);

  const [fieldState, setFieldState] = useState(
    initialValue !== true && initialValue !== false ? field.default || false : initialValue
  );
  const [userChanged, setUserChanged] = useState(false);
  const fieldDisabled =
    disabled ||
    field.readOnly ||
    (field.disableIfFalse && !formData[field.disableIfFalse]) ||
    (field.disableIfTrue && !!formData[field.disableIfTrue]);

  // Initial load
  useEffect(() => {
    if (!userChanged && fieldState !== initialValue) {
      const valueToSet = initialValue || field.default || false;
      setFieldState(valueToSet);
      if (!initialValue && initialValue !== false) {
        handleChangeWithParams(field.name, valueToSet);
      }
    }
    // eslint-disable-next-line
  }, [initialValue, field.name, field.default, handleChangeWithParams]);

  // Check if any linked toggles have changed and update to match
  useEffect(() => {
    if (field.linkField && fieldState !== formData[field.linkField]) {
      handleChangeWithParams(field.name, formData[field.linkField]);
      setFieldState(formData[field.linkField]);
    }
    // eslint-disable-next-line
  }, [field]);

  useEffect(() => {
    // treat redux store as SoT
    if (formData[field.name] !== undefined) {
      setFieldState(formData[field.name]);
    }
  }, [formData]);

  const onChange = (e) => {
    const newValue = field.displayCheck ? e.value : e.target.checked;
    if (field.linkField) {
      handleChangeWithParams(field.linkField, newValue, field.writeToParent);
    } else {
      if (field.childFields) {
        field.childFields.forEach((child) => {
          if (child.includes('Required') && !field.name.includes('Required')) {
            // Only change child required fields if Parent is updated to false
            if (!newValue) handleChangeWithParams(child, false);
          } else if (
            child.includes('Required') &&
            field.name.includes('Required') &&
            formData[child.replace('Required', '')] === false
          ) {
            // Don't change a child required value to true if the child itself is false
            handleChangeWithParams(child, false);
          } else handleChangeWithParams(child, newValue);
        });
      }
      setUserChanged(true);
      handleChangeWithParams(field.name, newValue);
      setFieldState(newValue);
    }

    if (!newValue && field.requiredField) {
      handleChangeWithParams(field.requiredField.name, false);
    }
  };

  return field.displayCheck ? (
    <Checkbox disabled={fieldDisabled} label={field.label} value={fieldState} onChange={onChange} />
  ) : (
    <FormControl component="fieldset" required={field.required}>
      <FormGroup aria-label="position" row>
        <FormControlLabel
          value="top"
          control={
            <Switch
              checked={fieldState}
              onChange={onChange}
              color="primary"
              inputProps={{ 'aria-label': 'primary checkbox' }}
              disabled={fieldDisabled}
            />
          }
          label={<Typography variant="body2">{field.label}</Typography>}
          labelPlacement={field.labelPlacement ? field.labelPlacement : 'start'}
        />
      </FormGroup>
    </FormControl>
  );
}

export default ToggleInput;
