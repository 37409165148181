import { createSlice } from '@reduxjs/toolkit';

import api from '../../api';
import { showNotification } from '../Notifications/notificationSlice';
import { handleError } from '../Shared/Content/APIUtils';
import { setFormDeleting, setFormSubmitting } from '../Shared/Forms/formSlice';
import history from '../Shared/Redux/history';
import utils from '../Shared/Utils/utils';

const initialState = {};

export const contentSlice = createSlice({
  name: 'content',
  initialState,
  reducers: {
    setTenantContentLoading: (state, { payload }) => ({
      [payload]: {
        ...state[payload],
        loading: true,
        deleteContentLoading: false,
      },
    }),

    setTenantContent: (state, { payload }) => ({
      [payload.contentType]: {
        ...state[payload.contentType],
        list: {
          ...payload.payload,
          page: payload.page,
          pageSize: payload.pageSize,
        },
        loading: false,
      },
    }),

    setTenantContentFailure: (state, { payload }) => ({
      [payload]: {
        ...state[payload],
        loading: false,
      },
    }),

    setWidgetTypesLoading: (state, { payload }) => ({
      ...state,
      [payload]: {
        ...state[payload],
        loadingContent: true,
        types: undefined,
      },
    }),

    setWidgetTypes: (state, { payload }) => ({
      ...state,
      [payload.contentType]: {
        ...state[payload.contentType],
        loadingContent: false,
        types: payload.payload.data,
      },
    }),

    setWidgetKeysLoading: (state, { payload }) => ({
      ...state,
      [payload]: {
        ...state[payload],
        loadingContent: true,
        keys: undefined,
      },
    }),

    setWidgetKeys: (state, { payload }) => ({
      ...state,
      [payload.contentType]: {
        ...state[payload.contentType],
        loadingContent: false,
        keys: payload.payload.data,
      },
    }),

    setContentLoading: (state, { payload }) => ({
      ...state,
      [payload]: {
        ...state[payload],
        selected: undefined,
        loadingContent: true,
      },
    }),

    setContent: (state, { payload }) => ({
      ...state,
      [payload.contentType]: {
        ...state[payload.contentType],
        selected: payload.payload,
        loadingContent: false,
      },
    }),

    setDeleteContentLoading: (state, { payload }) => ({
      ...state,
      [payload]: {
        ...state[payload],
        deleteContentLoading: false,
      },
    }),

    setDeleteContent: (state, { payload }) => ({
      ...state,
      [payload.contentType]: {
        ...state[payload.contentType],
        selected: payload.payload,
        loadingContent: false,
        deleteContentLoading: payload.deleteContentLoading,
      },
    }),

    setToggleEnabled: (state, { payload }) => ({
      [payload.contentType]: {
        ...state[payload.contentType],
        list: {
          ...state[payload.contentType].list,
          data: state[payload.contentType].list.data.map((i) =>
            i._id === payload.id
              ? {
                  ...i,
                  publish: payload.enabled,
                }
              : i
          ),
        },
        loading: false,
      },
    }),

    setVideosLoading: (state) => ({
      ...state,
      videos: {
        loading: true,
      },
    }),

    setVideosSubmitLoading: (state) => ({
      ...state,
      videosSubmitting: true,
    }),

    setVideosSubmitted: (state) => ({
      ...state,
      videosSubmitting: false,
    }),
  },
});

export const {
  setTenantContentLoading,
  setTenantContent,
  setTenantContentFailure,
  setWidgetTypes,
  setWidgetTypesLoading,
  setWidgetKeysLoading,
  setWidgetKeys,
  setContent,
  setContentLoading,
  setDeleteContent,
  setDeleteContentLoading,
  setToggleEnabled,
  setVideosLoading,
  setVideosSubmitLoading,
  setVideosSubmitted,
} = contentSlice.actions;

export const getContentList =
  (type, tenant, page, pageSize, query, sort, direction, filter) => async (dispatch, getState) => {
    dispatch(setTenantContentLoading(type));

    const { language } = getState().language;

    const params = {
      tenantID: tenant,
      skip: page * pageSize,
      limit: pageSize,
      q: query,
      sort,
      direction,
      permission: filter,
      language,
    };
    const [result, error] = await api.getContentListRequest(type, params);

    if (error && error.message) {
      dispatch(handleError(error));
    }

    if (result) {
      if (result.isOK) {
        dispatch(
          setTenantContent({
            payload: result,
            contentType: type,
            page,
            pageSize,
          })
        );
      } else {
        setTenantContentFailure(type);
        dispatch(handleError(result));
      }
    }
  };

export const getMessageStandardizationList = (data, type) => async (dispatch) => {
  dispatch(setTenantContentLoading(type));

  // const { language } = getState().language;
  const [result, error] = await api.getMessageStandardizationRequest(data);

  if (error && error.message) {
    dispatch(handleError(error));
  }

  if (result) {
    if (result.success) {
      dispatch(
        setTenantContent({
          payload: result.data,
          contentType: type,
          page: data.pageNumber,
          pageSize: data.pageSize,
          total: result.foundItemsCount,
        })
      );
    } else {
      setTenantContentFailure(type);
      dispatch(handleError(result));
    }
  }
};

export const getMessageContent = (type, id) => async (dispatch) => {
  dispatch(setContentLoading(type));

  const [result, error] = await api.getMessageContentRequest(type, id);

  if (error && error.message) {
    dispatch(handleError(error));
  }

  if (result) {
    dispatch(setContent({ payload: result, contentType: type }));
  }
};

export const getContent = (type, tenant, id) => async (dispatch) => {
  dispatch(setContentLoading(type));

  const params = {
    tenantID: tenant,
  };
  const [result, error] = await api.getContentRequest(type, id, params);

  if (error && error.message) {
    dispatch(handleError(error));
  }

  if (result) {
    dispatch(setContent({ payload: result, contentType: type }));
  }
};

export const getWidgetTypes = (type, id) => async (dispatch) => {
  dispatch(setWidgetTypesLoading(type));

  const [result, error] = await api.getWidgetTypesRequest();

  if (error && error.message) {
    dispatch(handleError(error));
  }

  if (result) {
    dispatch(setWidgetTypes({ payload: result, contentType: type, id }));
  }
};

export const getWidgetKeys = (type, id) => async (dispatch) => {
  dispatch(setWidgetKeysLoading(type));

  const [result, error] = await api.getWidgetKeysRequest();

  if (error && error.message) {
    dispatch(handleError(error));
  }

  if (result) {
    dispatch(setWidgetKeys({ contentType: type, payload: result, id }));
  }
};

export const addContent = (type, tenant, data) => async (dispatch) => {
  const payload = { ...data, tenantID: tenant };
  const [result, error] = await api.addContentRequest(payload, type);

  if (error && error.message) {
    dispatch(setFormSubmitting(false));
    dispatch(handleError(error));
  }

  if (result) {
    dispatch(showNotification('Successfully Added', 'success'));
    return Promise.resolve(result);
  }
};

export const addMessageContent = (type, tenant, data) => async (dispatch) => {
  const payload = { ...data, tenantID: tenant };
  const [result, error] = await api.addMessageContentRequest(payload, type);

  if (error && error.message) {
    dispatch(setFormSubmitting(false));
    dispatch(handleError(error));
  }

  if (result) {
    dispatch(showNotification('Successfully Added', 'success'));

    history.push(`/${tenant}/settings/${utils.camelToSnake(type).substring(0, utils.camelToSnake(type).length - 1)}`);

    return Promise.resolve(result);
  }
};

export const updateContent = (type, tenant, data) => async (dispatch) => {
  dispatch(setContentLoading(type));
  const payload = { ...data, tenantID: tenant };
  const [result, error] = await api.updateContentRequest(payload, type);
  if (error && error.message) {
    dispatch(toggleEnabled(type, data.Data._id, !data.Data.publish));
    dispatch(setFormSubmitting(false));
    dispatch(handleError(error));
  }

  if (result) {
    dispatch(toggleEnabled(type, result.data, data.Data.publish));
    dispatch(showNotification('Successfully Updated', 'success'));
  }
};

export const updateMessageContent = (type, tenant, data) => async (dispatch) => {
  dispatch(setContentLoading(type));
  const payload = { ...data, tenantID: tenant };
  const [result, error] = await api.updateMessageContentRequest(payload, type);

  if (error && error.message) {
    dispatch(setFormSubmitting(false));
    dispatch(handleError(error));
  }

  if (result) {
    dispatch(setContent({ payload: result, contentType: type }));
    dispatch(showNotification('Successfully Updated', 'success'));
  }
};

export const deleteContent = (type, tenantID, id) => async (dispatch) => {
  dispatch(setDeleteContentLoading(type));
  dispatch(setFormDeleting(true));
  const [result, error] = await api.deleteContentRequest(type, id);

  if (error && error.message) {
    dispatch(setFormDeleting(false));
    dispatch(handleError(error));
    dispatch(setDeleteContent({ contentType: type, deleteContentLoading: false }));
  }

  if (result) {
    dispatch(showNotification('Successfully Deleted', 'success'));
    dispatch(setDeleteContent({ contentType: type, deleteContentLoading: true }));
  }
};
export const deleteMessageContent = (type, tenantID, id) => async (dispatch) => {
  dispatch(setDeleteContentLoading(type));
  dispatch(setFormDeleting(true));
  const [result, error] = await api.deleteMessageRequest(type.toLowerCase(), id);

  if (error && error.message) {
    dispatch(setFormDeleting(false));
    dispatch(handleError(error));
    dispatch(setDeleteContent({ contentType: type, deleteContentLoading: false }));
  }

  if (result) {
    const windowLocation = window.location.pathname;
    history.push(windowLocation.includes('/details') ? getReturnPathname(2) : windowLocation);

    dispatch(showNotification('Successfully Deleted', 'success'));
    dispatch(setDeleteContent({ contentType: type, deleteContentLoading: true }));
  }
};

function getReturnPathname(steps) {
  const currentPathname = window.location.pathname.split('/');
  if (steps === 1) {
    currentPathname.splice(-1, 1);
  } else {
    currentPathname.splice(-2, 2);
  }

  return currentPathname.join('/');
}

export const toggleEnabled = (contentType, id, enabled) => async (dispatch) => {
  dispatch(setToggleEnabled({ contentType, id, enabled }));
};

export const getVideos = (tenant) => async (dispatch, getState) => {
  dispatch(setVideosLoading());

  const { language } = getState().language;
  const params = {
    tenantID: tenant,
    language,
  };
  const [result, error] = await api.getVideosRequest(params);

  if (error && error.message) {
    dispatch(handleError(error));
  }

  if (result) {
    dispatch(
      setTenantContent({
        payload: result,
        contentType: 'videos',
        page: '',
        pageSize: '',
      })
    );
  }
};
export const updateVideos = (tenant, video, type) => async (dispatch) => {
  dispatch(setVideosLoading());
  dispatch(setVideosSubmitLoading());

  let result;
  let error;

  if (type === 'videoDelete') {
    [result, error] = await api.deleteVideoRequest(video);
  } else {
    const payload = {
      tenantID: tenant,
      data: video,
    };
    [result, error] = await api.updateVideoRequest(payload);
  }

  if (error && error.message) {
    dispatch(setVideosSubmitted());
    dispatch(handleError(error));
  }

  if (result) {
    dispatch(getVideos(tenant));
    dispatch(setVideosSubmitted());
    if (type && type === 'videoDelete') {
      dispatch(showNotification('Successfully deleted video', 'success'));
    } else {
      dispatch(showNotification('Successfully updated videos', 'success'));
    }
  }
};

export default contentSlice.reducer;
