/* eslint-disable react/no-array-index-key */
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles, useTheme } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ProfileViewStyles from '../../assets/jss/components/ProfileViewStyles';
import { ProfileDropdownSkeletonLoadingMobile, AIInfoSkeletonLoading } from '../Profile/ProfileSkeletonLoading';
import { formatImgDimension, rotateImage } from './helpers/imageHelper';
import { getRapidTest, getRapidTestImageKit, getRapidTestImageMembrane } from './rapidTestSlice';
import {
  selectRapidTest,
  selectRapidMembrane,
  selectRapidMembraneLoading,
  selectRapidKit,
  selectRapidKitLoading,
} from './selectors';

function AIInfo(props) {
  const { classes, orderDetails } = props;
  const [imgDimension, setImgDimension] = useState(null);

  const rapidTest = useSelector(selectRapidTest);
  const rapidMembrane = useSelector(selectRapidMembrane);
  const rapidMembraneLoading = useSelector(selectRapidMembraneLoading);
  const rapidKit = useSelector(selectRapidKit);
  const rapidKitLoading = useSelector(selectRapidKitLoading);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  const dispatch = useDispatch();

  useEffect(() => {
    const getBase64ImgDimension = async (imgParams) => {
      let img = new Image();
      img.src = `data:image/png;base64,${imgParams}`;
      await img.decode();
      const width = img.width;
      const height = img.height;
      const orientation = img.width > img.height ? 'Landscape' : 'Portrait';

      const dimension = {
        width,
        height,
        orientation,
      };

      setImgDimension(dimension);
    };

    if (!imgDimension && rapidKit && rapidKit.data && rapidKit.data.scanImageData) {
      getBase64ImgDimension(rapidKit.data.scanImageData);
    }

    return () => {
      setImgDimension(null);
    };
  }, [rapidKit]);

  useEffect(() => {
    if (
      orderDetails &&
      orderDetails._id &&
      orderDetails._id != rapidMembrane?.data.test._id &&
      orderDetails._id != rapidKit?.data.test._id
    ) {
      dispatch(getRapidTest(orderDetails._id));
      dispatch(getRapidTestImageMembrane(orderDetails._id));
      dispatch(getRapidTestImageKit(orderDetails._id));
    }
  }, [orderDetails]);

  const createAIInfo = () => {
    const listItems = [];
    if (rapidTest && rapidTest.data && rapidKit && rapidKit.data && rapidMembrane && rapidMembrane.data) {
      listItems.push(
        <ListItem label="Elapsed Time (sec)" value={rapidKit?.data?.test?.elapsedTimeOnCapture} order="3" />
      );

      if (!rapidKit.data.isRND) {
        listItems.push(
          <ListItem label="Wait Time (sec)" value={rapidKit?.data?.test?.elapsedTimeOnSubmissionTimer} order="4" />
        );
      }

      listItems.push(
        <ListItem label="AI Processing Time (sec)" value={rapidKit?.data?.test?.elapsedTimeOnAI} order="2" />
      );
      listItems.push(
        <ListItem label="Upload Id" value={rapidKit?.data?.test?.metadata?.kitUploadID?.split('_')[1]} order="1" />
      );

      if (
        !(
          rapidKit.data.isSelfAssessment ||
          rapidKit.data.isRetry ||
          (rapidKit.data.test && rapidKit.data.test.status === 'INITIALIZED')
        ) ||
        rapidKit.data.isRND
      ) {
        listItems.push(<ListItem label="Confidence Score" value={rapidKit?.data?.testConfidenceScore} order="5" />);
      }
      listItems.push(
        <ListItem label="Is Retry" value={rapidKit.data.isRetry && !rapidKit.data.isRND ? 'Yes' : 'No'} order="6" />
      );

      if (rapidKit.data.isRetry && !rapidKit.data.isRND) {
        listItems.push(<ListItem label="Retry Count" value={rapidTest?.data?.test?.retryCount} />);
      }

      // SAF-603 - Image Width, Image Height, Orientation
      listItems.push(
        <ListItem
          label="Orientation"
          value={rapidKit?.data?.captureInfo?.['Exif IFD0 - Orientation'] || imgDimension?.orientation || '---'}
        />
      );

      listItems.push(<ListItem label="R&D" value={rapidTest?.data?.isRND ? 'Yes' : 'No'} />);

      // SAF-603 - Image Width, Image Height, Orientation
      listItems.push(
        <ListItem
          label="Image Height"
          value={formatImgDimension(
            rapidKit?.data?.captureInfo?.['PNG-IHDR - Image Height'] || imgDimension?.height || '---'
          )}
        />
      );

      listItems.push(
        <ListItem
          label="Image Width"
          value={formatImgDimension(
            rapidKit?.data?.captureInfo?.['PNG-IHDR - Image Width'] || imgDimension?.width || '---'
          )}
        />
      );
    } else if (!rapidMembraneLoading && !rapidKitLoading) {
      listItems.push(
        <Grid item xs={12}>
          <Box>
            <Typography
              variant="h4"
              style={{
                fontSize: '12px',
                fontWeight: 'bold',
                paddingBottom: '5px',
              }}
            >
              No AI Info Details Found.
            </Typography>
          </Box>
        </Grid>
      );
    }
    return listItems.map((item, index) => <React.Fragment key={index}>{item}</React.Fragment>);
  };

  const kitImageDisplay = () => (
    <>
      <Grid item xs={6} className={classes.imageContainer}>
        <Typography align="center" variant="h4">
          Detected Kit
        </Typography>

        <div
          style={{
            padding: '0 20px',
            margin: '24px auto',
          }}
        >
          {rapidKit?.data?.scanImageData ? (
            <RapidKitImage image={rapidKit?.data?.scanImageData} />
          ) : (
            <Typography align="center" variant="body1">
              N/A
            </Typography>
          )}{' '}
        </div>
      </Grid>

      <Grid item xs={6} className={classes.imageContainer}>
        <Typography align="center" variant="h4">
          Detected Membrane
        </Typography>

        <div
          style={{
            padding: '0 20px',
            margin: '24px auto',
          }}
        >
          {rapidMembrane?.data?.scanImageData ? (
            <img
              src={`data:image/png;base64,${rapidMembrane.data.scanImageData}`}
              height={500}
              alt="Image of detected membrane"
              style={{ borderRadius: 20 }}
            />
          ) : (
            <Typography align="center" variant="body1">
              N/A
            </Typography>
          )}
        </div>
      </Grid>
    </>
  );

  const createAIMetadataDisplay = () => {
    if (rapidTest?.data && rapidKit?.data && rapidMembrane?.data) {
      if (
        !(rapidKit.data.isSelfAssessment || rapidKit.data.isRetry || rapidKit.data.test?.status === 'INITIALIZED') ||
        rapidKit.data?.isRND
      ) {
        const getUploadImages = () => {
          if (rapidTest.data?.aiMetadata) {
            if (rapidTest.data.aiMetadata.uploadImages) return 'True';
            return 'False';
          }
          return null;
        };
        return (
          <>
            <ListItem label="Test Id" value={rapidTest.data?.aiMetadata?.testID} type="metadata" />
            <ListItem
              label="Max Length"
              value={rapidTest.data?.aiMetadata ? rapidTest.data.aiMetadata.maxLength : null}
              type="metadata"
            />
            <ListItem
              label="Kit Data json Path"
              value={rapidTest.data?.aiMetadata ? rapidTest.data.aiMetadata.kitDataJsnPath : null}
              type="metadata"
            />
            <ListItem
              label="Angle Calc Method"
              value={rapidTest.data?.aiMetadata ? rapidTest.data.aiMetadata.angleCalcMethod : null}
              type="metadata"
            />
            <ListItem
              label="Detection Endpoint"
              value={rapidTest.data?.aiMetadata ? rapidTest.data.aiMetadata.detectionEndpoint : null}
              type="metadata"
            />
            <ListItem
              label="Classification Endpoint"
              value={rapidTest.data?.aiMetadata ? rapidTest.data.aiMetadata.classificationEndpoint : null}
              type="metadata"
            />
            <ListItem label="Upload Images" value={getUploadImages()} type="metadata" />
            <ListItem
              label="Zone 1 Positive Threshold"
              value={rapidTest.data?.aiMetadata ? rapidTest.data.aiMetadata.zone1PositiveThreshold : null}
              type="metadata"
            />
            <ListItem
              label="Zone 2 Positive Threshold"
              value={rapidTest.data?.aiMetadata ? rapidTest.data.aiMetadata.zone2PositiveThreshold : null}
              type="metadata"
            />
            <ListItem
              label="Kit Box Threshold"
              value={rapidTest.data?.aiMetadata ? rapidTest.data.aiMetadata.kitBoxThreshold : null}
              type="metadata"
            />
            <ListItem
              label="Kit Mask Threshold"
              value={rapidTest.data?.aiMetadata ? rapidTest.data.aiMetadata.kitMaskThreshold : null}
              type="metadata"
            />
            <ListItem
              label="Membrane Box Threshold"
              value={rapidTest.data?.aiMetadata ? rapidTest.data.aiMetadata.membraneBoxThreshold : null}
              type="metadata"
            />
            <ListItem
              label="Membrane Mask Threshold"
              value={rapidTest.data?.aiMetadata ? rapidTest.data.aiMetadata.membraneMaskThreshold : null}
              type="metadata"
            />
            <ListItem
              label="Membrane Localization Threshold"
              value={rapidTest.data?.aiMetadata ? rapidTest.data.aiMetadata.membraneLocalizationThreshold : null}
              type="metadata"
            />
            <ListItem
              label="Angle Threshold"
              value={rapidTest.data?.aiMetadata ? rapidTest.data.aiMetadata.angleThreshold : null}
              type="metadata"
            />
          </>
        );
      }
    }

    return [];
  };
  if (!rapidMembrane || rapidMembraneLoading || !rapidKit || rapidKitLoading) {
    return (
      <div>
        {matches ? (
          <div>
            <Paper style={{ borderRadius: '20px', marginBottom: 30 }} elevation={0}>
              <AIInfoSkeletonLoading />
            </Paper>
          </div>
        ) : (
          <div>
            <Paper style={{ borderRadius: '20px', marginBottom: 30 }} elevation={0}>
              <ProfileDropdownSkeletonLoadingMobile />
            </Paper>
          </div>
        )}
      </div>
    );
  }

  return (
    <>
      <Grid container direction="row" justifyContent="space-between">
        <Card className={classes.basicContainer} style={{ width: '59%' }}>
          <CardContent>
            <Grid className={classes.processingMetaDataContainer}>
              <Typography variant="h4">Processing Metadata</Typography>
              <Box
                style={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
                  minWidth: '100%',
                }}
              >
                {createAIInfo()}
              </Box>
            </Grid>
          </CardContent>
        </Card>

        <Card className={classes.basicContainer} style={{ width: '39%' }}>
          <CardContent>
            <Grid className={classes.infoBoxesUpdated}>{kitImageDisplay()}</Grid>
          </CardContent>
        </Card>
      </Grid>

      <Grid container direction="row" justifyContent="flex-start" alignItems="center" className={classes.infoBoxes}>
        <Grid item xs={12}>
          <Accordion
            style={{
              margin: '10px 0 30px',
              borderRadius: '20px',
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h4" style={{ marginLeft: 15 }}>
                AI Metadata
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid className={classes.infoBoxesUpdated}>
                <Grid container>{createAIMetadataDisplay()}</Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </>
  );
}

function ListItem({ label = '---', value, type, ...props }) {
  const classes = useStyles();
  const width = type === 'metadata' ? 3 : 4;

  // Prioritize ListItem with Grid Order number
  const { order = 100 } = props;

  return (
    <Grid
      item
      xs={width}
      style={{
        order,
      }}
    >
      <Box>
        <Typography variant="h5" className={classes.label}>
          {label}
        </Typography>
        <div>
          <Typography variant="body1" style={{ wordWrap: 'break-word' }}>
            {value ?? '---'}
          </Typography>
        </div>
      </Box>
    </Grid>
  );
}

function RapidKitImage({ image }) {
  const imgRef = useRef(null);
  const [style, setStyle] = useState({});

  useEffect(() => {
    const imgStyle = {
      borderRadius: 20,
      height: 500,
    };

    const { height, width } = imgRef.current;
    const isImageLandscape = height < width;

    // rotate 90 degrees if image is landscape
    if (isImageLandscape) {
      imgStyle['height'] = 'auto';
      imgStyle['width'] = '100%';
      // we didn't use css transform: rotate(90deg) since the resulting image dimension doesn't follow the container div.
      rotateImage(imgRef.current.src, 90, (resultBase64) => {
        imgRef.current.src = resultBase64;
        setStyle(imgStyle);
      });
    } else {
      setStyle(imgStyle);
    }
  }, []);

  return (
    <img ref={imgRef} src={`data:image/png;base64,${image}`} alt="Image of kit" style={style} hidden={!style?.height} />
  );
}

const useStyles = makeStyles(() => ({
  label: {
    fontSize: '12px',
    fontWeight: 'bold',
    paddingBottom: '5px',
  },
}));

export default withStyles(ProfileViewStyles)(AIInfo);
