import { EnterCodeForm } from './EnterCodeForm';
import { LoginCredentialsForm } from './LoginCredentialsForm';

export const signInStepMapping = {
  loginCredentials: {
    title: 'Welcome',
    description: 'Sign in to your administrator account',
  },
  enterCodeSMS: {
    title: 'Enter Code',
    description: "We've texted you a code. This code expires shortly, so please enter it as soon as possible.",
  },
  enterCodeTOTP: {
    title: 'Enter Code',
    description: 'Please enter the 6-digit verification code generated by your authenticator application.',
  },
};

export const signInFormMap = {
  loginCredentials: LoginCredentialsForm,
  enterCodeSMS: EnterCodeForm,
  enterCodeTOTP: EnterCodeForm,
};
