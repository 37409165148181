/* eslint-disable react/no-array-index-key */
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import csv from 'csvtojson';
import React, { useState, useEffect, useCallback } from 'react';

import { previewReportHeaders } from './helpers/mappings';
import './ReportPreviewDialog.css';

function ReportPreviewDialog(props) {
  const { open, handleClose, previewData, reportType } = props;
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const getHeaders = useCallback(
    () =>
      new Promise((resolve) => {
        csv({
          delimiter: [','],
          fork: true,
        })
          .on('header', (headers) => {
            resolve(headers);
          })
          .fromString(previewData);
      }),
    [previewData]
  );

  const parseCSVString = useCallback(
    (parsedHeaders) =>
      new Promise((resolve) => {
        const getHeaders = () => {
          if (parsedHeaders && parsedHeaders.length) return parsedHeaders;
          if (reportType) return previewReportHeaders[reportType];
          return previewReportHeaders['User Report'];
        };
        csv({
          noheader: true,
          // output: "csv",
          delimiter: [','],
          fork: true,
          headers: getHeaders(),
        })
          .preFileLine((fileLineString) => {
            const countQuotesInLine = (fileLineString.match(/"/g) || []).length;

            // if unpaired count of quotes in line - delete it
            if (countQuotesInLine % 2) {
              fileLineString = '';
            } // removing the else part as it is causing parsing issue if a line contains double quotes and comma

            return fileLineString;
          })
          .fromString(previewData)
          .then((csvRow) => {
            resolve(csvRow);
          });
      }),
    [previewData, reportType]
  );

  const convertToColumnId = (label) => label.trim().replace(/ |/g, '_').toLowerCase();

  useEffect(() => {
    (async () => {
      const parsedHeaders = await getHeaders();

      const parsedData = await parseCSVString(parsedHeaders);

      if (parsedData && parsedData.length) {
        const parsedColumns = Object.keys(parsedData.shift()).map((row) => ({
          id: convertToColumnId(row),
          label: row,
          minWidth: 170,
        }));
        setColumns(parsedColumns);

        const parsedRows = parsedData.map((row) => {
          const convertedRow = {};
          // eslint-disable-next-line no-return-assign
          Object.values(row).map((item, index) => (convertedRow[parsedColumns[index].id] = item.trim()));
          return convertedRow;
        });
        setRows(parsedRows);
      }
    })();
  }, [getHeaders, parseCSVString]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Dialog fullWidth maxWidth="xl" open={open} onClose={handleClose} aria-labelledby="max-width-dialog-title">
      <DialogContent>
        <TableContainer style={{ maxHeight: window.innerHeight - 200 }}>
          <Table stickyHeader size="small" aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column, index) => (
                  <TableCell key={column.id + index}>{column.label}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === 'number' ? column.format(value) : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 50, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ReportPreviewDialog;
