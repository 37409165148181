import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import defaultFavicon from '../../../assets/img/defaultFavicon.png';
import { paths } from '../../../constants';
import { getAccountDetails } from '../../Account/accountSlice';
import { getAlertsList } from '../../Alerts/alertsSlice';
import { validateAccountId } from '../../Auth/authSlice';
import { getUserFromToken } from '../../Auth/helpers';
import { selectAccountId, selectAccountValid, selectAuthToken } from '../../Auth/selectors';
import { getNetworkByTenantId } from '../../Networks/networksSlice';
import { selectTenantNetwork, selectTenantNetworkLoading } from '../../Networks/selectors';
import { getCurrentUser, getUserBulks, getInitialData } from '../../Users/usersSlice';
import { selectAllTenants, selectCurrentTenant } from '../selectors';
import { getMyTenants, logout, setAuthToken, setCurrentTenant } from '../sharedSlice';

const API_ROOT = process.env.REACT_APP_API_ROOT;

export function useAppInit() {
  const currentNetwork = useSelector(selectTenantNetwork);
  const currentTenant = useSelector(selectCurrentTenant);
  const currentNetworkLoading = useSelector(selectTenantNetworkLoading);
  const allTenants = useSelector(selectAllTenants);
  const isLoggedIn = useSelector(selectAuthToken);
  const accountValid = useSelector(selectAccountValid);
  const accountId = useSelector(selectAccountId);
  const [currentTenantId, setCurrentTenantId] = useState('');
  const [theme, setTheme] = useState({
    palette: {
      primary: {
        main: '#2E5266',
        contrastText: '#fff',
      },
      secondary: {
        main: '#CED0CE',
        contrastText: '#fff',
      },
    },
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const doLogout = (delay = 0) => {
    setTimeout(() => {
      dispatch(logout(navigate));
    }, delay);
  };

  const user = useMemo(() => getUserFromToken(), [isLoggedIn]);

  const getAlerts = useCallback((currentTenantId) => {
    if (currentTenantId) {
      dispatch(getAlertsList(currentTenantId, 'menuAlerts', 0, window.outerWidth < 961 ? 6 : 10, '', 'time', 'DESC'));
    }
  }, []);

  // getting all initial data on load of application, or tenant change
  const initApp = useCallback(
    (tenantId) => {
      if (!tenantId) return;
      dispatch(getInitialData(tenantId));
      getAlerts(tenantId);
      setCurrentTenantId(tenantId);
    },
    [getAlerts, user]
  );

  useEffect(() => {
    const id_token = localStorage.getItem('id_token');
    if (!id_token && !new RegExp([paths.signIn(), paths.resetPassword()].join('|')).test(location.pathname)) {
      navigate(paths.signIn(), { replace: true });
    }
  }, [location.pathname]);

  useEffect(() => {
    if (!currentTenant) return;
    setCurrentTenantId(currentTenant.id);
  }, [currentTenant]);

  useEffect(() => {
    if (accountValid) {
      localStorage.setItem('accountId', accountId);
    }
  }, [accountValid]);

  useEffect(() => {
    (async () => {
      if (
        currentTenant &&
        currentTenantId &&
        !currentNetworkLoading &&
        (!currentNetwork || currentTenantId !== currentNetwork.tenantID)
      ) {
        // Need to get network info
        setTheme(null);
        const currentNetworkData = await dispatch(getNetworkByTenantId(currentTenant));

        if (!currentNetworkData) return;

        setTheme({
          palette: {
            primary: {
              main: currentNetworkData.appThemeColor || '#2E5266',
              contrastText: '#fff',
            },
            secondary: {
              main: '#CED0CE',
              contrastText: '#fff',
            },
          },
          overrides: {
            MuiButton: {
              root: {
                borderRadius: currentNetworkData.buttonStyle === 0 ? 5 : 50,
              },
            },
            MuiTextField: {
              root: {
                '& .MuiInputLabel-root': {
                  fontSize: '16px',
                  position: 'absolute',
                  top: '-10px',
                  left: '-4px',
                  backgroundColor: '#fff',
                  padding: '8px',
                },
              },
            },
          },
        });
      }
    })();
  }, [currentTenantId, currentNetwork, currentNetworkLoading, currentTenant]);

  // update favicon when network changes
  useEffect(() => {
    if (currentNetwork && !currentNetworkLoading && currentTenant && currentTenant.id === currentNetwork.tenantID) {
      const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
      link.type = 'image/x-icon';
      link.rel = 'shortcut icon';
      if (currentNetwork && currentNetwork.favicon) {
        link.href = currentNetwork.favicon;
      } else {
        link.href = defaultFavicon;
      }
      dispatch(setAuthToken(localStorage.getItem('id_token')));
    }

    if (!isLoggedIn) dispatch(setAuthToken(localStorage.getItem('id_token')));
  }, [currentNetwork, currentNetworkLoading, currentTenant, isLoggedIn]);

  useEffect(() => {
    // if (!isLoggedIn || !allTenants) return;
    if (!isLoggedIn) return;

    const url = window.location.href;
    const urlTenant = url.split('/')[3];
    const redirectUrl = sessionStorage.getItem('redirectUrl');

    if (urlTenant && !currentTenant && allTenants && allTenants.total > 0 && user) {
      const urlTenantObject = _.find(allTenants.items, { id: urlTenant });
      const tenantIdFromStorage = localStorage.getItem('tenantId');
      const tenantFromStorage = tenantIdFromStorage && _.find(allTenants.items, { id: tenantIdFromStorage });
      const rootTenant = _.find(allTenants.items, { root: true });

      if (urlTenantObject) {
        dispatch(setCurrentTenant(urlTenantObject));
      } else {
        const defaultTenant = tenantFromStorage || rootTenant || allTenants.items[0];
        dispatch(setCurrentTenant(defaultTenant));
        const url = redirectUrl ? `/${defaultTenant.id}${redirectUrl}` : `/${defaultTenant.id}`;
        sessionStorage.removeItem('redirectUrl');
        navigate(url);
      }
    } else if (allTenants && allTenants.total > 0 && !currentTenant && user) {
      // sort all tenants array in alphabetical order
      const array = [...allTenants.items];
      const sorted = array.sort((a, b) => {
        const tenantA = a.title.toUpperCase();
        const tenantB = b.title.toUpperCase();
        if (tenantA < tenantB) return -1;
        if (tenantA > tenantB) return 1;
        return 0;
      });
      for (let i = 0; i < allTenants.items.length; i++) {
        // check if root tenant selected, if so set as current tenant
        if (allTenants.items[i].root === true) {
          sorted.splice(i, 1);
          sorted.unshift(allTenants.items[i]);
          break;
        }
      }
      dispatch(setCurrentTenant(sorted[0]));
      const url = redirectUrl ? `/${sorted[0].id}${redirectUrl}` : `/${sorted[0].id}`;
      sessionStorage.removeItem('redirectUrl');
      navigate(url);
    } else if (allTenants && allTenants.total === 0) {
      dispatch(setCurrentTenant('None'));
      navigate('/');
    }
  }, [
    allTenants,
    isLoggedIn,
    currentTenant,
    // currentTenantId, // - this dependence cause 2 initial loadings,
    initApp,
    user,
  ]);

  useEffect(() => {
    if (!isLoggedIn || !currentTenantId) return;

    initApp(currentTenantId);
  }, [currentNetwork]);

  useEffect(() => {
    const url = new URL(window.location.href);
    let accountIdFromUrl = url.host.split('.')[0];
    if (accountIdFromUrl.includes('localhost') && API_ROOT.includes('development')) {
      accountIdFromUrl = 'healthcheck';
    }
    if (!accountId) {
      dispatch(validateAccountId(accountIdFromUrl));
    }
  }, [API_ROOT, accountId]);

  useEffect(() => {
    (async () => {
      if (user && isLoggedIn) {
        const accountDetails = await dispatch(getAccountDetails());
        if (!accountDetails) return doLogout(3000);

        const res = await dispatch(getMyTenants());
        if (!res) return doLogout(3000);
        dispatch(getCurrentUser());
      }
    })();
  }, [isLoggedIn]);

  useEffect(() => {
    if (isLoggedIn && currentTenant && currentTenant.id && user && user.email) {
      const interval = setInterval(() => {
        getAlerts(currentTenant.id);
        dispatch(getUserBulks(currentTenant, user.email, 0, 10, 'started', 'DESC'));
      }, 30000);
      return () => clearInterval(interval);
    }
  }, [currentTenant, user, getAlerts, isLoggedIn]);

  const isAppLoading =
    isLoggedIn &&
    (!currentTenant ||
      currentTenant === 'None' ||
      !currentNetwork?.tenantID ||
      currentTenant.id !== currentNetwork?.tenantID ||
      currentNetworkLoading);

  return { isAppLoading, theme };
}
