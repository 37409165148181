import { createSlice } from '@reduxjs/toolkit';

import api from '../../api';
import { handleError } from '../Shared/Content/APIUtils';

const initialState = {
  allAlerts: null,
  allAlertsLoading: null,
  menuAlerts: null,
  menuAlertsLoading: null,
};

export const alertsSlice = createSlice({
  name: 'alerts',
  initialState,
  reducers: {
    setTenantAlertsLoading: (state, { payload }) => ({
      ...state,
      [`${payload}Loading`]: true,
    }),

    setTenantAlerts: (state, { payload }) => ({
      ...state,
      [payload.type]: payload.data,
      [`${payload.type}Loading`]: false,
    }),
    resetTenantAlerts: () => ({}),
  },
});

export const { setTenantAlertsLoading, setTenantAlerts, resetTenantAlerts } = alertsSlice.actions;

export const getAlertsList = (tenant, type, page, pageSize, query, sort, direction) => async (dispatch) => {
  dispatch(setTenantAlertsLoading(type));

  const params = {
    skip: page * pageSize,
    limit: pageSize,
    q: query,
    sort,
    direction,
  };
  const [result, error] = await api.getAlertsRequest(tenant, params);

  if (result) {
    dispatch(setTenantAlerts({ data: result, type }));
  }

  if (error && error.message) {
    dispatch(handleError(error));
  }
};

export const readAllAlerts = (tenant, pageSize) => async (dispatch) => {
  const [result, error] = await api.readAllAlertsRequest(tenant);

  if (result) {
    dispatch(getAlertsList(tenant, 'menuAlerts', 0, pageSize, '', 'time', 'DESC'));
  }

  if (error && error.message) {
    dispatch(handleError(error));
  }
};

export default alertsSlice.reducer;
