import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React, { useEffect } from 'react';
// redux
import { useDispatch, useSelector } from 'react-redux';
import CreateFormWrapper from '../../Shared/Forms/CreateFormWrapper';
import { getAccountDetails, updateAccountDetails } from '../accountSlice';
import { selectAccountDetails, selectAccountDetailsLoading, selectAccountDetailsError } from '../selectors';
// components and helpers
import { transform, formSteps as steps } from './helpers/formHelper';
import { updateJsonBuilder } from './helpers/jsonBuilders';

function AccountFormWrapper() {
  const accountDetails = useSelector(selectAccountDetails);
  const loading = useSelector(selectAccountDetailsLoading);
  const error = useSelector(selectAccountDetailsError);

  const dispatch = useDispatch();

  // get network data from database
  useEffect(() => {
    if (!accountDetails) {
      dispatch(getAccountDetails());
    }
  }, [accountDetails]);

  // submit completed form
  const handleSubmit = (formData) => {
    const data = updateJsonBuilder(formData);
    dispatch(updateAccountDetails(data));
  };

  if (loading) return <CircularProgress />;

  if (!accountDetails) return <Typography>{error}</Typography>;

  return (
    <Grid container justifyContent="center">
      <CreateFormWrapper
        tabs
        steps={steps}
        handleSubmit={handleSubmit}
        initialValues={transform(accountDetails)}
        confirmation="The changes you are about to make are sensitive and will have an impact on the system. 
        Are you sure you want to submit these changes?"
      />
    </Grid>
  );
}

export default AccountFormWrapper;
